import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { CONFIRM_DIALOG_TITLE } from "../../../config";
import _ from "lodash";
import ConfirmDialog from "../../ConfirmDialog";

const MobilePriority = (props) => {
    const { optInServiceLineRes, optInData, handleOptIn, setMobilePriority, mobilePriority, row } = props;

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: {},
    });
    const [checked, setChecked] = useState(row?.optedIn ? true : false);
    
    const handleToggleMobilePriority = (e, action) => {
        const value = e.target.checked;
        setMobilePriority(value);
        setConfirmDialog({
            isOpen: true,
            title: CONFIRM_DIALOG_TITLE,
            subTitle: action == 'optOut' ?
                "Data will continue to be charged for up to 15 minutes." :
                "Charges will be incurred until Mobie Priority is disabled. Mobile Priority will begin within 15 minutes. ",
            onConfirm: () => {
                handleOptIn();
            },
        })
    };

    useEffect(() => {
        if (!_.isEmpty(optInServiceLineRes)) {
            const res = optInServiceLineRes;
            setMobilePriority(res.success ? !mobilePriority : checked);
        }
    }, [optInServiceLineRes])

    return (
        <div className="optIn-container">
            <Grid>
                <Grid>
                    <span id='edit-toggle-btn-text'>Disabled</span>
                    <Switch className="toggle-btn-edit"
                        checked={mobilePriority}
                        onChange={(e) => handleToggleMobilePriority(e, optInData?.action)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        size="small"
                    />
                    <span id='edit-toggle-btn-text'>Enabled</span>
                </Grid>
            </Grid>
            <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} setMobilePriority={setMobilePriority} checked={checked} isMobilePriority={true} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    optInServiceLineRes: state.starlinkAdmin.optInServiceLineRes
});

export default withRouter(
    connect(mapStateToProps, { })(MobilePriority)
);