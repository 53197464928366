import { Fragment, HTMLAttributes, ReactNode, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteRenderOptionState } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { getDPList } from "../../actions/Users/authenticateInventory";
import _ from "lodash";
import { Grid, Typography } from "@mui/material";
import './index.css';

interface Organization {
    id: string;
    name: string;
    parent_of: Organization[];
}

export interface OrgOption {
    id: string;
    name: string;
    level?: number;
    hasChildren?: boolean;
}

export interface OrgSelectionProps {
    authReducer: any;
    label: string;
    value?: OrgOption;
    disabled?: boolean;
    dpIds?: string[];
    onChange: (org: OrgOption | null | undefined) => void;
    getDPList: (search?: string) => void;
}

function OrgSelection(props: OrgSelectionProps) {
    const { authReducer, label, value, disabled, onChange, getDPList, dpIds } = props;
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<readonly OrgOption[]>([]);

    const createOptionsRecursively = (orgs: Organization[], level = 0, dpIdsSet = new Set(dpIds)): OrgOption[] => {
        const options: OrgOption[] = [];
        orgs.forEach((org) => {
            if (dpIdsSet.has(org.id)) {
                options.push({
                    id: org.id,
                    name: org.name,
                    level: level,
                    hasChildren: org?.parent_of?.length > 0,
                });
            }

            if (org.parent_of.length > 0) {
                options.push(...createOptionsRecursively(org.parent_of, level + 1, dpIdsSet));
            }
        });
        return options;
    };

    useEffect(() => {
        if (open) {
            getDPList();
        }
    }, [open, getDPList]);

    useEffect(() => {
        if (!_.isEmpty(authReducer?.getDPList?.dps)) {
            const uniqueOptions = createOptionsRecursively(authReducer.getDPList.dps);
            setOptions(uniqueOptions);
        }
    }, [authReducer.getDPList, dpIds]);

    return (
        <Autocomplete
            id="select-organization"
            size="small"
            fullWidth
            value={value}
            disabled={disabled}
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            options={options}
            loading={authReducer.inventoryListingLoading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <Fragment>
                                {authReducer.inventoryListingLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }}
                />
            )}
            renderOption={(props: HTMLAttributes<HTMLLIElement>, option: OrgOption, state: AutocompleteRenderOptionState): ReactNode => (
                <li {...props} key={option.id}>
                    <Grid
                        style={{ marginLeft: `${option.level || 0}rem`, paddingLeft: '0.25rem' }}
                        className="starlink--base--flex--gap--05x starlink--base--flex--align--center"
                    >
                        <Grid display={'flex'} alignItems={'center'}>
                            {option.level && option.level > 0 ? (
                                <Fragment>
                                    <Grid className="org--selection--option--pre0"></Grid>
                                    <Grid className="org--selection--option--pre1"></Grid>
                                </Fragment>
                            ) : null}
                            <Typography component="span" sx={{ marginLeft: '0.5rem' }}>
                                {option.name}
                            </Typography>
                        </Grid>
                    </Grid>
                </li>
            )}
        />
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    dpIds: state.starlinkCloud.dpIds, 
});

export default withRouter(
    connect(mapStateToProps, {
        getDPList
    })(OrgSelection)
);