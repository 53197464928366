import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, Button, Checkbox, Chip, Dialog, FormControl, FormHelperText, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { ServiceLine, StarlinkQuotaAlert, User } from "../../StarlinkReports/types";
import { checkLoggedInUserAuthorizedToViewPage, extractEmails } from "../../../utils/util";

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { getAllServiceLines, getAllServiceLinesParameters } from "../../StarlinkReports/slice";
import { Action, Actions, StarlinkQuota } from "../types";
import moment from "moment-timezone";

import { InputLabel, MenuItem, Select, SelectChangeEvent, FormControlLabel, ListSubheader } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';

import { PeriodWithUnitInput, QuotaPeriodChangeEvent } from "../../../UserScreen/QuotaManagement/PeriodWithUnitInput";
import { DataWithUnitInput } from "../../../UserScreen/QuotaManagement/DataWithUnitInput";
import AlertingConditions from '../QuotaActions';
import './index.css';
import { nanoid } from 'nanoid';
import { convertDateTimeIntoTimezone, getTimezoneCity } from "../../../utils/util";

import teamsWebhookHelpDoc from "../../../asset/user_guide/TeamsWebhookCreateRemove.pdf";
import close from "../../../asset/image/close.png";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from '../../../utils/constants';
import { SERVICE_FUNCTION_TO_FEATURE } from "../../../config";

interface StarlinkAddEditQuotaDialogProps {
    authReducer: any;
    errorReducer: any;
    heading: string;
    starlinkCloud: any;
    starlinkReports: any;
    quota: StarlinkQuota | null;
    serviceLines: ServiceLine[];
    open: boolean;
    onSave: (service_line_number: string, quota: any, isNew: boolean) => void;
    onClose: () => void;
    getAllServiceLines: (parameters: getAllServiceLinesParameters) => void;
}

const STARLINK_ADMIN_SF = "starlink-admin";
const starlinkAdminService = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["service"];
const starlinkAdminFunction = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["function"];

function AddEditQuotaDialog(props: StarlinkAddEditQuotaDialogProps) {

    const { authReducer, errorReducer, heading, starlinkCloud, starlinkReports, quota, serviceLines, open, onClose, onSave, getAllServiceLines } = props;
    let filteredServiceLines = serviceLines.filter((item, index, array) => array.findIndex(t => t.nickname === item.nickname) === index)

    const [selectedServiceLine, setSelectedServiceLine] = useState<ServiceLine | undefined>(quota != null ? serviceLines.find(sl => sl.serviceLineNumber === quota.service_line_number) : undefined);
    const [usersToNotify, setUsersToNotify] = useState<User[]>(quota != null && quota?.users_to_notify ? quota.users_to_notify : []);
    const [quotaLimit, setQuotaLimit] = useState<number>(quota != null ? quota.limit : 1000000000000);
    const [quotaPeriod, setQuotaPeriod] = useState<number>(quota != null ? quota.period : 1);
    const [quotaPeriodUnit, setQuotaPeriodUnit] = useState<string>(quota != null ? quota.period_unit : 'M');
    const [quotaStartDate, setQuotaStartDate] = useState(quota != null ? moment(new Date(moment(quota?.start_time).valueOf())) : moment().startOf('month'));
    const [notificationMethods, setNotificationMethods] = useState<string[]>(quota != null && quota.notification_methods ? quota.notification_methods : []);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [actions, setActions] = useState<Action[]>(quota != null && quota?.actions ? quota.actions.filter(ac => !ac.revert) : [])
    const [revertActions, setRevertActions] = useState<Action[]>(quota != null && quota?.actions ? quota.actions.filter(ac => ac.revert) : []);
    const [editableRevertActions, setEditableRevertActions] = useState<boolean>(false);
    const [trackSelectedDevice, setTrackSelectedDevice] = useState(false);
    const [openTrack, setOpenTrack] = useState(false);
    const [disableActions, setDisableActions] = useState<boolean>(false);
    
    const isAdmin = useMemo(() => {
        return checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction)
    }, [])

    const handleClose = (value: boolean) => {
        onClose();
        setTrackSelectedDevice(false);
    };

    const handleSave = () => {
        let _quota: any = {
            service_line_number: selectedServiceLine?.serviceLineNumber,
            limit: Number(quotaLimit),
            period: Number(quotaPeriod),
            period_unit: quotaPeriodUnit,
            start_time: quotaStartDate.toISOString(),
            actions: [...actions, ...revertActions],
            notification_methods: notificationMethods,
            users_to_notify: usersToNotify
        }
        if (quota != null) {
            _quota.id = quota?.id;
            for (let key of ['service_line_number', 'limit', 'period', 'period_unit', 'start_time', 'actions', 'notification_methods', 'users_to_notify']) {
                if (_.isEqual(quota[key], _quota[key])) {
                    delete _quota[key];
                }
            }
            if (quotaStartDate.isSame(moment(quota.start_time).utc())) {
                delete _quota.start_time;
            }
        }
        onSave(_quota.service_line_number, _quota, quota == null);
    }

    const handleChangeSelectedServiceLines = (event: SyntheticEvent<Element, Event>, value: unknown, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<unknown> | undefined) => {
        if (reason === 'selectOption') {
            let _value = value as ServiceLine;
            setSelectedServiceLine(_value);
        } else if (!value) {
            setSelectedServiceLine(undefined);
        }

        setActions([]);
        setRevertActions([]);
    }

    const handleQuotaPeriodChange = (event: QuotaPeriodChangeEvent<HTMLInputElement>) => {
        setQuotaPeriod(event.target.value.period)
        setQuotaPeriodUnit(event.target.value.unit)
    }

    const handleQuotaLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuotaLimit(Number(event.target.value))
    }

    const handleStartDateChange = (_startDate: moment.Moment | null) => {
        if (_startDate == null || !_startDate.isValid())
            return;
        setQuotaStartDate(_startDate.seconds(0))
    }

    const handleChangeNotificationMethods = (event: React.ChangeEvent<HTMLInputElement>) => {
        let _value = [...notificationMethods];
        if (event.target.checked) {
            _value.push(event.target.name);
        } else {
            _value = _value.filter(nm => nm !== event.target.name);
        }
        setNotificationMethods(_value);
    };

    const handleChangeUsersToNotify = (event: SyntheticEvent<Element, Event>, value: unknown, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<unknown> | undefined) => {
        if (reason === 'selectOption' || reason === 'removeOption') {
            let _value = value as User[];
            setUsersToNotify(_value);
        } else if (reason === 'createOption') {
            let _users: User[] = [];
            let _value = value as (string | User)[];
            _value.forEach((v: string | User) => {
                if (typeof v === 'string') {
                    let _values: string[] = extractEmails(v)
                    _values.forEach(_v => {
                        let _user = users.find(u => u.email === _v);
                        if (_user) {
                            _users.push(_user);
                        } else {
                            _users.push({ name: _v, email: _v });
                        }
                    })
                } else {
                    _users.push(v);
                }
            });
            setUsersToNotify(_users);
        } else if (reason === 'clear') {
            setUsersToNotify([]);
        }

    };

    const handleChangeActions = (actions: Action[]) => {
        setActions(actions)
    }

    const handleChangeRevertActions = (actions: Action[]) => {
        setRevertActions(actions)
    }

    const users = useMemo(() => {
        return authReducer?.usersList?.data?.records?.length > 0 ? _.orderBy(authReducer?.usersList?.data?.records?.map((u: any) => {
            let _u: User = {
                name: u?.name?.givenName + " " + u?.name?.familyName,
                email: u.userName
            };
            return _u;
        }).filter(
            (u: User) => u.name.trim() !== "" && u.email.trim() !== ""
        ), 'name', 'asc') : [];
    }, [authReducer?.usersList]);

    useEffect(() => {
        if (open) {
            if (serviceLines.length > 0) {
                setSelectedServiceLine(quota != null ? serviceLines.find(sl => sl.serviceLineNumber === quota.service_line_number) : undefined);
            }
            setQuotaLimit(quota != null ? quota.limit : 1000000000000);
            setQuotaPeriod(quota != null ? quota.period : 1);
            setQuotaPeriodUnit(quota != null ? quota.period_unit : 'M');
            setQuotaStartDate(quota != null ? moment(new Date(moment(quota?.start_time).valueOf())) : moment().utc(false).startOf('month'));
            setNotificationMethods(quota != null && quota.notification_methods ? quota.notification_methods : []);
            setUsersToNotify(quota != null && quota?.users_to_notify ? quota.users_to_notify : []);
            setActions(quota != null && quota?.actions ? quota.actions.filter(ac => !ac.revert) : [])
            setRevertActions(quota != null && quota?.actions ? quota.actions.filter(ac => ac.revert) : []);

            let _hasCriticalActions = false;
            if (quota != null && quota.actions && quota.actions.length > 0) {
                _hasCriticalActions = quota.actions.filter(a => a.type === Actions.ActionPauseServiceLine || a.type === Actions.ActionResumeServiceLine).length > 0;
            }
            if (!isAdmin && _hasCriticalActions) {
                setDisableActions(true);
            }
        } else {
            setDisableActions(false);
        }
    }, [open, quota])

    useEffect(() => {
        setIsValid(
            !_.isEmpty(selectedServiceLine) &&
            quotaLimit > 0 &&
            quotaPeriod > 0 &&
            (['D', 'W', 'M'].includes(quotaPeriodUnit)) &&
            quotaStartDate.isValid() &&
            (actions.length > 0 ? actions.filter(a => a.valid === false).length === 0 : true) &&
            (notificationMethods.includes("email") ? usersToNotify.length > 0 : true) &&
            (actions.filter(a => a.type === Actions.ActionNotification).length > 0 ? notificationMethods.length > 0 : true)
        );
    }, [selectedServiceLine, quotaLimit, quotaPeriod, quotaPeriodUnit, quotaStartDate, notificationMethods, usersToNotify, actions]);

    useEffect(() => {
        setDisableActions(
            starlinkReports.settingQuotaAlert
        );
    }, [starlinkReports]);

    useEffect(() => {
        if (notificationMethods.includes("email")) {
            setUsersToNotify(usersToNotify);
        } else {
            setUsersToNotify([]);
        }
    }, [notificationMethods]);

    useEffect(() => {
        if (quota == null) {
            let _revertActions = identifyRevertActions(actions);
            // let _revertActions: Action[] = [];
            setRevertActions(_revertActions);
        }
    }, [actions])

    return (
        <Dialog className="menuScroll" onClose={handleClose} open={open} maxWidth="lg" fullWidth>
            <div className="quota_management--base--margin_1x quota_management--base--font_1x">
                <div>
                    {heading}
                </div>
                <div className='margin-left-auto'>  <img
                    src={close}
                    alt='close'
                    onClick={() => { handleClose(false) }}
                    className='close-btn-ht-wd'/> </div>
            </div>
            <div className="quota_management--base--margin_1x">
                <Typography className="quota_management--base--pb_1x" variant="body2" color="text.secondary">
                    Data Usage Source
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            id="select-service-line"
                            size='small'
                            value={selectedServiceLine}
                            onChange={handleChangeSelectedServiceLines}
                            options={serviceLines}
                            getOptionLabel={(option) => option.nickname !== "" ? option.nickname : option.serviceLineNumber}
                            renderInput={(params) => <TextField {...params} label="Service Line" variant='outlined' />}
                            renderOption={(props, option) => <li {...props} key={option.serviceLineNumber}>{option.nickname !== "" ? option.nickname : option.serviceLineNumber}</li>}
                            placeholder='Select Service Line'
                            disabled={disableActions}
                        />
                    </Grid>
                </Grid>
            </div>
            <div className="quota_management--base--margin_1x">
                <Typography className='quota_management--base--pb_1x' variant="body2" color="text.secondary">
                    Quota Configuration
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <PeriodWithUnitInput value={quotaPeriod} unit={quotaPeriodUnit} onChange={handleQuotaPeriodChange} disabled={disableActions} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth sx={{ minWidth: 20 }}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DateTimePicker
                                    label="Quota Start"
                                    value={quotaStartDate}
                                    ampm={false}
                                    timezone={getTimezoneCity(authReducer.userTimezone)}
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                    }}
                                    onChange={handleStartDateChange}
                                    disabled={disableActions}
                                />
                            </LocalizationProvider>
                        </FormControl>

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DataWithUnitInput value={quotaLimit} onChange={handleQuotaLimitChange} disabled={disableActions} />
                    </Grid>
                </Grid>
            </div>
            <div className="quota_management--base--margin_1x">
                <Typography className='quota_management--base--pb_1x' variant="body2" color="text.secondary">
                    Quota Action(s)
                </Typography>
                <AlertingConditions value={actions} readOnly={disableActions} onChange={handleChangeActions} />
            </div>
            <div className="quota_management--base--margin_1x">
                <Typography className='quota_management--base--pb_1x' variant="body2" color="text.secondary">
                    Quota Action(s) on renewal <IconButton size='small' onClick={() => { setEditableRevertActions(!editableRevertActions) }}>
                        {editableRevertActions ? <Tooltip title="Disable Edit"><EditOffIcon /></Tooltip> : <Tooltip title="Enable Edit"><EditIcon /></Tooltip>}
                    </IconButton>
                </Typography>
                <AlertingConditions value={revertActions} onChange={handleChangeRevertActions} readOnly={!editableRevertActions || disableActions} forRevert={true} />
            </div>

            <div className="quota_management--base--margin_1x">
                <Typography className='quota_management--base--pb_1x' variant="body2" color="text.secondary">
                    Notification
                </Typography>
                <Grid spacing={2}>
                    <Grid container xs={12}>
                        <Grid item xs={12} md={2} >
                            <Checkbox size='small' name={'email'} checked={notificationMethods.includes("email")} onChange={handleChangeNotificationMethods} disabled={disableActions} />
                            <Typography variant="body2" color="text.secondary" display={'inline'}>
                                Email
                            </Typography>
                        </Grid>
                        {
                            notificationMethods.includes("email") && <Grid item xs={12} md={10}>
                                <FormControl fullWidth error={usersToNotify.length === 0} disabled={disableActions}>
                                    <Autocomplete
                                        id="select-users"
                                        size='small'
                                        multiple={true}
                                        className={notificationMethods.includes("email") ? "" : "disbale-user-selection"}
                                        freeSolo={true}
                                        value={usersToNotify}
                                        onChange={handleChangeUsersToNotify}
                                        options={users}
                                        getOptionLabel={(option) => {
                                            let _option = option as User;
                                            return _option.name !== "" ? _option.name : _option.email
                                        }}
                                        disabled={disableActions}
                                        renderInput={(params) => <TextField {...params} label="Send to (via Email)" variant='outlined' error={usersToNotify.length === 0} disabled={disableActions} />}
                                        renderOption={(props, option) => <li {...props} key={nanoid()}>{option.name !== "" ? option.name : option.email}</li>}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip size='small' label={option.name} {...getTagProps({ index })} />
                                            ))
                                        }
                                        placeholder='Send to (via Email)'
                                    />
                                    {
                                        usersToNotify.length === 0 ? <FormHelperText>Please select at least one user/or email</FormHelperText> : null
                                    }
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                    <Grid container xs={12}>
                        <Grid item xs={12} md={2} >
                            <Checkbox size='small' name={'microsoft_teams'} checked={notificationMethods.includes("microsoft_teams")} onChange={handleChangeNotificationMethods} disabled={disableActions} />
                            <Typography variant="body2" color="text.secondary" display={'inline'}>
                                Microsoft Teams
                            </Typography>
                            <IconButton size='small' onClick={() => window.open(teamsWebhookHelpDoc)}>
                                <Tooltip title="Help"><HelpOutlineIcon /></Tooltip>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div className='quota_management--base--flex quota_management--base--margin_1x quota_management--base--flex--justify--end'>
                <Button variant="outlined" className='quota_management--button--cancel' onClick={() => { handleClose(false) }}>Cancel</Button>
                <Button variant="contained" className='quota_management--button--save' onClick={handleSave} disabled={!isValid || disableActions}>Save</Button>
            </div>
        </Dialog>
    )
}

const identifyRevertActions = (actions: Action[]): Action[] => {

    let revertActions: Action[] = [];

    // find affected accessnetwork ids set and interfaces set
    actions.forEach(a => {
        if (a.type === Actions.ActionPauseServiceLine) {
            revertActions.push({
                id: nanoid(),
                usage_percentage: 0,
                type: Actions.ActionResumeServiceLine,
                executed: false,
                revert: true
            });
        }
    });

    return revertActions;
}

const mapStateToProps = (state: any) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    starlinkCloud: state.starlinkCloud,
    starlinkReports: state.starlinkReports,
    serviceLines: state.starlinkReports.allServiceLines,
});

export default withRouter(connect(mapStateToProps, {
    getAllServiceLines,
})(AddEditQuotaDialog));
