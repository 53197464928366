import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Avatar, Chip, Stack, Tooltip } from "@mui/material"
import topSite from "../../asset/image/topSiteIcon.svg";
import topUser from "../../asset/image/topUserIcon.svg";
import topCategory from "../../asset/image/categoryIcon.svg";
import topApp from "../../asset/image/topAppIcon.svg";
import topVlan from '../../asset/image/vlanIcon.svg';
import { getIntervalCheck } from "../../utils/util";

const ChipFilters = (props) => {
    const { authReducer, values, setValues, applyFilterClick, newSummaryStartDate, newSummaryEndDate, setConfirmPopup } = props;
    const { vessels, category, app, user, vlan, device, subCategory, trackApplyFilter } = values;
    const { setCategory, setApp, setUser, setVlan, setDevice, setSubCategory } = setValues;

    const handleDeleteCategory = (key) => {
        switch(key) {
            case 'category': {
                setCategory('');
                applyFilterClick(newSummaryStartDate, newSummaryEndDate, vessels, '', app, user, vlan, device, subCategory);
                break;
            }
            case 'app': {
                setApp('');
                applyFilterClick(newSummaryStartDate, newSummaryEndDate, vessels, category, '', user, vlan, device, subCategory);
                break;
            }
            case 'user': {
                setUser('');
                applyFilterClick(newSummaryStartDate, newSummaryEndDate, vessels, category, app, '', vlan, device, subCategory);
                break;
            }
            case 'vlan': {
                setVlan('');
                applyFilterClick(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, '', device, subCategory);
                break;
            }
            case 'device': {
                setDevice('');
                applyFilterClick(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, '', subCategory);
                break;
            }
            case 'subCat': {
                setSubCategory('');
                applyFilterClick(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, '');
                break;
            }
            case 'site': {
                const Vessels = authReducer?.getVesselsListing?.locations;
                const checkInterval = getIntervalCheck(Math.floor(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3));
                if(checkInterval) {
                    setConfirmPopup(checkInterval);
                }
                applyFilterClick(newSummaryStartDate, newSummaryEndDate, Vessels, category, app, user, vlan, device, subCategory, 'filter');
                break;
            }
        }
    }

    return (
        <Stack direction="row" spacing={1} alignItems='center'>
            {vessels && vessels.length == 1 && <Tooltip title={`Site : ${vessels[0]?.['vessel-name']}`}><Chip label={vessels[0]?.['vessel-name']} onDelete={() => handleDeleteCategory('site')} avatar={<Avatar style={{ width: '17px', height: '17px' }} src={topSite} />} /></Tooltip>}
            {user && trackApplyFilter && <Tooltip title={`User : ${user}`}><Chip label={user} onDelete={() => handleDeleteCategory('user')} avatar={<Avatar style={{ width: '17px', height: '17px' }} src={topUser} />} /></Tooltip>}
            {category && trackApplyFilter && <Tooltip title={`Category : ${category}`}><Chip label={category} onDelete={() => handleDeleteCategory('category')} avatar={<Avatar style={{ width: '14px', height: '14px', padding: '3px' }} src={topCategory} />} /></Tooltip>}
            {app && trackApplyFilter && <Tooltip title={`Domain : ${app}`}><Chip label={app} onDelete={() => handleDeleteCategory('app')} avatar={<Avatar style={{ width: '15px', height: '15px', paddingLeft: '5px' }} src={topApp} />} /></Tooltip>}
            {vlan && trackApplyFilter && <Tooltip title={`VLAN : ${vlan}`}><Chip label={vlan} onDelete={() => handleDeleteCategory('vlan')} avatar={<Avatar style={{ width: '15px', height: '15px', padding: '3px' }} src={topVlan} />} /></Tooltip>}
            {device && trackApplyFilter && <Tooltip title={`Device : ${device}`}><Chip label={device} onDelete={() => handleDeleteCategory('device')} /></Tooltip>}
            {subCategory && trackApplyFilter && <Tooltip title={`Sub Category : ${subCategory}`}><Chip label={subCategory} onDelete={() => handleDeleteCategory('subCat')} /></Tooltip>}
        </Stack>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    newSummaryStartDate: state.authReducer.newSummaryStartDate,
    newSummaryEndDate: state.authReducer.newSummaryEndDate,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, { })(ChipFilters)
);