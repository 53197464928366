import { Fragment, useEffect, useMemo, useState } from "react";

import { Box, Grid, IconButton, Paper, Switch, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { Edit, Delete, Lens } from "@material-ui/icons";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { convertDateTimeIntoTimezone, readablePeriod } from "../../../utils/util";
import { Device, Quota } from "../types";

import { UsageLineRep } from "../UsageLineRep";
import moment from "moment-timezone";
import Warning from "../../../asset/image/warning.svg"
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";

interface EditableQuotaRowProps {
    userTimezone: string;
    device: Device;
    quota: Quota;
    gettingCurrentUsage: boolean;
    onEdit: (quota: Quota) => void;
    onDelete: (quota: Quota) => void;
    onCopy: (quota: Quota) => void;
    onToggleDisable: (quota: Quota) => void;
    isReadOnlyUser: boolean;
    devices: any
}

export function EditableQuotaRow(props: EditableQuotaRowProps) {
    const { userTimezone, quota, devices, onEdit, onCopy, onDelete, onToggleDisable, isReadOnlyUser, device } = props;
    const [selectedDevice, setSelectedDevice] = useState<Device | undefined>(quota != null ? devices.find(d => d.id === quota.device_id) : undefined);
    const [deviceInterface, setDeviceInterface] = useState<string[]>(quota != null && quota?.wan_links ? quota.wan_links : []);

    const handleEdit = () => {
        onEdit(quota);
    }

    const handleCopy = () => {
        onCopy(quota);
    }

    const handleDelete = () => {
        onDelete(quota);
    }

    const handleToggleDisable = () => {
        onToggleDisable({...quota, disabled: !quota.disabled});
    }

    const showDualUsageWarning = () => {
        if (!quota.wan_links || !quota.starlink_quota || !quota.starlink_quota.enabled) return null;
        return quota.wan_links?.length > 0 && quota.wan_links?.some(di => device.interfaces.find(i => i.alias == di && i.wan_type == 'starlink')) ? <Tooltip title="Double counting of starlink usage">
            <Typography className="wan-hover"><img src={Warning} alt="warning"></img></Typography>
        </Tooltip> : null
    }

    useEffect(() => {
        if (devices?.length > 0) {
            setSelectedDevice(quota != null ? devices.find(d => d.id === quota.device_id) : undefined);
            setDeviceInterface(quota != null && quota.wan_links ? quota.wan_links : []);
        }
    }, [devices])

    const deviceInterfaces = useMemo(() => {
        let device = devices.find(d => d.id === selectedDevice?.id);
        if (device) {
            return device.interfaces;
        }
        return [];
    }, [selectedDevice])

    const invalidDeviceInterfaces = useMemo(() => {
        return deviceInterface.filter(di => deviceInterfaces.findIndex(d => d.alias === di) === -1)
    }, [deviceInterface, deviceInterfaces])

    return (
        <TableRow key={quota.id} className="row-parent">
            <TableCell>
                {quota.site_name}
            </TableCell>
            <TableCell>
                {quota.device_name}
            </TableCell>
            <TableCell className={quota.starlink_quota?.service_line_numbers?.length !== undefined && quota.starlink_quota?.service_line_numbers?.length > 0 ? "wan-hover-parent" : "wan-hover"}>
                {quota?.wan_links?.length && quota?.wan_links?.length > 0 ? <Box component="span"><Typography variant="caption" color="text.secondary">WAN INTERFACES: </Typography>{quota?.wan_links?.join(', ')}</Box> : null}
                {quota?.access_networks_name?.length && quota?.access_networks_name?.length > 0 ? <Box component="span"><Typography variant="caption" color="text.secondary" >ACCESS NETWORKS: </Typography>{quota?.access_networks_name?.join(', ')}</Box> : null}
                {quota?.starlink_quota?.service_line_numbers?.length && quota?.starlink_quota?.service_line_numbers?.length > 0 ? <Box component="span"><Typography variant="caption" color="text.secondary">STARLINK: </Typography>{quota?.starlink_quota?.service_line_numbers?.join(', ')}</Box> : null}
            </TableCell>
            <TableCell>
                {convertDateTimeIntoTimezone(quota.current_quota_start_time, userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}
            </TableCell>
            <TableCell>
                {readablePeriod(quota.period, quota.period_unit)}
            </TableCell>
            <TableCell>
                <UsageLineRep quota={quota} userTimezone={userTimezone} />
            </TableCell>
            <TableCell className={invalidDeviceInterfaces.length > 0 ? 'tooltip-invalid-interfaces' : ''}>
                {
                    quota?.status === "exceeded" ? <Grid classes={{ root: 'quota_management--base--flex--align--center' }}><Lens id="usageRedDotSymbol" />Over Max Quota {invalidDeviceInterfaces.length > 0 && <Tooltip title={`Selected interface (${invalidDeviceInterfaces.join(', ')}) does not exist, please reconfigure the interfaces`}><img src={Warning} alt=''/></Tooltip>}</Grid> 
                : quota?.status === "alerting" ? <Grid classes={{ root: 'quota_management--base--flex--align--center' }} ><Lens id="usageYellowDotSymbol" />Over Quota Threshold {invalidDeviceInterfaces.length > 0 && <Tooltip title={`Selected interface (${invalidDeviceInterfaces.join(', ')}) does not exist, please reconfigure the interfaces`}><img src={Warning} alt=''/></Tooltip>}</Grid> 
                : quota?.status === "active" ? <Grid classes={{ root: 'quota_management--base--flex--align--center' }} ><Lens id="usageBlueDotSymbol" />Normal {invalidDeviceInterfaces.length > 0 && <Tooltip title={`Selected interface (${invalidDeviceInterfaces.join(', ')}) does not exist, please reconfigure the interfaces`}><img src={Warning} alt=''/></Tooltip>}</Grid> : null}
            </TableCell>
            {!isReadOnlyUser && <TableCell>
                <Paper elevation={0} className="quota_management--device_summary--actions">
                    <Fragment>
                        <Tooltip title="Edit"><IconButton size='small' onClick={handleEdit}>
                            <Edit />
                        </IconButton></Tooltip>
                        <Tooltip title="Copy"><IconButton size='small' onClick={handleCopy}>
                            <ContentCopyIcon />
                        </IconButton></Tooltip>
                        <Tooltip title="Delete"><IconButton size='small' onClick={handleDelete}>
                            <Delete />
                        </IconButton></Tooltip>
                        <Tooltip title="Enable / Disable">
                            <Switch
                                size="small"
                                checked={!quota.disabled}
                                color="primary"
                                onChange={handleToggleDisable}
                            />
                        </Tooltip>
                    </Fragment>
                </Paper>
            </TableCell>}
        </TableRow>
    )
}