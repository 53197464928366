import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Line from "../../asset/image/Line.png";
import { Grid } from "@mui/material";
import './NotAuthorized.css';

const NotAuthorized = (props) => {
    const { pageTitle, PageMessage } = props;
    return (
        <div className="notAuthorizedPage">
            {/* <div className="title_div">
                <Toolbar>
                    <Typography className="page_title">
                        {pageTitle}
                    </Typography>
                    <img src={Line} style={{ marginLeft: "24px" }} />
                </Toolbar>
            </div> */}
            <div className="message">{PageMessage}</div>
        </div>
    );
}

export default NotAuthorized;