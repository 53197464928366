import React, { useState, useEffect, Fragment, useCallback } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import { checkLoggedInUserAuthorizedToViewPage, getServiceFunction } from "../../utils/util";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { RESOURCE_FIELD_ID, RESOURCE_FIELD_VESSELNAME } from "../../utils/constants";

import NotAuthorized from "../../UserScreen/NotAuthorized";
import TopBar from "./TopBar";
import LinksContainer from "./LinksContainer";

import { getEdgeServerStatus } from "../../actions/Users/graphQlServices";
import { updatePermission } from "../../actions/Users/authenticate";

import "./index.css";
import { clearData } from "./remoteConnectionSlice";

const VIEW_VESSEL_SUMMARY = "view-vessel-summary";

export interface FilterOptions {
    address: string[],
    port: string[],
    type: string[],
    alias: string[],
    isRevoked: string[]
    search?: string
}

const RemoteConnections = (props: any): JSX.Element => {
    const { authReducer, errorReducer, selectedSite, selectedSiteName, clearData, getEdgeServerStatus } = props;
    const [selectedDevice, setSelectedDevice] = useState<string>("");
    const [filter, setFilter] = useState<FilterOptions>({
        address: [],
        port: [],
        type: [],
        alias: [],
        isRevoked: []
    })

    const [isAuthorizedToViewPage, setIsAuthorizedToViewPage] = useState<Boolean>(false);

    const not_authorized_page_title = "Remote Connections";
    const not_authorized_page_message = "You are not authorised to view remote conenctions.";

    useEffect(() => {
        return () => {
            clearData();
        }
    }, [])

    useEffect(() => {
        const rcService = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["service"];
        const rcFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["function"];
        const authorizedUser = checkLoggedInUserAuthorizedToViewPage(rcService, rcFunction);
        setIsAuthorizedToViewPage(authorizedUser);

        const info = {
            permission: {
                service: rcService,
                serviceFunction: rcFunction,
            },
            isMultiVessel: false,
            isSingleVessel: false,
            showOu: false,
            ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
        };
        if (authorizedUser) {
            info.isSingleVessel = true;
            info.showOu = true;
        }
        updatePermission(info);
    }, [authReducer.selectedOu]);

    // useEffect(() => {
    //     if (!_.isEmpty(selectedSite) && !_.isEmpty(selectedDevice)) {
    //         getEdgeServerStatus(selectedDevice)
    //     }
    // }, [selectedSite, selectedDevice]);

    function handleDeviceSelection(device) {
        setSelectedDevice(device);
    }

    const handleSearch = (searchInput) => {
        setFilter({
            ...filter,
            search: searchInput
        })
    }

    return (
        <Fragment>
            {isAuthorizedToViewPage ?
                <div className="rc rm-conn-scroll padding-l-r-15">
                    <TopBar siteName={selectedSiteName} siteId={selectedSite} handleDeviceSelection={handleDeviceSelection} handleChangeFilter={(_filter) => { setFilter(_filter) }} handleSearch={handleSearch} filter={filter} />
                    <div className="rc--container">

                        <div className="rc--links_container">
                            <LinksContainer siteName={selectedSiteName} siteId={selectedSite} deviceId={selectedDevice} filter={filter} handleSearch={handleSearch} />
                        </div>
                    </div>
                </div> : <NotAuthorized pageTitle={not_authorized_page_title} PageMessage={not_authorized_page_message} />}
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(connect(mapStateToProps, {
    clearData,
    getEdgeServerStatus
})(RemoteConnections));