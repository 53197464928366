import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import close from "../../../asset/image/close.svg"
import _ from "lodash";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { populateForParent } from "../../../utils/util";
import { parentDpAndSubDpList } from "../../../actions/Users/authenticate";
import './index.css';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const QuotaCopy = (props) => {

    const { authReducer, clickedQuotaRow, openQuotaCopyPopUp, setOpenQuotaCopyPopUp, getGroupList, getDeviceList, copyQuotaConfig} = props

    const [groupList, setGroupList] = useState<any[]>([]);
    const [siteList, setSiteList] = useState<any[]>([]);
    const [deviceList, setDeviceList] = useState<any[]>([]);
    const [targetType, setTargetType] = useState({ label: "" });
    const [siteFilterValue, setSiteFilterValue] = useState("");
    const [deviceFilterValue, setDeviceFilterValue] = useState("");
    const [managedGroupFilterValue, setManagedGroupFilterValue] = useState("");
    const [selectedSites, setSelectedSites] = useState<any[]>([]);
    const [selectedDevices, setSelectedDevices] = useState<any[]>([]);
    const [selectedManagedGroups, setSelectedManagedGroups] = useState<any[]>([]);

    useEffect(() => {
        if (!_.isEmpty(authReducer.getOrganisationUnitList)) {
            if (authReducer.getOrganisationUnitList.dps && authReducer.getOrganisationUnitList.dps.length > 0) {
                getDpList(authReducer.getOrganisationUnitList.dps)
            }
        }
    }, [authReducer.getOrganisationUnitList])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getVesselsListing)) {
            let dpIds = getParentAndSubDpIds();
            let _dpIds = dpIds.map(x => "'" + x + "'").toString();
            const payloads = {
                "query": "GET_INVENTORY_GROUPS",
                "named": true,
                "format": "json",
                "parameters": {
                    "dp": _dpIds,
                    "search": "",
                    "page_start": 0,
                    "page_size": 100,
                    "sort_by": "update_time",
                    "sort_order": "desc"
                }
            };

            getGroupList(payloads);

            let ouName = authReducer.selectedOu?.name;
            getDeviceList(ouName);
        }
    }, [authReducer.getVesselsListing])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getInventoryGroupList)) {
            const data = authReducer.getInventoryGroupList?.data;
            setGroupList(data?.map((ele) => {
                return {
                    name: ele.name,
                    id: ele.id
                };
            }))
        }

    }, [authReducer.getInventoryGroupList]);
    
    useEffect(() => {
        if (!_.isEmpty(authReducer.getDeviceList)) {
            const data = authReducer.getDeviceList?.data;

            const siteListOptions: { name: string, id: number }[] = [];
            const deviceListOptions: { name: string, id: number }[] = [];
            data.forEach((ele) => {
                siteListOptions.push({
                    name: ele.location_name,
                    id: ele.location_id
                });
                ele?.devices.forEach((eleDevice) => {
                    if (clickedQuotaRow?.device_id !== eleDevice?.id) {
                        deviceListOptions.push({
                            name: eleDevice.name,
                            id: eleDevice.id
                        });
                    }
                });
            })
            setSiteList(siteListOptions);
            setDeviceList(deviceListOptions);
        }

    }, [authReducer.getDeviceList]);

    const getDPTree = (ous: any[], arr) => {
        if (ous && Array.isArray(ous)) {
            for (let i = 0; i < ous?.length; i++) {
                const item = ous[i];
                if (arr.hasOwnProperty(item.id)) {
                    const temp: any = arr[item.id];
                    arr[item.id] = { ...temp, ...item._childern };
                } else {
                    arr[item.id] = item._childern;
                }
                getDPTree(item._childern, arr);
            }
        }
    }

    const getDpList = (dpList) => {
        const tempArr: any = {};
        for (let i = 0; i < dpList.length; i++) {
            const _organisations = [];
            populateForParent(dpList[i], null, _organisations);
            getDPTree(_organisations, tempArr);
        }
        parentDpAndSubDpList(tempArr);
    }

    const getParentAndSubDpIds = () => {
        const _dpIds: any = [];
        if (!_.isEmpty(authReducer.selectedOu) && authReducer.selectedOu.hasOwnProperty("id") && authReducer.selectedOu.id && authReducer.selectedOu.hasOwnProperty("name") && authReducer.selectedOu.name) {
            const parentDpId = authReducer?.selectedOu?.id
            const dpIds = authReducer?.parentDpAndSubDpList
            if (!_.isEmpty(authReducer.selectedOu) && authReducer.selectedOu.hasOwnProperty("id") && authReducer.selectedOu.id && authReducer.selectedOu.hasOwnProperty("name") && authReducer.selectedOu.name && !_.isEmpty(authReducer.parentDpAndSubDpList)) {
            const parentDpId = authReducer?.selectedOu?.id
            const dpIds = authReducer?.parentDpAndSubDpList
            if (dpIds.hasOwnProperty(parentDpId)) {
                _dpIds.push(parentDpId)
                getChildren(_dpIds, dpIds[parentDpId])
            }
        }
            _dpIds.push(parentDpId)
        }
        return _dpIds
    }

    const getChildren = (dpIds, dps) => {
        for (let i = 0; i < dps?.length; i++) {
            let id = dps[i]?.id;
            dpIds.push(id);
            if (dps[i].hasOwnProperty('_childern') && dps[i]['_childern']?.length > 0) {
                getChildren(dpIds, dps[i]['_childern'])
            }
        }
    }

    const handleTargetTypeChange = (event, value) => {
        setTargetType(value);
        setSelectedSites([]);
        setSelectedDevices([]);
        setSelectedManagedGroups([]);
    }

    const handleClose = () => {
        setOpenQuotaCopyPopUp(false)
    }

    const handleQuotaCopy = async () => {
        const siteIds = selectedSites.map((site) => site.id);
        const deviceIds = selectedDevices.map((device) => device.id);
        const groupIds = selectedManagedGroups.map((group) => group.id);

        const payload = {
            "groupIds": groupIds,
            "siteIds": siteIds,
            "deviceIds": deviceIds,
            "sourceSiteId": clickedQuotaRow?.site_id,
            "sourceDeviceId": clickedQuotaRow?.device_id,
            "sourceDeviceQuotaId": clickedQuotaRow?.id
        }
        await copyQuotaConfig(payload)
        handleClose()
    }

    return (
        <div className="Form">
            <Dialog
                disableEnforceFocus
                open={openQuotaCopyPopUp}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                className='config-deploy-inventory-popup menuScroll inventory-warehouse-register-pop-up inventory-edit-actions-container quota-copy-config-pop-up'
            >
                {/* <div> */}
                <DialogTitle className='create-default-config-title edit-actions-title'>
                    <Grid className="device-configuration-popup">
                        <span className="configurations">Copy Quota Configurations</span>
                    </Grid>
                    <Grid><img src={close} title="Close" onClick={handleClose} /></Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container className="deploy-inventory-content register-inventory-content manage-config-pop-up">
                        <Grid item xs={12} sm={12} lg={12} className="config-apply-config-target">
                            <span>Source Device</span>
                            <Grid>
                                <div className="value"> {clickedQuotaRow?.device_name} </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} className="config-apply-config-target">
                            <span>Data Source</span>
                            <Grid>
                                <div className="value">
                                    {clickedQuotaRow?.wan_links?.length && clickedQuotaRow?.wan_links?.length > 0 ? <Box component="span"><Typography variant="caption" color="text.secondary">WAN INTERFACES: </Typography><span title={clickedQuotaRow?.wan_links?.join(', ')}>{clickedQuotaRow?.wan_links?.join(', ')}</span></Box> : null}
                                    {clickedQuotaRow?.access_networks_name?.length && clickedQuotaRow?.access_networks_name?.length > 0 ? <Box component="span"><Typography variant="caption" color="text.secondary" >ACCESS NETWORKS: </Typography><span title={clickedQuotaRow?.access_networks_name?.join(', ')}>{clickedQuotaRow?.access_networks_name?.join(', ')}</span></Box> : null}
                                    {clickedQuotaRow?.starlink_clickedQuotaRow?.service_line_numbers?.length && clickedQuotaRow?.starlink_clickedQuotaRow?.service_line_numbers?.length > 0 ? <Box component="span"><Typography variant="caption" color="text.secondary">STARLINK: </Typography><span title={clickedQuotaRow?.starlink_clickedQuotaRow?.service_line_numbers?.join(', ')}>{clickedQuotaRow?.starlink_clickedQuotaRow?.service_line_numbers?.join(', ')}</span></Box> : null}
                                </div>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy select-config-from select-target-type">
                            <span>Target Type</span>
                            <Grid className="configuration-form-build">
                                <Autocomplete
                                    options={[{ label: "Site" }, { label: "Device" }, { label: "Managed Group" }]}
                                    getOptionLabel={(option) => option.label}
                                    sx={{ width: 300 }}
                                    onChange={handleTargetTypeChange}
                                    value={targetType}
                                    renderInput={(params) => <TextField {...params} placeholder="Select Target Type" variant="outlined" size="small" />}
                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                />
                            </Grid>
                        </Grid>

                        {targetType?.label === "Site" ? (
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy select-config-from">
                                <span> Select Site</span>
                                <Grid className="menuScroll" style={{ marginTop: "8px" }}>
                                    <Autocomplete
                                        multiple
                                        id="site-select-box"
                                        options={[{ name: "Select All", id: null }, ...siteList]}
                                        value={selectedSites}
                                        limitTags={3}
                                        style={{ width: 300 }}
                                        disableCloseOnSelect
                                        inputValue={siteFilterValue}
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(prop, option, { selected }) => (
                                            <li {...prop}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} onChange={(e) => setSiteFilterValue(e.target.value)} placeholder="Select Site" variant="outlined" size="small" />
                                        )}
                                        onChange={(e, value) => {
                                            if (value.some((option) => option.name === "Select All")) {
                                                if (selectedSites.length === siteList.length) {
                                                    setSelectedSites([]);
                                                } else {
                                                    setSelectedSites(siteList);
                                                }
                                            } else {
                                                setSelectedSites(value);
                                            }
                                        }}
                                        onClose={() => { setSiteFilterValue("") }}
                                        isOptionEqualToValue={(option, value) => option.name === value.name}
                                    />
                                </Grid>
                            </Grid>

                        ) : null}

                        {targetType?.label === "Device" ? (
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy select-config-from">
                                <span> Select Device</span>
                                <Grid item className="menuScroll" style={{ marginTop: "8px" }}>
                                    <Autocomplete
                                        multiple
                                        id="device-select-box"
                                        options={[{ name: "Select All", id: null }, ...deviceList]}
                                        value={selectedDevices}
                                        limitTags={3}
                                        style={{ width: 300 }}
                                        disableCloseOnSelect
                                        inputValue={deviceFilterValue}
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(prop, option, { selected }) => (
                                            <li {...prop} key={option.id}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} onChange={(e) => setDeviceFilterValue(e.target.value)} placeholder="Select Device" variant="outlined" size="small" />
                                        )}
                                        onChange={(e, value) => {
                                            if (value.some((option) => option.name === "Select All")) {
                                                if (selectedDevices.length === deviceList.length) {
                                                    setSelectedDevices([]); // Deselect all
                                                } else {
                                                    setSelectedDevices(deviceList); // Select all
                                                }
                                            } else {
                                                setSelectedDevices(value);
                                            }
                                        }}
                                        onClose={() => { setDeviceFilterValue("") }}
                                        isOptionEqualToValue={(option, value) => option.name === value.name}
                                    />
                                </Grid>
                            </Grid>
                        ) : null}

                        {targetType?.label === "Managed Group" ? (
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy select-config-from">
                                <span> Managed Group</span>
                                <Grid className="menuScroll" style={{ marginTop: "8px" }}>
                                    <Grid className="menuScroll">
                                        <Autocomplete
                                            multiple
                                            id="managedgroup-select-box"
                                            options={[{ name: "Select All", id: null }, ...groupList]}
                                            value={selectedManagedGroups}
                                            limitTags={3}
                                            style={{ width: 300 }}
                                            disableCloseOnSelect
                                            inputValue={managedGroupFilterValue}
                                            getOptionLabel={(option) => option.name}
                                            renderOption={(prop, option, { selected }) => (
                                                <li {...prop}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.name}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} onChange={(e) => setManagedGroupFilterValue(e.target.value)} placeholder="Select Group" size="small" />
                                            )}
                                            onChange={(e, value) => {
                                                if (value.some((option) => option.name === "Select All")) {
                                                    if (selectedManagedGroups.length === groupList.length) {
                                                        setSelectedManagedGroups([]);
                                                    } else {
                                                        setSelectedManagedGroups(groupList);
                                                    }
                                                } else {
                                                    setSelectedManagedGroups(value);
                                                }
                                            }}
                                            onClose={() => setManagedGroupFilterValue("")}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : null}


                    </Grid>
                </DialogContent>
                <Grid className="register-edit-actions-confirm-btns">
                    <Divider className="confirm-dialogue-new-divider" />
                    <Grid className="confirm-dialogue-new-btns">
                        <Button className="confirm-dialogue-cancel" onClick={()=> setOpenQuotaCopyPopUp(false)}> Cancel </Button>
                        <Button className="confirm-dialogue-proceed" onClick={()=> handleQuotaCopy()}>Apply</Button>
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    )
}