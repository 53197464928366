import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FormControl, Grid, MenuItem, Select } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import AppUsageCard from "../AppUsageCard";
import { getContentFilterCategoryPie, getContentFilterApplicationPie, getContentFilterSubCategoryPie } from '../../actions/Users/authenticateContentFilter';
import _ from "lodash";
import { getDecodeURI, getEncodedURI } from "../../utils/util";

const BottomCards = (props) => {
    const { authReducer, values, setValues, location, history } = props;
    const { setTableLimit, setTrackDataLimit } = setValues;
    const { vessels, tableLimit } = values;
    const [categoryData, setCategoryData] = useState([]);
    const [subCategoryData, setSubCategoryData] = useState([]);
    const [domainData, setDomainData] = useState([]);

    useEffect(() => {
        if(vessels.length == 0) {
            setCategoryData([]);
            setSubCategoryData([]);
            setDomainData([]);
        }
    }, [vessels])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getContentFilterCategoryPie)) {
            const category = authReducer.getContentFilterCategoryPie.hasOwnProperty('data') ? authReducer.getContentFilterCategoryPie?.data : {};
            const categoryData: any = [];
            category?.rows?.forEach((item, i) => {
                if (i < tableLimit) {
                    categoryData.push({ application: item[0], hits: item[1] });
                }
            })
            setCategoryData(categoryData)
        }
        if (!_.isEmpty(authReducer.getContentFilterSubCategoryPie)) {
            const subCategory = authReducer.getContentFilterSubCategoryPie.hasOwnProperty('data') ? authReducer.getContentFilterSubCategoryPie?.data : {};
            const subCategoryData: any = [];
            subCategory?.rows?.forEach((item, i) => {
                if (i < tableLimit) {
                    subCategoryData.push({ application: item[0], hits: item[1] })
                }
            })
            setSubCategoryData(subCategoryData)
        }
        if (!_.isEmpty(authReducer.getContentFilterApplicationPie)) {
            const application = authReducer.getContentFilterApplicationPie.hasOwnProperty('data') ? authReducer.getContentFilterApplicationPie?.data : {};
            const applicationData: any = []
            application?.rows?.forEach((item, i) => {
                if (i < tableLimit) {
                    applicationData.push({ application: item[0], hits: item[1] })
                }
            })
            setDomainData(applicationData)
        }
    }, [authReducer.getContentFilterCategoryPie, authReducer.getContentFilterSubCategoryPie, authReducer.getContentFilterApplicationPie])

    const handleChangeTableLimit = (e) => {
        const value = e.target.value;
        setTableLimit(value);
        setTrackDataLimit(true);
        let params: any = getDecodeURI(location.search);
        value == 10 ? delete params.tableLimit : params.tableLimit = value;
        doNavigate(params);
    };

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }
    
    return (
        <Fragment>
            <Grid className="usageDetails-cards">
                <Grid className="usage-selectedTabs content-filter-table-limit">
                    <span className="selectUserTag-bottomTable">
                        <FormControl variant="standard" className='selectEntry-pie'>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={tableLimit}
                                onChange={handleChangeTableLimit}
                                >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>
                        </FormControl>
                    </span>
                </Grid>
                <Grid className="cardsParentComp">                        
                        <Grid className="cardParent-three">
                            <Grid container spacing={2} className="appUsageParent">
                                <Grid item xs={12} sm={12} md={6} lg={4} >
                                    <p>Top Category</p>
                                    <AppUsageCard appDownloadTableData={categoryData} header="HITS" tab='top_category' />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} >
                                    <p>Top Sub Category</p>
                                    <AppUsageCard appDownloadTableData={subCategoryData} header="HITS" tab='top_subCategory' />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} >
                                    <p>Top Domain</p>
                                    <AppUsageCard appDownloadTableData={domainData} header="HITS" tab='top_domain' />
                                </Grid>
                            </Grid>
                        </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, { getContentFilterCategoryPie, getContentFilterApplicationPie, getContentFilterSubCategoryPie })(BottomCards)
);