import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import "./LocationMap.css";
import { connect, useDispatch } from 'react-redux';
import MapLeaflet from '../../vis/react-app/src/components/Map/MapLeafLet';
import { getVesselAlerts } from '../../actions/Users/authenticateDashboard'
import { vesselLastLocWithAlerts } from '../../actions/Users/graphQlServices';
import { getDecodeURI, strToK4List } from "../../utils/util";

const LocationMap = (props: any) => {
  const { authReducer, locationFilter, setLocationFilter, alertCount, search, setSearch, history, location } = props;
  const dispatch = useDispatch();

  let alerting = 0;
  let totalVessel = 0;

  useEffect(() => {
    let _q = getDecodeURI(location?.search);
    let alerting = true, nonAlerting = true;
    if (_q.hasOwnProperty("alerting")) {
      if ('false' == _q.alerting) alerting = false;
    }

    if (_q.hasOwnProperty("nonAlerting")) {
      if ('false' == _q.nonAlerting) nonAlerting = false;
    }
    setLocationFilter({ alerting: alerting, nonAlerting: nonAlerting });
    if (_q.hasOwnProperty("search") && '' != _q.search) {
      setSearch(_q.search);
    } else if ("" !== _q.search) {
      setSearch("");
    }
  }, [location]);

  useEffect(() => {
    let _q = getDecodeURI(location?.search);
    let vessels: any[] = [];
    if (_q.hasOwnProperty("k4Ids") && '' != _q.k4Ids.trim()) {
      vessels = strToK4List(_q.k4Ids);
    }

    dispatch(vesselLastLocWithAlerts(vessels, authReducer?.selectedOu?.name, search));
  }, [search, authReducer.selectedVessels, authReducer.selectedOu]);

  const vesselCurrLocation = props?.vesselLocWithAlerts?.data?.getVesselLocWithAlert;
  if (vesselCurrLocation !== undefined) {
    totalVessel = vesselCurrLocation.length;
  }

  const vesselLocationDetails: any[] = vesselCurrLocation !== undefined && vesselCurrLocation.length > 0 &&
    vesselCurrLocation.map((item: any, i: any) => {
      let cordinates = item?.coordinates;
      let iconClasifer = 0;
      if (item?.isVsat) {
        iconClasifer = 1;
        alerting = alerting + 1;
      } else if (item?.isLte) {
        alerting = alerting + 1;
        iconClasifer = 2;
      }
      if (item?.isVsat && item?.isLte) {
        iconClasifer = 3;
      }
      if (!item?.isVsat && !item?.isLte) {
        iconClasifer = 4;
      }

      let alertingList = item?.events !== undefined && iconClasifer < 4 && item?.events.length > 0 && item?.events.map((item) => {
        if (item?.name.includes("VSAT") || item?.name.includes("LTE")) {
          return item.name;
        }
      }).filter((item) => item!);


      if (cordinates === undefined || cordinates[0] === null || cordinates[1] === null) {
        return;
      }
      return { "long": cordinates[1], "lat": cordinates[0], "name": item?.name, "heading": item?.heading, "iconClasfier": iconClasifer, "alertingList": alertingList }
    })

  alertCount.alerting = alerting;
  alertCount.nonAlerting = totalVessel - alerting;

  return (
    <div className="Form">
      <MapLeaflet
        vesselCurrentLocation={vesselLocationDetails}
        isAllVesselLocation={true}
        isMultiIcons={true}
        mapHeight="70vh"
        locationFilter={locationFilter}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
  vesselCurrentLocationDetails: state?.products?.vesselCurrentLocationDetails,
  vesselAlerts: state?.authReducer?.vesselAlerts,
  vesselLocWithAlerts: state?.authReducer?.vesselLocWithAlerts
});

export default withRouter(
  connect(mapStateToProps, {
    getVesselAlerts,
  })(LocationMap)
);