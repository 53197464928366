import { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, DialogActions, Divider, Grid } from "@mui/material";
import { Dialog } from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from '@mui/material/DialogContent';
import close from "../../asset/image/close.svg";
import _ from "lodash";
import SelectTag from "../InventoryWarehouseTable/SelectTag";
import { getConfigProducts, backupConfigData, applyGoldenConfig } from '../../actions/Users/authenticateInventory';
import { getVesselEdgeTopologyProducts } from '../../actions/Users/authenticateDashboard';
import "./ApplyConfig.css"
import ConfirmDialog from "../../components/ConfirmDialog";
import { CONFIRM_DIALOG_TITLE } from "../../config";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants";
import { convertDateTimeIntoTimezone } from "../../utils/util";
import { compare } from "compare-versions";

export const ApplyConfig = (props) => {
    const { authReducer, setConfirmDialog, selectedSite, selectedSiteName, selectedDevice,selectedRow, onClose, getVesselEdgeTopologyProducts, backupConfigData, getConfigProducts, applyGoldenConfig} = props;

    const [configSelectedValues, setConfigSelectedValue] = useState<any>({});
    const [configOptions, setConfigOptions] = useState<any>([]);
    const [idData, setIdData] = useState<any>({ Site: { label: '', id: '' }, Device: { label: '', id: '' }, Configuration: { label: '', id: '' } })
    const [selectedConfigDetails, setSelectedConfigDetails] = useState<any>({});

    useEffect(() => {
        if (!_.isEmpty(authReducer.getVesselsListing)) {
            let data = authReducer.getVesselsListing.hasOwnProperty("locations") && Array.isArray(authReducer.getVesselsListing.locations) ? authReducer.getVesselsListing.locations : [];
            let _k4Ids: any = []
            const vessels = data?.map((vs) => {
                _k4Ids.push(vs.id);
                return { label: vs.name, id: vs.id }
            })
            let sitesIds = _k4Ids.join(',')
            sitesIds && backupConfigData(sitesIds, -1, -1, '', 'create_time', 'desc', '', selectedDevice.productType, '', true);
        }
    }, [authReducer.getVesselsListing])

    useEffect(() => {
        if (!_.isEmpty(authReducer.backupConfigData)) {
            const data = authReducer?.backupConfigData?.result;
            setConfigOptions(data?.map((ele) => {
                return { label: ele.config_name, id: ele.id, details:ele }
            }));
            authReducer.backupConfigData = {};
        }
    }, [authReducer.backupConfigData])

    const handleChangeConfigFrom = (e, newValue, key) => {
        let previousValue = configSelectedValues;
        let selectedValue = { ...idData, [`${key}`]: newValue };
        if (!newValue) {
            previousValue = { ...previousValue, Configuration: '' };
            selectedValue = { ...idData, Configuration: { label: '', id: '' } };
        } else {
            previousValue = { ...previousValue, Configuration: e.target.innerText.trim() };
            selectedValue = { ...idData, Configuration: newValue };
        }

        setIdData(selectedValue);
        setConfigSelectedValue({ ...previousValue });
        if (!_.isEmpty(newValue) && !_.isEmpty(newValue.details)) {
            setSelectedConfigDetails(newValue.details)
        } else {
            setSelectedConfigDetails({})
        } 
    }

    const handleApplyConfig = () => {
        const payload = {
            site_id: selectedSite,
            device_id: selectedDevice?.id,
            config_id: idData?.Configuration?.id
        }
        applyGoldenConfig(payload);
        onClose();
    }


    return (
        <div className="Form">
            <Grid container className="deploy-inventory-content apply-inventory-content">
                <Grid item xs={12} sm={12} md={12} lg={12} className="config-apply-config-target" >
                    <span id="content-left-text">Device</span>
                    <Grid id='content-right-text'>
                        <div title={selectedDevice.name}>{selectedDevice.name}</div>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="config-apply-config-target" >
                    <span id="content-left-text">EOS Version</span>
                    <Grid id='content-right-text'>
                        <div title={selectedRow?.currentEOSversion}>{selectedRow?.currentEOSversion}</div>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy select-config-from" >
                    <span id="content-left-text">Select Configuration</span>
                    <Grid className="configuration-form-build">
                        <SelectTag options={configOptions} handleChange={(e, newValue) => handleChangeConfigFrom(e, newValue, 'Configuration')} value={idData.Configuration} />
                    </Grid>
                </Grid>
                {
                    !_.isEmpty(selectedConfigDetails)
                        ? <div className="new-selected-config-details">
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-apply-config-target" >
                                <span id="content-left-text">Device</span>
                                <Grid id="content-right-text">
                                    <div title={selectedConfigDetails.device_name}>{selectedConfigDetails.device_name}</div>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-apply-config-target" >
                                <span id="content-left-text">EOS Version</span>
                                <Grid id="content-right-text">
                                    <div title={selectedConfigDetails.software_version}>{selectedConfigDetails.software_version}</div>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-apply-config-target" >
                                <span id="content-left-text">Creation Date</span>
                                <Grid id="content-right-text">
                                    <div title={convertDateTimeIntoTimezone(selectedConfigDetails.create_time, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}>{convertDateTimeIntoTimezone(selectedConfigDetails.create_time, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</div>
                                </Grid>
                            </Grid>
                        </div>
                        : <></>
                }
                <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy select-config-from" >
                    {
                        // if config EOS is higher than current EOS - dont allow apply
                        !_.isEmpty(selectedConfigDetails) && compare(selectedConfigDetails.software_version, selectedRow.currentEOSversion, '>') ?
                            <Grid className="config-note">Selected config EOS version is higher than the target device current EOS version</Grid>
                            : null
                    }
                </Grid>
            </Grid>
            <div className="edit-actions-confirm-btns">
                <Divider className="confirm-dialogue-new-divider" />
                <div className="confirm-dialogue-new-btns">
                    <Button className="confirm-dialogue-cancel" onClick={onClose}> Cancel </Button>
                    <Button className={!_.isEmpty(selectedConfigDetails) && compare(selectedConfigDetails.software_version, selectedRow.currentEOSversion, '<=') && !_.isEmpty(idData.Configuration.id) ? "confirm-dialogue-proceed" : 'confirm-dialogue-proceed-notAllow'} onClick={() => {
                        if (!_.isEmpty(selectedConfigDetails) && compare(selectedConfigDetails.software_version, selectedRow.currentEOSversion, '<=') && !_.isEmpty(idData.Configuration.id)) {
                            setConfirmDialog({
                                isOpen: true,
                                title: CONFIRM_DIALOG_TITLE,
                                subTitle: "Configuration change needs server reboot and device will go offline for around 10-15 mins.\n\nAre you sure to apply the configuration changes on the device " + selectedDevice.name + " ?",
                                onConfirm: () => {
                                    handleApplyConfig()
                                },
                            })
                        }
                    }}>Upload Now</Button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});
export default withRouter(
    connect(mapStateToProps, { getConfigProducts, getVesselEdgeTopologyProducts, backupConfigData, applyGoldenConfig })(ApplyConfig)
);
