// New Summary Dashboard

import { api } from "../../server/request";
import { handleError, handleResponse } from "../responseUtil";
import {  GET_THREAT_DASHBOARD_TOPWIDGET, GET_TOP_10_THREAT_BY_NAME, SET_THREAT_DASHBOARD_TOPWIDGET_LOADING, SET_TOP_10_THREAT_BY_NAME_LOADING,GET_TOP_10_THREAT_BY_SEVERITY, SET_TOP_10_THREAT_BY_SERVERITY_LOADING, GET_THREAT_DASHBORAD_BAR_CHART_ON_SEVERITY_LOADING, GET_THREAT_DASHBORAD_BAR_CHART_ON_SEVERITY, GET_TOP_10_THREAT_TABLE_DATA, SET_THREAT_DASHBOARD_TOP_10_THREAT_TABLE_LOADING, GET_ALL_THREAT_TABLE_DATA, SET_THREAT_DASHBOARD_ALL_THREAT_TABLE_LOADING, SET_THREAT_DASHBOARD_TOPWIDGET_TOTAL_LOADING, GET_THREAT_DASHBOARD_TOPWIDGET_TOTAL, GET_THREAT_SITE_LIST_LOADING, GET_THREAT_SITE_LIST, GET_THREAT_DEVICE_LIST, GET_THREAT_DEVICE_LIST_LOADING, GET_USER_THREAT_TABLE_DATA, SET_THREAT_DASHBOARD_USER_THREAT_TABLE_LOADING, GET_THREAT_UNIQUE_DEVICE_LIST_LOADING, GET_THREAT_UNIQUE_DEVICE_LIST, GET_THREAT_UNIQUE_THREATNAME_LIST_LOADING, GET_THREAT_UNIQUE_THREATNAME_LIST, GET_THREAT_UNIQUE_IP_LIST_LOADING, GET_THREAT_UNIQUE_IP_LIST, GET_THREAT_UNIQUE_CLASS_LIST_LOADING, GET_THREAT_UNIQUE_CLASS_LIST, GET_THREAT_UNIQUE_ACTION_LIST_LOADING, GET_THREAT_UNIQUE_ACTION_LIST, SET_THREAT_ACTION_PIE_DATA_LOADING, GET_THREAT_ACTION_PIE_DATA } from "../types";
import moment from "moment";
import { getAvilWindow } from "./authenticateDashboard";

export const getClickhouseBucketReformat = (interval) => {
    switch (interval) {
        case "5m": return '5 minute';
        case "10m": return '10 minute';
        case "15m": return '15 minute';
        case "30m": return '30 minute';
        case "1h": return '1 hour';
        case "2h": return '2 hour';
        case "3h": return '3 hour';
        case "4h": return '4 hour';
        case "6h": return '6 hour';
        case "12h": return '12 hour';
        case "1d": return '1 day';
        case "7d": return '7 day';
        default: return '1 hour';
    }
}

export const getThreatTopWidget = (vessels, startDate, endDate, severityType, alertStatus, threatName , deviceName, ip, threatClassName , action) => (dispatch) => {
    dispatch({ type: SET_THREAT_DASHBOARD_TOPWIDGET_LOADING, payload: true });
    const vesselMap: any = [];
    let nameIsVirusOrMalware = threatClassName && (threatClassName.toLowerCase().includes("malware") || threatClassName.toLowerCase().includes("virus"))
    vessels?.map((item: any) => {
        let vesselName = item["id"];
        vesselMap.push(vesselName);
    })
    const threatWidget: any = {
        query: 'SNORT_DASHBOARD_SEVERITY_TOP_WIDGET_V2',
        named: true,
        format: "csv",
        "parameters": {
            "start_time": startDate,
            "end_time": endDate,
            "severity": severityType && severityType !== "all "? `and severity='${severityType.toUpperCase()}'`:"",
            "name": threatName ? `and msg='${threatName}'` : "",
            "ip": ip ? `and ip_address='${ip}'` : "",
            "class": threatClassName && nameIsVirusOrMalware ? `and msg ilike '%${threatClassName}%'` : threatClassName ? `and class='${threatClassName}'`: '',
            "devicename": deviceName ? `and Device_Name='${deviceName}'` : "",
            "siteid": vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            "action": action && action !== "" ? `and action='${action}'`:"",
          },
    }
    api
        .postRequestOut(`/store/ch/query`, threatWidget)
        .then((res) => handleResponse(res, dispatch, GET_THREAT_DASHBOARD_TOPWIDGET))
        .catch((err) => handleError(err, dispatch));
};

export const getTop10ThreatByName = (vessels, startDate, endDate, limit,severityType, threatName , deviceName, ip, threatClassName,action ) => (dispatch) => {

    dispatch({ type: SET_TOP_10_THREAT_BY_NAME_LOADING, payload: true });
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    let nameIsVirusOrMalware = threatClassName && (threatClassName.toLowerCase().includes("malware") || threatClassName.toLowerCase().includes("virus"))
    const data: any = {
        query: 'SNORT_DASHBOARD_THREAT_TYPE_HORIZONTAL_BAR_CHART_V2',
        named: true,
        parameters: {
          "start_time": startDate,
          "end_time": endDate,
          "severity": severityType && severityType !== "all "? `and severity='${severityType.toUpperCase()}'`:"",
          "name": threatName ? `and msg='${threatName}'` : "",
          "ip": ip ? `and ip_address='${ip}'` : "",
          "class": threatClassName && nameIsVirusOrMalware ? `and msg ilike '%${threatClassName}%'` : threatClassName ? `and class='${threatClassName}'`: '',
          "devicename": deviceName ? `and Device_Name='${deviceName}'` : "",
          "siteid":  vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
          "limits": limit? `limit ${limit}`: "",
          "action": action && action !== "" ? `and action='${action}'`:"",
        },
        "format": "csv"
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => {
            handleResponse(res, dispatch, GET_TOP_10_THREAT_BY_NAME)
        })
        .catch((err) => handleError(err, dispatch));
};


export const getTop10ThreatBySeverity= (vessels, startDate, endDate, limit, severityType,threatName , deviceName, ip, threatClassName, action) => (dispatch) => {
    dispatch({ type: SET_TOP_10_THREAT_BY_SERVERITY_LOADING, payload: true });
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    let nameIsVirusOrMalware = threatClassName && (threatClassName.toLowerCase().includes("malware") || threatClassName.toLowerCase().includes("virus"))
    const data: any = {
        query: 'SNORT_DASHBOARD_SEVERITY_PIE_V2',
        named: true,
        parameters: {
          "start_time": startDate,
          "end_time": endDate,
          "severity": severityType && severityType !== "all "? `and severity='${severityType.toUpperCase()}'`:"",
          "name": threatName ? `and msg='${threatName}'` : "",
          "ip": ip ? `and ip_address='${ip}'` : "",
          "class": threatClassName && nameIsVirusOrMalware ? `and msg ilike '%${threatClassName}%'` : threatClassName ? `and class='${threatClassName}'`: '',
          "devicename": deviceName ? `and Device_Name='${deviceName}'` : "",
          "siteid":  vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
          "limits":"",
          "action": action && action !== "" ? `and action='${action}'`:"",
        },
        "format": "csv"
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => {
            handleResponse(res, dispatch, GET_TOP_10_THREAT_BY_SEVERITY)
        })
        .catch((err) => handleError(err, dispatch));
};

export const getTopActionsPie = (vessels, startDate, endDate, limit, severityType,threatName , deviceName, ip, threatClassName, action) => (dispatch) => {
    dispatch({ type: SET_THREAT_ACTION_PIE_DATA_LOADING, payload: true });
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    let nameIsVirusOrMalware = threatClassName && (threatClassName.toLowerCase().includes("malware") || threatClassName.toLowerCase().includes("virus"))
    const data: any = {
        query: 'SNORT_DASHBOARD_ACTION_PIE',
        named: true,
        parameters: {
          "start_time": startDate,
          "end_time": endDate,
          "severity": severityType && severityType !== "all "? `and severity='${severityType.toUpperCase()}'`:"",
          "name": threatName ? `and msg='${threatName}'` : "",
          "ip": ip ? `and ip_address='${ip}'` : "",
          "class": threatClassName && nameIsVirusOrMalware ? `and msg ilike '%${threatClassName}%'` : threatClassName ? `and class='${threatClassName}'`: '',
          "devicename": deviceName ? `and Device_Name='${deviceName}'` : "",
          "siteid":  vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
          "limits":"",
          "action": action && action !== "" ? `and action='${action}'`:"",
        },
        "format": "csv"
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => {
            handleResponse(res, dispatch, GET_THREAT_ACTION_PIE_DATA)
        })
        .catch((err) => handleError(err, dispatch));
};

export const getThreatHistogramBySeverity = (vessels, startDate, endDate, severityType, limit, selectedBin,threatName , deviceName, ip, threatClassName, action) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    let nameIsVirusOrMalware = threatClassName && (threatClassName.toLowerCase().includes("malware") || threatClassName.toLowerCase().includes("virus"))
    dispatch({ type: GET_THREAT_DASHBORAD_BAR_CHART_ON_SEVERITY_LOADING, payload: true });
    let data: any = {
        query: "SNORT_DASHBOARD_BAR_CHART_V2",
        named: true,
        format:"chart",
        groupBy:"severity",
        series:"total_hits",
        tsColumn:"ts",
        parameters: {
            "start_time": startDate,
            "end_time": endDate,
            "severity": severityType && severityType !== "all "? `and severity='${severityType.toUpperCase()}'`:"",
            "name": threatName ? `and msg='${threatName}'` : "",
            "ip": ip ? `and ip_address='${ip}'` : "",
            "class": threatClassName && nameIsVirusOrMalware ? `and msg ilike '%${threatClassName}%'` : threatClassName ? `and class='${threatClassName}'`: '',
            "devicename": deviceName ? `and Device_Name='${deviceName}'` : "",
            "interval": selectedBin ? getClickhouseBucketReformat(selectedBin) : "1 hour",
            "siteid":  vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            "action": action && action !== "" ? `and action='${action}'`:"",
        }
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => {
            handleResponse(res, dispatch, GET_THREAT_DASHBORAD_BAR_CHART_ON_SEVERITY)
        })
        .catch((err) => handleError(err, dispatch));
};


export const getTopThreatTableData = (vessels, startDate, endDate, severityType, page, limit, sort, sortOrder, search, window,threatName , deviceName, ip, threatClassName, action) => (dispatch) => {
    const avail_window = Math.ceil((Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3)) / 5);
    dispatch({ type: SET_THREAT_DASHBOARD_TOP_10_THREAT_TABLE_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    let nameIsVirusOrMalware = threatClassName && (threatClassName.toLowerCase().includes("malware") || threatClassName.toLowerCase().includes("virus"))
    const top10TheratTableData: any = {
        query: 'SNORT_DASHBOARD_TOP_THREAT_TABLE_V2',
        named: true,
        parameters: {
            "start_time": startDate,
            "end_time": endDate,
            "severity":  severityType && severityType !== "all "? `and severity='${severityType.toUpperCase()}'`:"",
            "name": threatName ? `and msg='${threatName}'` : "",
            "devicename": deviceName ? `and Device_Name='${deviceName}'` : "",
            "ip": ip ? `and ip_address='${ip}'` : "",
            "siteid": vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            "pageOffset": ((page - 1) * limit ) + limit,
            "pageSize": limit,
            "search":search ? search: "",
            "sort_column":sort ? sort : "priority,total_hits",
            "sort_order":sortOrder ? sortOrder : "desc",
            "class": threatClassName && nameIsVirusOrMalware ? `and msg ilike '%${threatClassName}%'` : threatClassName ? `and class='${threatClassName}'`: '',
            "action": action && action !== "" ? `and action='${action}'`:"",
        },
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, top10TheratTableData)
        .then((res) => handleResponse(res, dispatch, GET_TOP_10_THREAT_TABLE_DATA))
        .catch((err) => handleError(err, dispatch));
};


export const getAllThreatTableData = (vessels, startDate, endDate, severityType, page, limit, sort, sortOrder, search, window, threatName , deviceName, ip, threatClassName, action) => (dispatch) => {
    const avail_window = Math.ceil((Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3)) / 5);
    dispatch({ type: SET_THREAT_DASHBOARD_ALL_THREAT_TABLE_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    let nameIsVirusOrMalware = threatClassName && (threatClassName.toLowerCase().includes("malware") || threatClassName.toLowerCase().includes("virus"))
    const allTheratTableData: any = {
        query: 'SNORT_DASHBOARD_THREAT_LOG_V2',
        named: true,
        parameters: {
            "start_time": startDate,
            "end_time": endDate,
            "severity":  severityType && severityType !== "all "? `and severity='${severityType.toUpperCase()}'`:"",
            "name": threatName ? `and msg='${threatName}'` : "",
            "ip": ip ? `and ip_address='${ip}'` : "",
            "class": threatClassName && nameIsVirusOrMalware ? `and msg ilike '%${threatClassName}%'` : threatClassName ? `and class='${threatClassName}'`: '',
            "devicename": deviceName ? `and Device_Name='${deviceName}'` : "",
            "siteid": vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            "pageOffset": ((page - 1) * limit ) + limit,
            "pageSize": limit,
            "search":search ? search: "",
            "sort_column":sort ? sort : "priority asc,Attack_Time",
            "sort_order":sortOrder ? sortOrder : "desc",
            "action": action && action !== "" ? `and action='${action}'`:"",
        },
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, allTheratTableData)
        .then((res) => handleResponse(res, dispatch, GET_ALL_THREAT_TABLE_DATA))
        .catch((err) => handleError(err, dispatch));
};

export const getUserThreatTableData = (vessels, startDate, endDate, severityType, page, limit, sort, sortOrder, search,window, threatName , deviceName, ip, threatClassName, action) => (dispatch) => {
    const avail_window = Math.ceil((Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3)) / 5);
    dispatch({ type: SET_THREAT_DASHBOARD_USER_THREAT_TABLE_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    let nameIsVirusOrMalware = threatClassName && (threatClassName.toLowerCase().includes("malware") || threatClassName.toLowerCase().includes("virus"))
    const userTheratTableData: any = {
        query: 'SNORT_DASHBOARD_USER_VERNABILITY_TABLE_V2',
        named: true,
        parameters: {
            "start_time": startDate,
            "end_time": endDate,
            "severity": severityType && severityType !== "all "? `${severityType.toUpperCase()}`:"",
            "name": threatName ? `and msg='${threatName}'` : "",
            "ip": ip ? `and ip_address='${ip}'` : "",
            "class": threatClassName && nameIsVirusOrMalware ? `and msg ilike '%${threatClassName}%'` : threatClassName ? `and class='${threatClassName}'`: '',
            "devicename": deviceName ? `and Device_Name='${deviceName}'` : "",
            "siteid": vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            "pageOffset": ((page - 1) * limit ) + limit,
            "pageSize": limit,
            "search":search ? search: "",
            "sort_column":sort ? sort : "priority asc,Attack_Time",
            "sort_order":sortOrder ? sortOrder : "desc",
            "action": action && action !== "" ? `and action='${action}'`:"",
        },
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, userTheratTableData)
        .then((res) => handleResponse(res, dispatch, GET_USER_THREAT_TABLE_DATA))
        .catch((err) => handleError(err, dispatch));
};


export const getThreatTopWidgetTotal = (vessels, startDate, endDate, severityType, alertStatus,threatName , deviceName, ip, threatClassName, action) => (dispatch) => {
    dispatch({ type: SET_THREAT_DASHBOARD_TOPWIDGET_TOTAL_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    let nameIsVirusOrMalware = threatClassName && (threatClassName.toLowerCase().includes("malware") || threatClassName.toLowerCase().includes("virus"))
    const vesselMap: any = [];
    vessels?.map((item: any) => {
        let vesselName = item["id"];
        vesselMap.push(vesselName);
    })
    let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;
    const threatWidget: any = {
        query: 'SNORT_DASHBOARD_TOTAL_TOP_WIDGET_V2',
        named: true,
        format: "csv",
        "parameters": {
            "start_time": startDate,
            "end_time": endDate,
            "severity": severityType && severityType !== "all "? `${severityType.toUpperCase()}`:"",
            "name": threatName ? `and msg='${threatName}'` : "",
            "ip": ip ? `and ip_address='${ip}'` : "",
            "class": threatClassName && nameIsVirusOrMalware ? `and msg ilike '%${threatClassName}%'` : threatClassName ? `and class='${threatClassName}'`: '',
            "devicename": deviceName ? `and Device_Name='${deviceName}'` : "",
            "siteid": vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            "action": action && action !== "" ? `and action='${action}'`:"",
          },
    }
    api
        .postRequestOut(`/store/ch/query`, threatWidget)
        .then((res) => handleResponse(res, dispatch, GET_THREAT_DASHBOARD_TOPWIDGET_TOTAL))
        .catch((err) => handleError(err, dispatch));
};


export const getAllThreatSiteList = (vessels, startDate, endDate, severityType, search, threatName , deviceName, ip, threatClassName, action) => (dispatch) => {
    const avail_window = Math.ceil((Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3)) / 5);
    dispatch({ type: GET_THREAT_SITE_LIST_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    let nameIsVirusOrMalware = threatClassName && (threatClassName.toLowerCase().includes("malware") || threatClassName.toLowerCase().includes("virus"))
    console.log("nameIsVirusOrMalware",nameIsVirusOrMalware)
    const allTheratSiteList: any = {
        query: 'SNORT_DASHBOARD_THREAT_TABLE_SITE_LIST_V2',
        named: true,
        parameters: {
            "start_time": startDate,
            "end_time": endDate,
            "severity":  severityType && severityType !== "all "? `and severity='${severityType.toUpperCase()}'`:"",
            "name": threatName ? `and msg='${threatName}'` : "",
            "ip": ip ? `and ip_address='${ip}'` : "",
            "class": threatClassName && nameIsVirusOrMalware ? `and msg ilike '%${threatClassName}%'` : threatClassName ? `and class='${threatClassName}'`: '',
            "devicename": deviceName ? `and Device_Name='${deviceName}'` : "",
            "siteid": vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            "search":search ? search: "",
            "action": action && action !== "" ? `and action='${action}'`:"",
        },
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, allTheratSiteList)
        .then((res) => handleResponse(res, dispatch, GET_THREAT_SITE_LIST))
        .catch((err) => handleError(err, dispatch));
};

export const getAllThreatDeviceList = (vessels, startDate, endDate, severityType, search, threatName,deviceName, ip, threatClassName, action) => (dispatch) => {
    const avail_window = Math.ceil((Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3)) / 5);
    dispatch({ type: GET_THREAT_DEVICE_LIST_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    let nameIsVirusOrMalware = threatClassName && (threatClassName.toLowerCase().includes("malware") || threatClassName.toLowerCase().includes("virus"))
    const allTheratDeviceList: any = {
        query: 'SNORT_DASHBOARD_THREAT_TABLE_DEVICE_NAME_LIST',
        named: true,
        parameters: {
            "start_time": startDate,
            "end_time": endDate,
            "severity":  severityType && severityType !== "all "? `and severity='${severityType.toUpperCase()}'`:"",
            "name": threatName ? `and msg='${threatName}'` : "",
            "ip": ip ? `and ip_address='${ip}'` : "",
            "class": threatClassName && nameIsVirusOrMalware ? `and msg ilike '%${threatClassName}%'` : threatClassName ? `and class='${threatClassName}'`: '',
            "devicename": deviceName ? `and Device_Name='${deviceName}'` : "",
            "siteid": vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            "search":search ? search: "",
            "action": action && action !== "" ? `and action='${action}'`:"",
        },
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, allTheratDeviceList)
        .then((res) => handleResponse(res, dispatch, GET_THREAT_DEVICE_LIST))
        .catch((err) => handleError(err, dispatch));
};

export const getUniqueDeviceList = (vessels, startDate, endDate, severityType, search, threatName,deviceName, ip, threatClassName, action) => (dispatch) => {
    dispatch({ type: GET_THREAT_UNIQUE_DEVICE_LIST_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    let nameIsVirusOrMalware = threatClassName && (threatClassName.toLowerCase().includes("malware") || threatClassName.toLowerCase().includes("virus"))
    const threatUniqueDeviceList: any = {
        query: 'SNORT_DASHBOARD_DEVICE_NAME_FILTER',
        named: true,
        parameters: {
            "start_time": startDate,
            "end_time": endDate,
            "severity":  severityType && severityType !== "all "? `and severity='${severityType.toUpperCase()}'`:"",
            "name": threatName ? `and msg='${threatName}'` : "",
            "ip": ip ? `and ip_address='${ip}'` : "",
            "class": threatClassName && nameIsVirusOrMalware ? `and msg ilike '%${threatClassName}%'` : threatClassName ? `and class='${threatClassName}'`: '',
            "devicename": deviceName ? `and Device_Name='${deviceName}'` : "",
            "siteid": vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            "search":search ? search: "",
            "action": action && action !== "" ? `and action='${action}'`:"",
        },
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, threatUniqueDeviceList)
        .then((res) => handleResponse(res, dispatch, GET_THREAT_UNIQUE_DEVICE_LIST))
        .catch((err) => handleError(err, dispatch));
};

export const getUniqueNameList = (vessels, startDate, endDate, severityType, search, threatName,deviceName, ip, threatClassName, action) => (dispatch) => {
    dispatch({ type: GET_THREAT_UNIQUE_THREATNAME_LIST_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    let nameIsVirusOrMalware = threatClassName && (threatClassName.toLowerCase().includes("malware") || threatClassName.toLowerCase().includes("virus"))
    const threatUniqueThreatNameList: any = {
        query: 'SNORT_DASHBOARD_THREAT_NAME_FILTER',
        named: true,
        parameters: {
            "start_time": startDate,
            "end_time": endDate,
            "severity":  severityType && severityType !== "all "? `and severity='${severityType.toUpperCase()}'`:"",
            "name": threatName ? `and msg='${threatName}'` : "",
            "ip": ip ? `and ip_address='${ip}'` : "",
            "class": threatClassName && nameIsVirusOrMalware ? `and msg ilike '%${threatClassName}%'` : threatClassName ? `and class='${threatClassName}'`: '',
            "devicename": deviceName ? `and Device_Name='${deviceName}'` : "",
            "siteid": vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            "search":search ? search: "",
            "action": action && action !== "" ? `and action='${action}'`:"",
        },
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, threatUniqueThreatNameList)
        .then((res) => handleResponse(res, dispatch, GET_THREAT_UNIQUE_THREATNAME_LIST))
        .catch((err) => handleError(err, dispatch));
};

export const getUniqueIpList = (vessels, startDate, endDate, severityType, search, threatName,deviceName, ip, threatClassName, action) => (dispatch) => {
    dispatch({ type: GET_THREAT_UNIQUE_IP_LIST_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    let nameIsVirusOrMalware = threatClassName && (threatClassName.toLowerCase().includes("malware") || threatClassName.toLowerCase().includes("virus"))
    const threatUniqueIpList: any = {
        query: 'SNORT_DASHBOARD_IP_ADDRESS_FILTER',
        named: true,
        parameters: {
            "start_time": startDate,
            "end_time": endDate,
            "severity":  severityType && severityType !== "all "? `and severity='${severityType.toUpperCase()}'`:"",
            "name": threatName ? `and msg='${threatName}'` : "",
            "ip": ip ? `and ip_address='${ip}'` : "",
            "class": threatClassName && nameIsVirusOrMalware ? `and msg ilike '%${threatClassName}%'` : threatClassName ? `and class='${threatClassName}'`: '',
            "devicename": deviceName ? `and Device_Name='${deviceName}'` : "",
            "siteid": vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            "search":search ? search: "",
            "action": action && action !== "" ? `and action='${action}'`:"",
        },
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, threatUniqueIpList)
        .then((res) => handleResponse(res, dispatch, GET_THREAT_UNIQUE_IP_LIST))
        .catch((err) => handleError(err, dispatch));
};

export const getUniqueClassList = (vessels, startDate, endDate, severityType, search, threatName,deviceName, ip, threatClassName, action) => (dispatch) => {
    dispatch({ type: GET_THREAT_UNIQUE_CLASS_LIST_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    let nameIsVirusOrMalware = threatClassName && (threatClassName.toLowerCase().includes("malware") || threatClassName.toLowerCase().includes("virus"))
    const threatUniqueClassList: any = {
        query: 'SNORT_DASHBOARD_CLASS_FILTER',
        named: true,
        parameters: {
            "start_time": startDate,
            "end_time": endDate,
            "severity":  severityType && severityType !== "all "? `and severity='${severityType.toUpperCase()}'`:"",
            "name": threatName ? `and msg='${threatName}'` : "",
            "ip": ip ? `and ip_address='${ip}'` : "",
            "class": threatClassName && nameIsVirusOrMalware ? `and msg ilike '%${threatClassName}%'` : threatClassName ? `and class='${threatClassName}'`: '',
            "devicename": deviceName ? `and Device_Name='${deviceName}'` : "",
            "siteid": vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            "search":search ? search: "",
            "action": action && action !== "" ? `and action='${action}'`:"",
        },
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, threatUniqueClassList)
        .then((res) => handleResponse(res, dispatch, GET_THREAT_UNIQUE_CLASS_LIST))
        .catch((err) => handleError(err, dispatch));
};

export const getUniqueActionList = (vessels, startDate, endDate, severityType, search, threatName,deviceName, ip, threatClassName, action) => (dispatch) => {
    dispatch({ type: GET_THREAT_UNIQUE_ACTION_LIST_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    let nameIsVirusOrMalware = threatClassName && (threatClassName.toLowerCase().includes("malware") || threatClassName.toLowerCase().includes("virus"))
    const threatUniqueActionList: any = {
        query: 'SNORT_DASHBOARD_ACTION_FILTER',
        named: true,
        parameters: {
            "start_time": startDate,
            "end_time": endDate,
            "severity":  severityType && severityType !== "all "? `and severity='${severityType.toUpperCase()}'`:"",
            "name": threatName ? `and msg='${threatName}'` : "",
            "ip": ip ? `and ip_address='${ip}'` : "",
            "class": threatClassName && nameIsVirusOrMalware ? `and msg ilike '%${threatClassName}%'` : threatClassName ? `and class='${threatClassName}'`: '',
            "devicename": deviceName ? `and Device_Name='${deviceName}'` : "",
            "siteid": vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            "search":search ? search: "",
            "action": action && action !== "" ? `and action='${action}'`:"",
        },
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, threatUniqueActionList)
        .then((res) => handleResponse(res, dispatch, GET_THREAT_UNIQUE_ACTION_LIST))
        .catch((err) => handleError(err, dispatch));
};