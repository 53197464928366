import React from 'react';
import { Alert } from '@material-ui/lab';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import './CustomAlert.css'
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  cookieAlert: {
    "&  .MuiAlert-message": {
      textAlign: 'center',
      justifyContent: 'center',
      marginLeft: 'calc(50% - 215px/2 + 0.5px)'
    },
    "& .MuiAlert-icon	": {
      display: 'none'
    }
  },
  AlertMsg: {
    "&  .MuiAlert-message": {
      textAlign: 'center',
      justifyContent: 'center',
    },
    "& .MuiAlert-icon	": {
      display: 'none'
    }
  }
});


const  CustomAlert = ({ type, msg, handleClose }) => {
  const classes = useStyles();
  return (
    <Alert
      severity={type}
      variant="filled"
      className={handleClose ? classes.cookieAlert : classes.AlertMsg}
      onClose={handleClose}
    >
      {msg}
    </Alert>

  )
}

const mapStateToProps = (state) => ({
    //props made from redux store 
    authReducer: state.authReducer,
    errorReducer: state.errorReducer
});


export default withRouter(connect(mapStateToProps, {
    //All functions defined in action will come here

})(CustomAlert));