import { nanoid } from "nanoid";
import { Fragment, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";


import { Button, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, TextField, Checkbox, FormHelperText, Tooltip } from "@mui/material";
import { RemoveCircle } from '@mui/icons-material';


import { Action, Actions, AccessNetwork, WanProfile, TrafficPolicy, DeviceInterface, ActionType } from "../types";
import { AddCircle } from "@material-ui/icons";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import _ from "lodash";

interface QuotaActionsProps {
    deviceInterfaces: DeviceInterface[];
    accessNetworks?: AccessNetwork[];
    wanProfiles?: WanProfile[];
    trafficPolicies?: TrafficPolicy[];
    value: Action[];
    readOnly?: boolean;
    forRevert?: boolean;
    onChange?: (actions: Action[]) => void;
}

function QuotaActions(props: QuotaActionsProps) {

    const { deviceInterfaces, accessNetworks, wanProfiles, trafficPolicies, value, readOnly, forRevert, onChange } = props;

    const [quotaActions, setQuotaActions] = useState<Action[]>(value)

    const addQuotaAction = () => {
        quotaActions.push({
            id: nanoid(),
            usage_percentage: 10,
            type: Actions.ActionUndefined,
            executed: false,
            revert: forRevert
        })
        let _quotaActions = [...quotaActions]
        if (onChange) onChange(_quotaActions)
    }

    const deleteQuotaAction = (id: string) => () => {
        quotaActions.splice(quotaActions.findIndex(condition => condition.id === id), 1)
        let _quotaActions = [...quotaActions]
        if (onChange) onChange(_quotaActions)
    }

    const changeQuotaAction = (id: string) => (condition: Action) => {
        let _quotaActions = [...quotaActions]
        _quotaActions[_quotaActions.findIndex(condition => condition.id === id)] = condition
        if (onChange) onChange(_quotaActions)
    }

    useEffect(() => {
        setQuotaActions(value)
    }, [value])

    return (
        <Fragment>
            {quotaActions.map(condition => <QuotaAction condition={condition} key={condition.id} deviceInterfaces={deviceInterfaces} accessNetworks={accessNetworks} wanProfiles={wanProfiles} trafficPolicies={trafficPolicies}
                onChange={changeQuotaAction(condition.id)} removeComp={
                    <IconButton aria-label="remove condition" onClick={deleteQuotaAction(condition.id)} disabled={readOnly}>
                        <RemoveCircle />
                    </IconButton>
                } readOnly={readOnly} />)}
            {
                !readOnly && <Button variant="outlined" size="small" startIcon={<AddCircle />} className="quota_management--button_outlined--primary" onClick={addQuotaAction} >
                    ADD ACTION
                </Button>
            }
        </Fragment>
    )
}

interface QuotaActionProps {
    deviceInterfaces: DeviceInterface[];
    condition: Action;
    removeComp: JSX.Element;
    accessNetworks?: AccessNetwork[];
    wanProfiles?: WanProfile[];
    trafficPolicies?: TrafficPolicy[];
    readOnly?: boolean;
    onChange?: (action: Action) => void;
}

function QuotaAction(props: QuotaActionProps) {

    const { condition, deviceInterfaces, removeComp, accessNetworks, wanProfiles, trafficPolicies, readOnly, onChange } = props;

    const [exhaustPercent, setExhaustPercent] = useState<number>(condition.usage_percentage)
    const [actionOnExhaust, setActionOnExhaust] = useState<ActionType>(condition.type)
    const [accessNetworkIds, setAccessNetworkIds] = useState<string[]>(condition.access_networks || [])
    const [wanProfileId, setWanProfileId] = useState<string>(condition.wan_profile_id || '')
    const [trafficPolicyId, setTrafficPolicyId] = useState<string>(condition.traffic_policy_id || '')
    const [interfaces, setInterfaces] = useState<string[]>(condition.interfaces || [])

    const handleChangeExhaustPercent = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.valueAsNumber < 1) {
            setExhaustPercent(1)
        } else {
            setExhaustPercent(event.target.valueAsNumber)
        }
    }

    const handleChangeActionOnExhaust = (event: SelectChangeEvent) => {
        const _action = event.target.value as ActionType
        setActionOnExhaust(_action)
        if (_action === Actions.ActionAssignWanProfile || _action === Actions.ActionAssignTrafficPolicy) {
            setWanProfileId("")
            setTrafficPolicyId("")
            setAccessNetworkIds([])
        }
        if (_action === Actions.ActionDisableInterface) {
            setInterfaces([])
        }
    }

    const handleChangeAccessNetworkIds = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event;
        let _value = typeof value === 'string' ? value.split(',') : value
        _value = _value.filter(id => accessNetworks?.find(network => network.id === id))
        setAccessNetworkIds(_value);
    }

    const handleChangeWanProfileId = (event: SelectChangeEvent) => {
        setWanProfileId(event.target.value as string)
    }

    const handleChangeTrafficPolicyId = (event: SelectChangeEvent) => {
        setTrafficPolicyId(event.target.value as string)
    }

    const handleChangeInterfaces = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event;
        setInterfaces(typeof value === 'string' ? value.split(',') : value);
    }

    const invalidWanProfile = useMemo(() => {
        if (actionOnExhaust === Actions.ActionAssignWanProfile) {
            return !wanProfiles?.find(profile => profile.id === wanProfileId)
        }
        return false
    }, [actionOnExhaust, wanProfileId, wanProfiles])

    const invalidTrafficPolicy = useMemo(() => {
        if (actionOnExhaust === Actions.ActionAssignTrafficPolicy) {
            return !trafficPolicies?.find(policy => policy.id === trafficPolicyId)
        }
        return false
    }, [actionOnExhaust, trafficPolicyId, trafficPolicies])

    const invalidAccessNetworks = useMemo(() => {
        if (actionOnExhaust === Actions.ActionAssignWanProfile || actionOnExhaust === Actions.ActionAssignTrafficPolicy) {
            return accessNetworkIds.filter(id => !accessNetworks?.find(network => network.id === id))
        }
        return []
    }, [actionOnExhaust, accessNetworkIds, accessNetworks])

    const invalidInterfaces = useMemo(() => {
        if (actionOnExhaust === Actions.ActionDisableInterface) {
            return interfaces.filter(alias => !deviceInterfaces?.find(inf => inf.alias === alias))
        }
        return []
    }, [actionOnExhaust, interfaces, deviceInterfaces])

    useEffect(() => {
        !readOnly && onChange && onChange({
            ...condition,
            usage_percentage: exhaustPercent,
            type: actionOnExhaust,
            access_networks: accessNetworkIds,
            wan_profile_id: wanProfileId,
            traffic_policy_id: trafficPolicyId,
            interfaces: interfaces,
            executed: condition.executed || false,
            valid:
                actionOnExhaust !== Actions.ActionUndefined &&
                !invalidWanProfile &&
                !invalidTrafficPolicy &&
                invalidAccessNetworks.length === 0 &&
                invalidInterfaces.length === 0 &&
                (
                    (
                        actionOnExhaust === Actions.ActionAssignWanProfile &&
                        wanProfileId !== "" && accessNetworkIds.length !== 0
                    ) ||
                    (
                        actionOnExhaust === Actions.ActionAssignTrafficPolicy &&
                        trafficPolicyId !== "" && accessNetworkIds.length !== 0
                    ) ||
                    (
                        actionOnExhaust === Actions.ActionDisableInterface &&
                        interfaces.length !== 0
                    ) ||
                    (
                        actionOnExhaust === Actions.ActionNotification
                    )
                )
        })
    }, [exhaustPercent, actionOnExhaust, accessNetworkIds, wanProfileId, trafficPolicyId, interfaces, invalidWanProfile, invalidTrafficPolicy, invalidAccessNetworks, invalidInterfaces])

    useEffect(() => {
        if (actionOnExhaust === Actions.ActionNotification) {
            setAccessNetworkIds([])
            setWanProfileId('')
            setTrafficPolicyId('')
            setInterfaces([])
        }
        if (actionOnExhaust === Actions.ActionAssignWanProfile || actionOnExhaust === Actions.ActionAssignTrafficPolicy) {
            setWanProfileId(condition.wan_profile_id || "")
            setTrafficPolicyId(condition.traffic_policy_id || "")
            setAccessNetworkIds(condition.access_networks || [])
            setInterfaces([])
        }
        if (actionOnExhaust === Actions.ActionDisableInterface) {
            setInterfaces(condition.interfaces || [])
            setWanProfileId('')
            setTrafficPolicyId('')
            setAccessNetworkIds([])
        }
    }, [actionOnExhaust])

    return (
        <Grid classes={{ root: 'quota_management--base--margin_1x' }}>
            <Grid container spacing={0.5}>
                <Grid item xs={12} md={condition.revert ? 3 : 4}>
                    <Grid container spacing={0.5}>
                        {
                            !condition.revert && <Grid item xs={12} md={4}>
                                <FormControl fullWidth>
                                    <TextField
                                        size="small"
                                        label="% used"
                                        variant="outlined"
                                        value={exhaustPercent}
                                        type="number"
                                        onChange={handleChangeExhaustPercent}
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                        InputProps={{
                                            readOnly: readOnly,
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={12} md={condition.revert ? 12 : 8}>
                            <FormControl fullWidth size="small" error={(_.isEmpty(deviceInterfaces) && _.isEmpty(wanProfiles) && _.isEmpty(trafficPolicies)) || actionOnExhaust === Actions.ActionUndefined}>
                                <InputLabel id="action-on-exhaust-label">Action</InputLabel>
                                <Select
                                    value={actionOnExhaust}
                                    onChange={handleChangeActionOnExhaust}
                                    labelId="action-on-exhaust-label"
                                    label="Action"
                                    readOnly={readOnly}
                                >
                                    {!_.isEmpty(wanProfiles) && <MenuItem value={Actions.ActionAssignWanProfile}>Assign WAN Profile</MenuItem>}
                                    {!_.isEmpty(trafficPolicies) && <MenuItem value={Actions.ActionAssignTrafficPolicy}>Assign Traffic Policy</MenuItem>}
                                    {
                                        !_.isEmpty(deviceInterfaces) ? condition.revert ? <MenuItem value={Actions.ActionEnableInterfaces}>Enable Interfaces</MenuItem> : <MenuItem value={Actions.ActionDisableInterface}>Disable Interfaces</MenuItem> : null
                                    }
                                    {
                                        !condition.revert ? <MenuItem value={Actions.ActionNotification}>Send Notification</MenuItem> : null
                                    }
                                </Select>
                                {
                                    _.isEmpty(deviceInterfaces) && _.isEmpty(wanProfiles) && _.isEmpty(trafficPolicies) ? <FormHelperText>Device data not available</FormHelperText> :
                                        actionOnExhaust === Actions.ActionUndefined ? <FormHelperText>Action is required</FormHelperText> : null
                                }
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    (actionOnExhaust === Actions.ActionAssignWanProfile || actionOnExhaust === Actions.ActionAssignTrafficPolicy) ? <Grid item xs={12} md={7}>
                        <Grid container spacing={0.5}>
                            {
                                actionOnExhaust === Actions.ActionAssignWanProfile && <Grid item xs={12} md={6}>
                                    <FormControl fullWidth size="small" error={invalidWanProfile}>
                                        <InputLabel id="wan-profile-label">WAN Profile</InputLabel>
                                        <Select
                                            value={wanProfileId}
                                            onChange={handleChangeWanProfileId}
                                            labelId="wan-profile-label"
                                            label="WAN Profile"
                                            readOnly={readOnly}
                                        >
                                            {wanProfiles && wanProfiles.map(profile => <MenuItem key={profile.id} value={profile.id}>{profile.name}</MenuItem>)}
                                        </Select>
                                        {
                                            invalidWanProfile ? wanProfileId === "" ? <FormHelperText> WAN profile is required</FormHelperText> : <FormHelperText>Selected wan profile does not exist, please reselect the wan profile</FormHelperText> : null
                                        }
                                    </FormControl>
                                </Grid>
                            }
                            {
                                actionOnExhaust === Actions.ActionAssignTrafficPolicy && <Grid item xs={12} md={6}>
                                    <FormControl fullWidth size="small" error={invalidTrafficPolicy}>
                                        <InputLabel id="traffic-policy-label">Traffic Policy</InputLabel>
                                        <Select
                                            value={trafficPolicyId}
                                            onChange={handleChangeTrafficPolicyId}
                                            labelId="traffic-policy-label"
                                            label="Traffic Policy"
                                            readOnly={readOnly}
                                        >
                                            {trafficPolicies && trafficPolicies.map(policy => <MenuItem key={policy.id} value={policy.id}>{policy.name}</MenuItem>)}
                                        </Select>
                                        {
                                            invalidTrafficPolicy ? trafficPolicyId === "" ? <FormHelperText>Traffic policy is required</FormHelperText> : <FormHelperText>Selected traffic policy does not exist, please reselect the traffic policy</FormHelperText> : null
                                        }
                                    </FormControl>
                                </Grid>
                            }
                            {
                                (actionOnExhaust === Actions.ActionAssignWanProfile || actionOnExhaust === Actions.ActionAssignTrafficPolicy) && <Grid item xs={12} md={6}>
                                    <FormControl fullWidth size="small" error={invalidAccessNetworks?.length > 0 || accessNetworkIds?.length === 0}>
                                        <InputLabel id="access-network-label">Access Networks</InputLabel>
                                        <Select
                                            multiple
                                            value={accessNetworkIds}
                                            onChange={handleChangeAccessNetworkIds}
                                            labelId="access-network-label"
                                            label="Access Networks"
                                            renderValue={(selected) => {
                                                let _anName = new Map(accessNetworks?.map(an => [an.id, an.name]))
                                                return (selected as string[]).map(id => _anName.get(id) || '<Deleted>').join(', ')
                                            }}
                                            readOnly={readOnly}
                                        >
                                            {accessNetworks && accessNetworks.map(network => <MenuItem key={network.id} value={network.id}>
                                                <Checkbox size='small' checked={accessNetworkIds.indexOf(network.id) > -1} />
                                                <ListItemText primary={network.name} />
                                            </MenuItem>)}
                                            {
                                                invalidAccessNetworks?.length > 0 ? invalidAccessNetworks.map(id => <MenuItem key={id} value={id}>
                                                    <Checkbox size='small' checked={accessNetworkIds.indexOf(id) > -1} />
                                                    <ListItemText primary={'<Deleted>'} />
                                                </MenuItem>) : null
                                            }
                                        </Select>
                                        {
                                            invalidAccessNetworks?.length > 0 ? <FormHelperText>Selected access networks do not exist, please reselect the access networks</FormHelperText> : accessNetworkIds.length === 0 ? <FormHelperText>Access networks are required</FormHelperText> : null
                                        }
                                    </FormControl>
                                </Grid>
                            }
                        </Grid>
                    </Grid> : (actionOnExhaust === Actions.ActionDisableInterface || actionOnExhaust === Actions.ActionEnableInterfaces) && !_.isEmpty(deviceInterfaces) && <Grid item xs={12} md={7}>
                        <FormControl fullWidth size="small" error={invalidInterfaces?.length > 0 || interfaces?.length === 0}>
                            <InputLabel id="interface-label">Interfaces</InputLabel>
                            <Select
                                multiple
                                value={interfaces}
                                onChange={handleChangeInterfaces}
                                labelId="interface-label"
                                label="Interfaces"
                                renderValue={(selected) => selected.join(', ')}
                                readOnly={readOnly}
                            >
                                {deviceInterfaces.map(inf => <MenuItem key={inf.alias} value={inf.alias}>
                                    <Checkbox size='small' checked={interfaces.indexOf(inf.alias) > -1} />
                                    <ListItemText primary={inf.alias} />
                                </MenuItem>)}
                                {
                                    invalidInterfaces?.length > 0 ? invalidInterfaces.map(alias => <MenuItem key={alias} value={alias}>
                                        <Checkbox size='small' checked={interfaces.indexOf(alias) > -1} />
                                        <ListItemText primary={`${alias} <Deleted>`} />
                                    </MenuItem>) : null
                                }
                            </Select>
                            {
                                invalidInterfaces?.length > 0 ? <FormHelperText>Selected interfaces ({invalidInterfaces?.join(', ')}) do not exist, please reselect the interfaces</FormHelperText> : interfaces.length === 0 ? <FormHelperText>Interfaces are required</FormHelperText> : null
                            }
                        </FormControl>
                    </Grid>
                }

                <Grid item xs={12} md={1} display={'flex'} gap={0.25} alignItems={'center'}>
                    {
                        condition.executed ? <Tooltip title={"Action executed in current quota period"} placement="top">
                            <CheckCircleIcon color={'success'} />
                        </Tooltip> : null
                    }
                    {removeComp}
                </Grid>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    accessNetworks: state.quotaManager.accessNetworks,
    wanProfiles: state.quotaManager.wanProfiles,
    trafficPolicies: state.quotaManager.trafficPolicies
});

export default connect(mapStateToProps, {})(QuotaActions)