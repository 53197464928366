import { api } from "../../server/request";

import { LOGIN_USER, SET_LOADING, DELETE_ALERT, DELETE_ALERT_LOADING } from '../../actions/types'
import _ from "lodash";
import { toast } from "react-toastify"; 
import { handleError, handleResponseObj } from "../../actions/responseUtil";

export const ACTION_TYPES = {
    GET_ALERTS: "alertDashboard/getAlerts",
    GET_ALERTS_LABELS: "alertDashboard/Alerts/labels",
    UPDATE_ALERT: "alertsDashboard/updateAlert",
    GET_ALERTS_HISTORY: "alertsDashboard/Alerts/getAlertsStateHostory",
    GET_ALERTCHILD_DETAILS: "alertsDashboard/Alerts/getAlertChildDetails",
    DELETE_ALERT: "alertsDashboard/deleteAlert",
    DELETE_ALERT_RULE: "alertsDashboard/deleteAlertRule",
    RERENDER_ALERT: "alertsDashboard/rerenderAlerts",
    GET_ALERTS_MAIN: "alertDashboard/getAlertsMain",
    ALERTS_MAIN_INFO: "alertDashboard/getAlertsMainInfo",
    ALERTS_MAIN_INFO_LOADING: 'getAlertsMainInfoLoading'
}

const initialState = {
    alerts: [],
    alertLabels: {},
    alertCountDetails: {},
    alertHistoryNew: {},
    alertChildDetails: {},
    alertDeleteResponse: {},
    alertUpdateResponse: {},
    deleteAlertRuleResponse: {},
    alertMainDetails: {},
    alertsMainInfo: {},
    alertsMainInfoLoading: false,
}

export function alertsDashboardReducer(state: any = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.GET_ALERTS: {
            return {
                ...state,
                alerts: action.payload,
                alertCountDetails: action.payload?.status_counts,
            }
        }
        case ACTION_TYPES.GET_ALERTS_LABELS: {
            return {
                ...state,
                alertLabels: action.payload
            }
        }
        case ACTION_TYPES.GET_ALERTS_HISTORY: {
            return {
                ...state,
                alertHistoryNew: action.payload
            }
        }
        case ACTION_TYPES.DELETE_ALERT: {
            return {
                ...state,
                alertDeleteResponse: action.payload
            }
        }
        case ACTION_TYPES.DELETE_ALERT_RULE: {
            return {
                ...state,
                deleteAlertRuleResponse: action.payload
            }
        }
        case ACTION_TYPES.GET_ALERTCHILD_DETAILS: {
            let alertsChild = {...state?.alertChildDetails}
            alertsChild[action.payload.id] = action.payload;
            return {
                ...state,
                alertChildDetails: alertsChild
            }
        }
        case ACTION_TYPES.UPDATE_ALERT: {
            let alertIndex = state.alerts.alerts.findIndex((alert: any) => alert.id === action.payload.id)
            state.alerts.alerts[alertIndex] = _.merge(state.alerts.alerts[alertIndex], action.payload);
            delete state.alerts.alerts[alertIndex].success
            delete state.alerts.alerts[alertIndex].result
            delete state.alerts.alerts[alertIndex].error
            return {
                ...state,
                alerts: _.cloneDeep(state.alerts),
                alertUpdateResponse: action.payload
            }
        }
        case ACTION_TYPES.RERENDER_ALERT: {
            return {
                ...state,
                alerts: _.cloneDeep(state.alerts)
            }
        }
        case ACTION_TYPES.GET_ALERTS_MAIN: {
            return {
                ...state,
                alertMainDetails:action.payload
            }
        }
        case ACTION_TYPES.ALERTS_MAIN_INFO_LOADING: {
            return {
                ...state,
                alertsMainInfoLoading: action.payload
            }
        }
        case ACTION_TYPES.ALERTS_MAIN_INFO: {
            return {
                ...state,
                alertsMainInfo: action.payload
            }
        }
        case LOGIN_USER:{
            return {
                ...initialState,
                loginUser: action.payload,
              }
        }
        default:
            return state
    }
}

export const getAlerts = (siteId, alertStatusFilter, alertLabelKey,pageNumber, rowsPerPage,alertId) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    let alertStatus = alertStatusFilter ? alertStatusFilter : "";
    let alertLabelK = alertLabelKey ? alertLabelKey : "";
    let URL = `/v1/sites/${siteId}/alerts?alert_id=${alertId}&status=${alertStatus}&search=${alertLabelK}&page=${pageNumber}&size=${rowsPerPage}`;
    api.siteController.get(URL)
        .then((res) => {
            dispatch({ type: SET_LOADING, payload: false })
            dispatch({ type: ACTION_TYPES.GET_ALERTS, payload: res?.result })
            dispatch({ type: ACTION_TYPES.DELETE_ALERT, payload: "" })
        })
        .catch((err) => {
            dispatch({ type: SET_LOADING, payload: false })
        });
};

export const getAlertsMain = (pageNumber, pageSize, searchlKey,currentAlertStatus,startDate,endDate, vesselIds) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.ALERTS_MAIN_INFO_LOADING, payload: true });
    const vesselMap: any = [];
    vesselIds?.map((item: any) => {
      let vesselName = item["id"];
      vesselMap.push(vesselName);
    })
    let URL = `/v1/sites/alerts/status_history`;
    let isCurrentAlert = currentAlertStatus.includes("isCurrentAlert")
  
    api.siteController.post(URL, {
            "site_id": vesselMap,
            "status": isCurrentAlert ? [] :currentAlertStatus,
            "created_after": startDate? startDate:null,
            "created_before": endDate?endDate:null,
            "search": searchlKey,
            "page": pageNumber,
            "size": pageSize,
            "currently_alerting":isCurrentAlert
    })
        .then((res) => {
            handleSpecialAction(res?.result, dispatch,ACTION_TYPES.GET_ALERTS_MAIN, "Not able to fetch alert details")
            dispatch({ type: ACTION_TYPES.ALERTS_MAIN_INFO, payload: res.result_info})
            dispatch({ type: ACTION_TYPES.ALERTS_MAIN_INFO_LOADING, payload: false})
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.ALERTS_MAIN_INFO_LOADING, payload: false })
        });
};

export const getAlertsLabel = (siteId) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    let URL = `/v1/sites/${siteId}/alerts/labels`;
    api.siteController.get(URL)
        .then((res) => {
            dispatch({ type: SET_LOADING, payload: false })
            dispatch({ type: ACTION_TYPES.GET_ALERTS_LABELS, payload: res?.result })
        })
        .catch((err) => {
            dispatch({ type: SET_LOADING, payload: false })
        });
};

export const updateAlert = (siteId, alert) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    let URL = `/v1/sites/${siteId}/alerts`;
    api.siteController.put(URL, {
        Alert: alert
    })
        .then((res) => {
            handleSpecialAction({...res, ...alert}, dispatch,ACTION_TYPES.UPDATE_ALERT, "Cannot Update Default Alert")
        })
        .catch((err) => {
            dispatch({ type: SET_LOADING, payload: false })
        });
};

export const getAlertsStateHostory = (siteId, alertsHistoryId, selectedRuleIdForHostory) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    let ruleId = selectedRuleIdForHostory ? `rule_id=${selectedRuleIdForHostory}&` : ""
    let URL = `/v1/sites/${siteId}/alerts/${alertsHistoryId}/status_history?${ruleId}status=Alerting,Normal&page=1&size=15`;
    api.siteController.get(URL)
        .then((res) => {
            dispatch({ type: SET_LOADING, payload: false })
            dispatch({ type: ACTION_TYPES.GET_ALERTS_HISTORY, payload: res?.result })
        })
        .catch((err) => {
            dispatch({ type: SET_LOADING, payload: false })
        });
}

export const getAlertChildInfo = (siteId, selectedRuleIdForHostory) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    let ruleId = selectedRuleIdForHostory ? selectedRuleIdForHostory : ""
    let URL = `/v1/sites/${siteId}/alerts/${ruleId}`;
    api.siteController.get(URL)
        .then((res) => {
            console.log("child response",res);
            dispatch({ type: SET_LOADING, payload: false })
            dispatch({ type: ACTION_TYPES.GET_ALERTCHILD_DETAILS, payload: res?.result })
        })
        .catch((err) => {
            dispatch({ type: SET_LOADING, payload: false })
        });
}

const handleSpecialAction = (res, dispatch, action , meesage) => {
    if (res.data && res.data.success == false) {
      toast.error(meesage, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      dispatch({ type: SET_LOADING, payload: false })
      dispatch({type: ACTION_TYPES.RERENDER_ALERT})
    } else {
        dispatch({ type: SET_LOADING, payload: false })
        dispatch({ type: action, payload: res })
    }
  }

export const deleteAlerts = (siteId, alertId) => async (dispatch) => {
    dispatch({ type: DELETE_ALERT_LOADING, payload: true });
    let URL = `/v1/sites/${siteId}/alerts`;
    let formData = { 'id':alertId}
    try {
        let res = await api.siteController.delete(URL, formData);
        dispatch({ type: DELETE_ALERT_LOADING, payload: false })
        handleResponseObj(res, dispatch, DELETE_ALERT)
      } catch (error) {
        dispatch({ type: DELETE_ALERT_LOADING, payload: false })
        handleError(error, dispatch)
      }
}

export const deleteAlertsRule = (siteId, alertId, ruleId) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    let URL = `/client/v1/sites/${siteId}/alerts/${alertId}/rule/${ruleId}`;
    let formData = {};
    api.deleteRequest(URL, formData)
    .then((res) =>  {
        handleSpecialAction(res, dispatch,ACTION_TYPES.DELETE_ALERT_RULE, "Cannot Delete Default Alert Rule")
    })
    .catch((err) => {
        dispatch({ type: SET_LOADING, payload: false })
    })
}
