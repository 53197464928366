import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Stack, TextField } from "@mui/material";
import close from "../../asset/image/close.svg";
import DP_Dropdown from "../../components/InventoryWarehouseTable/DP_Dropdown";
import SelectTag from "../../components/InventoryWarehouseTable/SelectTag";
import { getDecodeURI, getEncodedURI, getIntervalCheck, getTimezoneCity, strToK4List } from "../../utils/util";
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Moment } from "moment-timezone";
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import _ from "lodash";
import { fetchResources } from '../../actions/Users/authenticate';
import { toast } from "react-toastify";
import { UPDATE_NEW_SUMMARY_END_DATE, UPDATE_NEW_SUMMARY_START_DATE } from "../../actions/types";
import { createDownloadServiceContentFilter } from "../../actions/Users/authenticateReports";

const GenerateReportPopup = (props) => {
    const { authReducer, generateFilter, setGenerateFilter, fetchResources, handleClickFilterPopup, setFilterPopup, location, history, createDownloadServiceContentFilter, usersCount, sitesCount } = props;
    let _q = getDecodeURI(location?.search);
    const [startDate, setStartdate] = useState<any>(_q.hasOwnProperty("startDate") ? moment(new Date(parseInt(_q.startDate))) : moment(Date.now()).subtract(1, 'hour'));
    const [endDate, setEnddate] = useState<any>(_q.hasOwnProperty("endDate") ? moment(new Date(parseInt(_q.endDate))) : moment(Date.now()));
    const [sites, setSites] = useState<any>(_q.hasOwnProperty('k4Ids') && _q.k4Ids != '' ? [...strToK4List(_q.k4Ids)?.map((ele) => { return { id: ele["id"], name: ele['vessel-name'] } })] : []);
    const [sitesList, setSitesList] = useState<any>([]);
    const [selectedFilter, setSelectedFilter] = useState<any>(
        {
            user : _q.hasOwnProperty('user') ? _q.user : '',
            category : _q.hasOwnProperty('category') ? decodeURIComponent(_q.category) : '',
            app : _q.hasOwnProperty('app') ? _q.app : '',
            vlan : _q.hasOwnProperty('vlan') ? _q.vlan : '',
            device : _q.hasOwnProperty('device') ? _q.device : '',
            subCat : _q.hasOwnProperty('subCat') ? _q.subCat : '',
        }
    );
    const [selectedOu, setSelectedOu] = useState<any>({});
    const [serviceName, setServiceName] = useState("")
    const [serviceFunction, setServiceFunction] = useState("");
    const [enableDPI, setEnableDPI] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [appList, setAppList] = useState([]);
    const [userList, setUserList] = useState<any>([]);
    const [vlanList, setVlanList] = useState([]);
    const [deviceList, setDeviceList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);
    const dispatch = useDispatch();
    const [format, setFormat] = useState('xlsx');

    useEffect(()=> {
        const service = JSON.parse(localStorage.getItem('permissions') || '');
        service?.map((list)=>{
          if(list.service == 'iam'){
            setServiceName(list.service)
            setServiceFunction(list?.servicefunctions[0]?.function)
          }
        })
        handleClickFilterPopup();
        setFilterPopup(false);
    },[])

    useEffect(() => {
        if(!_.isEmpty(authReducer.getContentFilterCategoryList)) {
            const categories = authReducer.getContentFilterCategoryList.hasOwnProperty('data') ? authReducer.getContentFilterCategoryList.data : {};
            const categoryList = categories?.rows?.map((item) => {
                return categories?.columns?.reduce((acc, columItem, i) => {
                    acc[columItem] = item[i];
                    return acc;
                }, {})
            })
            setCategoryList(Array.from(new Set(categoryList?.map(value => value.category))))
        }
        if(!_.isEmpty(authReducer.getContentFilterAppList)) {
            const apps = authReducer.getContentFilterAppList.hasOwnProperty('data') ? authReducer.getContentFilterAppList.data : {};
            const appList = apps?.rows?.map((item) => {
                return apps?.columns?.reduce((acc, columItem, i) => {
                    acc[columItem] = item[i];
                    return acc;
                }, {})
            })
            setAppList(Array.from(new Set(appList?.map(value => value.domain))))
        }
        if(!_.isEmpty(authReducer.getContentFilterUserList)) {
            const users = authReducer.getContentFilterUserList.hasOwnProperty('data') ? authReducer.getContentFilterUserList.data : {};
            const userList = users?.rows?.map((item) => {
                return users?.columns?.reduce((acc, columItem, i) => {
                    acc[columItem] = item[i];
                    return acc;
                }, {})
            })
            setUserList(Array.from(new Set(userList?.map(value => value.username))))
        }
        if(!_.isEmpty(authReducer.getContentFilterVlanList)) {
            const vlans = authReducer.getContentFilterVlanList.hasOwnProperty('data') ? authReducer.getContentFilterVlanList.data : {};
            const vlanList = vlans?.rows?.map((item) => {
                return vlans?.columns?.reduce((acc, columItem, i) => {
                    acc[columItem] = item[i];
                    return acc;
                }, {})
            })
            setVlanList(Array.from(new Set(vlanList?.map(value => value.name))))
        }
        if(!_.isEmpty(authReducer.getContentFilterDeviceList)) {
            const devices = authReducer.getContentFilterDeviceList.hasOwnProperty('data') ? authReducer.getContentFilterDeviceList.data : {};
            const deviceList = devices?.rows?.map((item) => {
                return devices?.columns?.reduce((acc, columItem, i) => {
                    acc[columItem] = item[i];
                    return acc;
                }, {})
            })
            setDeviceList(Array.from(new Set(deviceList?.map(value => value.Device_Name))))
        }
        if(!_.isEmpty(authReducer.getContentFilterSubCategoryList)) {
            const devices = authReducer.getContentFilterSubCategoryList.hasOwnProperty('data') ? authReducer.getContentFilterSubCategoryList.data : {};
            const deviceList = devices?.rows?.map((item) => {
                return devices?.columns?.reduce((acc, columItem, i) => {
                    acc[columItem] = item[i];
                    return acc;
                }, {})
            })
            setSubCategoryList(Array.from(new Set(deviceList?.map(value => value.subcategory))))
        }
    }, [authReducer.getContentFilterCategoryList,
        authReducer.getContentFilterAppList,
        authReducer.getContentFilterUserList,
        authReducer.getContentFilterVlanList,
        authReducer.getContentFilterDeviceList,
        authReducer.getContentFilterSubCategoryList])

    const hanldeChangeSelect = (e, newValue, key) => {
        newValue ? setSelectedFilter({ ...selectedFilter, [key]: newValue }) : setSelectedFilter({...selectedFilter, [key]: ''});
    }

    const handleStartDatePicker = (date: Moment | null) => {
        if(date){
            setStartdate(date);
        }
    }

    const handleEndDatePicker = (date: Moment | null) => {
        if(date) {
            setEnddate(date);
        }
    }

    const handleOu = (ou: any) => {
        setSelectedOu(ou);
        if(authReducer?.selectedOu?.name != ou.name) {
            setSites([{ name:"All Sites", type:"Site" }])
        }
        fetchResources(ou.name, 0, 500, serviceName, serviceFunction);
    }

    useEffect(() => {
        if (!_.isEmpty(authReducer?.fetchResources)) {
            let resourceRecords = authReducer.fetchResources?.locations;
            if (resourceRecords && Array.isArray(resourceRecords)) {
                let resourceSort = resourceRecords.map((index, i) => index.name);
                resourceSort.sort();
                let sortedResourceNames: any[] = resourceRecords.sort((a: any, b: any) => {
                  return ('' + a.name).localeCompare(b.name);
                })
                let externalAllVesselObj = {name: "All Sites", description: "", type: "Site", is_all: true}
                sortedResourceNames?.unshift(externalAllVesselObj);
                let defaultAllVessel =  "All Sites"
                let all_sites = sortedResourceNames.find(item => item.name === defaultAllVessel)
                sortedResourceNames = sortedResourceNames.filter(item => item.name !== defaultAllVessel)
                if(all_sites){
                    sortedResourceNames.unshift(all_sites);
                    !_q.hasOwnProperty('k4Ids') && setSites([all_sites]);
                }
                setSitesList(sortedResourceNames);
            } else {
                setSitesList([]);
            }
            authReducer.fetchResources = {};
        }
    }, [authReducer.fetchResources]);

    const handleChangeSite = (e, newValue) => {
        setSites([...newValue]);
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const handleClose = () => {
        setGenerateFilter(false);
        if(_q.hasOwnProperty('filterClick')) {
            let params: any = getDecodeURI(location?.search);
            delete params.filterClick;
            doNavigate(params);
        }
        else if(_q.hasOwnProperty('startDate')) {
            const start = moment(Date.now()).utc().subtract(1, 'hour');
            const end = moment(Date.now()).utc();
            dispatch({ type: UPDATE_NEW_SUMMARY_END_DATE, payload: end})
            dispatch({ type: UPDATE_NEW_SUMMARY_START_DATE, payload: start})
            let params: any = getDecodeURI(location.search);
            delete params.startDate;
            delete params.endDate;
            params.interval = '1h';
            doNavigate(params);
        }
    }

    const getIntervalBin = (minutes) => {
        if (minutes <= 1440) {
          return "15m";
        } else if (minutes > 1440 && minutes <= 10080) {
          return "1h";
        } else {
          return "4h";
        }
    }

    const handleClickGenerate = () => {
        let dpId = selectedOu?.id
        if (dpId === undefined) {
            toast.error('Please select an Organization', {
                position: toast.POSITION.BOTTOM_LEFT,
            });
            return;
        }
        const payload = {
            "filters": {
                "site_ids": sites.length > 0 && sites[0].name !== 'All Sites' ? sites?.map((site: any) => site.id) : sitesList?.filter((site) => site.name !== 'All Sites')?.map((site: any) => site.id),
                "end_time": endDate.toISOString(),
                "start_time": startDate.toISOString(),
                "host_name": selectedFilter.user,
                "category": selectedFilter.category,
                "sub_category": selectedFilter.subCat,
                "application": selectedFilter.app,
                "device_name": selectedFilter.device,
                "detailed_report": enableDPI,
                "vlan": selectedFilter.vlan
            },
            "format": format
        }
        createDownloadServiceContentFilter(dpId, payload);
        handleClose();

        toast.success('Please check the progress of your report request in the "Generated Reports" tab.', {
            position: toast.POSITION.BOTTOM_LEFT,
        });
    }

    const handleChangeCheckbox = (e) => {
        setEnableDPI(e.target.checked);
    }

    return (
        <div className="Form menuScroll">
            <Dialog 
                disableEnforceFocus
                open={generateFilter}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                sx={{borderRadius: '8px'}}
                className='config-deploy-inventory-popup activity-filters-popup-dialogue menuScroll'
            >
                <div>
                    <DialogTitle className='create-default-config-title'>
                        <span>Report</span>
                        <img src={close} title="Close" onClick={handleClose} />
                    </DialogTitle>
                    <DialogContent>
                        <Grid container className="deploy-inventory-content">
                            <Grid className="selectedDeviceDetails">
                                <Grid className="generate-report-title">
                                    <span>Generate Report</span> <br />
                                    <span>Apply Filters below to generate your Report</span>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy">
                                <span>Select Date Range</span>
                                <Stack direction='row' spacing={1}>
                                    <div className="siteCharts__filters-date-time-picker">
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DateTimePicker
                                                ampm={false}
                                                timezone={getTimezoneCity(authReducer.userTimezone)}
                                                label='Start Date'
                                                value={startDate}
                                                onChange={handleStartDatePicker}
                                                maxDateTime={endDate}
                                                className="audit-filter-inputs date-range-activity"
                                                viewRenderers={{
                                                    hours: renderTimeViewClock,
                                                    minutes: renderTimeViewClock,
                                                    seconds: renderTimeViewClock,
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="siteCharts__filters-date-time-picker">
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DateTimePicker
                                                ampm={false}
                                                timezone={getTimezoneCity(authReducer.userTimezone)}
                                                label='End Date'
                                                value={endDate}
                                                onChange={handleEndDatePicker}
                                                minDateTime={startDate}
                                                className="audit-filter-inputs date-range-activity"
                                                maxDateTime={moment()}
                                                viewRenderers={{
                                                    hours: renderTimeViewClock,
                                                    minutes: renderTimeViewClock,
                                                    seconds: renderTimeViewClock,
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy">
                                <span>Select Organization</span>
                                <DP_Dropdown isReports= {true} setOu={handleOu} className={'dp-dropdown-activity-filters'} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="select-site-check" >
                                <div>
                                    <span>Select Site</span>
                                    <div className="list-filtered-filter">
                                        <Grid>
                                            <Autocomplete className="select-site-input"
                                                fullWidth
                                                size="small"
                                                multiple 
                                                limitTags={1}
                                                ListboxProps={{className: 'warehouse-apply-config-props'}}
                                                id="multiple-limit-tags"
                                                options={sitesList}
                                                getOptionLabel={(option: any) => {
                                                    return option.name;
                                                }}
                                                value={[...sites]}
                                                getOptionDisabled={(option: any) => {
                                                    let vesselOption = "All Sites";
                                                    if (sites.findIndex(item => item?.name === vesselOption) !== -1) {
                                                        return true;
                                                    }
                                                    if (option.name === vesselOption && sites[0]?.name && sites[0]?.name !== vesselOption) {
                                                        return true;
                                                    }
                                                    if(sites.find((ele)=> ele.name==option.name)) {
                                                        return true
                                                    }
                                                    return false;
                                                }}
                                                onChange={(e, newValue) => { handleChangeSite(e, newValue) }}
                                                renderInput={(params) => (<TextField {...params} required placeholder="select" style={{fontSize:'11px'}} />)}
                                            />
                                        </Grid>
                                        <span>List is Filtered based on Organization</span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy extand-width">
                                <span>Select Username</span>
                                <SelectTag options={userList} handleChange={(e, newValue) => hanldeChangeSelect(e, newValue, 'user')} value={selectedFilter?.user} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy extand-width">
                                <span>Select Category</span>
                                <SelectTag options={categoryList} handleChange={(e, newValue) => hanldeChangeSelect(e, newValue, 'category')} value={selectedFilter?.category} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy extand-width">
                                <span>Select Sub Category</span>
                                <SelectTag options={subCategoryList} handleChange={(e, newValue) => hanldeChangeSelect(e, newValue, 'subCat')} value={selectedFilter?.subCat} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy extand-width">
                                <span>Select VLAN</span>
                                <SelectTag options={vlanList} handleChange={(e, newValue) => hanldeChangeSelect(e, newValue, 'vlan')} value={selectedFilter?.vlan} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy extand-width">
                                <span>Select Device</span>
                                <SelectTag options={deviceList} handleChange={(e, newValue) => hanldeChangeSelect(e, newValue, 'device')} value={selectedFilter?.device} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy extand-width">
                                <span>Select Domain</span>
                                <SelectTag options={appList} handleChange={(e, newValue) => hanldeChangeSelect(e, newValue, 'app')} value={selectedFilter?.app} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy extand-width">
                                <span>Format</span>
                                <SelectTag options={['xlsx', 'csv']} handleChange={(e, newValue) => setFormat(newValue)} value={format} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy DPI-checkbox suggested-filters">
                                <span>Detailed Flow Report</span>
                                <FormControlLabel control={<Checkbox checked={enableDPI} onChange={handleChangeCheckbox} />} label="" />
                            </Grid>
                            <DialogActions className="config-create-actions">
                                <Button className="config-cancel-btn" onClick={handleClose}>Cancel</Button>
                                <Button className="config-create-btn" onClick = {handleClickGenerate}
                                style={{background: sitesList?.length > 1 ? "#264C86" : "#E8F0FD", color: sitesList?.length > 1 ? "#fff" : "#264C86"}}
                                disabled={sitesList?.length > 1 ? false : true}
                                >Generate</Button>
                            </DialogActions>
                        </Grid>
                    </ DialogContent>
                </div>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
  });
  
  export default withRouter(
    connect(mapStateToProps, { fetchResources, createDownloadServiceContentFilter })( GenerateReportPopup )
  );