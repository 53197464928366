import { GET_ERRORS, 
    ERROR_RESET_STATE, 
    ERROR_RESEND_INVITE, 
    ERROR_INVITE_USER, 
    ERROR_DETACH_USER,
    ERROR_DISPLAY_APPLICABLE_USER_POLICIES,
    ERROR_ENABLE_USER_ACCOUNT,
    ERROR_DISABLE_USER_ACCOUNT,
    ERROR_SET_PASSWORD_User,
    ERROR_FETCH_USERS,
    ERROR_FETCH_OU_RESOURCES,
    ERROR_ATTACH_RESOURCE_OU,
    ERROR_DETACH_RESOURCE_OU,
    ERROR_NOT_ATTACH_RESOURCE_OU,
    ERROR_SEARCH_ALERTS, 
    ERROR_CLOSE_VESSEL_ALERT,
    ERROR_GET_ALERT_NAME,
    ERROR_ALERTS_HISTORY,
    ERROR_GET_VESSEL_SUMMARY_TABLE,
    ERROR_GET_VESSEL_SUMMARY_DASHBOARD,
    ERROR_GET_ORGANISATIONS,
    ERROR_GET_ORGANISATIONS_UNITS,
    ERROR_GET_VESSELS_LISTING,
    ERROR_UPDATING_USER_DETAILS,
    ERROR_GET_EDGE_EVENTS,
    ERROR_GET_EDGE_EVENTS_FILTER,
    ERROR_GET_SITE_RSSI,
    ERROR_GET_SITE_SINR,
    ERROR_GET_SITE_DATA_RATE,
    ERROR_GET_SITE_LINK_STATUS,
    ERROR_GET_SITE_LATENCY_JITTER,
    ERROR_GET_SITE_INTERFACES,
    ERROR_ASSING_INVENTORY,
    ERROR_RESET_PASSWORD,
    ERROR_FETCH_SERVICE_LINES
} from "../actions/error_types";
import { displayToastError } from "../server/request";

const initialState = {
    error: {},
    errorResendInvite: {},
    errorInviteUser: {},
    errorDetachUser: {},
    errorDisplayApplicableUserPolicies: {},
    errorEnableUserAccount: {},
    errorDisableUserAccount: {},
    errorSetPasswordUser: {},
    errorFetchUsers: {},
    errorFetchOuResources: {},
    errorFetchServiceLines: {},
    errorAttachResourceOu: {},
    errorDetachResourceOu: {},
    errorNotAttachResourceOu: {},
    errorSearchAlerts: {},
    errorCloseVesselAlert: {},
    errorGetAlertName: {},
    errorAlertsHistory: {},
    errorGetVesselSummaryTable: {},
    errorGetVesselSummaryDashboard: {},
    errorGetOrganisation: {},
    errorGetOrganisationUnits: {},
    errorGetVesselsListing: {},
    errorUpdatingUserDetails: {},
    errorGetEdgeEvents: {},
    errorGetEdgeEventsFitler: {},
    errorGetSiteRSSI: {},
    errorGetSiteSINR: {},
    errorGetSiteDataRate: {},
    errorGetSiteLinkStatus: {},
    errorGetSiteLatencyJitter: {},
    errorGetSiteInterfaces: {},
    errorAssignInventory: {},
    errorResetPassword: {}
};

export default function errorReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ERRORS:
            console.log("Error", action.payload);
            // displayToastError(action.payload.message);
            return {
                ...state,
                error: action.payload
            }
        case ERROR_RESEND_INVITE:
            return {
                ...state,
                errorResendInvite: action.payload
            }
        case ERROR_INVITE_USER:
            return {
                ...state,
                errorInviteUser: action.payload
            }   
        case ERROR_DETACH_USER:
            return {
                ...state,
                errorDetachUser: action.payload
            }  
        case ERROR_DISPLAY_APPLICABLE_USER_POLICIES:
            return {
                ...state,
                errorDisplayApplicableUserPolicies: action.payload
            }
        case ERROR_ENABLE_USER_ACCOUNT:
            return {
                ...state,
                errorEnableUserAccount: action.payload
            } 
        case ERROR_DISABLE_USER_ACCOUNT:
            return {
                ...state,
                errorDisableUserAccount: action.payload
            }
        case ERROR_SET_PASSWORD_User:
            return {
                ...state,
                errorSetPasswordUser: action.payload
            }
        case ERROR_FETCH_USERS:
            return {
                ...state,
                errorFetchUsers: action.payload
            }
        case ERROR_FETCH_OU_RESOURCES:
            return {
                ...state,
                errorFetchOuResources: action.payload
            }
        case ERROR_FETCH_SERVICE_LINES:
                return {
                    ...state,
                    errorFetchServiceLines: action.payload
                }
        case ERROR_ATTACH_RESOURCE_OU:
            return {
                ...state,
                errorAttachResourceOu: action.payload
            }
        case ERROR_DETACH_RESOURCE_OU:
            return {
                ...state,
                errorDetachResourceOu: action.payload
            }
        case ERROR_NOT_ATTACH_RESOURCE_OU:
            return {
                ...state,
                errorNotAttachResourceOu: action.payload
            }
        case ERROR_SEARCH_ALERTS:
            return {
                ...state,
                errorSearchAlerts: action.payload
            }
        case ERROR_CLOSE_VESSEL_ALERT:
            return {
                ...state,
                errorCloseVesselAlert: action.payload
            }
        case ERROR_GET_ALERT_NAME:
            return {
                ...state,
                errorGetAlertName: action.payload
            }
        case ERROR_ALERTS_HISTORY:
            return {
                ...state,
                errorAlertsHistory: action.payload
            }
        case ERROR_GET_VESSEL_SUMMARY_TABLE:
            return {
                ...state,
                errorGetVesselSummaryTable: action.payload
            }
        case ERROR_GET_VESSEL_SUMMARY_DASHBOARD:
            return {
                ...state,
                errorGetVesselSummaryDashboard: action.payload
            }
        case ERROR_GET_ORGANISATIONS:
            return {
                ...state,
                errorGetOrganisation: action.payload
            }
        case ERROR_GET_ORGANISATIONS_UNITS:
            return {
                ...state,
                errorGetOrganisationUnits: action.payload
            }
        case ERROR_GET_VESSELS_LISTING:
            return {
                ...state,
                errorGetVesselsListing: action.payload
            }
        case ERROR_RESET_STATE:
            return initialState;
        case ERROR_UPDATING_USER_DETAILS:
            return {
                ...state,
                errorUpdatingUserDetails: action.payload
            }
        case ERROR_GET_EDGE_EVENTS:
            return {
                ...state,
                errorGetEdgeEvents: action.payload
            }
        case ERROR_GET_EDGE_EVENTS_FILTER:
            return {
                ...state,
                errorGetEdgeEventsFitler: action.payload
            }
        case ERROR_GET_SITE_RSSI:
            return {
                ...state,
                errorGetSiteRSSI: action.payload
            }
        case ERROR_GET_SITE_SINR:
            return {
                ...state,
                errorGetSiteSINR: action.payload
            }
        case ERROR_GET_SITE_DATA_RATE:
            return {
                ...state,
                errorGetSiteDataRate: action.payload
            }
        case ERROR_GET_SITE_LINK_STATUS:
            return {
                ...state,
                errorGetSiteLinkStatus: action.payload
            }
        case ERROR_GET_SITE_LATENCY_JITTER:
            return {
                ...state,
                errorGetSiteLatencyJitter: action.payload
            }
        case ERROR_GET_SITE_INTERFACES:
            return {
                ...state,
                errorGetSiteInterfaces: action.payload
            }
        case ERROR_ASSING_INVENTORY:
            return {
                ...state,
                errorAssignInventory: action.payload
            }
        case ERROR_RESET_PASSWORD:
            return {
                ...state,
                errorResetPassword: action.payload
            }
        default:
            return state;
    }
}