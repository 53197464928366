import React, { useState, useEffect } from "react";
import { Grid, Button, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import CustomAlert from "../../components/CustomAlert";
import { resetPassword } from "../../actions/Users/authenticate";
import DialogBox from "../../components/DialogBox";
import _ from "lodash";
import "./resetPassword.css";
import useStyles from "../Registration/style";
import { Typography } from "../../components/wrappers/Wrappers";
import CustomLoader from "../../components/Loader/CustomLoader";
import { Base64 } from "js-base64";
import Box from "@mui/material/Box";
import k4Logo from "../../asset/image/k4Logo.png";
import k4LogoMobileView from "../../asset/image/k4LogoMobileView.png";
import TextField from "@mui/material/TextField";
import CustomPasswordValidator from "../../components/CustomPasswordValidator";


function ResetPassword({ history, authReducer, errorReducer, resetPassword }) {
  const { confirmCode } = useParams();
  const [userName, setUserName] = useState("");
  const [oldPassword, setOldPassword] = useState(confirmCode);
  const [newPassword, setNewPassword] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [fullLoader, setFullLoader] = useState(false);
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [alertMsg, setAlertMsg] = useState({msg: null, type: "error"});
  const [notErrorInFields, setNotErrorInFields] = useState({newPassword: true});
  let [allFieldsIsValidORnot, setAllFieldsIsValidORnot] = useState(false);
  let [loaders, setLoaders] = useState({showLoader: false, loadingText: ""});
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isImageError, setImageError] = useState(false);

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const checkAllFieldsValidation = (notErrorInFields) => {
    let isExist = 0;
    Object.keys(notErrorInFields).map((keysName) => {
      if (notErrorInFields[keysName] === false) {
        isExist = 1;
        setAllFieldsIsValidORnot(notErrorInFields[keysName]);
      }});
    if (0 === isExist) setAllFieldsIsValidORnot(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (errorReducer.errorResetPassword.message && errorReducer.errorResetPassword.message !== "") {
      setFullLoader(false);
      setLoaders({ showLoader: false, loadingText: "" });
      setAlertMsg({ msg: errorReducer.errorResetPassword.message, type: "error" });
      setShowAlert(true);
      errorReducer.errorResetPassword.message = "";
    }
  }, [errorReducer.errorResetPassword]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.resetPassword)) {
      setFullLoader(false);
      setShowPopUp(true);
      authReducer.resetPassword = {};
    }
  }, [authReducer.resetPassword]);



  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    if (name === "oldPassword") {
      setOldPassword(event.target.value);
    }
    if (name === "newPassword") {
      setNewPassword(event.target.value);
    }
    if (name === "reEnterPassword") {
      setReEnterPassword(event.target.value);
    }
    if (name === "userName") {
      setUserName(event.target.value);
    }
  };

  const handleResetPassword = () => {
    let payload = {
      key: oldPassword,
      newPassword: Base64.encode(newPassword),
    };
    resetPassword(payload);
    setFullLoader(true);
  };

  const handleImgError = (host) => {
    if(host) {
      setImageError(true);
    }
  }

  const handlePasswordValidation = (result) => {
    notErrorInFields["newPassword"] = result
    setNotErrorInFields(notErrorInFields)
    checkAllFieldsValidation(notErrorInFields);
  }

  const [isSvgError, setIsSvgError] = useState(false);
  const [isPngError, setIsPngError] = useState(false);

  const handleSvgError = () => {
    setIsSvgError(true);
  };

  const handlePngError = () => {
    setIsPngError(true);
  };

  return (
    <Grid container component="main" className={classes.root}>
      {showAlert && showAlert === true ? (<CustomAlert type={alertMsg.type} msg={alertMsg.msg} />) : null}
      <Grid item container xs={12} style={{ height: "100%" }}>
        <Box display={{xs: "none", sm: "contents", md: "contents", lg: "contents"}}>
          <Grid xs={0} sm={6} md={6} lg={6} className={classes.logoContainer}>
          {authReducer.hostName && (
        <img
          onError={isSvgError ? handlePngError : handleSvgError}
          src={!isSvgError ? `https://k4storage.blob.core.windows.net/cdn/sportAssets/${authReducer.hostName}/k4Logo.svg` : (!isPngError ? `https://k4storage.blob.core.windows.net/cdn/sportAssets/${authReducer.hostName}/k4Logo.png` : k4Logo)}
          style={{ width: "50%" }}
          alt="K4 Logo"
        />
      )}
          </Grid>
        </Box>
        <Grid xs={12} sm={6} md={6} lg={6} className={classes.textContainer}>
          <Box display={{xs: "none", sm: "contents", md: "contents", lg: "contents"}}>
            <Typography className="reset-password_textCaption"> Reset Password </Typography>
          </Box>
          <Box display={{ xs: "contents", sm: "none", md: "none", lg: "none" }}>
            <Grid> <img src={k4LogoMobileView} style={{ width: "100%", marginTop: "-20px", height: "150px" }}/>
              <Grid className="reset-password_loginMobileTextCaption"> Reset Password</Grid>
            </Grid>
          </Box>
          <Grid xs={10}>
            <Box sx={{"& .MuiTextField-root": {m: 1, width: "100%", marginTop: "30px"}}} className="TextBoxMobileView NewPassword">
              <TextField
                id="outlined-basic" label="Password*" variant="outlined" placeholder="Password"
                size="small" value={newPassword} name="newPassword" onChange={handleChange} className="visibilityButton" 
                type={showPassword ? "text" : "password"} InputLabelProps={{ className: "UserNameInput" }}
                InputProps={{ className: "labelTextbox",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid xs={10}>
            <Box sx={{"& .MuiTextField-root": {m: 1, width: "100%", marginTop: "30px"}}} className="TextBoxMobileView NewPassword">
              <TextField
                id="outlined-basic" label="Confirm Password*" variant="outlined" placeholder="Confirm Password"
                size="small" className="visibilityButton" value={reEnterPassword} type={showConfirmPassword ? "text" : "password"}
                name="reEnterPassword" onChange={handleChange} InputLabelProps={{ className: "UserNameInput" }}
                InputProps={{ className: "labelTextbox",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowConfirmPassword} onMouseDown={handleMouseDownPassword}>
                        {showConfirmPassword ? ( <Visibility /> ) : ( <VisibilityOff />)}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Grid lg={12} style={{marginLeft: "2em"}}>
                <CustomPasswordValidator newPassword={newPassword} reEnterPassword={reEnterPassword} onValidatorChangeHandler={handlePasswordValidation} />
            </Grid>
          </Grid>
          <Grid>
            <Button className="reset-password_confirmButtonReset" color="primary" onClick={handleResetPassword} title="Reset password" variant="contained" disableRipple disableElevation
              disabled={!notErrorInFields["newPassword"]}> Reset </Button>
          </Grid>
        </Grid>
      </Grid>
      <DialogBox Open={showPopUp} onClose={() => setShowPopUp(false)} DialogTitle="Password Reset Successfully"
        ButtonLabel="Done" onClick={() => {window.location.href="/"}}/>
      {loaders["showLoader"] ? (
        <CustomLoader showLoader={loaders["showLoader"]} loadingText={loaders["loadingText"] ? loaders["loadingText"] : "Please wait..."}/>
      ) : null}
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    resetPassword,
  })(ResetPassword)
);