import * as React from 'react';
import _ from 'lodash';
import { nanoid } from 'nanoid';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Collapse, IconButton, Input } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoreOutlinedIcon from '@mui/icons-material/MoreOutlined';
import Fade from '@mui/material/Fade';


import "./index.css";

export function LabelConfiguration({
    defaultLabels,
    handleChange,
    showHeader,
    isDefaultCardView
}: {
    defaultLabels: { [key: string]: string; } | undefined,
    handleChange: (labels: { [key: string]: string; }) => void,
    showHeader: boolean,
    isDefaultCardView: boolean
}) {

    const getLabelMap = (labels: { [key: string]: string; } | undefined) => {
        let _labels = labels || {};
        return new Map<string, { key: string, value: string }>(Object.keys(_labels).map(key => {
            return [
                nanoid(), {
                    key: key,
                    value: _labels[key]
                }
            ]
        }))
    }

    const [editing, isEditing] = React.useState(isDefaultCardView ? true : false);
    const [labels, setLabels] = React.useState<Map<string, { key: string, value: string }>>(() => {
        return getLabelMap(defaultLabels);
    })

    const handleSave = () => {
        isEditing(false)
        let _labels = {}
        let m = new Map<string, { key: string, value: string }>();
        Array.from(labels.values()).forEach(label => {
            if(!_.isEmpty(label.key)) {
                _labels[label.key] = label.value
            }
        })
        Object.keys(_labels).forEach(key => {
            m.set(nanoid(), {
                key: key, value: _labels[key]
            })
        })
        setLabels(m)
        handleChange(_labels)
    }

    const handleCancel = () => {
        isEditing(false);
        setLabels(getLabelMap(defaultLabels));
    }

    const handleAddLabel = () => {
        labels.set(nanoid(), {
            key: '',
            value: ''
        })
        setLabels(new Map(labels));
    }

    const handleDeleteLabel = (id) => {
        labels.delete(id);
        let _labels = new Map(labels);
        setLabels(_labels);
    }

    const handleChangeLabel = (id, label) => {
        labels.set(id, label);
    }


    return (
        <div className="label_configuration--container">
            {
                showHeader ? <div className="label_configuration--header">
                    <div className="label_configuration--header--meta">
                        <div className="label_configuration--header--meta--icon">
                            <MoreOutlinedIcon />
                        </div>
                        <div className='label_configuration--header--meta--title_container'>
                            <div className='label_configuration--header--meta--title'>Label</div>
                            <div className='label_configuration--header--meta--subtitle'>Set label name and tag value </div>
                        </div>
                    </div>
                    <div className="label_configuration--header--action_dots">
                        <MoreActionMenu isEditing={isEditing} />
                    </div>
                </div> : null
            }
            <Collapse in={isDefaultCardView ? true : editing}>
                {
                    <div>
                        <div className={`label_configuration--body`}>
                            <div className="label_configuration--body--condition_container">
                                <div className="label_configuration--body--condition_header">Enter  label Details</div>
                                <div className="label_configuration--body--rule_container">
                                    {
                                        Array.from(labels.entries()).map(([id, label]) => <Label key={id} id={id} label={label} handleDelete={handleDeleteLabel} handleChange={handleChangeLabel} />)
                                    }
                                </div>
                                <div className="label_configuration--body--action">
                                    <div className="label_configuration--body--action--add">
                                        <Button className='label_configuration--body--action--add_button' onClick={handleAddLabel} size="small" variant="contained" startIcon={<AddCircleOutlineIcon />} >
                                            Add Label
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`label_configuration--footer`}>
                            <Button variant="outlined" className="" onClick={handleCancel}>Cancel</Button>
                            <Button variant="contained" disabled={false} className="save_config_button" onClick={handleSave}>Save</Button>
                        </div>
                    </div>
                }
            </Collapse>
        </div>
    )
}

function Label({ id, label, handleDelete, handleChange }) {

    const [key, setKey] = React.useState(label.key);
    const [value, setValue] = React.useState(label.value);

    React.useEffect(() => {
        if (!_.isEmpty(value)) {
            handleChange(id, { key, value })
        }
    }, [key, value])

    const handleChangeKey = (e) => {
        setKey(e.target.value);
    }

    const handleChangeValue = (e) => {
        setValue(e.target.value);
    }

    return (
        <Fade in={true}>
            <div className="label_configuration--body--rule">
                <div className="label_configuration--body--rule--inputs">
                    <div className="label_configuration--body--rule--inputs--key">
                        <Input placeholder='label key' value={key} onChange={handleChangeKey} inputProps={{ 'aria-label': 'label-key' }} />
                    </div>
                    <div className="label_configuration--body--rule--inputs--value">
                        <Input placeholder='label value' value={value} onChange={handleChangeValue} inputProps={{ 'aria-label': 'label-value' }} />
                    </div>
                </div>
                <div className="label_configuration--body--rule--action">
                    <IconButton aria-label="more-options" onClick={() => handleDelete(id)} >
                        <RemoveCircleIcon className='label_configuration--body--rule--remove_icon' />
                    </IconButton>
                </div>
            </div>
        </Fade>
    )
}

function MoreActionMenu({ isEditing }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleEdit = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
        isEditing(true)
    };

    return (
        <React.Fragment>
            <IconButton aria-label="more-options" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="label-configuration-more-options"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'more-action-button',
                }}
                PaperProps={{
                    className: 'label-configuration-more-options-paper'
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleEdit}>Edit</MenuItem>
            </Menu>
        </React.Fragment>
    )
}