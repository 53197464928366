import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Box, Button, Chip, Dialog, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import close from "../../asset/image/close.svg";
import "./CreateGroup.css";
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete";
import _ from "lodash";
import DP_Dropdown from "../InventoryWarehouseTable/DP_Dropdown";
import { useEffect, useState } from "react";
import { GET_DEVICE_LIST } from "../../actions/types";
import { getDeviceList, createGroup } from "../../actions/Users/authenticateInventory";

export const CreateGroup = (props) => {
    const { authReducer, createGroupPopup, setCreateGroupPopup, getDeviceList, createGroup } = props;

    const dispatch = useDispatch();
    const [groupName, setGroupName] = useState<string>("")
    const [sites, setSites] = useState<any[]>([])
    const [devices, setDevices] = useState<any[]>([])
    const [selectedDevices, setSelectedDevices] = useState<any[]>([])
    const [selectedOu, setSelectedOu] = useState<any>({})
    const [isAllSelected, setIsAllSelected] = useState(false)
    interface DeviceInterface {
        dpId: string;
        siteId: string;
        deviceId: string;
        isAllSelected: boolean
    }

    useEffect(() => {
        if (!_.isEmpty(selectedOu) && selectedOu.hasOwnProperty("name")) {
            let ouName = selectedOu.name
            setSites([])
            setDevices([])
            setSelectedDevices([])
            getDeviceList(ouName)
        }
    }, [selectedOu])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getDeviceList) && authReducer.getDeviceList.hasOwnProperty("data")) {
            setSites(authReducer.getDeviceList.data)
            dispatch({ type: GET_DEVICE_LIST, payload: {}})
        }
    }, [authReducer.getDeviceList])

    useEffect(() => {
        if (sites.length > 0) {
            const deviceIdentifiers = new Set();
            const _devices: any = [];
            for (let s = 0; s < sites.length; s++) {
                let site = sites[s]
                if (!_.isEmpty(site) && site.hasOwnProperty("devices") && site.devices.length > 0) {
                    let siteDevices = site["devices"]
                    for (let d = 0; d < siteDevices.length; d++) {
                        let device = {}
                        deviceIdentifiers.add(siteDevices[d]["id"].slice(0, 2))
                        device["dpId"] = site["dp_id"]
                        device["dpName"] = site["dp_name"]
                        device["siteId"] = site["location_id"]
                        device["siteName"] = site["location_name"]
                        device["deviceId"] = siteDevices[d]["id"]
                        device["deviceName"] = siteDevices[d]["name"]
                        _devices.push(device)
                    }
                }
            }

            let diDevices: any = []
            // deviceIdentifiers.forEach(deviceIdentifier =>
            //     diDevices.push({
            //         "siteName": "ALL " + deviceIdentifier,
            //         "siteId": deviceIdentifier,
            //         "deviceId": deviceIdentifier,
            //         "deviceName": "ALL " + deviceIdentifier,
            //         "isAllSelected": true,
            //         "deviceIdentifier": deviceIdentifier
            //     })
            // )
            diDevices = diDevices.concat(_devices)
            setDevices(diDevices)
        } else {
            setDevices([])
        }
    }, [sites])


    const getSelectedDevicesTags = (value, getTagProps: AutocompleteRenderGetTagProps) => {
        const numTags = value.length;
        const limitTags = 1
        return (
            <>
                {value.slice(0, limitTags).map((option, index) => (
                    <Chip
                        {...getTagProps({ index })}
                        key={index}
                        className="selected-devices-tags"
                        title={option.deviceName}
                        label={option.deviceName.slice(0, 10) + "..."}
                    />
                ))}
                {
                    numTags > limitTags
                        ?
                        <span title={value.slice(limitTags).map(d => { return d.deviceName }).toString()}>{"+" + (numTags - limitTags)}</span>
                        : <></>
                }
            </>
        );
    }

    const handleSelectedDevicesChange = (event: React.SyntheticEvent<Element, Event>, value: unknown, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<unknown> | undefined) => {
        if (reason === 'selectOption' || reason === 'removeOption') {
            let _value = value as DeviceInterface[];
            setSelectedDevices(_value);
            if (_value.length === 1) {
                if (_value[0].isAllSelected) {
                    setIsAllSelected(true)
                } else {
                    setIsAllSelected(false)
                }
            }
        } else if (reason === 'clear') {
            setSelectedDevices([]);
        }
    };

    const invenotoryCreateGroup = () => {
        if (!_.isEmpty(groupName) && selectedDevices.length) {
            let selectedOuId = selectedOu.id;

            let payload = {}
            if (isAllSelected && selectedDevices.length === 1) {
                payload = {
                    "name": groupName,
                    "isAllSelected": true,
                    "deviceIdentifier": selectedDevices[0]["deviceIdentifier"],
                    "devices": []
                }
            } else {
                payload = {
                    "name": groupName,
                    "isAllSelected": false,
                    "deviceIdentifier": "",
                    "devices": selectedDevices
                }
            }
            createGroup(selectedOuId, payload)
            setCreateGroupPopup(false)
        }
    }

    const closeDialog = () => {
        setCreateGroupPopup(false)
    }

    return (
        <Dialog
            open={createGroupPopup}
            onClose={() => { closeDialog() }}
            aria-labelledby='form-dialog-title'
            maxWidth={'md'}
            className='create-default-config-container group-actions-tab-create-group-popup'
        >
            <div>
                <DialogTitle className='create-default-config-title'>
                    <span>Create Group</span>
                    <img src={close} title="Close" onClick={() => { closeDialog() }} />
                </DialogTitle>
                <DialogContent className='create-default-config-content'>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} className='name-input-default-config name-margin-top'>
                            <span>Name</span>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <Box>
                                    {<TextField
                                        variant="outlined"
                                        type="search"
                                        placeholder="Group Name"
                                        onChange={(event) => {
                                            setGroupName(event.target.value);
                                        }}
                                        value={groupName}
                                        InputProps={{
                                            autoComplete: 'none',
                                            className: "serachBar name-config-default group_name",
                                        }}

                                    />}
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} className='name-input-default-config create-config-table select_organization'>
                            <span>Select Organization</span>
                            <DP_Dropdown isReports= {false} setOu={setSelectedOu} className='select-organisation-dropdown' />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} className='name-input-default-config create-config-table select_devices'>
                            <span>Select Devices</span>
                            <Autocomplete
                                freeSolo
                                multiple
                                disableCloseOnSelect={true}
                                value={selectedDevices}
                                options={devices}
                                getOptionLabel={(option: any) => option.deviceName}
                                getOptionDisabled={option => {
                                        if (selectedDevices.length > 0) {
                                            if (selectedDevices[0]['deviceId'].slice(0, 2) == option['deviceId'].slice(0, 2)) {
                                                return false
                                            }
                                            return true
                                        }
                                        return false
                                    }
                                }
                                renderOption={(props, option) => {
                                    const key = `listItem-${props["data-option-index"]}-${option.siteId + "_" + option.deviceId}`
                                    return (<li {...props} title={option.siteName} key={key}>{option.deviceName}</li>)
                                }}
                                renderTags={(value, getTagProps) => getSelectedDevicesTags(value, getTagProps)}
                                onChange={handleSelectedDevicesChange}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" className={selectedDevices.length > 0 ? "search-or-select" : ""} placeholder="Search or Select" type="search" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="dialog-actions">
                            <Button variant="outlined" className={!_.isEmpty(groupName) && selectedDevices.length > 0 ? "create" : "create disable"} onClick={() => invenotoryCreateGroup()}>Create</Button>
                            <Button variant="outlined" onClick={() => { closeDialog() }} >Cancel</Button>
                        </Grid>
                    </Grid>
                </ DialogContent>
            </div>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {
        getDeviceList, createGroup
    })(CreateGroup)
);