// Dialog Box for the ticket history of Ticketing Page Pop-up
import React, { useEffect, useState } from "react";
import {
    Grid, Typography,
} from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Dialog, DialogContentText } from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from '@mui/material/DialogContent';
import close from "../../asset/image/close.svg";
import "./TicketHotVessel.css";
import { getHotVessels } from "../../actions/Users/authenticateTicket";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { DEFAULT_TIMEZONE, DAYDDMMMYYHMMSS_DATE_FORMAT } from "../../utils/constants";
import moment from "moment-timezone";
import _ from "lodash";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";


const TicketHotVessel = (props) => {
    const { onClose,onCloseDialog, selectedValue, open, hotVesselSelection,
        getHotVessels, authReducer } = props;
    let [dateRangeValue, setDateRangeValue]: any = React.useState(moment().subtract(30, "days").format("DD/MM/YYYY")+"-"+moment().format("DD/MM/YYYY"));
    let [ticketData, setTicketData]: any = React.useState([]);

    useEffect(() => {
        if (open) {
            getHotVessels(1, 10, moment().subtract(30, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"))
        }
    }, [open])


    useEffect(() => {
        if (!_.isEmpty(authReducer.getHotVessels) && authReducer.getHotVessels.data) {
            setTicketData(authReducer.getHotVessels.data.tickets);
            authReducer.getHotVessels = {};
        }
    }, [authReducer.getHotVessels])


    const setDateRange = (date) => {
        setDateRangeValue(date);

    };

    useEffect(() => {
        if (dateRangeValue.includes("-") && open) {
            let startDate = moment(dateRangeValue.split("-")[0].trim()).format("YYYY-MM-DD");
            let endDate = moment(dateRangeValue.split("-")[1].trim()).format("YYYY-MM-DD");;
            getHotVessels(1, 10, startDate, endDate)
        }

    }, [dateRangeValue])


    const onSelect = (k4Id) => {
        hotVesselSelection(k4Id)
        onClose()

    };

    return (
        <div className="Form">
            <Dialog
                open={open}
                onClose={() => {
                    if (onClose) onClose()
                }}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth={'md'}
                style={{ borderRadius: '13px' }}
            >
                {/* <Dialog className="tkt_ticket_history_dialog" open={open} > */}
                <DialogTitle className="dialog_title">
                    <Grid>
                        Hot Sites
                        <Grid style={{ marginLeft: "auto" }}>
                        <img src={close} title="Close Hot Site" onClick={onCloseDialog} className="dialog_closeIcon" />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className="tkt_ticket_history_dialog_content">
                    <Grid container direction="column">
                        <Grid item style={{ display: 'flex' }}>

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                }}
                            >
                                <div style={{ width: "70%" }}>
                                    <Typography style={{ fontWeight: 'bold', fontSize: "12px" }}>
                                        Select Date Range
                                    </Typography>
                                </div>
                                <DateRangePickerComponent cssClass="tkt_more_filters_date_picker_calendar" max={new Date()} allowEdit={false} change={(event) => setDateRange(event.element.value)} value={dateRangeValue} ></DateRangePickerComponent>

                            </div>
                        </Grid>
                        <Grid item>
                            {ticketData && ticketData.length > 0 ? (
                                <TableContainer
                                    className={`scroll`}
                                    style={{ padding: "10px 0px", maxHeight: '60vh', }}
                                >
                                    <Table
                                        style={{
                                            border: '2px solid #264c86',
                                        }}
                                        size="small"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    style={{
                                                        fontSize: '13px',
                                                        background: "#264c86",
                                                        textAlign: 'center',
                                                        color: "#ffffff",
                                                    }}
                                                >
                                                    Site Name
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontSize: '13px',
                                                        background: "#264c86",
                                                        textAlign: 'center',
                                                        borderLeft: '1px solid lightgray',
                                                        color: "#ffffff",
                                                    }}
                                                >
                                                    Total Number of Tickets Raised
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {ticketData.map((value) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell
                                                            style={{
                                                                fontSize: '12px',
                                                                textAlign: 'center',
                                                                cursor: 'pointer',
                                                                color: "#264c86",
                                                            }}
                                                            onClick={() => {
                                                                onSelect(value.k4Id)
                                                            }}
                                                        >
                                                            {value.vessel}
                                                        </TableCell>

                                                        <TableCell
                                                            style={{
                                                                fontSize: '11px',
                                                                textAlign: 'center',
                                                                borderLeft: '1px solid lightgray',
                                                            }}
                                                        >
                                                            {value.count}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'Center',
                                        margin: '15vh',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {' '}
                                    No Tickets Found for the given Range
                                </div>
                            )}{' '}
                        </Grid>
                    </Grid>
                </ DialogContent>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});
export default withRouter(
    connect(mapStateToProps, {
        getHotVessels
    })(TicketHotVessel)
);