// New Summary Dashboard

import { api } from "../../server/request";
import { handleError, handleResponse } from "../responseUtil";
import { GET_DASHBOARD_ALERT_LINECHART_BYNAME, GET_DASHBOARD_ALERT_LINECHART_BYSITE, GET_DASHBOARD_ALERT_PIECHART_BYNAME, GET_DASHBOARD_ALERT_PIECHART_BYSITE, GET_DASHBOARD_ALERT_TOPWIDGET, GET_DASHBOARD_HISTORGRAM_USAGEPER_SITE_COUNT, GET_DASHBOARD_HISTORGRAM_USAGEPER_WANTYPE, GET_DASHBOARD_PIE_USAGEPER_WANTYPE, GET_DASHBOARD_PIE_WAN_USAGE_BYSITE, GET_DASHBOARD_UPTIME_LINECHART_BYSITE, GET_DASHBOARD_UPTIME_LINECHART_BYWAN, GET_DASHBOARD_UPTIME_PIECHART_BYSITE, GET_DASHBOARD_UPTIME_PIECHART_BYWAN, GET_DASHBOARD_UPTIME_SITESTATISTICS_TABLE, GET_DASHBOARD_USAGE_HISOGRAM_UPLINK_DLK, GET_DASHBOARD_USAGE_HISTOGRAM_BYSITE, GET_DASHBOARD_USAGE_PIE_TOP10_APPS, GET_NEW_SUMMARY_UPTIME_ALL_LINK_AVAILABLITY, GET_NEW_SUMMARY_UPTIME_ALL_LINK_AVAILABLITY_LOADING, GET_NEW_SUMMARY_USAGE_TABLE, GET_NEW_SUMMARY_USAGE_TABLE_LOADING, GET_TOP_WIDGET_DATA_NEW_SUMM, GET_TOP_WIDGET_WANLINK_STATUS_NEW_SUMM, GET_USAGE_TABLE_DATA, SET_ACTIVITY_REPORTS_TOP_USERS_LOADING, SET_DASHBOARD_ALERT_LINECHART_BYNAME_LOADING, SET_DASHBOARD_ALERT_LINECHART_BYSITE_LOADING, SET_DASHBOARD_ALERT_PIECHART_BYNAME_LOADING, SET_DASHBOARD_ALERT_PIECHART_BYSITE_LOADING, SET_DASHBOARD_ALERT_TOPWIDGET_LOADING, SET_DASHBOARD_HISTORGRAM_USAGEPER_SITE_LOADING, SET_DASHBOARD_HISTORGRAM_USAGEPER_WANTYPE_LOADING, SET_DASHBOARD_PIE_USAGEPER_WANTYPE_LOADING, SET_DASHBOARD_PIE_WAN_USAGE_BYSITE_LOADING, SET_DASHBOARD_UPTIME_LINECHART_BYSITE_LOADING, SET_DASHBOARD_UPTIME_LINECHART_BYWAN_LOADING, SET_DASHBOARD_UPTIME_PIECHART_BYSITE_LOADING, SET_DASHBOARD_UPTIME_PIECHART_BYWAN_LOADING, SET_DASHBOARD_UPTIME_SITESTATISTICS_TABLE_LOADING, SET_DASHBOARD_USAGE_HISOGRAM_UPLINK_DLK_LOADING, SET_DASHBOARD_USAGE_HISTOGRAM_BYSITE_LOADING, SET_DASHBOARD_USAGE_PIE_TOP10_APPS_LOADING, SET_LOADING, SET_VESSEL_DASHBOARD_LOADING } from "../types";
import moment from "moment";
import { getAvilWindow } from "./authenticateDashboard";

export const getClickhouseBucketReformat = (interval) => {
    switch (interval) {
        case "5m": return '5 minute';
        case "10m": return '10 minute';
        case "15m": return '15 minute';
        case "30m": return '30 minute';
        case "1h": return '1 hour';
        case "2h": return '2 hour';
        case "3h": return '3 hour';
        case "4h": return '4 hour';
        case "6h": return '6 hour';
        case "12h": return '12 hour';
        case "1d": return '1 day';
        case "7d": return '7 day';
        default: return '1 hour';
    }
}

// Usage Queries

export const getTopWidgetDataNewSumm = (vessels: any, search, wanType, filter, startDate: string, endDate: string) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const vesselMap: any = [];
    vessels?.map((item: any) => {
        let vesselName = item["id"];
        vesselMap.push(vesselName);
    })
    let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;
    let now = moment(moment.now()).utc();
    dispatch({ type: SET_LOADING, payload: true });
    const data: any = {
        query: 'DASHBOARD_TOPWIDGET_WANUSAGE',
        named: true,
        parameters: {
            dp: "",
            k4Id: k4idhere,
            search: search ? search : "",
            "wanType": wanType,
            "endTime": moment(endDate).toISOString(),
            "startTime": moment(startDate).toISOString(),
            filter: filter
        },
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => {
            handleResponse(res, dispatch, GET_TOP_WIDGET_DATA_NEW_SUMM)
        })
        .catch((err) => handleError(err, dispatch));
};

export const getTopWidgetWanlinkStatusNewSumm = (vessels: [JSON], searchText: [String], ouName: string, signal: AbortSignal | undefined, filter: string, wanType: string, startDate: string, endDate: string, tabValue) => (dispatch) => {
    dispatch({ type: SET_VESSEL_DASHBOARD_LOADING, payload: true });
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const vesselMap: any = [];
    vessels?.map((item: any) => {
        let vesselName = item["id"];
        vesselMap.push(vesselName);
    })

    let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;
    let now = moment(moment.now()).utc();
    const data: any = {
        query: 'DASHBOARD_TOPWIDGET_WANLINK_STATUS',
        named: true,
        parameters: {
            "dp": "",
            "k4Id": (k4idhere && k4idhere !== '') ? `${k4idhere}` : "",
            "search": searchText ? searchText : "",
            "endTime": moment(endDate).toISOString(),
            "startTime": moment(startDate).toISOString(),
            "wanType": wanType !== "" ? `AND wan_type ilike '%${wanType}%'` : "",
            "filter": filter
        },
        format: 'csv'
    }

    api
        .postRequestOut(`${apiUrl}`, data, signal)
        .then((res) => {
            handleResponse(res, dispatch, GET_TOP_WIDGET_WANLINK_STATUS_NEW_SUMM)
        })
        .catch((err) => handleError(err, dispatch));
}

export const getDashboardUsagePieTop10Apps = (vessels, startDate, endDate, limit) => (dispatch) => {
    dispatch({ type: SET_DASHBOARD_USAGE_PIE_TOP10_APPS_LOADING, payload: true });
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const data: any = {
        query: 'DASHBOARD_USAGE_PIE_TOP10_APPS',
        named: true,
        parameters: {
            startTime: startDate,
            endTime: endDate,
            recLimit: `limit ${limit}`,
            k4Id: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
        },
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => {
            handleResponse(res, dispatch, GET_DASHBOARD_USAGE_PIE_TOP10_APPS)
        })
        .catch((err) => handleError(err, dispatch));
};

export const getDashboardPieUsageperWantype = (vessels, startDate, endDate, wanType) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_DASHBOARD_PIE_USAGEPER_WANTYPE_LOADING, payload: true });
    const data: any = {
        query: 'DASHBOARD_PIE_USAGEPER_WANTYPE',
        named: true,
        parameters: {
            startTime: startDate,
            endTime: endDate,
            wanType: wanType ? `and wan_type ilike '%${wanType}%'` : '',
            k4Id: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
        },
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => {
            handleResponse(res, dispatch, GET_DASHBOARD_PIE_USAGEPER_WANTYPE)
        })
        .catch((err) => handleError(err, dispatch));
};

export const getDashboardPieWanUsageBySites = (vessels, startDate, endDate, wanType, limit) => (dispatch) => {
    dispatch({ type: SET_DASHBOARD_PIE_WAN_USAGE_BYSITE_LOADING, payload: true });
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const data: any = {
        query: 'DASHBOARD_PIE_WAN_USAGE_BYSITE',
        named: true,
        parameters: {
            startTime: startDate,
            endTime: endDate,
            wanType: wanType ? `and wan_type ilike '%${wanType}%'` : '',
            recLimit: limit,
            k4Id: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
        },
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => {
            handleResponse(res, dispatch, GET_DASHBOARD_PIE_WAN_USAGE_BYSITE)
        })
        .catch((err) => handleError(err, dispatch));
};

export const getDashboardHistogramUsageperWantype = (vessels, startDate, endDate, wanType, selectedBin) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_DASHBOARD_HISTORGRAM_USAGEPER_WANTYPE_LOADING, payload: true });
    let data: any = {
        query: 'DASHBOARD_HISTORGRAM_USAGEPER_WANTYPE',
        named: true,
        format: 'chart',
        tsColumn: 'ts',
        series: 'wan_usage',
        groupBy: 'wan_type',
        parameters: {
            startTime: startDate,
            endTime: endDate,
            wanType: wanType ? `and wan_type ilike '%${wanType}%'` : '',
            interval: isClickHouse ? getClickhouseBucketReformat(selectedBin) : selectedBin,
            k4Id: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
        }
    }
    if (isClickHouse) {
        delete data.parameters.interval;
        data.parameters.bucketInterval = getClickhouseBucketReformat(selectedBin);
    } 
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => {
            handleResponse(res, dispatch, GET_DASHBOARD_HISTORGRAM_USAGEPER_WANTYPE)
        })
        .catch((err) => handleError(err, dispatch));
};

export const getDashboardUsageHistogramBySite = (vessels, startDate, endDate, wanType, limit, selectedBin) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`

    dispatch({ type: SET_DASHBOARD_USAGE_HISTOGRAM_BYSITE_LOADING, payload: true });
    let data: any = {
        query: 'DASHBOARD_USAGE_HISTOGRAM_BYSITE',
        named: true,
        parameters: {
            startTime: startDate,
            endTime: endDate,
            wanType: wanType ? `and wan_type ilike '%${wanType}%'` : '',
            interval: isClickHouse ? getClickhouseBucketReformat(selectedBin) : selectedBin,
            recLimit: limit,
            k4Id: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
        },
        format: "chart",
        tsColumn: "ts",
        series: "siteusage",
        groupBy: "name"
    }
    if (isClickHouse) {
        delete data.parameters.interval;
        data.parameters.bucketInterval = getClickhouseBucketReformat(selectedBin);
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => {
            handleResponse(res, dispatch, GET_DASHBOARD_USAGE_HISTOGRAM_BYSITE)
        })
        .catch((err) => handleError(err, dispatch));
};

export const getDashboardUsageHistogramUpDownLink = (vessels, startDate, endDate, wanType, selectedBin) => (dispatch) => {
    dispatch({ type: SET_DASHBOARD_USAGE_HISOGRAM_UPLINK_DLK_LOADING, payload: true });
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    let data: any = {
        query: 'DASHBOARD_USAGE_HISOGRAM_UPLINK_DLK',
        named: true,
        parameters: {
            startTime: startDate,
            endTime: endDate,
            wanType: wanType,
            interval: isClickHouse ? getClickhouseBucketReformat(selectedBin) : selectedBin,
            k4Id: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
        },
        format: "chart",
        tsColumn: "ts",
        series: "upDown",
        groupBy: "groupHelp"
    }
    if (isClickHouse) {
        delete data.parameters.interval;
        data.parameters.bucketInterval = getClickhouseBucketReformat(selectedBin);
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => {
            handleResponse(res, dispatch, GET_DASHBOARD_USAGE_HISOGRAM_UPLINK_DLK)
        })
        .catch((err) => handleError(err, dispatch));
};

export const getDashboardHistogramUsageBySiteCount = (vessels, startDate, endDate, wanType) => (dispatch) => {
    dispatch({ type: SET_DASHBOARD_HISTORGRAM_USAGEPER_SITE_LOADING, payload: true });
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const data: any = {
        query: 'DASHBOARD_USAGE_HISTOGRAM_BYSITECOUNT',
        named: true,
        format: 'csv',
        parameters: {
            startTime: startDate,
            endTime: endDate,
            wanType: wanType ? `and wan_type ilike '%${wanType}%'` : '',
            k4Id: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
        }
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => {
            handleResponse(res, dispatch, GET_DASHBOARD_HISTORGRAM_USAGEPER_SITE_COUNT)
        })
        .catch((err) => handleError(err, dispatch));
};

export const getNewSummaryUsageTable = (vessels: [JSON], startDate: string, endDate: string, wanType: string, page, limit, sort, sortOrder, search) => (dispatch) => {
    dispatch({ type: GET_NEW_SUMMARY_USAGE_TABLE_LOADING, payload: true });
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const vesselMap: any = [];
    vessels?.map((item: any) => {
        let vesselName = item["id"];
        vesselMap.push(vesselName);
    })

    let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;
    const data: any = {
        query: 'DASHBOARD_SITESTATISTICS_TABLE',
        named: true,
        parameters: {
            "dp": "",
            "k4Id": (k4idhere && k4idhere !== '') ? `${k4idhere}` : "",
            "search": search,
            "endTime": endDate,
            "startTime": startDate,
            "wanType": wanType ? `and wan_type ilike '%${wanType}%'` : '',
            "pageStart": ((page) * limit),
            "pageLimit": limit,
            "sortColumn": sort,
            "sortOrder": sort == 'site' ? `${sortOrder} collate 'en'` : sortOrder
        },
        format: 'csv'
    }

    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => {
            handleResponse(res, dispatch, GET_NEW_SUMMARY_USAGE_TABLE)
        })
        .catch((err) => handleError(err, dispatch));
}

export const getActivityUsageTableData = (dp, vessels, startDate, endDate, user, category, appFilt, page, limit, vlanFilt, deviceFilt, search, sort, sortOrder) => (dispatch) => {
    dispatch({ type: SET_ACTIVITY_REPORTS_TOP_USERS_LOADING, payload: true });
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const usageTableData: any = {
        query: 'DASHBOARD_SITESTATISTICS_TABLE_BYSITE',
        named: true,
        parameters: {
            dp: dp,
            k4Id: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
            startDate: startDate,
            endDate: endDate,
            user: user ? `and (hostname LIKE '%${user}%' or ipAddress LIKE '%${user}%' or macAddress LIKE '%${user}%')` : '',
            category: category ? `and category LIKE '%${category}%'` : '',
            pageStart: ((page - 1) * limit) + 1,
            pageLimit: (page * limit),
            appFilt: appFilt ? `and usageFrom in ('${appFilt}')` : '',
            vlanName: vlanFilt ? `and vlanName = '${vlanFilt}'` : '',
            deviceName: deviceFilt ? `and deviceAliasName ilike '%${deviceFilt}%'` : '',
            filter: search,
            sortColumn: sort,
            sortOrder: sortOrder
        },
    }
    api
        .postRequestOut(`${apiUrl}`, usageTableData)
        .then((res) => handleResponse(res, dispatch, GET_USAGE_TABLE_DATA))
        .catch((err) => handleError(err, dispatch));
};

// Uptime Queries

export const getNewSummaryUptimeAllLinks = (vessels: any, search, wanType, filter, startDate: string, endDate: string) => (dispatch) => {
    const avail_window = Math.ceil((Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3)) / 5);
    dispatch({ type: GET_NEW_SUMMARY_UPTIME_ALL_LINK_AVAILABLITY_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const vesselMap: any = [];
    vessels?.map((item: any) => {
        let vesselName = item["id"];
        vesselMap.push(vesselName);
    })

    let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;
    const data: any = {
        query: 'DASHBOARD_UPTIME_WAN_ALL_LINK_AVAILABILITY',
        named: true,
        parameters: {
            "avail_window": avail_window ? avail_window : 12,
            "k4Id": (k4idhere && k4idhere !== '') ? `${k4idhere}` : "",
            "endTime": endDate,
            "startTime": startDate,
            "wanType": wanType ? `and wan_type like '%${wanType}%'` : '',
            "search": "",
        },
        format: 'csv'
    }

    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => {
            handleResponse(res, dispatch, GET_NEW_SUMMARY_UPTIME_ALL_LINK_AVAILABLITY)
        })
        .catch((err) => handleError(err, dispatch));
}

export const getUptimePiechartByWan = (vessels, startDate, endDate, wanType, recLimit) => (dispatch) => {
    const avail_window = Math.ceil((Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3)) / 5);
    dispatch({ type: SET_DASHBOARD_UPTIME_PIECHART_BYWAN_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const data: any = {
        query: 'DASHBOARD_UPTIME_PIECHART_BYWAN',
        named: true,
        parameters: {
            k4Id: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
            startTime: startDate,
            endTime: endDate,
            avail_window: avail_window ? avail_window : '12',
            wanType: wanType ? `and wan_type like '${wanType}%'` : '',
            recLimit: recLimit
        },
        format: "csv"
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => handleResponse(res, dispatch, GET_DASHBOARD_UPTIME_PIECHART_BYWAN))
        .catch((err) => handleError(err, dispatch));
};

export const getUptimePiechart = (vessels, startDate, endDate, wanType, recLimit) => (dispatch) => {
    const avail_window = Math.ceil((Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3)) / 5);
    dispatch({ type: SET_DASHBOARD_UPTIME_PIECHART_BYSITE_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const data: any = {
        query: 'DASHBOARD_UPTIME_PIECHART_BYSITE',
        named: true,
        parameters: {
            k4Id: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
            startTime: startDate,
            endTime: endDate,
            avail_window: avail_window ? avail_window : '12',
            wanType: wanType ? `and wan_type ilike '${wanType}'` : '',
            recLimit: recLimit
        },
        format: "csv"
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => handleResponse(res, dispatch, GET_DASHBOARD_UPTIME_PIECHART_BYSITE))
        .catch((err) => handleError(err, dispatch));
};

export const getUptimeLinechart = (vessels, startDate, endDate, wanType, recLimit, selectedBin) => (dispatch) => {
    dispatch({ type: SET_DASHBOARD_UPTIME_LINECHART_BYWAN_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    let uptimeTableData: any = {
        query: 'DASHBOARD_UPTIME_LINECHART_BYWAN',
        named: true,
        parameters: {
            k4Id: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
            startTime: startDate,
            endTime: endDate,
            avail_window: getAvilWindow(selectedBin),
            wanType: wanType ? `and wan_type ilike '${wanType}'` : '',
            interval: isClickHouse ? getClickhouseBucketReformat(selectedBin) : selectedBin,
            recLimit: recLimit
        },
        format: "chart",
        tsColumn: "ts",
        series: "percUsage",
        groupBy: "wan_type"
    }
    if (isClickHouse) {
        delete uptimeTableData.parameters.interval;
        uptimeTableData.parameters.bucketInterval = getClickhouseBucketReformat(selectedBin);
    }
    api
        .postRequestOut(`${apiUrl}`, uptimeTableData)
        .then((res) => handleResponse(res, dispatch, GET_DASHBOARD_UPTIME_LINECHART_BYWAN))
        .catch((err) => handleError(err, dispatch));
};

export const getUptimeLinechartBySite = (vessels, startDate, endDate, wanType, recLimit, selectedBin, interval_min) => (dispatch) => {
    dispatch({ type: SET_DASHBOARD_UPTIME_LINECHART_BYSITE_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    let lineChart: any = {
        query: 'DASHBOARD_UPTIME_LINECHART_BYSITE',
        named: true,
        parameters: {
            k4Id: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
            startTime: startDate,
            endTime: endDate,
            interval_min: interval_min,
            wanType: wanType ? `and wan_type ilike '${wanType}'` : '',
            interval: isClickHouse ? getClickhouseBucketReformat(selectedBin) : selectedBin,
            recLimit: recLimit
        },
        format: "chart",
        tsColumn: "ts",
        series: "percUsage",
        groupBy: "NAME"
    }
    if (isClickHouse) {
        delete lineChart.parameters.interval;
        lineChart.parameters.bucketInterval = getClickhouseBucketReformat(selectedBin);
    }
    api
        .postRequestOut(`${apiUrl}`, lineChart)
        .then((res) => handleResponse(res, dispatch, GET_DASHBOARD_UPTIME_LINECHART_BYSITE))
        .catch((err) => handleError(err, dispatch));
};

export const getUptimeTableData = (vessels, startDate, endDate, wanType, page, limit, sort, sortOrder, search) => (dispatch) => {
    const avail_window = Math.ceil((Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3)) / 5);
    dispatch({ type: SET_DASHBOARD_UPTIME_SITESTATISTICS_TABLE_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const uptimeTableData: any = {
        query: 'DASHBOARD_UPTIME_SITESTATISTICS_TABLE',
        named: true,
        parameters: {
            k4Id: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
            startTime: startDate,
            endTime: endDate,
            pageStart: (page * limit),
            pageLimit: limit,
            sortColumn: sort,
            sortOrder: sort == 'name' ? `${sortOrder} collate 'en'` : sortOrder,
            avail_window: avail_window ? avail_window : '12',
            wanType: wanType ? `and wan_type ilike '${wanType}'` : '',
            search: search
        },
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, uptimeTableData)
        .then((res) => handleResponse(res, dispatch, GET_DASHBOARD_UPTIME_SITESTATISTICS_TABLE))
        .catch((err) => handleError(err, dispatch));
};

// Alerts Queries

export const getAlertTopWidget = (vessels, startDate, endDate, wanType, alertStatus) => (dispatch) => {
    dispatch({ type: SET_DASHBOARD_ALERT_TOPWIDGET_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const alertWidget: any = {
        query: 'DASHBOARD_ALERT_TOPWIDGET',
        named: true,
        parameters: {
            k4Id: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
            startTime: startDate,
            endTime: endDate,
            wanType: wanType ? `and wan_type ilike '%${wanType}%'` : '',
            status: (alertStatus.Alerting && !alertStatus.Normal) ? `where status in ('Alerting')` : (!alertStatus.Alerting && alertStatus.Normal) ? `where status in ('Normal')` : `where status in ('Normal','Alerting')`
        },
        format: "csv",
        groupBy: "wanType"
    }
    api
        .postRequestOut(`/store/ch/query`, alertWidget)
        .then((res) => handleResponse(res, dispatch, GET_DASHBOARD_ALERT_TOPWIDGET))
        .catch((err) => handleError(err, dispatch));
};

export const getAlertPiechartByName = (vessels, startDate, endDate, wanType, alertStatus) => (dispatch) => {
    dispatch({ type: SET_DASHBOARD_ALERT_PIECHART_BYNAME_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const typePieChart: any = {
        query: 'DASHBOARD_ALERT_PIECHART_BYNAME',
        named: true,
        parameters: {
            k4Id: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
            startTime: startDate,
            endTime: endDate,
            wanType: wanType ? `and wan_type ilike '%${wanType}%'` : '',
            status: (alertStatus.Alerting && !alertStatus.Normal) ? `and status in ('Alerting')` : (!alertStatus.Alerting && alertStatus.Normal) ? `and status in ('Normal')` : `and status in ('Normal','Alerting')`,
            alertGroup: ''
        },
        format: "csv"
    }
    api
        .postRequestOut(`/store/ch/query`, typePieChart)
        .then((res) => handleResponse(res, dispatch, GET_DASHBOARD_ALERT_PIECHART_BYNAME))
        .catch((err) => handleError(err, dispatch));
};

export const getAlertPiechartBySite = (vessels, startDate, endDate, wanType, alertStatus, limit) => (dispatch) => {
    dispatch({ type: SET_DASHBOARD_ALERT_PIECHART_BYSITE_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const sitePieChart: any = {
        query: 'DASHBOARD_ALERT_PIECHART_BYSITE',
        named: true,
        parameters: {
            k4Id: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
            startTime: startDate,
            endTime: endDate,
            wanType: wanType ? `and wan_type ilike '%${wanType}%'` : '',
            status: (alertStatus.Alerting && !alertStatus.Normal) ? `and status in ('Alerting')` : (!alertStatus.Alerting && alertStatus.Normal) ? `and status in ('Normal')` : `and status in ('Normal','Alerting')`,
            alertGroup: '',
            recLimit: limit
        },
        format: "csv"
    }
    api
        .postRequestOut(`${apiUrl}`, sitePieChart)
        .then((res) => handleResponse(res, dispatch, GET_DASHBOARD_ALERT_PIECHART_BYSITE))
        .catch((err) => handleError(err, dispatch));
};

export const getAlertLinechartByName = (vessels, startDate, endDate, wanType, alertStatus, selectedBin) => (dispatch) => {
    dispatch({ type: SET_DASHBOARD_ALERT_LINECHART_BYNAME_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    let typeLineChart: any = {
        query: 'DASHBOARD_ALERT_LINECHART_BYNAME',
        named: true,
        parameters: {
            k4Id: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
            startTime: startDate,
            endTime: endDate,
            wanType: wanType ? `and wan_type ilike '%${wanType}%'` : '',
            status: (alertStatus.Alerting && !alertStatus.Normal) ? `and status in ('Alerting')` : (!alertStatus.Alerting && alertStatus.Normal) ? `and status in ('Normal')` : `and status in ('Normal','Alerting')`,
            alertGroup: '',
            interval: isClickHouse ? getClickhouseBucketReformat(selectedBin) : selectedBin
        },
        format: 'chart',
        tsColumn: 'ts',
        series: 'count',
        groupBy: 'alertGroup',
    }
    if (isClickHouse) {
        delete typeLineChart.parameters.interval;
        typeLineChart.parameters.bucketInterval = getClickhouseBucketReformat(selectedBin);
    }
    api
        .postRequestOut(`/store/ch/query`, typeLineChart)
        .then((res) => handleResponse(res, dispatch, GET_DASHBOARD_ALERT_LINECHART_BYNAME))
        .catch((err) => handleError(err, dispatch));
};

export const getAlertLinechartBySite = (vessels, startDate, endDate, wanType, alertStatus, limit, selectedBin) => (dispatch) => {
    dispatch({ type: SET_DASHBOARD_ALERT_LINECHART_BYSITE_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    let siteLineChart: any = {
        query: 'DASHBOARD_ALERT_LINECHART_BYSITE',
        named: true,
        parameters: {
            k4Id: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
            startTime: startDate,
            endTime: endDate,
            wanType: wanType ? `and wan_type ilike '%${wanType}%'` : '',
            status: (alertStatus.Alerting && !alertStatus.Normal) ? `and status in ('Alerting')` : (!alertStatus.Alerting && alertStatus.Normal) ? `and status in ('Normal')` : `and status in ('Normal','Alerting')`,
            alertGroup: '',
            interval: isClickHouse ? getClickhouseBucketReformat(selectedBin) : selectedBin,
            recLimit: limit
        },
        format: 'chart',
        tsColumn: 'ts',
        series: 'count',
        groupBy: 'name',
    }
    if (isClickHouse) {
        delete siteLineChart.parameters.interval;
        siteLineChart.parameters.bucketInterval = getClickhouseBucketReformat(selectedBin);
    }
    api
        .postRequestOut(`${apiUrl}`, siteLineChart)
        .then((res) => handleResponse(res, dispatch, GET_DASHBOARD_ALERT_LINECHART_BYSITE))
        .catch((err) => handleError(err, dispatch));
};