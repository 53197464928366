import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import _ from "lodash";
import { BC_SOFTWARE_VERSION_DEVICES } from "../../constants/Constants";
import { getSoftwareVersionDeviceDetails } from "../../actions/Users/authenticateSoftware";
import { getDecodeURI, getEncodedURI} from "../../utils/util";
import "./softwareVersionsDeviceDetails.css";
import BackArrow from "../../asset/image/arrow_back.svg";
import ascSortingSelected from "../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../asset/image/descSortingSelected.svg";
import AscSort from "../../asset/image/AscSort.svg";
import DescSort from "../../asset/image/DescSort.svg";

const SoftwareVersionDeviceDetailsTable = (props) => {
    const { authReducer, match, getSoftwareVersionDeviceDetails,location } = props;
    const [selectedSoftwareVersion, setSelectedSoftwareVersion] = useState<any>(match?.params?.softwareVersion ? match.params.softwareVersion : "");
    let [devicesData, setDevicesData] = useState<any[]>([]);
    let breadcrumbInfo = BC_SOFTWARE_VERSION_DEVICES;
    let [selectedOu, setSelectedOu ] = useState<any>();
    const history = useHistory();

    const SITE_NAME = "locationName";
    const DID = "deviceId";
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";

    let _q = getDecodeURI(location?.search);
    const [sort, setSort] = useState<string>(_q.hasOwnProperty("sort")?_q.sort: SITE_NAME);
    const [sortOrder, setSortOrder] = useState<string>(_q.hasOwnProperty("sortOrder")?_q.sortOrder: ASC_ORDER);
    
    useEffect(() => {
        let _q = getDecodeURI(location?.search);
        let ouName = _q.hasOwnProperty("ouName") ? _q.ouName : (!_.isEmpty(authReducer.selectedOu) && authReducer.selectedOu.hasOwnProperty("name") && authReducer.selectedOu.name ? authReducer.selectedOu.name :"");
        let ouId = _q.hasOwnProperty("ouId") ? _q.ouId : (!_.isEmpty(authReducer.selectedOu) && authReducer.selectedOu.hasOwnProperty("id") && authReducer.selectedOu.id ? authReducer.selectedOu.id :"");
        let ou = {
            "id": ouId,
            "name": ouName
        }
        setSelectedOu(ou);
        // if (!_.isEmpty(authReducer.selectedOu) && authReducer.selectedOu.hasOwnProperty("id") && authReducer.selectedOu.id
        //     && authReducer.selectedOu.hasOwnProperty("name") && authReducer.selectedOu.name) {
        //     setSelectedOu(authReducer.selectedOu);
        // }
    }, []);

    useEffect(() => {
        if ("" !== selectedSoftwareVersion && selectedOu && selectedOu.hasOwnProperty("id") && selectedOu.id && selectedOu.hasOwnProperty("name") && selectedOu.name) {
            breadcrumbInfo[2] = { title: "null" !== selectedSoftwareVersion ? (selectedSoftwareVersion.indexOf("~") > -1 ? selectedSoftwareVersion.substring(0, selectedSoftwareVersion.indexOf("~")) : selectedSoftwareVersion) : "-" };
            getSoftwareVersionDeviceDetails(selectedOu.name, selectedOu.id, selectedSoftwareVersion, "", -1, -1, sort, sortOrder);
        } 
    }, [selectedOu]);

    useEffect(() => {
        if (!_.isEmpty(authReducer.getSoftwareVersionDeviceDetails)) {
            let data = authReducer.getSoftwareVersionDeviceDetails.hasOwnProperty("data") && Array.isArray(authReducer.getSoftwareVersionDeviceDetails.data) ? authReducer.getSoftwareVersionDeviceDetails.data : [];
            setDevicesData(data);
            authReducer.getSoftwareVersionDeviceDetails = {};
        }
    }, [authReducer.getSoftwareVersionDeviceDetails]);

    const doNavigateToSoftwares = (path) => {
        let params: any = getDecodeURI(location?.search);
        history.push({ pathname: path, search: `?${getEncodedURI(params)}` });
    }

    const gotoSoftwares = () => {
        doNavigateToSoftwares(`/softwareList`);
    }

    const doNavigate = (pathname, params = {}) => {
        history.push({ pathname: pathname, search: `?${getEncodedURI(params)}` });
    }

    const switchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sort === field) {
            order = ASC_ORDER === sortOrder ? DESC_ORDER : ASC_ORDER;
        }
        let params: any = getDecodeURI(location?.search);
        params.sort = field;
        params.sortOrder = order;

        setSort(field);
        setSortOrder(order);
        doNavigate(location.url, params);
        getSoftwareVersionDeviceDetails(selectedOu.name, selectedOu.id, selectedSoftwareVersion, "", -1, -1, sort, sortOrder);
    }

    const setSorting = (event: any, field: any, order: any) => {
        if (event && event.currentTarget) {
            let params: any = getDecodeURI(location?.search);
            params.sortTwo = field;
            params.sortOrderTwo = order;

            setSort(field);
            setSortOrder(order);

            doNavigate(location.url, params);
        getSoftwareVersionDeviceDetails(selectedOu.name, selectedOu.id, selectedSoftwareVersion, "", -1, -1, sort, sortOrder);
        }
    }

    return (
        <div className="Form">
            <Grid style={{ width: "100%", paddingTop: "80px" }}>
            <div className="margin-left25 align-back" onClick={gotoSoftwares}>
                    <img src={BackArrow} className="downloadImg" />
                </div>
                <Grid className="am-table" style={{ marginLeft: "11px" }}>
                    <Grid style={{ marginLeft: "18px", paddingRight: "25px" }}>
                        <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer menuScroll software-container margin-t-15">
                                <Table aria-label="simple sticky table" stickyHeader>
                                    <TableHead className="usageReports-tableHead">
                                        <TableRow className="UsageReports-tableRow login-tableRow">
                                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                <span className="login-history-dp-name paddingRight font-wt-900 margin-5" onClick={(event) => switchSorting(event, SITE_NAME)}>Site</span>
                                                <Grid className="sort-icon-margin">
                                                    <img src={sort === SITE_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort} alt=""
                                                        className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SITE_NAME, ASC_ORDER)} />
                                                    <img src={sort === SITE_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort} alt=""
                                                        className="sortingImages" onClick={(event) => setSorting(event, SITE_NAME, DESC_ORDER)} />
                                                </Grid>
                                                </span>
                                            </TableCell>
                                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                <span className="login-history-dp-name paddingRight font-wt-900 margin-5" onClick={(event) => switchSorting(event, DID)}>DID</span>
                                                <Grid className="sort-icon-margin">
                                                    <img src={sort === DID && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort} alt=""
                                                        className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, DID, ASC_ORDER)} />
                                                    <img src={sort === DID && sortOrder === DESC_ORDER ? descSortingSelected : DescSort} alt=""
                                                        className="sortingImages" onClick={(event) => setSorting(event, DID, DESC_ORDER)} />
                                                </Grid>
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {devicesData && 0 != devicesData.length ? (
                                        <TableBody className="tableBody usage-tableBody">
                                            {
                                                devicesData && devicesData.map((row, i) =>
                                                    <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr height40">
                                                        <TableCell className="login-left-align inter-font">
                                                        {row.locationName}
                                                        </TableCell>
                                                        <TableCell className="login-left-align inter-font">
                                                        {row.deviceId}
                                                        </TableCell>
                                                    </TableRow>

                                                )
                                            }
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={12} className="noDataAvailableCss" align="center">No data available</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                    </Grid>
                </Grid>
            </Grid>

        </div>
    );
};

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {getSoftwareVersionDeviceDetails})(SoftwareVersionDeviceDetailsTable)
);