import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Grid, Input, Paper } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import GeneratedLinks from "./GeneratedLinks";
import GenerateLinkDialog from "./GenerateLinkDialog";
import CreateScannedDeviceDialog from "./CreateScannedDeviceDialog";
import EdgeLinkIcon from "../../../asset/image/EdgeLinkIcon.svg";
import K4Logo from '../../../asset/image/headerLogo.svg';
import EdgeLinkIconDisabled from "../../../asset/image/linkDisabled.svg";
import SelectAllIcon from '@mui/icons-material/SelectAll';

import { getScannedDevices, deleteScannedDevice, updateScannedDevice, clearData } from "../remoteConnectionSlice";
import { Edit, Save } from "@material-ui/icons";
import DoneIcon from '@material-ui/icons/Done';

import "./index.css";

interface ScannedDevice {
    authReducer: any
    id: string;
    siteId: string;
    deviceId: string;
    alias: string;
    address: string;
    port: number;
    type: string;
    userCreated: boolean;
    createdAt: string;
    updatedAt: string;
}

function LinksContainer(props) {

    const { authReducer, scannedDevices, siteId, siteName, deviceId, filter, getScannedDevices, clearData } = props
    const [selectedDevice, setSelectedDevice] = useState<any>();
    const [multipleSelected, setMultipleSelected] = useState({});


    useEffect(() => {
        let requestAbortController = new AbortController();

        if (!_.isEmpty(siteId) && !_.isEmpty(deviceId)) {
            clearData()
            getScannedDevices(siteId, deviceId, filter, requestAbortController.signal);
            setSelectedDevice({});
            setMultipleSelected({});
        }

        return () => {
            requestAbortController.abort();
        }
    }, [siteId, deviceId, filter])


    return (
        <Paper elevation={0}>
            <div className="rc--generated_links_container">
                <div className="rc--links_table">
                    <GeneratedLinks selectedScannedDevices={multipleSelected} siteName={siteName} siteId={siteId} deviceId={deviceId} filter={filter} />
                </div>
            </div>
        </Paper>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    scannedDevices: state.remoteConnection.scannedDevices,
    generatedLinks: state.remoteConnection.generatedLinks
});

export default connect(mapStateToProps, {
    getScannedDevices,
    deleteScannedDevice,
    updateScannedDevice,
    clearData
})(LinksContainer)