import PasswordValidattor from 'react-password-validattor';
import "./CustomPasswordValidator.css"

export default function CustomPasswordValidator ({newPassword, reEnterPassword, onValidatorChangeHandler, styles = {}}) {
    return (
        <div style={styles}>
           <PasswordValidattor 
                  rules={[
                    'minLength', 
                    'maxLength', 
                    'specialChar', 
                    'number', 
                    'capital', 
                    'matches', 
                    'notEmpty', 
                  ]}
                  minLength={8}
                  maxLength={29}
                  password={newPassword}
                  confirmedPassword={reEnterPassword}
                  iconSize={12}
                  onValidatorChange={onValidatorChangeHandler}
                  config={{ 
                    showProgressBar: true, 
                    showPasswordSuggestion: true,
                    customText: {
                      minLength: {
                        successText: "Min length 8 characters",
                        errorText: "Provide at least 8 characters"
                      },
                      maxLength: {
                        successText: "Max length 29 characters",
                        errorText: "Provide less than 29 characters"
                      },
                      specialChar: {
                        successText: "Contains special character",
                        errorText: "Provide at least 1 special character"
                      },
                      capital: {
                        successText: "Contains upper case",
                        errorText: "Provide at least 1 upper case"
                      },
                      number: {
                        successText: "Password contains number",
                        errorText: "Provide at least 1 number"
                      },
                      matches: {
                        successText: "Passwords match",
                        errorText: "Password should match with confirm password"
                      },
                    },
                    classNames: {
                      containerClass: 'custom-password-validator'
                    }
                  }} 
              />
        </div>
    )
}