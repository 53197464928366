import {  Fragment, useState, useEffect, useContext, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Grid, Collapse, TextField, Autocomplete, Divider } from "@mui/material";
import _ from "lodash";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import close from "../../asset/image/close.svg";
import "./usageReportsFilters.css";
import "../../components/RemoteConnections/index.css";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { UsageContext } from "../../UserScreen/UserData/UsageDataContext";
import { 
    getAllUsernames,
  } from "../../actions/Users/authenticateUsageReports";

 const UsageReportFilters = (props) => {
    const { authReducer,filterPopup,getAllUsernames, setFilterPopup, newSummaryStartDate, newSummaryEndDate } = props;
    const [userNames, setUserNames] = useState<any>([]);
    const [expanded, isExpanded] = useState(true);

    const { startDate, endDate, handleStartDatePicker, handleEndDatePicker, handleUsernameChange, handleCategoryChange, userName, category, applyFilterClick, clearFilters,selectedOu,selectedVessels, vessels, setTrackFilter} = useContext(UsageContext);

    useEffect(()=> {
        if(filterPopup) {
            getAllUsernames(selectedOu, vessels, newSummaryStartDate, newSummaryEndDate);
        }
        setTrackFilter(false);
    },[selectedOu,selectedVessels, vessels, filterPopup])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.getAllUsernames)) {
        const data = authReducer?.getAllUsernames?.hasOwnProperty('data') ? authReducer?.getAllUsernames?.data: {};
        if (data) {
            const rows: any[] = data?.rows;
            const columns: string[] = data?.columns;
            const usernames: any[] = [];
            for (let i = 0; i < rows?.length; i++) {
                const row = {};
                const currentRow = rows[i];
                columns.forEach((column, index) => {
                    console.log('column', column)
                    if (column == 'userName') {
                        row['label'] = currentRow[index]
                    } else {
                        row[column] = currentRow[index];
                    }
                })
                usernames.push(row);
            }
            // To fetch unique usernames as API was sending duplicates
            // const users = usernames.map(value => value.userName);
            setUserNames(usernames);
            authReducer.getAllUsernames = {};
        }
    }
    }, [authReducer?.getAllUsernames])

     const CategoryList = [
         'Advertiser',
         'Arts and Entertainment',
         'Business',
         'Career and Education',
         'CDN',
         'Cybersecurity',
         'Device/IoT',
         'File Sharing',
         'Financial',
         'Games',
         'Government',
         'Hosting',
         'ISP/Telco',
         'Mail',
         'Malware',
         'Messaging',
         'News',
         'OS/Software Updates',
         'Portal',
         'Recreation',
         'Reference',
         'Remote Desktop',
         'Shopping',
         'Social Media',
         'Sports',
         'Streaming Media',
         'Technology',
         'VoIP/Conferencing',
         'VPN and Proxy'
     ]

    return (
        <div className="Form">
            <Grid className="am-table">
                <Grid className="dash-filter-pop">
                    <Grid>
                        <Grid className="filter-clearFilter">
                            <div className="Filters_Title no-padding">Reports Filters</div>
                            <img src={close} onClick={() => setFilterPopup(false)} className="dialog_closeIcon-filters" alt="Close" />
                            
                        </Grid>
                        <Divider />
                        <Grid>
                            <div className="rc--filter_section">
                                <SearchUsageFilter name={'MAC/IP'} options={userNames} handleChange={handleUsernameChange} value={userName} />
                            </div>
                        </Grid>
                        <Grid>
                            <div className="rc--filter_section">
                                <SearchUsageFilter name={'Application Category'} options={CategoryList} handleChange={handleCategoryChange} value={category} />
                            </div>
                        </Grid>
                    </Grid>
                      <div className="clearApplyFilters">
                        <Button onClick={() => {
                          clearFilters();
                          setFilterPopup(false);
                        }} className="clearFiltersButton">Clear Filter</Button>

                        <Button className="usage-applyFilterBtn" onClick={()=>{
                            applyFilterClick();
                            setFilterPopup(false);
                        }}>Apply Filter</Button>
                      </div> 
                </Grid>
            </Grid>
        </div>
    )
    
    interface Props {
        options: any;
        name: string;
        value: string;
        handleChange: any;
    }

    function SearchUsageFilter({ name, options, handleChange, value }: Props) {
        const [expanded, isExpanded] = useState(true);

        const onKeyDown = (e: React.KeyboardEvent) => {
            e.stopPropagation();
        }
     
        return (
            <Fragment>
                <div className="rc--filter_header">
                    <div className="rc--content_sub_headers-select">{name}</div>
                </div>
                    <div className="rc--filter_search_container">
                        <div className="rc--filter_search userName-usageData">
                            <Autocomplete
                                disableClearable={value ? false : true}
                                ListboxProps={{ style: { maxHeight: 200, fontSize:'13px' } }}
                                size="small"
                                className="audit-filter-inputs-usage"
                                value={value}
                                onChange={(e, newValue) => { handleChange(e, newValue) }}
                                options={options}
                                filterSelectedOptions
                                renderInput={(params) => (
                                <TextField {...params} variant="outlined" onKeyDown={onKeyDown} placeholder="search" />
                                )}
                            />
                        </div>
                    </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {
        getAllUsernames
    })(UsageReportFilters)
);