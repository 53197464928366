import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Box, Grid, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getActivityUsageTableData } from "../../../actions/Users/authenticateTrends";
import { getDecodeURI, getEncodedURI, readableBytesAsGB } from "../../../utils/util";
import { Pagination } from "../../Pagination";
import ascSortingSelected from "../../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../../asset/image/descSortingSelected.svg";
import AscSort from "../../../asset/image/AscSort.svg";
import DescSort from "../../../asset/image/DescSort.svg";
import _ from "lodash";
import './activityTopUser.css';
import FilterChips from "./filterChips";
import ImageWithFallback from "../../ImageWithFallback";

const ActivityTopUserTable = (props) => {
    const { authReducer, getActivityUsageTableData, newSummaryStartDate, newSummaryEndDate, activityReportsTopUsersLoading, location, history } = props;

    const _q = getDecodeURI(location?.search);
    const DEFAULT_PAGE_SIZE = 10;
    const [search, setSearchValue] = useState('');
    const [pageNumber, setPage] = useState(_q.hasOwnProperty('pageNumber') ? parseInt(_q.pageNumber) : 1);
    const [pageLimit, setLimit] = useState(_q.hasOwnProperty('pageLimit') ? parseInt(_q.pageLimit) : DEFAULT_PAGE_SIZE);
    const [userName, setUserName] = useState(_q.hasOwnProperty('user') ? _q.user : '');
    const [deviceFilt, setDeviceFilt] = useState(_q.hasOwnProperty('device') ? _q.device : '');
    const [vlanFilt, setVlanFilt] = useState(_q.hasOwnProperty('vlan') ? _q.vlan : '');
    const [tableData, setTableData] = useState<any>([]);
    const [totalCount, setTotalCount] = useState(0);
    const SITE_NAME = 'site';
    const DEVICE = 'deviceAliasName';
    const VLAN = 'vlanName';
    const USER = 'hostname';
    const IP = 'ipAddress';
    const MAC = 'macAddress';
    const TOTAL = 'total';
    const UPLOAD = 'upload';
    const DOWNLOAD = 'download';
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";
    const ENTER_KEY_CODE = 'Enter';
    const [sortColumn, setSort] = useState<any>(_q.hasOwnProperty("sortColumn") ? _q.sortColumn : SITE_NAME);
    const [orderSort, setSortOrder] = useState<any>(_q.hasOwnProperty("orderSort") ? _q.orderSort : ASC_ORDER);
    const [vessels, setVessels] = useState([]);

    useEffect(() => {
        if (_.isEmpty(authReducer?.getVesselsListing))
            return;
        let vessels = [];
        const filteredVessels = authReducer?.filteredVessels;
        if (filteredVessels && filteredVessels.length > 0) {
            vessels = filteredVessels?.filter((el) => el?.id !== '');
        } else {
            vessels = authReducer?.getVesselsListing?.locations?.filter((el) => el?.id !== '');
        }
        setVessels(vessels);
    }, [authReducer?.getVesselsListing, authReducer?.filteredVessels])

    useEffect(() => {
        if(vessels?.length == 1) {
            getActivityUsageTableData('', vessels, newSummaryStartDate, newSummaryEndDate, '', '', '', pageNumber, pageLimit, '', '', search, sortColumn, orderSort);
        } else {
            setTableData([]);
            setTotalCount(0);
        }
        let params: any = getDecodeURI(location?.search);
        delete params.user;
        delete params.device;
        delete params.vlan;
        doNavigate(params);
        setUserName('');
        setDeviceFilt('');
        setVlanFilt('');
    }, [vessels, newSummaryStartDate, newSummaryEndDate])

    useEffect(() => {
        if(!_.isEmpty(authReducer.UsageTableData)) {
            const data = authReducer.UsageTableData.hasOwnProperty('data') ? authReducer.UsageTableData.data : {};
            setTotalCount(Number(data?.totalCount?.data[0]))
            if (data) {
                let cols: any = [];
                let result: any = [];
                for (var key in data) {
                    cols.push(key);
                }

                data[cols[0]]?.data?.forEach((el, i) => {
                    let rowObj: any = {};
                    cols.forEach((ele) => {
                        rowObj[ele] = data[ele].data[i]
                    })
                    result.push(rowObj);
                })
                result && result?.length > 0 && result.shift();
                setTableData(result);
            }
        }
    }, [authReducer.UsageTableData])

    const handleChangePage = (e, value) => {
        setPage(value);
        vessels && getActivityUsageTableData('', vessels, newSummaryStartDate, newSummaryEndDate, userName, '', '', value, pageLimit, vlanFilt, deviceFilt, search, sortColumn, orderSort);
    }

    const handleChangeRowsPerPage = (e) => { 
        const Limit = parseInt(e.target.value);
        const Page = 1;
        let params: any = getDecodeURI(location?.search);
        delete params.pageNumber;
        Limit == 10 ? delete params.pageLimit : params.pageLimit = Limit;
        setPage(1);
        setLimit(Limit);
        doNavigate(params);
        vessels && getActivityUsageTableData('', vessels, newSummaryStartDate, newSummaryEndDate, userName, '', '', Page, Limit, vlanFilt, deviceFilt, search, sortColumn, orderSort);
    }

    const Sorting = (field, order) => {
        let params: any = getDecodeURI(location?.search);
        delete params.pageNumber;
        params.sortColumn = field;
        params.orderSort = order;
        const Page = 1;
        setPage(1);
        setSort(field);
        setSortOrder(order);
        doNavigate(params);
        vessels && getActivityUsageTableData('', vessels, newSummaryStartDate, newSummaryEndDate, userName, '', '', Page, pageLimit, vlanFilt, deviceFilt, search, field, order);
    }

    const setSorting = (event: any, field: any, order: any,) => {
        if (event && event.currentTarget) {
            Sorting(field, order)
        }
    };

    const switchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sortColumn === field) {
            order = ASC_ORDER === orderSort ? DESC_ORDER : ASC_ORDER;
        }
        Sorting(field, order)
    };

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch();
        }
    }

    const doSearch = () => {
        let params: any = getDecodeURI(location?.search);
        search ? params.search = search : delete params.search;
        delete params.pageNumber;
        const Page = 1;
        setPage(1);
        doNavigate(params);
        vessels && getActivityUsageTableData('', vessels, newSummaryStartDate, newSummaryEndDate, userName, '', '', Page, pageLimit, vlanFilt, deviceFilt, search, sortColumn, orderSort);
    };

    const handleFilterClick = (row, key) => {
        switch(key) {
            case 'device' : {
                const value = row ? row.deviceAliasName : '';
                setDeviceFilt(value);
                ApplyFilters(value, vlanFilt, userName);
                break;
            }
            case 'vlan' : {
                const value = row ? row.vlanName : '';
                setVlanFilt(value);
                ApplyFilters(deviceFilt, value, userName);
                break;
            }
            case 'user' : {
                const value = row ? row.hostname : '';
                setUserName(value);
                ApplyFilters(deviceFilt, vlanFilt, value);
                break;
            }
            case 'ip' : {
                const value = row.ipAddress;
                setUserName(value);
                ApplyFilters(deviceFilt, vlanFilt, value);
                break;
            }
            case 'mac' : {
                const value = row.macAddress;
                setUserName(value);
                ApplyFilters(deviceFilt, vlanFilt, value);
                break;
            }
        }
    }

    const ApplyFilters = (device, vlan, user) => {
        let params: any = getDecodeURI(location?.search);
        device ? params.device = device : delete params.device;
        vlan ? params.vlan = vlan : delete params.vlan;
        user ? params.user = user : delete params.user;
        delete params.pageNumber;
        const Page = 1;
        setPage(1);
        doNavigate(params);
        vessels && getActivityUsageTableData('', vessels, newSummaryStartDate, newSummaryEndDate, user, '', '', Page, pageLimit, vlan, device, search, sortColumn, orderSort);
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const rowsOption = [10, 15, 25, 50, 100];

    function GetTopAppsImage (props)  {
        const { app } = props;
        if(app) {
          let appName = app.includes(' ') ? app.split(' ')[0]: app.includes('-') ? app.split('-')[0] : ""; 
          return <ImageWithFallback src={`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${app.trim()}`} fallbackSrcs={[`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${appName.trim()}`,"https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png"]} app={app} />
        } else {
          return <img className="logo-activity paddingLogo" src={`https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png`} />
        }
    
      }

    return (
        <div className="new-summary-single-site-table">
            <Grid container className="new-top-user-table-container">
                <Grid item xs={12} sm={12} md={12} lg={12} className="search-box--filter-chips search-dropdown-limit-div alignTopbarItemsCenter">
                    <Grid className="activity-usage-site-details-header">{`User Statistics ( ALL WAN )`}</Grid>
                    <Grid className="summary-top-user-chips">
                        <FilterChips userName={userName} deviceFilt={deviceFilt} vlanFilt={vlanFilt} handleFilterClick={handleFilterClick} />
                    </Grid>
                    <Grid className="margin-left-auto trendsUsageSearchbarMargin">
                        <Box className="searchbarBox">
                            {<TextField
                                id="outlined-basic"
                                variant="outlined"
                                placeholder="Type something"
                                size="small"
                                value={search}
                                onChange={(event) => {
                                    setSearchValue(event.target.value);
                                }}
                                onKeyDown={(event) => searchBoxKeyDown(event)}
                                InputLabelProps={{ className: "serachLabel" }}
                                InputProps={{
                                    className: "searchbarInputs",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon
                                                className="searchbarIconsPosition"
                                                onClick={doSearch}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />}
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Paper className="pagination-position config-pagination activity-users-pagination remove-shadow">
                        <Pagination
                            rowsPerPageOptions={rowsOption}
                            count={totalCount}
                            rowsPerPage={pageLimit}
                            page={pageNumber}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <TableContainer className="usageReports-FleetDashboard new-summary-top-users-table">
                        <Table aria-label="simple table">
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="UsageReports-tableRow login-tableRow">
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name" onClick={(event) => switchSorting(event, SITE_NAME)}>SITE</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sortColumn === SITE_NAME && orderSort === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SITE_NAME, ASC_ORDER)} />
                                                <img src={sortColumn === SITE_NAME && orderSort === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, SITE_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name" onClick={(event) => switchSorting(event, DEVICE)}>DEVICE</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sortColumn === DEVICE && orderSort === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, DEVICE, ASC_ORDER)} />
                                                <img src={sortColumn === DEVICE && orderSort === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, DEVICE, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left user-width-7per">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name" onClick={(event) => switchSorting(event, VLAN)}>VLAN</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sortColumn === VLAN && orderSort === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, VLAN, ASC_ORDER)} />
                                                <img src={sortColumn === VLAN && orderSort === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, VLAN, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="usage-fleetDataTable topApp-sitestable">Wan Type</TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name" onClick={(event) => switchSorting(event, USER)}>userName</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sortColumn === USER && orderSort === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, USER, ASC_ORDER)} />
                                                <img src={sortColumn === USER && orderSort === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, USER, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name" onClick={(event) => switchSorting(event, IP)}>IP Address</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sortColumn === IP && orderSort === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, IP, ASC_ORDER)} />
                                                <img src={sortColumn === IP && orderSort === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, IP, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name" onClick={(event) => switchSorting(event, MAC)}>MAc Address</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sortColumn === MAC && orderSort === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, MAC, ASC_ORDER)} />
                                                <img src={sortColumn === MAC && orderSort === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, MAC, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left user-width-7per">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name" onClick={(event) => switchSorting(event, TOTAL)}>Total</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sortColumn === TOTAL && orderSort === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, TOTAL, ASC_ORDER)} />
                                                <img src={sortColumn === TOTAL && orderSort === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, TOTAL, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left user-width-7per">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name" onClick={(event) => switchSorting(event, UPLOAD)}>Upload</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sortColumn === UPLOAD && orderSort === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, UPLOAD, ASC_ORDER)} />
                                                <img src={sortColumn === UPLOAD && orderSort === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, UPLOAD, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left user-width-7per">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name" onClick={(event) => switchSorting(event, DOWNLOAD)}>Download</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sortColumn === DOWNLOAD && orderSort === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, DOWNLOAD, ASC_ORDER)} />
                                                <img src={sortColumn === DOWNLOAD && orderSort === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, DOWNLOAD, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="usage-fleetDataTable topApp-sitestable topApp-sitestable-user user-width-7per">Top Apps</TableCell>
                                </TableRow>
                            </TableHead>
                            {0 != tableData.length && !activityReportsTopUsersLoading ? (
                                <TableBody className="tableBody usage-tableBody">
                                    {
                                        tableData && tableData.map((row, i) =>
                                            <TableRow key={i} className="usageData-tabelRow activity-new-tableRow">
                                                <TableCell className="usagedata-tableCell leftAllign">{row.site}</TableCell>
                                                <TableCell className="usagedata-tableCell leftAllign selected-site" onClick={() => handleFilterClick(row, 'device')}>{row.deviceAliasName}</TableCell>
                                                <TableCell className="usagedata-tableCell leftAllign selected-site" onClick={() => handleFilterClick(row, 'vlan')}>{row.vlanName}</TableCell>
                                                <TableCell className="usagedata-tableCell leftAllign">{row.wan_type}</TableCell>
                                                { row.hostname !== 'NA' ? 
                                                    <TableCell className="usagedata-tableCell leftAllign selected-site" onClick={() => handleFilterClick(row, 'user')}>{row.hostname}</TableCell>
                                                    :
                                                    <TableCell className="usagedata-tableCell leftAllign">{row.hostname}</TableCell>
                                                }
                                                <TableCell className="usagedata-tableCell leftAllign selected-site" onClick={() => handleFilterClick(row, 'ip')}>{row.ipAddress}</TableCell>
                                                <TableCell className="usagedata-tableCell leftAllign selected-site" onClick={() => handleFilterClick(row, 'mac')}>{row.macAddress}</TableCell>
                                                <TableCell className="usagedata-tableCell leftAllign">{readableBytesAsGB(row.total)}</TableCell>
                                                <TableCell className="usagedata-tableCell leftAllign">{readableBytesAsGB(row.upload)}</TableCell>
                                                <TableCell className="usagedata-tableCell leftAllign">{readableBytesAsGB(row.download)}</TableCell>
                                                <TableCell className="usageData-tabelCell">
                                                    <Grid className="tableSites-topIcons activity-top-sites user-top-apps">
                                                        { tableData[i]?.topApps?.split(',')?.map((app) => (
                                                            <Tooltip title={app}>
                                                                    <GetTopAppsImage app={app} />
                                                            </Tooltip>
                                                        ))}
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={9} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}


const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    activityReportsTopUsersLoading: state?.authReducer?.activityReportsTopUsersLoading,
    errorReducer: state.errorReducer,
  });
  
export default withRouter(
   connect(mapStateToProps, { getActivityUsageTableData })(ActivityTopUserTable)
)