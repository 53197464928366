import { FormControl, FormControlLabel, Grid, IconButton, MenuItem, Select } from "@mui/material";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import barIcon from '../../../asset/image/bar_chart_icon.svg';
import lineIcon from '../../../asset/image/line_chart_icon.svg';
import bar_chart from '../../../asset/image/bar_chart_light.svg';
import line_chart from '../../../asset/image/line_chart.svg';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import './chartComponent.css';
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getMinutes } from "../../../utils/util";

const WanLineChart = (props) => {
    const {title, data, setChartView, resetDate, handleResetZoom, handleDefaultResetZoom, options, handleLegendClick, chartView, selectedBin, handleBinChange, startDate, endDate} = props;

    return (
        <Grid>
            <Grid className="legends-pie-new-container chart-legends-histogram">
                <Grid className="title-text">{title}</Grid>
                <Grid className="legends-view-images">
                    <Grid className="time-series-legends-new">
                        {data && data.map((usage, i) => (
                            <Grid className="summary-pie-bar-legends">
                                <div className="summary-pie-usage-dot" style={{ background: usage?.color }}></div>
                                <span className={data[i]?.visible ? "barChart-legends" : 'disable-barChart-legends'} onClick={() => handleLegendClick(i)}>{usage.name}</span>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid className="line-bar-view">
                        <Grid className="pieChat-selectTag">
                            <FormControl variant="standard" className='selectEntry-pie'>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectedBin}
                                    onChange={handleBinChange}
                                >
                                    {getMinutes(startDate, endDate) >= 5 && <MenuItem value={'5m'}>5m</MenuItem>}
                                    {getMinutes(startDate, endDate) >= 10 && <MenuItem value={'10m'}>10m</MenuItem>}
                                    {getMinutes(startDate, endDate) >= 15 && <MenuItem value={'15m'}>15m</MenuItem>} 
                                    {getMinutes(startDate, endDate) >= 30 && <MenuItem value={'30m'}>30m</MenuItem>} 
                                    {getMinutes(startDate, endDate) >= 60 && <MenuItem value={'1h'}>1h</MenuItem>}
                                    {getMinutes(startDate, endDate) >= 120 && <MenuItem value={'2h'}>2h</MenuItem>}
                                    {getMinutes(startDate, endDate) >= 180 && <MenuItem value={'3h'}>3h</MenuItem>}
                                    {getMinutes(startDate, endDate) >= 360 && <MenuItem value={'6h'}>6h</MenuItem>}
                                    {getMinutes(startDate, endDate) >= 720 && <MenuItem value={'12h'}>12h</MenuItem>}
                                    {getMinutes(startDate, endDate) >= 1440 && <MenuItem value={'1d'}>1d</MenuItem>}
                                    {getMinutes(startDate, endDate) >= 10080 && <MenuItem value={'7d'}>7d</MenuItem>}
                                </Select>
                            </FormControl>
                        </Grid>
                        <img onClick={() => setChartView('column')} src={chartView == 'column' ? barIcon : bar_chart} />
                        <img onClick={() => setChartView('spline')} src={chartView == 'spline' ? line_chart : lineIcon} />
                    </Grid>
                    {resetDate.length > 0 &&
                        <div>
                            <FormControlLabel
                                value="Zoom Back"
                                control={<IconButton onClick={handleResetZoom}><ZoomOutIcon /></IconButton>}
                                label=""
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value="Zoom Reset"
                                control={<IconButton onClick={handleDefaultResetZoom}><RestartAltIcon /></IconButton>}
                                label=""
                                labelPlacement="start"
                            />
                        </div>
                    }
                </Grid>
            </Grid>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    startDate: state?.authReducer?.newSummaryStartDate,
    endDate: state?.authReducer?.newSummaryEndDate
});

export default withRouter(
  connect(mapStateToProps, { })(WanLineChart)
);