import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Switch, TextField, Button, Tooltip } from "@mui/material";
import "./GroupSettings.css";
import { useEffect, useState } from "react";
import { getGroupSettingsData, updateOrganizationSettingsData } from "../../actions/Users/authenticateGroupSettings";
import _ from "lodash"
import { useLocation } from "react-router-dom";
import { getDecodeURI } from "../../utils/util";
import CustomLoader from "../Loader/CustomLoaders";
import { toast } from "react-toastify";
import ConfirmDialog from "../../components/ConfirmDialog";
import {
    CONFIRM_DIALOG_TITLE,
    UPDATE_ORGANIZATION_TWO_FACTOR_AUTH,
    UPDATE_ORGANIZATION
} from "../../config";
import { KNOWLEDGE_CENTER } from "../../utils/constants";
import HelpIcon from '@mui/icons-material/Help';

const DEFAULT_2FA_TIMEOUT_IN_DAYS = 7
const DEFAULT_PASSWORD_EXPIRY_TIMEOUT_IN_DAYS = 60

const GroupSettings = (props) => {
    const { authReducer, getGroupSettingsData, updateOrganizationSettingsData } = props
    const [groupSettingsData, setGroupSettingsData] = useState({})
    const [twoFAEnabled, setTwoFAEnabled] = useState(false)
    const [twoFATimeout, setTwoFATimeout] = useState<Number>(DEFAULT_2FA_TIMEOUT_IN_DAYS)
    const [whiteLabeled, setWhiteLabel] = useState(false)
    const [sportDomain, setSportDomain] = useState("")
    const [captiveAccess, setCaptiveAccess] = useState(false)
    const [email, setEmail] = useState("")
    const [emailSignature, setEmailSignature] = useState("")
    const [twoFATimeoutDisabled, setTwoFATimeoutDisabled] = useState(false)
    const [showLoader, setShowLoader] = useState(false);
    const [loadingText, setLoadingText] = useState("");
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: {},
    });
    const [confirmClick, setConfirmClick] = useState(false);
    const [isEditing, setEditing] = useState(false);
    const [microsoftTeamsChannelName, setMicrosoftTeamsChannelName] = useState("");
    const [microsoftTeamsChannelURL, setMicrosoftTeamsChannelURL] = useState("");
    const [notificationEmails, setNotificationEmails] = useState("");
    const [passwordExpiryEnabled, setPasswordExpiry] = useState(false)
    const [passwordExpiryInDays, setPasswordExpiryInDays] = useState<Number>(DEFAULT_PASSWORD_EXPIRY_TIMEOUT_IN_DAYS)

    const location = useLocation()
    const _q = getDecodeURI(location?.search)
    const ouName = _q.hasOwnProperty("ouName") ? _q.ouName : ""
    const ouId = _q.hasOwnProperty("ouId") ? _q.ouId : ""
    let groupDocumentsPageURL = "/"+KNOWLEDGE_CENTER+"?tab=Documents"
    if (ouName && ouId) {
        groupDocumentsPageURL += "&ouName=" + ouName + "&ouId=" + ouId
    }

    useEffect(() => {
        if (!_.isEmpty(authReducer.selectedOu) && authReducer.selectedOu?.id) {
            setGroupSettingsData({})
            getGroupSettingsData(authReducer.selectedOu.id)
        }
    }, [authReducer.selectedOu]);

    useEffect(() => {
        if (!_.isEmpty(authReducer.getGroupSettingsData)) {
            setEditing(false)
            setGroupSettingsData(authReducer.getGroupSettingsData?.data)
            setTwoFAEnabled(authReducer.getGroupSettingsData?.data?.twofaEnabled)
            if (authReducer.getGroupSettingsData?.data?.mfaTimeout == 0){
                setTwoFATimeout(0)
            } else{
                let timeoutInDays = authReducer.getGroupSettingsData?.data?.mfaTimeout / (24 * 60)
                setTwoFATimeout(timeoutInDays)
            }
            setWhiteLabel(authReducer.getGroupSettingsData?.data?.whiteLabel)
            setSportDomain(authReducer.getGroupSettingsData?.data?.sportDomain)
            setCaptiveAccess(authReducer.getGroupSettingsData?.data?.cativeAccess)
            setEmail(authReducer.getGroupSettingsData?.data?.email)
            setEmailSignature(authReducer.getGroupSettingsData?.data?.emailSignature)
            setTwoFATimeoutDisabled(!authReducer.getGroupSettingsData?.data?.twofaEnabled)
            setMicrosoftTeamsChannelName(authReducer.getGroupSettingsData?.data?.microsoftTeamsChannelName)
            setMicrosoftTeamsChannelURL(authReducer.getGroupSettingsData?.data?.microsoftTeamsChannelWebhookUrl)
            let notificationEmails = authReducer.getGroupSettingsData?.data?.notificationEmails
            setNotificationEmails(notificationEmails.join(","))
            setPasswordExpiry(authReducer.getGroupSettingsData?.data?.passwordExpiryEnabled)
            setPasswordExpiryInDays(authReducer.getGroupSettingsData?.data?.passwordExpiryInDays)
            authReducer.getGroupSettingsData = {}
        }
    }, [authReducer.getGroupSettingsData]);
    
    useEffect(() => {
        if (!_.isEmpty(authReducer.updateGroupSettingsData)) {
            setShowLoader(false)
            toast.success("Organization settings updated successfully", {
                position: toast.POSITION.BOTTOM_LEFT,
            })
            authReducer.updateGroupSettingsData = {}
            getGroupSettingsData(authReducer.selectedOu.id)
        }
    }, [authReducer.updateGroupSettingsData]);

    useEffect(()=>{
        if(confirmClick){
            setConfirmClick(false)
        }
    }, [confirmClick])

    const getValue = (key) => {
        let value:any = ""
        if (!_.isEmpty(groupSettingsData) && groupSettingsData.hasOwnProperty(key)) {
            value = groupSettingsData[key]
            if (typeof value == "boolean") {
                if (value) {
                    value = "Enabled"
                } else {
                    value = "NA"
                }
            }
        }
        return _.isEmpty(value) ? "NA" : value
    }

    const  onUpdateTwoFA = (e:any)=>{
        let checked = e?.target?.checked
        if (checked != twoFAEnabled){
            setConfirmDialog({
                isOpen: true,
                title: CONFIRM_DIALOG_TITLE,
                subTitle: UPDATE_ORGANIZATION_TWO_FACTOR_AUTH,
                onConfirm: () => {
                    setTwoFATimeoutDisabled(!checked)
                    if (!checked){
                        setTwoFATimeout(DEFAULT_2FA_TIMEOUT_IN_DAYS)
                    }
                    setTwoFAEnabled(checked)
                    setConfirmClick(true)
                },
              });
        }
    }

    const  onUpdateTwoFATimeout = (e:any)=>{
        let twoFATimeout = e?.target?.value
        if (Number(twoFATimeout) >= 0 && Number(twoFATimeout) <= 365){
            setTwoFATimeout(Number(twoFATimeout))
        }
    }

    const onUpdateEmailSignature = (e:any) => {
        setEmailSignature(e.target.value)
    }

    const  onUpdateCaptiveAccess = (e:any)=>{
        let checked = e?.target?.checked
        setCaptiveAccess(checked)
    }

    const editOrganizationSettings = ()=>{
        setEditing(!isEditing)
    }

    const cancelOrganizationSettings = ()=>{
        setEditing(!isEditing)
    }

    const  onChangeMicrosoftTeamsChannelName = (e:any)=>{
        let value = e?.target?.value
        setMicrosoftTeamsChannelName(value)
    }

    const  onChangeMicrosoftTeamsChannelURL = (e:any)=>{
        let value = e?.target?.value
        setMicrosoftTeamsChannelURL(value)
    }

    function validateEmails(emails) {
        if (!emails) {
            return true;
        }
        // Regular expression to validate a single email address
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        // Split the input string by commas and remove extra spaces
        const emailArray = emails.split(',').map(email => email.trim());
        
        // Check each email address against the regular expression
        for (const email of emailArray) {
            if (!emailRegex.test(email)) {
                return false; // Invalid email found
            }
        }
        return true; // All emails are valid
    }

    const onChangeNotificationEmails = (e:any)=>{
        let emails = e?.target?.value
        setNotificationEmails(emails)
    }

    const  onUpdatePasswordExpiry = (e:any)=>{
        let checked = e?.target?.checked
        setPasswordExpiry(checked)
    }

    const  onUpdatePasswordExpiryInDays = (e:any)=>{
        let passwordExpiryInDays = e?.target?.value
        if (Number(passwordExpiryInDays) > 0 && Number(passwordExpiryInDays) <= 365){
            setPasswordExpiryInDays(Number(passwordExpiryInDays))
        }
    }

    const updateOrganizationSettings = ()=>{
        setConfirmDialog({
            isOpen: true,
            title: CONFIRM_DIALOG_TITLE,
            subTitle: UPDATE_ORGANIZATION,
            onConfirm: () => {
                if (Number(twoFATimeout) < 0 || Number(twoFATimeout) > 365){
                    return
                }
                let timeoutInMinutes = Math.floor(Number(twoFATimeout) * 24 * 60)
                if (!twoFAEnabled){
                    timeoutInMinutes = 0
                }
                let payload = {
                    "twoFAEnabled":twoFAEnabled,
                    "cativeAccess":captiveAccess,
                    "mfaTimeout":timeoutInMinutes,
                    "microsoftTeamsChannelName": microsoftTeamsChannelName,
                    "microsoftTeamsChannelWebhookUrl":microsoftTeamsChannelURL,
                    "notificationEmails": notificationEmails.split(",").map(email => email.trim()),
                    "passwordExpiryEnabled":passwordExpiryEnabled,
                    "passwordExpiryInDays":passwordExpiryInDays,
                    "emailSignature": emailSignature,
                }
                updateOrganizationSettingsData(authReducer.selectedOu.id, payload)
                setShowLoader(true);
                setEditing(false)
            },
          });
    }
    
    return (
        <Grid>
            <Grid className="group-settings">
                <div className="group-settings-data">
                    <div>
                        <span className="key">White Label</span>
                        <span className="value">{getValue("whiteLabel")}</span>
                    </div>
                    <div>
                        <span className="key">Documents</span>
                        <span className="value link"><a href={groupDocumentsPageURL}>Click here</a></span>
                    </div>
                    <div>
                        <span className={getValue("sportDomain") == "NA" ? "key" : "key logo"}>Logo</span>
                        <span className="value">
                            {getValue("sportDomain") != "NA"
                                ?
                                <img src={`https://k4storage.blob.core.windows.net/cdn/sportAssets/${getValue("sportDomain")}/headerLogo.png`} />
                                : "NA"
                            }
                        </span>
                    </div>
                    <div>
                        <span className="key">Sport Domain Name</span>
                        <span className="value" title={getValue("sportDomain")}>{getValue("sportDomain")}</span>
                    </div>
                    <div>
                        <span className="key">Sender Email Address</span>
                        <span className="value" title={getValue("email")}>{getValue("email")}</span>
                    </div>
                    <div>
                        <span className="key">Email Signature</span>
                        <span className="value" title={getValue("emailSignature")}>
                            {isEditing && email ? <TextField style={{width: "100%"}} type="text" multiline rows={6} className="emailSignature" value={emailSignature} onChange={onUpdateEmailSignature} /> : getValue("emailSignature")}
                        </span>
                    </div>
                    <div>
                        <span className="key logo">Two Factor Authentication</span>
                        <span className="value">
                            {isEditing ?<Switch checked={twoFAEnabled} onChange={onUpdateTwoFA}/>: getValue("twofaEnabled")}
                        </span>
                    </div>
                    <div>
                        <span className="key">
                            Two Factor Authentication Timeout (in days)
                        </span>
                        <span className="value timeoutValueDiv">
                            {isEditing ? 
                                <TextField type="number"  sx={{input: {textAlign: "center"}}} InputProps={{ inputProps: { min: 0, step:0.5, max: 365}}} className="two-fa-timeout-text-field" value={twoFATimeout} onChange={onUpdateTwoFATimeout} disabled={twoFATimeoutDisabled}></TextField>
                                :twoFATimeout
                            }
                            <Tooltip className="twofaTimeoutTooltip" title="If timeout(in days) is 0 then OTP will be asked every time on login."><HelpIcon className="helpIcon" /></Tooltip>
                        </span>
                    </div>
                    <div>
                        <span className="key">
                            Knowledge Helper
                        </span>
                        <span className="value timeoutValueDiv">
                            <a href={authReducer?.domainConfig?.knowldgeHelper ? `https://${authReducer?.domainConfig?.knowldgeHelper}.scrollhelp.site` :  `https://kognitive.scrollhelp.site`} target="_blank">
                           {authReducer?.domainConfig?.knowldgeHelper ? `https://${authReducer?.domainConfig?.knowldgeHelper}.scrollhelp.site` :  `https://kognitive.scrollhelp.site`}
                           </a>
                        </span>
                    </div>
                    <div>
                        <span className="key">Captive Access</span>
                        <span className="value">{isEditing ?<Switch checked={captiveAccess} onChange={onUpdateCaptiveAccess}/>: getValue("cativeAccess")}</span>
                    </div>
                    <div>
                        <span className="key">Password Expiry</span>
                        <span className="value">{isEditing ?<Switch checked={passwordExpiryEnabled} onChange={onUpdatePasswordExpiry}/>: getValue("passwordExpiryEnabled")}</span>
                    </div>
                    <div>
                        <span className="key">Password Expiry Timeout (in days)</span>
                        <span className="value">
                            {isEditing ? 
                                <TextField type="number"  sx={{input: {textAlign: "center"}}} InputProps={{ inputProps: { min: 1, step:0.5, max: 365}}} className="two-fa-timeout-text-field" value={passwordExpiryInDays} onChange={onUpdatePasswordExpiryInDays} disabled={!passwordExpiryEnabled}></TextField>
                                :passwordExpiryInDays
                            }
                        </span>
                    </div>
                    <div className="notification">
                        <span className="title">Notification</span>
                            {isEditing ?
                                <>
                                    <div>
                                        <span>Microsoft Teams Channel</span>
                                    </div>
                                    <div>
                                        <span className="key">Name</span>
                                        <TextField className="value text_field_height" value={microsoftTeamsChannelName} onChange={onChangeMicrosoftTeamsChannelName}></TextField>
                                    </div>
                                    <div>
                                        <span className="key">Webhook URL</span>
                                        <TextField className="value text_field_height" value={microsoftTeamsChannelURL} onChange={onChangeMicrosoftTeamsChannelURL}></TextField>
                                    </div>
                                </>
                                :
                                <div>
                                    <span className="key">Microsoft Teams Channel</span>
                                    <span className="value" title={getValue("microsoftTeamsChannelName")}>{getValue("microsoftTeamsChannelName")}</span>
                                </div>
                            }
                        <div>
                            <div className="org-settings-email-address-leftdiv">
                                <span className="key">Email Addresses</span><br />
                                {isEditing && <span className="email-error-msg">{!validateEmails(notificationEmails) ? `Invalid Email Ids, list should be seperated by ' , '` : null}</span>}
                            </div>
                            {
                                isEditing ? 
                                    <TextField className="value text_field_height" value={notificationEmails} onChange={onChangeNotificationEmails}></TextField>
                                : <span className="value" title={notificationEmails}>{Array.isArray(getValue("notificationEmails")) ? getValue("notificationEmails").join(', ') : getValue("notificationEmails")}</span>
                            }
                            
                        </div>
                    </div>
                </div>
                {!isEditing? <Button className="edit-org-settings-button" onClick={editOrganizationSettings}>Edit</Button>:<Button className="cancel-org-settings-button" onClick={cancelOrganizationSettings}>Cancel</Button>}
                {isEditing ?<Button
                disabled={!validateEmails(notificationEmails)}
                style={{ backgroundColor: !validateEmails(notificationEmails) ? "#E8F0FD" : "#264C86", 
                color: !validateEmails(notificationEmails) ? "#264C86" : "#fff" }}
                className="update-org-settings-button" onClick={updateOrganizationSettings}>Update</Button>:<></>}
            </Grid>
            <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
            {authReducer.loading ? (
                <CustomLoader
                showLoader={showLoader}
                loadingText={
                    loadingText ? loadingText : "Fetching data please wait..."
                }
                />
            ) : null}
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {
        getGroupSettingsData,
        updateOrganizationSettingsData
    })(GroupSettings)
)
