import { useState } from "react";
import { Grid, Button} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Form from "../Form";

const  DataSourceTable = (props) => {
  const createData = (name, calories, fat, carbs, protein) => {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData("Frozen", "Elasticsearch", "AB", null , null),
    createData("Ice", "Postgresql", "BC", null , null),
    createData("Eclair", "Kafka KSQL", "CD", null , null),
    createData("Cup", "Elasticsearch", "DE", null , null),
    createData("Ginger", "Postgresql", "EF", null , null)
  ];
  const [showPopUP, setShowPopUP] = useState(false);

  const handleForm = () => {
    setShowPopUP(true);
  };

  return (
    <div className="Form">
      <Grid
        item
        container
        xs={12}
        style={{ backgroundColor: "#fff", marginTop: "4px" }}
      >
        <Grid xs={9} sm={10} md={11} lg={11} xl={11} style={{ marginLeft: "-22px", marginTop: "20px" }}>
          <Button
            style={{
              marginLeft: "auto",
              display: "flex",
              border: "1px solid #938c8c",
              color: "rgb(66 65 65)",
            }}
            onClick={handleForm}
          >
            Add Data source
          </Button>
          <TableContainer
            // component={Paper}
            style={{ backgroundColor: "#FAF9FC", marginTop: "10px" }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{
                    borderBottom: "1.1px solid #676984",
                    fontWeight: 700,
                    fontSize: "12px",
                  }}
                >
                  <TableCell style={{ color: "rgb(144 144 151)" }}>
                    Datasource Name
                  </TableCell>
                  <TableCell align="left" style={{ color: "rgb(144 144 151)" }}>
                    Type
                  </TableCell>
                  <TableCell align="left" style={{ color: "rgb(144 144 151)" }}>
                    HostName
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    style={{
                      borderBottom: "0.5px solid #CFD0DD",
                      fontWeight: 700,
                      fontSize: "12px",
                    }}
                    key={row.name}
                    //   sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.calories}</TableCell>
                    <TableCell align="left">{row.fat}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Form open={showPopUP} onClose={() => setShowPopUP(false)} />
    </div>
  );
}

export default DataSourceTable;
