import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Dialog, DialogProps, DialogTitle, DialogContent, Grid, TextField, Box, Button, Switch, TableContainer, Table, TableCell, TableHead,TableRow, TableBody } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import CsvUploader from "../../CSVUpload";
import CSVImg from "../../../asset/image/csv_icon.png";
import { Domain } from "@material-ui/icons";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DownloadCSV, {DownloadNamedCSV} from "./../../DownloadCSV"
import AntSwitch from "../../AntSwitch";
import { toast } from "react-toastify";
interface Domain {
    domain: string
    sub_domain: boolean
}

const ContentFilterBlockedDomains = ({blockedDomainsProps, onSetBlockedDomains})=>{

    const [domainName, setDomainName] = useState<string>("")
    const [includeSubDomain, setIncludeSubDomain] = useState<boolean>(true)
    const [blockedDomains, setBlockedDomains] = useState<Domain[]>(blockedDomainsProps)
    const [fileSelected, setFileSelected] = useState<boolean>(false)
    const [fileName, setFileName] = useState<string>("")
    const [csvFileDomains, setCSVFileDomains] = useState<Domain[]>([])    
    const [errorMessage, setErrorMessage] = useState<string>("")
    let sampleCSVData = [{
            "domain":"DOMAIN",
            "sub_domain":"SUB_DOMAIN"
        },
        {
            "domain":"example.com",
            "sub_domain":"TRUE"
        },
        {
            "domain":"test.com",
            "sub_domain":"FALSE"
        }
    ]   

    useEffect(()=>{
        onSetBlockedDomains(blockedDomains)
    }, [blockedDomains])

    const onChangeIncludeSubDomain = (event)=>{
        setIncludeSubDomain(event.target.checked)
    }

    const onChangeDomainName = (event)=>{
        setDomainName(event.target.value)
    }

    const addBlockedDomain = ()=>{
        if(domainName.length == 0){
            return
        }

        if(!(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(domainName))) {
            setErrorMessage("Invalid domain name.")
            return
        }

        let bIsDuplicate = false
        for(var i=0;i<blockedDomains.length;i++){
            if(domainName == blockedDomains[i].domain){
                bIsDuplicate = true;
            }
        }

        if(bIsDuplicate) {
            setErrorMessage("Domain name is already present.")
            return
        }

        setErrorMessage("")

        let blockedDomain: Domain = {
            domain: domainName,
            sub_domain: includeSubDomain
        }

        blockedDomains.push(blockedDomain)
        setBlockedDomains(blockedDomains)

        setDomainName("")
    }

    const deleteBlockedDomain = (index)=>{
        let arrBlockedDomains = blockedDomains.filter((blockedDomain, i)=> i != index)
        setBlockedDomains(arrBlockedDomains)
    }

    const handleFileUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            let text:any
            text = event?.target?.result;

            let arrData:any = []
            if(text.includes("\r\n")){
                arrData = text?.split("\r\n")
            } else {
                arrData = text?.split("\n")
            }

            let arrBlockedDomains = blockedDomains
            let fileContentError = false
            for(let i=0;i<arrData.length;i++){
                if(i==0){
                    let row = arrData[i].split(",")
                    let column1Heading = row[0]
                    let column2Heading = row[1]

                    if(!(column1Heading == "DOMAIN" && column2Heading == "SUB_DOMAIN")){
                        toast.error("Invalid CSV file format.", {
                            position: toast.POSITION.BOTTOM_LEFT
                        })
                        return
                    }
                    continue
                }
                let row = arrData[i].split(",")
                let domain = row[0]
                let includeSubDomain = row[1]

                if(domain != ""){
                    if(!(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(domain))){
                        fileContentError = true
                        continue
                    }

                    if(!(includeSubDomain.toLowerCase() == "false" || includeSubDomain.toLowerCase() == "true")){
                        fileContentError = true
                        continue
                    }

                    let obj :Domain = {
                        domain: domain,
                        sub_domain: (includeSubDomain.toLowerCase() == "false") ? false : true
                    }
                    arrBlockedDomains.push(obj)
                    csvFileDomains.push(obj)
                }
            }

            setBlockedDomains(arrBlockedDomains)
            setCSVFileDomains(csvFileDomains)
            setFileName(file.name)
            setFileSelected(true)

            if(fileContentError){
                toast.error("Some rows are skipped due to invalid domain or sub domain values.", {
                    position: toast.POSITION.BOTTOM_LEFT
                })
            }
        };
        reader.readAsText(file);
    };

    const deleteCSVFile = ()=>{
        let arrBlockedDomains = blockedDomains
        for(let i=0;i<blockedDomains.length;i++){
            for(let j=0;j<csvFileDomains.length;j++){
                if(csvFileDomains[j].domain == blockedDomains[i].domain){
                    arrBlockedDomains.splice(i, 1)
                }    
            }
        }
        setBlockedDomains(arrBlockedDomains)
        setFileName("")
        setFileSelected(false)
        setCSVFileDomains([])
    }

    const downloadCSV = ()=>{
        if(blockedDomains.length > 0){
            let downloadedAllowedDomains :any= []
            downloadedAllowedDomains.push(["DOMAIN", "SUB_DOMAIN"])
            
            for(var i=0;i<blockedDomains.length;i++){
                let arr: any= []
                arr.push(blockedDomains[i].domain)
                arr.push(blockedDomains[i].sub_domain)

                downloadedAllowedDomains.push(arr)
            }
            DownloadNamedCSV(downloadedAllowedDomains, "Sample")
        }
    }

    const downloadSampleCSV = ()=>{
        let downloadedAllowedDomains :any= []
        for(var i=0;i<sampleCSVData.length;i++){
            let arr: any= []
            arr.push(sampleCSVData[i].domain)
            arr.push(sampleCSVData[i].sub_domain)

            downloadedAllowedDomains.push(arr)
        }
        DownloadCSV(downloadedAllowedDomains)
    }

    return (
        <>
            <Grid container xs={12} sm={12} md={12} lg={12} className="content-filter-blocked-categoies-div">
                {<TextField
                    style={{ width: "100%" }}
                    variant="outlined"
                    placeholder="Enter Domain Name"
                    value={domainName}
                    onChange={onChangeDomainName}
                />}
                {errorMessage.length > 0 ? <span className="domain_name_validation_err_message">{errorMessage}</span> :<></>}
            </Grid>
            <Grid container xs={12} sm={12} md={12} lg={12} className="content-filter-blocked-categoies-div">
                <Grid item xs={10} sm={10} md={10} lg={10}>
                    <span className="content-filter-domain-name-text">Include Subdomains</span>
                    <AntSwitch checked={includeSubDomain} onChange={onChangeIncludeSubDomain}/>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} className="content-filter-add-domain-grid">
                    <Button variant="outlined" startIcon={<AddCircleOutlineIcon/>} className="content_filter--button_outlined_filter" onClick={addBlockedDomain}>
                        Add
                    </Button>
                </Grid>
            </Grid>
            <Grid container xs={12} sm={12} md={12} lg={12} className="content-filter-blocked-categoies-div">
                <Grid container xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={9} sm={9} md={9} lg={9} className="content-filter-domain-list-count-grid">
                        <span>Domain List: {blockedDomains.length}</span>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} className="content-filter-add-domain-grid">
                        <Button variant="text" startIcon={<FileDownloadIcon/>} className="content_filter--text-button" onClick={downloadCSV}>
                            Download CSV
                        </Button>
                    </Grid>
                </Grid>
                <TableContainer className="content_filter--domain-names-table_container">
                    <Table sx={{ minWidth: 600, maxHeight:200 }} size="small" aria-label="content_filter--service_accounts">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <span>S. No.</span>
                                </TableCell>
                                <TableCell>
                                    <span>Domain Name</span>
                                </TableCell>
                                <TableCell>
                                    <span>Include Sub Domain</span>
                                </TableCell>
                                <TableCell>
                                    <span>Actions</span>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="tableBody alertsTable-tableBody">
                            {
                                blockedDomains.length == 0 ? 
                                <></>
                                :
                                <>
                                    {
                                        blockedDomains.map((blockedDoamin, index)=>{
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {index+1}
                                                    </TableCell>
                                                    <TableCell>
                                                        {blockedDoamin.domain}
                                                    </TableCell>
                                                    <TableCell>
                                                        {blockedDoamin.sub_domain ? <> <AntSwitch checked={true}/> </> : <> <AntSwitch checked={false}/> </>}
                                                    </TableCell>
                                                    <TableCell>
                                                    <IconButton onClick={()=>{deleteBlockedDomain(index)}}>
                                                        <DeleteIcon className="content-filter-delete-icon"/>
                                                    </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid container xs={12} sm={12} md={12} lg={12} className="content-filter-blocked-categoies-div content-filter-upload-csv-div">
                <Grid container xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={7} sm={7} md={7} lg={7}>
                        {
                            fileSelected ? 
                            <>
                            <img src={CSVImg} className="content-filter-csv-image"></img>
                            <span className="content-filter-csv-text">{fileName}</span>
                            </>
                            :
                            <span className="content-filter-csv-text">No CSV Selected</span>
                        }
                    </Grid>
                    <Grid item xs={5} sm={5} md={5} lg={5} className="content-filter-upload-csv-grid">
                        {
                            fileSelected ?
                            <>
                                <span className="content-filter-csv-uploaded-text">Uploaded Successfully</span>
                                <DeleteIcon className="content-filter-delete-icon" onClick={deleteCSVFile}></DeleteIcon>
                            </>
                            :
                            <>
                                <Button variant="text" startIcon={<FileDownloadIcon/>} className="content_filter--text-button" onClick={downloadSampleCSV}>
                                    Sample CSV
                                </Button>
                                <CsvUploader onFileUpload={handleFileUpload}></CsvUploader>
                            </>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    customContentFilter: state.customContentFilter,
})

export default withRouter(
    connect(mapStateToProps, null)(ContentFilterBlockedDomains)
);  