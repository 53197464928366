import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {useSelector } from 'react-redux';
import { Box, Button, Grid, Menu, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Dialog} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import TuneIcon from '@mui/icons-material/Tune';
import close from "../../asset/image/close.svg";
import { getDecodeURI, getEncodedURI, getIntervalCheck } from "../../utils/util";
import { getOperationalUsageTimeseriesTable, getOperationalUsageTimeseriesTableCount } from '../../actions/Users/authenticateUsageReports';
import _ from "lodash";
import { Pagination } from '../Pagination';
import SortArrows, { SortOrder } from "../SortArrows";
import './index.css';
import * as XLSX from "xlsx";
import {GET_OPERATIONAL_USAGE_TIMESERIES_TABLE} from "../../actions/types";
import axios from "axios";

const OperationalTimeSeriesTable = (props) => {
    const {
        location_ids, dwn, setDwn, open, setOpen,
        authReducer,
        getOperationalUsageTimeseriesTable,
        getOperationalUsageTimeseriesTableCount,
        newSummaryStartDate,
        newSummaryEndDate,
        reports,
        total_count,
        location,history
    } = props;

    const dispatch = useDispatch();
    let _q = new Map<string, string>(Object.entries(getDecodeURI(location.search)));
    const [vessels, setVessels] = useState([]);
    const [tableParams, setTableParams] = useState<{
        page: number;
        size: number;
    }>({
        page: _q.has('page') ? parseInt(_q.get('page') as string) : 1,
        size: _q.has('size') ? parseInt(_q.get('size') as string) : 10
    });

    const rowsOption = [10, 15, 25, 50, 100];

    const handleChangePage = (event: any, newPage: number) => {
        setTableParams({ ...tableParams, page: newPage });
    };

    const handleChangeSize = (event: any) => {
        setTableParams({ ...tableParams, size: event.target.value, page: 1 });
    };
    const location_id = `'${location_ids}'`;
    const site_interface="";

    const downloadExcel = () => {
        if (!reports?.data?.rows){
            return;
        }
    
        const headers = [
            "ts", "sitename", "deviceID", "interface_name",
            "User_Traffic", "Final_OPS", "Total_WAN", "PEP_Overhead", "LAN_Trafficover_tunnel_TCP",
            "Tunnel_Probe", "LAN_Trafficover_tunnel_NTCP", "Customer_Traffic_Native", "OPS", "NativeProbe_SP",
            "wan_usage", "DNS", "NTP", "Telemetry", "Konnect",
            "API", "SW_Update", "SoftEther", "Google_DNS", "EdgeSync",
            "OVPN", "Tun_Probe", "WAN_F_Native"
        ];
        
        const worksheetData = [
            headers,
            ...reports?.data?.rows.map(row => 
                row
                    .filter(cell => !(typeof cell === 'string' && cell.startsWith("K4-")))
                    .map(cell => {
                        return cell;
                    })
            )
        ];
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Operational Reports");
        XLSX.writeFile(workbook, "OperationalTimeseriesReports.xlsx");
        dispatch({type: GET_OPERATIONAL_USAGE_TIMESERIES_TABLE, payload: {}});
    };

    let count_rows=total_count? total_count[0] : 0;
    const DEFAULT_PAGE_SIZE = 10;
    let page_offset: 0;    
    let page_size: 100;         

    useEffect ( () => {
        if(location_id){
            getOperationalUsageTimeseriesTable({
                vessels: location_id,
                start_time: newSummaryStartDate,
                end_time: newSummaryEndDate,
                sort_column: "ts",      
                sort_order: "desc",     
                page_offset: tableParams.page,       
                page_size: tableParams.size,
                interface_name: site_interface
            })
            getOperationalUsageTimeseriesTableCount({
                vessels: location_id,
                start_time: newSummaryStartDate,
                end_time: newSummaryEndDate,
                interface_name: site_interface
            })
        }
    },[location_id, newSummaryStartDate, newSummaryEndDate])

    const handleClose = () => {
        setOpen(false);
    }
    
    useEffect (() => {
        if(dwn && reports?.data?.rows?.length>0){
            downloadExcel();
            setDwn(false);
        }
    },[dwn,reports])
    

    return (
        <Dialog className="menuScroll " onClose={handleClose} open={false} maxWidth="lg" fullWidth>
          <div className="operationalTopbar threatKontrolMargin">
                <h3>Operation Usage Timeseries Table</h3>
                <div className='threatKontrolClose'>  <img className="threatKontrolCloseIcon"
                    src={close}
                    alt='close'
                    onClick ={handleClose}>
                    </img>
                </div>
                {/* <button onClick={downloadExcel}>Download</button> */}
            </div>  
          <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner generated-reports-table threatKontrolMargin">
          <Pagination count={count_rows} onPageChange={handleChangePage} page={tableParams.page} rowsPerPage={tableParams.size} onRowsPerPageChange={handleChangeSize} />

            <TableContainer className="usageReports-FleetDashboard reports-gene-container">
                    <Table aria-label="simple sticky table" stickyHeader>
                        <TableHead className="usageReports-tableHead config-tableRow">
                            <TableRow>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">ts</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">locationID</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">sitename</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">deviceID</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">interface_name</TableCell>

                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">User_Traffic</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">Final_OPS</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">Total_WAN</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">PEP_Overhead</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">LAN_Trafficover_tunnel_TCP</TableCell>

                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">Tunnel_Probe</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">LAN_Trafficover_tunnel_NTCP</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">Customer_Traffic_Native</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">OPS</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">NativeProbe_SP</TableCell>

                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">wan_usage</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">DNS</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">NTP</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">Telemetry</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">Konnect</TableCell>

                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">API</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">SW_Update</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">SoftEther</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">Google_DNS</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">EdgeSync</TableCell>

                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">OVPN</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">Tun_Probe</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">WAN_F_Native"</TableCell>
                            
                            </TableRow>
                        </TableHead>
                        {reports ? (  
                            <TableBody className="tableBody usage-tableBody operationalTanbleOverflow">
                                 {reports?.data?.rows.map((row:any, rowIndex:number) => (
                                    <TableRow key={rowIndex}>
                                        {row.map((cell: any, cellIndex: number) => (
                                            <TableCell key={cellIndex}>{typeof cell === 'number' ? (Number.isInteger(cell)? (cell / (1024 ** 3)).toFixed(2) : cell.toFixed(2)) : cell} </TableCell>
                                        ))}
                                    </TableRow>
                                 ))}
                            </TableBody>
                        ) : (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
          </Grid>
        </Dialog>
    )
} 

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    newSummaryStartDate: state.authReducer.newSummaryStartDate,
    newSummaryEndDate: state.authReducer.newSummaryEndDate,
    reports: state.authReducer.getOperationalUsageTimeseriesTable,
    total_count: state.authReducer.getOperationalUsageTimeseriesTableCount
});

export default withRouter(
    connect(mapStateToProps, {
        getOperationalUsageTimeseriesTable,
        getOperationalUsageTimeseriesTableCount
    })(OperationalTimeSeriesTable)
);