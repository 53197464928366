import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import "./CsvUpload.css"

const CsvUploader = ({ onFileUpload }) => {
  const [fileName, setFileName] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      onFileUpload(file); // Callback to parent component
    }
  };

  return (
    <div>
      <input
        accept=".csv"
        style={{ display: 'none' }}
        id="upload-csv"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="upload-csv">
        <Button
          variant="outlined"
          component="span"
          className='csv-upload--button_outlined_filter select-csv-button'
        >
          Upload CSV
        </Button>
      </label>
    </div>
  );
};

export default CsvUploader;
