import { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, TableBody, TableRow, TableCell, TableContainer, Table, TableHead, Paper, Chip, Stack, FormControl, Select, MenuItem } from "@mui/material";
import _ from "lodash";
import "./usageReportsDetails.css";
import UsageLineChart from "../UsageLineChart";
import { readableBytes, readableBytesAsGB, readableBytesAsMB } from "../../utils/util";
import { Pagination } from "../Pagination/index";
import { UsageContext } from "../../UserScreen/UserData/UsageDataContext";
import { 
    getLineChartUsage,
    getTopApplicationLineChart,
  } from "../../actions/Users/authenticateUsageReports";
import TopWidgets from "./ActivityReportsSubComps/TopWidgets";
import AppAndBlockedPieChart from "./ActivityReportsSubComps/AppAndBlockedPieChart";
import CategoryPieChart from "./ActivityReportsSubComps/CategoryPieChart";
import TopSites from "./ActivityReportsSubComps/TopSites";
import TopUsageTable from "./ActivityReportsSubComps/TopUsageTable";
import UsageBottomCards from "./ActivityReportsSubComps/UsageBottomCards";
import CustomLoader from "../Loader/CustomLoaders";
import { Tooltip } from "@material-ui/core";
import topRiskScore from "../../asset/image/topRiskScoreIcon.svg";

const UsageReportsDetails = (props) => {
    const { authReducer, topUsersCount, setTopUsersCount, topSitesCount, setTopSitesCount } = props;

    const { handleChangePageSite,
        handleChangeRowsPerPageSite, 
        handleChangePageUser, 
        handleChangeRowsPerPageUser,
        page, 
        limit, 
        pageStart, 
        pageLimit,
        vessels, 
        setActiveTab, 
        activeTab, 
        setTrackTab, 
        selectTab, 
        setSelectTab, 
        lineChartTab, 
        setLineChartTab, 
        handleResetZoom, 
        handleDefaultResetZoom,
        resetDate,
        bottomTableLimit,
        handleChangeBottomTableLimit,
        categoryPieLimit,
        handleChangeCategoryPieLimit,
        topAppLimit,
        handleChangeTopAppLimit,
        handleApplicationFilterClick,
        application,
        handleClickUsageTab,
        selectedAppColor,
        topApplicationUsage, setTopApplicationUsage,
        lineChartData, SetLineChartData,
        chartAreaSelectionUsage, chartAreaSelectionTopUsage,
        selectedBin, handleBinChange,
        selectFilter, setPage, setPageStart, deviceFilt, vlanFilt, checkUser, handleUsernameChange, setUserNameTrack
    } = useContext(UsageContext);

    const [dataUnit, setDataUnit] = useState("GB");
    const [topWidgetRiskScore, setTopWidgetRiskScore] = useState({});
    const [topWidgetRiskScorePerSite, setTopWidgetRiskScorePerSite] = useState({});

    useEffect(() => {
      if(vessels?.length == 0) {
        let kpi:any = ["download", "kpi", "total",  "upload", "topSite"];
        let emptyArr:any = [];
        kpi.forEach((el)=>{
            emptyArr.push({download: null , kpi: null, total: null,  upload: null, usageFrom: null})
        })
        SetLineChartData([])
        setTopApplicationUsage([])
      }
    }, [vessels])



    useEffect(() => {
        if (!_.isEmpty(authReducer?.getLineChartUsage)) {
          const data = authReducer?.getLineChartUsage?.hasOwnProperty('data') ?  authReducer?.getLineChartUsage?.data : {};
            if(data){
              const series = data?.total?.series || {};
              const seriesKeys = Object.keys(series);
              const gbValue = Math.pow(1000, 3);
              const isGBCrossed = seriesKeys.some(key => {
                const seriesValue = series[key];
                return (_.isArray(seriesValue) && seriesValue.some(item => item.y > gbValue))
              })
              const convertBytes = isGBCrossed ? readableBytesAsGB : readableBytesAsMB;
            let arr:any=[];
              for(let key in data){
                let obj:any={}
                obj.name=key;
                data[key]?.series?.groupHelper?.map((el, i) => {
                  let ele = Number(convertBytes(el.y, false));
                  data[key].series.groupHelper[i].y=ele
                })
                  obj.data=data[key]?.series?.groupHelper;
                  obj.marker={symbol: 'circle'};
                  obj.visible=true; 
                  arr.push(obj)
                }
                setDataUnit(isGBCrossed ? 'GB' : 'MB');
                SetLineChartData(arr);
            }
            authReducer.getLineChartUsage = {};
        }
      },[authReducer.getLineChartUsage])


  useEffect(() => {
    if (!_.isEmpty(authReducer?.getTopApplicationLineChart)) {
      const data = authReducer?.getTopApplicationLineChart?.hasOwnProperty('data') ? authReducer?.getTopApplicationLineChart?.data : {};
      if (data) {
        const series = data?.total?.series || {};
        const seriesKeys = Object.keys(series);
        const gbValue = Math.pow(1000, 3);
        const isGBCrossed = seriesKeys.some(key => {
          const seriesValue = series[key];
          return (_.isArray(seriesValue) && seriesValue.some(item => item.y > gbValue))
        })
        const convertBytes = isGBCrossed ? readableBytesAsGB : readableBytesAsMB;
        let usage: any = seriesKeys.map(key => {
          series[key]?.forEach((el, i) => {
            series[key][i].y = Number(convertBytes(el.y, false));
          })
          return { name: key, data: series[key], marker: { symbol: 'circle' }, visible: true }
        })
        setDataUnit(isGBCrossed ? 'GB' : 'MB');
        setTopApplicationUsage(usage);
      }
      authReducer.getTopApplicationLineChart = {};
    }
  }, [authReducer.getTopApplicationLineChart])

  useEffect(() => {
    if (!_.isEmpty(authReducer.topWidgetAllSiteRiskScore)) {
      const data = authReducer.topWidgetAllSiteRiskScore.hasOwnProperty('data') ? authReducer.topWidgetAllSiteRiskScore.data : {};
      const cardData = data?.rows?.map((item) => {
        return data?.columns?.reduce((acc, columItem, i) => {
          acc[columItem] = item[i];
          return acc;
        }, {})
      })
      setTopWidgetRiskScore(cardData?.[0]);
      authReducer.topWidgetAllSiteRiskScore = {};
    }

    if (!_.isEmpty(authReducer.topWidgetSingleSiteRiskScore)) {
      const data = authReducer.topWidgetSingleSiteRiskScore.hasOwnProperty('data') ? authReducer.topWidgetSingleSiteRiskScore.data : {};
      const cardData = data?.rows?.map((item) => {
        return data?.columns?.reduce((acc, columItem, i) => {
          acc[columItem] = item[i];
          return acc;
        }, {})
      })
      setTopWidgetRiskScorePerSite(cardData?.[0]);
      authReducer.topWidgetSingleSiteRiskScore = {};
    }
}, [authReducer.topWidgetAllSiteRiskScore, authReducer.topWidgetSingleSiteRiskScore])

  const handleClickFilter = (data) => {
    if (data.identifier) {
      let filters: any = {};
      const user = data.identifier;
      filters.filterUserName = user;
      filters.vlanFilter = vlanFilt;
      filters.deviceFilter = deviceFilt;
      filters.userCheck = data.type == 'local_mac' ? 'macAddress' : 'ipAddress';
      handleUsernameChange(null, user);
      setPage(1);
      setPageStart(1);
      selectFilter(filters);
      setUserNameTrack(true);
    } else {
      let filters: any = {};
      filters.filterSite = data.name;
      filters.vlanFilter = vlanFilt;
      filters.deviceFilter = deviceFilt;
      filters.userCheck = checkUser;
      setPage(1);
      setPageStart(1);
      selectFilter(filters);
    }
  }

    const rowsOption = [10, 15, 25, 50, 100];

return (
    <div className="Form">
        <Grid className="am-table">
            <Grid container spacing={0.5} className="userDataRightContainer">
              <Grid item xs={12} sm={4} md={12} lg={12} >
                  <div className="Reports-overView">Overview</div>
                  <Grid container columns={12} direction={'row'} spacing={1} className="cardParent">
                      <TopWidgets />
                      <Grid item xs={12} sm={4} md={2} lg={2}>
                        <RiskScore data={vessels?.length == 1 ? topWidgetRiskScorePerSite : topWidgetRiskScore} handleClickFilter={handleClickFilter} vessels={vessels} />
                      </Grid>
                  </Grid>
              </Grid>

              <Grid item xs={12} sm={4} md={12} lg={12}>
                  <Grid className="pieChart-entryLimit">
                    <div className="Reports-Usage">Usage</div>
                    {categoryPieLimit ? 
                      <Grid className="pieChat-selectTag">
                          <FormControl variant="standard" className='selectEntry-pie'>
                              <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  value={categoryPieLimit}
                                  onChange={handleChangeCategoryPieLimit}
                                  >
                                  <MenuItem value={10}>10</MenuItem>
                                  <MenuItem value={20}>20</MenuItem>
                                  <MenuItem value={30}>30</MenuItem>
                                  <MenuItem value={50}>50</MenuItem>
                              </Select>
                          </FormControl>
                      </Grid> : null
                    }
                  </Grid>
                  <Grid  className="UsageContainer-overTime">
                      <Grid container spacing={1.5}>
                          <CategoryPieChart categoryIcons={true} />
                          <AppAndBlockedPieChart appIcons={true} />
                      </Grid>
                  </Grid>
              </Grid>

              <Grid item xs={12} sm={4} md={12} lg={12} className="UsageLineChartComponent">
                  <UsageLineChart 
                      lineChartData={lineChartData} 
                      topApplicationUsage={topApplicationUsage} 
                      chartAreaSelectionUsage={chartAreaSelectionUsage} 
                      chartAreaSelectionTopUsage={chartAreaSelectionTopUsage} 
                      setActiveTab={setActiveTab} 
                      activeTab={activeTab} 
                      setTrackTab={setTrackTab} 
                      lineChartTab={lineChartTab} 
                      setLineChartTab={setLineChartTab} 
                      handleResetZoom={handleResetZoom}
                      handleDefaultResetZoom={handleDefaultResetZoom}
                      resetDate={resetDate}
                      topAppLimit={topAppLimit}
                      handleChangeTopAppLimit={handleChangeTopAppLimit}
                      handleApplicationFilterClick={handleApplicationFilterClick}
                      application={application}
                      handleClickUsageTab={handleClickUsageTab}
                      selectedAppColor={selectedAppColor}
                      selectedBin={selectedBin} handleBinChange={handleBinChange}
                      yLabelUnit={dataUnit}
                  />
              </Grid>

              <TopSites 
                  handleChangePageSite={handleChangePageSite}
                  handleChangeRowsPerPageSite={handleChangeRowsPerPageSite}
                  pageStart={pageStart}
                  pageLimit={pageLimit}
                  rowsOption={rowsOption}
                  topSitesCount={topSitesCount} setTopSitesCount={setTopSitesCount}
              />

              <TopUsageTable 
                  handleChangePageUser={handleChangePageUser}
                  handleChangeRowsPerPageUser={handleChangeRowsPerPageUser}
                  page={page}
                  limit={limit}
                  rowsOption={rowsOption}
                  topUsersCount={topUsersCount} setTopUsersCount={setTopUsersCount}
              />
              <span className="activity-can-user-info">* Indicates Captive user</span>

              <UsageBottomCards 
              selectTab={selectTab} 
              setSelectTab={setSelectTab} 
              setTrackTab={setTrackTab} 
              activeTab={activeTab} 
              setActiveTab={setActiveTab} 
              vessels={vessels} 
              bottomTableLimit={bottomTableLimit}
              handleChangeBottomTableLimit={handleChangeBottomTableLimit}
              />
            </Grid>
            { authReducer.activityReportsWidgetLoading || authReducer.activityReportsCategoryPieLoading || authReducer.activityReportsApplicationPieLoading ||authReducer.activityReportsUsageLineChartLoading ||
              authReducer.activityReportsTopUsersLoading || authReducer.activityReportsTopSitesLoading || authReducer.setActivityReportsBottomTableLoading || authReducer.activityReportsTopAppLineChartUsage ||
              authReducer.setActivityReportsBottomTableDomainLoading || authReducer.setActivityReportsBottomTableIPLoading || authReducer.activityReportsTopSitesSortLoading || authReducer.activityReportsTopUsersSortLoading ||
              authReducer.activityReportsLoading ? (
                <CustomLoader
                  showLoader={true}
                  loadingText={ "Fetching data please wait..." }
                />
              ) : null
            }
        </Grid>
    </div>
   )

  function RiskScore ({data, handleClickFilter, vessels}) {

    return (
      <div className="Form">
        <Grid className="am-table usage-cardmain">
          <Grid className="Reprts-CardContainer risk-score-card">
            <Grid className="total-logoDiv">
              <Grid className="reports-totalDetails">
                <Grid>{vessels?.length == 1 ? `Top Risk Score(User)` : `Top Risk Score(Site)`}</Grid>
                <Tooltip title={data?.identifier ? data?.identifier : data?.name ? data?.name : '-'}>
                  <Grid onClick={() => handleClickFilter(data)} className="netify-cardFilter">{data?.identifier ? data?.identifier : data?.name ? data?.name : '-'}</Grid>
                </Tooltip>
              </Grid>
              <Grid className="reports-propsLogo reports-propsLogo-blocked"><img src={topRiskScore} alt="top-risk"/></Grid>
            </Grid>
            <Grid className="topBlockedUsage">
              <div>Risk Score</div>
              <Tooltip title={data?.id_risk_list ? data?.id_risk_list?.split(',')?.map((id, i) => (
                <Grid className="widget-risk-tooltip">
                  <span>{i == 0 && 'Risk Types : '}</span>
                  {i == 0 && <br />}
                  <span>● </span>
                  <span>{id}</span>
                </Grid>
              )) : ''}><span className="widget-risk-score-number riskScore-riskLevel">{data?.total_risk_score ? Math.round(data?.total_risk_score) : '-'}</span>
              </Tooltip>
              <Tooltip title={ data?.risk_level ?
                <Table>
                  <TableHead>
                    <th>NDPI Risk Severity</th>
                    <th>Risk Score</th>
                  </TableHead>
                  <TableBody>
                    <tr><td>● EMERGENCY</td><td>above 250</td></tr>
                    <tr><td>● CRITICAL</td><td>200 - 250</td></tr>
                    <tr><td>● SEVERE</td><td>150 - 200</td></tr>
                    <tr><td>● HIGH</td><td>100 - 150</td></tr>
                    <tr><td>● MEDIUM</td><td>50 - 100</td></tr>
                    <tr><td>● LOW</td><td>below 50</td></tr>
                  </TableBody>
                </Table> : ''
              }>
                <span className="widget-risk-score-number riskScore-riskLevel">{data?.risk_level ? `(${data?.risk_level})` : '-'}</span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer
});

export default withRouter(
    connect(mapStateToProps, {
      getLineChartUsage,
      getTopApplicationLineChart,
    })(UsageReportsDetails)
);