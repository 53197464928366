import { api } from "../../server/request";

import { SET_LOADING } from '../../actions/types'
import _ from "lodash";
const ACTION_TYPES = {
    GET_SCANNED_DEVICES: "remoteConnection/getScannedDevices",
    GET_GENERATED_LINKS: "remoteConnection/getGeneratedLinks",
    CLEAR: "remoteConnection/clearData",
    CREATE_REMOTE_CONNECTION: "remoteConnection/createRemoteConnection",
    UPDATE_REMOTE_CONNECTION: "remoteConnection/updateRemoteConnection",
    DELETE_REMOTE_CONNECTION: "remoteConnection/deleteRemoteConnection",
    CREATE_SCANNED_DEVICE: "remoteConnection/createScannedDevice",
    DELETE_SCANNED_DEVICE: "remoteConnection/deleteScannedDevice",
    UPDATE_SCANNED_DEVICE: "remoteConnection/updateScannedDevice"
}

const initialState = {
    scannedDevices: [],
    generatedLinks: [],
    updateRemoteConnection: {},
    deleteRemoteConnection: {}
}

export function remoteConnectionReducer(state: any = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.GET_SCANNED_DEVICES: {
            return {
                ...state,
                scannedDevices: action.payload
            }
        }
        case ACTION_TYPES.GET_GENERATED_LINKS: {
            return {
                ...state,
                generatedLinks: _.isArrayLike(action.payload) ? action.payload.sort((a, b) => {
                    if (b.address === "127.0.0.1") {
                        return 1;
                    }
                    if(a.address === "127.0.0.1") {
                        return -1;
                    }
                    switch (`${a.type}-${b.type}`) {
                        case 'lan-wan':
                            return 1;
                        case 'lan-vsat_mgmt':
                            return 1;
                        case 'lan-wan_sub':
                            return 1;
                        case 'wan-lan':
                            return -1;
                        case 'wan-vsat_mgmt':
                            return -1;
                        case 'wan-wan_sub':
                            return -1;
                        case 'vsat_mgmt-lan':
                            return -1;
                        case 'vsat_mgmt-wan':
                            return 1;
                        default:
                            return 0;
                    }
                }) : []
            }
        }
        case ACTION_TYPES.CREATE_REMOTE_CONNECTION: {
            return {
                ...state,
                generatedLinks: [
                    action.payload,
                    ...state.generatedLinks,
                ]
            }
        }
        case ACTION_TYPES.DELETE_REMOTE_CONNECTION: {
            return {
                ...state,
                generatedLinks: state.generatedLinks.filter((rc: any) => rc.connectionId !== action.payload)
            }
        }
        case ACTION_TYPES.UPDATE_REMOTE_CONNECTION: {
            let rcIndex = state.generatedLinks.findIndex((rc: any) => rc.connectionId === action.payload.connectionId)
            state.generatedLinks[rcIndex] = action.payload;
            return {
                ...state,
                generatedLinks: Array.from([
                    ...state.generatedLinks
                ])
            }
        }
        case ACTION_TYPES.CREATE_SCANNED_DEVICE: {
            return {
                ...state,
                scannedDevices: [
                    ...state.scannedDevices,
                    action.payload
                ]
            }
        }
        case ACTION_TYPES.DELETE_SCANNED_DEVICE: {
            let toDelete = new Set(action.payload)
            return {
                ...state,
                scannedDevices: _.cloneDeep(state.scannedDevices.filter((sd: any) => !toDelete.has(sd.id)))
            }
        }
        case ACTION_TYPES.UPDATE_SCANNED_DEVICE: {
            let sdIndex = state.scannedDevices.findIndex((sd: any) => sd.id === action.payload.id)
            state.scannedDevices[sdIndex] = Object.assign(state.scannedDevices[sdIndex], action.payload);
            return {
                ...state,
                scannedDevices: [
                    ...state.scannedDevices
                ]
            }
        }
        case ACTION_TYPES.CLEAR: {
            return initialState
        }
        default:
            return state
    }
}

export const getScannedDevices = (siteId, deviceId, filter = {}, signal = undefined) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });

    let filterConditions: string[] = []
    for (let condition of Object.keys(filter)) {
        if (!_.isEmpty(filter[condition]) && condition !== "alias") {
            filterConditions.push(`${condition}=${_.isArray(filter[condition]) ? filter[condition].join(',') : filter[condition]}`)
        }
    }
    filterConditions.push('page=1')
    filterConditions.push('size=1000')
    let URL = `/v1/sites/${siteId}/devices/${deviceId}/scanned_devices?${filterConditions.join('&')}`;
    api.siteController.get(URL, {}, signal)
        .then((res) => {
            dispatch({ type: SET_LOADING, payload: false })
            dispatch({ type: ACTION_TYPES.GET_SCANNED_DEVICES, payload: res?.result })
        })
        .catch((err) => {
            dispatch({ type: SET_LOADING, payload: false })
        });
};

export const getGeneratedLinks = (siteId, deviceId, filter, signal = undefined) => async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    let filterConditions: string[] = []
    for (let condition of Object.keys(filter)) {
        if (!_.isEmpty(filter[condition])) {
            filterConditions.push(`${condition}=${_.isArray(filter[condition]) ? filter[condition].join(',') : filter[condition]}`)
        }
    }
    filterConditions.push('page=1')
    filterConditions.push('size=10000')
    let URL = `/v1/sites/${siteId}/devices/${deviceId}/remote_connections?${filterConditions.join('&')}`;
    try {
        let res = await api.siteController.get(URL, {}, signal);
        dispatch({ type: SET_LOADING, payload: false })
        dispatch({ type: ACTION_TYPES.GET_GENERATED_LINKS, payload: res?.result })
        return res;
    } catch (error) {
        dispatch({ type: SET_LOADING, payload: false })
        return null;
    }
};

export const createRemoteConnectionLink = (siteId, deviceId, data) => async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    let URL = `/v1/sites/${siteId}/devices/${deviceId}/remote_connections`;
    try {
        let res = await api.siteController.post(URL, data);
        dispatch({ type: SET_LOADING, payload: false })
        dispatch({ type: ACTION_TYPES.CREATE_REMOTE_CONNECTION, payload: res?.result })
        return res;
    } catch (error) {
        dispatch({ type: SET_LOADING, payload: false })
        return null;
    }
};

export const deleteRemoteConnectionLink = (siteId, deviceId, connectionId) => async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    let URL = `/v1/sites/${siteId}/devices/${deviceId}/remote_connections/${connectionId}`;
    try {
        let res = await api.siteController.delete(URL);
        dispatch({ type: SET_LOADING, payload: false })
        dispatch({ type: ACTION_TYPES.DELETE_REMOTE_CONNECTION, payload: connectionId })
        return res?.result;
    } catch (error) {
        dispatch({ type: SET_LOADING, payload: false })
        return null;
    }
};

export const updateRemoteConnectionLink = (siteId, deviceId, connectionId, data) => async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    let URL = `/v1/sites/${siteId}/devices/${deviceId}/remote_connections/${connectionId}`;
    try {
        let res = await api.siteController.put(URL, data);
        dispatch({ type: SET_LOADING, payload: false })
        dispatch({ type: ACTION_TYPES.UPDATE_REMOTE_CONNECTION, payload: res?.result })
        return res?.result;
    } catch (error) {
        dispatch({ type: SET_LOADING, payload: false })
        return null;
    }
};

export const createScannedDevice = (siteId, deviceId, data) => async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    let URL = `/v1/sites/${siteId}/devices/${deviceId}/scanned_devices`;
    try {
        let res = await api.siteController.post(URL, data);
        dispatch({ type: SET_LOADING, payload: false })
        dispatch({ type: ACTION_TYPES.CREATE_SCANNED_DEVICE, payload: res?.result })
        return res?.result;
    } catch (error) {
        dispatch({ type: SET_LOADING, payload: false })
        return null;
    }
};

export const deleteScannedDevice = (siteId, deviceId, scannedDeviceId) => async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    let URL = `/v1/sites/devices/scanned_devices`;
    try {
        let res = await api.siteController.delete(URL, {
            site_id: [siteId],
            device_id: [deviceId],
            scanned_device_id: scannedDeviceId
        });
        dispatch({ type: SET_LOADING, payload: false })
        dispatch({ type: ACTION_TYPES.DELETE_SCANNED_DEVICE, payload: scannedDeviceId })
        return res?.result;
    } catch (error) {
        dispatch({ type: SET_LOADING, payload: false })
        return null;
    }
};

export const updateScannedDevice = (siteId, deviceId, scannedDeviceId, data) => async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    let URL = `/v1/sites/${siteId}/devices/${deviceId}/scanned_devices/${scannedDeviceId}`;
    try {
        let res = await api.siteController.put(URL, data);
        dispatch({ type: SET_LOADING, payload: false })
        dispatch({ type: ACTION_TYPES.UPDATE_SCANNED_DEVICE, payload: { id: scannedDeviceId, ...data } })
        return res?.result;
    } catch (error) {
        dispatch({ type: SET_LOADING, payload: false })
        return null;
    }
};

export const clearData = () => (dispatch) => {
    dispatch({ type: ACTION_TYPES.CLEAR});
};
