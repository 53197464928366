import { Grid } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import FirewallPieChart, { ChartData } from "./FirewallPieChart";
import { Activity, FirewallReportContext, updateActivitySelection } from "./FirewallReportContext";


interface PieChartProps {
    data: ChartData[];
    onLinkClick: (data: ChartData) => void; 
    name: string;
}

interface DailyActiveData {
    percAllow: number;
    percDenied: number;
    totAllowed: number;
    totDenied: number;
}

interface RuleNameData {
    ruleName: string;
    totalPackets: number;
    percentage: number;
    site_name: string;
    action: number;
}

const FirewallReportPieChart = (props: PieChartProps) => {
    return (
        <Grid>
            <Grid className="usage-doughnutChart">
                <FirewallPieChart {...props} unit='' title='' />
            </Grid>
        </Grid>
    )
}

const FirewallReportChart = (props: any) => {

    const { getFirewallActiveToday, getFirewallRuleName, getVesselsListing } = props;
    const { activities, handleRuleNameChange, handleActivityFilter, onSelectFilters } = useContext(FirewallReportContext);

    const [activeTodayReport, setActiveTodayReport] = useState<Array<ChartData>>([]);
    const [ruleNameReport, setRuleNameReport] = useState<Array<ChartData>>([]);


    useEffect(() => {
        if (_.isEmpty(getFirewallActiveToday))
            return;

        const data = getFirewallActiveToday?.hasOwnProperty('data') ? getFirewallActiveToday?.data : {};
        if (!data)
            return;
        const columns: string[] = data.columns ?? [];
        const rows: any[] = data.rows ?? [];
        rows.forEach((row) => {
            const item: Partial<DailyActiveData> = {};
            columns.forEach((col, index) => {
                item[col] = row[index];
            })
            const { percAllow = 0, percDenied = 0, totAllowed = 0, totDenied = 0 } = item;
            setActiveTodayReport([
                { x: 'Allowed', y: percAllow, z: totAllowed },
                { x: 'Denied', y: percDenied, z: totDenied }
            ]);
        });
    }, [getFirewallActiveToday])

    useEffect(() => {
        if (_.isEmpty(getVesselsListing)) {
            setActiveTodayReport([])
            setRuleNameReport([]);
        }
    }, [getVesselsListing])

    useEffect(() => {
        if (_.isEmpty(getFirewallRuleName))
            return;

        const data = getFirewallRuleName?.hasOwnProperty('data') ? getFirewallRuleName?.data : {};
        if (!data)
            return;
        const columns: string[] = data.columns ?? [];
        const rows: any[] = data.rows ?? [];
        const chartData: ChartData[] = [];
        rows.forEach((row) => {
            const item: Partial<RuleNameData> = {};
            columns.forEach((col, index) => {
                item[col] = row[index];
            })
            const { ruleName = '-', percentage = 0, totalPackets = 0, site_name, action } = item;
            chartData.push({ x: ruleName, y: percentage, z: totalPackets, tooltip: <span>Site Name: {site_name}<br />{action === 1 ? 'Action: Allowed' : "Action: Denied"}</span> });
        });
        setRuleNameReport(chartData);
    }, [getFirewallRuleName])

    const handleActivityLinkClick = (data: ChartData) => {
        let selectedActivity = updateActivitySelection(activities, data.x);
        if (!selectedActivity) return;
        handleActivityFilter(selectedActivity);
        onSelectFilters({ filterActivities: activities });
    }

    const handleRuleLinkClick = (data: ChartData) => {
        handleRuleNameChange(data.x);
        onSelectFilters({ filterRuleName: data.x });
    }


    return <Grid container spacing={4} className="userDataRightContainer">
        <Grid item xs={12} sm={4} md={6} lg={6}>
            <div className='margin-b-10'>
                <span className='doughnut-graph-head'>Firewall Actions </span>
            </div>
            <FirewallReportPieChart data={activeTodayReport} name='No. of Rules' onLinkClick={handleActivityLinkClick}/>
        </Grid>
        <Grid item xs={12} sm={4} md={6} lg={6}>
            <div className='margin-b-10'>
                <span className='doughnut-graph-head'>Top Rules</span>
            </div>
            <FirewallReportPieChart data={ruleNameReport} name='No. of Packets' onLinkClick={handleRuleLinkClick}/>
        </Grid>

        {/*  */}
    </Grid>
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    getFirewallActiveToday: state.authReducer?.getFirewallActiveToday,
    getFirewallRuleName: state.authReducer?.getFirewallRuleName,
    getVesselsListing: state.authReducer?.getVesselsListing,
});

export default connect(mapStateToProps)(FirewallReportChart);