import close from '../../../../public/images/icon-close.png';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Autocomplete, Divider, Grid, Popper, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getLTEOperators, getVSATOperators, getStarlinkServiceLines, getSiteStarlinkCount } from '../../../actions/singleStoreActions';
import _ from 'lodash';
import './layers.css';

const LayerDetails = (props: any) => {
    const { lteCarriersList, vsatCarrierList, starlinkServiceLines, ouId, products, getLayerDetails, setOpen, layerSelectionDetails, isLocationEnabled, isSpecialPrrmEnabled} = props;

    const dispatch = useDispatch();

    const sourceData: any = [
        {
            title: 'Starlink', dd: false,
            data_sorce_options: ['Latency', 'Speed', 'Drop Percentage'], micro_macro_value: [{ key: 'Macro', value: '12393', selectedValue: layerSelectionDetails.Starlink_Macro ? true : false }, { key: 'Micro', value: '1770', selectedValue: layerSelectionDetails.Starlink_Micro ? true : false }],
            selectedSource: layerSelectionDetails.selectedSource == 'Starlink' && 'Starlink', selectedDS: layerSelectionDetails.Starlink_Latency ? 'Latency' : layerSelectionDetails.Starlink_Speed ? 'Speed' : 'Drop Percentage'
        },
        {
            title: 'Cell', dd: true,
            data_sorce_options: ['Call Performance', 'Fastest Speed'], micro_macro_value: [{ key: 'Macro', value: '252', selectedValue: layerSelectionDetails.Cell_Macro ? true : false }, { key: 'Micro', value: '5', selectedValue: layerSelectionDetails.Cell_Micro ? true : false }],
            selectedSource: layerSelectionDetails.selectedSource == 'Cell' && 'Cell', operators: [], selectedOperator: layerSelectionDetails.Cell_provider, selectedDS: layerSelectionDetails.Cell_CallPerformance ? 'Call Performance' : 'Fastest Speed'
        },
        // {
        //     title: 'VSAT', dd: true,
        //     data_sorce_options: ['Call Performance', 'Fastest Speed'], micro_macro_value: [{ key: 'Macro', value: '252', selectedValue: true }, { key: 'Micro', value: '', selectedValue: false }],
        //     selectedSource: layerSelectionDetails.selectedSource == 'VSAT' && 'VSAT', operators: [], selectedOperator: layerSelectionDetails.VSAT_provider, selectedDS: layerSelectionDetails.VSAT_CallPerformance ? 'Call Performance' : 'Fastest Speed'
        // }
    ]

    let location = [
        { title: 'Site', dd: false, selectedLocation: layerSelectionDetails.Site && 'Site' },
        { title: 'Site with Starlink', dd: false, selectedLocation: layerSelectionDetails.SitewithStarlink && 'Site with Starlink' },
        { title: 'Starlink', dd: true, serviceLines: [], selectedLocation: layerSelectionDetails.Starlink ? 'Starlink' : '', selectedServiceLine: layerSelectionDetails.serviceTerminal == 'all' ? {label: 'All', id: 'All'} : layerSelectionDetails.serviceTerminal == null ? {label :'', id: ''} : {label: layerSelectionDetails.serviceTerminalName, id: layerSelectionDetails.serviceTerminal.id} }
    ]

    let viasatDefaultData = [
        { title: 'Viasat 1', dd: false, selectedLocation: layerSelectionDetails.Viasat1 ? 'Viasat 1' : ""  },
        { title: 'Viasat 2', dd: false, selectedLocation: layerSelectionDetails.Viasat2 ? 'Viasat 2': "" },
    ]

    if(localStorage.getItem("isStarlinkEnabled") !== "true") {
        location =       [{ title: 'Site', dd: false, selectedLocation: layerSelectionDetails.Site && 'Site' },
        { title: 'Site with Starlink', dd: false, selectedLocation: layerSelectionDetails.SitewithStarlink && 'Site with Starlink' }]
    }
    const [data, setData] = useState(sourceData);
    const [locationData, setLocationData] = useState(location);
    const [viasatData, setViasatData] = useState(viasatDefaultData);
    const [heatMaps, setHeatMaps] = useState(layerSelectionDetails.heatMaps);

    useEffect(() => {
        dispatch(getStarlinkServiceLines(ouId));
    }, [ouId])

    useEffect(() => {
        let arr: any = [...data];
        let arr2: any = [...locationData]
        if (!_.isEmpty(lteCarriersList)) {
            const operators = lteCarriersList.hasOwnProperty('data') ? lteCarriersList.data?.data?.operator?.data : [];
            arr[1].operators = operators.includes('All') ? operators : ['All', ...operators];
            products.lteCarriersList = {};
        }
        if (!_.isEmpty(vsatCarrierList)) {
            const operators = vsatCarrierList.hasOwnProperty('data') ? vsatCarrierList.data?.data?.service_provider?.data : [];
            arr[2].operators = operators.filter((el: string) => el !== '');
            products.vsatCarrierList = {};
        }
        if (!_.isEmpty(starlinkServiceLines) && localStorage.getItem("isStarlinkEnabled") === "true") {
            const serviceLines = starlinkServiceLines.hasOwnProperty('data') ? starlinkServiceLines.data?.data : [];
            const result = serviceLines?.map((sl: { service_line_number: any; service_line_name: any; }) => {
                return { label: sl.service_line_name, id: sl.service_line_number };
            })
            arr2[2].serviceLines = [{label: 'All', id: 'All'}, ...result];
            products.starlinkServiceLines = {};
        }
        setData(arr);
        setLocationData(arr2);
    }, [lteCarriersList, vsatCarrierList, starlinkServiceLines]);

    const getFinalObject = (arr: any, name: string, heatmap: boolean) => {
        let source_Data: any = [];
        let location_Data: any = [];
        let viasat_Data: any = [];
        let finalObj: any = {};

        if (name == 'dataSource') {
            source_Data = arr;
            location_Data = locationData;
            viasat_Data = viasatData;
        } else if (name == 'location') {
            source_Data = data;
            location_Data = arr;
            viasat_Data = viasatData;
        } else if (name == 'viasat') {
            source_Data = data;
            location_Data = locationData;
            viasat_Data = arr;
        }
        source_Data?.forEach((item: any) => {
            item.micro_macro_value.forEach((ele: any) => {
                if (ele.selectedValue && item.selectedSource !== '' && heatmap) {
                    finalObj[`${item.title}_${ele.key}`] = ele.value
                }
                else finalObj[`${item.title}_${ele.key}`] = null
            })
            if(item.selectedSource && item.selectedSource !== '') {
                finalObj[`selectedSource`] = item.selectedSource;
            }
            if (item.selectedOperator && item.selectedOperator !== '') {
                finalObj[`${item.title}_provider`] = item.selectedOperator
            } else {
                finalObj[`${item.title}_provider`] = null
            }
            if (item.selectedDS && item.selectedDS !== '' && item.selectedSource !== '') {
                finalObj[`${item.title}_${(item.selectedDS).replace(/\s/g, '')}`] = true;
            }
            item.data_sorce_options?.filter((el: any) => item.selectedSource !== '' ? el !== item.selectedDS : el)?.forEach((ele: any) => {
                finalObj[`${item.title}_${(ele).replace(/\s/g, '')}`] = null;
            })
        })
        location_Data?.forEach((location: any) => {
            if (location.selectedLocation && location.selectedLocation !== '') {
                finalObj[`${(location.selectedLocation).replace(/\s/g, '')}`] = true
            } else {
                finalObj[`${(location.title).replace(/\s/g, '')}`] = null
            }
            if (location.selectedLocation !== 'Starlink') {
                finalObj[`serviceTerminal`] = 'all'
                finalObj[`serviceTerminalName`] = 'all'
            } else if (location.selectedLocation == 'Starlink' && location.selectedServiceLine && location.selectedServiceLine !== '') {
                finalObj[`serviceTerminal`] = location.selectedServiceLine.id
                finalObj[`serviceTerminalName`] = location.selectedServiceLine.label
            }
            if(location.selectedLocation == 'Starlink' &&  !location.selectedServiceLine){
                finalObj[`serviceTerminal`] = null
                finalObj[`serviceTerminalName`] = null
            }
        })
        viasat_Data?.forEach((location: any) => {
            if (location.selectedLocation && location.selectedLocation !== '') {
                finalObj[`${(location.selectedLocation).replace(/\s/g, '')}`] = true
            } else {
                finalObj[`${(location.title).replace(/\s/g, '')}`] = null
            }
        })
        finalObj.heatMaps = heatmap;

        getLayerDetails(finalObj);
    }

    const handleChangeDataSource = (ds: any, name: string, value: any) => {
        let arr: any = [];
        switch (name) {
            case 'icon': {
                data?.forEach((el: any) => {
                    let obj = { ...el }
                    if (el.title == ds.title) {
                        if (ds.title == 'Cell') {
                            dispatch(getLTEOperators());
                            obj.selectedOperator = 'All'
                        }
                        else if (ds.title == 'VSAT') dispatch(getVSATOperators());
                        obj.selectedSource = el.title;
                        obj.selectedDS = obj.data_sorce_options[0];
                        obj.micro_macro_value[0].selectedValue = true;
                        obj.micro_macro_value[1].selectedValue = false;
                    } else {
                        obj.selectedSource = '';
                        obj.selectedOperator = '';
                    }
                    arr.push(obj)
                });
                break;
            }
            case 'dd': {
                data?.forEach((el: any) => {
                    let obj = { ...el }
                    if (el.title == ds.title) {
                        obj.selectedOperator = value
                    } else {
                        obj.selectedOperator = null
                    }
                    arr.push(obj)
                });
                break;
            }
            case 'wan_ds': {
                data.forEach((el: { title: string; selectedDS: string }) => {
                    let obj = { ...el };
                    if (el.title == ds.title) {
                        obj.selectedDS = value;
                    } else {
                        obj.selectedDS = '';
                    }
                    arr.push(obj);
                });
                break;
            }
            case 'heatMap': {
                data.forEach((el: { title: string; selectedDS: string; micro_macro_value: any }) => {
                    let obj = { ...el };
                    if (el.title == ds.title) {
                        obj.micro_macro_value.forEach((ele: any, i: any) => {
                            if (ele.key == value) {
                                obj.micro_macro_value[i].selectedValue = true
                            } else {
                                obj.micro_macro_value[i].selectedValue = false
                            }
                        })
                    }
                    arr.push(obj);
                });
                break;
            }
            case 'viasat': {
                data.forEach((el: { title: string; selectedDS: string; micro_macro_value: any }) => {
                    let obj = { ...el };
                    if (el.title == ds.title) {
                        obj.micro_macro_value.forEach((ele: any, i: any) => {
                            if (ele.key == value) {
                                obj.micro_macro_value[i].selectedValue = true
                            } else {
                                obj.micro_macro_value[i].selectedValue = false
                            }
                        })
                    }
                    arr.push(obj);
                });
                break;
            }
        }
        getFinalObject(arr, 'dataSource', heatMaps)
        setData(arr)
    }

    const handleChangeLocation = (ds: any, name: string, value: any) => {
        let arr: any = [];
        let viasatArr:any = [];
        switch (name) {
            case 'icon': {
                locationData.forEach((el) => {
                    let obj = { ...el };
                    if (el.title == ds.title) {
                        if(el.title == 'Starlink') {
                            obj.selectedServiceLine = { label: 'All', id: 'All' };                        
                        }
                        if (obj.selectedLocation !== '') {
                            obj.selectedLocation = '';
                        } else
                            obj.selectedLocation = el.title;
                    }
                    arr.push(obj);
                });
                break;
            }
            case 'viasat': {
                viasatData.forEach((el) => {
                    let obj = { ...el };
                    if (el.title == ds.title) {
                        if (obj.selectedLocation !== '') {
                            obj.selectedLocation = '';
                        } else
                            obj.selectedLocation = el.title;
                    }
                    viasatArr.push(obj);
                });
                break;
            }
            case 'dd': {
                locationData?.forEach((el: any) => {
                    let obj = { ...el }
                    if (el.title == ds.title) {
                        obj.selectedServiceLine = value
                    }
                    arr.push(obj)
                });
                break;
            }
        }
        if (name == 'icon') {
            getFinalObject(arr, 'location', heatMaps)
            setLocationData(arr);
        } else if(name == 'viasat'){
            getFinalObject(viasatArr, 'viasat', heatMaps);
            setViasatData(viasatArr);
        }
    }

    const handleChangeheatmaps = () => {
        let heatMap = !heatMaps;
        let result: any = [];
        if (heatMap) {
            result = data?.map((el: any) => {
                let obj = { ...el };
                obj.micro_macro_value[0].selectedValue = true;
                obj.micro_macro_value[1].selectedValue = false;
                return obj
            })
        } else {
            result = data?.map((el: any) => {
                let obj = { ...el };
                obj.micro_macro_value[0].selectedValue = false;
                obj.micro_macro_value[1].selectedValue = false;
                return obj
            })
        }
        getFinalObject(result, 'dataSource', heatMap);
        setData(result);
        setHeatMaps(heatMap);
    }

    return (
        <div>
            <div className='layers-title-close-icon'>
                <span>Map Layers</span>
                <img src={close} alt='close' onClick={() => setOpen(false)} />
            </div>
            {isLocationEnabled !==1 && <div className='layers-content-container'>
                <span className='each-content-title title-location-maps'>Location</span>
                {locationData?.map((ds: any, i:any) => (
                    <Grid className='each-item-source'>
                        <Grid className='each-content-child'>
                            {ds.title == ds.selectedLocation ? <RemoveRedEyeOutlinedIcon className={i == 1 ? "location-eye-icons" :"location-eye-icons"} onClick={() => handleChangeLocation(ds, 'icon', null)} /> : <VisibilityOffOutlinedIcon className={i == 1 ? "location-eye-icons " :"location-eye-icons"} onClick={() => handleChangeLocation(ds, 'icon', null)} />}
                            <span className={""}>{ds.title}</span>
                        </Grid>
                        {/* {ds.dd && <Autocomplete
                            disablePortal
                            ListboxProps={{ style: { fontSize: '12px' } }}
                            size='small'
                            id='combo-box-demo-map'
                            disableClearable={ds.selectedServiceLine ? false : true}
                            value={ds.selectedServiceLine}
                            onChange={(e: any, newvalue: any) => handleChangeLocation(ds, 'dd', newvalue)}
                            options={ds.serviceLines}
                            getOptionLabel={(option) => option.label}
                            sx={{ width: 150, height: 35, padding: 0, fontSize: 10 }}
                            className={ds.selectedLocation !== 'Starlink' ? 'hide-starlink-dd' : ''}
                            renderInput={(params) => <TextField {...params} label='select service line' style={{ fontSize: '11px' }} />}
                        />} */}
                    </Grid>
                ))}
            </div>}
            <Divider className='layers-content-divider' />
            <div className='layers-content-container'>
                <div className='heat-maps-title-div'>
                    {heatMaps ? <RemoveRedEyeOutlinedIcon className='location-eye-icons' onClick={handleChangeheatmaps} /> : <VisibilityOffOutlinedIcon className='location-eye-icons' onClick={handleChangeheatmaps} />}
                    <span className='each-content-title'>Heat Maps</span>
                </div>
                {heatMaps && data?.map((ds: { micro_macro_value: any[]; title: string; selectedSource: string; }) => (
                    ds.micro_macro_value.map(sc => (
                        ds.title == ds.selectedSource && <Grid className='each-item-source'>
                            {sc.value && <Grid className='each-content-child'>
                                {sc.selectedValue ? <RadioButtonCheckedIcon className='each-content-radio-btn' onClick={() => handleChangeDataSource(ds, 'heatMap', sc.key)} /> : <RadioButtonUncheckedIcon className='each-content-radio-btn' onClick={() => handleChangeDataSource(ds, 'heatMap', sc.key)} />}
                                <span>{`${sc.key} (${sc.value} km2)`}</span>
                            </Grid>}
                        </Grid>
                    ))
                ))}
            </div>
            {heatMaps && <Divider className='layers-content-divider' />}
            <div className='layers-content-container'>
                {heatMaps && <span className='each-content-title'>Data Source</span>}
                {heatMaps && data?.map((ds: { selectedOperator: any; selectedSource: string; operators: unknown[]; title: string; dd: any; }) => (
                    <Grid className='each-item-source'>
                        <Grid className='each-content-child'>
                            {ds.title == ds.selectedSource ? <RadioButtonCheckedIcon className='each-content-radio-btn' onClick={() => handleChangeDataSource(ds, 'icon', null)} /> : <RadioButtonUncheckedIcon className='each-content-radio-btn' onClick={() => handleChangeDataSource(ds, 'icon', null)} />}
                            <span>{ds.title}</span>
                        </Grid>
                        {ds.dd && <Autocomplete
                            disablePortal
                            ListboxProps={{ style: { fontSize: '12px' } }}
                            size='small'
                            id='combo-box-demo-map'
                            disableClearable={ds.selectedOperator ? false : true}
                            options={ds.title === ds.selectedSource ? ds.operators : []}
                            sx={{ width: 150, height: 35, padding: 0, fontSize: 10 }}
                            value={ds.title === ds.selectedSource ? ds.selectedOperator : null}
                            className={(ds.selectedSource !== 'Cell' && ds.selectedSource !== 'VSAT') ? 'hide-starlink-dd' : ''}
                            onChange={(e: any, newvalue: any) => handleChangeDataSource(ds, 'dd', newvalue)}
                            PopperComponent={(props) => (
                                <Popper
                                  {...props}
                                  placement="top"
                                />
                              )}
                            renderInput={(params) => <TextField {...params} label='select provider' style={{ fontSize: '11px' }} />}
                        />}
                    </Grid>
                ))}
            </div>
            {heatMaps && <Divider className='layers-content-divider' />}
            <div className='layers-content-container'>
                {heatMaps && <span className='each-content-title'>Layers</span>}
                {heatMaps && data?.map((ds: { selectedDS: any; data_sorce_options: any[]; title: string; selectedSource: string }) => (
                    ds.data_sorce_options.map((sc, i) => (
                        ds.title == ds.selectedSource && <Grid className='each-item-source'>
                            <Grid className='each-content-child'>
                                {ds.selectedDS == sc ? <RadioButtonCheckedIcon className='each-content-radio-btn' onClick={() => handleChangeDataSource(ds, 'wan_ds', sc)} /> : <RadioButtonUncheckedIcon className='each-content-radio-btn' onClick={() => handleChangeDataSource(ds, 'wan_ds', sc)} />}
                                <span>{sc}</span>
                            </Grid>
                        </Grid>
                    ))
                ))}
            </div>
            {isSpecialPrrmEnabled && <Divider className='layers-content-divider' />}
            {isSpecialPrrmEnabled && <div className='layers-content-container'>
                <span className='each-content-title title-location-maps'>Viasat</span>
                {viasatData?.map((ds: any, i:any) => (
                    <Grid className='each-item-source'>
                        <Grid className='each-content-child'>
                            {ds.title == ds.selectedLocation ? <RemoveRedEyeOutlinedIcon className={i == 1 ? "location-eye-icons" :"location-eye-icons"} onClick={() => handleChangeLocation(ds, 'viasat', null)} /> : <VisibilityOffOutlinedIcon className={i == 1 ? "location-eye-icons " :"location-eye-icons"} onClick={() => handleChangeLocation(ds, 'viasat', null)} />}
                            <span className={""}>{ds.title}</span>
                        </Grid>
                    </Grid>
                ))}
            </div>}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    products: state?.products,
    lteCarriersList: state?.products?.carriersList,
    vsatCarrierList: state?.products?.vsatCarrierList,
    starlinkServiceLines: state?.products?.starlinkServiceLines,
    ouId: state.authReducer?.selectedOu?.id
});

const LayerDetailsMaps = connect(
    mapStateToProps,
)(LayerDetails);

export default LayerDetailsMaps;