export interface Alert {
    id?: string
    name: string
    root_condition: string
    event_alerts: { [key: string]: EventAlert; }
    metric_alerts: { [key: string]: MetricAlert; }
    // geoAlerts: Map<string, GeoAlert>
    status?: string,
    labels?: { [key: string]: string },
    description: string
}
export interface EventAlert {
    entry: EventCondition[]
    exit: EventCondition[]
    status?: string
}
export interface EventCondition {
    key: EventConditionKeys
    operation: EventConditionOperations
    value: string
}
export enum EventConditionKeys {
    Device = "device_id",
    Module = "module",
    Level = "level",
    Description = "description",
}
export enum EventConditionOperations {
    Equal = "equal",
    Notequal = "not equal",
    Contains = "contains",
    Startswith = "starts with",
    Endswith = "ends with",
}
export interface MetricAlert {
    device: string
    interface: string
    metric: string
    every: string
    for: string
    metric_condition: MetricCondition
    nodata: string
    error: string
}
export interface MetricCondition {
    aggregation: string
    start_interval: string
    end_interval: string
    condition: string
    condition_value: number
}
export enum MetricTypes {
    "Link Status" = "link_status",
    SINR = "sinr",
    RSSI = "rssi",
    Latency = "latency",
    Jitter = "jitter",
    "Peak Rate Estimate" = "peak_rate_estimate",
}

export enum _MetricTypes {
    link_status = "Link Status",
    sinr = "SINR",
    rssi = "RSSI",
    latency = "Latency",
    jitter = "Jitter",
    peak_rate_estimate = "Peak Rate Estimate",
}
export enum MetricAggregation {
    count = "count",
    sum = "sum",
    min = "min",
    max = "max",
    average = "avg"
}
export enum MetricConditionType {
    above = "above",
    below = "below"
}
export enum MetricConditionEndInterval {
    "now" = "now",
    "5m" = "5m",
    "10m" = "10m",
    "30m" = "30m",
    "1h" = "1h",
}
export enum MetricConditionStartInterval {
    "5m" = "5m",
    "10m" = "10m",
    "30m" = "30m",
    "1h" = "1h",
}
export enum AlertStatus {
    Normal = "Normal",
    Pending = "Pending",
    Alerting = "Alerting"
}
export enum NoDataErrorOptions {
    Null = "null",
    Normal = "Normal",
    Pending = "Pending",
    Alerting = "Alerting"
}
export interface AlertStatusHistory {
    timeStamp: Date,
    status: AlertStatus
}

export enum AlertConfigTypes {
    Event = "event_alerts",
    Metric = "metric_alerts",
    "Geo Location" = "geo_alerts"
}

export enum AlertConfigTypeName {
    "event_alerts" = "Event",
    "metric_alerts" = "Metric",
    "geo_alerts" = "Geo Location"
}

export type AlertConfig = {
    id: string
    type: AlertConfigTypes.Event
    data: EventAlert
} | {
    id: string
    type: AlertConfigTypes.Metric
    data: MetricAlert
}