import { Fragment, useState, useEffect } from "react";

import Highcharts, { seriesType } from "highcharts";
import HighchartsReact from 'highcharts-react-official';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsTimeline from 'highcharts/modules/timeline';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ChartsTable from "./ChartsTable";

const Dash = (props) => {
    return (
        <svg width="12" height="4" viewBox="0 0 12 4" fill={props.fill} xmlns="http://www.w3.org/2000/svg" style={{paddingBottom: '3px'}}>
            <path d="M0.828613 0H7.04783V3H0.828613V0Z" fill={props.fill}></path>
        </svg>
    )
}

NoDataToDisplay(Highcharts);
HighchartsTimeline(Highcharts);

const Chart = (props: any) => {
    const PING_DROP_RATE = "Ping Drop Rate"
    const { chartOptions, immutable, updateArgs, hideLegendTable, parameters, page, setPage, enableEventsTable} = props;
    const tableHeadCellStyle = {background: '#DDE2EE', color: '#747474', fontWeight: '500', fontSize: '0.8rem'};
    const tableRowCellStyle = {fontWeight: '500', color: '#3F3F3F', fontSize: '0.8rem'};

    const getTotalUsage = (series: any) => {
        const usage = series?.data?.reduce((sum, point) => sum + point.y, 0);
        return usage && series.valueUnit ? {usageValue: usage, unit: series.valueUnit} : 0;
    }

    return (
        <Fragment>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} immutable={immutable ? immutable : false} updateArgs={updateArgs ? updateArgs : [true, true, true]} />
            { !hideLegendTable && chartOptions?.series?.length > 0 ? <TableContainer component={Paper} sx={{m: 2, width: 'unset', overflowY: 'auto', boxShadow: 'unset', border: '1px solid #DDE2EE'}} className="legend-table-container">
                <Table size="small" aria-label="a dense table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={tableHeadCellStyle}>LEGEND</TableCell>
                            <TableCell sx={tableHeadCellStyle} align="right">MIN</TableCell>
                            <TableCell sx={tableHeadCellStyle} align="right">MAX</TableCell>
                            <TableCell sx={tableHeadCellStyle} align="right">AVG</TableCell>
                            <TableCell sx={tableHeadCellStyle} align="right">Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {chartOptions?.series.filter(s => s.name !== 'Event').map((series) => (
                            <TableRow
                                key={series.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={tableRowCellStyle} component="th" scope="row">
                                    <span>
                                        <span><Dash fill={series.color}></Dash></span> {series.name}</span>
                                </TableCell>
                                {series.name == 'SpeedTest' ?
                                    <TableCell sx={tableRowCellStyle} align="right">
                                        <span>{`Upload : ${Math.round((Math.min(...series?.data.map(point => point.upSpeed)) + Number.EPSILON) * 10000) / 10000} Mbps`}</span><br/>
                                        <span>{`Download : ${Math.round((Math.min(...series?.data.map(point => point.downSpeed)) + Number.EPSILON) * 10000) / 10000} Mbps`}</span>
                                    </TableCell>
                                    : <TableCell sx={tableRowCellStyle} align="right">{
                                        series?.name?.includes(PING_DROP_RATE) ? 
                                        Math.round((Math.min(...series?.data.map(point => point.y ? point.y : 0)) + Number.EPSILON) * 100) : 
                                        Math.round((Math.min(...series?.data.map(point => point.y ? point.y : 0)) + Number.EPSILON) * 10000) / 10000
                                    } {series.valueUnit}</TableCell>
                                }
                                {series.name == 'SpeedTest' ?
                                    <TableCell sx={tableRowCellStyle} align="right">
                                        <span>{`Upload : ${Math.round((Math.max(...series?.data.map(point => point.upSpeed)) + Number.EPSILON) * 10000) / 10000} Mbps`}</span><br/>
                                        <span>{`Download : ${Math.round((Math.max(...series?.data.map(point => point.downSpeed)) + Number.EPSILON) * 10000) / 10000} Mbps`}</span>
                                    </TableCell>
                                    : <TableCell sx={tableRowCellStyle} align="right">{
                                        series?.name?.includes(PING_DROP_RATE) ? 
                                        Math.round((Math.max(...series?.data.map(point => point.y)) + Number.EPSILON) * 100) :
                                        Math.round((Math.max(...series?.data.map(point => point.y)) + Number.EPSILON) * 10000) / 10000
                                    } {series.valueUnit}</TableCell>
                                }
                                {series.name == 'SpeedTest' ?
                                    <TableCell sx={tableRowCellStyle} align="right">
                                        <span>{`Upload : ${(((series?.data.map(point => point.upSpeed).reduce((a,b) => a + b, 0) / series?.data.length + Number.EPSILON) * 10000) / 10000).toFixed(2)} Mbps`}</span><br/>
                                        <span>{`Download : ${(((series?.data.map(point => point.downSpeed).reduce((a,b) => a + b, 0) / series?.data.length + Number.EPSILON) * 10000) / 10000).toFixed(2)} Mbps`}</span>
                                    </TableCell>
                                    : <TableCell sx={tableRowCellStyle} align="right">{
                                        series?.name?.includes(PING_DROP_RATE) ? 
                                        ((series?.data.map(point => point.y).reduce((a,b) => a + b, 0) / series?.data.length + Number.EPSILON) * 100).toFixed(2) :
                                        (((series?.data.map(point => point.y).reduce((a,b) => a + b, 0) / series?.data.length + Number.EPSILON) * 10000) / 10000).toFixed(2)
                                    } {series.valueUnit}</TableCell>
                                }
                                {series?.name?.includes('Usage') ? <TableCell sx={tableRowCellStyle} align="right">{(getTotalUsage(series)['usageValue'])?.toFixed(2)} {getTotalUsage(series)['unit']}</TableCell> : <TableCell sx={tableRowCellStyle} align="right">-</TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> : null}
            {!hideLegendTable && enableEventsTable ?
                <ChartsTable parameters={parameters} Page={page} setPage={setPage} />
             : null}
        </Fragment>
    )
}

export default Chart;