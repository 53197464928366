import _ from "lodash";
import { api } from "../../server/request";
import { handleError, handleResponse } from "../../actions/responseUtil";
import { STARLINK_METRIC_COLUMN_NAMES, StarlinkMetric, StarlinkMetricColumn } from "./constants";

const ACTION_TYPES = {
    SET_LOADING: "starlinkAnalytics/setLoading",
    GET_DEPLOYED_USER_TERMINALS_BY_DP_ID: "starlinkAnalytics/getDeployedUserTerminalsByDPId",
    GET_STARLINK_METRIC_SERIES: "starlinkAnalytics/getStarlinkMetricSeries",
    GET_STARLINK_USER_TERMINALS_ALERTS: "starlinkAnalytics/getStarlinkUserTerminalAlerts",
    CLEAR_METRIC_SERIES: "starlinkAnalytics/clearMetricSeries",
    CLEAR_ALERTS: "starlinkAnalytics/clearAlerts",
}

const initialState = {
    userTerminals: [],
    userTerminalAlerts:[],
    userTerminalAlertColumns:[],
    ustAlertsLoading:false,
    gettingUserTerminals: false,
    series: {
        downlink_throughput: {},
        uplink_throughput: {},
        ping_drop_rate: {},
        ping_latency_ms: {},
        obstruction_percent_time: {},
        signal_quality: {},
    },
    gettingMetricSeries: 0,
}

export function starlinkAnalyticsReducer(state: any = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.GET_DEPLOYED_USER_TERMINALS_BY_DP_ID: {
            const userTerminals = action.payload?.data;
            const totalUserTerminals = action.payload?.data[0]?.total_count;
            return {
                ...state,
                userTerminals: userTerminals || [],
                totalUserTerminals: totalUserTerminals ? totalUserTerminals : 0,
            }
        }
        case ACTION_TYPES.SET_LOADING: {
            if (action.payload.gettingMetricSeries === "add") {
                return {
                    ...state,
                    gettingMetricSeries: state.gettingMetricSeries + 1
                }
            } else if (action.payload.gettingMetricSeries === "remove") {
                return {
                    ...state,
                    gettingMetricSeries: state.gettingMetricSeries - 1
                }
            }
            return {
                ...state,
                ...action.payload
            }
        }
        case ACTION_TYPES.GET_STARLINK_USER_TERMINALS_ALERTS:{
            let data = action.payload?.data?.rows
            return {
                ...state,
                userTerminalAlerts:data,
                userTerminalAlertColumns:action.payload?.data?.columns
            }
        }
        case ACTION_TYPES.GET_STARLINK_METRIC_SERIES: {
            let metric: string = action.payload?.metric;
            let series = action.payload?.data?.metric?.series;
            delete series[""];
            return {
                ...state,
                series: {
                    ...state.series,
                    [metric]: {
                        ...state.series[metric],
                        ...series
                    }
                }
            }
        }
        case ACTION_TYPES.CLEAR_ALERTS:{
            return {
                ...state,
                userTerminalAlerts:[]
            }

        }
        case ACTION_TYPES.CLEAR_METRIC_SERIES: {
            let metric: string = action.payload?.metric;
            let userTerminalIds: undefined | string[] = action.payload?.userTerminalIds;
            console.log(action.payload);
            if (userTerminalIds && userTerminalIds?.length > 0) {
                let series = state.series[metric];
                userTerminalIds.forEach((id) => {
                    delete series[id];
                });
                return {
                    ...state,
                    series: {
                        ...state.series,
                        [metric]: {
                            ...series
                        }
                    }
                }
            } else {
                return {
                    ...state,
                    series: {
                        ...state.series,
                        [metric]: {}
                    }
                }
            }
        }
        default:
            return state
    }
}

export interface paramGetDeployedUserTerminalsByDP {
    dpIds: string[];
    serviceLines: string[];
}

export const getDeployedUserTerminalsByDP = (parameters: paramGetDeployedUserTerminalsByDP) => (dispatch) => {
    if (_.isEmpty(parameters?.dpIds)) return;
    if (_.isEmpty(parameters?.serviceLines)) return;
    if (parameters?.serviceLines.includes('All') ) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: true } });
    let _parameters: any = {
        dpIds: parameters.dpIds.join(','),
        serviceLines: parameters.serviceLines.map(sl => `'${sl}'`).join(','),
    };
    const data = {
        query: `GET_DEPLOYED_USER_TERMINALS_BY_DP_ID`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_DEPLOYED_USER_TERMINALS_BY_DP_ID)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: false } });
        });
}

export interface paramGetStarlinkMetricSeries {
    source: "" | "_agg_1h" | "agg_6h" | "agg_1day" | "agg_1_week";
    start_time: string;
    end_time: string;
    interval: string;
    user_terminal_ids: string;
    metric: StarlinkMetric;
}

export const getStarlinkMetricSeries = (parameters: paramGetStarlinkMetricSeries) => (dispatch) => {
    const data = {
        query: `STARLINK_METRIC_CHART`,
        named: true,
        format: 'chart',
        parameters: {
            ...parameters,
            metric: STARLINK_METRIC_COLUMN_NAMES[parameters.metric],
        },
        groupBy: "device_id",
        series: "metric",
        tsColumn: "tb"
    }
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingMetricSeries: "add" } });
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            if (res?.data) {
                res.data.metric = parameters.metric;
            }
            handleResponse(res, dispatch, ACTION_TYPES.GET_STARLINK_METRIC_SERIES)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingMetricSeries: "remove" } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingMetricSeries: "remove" } });
        });
}

export interface paramGetStarlinkUserTerminalAlerts {
    start_ts: string;
    end_ts: string;
    user_terminal_ids: string;
}


export const getStarlinkUserTerminalAlerts = (parameters: paramGetStarlinkUserTerminalAlerts) => (dispatch) => {
    const data = {
        query: `GET_STARLINK_USER_TERMINAL_ALERTS`,
        named: true,
        format: 'csv',
        parameters: {
            ...parameters,
        }
    }
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { ustAlertsLoading: true } });
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            if (res?.data) {
            }
            handleResponse(res, dispatch, ACTION_TYPES.GET_STARLINK_USER_TERMINALS_ALERTS)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { ustAlertsLoading: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { ustAlertsLoading: false } });
        });
}

export interface paramClearMetricSeries {
    metric: StarlinkMetric;
    userTerminalIds: undefined | string[];
}

export const clearAlerts = (parameters: paramClearMetricSeries) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.CLEAR_METRIC_SERIES, payload: parameters });
}

export const clearMetricSeries = (parameters: paramClearMetricSeries) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.CLEAR_METRIC_SERIES, payload: parameters });
}

export const clearComponentData = () => (dispatch) => {
    dispatch({ type: ACTION_TYPES.GET_DEPLOYED_USER_TERMINALS_BY_DP_ID, payload: { data: [] } })
    dispatch({ type: ACTION_TYPES.GET_STARLINK_USER_TERMINALS_ALERTS, payload: { data: { rows: [[[], 0]] } } })
    dispatch({ type: ACTION_TYPES.GET_STARLINK_METRIC_SERIES,  payload: { metric: [{result:[]}], data:{metric:{series:[""]}} } }) 
    // dispatch({ type: ACTION_TYPES.CLEAR_METRIC_SERIES, payload: parameters });
   
}