import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core'
import { Grid, Button, Table, Collapse } from "@mui/material";
import "./ConfirmDialog.css";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import GenericAccordionComp from '../TableSummary/genericAccordionComp';
import close from "../../asset/image/cross.svg";

export default function ConfirmDialog(props) {
    const { confirmDialog, setConfirmDialog, row, interval, handleClosePopUp } = props;
    const handleConfirmClick = () => {
        // close confirm dialog
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        // call back function for onlcick of YES
        confirmDialog.onConfirm();
    }

    const handleClose = () => {
        
        handleClosePopUp()
    }

    return (
      <div className='status-pop'>
        <Dialog open={confirmDialog.isOpen} className="dashboard_popop_dialog interface-profile-popup" onClose={() => handleClose()}>
          <DialogTitle className='pop-head width-752px'>
            <Grid item container style={{ color: "black", fontSize: "14px" }}>
              {row?.sitename}
              <Grid style={{ marginLeft: "auto" }}>
                <img src={close} onClick={handleClose} style={{ width: "24px", height: "24px", cursor: "pointer", border: "none", color: "black" }} />
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent style={{ padding: "8px, 16px" }}>
          <GenericAccordionComp k4Id={row?.k4Id} interval={interval} data={row} />
          </DialogContent>
          {/* <DialogActions style={{ marginLeft: "18px", marginRight: "20px", marginBottom: "5px"}}>
                <Button className="CancelRoleEditButton" onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}> No </Button>
                <Button className="role-button" onClick={() => handleConfirmClick()}> Yes </Button>
            </DialogActions> */}
        </Dialog>
      </div>
    )
}