import _ from "lodash";
import moment from "moment-timezone";

const ACTION_TYPES = {
    SET_LOADING: "starlinkCloud/setLoading",
    SET_DP_IDS: "starlinkCloud/setDpIds",
    SET_SELECTED_ACCOUNT: "starlinkCloud/setSelectedAccount",
    SET_SEREVICE_ACCOUNTS: "starlinkCloud/setServiceAccounts",
}

const initialState = {
    loading: false,
    dpIds: [],
    accountsData:[],
    selectedAccount : {}
}

export function starlinkCloudReducer(state: any = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.SET_DP_IDS: {
            return {
                ...state,
                dpIds: action.payload
            }
        }
        case ACTION_TYPES.SET_LOADING: {
            return {
                ...state,
                loading: action.payload
            }
        }
        case ACTION_TYPES.SET_SELECTED_ACCOUNT: {
            return {
                ...state,
                selectedAccount: action.payload
            }
        }
        case ACTION_TYPES.SET_SEREVICE_ACCOUNTS: {
            return {
                ...state,
                accountsData: action.payload
            }
        }
        default:
            return state
    }
}

export const setLoading = (loading: boolean) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: loading })
}

export const setDpIds = (dpIds: string[]) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_DP_IDS, payload: dpIds })
}

export const setSelectedAccount = (account) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_SELECTED_ACCOUNT, payload: account })
}

export const setServiceAccount = (data) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_SEREVICE_ACCOUNTS, payload: data })
}
export interface setCommonFiltersParameters {
    startDate: string,
    endDate: string,
    search: string
} 

export const clearComponentData = () => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_DP_IDS, payload: [] })
}