import React, { useEffect, useState } from "react";
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { KAPTURE_UI_BASE_URL } from '../../config';
import { DEFAULT_TIMEZONE, DEFAULT_DATE_FORMAT } from "../../utils/constants";
import userEditImage from "../../asset/image/user_edit.svg";
import moment from "moment-timezone";
import './ServiceManagementTable.css';
import _ from "lodash";
import { SRVC_MGMT_DEACTIVATION_YEAR, SRVC_MGMT_ALTERNATIVE_TEXT_FOR_DEACTIVATION_YEAR } from "../../constants/Constants"
function ServiceManagementTable(props) {

  const { authReducer, selectedVessels, modifyServiePlan, updateData } = props;

  function createData(k4id, vesselName, planType, planName,quota, usage, wanThrottle, actDate, deactDate, planCount, isFirst, index) {
    return { k4id, vesselName, planType, planName,quota, usage, wanThrottle, actDate, deactDate, planCount, isFirst, index };
  }

  let [structuredData, setStructuredData] = useState<any[]>([]);
  let [page, setPage] = React.useState(0);
  let [vessels, setVessels] = useState<any[]>([])
  


  useEffect(() => {
    let vessels = authReducer?.selectedVessels?.vessels;
    if (!vessels) {
      vessels = [];
    }
    setVessels(vessels);
    callGetVesselPlansAPI(vessels);
  }, [authReducer.selectedVessels]);

  useEffect(() => {
    if (updateData) {
      updateData.current = _updateData;
    }
  });

  const _updateData = (vesselPlans: any) => {
    if (vesselPlans) {
      // clear the structuredData
      structuredData.length = 0;
      let data = vesselPlans.data;
      if (data !== undefined) {
        for (let i = 0; i < data.length; i++) {
          prepareVesselPlanInfo(data[i], i);
        }
      }
    }
  };

  function prepareVesselPlanInfo(vessel, index) {
    let k4Id = vessel.hasOwnProperty("k4id") ? vessel.k4id : "";
    let vesselName = vessel.hasOwnProperty("vesselName") ? vessel.vesselName : "";
    let quota = 0;
    let planCount = 0;
    let isFirst = true;
    let usage = 0;
    
    if (vessel.hasOwnProperty("activeLTE") &&
    Array.isArray(vessel.activeLTE) &&
    vessel.activeLTE.length > 0) {
      vessel.activeLTE.reduce((el)=>{
        quota += el.quota
      })
  }

  if(vessel.hasOwnProperty("lteUsage")&&
  Array.isArray(vessel.lteUsage) &&
  vessel.lteUsage.length > 0){
      usage = vessel.lteUsage[0].totalUsage
  }

    if (vessel.hasOwnProperty("activeVSAT") &&
      Array.isArray(vessel.activeVSAT) &&
      vessel.activeVSAT.length > 0) {
      planCount += vessel.activeVSAT.length;
    }

    if (vessel.hasOwnProperty("activeLTE") &&
      Array.isArray(vessel.activeLTE) &&
      vessel.activeLTE.length > 0) {
      planCount += vessel.activeLTE.length;
    }

    if (vessel.hasOwnProperty("activeKaaS") &&
      Array.isArray(vessel.activeKaaS) &&
      vessel.activeKaaS.length > 0) {
      planCount += vessel.activeKaaS.length;
    }

    if (vessel.hasOwnProperty("activeBlended") &&
    Array.isArray(vessel.activeBlended) &&
    vessel.activeBlended.length > 0) {
    planCount += vessel.activeBlended.length;
  }

    if (vessel.hasOwnProperty("activeVSAT") && Array.isArray(vessel.activeVSAT) && vessel.activeVSAT.length > 0) {
      vessel.activeVSAT.map((row: any) => {
        addToStructuredData(row, k4Id, vesselName, "VSAT", planCount, isFirst, index, vessel);
        isFirst = false;
      });
    }

    if (vessel.hasOwnProperty("activeLTE") && Array.isArray(vessel.activeLTE) && vessel.activeLTE.length > 0) {
      vessel.activeLTE.map((row: any) => {
        addToStructuredData(row, k4Id, vesselName, "LTE", planCount, isFirst, index, vessel);
        isFirst = false;
      });
    }

    if (vessel.hasOwnProperty("activeKaaS") && Array.isArray(vessel.activeKaaS) && vessel.activeKaaS.length > 0) {
      vessel.activeKaaS.map((row: any) => {
        addToStructuredData(row, k4Id, vesselName, "KaaS", planCount, isFirst, index,  vessel);
        isFirst = false;
      });
    }

    if (vessel.hasOwnProperty("activeBlended") && Array.isArray(vessel.activeBlended) && vessel.activeBlended.length > 0) {
      vessel.activeBlended.map((row: any) => {
        addToStructuredData(row, k4Id, vesselName, "Blended", planCount, isFirst, index,  vessel);
        isFirst = false;
      });
    }

    if (vessel.hasOwnProperty("activeVSAT") && Array.isArray(vessel.activeVSAT) && vessel.activeVSAT.length == 0
      && vessel.hasOwnProperty("activeLTE") && Array.isArray(vessel.activeLTE) && vessel.activeLTE.length == 0
      && vessel.hasOwnProperty("activeKaaS") && Array.isArray(vessel.activeKaaS) && vessel.activeKaaS.length == 0
      && vessel.hasOwnProperty("activeBlended") && Array.isArray(vessel.activeBlended) && vessel.activeBlended.length == 0
    ) {
      structuredData.push(createData(k4Id, vesselName, "", "", quota, usage, false, "", "", 0, true, index));
      isFirst = false;
    }
  }

  function addToStructuredData(row, k4Id, vesselName, planType, planCount, isFirst, index, vesselData) {
    let planName = row.name ? row.name : "";
    let quota = row.quota ? row.quota : "";
    let usage = vesselData.lteUsage ? vesselData.lteUsage[0] ?  vesselData.lteUsage[0].totalUsage ? vesselData.lteUsage[0].totalUsage : 0 : 0 : 0 ;
    let wanThrottle = planType === "LTE"  && row.actualQuota && usage ? usage >  row.actualQuota && row.isWANThrottleFlag  ? true : false : false;
    let activationDate = row.actDate ? row.actDate : "";
    let deactivationDate = row.deactDate ? row.deactDate : "";
    if (activationDate && activationDate != '') {
      activationDate = moment(activationDate).tz(DEFAULT_TIMEZONE).format(DEFAULT_DATE_FORMAT);
    }
    if (deactivationDate && deactivationDate != '') {
      deactivationDate = moment(deactivationDate).tz(DEFAULT_TIMEZONE).format(DEFAULT_DATE_FORMAT);
      if (moment(deactivationDate, DEFAULT_DATE_FORMAT).year() == SRVC_MGMT_DEACTIVATION_YEAR) {
        deactivationDate = SRVC_MGMT_ALTERNATIVE_TEXT_FOR_DEACTIVATION_YEAR;
      }
    }
    structuredData.push(createData(k4Id, vesselName, planType, planName, quota, usage,wanThrottle, activationDate, deactivationDate, planCount, isFirst, index));
  }

  let lastPlanCountIndex = -1;
  let k4Id = "";

  function getFirstColumn(row, i) {
    if (row && row.hasOwnProperty("planCount") && row.planCount < 2) {
      return row && row.isFirst ? (<TableCell className="tableTbodyTd removeBorderBottom fleetRowDataDetails vessel-name" rowSpan={row.planCount > 0 ? row.planCount : 1}>{row.vesselName}</TableCell>) : "";
    }

    if (row && row.isFirst && row.hasOwnProperty("planCount")) {
      lastPlanCountIndex = i + row.planCount - 1;
      k4Id = row.k4id;
    }
    return row && row.isFirst ? (<TableCell className="tableTbodyTd removeBorderBottom fleetRowDataDetails vessel-name" rowSpan={row.planCount}>{row.vesselName}</TableCell>) : "";
  }

  function getLastColumn(row, i) {
    const url = `${KAPTURE_UI_BASE_URL}/Vessels/Vessel/${row.k4id}`;
    let isEditPlan = modifyServiePlan.find(o => o["id"] === row.k4id && o["vessel-name"] === row.vesselName);
    return row && row.isFirst ? (
      <TableCell className="tableTbodyTd removeBorderBottom alignCenter" align="center" rowSpan={row.planCount > 0 ? row.planCount : 1}>
        {
          isEditPlan &&
          <a href={url} target="_blank">
            <img src={userEditImage} title="Edit Plan" alt="Edit Plan" />
          </a>
          //  <Link to={{
          //   pathname: url,
          //   state: {name: row.vesselName ,recType:{},k4DpId:{}, vs_nm:  row.vesselName }
          // }} style={{ textDecoration: "none" }}
          // >
          //   <img src={userEditImage} title="Edit Plan" alt="Edit Plan" />
          // </Link>
        }
      </TableCell>) : "";
  }

  function addBorderBottomToTableRow(row: any, i: number) {
    let classes = "fleetRowRank fleetRowRank-even removeBorderBottom";
    // if(row && row.hasOwnProperty("planCount")) {
    //   console.log(i, row.planCount);
    //   if(row.planCount < 2) {
    //     classes = "removeBorderBottom fleetRowRank fleetRowRank-even";
    //   }
    // }
    if (row.index % 2) {
      classes = "fleetRowRank fleetRowRank-odd removeBorderBottom";
    }
    return classes;
  }

  function addBorderBottomToTableCell(row: any, i: number) {
    if (k4Id == row.k4id && i == lastPlanCountIndex) {
      return "tableTbodyTd removeBorderBottom fleetRowDataDetails";
    } else {
      return "tableTbodyTd removeBorderBottom fleetRowDataDetails";
    }
  }

  function nextPage() {
    setPage(++page);
    callGetVesselPlansAPI(vessels);
  }

  function prevPage() {
    setPage(--page);
    callGetVesselPlansAPI(vessels);
  }

  const callGetVesselPlansAPI = (vessels: any[]) => {
    structuredData.length = 0;
  }
  const selectedDropdown = () => {
    setPage(0);
    callGetVesselPlansAPI(vessels);
  }

  useEffect(() => {

    if (selectedVessels && Array.isArray(selectedVessels) && selectedVessels.length > 0) {
      selectedDropdown();
    }
    if (modifyServiePlan && Array.isArray(modifyServiePlan) && modifyServiePlan.length > 0) {
      //alert("modifyServiePlan "+modifyServiePlan);
    }
  }, [selectedVessels, modifyServiePlan]);

  return (
    <div>
      <Grid className="serviceManagement-table" style={{ marginBottom: "10px", marginLeft: "25px", marginRight: "25px" }}>
        <TableContainer className="FleetDashboard" >
          <Table stickyHeader aria-label="Subscriptions"  className="srvc_mgmt_table">
            <TableHead>
              <TableRow className="tableHeaderFleetVesselData ">
                <TableCell className="fleetDataTable account-column tableHeaderBg" align="left">SITE</TableCell>
                <TableCell className="fleetDataTable account-column tableHeaderBg" align="left">CURRENT PLANS</TableCell>
                <TableCell className="fleetDataTable account-column tableHeaderBg" align="center">Quota (GB)</TableCell>
                <TableCell className="fleetDataTable account-column tableHeaderBg" align="center">Usage (GB)</TableCell>
                <TableCell className="fleetDataTable account-column tableHeaderBg" align="center">Overage (GB)</TableCell>
                <TableCell className="fleetDataTable account-column tableHeaderBg" align="center">Throttled</TableCell>
                <TableCell className="fleetDataTable account-column tableHeaderBg" align="center">ACTIVATION DATE</TableCell>
                <TableCell className="fleetDataTable account-column tableHeaderBg" align="center">DEACTIVATION Date</TableCell>
                <TableCell className="fleetDataTable account-column tableHeaderBg" align="center">ACTIONS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                structuredData.length > 0 ?
                  structuredData.map((row: any, i) => (
                    <TableRow className={addBorderBottomToTableRow(row, i)} key={i}>
                      {getFirstColumn(row, i)}
                      <TableCell className={addBorderBottomToTableCell(row, i)}>
                        <span className="plan-type">{row.planType}</span>
                        <span>{row.planType != "" ? ": " : ""}</span>
                        <span className="plan-name">{row.planName}</span>
                      </TableCell>
                      <TableCell style={{textAlign:"center"}} className={addBorderBottomToTableCell(row, i)}>{"     "+row.quota !== "" ? row.quota >= 99999 ? "Unlimited" : row.quota : ""}</TableCell>
                      <TableCell style={{textAlign:"center"}} className={addBorderBottomToTableCell(row, i)}>{row.planType === "LTE" ?  Math.round(row.usage  * 100) / 100: ""}</TableCell>
                      <TableCell style={{textAlign:"center"}} className={addBorderBottomToTableCell(row, i)}>{row.planType === "LTE" ? row.usage > row.quota ?   Math.round((row.usage - row.quota)  * 100) / 100  : 0 : ""}</TableCell>
                      <TableCell style={{textAlign:"center"}} className={addBorderBottomToTableCell(row, i)}>{row.wanThrottle ? "(T)" : ""}</TableCell>
                      <TableCell style={{textAlign:"center"}} className={addBorderBottomToTableCell(row, i)}>{row.actDate}</TableCell>
                      <TableCell style={{textAlign:"center"}} className={addBorderBottomToTableCell(row, i)}>{row.deactDate}</TableCell>
                      {getLastColumn(row, i)}
                    </TableRow>
                  ))
                  : <TableRow className="fleetRowRank fleetRowRank-odd"><TableCell align="center" className="fleetRowDataDetails removeBorderBottom" colSpan={5}>No Data Available</TableCell></TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
  })(ServiceManagementTable)
);
