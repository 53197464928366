import { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { useLeafletContext } from "@react-leaflet/core";
import L from "leaflet";
import './Map.css';

import "leaflet.vectorgrid";
import { SET_SELECTED_HEX } from "../../actions/actionType";


const StarLinkGrid = (props) => {
  const { layerContainer, map } = useLeafletContext();
  const { assets, selectedLayer, hexOpacity, isEventsEnabled, handleHexogonClick,selectedCarrier, h3Index, setHexId, selectedHexId, zindex, isVsatDataSource, selectedDataSource, resolution } = props;
  const data = assets;
  const [selectdHexLocal, setSelectedHeIdLocal] = useState(0);

  const getLetency = (item) => {
    let latency = Math.round(item);
    if (latency <= 60) {
      return "#0183D7";
    } else if (latency >= 61 && latency <= 80) {
      return "#37b345";
    } else if (latency >= 81 && latency <= 120) {
      return "#88ce7d";
    } else if (latency >= 121 && latency <= 140) {
      return "#B9E0A8";
    } else if (latency >= 141 && latency <= 200) {
      return "#FFFFCC";
    } else if (latency >= 201 && latency <= 300) {
      return "#FFD11D";
    } else if (latency >= 301 && latency <= 400) {
      return "#FA9100";
    } else if (latency >= 401) {
      return "#EA3A24";
    }
  }

  const getLoss = (loss) => {
    if (loss <= 0.01) {
      return "#0183D7";
    } else if (loss >= 0.01 && loss <= 0.03) {
      return "#37b345";
    } else if (loss >= 0.031 && loss <= 0.07) {
      return "#88ce7d";
    } else if (loss >= 0.071 && loss <= 0.15) {
      return "#B9E0A8";
    } else if (loss >= 0.151 && loss <= 0.30) {
      return "#FFFFCC";
    } else if (loss >= 0.31 && loss <= 0.50) {
      return "#FFD11D";
    } else if (loss >= 0.51 && loss <= 0.70) {
      return "#FA9100";
    } else if (loss >= 0.71) {
      return "#EA3A24";
    }
  }

  const getSpeedColor = (rateAvg) => {
    let rateInMbps = Math.round((rateAvg / 1024) / 1024);
    if (rateInMbps <= 1) {
      return "#EA3A24";
    } else if (rateInMbps >= 2 && rateInMbps <= 5) {
      return "#FA9100";
    } else if (rateInMbps >= 6 && rateInMbps <= 10) {
      return "#FFD11D";
    } else if (rateInMbps >= 11 && rateInMbps <= 15) {
      return "#FFFFCC";
    } else if (rateInMbps >= 16 && rateInMbps <= 20) {
      return "#B9E0A8";
    } else if (rateInMbps >= 21 && rateInMbps <= 30) {
      return "#88ce7d";
    } else if (rateInMbps >= 31 && rateInMbps <= 50) {
      return "#37b345";
    } else if (rateInMbps >= 51) {
      return "#0183D7";
    }
  }

  const getStarLinkData= (starLinkLayer,e) => {
    switch(starLinkLayer) {
        case "Latency": return getLetency(e?.latency_avg);;
        case "Speed": return getSpeedColor(e?.rate_avg);
        default: return getLoss(e?.loss_avg);
    }
}

  const vectorGrid = useMemo(() => {

    return L.vectorGrid.slicer(data, {

      // rendererFactory: L.canvas.tile,
      // idField: 'vectorLayer',
      zIndex: zindex,
      vectorTileLayerStyles: {
        data: function (e) {
        let rssiColor = "#000000"
        // let latencyAvg = e?.latency_avg;
        rssiColor = getStarLinkData(selectedLayer,e)
        let style = { fill: true, fillColor: rssiColor, fillOpacity: (hexOpacity * 0.1), color: 'white', weight: 0.3 };
        return style;
        },
      },
      getFeatureId: function (e) {
        return e?.properties?.id
      },
      interactive: true,
    });

  }, [data, selectedCarrier,hexOpacity,selectedLayer]);


  vectorGrid.on("mouseover", function (e) {
    // console.log("mouseover",e);
      let rssiColor = "#000000"
    //   let rateAvg = isVsatDataSource? e?.layer?.properties?.rate_avg: e?.layer?.properties?.performance_avg;
      rssiColor = getStarLinkData(selectedLayer,e)
      isEventsEnabled && vectorGrid.setFeatureStyle(e?.layer?.properties?.id, { fill: true, fillColor: rssiColor, fillOpacity: 0.3, color: "white", weight: 3 });
  });

  const resetSelectedHex = (hexId) => {
    vectorGrid.resetFeatureStyle(hexId);
  }

  vectorGrid.on("click", function (e) {
    console.log("click",e);
    isEventsEnabled && h3Index(e?.layer?.properties?.h3index);
    resetSelectedHex(selectdHexLocal)
    handleHexogonClick(e, resolution);
    setHexId(e?.layer?.properties?.id);
    setSelectedHeIdLocal(e?.layer?.properties?.id)

      let rssiColor = "#000000";
    //   let rateAvg = isVsatDataSource? e?.layer?.properties?.rate_avg: e?.layer?.properties?.performance_avg;
      rssiColor = getStarLinkData(selectedLayer,e)
      isEventsEnabled && vectorGrid.setFeatureStyle(e?.layer?.properties?.id, { fill: true, fillColor: rssiColor, fillOpacity: 0.3, color: "white", weight: 3 });
    
  });

  vectorGrid.on("mouseout", function (e) {
   if(selectedHexId !== e?.layer?.properties?.id) {
    vectorGrid.resetFeatureStyle(e?.layer?.properties?.id);
   } else {
      let rssiColor = "#000000"
    //   let rateAvg = isVsatDataSource? e?.layer?.properties?.rate_avg: e?.layer?.properties?.performance_avg;
      rssiColor = getStarLinkData(selectedLayer,e)
      isEventsEnabled && vectorGrid.setFeatureStyle(e?.layer?.properties?.id, { fill: true, fillColor: rssiColor, fillOpacity: 0.3, color: "white", weight: 3 });
   }
  });

  if(selectedHexId === 0) {
    vectorGrid.resetFeatureStyle(selectdHexLocal);
  }

  const container = layerContainer || map;

  useEffect(() => {
    container.addLayer(vectorGrid);
    return () => {
      container.removeLayer(vectorGrid);
      
    };
  }, [data,selectedCarrier,hexOpacity,selectedLayer]);
  
  return null;
}

const mapStateToProps = (state) => ({
  selectedHexId: state?.products?.selectedHexId
});

const mapDispatchToProps = (dispatch) => {
  return {
    setHexId: (hexIndexId) => dispatch({ type: SET_SELECTED_HEX, item: hexIndexId })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StarLinkGrid);
