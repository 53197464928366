import { Button, Grid, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { PageChangeHandler, Pagination } from "../../components/Pagination";
import { FirewallReportContext, updateActivitySelection } from "./FirewallReportContext";
import Download from '../../asset/image/DownloadUsage.svg';

import './firewallReport.css';

interface FirewallTableData {
    site_id: string;
    ruleName: string;
    site_name: string;
    protocol: string;
    src_interface: string;
    dest_interface: string;
    destination_type: string;
    packetsCount: number;
    action: number;
    destination_ip: string;
    source_ip: string;
    src_port: string;
    dest_port: string
}

const rowsOption = [10, 15, 25, 50, 100];

const FirewallReportData = (props: any) => {

    const { getFirewallTableData, getFirewallTableDataCount, getVesselsListing, tableCount, setTableCount } = props;

    const [firewallTableData, setFirewallTableData] = useState<Array<FirewallTableData>>([]);
    const {
        page,
        limit,
        activities,
        handleChangePageUser,
        handleChangeRowsPerPageUser,
        handleRuleNameChange,
        handleActivityFilter,
        onSelectFilters,
        handleTopUsageOnCsvDownload
    } = useContext(FirewallReportContext);

    useEffect(() => {
        if (_.isEmpty(getFirewallTableData))
            return;

        const data = getFirewallTableData?.hasOwnProperty('data') ? getFirewallTableData?.data : {};
        if (!data)
            return;
        const columns: string[] = data.columns ?? [];
        const rows: any[] = data.rows ?? [];
        const tableData = rows.map((row) => {
            const item: FirewallTableData = Object.assign({});
            columns.forEach((col, index) => {
                item[col] = row[index];
            })
            return item;
        });
        setFirewallTableData(tableData);
    }, [getFirewallTableData])

    useEffect(() => {
        if (_.isEmpty(getFirewallTableDataCount))
            return;
        const data = getFirewallTableDataCount?.hasOwnProperty('data') ? getFirewallTableDataCount?.data : {};
        if (!data) {
            return;
        }
        const rows: any[] = data.rows ?? [];
        if(rows.length == 0){
            setTableCount(0);
            return;
        }
        const count: number = rows[0][0];
        setTableCount(count)
    }, [getFirewallTableDataCount])

    useEffect(() => {
        if (_.isEmpty(getVesselsListing)) {
            setFirewallTableData([]);
            setTableCount(0);
        }
    }, [getVesselsListing])

    const onPageChange : PageChangeHandler = (e, page) => {
        setFirewallTableData([]);
        handleChangePageUser(e, page);
    }

    const handleRuleNameClick = (data: FirewallTableData) => {
        handleRuleNameChange(data.ruleName);
        onSelectFilters({ filterRuleName: data.ruleName });
    }

    const handleSiteNameClick = (data: FirewallTableData) => {
        onSelectFilters({ filterSiteName: data.site_name });
    }

    const handleActionsClick = (data: FirewallTableData) => {
        let selectedActivity = updateActivitySelection(activities, data.action === 1 ? 'Allowed' : 'Denied');
        if (!selectedActivity) return;
        handleActivityFilter(selectedActivity);
        onSelectFilters({ filterActivities: activities });
    }

    const handleDownloadReport = () => {
        handleTopUsageOnCsvDownload(tableCount);
    }

    return (<Grid item xs={12} sm={4} md={12} lg={12}>
        <Grid className="topSites-download">
            <Grid item xs={12} sm={12} md={12} lg={12} className="firewall-table-download">
                <Grid item xs={6} sm={6} md={6} lg={6} className="Reports-overView-topUsers align-text-center">Firewall</Grid>
                {tableCount > 0 && <Grid item xs={6} sm={6} md={6} lg={6}>
                    <div className="download-summary-btn marg-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                </Grid>}
            </Grid>
        </Grid>
            {/* <span>
                <Button className="firewall-add-rule" variant="contained"> Add Rule </Button>
            </span> */}
        <Grid container className="margin-t-15">
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid>
                </Grid>
                <Grid className="TopUsersConatiner">
                    <Paper className="pagination-position config-pagination activity-usage">
                        <Pagination
                            rowsPerPageOptions={rowsOption}
                            count={tableCount}
                            rowsPerPage={limit}
                            page={page}
                            onPageChange={onPageChange}
                            onRowsPerPageChange={handleChangeRowsPerPageUser}
                        />
                    </Paper>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="firewall-tableRow">
                                    <TableCell className="firewall-table-data font-wt-900">S. NO</TableCell>
                                    <TableCell className="firewall-table-data font-wt-900">RULE NAME</TableCell>
                                    <TableCell className="firewall-table-data font-wt-900">SITE NAME</TableCell>
                                    <TableCell className="firewall-table-data font-wt-900">PROTOCOL</TableCell>
                                    <TableCell className="firewall-table-data font-wt-900">SRC. INTERFACE</TableCell>
                                    <TableCell className="firewall-table-data font-wt-900">SRC. IP DOMAIN</TableCell>
                                    <TableCell className="firewall-table-data font-wt-900">SRC. PORT</TableCell>
                                    <TableCell className="firewall-table-data font-wt-900">DEST. INTERFACE</TableCell>
                                    <TableCell className="firewall-table-data font-wt-900">DEST. IP DOMAIN</TableCell>
                                    <TableCell className="firewall-table-data font-wt-900">DEST. PORT</TableCell>
                                    <TableCell className="firewall-table-data font-wt-900">NO. OF PKT</TableCell>
                                    <TableCell className="firewall-table-data font-wt-900">ACTION</TableCell>
                                </TableRow>
                            </TableHead>
                            {firewallTableData.length ? (
                            <TableBody className="tableBody usage-tableBody">
                                {firewallTableData.map((row, i) => <TableRow key={`firewall-table-row-${i}`} className="firewall-table-data">
                                    <TableCell className="firewall-table-cell">{(page - 1) * limit + (i + 1)}</TableCell>
                                    <TableCell className="firewall-table-cell">
                                        <span className="cardFilter table-cardFilter" onClick={handleRuleNameClick.bind(this, row)}>{row.ruleName}</span>
                                    </TableCell>
                                    <TableCell className="firewall-table-cell">
                                        <span className="cardFilter table-cardFilter" onClick={handleSiteNameClick.bind(this, row)}>{row.site_name}</span>
                                    </TableCell>
                                    <TableCell className="firewall-table-cell">{row.protocol}</TableCell>
                                    <TableCell className="firewall-table-cell">{row.src_interface}</TableCell>
                                    <TableCell className="firewall-table-cell">{row.source_ip}</TableCell>
                                    <TableCell className="firewall-table-cell">{row.src_port}</TableCell>
                                    <TableCell className="firewall-table-cell">{row.dest_interface}</TableCell>
                                    <TableCell className="firewall-table-cell">{row.destination_ip}</TableCell>
                                    <TableCell className="firewall-table-cell">{row.dest_port}</TableCell>
                                    <TableCell className="firewall-table-cell">{row.packetsCount}</TableCell>
                                    <TableCell className="firewall-table-cell">
                                        <span className="cardFilter table-cardFilter" onClick={handleActionsClick.bind(this, row)}>{row.action == 1 ? 'Allowed' : 'Denied'}</span>
                                    </TableCell>
                                </TableRow>
                                )}
                            </TableBody>
                             ) : (
                             <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={12} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                             )}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    )
}
const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    getFirewallTableData: state.authReducer?.getFirewallTableData,
    getFirewallTableDataCount: state.authReducer?.getFirewallTableDataCount,
    getVesselsListing: state.authReducer?.getVesselsListing
});

export default
    connect(mapStateToProps)(FirewallReportData);