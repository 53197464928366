import { Grid, Tooltip } from "@mui/material";
import upArrow from "../../asset/image/upArrow.svg";
import downArrow from "../../asset/image/downArrow.svg";
import { readableBytesAsGB } from "../../utils/util";
import _ from "lodash";
import "./reportsCard.css";
import topApp from "../../asset/image/topAppIcon.svg";
import topBlocked from "../../asset/image/topBlockedIcon.svg";
import topUser from "../../asset/image/topUserIcon.svg";
import totalUsage from "../../asset/image/totalUsageIcon.svg";
import topSite from "../../asset/image/topSiteIcon.svg";
import { useContext } from "react";
import { UsageContext } from "../../UserScreen/UserData/UsageDataContext";


const ReportsCard = (props) => {
    const { widgetData } = props;  
    const { selectFilter, handleUsernameChange, handleAppFilterChange, appFilt, userName, setPage, setPageStart, setUserNameTrack, vlanFilt, checkUser} = useContext(UsageContext)

    const filterCardUser = () => {
        let filters: any = {};
        const usageFrom = widgetData.usageFrom ?? '';
        if (widgetData.kpi === 'topUser' && usageFrom !== userName) {
            filters.filterUserName = usageFrom;
            filters.vlanFilter = vlanFilt;
            filters.userCheck = widgetData?.userFilter;
            handleUsernameChange(null, usageFrom);
            setPage(1)
            setPageStart(1);
            selectFilter(filters);
            setUserNameTrack(true);
        }
        if (widgetData.kpi === 'topApp' && usageFrom !== appFilt) {
            filters.filterAppFilt = usageFrom;
            filters.vlanFilter = vlanFilt;
            filters.userCheck = checkUser;
            handleAppFilterChange(null, usageFrom);
            setPage(1)
            setPageStart(1);
            selectFilter(filters);
        }
        if (widgetData.kpi === 'topSite') {
            filters.filterSite = usageFrom;
            filters.vlanFilter = vlanFilt;
            filters.userCheck = checkUser;
            setPage(1)
            setPageStart(1);
            selectFilter(filters);
        }
    }

  return (
    <div className="Form">
        <Grid className="am-table usage-cardmain">
           <Grid className="Reprts-CardContainer">
              <Grid className="total-logoDiv">
                <Grid className="reports-totalDetails">
                    <Grid>
                        { 
                           widgetData.kpi=='totalUsage' ? <Grid>Total</Grid> : widgetData.kpi=='topUser' ? <Grid>Top User</Grid> : widgetData.kpi=='topApp' ? <Grid>Top App</Grid> : widgetData.kpi=='topSite' ? <Grid>Top Site</Grid> : <Grid>Top Blocked</Grid>
                        }
                    </Grid>
                    {
                        widgetData.kpi=="totalUsage" && widgetData.kpi!==null ? <Grid>{widgetData.total ? readableBytesAsGB(widgetData.total) : ""}</Grid> :
                        widgetData.usageFrom ? <Grid>{widgetData.kpi == 'topUser' || widgetData.kpi == 'topApp' || widgetData.kpi == 'topSite' ? <Tooltip title={widgetData.kpi=='topUser' && widgetData.captiveFlag == 1 ? `${widgetData.usageFrom} *` : widgetData.usageFrom}><a onClick={filterCardUser} className="cardFilter activity-cardFilter"> {widgetData.kpi=='topUser' && widgetData.captiveFlag == 1 ? `${widgetData.usageFrom}*` : widgetData.usageFrom}</a></Tooltip> : <Tooltip title={widgetData.usageFrom}><a>{widgetData.usageFrom}</a></Tooltip> }</Grid> : <Grid>-</Grid>
                    }
                </Grid>
                { widgetData.kpi=='totalUsage' ? <Grid className="reports-propsLogo reports-propsLogo-total"><img src={totalUsage} alt="icon" /></Grid> :
                  widgetData.kpi=='topSite' ? <Grid className="reports-propsLogo reports-propsLogo-site"><img src={topSite} alt="icon" /></Grid> : 
                  widgetData.kpi=='topUser' ? <Grid className="reports-propsLogo reports-propsLogo-user"><img src={topUser} alt="icon" /></Grid> :
                  widgetData.kpi=='topApp' ? <Grid className="reports-propsLogo reports-propsLogo-app"><img src={topApp} alt="icon" /></Grid> :
                  widgetData.kpi=='topBlocked' ? <Grid className="reports-propsLogo reports-propsLogo-blocked"><img src={topBlocked} alt="icon" /></Grid> :
                  <Grid className="reports-propsLogo"></Grid>
                }
              </Grid>
               {
                widgetData.kpi !== "topBlocked" ? 
                    <Grid className="reports-up_down-details">
                        <Grid>
                            <Grid className="statusTitle_up">Up</Grid>
                            <Grid className="upArrow-details">
                                <img src={upArrow} alt="upArrow" />
                                <Grid>{widgetData.upload ? readableBytesAsGB(widgetData.upload) : ""}</Grid>
                            </Grid>
                        </Grid>
                        <Grid className="downStatus-Data">
                            <Grid className="statusTitle_up">Down</Grid>
                            <Grid className="upArrow-details">
                                <img src={downArrow} alt="downArrow" />
                                <Grid>{widgetData.download ? readableBytesAsGB(widgetData.download): ""}</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                :
                    <Grid className="topBlockedUsage">
                        <div>Hits</div>
                        {widgetData.total ? <span>{widgetData.total}</span> : "-"}
                    </Grid>
               }
           </Grid>
        </Grid>
    </div>
  )
}

export default ReportsCard;