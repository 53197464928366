import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Grid, Tooltip } from "@mui/material";
import "./OrganizationConfig.css";
import { useEffect, useState } from "react";
import _ from "lodash";
import { CONFIRM_DIALOG_TITLE } from "../../config";
import ConfirmDialog from "../ConfirmDialog";
import { moveDeviceToWarehouse, inventoryChangeGroup } from "../../actions/Users/authenticateInventory";
import DP_Dropdown from "../InventoryWarehouseTable/DP_Dropdown";
import { DEFAULT_DATE_FORMAT } from "../../utils/constants";
import moment from "moment";
import InfoIcon from '@mui/icons-material/Info';

export const OrganizationConfig = (props) => {
    const { authReducer, selectedDeviceInfo, onClose, moveDeviceToWarehouse, inventoryChangeGroup, setConfirmDialogMessage } = props;

    const [deviceName, setDeviceName] = useState(selectedDeviceInfo.deviceName)
    const [deviceId, setDeviceId] = useState(selectedDeviceInfo.deviceId)
    const [siteId, setSiteId] = useState(selectedDeviceInfo.k4Id)
    const [ouId, setOuId] = useState(selectedDeviceInfo.dpId)

    const [changedOuInfo, setChangedOuInfo] = useState<any>({});

    const [confirmDialog, setConfirmDialog] = useState<any>({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: {},
    });

    return (
        <Grid className="Form">
            <Grid container className="device-organization-config device-organization-config-no-padding">
                <Grid container className="config-apply-config-target">
                    <Grid xs={4} sm={4} md={4} lg={4} className="content-left">Device</Grid>
                    <Grid xs={5} sm={5} md={5} lg={5} className="content-right" title={deviceName}>{deviceName}</Grid>
                    <Grid xs={3} sm={3} md={3} lg={3} className="content-right action"><Button className={_.isEmpty(selectedDeviceInfo.deRegisteredDate) ? "action-button":"action-button-disabled"}
                        onClick={() => {
                            if(_.isEmpty(selectedDeviceInfo.deRegisteredDate)){
                                setConfirmDialogMessage({
                                    isOpen: true,
                                    title: CONFIRM_DIALOG_TITLE,
                                    subTitle: "Confirm move to Warehouse ?",
                                    onConfirm: () => {
                                        // moveDeviceToWarehouse(deviceId, { moveToDP: true })
                                    },
                                })
                            }
                        }}
                    >Move to Warehouse</Button></Grid>
                </Grid>
                <Grid container className="config-apply-config-target">
                    <Grid xs={4} sm={4} md={4} lg={4} className="content-left">Current organization</Grid>
                    <Grid xs={5} sm={5} md={5} lg={5} className="content-right action">
                        <Grid className="current-organization" title={selectedDeviceInfo.dpname}>{selectedDeviceInfo.dpname}</Grid>
                    </Grid>
                </Grid>
                <Grid container className="config-apply-config-target">
                    <Grid xs={4} sm={4} md={4} lg={4} className="content-left">Change organization
                        <div className="change-license-info-icon">
                            <Tooltip arrow placement="top" title='The "Change Organization" feature currently moves the entire site from one organization to another along with the devices.'><InfoIcon id="helpIcon-reports" /></Tooltip>
                        </div>
                    </Grid>
                    <Grid xs={5} sm={5} md={5} lg={5} className="content-right action">
                        <Grid className="change-org-dp-dropdown change-org-width-250px"><DP_Dropdown isReports={false} setOu={setChangedOuInfo} /></Grid>
                    </Grid>
                    <Grid xs={3} sm={3} md={3} lg={3} className="content-right action"><Button className="action-button"
                        onClick={() => {
                            setConfirmDialog({
                                isOpen: true,
                                title: CONFIRM_DIALOG_TITLE,
                                subTitle: (
                                    <>
                                        Moving <span className="custom-word">{selectedDeviceInfo.name}</span> from <span className="custom-word">{selectedDeviceInfo.dpname}</span> to organization <span className="custom-word">{changedOuInfo.name}</span>?<br />
                                        <br />
                                        <span className="custom-word">Warning</span>: This action will not migrate the captive users to <span className="custom-word">{changedOuInfo.name}</span>.
                                    </>
                                ),
                                    onConfirm: () => {
                                    inventoryChangeGroup(siteId, changedOuInfo.id);
                                    onClose()
                                },
                            })

                        }}
                    >Move Site</Button></Grid>
                </Grid>
                {selectedDeviceInfo?.deRegisteredDate && <span className="Note-Message">{`Note : The device ${deviceName} is currently in the de-registration queue. It is scheduled to be de-registered on ${moment(selectedDeviceInfo?.deRegisteredDate)?.format(DEFAULT_DATE_FORMAT)}`}</span>}
            </Grid>
            <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </Grid>
    )
}


const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {
        moveDeviceToWarehouse, inventoryChangeGroup
    })(OrganizationConfig)
);