// Before login
export const GET_ERRORS = "get_errors";
export const ERROR_RESET_STATE = "error_reset_state";
export const OTP_ERROR="OTP_ERROR";
export const ERROR_RESET_PASSWORD = "error_reset_password";

// Account Managment
export const ERROR_RESEND_INVITE = 'error_resend_invite';
export const ERROR_INVITE_USER = "error_invite_user";
export const ERROR_DETACH_USER = "error_detach_user";
export const ERROR_DISPLAY_APPLICABLE_USER_POLICIES = "error_display_applicable_user_policies";
export const ERROR_ENABLE_USER_ACCOUNT = "error_enable_user_account";
export const ERROR_DISABLE_USER_ACCOUNT = "error_disable_user_account";
export const ERROR_SET_PASSWORD_User = "error_set_password_user";
export const ERROR_FETCH_USERS = "error_fetch_users";

// Resouce under Account Management
export const ERROR_FETCH_OU_RESOURCES = "error_fetch_ou_resource";
export const ERROR_ATTACH_RESOURCE_OU = "error_attach_resource_ou";
export const ERROR_DETACH_RESOURCE_OU = "error_detach_resource_ou";
export const ERROR_NOT_ATTACH_RESOURCE_OU = "error_not_attach_resource_ou";
export const ERROR_UPDATING_USER_DETAILS = "error_updating_user_details";
export const ERROR_FETCH_SERVICE_LINES = "error_fetch_service_lines";

// Alert Page
export const ERROR_SEARCH_ALERTS = "error_serach_alerts";
export const ERROR_CLOSE_VESSEL_ALERT = "error_close_vessel_alert";
export const ERROR_GET_ALERT_NAME = "error_get_alert_name";
export const ERROR_ALERTS_HISTORY = "error_alerts_history";

// Dashboard
export const ERROR_GET_VESSEL_SUMMARY_TABLE = "error_get_vessel_summary_table";
export const ERROR_GET_VESSEL_SUMMARY_DASHBOARD = "error_get_vessel_summary_dashboard";

// SubHeader
export const ERROR_GET_ORGANISATIONS = "error_get_organisation";
export const ERROR_GET_ORGANISATIONS_UNITS = "error_get_organisation_units";
export const ERROR_GET_VESSELS_LISTING = "error_get_vessels_listing";

//Edge Events
export const ERROR_GET_EDGE_EVENTS = "error_get_edge_events";
export const ERROR_GET_EDGE_EVENTS_FILTER = "error_get_edge_events_filter";

// Site Charts
export const ERROR_GET_SITE_RSSI = "error_get_site_rssi";
export const ERROR_GET_SITE_SINR = "error_get_site_sinr";
export const ERROR_GET_SITE_DATA_RATE = "error_get_site_data_rate";
export const ERROR_GET_SITE_LINK_STATUS = "error_get_site_link_status";
export const ERROR_GET_SITE_LATENCY_JITTER = "error_get_site_latency_jitter";
export const ERROR_GET_SITE_INTERFACES = 'error_get_site_interfaces';

//Inventory
export const ERROR_ASSING_INVENTORY = "error_assign_inventory";