import { Button, Dialog, Grid, Paper, Typography } from "@mui/material";
import { Cancel, Delete } from "@material-ui/icons";

interface DeleteConfirmationDialogProps {
    open: boolean;
    itemName: string;
    itemCaption?: string;
    onConfirm: () => void;
    onCancel: () => void;
}

export function DeleteConfirmationDialog(props: DeleteConfirmationDialogProps) {
    const { open, itemName, itemCaption, onConfirm, onCancel } = props;

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Paper elevation={0} square={true} classes={{root: 'starlink--base--padding_1x'}}>
                <Typography component="div" className="starlink--base--font_1x">
                    Are you sure you want to delete this {itemName}?
                </Typography>
                {itemCaption && (
                    <Typography component="div" className="starlink--base--font_09x">
                        {itemCaption}
                    </Typography>
                )}
                <Grid container spacing={3} classes={{root: 'starlink--base--mt_1x'}}>
                    <Grid item xs={6}>
                        <Paper elevation={0} classes={{root: 'starlink--base--flex--justify--end starlink--base--padding_1x'}}>
                            <Typography variant="body2" classes={{root: 'starlink--base--font_1x'}}>
                                <Button variant="outlined" color="error" onClick={onCancel} startIcon={<Cancel />}>
                                    No
                                </Button>
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper elevation={0} classes={{root: 'starlink--base--flex--justify--start starlink--base--padding_1x'}}>
                            <Typography variant="body2" classes={{root: 'starlink--base--font_1x'}}>
                                <Button variant="outlined" color="primary" onClick={onConfirm} startIcon={<Delete />}>
                                    Yes
                                </Button>
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
        </Dialog>
    )
}