import { api } from "../../server/request";
import { handleResponse, handleError } from "../responseUtil";
import { SET_HUB_DASHBOARD_HUB_DATA_LOADING, GET_HUB_DASHBOARD_HUB_DATA, SET_HUB_DASHBOARD_HUB_DATA_COUNT_LOADING, GET_HUB_DASHBOARD_HUB_DATA_COUNT, SET_HUB_DASHBOARD_CLIENT_TABLE_LOADING, GET_HUB_DASHBOARD_CLIENT_TABLE, SET_HUB_DASHBOARD_SITES_DROPDOWN_LOADING, GET_HUB_DASHBOARD_SITES_DROPDOWN } from "../types";
import { getAvilWindow } from "./authenticateDashboard";

export const getIntervalInNewFormat = (interval) => {
  switch (interval) {
    case "15m": return "minute,15";
    case "1h": return "hour,1";
    case "2h": return "hour,2";
    case "6h": return "hour,6";
    case "12h": return "hour,12";
    case "1d": return "day,1";
    case "7d": return "day,7";
    case "30d": return "day,30";
    default: return "minute,15";
  }
}

export const getHubDataTableRow = (dpId, startDate, endDate, page, limit, interval, vessels, sort, sortOrder, search) => (dispatch) => {
  dispatch({ type: SET_HUB_DASHBOARD_HUB_DATA_LOADING, payload: true });
  const data: any = {
    query: 'HUB_DASHBOARD',
    named: true,
    groupBy: 'server_location_id',
    parameters: {
      "dp_id": dpId,
      "pageoffset": ((page - 1) * limit),
      "pagesize": limit,
      "start_time": startDate,
      "end_time": endDate,
      "interval": getIntervalInNewFormat(interval),
      "apps_interval": getIntervalInNewFormat(interval),
      "avail_window": getAvilWindow(interval),
      "site_ids": vessels && vessels.map((vs) => `${vs.k4Id}`).join(','),
      "sortColumn": sort,
      "sortOrder": sortOrder,
      "search": search
    },
    format: 'csv'
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, data)
    .then((res) => {
      handleResponse(res, dispatch, GET_HUB_DASHBOARD_HUB_DATA)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getHubDataTableRowCount = (dpId, vessels, search) => (dispatch) => {
  dispatch({ type: SET_HUB_DASHBOARD_HUB_DATA_COUNT_LOADING, payload: true });
  const count: any = {
    query: 'HUB_DASHBOARD_COUNT',
    named: true,
    parameters: {
      "dp_id": dpId,
      "site_ids": vessels && vessels.map((vs) => `${vs.k4Id}`).join(','),
      "search": search
    },
    format: 'csv'
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, count)
    .then((res) => {
      handleResponse(res, dispatch, GET_HUB_DASHBOARD_HUB_DATA_COUNT)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getHubDataClietTable = (server_location_id, client_device_id, interval) => (dispatch) => {
  dispatch({ type: SET_HUB_DASHBOARD_CLIENT_TABLE_LOADING, payload: true });
  const clientData: any = {
    query: 'HUB_DASHBOARD_CLIENT_TABLE',
    named: true,
    parameters: {
      "server_location_id": server_location_id,
      "client_device_id": client_device_id,
      "interval": getIntervalInNewFormat(interval)
    },
    format: 'json'
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, clientData)
    .then((res) => {
      handleResponse(res, dispatch, GET_HUB_DASHBOARD_CLIENT_TABLE)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getHubDataSitesList = (dpId) => (dispatch) => {
  dispatch({ type: SET_HUB_DASHBOARD_SITES_DROPDOWN_LOADING, payload: true });
  const sitesDropdown: any = {
    query: 'HUB_DASHBOARD_SITES_DROPDOWN',
    named: true,
    parameters: {
      "dp_id": dpId
    },
    format: 'json'
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, sitesDropdown)
    .then((res) => {
      handleResponse(res, dispatch, GET_HUB_DASHBOARD_SITES_DROPDOWN)
    })
    .catch((err) => handleError(err, dispatch));
};