import { useState, useEffect } from "react";
import 'leaflet/dist/leaflet.css';
import L from "leaflet";
import { MapContainer, Polygon, Marker, Popup, Tooltip, ZoomControl, ScaleControl, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";
import MapIncluder from './MapIncluder';
import Polyline from './PolyLineArrow';
import "./Map.css";
import { useMapEvents } from "react-leaflet";
import VesselRedIc from './images/vesselRed.svg';
import VesselBlueIc from './images/vesselBlue.svg';
import VesselGreenIc from './images/vesselGreen.svg';
import VesselGreyIc from './images/vesselGreyIc.svg'
import TerminaBluelIc from './images/terminalBlue.svg'
import TerminaGraylIc from './images/terminalGray.svg'
import TerminalRedIc from './images/terminalRed.svg'
import MarkerClusterGroup from "react-leaflet-markercluster";
import Control from "react-leaflet-custom-control";
import LayersIcon from '@mui/icons-material/Layers';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import VectorGrid from './VectorGrid';
import LocationGrid from './LocationGrid'
import StarLinkGrid from './StarLinkGrid';
import { ButtonGroup, Button } from "@mui/material";
import './smoothWheelZoom';
import moment from "moment";
import CellTowerIcon from '@mui/icons-material/CellTower';
import MultiOptionsPolyline from './MultiOptionPolyLine';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { getSingleRecordChartLable } from '../../util/util';
import LayerDetailsMaps from "../../pages/TowerVesselDetails/LayerDetails/LayerDetails";
import MapCharts from '../../../../../../src/components/MapCharts/index';

let DefaultIcon = L.icon({
   iconUrl: icon,
   shadowUrl: iconShadow,
   iconSize: [24,36],
   iconAnchor: [12,36]
 });

 L.Marker.prototype.options.icon = DefaultIcon; 


const MapLeaflet = (props) => {

  const [isOuterHexagonEnabled, setIsOuterhexagaonEnabled] = useState(true);
  const [isOuterStarLinkEnabled, setIsOuterStarLinkEnabled] = useState(true)
  const [isToolTip, setIsToolTip] = useState(false);
  const [map, setMap] = useState(null);
  const [mapPosition, setMapPosition] = useState([35, -35]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedLastLocIndex, setSelectedLastLocIndex] = useState(-1);
  const [kml, setKml] = useState(null);
  const [kml2, setKml2] = useState(null);
  const [isSplitRequired, setSplitRequired] = useState(false);
  const [multiSplitPolyLines , setSplitPolyLines] = useState([])

  const [latitudeLast, setLatitudeLast] = useState(null);
  const [longitudeLast, setLongitudeLast] = useState(null);
  const [siteNameLast, setSiteNameLast] = useState(null);
  const [startTimeLast, setStartTimeLast] = useState(null);
  const [endTimeLast, setEndTimeLast] = useState(null);
  const [isStarlinkLocIcon , setIsStarlinkLocIcon] = useState(false)

  const { polyColor, hexagons, isHexogonsEnabled, vesselData, vesselTrack,
    isVesselTrackEnabled, handleVesselClick, selectedvessels, handleChartOpen, isChartClosed, outerHexData,
    innerHexData, vesselCurrentLocation, selectedLayer, handleVesselOnHover, mapHeight,
    outerHexOpacity, innerHexOpacity, locationFilter, isPopUpOpen, handleLayerButton, handleHexClick, selectedTrack, selectedCarrier, h3Index,
    selctedVesselId, isVsatDataSource, connectivity,vesselTrackPointsData, vesselPointsEnabled, edgeEventsData, isEdgeEventsEnabled, isKmlEnabled, kmlLayersDetails, isSpecialPrrmEnabled,starLinkData, selectedDataSource,starLinkCoverageFour, userTimezone, isStarlinkLatencyMacro, isStarlinkLatencyMicro, isLteMicro, isLteMacro, selectedOu, k4Ids, selctedK4Id,
    getLayerDetails, setLayersOpen, layerSelectionDetails, isLocationEnabled, layersOpen,
    chartsOpen, setChartsOpen, selectedVessel, selectedVesselName, hoveredStart, hoveredEnd, isStarlinkSourceLoc, starlinkLocationData
  } = props;

  const style = {
    width: "100%",
    height: mapHeight
  };

  const processGeometries = (data) => {
    // Check if data and geometries exist
    if (data && data.objects && data.objects.data && data.objects.data.geometries && data.objects.data.geometries.length > 0) {
        // Get the last geometry in the list
        const lastGeometry = data.objects.data.geometries[data.objects.data.geometries.length - 1];

        // Extract the necessary properties
        if (lastGeometry && lastGeometry.properties) {
            const {
                latitude,
                longitude,
                site_name,
                start_time,
                last_time
            } = lastGeometry.properties;

            // Set the state with extracted values
            setLatitudeLast(latitude);
            setLongitudeLast(longitude);
            setSiteNameLast(site_name);
            setStartTimeLast(start_time);
            setEndTimeLast(last_time);
        } else {
            console.error("Properties not found in the last geometry.");
        }
    } else {
        console.error("Invalid data or geometries not found.");
    }
};

const parseDate = (dateString) => {
  // Remove the "+0000 UTC" part for easier parsing
  const dateWithoutUTC = dateString?.replace(" +0000 UTC", "");
  return new Date(dateWithoutUTC + "Z"); // Add 'Z' to indicate UTC time
};
const antimeridian = 180;

const needsSplit = (points) => {
  for (let i = 0; i < points.length - 1; i++) {
    const [lat1, lon1] = points[i];
    const [lat2, lon2] = points[i + 1];

    // Detect a crossing of the antimeridian by checking if one point is near +180 and the other near -180
    if ((lon1 > 170 && lon2 < -170) || (lon1 < -170 && lon2 > 170)) {
      return true; // A split is needed
    }
  }
  return false; // No split needed
};



const splitAtAntimeridianWithOffset = (points) => {
  const splitLines = [];
  let currentLine = [points[0]];

  for (let i = 1; i < points.length; i++) {
    const [lat1, lon1] = points[i - 1];
    const [lat2, lon2] = points[i];

    // Check if crossing the antimeridian
    if ((lon1 > 170 && lon2 < -170) || (lon1 < -170 && lon2 > 170)) {
      const splitLon = lon1 > 0 ? 180 : -180;

      // Add a tiny offset to separate the lines visually
      currentLine.push([lat1, splitLon - 0.0001]);
      splitLines.push(currentLine);

      currentLine = [[lat2, -splitLon + 0.0001]];
    }
    currentLine.push([lat2, lon2, points[i][2]]);
  }

  if (currentLine.length > 1) {
    splitLines.push(currentLine);
  }

  return splitLines;
};


const createPolyline = (points) => {
  console.log("points",points)
  const lines = [];
  let currentLine = [];

  for (let i = 0; i < points.length; i++) {
    const point = points[i];
    const [lon] = point;

    if (currentLine.length === 0) {
      currentLine.push(point);
    } else {
      const [prevLon] = currentLine[currentLine.length - 1];

      // Check if the line crosses the antimeridian using the needsSplit function
      const isSplitNeeded = needsSplit([currentLine[currentLine.length - 1], point])
      console.log("isSplitNeeded",isSplitNeeded)
      if (needsSplit([currentLine[currentLine.length - 1], point])) {
        setSplitRequired(isSplitNeeded);
        // Handle crossing the antimeridian by splitting the line
        const splitPoints = splitAtAntimeridianWithOffset(currentLine, prevLon, point);

        console.log("splitPoints",splitPoints)
        lines.push(...splitPoints);
        currentLine = []; // Reset current line
      }
      currentLine.push(point);
    }
  }

  // Add the last line if it exists
  if (currentLine.length > 0) {
    lines.push(currentLine);
  }
  console.log("lines",lines)
  return lines;
};

useEffect(() => {
  console.log("vesselTrack here",vesselTrack)
  const polylines = createPolyline(vesselTrack);
  console.log("polylines new", polylines)
  if(polylines && polylines.length > 0) {
    setSplitPolyLines(polylines)
  }
},[vesselTrack])

  console.log("multiSplitPolyLines",multiSplitPolyLines)

  useEffect(() => {
    console.log("vesselData here")
    if (vesselData && starlinkLocationData) {

      let dateStr1 = "";
      if(vesselData && vesselData.length > 0) {
        dateStr1 = vesselData[vesselData.length - 1][0]
      }
      const date1 = parseDate(endTimeLast); 
      const date2 = parseDate(dateStr1);

      console.log("date1" , date1 , date2)

      if (date1 && date2 && date1 > date2) {
        // showStarlink vessel icon
        setIsStarlinkLocIcon(true)
      } else {
        setIsStarlinkLocIcon(false)
      }
    }
  }, [endTimeLast, vesselData, starlinkLocationData])

useEffect(()=> {
  if(starlinkLocationData) {
    processGeometries(starlinkLocationData?.data);
  }
},[starlinkLocationData])

console.log("starlinkLoc", isStarlinkLocIcon )

  useEffect(() => {
    setSelectedIndex(-1)
    setSelectedLastLocIndex(-1);
  },[selectedOu, k4Ids])


  function MyComponent() {
    const mapEvents = useMapEvents({
      zoomend: () => {
        let zoomLvl = mapEvents.getZoom();



        if (zoomLvl < 11) {
          if (!isOuterHexagonEnabled) {
            setIsOuterhexagaonEnabled(true);
          }
        } else if (zoomLvl >= 11) {
          if (isOuterHexagonEnabled) {
            setIsOuterhexagaonEnabled(false);
          }
        }
        if (zoomLvl < 9) {
          if (!isOuterStarLinkEnabled) {
            setIsOuterStarLinkEnabled(true);
          }
        } else if (zoomLvl >= 9) {
          if (isOuterStarLinkEnabled) {
            setIsOuterStarLinkEnabled(false);
          }
        }
        if (zoomLvl >= 8) {
          if (!isToolTip) {
            setIsToolTip(true);
          }
        } else if (zoomLvl < 8) {
          if (isToolTip) {
            setIsToolTip(false);
          }
        }
      }
    });
    return null
  }

  const createClusterCustomIcon = function (cluster) {
    return L.divIcon({
      html: `<span className="outerCircle" style={{height:8px,width: 8px, background: blue, borderRadius:50%, display: inline-block}}></span>`,
    })
  }

  const vesselIcon = (angle, index, isEdge) => {
    let angles = angle? angle+270:-90;
    return renderToStaticMarkup(
      isEdge? isEdgeEventsEnabled && <span className="outerCircle" style ={{ position:"absolute",left: "2px", top: "-3px"}}><div>&#x2742;</div></span>:
      selectedIndex === index ?  
      <span className="outerCircle"  style={{height:`16px`,width: `16px`, background: `black`, borderRadius:`50%`, display: `inline-block`}}><div style={{transform:"scale(2,2.4) rotate("+angles+"deg)",display:"block",color:"red", position:"absolute",left: "1px", top: "-3px"}}></div></span> :
      <span className="outerCircle" style={{height:`0px`,width: `0px`, background: `#00008B`, borderRadius:`50%`, display: `inline-block`}}><div style={{transform:"scale(1,1.2) rotate("+angles+"deg)",display:"block",color:"#00008B", position:"absolute",left: "1px", top: "-3px"}}></div></span>
    );
  }

  const CustomVesselIcon = (angle, index,isEdge) => divIcon({
    html: vesselIcon(angle, index,isEdge)
  });

  const getVesselRedIconOnCondition = (angle, index, iconClasfier, source) => {
        return selectedLastLocIndex === index ? <img src={source=='STARLINK'? TerminalRedIc :VesselRedIc} alt={'Tower'} className={source=='STARLINK'? "starlinklBigIcon":"vesselRedIcon"} style={{ transform: `rotate(${angle}deg)` }} /> : <img src={source=='STARLINK'? TerminalRedIc:VesselRedIc} alt={'Vessel'} className="vesselIcon" style={{ transform: `rotate(${angle}deg)`, borderRadius: 50 }} />
  }
  const VesselRedIcon = (angle, index, iconClasfier, source) => renderToStaticMarkup(
    getVesselRedIconOnCondition(angle, index, iconClasfier, source)
  );

  const getVesselGrayIconOnCondition = (angle, index, iconClasfier, source) => {
    return selectedLastLocIndex === index ? <img src={source == 'STARLINK' ? TerminaGraylIc:VesselGreyIc} alt={'Tower'} className="vesselRedIcon" style={{ transform: `rotate(${angle}deg)` }} /> : <img src={source == 'STARLINK' ? TerminaGraylIc:VesselGreyIc} alt={'Vessel'} className="vesselIcon" style={{ transform: `rotate(${angle}deg)`, borderRadius: 50 }} />
}

  const getVesselIconOnCondition = (angle, index, iconClasfier, source) => {
    switch (iconClasfier) {
      case 1:
        return locationFilter?.nonAlerting ? <img src={VesselRedIc} alt={'Vessel'} className="vesselIcon" style={{ transform: `rotate(${angle}deg)`, borderRadius: 50 }} /> : null;
      case 2:
        return locationFilter?.nonAlerting ? <img src={VesselRedIc} alt={'Vessel'} className="vesselIcon" style={{ transform: `rotate(${angle}deg)`, borderRadius: 50 }} /> : null;
      case 3:
        return locationFilter?.nonAlerting ? <img src={VesselRedIc} alt={'Vessel'} className="vesselIcon" style={{ transform: `rotate(${angle}deg)`, borderRadius: 50 }} /> : null;
      case 4:
        return locationFilter?.alerting ? <img src={VesselGreenIc} alt={'Vessel'} className="vesselIcon" style={{ transform: `rotate(${angle}deg)`, borderRadius: 50 }} /> : null;
      default:
        return selectedLastLocIndex === index ? <img src={source == 'STARLINK' ? TerminaBluelIc:VesselBlueIc} alt={'Tower'} className="starlinklBigIcon" style={{ transform: `rotate(${angle}deg)` }} /> : <img src={source == 'STARLINK' ? TerminaBluelIc: VesselBlueIc} alt={'Vessel'} className={selectedLastLocIndex === index ? "starlinklBigIcon" : "vesselIcon"} style={{ transform: `rotate(${angle}deg)`, borderRadius: 50 }} />
    }
  }

  const VesselBlueIcon = (angle, index, iconClasfier, source) => renderToStaticMarkup(
    getVesselIconOnCondition(angle, index, iconClasfier, source)
  );

  const CustomVesselBlueIcon = (angle, index, iconClasfier, source) => divIcon({
    html: VesselBlueIcon(angle, index, iconClasfier, source)
  });

  useEffect(() => {
    if (!isChartClosed) {
      setSelectedIndex(-1)
      setSelectedLastLocIndex(-1);
    }
  }, [isChartClosed])

  useEffect(() => {
    if (vesselCurrentLocation && vesselCurrentLocation.length > 0 && selctedVesselId) {
      setSelectedLastLocIndex(vesselCurrentLocation.findIndex(vessel => vessel?.locationId === selctedVesselId));
    } else {
      setSelectedLastLocIndex(-1);
    }
  }, [selctedVesselId, vesselCurrentLocation])


  const handleVesselClickInner = (e, vesselTimeStamp, vesselEndTime, selectedvessels, nearestTowerDetails, isWindowEnabled) => {

    handleChartOpen(true);
    if(e?.layer?.properties?.h3index) {
      if(e?.layer?.properties?.h3index === selectedIndex) {
        setSelectedIndex(-1);
        handleChartOpen(false);
        return
      } else {
        setSelectedIndex(e?.layer?.properties?.h3index)
        handleVesselClick(vesselTimeStamp, vesselEndTime, selectedvessels, nearestTowerDetails,isWindowEnabled);
        return
      }
    }

    if (selectedIndex === e?.target?.options?.index) {
      setSelectedIndex(-1);
      handleChartOpen(false);
      return
    }
    setSelectedIndex(e?.target?.options?.index);
    handleVesselClick(vesselTimeStamp, vesselEndTime, selectedvessels, nearestTowerDetails,isWindowEnabled);
  }

  const handleHexClickInner = (e, resolution) => {
    handleHexClick(e, resolution);
  }
// vesselData Voyage correct here
  useEffect(() => {
    if (vesselData !== undefined && vesselData.length > 0) {
      let indexPosition = vesselData.length - 1
      let vesselDataLastCord = vesselData[indexPosition];
      if (mapPosition[0] !== vesselDataLastCord[2]) {
        if(vesselDataLastCord[2] && vesselDataLastCord[3]) {
          // SetView([vesselDataLastCord[2], vesselDataLastCord[3]], 10);
             
          // let clickedLatLng = e.latlng;
          // let VariableMovement = calculateOffset(mapZoom);
          const mapContainer = map?.getContainer();
          if(mapContainer) {
            const fixedPosition = {
              x: mapContainer.clientWidth / 4,
              y: mapContainer.clientHeight / 2
           };
            let fixedPointLatLng = map.containerPointToLatLng(fixedPosition);
            let newLat = vesselDataLastCord[2] - (fixedPointLatLng.lat - map.getCenter().lat);
            let newLng = vesselDataLastCord[3] - (fixedPointLatLng.lng - map.getCenter().lng);
            map.setView([newLat, newLng], map.getZoom());
          }
        
        } else {
          console.log("CORDINATES EMPTY", vesselDataLastCord[2], vesselDataLastCord[3]);
        }
        
      }
    }
  }, [vesselData])

  const customMarkerPoints = (props) => divIcon({
    className: 'pointsTrackIcons',
    html: `<span style=paddingTop:10px>${props}Mb/s</span>`,

    iconSize: [25, 41],
});

const EdgeEventMarkerPoints = (props) => divIcon({
  className: '',
  html: `<span>&#x2742;</span>`,
});
  return (
    <div id="map" className='LeafLetMaps' style={isPopUpOpen ? style : style} >
      <MapContainer
        center={mapPosition}
        zoom={4}
        minZoom={3}
        maxZoom={22}
        style={{ height: "100%" }}
        whenCreated={setMap}
        attributionControl={false}
        zoomControl={false}
        worldCopyJump={true} // Disable world copy jumping
        // maxBounds={[[-90, -180], [90, 180]]} // Constrain to world bounds
        maxBoundsViscosity={1.0} // Prevent overscrolling
        scrollWheelZoom={false}
        smoothSensitivity={0.90}
        smoothWheelZoom={true}
        fadeAnimation={true}
        preferCanvas={false}
        boxZoom={true}
      >
        <ScaleControl className="mapScale" position="bottomright" />
        <ZoomControl position="topright" />
        <Control position="topright" className="customButtons" >
          <ButtonGroup orientation="vertical" className="mapCustButtonsGroup" >
            <Button title='Layers' id="layersSel"
              // color={active === "adjust" ? "primary" : "inherit"}
              onClick={() => handleLayerButton()}
            >
              <LayersIcon className="layersIcon" color="black" />
            </Button>
           { selctedK4Id !=="" && <Button title='Auto Chart' id="layersSel" onClick ={() => handleVesselClickInner(null, null, null, null,false)}>
              <AutoGraphOutlinedIcon  className="layersIcon" color="black"/>
            </Button>}
          </ButtonGroup>
        </Control>
        <MyComponent />
        {layersOpen && <div className="maps-layers-new-container" id="maps-layers-new-container">
          <LayerDetailsMaps getLayerDetails={getLayerDetails} setOpen={setLayersOpen} layerSelectionDetails={layerSelectionDetails} isLocationEnabled={(isLocationEnabled)} isSpecialPrrmEnabled={false}/>
        </div>}
        {isLocationEnabled > 0 && chartsOpen  &&
        <div className="maps-journey-container">
          <MapCharts open={chartsOpen} setOpen={setChartsOpen} selectedVessel={selectedVessel} selectedVesselName={selectedVesselName}    
              hoveredStart={hoveredStart}
              hoveredEnd={hoveredEnd} />
        </div>
        }

        <MapIncluder isDark={false} isOpeStreet={true} />
        {isHexogonsEnabled && !isVsatDataSource ? Object.entries(hexagons).map(([weight, polygons]) => {
          return <Polygon
            positions={polygons}
            pathOptions={{
              fillColor: polyColor,
              color: polyColor,

            }}

          />;
        }) : null}

        {isStarlinkLatencyMacro  && starLinkData  && selectedDataSource === "STARLINK" &&<StarLinkGrid assets={starLinkData} selectedLayer={selectedLayer} hexOpacity={innerHexOpacity} isEventsEnabled={true} handleHexogonClick={handleHexClickInner} selectedCarrier={selectedCarrier} h3Index={h3Index} zindex={1000} selectedDataSource={selectedDataSource} resolution={3}/> }
        {isStarlinkLatencyMicro && starLinkData && selectedDataSource === "STARLINK" &&<StarLinkGrid assets={starLinkCoverageFour} selectedLayer={selectedLayer} hexOpacity={innerHexOpacity} isEventsEnabled={true} handleHexogonClick={handleHexClickInner} selectedCarrier={selectedCarrier} h3Index={h3Index} zindex={10} selectedDataSource={selectedDataSource} resolution={4}/> }
        {isLteMacro && outerHexData && <VectorGrid assets={outerHexData} selectedLayer={selectedLayer.replace("_", " ")} hexOpacity={outerHexOpacity} isEventsEnabled={true} handleHexogonClick={handleHexClickInner} selectedCarrier={selectedCarrier} h3Index={h3Index} zindex={10000} resolution={5} /> }
        {isLteMicro && innerHexData && <VectorGrid assets={innerHexData} selectedLayer={selectedLayer.replace("_", " ")} hexOpacity={innerHexOpacity} isEventsEnabled={true} handleHexogonClick={handleHexClickInner} selectedCarrier={selectedCarrier} h3Index={h3Index} zindex={10} resolution={7}/>} 
{/* <MarkerClusterGroup
          maxClusterRadius={10}
          // disableClusteringAtZoom={undefined}
          iconCreateFunction={createClusterCustomIcon}
          showCoverageOnHover={false}
        >           */}
        {selctedK4Id !=="" && vesselData !== undefined && vesselData?.length> 0 && vesselData?.map((item, index) => {

            // const cordinates = item?.geometry?.coordinates;
            const vesselTimeStamp = moment.utc(item[0], 'YYYY-MM-DD HH:mm:ss.SSS +-HHmm').toISOString();
            const vesselEndTime = moment.utc(item[1], 'YYYY-MM-DD HH:mm:ss.SSS +-HHmm').toISOString();
            const nearestTowerDetails  = null;
            const isEdge = null;
            // const isVesselPath  = item?.isVesselPath;
        
            const long = item[3];
            const lat = item[2];
            if ( long === null || lat === null) {
              return null;
            }
            const angle = null;
            if(isEdge && !isEdgeEventsEnabled) {
              return;
            } 

            return <Marker
              key={index}
              index={index}
              position={[lat, long]}
              icon={CustomVesselIcon(angle, index,isEdge)}
              eventHandlers={{
                mouseover: (e) => {
                  // SetVesselChartDetails(handleVesselOnhover(vesselTimeStamp, selectedvessels))

                  e.target.openPopup();
                },
                mouseout: (e) => {
                  setTimeout(() => {
                    e.target.closePopup();
                    // handleVesselPopUp(vesselTimeStamp, vesselEndTime, selectedvessels)
                  }, 500);
                },
                click: (e) => {
                  // handleClick(e, true);
                  e.target.closePopup();
                  let clickedLatLng = e.latlng;
                  // let VariableMovement = calculateOffset(mapZoom);
                  const mapContainer = map?.getContainer();
                  if(mapContainer) {
                    const fixedPosition = {
                      x: mapContainer.clientWidth / 4,
                      y: mapContainer.clientHeight / 2
                   };
                    let fixedPointLatLng = map.containerPointToLatLng(fixedPosition);
                    let newLat = clickedLatLng.lat - (fixedPointLatLng.lat - map.getCenter().lat);
                    let newLng = clickedLatLng.lng - (fixedPointLatLng.lng - map.getCenter().lng);
                    map.setView([newLat, newLng], map.getZoom());
                  }
                   handleVesselClickInner(e, vesselTimeStamp, vesselEndTime, nearestTowerDetails,true);

                },
              }}
            >
              {(vesselData?.length - 1 === index) &&
                <Tooltip direction="right" offset={[0, 0]} opacity={1} permanent>{selectedvessels}</Tooltip>}
              {isEdgeEventsEnabled && isEdge && <Popup>
                {handleVesselOnHover(vesselTimeStamp, vesselEndTime, selectedvessels, lat, long, nearestTowerDetails,isEdge,item,isEdgeEventsEnabled)}
              </Popup>}
              { <Popup>
                {handleVesselOnHover(vesselTimeStamp, vesselEndTime, selectedvessels, lat, long, nearestTowerDetails,isEdge,item,isEdgeEventsEnabled)}
              </Popup>}

            </Marker>
          }
          )}
    {/* </MarkerClusterGroup> */}

    {!isStarlinkLocIcon && selctedK4Id !=="" && vesselData !== undefined && vesselData?.length> 0 && vesselData?.map((item, index) => {

// const cordinates = item?.geometry?.coordinates;
const vesselTimeStamp = moment.utc(item[0], 'YYYY-MM-DD HH:mm:ss.SSS +-HHmm').toISOString();
const vesselEndTime = moment.utc(item[1], 'YYYY-MM-DD HH:mm:ss.SSS +-HHmm').toISOString();
const nearestTowerDetails  = null;
const isEdge = null;
// const isVesselPath  = item?.isVesselPath;

const long = item[3];
const lat = item[2];
if ( long === null || lat === null) {
  return null;
}
const angle = null;
if(isEdge && !isEdgeEventsEnabled) {
  return;
} 
let totalLength = vesselData.length -1;
if(index < totalLength ) {
  return
}

return <Marker
  key={index}
  index={index}
  position={[lat, long]}
  icon={CustomVesselBlueIcon(angle, index, "", "")}
  eventHandlers={{
    mouseover: (e) => {
      // SetVesselChartDetails(handleVesselOnhover(vesselTimeStamp, selectedvessels))

      e.target.openPopup();
    },
    mouseout: (e) => {
      setTimeout(() => {
        e.target.closePopup();
        // handleVesselPopUp(vesselTimeStamp, vesselEndTime, selectedvessels)
      }, 500);
    },
    // click: (e) => {
    //   // handleClick(e, true);
    //   e.target.closePopup();
    //   let clickedLatLng = e.latlng;
    //   // let VariableMovement = calculateOffset(mapZoom);
    //   const mapContainer = map?.getContainer();
    //   if(mapContainer) {
    //     const fixedPosition = {
    //       x: mapContainer.clientWidth / 4,
    //       y: mapContainer.clientHeight / 2
    //    };
    //     let fixedPointLatLng = map.containerPointToLatLng(fixedPosition);
    //     let newLat = clickedLatLng.lat - (fixedPointLatLng.lat - map.getCenter().lat);
    //     let newLng = clickedLatLng.lng - (fixedPointLatLng.lng - map.getCenter().lng);
    //     map.setView([newLat, newLng], map.getZoom());
    //   }
    //   //  handleVesselClickInner(e, vesselTimeStamp, vesselEndTime, nearestTowerDetails,true);

    // },
  }}
>
  {(vesselData?.length - 1 === index) &&
    <Tooltip direction="right" offset={[0, 0]} opacity={1} permanent>{selectedvessels}</Tooltip>}
  {isEdgeEventsEnabled && isEdge && <Popup>
    {handleVesselOnHover(vesselTimeStamp, vesselEndTime, selectedvessels, lat, long, nearestTowerDetails,isEdge,item,isEdgeEventsEnabled)}
  </Popup>}
  { <Popup>
    {handleVesselOnHover(vesselTimeStamp, vesselEndTime, selectedvessels, lat, long, nearestTowerDetails,isEdge,item,isEdgeEventsEnabled)}
  </Popup>}

</Marker>
}
)}

{vesselTrack && map && vesselTrack.length > 0 && (
        isSplitRequired ? (
          multiSplitPolyLines?.map((line, index) => (
              <MultiOptionsPolyline key={index} points={line} map={map} weightVal={2} />
          ))
        ) : (
          <MultiOptionsPolyline points={vesselTrack} map={map} weightVal={2} />
        )
      )}
        {/* {selctedK4Id !=="" && isVesselTrackEnabled && selectedvessels && selectedTrack !== undefined && selectedTrack.length > 1 &&
          <Polyline className='selectedTrack' key={'NEw'} pathOptions={{
            color: ' #00FFFF',
            weight: 6,
          }}
            positions={selectedTrack} arrowheads={{ size: '5px' }}

          >

          </Polyline>
        }  */}
        {/* <MarkerClusterGroup
          maxClusterRadius={20}
          iconCreateFunction={createClusterCustomIcon}
          >
        {selctedK4Id !=="" && vesselPointsEnabled && selectedvessels && vesselTrackPointsData && map && vesselTrackPointsData.length > 0 && vesselTrackPointsData.map((item,index) => {
          let lat = item[0];
          let long = item[1];
          let linkBwe = `${(item[2]/(1000 * 1000)).toFixed(0)}`;

          return <Marker
            key={index}
            index={index}
            position={[lat, long]}
            icon={customMarkerPoints(linkBwe)}
           />
        })}
        </MarkerClusterGroup> */}
        {selctedK4Id !== "" && isEdgeEventsEnabled && edgeEventsData && edgeEventsData.length > 0 && edgeEventsData.map((item, index) => {
          let position=item?.geo_data;
          return <Marker
          key={index}
          index={index}
          position={[position?.latitude, position?.longitude]}
          icon={EdgeEventMarkerPoints()}
          eventHandlers={{
            mouseover: (e) => {
              e.target.openPopup();
            },
            mouseout: (e) => {
              setTimeout(() => {
                e.target.closePopup();
              }, 500);
            }
          }}
         >
          <Popup closeButton={false}>
            <div>{item?.name}</div>
            <div>Time - {getSingleRecordChartLable(moment(item?.timestamp).valueOf(), userTimezone)}</div>
          </Popup>
          </Marker>
        })
        }
        {siteNameLast  && ( vesselTrack.length == undefined || isStarlinkLocIcon) &&
          <Marker
          key={1}
          index={1}
          position={[latitudeLast, longitudeLast]}
          icon={CustomVesselBlueIcon(0, 0, "", "")}
          eventHandlers={{
            mouseover: (e) => {
              e.target.openPopup();
            },
            mouseout: (e) => {
              setTimeout(() => {
                e.target.closePopup();
              }, 500);
            }
          }}
         >
          <Popup closeButton={false}>
            <div>{siteNameLast}</div>
            <div>Time - {getSingleRecordChartLable(moment(endTimeLast).valueOf(), userTimezone)}</div>
          </Popup>
          </Marker>
        }
        {/* {selctedK4Id !=="" && connectivity && connectivity !== undefined && connectivity.length > 0 && connectivity.map((item) => {
          if(item) {
            return  <Polyline className='vesselTrack' dashArray="10,4" key={'some'} pathOptions={{
              color: '#000000'
            }} positions={item} arrowheads={{ size: '5px' }} />
          }
        })
        } */}
        {// need further optimization
        kml && isKmlEnabled && isVsatDataSource && isSpecialPrrmEnabled && kmlLayersDetails?.Viasat1 && <GeoJSON  data={kml} />}
        {kml2 && isKmlEnabled && isVsatDataSource && isSpecialPrrmEnabled && kmlLayersDetails?.Viasat2 && <GeoJSON data={kml2} />}
        {<LocationGrid  assets={starlinkLocationData?.data} selectedLayer={selectedLayer.replace("_", " ")} hexOpacity={outerHexOpacity} isEventsEnabled={true} handleHexogonClick={(e , vesselStart, vesselEnd) => handleVesselClickInner(e, vesselStart, vesselEnd, null,false)} selectedCarrier={selectedCarrier} h3Index={h3Index} zindex={10000} resolution={5} userTimezone={userTimezone} isChartClosed={isChartClosed}/>}
      </MapContainer>
    </div> 
  );
};

export default MapLeaflet;



