import { gql } from "@apollo/client";
import _ from "lodash";
import { handleGRaphQlError, handleGraphQlResponse } from "../responseUtil";
import {
  SET_VESSEL_DASHBOARD_LOADING,
  GET_VESSEL_SUMMARY_DASHBOARD,
  SET_VESSEL_SUMMARY_LOADING,
  GET_VESSEL_SUMMARY_TABLE,
  GET_VESSEL_LOC_WITH_ALERTS_LOADING,
  GET_VESSEL_LOC_WITH_ALERTS,
  GET_SYSTEM_INVENTORY,
  GET_EDGE_SERVER_STATUS,
  // ALERTS_NAME,
  GET_CONFIGURED_ALERTS_FOR_DEVICE,
  UPDATE_CREATE_DEVICE_ALERT_CONFIGURATION,
  UPDATE_DELETE_DEVICE_ALERT_CONFIGURATION,
  UPDATE_DEVICE_ALERT_CONFIGURATION_SETTINGS,
  GET_VESSEL_SUMMARY_TOPOLOGY,
  SET_LOADING,
  GET_EDGE_SERVER_STATUS_LOADING
} from "../types";
import {ERROR_GET_VESSEL_SUMMARY_TABLE, ERROR_GET_VESSEL_SUMMARY_DASHBOARD} from "../error_types";
import { RESOURCE_FIELD_VESSELNAME } from "../../utils/constants";
// import { LOGGED_IN_EMAIL } from "../../constants/Constants";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import authReducer from "../../reducers/authReducer";

const VIEW_VESSEL_SUMMARY = "view-vessel-summary";
const viewVesselSummaryService =
  SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["service"];
const viewVesselSummaryFunction =
  SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["function"];

const DASHBOARD_WIDGET_DETAILS = gql`
query GetVesselSummaryDashboard($payload: [JSON]) {
  getVesselSummaryDashboard(payload: $payload) {
    data
  }
}
`;

const VESSEL_SUMMARY_TABLE = gql`
query GetVesselSummary($payload: [JSON]) {
  getVesselSummary(payload: $payload) {
    data
  }
}
`;

const VESSEL_LAST_LOC_WITH_ALERTS = gql`
query GetVesselLocWithAlert($payload: [JSON]) {
  getVesselLocWithAlert(payload: $payload) {
    name
    coordinates
    events {
      name
      status
      addedOn
      updatedOn
    }
    isVsat
    isLte
    heading
  }
}
`;

const SYSTEM_INVENTORY = gql`
query GetSystemInventory($vesselist: [String]) {
  getSystemInventory(vesselist: $vesselist) {
    name
    inventory {
      systemId
      LTEModems
      VSATModems
      SIMs
      boxIdentitiy
      partNumber
      serialNumber
      softwareVersion
      simCacheJson {
        simslot
        iccid
        ad
        imsi
      }
    }
  }
}
`;

const EDGE_SERVER_STATUS = gql`
query GetVesselsEndPointStatusResponse($names: [String]) {
  getVesselsEndPointStatusResponse(names: $names) {
    server_name
    endpoint_status {
      name
      status
      last_seen
    }
  }
}
`;

const VESSEL_SUMMARY_TOPOLOGY = gql`
query VesselSummaryGetWidgetData($locationId: String, $productId: String, $deviceId: String) {
  vesselSummaryGetWidgetData(locationId: $locationId, productId: $productId, deviceId: $deviceId) {
    input
    status
    message
    data
    error
  }
}
`;

export function vesselSummaryTopology(locationId: string, productId: String, deviceId: String) {
  return (dispatch: any, getState: any, client: any) => {
    client
      .query({
        query: VESSEL_SUMMARY_TOPOLOGY,
        fetchPolicy: "no-cache",
        variables: {
          locationId: locationId,
          productId: productId,
          deviceId: deviceId
        },
      })
      .then((res) => {
        handleGraphQlResponse(res, dispatch, GET_VESSEL_SUMMARY_TOPOLOGY);
      })
      .catch((err) => {
        handleGRaphQlError(err, dispatch);
      });
  };
}

export function vesselSummaryDashboard(vessels: [JSON], searchText: [String], interval: string, ouName: string) {
  let _vessels: any[] = [];
  if (vessels && Array.isArray(vessels)) {
    for (let i = 0; i < vessels.length; i++) {
      const item = vessels[i];
      _vessels.push({
        k4Id: item["id"],
        vesselName: item[RESOURCE_FIELD_VESSELNAME],
      });
    }
  }

  return (dispatch: any, getState: any, client: any) => {
    dispatch({ type: SET_VESSEL_DASHBOARD_LOADING, payload: true });
    let payload = {
      vessels: _vessels,
      // filterBy:filterBy,
      searchText: searchText,
      interval: interval
    };
    if (ouName) {
      payload['ouName'] = ouName;
    }
    client
      .query({
        query: DASHBOARD_WIDGET_DETAILS,
        fetchPolicy: "no-cache",
        variables: {
          payload: payload,
        },
      })
      .then((res) => {
        handleGraphQlResponse(res, dispatch, GET_VESSEL_SUMMARY_DASHBOARD, ERROR_GET_VESSEL_SUMMARY_DASHBOARD);
      })
      .catch((err) => {
        handleGRaphQlError(err, dispatch, ERROR_GET_VESSEL_SUMMARY_DASHBOARD);
      });
  };
}

export function vesselSummaryTableData(
  vessels: [JSON],
  searchText: [String],
  // filterBy:string,
  page: number,
  limit: number,
  sort,
  order,
  interval: string,
  ouName: string
) {

  let _vessels: any[] = [];
  if (vessels && Array.isArray(vessels)) {
    for (let i = 0; i < vessels.length; i++) {
      const item = vessels[i];
      _vessels.push({
        k4Id: item["id"],
        vesselName: item[RESOURCE_FIELD_VESSELNAME],
      });
    }
  }
  return (dispatch: any, getState: any, client: any) => {
    dispatch({ type: SET_VESSEL_SUMMARY_LOADING, payload: true });
    let payload = {
      vessels: _vessels,
      searchText: searchText,
      // filterBy:filterBy,
      page: page,
      limit: limit,
      sort: sort,
      sortOrder: order,
      interval: interval,
      // @TODO: Enable for only development
      // userName: getDataFromLocalStorage(LOGGED_IN_EMAIL),
      // service: viewVesselSummaryService,
      // service_function: viewVesselSummaryFunction,
    };
    if (ouName) {
      payload['ouName'] = ouName;
    }
    client
      .query({
        query: VESSEL_SUMMARY_TABLE,
        fetchPolicy: "no-cache",
        variables: {
          payload: payload
        },
      })
      .then((res) => {
        handleGraphQlResponse(res, dispatch, GET_VESSEL_SUMMARY_TABLE, ERROR_GET_VESSEL_SUMMARY_TABLE);
      })
      .catch((err) => {
        handleGRaphQlError(err, dispatch, ERROR_GET_VESSEL_SUMMARY_TABLE);
      });
  };
}

export function vesselLastLocWithAlerts(vessels: any[], ouName: string, search: string) {
  let _vessels: any[] = [];
  if (vessels && Array.isArray(vessels)) {
    for (let i = 0; i < vessels.length; i++) {
      const item = vessels[i];
      _vessels.push({
        k4Id: item["id"],
        vesselName: item[RESOURCE_FIELD_VESSELNAME],
      });
    }
  }
  return (dispatch: any, getState: any, client: any) => {
    dispatch({ type: GET_VESSEL_LOC_WITH_ALERTS_LOADING, payload: true });
    let payload = {
      vessels: _vessels,
      // @TODO: Enable for only development
      // userName: getDataFromLocalStorage(LOGGED_IN_EMAIL),
      // service: viewVesselSummaryService,
      // service_function: viewVesselSummaryFunction,
    };
    if (ouName) {
      payload['ouName'] = ouName;
    }
    if(search) {
      payload['searchText'] = search;
    }
    client
      .query({
        query: VESSEL_LAST_LOC_WITH_ALERTS,
        fetchPolicy: "no-cache",
        variables: {
          payload: payload,
        },
      })
      .then((res) => {
        handleGraphQlResponse(res, dispatch, GET_VESSEL_LOC_WITH_ALERTS);
      })
      .catch((err) => {
        handleGRaphQlError(err, dispatch);
      });
  };
}


export function getVesselEdgeInfo(vessel: string) {

  return (dispatch: any, getState: any, client: any) => {
    client
      .query({
        query: SYSTEM_INVENTORY,
        fetchPolicy: "no-cache",
        variables: {
          vesselist: [vessel],
        },
      })
      .then((res) => {
        handleGraphQlResponse(res, dispatch, GET_SYSTEM_INVENTORY);
      })
      .catch((err) => {
        handleGRaphQlError(err, dispatch);
      });
  };
}

export function getEdgeServerStatus(vessel: string) {
  let _vesselName = "";
  if (!_.isEmpty(vessel)) {
    _vesselName = vessel;
  }

  return (dispatch: any, getState: any, client: any) => {
    dispatch({ type: GET_EDGE_SERVER_STATUS_LOADING, payload: true });
    client
      .query({
        query: EDGE_SERVER_STATUS,
        fetchPolicy: "no-cache",
        variables: {
          names: _vesselName,
        },
      })
      .then((res) => {
        handleGraphQlResponse(res, dispatch, GET_EDGE_SERVER_STATUS);
      })
      .catch((err) => {
        handleGRaphQlError(err, dispatch);
      });
  };
}

const CONFIGURED_ALERTS_FOR_DEVICE = gql`
  query GetDeviceAlertConfiguration($locationId: String, $deviceId: String) {
    getDeviceAlertConfiguration(locationId: $locationId, deviceId: $deviceId) {
      input
      status
      message
      data
      error
    }
  }
`;

export function getConfiguredAlertsForDevice(locationId: string, deviceId: string) {
  return (dispatch: any, getState: any, client: any) => {
    client
      .query({
        query: CONFIGURED_ALERTS_FOR_DEVICE,
        fetchPolicy: "no-cache",
        variables: {
          deviceId: deviceId,
          locationId: locationId
        }
      })
      .then((res) => {
        handleGraphQlResponse(res, dispatch, GET_CONFIGURED_ALERTS_FOR_DEVICE);
      })
      .catch((err) => {
        handleGRaphQlError(err, dispatch);
      });
  };
}

const CREATE_DEVICE_ALERT_CONFIGURATION = gql`
  query CreateDeviceAlertConfiguration($locationId: String, $productId: String, $deviceId: String) {
    createDeviceAlertConfiguration(locationId: $locationId, productId: $productId, deviceId: $deviceId) {
      input
      status
      message
      data
      error
    }
  }
`;

export function createDeviceAlertConfiguration(locationId: string, productId: string, deviceId: string) {
  return (dispatch: any, getState: any, client: any) => {
    client
      .query({
        query: CREATE_DEVICE_ALERT_CONFIGURATION,
        fetchPolicy: "no-cache",
        variables: {
          deviceId: deviceId,
          locationId: locationId,
          productId: productId
        }
      })
      .then((res) => {
        handleGraphQlResponse(res, dispatch, UPDATE_CREATE_DEVICE_ALERT_CONFIGURATION);
      })
      .catch((err) => {
        handleGRaphQlError(err, dispatch);
      });
  };
}

const DELETE_DEVICE_ALERT_CONFIGURATION = gql`
  query DeleteDeviceAlertConfiguration($locationId: String, $deviceId: String, $alertType: String) {
    deleteDeviceAlertConfiguration(locationId: $locationId, deviceId: $deviceId, alertType: $alertType) {
      input
      status
      message
      data
      error
    }
  }
`;

export function deleteDeviceAlertConfiguration(locationId: string, deviceId: string, alertType: string) {
  return (dispatch: any, getState: any, client: any) => {
    client
      .query({
        query: DELETE_DEVICE_ALERT_CONFIGURATION,
        fetchPolicy: "no-cache",
        variables: {
          deviceId: deviceId,
          locationId: locationId,
          alertType: alertType
        }
      })
      .then((res) => {
        handleGraphQlResponse(res, dispatch, UPDATE_DELETE_DEVICE_ALERT_CONFIGURATION);
      })
      .catch((err) => {
        handleGRaphQlError(err, dispatch);
      });
  };
}

const UPDATE_DEVICE_ALERT_CONFIGURATION = gql`
  query UpdateDeviceAlertConfiguration($locationId: String, $productId: String, $deviceId: String, $alertType: String, $frequency: Int, $checkInterval: Int, $status: String) {
    updateDeviceAlertConfiguration(locationId: $locationId, productId: $productId, deviceId: $deviceId, alertType: $alertType, frequency: $frequency, checkInterval: $checkInterval, status: $status) {
      input
      status
      message
      data
      error
    }
  }
`;

export function updateDeviceAlertConfiguration(locationId: string, deviceId: string, productId: string, alertType: string, status: string) {
  return (dispatch: any, getState: any, client: any) => {
    client
      .query({
        query: UPDATE_DEVICE_ALERT_CONFIGURATION,
        fetchPolicy: "no-cache",
        variables: {
          deviceId: deviceId,
          locationId: locationId,
          productId: productId,
          alertType: alertType,
          status: status
        }
      })
      .then((res) => {
        handleGraphQlResponse(res, dispatch, UPDATE_DEVICE_ALERT_CONFIGURATION_SETTINGS);
      })
      .catch((err) => {
        handleGRaphQlError(err, dispatch);
      });
  };
}
