import React, { useEffect, useLayoutEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import {
  Typography, Grid, Stack, TextField, InputAdornment, Autocomplete, Paper, Tooltip, Box, Input, Button
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { SimboxPayload, getBoxes, getSimBoxes } from "../../actions/Users/authenticateReports";
import { PER_PAGE_ITEMS, DEFAULT_PAGE_SIZE, NEW_PER_PAGE_ITEMS } from "../../constants/Constants";
import { getEncodedURI, getDecodeURI, strToK4List, convertDateTimeIntoTimezone } from '../../utils/util';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import Download from '../../asset/image/DownloadUsage.svg';

import "./simAnalysis.css";
import CustomLoader from "../../components/Loader/CustomLoader";
import { Pagination } from "../../components/Pagination";
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";
import { GET_CSV_DATA_TO_DOWNLOAD } from "../../actions/types";
import DownloadCSV, { clearCsvDownload } from "../../components/DownloadCSV";
import { DEFAULT_DATE_FORMAT, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants";

const convertToCsv = (data: any[][], userTimezone: string) => {
  const [_columns, ...rows] = data;
  const csvRows: any[] = [];
  rows.forEach(row => {
    csvRows.push([row[2], row[8], row[9], row[13], row[10], row[11], row[3], convertDateTimeIntoTimezone(row[6], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), convertDateTimeIntoTimezone(row[12], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)
  ])
  })
  let lastReported = 'Last Reported (' + userTimezone + ')'
  let lastSeen = 'Last Seen (' + userTimezone + ')'
  return [['Site', 'Product ID / DID', 'Provider', 'IMEI', 'ICCID', 'Usage (MTD) GB', 'Status', lastReported, lastSeen], ...csvRows]
}

type FilterOption = {
  label: string,
  value: string,
}
const EmptyFilter = { label: "", value: "" };


const FILTERS = {
  'name' : { label: "Provider", value: "provider" },
  'device_id':  { label: "DID", value: "device_id" },
  'snum': { label: "ICCID", value: "snum" }
} 

const FILTER_NAMES = new Set(Object.keys(FILTERS));

const SimAnalysis = (props) => {
  
  const { authReducer, getBoxes, getSimBoxes, history, location, downloadAsCSV } = props;
  
  const _q = getDecodeURI(location?.search);
  const [filterBySearch, setFilterBySearch] = useState<FilterOption | null>(_q.hasOwnProperty('filterBy') && FILTER_NAMES.has(_q.filterBy) ? FILTERS[_q.filterBy]: EmptyFilter);
  const [searchValue, setSearchValue] = useState(_q.hasOwnProperty("search") ? _q.search?.trim() : "");
  const [simBoxData, setSimBoxData]: any = useState([]);
  const [page, setPage] = useState<any>(_q.hasOwnProperty("page") ? parseInt(_q.page) : 0)
  const [pages, setPages] = useState<number>(0);
  const [limit, setLimit] = useState<any>(_q.hasOwnProperty("limit") ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
  const [total, setTotal] = useState<number>(0);
  const [fromItem, setFromItem] = useState<number>(0);
  const [toItem, setToItem] = useState<number>(0);
  const [isSearchApplied, setISearchApplied] = useState<Boolean>(searchValue ? true : false);
  const [loaders, setLoaders] = useState({ showLoader: false, loadingText: "Fetching data please wait..." });
  const [vesselIds, setVesselIds] = useState([]);
  const [selectedOu, setSelectedOu] = useState( _q.hasOwnProperty('ouName') ? _q.ouName : '');
  const [payload, setPayload] = useState<SimboxPayload>({
    limit: limit,
    page: page,
    search: filterBySearch && searchValue ? `and ${filterBySearch.value} like '%${searchValue}%'` : '',
    dp: selectedOu && selectedOu !== 'K4Mobility' ? `and fullname IN ('${selectedOu}')` : '',
    vessels: undefined
  });
  
  const ENTER_KEY_CODE = "Enter";
  const dispatch = useDispatch();


  const FILTER_BY_LIST : FilterOption[] = [
    { label: "Provider", value: "provider" },
    { label: "DID", value: "device_id" },
    { label: "ICCID", value: "snum" },
  ];

  const handleFilterSearchBy = (event, newValue: FilterOption) => {
    setFilterBySearch(newValue);
  }

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value.length === 0) {
      let params: any = getDecodeURI(location?.search);
      params.search = value;
      doNavigate(params);
    }
  }

  const handlePagination = (e: any, value: number) => {
    let params: any = getDecodeURI(location?.search);
    params.page = value - 1;
    setPage(value-1)
    doNavigate(params);
    setPayload({
      ...payload,
      page: value - 1
    });
  }

  const handlePerPageChange = (e: any) => {
    const value = parseInt(e.target.value);
    let params: any = getDecodeURI(location?.search);
    params.limit = value
    setPage(0);
    setLimit(value);
    doNavigate(params);    
    setPayload({
      ...payload,
      limit: value,
      page: 0
    });
  }

  const fnSearch = () => {
    if (filterBySearch && searchValue.trim() != "") {
      const params: any = getDecodeURI(location?.search);
      params.filterBy = filterBySearch.value;
      params.search = searchValue;
      delete params.page;
      setPage(0);
      setISearchApplied(true);
      doNavigate(params);
      setPayload({
        ...payload,
        page: 0,
        search: filterBySearch && searchValue ? `and ${filterBySearch.value} like '%${searchValue}%'` : ''
      });
    }
  }

  const doNavigate = (params) => {
    history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
  }

  useEffect(() => {
    if (!payload.vessels)
      return;
    getSimBoxes(payload);
  }, [payload]);

  useEffect(() => {
    if (_.isEmpty(authReducer?.getVesselsListing))
      return;
    let vessels = [];
    const filteredVessels = authReducer?.filteredVessels;
    if (filteredVessels && filteredVessels.length > 0) {
      vessels = filteredVessels;
    } else {
      vessels = authReducer?.getVesselsListing?.locations;
    }
    setVesselIds(vessels);
    setPage(0)
    setPayload({ ...payload, page: 0, vessels, dp: selectedOu && selectedOu !== 'K4Mobility' ? `and fullname IN ('${selectedOu}')` : '' });
  }, [authReducer?.getVesselsListing, authReducer?.filteredVessels])

  useEffect(() => {
    const _selectedOu = authReducer?.selectedOu;
    if (_.isEmpty(_selectedOu) || !_selectedOu.hasOwnProperty("name"))
      return;
    if (_selectedOu.name && _selectedOu.name != selectedOu) {
      setSelectedOu(_selectedOu.name);
      setPage(0);
    }
  }, [authReducer?.selectedOu])

  useEffect(()=> {
    if (!_.isEmpty(authReducer.getSimBoxes)) {
      const data = authReducer.getSimBoxes.hasOwnProperty("data") ? authReducer.getSimBoxes.data : [];
      if(data){
        let finalData = {};
        for(var key in data) {
          const columns: string[] = data[key]?.columns;
          const simData: any[] = [];
          for (let i = 0; i < data[key].rows?.length; i++){
            const row = {};
            const currentRow = data[key].rows[i];
            currentRow.forEach((ele, index) => {
              row[columns[index]] = ele;
            })
            simData.push(row);
          }
          finalData[key] = simData;
        }
        let resultArr:any = [];
        for(let key in finalData) {
           let innerArr:any = [];
           let sims: any = [];
           let tempObj = {
            name: finalData[key][0].name,
            device_id: finalData[key][0].device_id,
            k4Id : finalData[key][0].k4Id,
            boxId: key,
            sims : sims
           }
           let temp:any = [];
           for(let i=0; i<finalData[key].length; i++){
            temp.push(finalData[key][i])
           }
           tempObj.sims = [...temp]
           innerArr.push(tempObj)
           resultArr.push(innerArr)
        }
        setSimBoxData(resultArr)
      }
    }
},[authReducer.getSimBoxes])

useEffect(()=> {
  if (!_.isEmpty(authReducer.getSimBoxesCount)) {
    let totalRecords = authReducer.getSimBoxesCount.hasOwnProperty("data") ? authReducer.getSimBoxesCount.data.rows[0][0] : 0;
    let totalPages = Math.ceil(totalRecords / limit);
    let fromIndex = (totalRecords > 0) ? (page * limit + 1) : 0;
    let toIndex = (fromIndex + limit - 1) > totalRecords ? totalRecords : (fromIndex + limit - 1);
    setTotal(totalRecords)
    setPages(totalPages);
    setFromItem(fromIndex);
    setToItem(toIndex);
    authReducer.getSimBoxesCount = {};
  }
},[authReducer.getSimBoxesCount])

  useEffect(() => {
    if (isSearchApplied && _.isEmpty(searchValue)) {
      closeSearch();
    }
  }, [searchValue]);

  
  useEffect(() => {
    if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
      if (authReducer.csvDownloadId === 'cellular-csv') {
        DownloadCSV(convertToCsv(authReducer.csvDataDownloded, authReducer.userTimezone), {
          formatters: {
            0: 'ESC-COMMA',
            7: 'ESC-COMMA',
            8: 'ESC-COMMA',
            5: 'GB'
          }
        });
        return () => {
          dispatch(clearCsvDownload())
        }
      }
    }
  }, [authReducer.csvDataDownloded])

  const searchBoxKeyDown = (event: any) => {
    if (event && event.key === ENTER_KEY_CODE) {
        fnSearch();
    }
  }

  const closeSearch = () => {
    setSearchValue("");
    let params: any = getDecodeURI(location?.search);
    delete params.search;
    delete params.page;
    delete params.filterBy;
    setSearchValue("");
    setFilterBySearch(EmptyFilter);
    doNavigate(params);
    setISearchApplied(false);
    setPayload({
      ...payload, 
      search: ''
    })

  }

  const getStatus = (interval) => {
    switch (interval) {
      case "Deactivate": return "DEAC";
      case "InActive": return "INAC";
      case "Email Sent": return "WIP";
      case "Suspended": return "SUSP";
      case "Inactivate": return "INAC";
      case "Activate": return "ACTIVE";
      case "Suspend": return "SUSP";
      default: return "INAC";
    }
  }

  const colorStatus = (interval) => {
    switch (interval) {
      case "Deactivate": return "red";
      case "InActive": return "gray";
      case "Email Sent": return "blue";
      case "Suspended": return "yellow";
      case "Inactivate": return "gray";
      case "Activate": return "green";
      case "Suspend": return "orange";
      default: return "gray";
    }
  }

  const handleDownloadReport = () => {
    let isClickHouse = true
    const vesselMap: any = payload.vessels?.map((item: any) => item["id"])
    let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;
    const simAnalysisData: any = {
      f5_limit_addf4: total,
      f4_pageStart: 0,
      f3_search: payload.search,
      f2_fullName: payload.dp,
      f1_k4id: (k4idhere && k4idhere !== '') ? `${k4idhere}` : ''
    }
    const params: CsvParameters = {
      type: isClickHouse ? 'QUERY_CH' : 'QUERY',
      id: 'cellular-csv',
      queryName: 'REPORTS_SIM_ANALYSIS_DASHBAORD',
      payload: simAnalysisData
    }
    downloadAsCSV(params);
  }

  return (
    <div className="bodyScroll">
    <div className="Form">
      <div className="d-flex justyfy-space-between sim-justyfy-space-between">
        {/* <div className="simAnalysisHeader"> */}
        <Grid container xs={12} sm={12} md={12} lg={12} className="topbarWidth alignTopbarItemsCenter">
          <Grid item xs={3} sm={3} md={3} lg={3} className="filterAlertCss selectButtonMaxWidth">
            <Autocomplete
              id="status_list" className="fliterAlertDropdown filterAction filterBy"
              options={FILTER_BY_LIST}
              onChange={(e, newValue: any) => { handleFilterSearchBy(e, newValue) }}
              value={filterBySearch}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" placeholder="Filter By" className= "filterStateName_filterBy"/>
              )}
            />
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} className="searchbarMaxWidth cellularSearchbarPadding">
            <Box className="searchbarBox">
              <TextField
                id="search"
                variant="outlined"
                placeholder="Type something"
                size="small"
                value={searchValue}
                onChange={(event) => {
                  handleSearch(event.target.value);
                }}
                onKeyDown={(event) => searchBoxKeyDown(event)}
                InputLabelProps={{ className: "serachLabel" }}
                InputProps={{
                  className: "searchbarInputs",
                  startAdornment: (
                    <InputAdornment position="start">
                      {!isSearchApplied && <SearchIcon
                        className="searchbarIconsPosition" onClick={fnSearch}
                      />}
                      {isSearchApplied && <CloseIcon
                        className="searchbarIconsPosition"
                        onClick={closeSearch}
                      />}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>

            {total > 0 && <Grid item xs={4} sm={4} md={4} lg={4} className="sim-anal-csv">
              <div className="download-summary-btn marg-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
            </Grid>}

        </Grid>
          {/* <Typography className="deviceId"> Device ID</Typography>
          <TextField
            id="searchtext"
            variant="outlined"
            placeholder="Type something"
            size="small"
            value={searchValue}
            onChange={(event) => {
              handleSearch(event.target.value);
            }}
            InputLabelProps={{ className: "serachLabel" }}
            InputProps={{
              className: "serachBar",
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className="searchDevice cursorPointer" onClick={(e) => { fnSearch() }} />
                </InputAdornment>
              ),
            }}
          /> */}
      </div>
        <div className="menuScroll">
          <Box>
            <Pagination
              rowsPerPageOptions={NEW_PER_PAGE_ITEMS}
              count={total ? total : 0}
              rowsPerPage={limit}
              page={page + 1}
              onPageChange={handlePagination}
              onRowsPerPageChange={handlePerPageChange}
            />
          </Box>
          <TableContainer className="alertsTable-FleetDashboard reports-gene-container cellular-table">
            <Table aria-label="simple sticky table" stickyHeader>
              <TableHead className="tableHead alertsTable-tableHead">
                <TableRow className="cellular-tableRow login-tableRow">
                  <TableCell className="cellular-fleetDataTable generated-reports-head noWrap font-wt-900">Site</TableCell>
                  <TableCell className="cellular-fleetDataTable generated-reports-head noWrap font-wt-900">Product ID / DID</TableCell>
                  <TableCell className="cellular-fleetDataTable generated-reports-head noWrap font-wt-900 CF-cell align-left">Provider</TableCell>
                  <TableCell className="cellular-fleetDataTable generated-reports-head noWrap font-wt-900">IMEI</TableCell>
                  <TableCell className="cellular-fleetDataTable generated-reports-head noWrap font-wt-900">ICCID</TableCell>
                  <TableCell className="cellular-fleetDataTable generated-reports-head noWrap font-wt-900">Usage (MTD) GB</TableCell>
                  <TableCell className="cellular-fleetDataTable generated-reports-head noWrap font-wt-900">
                    <span>
                      <span className="login-history-dp-name"><div className="simStatusIcon">
                        <span > Status </span>
                        <Tooltip title={<div className="tooltipBr">
                          <span>ACTIVE : Active</span><br></br>
                          <span>DEAC: SIM Deactivated – No Monthly Charges </span><br></br>
                          <span>INAC: SIM Was NEVER activated </span><br></br>
                          <span>SUSP:Temporary Suspension (there might be Suspension Charges every month)</span><br></br>
                          <span>WIP: There is a request sent to the Service Provider (ACTIVATION / DEACTIVATION / ENQUIRY)</span><br></br>
                        </div>} arrow>
                          <InfoIcon className="marg5"></InfoIcon>
                        </Tooltip>
                      </div></span>
                    </span>
                  </TableCell>
                  <TableCell className="cellular-fleetDataTable generated-reports-head noWrap font-wt-900">Last Reported</TableCell>
                  <TableCell className="cellular-fleetDataTable generated-reports-head noWrap font-wt-900">Last Seen</TableCell>
                </TableRow>
              </TableHead>
              {simBoxData.length > 0 &&
                <TableBody className="tableBody alertsTable-tableBody">

                  {simBoxData && Array.isArray(simBoxData) && simBoxData.length > 0 && simBoxData.map((simBox, i) =>
                    <TableRow className="cellularData-tabelRow">
                      <TableCell align="left" className="cellularTable-tableCell noWrap">
                        {simBox[0].name}
                      </TableCell>
                      <TableCell align="left" className="cellularTable-tableCell noWrap">
                        {simBox[0].device_id !== null ? simBox[0].device_id : '-'}
                      </TableCell>
                      <TableCell align="left" className="cellularTable-tableCell noWrap">
                        <ul className="pl-15">
                          {simBox[0].sims && Array.isArray(simBox[0].sims) && simBox[0].sims.length > 0 && simBox[0].sims.map((sim) => {
                            return (
                              <div>
                                {sim.provider && sim.k4Owned == 'Yes' ? <li>{sim.provider}</li> : sim.provider && sim.k4Owned == 'No' ? <li className="providerAlign"><span className="_provider">3P</span>{` - ${sim.provider}`}</li> : <li>{"3P"}</li>}
                              </div>
                            )
                          })}
                        </ul>
                      </TableCell>
                      <TableCell align="left" className="cellularTable-tableCell noWrap">
                        <ul>
                          {simBox[0].sims && Array.isArray(simBox[0].sims) && simBox[0].sims.length > 0 && simBox[0].sims.map((sim) => {
                            return (
                              <li>{sim.imei}{!_.isEmpty(sim.peplink) && sim.peplink.toLowerCase() == "yes" ? "*" : ""}</li>
                            )
                          })}
                        </ul>
                      </TableCell>
                      <TableCell align="left" className="cellularTable-tableCell noWrap">
                        <ul >
                          {simBox[0].sims && Array.isArray(simBox[0].sims) && simBox[0].sims.length > 0 && simBox[0].sims.map((sim) => {
                            return (
                              <li>{sim.snum}</li>
                            )
                          })}
                        </ul>
                      </TableCell>
                      <TableCell align="left" className="cellularTable-tableCell noWrap">
                        <ul>
                          {simBox[0].sims && Array.isArray(simBox[0].sims) && simBox[0].sims.length > 0 && simBox[0].sims.map((sim) => {
                            return (
                              <div>
                                {sim.data_usage != null ? <li>{((sim.data_usage) / (1024 * 1024 * 1024)).toFixed(2)}</li> : 0}
                              </div>
                            )
                          })}
                        </ul>
                      </TableCell>
                      <TableCell align="left" className="cellularTable-tableCell noWrap">
                        <ul>
                          {simBox[0].sims && Array.isArray(simBox[0].sims) && simBox[0].sims.length > 0 && simBox[0].sims.map((sim) => {
                            return (
                              <li className="noLeftPadding" style={{ color: colorStatus(sim.status) }}>{getStatus(sim.status)}</li>
                            )
                          })}
                        </ul>
                      </TableCell>
                      <TableCell align="left" className="cellularTable-tableCell noWrap">
                        <ul>
                          {simBox[0].sims && Array.isArray(simBox[0].sims) && simBox[0].sims.length > 0 && simBox[0].sims.map((sim) => {
                            return (
                              <li className="noLeftPadding">{convertDateTimeIntoTimezone(sim.lastReported, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</li>
                            )
                          })}
                        </ul>
                      </TableCell>
                      <TableCell align="left" className="cellularTable-tableCell labelDetails noWrap">
                        <ul>
                          {simBox[0].sims && Array.isArray(simBox[0].sims) && simBox[0].sims.length > 0 && simBox[0].sims.map((sim) => {
                            return (
                              <div>
                                {convertDateTimeIntoTimezone(sim.lastSeen, authReducer.userTimezone, DEFAULT_DATE_FORMAT)}
                              </div>
                            )
                          })}
                        </ul>
                      </TableCell>
                    </TableRow>
                  )
                  }
                </TableBody>}

              {simBoxData.length === 0 && <TableBody>
                <TableRow>
                  <TableCell colSpan={12} className="noDataAvailableCss" align="center">No data available</TableCell>
                </TableRow>
              </TableBody>}

            </Table>
          </TableContainer>
        </div>

    </div >
    { authReducer.getSimBoxesLoading ? (
          <CustomLoader
            showLoader={true}
            loadingText={ "Fetching data please wait..." }
          />
        ) : null
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer
});

export default withRouter(
  connect(mapStateToProps, {
    getBoxes,
    getSimBoxes,
    downloadAsCSV
  })(SimAnalysis)
);