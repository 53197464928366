import { FormControl, Grid, Tooltip } from "@mui/material";
import _ from "lodash";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import "./usagePieChart.css"
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { UsageContext } from "../../UserScreen/UserData/UsageDataContext";
import { useContext } from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getDecodeURI } from "../../utils/util";
import ImageWithFallback from "../ImageWithFallback";

const UsagePieChart = (props) => {
    const { pieChartUsage, title, name, unit, categoryIcons, appIcons, header, location } = props;
    const { selectFilter, handleCategoryChange, handleAppFilterChange, setPage, setPageStart, setCategoryTrack, vlanFilt, checkUser, deviceFilt, handleUsernameChange, setUserNameTrack } = useContext(UsageContext);

    let _q = getDecodeURI(location?.search);
    
    const tooltip = {
        enabled: true,
        useHTML: true,
        headerFormat: ``,
        pointFormat: '<tr>' +
            `<td style="text-align: left;display:block"><b>{point.x} <br/> ${name}: {point.z} ${unit}</b></td></tr>`,
        footerFormat: '</table>',
        valueDecimals: 2
    }

    const netifyTooltip = {
        enabled: true,
        useHTML: true,
        headerFormat: ``,
        formatter: function() {
            var self: any = this;
            return `<b>${header} : </b> ${self.point.x}` + '<br/>' + `<b>Risk Score : </b> ${self.point.z}` + '<br/>' + `<b>Risk Types : </b>` + '<br/>' + `${self.point.list.split(',').join('\n')}`
        },
        footerFormat: '</table>',
        valueDecimals: 2
    }

    const options: Highcharts.Options = {
      chart: {
        type: 'pie',
        height: '185px',
        style: {
            fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
        },
        backgroundColor: 'none'
      },
      colors: ["#1b51a7", "#8cb5ec", "#0e0548", "#1d136a", "#3e88d3", "#86c6df", "#4b91f1", "#64a1f4", "#8dbdff", "#c1d5f6"],
      title: {
          text: ''
      },
      yAxis: {
          title: {
              text: 'App Category Used'
          }
      },
      tooltip: name != 'netify' ? tooltip : netifyTooltip,
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
              shadow: false,
        }
    },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
        series: [{
            type: 'pie',
            name: 'Usage',
            data: pieChartUsage,
            size: '100%',
            innerSize: '50%',
            dataLabels: {
                enabled: false
            }
        }]
    }

   const colors= ["#1b51a7", "#8cb5ec", "#0e0548", "#1d136a", "#3e88d3", "#86c6df", "#4b91f1", "#64a1f4", "#8dbdff", "#c1d5f6", 
   "#1b51a7", "#8cb5ec", "#0e0548", "#1d136a", "#3e88d3", "#86c6df", "#4b91f1", "#64a1f4", "#8dbdff", "#c1d5f6"
   ];

   const handlePieChartClick = (ele) => {
    if(ele.type == 'application') {
        let filters: any = {};
        let appFilt = ele.x;
        filters.filterAppFilt = appFilt;
        filters.vlanFilter = vlanFilt;
        filters.userCheck = checkUser;
        handleAppFilterChange(null, appFilt);
        selectFilter(filters);
    } else if(ele.type == 'netify') {
        if (ele.userCheck) {
            let filters: any = {};
            filters.filterUserName = ele.x;
            filters.vlanFilter = vlanFilt;
            filters.deviceFilter = deviceFilt;
            filters.userCheck = ele.userCheck == 'local_mac' ? 'macAddress' : 'ipAddress';;
            handleUsernameChange(null, ele.x);
            setPage(1);
            setPageStart(1);
            selectFilter(filters);
            setUserNameTrack(true);
          } else {
            let filters: any = {};
            filters.filterSite = ele.x;
            filters.vlanFilter = vlanFilt;
            filters.deviceFilter = deviceFilt;
            filters.userCheck = checkUser;
            setPage(1);
            setPageStart(1);
            selectFilter(filters);
          }
    } else{
        let filters: any = {};
        let category = ele.x;
        filters.filterCategory = category;
        filters.vlanFilter = vlanFilt;
        filters.userCheck = checkUser;
        handleCategoryChange(null, category);
        selectFilter(filters);
        setCategoryTrack(true);
    }
    setPage(1);
    setPageStart(1);   
   }


  function GetTopAppsImageCategory (props)  {
    const { app } = props;
    if(app) {
      let appName = app.includes(' ') ? app.split(' ')[0]: app.includes('-') ? app.split('-')[0] : ""; 
      return <ImageWithFallback src={`https://k4storage.blob.core.windows.net/cdn/K4ICONS/ActivityIcons/${app.trim().toLowerCase()}.svg`} fallbackSrcs={[`https://k4storage.blob.core.windows.net/cdn/K4ICONS/ActivityIcons/${appName.trim().toLowerCase()}.svg`,"https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png"]} app={app} />
    } else {
      return <img className="logo-activity paddingLogo" src={`https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png`} />
    }

  }

  function GetTopAppsImage (props)  {
    const { app } = props;
    if(app) {
      let appName = app.includes(' ') ? app.split(' ')[0]: app.includes('-') ? app.split('-')[0] : ""; 
      return <ImageWithFallback src={`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${app.trim()}`} fallbackSrcs={[`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${appName.trim()}`,"https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png"]} app={app} />
    } else {
      return <img className="logo-activity paddingLogo" src={`https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png`} />
    }

  }

  return (
    <div className="Form activity-Form">
        <Grid className="am-table">
           <Grid className="totalUsageChartContainer activity-totalUsageChartContainer" id={_q.hasOwnProperty('topUsage') && 'activity-pie-container'}>
              <Grid id={_q.hasOwnProperty('topUsage') && 'activity-pie-container-1'}>
                    <Grid>
                        <div className="rc--filter_pie-usage piechart-selectContainer">
                           <Grid>{title}</Grid>
                        </div>
                    </Grid>
                    <Grid className="usage-doughnutChart usage-activity-pie">
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                        />
                    </Grid>
              </Grid>
              <Grid id={_q.hasOwnProperty('topUsage') && 'activity-pie-container-2'}>
                    <Grid className="usageDetailsWithCategory">
                        {
                            pieChartUsage && pieChartUsage.map((ele,i) => (
                                <Grid key={i} className="appcategoryUsed-usage">
                                    <div style={{background:`${colors[i]}`}} className="usageDot-1"></div>
                                    <Grid className="pieChart-activity" id={_q.hasOwnProperty('topUsage') && 'activity-appcategoryUsed-usage'}>
                                        <Grid className="usagepercentage">{pieChartUsage && `${ele.y < 1 ? ele.y < 0.01 && ele.y !== 0 ? 0.01: Math.round(ele.y * 100) / 100: Math.round(ele.y)}%`}</Grid>
                                        {categoryIcons ? <GetTopAppsImageCategory app={ele.x.split(' ').join('')} />: appIcons ? <GetTopAppsImage app={ele.x} /> : null}
                                        {ele.tooltip ? <Tooltip title={ele.tooltip} placement={'left-end'}>
                                            <p>{ele.x}</p>
                                        </Tooltip> : (categoryIcons || appIcons || name=='netify') ? <Tooltip title={ele.x}><p className="activity-pieChart-app" onClick={() => { handlePieChartClick(ele) }} >{ele.x}</p></Tooltip> : <p>{ele.x}</p> }
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </Grid>
              </Grid>
           </Grid>
        </Grid>
    </div>
  )
} 

const mapStateToProps = () => ({});

export default withRouter(
    connect(mapStateToProps, {
    })(UsagePieChart)
);