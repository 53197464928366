import { Fragment, useState, useEffect, useContext, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Grid, Collapse, TextField, Autocomplete, FormGroup, FormControlLabel, Checkbox, Box, Menu, Chip } from "@mui/material";
import _ from "lodash";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {

} from "../../actions/Users/authenticateReports";
import moment, { Moment } from "moment";
import { Activity, FirewallReportContext } from "./FirewallReportContext";
import { Tune } from '@material-ui/icons';

import "../../components/RemoteConnections/index.css";
import "../../components/UsageReportFilters/usageReportsFilters.css";

import close from "../../asset/image/close.svg";
import { Stack } from "@mui/system";
import Download from '../../asset/image/DownloadUsage.svg';
import ReportPopup from "./ReportPopup";
import Reports from "../UserData/Reports";
import { getDecodeURI, getEncodedURI } from "../../utils/util";

const FirewallFilters = (props) => {
    const { selectedVessels = [], getFirewallRuleNameFilter, isSitesLoading = false, isAuthorizedToViewBetaFeature, tableCount, newSummaryStartDate, newSummaryEndDate, location, history } = props;
    const [ruleNames, setRuleNames] = useState<string[]>([]);
    const [filterPopup, setFilterPopup] = useState(false);
    const [reportPopup, setReportPopup] = useState(false);
    const [confirmFilter, setConfirmFilter] = useState(false);

    const [anchorElRef, setAnchorElRef] = useState<HTMLElement>();

    const {
        ruleName,
        activities,
        handleActivityFilter,
        handleRuleNameChange,
        onApplyFilters,
        onClearFilters,
        onSelectFilters,
    } = useContext(FirewallReportContext);

    useEffect(() => {
        if (!_.isEmpty(getFirewallRuleNameFilter)) {
            const data = getFirewallRuleNameFilter?.hasOwnProperty('data') ? getFirewallRuleNameFilter?.data : {};
            if (data) {
                const rows: any[] = data?.rows;
                const columns: string[] = data?.columns;
                const ruleNames: any[] = [];
                for (let i = 0; i < rows?.length; i++) {
                    const row: Record<string, string> = {};
                    const currentRow = rows[i];
                    columns.forEach((column, index) => {
                        row[column] = currentRow[index];
                    })
                    ruleNames.push(row.ruleName);
                }
                setRuleNames(ruleNames)
            }
        }
    }, [getFirewallRuleNameFilter]);

    const handleActivityFilterChange = (activity: Activity, checked: boolean) => {
        activity.selected = checked;
        handleActivityFilter(activity);
    }

    const handleApplyFilters = () => {
        onApplyFilters();
        handleOnCloseFilters();
    }

    const handleOnCloseFilters = () => {
        setFilterPopup(false)
    }

    const handleSiteDelete = () => {
        const checkInterval = getInterval(Math.floor(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3));
        if(checkInterval) {
            setConfirmFilter(checkInterval);
            onSelectFilters({filterSiteName: '', trackSite: true})
        } else {
            onSelectFilters({filterSiteName: '', trackSite: false})
        }
    }

    const handleRulenameDelete = () => {
        handleRuleNameChange(null);
        onSelectFilters({filterRuleName: ''});
    }

    const handleActivityDelete = () => {
        let selectedActivity: Activity | null = null;
        activities.forEach(act => {
            if(act.selected) {
                selectedActivity = act;
                return;
            }
            act.selected = true;
        });
        if (!selectedActivity)
            return;
        handleActivityFilter(selectedActivity);
        onSelectFilters({ filterActivities: activities });
    }

    const getSiteFilterChip = () => {
        const _vessels = selectedVessels;
        if (!_.isArray(_vessels) || _vessels.length !== 1 || !_vessels[0].hasOwnProperty('vessel-name'))
            return null;
        return <Chip label={_vessels[0]['vessel-name']} disabled={isSitesLoading} onDelete={handleSiteDelete} />
    }

    const handleOpenFilters = () => {
        setReportPopup(true);
        let params: any = getDecodeURI(location.search);
        params.filterClick = true;
        doNavigate(params);
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const getInterval = (minutes) => {
        if (minutes > 10080) {
          return true;
        } 
        return false;
    }

    useEffect(() => {
        let _q: any = getDecodeURI(location?.search);
        if(selectedVessels.length !== 1) {
            const checkInterval = getInterval(Math.floor(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3));

            !_q.isFixedIntervalDisabled && !reportPopup && setConfirmFilter(checkInterval);
        }
        // if(_q.isFixedIntervalDisabled) {
        //     localStorage.setItem("intraUrl","http://sqlapi.dev.svc.cluster.local:8080/store/ch/query")
        // } else {
        //     localStorage.setItem("intraUrl","")
        // }
    }, [newSummaryStartDate, newSummaryEndDate, selectedVessels])

    const filterPopClick = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorElRef(e.currentTarget)
        setFilterPopup(true);
    } 

    return (
        <Box className="padding-filters">
            <Fragment>
                <Grid className="filrewall-date-filter-btn alignTopbarItemsCenter">
                    <Grid display={"flex"}>
                        <Button variant="outlined" startIcon={<Tune id="filterIcon"/>} onClick={filterPopClick} id="filterButton">
                            Filter
                        </Button>
                        <Stack className="chip-margin" direction="row" spacing={2} alignItems='center'>
                            {getSiteFilterChip()}
                            {ruleName && <Chip label={ruleName} disabled={isSitesLoading} onDelete={handleRulenameDelete} />}
                            {activities.every(act => act.selected) ? null : activities.filter(act => act.selected).map(act => <Chip key={'activity-chip-' + act.key} label={act.label} disabled={isSitesLoading} onDelete={handleActivityDelete} />)}
                        </Stack>
                    </Grid>
                    {<Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} className="download-reports-container">
                        <div className="download-reports-btn" onClick={handleOpenFilters}><Button>Generate Report</Button><img src={Download} alt="" /></div>
                    </Grid>}
                </Grid>
                <Menu
                    open={filterPopup}
                    anchorEl={anchorElRef}
                    className="rc--filter_menu_container_reports"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    
                    <Grid className="usageFilterComponent">
                        <div className="Form">
                            <Grid className="am-table">
                                <Grid className="dash-filter-pop">
                                    <Grid>
                                        <Grid className="filter-clearFilter">
                                            <div className="Filters_Title">Filters</div>
                                            <img src={close} onClick={handleOnCloseFilters} alt="Close" className="activityReportsFilter-close"/>
                                        </Grid>
                                        <Grid>
                                            <div className="rc--filter_section">
                                                <SearchRuleFilter name='Rule name' rules={ruleNames} handleChange={handleRuleNameChange} value={ruleName} />
                                            </div>
                                        </Grid>

                                        <Grid>
                                            <FormGroup sx={{ paddingLeft: 3 }} className="firewallFilterOptions">
                                                {activities.map(filter => <FormControlLabel key={`form-filter-${filter.key}`} sx={{ padding: 0 }} control={<Checkbox name={filter.key} checked={filter.selected} />} onChange={(_, value) => handleActivityFilterChange(filter, value)} label={filter.label} />)}
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                      <div className="clearApplyFilters">
                                        <Button onClick={onClearFilters} className="clearFiltersButton">Clear Filter</Button>
                                        <Button className="usage-applyFilterBtn" onClick={handleApplyFilters}>Apply Filter</Button>
                                      </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Menu>
            </Fragment>
            { reportPopup ? <ReportPopup open={reportPopup} setOpen={setReportPopup} ruleNames={ruleNames} tableCount={tableCount} /> : null}
            {confirmFilter ? <Reports open={confirmFilter} setOpen={setConfirmFilter} setGenerateFilter={setReportPopup} tab={'firewall'} /> : null}
        </Box>
    )
}

interface CollapsibleProps {
    label: string;
    children: React.ReactNode;
}

export const CollapsibleSection = ({ label, children }: CollapsibleProps) => {

    const [expanded, isExpanded] = useState(true);

    return <div className="rc--filter_section">
        <div className="rc--filter_header">
            <div className="rc--content_sub_headers-select">{label}</div>
            <div className="rc--links" onClick={(e) => { isExpanded(value => !value) }}>{expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</div>
        </div>
        <Collapse in={expanded}>
            {children}
        </Collapse>
    </div>
}

interface SearchRuleFilterProps {
    name: string;
    rules: string[];
    handleChange: (value: string | null) => void;
    value: string;
}

const SearchRuleFilter: React.FC<SearchRuleFilterProps> = ({ name, rules, handleChange, value }) => {
    const [expanded, isExpanded] = useState(true);
    const [clearable, setClearable] = useState(false);

    useEffect(() => {
        if (!value || value.length == 0 && clearable) {
            setClearable(false);
        } else if (!clearable) {
            setClearable(true);
        }
    }, [value])

    const handleRuleChange = (_, value: string | null) => {
        handleChange(value);
    }
    
    const onKeyDown = (e: React.KeyboardEvent) => {
        e.stopPropagation();
    }

    return (
        <Fragment>
            <div className="rc--filter_header">
                <div className="rc--content_sub_headers-select">{name}</div>
            </div>
                <div className="rc--filter_search_container">
                    <div className="rc--filter_search userName-usageData">
                        <Autocomplete
                            disableClearable={!clearable}
                            id="tags-outlined"
                            ListboxProps={{className: "list-box-props" }}
                            size="small"
                            className="audit-filter-inputs-usage"
                            value={value}
                            onChange={handleRuleChange}
                            options={rules}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField {...params} onKeyDown={onKeyDown} variant="outlined" placeholder="search" />
                            )}
                        />
                    </div>
                </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    selectedVessels: state.authReducer?.filteredVessels,
    isSitesLoading: state.authReducer.setVesselListingLoading,
    getFirewallRuleNameFilter: state.authReducer?.getFirewallRuleNameFilter,
    newSummaryStartDate: state.authReducer.newSummaryStartDate,
    newSummaryEndDate: state.authReducer.newSummaryEndDate
});

export default withRouter(
    connect(mapStateToProps, {

    })(FirewallFilters)
);