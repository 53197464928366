
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import _ from "lodash"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from "moment-timezone";
import { useHistory, useLocation } from "react-router-dom";

import { JsonDataTooltip } from "../JSONTooltip/JsonTooltip";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS, YYYYMMDDHHMM_UTC_OFFSET } from "../../utils/constants";
import { ERROR_GET_EDGE_EVENTS, ERROR_GET_EDGE_EVENTS_FILTER } from "../../actions/error_types";
import { displayToastError } from "../../server/request";
import { Pagination, RowPerPageHandler } from "../Pagination/index";
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI } from "../../utils/util";
import { DEFAULT_PAGE_SIZE } from "../../constants/Constants";

const DEFAULT_PAGE = 1;

const HaLogData = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const { authReducer, errorReducer, filters, getHaLogTableData } = props;

    const _q = getDecodeURI(location?.search);

    const [pages, setPages] = useState<number>(0);
    const [page, setPage] = useState<number>(_q.hasOwnProperty("page") && '' != _q.page.trim() ? parseInt(_q.page) : DEFAULT_PAGE);
    const [limit, setLimit] = useState<number>(_q.hasOwnProperty("limit") && '' != _q.limit.trim() ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);

    const [totalCount, setTotalCount] = useState<number>(0);
    const [fromItem, setFromItem] = useState<number>(0);
    const [toItem, setToItem] = useState<number>(0);
    const [haLogsData, setHaLogsData] = useState<any[]>([]);

    const rowsOption = [10, 15, 25, 50, 100];

    useEffect(() => {
        if (!_.isEmpty(authReducer.getEdgeEvents)) {
            const edgeEvents = authReducer.getEdgeEvents;
            if (edgeEvents) {
                const rows: any[] = edgeEvents?.data?.rows;
                const columns: string[] = edgeEvents?.data?.columns;
                const haLogsData: any[] = [];
                for (let i = 0; i < rows?.length; i++) {
                    const row = {};
                    const currentRow = rows[i];
                    columns.forEach((column, index) => {
                        row[column] = currentRow[index];
                    })
                    haLogsData.push(row);
                }
                setHaLogsData(haLogsData);
            }
            authReducer.getEdgeEvents = {}
        }
    }, [authReducer.getEdgeEvents]);

    useEffect(() => {
        if (!_.isEmpty(authReducer.getEdgeEventsFilter)) {
            const edgeEvents = authReducer.getEdgeEventsFilter?.data;
            if (edgeEvents && edgeEvents.hasOwnProperty('filters')) {
                const data = edgeEvents.filters.data;
                if (data.length >= 3) {
                    const totalRecords = Number.parseInt(data[4]);
                    let totalPages = Math.ceil(totalRecords / limit);
                    let fromIndex = (totalRecords > 0) ? (page * limit + 1) : 0;
                    let toIndex = (fromIndex + limit - 1) > totalRecords ? totalRecords : (fromIndex + limit - 1);
                    setTotalCount(totalRecords);
                    setPages(totalPages);
                    setFromItem(fromIndex);
                    setToItem(toIndex);
                } else {
                    setTotalCount(0);
                    setPages(0);
                    setFromItem(0);
                    setToItem(0);
                }
            }
            authReducer.getEdgeEventsFilter = {}
        }
    }, [authReducer.getEdgeEventsFilter])

    useEffect(() => {
        if (!_.isEmpty(errorReducer.errorGetEdgeEvents)) {
            displayToastError(errorReducer.errorGetEdgeEvents.description);
            dispatch({ type: ERROR_GET_EDGE_EVENTS, payload: {} })
        }
    }, [errorReducer.errorGetEdgeEvents]);

    useEffect(() => {
        if (!_.isEmpty(errorReducer.errorGetEdgeEventsFitler)) {
            displayToastError(errorReducer.errorGetEdgeEventsFitler.description);
            dispatch({ type: ERROR_GET_EDGE_EVENTS_FILTER, payload: {} })
        }
    }, [errorReducer.errorGetEdgeEventsFitler]);

    const handlePageChange = (event: React.SyntheticEvent<HTMLButtonElement> | null,newPage: number,) => {
            setPage(newPage);
            let _filters = {}
            _filters["page"] = newPage;
            _filters["limit"] = limit;
            getHaLogTableData(_filters);
    };

    const handleChangeRowsPerPage: RowPerPageHandler = (event) => {
        let _limit = Number(event.target.value);
        if (Number(_limit)) {
            setPage(DEFAULT_PAGE)
            setLimit(_limit);
            let _filters = {}
            _filters["page"] = DEFAULT_PAGE;
            _filters["limit"] = _limit;
            getHaLogTableData(_filters)
        }
    };


    useEffect(() => {
        if(filters.hasOwnProperty("page") && page!=filters.page){
            setPage(filters.page)
        }
        if(filters.hasOwnProperty("pageSize") && limit!=filters.pageSize){
            setLimit(filters.pageSize)
        }
    }, [filters]);
    let inputDateFormat = "YYYY-MM-DD HH:mm:ss.SSSSSS Z UTC";
    return (
        <div className="audit-data-container ha-logs">
            <Paper className="audit-container-data">
                <Paper className="pagination-position HA-pagination">
                    <Pagination
                        rowsPerPageOptions={rowsOption}
                        count={totalCount}
                        rowsPerPage={limit}
                        page={page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                <TableContainer className="ha-logs-table-div">
                    <Table size="small" className="" aria-label="collapsible sticky table" stickyHeader>
                        <TableHead className="audit-data-head">
                            <TableRow>
                                <TableCell className="audit-data-header">TIMESTAMP</TableCell>
                                <TableCell className="audit-data-header">DID</TableCell>
                                <TableCell className="audit-data-header">DEVICE NAME</TableCell>
                                <TableCell className="audit-data-header">LEVEL</TableCell>
                                <TableCell className="audit-data-header">DESCRIPTION</TableCell>
                                <TableCell className="audit-data-header ha-logs-header-metadata">METADATA</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="audit-data-header audit-logs-font">
                            {
                                haLogsData.length > 0 ? haLogsData.map((row, idx) =>
                                    <TableRow style={{ background: "white" }} className="fleetRowRank" key={idx}>
                                        <TableCell className="fleetRowDataDetails">{convertDateTimeIntoTimezone(row['ts'], authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                        <TableCell className="fleetRowDataDetails">{row['device_id']}</TableCell>
                                        <TableCell className="fleetRowDataDetails">{row['deviceAlias']}</TableCell>
                                        <TableCell className="fleetRowDataDetails">{row['level']}</TableCell>
                                        <TableCell className="fleetRowDataDetails">{row['deviceAlias']} : {row['description']}</TableCell>
                                        <TableCell align="center" className="fleetRowDataDetails">
                                            <JsonDataTooltip data={row['metadata']} />
                                        </TableCell>
                                    </TableRow>
                                )
                                    :
                                    <TableRow style={
                                        { background: "white" }
                                    }
                                        className="fleetRowRank">
                                        <TableCell colSpan={7} align="center">
                                            No Data Available
                                        </TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer
});

export default withRouter(
    connect(mapStateToProps, {
    })(HaLogData)
);
