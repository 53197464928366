import { Fragment, ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from "react-redux";

import moment from 'moment-timezone';
import _ from 'lodash';

import { Dialog, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Button, Typography, Checkbox, ListItemText, Autocomplete, AutocompleteChangeReason, AutocompleteChangeDetails, Chip, FormHelperText, IconButton, Tooltip, FormControlLabel, ListSubheader } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { AccessNetwork, Action, Actions, Device, DeviceInterface, NotificationMethods, Quota, Site, StarlinkServiceLine, TrafficPolicy, User, WanProfile } from '../types';

import { PeriodWithUnitInput, QuotaPeriodChangeEvent } from '../PeriodWithUnitInput';
import { DataWithUnitInput } from '../DataWithUnitInput';
import AlertingConditions from '../QuotaActions';
import { clearDevicePolicyData, getAccessNetworks, getTrafficPolicies, getWanProfiles, getServiceLineForEdge } from '../slice';
import './index.css';
import { nanoid } from 'nanoid';
import { convertDateTimeIntoTimezone, extractEmails, getTimezoneCity } from '../../../utils/util';

import teamsWebhookHelpDoc from "../../../asset/user_guide/TeamsWebhookCreateRemove.pdf";
import close from "../../../asset/image/close.png";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from '../../../utils/constants';

interface QuotaConfigDialogProps {
    authReducer: any;
    heading: string;
    devices: Device[];
    users: User[];
    open: boolean;
    quota: Quota | null;
    accessNetworks: AccessNetwork[];
    wanProfiles?: WanProfile[];
    trafficPolicies?: TrafficPolicy[];
    starlinkServiceLines: StarlinkServiceLine[];
    onSave: (siteId: string, deviceId: string, quota: Quota, newVal: boolean) => void;
    onCancel: () => void;
    getAccessNetworks: (params: { deviceId: string }) => void;
    getWanProfiles: (params: { deviceId: string }) => void;
    getTrafficPolicies: (params: { deviceId: string }) => void;
    getServiceLineForEdge: (params: { site_id: string, device_id: string }) => void;
    clearDevicePolicyData: () => void;
}

function QuotaConfigDialog(props: QuotaConfigDialogProps) {
    const { authReducer, heading, open, devices, users, quota, accessNetworks, wanProfiles, trafficPolicies, starlinkServiceLines, onSave, onCancel, getAccessNetworks, getWanProfiles, getTrafficPolicies, getServiceLineForEdge, clearDevicePolicyData } = props;

    const [selectedDevice, setSelectedDevice] = useState<Device | undefined>(quota != null ? devices.find(d => d.id === quota.device_id) : undefined);
    const [deviceInterface, setDeviceInterface] = useState<string[]>(quota != null && quota?.wan_links ? quota.wan_links : []);
    const [accessNetworkIds, setAccessNetworkIds] = useState<string[]>(quota != null && quota?.access_networks ? quota.access_networks : []);
    const [usersToNotify, setUsersToNotify] = useState<User[]>(quota != null && quota?.users_to_notify ? quota.users_to_notify : []);
    const [quotaLimit, setQuotaLimit] = useState<number>(quota != null ? quota.limit : 107374182400);
    const [quotaPeriod, setQuotaPeriod] = useState<number>(quota != null ? quota.period : 1);
    const [quotaPeriodUnit, setQuotaPeriodUnit] = useState<string>(quota != null ? quota.period_unit : 'D');
    const [quotaStartDate, setQuotaStartDate] = useState(quota != null ? moment(new Date(moment(quota?.start_time).valueOf())) : moment(Date.now()));
    const [notificationMethods, setNotificationMethods] = useState<string[]>(quota != null && quota.notification_methods ? quota.notification_methods : []);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [actions, setActions] = useState<Action[]>(quota != null && quota?.actions ? quota.actions.filter(ac => !ac.revert) : [])
    const [revertActions, setRevertActions] = useState<Action[]>(quota != null && quota?.actions ? quota.actions.filter(ac => ac.revert) : []);
    const [editableRevertActions, setEditableRevertActions] = useState<boolean>(false);
    const [starlinkServiceLineNumbers, setStarlinkServiceLineNumbers] = useState<string[]>(quota != null && quota?.starlink_quota?.service_line_numbers ? quota.starlink_quota.service_line_numbers : []);
    const [trackSelectedDevice, setTrackSelectedDevice] = useState(false);
    const [openTrack, setOpenTrack] = useState(false);
    const [selectedSourceType, setSelectedSourceType] = useState<string>(quota != null ? (quota?.wan_links && quota?.wan_links?.length > 0 ? 'interface' : quota?.access_networks && quota?.access_networks?.length > 0 ? 'access_network' : quota?.starlink_quota?.service_line_numbers && quota?.starlink_quota?.service_line_numbers?.length > 0 ? 'starlink_service_line' : '') : '')

    const handleClose = (value: boolean) => {
        onCancel();
        setTrackSelectedDevice(false);
    };

    const handleSave = () => {
        let site_id = devices.filter(device => device.id === selectedDevice?.id)?.[0]?.site_id
        let device_id = selectedDevice?.id;
        let _quota: any = {
            site_id: site_id,
            device_id: device_id,
            wan_links: deviceInterface,
            access_networks: accessNetworkIds,
            starlink_quota: {
                service_line_numbers: starlinkServiceLineNumbers,
                enabled: !_.isEmpty(starlinkServiceLineNumbers)
            },
            limit: Number(quotaLimit),
            period: Number(quotaPeriod),
            period_unit: quotaPeriodUnit,
            start_time: quotaStartDate.toISOString(),
            actions: [...actions, ...revertActions],
            notification_methods: notificationMethods,
            users_to_notify: usersToNotify
        }
        if (quota != null) {
            _quota.id = quota?.id;
            for (let key of ['site_id', 'device_id', 'wan_links', 'access_networks', 'limit', 'period', 'period_unit', 'actions', 'notification_methods', 'users_to_notify']) {
                if (_.isEqual(quota[key], _quota[key])) {
                    delete _quota[key];
                }
            }
            if (quotaStartDate.isSame(moment(quota.start_time).utc())) {
                delete _quota.start_time;
            }
            site_id = quota?.site_id;
            device_id = quota?.device_id;
        }
        if (device_id && site_id) {
            onSave(site_id, device_id, _quota, quota == null);
        }
    };

    const handleChangeDevice = (event: SyntheticEvent<Element, Event>, value: unknown, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<unknown> | undefined) => {
        if (reason === 'selectOption') {
            let _value = value as Device;
            setSelectedDevice(_value);
        } else if (!value) {
            setSelectedDevice(undefined);
            setTrackSelectedDevice(false);
        }

        setActions([]);
        setDeviceInterface([]);
        setAccessNetworkIds([]);
        setStarlinkServiceLineNumbers([]);
        setSelectedSourceType('');
    };

    const handleChangeSource = (event: SelectChangeEvent<string[]>, child: ReactNode) => {
        const {
            target: { value },
        } = event;
        const _value = typeof value === 'string' ? value.split(',') : value;

        console.log('value', _value)

        if (child) {
            let _child = child as React.ReactElement;
            switch (_child.props['data-source-type']) {
                case 'interface':
                    setDeviceInterface(_value.filter(di => deviceInterfaces.findIndex(d => d.alias === di) !== -1));
                    setStarlinkServiceLineNumbers([]);
                    setAccessNetworkIds([]);
                    break;
                case 'access_network':
                    console.log('access_network', accessNetworks)
                    setAccessNetworkIds(_value.filter(an => accessNetworks?.findIndex(a => a.id === an) !== -1));
                    setDeviceInterface([]);
                    setStarlinkServiceLineNumbers([]);
                    break;
                case 'starlink_service_line':
                    setStarlinkServiceLineNumbers(_value.filter(sl => starlinkServiceLines.findIndex(s => s.service_line_number === sl) !== -1));
                    setDeviceInterface([]);
                    setAccessNetworkIds([]);
                    break;
            }
        }
    };

    const handleQuotaPeriodChange = (event: QuotaPeriodChangeEvent<HTMLInputElement>) => {
        setQuotaPeriod(event.target.value.period)
        setQuotaPeriodUnit(event.target.value.unit)
    }

    const handleQuotaLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuotaLimit(Number(event.target.value))
    }

    const handleStartDateChange = (_startDate: moment.Moment | null) => {
        if (_startDate == null || !_startDate.isValid())
            return;
        setQuotaStartDate(_startDate.seconds(0))
    }

    const handleChangeNotificationMethods = (event: React.ChangeEvent<HTMLInputElement>) => {
        let _value = [...notificationMethods];
        if (event.target.checked) {
            _value.push(event.target.name);
        } else {
            _value = _value.filter(nm => nm !== event.target.name);
        }
        setNotificationMethods(_value);
    };

    const handleChangeUsersToNotify = (event: SyntheticEvent<Element, Event>, value: unknown, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<unknown> | undefined) => {
        if (reason === 'selectOption' || reason === 'removeOption') {
            let _value = value as User[];
            setUsersToNotify(_value);
        } else if (reason === 'createOption') {
            let _users: User[] = [];
            let _value = value as (string | User)[];
            _value.forEach((v: string | User) => {
                if (typeof v === 'string') {
                    let _values: string[] = extractEmails(v)
                    _values.forEach(_v => {
                        let _user = users.find(u => u.email === _v);
                        if (_user) {
                            _users.push(_user);
                        } else {
                            _users.push({ name: _v, email: _v });
                        }
                    })
                } else {
                    _users.push(v);
                }
            });
            setUsersToNotify(_users);
        } else if (reason === 'clear') {
            setUsersToNotify([]);
        }

    };

    const handleChangeActions = (actions: Action[]) => {
        setActions(actions)
    }

    const handleChangeRevertActions = (actions: Action[]) => {
        setRevertActions(actions)
    }

    const deviceInterfaces = useMemo(() => {
        let device = devices.find(d => d.id === selectedDevice?.id);
        if (device) {
            let arr = device.interfaces;
            let key ="alias";
            return Array.from(new Map(arr.map(item =>[item[key], item])).values())
        }
        return [];
    }, [selectedDevice])

    const invalidDeviceInterfaces = useMemo(() => {
        return deviceInterface.filter(di => deviceInterfaces.findIndex(d => d.alias === di) === -1)
    }, [deviceInterface, deviceInterfaces])

    const invalidAccessNetworkIds = useMemo(() => {
        return accessNetworkIds.filter(an => accessNetworks.findIndex(a => a.id === an) === -1)
    }, [accessNetworkIds, accessNetworks])

    const invalidStarlinkServiceLineNumbers = useMemo(() => {
        return starlinkServiceLineNumbers.filter(sn => starlinkServiceLines.findIndex(s => s.service_line_number === sn) === -1)
    }, [starlinkServiceLineNumbers, starlinkServiceLines])

    useEffect(() => {
        if (devices.length > 0) {
            setSelectedDevice(quota != null ? devices.find(d => d.id === quota.device_id) : undefined);
            setDeviceInterface(quota != null && quota.wan_links ? quota.wan_links : []);
            setAccessNetworkIds(quota != null && quota.access_networks ? quota.access_networks : []);
            setStarlinkServiceLineNumbers(quota != null && quota?.starlink_quota?.service_line_numbers ? quota.starlink_quota.service_line_numbers : []);
        }
    }, [devices])

    useEffect(() => {
        clearDevicePolicyData();
        setActions([]);

        let siteId = selectedDevice?.site_id || '';
        let deviceId = selectedDevice?.id || '';
        if (!_.isEmpty(deviceId)) {
            getAccessNetworks({ deviceId: selectedDevice?.id || '' })
            getWanProfiles({ deviceId: selectedDevice?.id || '' })
            getTrafficPolicies({ deviceId: selectedDevice?.id || '' })
        }

        if (!_.isEmpty(siteId) && !_.isEmpty(deviceId)) {
            getServiceLineForEdge({ site_id: siteId, device_id: deviceId })
        }
    }, [selectedDevice])

    useEffect(() => {
        if (open) {
            if (devices.length > 0) {
                setSelectedDevice(quota != null ? devices.find(d => d.id === quota.device_id) : undefined);
                setDeviceInterface(quota != null && quota.wan_links ? quota.wan_links : []);
                setAccessNetworkIds(quota != null && quota.access_networks ? quota.access_networks : []);
                setStarlinkServiceLineNumbers(quota != null && quota?.starlink_quota?.service_line_numbers ? quota.starlink_quota.service_line_numbers : []);
            }
            setQuotaLimit(quota != null ? quota.limit : 100000000000);
            setQuotaPeriod(quota != null ? quota.period : 1);
            setQuotaPeriodUnit(quota != null ? quota.period_unit : 'D');
            setQuotaStartDate(quota != null ? moment(new Date(moment(quota?.start_time).valueOf())) : moment(Date.now()));
            setNotificationMethods(quota != null && quota.notification_methods ? quota.notification_methods : []);
            setUsersToNotify(quota != null && quota?.users_to_notify ? quota.users_to_notify : []);
            setActions(quota != null && quota?.actions ? quota.actions.filter(ac => !ac.revert) : [])
            setRevertActions(quota != null && quota?.actions ? quota.actions.filter(ac => ac.revert) : []);
            setSelectedSourceType(quota != null ? (quota?.wan_links && quota?.wan_links?.length > 0 ? 'interface' : quota?.access_networks && quota?.access_networks?.length > 0 ? 'access_network' : quota?.starlink_quota?.service_line_numbers && quota?.starlink_quota?.service_line_numbers?.length > 0 ? 'starlink_service_line' : '') : '')
        }
    }, [open, quota])

    useEffect(() => {

        if (
            !_.isEmpty(selectedDevice) &&
            (!_.isEmpty(starlinkServiceLineNumbers) || !_.isEmpty(deviceInterface) || !_.isEmpty(accessNetworkIds)) &&
            invalidDeviceInterfaces.length === 0 &&
            invalidAccessNetworkIds.length === 0 &&
            invalidStarlinkServiceLineNumbers.length === 0 &&
            quotaLimit > 0 &&
            quotaPeriod > 0 &&
            (['D', 'W', 'M'].includes(quotaPeriodUnit)) &&
            quotaStartDate.isValid() &&
            (actions.length > 0 ? actions.filter(a => a.valid === false).length === 0 : true) &&
            (notificationMethods.includes("email") ? usersToNotify.length > 0 : true) &&
            (actions.filter(a => a.type === Actions.ActionNotification).length > 0 ? notificationMethods.length > 0 : true)
        ) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [selectedDevice, deviceInterface, accessNetworkIds, starlinkServiceLineNumbers, quotaLimit, quotaPeriod, quotaPeriodUnit, quotaStartDate, actions, notificationMethods, usersToNotify, invalidDeviceInterfaces, invalidAccessNetworkIds, invalidStarlinkServiceLineNumbers])

    useEffect(() => {
        if (quota == null) {
            let _revertActions = identifyRevertActions(actions, accessNetworks, wanProfiles, trafficPolicies);
            setRevertActions(_revertActions);
        }
    }, [actions])

    const availableSourceTypes = useMemo(() => {
        let sourceTypes = new Set<string>();
        if (deviceInterfaces.length > 0) {
            sourceTypes.add('interface');
        }
        if (accessNetworks.length > 0) {
            sourceTypes.add('access_network');
        }
        if (starlinkServiceLines.length > 0) {
            sourceTypes.add('starlink_service_line');
        }
        return Array.from(sourceTypes);
    }, [deviceInterfaces, accessNetworks, starlinkServiceLines])

    const showDualUsageWarning = () => {
        return starlinkServiceLineNumbers.length > 0 && deviceInterface.some(di => deviceInterfaces.find(i => i.alias == di && i.wan_type == 'starlink')) ? <Typography className='starlink-dual-message'>Warning: Double counting of starlink usage.</Typography> : null
    }

    const handleOpenInterfaces = () => {
        if (selectedDevice) {
            setOpenTrack(true);
        } else {
            setTrackSelectedDevice(true);
            setOpenTrack(false);
        }
    }

    const handleCloseInterfaces = () => {
        if (selectedDevice) {
            setOpenTrack(false);
        } else {
            setTrackSelectedDevice(false);
        }
    }

    const renderSelectedSource = (selected) => {
        if (selectedSourceType === 'interface') {
            return deviceInterfaces.filter(d => selected.indexOf(d.alias) > -1).map(d => d.alias).join(', ')
        } else if (selectedSourceType === 'access_network') {
            return accessNetworks.filter(a => selected.indexOf(a.id) > -1).map(a => a.name).join(', ')
        } else if (selectedSourceType === 'starlink_service_line') {
            return starlinkServiceLines.filter(s => selected.indexOf(s.service_line_number) > -1).map(s => s.nickname).join(', ')
        }
        return null;
    }

    const dataSourceNames = {
        'interface': 'WAN Interface',
        'access_network': 'Access Network',
        'starlink_service_line': 'Starlink Service Line'
    }

    const handleChangeSourceType = (event) => {
        setSelectedSourceType(event.target.value)
        setDeviceInterface([]);
        setAccessNetworkIds([]);
        setStarlinkServiceLineNumbers([]);
    }

    return (
        <Dialog className="menuScroll" onClose={handleClose} open={open} maxWidth="lg" fullWidth>
            <div className="quota_management--base--margin_1x quota_management--base--font_1x">
                <div>
                    {heading}
                </div>
                <div className='margin-left-auto'>  <img
                    src={close}
                    alt='close'
                    onClick={() => { handleClose(false) }}
                    className='close-btn-ht-wd'/> </div>
            </div>
            <div className="quota_management--base--margin_1x">
                <Typography className="quota_management--base--pb_1x" variant="body2" color="text.secondary">
                    Data Usage Source
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            id="select-device"
                            size='small'
                            value={selectedDevice}
                            onChange={handleChangeDevice}
                            options={devices}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="Device" variant='outlined' />}
                            renderOption={(props, option) => <li {...props} key={option.id}>{option.name}</li>}
                            placeholder='Select Device'
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth size='small' className='form-parent'>
                            <InputLabel id="select-source-type-label">Data Usage Type</InputLabel>
                            <Select
                                className='select-parent'
                                labelId="select-source-type-label"
                                id="select-source-type"
                                value={selectedSourceType}
                                label="Data Usage Type"
                                onChange={handleChangeSourceType}
                            >
                                {
                                    availableSourceTypes.map((st) => <MenuItem key={st} value={st}>{dataSourceNames[st]}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth size='small' error={invalidDeviceInterfaces?.length > 0 || invalidAccessNetworkIds?.length > 0 || invalidStarlinkServiceLineNumbers?.length > 0} className='form-parent'>
                            <InputLabel id="select-interfaces-label">Source</InputLabel>
                            <Select
                                className='select-parent'
                                labelId="select-interfaces-label"
                                id="select-interfaces"
                                multiple={true}
                                value={[...deviceInterface, ...accessNetworkIds, ...starlinkServiceLineNumbers]}
                                label="Source"
                                onChange={handleChangeSource}
                                renderValue={renderSelectedSource}
                                onOpen={handleOpenInterfaces}
                                open={openTrack}
                                onClose={handleCloseInterfaces}
                            >
                                {
                                    selectedSourceType === 'interface' && deviceInterfaces.map(d => <MenuItem dense={true} key={d.alias} value={d.alias} data-source-type={'interface'}>
                                        <Checkbox size='small' checked={deviceInterface.indexOf(d.alias) > -1} />
                                        <ListItemText primary={d.alias} />
                                    </MenuItem>)
                                }
                                {
                                    invalidDeviceInterfaces?.length > 0 ? invalidDeviceInterfaces.map(alias => <MenuItem key={alias} value={alias}>
                                        <Checkbox size='small' checked={deviceInterface.indexOf(alias) > -1} />
                                        <ListItemText primary={`${alias} <Deleted>`} />
                                    </MenuItem>) : null
                                }
                                {
                                    selectedSourceType === 'access_network' && accessNetworks.map(an => <MenuItem dense={true} key={an.id} value={an.id} data-source-type={'access_network'}>
                                        <Checkbox size='small' checked={an.id !== undefined && accessNetworkIds.indexOf(an.id) > -1} />
                                        <ListItemText primary={an.name} />
                                    </MenuItem>)
                                }
                                {
                                    invalidDeviceInterfaces?.length > 0 ? invalidDeviceInterfaces.map(alias => <MenuItem key={alias} value={alias}>
                                        <Checkbox size='small' checked={deviceInterface.indexOf(alias) > -1} />
                                        <ListItemText primary={`${alias} <Deleted>`} />
                                    </MenuItem>) : null
                                }
                                {
                                    selectedSourceType === 'starlink_service_line' && starlinkServiceLines.filter(s => s.service_line_number).map(d => <MenuItem dense={true} key={d.service_line_number} value={d.service_line_number} data-source-type={'starlink_service_line'}>
                                        <Checkbox size='small' checked={d.service_line_number !== undefined && starlinkServiceLineNumbers.indexOf(d.service_line_number) > -1} />
                                        <ListItemText primary={d.nickname} />
                                    </MenuItem>)
                                }
                                {
                                    invalidStarlinkServiceLineNumbers?.length > 0 ? invalidStarlinkServiceLineNumbers.map(alias => <MenuItem key={alias} value={alias}>
                                        <Checkbox size='small' checked={invalidStarlinkServiceLineNumbers.indexOf(alias) > -1} />
                                        <ListItemText primary={`${alias} <Deleted>`} />
                                    </MenuItem>) : null
                                }
                            </Select>
                            {
                                invalidDeviceInterfaces?.length > 0 ? <FormHelperText>Selected interface ({invalidDeviceInterfaces.join(', ')}) does not exist, please reselect the interfaces</FormHelperText> : null
                            }
                            {
                                invalidAccessNetworkIds?.length > 0 ? <FormHelperText>Selected access network ({invalidAccessNetworkIds.join(', ')}) does not exist, please reselect the access networks</FormHelperText> : null
                            }
                            {
                                invalidStarlinkServiceLineNumbers?.length > 0 ? <FormHelperText>Selected service lines ({invalidStarlinkServiceLineNumbers.join(', ')}) does not exist, please reselect the service lines</FormHelperText> : null
                            }
                            {showDualUsageWarning()}
                        </FormControl>
                        {!selectedDevice && trackSelectedDevice ? <span className='device-length-render-text'>Please select Device</span> : null}
                    </Grid>
                </Grid>
            </div>
            <div className="quota_management--base--margin_1x">
                <Typography className='quota_management--base--pb_1x' variant="body2" color="text.secondary">
                    Quota Configuration
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <PeriodWithUnitInput value={quotaPeriod} unit={quotaPeriodUnit} onChange={handleQuotaPeriodChange} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth sx={{ minWidth: 20 }}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DateTimePicker
                                    label="Quota Start"
                                    value={quotaStartDate}
                                    ampm={false}
                                    timezone={getTimezoneCity(authReducer.userTimezone)}
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                    }}
                                    onChange={handleStartDateChange}
                                />
                            </LocalizationProvider>
                        </FormControl>

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DataWithUnitInput value={quotaLimit} onChange={handleQuotaLimitChange} />
                    </Grid>
                </Grid>
            </div>
            <div className="quota_management--base--margin_1x">
                <Typography className='quota_management--base--pb_1x' variant="body2" color="text.secondary">
                    Quota Action(s)
                </Typography>
                <AlertingConditions deviceInterfaces={deviceInterfaces} value={actions} onChange={handleChangeActions} />
            </div>
            <div className="quota_management--base--margin_1x">
                <Typography className='quota_management--base--pb_1x' variant="body2" color="text.secondary">
                    Quota Action(s) on renewal <IconButton size='small' onClick={() => { setEditableRevertActions(!editableRevertActions) }}>
                        {editableRevertActions ? <Tooltip title="Disable Edit"><EditOffIcon /></Tooltip> : <Tooltip title="Enable Edit"><EditIcon /></Tooltip>}
                    </IconButton>
                </Typography>
                <AlertingConditions deviceInterfaces={deviceInterfaces} value={revertActions} onChange={handleChangeRevertActions} readOnly={!editableRevertActions} forRevert={true} />
            </div>

            <div className="quota_management--base--margin_1x">
                <Typography className='quota_management--base--pb_1x' variant="body2" color="text.secondary">
                    Notification
                </Typography>
                <Grid spacing={2}>
                    <Grid container xs={12}>
                        <Grid item xs={12} md={2} >
                            <Checkbox size='small' name={'email'} checked={notificationMethods.includes("email")} onChange={handleChangeNotificationMethods} />
                            <Typography variant="body2" color="text.secondary" display={'inline'}>
                                Email
                            </Typography>
                        </Grid>
                        {
                            notificationMethods.includes("email") && <Grid item xs={12} md={10}>
                                <FormControl fullWidth error={usersToNotify.length === 0}>
                                    <Autocomplete
                                        id="select-users"
                                        size='small'
                                        multiple={true}
                                        className={notificationMethods.includes("email") ? "" : "disbale-user-selection"}
                                        freeSolo={true}
                                        value={usersToNotify}
                                        onChange={handleChangeUsersToNotify}
                                        options={users}
                                        getOptionLabel={(option) => {
                                            let _option = option as User;
                                            return _option.name !== "" ? _option.name : _option.email
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Send to (via Email)" variant='outlined' error={usersToNotify.length === 0} />}
                                        renderOption={(props, option) => <li {...props} key={nanoid()}>{option.name !== "" ? option.name : option.email}</li>}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip size='small' label={option.name} {...getTagProps({ index })} />
                                            ))
                                        }
                                        placeholder='Send to (via Email)'
                                    />
                                    {
                                        usersToNotify.length === 0 ? <FormHelperText>Please select at least one user/or email</FormHelperText> : null
                                    }
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                    <Grid container xs={12}>
                        <Grid item xs={12} md={2} >
                            <Checkbox size='small' name={'microsoft_teams'} checked={notificationMethods.includes("microsoft_teams")} onChange={handleChangeNotificationMethods} />
                            <Typography variant="body2" color="text.secondary" display={'inline'}>
                                Microsoft Teams
                            </Typography>
                            <IconButton size='small' onClick={() => window.open(teamsWebhookHelpDoc)}>
                                <Tooltip title="Help"><HelpOutlineIcon /></Tooltip>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div className='quota_management--base--flex quota_management--base--margin_1x quota_management--base--flex--justify--end'>
                <Button variant="outlined" className='quota_management--button--cancel' onClick={() => { handleClose(false) }}>Cancel</Button>
                <Button variant="contained" className='quota_management--button--save' onClick={handleSave} disabled={!isValid}>Save</Button>
            </div>
        </Dialog>
    );
}

const identifyRevertActions = (actions: Action[], accessNetworks: AccessNetwork[] | undefined, wanProfiles: WanProfile[] | undefined, trafficPolicies: TrafficPolicy[] | undefined): Action[] => {

    let revertActions: Action[] = [];

    // find affected accessnetwork ids set and interfaces set
    let affectedAccessNetworkIds = new Set<string>();
    let affectedWanProfiles = new Set<string>();
    let affectedTrafficPolicies = new Set<string>();
    let affectedInterfaces = new Set<string>();
    actions.forEach(a => {
        if (a.type === Actions.ActionAssignWanProfile || a.type === Actions.ActionAssignTrafficPolicy) {
            if (a.access_networks) {
                a.access_networks.forEach((an: string) => affectedAccessNetworkIds.add(an));
            }
        }
        if (a.type === Actions.ActionDisableInterface) {
            if (a.interfaces) {
                a.interfaces.forEach((i: string) => affectedInterfaces.add(i));
            }
        }
        if (a.type === Actions.ActionAssignWanProfile || a.type === Actions.ActionAssignTrafficPolicy) {
            a?.access_networks?.forEach((an: string) => {
                let _an = accessNetworks?.find(a => a.id === an);
                if (_an) {
                    if (a.type === Actions.ActionAssignWanProfile) {
                        affectedWanProfiles.add(_an.wan_profile);
                    } else if (a.type === Actions.ActionAssignTrafficPolicy) {
                        affectedTrafficPolicies.add(_an.vlan_aggregate);
                    }
                }
            });
        }
    });

    // create map of wan profile to array of access networks, map of traffic policy to array of access networks
    let wanProfileToAccessNetworks = new Map<string, string[]>();
    let trafficPolicyToAccessNetworks = new Map<string, string[]>();
    if (accessNetworks) {
        accessNetworks.forEach(an => {
            if (affectedWanProfiles.has(an.wan_profile)) {
                if (wanProfileToAccessNetworks.has(an.wan_profile)) {
                    wanProfileToAccessNetworks.get(an.wan_profile)?.push(an.id);
                } else {
                    wanProfileToAccessNetworks.set(an.wan_profile, [an.id]);
                }
            }
            if (affectedTrafficPolicies.has(an.vlan_aggregate)) {
                if (trafficPolicyToAccessNetworks.has(an.vlan_aggregate)) {
                    trafficPolicyToAccessNetworks.get(an.vlan_aggregate)?.push(an.id);
                } else {
                    trafficPolicyToAccessNetworks.set(an.vlan_aggregate, [an.id]);
                }
            }
        });
    }
    // create actions to assign wan profile
    wanProfileToAccessNetworks.forEach((ans, wp) => {
        revertActions.push({
            id: nanoid(),
            usage_percentage: 0,
            type: Actions.ActionAssignWanProfile,
            access_networks: ans,
            wan_profile_id: wp,
            executed: false,
            revert: true
        });
    });

    // create actions to assign traffic policy
    trafficPolicyToAccessNetworks.forEach((ans, tp) => {
        revertActions.push({
            id: nanoid(),
            usage_percentage: 0,
            type: Actions.ActionAssignTrafficPolicy,
            access_networks: ans,
            traffic_policy_id: tp,
            executed: false,
            revert: true
        });
    });

    // create actions to enable interfaces
    if (affectedInterfaces.size > 0) {
        revertActions.push({
            id: nanoid(),
            usage_percentage: 0,
            type: Actions.ActionEnableInterfaces,
            interfaces: Array.from(affectedInterfaces),
            executed: false,
            revert: true
        });
    }

    return revertActions;
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    accessNetworks: state.quotaManager.accessNetworks,
    wanProfiles: state.quotaManager.wanProfiles,
    trafficPolicies: state.quotaManager.trafficPolicies,
    starlinkServiceLines: state.quotaManager.starlinkServiceLines
});

export default connect(mapStateToProps, { getAccessNetworks, getWanProfiles, getTrafficPolicies, getServiceLineForEdge, clearDevicePolicyData })(QuotaConfigDialog);