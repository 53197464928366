import { useLayoutEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Box, IconButton, Modal, Paper, SxProps, Theme } from "@mui/material";
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import CloseIcon from '@mui/icons-material/Close';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'
import './ReleaseNote.css';

const style: SxProps<Theme> = {
    position: 'absolute',
    top: '5%',
    bottom: '10%',
    right: '3%',
    width: '50%',
    bgcolor: 'background.paper',
    overflowY: 'scroll',
    border: '1px solid rgba(126, 126, 126, 0.8)',
};

const headerStyle: SxProps<Theme> = {
    position: 'sticky',
    top: 0,
    background: '#2860B4',
    color: '#fff',
    fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #D9D9D9',
    padding: '5px 10px 5px 24px'
}

const bodyStyle: SxProps<Theme> = {
    display: 'flex',
    margin: '0.5em 0.5em 0.5em 24px',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    fontSize: '14px',
    fontFamily: 'Roboto'
}

const ReleaseNote = (props: any) => {
    const [releaseNotes, setReleaseNotes] = useState<string>('');
    const [open, setOpen] = useState(false);

    useLayoutEffect(() => {
        fetch('/readme.md').then(res => res.text()).then((data) => {
            if (data) {
                setReleaseNotes(data);
            }
        })
    }, []);

    const onClose = () => {
        setOpen(false);
    }

    return (
        <div>
            <IconButton
                onClick={() => setOpen(true)}
                sx={{padding: '0.16px 4px'}}
            >
                <NewReleasesIcon className="releaseNoteIcon" />
            </IconButton>
            <Modal open={open} disableScrollLock disableAutoFocus>
                <Paper sx={style}>
                    <Box sx={headerStyle}>
                        <h4>RELEASE NOTES</h4>
                        <IconButton onClick={onClose}>
                            <CloseIcon className="color-white"/>
                        </IconButton>
                    </Box>
                    <Box sx={bodyStyle}>
                        <ReactMarkdown rehypePlugins={[rehypeRaw]} className="markdown">{releaseNotes}</ReactMarkdown>
                    </Box>
                </Paper>
            </Modal>
        </div>
    );
};

export default withRouter(
    (ReleaseNote)
);