import _ from "lodash";
import { api } from "../../server/request";
import { handleError, handleResponse } from "../../actions/responseUtil";
import { KAPTUREV2_URL_INV } from "../../config";

const ACTION_TYPES = {
    GET_TOS_ACCEPTED: "get_tos_accepted",
    SET_LOADING: "starlinkAdmin/setLoading",
    ERROR_VALIDATION:"error_in_validation"
}

const initialState = {
    tosAccepted: false,
    validateTosLoading:false,
    errorInTosValidation:false,

}

export function starlinkToSReducer(state: any = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.GET_TOS_ACCEPTED:
            return {
                ...state,
                tosAccepted: action.payload,
            };
        case ACTION_TYPES.SET_LOADING: 
            return {
                ...state,
                ...action.payload
            };
        case ACTION_TYPES.ERROR_VALIDATION: 
            return {
                ...state,
                ...action.payload
            };  

        default:
            return state
    }
}

export const validateTosAcceptance = (params) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { validateTosLoading: true } });
    dispatch({ type: ACTION_TYPES.ERROR_VALIDATION, payload: { errorInTosValidation: false } });
    const data = {
        dpId: params.dpId,
    }

    try {
        let res = await api.starlink.post(`/agreement/validateTos`, data)
            .then((res) => {
            dispatch({ type: ACTION_TYPES.GET_TOS_ACCEPTED, payload: (res as { tos: boolean }).tos });
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { validateTosLoading: false } });
        })
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { validateTosLoading: false } });
        dispatch({ type: ACTION_TYPES.ERROR_VALIDATION, payload: { errorInTosValidation: true } });
        return null;
    }
}

export const acceptTerms = (params) => async (dispatch) => {
    const data = {
        dpId: params?.dpId,
        acceptorSignature: params?.acceptorSignature,
        loggedInUser: params?.loggedInUser,
        tosAccepted: params?.tosAccepted,
        acceptorEmail:params?.acceptorEmail,
        createdAt:params?.createdAt
    }

    try {
        let res = await api.starlink.post(`/agreement/acceptTos`, data).then((res) => {
            dispatch({ type: ACTION_TYPES.GET_TOS_ACCEPTED, payload: { res } });
        })
        return res;
    } catch (error) {
        return null;
    }
}



// export const clearComponentData = () => (dispatch) => {
//     dispatch({ type: ACTION_TYPES.GET_SERVICE_ACCOUNTS, payload: { data: { rows: [[[], 0]] } } })
// }