import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import ReportsCard from "../../ReportsCard";
import { connect, useDispatch } from 'react-redux';
import { getUsageWidgets } from "../../../actions/Users/authenticateUsageReports";
import { withRouter } from "react-router-dom";
import _ from "lodash";

const TopWidget = (props) => {
    const { UsageWidgetsData, getVesselsListing } = props;
    const [topWidgetData, setTopWidgetData] = useState<any>([{}, {}, {}, {}, {}]);

    useEffect(() => {
        if (getVesselsListing?.locations && getVesselsListing?.locations?.length == 0) {
            setTopWidgetData([{}, {}, {}, {}, {}]);
            return
        }
        if (!_.isEmpty(UsageWidgetsData)) {
            const data = UsageWidgetsData?.hasOwnProperty('data') ? UsageWidgetsData?.data : {};
            if (data) {
                let cols: any = [];
                let result: any = [];
                let kpiarr: any = ['totalUsage', "topSite", 'topUser', 'topApp', 'topBlocked']
                for (var key in data) {
                    cols.push(key);
                }
                kpiarr && kpiarr.forEach((kpi, i) => {
                    let rowObj: any = {};
                    cols.forEach((ele) => {
                        if (data?.kpi?.data[i] == kpi) {
                            if (ele == "kpi") {
                                rowObj.kpi = kpi;
                            } else {
                                rowObj[ele] = data[ele].data[i]
                            }
                        }
                        else {
                            if (ele == "kpi") {
                                rowObj.kpi = kpi;
                            } else {
                                rowObj[ele] = null;
                            }
                        }
                    })
                    result.push(rowObj);
                })
                setTopWidgetData(result);
            }
        }
    }, [UsageWidgetsData, getVesselsListing])

    return topWidgetData?.map((card, i) => (
        <Grid key={i} item xs={12} sm={4} md={2} lg={2}>
            <ReportsCard widgetData={card} />
        </Grid>
    ))
}

const mapStateToProps = (state) => ({
    UsageWidgetsData: state.authReducer?.UsageWidgetsData,
    getVesselsListing: state?.authReducer?.getVesselsListing
});

export default withRouter(
    connect(mapStateToProps, {
    })(TopWidget)
);