// Alert page for vessels
import { useState, useEffect } from "react";
import { Grid, Button, Box } from "@mui/material";
import { withRouter, Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { InputLabel } from '@mui/material';
import _ from "lodash";
import "./index.css";
import ColorTabs from "../../NewComponents/Tabs/Tabs";
import SearchBar from "../../NewComponents/SearchBar/SearchBar";
import AccordionTree from "../../NewComponents/AccordionTree";
import { deleteAlerts, deleteAlertsRule, getAlerts,getAlertsLabel } from "./slice";
import { useDispatch } from 'react-redux';
import AccordianMenuComponent from "../../NewComponents/AccordionTree/AccordianMenuComponent"
import StateHistoryDialog from "../../NewComponents/StateHistoryDialog";
import {Pagination,RowPerPageHandler} from "../../components/Pagination";
import Paper from "@mui/material/Paper";
import { getDecodeURI, redirectToTopolgyTabs } from "../../utils/util";


const AlertDashboard = (props) => {

    // const dispatch = useDispatch();

    const {
        siteId,
        siteName,
        alerts,
        getAlerts,
        getAlertsLabel,
        alertCountDetails,
        alertLabels,
        deleteAlerts,
        alertDeleteResponse,
        deleteAlertsRule,
        deleteAlertRuleResponse,
    } = props;
    const location = useLocation();
    const _q = getDecodeURI(location?.search);
    const [alertStatusFilert, setAlertStatusFiletr] = useState<any>(false);
    const [openAccordianMenu, setOpenAccordianMenu] = useState(false)
    const [menuAnchorElement, setMenuAnchorElement] = useState(0)
    const [openStatusHistoryDialog, setOpenStatusHistoryDialog] = useState(false)
    const [alertLabelValue , setAlertLabelValues] = useState([]);
    const [alertLabelValueList, setAlertLabelValueList] = useState<any>([]);
    const [selectedLabelKey, setSelectedLabelKey] = useState<any>("");
    const [selectedIdForHistory, setSelctedIdForHistory] = useState<any>("");
    const [selectedRuleIdForHostory, setSelctedRuleIdForHistory] = useState<any>("");
    const [rowsPerPage,setRowsPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [selectedAlertId, setSelectedAlertId] = useState(_q?.alert_id?_q.alert_id:"");


    useEffect(() => {
        if(!_.isEmpty(siteId)){
            getAlerts(siteId,alertStatusFilert,selectedLabelKey,pageNumber,rowsPerPage,selectedAlertId)
        }
    }, [alertStatusFilert,selectedLabelKey,pageNumber,rowsPerPage,selectedAlertId]);

    const labelKeyList = alertLabels ? Object.keys(alertLabels) : "No Data";
    const tabList = ["Alerting","Normal","Pending"];

    const handleTabs = (selectedTab) => {
        let indexNum = parseInt(selectedTab)
        setAlertStatusFiletr(tabList[indexNum]);
    }

    const onSetMenuAnchorElement = (currentTarget)=>{
        setMenuAnchorElement(currentTarget)
    }

    const handleKeySerach = (key) => {
        setSelectedLabelKey(key);
    }

    const handleValueSerach = (value) => {
        setAlertLabelValues(value);
    }

    const handleOpenAccordion = (val,alertId, ruleId) => {
        setOpenAccordianMenu(val);
        setSelctedIdForHistory(alertId)
        setSelctedRuleIdForHistory(ruleId)
    }

    const handleShowStatusHitory = (val, alertId,ruleId) => {
        setOpenStatusHistoryDialog(val);
        if(!val) {
            setSelctedIdForHistory(alertId)
            setSelctedRuleIdForHistory(ruleId)
        }
    }

    const handleDeleteAccordion = () => {
        if(siteId && selectedIdForHistory && selectedRuleIdForHostory) {
           deleteAlertsRule(siteId,selectedIdForHistory,selectedRuleIdForHostory)
        } else {
            deleteAlerts(siteId,selectedIdForHistory)
        }
    }

    useEffect(() => {
        if(alertDeleteResponse == "alert deleted successfully") {
            getAlerts(siteId,alertStatusFilert,selectedLabelKey, pageNumber, rowsPerPage,"")
        }
    },[alertDeleteResponse])

    useEffect(() => {
        if(deleteAlertRuleResponse?.name) {
            getAlerts(siteId,alertStatusFilert,selectedLabelKey, pageNumber, rowsPerPage,"")
        }
    }, [deleteAlertRuleResponse])

    const handleChangeRowsPerPage: RowPerPageHandler = (
        event,
    ) => {
        const value = event.target.value;
        let limit: number;
        if (typeof value == 'string') {
            limit = parseInt(value, 10)
        } else {
            limit = value;
        }
        setRowsPerPage(limit);
        setPageNumber(1);
    };

    const handleChangePage = (
        event: React.SyntheticEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPageNumber(newPage);
    };

    const rowsOption = [10, 15, 25, 50, 100];
    
    return (
        <>
        <div className="AlertsContainer alert-config-scroll">
            <Grid container >
                <Grid  item xs={12} sm={12} md={12} lg={12} className="alertsFilterContainer">
                     <Grid item container xs={12} className="alertsFilterContainerBox">
                        {/* <InputLabel className="alertslabel">Search by Label</InputLabel> */}
                        <SearchBar handleSerach={handleKeySerach}></SearchBar>
                        {/* <InputLabel className="alertslabel">State</InputLabel> */}
                        {/* <ColorTabs tabList={tabList} handleTabs={handleTabs} defaultValue={false}></ColorTabs> */}
                        <Link to={redirectToTopolgyTabs(location,"/fleet-dashboard/sites/alerts/create-alert",siteId,siteName,{},false,true)} style={{textDecoration: 'none'}}>
                            <Button className="alertButton" variant="contained" size="small">Add Alert</Button>
                        </Link>
                    </Grid>
                </Grid>

                {/* Alert Counts */}
               {alertCountDetails && Object.keys(alertCountDetails).length > 0  && <Grid item xs={12} sm={12} md={12} lg={12} className="alertsCount">
                    <div>{alertCountDetails?.Total} alerts: <span className="firingSpan">{alertCountDetails?.Alerting} Alerting</span>, {alertCountDetails?.Normal} Normal</div>
                </Grid>}
                <Paper className="pagination-position alert_dashboard_pagination">
               <Pagination
                   count={alertCountDetails?.Total? alertCountDetails?.Total: 0 }
                   rowsPerPageOptions={rowsOption}
                   rowsPerPage={rowsPerPage}
                   page={pageNumber}
                   onPageChange={handleChangePage}
                   onRowsPerPageChange={handleChangeRowsPerPage}
               ></Pagination>
           </Paper>

                <Grid  className="accordionContainer">
                    <AccordionTree siteId={siteId} openAccordianMenu={handleOpenAccordion} setMenuAnchorElement={onSetMenuAnchorElement}></AccordionTree>
                </Grid>
            </Grid>
            <AccordianMenuComponent open={openAccordianMenu}  setOpenAccordianMenu={setOpenAccordianMenu} menuAnchorElement={menuAnchorElement} setShowStatusHistory={handleShowStatusHitory} deleteAccordion={handleDeleteAccordion}></AccordianMenuComponent>
            {openStatusHistoryDialog && <StateHistoryDialog 
                open={openStatusHistoryDialog} 
                setOpenDialogState={handleShowStatusHitory} 
                selectedIdForHistory={selectedIdForHistory}
                siteId={siteId}
                selectedRuleIdForHostory={selectedRuleIdForHostory}
            ></StateHistoryDialog>}
 
        </div >
        </>
    );

}

const mapStateToProps = (state) => ({
    alerts: state.alertsDashboard.alerts,
    alertCountDetails: state.alertsDashboard.alertCountDetails,
    alertLabels: state.alertsDashboard.alertLabels,
    alertDeleteResponse: state?.alertsDashboard?.alertDeleteResponse,
    deleteAlertRuleResponse: state?.alertsDashboard?.deleteAlertRuleResponse
});

export default withRouter(
    connect(mapStateToProps, {
        getAlerts,
        getAlertsLabel,
        deleteAlerts,
        deleteAlertsRule
    })(AlertDashboard)
);