import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Box, Button, Grid, Menu } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import TuneIcon from '@mui/icons-material/Tune';
import close from "../../asset/image/close.svg";
import { getDecodeURI, getEncodedURI, getIntervalCheck } from "../../utils/util";
import { getContentFilterTopUsersTable,
    getContentFilterTopUsersTableCount,
    getContentFilterTopSitesTable,
    getContentFilterTopSitesTableCount,
    getContentFilterCategoryPie,
    getContentFilterApplicationPie,
    getContentFilterSubCategoryPie,
    getContentFilterTopWidgetTopSite,
    getContentFilterTopWidgetTopUser,
    getContentFilterTopWidgetTopCategory,
    getContentFilterTopWidgetTopDomain,
    getContentFilterLinechart,
    getContentFilterCategoryList,
    getContentFilterAppList,
    getContentFilterUserList,
    getContentFilterVlanList,
    getContentFilterDeviceList,
    getContentFilterSubCategoryList
} from '../../actions/Users/authenticateContentFilter';
import _ from "lodash";
import TopUsersTable from "./TopUsersTable";
import TopSitesTable from "./TopSitesTable";
import TopWidget from "./TopWidget";
import PieChart from "./PieChart";
import ChipFilters from "./ChipFilters";
import './contentFilter.css';
import LineChart from "./LineChart";
import CustomLoader from "../Loader/CustomLoader";
import FilterPopup from "./FilterPopup";
import BottomCards from './BottomCards';
import Download from '../../asset/image/DownloadUsage.svg';
import GenerateReportPopup from "./GenerateReportPopup";
import Reports from "../../UserScreen/UserData/Reports";

const ContentFilter = (props) => {
    const { authReducer,
        getContentFilterTopUsersTable,
        getContentFilterTopUsersTableCount,
        getContentFilterTopSitesTable,
        getContentFilterTopSitesTableCount,
        getContentFilterCategoryPie,
        getContentFilterApplicationPie,
        getContentFilterSubCategoryPie,
        getContentFilterTopWidgetTopSite,
        getContentFilterTopWidgetTopUser,
        getContentFilterTopWidgetTopCategory,
        getContentFilterTopWidgetTopDomain,
        getContentFilterLinechart,
        getContentFilterCategoryList,
        getContentFilterAppList,
        getContentFilterUserList,
        getContentFilterVlanList,
        getContentFilterDeviceList,
        getContentFilterSubCategoryList,
        newSummaryStartDate,
        newSummaryEndDate,
        location, history 
    } = props;

    const _q = getDecodeURI(location?.search);
    const DEFAULT_PAGE_SIZE = 10;
    const [filterPopup, setFilterPopup] = useState(false);
    const [vessels, setVessels] = useState([]);
    const [category, setCategory] = useState(_q.hasOwnProperty('category') ? decodeURIComponent(_q.category) : '');
    const [app, setApp] = useState(_q.hasOwnProperty('app') ? _q.app : '');
    const [user, setUser] = useState(_q.hasOwnProperty('user') ? _q.user : '');
    const [vlan, setVlan] = useState(_q.hasOwnProperty('vlan') ? _q.vlan : '');
    const [device, setDevice] = useState(_q.hasOwnProperty('device') ? _q.device : '');
    const [subCategory, setSubCategory] = useState(_q.hasOwnProperty('subCat') ? _q.subCat : '');
    const [page, setPage] = useState(_q.hasOwnProperty('page') ? parseInt(_q.page) : 1);
    const [limit, setLimit] = useState(_q.hasOwnProperty('limit') ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const [pageStart, setPageStart] = useState(_q.hasOwnProperty('pageStart') ? parseInt(_q.pageStart) : 1);
    const [pageLimit, setPageLimit] = useState(_q.hasOwnProperty('pageLimit') ? parseInt(_q.pageLimit) : DEFAULT_PAGE_SIZE);
    const USER_HITS = 'User_hits';
    const DESC_ORDER = "desc";
    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : USER_HITS);
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER);
    const TOTAL_HITS = 'total_hits';
    const [field, setField] = useState(_q.hasOwnProperty('field') ? _q.field : TOTAL_HITS)
    const [order, setOrder] = useState(_q.hasOwnProperty('order') ? _q.order : DESC_ORDER)
    const [trackApplyFilter, setTrackApplyFilter] = useState(_q.hasOwnProperty('category') || _q.hasOwnProperty('app') || _q.hasOwnProperty('user') || _q.hasOwnProperty('vlan') || _q.hasOwnProperty('device') || _q.hasOwnProperty('subCat') ? true : false);
    const [pieChartLimit, setPieChartLimit] = useState(_q.hasOwnProperty('pieLimit') ? _q.pieLimit : 10);
    const [tableLimit, setTableLimit] = useState(_q.hasOwnProperty('tableLimit') ? _q.tableLimit : 10);
    const [trackDataLimit, setTrackDataLimit] = useState(false);
    const [anchorElRef, setAnchorElRef] = useState<HTMLElement>();
    const getInterval = (minutes) => {
        if (minutes <= 1440) {
          return "10m";
        } else if (minutes > 1440 && minutes <= 10080) {
          return "1h";
        } else {
          return "4h";
        }
    }
    const [selectedBin, setSelectedBin] = useState(getInterval(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)));
    const [generateFilter, setGenerateFilter] = useState(false);
    const [confirmPopup, setConfirmPopup] = useState(false);

    useEffect(() => {
        if(trackDataLimit && vessels.length > 0) {
            if(pieChartLimit > tableLimit) {
                getContentFilterCategoryPie(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, subCategory, pieChartLimit);
                getContentFilterApplicationPie(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, subCategory, pieChartLimit);
                getContentFilterSubCategoryPie(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, subCategory, pieChartLimit);
            } else if(pieChartLimit == tableLimit) {
                getContentFilterCategoryPie(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, subCategory, pieChartLimit);
                getContentFilterApplicationPie(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, subCategory, pieChartLimit);
                getContentFilterSubCategoryPie(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, subCategory, pieChartLimit);
            } else if(pieChartLimit < tableLimit) {
                getContentFilterCategoryPie(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, subCategory, tableLimit);
                getContentFilterApplicationPie(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, subCategory, tableLimit);
                getContentFilterSubCategoryPie(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, subCategory, tableLimit);
            }
        }
    }, [pieChartLimit, tableLimit])

    const values = { vessels, category, app, user, vlan, device, subCategory, page, limit, sort, sortOrder, pieChartLimit, trackApplyFilter, field, order, pageStart, pageLimit, tableLimit, selectedBin }
    const setValues = { setVessels, setCategory, setApp, setUser, setVlan, setDevice, setSubCategory, setPage, setLimit, setSort, setSortOrder, setPieChartLimit, setTrackApplyFilter, setField, setOrder, setPageStart, setPageLimit, setTableLimit, setTrackDataLimit, setSelectedBin };

    const contentFilterApiCall = (start, end, k4Ids, Category, App, User, Vlan, Device, SubCat, Page, Limit, PageStart, PageLimit, sortcolumn, sortOrd, Field, Order, _selectedBin) => {
        const Interval = _selectedBin;
        let dataLimit;
        pieChartLimit > tableLimit ? dataLimit = pieChartLimit : pieChartLimit < tableLimit ? dataLimit = tableLimit : dataLimit = pieChartLimit;
        if (k4Ids.length > 0) {
            getContentFilterTopUsersTable(start, end, k4Ids, Category, App, User, Vlan, Device, SubCat, Page, Limit, sortcolumn, sortOrd);
            getContentFilterTopUsersTableCount(start, end, k4Ids, Category, App, User, Vlan, Device, SubCat);
            getContentFilterTopSitesTable(start, end, k4Ids, Category, App, User, Vlan, Device, SubCat, PageStart, PageLimit, Field, Order);
            getContentFilterTopSitesTableCount(start, end, k4Ids, Category, App, User, Vlan, Device, SubCat);
            getContentFilterCategoryPie(start, end, k4Ids, Category, App, User, Vlan, Device, SubCat, dataLimit);
            getContentFilterApplicationPie(start, end, k4Ids, Category, App, User, Vlan, Device, SubCat, dataLimit);
            getContentFilterSubCategoryPie(start, end, k4Ids, Category, App, User, Vlan, Device, SubCat, dataLimit);
            getContentFilterLinechart(start, end, k4Ids, Category, App, User, Vlan, Device, SubCat, Interval);
            getContentFilterTopWidgetTopSite(start, end, k4Ids, Category, App, User, Vlan, Device, SubCat);
            getContentFilterTopWidgetTopUser(start, end, k4Ids, Category, App, User, Vlan, Device, SubCat);
            getContentFilterTopWidgetTopCategory(start, end, k4Ids, Category, App, User, Vlan, Device, SubCat);
            getContentFilterTopWidgetTopDomain(start, end, k4Ids, Category, App, User, Vlan, Device, SubCat);
        }
    }

    useEffect(() => {
        if (_.isEmpty(authReducer?.getVesselsListing))
            return;
        let Vessels = [];
        const filteredVessels = authReducer?.filteredVessels;
        if (filteredVessels && filteredVessels.length > 0) {
            Vessels = filteredVessels;
        } else {
            Vessels = authReducer?.getVesselsListing?.locations;
        }
        setVessels(Vessels);
        setPage(1);
        setPageStart(1);
    },[authReducer?.getVesselsListing, authReducer?.filteredVessels])

    useEffect(() => {
        // if(_q.isFixedIntervalDisabled) {
        //     localStorage.setItem("intraUrl","http://sqlapi.dev.svc.cluster.local:8080/store/ch/query")
        // } else {
        //     localStorage.setItem("intraUrl","")
        // }
        const checkInterval = getIntervalCheck(Math.floor(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3));
        if(vessels.length > 1 && checkInterval && !_q.isFixedIntervalDisabled) {
            setConfirmPopup(true);
        }
        if(vessels.length > 0 && vessels?.length == authReducer?.getVesselsListing?.locations?.length && !checkInterval) {
              contentFilterApiCall(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, subCategory, page, limit, pageStart, pageLimit, sort, sortOrder, field, order, selectedBin);
          } else if(vessels.length == 1) {
            contentFilterApiCall(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, subCategory, page, limit, pageStart, pageLimit, sort, sortOrder, field, order, selectedBin);
          } else if(vessels.length > 0 && !checkInterval) {
            contentFilterApiCall(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, subCategory, page, limit, pageStart, pageLimit, sort, sortOrder, field, order, selectedBin);
          } else if(vessels.length > 0 && _q.isFixedIntervalDisabled) {
            contentFilterApiCall(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, subCategory, page, limit, pageStart, pageLimit, sort, sortOrder, field, order, selectedBin);
        }
    }, [vessels])

    useEffect(() => {
        const _interval = getInterval(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3));
        const checkInterval = getIntervalCheck(Math.floor(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3));
        setSelectedBin(_interval);
        if(vessels.length > 1) {
            !_q.isFixedIntervalDisabled && setConfirmPopup(checkInterval);
        }
        if(vessels.length > 0 && vessels?.length == authReducer?.getVesselsListing?.locations?.length && !checkInterval) {
              contentFilterApiCall(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, subCategory, page, limit, pageStart, pageLimit, sort, sortOrder, field, order, _interval);
          } else if(vessels.length == 1) {
            contentFilterApiCall(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, subCategory, page, limit, pageStart, pageLimit, sort, sortOrder, field, order, _interval);
          } else if(vessels.length > 0 && !checkInterval) {
            contentFilterApiCall(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, subCategory, page, limit, pageStart, pageLimit, sort, sortOrder, field, order, _interval);
          } else if(vessels.length > 0 && _q.isFixedIntervalDisabled) {
            contentFilterApiCall(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, subCategory, page, limit, pageStart, pageLimit, sort, sortOrder, field, order, _interval);
          }    
    }, [newSummaryStartDate, newSummaryEndDate])

    const applyFilterClick = (start, end, k4Ids, Cat, App, User, Vlan, Device, SubCat, filter) => {
        let params: any = getDecodeURI(location.search);
        params.startDate = start.valueOf();
        params.endDate = end.valueOf();
        delete params.page;
        k4Ids.length == 1 ? params.k4Ids = `${k4Ids[0].id}:${k4Ids[0]['vessel-name']}` : delete params.k4Ids;
        Cat ? params.category = encodeURIComponent(Cat) : delete params.category;
        App ? params.app = App : delete params.app;
        User ? params.user = User : delete params.user;
        Vlan ? params.vlan = Vlan : delete params.vlan;
        Device ? params.device = Device : delete params.device;
        SubCat ? params.subCat = SubCat : delete params.subCat;
        const Page = 1;
        const PageStart = 1;
        setPage(1);
        setPageStart(1);
        doNavigate(params);
        !filter && contentFilterApiCall(start, end, k4Ids, Cat, App, User, Vlan, Device, SubCat, Page, limit, PageStart, pageLimit, sort, sortOrder, field, order, selectedBin);
        setTrackApplyFilter(true);
    };

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const handleClickFilterPopup = () => {
        setFilterPopup(true);
        if( vessels.length > 0) {
            getContentFilterCategoryList(newSummaryStartDate, newSummaryEndDate, vessels);
            getContentFilterAppList(newSummaryStartDate, newSummaryEndDate, vessels);
            getContentFilterUserList(newSummaryStartDate, newSummaryEndDate, vessels);
            getContentFilterVlanList(vessels);
            getContentFilterDeviceList(vessels);
            getContentFilterSubCategoryList(newSummaryStartDate, newSummaryEndDate, vessels);
        }
    }

    const filterPopClick = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorElRef(e.currentTarget)
        handleClickFilterPopup();
    }

    const handleOpenFilters = () => {
        setGenerateFilter(true);
        let params: any = getDecodeURI(location.search);
        params.filterClick = true;
        doNavigate(params);
    }

    return (
        <div className="Form">
            <Grid className="am-table">
                <Box className='activityReports-Filter alignTopbarItemsCenter'>
                    <Fragment>
                        <Grid item xs={10.5} sm={10.5} md={10.5} lg={10.5} className="filter-dateRange-container">
                            <Button variant="outlined" startIcon={<TuneIcon id="filterIcon"/>} onClick={filterPopClick} id="filterButton">
                                Filter
                            </Button>
                            <ChipFilters values={values} setValues={setValues} applyFilterClick={applyFilterClick} setConfirmPopup={setConfirmPopup} />
                        </Grid>
                        {<Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} className="download-reports-container">
                            <div className="download-reports-btn" onClick={handleOpenFilters}><Button>Generate Report</Button><img src={Download} alt="" /></div>
                        </Grid>}
                        <Menu disableEnforceFocus
                            onClose={() => setFilterPopup(false)}
                            open={filterPopup}
                            className="rc--filter_menu_container_reports"
                            anchorEl={anchorElRef}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            {/* <Grid className="activityReportsFilter-close">
                                <img src={close} onClick={() => setFilterPopup(false)} alt="Close" />
                            </Grid> */}
                            <Grid className="usageFilterComponent">
                                <FilterPopup  filterPopup={filterPopup} setFilterPopup={setFilterPopup} values={values} setValues={setValues} applyFilterClick={applyFilterClick} />
                            </Grid>     
                        </Menu>
                    </Fragment>
                </Box>
                <Grid className="userDataContainer contentFilterContainer">
                    <Grid className="usageDataComponent">
                        <TopWidget values={values} setValues={setValues} applyFilterClick={applyFilterClick} />
                        <PieChart values={values} setValues={setValues} applyFilterClick={applyFilterClick} />
                        <LineChart values={values} setValues={setValues} apiCall={contentFilterApiCall} />
                        <TopSitesTable values={values} setValues={setValues} applyFilterClick={applyFilterClick} />
                        <TopUsersTable values={values} setValues={setValues} applyFilterClick={applyFilterClick} />
                        <BottomCards values={values} setValues={setValues} />
                    </Grid>
                </Grid>
            </Grid>
            { generateFilter ? <GenerateReportPopup generateFilter={generateFilter} setGenerateFilter={setGenerateFilter} handleClickFilterPopup={handleClickFilterPopup} setFilterPopup={setFilterPopup} 
              usersCount={authReducer?.getContentFilterTopUsersTableCount?.data?.rows?.[0]?.[0]} sitesCount={authReducer?.getContentFilterTopSitesTableCount?.data?.rows?.[0]?.[0]}
            /> : null}
            { confirmPopup ? <Reports open={confirmPopup} setOpen={setConfirmPopup} setGenerateFilter={setGenerateFilter} /> : null}
            {authReducer.setContentFilterTopUsersTableLoading || authReducer.setContentFilterTopUsersTableCountLoading || authReducer.setContentFilterTopSitesTableLoading || authReducer.setContentFilterTopSitesTableCountLoading || 
            authReducer.setContentFilterCategoryPieLoading || authReducer.setContentFilterApplicationPieLoading || authReducer.setContentFilterSubCategoryPieLoading || authReducer.setContentFilterTopWidgetTopSiteLoading || 
            authReducer.setContentFilterTopWidgetTopUserLoading || authReducer.setContentFilterTopWidgetTopCategoryLoading || authReducer.setContentFilterTopWidgetTopDomainLoading || authReducer.setContentFilterLinechartLoading ? (
                <CustomLoader
                    showLoader={true}
                    loadingText={"Fetching data please wait..."}
                />
            ) : null
            }
       </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    newSummaryStartDate: state.authReducer.newSummaryStartDate,
    newSummaryEndDate: state.authReducer.newSummaryEndDate,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {
        getContentFilterTopUsersTable,
        getContentFilterTopUsersTableCount,
        getContentFilterTopSitesTable,
        getContentFilterTopSitesTableCount,
        getContentFilterCategoryPie,
        getContentFilterApplicationPie,
        getContentFilterSubCategoryPie,
        getContentFilterTopWidgetTopSite,
        getContentFilterTopWidgetTopUser,
        getContentFilterTopWidgetTopCategory,
        getContentFilterTopWidgetTopDomain,
        getContentFilterLinechart,
        getContentFilterCategoryList,
        getContentFilterAppList,
        getContentFilterUserList,
        getContentFilterVlanList,
        getContentFilterDeviceList,
        getContentFilterSubCategoryList
    })(ContentFilter)
);