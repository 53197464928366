import { Fragment, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import { Switch, Box, Button, Chip, FormControl, Grid, InputAdornment, Paper, Table, TableBody, 
    TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, IconButton, DialogActions, Dialog, DialogTitle, DialogContent} from "@mui/material";

import { Edit, Delete, Policy } from "@material-ui/icons";

import { Pagination } from "../../Pagination";
import CreateContentFilterDialog from "../ContentFilterDialog";

import './ContentFilterTable.css';
import { getDecodeURI, getEncodedURI } from '../../../utils/util';

import { CreateContentFilterParameters, DeleteContentFilterParameters, GetContentFiltersParameters, UpdateContentFilterParameters, createContentFilter, deleteContentFilter, getContentFilters, updateContentFilter } from "../../../UserScreen/CustomContentFilter/slice";
import SortArrows, { SortOrder } from "../../SortArrows";
import { Category, ContentFilter, AllowedDomain, BlockedDomain, DialogListData } from "../../../UserScreen/CustomContentFilter/types";
import { toast } from "react-toastify";
import { DeleteConfirmationDialog } from "../DeleteConfirm";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';

import DownloadIcon from '@mui/icons-material/Download';
import DownloadCSV, { clearCsvDownload } from "../../DownloadCSV";
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import { WEB_TITAN_CATEGORIES } from "../../../UserScreen/CustomContentFilter/categories";
import FilterListIcon from '@mui/icons-material/FilterList';
import {Domain} from "../../../UserScreen/CustomContentFilter/types"
import {convertDateTimeIntoTimezone} from "./../../../utils/util"
import {MMDDYYYYHMMSS_DATE_FORMAT_24_HRS} from "./../../../utils/constants"
import { styled } from '@mui/material/styles';
import close from "../../../asset/image/close.svg";
import AntSwitch from "../../AntSwitch";
import HelpIcon from '@mui/icons-material/Help';
import _, { forEach } from "lodash"

const convertToCsv = (data: any[][]) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        csvRows.push([row[1], row[2], row[5]?.categories?.map(cat => WEB_TITAN_CATEGORIES[cat.id]?.name).join(', '), row[5]?.dns_tag, row[5]?.status, row[7], `${row[1]}-${row[5]?.id}`])
    })
    return [['Organization', 'Name', 'Blocked Categories', 'DNS Tag', 'Status', 'Update Time', 'WT Policy Name'], ...csvRows]
}
interface ContentFilterTableProps {
    authReducer: any;
    errorReducer: any;
    history: any;
    location: any;
    dpIds: string[];
    contentFilters: any;
    totalContentFilters: number;
    getContentFilters: (parameters: GetContentFiltersParameters) => void;
    createContentFilter: (parameters: CreateContentFilterParameters, abortSignal: AbortSignal | undefined) => Promise<any>;
    updateContentFilter: (parameters: UpdateContentFilterParameters) => Promise<any>;
    deleteContentFilter: (parameters: DeleteContentFilterParameters) => Promise<any>;
    downloadAsCSV: (parameters: any) => void;
    isReadOnlyUser: boolean;
}

export type AccountSortBy = 'name' | 'updated_at';

export interface OnSaveContentFilterParameters {
    id?: string;
    dp_id?: string;
    name: string;
    description: string;
    categories: Category[];
    allowed_domains: Domain[];
    blocked_domains: Domain[];
    enabled:boolean;
}

function ContentFilterTable(props: ContentFilterTableProps) {

    const { authReducer, errorReducer, history, location, dpIds, contentFilters, totalContentFilters, getContentFilters,createContentFilter, updateContentFilter, deleteContentFilter, downloadAsCSV, isReadOnlyUser } = props;

    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));

    const [searchText, setSearchText] = useState<string>(queryParams.get('search') || '');
    const [paginationParams, setPaginationParams] = useState<{
        page: number;
        size: number;
        sortBy: AccountSortBy;
        sortOrder: SortOrder;
        search: string;
    }>({
        page: queryParams.get('page') ? Number(queryParams.get('page')) : 1,
        size: queryParams.get('size') ? Number(queryParams.get('size')) : 10,
        sortBy: queryParams.get('sortBy') as AccountSortBy || 'updated_at',
        sortOrder: queryParams.get('sortOrder') as SortOrder || 'desc',
        search: queryParams.get('search') || '',
    });

    const [openContentFilterDialog, setOpenContentFilterDialog] = useState<boolean>(false);
    const [contentFilterToUpdate, setContentFilterToUpdate] = useState<any>(null);
    const [toDeleteContentFilter, setToDeleteContentFilter] = useState<ContentFilter | null>(null);
    const [requesAbortController, setRequesAbortController] = useState<AbortController | null>(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpenCategoriesListDialog, setIsOpenCategoriesListDialog] = useState<boolean>(false);
    const [categoriesListDialogData, setCategoryListDialogData] = useState<DialogListData[]>([]);
    const [categoriesListDialogTitle, setCategoryListDialogTitle] = useState<string>("");
    const [isOpenDomainListDialog, setIsOpenDomainsListDialog] = useState<boolean>(false);
    const [domainsListDialogData, setDomainsListDialogData] = useState<Domain[]>([]);
    const [domainsListDialogTitle, setDomainsListDialogTitle] = useState<string>("");
    const [contentFilterData, setContentFilterData] = useState<any>([]);
    const [dialogType, setDialogType] = useState<string>("")
    const [isOpenCommonListDialog, setIsOpenCommonListDialog] = useState<boolean>(false);
    const [commonListDialogData, setCommonListDialogData] = useState<any>([]);
    const [commonListDialogTitle, setCommonListDialogTitle] = useState<string>("");

    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();

    useEffect(() => {
        updateQueryParams();
        getContentFilters({
            dp_id: authReducer.selectedOu.id,
            search: paginationParams.search,
            sort_by: paginationParams.sortBy,
            sort_order: paginationParams.sortOrder,
            page: paginationParams.page,
            size: paginationParams.size
        });
    }, [paginationParams]);

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'custom-content-filters') {
                DownloadCSV(convertToCsv(authReducer.csvDataDownloded), {
                    formatters: {
                        0: 'ESC-COMMA',
                        1: 'ESC-COMMA',
                        2: 'ESC-COMMA'
                    }
                });
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    useEffect(()=>{
        if (!_.isEmpty(contentFilters)) {
            const data = contentFilters.hasOwnProperty("data") ? contentFilters.data : {}
            if (data.hasOwnProperty("rows") && Array.isArray(data["rows"])) {
                const rows: any[] = data["rows"]
                const columns: any[] = data["columns"]

                let tableData = rows.map((item, index) => {
                    return columns.reduce((vsd,columnitem,i) => {
                        vsd[columnitem] = item[i]
                        return vsd;
                    },{})
                })

                if(tableData.length > 0){
                    dispatch({
                        type: "contentFilter/setContentFilterCount",
                        payload: tableData[0]["total_count"],
                    })
                }

                if(tableData.length > 1){
                    tableData = tableData.slice(1)
                }
                
                setContentFilterData(tableData)
            } else {
                setContentFilterData([])
            }
        }
    }, [contentFilters])

    const refreshContentFilterTable = (samePage: boolean = false) => {
        let _page = paginationParams.page;
        setPaginationParams({
            ...paginationParams,
            page: samePage ? _page : 1
        });
    }

    const handleOpenContentFilterDialog = () => {
        setRequesAbortController(new AbortController());
        setOpenContentFilterDialog(true);
    }

    const handleCloseContentFilterDialog = (refresh: boolean = false) => {
        setOpenContentFilterDialog(false);
        setContentFilterToUpdate(null);
        if (refresh) {
            refreshContentFilterTable();
        }
    }

    const handleSaveContentFilter = async (contentFilter: OnSaveContentFilterParameters) => {
        // do api call here to create or update
        if (contentFilterToUpdate) {
            // update
            let payload :UpdateContentFilterParameters = {
                    id: contentFilterToUpdate.id,
                    dp_id: contentFilterToUpdate.dp_id,
                    name: contentFilter.name,
                    description: contentFilter.description,
                    categories: contentFilter.categories,
                    allowed_domains: contentFilter.allowed_domains,
                    blocked_domains: contentFilter.blocked_domains,
                    enabled: contentFilterToUpdate.enabled
            }
            let res = await updateContentFilter(payload);
            if(res?.success){
                toast.success("Custom content filter update in progress.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
            refreshContentFilterTable()
        } else {
            // create
            const payload:CreateContentFilterParameters = {
                dp_id: authReducer.selectedOu.id,
                description: contentFilter.description,
                name: contentFilter.name,
                categories: contentFilter.categories,
                allowed_domains: contentFilter.allowed_domains,
                blocked_domains: contentFilter.blocked_domains,
                enabled: true
            };
            
            let res = await createContentFilter(payload, requesAbortController?.signal);
            if(res?.success){
                toast.success("Custom content filter created successfully.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
            refreshContentFilterTable()
        }
        setOpenContentFilterDialog(false);
        setContentFilterToUpdate(null);
    }

    const handleDeleteConfirm = async (contentFilterId:string, dialogType:string) => {
        if(dialogType == "delete"){

            if (toDeleteContentFilter) {
                setToDeleteContentFilter(null);
                let payload:DeleteContentFilterParameters = {
                    dp_id: authReducer.selectedOu.id,
                    id: contentFilterId
                }
                let res = await deleteContentFilter(payload);
                if (res?.success) {
                    toast.success("Custom content filter deleted successfully.", {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                    refreshContentFilterTable();
                } else {
                    toast.error("Failed to delete custom content filter", {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                }
            }
            setToDeleteContentFilter(null);
        }
        else if (dialogType == "disable") {
            let payload :UpdateContentFilterParameters = {
                id: toDeleteContentFilter?.id || "",
                dp_id: toDeleteContentFilter?.dp_id || "",
                name: toDeleteContentFilter?.name || "",
                description: toDeleteContentFilter?.description || "",
                categories: toDeleteContentFilter?.policy?.categories || [],
                allowed_domains: toDeleteContentFilter?.policy?.allowed_domains || [],
                blocked_domains: toDeleteContentFilter?.policy?.blocked_domains || [],
                enabled: false
            }
            let res = await updateContentFilter(payload);
            if(res?.success){
                toast.success("Custom content filter disabled successfully.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
            refreshContentFilterTable()
            setToDeleteContentFilter(null);
        } else {
            let payload :UpdateContentFilterParameters = {
                id: toDeleteContentFilter?.id || "",
                dp_id: toDeleteContentFilter?.dp_id || "",
                name: toDeleteContentFilter?.name || "",
                description: toDeleteContentFilter?.description || "",
                categories: toDeleteContentFilter?.policy?.categories || [],
                allowed_domains: toDeleteContentFilter?.policy?.allowed_domains || [],
                blocked_domains: toDeleteContentFilter?.policy?.blocked_domains || [],
                enabled: true
            }
            let res = await updateContentFilter(payload);
            if(res?.success){
                toast.success("Custom content filter enabled successfully.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
            refreshContentFilterTable()
            setToDeleteContentFilter(null);
        }
    }

    const handleDeleteCancel = () => {
        setToDeleteContentFilter(null);
    }


    const handleOnSearchClick = () => {
        handleSearch(searchText);
    }

    const handleOnSearchClear = () => {
        setSearchText('');
        handleSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }


    const handleSearch = (value: string) => {
        setPaginationParams({
            ...paginationParams,
            search: value,
            page: 1
        });
    }

    const handleChangePage = (event: any, newPage: number) => {
        setPaginationParams({ ...paginationParams, page: newPage });
    };

    const handleChangeSize = (event: any) => {
        setPaginationParams({ ...paginationParams, size: event.target.value, page: 1 });
    };

    const handleChangeSorting = (sortBy: AccountSortBy, sortOrder: SortOrder) => {
        setPaginationParams({
            ...paginationParams,
            sortBy: sortBy,
            sortOrder: sortOrder,
            page: 1
        });
    }

    const updateQueryParams = () => {
        queryParams.set('page', paginationParams.page.toString())
        queryParams.set('size', paginationParams.size.toString())
        queryParams.set('sortBy', paginationParams.sortBy)
        queryParams.set('sortOrder', paginationParams.sortOrder)
        queryParams.set('search', paginationParams.search)
        history.replace({ pathname: location.pathname, search: `?${getEncodedURI(Object.fromEntries(queryParams.entries()))}` });
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = (row:any) => {
        let contentFilter: ContentFilter = {
            id: row?.id,
            dp_id: row?.dp_id,
            dp_name: row?.dp_name,
            name: row?.name,
            description: row?.description,
            policy: {
                allowed_domains: row?.allowed_domains,
                blocked_domains: row?.blocked_domains,
                categories: row?.categories
            },
            enabled: row?.enabled
        }
        setContentFilterToUpdate(contentFilter);
        handleClose();
    };

    const handleDelete = (row:any) => {
        let contentFilter: ContentFilter = {
            id: row?.id,
            dp_id: row?.dp_id,
            dp_name: row?.dp_name,
            name: row?.name,
            description: row?.description,
            policy: {
                allowed_domains: row?.allowed_domains,
                blocked_domains: row?.blocked_domains,
                categories: row?.categories
            },
            enabled: row?.enabled
        }
        setToDeleteContentFilter(contentFilter);
        setDialogType("delete")
        handleClose();
    };

    const openCategoryListDialog = ()=>{
        setIsOpenCategoriesListDialog(true)
    }

    const onSetDialogListData = (data:DialogListData[])=>{
        setCategoryListDialogData(data)
    }

    const OnSetDialogListTitle = (data:string)=>{
        setCategoryListDialogTitle(data)
    }

    const openDomainListDialog = ()=>{
        setIsOpenDomainsListDialog(true)
    }

    const onSetDomainsListDialogData = (data:Domain[])=>{
        setDomainsListDialogData(data)
    }

    const OnSetDomainsListDialogTitle = (data:string)=>{
        setDomainsListDialogTitle(data)
    }

    const openCommonListDialog = ()=>{
        setIsOpenCommonListDialog(true)
    }

    const onSetDialogCommonListData = (data:any)=>{
        setCommonListDialogData(data)
    }

    const OnSetDialogCommonListTitle = (data:string)=>{
        setCommonListDialogTitle(data)
    }

    const onChangeContentFilterStatus = (row:any) => (event:any) =>{
        let contentFilter: ContentFilter = {
            id: row?.id,
            dp_id: row?.dp_id,
            dp_name: row?.dp_name,
            name: row?.name,
            description: row?.description,
            policy: {
                allowed_domains: row?.allowed_domains,
                blocked_domains: row?.blocked_domains,
                categories: row?.categories
            },
            enabled: row?.enabled
        }
        if(!event?.target?.checked){
            setToDeleteContentFilter(contentFilter);
            setDialogType("disable")
        } else {
            setToDeleteContentFilter(contentFilter);
            setDialogType("enable")
        }
    }

    return (
        <Paper elevation={0} square={true} className="content-filter--container">
            <Grid container xs={12} sm={12} md={12} lg={12} classes={{ root: 'content_filter--base--mtb_07x content_filter--base--flex--align--center content_filter--base--flex--justify--space_between' }}>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Typography component="div" classes={{ root: 'content_filter--base--font_2x' }}>
                        Content Filter
                    </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8} className="content_filter-div-flex-end">
                        {/* <Box>
                            <TextField
                                id="search"
                                variant="outlined"
                                placeholder="Type something"
                                className="content_filter-search_field"
                                size="small"
                                value={searchText}
                                onChange={handleOnSearchChange}
                                onKeyDown={(e) => { if (e.key === 'Enter') { handleOnSearchClick() } }}
                                InputLabelProps={{ className: "serachLabel" }}
                                InputProps={{
                                    className: "serachBar",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {!searchText && <SearchIcon
                                                className="cursorPointer input-search-icons" onClick={handleOnSearchClick}
                                            />}
                                            {searchText && <CloseIcon
                                                className="cursorPointer input-search-icons"
                                                onClick={handleOnSearchClear}
                                            />}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Button variant="outlined" startIcon={<FilterListIcon/>} className="content_filter--button_outlined_filter">
                            Filter
                        </Button> */}
                        <Button variant="contained" color="primary" className="content_filter--button_contained--primary" onClick={handleOpenContentFilterDialog}>
                            Create Content Filter
                        </Button>
                </Grid>
            </Grid>
            <Grid classes={{ root: 'content-filter--base--padding_05x' }}>
                <Pagination count={totalContentFilters} onPageChange={handleChangePage} page={paginationParams.page} rowsPerPage={paginationParams.size} onRowsPerPageChange={handleChangeSize} />
                <TableContainer component={Paper} className="content-filter--table_container">
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="content filter dashboard">
                        <TableHead>
                            <TableRow>
                                <TableCell align='left'>
                                        ORGANIZATION
                                </TableCell>
                                <TableCell align='left'>
                                    <SortArrows sortBy={paginationParams.sortBy} sortOrder={paginationParams.sortOrder} field={'name'} onChange={handleChangeSorting}>
                                        NAME
                                    </SortArrows>
                                </TableCell>
                                <TableCell align='left'>
                                    <span>
                                        BLOCKED CATEGORIES
                                    </span>
                                </TableCell>
                                <TableCell>
                                    <span>
                                        ALLOWED DOMAINS
                                    </span>
                                </TableCell>
                                <TableCell>
                                    <span>
                                        BLOCKED DOMAINS
                                    </span>
                                </TableCell>
                                <TableCell>
                                    <span>
                                        APPLIED DEVICES
                                    </span>
                                </TableCell>
                                <TableCell>
                                    <span>
                                        ASSIGNED DEVICES
                                    </span>
                                </TableCell>
                                <TableCell>
                                    <SortArrows sortBy={paginationParams.sortBy} sortOrder={paginationParams.sortOrder} field={'updated_at'} onChange={handleChangeSorting}>
                                        UPDATED AT
                                    </SortArrows>
                                </TableCell>
                                <TableCell align='left'>
                                    <span>STATUS <Tooltip title="Enable or disable the content filter. When disabled, the content filter will be removed from the devices.."><HelpIcon className="content-filter-status-help-icon"></HelpIcon></Tooltip></span>
                                </TableCell>
                                <TableCell align='left'>
                                    <span>Default <Tooltip title="Default Content Filter"><HelpIcon className="content-filter-status-help-icon"></HelpIcon></Tooltip></span>
                                </TableCell>
                               {!isReadOnlyUser &&  <TableCell align='left' classes={{ root: 'content-filter--dashboard--actions' }}>
                                    <Grid classes={{ root: 'content-filter--base--flex--align--center' }}>
                                        ACTIONS
                                    </Grid>
                                </TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                contentFilterData?.length > 0 ? contentFilterData.map((row:any) => {
                                    return <TableRow
                                        key={row.id}
                                        className="row-parent"
                                    >
                                        <TableCell>
                                            <CommonList title="Organizations" data={row?.organization} openCommonListDialog={openCommonListDialog} setCommonListDialogData={onSetDialogCommonListData} setCommonListDialogTitle={OnSetDialogCommonListTitle}/>
                                        </TableCell>
                                        <TableCell>
                                            {row.name}
                                        </TableCell>
                                        <TableCell>
                                            <CategoryList categories={row?.categories} openCategoryListDialog={openCategoryListDialog} setCategoryListDialogData={onSetDialogListData} setCategoryListDialogTitle={OnSetDialogListTitle}/>
                                        </TableCell>
                                        <TableCell>
                                            <DomainList domains={row?.allowed_domains} openDomainsListDialog={openDomainListDialog} setDomainsListDialogData={onSetDomainsListDialogData} setDomainsListDialogTitle={OnSetDomainsListDialogTitle} domainTitle={"Allowed Domains"}/> 
                                        </TableCell>
                                        <TableCell>
                                            <DomainList domains={row?.blocked_domains} openDomainsListDialog={openDomainListDialog} setDomainsListDialogData={onSetDomainsListDialogData} setDomainsListDialogTitle={OnSetDomainsListDialogTitle} domainTitle={"Blocked Domains"}/>
                                        </TableCell>
                                        <TableCell>
                                            <CommonList title="Applied Devices" data={row?.applied_devices} openCommonListDialog={openCommonListDialog} setCommonListDialogData={onSetDialogCommonListData} setCommonListDialogTitle={OnSetDialogCommonListTitle}/>
                                        </TableCell>
                                        <TableCell>
                                            <CommonList title="Assigned Devices" data={row?.assigned_devices} openCommonListDialog={openCommonListDialog} setCommonListDialogData={onSetDialogCommonListData} setCommonListDialogTitle={OnSetDialogCommonListTitle}/>
                                        </TableCell>
                                        <TableCell>
                                            {convertDateTimeIntoTimezone(row?.updated_at, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}
                                        </TableCell>
                                        <TableCell>
                                            <AntSwitch checked={row?.enabled} inputProps={{ 'aria-label': 'ant design' }} onChange={onChangeContentFilterStatus(row)} disabled={row.default || authReducer.selectedOu.id != row.dp_id || row.delete_in_progress}/>
                                        </TableCell>
                                        <TableCell>
                                            {row.default ? <p>TRUE</p> : <p>FALSE</p>}
                                        </TableCell>
                                        <TableCell>
                                            <Paper elevation={0}>
                                                <Fragment>
                                                    <Tooltip title="Edit">
                                                        <IconButton size='small' onClick={()=>{handleEdit(row)}} disabled={row.default || authReducer.selectedOu.id != row.dp_id || row.delete_in_progress}>
                                                            <Edit />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete">
                                                        <IconButton size='small' onClick={()=>{handleDelete(row)}} disabled={row.default || authReducer.selectedOu.id != row.dp_id || row.delete_in_progress}>
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Fragment>
                                            </Paper>
                                        </TableCell>
                                    </TableRow>
                                }) : <TableRow>
                                    <TableCell colSpan={8} align='center'>
                                        No data available
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer >
            </Grid>

            {/* DELETE CONTENT FILTER CONFIRMATION */}
            <DeleteConfirmationDialog open={toDeleteContentFilter != null} contentFilterName={toDeleteContentFilter?.name} contentFilterId={toDeleteContentFilter?.id} onDelete={handleDeleteConfirm} onCancel={handleDeleteCancel} dialogType={dialogType} />

            {/* CONTENT FILTER EDIT DIALOG */}
            <CreateContentFilterDialog open={openContentFilterDialog || contentFilterToUpdate !== null} contentFilterToUpdate={contentFilterToUpdate} onClose={handleCloseContentFilterDialog} onSave={handleSaveContentFilter}/>

            {/* SHOW LIST */}
            <CategoriesListDialog open={isOpenCategoriesListDialog} onCancel={()=>{setIsOpenCategoriesListDialog(false)}} contentFilterName={""} list={categoriesListDialogData} title={categoriesListDialogTitle}></CategoriesListDialog>
            
            {/* SHOW LIST */}
            <DoaminListDialog open={isOpenDomainListDialog} onCancel={()=>{setIsOpenDomainsListDialog(false)}} contentFilterName={""} list={domainsListDialogData} title={domainsListDialogTitle}></DoaminListDialog>

            {/* SHOW LIST */}
            <CommonListDialog open={isOpenCommonListDialog} onCancel={()=>{setIsOpenCommonListDialog(false)}} contentFilterName={""} list={commonListDialogData} title={commonListDialogTitle}></CommonListDialog>
                        

        </Paper>
    );
}

interface CommonListProps {
    data: any;
    title: string
    openCommonListDialog:()=>void
    setCommonListDialogData:(data:[])=>void
    setCommonListDialogTitle:(data:string)=>void
}

function CommonList(props: CommonListProps) {
    const { data, title, openCommonListDialog, setCommonListDialogData, setCommonListDialogTitle } = props;
    const [open, setOpen] = useState<boolean>(false);

    const [listData, setListData] = useState([])

    useEffect(()=>{
        if(data == null){
            setListData([])
        } else {
            setListData(data.split(","))
        }
    }, [])
    const openDisplayCommonListDialog = ()=>{
        let commonListDialogData:any = listData.map((value, index)=> {
            return value
        })
        openCommonListDialog()
        setCommonListDialogData(commonListDialogData)
        setCommonListDialogTitle(title)
    }
    return (
        <Fragment>
            {
                listData != null ? 
                listData?.length > 0 ? listData?.length > 1 && !open ? 
                        <Fragment>
                            {
                                listData?.slice(0, 1)?.map((value: string) =>  <Chip size="small" label={value} className="blocked-category-chip"/>)
                            } 
                            <Chip variant="outlined" size="small" label={`+${listData?.length - 1}`} onClick={() => { openDisplayCommonListDialog() }} />
                        </Fragment>
                    : listData.map((value: any) =>  <Chip size="small" label={value} className="blocked-category-chip" />)
                    : <></>
                    :<></>
            }
        </Fragment>
    )
}
interface CommonListDialogProps {
    open: boolean;
    contentFilterName: string;
    list: any;
    onCancel:()=>void;
    title:string;
}

function CommonListDialog(props: CommonListDialogProps) {
    const { open, contentFilterName, list, onCancel, title} = props;

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className='delete-content-filter-title'>
                        <span className="content-filter-title-name"> {title} </span>
                        <img src={close} title="Close" onClick={onCancel} className="create-content-filter-close-btn" />
            </DialogTitle>
            <DialogContent className="dialog-list-content-filter-dialog-content">
                {
                    list.map((value)=>{
                        return (
                            <>
                                <Chip size="small" label={value} className="dialog-list-chip"/>
                            </>
                        )
                    })
                }
            </DialogContent>
        </Dialog>
    )
}


interface CategoryListProps {
    categories: Category[];
    openCategoryListDialog:()=>void
    setCategoryListDialogData:(data:DialogListData[])=>void
    setCategoryListDialogTitle:(data:string)=>void
}

function CategoryList(props: CategoryListProps) {
    const { categories, openCategoryListDialog, setCategoryListDialogData, setCategoryListDialogTitle } = props;
    const [open, setOpen] = useState<boolean>(false);

    const openDisplayListDialog = ()=>{
        let categoriesListDialogData: DialogListData[] = categories.map((category, index)=> {
            return {
                name: WEB_TITAN_CATEGORIES?.[category.id]?.name
            }
        })
        openCategoryListDialog()
        setCategoryListDialogData(categoriesListDialogData)
        setCategoryListDialogTitle("Blocked Categoriess")
    }
    return (
        <Fragment>
            {
                categories?.length > 0 ? categories?.length > 1 && !open ? <Fragment>
                    {categories?.slice(0, 1)?.map((category: Category) => WEB_TITAN_CATEGORIES?.[category.id] ? 
                        <Chip size="small" label={WEB_TITAN_CATEGORIES?.[category.id]?.name} className="blocked-category-chip"/> : null)} <Chip variant="outlined" size="small" label={`+${categories?.length - 1}`} onClick={() => { openDisplayListDialog() }} />
                </Fragment>
                    : categories.map((category: any) => WEB_TITAN_CATEGORIES?.[category.id] ?
                        <Chip size="small" label={WEB_TITAN_CATEGORIES?.[category.id]?.name} className="blocked-category-chip" />
                    : null)
                    : <Chip size="small" label="None" />
            }
        </Fragment>
    )
}
interface CategoriesListDialogProps {
    open: boolean;
    contentFilterName: string;
    list: DialogListData[];
    onCancel:()=>void;
    title:string;
}

function CategoriesListDialog(props: CategoriesListDialogProps) {
    const { open, contentFilterName, list, onCancel, title} = props;

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className='delete-content-filter-title'>
                        <span className="content-filter-title-name"> {title} </span>
                        <img src={close} title="Close" onClick={onCancel} className="create-content-filter-close-btn" />
            </DialogTitle>
            <DialogContent className="dialog-list-content-filter-dialog-content">
                {
                    list.map((row)=>{
                        return (
                            <>
                                <Chip size="small" label={row.name} className="dialog-list-chip"/>
                            </>
                        )
                    })
                }
            </DialogContent>
        </Dialog>
    )
}


interface DomainListProps {
    domains: Domain[]
    openDomainsListDialog:()=>void
    setDomainsListDialogData:(data:Domain[])=>void
    setDomainsListDialogTitle:(data:string)=>void
    domainTitle: string
}
function DomainList(props:DomainListProps) {
    const { domains, openDomainsListDialog, setDomainsListDialogData, setDomainsListDialogTitle, domainTitle} = props;
    const [open, setOpen] = useState<boolean>(false);

    const openDisplayListDialog = ()=>{
        openDomainsListDialog()
        setDomainsListDialogData(domains)
        setDomainsListDialogTitle(domainTitle)
    }

    return (
        props.domains?.length > 0 ? props.domains?.length > 1 ? 
            <Fragment>
                {   
                    props.domains?.slice(0, 1)?.map((domain:Domain) => <Chip size="small" label={domain.domain} className="blocked-category-chip"/>) 
                }
                <Chip variant="outlined" size="small" label={`+${props.domains?.length - 1}`} onClick={() => { openDisplayListDialog() }}/>
            </Fragment>
        : 
            <Fragment> 
                {props.domains.map((domain: Domain) => (<Chip size="small" label={domain.domain} className="blocked-category-chip"/>))}
            </Fragment>
        :<></>
    )
}

interface DoaminsListDialogProps {
    open: boolean;
    contentFilterName: string;
    list: Domain[];
    onCancel:()=>void;
    title:string;
}

function DoaminListDialog(props: DoaminsListDialogProps) {
    const { open, contentFilterName, list, onCancel, title} = props;

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className='delete-content-filter-title'>
                        <span className="content-filter-title-name"> {title} </span>
                        <img src={close} title="Close" onClick={onCancel} className="create-content-filter-close-btn" />
            </DialogTitle>
            <DialogContent className="dialog-list-content-filter-dialog-content">
            <TableContainer className="content_filter--domain-names-table_container">
                    <Table sx={{ minWidth: 600, maxHeight:200 }} size="small" aria-label="content_filter--service_accounts">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <span>S. No.</span>
                                </TableCell>
                                <TableCell>
                                    <span>Domain Name</span>
                                </TableCell>
                                <TableCell>
                                    <span>Sub Domain</span>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="tableBody alertsTable-tableBody">
                            {
                                list.length == 0 ? 
                                <></>
                                :
                                <>
                                    {
                                        list.map((allowedDoamin, index)=>{
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {index+1}
                                                    </TableCell>
                                                    <TableCell>
                                                        {allowedDoamin.domain}
                                                    </TableCell>
                                                    <TableCell>
                                                        {allowedDoamin.sub_domain ? <> <AntSwitch checked={true}/> </> : <> <AntSwitch checked={false}/> </>}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    dpIds: state.customContentFilter.dpIds,
    contentFilters: state.customContentFilter.contentFilters,
    totalContentFilters: state.customContentFilter.totalContentFilters,
    isReadOnlyUser: state?.authReducer?.isReadOnlyUser
});

export default withRouter(
    connect(mapStateToProps, {
        getContentFilters,
        createContentFilter,
        updateContentFilter,
        deleteContentFilter,
        downloadAsCSV
    })(ContentFilterTable)
);