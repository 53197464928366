import ascSortingSelected from "../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../asset/image/descSortingSelected.svg";
import AscSort from "../../asset/image/AscSort.svg";
import DescSort from "../../asset/image/DescSort.svg";
import { Grid } from "@mui/material";

import './index.css';

export type SortOrder = 'asc' | 'desc'

interface SortArrowsProps<T> {
    sortBy: T;
    sortOrder: SortOrder;
    field: T;
    onChange: (sortBy: T, sortOrder: SortOrder) => void;
    children?: React.ReactNode;
}

export default function SortArrows<T>(props: SortArrowsProps<T>) {
    const createSortHandler = (sortBy: T, sortOrder: SortOrder | undefined = undefined) => () => {
        props.onChange(sortBy, sortOrder ? sortOrder : props.sortBy !== sortBy ? 'asc' : props.sortOrder === 'asc' ? 'desc' : 'asc');
    }
    return (
        <Grid classes={{ root: 'starlink--base--flex--align--center starlink--base--cursor--pointer' }}>
            <div onClick={createSortHandler(props.field)}>{props.children}</div>
            <div className='starlink--base--flex--align--center starlink--base--flex--column starlink--table--sort_arrows'>
                <ArrowUp selected={props.sortBy === props.field && props.sortOrder === 'asc'} onClick={createSortHandler(props.field, 'asc')} />
                <ArrowDown selected={props.sortBy === props.field && props.sortOrder === 'desc'} onClick={createSortHandler(props.field, 'desc')} />
            </div>
        </Grid>
    )
}

function ArrowUp(props: any) {
    return (
        <img alt="" src={props.selected ? ascSortingSelected : AscSort}
            className="sorting_icons"
            {...props} />
    )
}

function ArrowDown(props: any) {
    return (
        <img alt="" src={props.selected ? descSortingSelected : DescSort}
            className="sorting_icons"
            {...props} />
    )
}