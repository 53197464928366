import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const KnowledgeCenter = (props) => {
 const { authReducer } = props;
 let url = ""
  if(authReducer?.domainConfig?.knowldgeHelper) {
    url = `https://${authReducer?.domainConfig?.knowldgeHelper}.scrollhelp.site`
  } else {
    url = `https://kognitive.scrollhelp.site`
  }
  console.log("KURL",url)
  return (
    <div>
      <iframe
        src={url}
        title="Knowledge Center"
        style={{ width: "100%", height: "calc(100vh - 80px)", border: "none", marginTop: "40px", marginBottom: "350px"}}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {
    })(KnowledgeCenter)
)
// import { withRouter } from "react-router-dom";
// import { connect } from "react-redux";
// import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
// import { BC_KNOWLEDGE_CENTER } from "../../constants/Constants";
// import { getDecodeURI } from "../../utils/util";
// import { useEffect, useState } from "react";
// import React from "react";
// import { updatePermission } from "../../actions/Users/authenticate";
// import { SIDE_MENU_ITEM_KNOWLEDGE_CENTER } from "../../utils/constants";
// import { Button, Grid } from "@mui/material";
// import DpDocuments from "../../components/DpDocuments";
// import "./knowledgeCenter.css"
// import CommonFilters from "../../components/BreadCrumbs/CommonFilters";

// const KnowledgeCenter = (props) => {
//     const { authReducer, location, updatePermission } = props;
//     let breadcrumbInfo = BC_KNOWLEDGE_CENTER;
//     const DOCUMENTS = "Documents";
//     let _q = getDecodeURI(location?.search);
//     const [selectedTab, setSelectedTab] = useState<any>(_q.hasOwnProperty("tab") ? _q.tab : DOCUMENTS);
//     const setBreadcrumbInfo: any = React.useRef();
  
//     if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_KNOWLEDGE_CENTER !== authReducer.sideMenu.menuItem)) {
//       authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_KNOWLEDGE_CENTER };
//     }

//     useEffect(() => {
//         applyBreadCumbAndPermissionChanges()
//     }, [selectedTab]);

//     const applyBreadCumbAndPermissionChanges = () => {
//         if (DOCUMENTS == selectedTab) {
//             permissionChanges(true)
//         }
//         breadcrumbInfo[2]["title"] = selectedTab
//         setBreadcrumbInfo.current(breadcrumbInfo)
//     }

//     const permissionChanges = (showOu) => {
//         const info = {
//             permission: {
//                 service: true,
//                 serviceFunction: true,
//               },
//             isMultiVessel: false,
//             isSingleVessel: false,
//             showOu: showOu,
//             ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
//         };
//         updatePermission(info);
//     }
//     return (
//         <div>
//             <CommonFilters breadcrumbinfo={breadcrumbInfo} setBreadcrumbInfo={setBreadcrumbInfo} loading={authReducer?.inventoryListingLoading || authReducer?.userPreferencesLoading} showWantType={false} showSummaryPeriod={false}></CommonFilters>
//             <Grid item container style={{ backgroundColor: "#fff" }}>
//                 <Grid item style={{width: "100%", paddingTop: "80px", backgroundColor: "#F4F7F8" }}>
//                     <Grid id="knowledgeCenterTabs">
//                         <Grid>
//                             {/* <Button className={selectedTab === DOCUMENTS ? "buttonCssFleet activeTab" : "buttonCssFleet inActiveTab"}>{DOCUMENTS}</Button> */}
//                         </Grid>
//                     </Grid>
//                     {
//                         selectedTab === DOCUMENTS ?<DpDocuments/>:<></>
//                     }
//                 </Grid>
//             </Grid>
//         </div>
//     )
// }

// const mapStateToProps = (state) => ({
//     authReducer: state.authReducer,
//     errorReducer: state.errorReducer,
// });

// export default withRouter(
//     connect(mapStateToProps, {
//         updatePermission
//     })(KnowledgeCenter)
// )