// Alert Table for Summarey page
import React, { useState, useEffect } from "react";
import { Grid, Button, Typography, Dialog } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ascSorting from "../../asset/image/ascSorting.svg";
import descSorting from "../../asset/image/descSorting.svg";
import ascSortingSelected from "../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../asset/image/descSortingSelected.svg";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import close from "../../asset/image/cross.svg";
import AlertHistory from "../AlertHistory";
import { getVesselAlerts, CloseVesselAlert, QueryParameters } from "../../actions/Users/authenticateAlert";
import _ from "lodash";
import arrowAlert from "../../asset/image/arrowAlert.svg";
import errorAlert from "../../asset/image/errorAlert.svg";
import correctAlert from "../../asset/image/correctAlert.svg";
import "./alertTable.css";
import { DEFAULT_TIMEZONE, MMDDYYYYHMMSS_DATE_FORMAT, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants";
import moment from "moment-timezone";
import TextField from "@mui/material/TextField";
import { getDecodeURI, getEncodedURI, strToK4List } from "../../utils/util";
import { DEFAULT_PAGE_SIZE } from "../../constants/Constants";
import JsonTooltip from "../JSONTooltip/JsonTooltip";


const DEFAULT_SORT = "opendate";
const DEFAULT_SORT_ORDER = 0;

const AlertTable = (props: any) => {
  const {
    getVesselAlerts,
    authReducer,
    page,
    setPage,
    limit,
    setLimit,
    search,
    setSearch,
    // filterBy,
    // setFilterBy,
    CloseVesselAlert,
    updatePageInfo,
    history,
    location
  } = props;

  let [structuredData, setStructuredData] = useState<any[]>([]);
  const [sort, setSort] = useState(DEFAULT_SORT);
  const [sortOrder, setSortOrder] = useState(DEFAULT_SORT_ORDER);
  const [loaders, setLoaders] = useState({ showLoader: false, loadingText: "Fetching data please wait..." });
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = useState("");
  const [alertId, setAlertId] = useState("");
  const [allFieldsIsValidORnot, setAllFieldsIsValidORnot] = useState(false);
  const [openHistory, setOpenHistory] = React.useState(false);
  const [alertIdHistoryPopUp, SetAlertIdHistoryPopUp] = useState("");
  const [alertLocationId, setAlertLocationId] = useState("");

  useEffect(() => {
    processQueryUrl();
  }, [location]);

  const setSortingField = (event: any, order: any, sorting: any) => {
    let params: any = getDecodeURI(location?.search);
    params.page = 0;
    params.sort = order;
    params.sortOrder = sorting;
    doNavigate(location.url, params);
  };

  const switchSorting = (event: any, field: any) => {
    let sorting = 1;
    if (sort === field) {
      sorting = 0 === sortOrder ? 1 : 0;
    }
    let params: any = getDecodeURI(location?.search);
    params.page = 0;
    params.sort = field;
    params.sortOrder = sorting;
    doNavigate(location.url, params);
  };

  const handleMarkAsFixed = () => {
    let payload = { id: alertId, comment: comment, locationId: alertLocationId };

    CloseVesselAlert(payload);
    setLoaders({ showLoader: false, loadingText: "Please wait..! Marking alert as fixed" });
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAlertHistory = (e: any) => {
    SetAlertIdHistoryPopUp(e.id);
    setAlertLocationId(e.locationId);
    setOpenHistory(true);
  };

  const handleHistoryClose = () => {
    setOpenHistory(false);
  };

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    if (name === "comment") {
      setComment(event.target.value);
      valueIsVlidOrNot(event.target.value);
    }
  };

  const valueIsVlidOrNot = (value) => {
    setAllFieldsIsValidORnot(value);
  };

  const MarkAsFixedPopUp = (e: any) => {
    setOpen(true);
    setAlertId(e.id);
  };

  const processQueryUrl = () => {
    let _q = getDecodeURI(location?.search);
    let _sort = sort, _sortOrder = sortOrder, _search = '';
    //  _filterBy=""
    //  ;
    if (_q.hasOwnProperty("sort") && '' != _q.sort) {
      setSort(_q.sort);
      _sort = sort;
    } else if (DEFAULT_SORT !== sort) {
      setSort(DEFAULT_SORT);
      _sort = DEFAULT_SORT;
    }

    if (_q.hasOwnProperty("sortOrder") && '' != _q.sortOrder) {
      setSortOrder(_q.sortOrder);
      _sortOrder = parseInt(_q.sortOrder);
    } else if (DEFAULT_SORT_ORDER != sortOrder) {
      setSortOrder(DEFAULT_SORT_ORDER);
      _sortOrder = DEFAULT_SORT_ORDER;
    }

    if (_q.hasOwnProperty("search") && '' != _q.search) {
      // if(filterBy && filterBy === "systemType"){
      //   console.log("Hereeee")
      //   setSearch("");
      //   _search = "";
      //   setFilterBy("");
      //   _filterBy = "";
      // }else{
        setSearch(_q.search);
        _search = _q.search;
      // }
    } else if ("" !== _q.search) {
      setSearch("");
      _search = "";
    }

    // if (_q.hasOwnProperty("filterBy") && '' != _q.filterBy) {
    //   setFilterBy(_q.filterBy);
    //   _filterBy = _q.filterBy;
    // }
    //  else if ("" !== _q.filterBy) {
    //   setFilterBy("");
    //   _filterBy = "";
    // }
    

    let _page = _q.page && '' != _q.page.trim() ? parseInt(_q.page) : 0;
    if (_page != page) {
      setPage(_page);
    }

    let _limit = _q.limit && '' != _q.limit.trim() ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE;
    if (_limit != limit) {
      setLimit(_limit);
    }
    let ouName = authReducer?.userOu?.name;
    if (_q.hasOwnProperty("ouName") && '' != _q.ouName) {
      ouName = _q.ouName;
    }

    let vessels: any[] = [];
    if (_q.hasOwnProperty("k4Ids") && '' != _q.k4Ids.trim()) {
      vessels = strToK4List(_q.k4Ids);
    }

    callGetAlertPageAPI(vessels, _page, _limit, _search, _sort, _sortOrder, ouName);
  }

  const callGetAlertPageAPI = (vessels: any[], _page, _limit, _search, _sort, _sortOrder, _ouName) => {
    const parameters: QueryParameters = {
      locationId: vessels.map(v => `'${v}'`).join(),
      state: `and state IN ('alerting')`,
      severity: `and severity IN('critical')`,
      alert: "",
      sortBy: _sort,
      order: _sortOrder,
      pageStart: _page,
      limit: _limit,
      startTime: "",
      endTime: "",
      search: "",
      active: "and rn=1 and state='alerting'"
    }
    if (_ouName && '' != _ouName.trim()) {
      getVesselAlerts(parameters);
    }
  }

  useEffect(() => {
    if (!_.isEmpty(authReducer.searchAlerts) &&
      authReducer.searchAlerts && authReducer?.searchAlerts?.data?.getVesselAlerts?.alerts) {
      structuredData.length = 0;
      let data = authReducer.searchAlerts?.data?.getVesselAlerts?.alerts;
      if (!data) data = [];
      updatePageInfo(page, limit, data.length, authReducer.searchAlerts?.data?.getVesselAlerts?.pages,
        authReducer?.searchAlerts?.data?.getVesselAlerts?.total, "Alerts");
      let alerts = data.map(alert => prepareAlertInfo(alert));
      setStructuredData(alerts);
      authReducer.searchAlerts = {};
    }
  }, [authReducer.searchAlerts]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.markAsFixedAlert) && authReducer?.markAsFixedAlert?.data?.closeVesselAlert?.alert) {
      setComment("");
      let vessels = authReducer.selectedVessels.isAll?[]:authReducer.selectedVessels.vessels;
      callGetAlertPageAPI(vessels, page, limit, search, sort, sortOrder, authReducer?.selectedOu?.name);
      authReducer.markAsFixedAlert = {};
    }
  }, [authReducer.markAsFixedAlert]);

  const doNavigate = (pathname, params = {}) => {
    history.push({ pathname: pathname, search: `?${getEncodedURI(params)}` });
  }

  function prepareAlertInfo(alert: any) {
    let processedAlert = {};
    if (alert) {
      let opendate = "", lastupdated = "";
      if (alert.opendate && "" !== alert.opendate.trim()) {
        
        //Converted to 24hrs date format and UTC DateTime
        opendate = moment(alert.opendate)
          //.tz(DEFAULT_TIMEZONE)
          .utc()
          .format(MMDDYYYYHMMSS_DATE_FORMAT_24_HRS);
      }

      if (alert.lastupdatedon && "" !== alert.lastupdatedon.trim()) {

        //Converted to 24hrs date format and UTC DateTime
        lastupdated = moment(alert.lastupdatedon)
          //.tz(DEFAULT_TIMEZONE)
          .utc()
          .format(MMDDYYYYHMMSS_DATE_FORMAT_24_HRS);
      }
      processedAlert = createData(
        alert.alert,
        alert.vessel,
        alert.device ? alert.device.name : '',
        opendate,
        lastupdated,
        alert.severity ? alert.severity : "Normal",
        alert.description ? alert.description : "",
        alert.status,
        alert.id,
        alert.location?.id
      );
    }
    return processedAlert;
  }

  function createData(name, vesselname, deviceName, opendate, lastupdated, severity, details, status, id, locationId) {
    return { name, vesselname, deviceName, opendate, lastupdated, severity, details, status, id, locationId };
  }

  return (
    <div className="Form">
      <Grid className="am-table">
        <TableContainer className="FleetDashboard">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow
                style={{
                  borderBottom: "1.1px solid #676984",
                  fontWeight: 700,
                  fontSize: "12px",
                }}
              >
                <TableCell className="fleetDataTable" align="left">
                  <span className="align-items-center">
                    <span style={{ cursor: 'pointer' }} onClick={(event) => switchSorting(event, "severity")}>SEVERITY</span>
                    <div className="sort-icon-margin">
                      <img src={sort === "severity" && 1 === sortOrder ? ascSortingSelected : ascSorting} alt="Severity ascending"
                        className="ascendingOrderIcon sortingImages" onClick={(event) => setSortingField(event, "severity", 1)} />
                      <img src={sort === "severity" && 0 === sortOrder ? descSortingSelected : descSorting} alt="Severity descending"
                        className="sortingImages" onClick={(event) => setSortingField(event, "severity", 0)} />
                    </div>
                  </span>
                </TableCell>
                <TableCell className="fleetDataTable">
                  <span className="align-items-center">
                    <span style={{ cursor: 'pointer' }} onClick={(event) => switchSorting(event, "opendate")}>REPORTED ON</span>
                    <div className="sort-icon-margin">
                      <img src={sort === "opendate" && 1 === sortOrder ? ascSortingSelected : ascSorting} alt="Created on ascending"
                        className="ascendingOrderIcon sortingImages" onClick={(event) => setSortingField(event, "opendate", 1)} />
                      <img src={sort === "opendate" && 0 === sortOrder ? descSortingSelected : descSorting} alt="Created on descending"
                        className="sortingImages" onClick={(event) => setSortingField(event, "opendate", 0)} />
                    </div>
                  </span>
                </TableCell>
                {/* <TableCell className="fleetDataTable">
                  <span>
                    <span style={{ cursor: 'pointer' }} onClick={(event) => switchSorting(event, "lastupdatedon")}>LAST UPDATED ON</span>
                    <div>
                      <img src={sort === "lastupdatedon" && 1 === sortOrder ? ascSortingSelected : ascSorting} alt="Last updated on ascending"
                        className="ascendingOrderIcon sortingImages" onClick={(event) => setSortingField(event, "lastupdatedon", 1)} />
                      <img src={sort === "lastupdatedon" && 0 === sortOrder ? descSortingSelected : descSorting} alt="Last updated on descending"
                        className="sortingImages" onClick={(event) => setSortingField(event, "lastupdatedon", 0)} />
                    </div>
                  </span>
                </TableCell> */}
                <TableCell align="center" className="fleetDataTable">
                  <span>
                    <span style={{ cursor: 'pointer' }} onClick={(event) => switchSorting(event, "vessel")}>SITE</span>
                    {/* <div>
                      <img src={sort === "vessel" && 1 === sortOrder ? ascSortingSelected : ascSorting}
                        className="ascendingOrderIcon sortingImages" onClick={(event) => setSortingField(event, "vessel", 1)} />
                      <img src={sort === "vessel" && 0 === sortOrder ? descSortingSelected : descSorting}
                        className="sortingImages" onClick={(event) => setSortingField(event, "vessel", 0)} />
                    </div> */}
                  </span>
                </TableCell>
                {/* <TableCell align="center" className="fleetDataTable">
                  <span>
                    <span style={{ cursor: 'pointer' }} onClick={(event) => switchSorting(event, "vessel")}>DEVICE NAME</span>
                    <div>
                      <img src={sort === "device" && 1 === sortOrder ? ascSortingSelected : ascSorting}
                        className="ascendingOrderIcon sortingImages" onClick={(event) => setSortingField(event, "device", 1)} />
                      <img src={sort === "device" && 0 === sortOrder ? descSortingSelected : descSorting}
                        className="sortingImages" onClick={(event) => setSortingField(event, "device", 0)} />
                    </div>
                  </span>
                </TableCell> */}
                <TableCell align="center" className="fleetDataTable">
                  <span className="align-items-center">
                    <span style={{ cursor: 'pointer' }} onClick={(event) => switchSorting(event, "alert")}>ALERT NAME</span>
                    <div className="sort-icon-margin">
                      <img src={sort === "alert" && 1 === sortOrder ? ascSortingSelected : ascSorting} alt="Alert name ascending"
                        className="ascendingOrderIcon sortingImages" onClick={(event) => setSortingField(event, "alert", 1)} />
                      <img src={sort === "alert" && 0 === sortOrder ? descSortingSelected : descSorting} alt="Alert name descending"
                        className="sortingImages" onClick={(event) => setSortingField(event, "alert", 0)} />
                    </div>
                  </span>
                </TableCell>
               
                <TableCell align="center" className="fleetDataTable">STATUS</TableCell>
                <TableCell align="center" className="fleetDataTable">Action</TableCell>
                <TableCell align="center" className="fleetDataTable">DETAILS</TableCell>
                {/* <TableCell align="center" className="fleetDataTable">History</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {structuredData.map((row, idx) => (
                <TableRow
                  style={
                    idx % 2
                      ? {
                        background:
                          "linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%)",
                      }
                      : { background: "white" }
                  }
                  className="fleetRowRank"
                  key={idx}
                >
                  <TableCell component="th" scope="row" align="left">
                    <div className="componentCatergory">{row?.severity}</div>
                  </TableCell>
                  <TableCell align="left" className="fleetRowDataDetails">
                    <span style={{ display: "inline", whiteSpace: "nowrap" }}>{row?.opendate}</span>
                  </TableCell>
                  {/* <TableCell align="left" className="fleetRowDataDetails">
                    <span style={{ display: "inline", whiteSpace: "nowrap" }}>{row?.lastupdated}</span>
                  </TableCell> */}
                  <TableCell align="left" className="fleetRowDataDetails">
                    <span style={{ display: "inline", whiteSpace: "nowrap" }}>{row?.vesselname}</span>
                  </TableCell>
                  {/* <TableCell align="left" className="fleetRowDataDetails">
                    <span style={{ display: "inline", whiteSpace: "nowrap" }}>{row?.deviceName}</span>
                  </TableCell> */}
                  <TableCell align="left" className="fleetRowDataDetails">{row?.name}</TableCell>
              
              
                  <TableCell align="center">
                    {row?.status && "alerting" == row?.status ? (
                      <img
                        src={errorAlert}
                        className="errCorMesg"
                      />
                    ) : (
                      <img
                        src={correctAlert}
                        className="errCorMesg"
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row?.status && "alerting" == row?.status ? (
                      <Button
                        className="componentStatus"
                        title="Mark as fixed"
                        onClick={() => MarkAsFixedPopUp(row)}
                      >
                        Mark as fixed
                      </Button>
                    ) : (
                      <div></div>
                    )}
                  </TableCell>
                  <TableCell align="center" className="fleetRowDataDetails">
                    <JsonTooltip className="prettyToolTip" json={row?.details} />
                  </TableCell>
                  {/* <TableCell align="center">
                    <img
                      src={arrowAlert}
                      style={{
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                      }}
                      title={"history"}
                      onClick={() => handleAlertHistory(row)}
                    />
                  </TableCell> */}
                </TableRow>
              ))}

              {0 === structuredData.length ? (
                <TableRow>
                  <TableCell colSpan={9} align="center" className="noDataAvailableCss">
                    {authReducer.loading ? loaders["loadingText"] : 'No alerts available'}
                  </TableCell>
                </TableRow>)
                : null
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog onClose={handleClose} open={open}>
          <DialogTitle
            style={{
              fontWeight: 500,
              fontSize: "20px",
              width: "350px",
              background: "#264C86",
            }}
          >
            <Grid item container style={{ color: "#fff", fontSize: "16px" }}>
              Mark alert as fixed
              <Grid style={{ marginLeft: "auto" }}>
                <img
                  src={close}
                  onClick={handleClose}
                  style={{ width: "16px", height: "16px" }}
                />
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent style={{ height: "max-content" }}>
            <Typography
              style={{
                fontWeight: 400,
                fontSize: "14px",
                color: "#676984",
                marginTop: "16px",
                marginRight: "16px",
              }}
            >
              Remark
            </Typography>
            <Grid style={{ marginTop: "5px" }}>
              <TextField
                className="customTextArea"
                style={{ height: "40px !important", width: "100%" }}
                id="outlined-multiline-static"
                multiline
                rows={4}
                placeholder="Enter the alert remark"
                required
                name="comment"
                value={comment}
                onChange={handleChange}
              />
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              marginLeft: "16px",
              marginRight: "18px",
              marginBottom: "10px",
            }}
          >
            <Button onClick={handleClose} className="Cancel-button">Cancel</Button>
            <Button
              onClick={handleMarkAsFixed}
              className="send-button"
              style={{
                backgroundColor: !allFieldsIsValidORnot ? "#dbdde4" : "#264C86",
              }}
              disabled={!allFieldsIsValidORnot}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      {
        openHistory ?
          <AlertHistory onClose={handleHistoryClose} open={openHistory} AlertIdHistory={alertIdHistoryPopUp} locationId={alertLocationId}/>
          :
          <></>
      }
    </div >
  );
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    getVesselAlerts,
    CloseVesselAlert,
  })(AlertTable)
);
