import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import close from "../../../asset/image/close.svg";
import AutoCompleteComponent from "./AutoComplete";
import deleteIcon from "../../../asset/image/configDelete.svg";
import Tooltip from '@mui/material/Tooltip';
import { createDefaultConfig, getInventoryDeployedData, backupConfigData, getDefaultProducts, editDefaultConfig } from '../../../actions/Users/authenticateInventory';
import { GET_INVENTORY_DEPLOYED_DATA } from "../../../actions/types";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import _ from "lodash";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../../config";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";
import { convertDateTimeIntoTimezone } from "../../../utils/util";

const EditDefaultConfig = (props) => {
    const { authReducer, createConfigPopup, setCreateConfigPopup, editPopup, setEditPopup, ou, createDefaultConfig, getInventoryDeployedData, backupConfigData, configDetails, getDefaultProducts, getConfigSoftwareVersions, editDefaultConfig } = props;
    const [createTable, setCreateTable] = useState<any>([]);
    const [searchValue, setSearchValue] = useState<any>('');
    const [deployedData, setDeployedData] = useState<any>([]);

    const [productType, setProductType]=useState<any>([]);
    const [config, setConfig]=useState<any>([]);

    const [active, setActive] = useState<any>(configDetails?.active == true ? true : false);
    const [k4Ids, setK4ids] = useState('');
    const VIEW_INVENTORY = "view-inventory";
    const viewDeployedService = SERVICE_FUNCTION_TO_FEATURE[VIEW_INVENTORY]['service'];
    const viewDeployedFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_INVENTORY]['function'];

    useEffect(() => {
        if (!_.isEmpty(authReducer.getVesselsListing)) {
            const sites = authReducer?.getVesselsListing?.hasOwnProperty("locations") ? authReducer?.getVesselsListing?.locations : [];
            if (sites?.length == 0) {
                setK4ids("");
                return;
            }
            const k4Ids = sites && sites.length > 0 ? `${sites.map((item: any) => item.id).join(",")}` : '';
            setK4ids(k4Ids);
        }
    }, [authReducer.getVesselsListing])

    useEffect(() => {
        if (_.isEmpty(authReducer?.getVesselsListing))
        return;
        let vessels = [];
        const filteredVessels = authReducer?.filteredVessels;
        if (filteredVessels && filteredVessels.length > 0) {
            vessels = filteredVessels;
        } else {
            vessels = authReducer?.getVesselsListing?.locations;
        }
        const payload = {
            pageStart: 0,
            pagesize: 500,
            search: '',
            sortBy : 'name',
            sortOrder: 'asc',
            dp: '',
            k4Ids: vessels
        }
        vessels.length > 0  && getInventoryDeployedData(payload, GET_INVENTORY_DEPLOYED_DATA);
        editPopup && getDefaultProducts(configDetails?.id);
    },[authReducer?.getVesselsListing, authReducer?.filteredVessels]);

    useEffect(() => {
        if (!_.isEmpty(authReducer.getInventoryDeployedData)) {
            const data = authReducer?.getInventoryDeployedData?.hasOwnProperty("data") ? authReducer?.getInventoryDeployedData?.data : [];
            const deployedData = data?.rows?.map((item) => {
                return data?.columns?.reduce((acc, columItem, i) => {
                    acc[columItem] = item[i];
                    return acc;
                }, {})
            })
            setDeployedData(deployedData);
            authReducer.getInventoryDeployedData = {};
        }
    }, [authReducer.getInventoryDeployedData])

    useEffect(() => {
        if (!_.isEmpty(authReducer.backupConfigData)) {
            const data = authReducer?.backupConfigData?.result;
            setConfig(data?.map((ele) => {
                return { label: ele.config_name, id: ele.id, details: ele }
            }));
            authReducer.backupConfigData = {};
        }

        if (!_.isEmpty(authReducer.getDefaultProducts)) {
            const data = authReducer.getDefaultProducts;
            if (data?.length) {
                const defaultProducts = data.map((ele) => { return { deviceName: { label: ele.device_name, id: ele.device_id }, name: { label: ele.site_name, id: ele.site_id }, systemType: ele.device_type, config: { label: ele.config_name, id: ele.config_id }, softwareVersion: ele.software_version, create_time: ele.create_time } })
                editPopup && setCreateTable([...defaultProducts])
                setSearchValue(configDetails?.name);
            }
            authReducer.getDefaultProducts = {};
        }
    }, [authReducer.backupConfigData, authReducer.getDefaultProducts])

    const getUniqueArray = (arr) => {
        return Array.from(new Set(arr))
    }

    const handleChangeInput = (e, newValue, i ,key) => {
        setConfig([]);
        const dataArray: any[] = [...createTable];
        const EmptyObj = { label: '', id: '' }
        let selectedValue = { systemType: '' , config: EmptyObj };
        selectedValue = createTable[i] ? { ...selectedValue, ...createTable[i], ...{config: EmptyObj} } : selectedValue;
       
        selectedValue[key] = e.target.innerText ? newValue : '';

        const siteArray: any = [];
        const typeArray: any = [];

        if (selectedValue.systemType != "") {
            deployedData?.forEach((ele) => {
                if (selectedValue.systemType == ele.systemType)
                    siteArray.push({ label: ele['name'], id: ele['k4Id'] });
            })
            selectedValue = { ...selectedValue, ...{ config: EmptyObj } };
        } else {
            deployedData?.forEach((ele) => {
                typeArray.push(ele?.systemType);
            })
            selectedValue = { ...selectedValue, ...{ config: EmptyObj } };
            setProductType(getUniqueArray(typeArray))
        }
       
        dataArray[i] = selectedValue;
        setCreateTable([...dataArray]);
        if (selectedValue.systemType != '') {
            // show only manual backups
            let manual = true
            backupConfigData(k4Ids, -1, -1, '', 'create_time', 'desc', '', selectedValue.systemType, '', manual)
        }
    }

    const handleChangeConfig = (e, newValue, i, name) => {
        const dataArray: any[] = [...createTable];
        const EmptyObj = { label: '', id: ''};
        let selectedValue = { deviceName: EmptyObj, name: EmptyObj, systemType: '', config: EmptyObj, softwareVersion: '' };
        selectedValue = createTable[i] ? { ...selectedValue, ...createTable[i] } : selectedValue;
        selectedValue[name] = e.target.innerText ? newValue : EmptyObj;
        if(!_.isEmpty(newValue)){
            selectedValue["deviceName"] = {
                "label" : _.isEmpty(newValue.details)?"":newValue.details.device_name,
                "id": _.isEmpty(newValue.details)?"":newValue.details.device_id
            }
            selectedValue["name"] = {
                "label" : _.isEmpty(newValue.details)?"":newValue.details.site_name,
                "id": _.isEmpty(newValue.details)?"":newValue.details.site_id
            }
            selectedValue["softwareVersion"]= _.isEmpty(newValue.details)?"":newValue.details.software_version
            selectedValue["create_time"]= _.isEmpty(newValue.details)?"":newValue.details.create_time
        }

        dataArray[i] = selectedValue;
        setCreateTable([...dataArray]);    
    }

    const handleAdd = (ele, i)=> {
        let newproductType = productType.filter(value => value !== null)
        return <TableRow className="UsageReports-tableRow config-tableRow default-config-autoComplete">
            {newproductType  && newproductType.length > 0  ? <TableCell className="config-left-align select-product-td"><AutoCompleteComponent options={newproductType} onChange={(e,newValue) => handleChangeInput(e, newValue,i, 'systemType')} value={ele?.systemType} data={createTable} /></TableCell> : <TableCell>No Data</TableCell>}
            {config && config.length > 0  ? <TableCell className="config-left-align select-product-td"><AutoCompleteComponent options={config} onChange={(e, newValue) => handleChangeConfig(e, newValue, i, 'config')} value={ele?.config?.label} data={createTable} /></TableCell> : <TableCell>No Data</TableCell>}
            <TableCell className="config-left-align select-product-td">
                { 
                    ele?.config?.label
                        ? <div className="selected-config-details">
                            <div>Site: <div className="value" title={ele.name.label}>{ele?.name?.label}</div></div>
                            <div>Device: <div className="value" title={ele.deviceName.labe}>{ele?.deviceName?.label}</div></div>
                            <div>EOS Version: <div className="value" title={ele.softwareVersion}>{ele?.softwareVersion}</div></div>
                            <div>Creation Date:<div className="value" title={convertDateTimeIntoTimezone(ele?.create_time, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}>{convertDateTimeIntoTimezone(ele.create_time, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</div></div>
                        </div>
                        : <></>
                }
            </TableCell>
            <TableCell className="config-left-align select-product-td" onClick={() => handleDeleteClick(i)} >
                <Tooltip title='Delete'><img src={deleteIcon} alt="delete" /></Tooltip>
            </TableCell>
        </TableRow> 
    }

    const handleAddClick = () => {
        const EmptyObj = { label: '', id: ''}
        setCreateTable([...createTable,{ deviceName: EmptyObj, name: EmptyObj, systemType: '' , config: EmptyObj, softwareVersion: ''}])
        const productTypes:any = [];
        deployedData?.forEach((el) => {
            productTypes.push(el['systemType']);
        })
        setProductType(getUniqueArray(productTypes));
    }

    const handleDeleteClick = (i) => {
       const deleteRow = [...createTable];
       deleteRow.splice(i, 1);
       setCreateTable(deleteRow)
    }

    const handleCreateAndEditConfig = (action) => {
        const products:any = [];
        const payload = {
            name : searchValue,
            active : active,
            products
        }
        createTable.length && createTable?.forEach((ele) => {
            payload.products.push(
                { 
                    device_type: ele.systemType.includes('K4') ? ele.systemType : `K4 ${ele.systemType}`,
                    config_id: ele.config['id']
                }
            )
        })
        action == 'create' ? createDefaultConfig(authReducer?.selectedOu?.id, payload) : editDefaultConfig(configDetails.dp_id, configDetails?.id, payload);
    }

    return (
        <div className="Form">
            <Dialog 
                open={editPopup ? editPopup : createConfigPopup}
                onClose={() => { editPopup ? setEditPopup (false) : setCreateConfigPopup(false) }}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                className='create-default-config-container'
            >
                <div>
                    <DialogTitle className='create-default-config-title'>
                        { editPopup ? <span>{`Edit Default Configuration (${ou})`}</span> :<span>{`Create Default Configuration (${ou})`}</span> }
                        <img src={close} title="Close" onClick={() => { editPopup ? setEditPopup (false) : setCreateConfigPopup(false) }} />
                    </DialogTitle>
                    <DialogContent className='create-default-config-content'>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='name-input-default-config name-margin-top'>
                                <span>Name</span>
                                <Grid item xs={6} sm={6} md={4} lg={4}> 
                                    <Box>
                                        {<TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            placeholder="Enter Name"
                                            onChange={(event) => {
                                                setSearchValue(event.target.value);
                                            }}
                                            value={searchValue}
                                            size="small"
                                            InputProps={{
                                                className: "serachBar name-config-default",
                                            }}
                                        />}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='name-input-default-config'>
                                <span>Set Active</span>
                                <div className="radioButton config-radio-button"> <input type="radio" onChange={() => setActive(!active)} checked={active}/> <span>Yes</span> </div>
                                <div className="radioButton config-radio-button"> <input type="radio" onChange={() => setActive(!active)} checked={!active} /> <span>No</span> </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='name-input-default-config create-config-table'>
                                    <span className="config-select-product">Select Default Configuration</span>
                                    <Tooltip title={<Typography>select default configuration to use from existing saved/backup configurations. Note that configurations are product and software release specific.</Typography>}>
                                        <div className="help-icon-create"><HelpOutlineIcon /></div>
                                    </Tooltip>
                                <TableContainer className="usageReports-FleetDashboard golden-config-table-container create-config">
                                    <Table aria-label="simple sticky table" stickyHeader>
                                        <TableHead className="usageReports-tableHead">
                                            <TableRow className="UsageReports-tableRow config-tableRow">
                                                <TableCell className="usage-fleetDataTable create-select-product">Select Product</TableCell>
                                                <TableCell className="usage-fleetDataTable create-select-product">Select Config</TableCell>
                                                <TableCell className="usage-fleetDataTable create-select-product">Selected Config Details</TableCell>
                                                <TableCell className="usage-fleetDataTable create-select-product config-tableCell">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="tableBody">   
                                            {editPopup && createTable?.map((prod, i) => {
                                                return <TableRow key={i} className="UsageReports-tableRow config-tableRow default-config-autoComplete">
                                                    <TableCell className="config-left-align select-product-td"><AutoCompleteComponent options={productType} onChange={(e, newValue) => handleChangeInput(e, newValue, i, 'systemType')} value={prod?.systemType} data={createTable} /></TableCell>
                                                    <TableCell className="config-left-align select-product-td"><AutoCompleteComponent options={config} onChange={(e, newValue) => handleChangeConfig(e, newValue, i, 'config')} value={prod?.config?.label} data={createTable} /></TableCell>
                                                    <TableCell className="config-left-align select-product-td" >
                                                        {prod?.config?.label
                                                            ? <div className="selected-config-details">
                                                                <div>Site: <div className="value" title={prod.name?.label}>{prod.name.label}</div></div>
                                                                <div>Device: <div className="value" title={prod.deviceName.label}>{prod.deviceName.label}</div></div>
                                                                <div>EOS Version: <div className="value" title={prod.softwareVersion}>{prod.softwareVersion}</div></div>
                                                                <div>Creation Date:<div className="value" title={convertDateTimeIntoTimezone(prod.create_time, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}>{convertDateTimeIntoTimezone(prod.create_time, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</div></div>
                                                            </div> : <></>
                                                        }
                                                    </TableCell>
                                                    <TableCell className="config-left-align select-product-td" onClick={() => handleDeleteClick(i)}>
                                                        <Tooltip title='Delete'><img src={deleteIcon} alt="delete" /></Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            })}
                                            {!editPopup && deployedData && createTable?.map((ele, i) => handleAdd(ele, i) ) }
                                        </TableBody>
                                    </Table>
                                    <Grid className={createTable.length > 0 ? "dynamic-create-row-config" : 'empty-dynamic-create-row-config'} >
                                        {!authReducer?.inventoryListingLoading? <Button onClick={handleAddClick}>+Add</Button>: <span>Loading ...</span> }
                                    </Grid>
                                </TableContainer>
                            </Grid>
                            <DialogActions className="config-create-actions">
                                <Button className="config-cancel-btn" onClick={() => { editPopup ? setEditPopup (false) : setCreateConfigPopup(false) }}>Cancel</Button>
                                <Button className="config-create-btn" onClick={() => editPopup? handleCreateAndEditConfig('edit') :handleCreateAndEditConfig('create')}
                                disabled={(searchValue && createTable[0]?.systemType && createTable[0]?.config['id']) ? false : true}
                                style={{ backgroundColor: (searchValue && createTable[0]?.systemType && createTable[0]?.config['id']) ? "#264C86" : "#E8F0FD",
                                color: (searchValue && createTable[0]?.systemType && createTable[0]?.config['id']) ? "#fff" : "#264C86" }}
                                > {editPopup?"Save":"Create"}</Button>

                            </DialogActions>
                        </Grid>
                    </ DialogContent>
                </div>
            </Dialog>
        </div>
    )
}


const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
  });

  export default withRouter(
    connect(mapStateToProps, { 
        createDefaultConfig, getInventoryDeployedData, backupConfigData, getDefaultProducts, editDefaultConfig
    })(EditDefaultConfig)
  )
