export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_ERROR = "FETCH_DATA_ERROR";
export const FETCH_TOWER_DETAILS = "FETCH_TOWER_DETAILS";
export const FETCH_TOWER_DETAILS_SUCCESS = "FETCH_TOWER_DETAILS_SUCCESS";
export const FETCH_VESSEL_NAME = "FETCH_VESSEL_NAME";
export const FETCH_VESSEL_NAME_SUCCESS = "FETCH_VESSEL_NAME_SUCCESS";
export const FETCH_VESSEL_VOYAGE = "FETCH_VESSEL_VOYAGE";
export const FETCH_VESSEL_VOYAGE_SUCCESS = "FETCH_VESSEL_VOYAGE_SUCCESS";
export const FETCH_VESSEL_VOAYGE_STARLINK_REQUEST = "FETCH_VESSEL_VOAYGE_STARLINK_REQUEST";
export const FETCH_VESSEL_VOYAGE_STARLINK_SUCCESS = "FETCH_VESSEL_VOYAGE_STARLINK_SUCCESS";

export const SET_EMPTY_VESSEL_VOYAGE = "SET_EMPTY_VESSEL_VOYAGE";
export const FETCH_SELCTED_VESSEL_VOYAGE_SUCCESS = "FETCH_SELCTED_VESSEL_VOYAGE_SUCCESS";
export const FETCH_SELCTED_VESSEL_VOYAGE_REQUEST = "FETCH_SELCTED_VESSEL_VOYAGE_REQUEST";
export const FETCH_SELECTED_SITE_SERVICE_LINE_DATA_SUCCESS = "FETCH_SELECTED_SITE_SERVICE_LINE_DATA_SUCCESS";
export const FETCH_SELECTED_SITE_SERVICE_LINE_DATA = "FETCH_SELECTED_SITE_SERVICE_LINE_DATA";
export const FETCH_VESSEL_KPI_DATA = "FETCH_VESSEL_KPI_DATA";
export const FETCH_VESSEL_KPI_DATA_SUCCESS = "FETCH_VESSEL_KPI_DATA_SUCCESS";
export const FETCH_VESSEL_KPI_DATA_RATE_SUCCESS = "FETCH_VESSEL_KPI_DATA_RATE_SUCCESS";
export const FETCH_VESSEL_KPI_DATA_RTT_SUCCESS = "FETCH_VESSEL_KPI_DATA_RTT_SUCCESS";
export const FETCH_VESSEL_KPI_DATA_LINK_STATUS_SUCCESS = "FETCH_VESSEL_KPI_DATA_LINK_STATUS_SUCCESS";
export const FETCH_CARRIER_DATA = "FETCH_CARRIER_DATA";
export const FETCH_CARRIER_DATA_SUCCESS = "FETCH_CARRIER_DATA_SUCCESS";
export const FETCH_CARRIER_DATA_REQUEST = "FETCH_CARRIER_DATA_REQUEST";
export const FETCH_CARRIER_DATA_INNER_LAYER_SUCCESS = "FETCH_CARRIER_DATA_INNER_LAYER_SUCCESS";
export const FETCH_CARRIER_DATA_INNER_LAYER_REQUEST = "FETCH_CARRIER_DATA_INNER_LAYER_REQUEST";
export const FETCH_VESSEL_CURRENT_LOCATION_SUCCESS = "FETCH_VESSEL_CURRENT_LOCATION_SUCCESS";
export const FETCH_VESSEL_CURRENT_LOCATION = "FETCH_VESSEL_CURRENT_LOCATION";
export const FETCH_TERMINAL_CURRENT_LOCATION_SUCCESS = "FETCH_TERMINAL_CURRENT_LOCATION_SUCCESS";
export const FETCH_TERMINAL_CURRENT_LOCATION = "FETCH_TERMINAL_CURRENT_LOCATION";
export const FETCH_VESSEL_METRICS_INFO_SUCCESS= "FETCH_VESSEL_METRICS_INFO_SUCCESS";
export const FETCH_UNIQUE_CARRIERS = "FETCH_UNIQUE_CARRIERS";
export const FETCH_UNIQUE_CARRIER_SUCCESS="FETCH_UNIQUE_CARRIER_SUCCESS";
export const FETCH_UNIQUE_VSAT_CARRIER_SUCCESS="FETCH_UNIQUE_VSAT_CARRIER_SUCCESS";
export const FETCH_UNIQUE_VSAT_CARRIER_REQUEST="FETCH_UNIQUE_VSAT_CARRIER_REQUEST";
export const FETCH_VESSEL_VOAYGE_REQUEST="FETCH_VESSEL_VOAYGE_REQUEST";
export const FETCH_HEX_SINR_HISTOGRAM_SUCCESS="FETCH_HEX_SINR_HISTOGRAM_SUCCESS";
export const FETCH_HEX_SINR_HISTOGRAM_REQUEST="FETCH_HEX_SINR_HISTOGRAM_REQUEST";
export const FETCH_HEX_RSSI_HISTOGRAM_SUCCESS="FETCH_HEX_RSSI_HISTOGRAM_SUCCESS";
export const FETCH_HEX_RSSI_HISTOGRAM_REQUEST="FETCH_HEX_RSSI_HISTOGRAM_REQUEST";
export const FETCH_HEX_SPEED_HISTOGRAM_SUCCESS="FETCH_HEX_SPEED_HISTOGRAM_SUCCESS";
export const FETCH_HEX_SPEED_HISTOGRAM_REQUEST="FETCH_HEX_SPEED_HISTOGRAM_REQUEST";
export const SET_SELECTED_HEX="SET_SELECTED_HEX";
export const SET_API_ERRORS="SET_API_ERRORS";
export const DELETE_API_ERRORS="DELETE_API_ERRORS";
export const FETCH_VSAT_COVERAGE_DATA_SUCCESS = "FETCH_VSAT_COVERAGE_DATA_SUCCESS";
export const FETCH_VSAT_COVERAGE_DATA_REQUEST = "FETCH_VSAT_COVERAGE_DATA_REQUEST";
export const FETCH_VSAT_COVERAGE_INNER_DATA_SUCCESS = "FETCH_VSAT_COVERAGE_INNER_DATA_SUCCESS";
export const FETCH_VSAT_HISTO_SUCCESS = "FETCH_VSAT_HISTO_SUCCESS";
export const FETCH_VSAT_TX_POWER_HISTO_SUCCESS = "FETCH_VSAT_TX_POWER_HISTO_SUCCESS";
export const GET_VSAT_ALL_HEX_METRICS="GET_VSAT_ALL_HEX_METRICS";
export const SET_ENV = "SET_ENV";
export const  CLEAR_MAP_ERRORS = "CLEAR_MAP_ERRORS";
export const GET_EDGE_EVENTS_SUCCESS="GET_EDGE_EVENTS_SUCCESS";
export const SET_STARTLINK_DATA="SET_STARTLINK_DATA";
export const SET_STARTLINK_DATA_LOADING = "SET_STARTLINK_DATA_LOADING";
export const GET_STARLINK_SPEED_DATA="GET_STARLINK_SPEED_DATA";
export const GET_STARLINK_SPEED_DATA_LOADING="GET_STARLINK_SPEED_DATA_LOADING";
export const GET_STARLINK_HISTO_SUCCESS = "GET_STARLINK_HISTO_SUCCESS";
export const GET_STARLINK_LATENCY_HISTO_SUCCESS = "GET_STARLINK_LATENCY_HISTO_SUCCESS";
export const GET_STARLINK_LATENCY_HISTO_REQUEST = "GET_STARLINK_LATENCY_HISTO_REQUEST";
export const GET_STARLINK_DROPPERCENTAGE_HISTO_SUCCESS = "GET_STARLINK_DROPPERCENTAGE_HISTO_SUCCESS";
export const GET_STARLINK_DROPPERCENTAGE_HISTO_REQUEST = "GET_STARLINK_DROPPERCENTAGE_HISTO_REQUEST";
export const GET_STARLINK_SPEED_HISTO_SUCCESS =  "GET_STARLINK_SPEED_HISTO_SUCCESS";
export const GET_STARLINK_SPEED_HISTO_REQUEST =  "GET_STARLINK_SPEED_HISTO_REQUEST";
export const FETCH_UNIQUE_CARRIER_REQUEST = 'FETCH_UNIQUE_CARRIER_REQUEST';
export const GET_ALL_STARLINK_SERVICE_LINES_REQUEST = 'GET_ALL_STARLINK_SERVICE_LINES_REQUEST';
export const GET_ALL_STARLINK_SERVICE_LINES = 'GET_ALL_STARLINK_SERVICE_LINES';
export const GET_MAP_SITES_STARLINK_COUNT = 'GET_MAP_SITES_STARLINK_COUNT';
export const GET_MAP_SITES_STARLINK_LOADER= 'GET_MAP_SITES_STARLINK_LOADER';