import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContentText, DialogTitle, IconButton, Button } from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';
// import CustomButton from '../CustomButton';
import './DialogBocError.css';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        height: "auto",
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 7px 64px rgba(0, 0, 0, 0.1)',
        borderRadius: '12px',
        padding: '20px',
        border: '2px solid #cacaca'
    },
    dialogPaperClose: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 7px 64px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        paddingTop: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    dialogContent: {
        textAlign: 'center',
        marginTop: '-10px',
        marginBottom: "0px"
    },
    dialogFooter: {
        justifyContent: 'center',
        marginBottom: '-11px'
    },
}));

const DialogBox = (props) => {
    const { onClose, Open, DialogTitle, DialogBody, onClick, ButtonLabel, HrefLink, HrefText  } = props;
    const classes = useStyles();
    return (
        <div className='dialog-box'>
            <Dialog classes={ onClose ? { paper: classes.dialogPaper } : { paper: classes.dialogPaperClose }} open={ Open } >
                <DialogContentText classes={{ root: classes.dialogContent }}>
                    <ErrorOutlineRoundedIcon style={{height: "60px", width: "60px", color: "red"}}/>
                    <div className='dialog-box-title'>
                        { DialogTitle }
                    </div>
                    <div className='dialog-body-content'>
                        { DialogBody }
                    </div>
                    {
                        HrefLink?.length > 0  ?
                            <span><a href={HrefLink}>{HrefText}</a></span>
                            : ''
                    }
                </DialogContentText>
                <DialogActions classes={{ root: classes.dialogFooter }}>
                    {
                        ButtonLabel ?
                            <div className='button'>
                                <Button onClick={ onClick } ButtonLabel={ ButtonLabel } />
                            </div> : ''
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DialogBox;