import { Grid, TableBody, TableRow, TableCell, TableContainer, Table, TableHead, Tooltip, Paper } from "@mui/material";
import "./appUsageCard.css";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { readableBytesAsGB} from "../../utils/util"
import ImageWithFallback from "../ImageWithFallback";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1B51A7' : '#308fe8',
    },
  }));

const AppUsageCard = (props) => {
    const { appDownloadTableData, title, header, tab } = props;

function GetTopAppsImage (props)  {
        const { app } = props;
        if(app) {
            let appName = app.includes(' ') ? app.split(' ')[0]: app.includes('-') ? app.split('-')[0] : ""; 
            return <ImageWithFallback src={`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${app.trim()}`} fallbackSrcs={[`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${appName.trim()}`,"https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png"]} app={app} />
        } else {
            return <img className="logo-activity paddingLogo" src={`https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png`} />
        }
    }

return (
    <div className="Form">
        <Grid className="am-table">
           <Grid container className="AppUsageContainer">
                <Grid>
                    <div className="TopApplicationData">{title}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="AppUsageTableContainer">
                    <TableContainer className="AppUsage-FleetDashboard">
                        <Table aria-label="simple table" style={tab!=='vessel_chart' ? {tableLayout:'fixed'} : {tableLayout: 'auto'}}>
                            <TableHead className="usage-tableHead">
                                <TableRow className="appUsage-tableRow">
                                    <TableCell className={tab!=='vessel_chart' ? "appUsage-fleetDataTable activity-appUsage-fleetDataTable" : "appUsage-fleetDataTable"}>{tab=='top_subCategory' ? 'SUB CATEGORY' : tab=='top_category' ? 'CATEGORY' : tab=='top_domain' ? 'DOMAIN' : tab=='top_ip' ? 'IP' : tab=='vessel_chart' ? 'SITE' : 'APPLICATION'}</TableCell>
                                    { header ? <TableCell align="left" className={tab!=='vessel_chart' ? "appUsage_fleetDataTable activity-appUsage_fleetDataTable" : "appUsage_fleetDataTable"}>{`${header}`}</TableCell> : ''}
                                </TableRow>
                            </TableHead>
                            { appDownloadTableData && 0 != appDownloadTableData.length ? (
                                <TableBody className="tableBody appUsage-tableBody">
                                    {
                                        header!=='HITS' ? 
                                        appDownloadTableData && appDownloadTableData.map((row, i) =>
                                            <TableRow className="tabelRow appUsage-tableRow">
                                                { tab == "top_aaplication" ?
                                                    <TableCell className={tab!=='vessel_chart' ? "appUsage-tableCell activityTableCell-logo activity-appUsage-tableCell" : 'appUsage-tableCell activityTableCell-logo'}>
                                                        <Tooltip title={row.application}>
                                                            <div>
                                                                <GetTopAppsImage app={row.application} />
                                                                <span>{row.application}</span>
                                                            </div>
                                                        </Tooltip>
                                                    </TableCell> : 
                                                    <TableCell className={tab!=='vessel_chart' ? "appUsage-tableCell activityTableCell-logo activity-appUsage-tableCell" : 'appUsage-tableCell activityTableCell-logo'}>
                                                        <Tooltip title={tab!=='vessel_chart' &&  row.application}>
                                                            <div>
                                                                <span>{row.application}</span>
                                                            </div>
                                                        </Tooltip>
                                                    </TableCell>
                                                }
                                                <TableCell className="appUsage_tableCell">
                                                    <TableRow className="usage-innerTableRow">
                                                        <Tooltip title={ tab == 'vessel_chart' &&
                                                            <Paper style={{width:'200px'}}>
                                                                <div className="vessel-chart-usage-cont">
                                                                    {row?.wan_usage?.map((ele) => (
                                                                        <div>
                                                                            <span>{ele['wan_type']}</span>
                                                                            <span>{readableBytesAsGB(ele['usage'])}</span>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </Paper>
                                                            } classes={{ tooltip: "quota_management--usage_tooltip" }} placement="top">
                                                            <TableCell className={tab!=='vessel_chart' ? "usage-innerTableCell activity-usage-innerTableCell" : 'usage-innerTableCell'}>
                                                                <div>
                                                                    <BorderLinearProgress variant="determinate" value={Math.ceil(row.percentage)} />
                                                                </div>
                                                            </TableCell>
                                                        </Tooltip>
                                                        <TableCell className={tab!=='vessel_chart' ? "usage_innerTableCell activity-usage_innerTableCell" : 'usage_innerTableCell'}>
                                                            <span>{readableBytesAsGB(row.download)}</span>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableCell>
                                            </TableRow>
                                        )
                                        : 
                                        appDownloadTableData && appDownloadTableData.map((row, i) =>
                                            <TableRow className="tabelRow appUsage-tableRow">
                                                { tab == "top_aaplication" ?
                                                    <TableCell className={tab!=='vessel_chart' ? "appUsage-tableCell activityTableCell-logo activity-appUsage-tableCell" : 'appUsage-tableCell activityTableCell-logo'}>
                                                        <Tooltip title={row.application}>
                                                            <div>
                                                                <GetTopAppsImage app={row.application} />
                                                                <span>{row.application}</span>
                                                            </div>
                                                        </Tooltip>
                                                    </TableCell> : 
                                                    <TableCell className={tab!=='vessel_chart' ? "appUsage-tableCell activityTableCell-logo activity-appUsage-tableCell" : 'appUsage-tableCell activityTableCell-logo'}>
                                                        <Tooltip title={row.application}>
                                                            <div>
                                                                <span>{row.application}</span>
                                                            </div>
                                                        </Tooltip>
                                                    </TableCell>
                                                }
                                                <TableCell className="appUsage_tableCell"><span className="blockedHits">{row.hits}</span></TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                                ) : (
                                <TableBody>
                                    <TableRow className="noData-appDetails">
                                        <TableCell colSpan={2} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}                                     
                        </Table>
                    </TableContainer>
                </Grid>
           </Grid>
        </Grid>
    </div>
)
}

export default AppUsageCard;