// Table of Summary vessels
import React, { useCallback, useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Accordion, AccordionSummary, Typography, AccordionDetails, Tooltip, IconButton, Chip } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "../AccordionTree/AccordionTree.css";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { EventAlertConfiguration } from "../../UserScreen/CreateAlert/EventAlertConfiguration";
import { AlertConfig, AlertConfigTypeName, AlertConfigTypes, EventAlert, MetricAlert } from "../../UserScreen/CreateAlert/types";
import NormalAlert from "../../asset/image/bar_chart.svg"
import Warning from "../../asset/image/warning.svg"
import Pending from "../../asset/image/Pending.svg"
import MetricAlertConfiguration from "../../UserScreen/CreateAlert/MetricAlertConfiguration";

const ChildAccrodionComp = (props: any) => {

  const { alertId, ruleId, ruleType, configData, handleChange,siteId }: {
    alertId: string,
    ruleId: string,
    ruleType: AlertConfigTypes,
    configData: EventAlert,
    handleChange: (type: AlertConfigTypes, id: string, configData: EventAlert | MetricAlert) => void,
    siteId
  } = props;

  const [ruleExpanded, isRuleExpanded] = useState(false);

  const handleAccordClick = (event, expanded, parentKey) => {
    if (expanded) {
      // call api for parentKey and get child objects
      // console.log("accordion expanded", expanded, parentKey);
    }
  }

  const constructChilds = (childKey) => {
    // charts 

  }


  const handleAccordionMore = (e,alertId ,ruleId) => {
    e.stopPropagation();
    props.setMenuAnchorElement(e.currentTarget)
    props.openAccordianMenu(true,alertId, ruleId)
    // implement accordion menu here
  }


  const getConfig = useCallback(() => {
    let config: Extract<AlertConfig, {type: AlertConfigTypes.Event}> = {
      id: ruleId,
      type: AlertConfigTypes.Event,
      data: configData
    }
    return config
  }, [ruleType, ruleId, configData])

  const [config, setConfig] = useState(getConfig());

  useEffect(() => {
    setConfig(getConfig())
  }, [getConfig])

  const getAlertTextColor = (alertText:any) => {
    switch (alertText.trim()) {
      case "Alerting": return "RedColor"
      case "Normal": return "GreenColor";
      case "Pending": return "OrangeColor"
      default: return "GreenColor";
    }
  }

  const handleChangeEventRule = (id: string, data: EventAlert) => {
    handleChange(AlertConfigTypes.Event, id, data);
    isRuleExpanded(false);
  }

  const handleChangeMetricRule = (id: string, data: MetricAlert) => {
    handleChange(AlertConfigTypes.Metric, id, data);
    isRuleExpanded(false);
  }

  const alertDiv = (status) => {
    switch (status) {
      case "Alerting": return <div className="alertStatusWithIcon"><img src={Warning} className="alertIcon"/> <span className="statusName">Alerting </span></div>;
      case "Normal": return <div className="alertStatusWithIcon"><img src={NormalAlert} className="alertIcon"/><span className="statusName">Normal </span></div> ;
      case "Pending": return  <div className="alertStatusWithIcon"><img src={Pending} className="alertIcon"/><span className="statusName">Pending </span></div> 
      default: return <div className="alertStatusWithIcon"><img src={NormalAlert} className="alertIcon"/><span  className="statusName">Normal </span></div>;
    }
  }
  return (
    <div className="childAccordion">
      <Accordion expanded={ruleExpanded} onChange={() => isRuleExpanded(prev => !prev)}>
        <AccordionSummary className="accordionSummaryAlert childAccord"
          expandIcon={<ExpandMoreIcon className="expandIconAlert"/>}
        >
          <Grid item xs={2} className={`eachChildAccordCol ${getAlertTextColor(configData.status)}`}>
              {alertDiv(configData?.status)}
              </Grid> 
          {/* <Grid item xs={2} className="eachChildAccordCol" >{AlertConfigTypeName.event_alerts}</Grid> */}
          <Grid item xs={2} className="eachChildAccordCol">{ruleType === "event_alerts" ? AlertConfigTypeName.event_alerts : AlertConfigTypeName.metric_alerts}</Grid>
          <Grid item xs={6} className="eachChildAccordCol"><Typography></Typography></Grid>
          <Grid item xs={2} className="eachChildAccordCol"><Typography>
            <IconButton className="accordionThreeDotMenu" onClick={(e) => handleAccordionMore(e, alertId,ruleId)}><MoreVertIcon></MoreVertIcon></IconButton>
          </Typography></Grid>
        </AccordionSummary>
        {ruleType == 'event_alerts'  && <AccordionDetails>
          <EventAlertConfiguration key={config.id} config={config} showHeader={false} isDefaultCardView={true} devices={[]} handleChange={handleChangeEventRule} handleDelete={(id: string) => {}} handleCancel={() => isRuleExpanded(false)} />
        </AccordionDetails>}
        {ruleType == 'metric_alerts'  && <AccordionDetails>
          <MetricAlertConfiguration key={config.id} config={config} showHeader={false} isDefaultCardView={true} deviceInterfaces={""} siteId={siteId} handleChange={handleChangeMetricRule} handleDelete={(id: string) => {}} ></MetricAlertConfiguration>
        </AccordionDetails>}
      </Accordion>

    </div>
  )
}

export default ChildAccrodionComp;