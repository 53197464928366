import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI, getEncodedURI, updateTimePeriod } from "../../utils/util";
import { SIDE_MENU_ITEM_CAPTIVE } from "../../utils/constants";
import CommonFilters from "../../components/BreadCrumbs/CommonFilters"
import NotAuthorized from "../NotAuthorized"
import { updatePermission } from "../../actions/Users/authenticate";
import _ from "lodash";
import TabsComponent from "../../components/NewTabs";
import CaptiveTopWidget from './captiveComponent/topWidget';
import CaptiveCharts from './captiveComponent';
import Table from './captiveComponent/Table';

const CaptiveDashboard = (props) => {
  const { authReducer, updatePermission, history, location } = props;

  const _q = getDecodeURI(location?.search);
  const [selectedTab, setSelectedTab] = useState(_q.hasOwnProperty('tab') ? _q.tab : "sites");
  const [loading, setLoading] = useState();
  const [vessels, setVessels] = useState<any>();
  const [topcardSelected, setTopCardSelected] = useState(_q.hasOwnProperty('topCard') ? _q.topCard : '')
  const dispatch = useDispatch();

  const [isAuthorizedToViewPage, setIsAuthorizedToViewPage] = useState<Boolean>(false);
  const VIEW_VESSEL_SUMMARY = "view-vessel-summary";
  const not_authorized_page_title = "Dashboard";
  const not_authorized_page_message = "You are not authorised to view site dashboard.";

  const viewVesselSummaryService = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["service"];
  const viewVesselSummaryFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["function"];

  if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_CAPTIVE !== authReducer.sideMenu.menuItem)) {
    authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_CAPTIVE };
  }

  useEffect(() => {
    let authorized = checkLoggedInUserAuthorizedToViewPage(viewVesselSummaryService, viewVesselSummaryFunction);
    setIsAuthorizedToViewPage(authorized);
    const info = {
      permission: {
        service: viewVesselSummaryService,
        serviceFunction: viewVesselSummaryFunction,
      },
      isMultiVessel: false,
      isSingleVessel: false,
      showOu: false,
      ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
    };

    if (authorized) {
      info.isMultiVessel = true;
      info.showOu = true;
    }

    updatePermission(info);

    const params = getDecodeURI(location?.search);
    const { startDate, endDate } = updateTimePeriod(dispatch, location);
    params.startDate = startDate;
    params.endDate = endDate;
    history.push({ pathname: location.pathname, search: getEncodedURI(params) });
  }, []);

  useEffect(() => {
    setLoading(authReducer.setCaptiveDashSitesSubscribersSesTopWidgetLoading || authReducer.setCaptiveDashPlansTopWidgetLoading || authReducer.setCaptiveDashUsageTopWidgetLoading ||
      authReducer.setCaptiveDashSitesUsagePieLoading || authReducer.setCaptiveDashSubscribersPieLoading || authReducer.setCaptiveDashSubscribersLineChartLoading ||
      authReducer.setCaptiveDashSessionsLineChartLoading || authReducer.setCaptiveDashUsageLineChartLoading || authReducer.setCaptiveDashSitesTableLoading ||
      authReducer.setCaptiveDashTopSitesSubscribersPieLoading || authReducer.setCaptiveDashTopSitesSessionsPieLoading || authReducer.setCaptiveDashTopSessionsDurationPieLoading)
  }, [authReducer.setCaptiveDashSitesSubscribersSesTopWidgetLoading, authReducer.setCaptiveDashPlansTopWidgetLoading, authReducer.setCaptiveDashUsageTopWidgetLoading,
  authReducer.setCaptiveDashSitesUsagePieLoading, authReducer.setCaptiveDashSubscribersPieLoading, authReducer.setCaptiveDashSubscribersLineChartLoading, authReducer.setCaptiveDashSessionsLineChartLoading,
  authReducer.setCaptiveDashUsageLineChartLoading, authReducer.setCaptiveDashSitesTableLoading, authReducer.setCaptiveDashTopSitesSubscribersPieLoading, authReducer.setCaptiveDashTopSitesSessionsPieLoading,
  authReducer.setCaptiveDashTopSessionsDurationPieLoading])

  const tabs = [
    { tabName: 'Sites', tabValue: 'sites', pathName: '/captive' },
    // { tabName: 'Events', tabValue: 'logs', pathName: '/captive' }
  ]

  const doNavigateTab = (pathname: any, tabName: any) => {
    const currentParams = getDecodeURI(location?.search);
    setSelectedTab(tabName);
    currentParams.tab = tabName;
    const { startDate, endDate } = updateTimePeriod(dispatch, location);
    currentParams.startDate = startDate;
    currentParams.endDate = endDate;
    history.push({ pathname, search: getEncodedURI(currentParams) });
  }

  useEffect(() => {
    if (_.isEmpty(authReducer?.getVesselsListing))
      return;
    let vessels = [];
    const filteredVessels = authReducer?.filteredVessels;
    if (filteredVessels && filteredVessels.length > 0) {
      vessels = filteredVessels?.filter((el) => el?.id !== "");
    } else {
      vessels = authReducer?.getVesselsListing?.locations;
    }
    setVessels(vessels);
  }, [authReducer?.getVesselsListing, authReducer.filteredVessels])

  return (
    <div className="Form">
      <CommonFilters loading={loading} showWantType={false}></CommonFilters>
      <TabsComponent tabs={tabs} handleChangeTab={doNavigateTab} activeTab={selectedTab} />
      <Grid key="dashboard" container style={{ backgroundColor: "#ffffff" }}>
        <Grid key="fleet" item className="threat-dashboard-NewSummary">
          {
            isAuthorizedToViewPage ? [
              <Grid className="new-summary-dash-container">
                <Grid key="SubHeader" className="SubHeader_NewSummary padding-right-17">
                  <CaptiveTopWidget vessels={vessels} topcardSelected={topcardSelected} setTopCardSelected={setTopCardSelected} />
                  <CaptiveCharts vessels={vessels} topcardSelected={topcardSelected} />
                  <Table vessels={vessels} />
                </Grid>
              </Grid>
            ] :
              <Grid key="noitems" item container style={{ position: "fixed", paddingBottom: "16px", backgroundColor: "#ffffff", zIndex: 10, paddingTop: "80px" }}>
                <NotAuthorized pageTitle={not_authorized_page_title} PageMessage={not_authorized_page_message} />
              </Grid>
          }
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, { updatePermission })(CaptiveDashboard)
);