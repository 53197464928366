import { Button, Dialog, Grid, Paper, Typography, DialogActions, DialogTitle, DialogContent } from "@mui/material";
import { Cancel, Delete } from "@material-ui/icons";
import "./DeleteConfirm.css"
import { useEffect, useState } from "react";
interface DeleteConfirmationDialogProps {
    open: boolean;
    contentFilterName?: string;
    contentFilterId?: string;
    onDelete: (arg1:string, arg2:string) => void;
    onCancel: () => void;
    dialogType:string;
}

export function DeleteConfirmationDialog(props: DeleteConfirmationDialogProps) {
    const { open, contentFilterName, contentFilterId, onDelete, onCancel, dialogType } = props;
    
    const [confirmationText, setConfirmationText] = useState<string>("")
    const [buttonText, setButtonText] = useState<string>("")
    
    useEffect(()=>{
        if(dialogType == "delete"){
            setConfirmationText("Are you sure want to delete custom content filter?") 
            setButtonText("Delete")
        } else if(dialogType == "disable") {
            setConfirmationText("Are you sure want to disable custom content filter?") 
            setButtonText("Disable")
        } else if(dialogType == "enable") {
            setConfirmationText("Are you sure want to enable custom content filter?") 
            setButtonText("Enable")
        }
    }, [dialogType])

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className='delete-content-filter-title'>
                        <span className="content-filter-title-name"> Confirmation </span>
            </DialogTitle>
            <DialogContent className="delete-content-filter-dialog-content">
                <div className="delete-form-content-filter-body">
                    
                    <span>{confirmationText} 
                        <br/><br/>{contentFilterName}</span>
                </div>
            </DialogContent>
            <DialogActions className='delete-content-filter-dialog-actions'>
                <Button variant="outlined" className="content_filter--button_outlined_filter" onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="contained" className="content_filter-footer-create-button" onClick={()=>{onDelete(contentFilterId || "", dialogType)}}>{buttonText}</Button>
            </DialogActions>
        </Dialog>
    )
}