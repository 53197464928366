import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';

const MultiColorBar = ({ data }) => {
  const total = data.reduce((acc, item) => acc + item.count, 0);
  const minWidth = 25; // Set a minimum width for small values

  return (
    <Box width="80%"> {/* Ensure the overall bar is 100% wide */}
      <Box display="flex" flexDirection="column" alignItems="center">
        {/* Container for the counts above the bar */}
        <Box display="flex" width="100%">
          {data.map((item, index) => (
            <Box
              key={index}
              width={`${(item.count / total) * 100}%`}
              minWidth={minWidth} // Ensure a minimum width for each segment
              display="flex"
              justifyContent="center"
            >
              <Typography variant="body2" color="black"
              >
                {item.count}
              </Typography>
            </Box>
          ))}
        </Box>

        {/* Color bar below the numbers */}
        <Box display="flex" width="100%" height="10px" border="1px solid #ddd" mt={0.5}>
          {data.map((item, index) => (
            <Box
              key={index}
              width={`${(item.count / total) * 100}%`}
              minWidth={minWidth}  // Minimum width to ensure visibility of small values
              bgcolor={item.color}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default MultiColorBar;
