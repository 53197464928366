import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Grid, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Table, Button } from "@mui/material";
import { Pagination } from "../Pagination";
import ascSortingSelected from "../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../asset/image/descSortingSelected.svg";
import AscSort from "../../asset/image/AscSort.svg";
import DescSort from "../../asset/image/DescSort.svg";
import { getDecodeURI, getEncodedURI } from "../../utils/util";
import { useEffect, useState } from "react";
import _ from "lodash";
import { getContentFilterTopSitesTable } from '../../actions/Users/authenticateContentFilter';
import Download from '../../asset/image/DownloadUsage.svg';
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";
import DownloadCSV, { clearCsvDownload } from "../DownloadCSV";
import { GET_CF_TOP_SITES_TABLE_COUNT, GET_CSV_DATA_TO_DOWNLOAD } from "../../actions/types";
import { toast } from "react-toastify";

const convertToCsvContentFilter = (data: any[][]) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        csvRows.push([row[0], row[2], row[3], row[5], row[6], row[4]])
    })
    return [['Site Name', 'Hits', 'Top User', 'Top Category', 'Top Sub Category', 'Top Domains'], ...csvRows]
}

const ContentFilterTable = (props) => {
    const { authReducer, values, setValues, getContentFilterTopSitesTable, applyFilterClick, newSummaryStartDate, newSummaryEndDate, location, history, downloadAsCSV } = props;
    const { vessels, category, app, user, vlan, device, subCategory, field, order, pageStart, pageLimit} = values;
    const { setCategory, setApp, setUser, setSubCategory, setPageStart, setPageLimit, setField, setOrder } = setValues;
    const SITENAME = 'Site_Name';
    const USERNAME = 'Top_User';
    const HITS = 'total_hits';
    const FQDN = 'fqdn';
    const CATEGORY = 'Top_Category';
    const SUB_CATEGORY = 'Top_SubCategory';
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";
    const [tableData, setTableData] = useState<any>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if(vessels.length == 0) {
            setTableData([]);
            dispatch({
                type: GET_CF_TOP_SITES_TABLE_COUNT, payload: {
                    data: {
                        rows: [[0]]
                    }
                }
            })
        }
     }, [vessels])

    useEffect(() => {
        if(!_.isEmpty(authReducer.getContentFilterTopSitesTable)) {
            const data = authReducer.getContentFilterTopSitesTable.hasOwnProperty('data') ? authReducer.getContentFilterTopSitesTable?.data : {};
            const tableData = data?.rows?.map((item) => {
                return data?.columns?.reduce((acc, columItem, i) => {
                    acc[columItem] = item[i];
                    return acc;
                }, {})
            })
            setTableData(tableData);
        }
    }, [authReducer.getContentFilterTopSitesTable])

    useEffect(() => {
        if (!authReducer.csvDataDownloded || !authReducer.csvDataDownloded.length) return;
        if (authReducer.csvDownloadId !== 'top-sites') return;
        DownloadCSV(convertToCsvContentFilter(authReducer.csvDataDownloded), {
            formatters: {
                3: 'ESC-COMMA',
                4: 'ESC-COMMA',
                5: 'ESC-COMMA'
            }
        });
        return () => {
            dispatch(clearCsvDownload())
        }
    }, [authReducer.csvDataDownloded])

    const handleChangePage = (e, value) => {
        let params: any = getDecodeURI(location?.search);
        value == 1 ? delete params.pageStart : params.pageStart = value;
        setPageStart(value);
        doNavigate(params);
        vessels.length > 0 && getContentFilterTopSitesTable(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, subCategory, value, pageLimit, field, order)
    };

    const handleChangeRowsPerPage = (e) => {
        const Limit = parseInt(e.target.value);
        let params: any = getDecodeURI(location?.search);
        delete params.pageStart;
        Limit == 10 ? delete params.pageLimit : params.pageLimit = Limit
        setPageStart(1);
        setPageLimit(Limit);
        doNavigate(params);
        vessels.length > 0 && getContentFilterTopSitesTable(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, subCategory, 1, Limit, field, order)
    };

    const Sorting = (sortcolumn, sortOrd) => {
        let params: any = getDecodeURI(location?.search);
        delete params.pageStart;
        params.field = sortcolumn;
        params.order = sortOrd;
        const Page = 1;
        setPageStart(1);
        setField(sortcolumn);
        setOrder(sortOrd);
        doNavigate(params);
        vessels.length > 0 && getContentFilterTopSitesTable(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, subCategory, Page, pageLimit, sortcolumn, sortOrd)
    }

    const setSorting = (event: any, Field: any, Order: any,) => {
        if (event && event.currentTarget) {
            Sorting(Field, Order)
        }
    };

    const switchSorting = (event: any, Field: any) => {
        let Order = ASC_ORDER;
        if (field === Field) {
            Order = ASC_ORDER === order ? DESC_ORDER : ASC_ORDER;
        }
        Sorting(Field, Order)
    };

    const handleApplyFilter = (row, key) => {
        switch(key) {
            case 'app': {
                const value = row;
                setApp(value);
                applyFilterClick(newSummaryStartDate, newSummaryEndDate, vessels, category, value, user, vlan, device, subCategory);
                break;
            }
            case 'user': {
                const value = row;
                setUser(value);
                applyFilterClick(newSummaryStartDate, newSummaryEndDate, vessels, category, app, value, vlan, device, subCategory);
                break;
            }
            case 'category': {
                const value = row.Top_Category;
                setCategory(value);
                applyFilterClick(newSummaryStartDate, newSummaryEndDate, vessels, value, app, user, vlan, device, subCategory);
                break;
            }
            case 'subCat': {
                const value = row.Top_SubCategory;
                setSubCategory(value);
                applyFilterClick(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, value);
                break;
            }
            case 'site': {
                const value = [{id: row.location_id, 'vessel-name': row.Site_Name, }];
                applyFilterClick(newSummaryStartDate, newSummaryEndDate, value, category, app, user, vlan, device, subCategory, 'filter');
                break;
            }
        }
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const rowsOption = [10, 15, 25, 50, 100];

    const handleDownloadReport = () => {
        let isClickHouse = true;
        if (vessels.length === 0) {
            toast.error("No data available to download", {
                position: toast.POSITION.BOTTOM_LEFT,
            })
            return;
        }
        if (authReducer?.getContentFilterTopSitesTableCount) {
            const params: CsvParameters = {
                type: isClickHouse ? 'QUERY_CH' : 'QUERY',
                id: 'top-sites',
                queryName: 'CONTENT_FILTER_TOP_SITES_TABLE',
                payload: {
                    start_time: newSummaryStartDate,
                    end_time: newSummaryEndDate,
                    location_ids: vessels && vessels.length > 0 ? `${vessels.map((item: any) => `'${item.id}'`).join(",")}` : '',
                    category: category ? `and category='${category}'` : '',
                    application: app ? `and fqdn='${app}'` : '',
                    hostname: user ? `and (Username LIKE '%${user}%' OR client LIKE '%${user}%' OR mac LIKE '%${user}%')` : '',
                    vlan: vlan ? `and Access_Network='${vlan}'` : '',
                    devicename: device ? `and Device_Name='${device}'` : '',
                    subcategory: subCategory ? `and subcategory='${subCategory}'` : '',
                    sortcolumn: field,
                    sortorder: order,
                    pageoffset: 0,
                    pagesize: authReducer?.getContentFilterTopSitesTableCount?.data?.rows?.[0]?.[0]
                }
            }
            downloadAsCSV(params);
        }
    }

    return (
        <div className="config-container">
            <Grid container spacing={0.5} className="reports-login-history">
            <Grid item xs={12} sm={12} md={12} lg={12} className="firewall-table-download">
                <Grid item xs={6} sm={6} md={6} lg={6} className="Reports-overView-topUsers align-text-center">Top Sites</Grid>
                    {authReducer?.getContentFilterTopSitesTableCount?.data?.rows?.[0]?.[0] > 0 && <Grid item xs={6} sm={6} md={6} lg={6}>
                        <div className="download-summary-btn marg-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                    </Grid>}
            </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner content-filter-table-container">
                    <Paper className="pagination-position config-pagination CF-usage">
                        <Pagination
                            rowsPerPageOptions={rowsOption}
                            count={authReducer?.getContentFilterTopSitesTableCount?.data?.rows?.[0]?.[0]}
                            rowsPerPage={pageLimit}
                            page={pageStart}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer CF-sites">
                        <Table aria-label="simple sticky table" stickyHeader>
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="UsageReports-tableRow login-tableRow">
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                        <span className="table-cell-span align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, SITENAME)}>SITE NAME</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={field === SITENAME && order === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SITENAME, ASC_ORDER)} />
                                                <img src={field === SITENAME && order === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, SITENAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                        <span className="table-cell-span align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, HITS)}>HITS</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={field === HITS && order === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, HITS, ASC_ORDER)} />
                                                <img src={field === HITS && order === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, HITS, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                        <span className="table-cell-span align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, USERNAME)}>TOP USERS</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={field === USERNAME && order === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, USERNAME, ASC_ORDER)} />
                                                <img src={field === USERNAME && order === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, USERNAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                        <span className="table-cell-span align-items-center">
                                            <span className="login-history-dp-name font-wt-900" >TOP CATEGORY</span>
                                            {/* <Grid className="sort-icon-margin">
                                            onClick={(event) => switchSorting(event, CATEGORY)}
                                                <img src={field === CATEGORY && order === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, CATEGORY, ASC_ORDER)} />
                                                <img src={field === CATEGORY && order === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, CATEGORY, DESC_ORDER)} />
                                            </Grid> */}
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                        <span className="table-cell-span align-items-center">
                                            <span className="login-history-dp-name font-wt-900" >TOP SUB CATEGORY</span>
                                            {/* <Grid className="sort-icon-margin"> onClick={(event) => switchSorting(event, SUB_CATEGORY)}
                                                <img src={field === SUB_CATEGORY && order === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SUB_CATEGORY, ASC_ORDER)} />
                                                <img src={field === SUB_CATEGORY && order === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, SUB_CATEGORY, DESC_ORDER)} />
                                            </Grid> */}
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                        <span className="table-cell-span align-items-center">
                                            <span className="login-history-dp-name font-wt-900" >TOP DOMAINS</span>
                                            {/* <Grid className="sort-icon-margin">
                                            onClick={(event) => switchSorting(event, FQDN)}
                                                <img src={field === FQDN && order === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, FQDN, ASC_ORDER)} />
                                                <img src={field === FQDN && order === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, FQDN, DESC_ORDER)} />
                                            </Grid> */}
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {0 != tableData?.length ? (
                                <TableBody className="tableBody usage-tableBody">
                                    {
                                        tableData && tableData.map((row, i) =>
                                            <TableRow key={i} className="loginHistory-tableRow">
                                                <TableCell className="login-left-align selectedClick" onClick={() => handleApplyFilter(row, 'site')}>{row['Site_Name']}</TableCell>
                                                <TableCell className='login-left-align'>{row['total_hits']}</TableCell>
                                                <TableCell className={row['Top_User'] != 'NA' ? "login-left-align selectedClick" : "login-left-align"}>
                                                    {row['Top_User']?.split('/')?.map((user, j) => (
                                                        <div className={user != 'NA' ? 'userClick' : 'notUserClick'} key={j} onClick={() => user != 'NA' && handleApplyFilter(user, 'user')}>{user}</div>
                                                    ))}
                                                </TableCell>
                                                <TableCell className='login-left-align selectedClick' onClick={() => handleApplyFilter(row, 'category')}>{row['Top_Category']}</TableCell>
                                                <TableCell className='login-left-align selectedClick' onClick={() => handleApplyFilter(row, 'subCat')}>{row['Top_SubCategory']}</TableCell>
                                                <TableCell className="login-left-align selectedClick">
                                                    {row['Top_domains']?.split(',')?.map((dm, k) => (
                                                        <div key={k} onClick={() => handleApplyFilter(dm, 'app')}>{dm}</div>
                                                    ))}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={7} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    newSummaryStartDate: state.authReducer.newSummaryStartDate,
    newSummaryEndDate: state.authReducer.newSummaryEndDate,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, { getContentFilterTopSitesTable, downloadAsCSV
         })(ContentFilterTable)
);