import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material"
import close from "../../asset/image/close.svg";
import { useEffect, useState } from "react";
import { getUserMaxRiskList } from "../../actions/Users/authenticateUsageReports";
import _ from "lodash";

const RiskScorePopup = (props) => {
    const { selectedRow, open, setOpen, startDate, endDate, getUserMaxRiskList, riskList } = props;

    const [riskListValue, setRiskListValue] = useState([]);

    useEffect(() => {
        getUserMaxRiskList(selectedRow.locationID, startDate, endDate, selectedRow.macAddress)
    }, [])

    useEffect(() => {
        if (!_.isEmpty(riskList)) {
            const data = riskList.hasOwnProperty('data') ? riskList.data : {};
            setRiskListValue(data?.id_risk_list?.data);
        }
    }, [riskList])

    return (
        <div className="Form">
            <Dialog
                disableEnforceFocus
                open={open}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                className='create-default-config-container risk-score-container'
            >
                <div>
                    <DialogTitle className='create-default-config-title risk-score-title'>
                        <span>Top Risks</span>
                        <img src={close} title="Close" onClick={() => setOpen(false)} />
                    </DialogTitle>
                    <DialogContent className={riskListValue?.length > 0 ? "risk-score-content" : 'remove-margin-top'}>
                        {riskListValue?.length > 0 ? riskListValue.map((row, i) => (
                            <Grid key={i} className="table-risk-list">
                                <span>● </span>
                                <span>{row}</span>
                            </Grid>
                        )) : <div className="risk-list-loading">Fetching data please wait...</div>
                        }
                    </ DialogContent>
                </div>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => ({
    startDate: state.authReducer?.newSummaryStartDate,
    endDate: state.authReducer?.newSummaryEndDate,
    riskList: state.authReducer?.riskList,
});

export default withRouter(
    connect(mapStateToProps, { getUserMaxRiskList })(RiskScorePopup)
);