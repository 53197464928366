import { gql } from "@apollo/client";
import { SET_LOADING } from "../types";
import { handleGraphQlResponse, handleGRaphQlError, handleResponse, handleError } from "./../responseUtil";
import { GET_EDGE_EVENTS, GET_EDGE_EVENTS_FILTER, SET_EDGE_EVENTS_LOADING,SET_EDGE_EVENTS_FILTER_LOADING, GET_EDGE_EVENTS_TABLE, GET_EDGE_EVENTS_TABLE_COUNT, DOWNLOAD_EDGE_EVENTS_TABLE, CLEAR_EDGE_EVENTS, SET_EDGE_EVENTS_TABLE_LOADING, SET_EDGE_EVENTS_TABLE_COUNT_LOADING} from "../types";
import { ERROR_GET_EDGE_EVENTS, ERROR_GET_EDGE_EVENTS_FILTER, GET_ERRORS } from "../error_types";
import _ from "lodash";
import { api } from "../../server/request";
import { EDGE_EVENTS } from "../../utils/constants";
import { API_URL } from "../../config";


interface QueryData {query: string;
named?: boolean;
format?: string;
parameters: QueryParameters}
interface QueryParameters {
  startTime: string;
  endTime: string;
  locationId: any;
  pageSize: any;
  offset: any;
  deviceIdFilter: string;
  moduleFilter: string;
  levelFilter: string;
  sourceFilter: string;
  search: string;
}

const handleEdgeEventResponse = (res: any, dispatch: any, handler: string, errorHandler: string = "", successHandler: string = SET_LOADING) => {
  if (res) {
    dispatch({ type: handler, payload: res.data });
  } else {
    if (errorHandler && "" != errorHandler) {
    dispatch({ type: errorHandler, payload: res })}
    else {dispatch({ type: GET_ERRORS, payload: res })}
  }
  dispatch({ type: successHandler, payload: false });
}


export const getEdgeEvents = (parameters: QueryParameters) => (dispatch) => {
  dispatch({ type: SET_EDGE_EVENTS_LOADING, payload: true });
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const data : QueryData = {
    query: 'EDGE_EVENTS',
    named: true,
    parameters: parameters,
    format: 'csv'
  }
  api
  .postRequestOut(`${apiUrl}`, data)
  .then((res) => 
  handleEdgeEventResponse(res, dispatch, GET_EDGE_EVENTS))
      .catch((err) => handleError(err, dispatch));
};

export const getEdgeEventsDashBoard = (parameters: QueryParameters) => (dispatch) => {
  dispatch({ type: SET_EDGE_EVENTS_LOADING, payload: true });
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const data : QueryData = {
    query: 'EVENTS_DASHBOARD',
    named: true,
    parameters: parameters,
    format: 'csv'
  }
  api
  .postRequestOut(`${apiUrl}`, data)
  .then((res) => 
  handleEdgeEventResponse(res, dispatch, GET_EDGE_EVENTS))
      .catch((err) => handleError(err, dispatch));
};

export const getEdgeEventsFilter = (parameters: QueryParameters) => (dispatch) => {
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  dispatch({ type: SET_EDGE_EVENTS_FILTER_LOADING, payload: true });
  const data : QueryData = {
    query: 'EDGE_EVENTS_FILTERS',
    named: true,
    parameters: parameters,
  }
  api
  .postRequestOut(`${apiUrl}`, data)
  .then((res) => 
  handleEdgeEventResponse(res, dispatch, GET_EDGE_EVENTS_FILTER))
      .catch((err) => handleError(err, dispatch));
};

export const getEdgeEventsFilterDashboard = (parameters: QueryParameters) => (dispatch) => {
  dispatch({ type: SET_EDGE_EVENTS_FILTER_LOADING, payload: true });
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const data : QueryData = {
    query: 'EVENTS_DASHBOARD_FILTERS',
    named: true,
    parameters: parameters,
  }
  api
  .postRequestOut(`${apiUrl}`, data)
  .then((res) => 
  handleEdgeEventResponse(res, dispatch, GET_EDGE_EVENTS_FILTER))
      .catch((err) => handleError(err, dispatch));
};

export const getEdgeEventsForCharts = (parameters: QueryParameters) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const data : QueryData = {
    query: `EDGE_EVENTS_CHARTS`,
    named: true,
    parameters: parameters,
    format: 'csv'
  }
  api
  .postRequestOut(`${apiUrl}`, data)
  .then((res) => 
  handleEdgeEventResponse(res, dispatch, GET_EDGE_EVENTS))
      .catch((err) => handleError(err, dispatch));
};

export const getEdgeEventsTable = (parameters: QueryParameters) => (dispatch) => {
  dispatch({ type: SET_EDGE_EVENTS_TABLE_LOADING, payload: true });
  dispatch({ type: SET_LOADING, payload: true });
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const data : QueryData = {
    query: 'EDGE_EVENTS',
    named: true,
    parameters: parameters,
    format: 'csv'
  }
  api
  .postRequestOut(`${apiUrl}`, data)
  .then((res) => 
  handleEdgeEventResponse(res, dispatch, GET_EDGE_EVENTS_TABLE))
      .catch((err) => handleError(err, dispatch));
};

export const getEdgeEventsTableCount = (parameters: QueryParameters) => (dispatch) => {
  dispatch({ type: SET_EDGE_EVENTS_TABLE_COUNT_LOADING, payload: true });
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const data : QueryData = {
    query: 'EDGE_EVENTS_FILTERS',
    named: true,
    parameters: parameters,
  }
  api
  .postRequestOut(`${apiUrl}`, data)
  .then((res) => 
  handleEdgeEventResponse(res, dispatch, GET_EDGE_EVENTS_TABLE_COUNT))
      .catch((err) => handleError(err, dispatch));
};

export const downloadEdgeEventsTable = (parameters: QueryParameters) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const data : QueryData = {
    query: 'EDGE_EVENTS',
    named: true,
    parameters: parameters,
    format: 'csv'
  }
  api
  .postRequestOut(`${apiUrl}`, data)
  .then((res) => 
  handleEdgeEventResponse(res, dispatch, DOWNLOAD_EDGE_EVENTS_TABLE))
  .catch((err) => handleError(err, dispatch));
};

export const downloadEdgeEventsTableDashbOard = (parameters: QueryParameters) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const data : QueryData = {
    query: 'EVENTS_DASHBOARD',
    named: true,
    parameters: parameters,
    format: 'csv'
  }
  api
  .postRequestOut(`${apiUrl}`, data)
  .then((res) => 
  handleEdgeEventResponse(res, dispatch, DOWNLOAD_EDGE_EVENTS_TABLE))
  .catch((err) => handleError(err, dispatch));
};

export const clearEdgeEventsDownload = () => (dispatch) => {
  dispatch({ type: DOWNLOAD_EDGE_EVENTS_TABLE})
}

export const clearEdgeEvents = () => (dispatch) => {
  dispatch({ type: CLEAR_EDGE_EVENTS});
}