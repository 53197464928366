import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './table.css';


export default function BasicTable(props) {
  const { vesselMetricsInfo, isModemEnabled, isOperatorEnabled } = props;
  const headingList = isModemEnabled ? ["Modem","Carrier","MCC","MNC"]: ["Operator","Carrier","MCC","MNC"];


  return (
    <>
    <TableContainer className="mccMncTable" component={Paper}>
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow classNatableHeaderRowme="">
            {headingList.map((item) => {
              return <TableCell className="tableHeader">{item}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {vesselMetricsInfo !== undefined && vesselMetricsInfo.length> 0 && vesselMetricsInfo.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >

              {isModemEnabled && <TableCell component="th" scope="row">
                {row.modemId} </TableCell>}
              
              {/* <TableCell align="right">{row.iccid}</TableCell> */}
              {isOperatorEnabled && <TableCell >{row.operator}</TableCell>}
              <TableCell >{row.carrier}</TableCell>
              <TableCell >{row.mcc}</TableCell>
              <TableCell >{row.mnc}</TableCell>
              
            </TableRow> 
          ))}
        </TableBody>
      </Table>
      {!(vesselMetricsInfo !== undefined && vesselMetricsInfo.length> 0) && <div className="noData">No data</div> }
    </TableContainer>
    </>
  );
}