import { Fragment, useEffect, useState } from "react";
import { Menu, MenuItem, Button } from "@mui/material";
import TuneIcon from '@mui/icons-material/Tune';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import IndeterminateCheckboxGroup from "../IndeterminateCheckbox";
import { EVENT_FILTERS } from "../../constants/Constants";
import _ from "lodash";

interface EventFilterOptions {
    devices: string[];
    modules: string[];
    levels: string[];
    sources: string[];
    handleChange(_devices: string[] | undefined, _modules: string[] | undefined, _levels: string[] | undefined, _sources: string[] | undefined): void;
    defaultSelectionDevices: string[] | undefined;
    defaultSelectionModules: string[] | undefined;
    defaultSelectionLevels: string[] | undefined;
    defaultSelectionSources: string[] | undefined;
}

export const EventsFilterMenu = (props: EventFilterOptions) => {

    const { devices, modules, levels, sources, handleChange, defaultSelectionDevices, defaultSelectionModules, defaultSelectionLevels, defaultSelectionSources } = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleDeviceSelection = (_devices: string[]) => {
        handleChange(_devices, undefined, undefined, undefined)
    }

    const handleModuleSelection = (_modules: string[]) => {
        handleChange(undefined, _modules, undefined, undefined)
    }

    const handleLevelSelection = (_levels: string[]) => {
        handleChange(undefined, undefined, _levels, undefined)
    }

    const handleSourceSelection = (_sources: string[]) => {
        handleChange(undefined, undefined, undefined, _sources)
    }

    return (
        <Fragment>
            <div>
                <Button variant="outlined" startIcon={<TuneIcon id="filterIcon"/>} id="filterButton" onClick={handleClick}>
                    Filter
                </Button>
            </div>
            <Menu
                anchorEl={anchorEl}
                open={open}
                disableEnforceFocus
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem disableRipple>
                    <IndeterminateCheckboxGroup name="Sources" values={sources} defaultChecked={defaultSelectionSources} handleChange={handleSourceSelection} />
                </MenuItem>
                <MenuItem disableRipple>
                    <IndeterminateCheckboxGroup name="Devices" values={devices} defaultChecked={defaultSelectionDevices} handleChange={handleDeviceSelection} />
                </MenuItem>
                <MenuItem disableRipple>
                    <IndeterminateCheckboxGroup name="Modules" values={modules} defaultChecked={defaultSelectionModules} handleChange={handleModuleSelection} />
                </MenuItem>
                <MenuItem disableRipple>
                    <IndeterminateCheckboxGroup name="Levels" values={levels} defaultChecked={defaultSelectionLevels} handleChange={handleLevelSelection} />
                </MenuItem>
            </Menu>
        </Fragment>
    )
}