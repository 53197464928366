import { Fragment, SyntheticEvent, useEffect, useState } from "react";
import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, Button, Dialog, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DP_Dropdown from "../../InventoryWarehouseTable/DP_Dropdown";
import _, { set } from "lodash";
import OrgSelection from "../../OrgSelection";
import { createSubOrg } from "../slice";
import AddIcon from '@mui/icons-material/Add';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { getAllServiceLines } from "../../StarlinkReports/slice";
import { ServiceLine } from "../../StarlinkReports/types";
import { nanoid } from "nanoid";

interface ChangeSubOrgDialogProps {
    open: boolean;
    authReducer: any;
    errorReducer: any;
    starlinkCloud: any;
    starlinkAdmin: any;
    serviceLine: ServiceLine | null;
    serviceLines: ServiceLine[];

    onClose: () => void;
    onSave: (dpId: string, serviceLineNumbers: string[]) => void;
    createSubOrg: (parameters: any) => Promise<any>;
    getAllServiceLines: (parameters: any) => void;
}


function ChangeSubOrgDialog(props: ChangeSubOrgDialogProps) {

    const { authReducer, errorReducer, starlinkAdmin, open, serviceLine, serviceLines, onClose, starlinkCloud, onSave, createSubOrg, getAllServiceLines } = props;

    const [makeNewSubOrg, setMakeNewSubOrg] = useState<boolean>(false);
    const [newSubOrgName, setNewSubOrgName] = useState<string>('');
    const [selectedDp, setSelectedDp] = useState<any>(null);
    const [selectedServiceLines, setSelectedServiceLine] = useState<ServiceLine[]>([])
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [newSubOrgNameValidationError, setNewSubOrgNameValidationError] = useState<string>('');
    const [valid, setValid] = useState<boolean>(false);
    const [disableActions, setDisableActions] = useState<boolean>(false);

    const handleClose = () => {
        onClose();
    }

    const handleSave = () => {
        setErrorMessage('')
        setDisableActions(true)
        onSave(selectedDp?.id, selectedServiceLines?.map(serviceLine => serviceLine.serviceLineNumber))
    }

    const handleChangeDp = (ou: any) => {
        setSelectedDp(ou)
    }

    const handleChangeNewSubOrgName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewSubOrgName(event.target.value);
        if (event.target.value.match(/[^A-Za-z0-9\s]/)) {
            setNewSubOrgNameValidationError('Only alphanumeric characters are allowed');
        } else {
            setNewSubOrgNameValidationError('');
        }
    }

    const handleNewSubOrgInit = () => {
        setMakeNewSubOrg(true);
    }

    const handleNewSubOrgCreationCancel = () => {
        setMakeNewSubOrg(false);
    }

    const handleChangeSelectedServiceLines = (event: SyntheticEvent<Element, Event>, value: unknown, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<unknown> | undefined) => {

        if (reason === "selectOption" || reason === "removeOption") {
            if ((value as ServiceLine[]).find(option => option.serviceLineNumber === "all")) {
                return setSelectedServiceLine(serviceLines);
            } else {
                return setSelectedServiceLine(value as ServiceLine[]);
            }
        } else if (reason === "clear") {
            setSelectedServiceLine([]);
        }
    }

    const handleNewSubOrgSave = async () => {
        setDisableActions(true);
        setErrorMessage('');

        if (!_.isEmpty(selectedDp)) {
            let res = await createSubOrg({
                DPName: newSubOrgName,
                DPFullName: newSubOrgName,
                parentName: selectedDp.name
            })
            let _level = selectedDp.level + 1;
            if (res?.status === 1) {
                setSelectedDp({
                    id: res?.data?.data?.dpId,
                    name: newSubOrgName,
                    level: _level,
                    hasChildren: false,
                })
            } else if (res?.response?.data?.message) {
                setErrorMessage(res?.response?.data?.message);
            } else {
                setErrorMessage('Failed to create Sub-Organization');
            }
        }
        setDisableActions(false);
        setMakeNewSubOrg(false);
    }

    useEffect(() => {
        if (open) {
            setSelectedDp(null)
            setErrorMessage('')
            setSelectedServiceLine(serviceLine !== null ? [serviceLine] : [])
            setDisableActions(false)
            setMakeNewSubOrg(false)
            setNewSubOrgName('')
        }
    }, [open, serviceLine]);

    useEffect(() => {
        setValid(
            selectedDp && selectedDp?.id !== ""
        )
    }, [selectedDp]);

    useEffect(() => {
        setDisableActions(
            starlinkAdmin.updatingServiceLine
        );
    }, [starlinkAdmin.updatingServiceLine]);

    useEffect(() => {
        if (!_.isEmpty(starlinkCloud.dpIds)) {
            getAllServiceLines({ dpIds: starlinkCloud.dpIds });
        }
    }, [starlinkCloud.dpIds]);

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="service_account--dialog" fullWidth>
            <Grid classes={{ root: 'starlink--base--padding_1x' }}>
                <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                    <Typography component="div" className="starlink--base--font_1x">
                        Assign Service Line to Sub Organization
                    </Typography>
                </Grid>
                {/* <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                    <Typography component="div" className="starlink--base--font_08x">
                        Service Line: <Typography component="span" className="starlink--base--font_08x starlink--base--font_bold">{serviceLine?.serviceLineName} ({serviceLine?.serviceLineNumber})</Typography>
                    </Typography>
                    <Typography component="div" className="starlink--base--font_08x">
                        Current Sub Organization: <Typography component="span" className="starlink--base--font_08x starlink--base--font_bold">{serviceLine?.dpName || '-'}</Typography>
                    </Typography>
                </Grid> */}
                <Grid container>
                    <Grid item xs={5}>
                        <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                            <OrgSelection label={makeNewSubOrg ? "Parent Organization" : "Sub-Organization"} value={selectedDp} onChange={handleChangeDp} disabled={disableActions} />
                        </Grid>
                        <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                            {
                                makeNewSubOrg ? <TextField
                                    disabled={disableActions}
                                    fullWidth
                                    size="small"
                                    id="starlink--service_account--sub_org_name"
                                    label="Sub-Organization Name"
                                    value={newSubOrgName}
                                    onChange={handleChangeNewSubOrgName}
                                    error={newSubOrgNameValidationError.length > 0}
                                /> : null
                            }
                            <FormHelperText error={newSubOrgNameValidationError?.length > 0}>
                                <Typography component="div" className="starlink--base--text_center starlink--base--font_08x">
                                    {newSubOrgNameValidationError}
                                </Typography>
                            </FormHelperText>
                        </Grid>
                        <Grid classes={{ root: 'starlink--base--flex starlink--base--mtb_07x starlink--base--flex--justify--start' }}>
                            {
                                makeNewSubOrg ? <Fragment>
                                    <Button size="small" variant="outlined" className='starlink--button--cancel' onClick={handleNewSubOrgCreationCancel} disabled={disableActions}>Cancel</Button>
                                    <Button size="small" variant="contained" className='starlink--button_contained--primary' onClick={handleNewSubOrgSave} disabled={disableActions || newSubOrgNameValidationError.length > 0 || newSubOrgName.length === 0 || selectedDp === null}>Create</Button>
                                </Fragment> : <Button size="small" variant="contained" className='starlink--button_contained--primary' onClick={handleNewSubOrgInit} disabled={disableActions} startIcon={<AddIcon />}>New Sub-Organization</Button>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={2} classes={{ root: 'starlink--base--flex--justify--center' }}>
                        <Grid classes={{ root: 'starlink--base--flex--align--center' }}>
                            <KeyboardBackspaceIcon />
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <Grid display={'flex'} flexDirection={'column'} justifyContent={'center'} height={'100%'}>
                            <Autocomplete
                                id="service_line"
                                multiple
                                disableCloseOnSelect
                                fullWidth
                                readOnly={serviceLine !== null}
                                disabled={disableActions}
                                options={serviceLines.length > 0 ? [{ serviceLineNumber: 'all', nickname: 'Select All' }, ...serviceLines] as ServiceLine[] : []}
                                value={selectedServiceLines}
                                onChange={handleChangeSelectedServiceLines}
                                size="small"
                                getOptionLabel={(option: ServiceLine) => `${option.nickname}${option.serviceLineNumber !== "all" ? ` (${option.serviceLineNumber})` : ''}`}
                                renderInput={(params) => <TextField {...params} label="Service Line" variant="outlined" />}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <FormHelperText error={errorMessage?.length > 0}>
                        <Typography component="div" className="starlink--base--text_center starlink--base--font_08x">
                            {errorMessage}
                        </Typography>
                    </FormHelperText>
                </Grid>

                <Grid classes={{ root: 'starlink--base--flex starlink--base--mtb_07x starlink--base--flex--justify--end' }}>
                    <Button variant="outlined" className='starlink--button--cancel' onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" className='starlink--button_contained--primary' onClick={handleSave} disabled={!valid || disableActions || makeNewSubOrg}>Save</Button>
                </Grid>
            </Grid>
        </Dialog>
    )
}

const mapStateToProps = (state: any) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    starlinkAdmin: state.starlinkAdmin,
    starlinkCloud: state.starlinkCloud,
    serviceLines: state.starlinkReports.allServiceLines,
});

export default withRouter(
    connect(mapStateToProps, {
        createSubOrg,
        getAllServiceLines
    })(ChangeSubOrgDialog)
);
