import { Grid } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import totalLoginsIcon from "../../../asset/image/totalLogins.svg";
import uniqueLoginsIcon from "../../../asset/image/uniqueLogins.svg";
import failedLoginsIcon from "../../../asset/image/failedLogins.svg";
import activeUserIcon from "../../../asset/image/activeUser.svg";
import "./loginHistoryWidget.css";
import { useEffect, useState } from "react";
import _ from "lodash";
import { getIamLoginHistoryTopWidget, getIamLoginHistoryTable, getIamLoginHistortTableCount, getIamLoginHistoryTimeseriesChart } from "../../../actions/Users/authenticate";
import { getDecodeURI, getEncodedURI } from "../../../utils/util";

const LoginHistoryWidget = (props) => {
    const { authReducer, getIamLoginHistoryTable, getIamLoginHistortTableCount, getIamLoginHistoryTimeseriesChart, setValues, values, location, history } = props;
    const { setWidgetFilt, setSelectedDesc, setPage } = setValues;
    const { startDate, endDate, searchValue, selectedOu, selectedUsers, selectedApp, selectedDesc, interval, limit, sort, sortOrder } = values;
    const [topWidgetData, setTopWidgetData] = useState<any>([]);

    useEffect(() => {
        if (_.isEmpty(authReducer.getIamLoginHistoryTopWidget)) {
            setTopWidgetData([{}, {}, {}, {}]);
            return;
        }
        if(!_.isEmpty(authReducer.getIamLoginHistoryTopWidget)) {
            const data = authReducer.getIamLoginHistoryTopWidget?.data;
            if(data) {
                const rows: any[] = data?.rows;
                const columns: string[] = data?.columns;
                const topWidgetData: any[] = [];
                for (let i = 0; i < rows?.length; i++) {
                    const row = {};
                    const currentRow = rows[i];
                    columns.forEach((column, index) => {
                        row[column] = currentRow[index];
                    })
                    topWidgetData.push(row);
                }
                setTopWidgetData(topWidgetData);
            }
        }
    },[authReducer.getIamLoginHistoryTopWidget])

    const handleCardClick = (kpi) => {
        setSelectedDesc([]);
        const desc = [];
        let card = {
            tot_login : {type: 'login', check: '', kpi},
            fail_login: {type: 'login', check: 'false', kpi},
            success_login : {type: 'login', check: 'true', kpi},
            tot_logouts: {type: 'logout', check: '', kpi}
        }
        setWidgetFilt(card[kpi])

        let params: any = getDecodeURI(location.search);
        card[kpi].type ? params.type = card[kpi].type : delete params.type;
        card[kpi].check ? params.check = card[kpi].check : delete params.check;
        card[kpi].kpi ? params.kpi = card[kpi].kpi : delete params.kpi;
        delete params.desc;
        delete params.page;
        doNavigate(params);
        const Page = 1;
        setPage(1);
        getIamLoginHistoryTimeseriesChart(startDate, endDate, searchValue, interval, selectedOu, selectedUsers, selectedApp, desc, card[kpi]);
        getIamLoginHistoryTable(startDate, endDate, searchValue, Page, limit, sort, sortOrder, selectedOu, selectedUsers, selectedApp, desc, card[kpi]);
        getIamLoginHistortTableCount(startDate, endDate, searchValue, selectedOu, selectedUsers, selectedApp, desc, card[kpi]);
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }
    
    return (
        <div className="loginHistoryWidget-container">
            <p>Summary</p>
           <Grid container spacing={2}>
               {
                topWidgetData?.map((card, i) => (
                    <Grid key={i} item xs={6} sm={6} md={3} lg={3}>
                        <WidgetCard card={card} handleCardClick={handleCardClick} />
                    </Grid>
                ))
               }
           </Grid>
        </div>
    )
}

const WidgetCard = ({card, handleCardClick}) => {

    let widget = {
        tot_login : {icon: totalLoginsIcon, text:"Total No of Login Attempts"},
        fail_login: {icon: failedLoginsIcon, text:"No of Failed Logins"},
        success_login : {icon: activeUserIcon, text:'Total Success Logins'},
        tot_logouts: {icon: uniqueLoginsIcon, text:'Total No of Logouts'}
    }

    return (
        <div className="topWidget-loginHistory-card">
            <Grid className="parent-card">
                <Grid className={`${card.kpi}`}>{widget[card.kpi]?.icon ? <img src={widget[card.kpi]?.icon} alt="" /> : ""}</Grid>
                <Grid className="card-login-detaild">
                    <p>{card.value ? `${card.value}` : 0}</p>
                    <p onClick={() => handleCardClick(card.kpi)}>{widget[card.kpi]?.text}</p>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, { 
        getIamLoginHistoryTopWidget,
        getIamLoginHistoryTable,
        getIamLoginHistortTableCount,
        getIamLoginHistoryTimeseriesChart
     })(LoginHistoryWidget)
);