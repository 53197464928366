// Dialog Box for the ticket details of Ticketing Page Pop-up
import React, { useEffect, useState } from "react";
import {
    Grid,
    Button,
} from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from '@mui/material/DialogContent';
import close from "../../asset/image/close.svg";
import close_2 from "../../asset/image/close.png";
import dotIcon from "../../asset/image/dot_icon.svg";
import redDotIcon from "../../asset/image/dot_red_icon.svg";
import menu from "../../asset/image/3DotsMenu.svg";
import './TicketDetailsDialog.css';
import { getTicketDetailsByTicketId, getTicketCommentsByTicketId, sendComment, searchTicketAssigneeByName } from "../../actions/Users/authenticateTicket";
import { DEFAULT_TIMEZONE, DEFAULT_DATE_FORMAT, DAYDDMMMHMMSS_DATE_FORMAT } from "../../utils/constants";
import moment from "moment-timezone";
import _ from "lodash";
import TextField from '@mui/material/TextField';
import { MenuItem, Menu } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import { InputAdornment } from "@mui/material";
import { updateTicket } from "../../actions/Users/authenticateTicket";
import { CONFIRM_DIALOG_TITLE, MARK_AS_CLOSED_CONFIRM_DIALOG_SUBTITLE } from '../../config';

import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";

const TicketDetailsDialog = (props) => {
    const { onClose, selectedValue, open, ticketInfo, clearTicketInfo, createTicketVesselList,
        assigneeList, autoCompleteNoOptionsText, getTicketDetailsByTicketId, getTicketCommentsByTicketId,
        closeTicket, updateTicket, sendComment, searchTicketAssigneeByName, setConfirmDialog, authReducer } = props;

    const ID_KEY = "id";
    const K4ID_KEY = "k4Id";
    const TICKET_ID_KEY = "ticketId";
    const SUBJECT_KEY = "ticketSubject";
    const DESCRIPTION_KEY = "ticketDesc";
    const EVENT_DATE_KEY = "eventDate";
    const CREATOR_KEY = "fullName";
    const ASSIGNEE_KEY = "assignee";
    const ASSIGNED_TO_KEY = "assignedTo";
    const STATUS_KEY = "ticketStatus";
    // const COMMENT_ID_KEY = "commentId";
    const COMMENT_KEY = "comment";
    const COMMENT_DATE_KEY = "commentDate";
    const COMMENTER_KEY = "commenter";
    const FULL_NAME_KEY = "fullName";
    const K4_EMP_ID_KEY = "k4EmpId";

    let [isTicketDetailsAvailable, setIsTicketDetailsAvailable]: any = useState(false);
    let [ticketDeatils, setTicketDeatils]: any = useState();
    let [ticketComments, setTicketComments]: any = useState([]);
    let [isTicketCommentsAvailable, setIsTicketCommentsAvailable]: any = useState(false);
    let [addComment, setAddComment]: any = useState("");
    let [clickedCommentId, setClickedCommentId]: any = useState();
    let [isCreateTicketAccess, setIsCreateTicketAccess]: any = useState();

    let [ticketMenuEl, setTicketMenuEl]: any = React.useState(null);
    let openTicketMenu = Boolean(ticketMenuEl);
    let [isEditTicket, setIsEditTicket] = useState(false);
    let [editTicketSubject, setEditTicketSubject]: any = useState();
    let [editTicketDescription, setEditTicketDescription]: any = useState();
    let [editTicketAssignee, setEditTicketAssignee]: any = useState();
    let [editTicketAssigneeSearch, setEditTicketAssigneeSearch]: any = useState("");
    let [editTicketAssigneeSearchData, setEditTicketAssigneeSearchData] = useState<any>([]);
    let [editTicketAssigneeSearchDataNotFound, setEditTicketAssigneeSearchDataNotFound] = useState<boolean>(false);

    const UNASSIGNED = "Unassigned";
    useEffect(() => {
        if (ticketInfo && ticketInfo.hasOwnProperty(TICKET_ID_KEY)) {
            getTicketDetails(ticketInfo[TICKET_ID_KEY]);
            getTicketComments(ticketInfo[TICKET_ID_KEY]);
        }

        if (createTicketVesselList && Array.isArray(createTicketVesselList) && ticketInfo && ticketInfo.hasOwnProperty(TICKET_ID_KEY)) {
            setIsCreateTicketAccess(createTicketVesselList.some(vessel => vessel[ID_KEY] === ticketInfo[K4ID_KEY]));
        }
    }, [ticketInfo, createTicketVesselList])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getTicketDetails) && authReducer.getTicketDetails.data) {
            // if value are empty/null on UI show it as Normal
            let copiedData = JSON.parse(JSON.stringify(authReducer.getTicketDetails.data));
            for (const [key, value] of Object.entries(copiedData)) {
                // empty,  null
                if (!value) {
                    copiedData[key] = "";
                }
            }
            setTicketDeatils(copiedData);
            setIsTicketDetailsAvailable(Object.keys(copiedData).length > 0)
            copiedData && copiedData[SUBJECT_KEY] ? setEditTicketSubject(copiedData[SUBJECT_KEY]) : setEditTicketSubject("");
            copiedData && copiedData[DESCRIPTION_KEY] ? setEditTicketDescription(copiedData[DESCRIPTION_KEY]) : setEditTicketDescription("");
            getTicketAssigneeDeatils(copiedData);
            authReducer.getTicketDetails = {};
        }
    }, [authReducer.getTicketDetails])

    const getTicketAssigneeDeatils = (data: any) => {
        if (data && data[ASSIGNED_TO_KEY] && data[ASSIGNEE_KEY]) {
            let assigneeDetails = {};
            assigneeDetails[K4_EMP_ID_KEY] = data[ASSIGNED_TO_KEY];
            assigneeDetails[FULL_NAME_KEY] = data[ASSIGNEE_KEY];
            setEditTicketAssignee(assigneeDetails);
        }
    }

    useEffect(() => {
        if (isEditTicket) {
            getTicketAssigneeDeatils(ticketDeatils);
        }
    }, [isEditTicket])

    useEffect(() => {
        // clear clicked comment id
        setClickedCommentId("");
        if (!_.isEmpty(authReducer.getTicketComments) && authReducer.getTicketComments.data) {
            setTicketComments(authReducer.getTicketComments.data);
            setIsTicketCommentsAvailable(authReducer.getTicketComments.data.length > 0);
            authReducer.getTicketComments = {};
        }
    }, [authReducer.getTicketComments])

    // once user adds comment it will refresh the comment section UI by calling getTicketCommentsByTicketId API
    // handleSendComment: if close dailog box is not needed after click on send to add comment, then uncomment below function
    // useEffect(() => {
    //     if (open) {
    //         // clear add comment content 
    //         setAddComment("");
    //         if (!_.isEmpty(authReducer.sendTicketComment) && ticketInfo && ticketInfo.hasOwnProperty(TICKET_ID_KEY)) {
    //             getTicketComments(ticketInfo[TICKET_ID_KEY]);
    //         }
    //     }
    // }, [authReducer.sendTicketComment])

    // if Ticket Details Dailog is open: once user marks ticket as closed OR edits the ticket, 
    // it will refresh the details section UI by calling getTicketDetailsByTicketId API
    useEffect(() => {
        if (open) {
            clearEditTicketContents();
            if (!_.isEmpty(authReducer.updateTicket) && ticketInfo && ticketInfo.hasOwnProperty(TICKET_ID_KEY)) {
                getTicketDetails(ticketInfo[TICKET_ID_KEY]);
            }
        }
    }, [authReducer.updateTicket])

    const getTicketDetails = (ticketId: any) => {
        //clearTicketDetails();
        getTicketDetailsByTicketId(ticketId);
    }

    const clearTicketDetails = () => {
        setTicketDeatils({});
        setIsTicketDetailsAvailable(false);
    }

    const getTicketComments = (ticketId: any) => {
        // clearComments();
        getTicketCommentsByTicketId(ticketId);
    }

    const clearComments = () => {
        ticketComments.length = 0
        setIsTicketCommentsAvailable(false);
    }

    const handleEditTicketAssigneeSearchClick = () => {
        if (editTicketAssigneeSearch) {
            searchTicketAssigneeByName(editTicketAssigneeSearch);
        }
    }

    const clearEditTicketContents = () => {
        setIsEditTicket(false);
        setEditTicketSubject("");
        setEditTicketDescription("");
        setEditTicketAssignee({});
        setEditTicketAssigneeSearch("");
        setEditTicketAssigneeSearchDataNotFound(false);
    }

    const handleEditTicketSumbit = () => {
        if (editTicketSubject && editTicketDescription && editTicketAssignee && editTicketAssignee.k4EmpId) {
        let isEdited =false;
        let payload = {}
        payload[K4ID_KEY] = ticketInfo[K4ID_KEY];
        payload[TICKET_ID_KEY] = ticketInfo[TICKET_ID_KEY];
        if(ticketDeatils.hasOwnProperty(SUBJECT_KEY) && ticketDeatils[SUBJECT_KEY] !=editTicketSubject){
        payload[SUBJECT_KEY] = editTicketSubject;
        isEdited = true;
        }
        
        
        
        if(ticketDeatils.hasOwnProperty(DESCRIPTION_KEY) && ticketDeatils[DESCRIPTION_KEY] !=editTicketDescription){
        payload[DESCRIPTION_KEY] = editTicketDescription;
        isEdited = true;
        }
        
        
        if(ticketDeatils.hasOwnProperty(ASSIGNED_TO_KEY) && ticketDeatils[ASSIGNED_TO_KEY] !=editTicketAssignee.k4EmpId ){
        payload[ASSIGNED_TO_KEY] = editTicketAssignee.k4EmpId;
        isEdited = true;
        }
        
        isEdited && updateTicket(payload);
        // close dailog box
        isEdited && handleClose();
        }
        }

    useEffect(() => {
        if (open && !_.isEmpty(authReducer.getTicketAgentsByName) && authReducer.getTicketAgentsByName.data) {
            // clear the editTicketAssigneeSearchData
            editTicketAssigneeSearchData.length = 0;
            if (Array.isArray(authReducer.getTicketAgentsByName.data) && authReducer.getTicketAgentsByName.data.length > 0) {
                setEditTicketAssigneeSearchDataNotFound(false);
                authReducer.getTicketAgentsByName.data.forEach((item) => {
                    setEditTicketAssigneeSearchData(prevState => [...prevState, item]);
                });
            } else {
                setEditTicketAssigneeSearchDataNotFound(true);
            }
            authReducer.getTicketAgentsByName = {};
        }
    }, [authReducer.getTicketAgentsByName])

    useEffect(() => {
        // when user changes assigneeSearch box
        setEditTicketAssigneeSearchData([]);
        setEditTicketAssigneeSearchDataNotFound(false);
    }, [editTicketAssigneeSearch])

    const clearEditTicketAssignee = () => {
        setEditTicketAssignee({});
    }

    // DELETE TICKET is not supported as of now
    // const deleteTicket = () => {
    //     console.log("k4Id: " + ticketInfo[K4ID_KEY] + " ticketId: " + ticketInfo[TICKET_ID_KEY]);
    // }

    const handleClose = () => {
        // clear ticket details related variables
        clearTicketInfo();
        clearTicketDetails();
        // clear ticket comment related variables
        clearComments();
        // clear clicked comment related variables
        setClickedCommentId("");
        // clear add comment related variables
        setAddComment("");
        // clear edit ticket related variables
        clearEditTicketContents();

        onClose(selectedValue);
    };

    const markAsClosed = () => {
        closeTicket(ticketInfo[K4ID_KEY], ticketInfo[TICKET_ID_KEY]);
        // close dailog box
        handleClose();
    }

    const getCommentorShortName = (fullname: any) => {
        let shortname = "";
        let nameArray = fullname.split(" ");
        if (nameArray.length > 0) {
            for (let i = 0; i < nameArray.length; i++) {
                // show only two letters ex: venkat sai desai show it as VS
                if (i < 2 && nameArray[i].length > 0) {
                    shortname += nameArray[i][0];
                }
            }
        } else {
            shortname = fullname.length > 0 ? fullname[0] : "";
        }
        return shortname;
    }

    // DELETE COMMENT is not supported as of now
    // const deleteComment = (commentId: any) => {
    // }

    // DELETE COMMENT is not supported as of now
    // const showDeleteCommentIcon = (commentInfo: any) => {
    //     if (commentInfo && commentInfo[COMMENT_ID_KEY]) {
    //         setClickedCommentId(commentInfo[COMMENT_ID_KEY])
    //     }
    // }

    const handleSendComment = () => {
        if (addComment) {
            let payload = {
                "k4Id": ticketInfo[K4ID_KEY],
                "ticketId": ticketInfo[TICKET_ID_KEY],
                "comment": addComment
            }
            sendComment(payload);
            // close dailog box
            handleClose();
        }
    }

    return (
        <div className="Form">
            <Dialog className="tkt_ticket_details_dialog" open={open} >
                <DialogTitle className="dialog_title">
                    <Grid>
                        Ticket Details
                        <Grid style={{ marginLeft: "auto" }}>
                            {
                                isCreateTicketAccess ? <img src={menu} onClick={(event) => setTicketMenuEl(event.currentTarget)} className="tkt_ticket_details_menuIcon cursorPointer" /> : ""
                            }
                            <Menu
                                anchorEl={ticketMenuEl}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={openTicketMenu}
                                onClose={() => setTicketMenuEl(null)}
                                className="tkt_ticket_details_menu"
                                style={{ top: "20px", }}
                            >
                                <MenuItem selected={isEditTicket} className="tkt_ticket_details_menuItem" onClick={() => setIsEditTicket(true)}>
                                    Edit ticket
                                </MenuItem>
                                {/* DELETE TICKET is not supported as of now
                                <MenuItem className=" tkt_ticket_details_menuItem" onClick={() => deleteTicket()}>
                                    Delete ticket
                                </MenuItem> */}
                            </Menu>
                            <img src={close} onClick={handleClose} className="dialog_closeIcon" />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className="tkt_ticket_details_dialog_content">
                    <Grid container>
                        <Grid item xs={12} style={{ position: "relative" }}>
                            {
                                isTicketDetailsAvailable
                                    ? [
                                        <Grid className="tkt_ticket_details_dialog_content_status">
                                            <div style={{marginRight: "10px"}}>
                                                {"closed" === ticketDeatils[STATUS_KEY].toLowerCase() || "resolved" === ticketDeatils[STATUS_KEY].toLowerCase() ? <img src={dotIcon} /> : <img src={redDotIcon} />}
                                                <span className="status">{ticketDeatils[STATUS_KEY].toUpperCase()}</span>
                                            </div>
                                            {
                                                "open" === ticketDeatils[STATUS_KEY].toLowerCase() && isCreateTicketAccess
                                                ?
                                                    <Button className="markAsClosed cursorPointer" onClick={() => {
                                                        setConfirmDialog({
                                                            title: CONFIRM_DIALOG_TITLE,
                                                            subTitle: MARK_AS_CLOSED_CONFIRM_DIALOG_SUBTITLE,
                                                            isOpen: true,
                                                            onConfirm: () => { markAsClosed() }
                                                        })
                                                    }
                                                    }>
                                                        Mark as closed
                                                    </Button>
                                                : ""
                                            }
                                        </Grid>,
                                        <div className="tkt_ticket_details_dialog_content_subject_title">Subject</div>,
                                        isEditTicket ?
                                            <Grid item className="tkt_ticket_details_dialog_content_subject_content multi_line_textfield_parent">
                                                <TextField multiline value={editTicketSubject} onChange={(event) => setEditTicketSubject(event.currentTarget.value)}></TextField>
                                            </Grid>
                                            :
                                            <Grid item className="tkt_ticket_details_dialog_content_subject_content">
                                                {ticketDeatils && ticketDeatils.hasOwnProperty(SUBJECT_KEY) ? ticketDeatils[SUBJECT_KEY] : " "}
                                            </Grid>,

                                        <div className="tkt_ticket_details_dialog_content_description_title" style={{ marginTop: "15px" }}>Description</div>,
                                        isEditTicket
                                            ?
                                            <div className="tkt_ticket_details_dialog_content_description_content multi_line_textfield_parent">
                                                <TextField multiline value={editTicketDescription} onChange={(event) => setEditTicketDescription(event.currentTarget.value)}></TextField>
                                            </div>
                                            : <div className="tkt_ticket_details_dialog_content_description_content">
                                                {ticketDeatils && ticketDeatils.hasOwnProperty(DESCRIPTION_KEY) ? ticketDeatils[DESCRIPTION_KEY] : " "}
                                            </div>

                                    ]
                                    : ""
                            }
                        </Grid>
                        {
                            isTicketDetailsAvailable
                            ?
                            <Grid item xs={12} style={{marginTop: "10px"}}>
                                <Grid container>
                                    <Grid item xs={4} className="eventDate">
                                        <div className="tkt_ticket_details_dialog_content_event_title">Event Date</div>
                                        <div className="tkt_ticket_details_dialog_content_event_content">
                                            {
                                                ticketDeatils && ticketDeatils.hasOwnProperty(EVENT_DATE_KEY)
                                                    ? ticketDeatils[EVENT_DATE_KEY].toLowerCase() == "" ? " " : moment(ticketDeatils[EVENT_DATE_KEY]).tz(DEFAULT_TIMEZONE).format(DEFAULT_DATE_FORMAT)
                                                    : " "
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item xs={4} className="creator">
                                        <div className="tkt_ticket_details_dialog_content_creator_title">Creator</div>
                                        <div className="tkt_ticket_details_dialog_content_creator_content">
                                            {ticketDeatils && ticketDeatils.hasOwnProperty(CREATOR_KEY) ? ticketDeatils[CREATOR_KEY] : " "}
                                        </div>
                                    </Grid>
                                    <Grid item xs={4} className={isEditTicket?"editAssignee":"assignee"}>
                                        <div className="tkt_ticket_details_dialog_content_assignee_title">Assignee</div>
                                        <div className="tkt_ticket_details_dialog_content_assignee_content">
                                            {
                                                isEditTicket
                                                    ?
                                                    editTicketAssignee && editTicketAssignee[FULL_NAME_KEY]
                                                        ?
                                                        <div><OutlinedInput className="tkt_ticket_details_dialog_content_assignee_searchBox" type="text" placeholder="Type something"
                                                            value={"" === editTicketAssignee[FULL_NAME_KEY] ? UNASSIGNED : editTicketAssignee[FULL_NAME_KEY]}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <img onClick={() => clearEditTicketAssignee()} className="tkt_ticket_details_assignee_clearImage cursorPointer" src={close_2} />
                                                                </InputAdornment>
                                                            }
                                                        /></div>
                                                        :
                                                        <div><div className="tkt_ticket_details_dialog_content_assigneeAutocomplete">
                                                            <Autocomplete
                                                                size="small"
                                                                noOptionsText={autoCompleteNoOptionsText}
                                                                options={assigneeList}
                                                                getOptionLabel={(option: any) => option.fullName ? option.fullName : "fullName key missing!! "}
                                                                PaperComponent={({ children }) => (
                                                                    <Paper className="tkt_ticket_details_dialog_content_edit_tkt_assigneeAutocompleteListItems">{children}</Paper>
                                                                )}
                                                                onChange={(e, newValue: any) => {
                                                                    setEditTicketAssignee(newValue);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} placeholder="Search assignee" />
                                                                )}
                                                            />
                                                        </div></div>
                                                    : ticketDeatils && ticketDeatils.hasOwnProperty(ASSIGNEE_KEY) ? "" === ticketDeatils[ASSIGNEE_KEY] ? UNASSIGNED : ticketDeatils[ASSIGNEE_KEY] : " "
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            :""
                        }
                        {
                            !isEditTicket && isTicketDetailsAvailable && isCreateTicketAccess
                                ?
                                [
                                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                                        <div className="tkt_ticket_details_dialog_content_comments_title">Add Comment</div>
                                        <div className="tkt_ticket_details_dialog_content_comment_addComment multi_line_textfield_parent">
                                            <TextField multiline value={addComment} onChange={(event) => setAddComment(event.currentTarget.value)}></TextField>
                                        </div>
                                        <Button className="tkt_ticket_details_dialog_content_comment_sendButton dialog_okButton cursorPointer" onClick={() => handleSendComment()}>
                                            Post comment
                                        </Button>
                                    </Grid>
                                ]
                                : ""
                        }
                        {
                            !isEditTicket && isTicketDetailsAvailable
                            ?
                            <Grid item xs={12} style={{ marginTop: "20px" }}>
                                <div className="tkt_ticket_details_dialog_content_comments_title">Comments ({ticketComments.length})</div>
                                <div className={isCreateTicketAccess ? "tkt_ticket_details_dialog_content_comments" : "tkt_ticket_details_dialog_content_comments"}>
                                    {
                                        isTicketCommentsAvailable && ticketComments.map((row: any, i) => (
                                            // DELETE COMMENT is not supported as of now
                                            // <div className={clickedCommentId && clickedCommentId === row[COMMENT_ID_KEY] ? "tkt_clicked_ticket tkt_ticket_details_dialog_content_comments_content cursorPointer" : "tkt_ticket_details_dialog_content_comments_content cursorPointer"} onClick={() => showDeleteCommentIcon(row)} >
                                            <div className="tkt_ticket_details_dialog_content_comments_content">
                                                <div className="tkt_ticket_details_dialog_content_commenter" title={row[COMMENTER_KEY] ? row[COMMENTER_KEY] : ""}>
                                                    <div>{row[COMMENTER_KEY] ? getCommentorShortName(row[COMMENTER_KEY]) : ""}</div>
                                                </div>
                                                <div className="tkt_ticket_details_dialog_content_comment">
                                                    <div>
                                                        <div className="tkt_ticket_details_dialog_content_commentedBy">
                                                            {row[COMMENTER_KEY] ? row[COMMENTER_KEY] : ""}
                                                        </div>
                                                        <div className="tkt_ticket_details_dialog_content_comment_commentDate">
                                                            {
                                                                row[COMMENT_DATE_KEY]
                                                                    ?
                                                                    [
                                                                        moment(row[COMMENT_DATE_KEY]).tz(DEFAULT_TIMEZONE).format(DAYDDMMMHMMSS_DATE_FORMAT),
                                                                        // DELETE COMMENT is not supported as of now
                                                                        // isCreateTicketAccess
                                                                        //     ?
                                                                        //     clickedCommentId && clickedCommentId === row[COMMENT_ID_KEY]
                                                                        //         ?
                                                                        //         <img className="tkt_ticket_details_dialog_content_comment_deleteComment cursorPointer" src={deleteIcon} onClick={() => deleteComment([row[COMMENT_ID_KEY]])} />
                                                                        //         : ""
                                                                        //     : ""
                                                                    ] : ""
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="tkt_ticket_details_dialog_content_comment_text">{row[COMMENT_KEY] ? row[COMMENT_KEY] : ""}</div>
                                                </div>

                                            </div>
                                        ))
                                    }
                                </div>
                            </Grid>
                            :""
                        }
                        {
                            isEditTicket
                            ?
                            <Grid item xs={12} style={{ marginTop: "20px" }}>
                                <div className="editTicketActions">
                                <Button className="dialog_clearAllButton cursorPointer" onClick={() => { setIsEditTicket(false); setEditTicketAssigneeSearch("") }}>
                                    Cancel
                                </Button>
                                <Button className="dialog_okButton cursorPointer" onClick={() => handleEditTicketSumbit()}>
                                    Update
                                </Button>
                                </div>
                            </Grid>
                            :""
                        }
                    </Grid>
                </ DialogContent>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});
export default withRouter(
    connect(mapStateToProps, {
        getTicketDetailsByTicketId,
        getTicketCommentsByTicketId,
        updateTicket,
        sendComment, searchTicketAssigneeByName
    })(TicketDetailsDialog)
);
