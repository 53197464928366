import React, { useState, useContext, useLayoutEffect, useMemo, useEffect } from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { AuditTableContext, TableData } from "./AuditTableContext";
import { Pagination, RowPerPageHandler } from "../Pagination/index";
import { AuditLogContent } from "./AuditLogContent";
import moment from "moment-timezone";

import './AuditLogs.css';
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants";
import { convertDateTimeIntoTimezone } from "../../utils/util";

const Row = (props: { row: TableData, userTimezone: string }) => {
    const { row, userTimezone } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell className="accordian-expand no-padding">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon className="accordian-arrow" /> : <KeyboardArrowRightIcon className="accordian-arrow" />}
                    </IconButton>
                </TableCell>
                
                <TableCell className="audit-logs-font" align="left">{convertDateTimeIntoTimezone(row.createTime.toString(), userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                <TableCell className="audit-logs-font" align="left">{row.user}</TableCell>
                <TableCell className="audit-logs-font" align="left">{row?.metadata?.description}</TableCell>
                <TableCell className="audit-logs-font" align="left">{row.resource}</TableCell>
                <TableCell className="audit-logs-font" align="center">{row.action}</TableCell>
                <TableCell className="audit-logs-font" align="left" scope="row">
                    {row.ipAddress}
                </TableCell>
            </TableRow>
            <TableRow className="padding-control">
                <TableCell className="no-padding-top-btm" colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <AuditLogContent
                            id={row.id}
                            after={row.after}
                            before={row.before}
                            metadata={row.metadata} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const AuditLogData = (props) => {
    const { authReducer } = props;
    const { filters, items, totalCount, setFilter: applyFilter, doNavigate } = useContext(AuditTableContext);

    const rowsOption = [10, 15, 25, 50, 100];

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        filters.page > 0 ? Math.max(0, filters.page * filters.limit - items.length) : 0;

    const handleChangePage = (
        event: React.SyntheticEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        applyFilter('page', newPage);
        doNavigate({
            page: newPage
        })
    };

    const handleChangeRowsPerPage: RowPerPageHandler = (
        event,
    ) => {
        const value = event.target.value;
        let limit: number;
        if (typeof value == 'string') {
            limit = parseInt(value, 10)
        } else {
            limit = value;
        }
        applyFilter('limit', limit);
        applyFilter('page', 1);
        doNavigate({
            limit,
            page: 1
        })
    };


    return (<div className="audit-data-container">
         <Paper className="pagination-position">
            <Pagination
                rowsPerPageOptions={rowsOption}
                count={totalCount}
                rowsPerPage={filters.limit}
                page={filters.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
        <Paper className="audit-container-data">
            <TableContainer className="audit-table-root">
                <Table size="small" className="audit-table-data" aria-label="collapsible sticky table" stickyHeader>
                    <TableHead className="audit-data-head">
                        <TableRow>
                            <TableCell />
                            <TableCell className="audit-data-header">TIME STAMP</TableCell>
                            <TableCell className="audit-data-header">USER</TableCell>
                            <TableCell className="audit-data-header">DESCRIPTION</TableCell>
                            <TableCell className="audit-data-header">RESOURCE</TableCell>
                            <TableCell align="center" className="audit-data-header">ACTION</TableCell>
                            <TableCell className="audit-data-header">IP ADDRESS</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody className="audit-data-header audit-logs-font">
                        {items.map((row) => (
                            <Row key={row.id} row={row} userTimezone={authReducer.userTimezone} />
                        ))}
                        {items.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={7} align='center' className="audit-logs-no-data">
                                    No Data Available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
       
    </div>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer
});

export default withRouter(
    connect(mapStateToProps, {})(AuditLogData)
);