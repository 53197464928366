import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {useSelector } from 'react-redux';
import { Box, Button, Grid, Menu, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Dialog} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import TuneIcon from '@mui/icons-material/Tune';
import close from "../../asset/image/close.svg";
import { getDecodeURI, getEncodedURI, getIntervalCheck } from "../../utils/util";
import { getOperationalUsageReportsDownload } from '../../actions/Users/authenticateUsageReports';
import _ from "lodash";
import { Pagination } from '../Pagination';
import SortArrows, { SortOrder } from "../SortArrows";
import * as XLSX from "xlsx";
import {GET_OPERATIONAL_USAGE_DATA_DUMP_DOWNLOAD_TABLE} from "../../actions/types";
import axios from "axios";

const OperationalUsageReportsDownload = (props) => {
    const {
        authReducer, reportsDownload, setReportsDownload, device, softwareVersion, total_count,
        getOperationalUsageReportsDownload,
        newSummaryStartDate,
        newSummaryEndDate,
        reports,
        location, history
    } = props;

    const dispatch = useDispatch();
    let _q = new Map<string, string>(Object.entries(getDecodeURI(location.search)));
    const [vessels, setVessels] = useState([]);

    const downloadExcel = () => {
        if (!reports?.data?.rows){
            return;
        }
    
        const headers = [
            "ts",
            "sitename",
            "deviceID",
            "wan_usage",
            "operational_usage",
            "activity_usage",
            "software_version"
        ];
        
        const worksheetData = [
            headers,
            ...reports?.data?.rows.map(row => 
                row
                    .filter(cell => !(typeof cell === 'string' && cell.startsWith("K4-")))
                    .map(cell => {
                        return cell;
                    })
            )
        ];
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Operational Reports");
        XLSX.writeFile(workbook, "OperationalReports.xlsx");
        dispatch({type: GET_OPERATIONAL_USAGE_DATA_DUMP_DOWNLOAD_TABLE, payload: {}});
    };       

    useEffect(() => {
        if (_.isEmpty(authReducer?.getVesselsListing)){
            return;
        }
        let Vessels = [];
        const filteredVessels = authReducer?.filteredVessels;
        if (filteredVessels && filteredVessels.length > 0) {
            Vessels = filteredVessels;
        } else {
            Vessels = authReducer?.getVesselsListing?.locations;
        }
        setVessels(Vessels);
    },[authReducer?.getVesselsListing, authReducer?.filteredVessels])

    useEffect ( () => {
        if(vessels.length > 0){
            getOperationalUsageReportsDownload({
                vessels : vessels,
                device_id: device,
                software_version: softwareVersion,
                start_time: newSummaryStartDate,
                end_time: newSummaryEndDate,
                sort_column:"wan_usage",
                sort_order:"desc",
                page_offset:"0",
                page_size: total_count ? total_count[0] : 0
            })
        }
    },[vessels, newSummaryStartDate, newSummaryEndDate])
    
    useEffect (() => {
        if(reportsDownload && reports?.data?.rows?.length>0){
            downloadExcel();
            setReportsDownload(false);
        }
    },[reportsDownload,reports])
    

    return (
        <div></div>
    )
} 

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    newSummaryStartDate: state.authReducer.newSummaryStartDate,
    newSummaryEndDate: state.authReducer.newSummaryEndDate,
    reports: state.authReducer.getOperationalUsageReportsDownload
});

export default withRouter(
    connect(mapStateToProps, {
        getOperationalUsageReportsDownload
    })(OperationalUsageReportsDownload)
);