import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { UPDATE_USER_EMAIL, USER_OU, } from "../../constants/Constants";
import {
  Grid, Button, Typography, Dialog, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Paper,
  Tooltip
} from "@material-ui/core";
import { getUserDetails } from "../../actions/Users/authenticateService";
import { policiesDetails } from "../../actions/Users/authenticate";
import _ from "lodash";
import "./ViewUser.css";
import viewProfileLine from "../../asset/image/viewProfileLine.svg";
import Avatar from "@mui/material/Avatar";
import CustomLoader from "../../components/Loader/CustomLoaders";
import Chip from "@mui/material/Chip";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import close from "../../asset/image/cross.svg";
import { getDataFromLocalStorage } from "../../utils/util";
import { SIDE_MENU_ITEM_ACCOUNT_MANAGEMENT } from "../../utils/constants";
import UserLoginHistory from "./../../components/UserLoginHistory";
import { SERVICE_FUNCTION_TO_FEATURE } from "./../../config";
import { PERMISSIONS } from "./../../constants/Constants";

enum AccountStatus {
  LOCKED = "LOCKED",
  PENDING_EV = "PENDING_EV",
  PENDING_AP = "PENDING_AP",
  PENDING_SR = "PENDING_SR",
  DISABLED = "DISABLED",
  UNLOCKED = "UNLOCKED"
};

const ViewUser = (props) => {
  const { getUserDetails, authReducer, history, location, policiesDetails } = props;
  const [loadingText, setLoadingText] = useState("");
  const [roleName, setRoleName] = useState("");
  const [roleDetailPopUp, setRoleDetailPopUp] = useState(false);
  const [role, setRole] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedOu, setSelectedOu] = useState(authReducer?.selectedOu?.name ? authReducer?.selectedOu?.name : '');
  let [selectedOuId, setSelectedOuId] = useState(authReducer?.selectedOu?.id ? authReducer?.selectedOu?.id : null);
  const [userDetailsList, setUserDetailsList] = useState<any>({
    USER_NAME: "",
    FIRST_NAME: "",
    LAST_NAME: "",
    ACCOUNT_STATUS: "",
    OU_NAME: "",
    POLICY_NAMES: [],
    GROUP_INFO: [],
  });
  const [viewLoginHistoryPermission, setviewLoginHistoryPermission] = useState(false);

  const breadcrumbInfo = UPDATE_USER_EMAIL;

  if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_ACCOUNT_MANAGEMENT !== authReducer.sideMenu.menuItem)) {
    authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_ACCOUNT_MANAGEMENT };
  }

  const VIEW_LOGIN_HISTORY_FEATURE = "view-login-history";
  const viewService = SERVICE_FUNCTION_TO_FEATURE[VIEW_LOGIN_HISTORY_FEATURE]["service"];
  const viewServiceFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_LOGIN_HISTORY_FEATURE]["function"];

  const URN_IETF = "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User";

  const viewEditProfile = () => {
    history.push({ pathname: "/updateUser",search:`?ouName=${selectedOu}&ouId=${selectedOuId}`, state: { demo: location.state.demo } });
  };

  const handleRoleDetailsClose = () => {
    setRoleDetailPopUp(false);
  };

  const handleRolePopUp = (e) => {
    setRoleName(e);
    if (selectedOu) {
      policiesDetails(selectedOu, e);
      setShowLoader(true);
      setLoadingText("Fetching data please wait...");
    }
    setRoleDetailPopUp(true);
  };

  const getResourceRow = (resource: any, index: any) => {
    if (0 == index) {
      return <TableCell className="viewUserTableCell">{resource?.name}</TableCell>;
    }
    return (
      <TableRow className="viewUserTableRow">
        <TableCell className="viewUserTableCell">{resource?.name}</TableCell>
      </TableRow>
    );
  };

  const getServiceRow = (service: any, index: any, _trs: any[]) => {
    if (
      !service.servicefunctions ||
      (Array.isArray(service.servicefunctions) &&
        0 == service.servicefunctions.length)
    ) {
      _trs.push(
        <TableRow style={{ background: "#fff"}}>
          <TableCell className="viewUserTableCell">{service?.name}</TableCell>
          <TableCell className="viewUserTableCell"></TableCell>
          <TableCell className="viewUserTableCell"></TableCell>
        </TableRow>
      );
    } else {
      let _fRowCount = 0;
      for (let i = 0; i < service.servicefunctions.length; i++) {
        let servicefunction: any = service.servicefunctions[i];
        if (servicefunction.resources && Array.isArray(servicefunction.resources)) {
          _fRowCount += (!servicefunction.resources || 0 === servicefunction.resources.length) ? 1 : servicefunction.resources.length;
        }
      }
      let _tds: any[] = [];
      _tds.push(<TableCell rowSpan={_fRowCount}>{service?.name}</TableCell>);
      if (!service.servicefunctions || 0 == service.servicefunctions.length) {
        _trs.push(
          <TableRow className="viewUserTableRow">
            {_tds}
            <TableCell className="viewUserTableCell"></TableCell>
            <TableCell className="viewUserTableCell"></TableCell>
          </TableRow>
        );
        return;
      }

      for (let i = 0; i < service.servicefunctions.length; i++) {
        let servicefunction: any = service.servicefunctions[i];
        let rowSpan = (!servicefunction.resources || 0 === servicefunction.resources.length) ? 1 : servicefunction.resources.length;
        _tds.push(
          <TableCell className="viewUserTableCell" rowSpan={rowSpan}>
            {servicefunction?.name}
          </TableCell>
        );
        if (!servicefunction.resources || 0 == servicefunction.resources.length) {
          _trs.push(
            <TableRow className="viewUserTableRow">
              {_tds}
              <TableCell className="viewUserTableCell"></TableCell>
            </TableRow>
          );
        } else {
          for (let j = 0; j < servicefunction.resources.length; j++) {
            let _resource = servicefunction.resources[j];
            let _rtds = getResourceRow(_resource, j);
            if (0 == j) {
              _tds.push(_rtds);
              _trs.push(<TableRow className="viewUserTableRow">{_tds}</TableRow>);
            } else {
              _trs.push(_rtds);
            }
          }
        }
        _tds = [];
      }
    }
  };

  const getRows = (permissions: any[]) => {
    let _tds: any[] = [];
    for (let i = 0; i < permissions.length; i++) {
      getServiceRow(permissions[i], i, _tds);
    }
    return _tds;
  };

  useEffect(() => {
    if (!_.isEmpty(authReducer.policiesDetails)) {
      setShowLoader(false);
      setRole(authReducer.policiesDetails?.data?.policy ? authReducer.policiesDetails.data.policy : []);
      authReducer.policiesDetails = {};
    }
  }, [authReducer.policiesDetails]);

  useEffect(() => {
    if(authReducer?.selectedOu) {
      getUserDetails(location.state.demo, authReducer.selectedOu?.name);
    }

    let PermissionCheck: any = getDataFromLocalStorage(PERMISSIONS);
    PermissionCheck = JSON.parse(PermissionCheck)

    let serviceFunctions = PermissionCheck.find(item => item.service === viewService);
    if (serviceFunctions) {
      let viewLoginHistoryPermission = serviceFunctions.servicefunctions.find(item => item.function == viewServiceFunction)
      if (viewLoginHistoryPermission) {
        setviewLoginHistoryPermission(true);
      }
      else {
        setviewLoginHistoryPermission(false);
      }
    }
  }, []);

  const getAccountStatus = (status:any, data: any)=>{
    if (status == AccountStatus.PENDING_EV || data?.URN_IETF?.accountLocked == false){
      return "Confirmed"
    } else if (status == AccountStatus.UNLOCKED) {
      return "Confirmed"
    }
    return status
  }
  useEffect(() => {
    if (authReducer.userDetails !== null) {
      let defaultDetails = { ...userDetailsList };
      defaultDetails["USER_NAME"] = authReducer.userDetails?.data?.userName;
      defaultDetails["FIRST_NAME"] = authReducer.userDetails?.data?.name?.givenName;
      defaultDetails["LAST_NAME"] = authReducer.userDetails?.data?.name?.familyName;
      defaultDetails["ACCOUNT_STATUS"] = getAccountStatus(authReducer.userDetails?.data?.accountState, authReducer.userDetails?.data);
      defaultDetails["OU_NAME"] = authReducer.userDetails?.data?.orgName;
      defaultDetails["POLICY_NAMES"] = authReducer.userDetails?.data?.permissions;
      defaultDetails["GROUP_INFO"] = authReducer.userDetails?.data?.groupInfo;
      setUserDetailsList(defaultDetails);
    }
  }, [authReducer.userDetails]);

  return (
    <div className="Form">
      {/* <BreadCrumbs breadcrumbinfo={breadcrumbInfo}></BreadCrumbs> */}
      <Grid item container style={{ backgroundColor: "#fff"}} className="">
        <Grid item style={{ paddingTop: "88px", width: "100%" }} className="changePasswordPage scrollFix">
          <Grid container xs={12} sm={11} md={10} lg={7}>
            <Grid item className="changePasswordTitle">Profile</Grid>
            <Grid item className="viewPasswordEditButton">
              <Button className="editButton" onClick={viewEditProfile}>edit</Button>
            </Grid>
          </Grid>
          <Grid container xs={12} sm={11} md={10} lg={7} className="changeProfileContainer">
            <Grid item className="avatarProfile">
              <Avatar src="/broken-image.jpg" style={{ width: "60px", height: "60px" }} />
            </Grid>
            <Grid item className="textProfile">
              <Grid container xs={12}>
                <Grid item xs={6}>
                  <Typography className="profileText">First Name</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="profileText">Last Name</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="profileText1">{userDetailsList.FIRST_NAME}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="profileText1">{userDetailsList.LAST_NAME}</Typography>
                </Grid>
              </Grid>
              <img src={viewProfileLine} style={{ marginTop: "20px" }} className="lineWidth" />
              <Grid container xs={12} style={{ marginTop: "20px" }}>
                <Grid item xs={6}>
                  <Typography className="profileText">User Name (Email)</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="profileText">Account Status</Typography>
                </Grid>
              </Grid>
              <Grid container xs={12}>
                <Grid item xs={6}>
                  <Tooltip title={userDetailsList.USER_NAME}><Typography className="profileText1 profile-user-name-text">{userDetailsList.USER_NAME}</Typography></Tooltip>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="profileText1">{userDetailsList.ACCOUNT_STATUS}</Typography>
                </Grid>
              </Grid>
              <img src={viewProfileLine} className="lineWidth marginTop20" />
              <Grid container xs={12} className="marginTop20">
                <Grid item xs={6}>
                  <Typography className="profileText">Organization</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="profileText">Permissions (Policies)</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="profileText1">{userDetailsList.OU_NAME}</Typography>
                </Grid>
                <Grid item xs={6} style={{ marginTop: "10px", maxWidth: "450px" }}>
                  {userDetailsList?.POLICY_NAMES?.map((policy, idx) => (
                    <Chip key={`policy_${idx}`} label={policy.name} variant="outlined" className="chipsPolicy" onClick={() => handleRolePopUp(policy.name)} />
                  ))}
                </Grid>
              </Grid>
              {userDetailsList?.GROUP_INFO?.length > 0 ?
                <>
                  <img src={viewProfileLine} className="lineWidth marginTop20" />
                  <Grid container xs={12} className="marginTop20 marginBottom20">
                    <Grid item xs={12}>
                      <Typography className="profileText">Group Details</Typography>
                    </Grid>
                  </Grid>
                  <Table>
                    <TableHead>
                      <TableRow className="groupTableHeading">
                        <TableCell className="profileText">Group</TableCell>
                        <TableCell className="profileText">Permissions (Policies)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userDetailsList?.GROUP_INFO?.map((group, idx) => (
                        <TableRow>
                          <TableCell className="profileText1 marginTop20 groupTableCell">{group?.ouName}</TableCell>
                          <TableCell className="marginTop20 groupTableCell">
                            {group?.permissions?.map((policy, idx) => (
                              <Chip key={`group_policy_${idx}`} label={policy?.name} variant="outlined" className="chipsPolicy" onClick={() => handleRolePopUp(policy?.name)} />
                            ))}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table >
                </>
              :<></>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container style={{ width: "100%" }} className="changePasswordPage">
          {viewLoginHistoryPermission &&
            <>
              <Grid container xs={12} sm={12} md={12} lg={12}>
                <Grid item className="changePasswordTitle">Login History</Grid>
              </Grid>
              <Grid container xs={12} sm={12} md={12} lg={12} style={{ marginLeft: '24px' }}>
                <UserLoginHistory username={location.state.demo} ></UserLoginHistory>
              </Grid>
            </>
          }
        </Grid>
      </Grid>
       <Dialog onClose={handleRoleDetailsClose} open={roleDetailPopUp} className="viewUserDialog" >
        <DialogTitle className="viewUserDialogTitle">
          <Grid item container style={{ color: "#45464E" }}>
            View Role
            <Grid style={{ marginLeft: "auto" }}>
              <img src={close} onClick={handleRoleDetailsClose} className="viewUserCloseIcon" />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className="viewUserDialogContent menuScroll" >
          <Grid container className="viewUserRoleContainer">
            <Grid item className="viewUserRole" style={{ marginLeft: "16px", fontWeight: 700, }}>Role Name:</Grid>
            <Grid item className="viewUserRole" style={{ marginLeft: "5px", fontWeight: 500,  }}>{roleName}</Grid>
          </Grid>
          <Paper elevation={2}>
          <TableContainer className="viewUserTableContainer menuScroll">
            <Table aria-label="simple table">
              <TableHead>
                <TableRow className="tableHeaderFleetVesselData">
                  <TableCell className="viewUsertableHeader ">Service Name</TableCell>
                  <TableCell align="left" className="viewUsertableHeader">Service Function Name</TableCell>
                  <TableCell align="left" className="viewUsertableHeader">Resources</TableCell>
                </TableRow>
              </TableHead>
              {role && Array.isArray(role) ? (
                <TableBody>{getRows(role)}</TableBody>
              ) : (
                <TableBody></TableBody>
              )}
            </Table>
          </TableContainer>
          </Paper>
        </DialogContent>
      </Dialog> 
      {authReducer.loading ? (
        <CustomLoader
          showLoader={showLoader}
          loadingText={
            loadingText ? loadingText : "Fetching data please wait..."
          }
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, { getUserDetails, policiesDetails })(ViewUser)
);