import * as React from 'react';
import _ from 'lodash';

import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Collapse, IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Fade from '@mui/material/Fade';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { EVENT_FILTERS } from '../../../constants/Constants';

import "./index.css";
import { nanoid } from 'nanoid';

import { AlertConfig, AlertConfigTypes, EventAlert, EventCondition, EventConditionKeys, EventConditionOperations } from '../types';

export function EventAlertConfiguration({ config,
    devices,
    handleDelete,
    handleChange,
    showHeader,
    isDefaultCardView,
    handleCancel
 }: {
        config: Extract<AlertConfig, {type: AlertConfigTypes.Event}>
        devices: string[]
        handleDelete: (id: string) => void,
        handleChange: (id: string, data: EventAlert) => void,
        showHeader: boolean,
        isDefaultCardView: boolean,
        handleCancel?: (id: string) => void
    }) {

    const [entryConditions, setEntryConditions] = React.useState<EventCondition[]>(config.data.entry)
    const [exitConditions, setExitConditions] = React.useState<EventCondition[]>(config.data.exit)
    const [editing, isEditing] = React.useState(true);
    
    React.useEffect(() => {
        if (isDefaultCardView) {
            isEditing(true);
        }
    }, [editing])

    const handleSave = () => {
        isEditing(false)
        handleChange(config.id, {
            entry: entryConditions,
            exit: exitConditions
        })
    }

    const handleClickCancel = () => {
        isEditing(false);
        if(handleCancel){
            handleCancel(config.id)
        }
    }

    const handleChangeEntryConditions = (conditions) => {
        setEntryConditions(conditions)
    }

    const handleChangeExitConditions = (conditions) => {
        setExitConditions(conditions)
    }

    return (
        <div className="event_alert_configuration--container">
            {
                showHeader ? <div className="event_alert_configuration--header">
                    <div className="event_alert_configuration--header--meta">
                        <div className="event_alert_configuration--header--meta--icon">
                            <InsertInvitationIcon />
                        </div>
                        <div className='event_alert_configuration--header--meta--title_container'>
                            <div className='event_alert_configuration--header--meta--title'>Event</div>
                            <div className='event_alert_configuration--header--meta--subtitle'>Set customized details for trigger</div>
                        </div>
                    </div>
                    <div className="event_alert_configuration--header--action_dots">
                        <MoreActionMenu isEditing={isEditing} config={config} handleDeleteEventConfig={handleDelete} />
                    </div>
                </div> : null
            }
            <Collapse in={editing}>
                {
                    editing ? <div>
                        <div className={`event_alert_configuration--body`}>
                            <div style={{ "borderBottom": "1px solid #EBEBEB" }}>
                                <EventConditions type={"Entry"} conditions={config.data.entry} devices={devices} handleChange={handleChangeEntryConditions} />
                            </div>
                            <EventConditions type={"Exit"} conditions={config.data.exit} devices={devices} handleChange={handleChangeExitConditions} />
                        </div>
                        <div className={`event_alert_configuration--footer`}>
                            <Button variant="outlined" className="" onClick={handleClickCancel}>Cancel</Button>
                            <Button variant="contained" disabled={_.isEmpty(entryConditions) || _.isEmpty(exitConditions)} className="save_config_button" onClick={handleSave}>Save</Button>
                        </div>
                    </div> : null
                }
            </Collapse>
        </div>
    )
}

function EventConditions({ type, conditions, devices, handleChange }) {
    const [rules, setRules] = React.useState<Map<string, EventCondition>>(() => {
        let m = new Map<string, EventCondition>()
        for (let c of conditions) {
            m.set(nanoid(), c)
        }
        return m;
    });

    React.useEffect(() => {
        let m = new Map<string, EventCondition>()
        for (let c of conditions) {
            m.set(nanoid(), c)
        }
        setRules(m);
    }, [conditions])

    const handleAddRule = () => {
        rules.set(nanoid(), {
            key: EventConditionKeys.Device,
            operation: EventConditionOperations.Contains,
            value: ''
        })
        setRules(new Map(rules));
    }

    const handleDeleteRule = (id: string) => {
        rules.delete(id);
        let _rules = new Map(rules);
        setRules(_rules);
        handleChange(Array.from(_rules.values()))
    }

    const handleChangeRule = (id: string, rule: EventCondition) => {
        rules.set(id, rule);
        handleChange(Array.from(rules.values()))
    }

    return (
        <div className="event_alert_configuration--body--condition_container">
            <div className="" style={{ "fontWeight": "500", "fontSize": "14px", "lineHeight": "17px", "color": "#45464E", "padding": "0.5rem", "margin": "0.5rem" }}>Set {type} Rule</div>
            <div className="event_alert_configuration--body--rule_container">
                {
                    Array.from(rules.entries()).map(([id, rule]) => <Rule key={id} id={id} rule={rule} devices={devices} handleDelete={handleDeleteRule} handleChange={handleChangeRule} />)
                }
            </div>
            <div className="event_alert_configuration--body--action">
                <div className="event_alert_configuration--body--action--add">
                    <Button onClick={handleAddRule} size="small" variant="contained" startIcon={<AddCircleOutlineIcon />} sx={{ margin: '1em', color: '#2F4B82', background: '#FFFFFF', "&:hover": { background: '#FFFFFF' }, borderRadius: '40px' }}>
                        Add Rule
                    </Button>
                </div>
            </div>
        </div>
    )
}

function Rule({ id, rule, devices, handleDelete, handleChange }) {

    const [key, setKey] = React.useState(rule.key);
    const [operation, setOperation] = React.useState(rule.operation);
    const [value, setValue] = React.useState(rule.value);
    const [valueOptions, setValueOptions] = React.useState<string[]>([]);
    const filter = createFilterOptions<string>();

    React.useEffect(() => {
        switch (key) {
            case EventConditionKeys.Module:
                setValueOptions(Object.keys(EVENT_FILTERS.MODULES))
                break;
            case EventConditionKeys.Level:
                setValueOptions(Object.keys(EVENT_FILTERS.LEVELS))
                break;
            case EventConditionKeys.Device:
                setValueOptions(!_.isEmpty(devices) ? devices : [])
                break;
            default:
                setValueOptions([])
                break;
        }
    }, [key])

    React.useEffect(() => {
        if (!_.isEmpty(value)) {
            handleChange(id, {
                key: key,
                operation: operation,
                value: value
            })
        }
    }, [key, operation, value])

    React.useEffect(() => {
        setKey(rule.key)
        setOperation(rule.operation)
        setValue(rule.value)
    }, [rule])

    const handleChangeKey = (e) => {
        setKey(e.target.value);
        setValue(null)
    }

    const handleChangeOperation = (e) => {
        setOperation(e.target.value);
    }

    return (
        <Fade in={true}>
            <div className="event_alert_configuration--body--rule">
                <div className="event_alert_configuration--body--rule--inputs">
                    <div className="event_alert_configuration--body--rule--inputs--key">
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} size="small">
                            <Select
                                value={key}
                                onChange={handleChangeKey}
                            >
                                <MenuItem value={EventConditionKeys.Device}>Device</MenuItem>
                                <MenuItem value={EventConditionKeys.Module}>Module</MenuItem>
                                <MenuItem value={EventConditionKeys.Level}>Level</MenuItem>
                                <MenuItem value={EventConditionKeys.Description}>Description</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="event_alert_configuration--body--rule--inputs--operation">
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} size="small">
                            <Select
                                value={operation}
                                onChange={handleChangeOperation}
                            >
                                <MenuItem value={"contains"}>contains</MenuItem>
                                <MenuItem value={"startswith"}>starts with</MenuItem>
                                <MenuItem value={"endswith"}>ends with</MenuItem>
                                <MenuItem value={"equal"}>equal</MenuItem>
                                <MenuItem value={"notequal"}>not equal</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="event_alert_configuration--body--rule--inputs--value">
                        <Autocomplete
                            value={value}
                            onChange={(event, newValue: any) => {
                                if (typeof newValue === 'string') {
                                    setValue(newValue);
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    setValue(newValue.inputValue);
                                } else {
                                    setValue(newValue);
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push(inputValue);
                                }

                                return filtered;
                            }}
                            options={valueOptions}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} variant="standard" />}
                        />
                    </div>
                </div>
                <div className="event_alert_configuration--body--rule--action">
                    <IconButton aria-label="more-options" onClick={() => handleDelete(id)} >
                        <RemoveCircleIcon sx={{ color: '#D14343' }} />
                    </IconButton>
                </div>
            </div>
        </Fade>
    )
}

function MoreActionMenu({ isEditing, config, handleDeleteEventConfig }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleEdit = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
        isEditing(true)
    };

    const handleDelete = (e) => {
        e.stopPropagation();
        handleDeleteEventConfig(config.id);
    };

    return (
        <React.Fragment>
            <IconButton aria-label="more-options" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="event-configuration-more-options"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'more-action-button',
                }}
                PaperProps={{
                    className: 'event-configuration-more-options-paper'
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleEdit}>Edit</MenuItem>
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
        </React.Fragment>
    )
}