import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import React, { useRef, useState } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@mui/icons-material/Close';
import "./searchBar.css"
import { getDecodeURI, getEncodedURI } from "../../utils/util";
import { Box, Grid } from "@mui/material";
const SearchBar = (props) => {
  const { handleSerach, tab, location, history } = props;
  let _q: any = getDecodeURI(location.search);
  const [value, setValue] = useState(_q.hasOwnProperty('search') ? _q.search : "");
  const ENTER_KEY_CODE = "Enter";
  const searchTimeoutRef = useRef<NodeJS.Timeout>();

  const searchBoxKeyDown = (event: any) => {
    if (event && event.key === ENTER_KEY_CODE) {
        doSearch(value);
    }
  }
  const doSearch = (value: string) => {
    handleSerach(value);
    let params: any = getDecodeURI(location.search);
    value ? params.search = value : delete params.search;
    delete params.page;
    doNavigate(params);
  }

  const doNavigate = (params) => {
    history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
  }

  const handleOnSearchClick = () => {
    doSearch(value);
  }

  const handleOnSearchClear = () => {
    setValue('');
    doSearch('');
  }

  const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(() => e.target.value);
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    searchTimeoutRef.current = setTimeout(() => {
      searchTimeoutRef.current = undefined;
      doSearch(e.target.value);
    }, 1000)
  }
  
  return (

    <Grid item xs={12} sm={12} md={12} lg={12}> <Box className="searchbarBox alignTopbarItemsCenter">
      {<TextField
    id="search"
    variant="outlined"
    placeholder="Type something"
    size="small"
    value={value}
    onChange={handleOnSearchChange}
    onKeyDown={(event) => searchBoxKeyDown(event)}
    InputLabelProps={{ className: "serachLabel" }}
    InputProps={{
      className: "searchbarInputs",
      startAdornment: (
        <InputAdornment position="start" className="alertsSearchbarInputMarginReset">
          {!value && <SearchIcon
            className="searchbarIconsPosition" 
            onClick={handleOnSearchClick}
            />}
          {value && <CloseIcon
            className="searchbarIconsPosition"
            onClick={handleOnSearchClear}
          />}
        </InputAdornment>
      ),
    }}
  />}
    </Box>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
});

export default withRouter(
  connect(mapStateToProps, {})(SearchBar)
);