import { SET_LOADING } from "./types";
import { GET_ERRORS } from "./error_types";
import { displayToastError } from "../server/request";
import { api } from "../server/request";
import { APP_ENV } from "../config";
import _ from "lodash";

export const handleResponse = (res: any, dispatch: any, handler: string, errorHandler: string = "", successHandler: string = SET_LOADING) => {
  if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
    dispatch({ type: handler, payload: res.data });
  } else if(res == 'FAILED' && handler == 'create_download_service') {
    dispatch({ type: handler, payload: res });
  } else if(handler == 'inventory_assign_device_to_warehouse') {
    dispatch({ type: handler, payload: res });
  } else {
    if (errorHandler && "" != errorHandler) {
    dispatch({ type: errorHandler, payload: res })}
    else {dispatch({ type: GET_ERRORS, payload: res })}
  }
  dispatch({ type: successHandler, payload: false });
}

export const handleResponseObj = (res: any, dispatch: any, handler: string, errorHandler: string = "", successHandler: string = SET_LOADING) => {
  if (res) {
    dispatch({ type: handler, payload: res });
  } else {
    if (errorHandler && "" != errorHandler) {
    dispatch({ type: errorHandler, payload: res })}
    else {dispatch({ type: GET_ERRORS, payload: res })}
  }
  dispatch({ type: successHandler, payload: false });
}

export const handleAttachmentResponse = (res: any, dispatch: any, handler: string) => {
  if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
    dispatch({ type: handler, payload: res });
  } else {
    dispatch({ type: GET_ERRORS, payload: res });
  }
  dispatch({ type: SET_LOADING, payload: false });
}

export const handleError = (err: any, dispatch: any, handler: string = "") => {
  if(handler && "" != handler) {
    dispatch({ type: handler, payload: { message: err } });
  }
  dispatch({ type: SET_LOADING, payload: false });
}

export const handleGraphQlResponse = (data: any, dispatch: any, handler: string, errorHandler: string = "") => {
  if (data !== undefined) {
    dispatch({ type: handler, payload: data });
  } else {
    if (errorHandler && "" != errorHandler) {
    dispatch({ type: errorHandler, payload: data })}
    else {dispatch({ type: GET_ERRORS, payload: data })}
  }
  dispatch({ type: SET_LOADING, payload: false });
}

export const handleGRaphQlError = (err: any, dispatch: any, handler: string = "") => {
  console.log("error", GET_ERRORS, err)
  if (handler && "" != handler) {
    dispatch({ type: handler, payload: { message: err } });
  }
  let queryTime = new Date().toISOString();
  let res = JSON.parse(JSON.stringify(err));
  let response =  res;
  res = res.hasOwnProperty("networkError") ? res.networkError : res;
  let statusCode = res.hasOwnProperty("statusCode") ? res.statusCode : null;
  let message = res.hasOwnProperty("bodyText") ? res.bodyText : null;
  let apiUrl = handler;

  if (handler && handler.toLowerCase().startsWith("error_")) {
    var splitted = handler.split('error_');
    if (splitted && Array.isArray(splitted) && splitted.length > 1 && splitted[1]) {
      apiUrl = splitted[1];
    }
  }

  if(_.isEmpty(message)){
    message = response.message;
  }

  let errorInfo = {
    "api" : "graphQL",
    "errorKey": message,
    "url" : apiUrl,
    "error": message,
    "statusCode": statusCode,
    "response" : response,
    "queryTime": queryTime,
    "env":APP_ENV,
    "userEmail": localStorage.getItem("loggedInEmail")
  };
  api.saveError(JSON.stringify(errorInfo));
  dispatch({ type: SET_LOADING, payload: false });
}