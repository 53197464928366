import * as React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { concat } from 'lodash';

interface Options {
    name: string;
    defaultChecked?: string[];
    values: string[];
    handleChange(checked: string[]): void;
}

export default function IndeterminateCheckboxGroup(props: Options): JSX.Element {
    const { name, defaultChecked, values, handleChange } = props;

    const [allChecked, setAllChecked] = React.useState(false);
    const [checked, setChecked] = React.useState(() => {
        let initialState = {};
        values.forEach(v => initialState[v] = false);
        if (defaultChecked) {
            defaultChecked.forEach(v => initialState[v] = true)
        }
        return initialState;
    });

    const _handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setChecked({
            ...checked,
            [event.target.name]: event.target.checked,
        });
    };

    const checkAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        let _checked = {};
        values.forEach(v => _checked[v] = event.target.checked);
        setChecked(_checked);
    }

    React.useEffect(() => {
        setAllChecked(Object.keys(checked).filter(value => checked[value]).length == Object.keys(checked).length);
        handleChange(Object.keys(checked).filter(value => checked[value]));
    }, [checked]);

    const children = (
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
            {
                values.map(value => <FormControlLabel
                    label={value}
                    key={value}
                    control={<Checkbox name={value} checked={checked[value]} onChange={_handleChange} />}
                />
                )
            }
        </Box>
    );

    return (
        <div>
            <FormControlLabel
                label={name}
                control={
                    <Checkbox
                        checked={allChecked}
                        indeterminate={Object.keys(checked).filter(v => checked[v]).length > 0 && !allChecked }
                        onChange={checkAll}
                    />
                }
            />
            {children}
        </div>
    );
}
