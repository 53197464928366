import React, { useEffect, useState } from "react";
import {
  Grid, Button, Dialog, Typography, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, IconButton, InputAdornment, Checkbox, Switch
} from "@mui/material";
import {
  fetchUserListAPI,
  resendInvite,
  inviteUser,
  organizationpolicy,
  userdetch,
  displayApplicableUserPolicies,
  policiesDetails,
  UpdateUser,
  updateUserOrganization,
  disableUserAccount,
  enableUserAccount,
  setPasswordUser,
  fetchResources,
  allTemplate,
  userDelete,
  enableDisable2Fa,
  updateUserOuHasSubOus,
  fetchServiceLines,
} from "../../actions/Users/authenticate";
import { DEFAULT_DATE_FORMAT, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import close from "../../asset/image/cross.svg";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./AccountManagmentTable.css";
import CustomTextField from "../CustomTextField/index";
import CustomLoader from "../Loader/CustomLoaders";
import userEditImage from "../../asset/image/user_edit.svg";
import userDeleteImage from "../../asset/image/user_delete.svg";
import disable from "../../asset/image/disable.svg";
import enable from "../../asset/image/enable.svg";
import Chip from "@mui/material/Chip";
import {
  CONFIRM_DIALOG_TITLE,
  MARK_AS_DETACH_CONFIRM_DIALOG_SUBTITLE,
  MARK_AS_RESEND_INVITE_CONFIRM_DIALOG_SUBTITLE,
  MARK_AS_USER_ENABLED_CONFIRM_DIALOG_SUBTITLE,
  MARK_AS_USER_DISABLED_CONFIRM_DIALOG_SUBTITLE,
  UPDATE_USER_ORGANIZATION
} from "../../config";
import ConfirmDialog from "../../components/ConfirmDialog";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import moment from "moment-timezone";
import { PERMISSIONS, USER_OU } from "../../constants/Constants";
import lock from "../../asset/image/lock.svg";
import Box from "@mui/material/Box";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Base64 } from "js-base64";
import plus from "../../asset/image/Plus.svg";
import union from "../../asset/image/Union.svg";
import { convertDateTimeIntoTimezone, getDataFromLocalStorage, getInvitedOuList, getloggedInUserOuandSubOusList, getDataFromLocalStorageParsed} from "../../utils/util";
import SearchIcon from "@mui/icons-material/Search";
import { displayToastError } from "../../server/request";
import {SERVICE_FUNCTION_TO_FEATURE} from "../../config";
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import { getDecodeURI, getEncodedURI } from "../../utils/util";
import RolesList from "./RolesList";
import { toast } from "react-toastify";
import DP_Dropdown from "../InventoryWarehouseTable/DP_Dropdown";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { getDPList } from "../../actions/Users/authenticateInventory";
import HelpIcon from '@mui/icons-material/Help';
import { GET_CSV_DATA_TO_DOWNLOAD } from "../../actions/types";
import DownloadCSV, { clearCsvDownload } from "../DownloadCSV";

const convertToCsv = (data: any[], userTimezone: string) => {
  const csvData = data.map(item => {
    return [item.name.givenName, item.name.familyName, item.userName, !item.phoneNumbers ? '' : item.phoneNumbers.map(item => item.value).join(";"), !item.permissions ? '' : item.permissions.map(item => item.name).join(";"), convertDateTimeIntoTimezone(item.meta.lastLogin, userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), item.accountState, item.orgName]
  })
  let lastLogin = 'Last Login (' + userTimezone + ')'
  const headers: string[] = ['First Name', 'Last Name', 'Email Address', 'Phone', 'Access Privileges', lastLogin, 'Status', 'Organization'];
  return [headers, ...csvData];
}

type Resource = {
  name: string,
  type: string
};
type Template = {
  policyName: string,
  templateName: string
  resources: Resource[]
};
type ServiceLine = {
  name: string,
  id: string
};

enum AccountStatus {
  LOCKED = "LOCKED",
  PENDING_EV = "PENDING_EV",
  PENDING_AP = "PENDING_AP",
  PENDING_SR = "PENDING_SR",
  DISABLED = "DISABLED",
  UNLOCKED = "UNLOCKED"
};

const AccountManagmentTable = ({
  fetchUserListAPI,
  authReducer,
  errorReducer,
  resendInvite,
  inviteUser,
  organizationpolicy,
  userdetch,
  displayApplicableUserPolicies,
  policiesDetails,
  UpdateUser,
  updateUserOrganization,
  disableUserAccount,
  enableUserAccount,
  accountManagement,
  updatePagination,
  setPasswordUser,
  history,
  allTemplate,
  page,
  limit,
  search,
  selectedOu,
  updateSearch,
  fetchResources,
  userDelete,
  selectedOuId,
  location,
  searchValue,
  setSearchValue,
  enableDisable2Fa,
  updateUserOuHasSubOus,
  getDPList,
  fetchServiceLines,
}) => {
  const [loadingText, setLoadingText] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [userEmail, setUserEmail] = useState("")
  const [userDp, setUserDp] = useState("");
  const [templateName, setTemplateName] = useState<any>(['Full Access']);
  const [starlinktemplateName, setStarlinkTemplateName] = useState<any>([]);
  let vesselObject:any = { name:"All Sites", type:"Site" }
  const [resources, setResources] = useState<Resource[]>([vesselObject]);
  let allServiceLineObject:any = { name:"All ServiceLines", is:"All Service Lines" }
  const [serviceLines, setServiceLines] = useState<ServiceLine[]>([]);
  const [open, setOpen] = React.useState(false);
  const [helperTextList, setHelperTextList] = useState({ email: "Email is invalid" });
  const [notErrorInFields, setNotErrorInFields] = useState({ email: true });
  const [allFieldsIsValidORnot, setAllFieldsIsValidORnot] = useState({
    isEmailValid: false,
    isTemplateNameValid:true,
    isResourcesValid:true,
    isRolesValid: false,
    isServiceLinesValid: false,
    isStarlinkTemplateValid: false
  });
  const [showingUsers, setShowingUsers] = useState("0");
  const [usersCount, setUsersCount] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [ouPolicy, setOuPolicy] = useState<any>("");
  const [rolePolicy, setRolePolicy] = useState<any>("");
  const [policyName, setPolicyName]: any = useState([]);
  const [policyEditName, setPolicyEditName]: any = useState([]);
  const [EditPopUp, setEditPopUp] = useState(false);
  const [editRoleValue, setEditRoleValue] = useState("");
  const [roleName, setRoleName] = useState("");
  const [roleDetailPopUp, setRoleDetailPopUp] = useState(false);
  const [listRolePopUp, setListRolePopUp] = useState(false);
  const [createPassword, setCreatePassword] = useState(false);
  const [listRoleData, setListRoleData] = useState([]);
  const [role, setRole] = useState([]);
  const [userList, setUserList] = useState<any[]>([]);
  const [value, setValue] = React.useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showSetPassword, setShowSetPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: {},
  });
  const URN_IETF = "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User";
  const [checkPermission, setCheckPermission] = useState(false);
  const [userNameSetPassword, setUserNameSetPassword] = useState("");
  const [rootOu, setRootOu] = useState("");
  const [templates, setTemplates]: any = useState([]);
  const [template, setTemplate]: any = useState<any>(null);
  const [textFieldCount, setTextFieldCount] = React.useState(0);
  const [resourceName, setResourceName] = useState<any[]>([]);
  const [templateNames, setTemplateNames] = useState<any>([]);
  const [serviceLineName, setServiceLineName] = useState<any[]>([]);
  const [starlinktemplateNames, setStarlinkTemplateNames] = useState<any>([]);
  const [clearFilterLabel, setClearFilterLabel]:any = useState(true);
  const [clearIcon, setClearIcon] = useState(false);
  const [serviceName, setServiceName] = useState("")
  const [serviceFunction, setServiceFunction] = useState("");
  const [trackService, setTrackService] = useState(true);
  const [isMfaEnable, setMfaEnabled] = useState(false);
  const [userDpId,setUserDpId] = useState("");
  const [newDpId,setNewDpId] = useState("");
  const [is2FaAvailableforDp, setIs2FaforDp] = useState(false)
  const [loggedInUserOuName, setLoggedInUserOuName] = useState("")

  const ENTER_KEY_CODE = "Enter";
  let _q = getDecodeURI(location?.search);

  const VIEW_SERVICE_FUNCTION_FEATURE = "view-service-functions";
  const viewService = SERVICE_FUNCTION_TO_FEATURE[VIEW_SERVICE_FUNCTION_FEATURE]["service"];
  const viewServiceFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_SERVICE_FUNCTION_FEATURE]["function"];
  const [viewServiceFunctionPermission, setViewServiceFunctionPermission]= useState(false);
  const [invitedOuList, setInvitedOuList] = useState<any[]>([])
  const [loggedInUserOuList, setLoggedInUserOuList] = useState<any[]>([])
  const [ouNameInUpdateAccessPrivileges, setOuNameInUpdateAccessPrivileges] = useState<string>("")
  const [userGroupOuName, setUserGroupOuName] = useState<string>("")
  const [isUpdateOU, setUpdateOu] = useState<boolean>(false)
  const [userNewOuName, setUserNewOuName] = useState<string>("")
  const [existingPrivileges, setExistingPrivileges] = useState<string>("")
  const [groupedUser, setGroupedUser] = useState<boolean>(false)
  const [existingPolicyOptions, setExistingPolicyOptions] = useState<string>("")

  const dispatch = useDispatch();

  useEffect(() => {
    getDPList();
  }, []);  

  useEffect(() => {
    if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
      if (authReducer.csvDownloadId === 'account-mgmt-csv') {
        DownloadCSV(convertToCsv(authReducer.csvDataDownloded, authReducer.userTimezone), {
          formatters : {
            5: 'ESC-COMMA',
            7: 'ESC-COMMA'
          }
        });
        return () => {
          dispatch(clearCsvDownload())
        }
      }
    }
  }, [authReducer.csvDataDownloded])

  useEffect(() => {
    if (!_.isEmpty(authReducer.getDPList) && authReducer.getDPList.dps) {
      setInvitedOuList(getInvitedOuList(authReducer.getDPList))
      setLoggedInUserOuList(getloggedInUserOuandSubOusList(authReducer.getDPList))
      authReducer.getDPList = {}
    }
  }, [authReducer.getDPList]);

  useEffect(()=>{
    const service = JSON.parse(localStorage.getItem('permissions') || '');
    service?.map((list)=>{
      if(list.service == 'iam'){
        setServiceName(list.service)
        setServiceFunction(list?.servicefunctions[0]?.function)
      }
    })
  },[])

  // useEffect(() => {
  //   updateUserOuHasSubOus(authReducer)
  // }, [authReducer.getOrganisationUnitList]);

  useEffect(() => {
    loadUsers();
  }, [selectedOu, page, limit, search]);

  useEffect(() => {
    setSearchValue(search);
  }, [search]);

  useEffect(() => {
    if (!_.isEmpty(authReducer?.fetchResources)) {
      let resourceRecords = authReducer.fetchResources?.locations;
      if (resourceRecords && Array.isArray(resourceRecords)) {
        let resourceSort = resourceRecords.map((index, i) => index.name);
        resourceSort.sort();
        let sortedResourceNames: any[] = resourceRecords.sort((a: any, b: any) => {
          return ('' + a.name).localeCompare(b.name);
        })
        let externalAllVesselObj = {name: "All Sites", description: "", type: "Site", is_all: true}
        sortedResourceNames?.unshift(externalAllVesselObj);
        let defaultAllVessel =  "All Sites"
        let allVessel = sortedResourceNames.find(item => item.name === defaultAllVessel)
        sortedResourceNames = sortedResourceNames.filter(item => item.name !== defaultAllVessel)
        if(allVessel){
          sortedResourceNames.unshift(allVessel);
          setResources([allVessel])
          valueIsVlidOrNot(true, "resources")
          valueIsVlidOrNot(true, "template_name")
        }
        setResourceName(sortedResourceNames);
      } else {
        setResourceName([]);
      }
      authReducer.fetchResources = {};
    }
  }, [authReducer.fetchResources]);

  useEffect(() => {
    if (!_.isEmpty(authReducer?.fetchServiceLines)) {
      let serviceLinesData = Object.assign(authReducer.fetchServiceLines?.serviceLines);
      let externalAllServiceLineObj = {name: "All Service Lines", id:"All Service Lines"}
      if (!serviceLinesData.some(serviceLine => serviceLine.name === "All Service Lines")) {
        serviceLinesData.unshift(externalAllServiceLineObj);
      }
      setServiceLineName(serviceLinesData)
    }
  }, [authReducer.fetchServiceLines]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.allTemplate)) {
      let templateRecords = authReducer.allTemplate.data;
      let templateNames = templateRecords.map((index, i) => index.name)
      let index = 'Full Access';
      let otherTemplateNames = templateNames.filter((item) => item !== index && item !== "Starlink Access" && item !== "Starlink Admin Access" && item !== "Starlink Full Access");
      otherTemplateNames.unshift(index);
      setTemplateNames(otherTemplateNames);

      let starlinktemplateNames = templateNames.filter((item) => item.includes("Starlink"));
      setStarlinkTemplateNames(starlinktemplateNames)

      authReducer.allTemplate = {};
    }
  }, [authReducer.allTemplate]);

  useEffect(() => {
    accountManagement.current = handleClickOpen;
    setUserDp(selectedOu);
  }, [selectedOu]);

  const loadUsers = () => {
    if (selectedOu && '' !== selectedOu) {
      fetchUserListAPI(selectedOu, page, limit, searchValue);
    }
  }
  const handleDisableUserChange = (e: any) => {
    let payload = { userName: e.userName, ouName: selectedOu };
    disableUserAccount(payload);
    setShowLoader(true);
    setLoadingText("Disabling the user");
  }

  const handleEnableUserChange = (e: any) => {
    let payload = { userName: e.userName, ouName: selectedOu };
    enableUserAccount(payload);
    setShowLoader(true);
    setLoadingText("Enabling the user");
  }

  const handleDisable2FA = () => {
    let twofaPayload = {
      dpId: userDpId,
      usernames:[userEmail],
      enabled:false,
      mfaAskInMinutes:0
    }
    enableDisable2Fa(twofaPayload);
    setMfaEnabled(false)
  }

  const handleEnable2FA = () => {
    let twofaPayload = {
      dpId: userDpId,
      usernames:[userEmail],
      enabled:true,
      mfaAskInMinutes:0
    }
    enableDisable2Fa(twofaPayload);
    setMfaEnabled(true)
  }

  const handleSetCreatePassword = () => {
    if(password !== confirmPassword)
    {
      toast.error('Please make sure you passwords match', {
        position: "bottom-left"
      })
      return;
    }
    let payload = { newPassword: Base64.encode(password), ouName: rootOu, userName: userNameSetPassword };
    setPasswordUser(payload);
    handleCreateClose()
  }

  const handleUpdateOuChange = (e:any) =>{
    let checked = e?.target?.checked
    if (checked) {
      setConfirmDialog({
        isOpen: true,
        title: CONFIRM_DIALOG_TITLE,
        subTitle: UPDATE_USER_ORGANIZATION,
        onConfirm: () => {
          if (checked) {
            setUpdateOu(checked)
            setRolePolicy([])
            setPolicyEditName([])
          }
        },
      });
    } else{
      setUpdateOu(checked)
      setRolePolicy(existingPrivileges)
      setPolicyEditName(existingPolicyOptions)
    }
  }

  const handleUserNewOuChange = (ou: any) => {
    setUserNewOuName(ou.name);
    setNewDpId(ou.id)
    fetchResources(ou.name, 0, 500, serviceName, serviceFunction);
    fetchServiceLines(ou.id);
    allTemplate(ou.name, true);
  }

  useEffect(() => {
    let PermissionCheck: any = getDataFromLocalStorage(PERMISSIONS);
    PermissionCheck = JSON.parse(PermissionCheck)
    let iam = PermissionCheck.find(item => item.service === "iam")
    if (iam) {
      let ouAdmin = iam.servicefunctions.find(item => item.function === "OuAdmin")
      const loggedInUser = getDataFromLocalStorageParsed("loggedInUser");
      const loggedInUserOu = loggedInUser?.data?.ou?.name;
      setLoggedInUserOuName(loggedInUserOu)
      if (ouAdmin) {
        setCheckPermission(true);
      }
      else {
        setCheckPermission(false);
      }
    }

    let serviceFunctions = PermissionCheck.find(item => item.service === viewService);
    if(serviceFunctions){
      let viewServiceFunctionPermission = serviceFunctions.servicefunctions.find(item => item.function == viewServiceFunction)
      if(viewServiceFunctionPermission){
        setViewServiceFunctionPermission(true);
      }
      else{
        setViewServiceFunctionPermission(false);
      }
    }
  }, [])


  useEffect(() => {
    let firstRowIndex = page * limit + 1;
    let lastRowIndex = firstRowIndex + limit - 1;
    lastRowIndex = lastRowIndex > usersCount ? usersCount : lastRowIndex;
    setShowingUsers(firstRowIndex + "-" + lastRowIndex);
  }, [usersCount]);

  useEffect(() => {
    if (
      !_.isEmpty(authReducer.usersList) &&
      authReducer.usersList &&
      authReducer.usersList.data
    ) {
      const data = authReducer.usersList.data;
      setIs2FaforDp(data?.mfaEnabledDP)
      setUsersCount(data.total);
      if (data !== undefined) {
        setLastPage(Math.ceil(data.total / limit) - 1);
        if (data.total <= limit) {
          setShowingUsers(`${data.records.length}`);
        } else {
          let firstRowIndex = page * limit + 1;
          let lastRowIndex = firstRowIndex + limit - 1;
          lastRowIndex = lastRowIndex > usersCount ? usersCount : lastRowIndex;
          setShowingUsers(firstRowIndex + "-" + lastRowIndex);
        }
        setUserList(_getUsers(data.records));
      }
      authReducer.usersList = {};
      updatePagination({ total: data.total, page: data.page, pages: data.pages, limit: data.limit, size: data.records.length });
      setShowLoader(false);
    }
  }, [authReducer.usersList]);

  const handleInputResourceChange = (e, newValue) => {
    const { name, value } = e.target;
    console.log(newValue);
    const resources = newValue.map((resource)=>({name:resource.name, type:resource.type}))
    setResources(resources);
    valueIsVlidOrNot(resources?.length > 0 ? true: false, "resources");
  }

  const handleInputTemplateChange = (e, newValue) => {
    const { name, value } = e.target;
    setTemplateName(newValue);
    valueIsVlidOrNot(newValue?.length > 0 ? true: false, "template_name")
  }

  const handleInputStarlinkTemplateChange = (e, newValue) => {
    const { name, value } = e.target;
    setStarlinkTemplateName(newValue);
    valueIsVlidOrNot(newValue?.length > 0 ? true: false, "starlink_template")
    if(newValue == "Starlink Admin Access") {
      setServiceLines([{
        name:"All Service Lines",
        id:"All Service Lines",
      }])
      valueIsVlidOrNot(true, "service_lines");
    }
  }

  const handleInputServiceLineChange = (e, newValue) => {
    const isStarlinkAdminAccess = starlinktemplateName.filter(template => template.includes('Starlink Admin'))
    if (isStarlinkAdminAccess.length != 0){
      return
    }
    const { name, value } = e.target;
    console.log(newValue);
    const serviceLines = newValue.map((serviceLine)=>({name:serviceLine.name, id:serviceLine.id}))
    setServiceLines(serviceLines);
    valueIsVlidOrNot(serviceLines?.length > 0 ? true: false, "service_lines");
  }

  const handleClose = () => {
    setEmail("");
    resetUserDp();
    setAllFieldsIsValidORnot({
      isEmailValid: false,
    isTemplateNameValid:true,
    isResourcesValid:true,
    isRolesValid: false,
    isServiceLinesValid: true,
    isStarlinkTemplateValid: true,
    });
    setOpen(false);
    setServiceLines([])
    setStarlinkTemplateName([])
  };

  const handleEditClose = () => {
    setEditPopUp(false);
    resetUserDp();
  };

  const handleRoleDetailsClose = () => {
    setRoleDetailPopUp(false);
  };

  const handleListRoleClose = () => {
    setListRolePopUp(false);
  };

  const handleCreateClose = () => {
    setCreatePassword(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
    if (selectedOu) {
      setShowLoader(true);
      setLoadingText("Fetching data please wait...");
    }
  };

  useEffect(() => {
    if(trackService) {
      const service = JSON.parse(localStorage.getItem('permissions') || '');
      let service_Name = "";
      let service_Function = "";
      service?.map((list)=>{
        if(list.service == 'iam'){
        
          service_Name = list.service;
          service_Function = list?.servicefunctions[0]?.function;
         
        }
      })
      // organizationpolicy(selectedOu);
      allTemplate(selectedOu, true);
      // fetchResources(selectedOu, 0, 500, service_Name, service_Function);
      setTrackService(false);
    }
    else if(selectedOu){
      // organizationpolicy(selectedOu);
      allTemplate(selectedOu, true);
      // fetchResources(selectedOu, 0, 500, serviceName, serviceFunction);
    }
  },[selectedOu])

  const valueIsVlidOrNot = (value, field) => {
    if(field == "email"){
      setAllFieldsIsValidORnot((prevState)=>{
        return {
          ...prevState,
          isEmailValid: value
        }
      });
    }
    else if(field == "template_name"){
      setAllFieldsIsValidORnot((prevState)=>{
        return {
          ...prevState,
          isTemplateNameValid: value
        }
      });
    }
    else if(field == 'resources'){
      setAllFieldsIsValidORnot((prevState)=>{
        return {
          ...prevState,
          isResourcesValid: value
        }
      });
    }
    else if(field == 'roles'){
      setAllFieldsIsValidORnot((prevState)=>{
        return {
          ...prevState,
          isRolesValid: value
        }
      });
    }
    else if(field == 'service_lines'){
      setAllFieldsIsValidORnot((prevState)=>{
        return {
          ...prevState,
          isServiceLinesValid: value
        }
      });
    }
    else if(field == 'starlink_template'){
      setAllFieldsIsValidORnot((prevState)=>{
        return {
          ...prevState,
          isStarlinkTemplateValid: value
        }
      });
    }
  };

  const validateEmails = (emails) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailList = emails.split(';');
    
    for (const email of emailList) {
      if (!emailRegex.test(email.trim())) {
        return false;
      }
    }
    
    return true;
  };

  const handleChange = (event, newValue = "") => {
    const { target } = event;
    const { name } = target;
    if (name === "email") {
      notErrorInFields["email"] = validateEmails(event.target.value)
      // const re =
      //   /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))+/;
      // notErrorInFields["email"] = re.test(event.target.value);
      setEmail(event.target.value);
      setNotErrorInFields(notErrorInFields);
      valueIsVlidOrNot(validateEmails(event.target.value), "email");
    } else {
      valueIsVlidOrNot((newValue.length > 0 ? true: false), "roles");
      setOuPolicy(newValue);
    }
  };

  const handleEditChange = (event, newValue:any) => {
    const { target } = event;
    const { name } = target;
    setRolePolicy(newValue);
    valueIsVlidOrNot((newValue?.length > 0 ? true: false), "roles");
    if(newValue.filter(policy => policy.includes('Starlink Admin Access')).length > 0){
      if (starlinktemplateName.includes("Starlink Access") || starlinktemplateName.includes("Starlink Full Access")) {
        setServiceLines([])
        setStarlinkTemplateName([])
      }
    }

    if(newValue.filter(policy => policy.includes('Starlink Access')).length > 0){
      if (starlinktemplateName.includes("Starlink Admin Access") || starlinktemplateName.includes("Starlink Full Access")) {
        setServiceLines([])
        setStarlinkTemplateName([])
      }
    }

    if(newValue.filter(policy => policy.includes('Starlink Full Admin')).length > 0){
      if (starlinktemplateName.includes("Starlink Access") || starlinktemplateName.includes("Starlink Admin Access")) {
        setServiceLines([])
        setStarlinkTemplateName([])
      }
    }
  };

  const handleCreatePasswordChange = (event) => {
    const { target } = event;
    const { name } = target;
    if (name === "password") {
      setPassword(event.target.value);
    }
    if (name === "confirmPassword") {
      setConfirmPassword(event.target.value);
    }
  }

  const handleOu = (ou: any) => {
    setUserDp(ou.name);
    fetchResources(ou.name, 0, 500, serviceName, serviceFunction);
    fetchServiceLines(ou.id)
    allTemplate(ou.name, true);
    setServiceLines([])
    setStarlinkTemplateName([])
  }

  const handleInviteClient = () => {
    /*
     * To create a payload, first create the dynamic policy name
     * according to the template selected and selected resources
     */

    let ouPolicies:any[] = [];
    let templates:any[] = [];
    ouPolicies = [];

    templateName?.forEach((ele, i) => {
      resources?.forEach((res) => {
      ouPolicies.push(templateName[i] + '_' + res.name)
      })
    })

    starlinktemplateName?.forEach((ele, i) => {
      serviceLines?.forEach((res) => {
        let policiesName = ""
        if(res.name == "All Service Lines"){
            policiesName = starlinktemplateName[i] + '_' + res.name
        } else {
            policiesName = starlinktemplateName[i] + '_' + res.name + "(" + res.id + ")"
        }
      ouPolicies.push(policiesName)
      })
    })

    templateName?.forEach((el, i)=>{
      resources?.forEach((resource)=>{
       let obj = {policyName: templateName[i] + "_" + resource.name,
        templateName: templateName[i],
        resources:[{
          name: resource.name,
          type: "Vessel"
        }]}
        templates.push(obj);
      })
    })

    starlinktemplateName?.forEach((el, i)=>{
      serviceLines?.forEach((serviceLine)=>{
        let policiesName = ""
        if(serviceLine.name == "All Service Lines"){
            policiesName = starlinktemplateName[i] + '_' + serviceLine.name
        } else {
            policiesName = starlinktemplateName[i] + '_' + serviceLine.name + "(" + serviceLine.id + ")"
        }
       let obj = {
        policyName: policiesName,
        templateName: starlinktemplateName[i],
        serviceLines:[{
          name: serviceLine.name,
          id: serviceLine.id
        }]}
        templates.push(obj);
      })
    })

    let payload = {
      userName: email,
      ouName: userDp,
      policies: ouPolicies,
      expireOn: value ? moment(value).format("MM-DD-YYYY") : null,
      templates: templates,
    };
    inviteUser(payload);
    setShowLoader(true);
    setLoadingText("Inviting user to Portal");
    setResources([{ name:"All Sites", type:"Site" }]);
    setTemplateName(['Full Access']);
    setEmail("");
    resetUserDp();
    setAllFieldsIsValidORnot({
      isEmailValid: false,
      isTemplateNameValid:true,
      isResourcesValid:true,
      isRolesValid: false,
      isServiceLinesValid: true,
      isStarlinkTemplateValid: true
    });
    setOpen(false);
    setServiceLines([])
    setStarlinkTemplateName([])
  };

  const resetUserDp = () => {
    if (selectedOu !== userDp) {
      setUserDp(selectedOu);
      // reset resource names
      fetchResources(selectedOu, 0, 500, serviceName, serviceFunction);
      fetchServiceLines(authReducer?.selectedOu?.id)
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowSetPassword = () => {
    setShowSetPassword(!showSetPassword);
  };

  const _getUsers = (theUserList: any[]) => {
    let rows: any[] = [];
    if (Array.isArray(theUserList)) {
      for (let i = 0; i < theUserList.length; i++) {
        if (theUserList[i]) {
          let u_name = theUserList[i]?.name?.givenName ? theUserList[i].name.givenName : "-";
          let u_lastName = theUserList[i]?.name?.familyName ? theUserList[i].name.familyName : "-";
          let u_userName = theUserList[i]?.userName ? theUserList[i].userName : "-";
          let u_expireOn = theUserList[i]?.expireOn ? theUserList[i].expireOn : "-";
          let u_userDp = theUserList[i]?.orgName ? theUserList[i].orgName : "-";
          //if the accountLocked is false and if it is in the pending state we can mark it as unlocked
          let u_status = ((theUserList[i][URN_IETF]["accountState"] === AccountStatus.PENDING_EV ||
                            theUserList[i][URN_IETF]["accountState"] === AccountStatus.PENDING_SR || 
                            theUserList[i][URN_IETF]["accountState"] === AccountStatus.PENDING_AP) 
                          && theUserList[i][URN_IETF]["accountLocked"] == false) ? AccountStatus.UNLOCKED : theUserList[i][URN_IETF]["accountState"];
          let u_userState = theUserList[i].hasOwnProperty(URN_IETF) && theUserList[i][URN_IETF]["accountState"] === "DISABLED" ?
            theUserList[i][URN_IETF]["accountState"] : "-";
          let permissions: any = [];
          if (theUserList[i].permissions && Array.isArray(theUserList[i].permissions)) {
            permissions = theUserList[i].permissions;
          }
          let mfaEnabled = theUserList[i]?.mfaEnabled;
          let dpId = theUserList[i]?.dpId;
          let u_phoneNumber = theUserList[i]?.phoneNumbers ? theUserList[i].phoneNumbers[0].value : "-";
          let u_lastLogin = theUserList[i]?.meta?.lastLogin;
          let groupedUser = theUserList[i]?.groupedUser;
          let groupDp = ""
          if(groupedUser){
            groupDp = theUserList[i]?.groupInfo[0]?.ouName;
          }
          rows.push(createData(u_name, u_lastName, u_userName, permissions, u_status, u_expireOn, u_userState, u_userDp, mfaEnabled, dpId, u_phoneNumber, u_lastLogin, groupedUser, groupDp));
        }
      }
    }
    return rows;
  };

  const handleResendInvite = (user) => {
    let payload = { userName: user.userName, ouName: selectedOu };
    resendInvite(payload);
    setShowLoader(true);
    setLoadingText("Resending the invite..");
  };

  const handleCreatePassword = (e: any) => {
    setUserNameSetPassword(e.userName);
    const _userOu: any = getDataFromLocalStorage(USER_OU);
    const userOu: any = _userOu ? JSON.parse(_userOu) : {};
    if (userOu?.name) {
      setRootOu(userOu.name);
    }
    setPassword("");
    setConfirmPassword("");
    setCreatePassword(true);
  }

  const handleEditRole = (e: any) => {
    setUserDpId(e?.dpId);
    setMfaEnabled(e?.mfaEnabled);
    setUserEmail(e?.userName);
    setRolePolicy(e.permissions.map((item, i) => item.name));
    setExistingPrivileges(e.permissions.map((item, i) => item.name))
    setPolicyEditName(e.permissions.map((item, i) => item.name));
    setExistingPolicyOptions(e.permissions.map((item, i) => item.name));
    setEditRoleValue(e.userName);
    setEditPopUp(true);
    valueIsVlidOrNot((e.permissions?.length > 0 ? true : false), "roles")
    let userOu = ""
    if (e.groupedUser){
      userOu = e.groupDp
    } else{
      userOu = e.userDp
    }
    
    setUserDp(userOu)
    setOuNameInUpdateAccessPrivileges(e.userDp)
    setUserGroupOuName(e.groupDp)
    setGroupedUser(e.groupedUser)

    fetchResources(userOu, 0, 500, serviceName, serviceFunction);
    fetchServiceLines(e?.dpId);
    if (selectedOu) {
      // displayApplicableUserPolicies(e.userName, selectedOu);
      // allTemplate(selectedOu, true);
      // fetchResources(selectedOu, 0, 500);
      setShowLoader(true);
      setLoadingText("Fetching data please wait...");
    }
    setTemplateName([]);
    setResources([]);
    setStarlinkTemplateName([]);
    setServiceLines([]);
    setUpdateOu(false)
  };

  const handleEditUser = () => {
    let templates:any[] = [];
    let ouPolicies:any[] = [];
    
    templateName && templateName?.forEach((ele, i) => {
      resources?.forEach((res) => {
      ouPolicies.push(templateName[i] + '_' + res.name)
      })
    })

    templateName && templateName?.forEach((el, i)=>{
      resources?.forEach((resource)=>{
       let obj = {policyName: templateName[i] + "_" + resource.name,
        templateName: templateName[i],
        resources:[{
          name: resource.name,
          type: resource.type
        }]}
        templates.push(obj);
      })
    })

    starlinktemplateName && starlinktemplateName?.forEach((ele, i) => {
      serviceLines?.forEach((res) => {
        let policiesName = ""
        if(res.name == "All Service Lines"){
            policiesName = starlinktemplateName[i] + '_' + res.name
        } else {
            policiesName = starlinktemplateName[i] + '_' + res.name + "(" + res.id + ")"
        }

        ouPolicies.push(policiesName)
      })
    })

    starlinktemplateName && starlinktemplateName?.forEach((el, i)=>{
      serviceLines?.forEach((serviceLine)=>{
        let policiesName = ""
        if(serviceLine.name == "All Service Lines"){
            policiesName = starlinktemplateName[i] + '_' + serviceLine.name
        } else {
            policiesName = starlinktemplateName[i] + '_' + serviceLine.name + "(" + serviceLine.id + ")"
        }
       let obj = {
        policyName: policiesName,
        templateName: starlinktemplateName[i],
        serviceLines:[{
          name: serviceLine.name,
          id: serviceLine.id
        }]}
        templates.push(obj);
      })
    })
    let payload = {
      userName: editRoleValue,
      ouName: userDp,
      policies: [...rolePolicy, ...ouPolicies],
      templates: templates,
      changeOU: isUpdateOU,
      newOuName :userNewOuName
    };
    if (isUpdateOU) {
      let kapturePayload = {
        userEmail: editRoleValue,
        k4DpId: newDpId,
        sport: payload
      }
      updateUserOrganization(kapturePayload)
    } else {
      UpdateUser(payload);
    }
    setShowLoader(true);
    setLoadingText("Edit Role...");
    setResources([{ name:"All Sites", type:"Site" }]);
    setTemplateName(['Full Access']);
    if(is2FaAvailableforDp) {
      if(isMfaEnable) {
        handleEnable2FA()
      } else {
        handleDisable2FA()
      }
    }
    setEmail("");
    setAllFieldsIsValidORnot({
      isEmailValid: false,
      isTemplateNameValid:true,
      isResourcesValid:true,
      isRolesValid: false,
      isServiceLinesValid: true,
      isStarlinkTemplateValid: true
    });
    setOpen(false);
    setServiceLines([])
    setStarlinkTemplateName([])
  };
  
  const searchBoxKeyDown = (event: any) => {
    if (event && event.key === ENTER_KEY_CODE) {
        doSearch();
    }
  }

  const doSearch = () => {
      let params: any = getDecodeURI(location?.search);
      searchValue ? params.search = searchValue : delete params.search;
      delete params.page;

      updateSearch(searchValue);
      doNavigate(location.url, params);
      setClearIcon(false);
  }

  const doNavigate = (pathname, params={}) => {
    history.push({ pathname: pathname, search: `?${getEncodedURI(params)}` });
  }

  useEffect(() => {
    if (!_.isEmpty(authReducer.inviteUser)) {
      setShowLoader(false);
      loadUsers();
      setShowLoader(true);
      setLoadingText("Fetching data please wait...");
      authReducer.inviteUser = {};
    }
  }, [authReducer.inviteUser]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.UpdateUser)) {
      setShowLoader(false);
      setEditPopUp(false);
      loadUsers();
      setShowLoader(true);
      setLoadingText("Fetching data please wait...");
      authReducer.UpdateUser = {};
    }
  }, [authReducer.UpdateUser]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.updateUserOrganization)) {
      setShowLoader(false);
      setEditPopUp(false);
      loadUsers();
      setShowLoader(true);
      setLoadingText("Fetching data please wait...");
      authReducer.updateUserOrganization = {};
    }
  }, [authReducer.updateUserOrganization]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.userdelete)) {
      setShowLoader(false);
      userList.length = 0;
      loadUsers();
      setShowLoader(true);
      setLoadingText("Fetching data please wait...");
      authReducer.userdelete = {};
    }
  }, [authReducer.userdelete]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.disableUserAccount)) {
      setShowLoader(false);
      loadUsers();
      setShowLoader(true);
      setLoadingText("Fetching data please wait...");
      authReducer.disableUserAccount = {};
    }
  }, [authReducer.disableUserAccount]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.enableUserAccount)) {
      setShowLoader(false);
      loadUsers();
      setShowLoader(true);
      setLoadingText("Fetching data please wait...");
      authReducer.enableUserAccount = {};
    }
  }, [authReducer.enableUserAccount]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.organizationpolicy)) {
      let records = authReducer.organizationpolicy.data;
      let record1 = records.permissions;
        setPolicyName(record1.map((index, i) => index.name));
        setShowLoader(false);
    }
  }, [authReducer.organizationpolicy]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.displayApplicableUserPolicies)) {
      let records = authReducer.displayApplicableUserPolicies?.data?.permissions ? authReducer.displayApplicableUserPolicies?.data?.permissions : null;
      //setPolicyEditName(records.map((index, i) => index.name));
      setShowLoader(false);
    }
  }, [authReducer.displayApplicableUserPolicies]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.resendInvite)) {
      authReducer.resendInvite = {};
    }
  }, [authReducer.resendInvite]);

  useEffect(() => {
    if (!_.isEmpty(errorReducer.errorResendInvite)) {
      displayToastError(errorReducer.errorResendInvite.description)
      errorReducer.errorResendInvite = {};
    }
  }, [errorReducer.errorResendInvite]);

  useEffect(() => {
    if (!_.isEmpty(errorReducer.errorInviteUser)) {
      displayToastError(errorReducer.errorInviteUser.description.detail);
      errorReducer.errorInviteUser = {};
    }
  }, [errorReducer.errorInviteUser]);

  useEffect(() => {
    if (!_.isEmpty(errorReducer.errorDetachUser)) {
      displayToastError(errorReducer.errorDetachUser.description);
      errorReducer.errorDetachUser = {};
    }
  }, [errorReducer.errorDetachUser]);

  useEffect(() => {
    if (!_.isEmpty(errorReducer.errorDisplayApplicableUserPolicies)) {
      displayToastError(errorReducer.errorDisplayApplicableUserPolicies.description);
      errorReducer.errorDisplayApplicableUserPolicies = {};
    }
  }, [errorReducer.errorDisplayApplicableUserPolicies]);

  useEffect(() => {
    if (!_.isEmpty(errorReducer.errorEnableUserAccount)) {
      displayToastError(errorReducer.errorEnableUserAccount.description);
      errorReducer.errorEnableUserAccount = {};
    }
  }, [errorReducer.errorEnableUserAccount]);

  useEffect(() => {
    if (!_.isEmpty(errorReducer.errorDisableUserAccount)) {
      displayToastError(errorReducer.errorDisableUserAccount.description);
      errorReducer.errorDisableUserAccount = {};
    }
  }, [errorReducer.errorDisableUserAccount]);

  useEffect(() => {
    if (!_.isEmpty(errorReducer.errorSetPasswordUser)) {
      displayToastError(errorReducer.errorSetPasswordUser.description);
      errorReducer.errorSetPasswordUser = {};
    }
  }, [errorReducer.errorSetPasswordUser]);

  useEffect(() => {
    if (!_.isEmpty(errorReducer.errorFetchUsers)) {
      displayToastError(errorReducer.errorFetchUsers.description);
      errorReducer.errorFetchUsers = {};
    }
  }, [errorReducer.errorFetchUsers]);

  useEffect(() => {
    if (!_.isEmpty(errorReducer.errorFetchOuResources)) {
      displayToastError(errorReducer.errorFetchOuResources.description)
      errorReducer.errorFetchOuResources = {};
    }
  }, [errorReducer.errorFetchOuResources]);
  
  useEffect(() => {
    if (!_.isEmpty(authReducer.policiesDetails)) {
      setShowLoader(false);
        setRole(authReducer.policiesDetails?.data?.policy ? authReducer.policiesDetails.data.policy : []);
      authReducer.policiesDetails = {};
    }
  }, [authReducer.policiesDetails]);

  const createData = (name: string, lastName: string, userName: string, permissions: string, status: string, expireOn: string, userState: string, userDp: string, mfaEnabled:boolean, dpId: string, phoneNumber: string, lastLogin: string, groupedUser:boolean, groupDp:string) => {
    return { name, lastName, userName, permissions, status, expireOn, userState, userDp , mfaEnabled, dpId, phoneNumber, lastLogin, groupedUser, groupDp};
  };

  const handleDelete = (e: any, dpId: any) => {
    let payload = {
      "username": e?.userName,
      "dpId": dpId
    };
    userDelete(payload);
    setShowLoader(true);
    setLoadingText("Deatching the user");
  };

  const handleRolePopUp = (e) => {
    setRoleName(e);
    if (selectedOu) {
      policiesDetails(selectedOu, e);
      setShowLoader(true);
      setLoadingText("Fetching data please wait...");
    }
    setRoleDetailPopUp(true);
  };

  const handleListRolePopUp = (e, value) => {
    setListRoleData(e);
    setListRolePopUp(true);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getResourceRow = (resource: any, index: any) => {
    if (0 == index) {
      return <TableCell>{resource?.name}</TableCell>;
    }
    return (
      <TableRow style={{ background: "#fff" }}>
        <TableCell>{resource?.name}</TableCell>
      </TableRow>
    );
  };

  const getServiceRow = (service: any, index: any, _trs: any[]) => {
    if (
      !service.servicefunctions ||
      (Array.isArray(service.servicefunctions) &&
        0 == service.servicefunctions.length)
    ) {
      _trs.push(
        <TableRow style={{ background: "#fff" }}>
          <TableCell>{service?.name}</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      );
    } else {
      let _fRowCount = 0;
      for (let i = 0; i < service.servicefunctions.length; i++) {
        let servicefunction: any = service.servicefunctions[i];
        if (servicefunction.resources && Array.isArray(servicefunction.resources)) {
          _fRowCount += (!servicefunction.resources || 0 === servicefunction.resources.length) ? 1 : servicefunction.resources.length;
        }
      }

      let _tds: any[] = [];
      _tds.push(<TableCell rowSpan={_fRowCount}>{service?.name}</TableCell>);
      if (!service.servicefunctions || 0 == service.servicefunctions.length) {
        _trs.push(
          <TableRow style={{ background: "#fff" }}>
            {_tds}
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        );
        return;
      }

      for (let i = 0; i < service.servicefunctions.length; i++) {
        let servicefunction: any = service.servicefunctions[i];
        let rowSpan = (!servicefunction.resources || 0 === servicefunction.resources.length) ? 1 : servicefunction.resources.length;
        _tds.push(
          <TableCell rowSpan={rowSpan}>
            {servicefunction?.name}
          </TableCell>
        );
        if (!servicefunction.resources || 0 == servicefunction.resources.length) {
          _trs.push(
            <TableRow style={{ background: "#fff" }}>
              {_tds}
              <TableCell></TableCell>
            </TableRow>
          );
        } else {
          for (let j = 0; j < servicefunction.resources.length; j++) {
            let _resource = servicefunction.resources[j];
            let _rtds = getResourceRow(_resource, j);
            if (0 == j) {
              _tds.push(_rtds);
              _trs.push(<TableRow style={{ background: "#fff" }}>{_tds}</TableRow>);
            } else {
              _trs.push(_rtds);
            }
          }
        }
        _tds = [];
      }
    }
  };

  const getRows = (permissions: any[]) => {
    let _tds: any[] = [];
    for (let i = 0; i < permissions.length; i++) {
      getServiceRow(permissions[i], i, _tds);
    }

    return _tds;
  };

  const handleTwoFAChange = (e:any) => {
    setMfaEnabled(e?.target?.checked);
  }
  
  return (
    <div className="Form menuScroll">
      <Grid style={{ backgroundColor: "#FAF9FC", marginLeft: "25px", marginRight: "25px"}}>
        <TableContainer className="alertsTable-FleetDashboard">
            <Table aria-label="simple table">
                <TableHead className="tableHead alertsTable-tableHead">
                <TableRow className="alertReports-tableRow">
                    <TableCell className="alertsTable-fleetDataTable font-wt-900">FIRST NAME</TableCell>
                    <TableCell className="alertsTable-fleetDataTable font-wt-900">LAST NAME</TableCell>
                    <TableCell className="alertsTable-fleetDataTable font-wt-900">EMAIL ADDRESS</TableCell>
                    <TableCell className="alertsTable-fleetDataTable font-wt-900">PHONE</TableCell>
                    <TableCell className="alertsTable-fleetDataTable font-wt-900">ACCESS PRIVILEGES</TableCell>
                    <TableCell className="alertsTable-fleetDataTable font-wt-900">LAST LOGIN</TableCell>
                    <TableCell className="alertsTable-fleetDataTable font-wt-900">STATUS</TableCell>
                    <TableCell className="alertsTable-fleetDataTable font-wt-900">ORGANIZATION</TableCell>
                    <TableCell className="alertsTable-fleetDataTable font-wt-900">ACTIONS</TableCell>
                </TableRow>
                </TableHead>
                {0 != userList.length ? (
                <TableBody className="tableBody alertsTable-tableBody">
                    {
                    userList.length > 0 && userList.map((row) =>
                        <TableRow className="accounts-tabelRow">
                        <TableCell className="accounts-tableCell noWrapAccounts">
                        {row.name}
                        </TableCell>
                        <TableCell className="accounts-tableCell noWrapAccounts">
                        {row.lastName}
                        </TableCell>
                        {(checkPermission === true && !row.groupedUser) || loggedInUserOuName == 'K4Mobility' ?
                        <TableCell className="accounts-tableCell noWrapAccounts onHover" onClick={() => { history.push({ pathname: "/viewUser", search:`?ouName=${selectedOu}&ouId=${selectedOuId}`, state: { demo: row.userName }, }); }}>
                        {row.userName}
                        </TableCell>
                        : (
                          <TableCell className="accounts-tableCell noWrapAccounts">{row.userName}</TableCell>
                        )}
                        <TableCell className="accounts-tableCell noWrapAccounts">{row.phoneNumber ? row.phoneNumber : "-"}</TableCell>
                        <TableCell className="accounts-tableCell noWrapAccounts" style={{ paddingTop: "3px", paddingBottom: "3px" }}>
                        <span>
                            <div className="overflow-test">
                              {row.permissions.map((policy, idx) => (
                              <Tooltip title={<span style={{ fontSize: "12px" }}>{policy.name}</span>}>
                                <Chip key={idx} label={policy.name} variant="outlined"
                                  style={{
                                    padding: "1px", display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", fontSize: "10px",
                                    background: "#fff", height: "25px", maxWidth: "110px", marginRight: "5px"
                                  }}
                                  onClick={() => {
                                      if(viewServiceFunctionPermission){
                                        handleRolePopUp(policy.name)
                                      }
                                      else{
                                        //do nothing
                                      }
                                    }
                                  }
                                />
                              </Tooltip>))}
                            </div>
                            {row.permissions.length > 2 ? (
                              <Chip key="pmore" label={"+" + (row.permissions.length - 2)} variant="outlined" title={"More Roles"}
                                style={{
                                  padding: "1px", display: "inline-block", fontSize: "10px", background: "#fff",
                                  height: "25px", maxWidth: "110px", marginLeft: "5px"
                                }}
                                onClick={() => handleListRolePopUp(row.permissions, row.name)}
                              />
                            ) : null}

                          </span>
                        </TableCell>
                        <TableCell className="accounts-tableCell">{convertDateTimeIntoTimezone(row.lastLogin, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                        <TableCell className="accounts-tableCell fleetRowDataDetails noWrapAccounts">
                        {row.status === AccountStatus.PENDING_EV ? (
                          <div>
                            <Button
                              className="ResendInviteButton"
                              title="Resend invite notification"
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: CONFIRM_DIALOG_TITLE,
                                  subTitle:
                                    MARK_AS_RESEND_INVITE_CONFIRM_DIALOG_SUBTITLE,
                                  onConfirm: () => {
                                    handleResendInvite(row);
                                  },
                                })
                              }}
                            > Resend invite
                            </Button>
                          </div>
                        ) : row.status === AccountStatus.UNLOCKED ? ("Confirmed") : row.status === AccountStatus.LOCKED ? ("Locked") : row.status === AccountStatus.DISABLED ? ("Disabled") :("-")}
                        </TableCell>
                        {
                          row.groupedUser 
                          ? 
                          <Tooltip title={<span style={{ fontSize: "12px" }}>{row.groupDp}</span>}>
                            <TableCell className="accounts-tableCell">
                              <div className="dp-textWrap">{row.groupDp}</div>
                            </TableCell>
                          </Tooltip> 
                          : 
                          <Tooltip title={<span style={{ fontSize: "12px" }}>{row.userDp}</span>}>
                            <TableCell className="accounts-tableCell">
                              <div className="dp-textWrap">{row.userDp}</div>
                            </TableCell>
                          </Tooltip>
                        }
                        <TableCell className="accounts-tableCell noWrapAccounts">
                        {checkPermission === true ? (<img className="icon-cursor" src={lock} title="Set password" onClick={() => handleCreatePassword(row)} />) : ""}
                          <img className="icon-cursor" style={{ marginRight: "10px", marginLeft: "10px" }} src={userEditImage}
                            alt="Edit access privileges" title="Edit access privileges" onClick={() => handleEditRole(row)} />
                          <img className="icon-cursor" style={{ marginRight: "10px" }} src={userDeleteImage} alt="Remove user" title="Remove user"
                            onClick={() => {
                              setConfirmDialog({
                                isOpen: true,
                                title: CONFIRM_DIALOG_TITLE,
                                subTitle: MARK_AS_DETACH_CONFIRM_DIALOG_SUBTITLE,
                                onConfirm: () => {
                                  handleDelete(row, row?.dpId);
                                },
                              })
                            }} />
                          {row.userState != "-" ? (
                            <img className="icon-cursor" src={disable} style={{ height: "20px", width: "30px", marginBottom: "3px"}} title="Enable user"
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: CONFIRM_DIALOG_TITLE,
                                  subTitle:
                                    MARK_AS_USER_ENABLED_CONFIRM_DIALOG_SUBTITLE,
                                  onConfirm: () => {
                                    handleEnableUserChange(row);
                                  },
                                });
                              }}
                            />
                          ) : (
                            <img className="icon-cursor" src={enable} style={{ height: "20px", width: "30px", marginBottom: "3px" }} title="Disable user"
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: CONFIRM_DIALOG_TITLE,
                                  subTitle:
                                    MARK_AS_USER_DISABLED_CONFIRM_DIALOG_SUBTITLE,
                                  onConfirm: () => {
                                    handleDisableUserChange(row);
                                  },
                                });
                              }}
                            />
                          )}
                        </TableCell>
                        </TableRow>
                    )
                    }
                </TableBody>) :
                
                <TableBody>
                    <TableRow>
                    <TableCell colSpan={12} className="noDataAvailableCss" align="center">No data available</TableCell>
                    </TableRow>
                </TableBody>}
                
            </Table>
          </TableContainer>
      </Grid>
      <Dialog className="menuScroll" onClose={handleClose} open={open}>
        <DialogTitle className="invite-user-padding" style={{ fontWeight: 500, fontSize: "18px", background: "#f5f5f5" }}>
          <Grid item container className="inter-font" style={{ color: "#45464E" }}>
            Invite User
            <Grid style={{ marginLeft: "auto" }}>
              <img src={close} onClick={handleClose} style={{ width: "24px", height: "24px", cursor: "pointer" }} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ width: "700px", height: "max-content", paddingBottom: "10px" }}>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography style={{ marginTop: "10px", fontWeight: 500, fontSize: "12px", color: "#676984", lineHeight: "14px" }}>
                Email / Email Address list seperated by ' ; '
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ marginTop: "10px", fontWeight: 500, fontSize: "12px", color: "#676984", lineHeight: "14px", marginBottom: "10px" }}>
                Account Expire on
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <CustomTextField
                label="" required name="email" value={email} maxLength={3000} onChange={handleChange} placeholder={"Email*"}
                isvalidorinvalid={notErrorInFields["email"] ? "" : "isInvalid"} isvalidorinvalidmessage={helperTextList["email"]}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disablePast label="Expire Date" value={value}
                  onChange={(newValue) => { setValue(newValue) }}
                  InputProps={{ style: { height: 50, paddingLeft: "10px" } }}
                  renderInput={(params) => (
                    <TextField style={{ width: "320px" }} {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid item xs={6} className="accountHead">
            <Typography className="createAccountHeaders">
            Organization Name
            </Typography>
            <div className="userDp">
              <DP_Dropdown isReports= {false} setOu={handleOu} />
            </div>
          </Grid>
          <Grid item container>
            <Typography style={{ marginTop: "10px", fontWeight: 500, fontSize: "12px", color: "#676984", lineHeight: "14px" }}>
              Access Privileges
            </Typography>
            <Grid item container className="menuScroll" xs={12} style={{marginTop:"10px"}}>
              <Grid item container className="menuScroll" xs={6} style={{padding:"2px"}}>
                  <Autocomplete className="autocompleteText"
                    fullWidth
                    multiple limitTags={1} id="multiple-limit-tags"
                    value={[...templateName]}
                    options={templateNames}
                    getOptionLabel={(option: any) => option}
                    onChange={(e, newValue: any) => { handleInputTemplateChange(e, newValue) }}
                    getOptionDisabled={(option: any) =>{
                      let fullAccess = 'Full Access';
                      let limitedAccess = 'Limited Access';
                      let readAccess = 'Read Access';
                      if(option == limitedAccess && templateName?.find((ele)=> (ele == fullAccess || ele==readAccess))){
                        return true;
                      }
                      if(option == fullAccess && templateName?.find((ele)=> (ele == limitedAccess || ele == readAccess))){
                        return true;
                      }
                      if(option == readAccess && templateName?.find((ele)=> (ele == limitedAccess || ele == fullAccess))){
                        return true;
                      }
                       return false;
                    }}
                    renderInput={(params) => (<TextField style={{backgroundColor:'white'}} {...params} placeholder="Select Access Level" />)}
                  />
              </Grid>
              <Grid item container xs={6} style={{padding:"2px"}}>
                <Autocomplete className="autocompleteText"
                  fullWidth
                  multiple limitTags={1} id="multiple-limit-tags"
                  options={resourceName}
                  getOptionLabel={(option: any) => option.name}
                  value={[...resources]}
                  getOptionDisabled={(option: any) => {
                    let vesselOption = "All Sites";
                      if (resources.findIndex(item => item?.name === vesselOption) !== -1) {
                        return true;
                      }
                      if (option.name === vesselOption && resources[0]?.name && resources[0]?.name !== vesselOption) {
                        return true;
                      }
                      if(resources.find((ele)=> ele.name==option.name)) {
                        return true
                      }
                      return false;
                    }}
                  onChange={(e, newValue: any) => { handleInputResourceChange(e, newValue) }}
                  renderInput={(params) => (<TextField {...params} required placeholder="Select Sites" />)}
                />
              </Grid>
            </Grid>
            <Grid item container className="menuScroll" xs={12} style={{marginTop:"10px"}}>
              <Grid item container className="menuScroll"  xs={6} style={{padding:"2px"}}>
                  <Autocomplete className="autocompleteText"
                    fullWidth
                    multiple limitTags={1} id="multiple-limit-tags"
                    value={[...starlinktemplateName]}
                    options={starlinktemplateNames}
                    getOptionLabel={(option: any) => option}
                    onChange={(e, newValue: any) => { handleInputStarlinkTemplateChange(e, newValue) }}
                    getOptionDisabled={(option: any) =>{
                      let starlinkAccess = 'Starlink Access';
                      let starlinkAdminAccess = 'Starlink Admin Access';
                      let starlinkFullAccess = 'Starlink Full Access';
                      if(option == starlinkAccess && starlinktemplateName?.find((ele)=> (ele == starlinkAdminAccess || ele==starlinkFullAccess))){
                        return true;
                      }
                      if(option == starlinkAdminAccess && starlinktemplateName?.find((ele)=> (ele == starlinkAccess || ele == starlinkFullAccess))){
                        return true;
                      }
                      if(option == starlinkFullAccess && starlinktemplateName?.find((ele)=> (ele == starlinkAdminAccess || ele == starlinkAccess))){
                        return true;
                      }
                       return false;
                    }}
                    renderInput={(params) => (<TextField style={{backgroundColor:'white'}} {...params} required placeholder="Select Starlink Access Level" />)}
                  />
              </Grid>
              <Grid item container xs={6} style={{padding:"2px"}}>
                <Autocomplete className="autocompleteText"
                  fullWidth
                  multiple limitTags={1} id="multiple-limit-tags"
                  options={serviceLineName}
                  getOptionLabel={(option: any) => option.name}
                  value={[...serviceLines]}
                  getOptionDisabled={(option: any) => {
                    let vesselOption = "All Service Lines";
                      if (serviceLines.findIndex(item => item?.name === vesselOption) !== -1) {
                        return true;
                      }
                      if (option.name === vesselOption && serviceLines[0]?.name && serviceLines[0]?.name !== vesselOption) {
                        return true;
                      }
                      if(serviceLines.find((ele)=> ele.id==option.id)) {
                        return true
                      }
                      return false;
                    }}
                  onChange={(e, newValue: any) => { handleInputServiceLineChange(e, newValue) }}
                  renderInput={(params) => (<TextField {...params} required placeholder="Select Service Lines" />)}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginLeft: "350px", marginRight: "16px", marginBottom: "10px" }}
        >
          <Button onClick={handleClose} className="CancelInviteButton"> Cancel </Button>
          <Button
            onClick={handleInviteClient} className="send-button"
            style={{ backgroundColor: !(allFieldsIsValidORnot.isEmailValid && ((allFieldsIsValidORnot.isResourcesValid && allFieldsIsValidORnot.isTemplateNameValid) || (allFieldsIsValidORnot.isStarlinkTemplateValid && allFieldsIsValidORnot.isServiceLinesValid))) ? "#E8F0FD" : "#264C86", 
            color: !(allFieldsIsValidORnot.isEmailValid && ((allFieldsIsValidORnot.isResourcesValid && allFieldsIsValidORnot.isTemplateNameValid) || (allFieldsIsValidORnot.isStarlinkTemplateValid && allFieldsIsValidORnot.isServiceLinesValid))) ? "#264C86" : "#fff" }}
            disabled={!(allFieldsIsValidORnot.isEmailValid && ((allFieldsIsValidORnot.isResourcesValid && allFieldsIsValidORnot.isTemplateNameValid) || (allFieldsIsValidORnot.isStarlinkTemplateValid && allFieldsIsValidORnot.isServiceLinesValid)))}
          >
            Invite
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog className="set-pwd-pad-parent menuScroll" onClose={handleEditClose} open={EditPopUp} style={{ background: "rgba(38, 76, 134, 0.75)" }}>
        <DialogTitle className="inter-font-parent set-pwd-pad" style={{ fontWeight: 500, fontSize: "18px", background: "#f5f5f5" }}>
          <Grid item container className="inter-font">
            Update Access Privileges
            <Grid style={{ marginLeft: "auto" }}>
              <img src={close} onClick={handleEditClose} style={{ width: "24px", height: "24px", cursor: "pointer" }} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ width: "700px", height: "max-content", paddingBottom: "10px" }}>
          <Grid item container xs={12}>
            <Grid item container xs={12} className="paddingTop10">
              <Grid className="inter-font-parent" item container xs={1}>
                <Typography className="inter-font">User: </Typography>
              </Grid>
              <Grid className="inter-font-parent" item container xs={11}>
                <Typography className ="inter-font">{editRoleValue}</Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} className="paddingTop10">
                  {
                    groupedUser ? <></> : <>
                      <Grid className="inter-font-parent" item container xs={3}>
                        <Typography className="inter-font">Update Organization: </Typography>
                      </Grid>
                      <Grid className="inter-font-parent" item container xs={9}>
                        <Switch className="margin-adj" checked={isUpdateOU} onChange={handleUpdateOuChange}/>
                      </Grid> 
                    </>
                  }
            </Grid>
            <Grid item container xs={12}>
              <Grid className="inter-font-parent" item container xs={3}>
                  <Typography className="inter-font">Organization Name: </Typography>
              </Grid>
              <Grid item container xs={9}>
              {
                    groupedUser ? 
                    <>
                      <Typography className="inter-font">{userGroupOuName}</Typography>
                    </> : 
                    <>
                     {
                      !isUpdateOU ? 
                        <Typography className="updatePrivilegesValue">{ouNameInUpdateAccessPrivileges}</Typography>
                        : 
                        <div className="userDp"><DP_Dropdown setOu={handleUserNewOuChange} /></div>
                      }
                    </>
                }
                {
                  
                }
              </Grid>
            </Grid>
          </Grid>
          <Typography style={{ marginTop: "10px", marginBottom: "10px", fontWeight: 500, fontSize: "12px", color: "#676984" }}>
            Existing Access Privileges
          </Typography>
          <Grid item container style={{marginTop:"10px"}} xs={12}>
            <Autocomplete
              fullWidth
              multiple limitTags={1} id="multiple-limit-tags"
              options={policyEditName}
              value={[...rolePolicy]}
              onChange={(e, newValue: any) => handleEditChange(e, newValue)}
              renderInput={(params) => (<TextField {...params} placeholder="Select Existing Access Privileges ..." InputProps={{
                ...params.InputProps
              }} />)}
            />
          </Grid>
          <Typography style={{ marginTop: "10px", marginBottom: "10px", fontWeight: 500, fontSize: "12px", color: "#676984" }}>
            Create New Access Privileges
          </Typography>
          <Grid item container xs={12} style={{marginTop:"10px"}}>
            <Grid item container xs={6} style={{padding:"2px"}}>
                <Autocomplete className="autocompleteText"
                  fullWidth
                  multiple limitTags={1} id="multiple-limit-tags"
                  options={templateNames}
                  getOptionDisabled={(option: any) =>{
                    let starlinkFullAccess = 'Starlink Full Access';
                    let starlinkAdminAccess = 'Starlink Admin Access';
                    let starlinkAccess = 'Starlink Access';
                    if(option == starlinkAccess && templateName?.find((ele)=> (ele == starlinkFullAccess || ele==starlinkAdminAccess))){
                      return true;
                    }
                    if(option == starlinkFullAccess && templateName?.find((ele)=> (ele == starlinkAdminAccess || ele == starlinkAccess))){
                      return true;
                    }
                    if(option == starlinkAdminAccess && templateName?.find((ele)=> (ele == starlinkAccess || ele == starlinkFullAccess))){
                      return true;
                    }
                    if(option == starlinkAccess &&  [...rolePolicy].filter(policy => policy.includes('Starlink')).length > 0){
                      return true
                    }
                    if(option == starlinkFullAccess &&  [...rolePolicy].filter(policy => policy.includes('Starlink')).length > 0){
                      return true
                    }
                    if(option == starlinkAdminAccess &&  [...rolePolicy].filter(policy => policy.includes('Starlink')).length > 0){
                      return true
                    }
                     return false;
                  }}
                  onChange={(e, newValue: any) => { handleInputTemplateChange(e, newValue) }}
                  renderInput={(params) => (<TextField style={{backgroundColor:'white'}} {...params} required placeholder="Select Access Level" />)}
                />
            </Grid>
            <Grid item container xs={6} style={{padding:"2px"}}>
              <Autocomplete className="autocompleteText"
                fullWidth
                multiple limitTags={1} id="multiple-limit-tags"
                options={resourceName}
                getOptionLabel={(option: any) => option.name}
                onChange={(e, newValue: any) => { handleInputResourceChange(e, newValue) }}
                renderInput={(params) => (<TextField {...params} required placeholder="Select Sites" />)}
              />
            </Grid>
          </Grid>
          <Grid item container className="menuScroll" xs={12} style={{marginTop:"10px"}}>
              <Grid item container className="menuScroll" xs={6} style={{padding:"2px"}}>
                  <Autocomplete className="autocompleteText"
                    fullWidth
                    multiple limitTags={1} id="multiple-limit-tags"
                    value={[...starlinktemplateName]}
                    options={starlinktemplateNames}
                    getOptionLabel={(option: any) => option}
                    onChange={(e, newValue: any) => { handleInputStarlinkTemplateChange(e, newValue) }}
                    getOptionDisabled={(option: any) =>{
                      let starlinkAccess = 'Starlink Access';
                      let starlinkAdminAccess = 'Starlink Admin Access';
                      let starlinkFullAccess = 'Starlink Full Access';
                      if(option == starlinkAccess && starlinktemplateName?.find((ele)=> (ele == starlinkAdminAccess || ele==starlinkFullAccess))){
                        return true;
                      }
                      if(option == starlinkAdminAccess && starlinktemplateName?.find((ele)=> (ele == starlinkAccess || ele == starlinkFullAccess))){
                        return true;
                      }
                      if(option == starlinkFullAccess && starlinktemplateName?.find((ele)=> (ele == starlinkAdminAccess || ele == starlinkAccess))){
                        return true;
                      }
                      if(option == starlinkAccess){
                          if (rolePolicy.filter(policy => policy.includes('Starlink Full Access')).length > 0 || rolePolicy.filter(policy => policy.includes('Starlink Admin Access')).length > 0){
                            return true
                          }
                      }
                      if(option == starlinkFullAccess){
                        if (rolePolicy.filter(policy => policy.includes('Starlink Admin Access')).length > 0 || rolePolicy.filter(policy => policy.includes('Starlink Access')).length > 0){
                          return true
                        }
                      }
                      if(option == starlinkAdminAccess){
                        if (rolePolicy.filter(policy => policy.includes('Starlink Full Access')).length > 0 || rolePolicy.filter(policy => policy.includes('Starlink Access')).length > 0){
                          return true
                        }
                      }
                       return false;
                    }}
                    renderInput={(params) => (<TextField style={{backgroundColor:'white'}} {...params} required placeholder="Select Starlink Access Level" />)}
                  />
              </Grid>
              <Grid item container xs={6} style={{padding:"2px"}}>
                <Autocomplete className="autocompleteText"
                  fullWidth
                  multiple limitTags={1} id="multiple-limit-tags"
                  options={serviceLineName}
                  getOptionLabel={(option: any) => option.name}
                  value={[...serviceLines]}
                  getOptionDisabled={(option: any) => {
                    let vesselOption = "All Service Lines";
                      if (serviceLines.findIndex(item => item?.name === vesselOption) !== -1) {
                        return true;
                      }
                      if (option.name === vesselOption && serviceLines[0]?.name && serviceLines[0]?.name !== vesselOption) {
                        return true;
                      }
                      if(serviceLines.find((ele)=> ele.id==option.id)) {
                        return true
                      }
                      return false;
                    }}
                  onChange={(e, newValue: any) => { handleInputServiceLineChange(e, newValue) }}
                  renderInput={(params) => (<TextField {...params} required placeholder="Select Service Lines" />)}
                />
              </Grid>
            </Grid>
         {is2FaAvailableforDp && <>
          <span>Two factor Authentication </span>
          <FormControlLabel
            className="twofaCheckBox"
            control={
              <Checkbox
                checked={isMfaEnable}
                onChange={handleTwoFAChange}
                name="primaryCheckbox"
                color="primary"
              />
            }
            label=""
      />
         </>}
        </DialogContent>
        <DialogActions style={{ marginLeft: "350px", marginRight: "16px", marginBottom: "10px" }}>
          <Button onClick={handleEditClose} className="CancelRoleEditButton"> Cancel </Button>
          <Button onClick={handleEditUser} className="send-button" style={{ backgroundColor: !(allFieldsIsValidORnot.isRolesValid || ((allFieldsIsValidORnot.isResourcesValid && allFieldsIsValidORnot.isTemplateNameValid) || (allFieldsIsValidORnot.isStarlinkTemplateValid && allFieldsIsValidORnot.isServiceLinesValid))) ? "#E8F0FD" : "#264C86", 
            color: !(allFieldsIsValidORnot.isRolesValid || ((allFieldsIsValidORnot.isResourcesValid && allFieldsIsValidORnot.isTemplateNameValid) || (allFieldsIsValidORnot.isStarlinkTemplateValid && allFieldsIsValidORnot.isServiceLinesValid))) ? "#264C86" : "#fff" }}
            disabled={!(allFieldsIsValidORnot.isRolesValid || ((allFieldsIsValidORnot.isResourcesValid && allFieldsIsValidORnot.isTemplateNameValid) || (allFieldsIsValidORnot.isStarlinkTemplateValid && allFieldsIsValidORnot.isServiceLinesValid)))}> Update </Button>
        </DialogActions>
      </Dialog>
      <Dialog disableEnforceFocus onClose={handleRoleDetailsClose} open={roleDetailPopUp} style={{ background: "rgba(38, 76, 134, 0.75)" }}>
        <DialogTitle style={{ fontWeight: 500, fontSize: "18px", background: "#f5f5f5", lineHeight: "21px" }}>
          <Grid item container style={{ color: "#8B8D97" }}>
            View Role
            <Grid style={{ marginLeft: "auto" }}>
              <img src={close} onClick={handleRoleDetailsClose} style={{ width: "16px", height: "16px", cursor: "pointer" }} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ padding: "0px", minWidth: "403px", width: "600px", maxHeight: "450px" }}>
          <Grid container>
            <Grid item style={{ marginLeft: "16px", marginTop: "10px", fontSize: "12px", lineHeight: "14px", fontWeight: 700, color: "#676984" }}>Role Name:</Grid>
            <Grid item style={{ marginLeft: "5px", marginTop: "10px", fontSize: "12px", lineHeight: "14px", fontWeight: 500, color: "#676984" }}>{roleName}</Grid>
          </Grid>
          <TableContainer className="FleetDashboard" style={{ width: "-webkit-fill-available", marginLeft: "10px", marginRight: "10px", marginBottom: "10px", overflow: "hidden" }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow className="tableHeaderFleetVesselData">
                  <TableCell className="fleetDataTable account-column">Service Name</TableCell>
                  <TableCell align="left" className="fleetDataTable account-column">Service Function Name</TableCell>
                  <TableCell align="left" className="fleetDataTable account-column">Resources</TableCell>
                </TableRow>
              </TableHead>
              {role && Array.isArray(role) ? (
                <TableBody>{getRows(role)}</TableBody>
              ) : (
                <TableBody></TableBody>
              )}
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
      
      <RolesList handleListRoleClose={handleListRoleClose} listRolePopUp={listRolePopUp} listRoleData={listRoleData} viewServiceFunctionPermission={viewServiceFunctionPermission} handleRolePopUp={handleRolePopUp}  />
      
      <Dialog className="set-pwd-pad-parent" onClose={handleCreateClose} open={createPassword} style={{ background: "rgba(38, 76, 134, 0.75)" }}>
        <DialogTitle className="inter-font-parent set-pwd-pad" style={{ fontWeight: 500, fontSize: "18px", background: "#f5f5f5" }}>
          <Grid item container className="inter-font">
            Set Password
            <Grid style={{ marginLeft: "auto" }}>
              <img src={close} onClick={handleCreateClose} style={{ width: "24px", height: "24px", cursor: "pointer" }} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ width: "300px", height: "max-content", paddingBottom: "10px" }}>
          <Typography style={{ marginTop: "10px", marginBottom: "10px", fontWeight: 500, fontSize: "12px", color: "#676984" }}>
            New Password
          </Typography>
          <Box sx={{ "& .MuiTextField-root": { m: 1, width: "100%", maxWidth: "592px" } }}>
            <TextField
              style={{ margin: "0px" }}
              id="outlined-basic"
              label="Password"
              variant="outlined"
              placeholder="Password"
              size="small"
              type={showPassword ? "text" : "password"}
              value={password}
              name="password"
              onChange={handleCreatePasswordChange}
              InputLabelProps={{ className: "UserNameInput" }}
              InputProps={{
                className: "labelTextbox",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Typography style={{ marginTop: "10px", marginBottom: "10px", fontWeight: 500, fontSize: "12px", color: "#676984" }}>
            Confirm Password
          </Typography>
          <Box sx={{ "& .MuiTextField-root": { m: 1, width: "100%", maxWidth: "592px" } }}>
            <TextField
              style={{ margin: "0px" }}
              id="outlined-basic"
              label="Confirm password"
              variant="outlined"
              placeholder="Confirm password"
              size="small"
              type={showSetPassword ? "text" : "password"}
              value={confirmPassword}
              name="confirmPassword"
              onChange={handleCreatePasswordChange}
              InputLabelProps={{ className: "UserNameInput" }}
              InputProps={{
                className: "labelTextbox",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowSetPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showSetPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions style={{ marginLeft: "16px", marginRight: "14px", marginBottom: "10px" }}>
          <Button onClick={handleCreateClose} className="CancelRoleEditButton"> Cancel </Button>
          <Button onClick={handleSetCreatePassword} className="role-button" disabled = {!(password && confirmPassword)}> Save </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      {authReducer.loading ? (
        <CustomLoader
          showLoader={showLoader}
          loadingText={
            loadingText ? loadingText : "Fetching data please wait..."
          }
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    fetchUserListAPI,
    resendInvite,
    inviteUser,
    organizationpolicy,
    userdetch,
    displayApplicableUserPolicies,
    policiesDetails,
    UpdateUser,
    updateUserOrganization,
    disableUserAccount,
    enableUserAccount,
    setPasswordUser,
    fetchResources,
    allTemplate,
    userDelete,
    enableDisable2Fa,
    updateUserOuHasSubOus,
    getDPList,
    fetchServiceLines,
  })(AccountManagmentTable)
);
