import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SearchBar from "../SearchBar/SearchBar"
import "./StateHistoryDialog.css"
import StateHistoryTableComponent from './StateHistoryTableComponent';
import { getAlertsStateHostory } from '../../components/AlertsDashboard/slice';
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

const StateHistoryDialog = (props:any) =>{
  const { authReducer, selectedIdForHistory,siteId , getAlertsStateHostory, open ,alertHistoryNew, selectedRuleIdForHostory} = props;

    //temp
    function createData(
        name: string,
        calories: number,
        fat: number,
        carbs: number,
        protein: number,
      ) {
        return { name, calories, fat, carbs, protein };
      }
    //

    // useEffect(()=>{
    //     setOpen(props.open)
    // }, [props.open])

    useEffect(() => {
      if(selectedIdForHistory && siteId && open) {
        getAlertsStateHostory(siteId,selectedIdForHistory, selectedRuleIdForHostory);
      }
    },[selectedIdForHistory,siteId,open, selectedRuleIdForHostory])

    const handleClose = ()=>{
        // setOpen(false)
        props.setOpenDialogState(false, null ,null)
    }

    return ( 
        <div className="stateHistoryDialog">
        <Dialog open={open}>
                <DialogTitle className='stateHistoryDialogTitleDiv'>
                    <span className="stateHistoryDialogTitle">State History</span>
                    <CloseIcon className='stateHistoryDialogCrossIcon' onClick={handleClose}></CloseIcon>                    
                </DialogTitle>
                <DialogContent className='stateHistoryDialogContentDiv'>
                  {/* <SearchBar></SearchBar>   */}
                    <StateHistoryTableComponent data={alertHistoryNew} userTimezone={authReducer.userTimezone} ></StateHistoryTableComponent>
                </DialogContent>
        </Dialog>
        </div>
        )
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  alertHistoryNew: state?.alertsDashboard?.alertHistoryNew
});

export default withRouter(
  connect(mapStateToProps, {
    getAlertsStateHostory
  })(StateHistoryDialog)
);