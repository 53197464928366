import React, { useState, useEffect } from "react";
import { Grid, CircularProgress, Button, Typography as MyTypography, Dialog} from "@material-ui/core";
import { Typography } from "../../components/wrappers/Wrappers";
import TextField from "@mui/material/TextField";
import useStyles from "../Login/styles";
import CustomLoader from "../../components/Loader/CustomLoaders";
import _ from "lodash";
import "./ForgotPassword.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { recoveryPasswordUser } from "../../actions/Users/authenticateLogin";
import { APP_VERSION } from "../../config";
import k4Logo from "../../asset/image/k4Logo.png";
import Box from "@mui/material/Box";
import k4LogoMobileView from "../../asset/image/k4LogoMobileView.png";
import errorMessageAlert from "../../asset/image/errorMessageAlert.svg";
import DialogContent from "@mui/material/DialogContent";
import CheckCircleRoundedIcon from "../../asset/image/CheckCircleRoundedIcon.svg";
import { popup } from "leaflet";
import favIcon from "../../asset/image/poweredBy.png"

function ForgotPassword({ history, authReducer, recoveryPasswordUser, errorReducer}) {
  const [userName, setUserName] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [fullLoader, setFullLoader] = useState(false);
  const classes = useStyles();
  const [isImageError, setImageError] = useState(false);
  //......
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const checkUserName = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    if (!_.isEmpty(authReducer.recoveryPasswordUser)) {
      setFullLoader(false);
      setShowPopUp(true);
      authReducer.recoveryPasswordUser = {};
    }
  }, [authReducer.recoveryPasswordUser]);

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      setFullLoader(false);
      setError({ message: errorReducer.description, status: true });
      setErrorMessage(errorReducer.description)
      errorReducer.message = "";
    }
  }, [errorReducer]);

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    if (name === "userName") {
     setUserName(event.target.value);
    }
  };

  const handleSend = () => {
    setError(null)
    if (_.isEmpty(userName)) {
      setError({
        message: "Please enter all the mandatory fields.",
        status: true,
      });
      setErrorMessage("Please enter the email.")
    } else {
      let payload = {userName: userName}
      recoveryPasswordUser(payload, redirectUrl);
      setFullLoader(true);
    }
  };

  const handleForgetMailSend = () => {
    setShowPopUp(false);
    history.push("/");
  }

  const handleImgError = (host) => {
    if(host) {
      setImageError(true);
    }
  }

  const [isSvgError, setIsSvgError] = useState(false);
  const [isPngError, setIsPngError] = useState(false);

  const handleSvgError = () => {
    setIsSvgError(true);
  };

  const handlePngError = () => {
    setIsPngError(true);
  };


  return (
    <Grid container component="main" className={classes.root}>
      <Grid item container style={{height:"100%"}}>
        <Box display={{ xs: "none", sm:"contents", md:"contents", lg:"contents" }}>
          <Grid xs={0} sm={6} md={6} lg={6} className={classes.logoContainer}>
          {authReducer.hostName && (
        <img
          onError={isSvgError ? handlePngError : handleSvgError}
          src={!isSvgError ? `https://k4storage.blob.core.windows.net/cdn/sportAssets/${authReducer.hostName}/k4Logo.svg` : (!isPngError ? `https://k4storage.blob.core.windows.net/cdn/sportAssets/${authReducer.hostName}/k4Logo.png` : k4Logo)}
          style={{ width: "50%" }}
          alt="K4 Logo"
        />
        )}
            <Typography className={classes.copyright}> 
            {/* <span>Powered by</span>
              <span className="poweredByCover">
                <img className ="poweredbyIcon" src={favIcon} alt={"Poweredby"} />
              </span>
              <span>{APP_VERSION}</span> */}
            </Typography>
          </Grid>
        </Box>
        <Grid xs={12} sm={6} md={6} lg={6} className={classes.textContainer}>
          <Box display={{ xs: "none", sm:"contents", md:"contents", lg:"contents" }}>
            {authReducer?.domainConfig?.name  && <Typography className={classes.logoCaption}>{authReducer?.domainConfig?.name ? `${authReducer?.domainConfig?.name} ` : `K4 Service Portal`}</Typography>}
            <Typography className={classes.loginText}>Forgot Password</Typography>
          </Box>
          <Box display={{ xs: "contents", sm:"none", md:"none", lg:"none" }}>
            <Grid>
              <img src={k4LogoMobileView} style={{width:"100%", marginTop:"-20px", height:"150px"}} />
              <Grid className="loginMobileText">Forgot Password</Grid>
            </Grid>
            <Typography className={classes.logoCaptionMobile}> K4 Service Portal </Typography>
          </Box>
          {authReducer?.domainConfig?.name && <Typography className="forgotText">
            Enter email address to reset your {authReducer?.domainConfig?.name ? `${authReducer?.domainConfig?.name} ` : `K4 Service Portal`} account password.
          </Typography>}
          <Grid className="inputBoxMobileView">
            <Box
              
              className="TextBoxMobileView"
            >
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                placeholder="Email"
                classes={{root: "input-box-fgt-pass"}}
                size="small"
                value={userName}
                name="userName"
                onChange={handleChange}
                required
                InputLabelProps={{ className: "UserNameInput" }}
                InputProps={{ className: "labelTextbox" }}
                error={ checkUserName.test(userName) || userName.length === 0 ? null : "Email is invalid" }
                helperText={ checkUserName.test(userName) || userName.length === 0 ? null : "Email is invalid" }
              />
            </Box>
          </Grid>
          {error ? (<Typography className="errorMessageAlert"><img src={errorMessageAlert} className="alertMessageImage"/> {errorMessage} </Typography>): ""}
          <div className={classes.submitBtnPasswordWrapper}>
            <Button className="ResetButtonCss" onClick={handleSend}>
              Reset password
            </Button>
            {isLoading && (<CircularProgress size={26} className={classes.loginLoader} />)}
          </div>
        </Grid>
      </Grid>
      {fullLoader && <CustomLoader showLoader={fullLoader} />}
      <Dialog onClose={() => setShowPopUp(false)} open={showPopUp} className="SuccessMessagePopUp" style={{background: "rgba(38, 76, 134, 0.75)"}}>
        <DialogContent style={{width:"300px"}}>
          <Grid container xs={12}>
            <Grid item xs={4} className="ImageCss">
              <img src={CheckCircleRoundedIcon} className="successIcon"/>
            </Grid>
            <Grid item xs={8}>
              <Typography className="dialogTitleForgetPassword">Success!</Typography>
              <Typography className="dialogTextForgetPassword">Password reset request was send successfully. please check your email to reset your password</Typography>
              <Button onClick={handleForgetMailSend} className="dialogButtonForgetPassword">ok</Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
    recoveryPasswordUser,
  })(ForgotPassword)
);
