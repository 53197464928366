import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AnyIfEmpty, connect, useDispatch } from "react-redux";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { UPDATE_USER_EMAIL, LOGGED_IN_EMAIL, USER_OU } from "../../constants/Constants";
import {
    Grid, CircularProgress, Button, Typography as MyTypography,
} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import Box from "@mui/material/Box";
import { getUserDetails, updateUserProfile } from "../../actions/Users/authenticateService";
import { useHistory } from "react-router-dom";
import DialogBox from "../../components/DialogBox";
import DialogBoxError from "../../components/DialogBoxError"
import MuiPhoneNumber from 'mui-phone-number';
import { getDataFromLocalStorage } from "../../utils/util";
import _ from "lodash";
import './updateUser.css';
import {displayToastError} from "../../server/request";
import {UPDATE_USER_PROFILE} from "./../../actions/types";
import PhoneInput from "react-phone-input-2";
import parsePhoneNumberFromString from "libphonenumber-js";


const UpdateUser = (props) => {
    const { getUserDetails, authReducer, errorReducer, updateUserProfile, location } = props;
    const [isSuccess, setSuccess] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [userDetailsList, setUserDetailsList] = useState<any>({ "USER_NAME": "", "FIRST_NAME": "", "LAST_NAME": "", "CONTACT_NUMBER": "" });
    const [errorMessage, setErrorMessage] = useState("");
    const [notErrorInFields, setNotErrorInFields] = useState({
        firstName: true,
        lastName: true,
        contactNo: true,
      });
    let [contactNoCountryCode, setContactNoCountryCode] = useState("");
    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!_.isEmpty(authReducer.updateUserProfile)) {
            if (authReducer.updateUserProfile.status === 1) {
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                    dispatch({type: UPDATE_USER_PROFILE, payload: {}});
                    history.push('/account-management/accounts');
                }, 3000);
            } else {
                setIsFailed(true);
                setTimeout(() => {
                    setIsFailed(false);
                }, 3000);
            }

        }
    }, [authReducer.updateUserProfile])

    useEffect(() => {
        const _userOu: any = getDataFromLocalStorage(USER_OU);
        const userOu: any = _userOu ? JSON.parse(_userOu) : {};
        getUserDetails(location.state.demo, userOu?.name);
    }, [])

    useEffect(() => {
        if (authReducer.userDetails !== null) {
            let defaultDetails = { ...userDetailsList };
            defaultDetails["USER_NAME"] = authReducer.userDetails?.data?.userName;
            defaultDetails["FIRST_NAME"] = authReducer.userDetails?.data?.name?.givenName;
            defaultDetails["LAST_NAME"] = authReducer.userDetails?.data?.name?.familyName;

            //checking if phoneNumbers is an Array and it has a value at 0th index
            if (authReducer.userDetails && authReducer.userDetails.data && authReducer.userDetails.data.phoneNumbers && Array.isArray(authReducer.userDetails.data.phoneNumbers) && authReducer.userDetails.data.phoneNumbers[0]) {
                defaultDetails["CONTACT_NUMBER"] = authReducer.userDetails?.data?.phoneNumbers[0]?.value;
            }

            setUserDetailsList(defaultDetails);
        }
    }, [authReducer.userDetails])

    useEffect(()=>{
        if (!_.isEmpty(errorReducer.errorUpdatingUserDetails)) {
            displayToastError(errorReducer.errorUpdatingUserDetails.description)
            errorReducer.errorUpdatingUserDetails = {};
        }
    }, [errorReducer.errorUpdatingUserDetails]);

    const [isValidDetails, setIsValidDetails] = useState(true);

    const breadcrumbInfo = UPDATE_USER_EMAIL;

    const checkValidation = (value, itemKey) => {
        const reg = /^[a-zA-Z0-9\s- ]+$/;
        const regexCheck = reg.test(value)

        if (!value) {
            if (itemKey === "FIRST_NAME") {
                notErrorInFields["firstName"] = false
                setErrorMessage("First name is mandatory")
            }
            else if (itemKey === "LAST_NAME") {
                notErrorInFields["lastName"] = false
                setErrorMessage("Last name is mandatory")
            }
        } else if (!regexCheck) {
            notErrorInFields[itemKey === "FIRST_NAME" ? "firstName": "lastName"] = false
            setErrorMessage("Special characters are not allowed")
        }

        setNotErrorInFields(notErrorInFields)
    }

    const handleChange = (event: any, itemKey: any) => {
        notErrorInFields["firstName"] = true
        notErrorInFields["lastName"] = true
        setNotErrorInFields(notErrorInFields)
        setErrorMessage("")
        const value = event.target.value
        if (["FIRST_NAME", "LAST_NAME"].includes(itemKey)) {
            checkValidation(value, itemKey)
        }
        let userDetailList = { ...userDetailsList }
        userDetailList[itemKey] = value;
        setUserDetailsList(userDetailList);
    }

    useEffect(() => {
        let isValidDetails = true;
        Object.keys(userDetailsList).map((item) => {
            if (userDetailsList[item] === null || userDetailsList[item] === "" || userDetailsList[item] === undefined) {
                isValidDetails = false;
            }
        });
        setIsValidDetails(isValidDetails);
    }, [userDetailsList])

    const handleUpdateProfile = () => {
        if (isValidDetails && notErrorInFields["firstName"] && notErrorInFields["lastName"] && notErrorInFields["contactNo"]) {
            let payload = { "userName": "", "firstName": "", "lastName": "", "phone": "", "password": "" };
            payload["userName"] = userDetailsList["USER_NAME"];
            payload["firstName"] = userDetailsList["FIRST_NAME"];
            payload["lastName"] = userDetailsList["LAST_NAME"];
            payload["phone"] = userDetailsList["CONTACT_NUMBER"];
            payload["ouName"] = authReducer.userDetails?.data?.orgName;
            updateUserProfile(payload);
        }
    }

    // const handleCancel = () => {
        // setIsValidDetails(true);
        // setUserDetailsList({ "USER_NAME": getDataFromLocalStorage(LOGGED_IN_EMAIL), "FIRST_NAME": "", "LAST_NAME": "", "CONTACT_NUMBER": "" });

    // }

    const validatePhoneNumber = (number) => {
        const phoneNumber = parsePhoneNumberFromString(number);
        return phoneNumber && phoneNumber.isValid() && phoneNumber.isPossible();
    }

    const handlPhoneChange = (value, country, e, formattedValue) => {
        setErrorMessage("")
        notErrorInFields["contactNo"] = true

        if (!validatePhoneNumber(formattedValue)) {
            notErrorInFields["contactNo"] = false;
            setErrorMessage("Enter a valid phone number");
        }

        let userDetailList = { ...userDetailsList }
        userDetailList["CONTACT_NUMBER"] = formattedValue;
        setUserDetailsList(userDetailList);
        setNotErrorInFields(notErrorInFields)
    
    }


    return (
        <div className="Form">
            {/* <BreadCrumbs breadcrumbinfo={breadcrumbInfo}></BreadCrumbs> */}
            <Grid item container style={{ backgroundColor: "#fff" }}>
                <Grid item style={{ paddingTop: "88px", width: "100%" }} className="changePasswordPage">
                    <Grid item container lg={12} className="changePasswordTitle" > Update Profile</Grid>
                    <Grid item container lg={5} className="changePasswordContainer" >
                        {Object.keys(userDetailsList).map((item: any, index: any) => {
                            if (item === "CONTACT_NUMBER") {
                                return <Box className="contactField">
                                    {/* <MuiPhoneNumber className="contactField"
                                        defaultCountry={'us'}
                                        onChange={handlPhoneChange}
                                        InputProps={{ disableUnderline: true }}
                                        value={userDetailsList["CONTACT_NUMBER"]}
                                        disableAreaCodes={true} /> */}


                                    <PhoneInput
                                        country={"us"}
                                        value={userDetailsList["CONTACT_NUMBER"]}
                                        placeholder="Phone number"
                                        enableSearch={true}
                                        enableTerritories={true}
                                        onChange={handlPhoneChange}
                                        isValid={(value, country:any) => {
                                            if (value.match(/12345/)) {
                                              return 'Invalid value: '+value+', '+country.name;
                                            } else if (value.match(/1234/)) {
                                              return false;
                                            } else {
                                              return true;
                                            }
                                          }}
                                          defaultErrorMessage="Enter valid phone number"
                                        />
                                        </Box>
                            }
                            return <Box className="passWordFiled">
                                <InputLabel className="fieldLabel">{item.replace('_', ' ')}</InputLabel>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    type={"text"}
                                    value={userDetailsList[item]}
                                    name="password"
                                    onChange={(e) => handleChange(e, item)}
                                    disabled={(item === "USER_NAME") ? true : false}
                                />
                            </Box>
                        })
                        }
                        {
                            (
                                !isValidDetails || 
                                !notErrorInFields["firstName"] || 
                                !notErrorInFields["lastName"] || 
                                !notErrorInFields["contactNo"]
                            ) ?
                            <div className="invalidDetails">{errorMessage}</div> : 
                            null
                        }
                        <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button className="onChangeCancel" onClick={() => history.goBack()}>CANCEL</Button>
                            <Button disabled={(!isValidDetails && !notErrorInFields["firstName"] && !notErrorInFields["lastName"] && !notErrorInFields["contactNo"])} className={(isValidDetails && notErrorInFields["firstName"] && notErrorInFields["lastName"] && notErrorInFields["contactNo"]) ?"changePassButtons":"disabledUpdateButton"} variant={(!isValidDetails && !notErrorInFields["firstName"] && !notErrorInFields["lastName"] && !notErrorInFields["contactNo"]) ? "outlined" : "contained"}  onClick={handleUpdateProfile}>UPDATE USER</Button>
                        </Grid>
                        {isSuccess && <DialogBox
                            Open={true}
                            // onClose={() => setShowPopUp(false)}
                            DialogTitle="Profile Updated "
                            DialogBody=""
                            ButtonLabel="Done"
                        />}
                        {isFailed && <DialogBoxError
                            Open={true}
                            // onClose={() => setShowPopUp(false)}
                            DialogTitle="Profile Updated Failed"
                            DialogBody=""
                            ButtonLabel="Done"
                        />}

                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, { getUserDetails, updateUserProfile })(UpdateUser)
);