import React, { useEffect, useState } from "react";
import {
  Grid, Dialog, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Checkbox, Button, Typography
} from "@mui/material";
import { fetchResources, detachResource, notAttachedOuResources, attachResource } from "../../actions/Users/authenticate";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import close from "../../asset/image/cross.svg";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import _ from "lodash";
import "./ResourceTable.css";
import JSONResource from "../../asset/image/JSONResource.svg";
import DetachIcon from "../../asset/image/DetachIcon.svg";
import ConfirmDialog from "../../components/ConfirmDialog";
import { CONFIRM_DIALOG_TITLE, MARK_AS_DETACH_RESOURCE_OU_CONFIRM_DIALOG_SUBTITLE } from "../../config";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@mui/material/DialogActions";
import { displayToastError } from "../../server/request";

const useStyles = makeStyles((theme) => ({
  CheckBoxroot: {
    fontSize: "13px", padding: "0px 10px 0px 0px", color: "#264C86", borderRadius: "4px",
    "&$checked": {
      color: "#264C86",
    },
  },
  checked: {},
  rootDialog: { flexGrow: 1, padding: "5px 10px", },
}));

const ResourceTable = ({ fetchResources, authReducer, updatePagination,
  detachResource, resourceManagement, notAttachedOuResources, attachResource, errorReducer,
  page, limit, selectedOu, userOuName }) => {
  function createData(name: any, type: any, resource: any) {
    return { name, type, resource };
  }
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [addOpen, setAddOpen] = React.useState(false);
  const [roleName, setRoleName] = useState("");
  const [resourceJson, setResourceJson] = useState("");
  let [resourcesCount = 0, setResourcesCount] = useState();
  let [resources, setResources] = React.useState<any[]>([]);
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "", onConfirm: {} });
  const [resourceName, setResourceName] = useState([]);
  const [selectedResources, setSelectedResources]: any = useState([]);


  useEffect(() => {
    loadResources();
  }, [selectedOu, page, limit]);

  useEffect(() => {
    if (!_.isEmpty(errorReducer.errorFetchOuResources)) {
      displayToastError(errorReducer.errorFetchOuResources.description)
      errorReducer.errorFetchOuResources = {};
    }
  }, [errorReducer.errorFetchOuResources]);

  useEffect(() => {
    if (!_.isEmpty(errorReducer.errorAttachResourceOu)) {
      displayToastError(errorReducer.errorAttachResourceOu.description)
      errorReducer.errorAttachResourceOu = {};
    }
  }, [errorReducer.errorAttachResourceOu]);

  useEffect(() => {
    if (!_.isEmpty(errorReducer.errorDetachResourceOu)) {
      displayToastError(errorReducer.errorDetachResourceOu.description)
      errorReducer.errorDetachResourceOu = {};
    }
  }, [errorReducer.errorDetachResourceOu]);

  useEffect(() => {
    if (!_.isEmpty(errorReducer.errorNotAttachResourceOu)) {
      displayToastError(errorReducer.errorNotAttachResourceOu.description)
      errorReducer.errorNotAttachResourceOu = {};
    }
  }, [errorReducer.errorNotAttachResourceOu]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.detachResource)) {
      resources.length = 0;
      loadResources();
      authReducer.detachResource = {};
    }
  }, [authReducer.detachResource]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.attachResource)) {
      resources.length = 0;
      loadResources();
      authReducer.attachResource = {};
    }
  }, [authReducer.attachResource]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.notAttachedOuResources)) {
      setResourceName(authReducer.notAttachedOuResources.data);
      authReducer.notAttachedOuResources = {};
    }
  }, [authReducer.notAttachedOuResources]);

  const loadResources = () => {
    fetchResources(selectedOu, page, limit);
  };

  const handleUserClick = (e: any) => {
    if (!selectedResources.includes(e)) {
      setSelectedResources([e, ...selectedResources]);
    } else {
      let arr = selectedResources.filter((item) => item !== e);
      setSelectedResources(arr);
    }
  };

  const handleRolePop = (e) => {
    setOpen(true);
    setRoleName(e.name);
    setResourceJson(e.resource);
  };

  const handleDetach = (e) => {
    let payload = {
      name: selectedOu,
      resources: [e.name],
    };
    detachResource(payload);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddClose = () => {
    setAddOpen(false);
  };

  const handleAttachResource = () => {
    let payload = {
      name: selectedOu,
      resources: selectedResources
    };
    attachResource(payload);
    setAddOpen(false);
  }

  const _getResources = (resourceList: any[]) => {
    let rows: any[] = [];

    if (Array.isArray(resourceList)) {
      for (let i = 0; i < resourceList.length; i++) {
        let u_name =
          resourceList[i].hasOwnProperty("name") &&
            resourceList[i].name != null &&
            resourceList[i].name !== ""
            ? resourceList[i].name
            : "-";
        let u_type =
          resourceList[i].hasOwnProperty("type") &&
            resourceList[i].type != null &&
            resourceList[i].type !== ""
            ? resourceList[i].type
            : "-";
        let u_resource =
          resourceList[i].hasOwnProperty("resource") &&
            resourceList[i].resource != null &&
            resourceList[i].resource !== ""
            ? resourceList[i].resource
            : "-";
        rows.push(createData(u_name, u_type, u_resource));
      }
    }
    return rows;
  };

  const handleClickOpen = () => {
    setAddOpen(true);
    if (selectedOu) {
      notAttachedOuResources(selectedOu);
    }
  };

  useEffect(() => {
    let firstRowIndex = page * limit + 1;
    let lastRowIndex = firstRowIndex + limit - 1;
    lastRowIndex = lastRowIndex > resourcesCount ? resourcesCount : lastRowIndex;
  }, [resourcesCount])

  useEffect(() => {
    resourceManagement.current = handleClickOpen;
  }, []);

  useEffect(() => {
    if (
      !_.isEmpty(authReducer.fetchResources) &&
      authReducer.fetchResources.data
    ) {
      setResourcesCount(authReducer.fetchResources.data.total);
      let data = authReducer.fetchResources.data;
      if (data !== undefined) {
        if (authReducer.fetchResources.data.total <= limit) {
        } else {
          let firstRowIndex = page * limit + 1;
          let lastRowIndex = firstRowIndex + limit - 1;
          lastRowIndex = lastRowIndex > resourcesCount ? resourcesCount : lastRowIndex;
        }
        setResources(_getResources(data.records));
      }
      authReducer.fetchResources = {};
      updatePagination({ total: data.total, page: data.page, pages: data.pages, limit: data.limit, size: data.records.length });
    }
  }, [authReducer.fetchResources]);

  console.log("hgfdxcvghbj..........", selectedOu);

  return (
    <div className="Form" style={{ marginTop: "10px", marginBottom: "10px" }}>
      <Grid style={{ backgroundColor: "#FAF9FC", marginLeft: "25px", marginRight: "25px" }} className="am-table">
        <TableContainer className="FleetDashboard">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className="tableHeaderFleetVesselData">
                <TableCell className="fleetDataTable account-column" align="left">Name</TableCell>
                <TableCell className="fleetDataTable account-column" align="left">Type</TableCell>
                <TableCell className="fleetDataTable account-column" align="center">Details</TableCell>
                <TableCell className="fleetDataTable account-column" align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            {0 != resources.length ? (
              <TableBody>
                {resources.map((row, i) => (
                  <TableRow className={i % 2 ? "fleetRowRank fleetRowRank-odd" : "fleetRowRank fleetRowRank-even"} key={row.name + i}>
                    <TableCell align="left" className="fleetRowDataDetails">{row.name}</TableCell>
                    <TableCell align="left" className="fleetRowDataDetails">{row.type}</TableCell>
                    <TableCell align="center" className="fleetRowDataDetails">
                      <img src={JSONResource} className="iconColor" title="Details" onClick={() => handleRolePop(row)} />
                    </TableCell>
                    <TableCell align="center" className="fleetRowDataDetails">
                      {selectedOu === userOuName ?
                        <img src={DetachIcon} className="iconColorDisable" title="Cannot Detach Resource" /> :
                        <img src={DetachIcon} className="iconColor" title="Detach Resource"
                          onClick={() => {
                            setConfirmDialog({
                              isOpen: true,
                              title: CONFIRM_DIALOG_TITLE,
                              subTitle: MARK_AS_DETACH_RESOURCE_OU_CONFIRM_DIALOG_SUBTITLE,
                              onConfirm: () => { handleDetach(row) },
                            });
                          }} />
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow className="fleetRowRank fleetRowRank-odd">
                  <TableCell colSpan={9} align="center" className="fleetRowDataDetails">No data available</TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid>
      <Dialog onClose={handleClose} open={open} style={{ background: "rgba(38, 76, 134, 0.75)" }}>
        <DialogTitle style={{ fontWeight: 500, fontSize: "18px", background: "#264C86" }}>
          <Grid item container style={{ color: "#fff" }}>
            {roleName}
            <Grid style={{ marginLeft: "auto" }}>
              <img src={close} onClick={handleClose} style={{ width: "16px", height: "16px", cursor: "pointer" }} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ width: "300px" }}>
          <pre>{JSON.stringify(resourceJson, null, 2)}</pre>
        </DialogContent>
      </Dialog>
      <Dialog onClose={handleAddClose} open={addOpen} style={{ background: "rgba(38, 76, 134, 0.75)" }}>
        <DialogTitle style={{ fontWeight: 500, fontSize: "18px", background: "#264C86" }}>
          <Grid item container style={{ color: "#fff" }}>
            Attach resources
            <Grid style={{ marginLeft: "auto" }}>
              <img src={close} onClick={handleAddClose} style={{ width: "16px", height: "16px", cursor: "pointer" }} />
            </Grid>
          </Grid>
        </DialogTitle>
        {resourceName.length === null ?
          <DialogContent style={{ width: "300px", maxHeight: "350px" }}>
            {resourceName.map((e: any) => (
              <Grid container xs={12}>
                <Grid item>{e.name}</Grid>
                <Grid item style={{ marginLeft: "auto" }}>
                  <Checkbox
                    onClick={() => { handleUserClick(e.name) }}
                    role="checkbox"
                    classes={{ root: classes.CheckBoxroot, checked: classes.checked }}
                    checked={selectedResources.includes[e.name]}
                  />
                </Grid>
              </Grid>
            ))}
          </DialogContent> :
          <DialogContent style={{ width: "300px", maxHeight: "350px" }}>
            <Typography>No resource to attach</Typography>
          </DialogContent>
        }
        <DialogActions style={{ marginLeft: "16px", marginRight: "16px", marginBottom: "10px" }}
        >
          <Button onClick={handleAddClose} className="CancelInviteButton"> Cancel </Button>
          <Button onClick={handleAttachResource} className="role-button"> Attach </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    fetchResources,
    detachResource,
    notAttachedOuResources,
    attachResource
  })(ResourceTable)
);