import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const styles:any = (theme:any) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {

  },
  tableRow: {
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "rgba(25, 118, 210, 0.08)",
      "& > .MuiTableCell-root": {
        color: "black"
      }
    }
  }
});


function SimpleTable(props:any) {
  const { classes,singleCarrierMetrics, handleRowData,selctedIndex } = props;
  const [selectedID, setSelectedID] = useState<any>(0);

  useEffect(() => {
    singleCarrierMetrics && singleCarrierMetrics.length > 0 && handleRowData(singleCarrierMetrics[0]);
    setSelectedID(0);
  },[selctedIndex,singleCarrierMetrics[0].connectedPercentage])

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="left">NAME</TableCell>
            <TableCell align="left">MCC</TableCell>
            <TableCell align="left">MNC</TableCell>
            <TableCell align="left">PROBE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {singleCarrierMetrics && singleCarrierMetrics.length>0 && singleCarrierMetrics.map((row:any,i:any) => (
            <TableRow
              hover
              key={i}
              onClick={() => {
                setSelectedID(i);
                handleRowData(row)
              }}
              selected={selectedID === i}
              className={classes.tableRow}
            >
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">{row.mcc}</TableCell>
              <TableCell align="left">{row.mnc}</TableCell>
              <TableCell align="left">{row.connectedPercentage ? parseFloat(row.connectedPercentage).toFixed() : '-'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(SimpleTable);
