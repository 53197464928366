import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, TextField } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { updateNames, getVesselInfo, updateMMSI_StreetAddress, getParentDeviceOfSite, updateParentDeviceOfSite } from "../../actions/Users/authenticateService";
import close from "../../asset/image/close.svg";
import { updateLocalSiteName } from "../../utils/util";
import disable from "../../asset/image/disable.svg";
import enable from "../../asset/image/enable.svg";
import ConfirmDialog from "../../components/ConfirmDialog";
import { CONFIRM_DIALOG_TITLE } from "../../config";
import './VesselSummary.css';
import CustomLoader from "../Loader/CustomLoader";
import { UPDATE_NAME } from "../../actions/types";
import { getHighAvailabilityDevice } from "../../actions/Users/authenticateHighAvailability";

const helperTextStyles = makeStyles(theme => ({
    root: {
        margin: 4,
        color: "red"
    },
}));

type NameUpdatePayload = {
    newLocationName?: string;
    deviceAliasName?: string;
};

interface Props {
    updateNames: (name: string, type: string, payload: NameUpdatePayload) => any;
    onNameUpdate: (newSiteName: string) => void;
    closeUpdatePopup: () => void;
    open: boolean;
    type: string;
    siteId: string;
    nameId: string;
    devices : any[]
    name: string;
    authReducer?: any;
    errorReducer?: any;
    value: string;
    updateMMSI_StreetAddress : (payload: any) => any;
    getVesselInfo: (k4Id: any) => void;
    getParentDeviceOfSite : (siteId: string) => any
    updateParentDeviceOfSite: (siteId: string, deviceId: string) => any
    getHighAvailabilityDevice: (dpId: string, siteId: string) => any
    k4Id: string;
    isReadOnlyUser: boolean
}

const NameUpdate = (props: Props) => {
    const { closeUpdatePopup, open, siteId, nameId, name, devices, authReducer, type, getHighAvailabilityDevice, updateNames, onNameUpdate, value, updateMMSI_StreetAddress, getVesselInfo, getParentDeviceOfSite, updateParentDeviceOfSite, k4Id, isReadOnlyUser } = props;
    const [updatedName, setUpdatedName] = useState("");
    const [helperText, setHelperText] = useState("")
    const [isUpdated, setIsUpdated]: any = useState(false)
    const [openConfirmDialog, setOpenConfirmDialog]: any = useState(false)
    const helperTestClasses = helperTextStyles();
    const [updatedIMO, setUpdatedIMO] = useState("");
    const [updatedMMSIValue, setMMSIValue] = useState("")
    const [updatedAddLine1, setAddLine1] = useState("")
    const [updatedAddLine2, setAddLine2] = useState("")
    const [updatedCity, setCity] = useState("")
    const [updatedState, setState] = useState("")
    const [updatedZipCode, setZipCode] = useState("")
    const [updatedCountry, setCountry] = useState("")
    const [isParentDevice, setIsParentDevice] = useState(false)
    const [parentDeviceId, setParentDeviceId] = useState("");
    const [isUpdateButtonEnabled, setIsUpdateButtonEnabled] = useState(false);
    const [latitude, setLatitude] = useState(0)
    const [longitude, setLongitude] = useState(0)
    const loadingTextOnUI = "Fetching data please wait...";
    const [loaders, setLoaders] = useState({ showLoader: false, loadingText: loadingTextOnUI });
    const dispatch = useDispatch();

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: {},
      });

    useEffect(() => {
        if (!_.isEmpty(authReducer.getVesselInfo)) {
            let data = authReducer.getVesselInfo.hasOwnProperty('data') && authReducer.getVesselInfo.data ;
            setUpdatedIMO(data.imo)
            setMMSIValue(data.mmsi)
            setAddLine1(data.addLine1)
            setAddLine2(data.addLine2)
            setCity(data.city)
            setState(data.state)
            setZipCode(data.zipcode)
            setCountry(data.country)
            if(data?.geocoordinates){
                const [lat, long] = data?.geocoordinates.substring(1, data?.geocoordinates.length - 1).split(',');

                // Convert the latitude and longitude strings to numbers
                const latitude = parseFloat(lat);
                const longitude = parseFloat(long);

                setLatitude(latitude)
                setLongitude(longitude)
            }
            authReducer.getVesselInfo={}
        }
    }, [authReducer.getVesselInfo]);

    useEffect(()=>{
        getVesselInfo(nameId);
        if(type === "Device"){
            getParentDeviceOfSite(siteId);
        }
    },[])

    useEffect(() => {
        if (!_.isEmpty(authReducer.parentDeviceOfSite)) {
            setIsParentDevice(false);
            let pDeviceInfo = authReducer.parentDeviceOfSite
            if (pDeviceInfo?.parent_device) {
                if (devices && Array.isArray(devices) && devices.length > 1){
                    devices?.forEach(function (device) {
                        if(device && device?.id && pDeviceInfo?.parent_device === device.id){
                            setParentDeviceId(device?.id);
                            setIsParentDevice(true);
                        }
                    });
                } else {
                    if(pDeviceInfo?.parent_device == nameId){
                        setParentDeviceId(pDeviceInfo.parent_device);
                        setIsParentDevice(true);
                    }
                }
            }else {
                if(devices && Array.isArray(devices)){
                    devices.forEach(function (device) {
                        if(device && device?.parent && device?.parent?.toLowerCase() === "yes"){
                            setParentDeviceId(device?.id);
                            setIsParentDevice(true);
                        }
                    });
                }
            }
            authReducer.getParentDeviceOfSite = {};
        }
    }, [authReducer.parentDeviceOfSite])


    useEffect(() => {
        if (!_.isEmpty(authReducer.updateParentDeviceOfSite)) {
            authReducer.updateParentDeviceOfSite = {}
            getParentDeviceOfSite(siteId);
            let selectedOuId = authReducer?.selectedOu?.id;
            getHighAvailabilityDevice(selectedOuId, siteId);
        }
    }, [authReducer.updateParentDeviceOfSite])

    const closeUpdateDialog = () => {
        if (isUpdated) {
            setOpenConfirmDialog(true)
        } else {
            closeUpdatePopup()
        }
    }

    const handleChangeSubject = (event) => {
        // here 
        let value = event.currentTarget.value;
        if(value) {
            setHelperText("");
          } else {
            setIsUpdateButtonEnabled(true);
            setHelperText("Site name cannot be empty");
            setUpdatedName(value);
            return
          }
        if (!value.match(/['\[\]]/)) {
            setHelperText("");
            setIsUpdateButtonEnabled(false);
          } else {
            setHelperText("Character \" ' \" is Not allowed");
            setIsUpdateButtonEnabled(true);
          }   
          setUpdatedName(value);
    }

    useEffect(() => {
        setIsUpdated(name !== updatedName);
    }, [updatedName]);

    useEffect(() => {
        setUpdatedName(name);
    }, [name])

    useEffect(() => {
        if (!_.isEmpty(authReducer.updateNames)) {
            onNameUpdate(updatedName);
            updateLocalSiteName(nameId, updatedName);
            setTimeout(() => closeUpdatePopup(), 100);
            // authReducer.updateNames = {};
            dispatch({ type: UPDATE_NAME, payload: {}})  
        }

    }, [authReducer.updateNames])

    const handleUpdateClick = () => {
        // If field is empty then return 
        if (updatedName.trim() === "") {
            setHelperText(`${type} name cannot be empty`)
            return
        }
        if (nameId) {
            const payload = {};
            payload[value] = updatedName;
            updateNames(nameId, type, payload);
        }
    }

    const handleChangeImo = (event) => {
        setUpdatedIMO(event.currentTarget.value)
    }

    const handleChangeMMSI = (event) => {
       setMMSIValue(event.currentTarget.value)
    }

    const handleChangeAddLine1 = (event) => {
        setAddLine1(event.currentTarget.value)
    }

    const handleChangeAddLine2 = (event) => {
        setAddLine2(event.currentTarget.value)
    }

    const handleChangeCity = (event) => {
     setCity(event.currentTarget.value)
    }

    const handleChangeState = (event) => {
        setState(event.currentTarget.value)
    }

    const handleChangeZipCode = (event) => {
        setZipCode(event.currentTarget.value)
    }

    const handleChangeCountry = (event) => {
        setCountry(event.currentTarget.value)
    }

    const handleUpdateMMSIClick = () => {
        let payload = {
            k4Id: nameId,
            imo: updatedIMO,
            mmsi: updatedMMSIValue,
            addLine1: updatedAddLine1,
            addLine2: updatedAddLine2,
            city: updatedCity,
            state: updatedState,
            zipCode: updatedZipCode,
            country: updatedCountry,
            lat: latitude,
            long: longitude
           }
        updateMMSI_StreetAddress(payload)
    }

    const updateParent = () => {
        updateParentDeviceOfSite(siteId,nameId);
    }

    const handleLatitudeValidation = (e) => {
        let value = e.currentTarget.value;
        if(!isNaN(value)){
            setLatitude(value);
        }
    }

    const handleLongitudeValidation = (e) => {
        let value = e.currentTarget.value;
        if(!isNaN(value)){
            setLongitude(value);
        }
    }

    return (
        <div className="Form">
            {
                authReducer.loading ? (
                    <CustomLoader
                        showLoader={true}
                        loadingText={
                            loaders["loadingText"]
                                ? loaders["loadingText"]
                                : loadingTextOnUI
                        }
                    />
                ) : null
            }
            <Dialog disableEnforceFocus
                open={open}
                onClose={() => {
                    closeUpdatePopup();
                }}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth={'md'}
                className="noteDialog menuScroll new-theme-dialogue"
            >
                <DialogTitle className="dialog_title new-theme-dialogue-title">
                    <Grid>
                        <span className="new-theme-dialogue-title-header">{`Update ${type} ${name && k4Id ? " : " + name + "("+k4Id+")" : name}`}</span>
                        <Grid className="margAuto">
                            <img src={close} title="Close" onClick={closeUpdateDialog} className="dialog_closeIcon" />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className="topolgoy_update_device_info dialog_content">
                    <div className="updateSiteNameBox">
                        <Grid container className="siteNameEditContainer">
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                <div>
                                    <span className="dialog-field-name-font-parent">
                                        <InputLabel className="inputStyles dialog-field-name-font" >{`Enter new ${type} name`}</InputLabel>
                                        <TextField style={{marginBottom:'10px'}} 
                                         error={!!helperText} 
                                         helperText={helperText}
                                         id="standard-basic" 
                                         FormHelperTextProps={{ classes: helperTestClasses }} 
                                         label={`${type} Name`} 
                                         variant="standard" 
                                         value={updatedName} 
                                         title={updatedName}
                                         onChange={handleChangeSubject} 
                                         className="tkt_new_ticket_dialog_subject_textfield editName" />
                                    </span>
                                </div>
                            </Grid>
                            {!isReadOnlyUser && <Grid xs={6} sm={6} md={6} lg={6} className="updateSiteNameBtn">
                               <Button disabled={isUpdateButtonEnabled} className="dialog_okButton" onClick={() => handleUpdateClick()}>Update Name</Button>
                            </Grid>}
                        </Grid>
                        { type === "Device"  ?
                            <Grid container className="parentSetting">
                               {!isReadOnlyUser &&  <Grid item xs={6} sm={6} md={6} lg={6}>
                                    <div className="label">
                                        <span>Parent </span>
                                        <br/><span>
                                            {
                                                devices && Array.isArray(devices) && devices.length > 1 ? devices.map(device => {
                                                    if(device?.id === parentDeviceId){return " ("+device.name +")"}
                                                })
                                                :""
                                            }
                                        </span>
                                    </div>
                                </Grid>}
                                {!isReadOnlyUser &&  <Grid xs={5} sm={5} md={5} lg={5} className="updateSiteNameBtn">
                                {   isParentDevice ?
                                        <img className="icon-cursor parent-enable-disable-icon enable" title="parent device" src={enable}/>
                                        :   devices && Array.isArray(devices) && devices.length > 1 ?
                                            <img className="icon-cursor parent-enable-disable-icon disable" title="parent device setting is not available" src={disable}  />
                                            : <img className="icon-cursor parent-enable-disable-icon" title="set as parent device" src={disable}
                                                onClick={() => {
                                                    setConfirmDialog({
                                                        isOpen: true,
                                                        title: CONFIRM_DIALOG_TITLE,
                                                        subTitle: "Are you sure to set device "+updatedName+" as parent ?",
                                                        onConfirm: () => {
                                                            updateParent();
                                                        },
                                                    });
                                                }}/>
                                }
                                </Grid>}
                            </Grid>
                            : <></>
                        }

                    </div>
               
                    {type == 'Site' && <>
                        <hr />
                        <div className="dialog-field-name-font-parent">
                            <InputLabel className="inputStyles dialog-field-name-font" >Enter new MMSI and StreetAddress</InputLabel>
                            <Grid>
                                <Grid className="MMSIDiv">
                                    <div>
                                        <span>
                                            <TextField style={{ marginBottom: '10px' }} error={false} id="standard-basic" label={`IMO`} variant="standard" value={updatedIMO} onChange={handleChangeImo} className="topologySitePopUp" />
                                            <TextField style={{ marginBottom: '10px' }} error={false} id="standard-basic" label={`MMSI`} variant="standard" value={updatedMMSIValue} onChange={handleChangeMMSI} className="topologySitePopUp" />
                                        </span>
                                    </div>
                                </Grid>
                                {/* <InputLabel className="inputStyles" >Coordinates</InputLabel>
                                <Grid className="MMSIDiv">
                                    <div>
                                        <span>
                                            <TextField style={{ marginBottom: '10px', height: "20px" }} error={false} id="standard-basic" label={`LAT`} variant="standard" value={updatedIMO} onChange={handleChangeImo} className="topologySitePopUp"  size="small"  />
                                            <TextField style={{ marginBottom: '10px', height: "20px" }} error={false} id="standard-basic" label={`LONG`} variant="standard" value={updatedMMSIValue} onChange={handleChangeMMSI} className="topologySitePopUp"  size="small"  />
                                        </span>
                                    </div>
                                </Grid> */}
                                <Grid className="addressDiv">
                                    <Grid >
                                        <span>
                                            <TextField style={{ marginBottom: '10px' }} id="standard-basic" label={`Address1`} variant="standard" value={updatedAddLine1} onChange={handleChangeAddLine1} className="topologySitePopUp" />
                                            <TextField style={{ marginBottom: '10px' }} id="standard-basic" label={`Address2`} variant="standard" value={updatedAddLine2} onChange={handleChangeAddLine2} className="topologySitePopUp" />
                                        </span>
                                    </Grid>
                                    <div>
                                        <Grid className="addressLineDiv">
                                            <TextField style={{ marginBottom: '10px' }} error={false} id="standard-basic" label={`City`} variant="standard" value={updatedCity} onChange={handleChangeCity} className="topologySitePopUp" />
                                            <TextField style={{ marginBottom: '10px' }} error={false} id="standard-basic" label={`State`} variant="standard" value={updatedState} onChange={handleChangeState} className="topologySitePopUp" />
                                        </Grid>
                                        <Grid className="addressLineDiv">
                                            <TextField style={{ marginBottom: '10px' }} id="standard-basic" label={`zipCode`} variant="standard" value={updatedZipCode} onChange={handleChangeZipCode} className="topologySitePopUp" />
                                            <TextField style={{ marginBottom: '10px' }} id="standard-basic" label={`Country`} variant="standard" value={updatedCountry} onChange={handleChangeCountry} className="topologySitePopUp" />
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div> 
                            <Grid className="addressDiv addressLineDiv">
                                <TextField style={{ marginBottom: '10px' }} error={false} label={`Latitude `} value={latitude} onChange={(e)=>{handleLatitudeValidation(e)}} variant="standard" className="tkt_new_ticket_dialog_subject_textfield" />
                                <TextField style={{ marginBottom: '10px' }} error={false} label={`Longitude`} value={longitude} onChange={(e)=>{handleLongitudeValidation(e)}} variant="standard" className="tkt_new_ticket_dialog_subject_textfield" />
                            </Grid>
                        </div>
                        {!isReadOnlyUser && <DialogActions className="updateSiteAdressbtn">
                            <Button className="dialog_okButton" onClick={() => handleUpdateMMSIClick()}>Update Address</Button>
                        </DialogActions>}
                    </>}
                </ DialogContent>

            </Dialog>

            {openConfirmDialog ? (
                <Dialog
                    open={openConfirmDialog}
                    aria-labelledby="form-dialog-title"
                    maxWidth={'md'}
                    className="noteDialog"
                >
                    <DialogContent className="closeConfirmBorder">
                        <div >
                            <h4 className='fontWt'>{`${type} name not updated. Do you want to close?`}</h4>
                            <div className="closeConfirm">
                                <Button className="dialog_okButton"
                                    onClick={() => {
                                        setOpenConfirmDialog(false)
                                    }}
                                >
                                    No
                                </Button>
                                <Button className="dialog_okButton"
                                    onClick={() => {
                                        setOpenConfirmDialog(false)
                                        closeUpdatePopup()
                                    }}
                                >
                                    Yes
                                </Button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            ) : null}

            <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </div>
    );

}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    isReadOnlyUser: state?.authReducer?.isReadOnlyUser
});

export default withRouter(
    connect(mapStateToProps, {
        updateNames,
        getVesselInfo,
        updateMMSI_StreetAddress,
        getParentDeviceOfSite,
        updateParentDeviceOfSite,
        getHighAvailabilityDevice
    })(NameUpdate)
);