import React, { Fragment } from 'react';
import { Box, Chip, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { convertDateTimeIntoTimezone, readableBytes, readableBytesAsGB, readablePeriod } from '../../../utils/util';
import './index.css';
import { Quota } from '../types';
import moment from 'moment';
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from '../../../utils/constants';
import { nanoid } from 'nanoid';


interface UsageLineRepProps {
    quota: Quota;
    userTimezone: string;
}

// component draws usage line representation using divs and colors
// with total, used and limit
export function UsageLineRep(props: UsageLineRepProps) {
    const { quota, userTimezone } = props;

    const used = quota?.current_usage_updated ? Number(quota?.current_usage_updated) : quota?.current_usage ? Number(quota?.current_usage) : 0
    const total = Number(quota.limit)

    let thresholds = quota.actions?.filter(action => !action.revert)?.map(action => action.usage_percentage)
    thresholds = thresholds ? thresholds : [100]
    const thresholdPercent = Math.min(...thresholds)
    const usedPercent = Math.round(used / total * 100)

    const status = used > total ? "exceeded" : usedPercent > thresholdPercent ? "alerting" : "normal"

    return (
        <Fragment>
            <Tooltip title={
                <Paper elevation={3}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    WAN LINK
                                </TableCell>
                                <TableCell>
                                    USAGE
                                </TableCell>
                                {
                                    quota.actions?.sort((a, b) => a.usage_percentage - b.usage_percentage)?.filter(action => !action.revert)?.map((action, index) => {
                                        return (
                                            <TableCell key={index}>
                                                QUOTA ({action.usage_percentage}%)
                                            </TableCell>
                                        )
                                    })
                                }
                                <TableCell>
                                    MAX QUOTA
                                </TableCell>
                                <TableCell>
                                    PERIOD
                                </TableCell>
                                <TableCell>
                                    EST. MAX OVER DATE
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    {quota.starlink_quota?.enabled ? quota.wan_links?.concat([`Starlink:(${quota.starlink_quota.service_line_numbers?.join(',')})`])?.join(', ') : quota.wan_links?.join(', ')}
                                </TableCell>
                                <TableCell>
                                    {readableBytesAsGB(used)}
                                </TableCell>
                                {
                                    quota.actions?.filter(action => !action.revert)?.map((action, index) => {
                                        return (
                                            <TableCell key={nanoid()} sx={{ color: action.usage_percentage < usedPercent ? status === 'exceeded' ? '#cc3300' : status === 'alerting' ? '#ffcc00' : 'inherit' : 'inherit' }}>
                                                {readableBytesAsGB(action.usage_percentage * total / 100)}
                                            </TableCell>
                                        )
                                    })
                                }
                                {/* <TableCell classes={{
                                    root: used > total ? "quota_management--usage_tooltip--cell_alerting" : "quota_management--usage_tooltip--cell_normal"
                                }} >
                                    {readableBytes(used)}
                                </TableCell> */}
                                <TableCell>
                                    {readableBytesAsGB(total)}
                                </TableCell>
                                <TableCell>
                                    {quota?.span !== "0" ? `${quota.span}/${quota.period}${quota.period_unit}` : ""}
                                </TableCell>
                                <TableCell>
                                    {moment(quota.estimated_max_over_date).isValid() ? convertDateTimeIntoTimezone(quota.estimated_max_over_date, userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS) : "None"}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
            } classes={{ tooltip: "quota_management--usage_tooltip" }} placement="top">
                <Box sx={{ width: '100%', height: 10, bgcolor: status === "exceeded" ? '#cc3300' : '#c2c7e3', borderRadius: 5, position: 'relative' }} >
                    <Box sx={{ width: `${status === "exceeded" ? total / used * 100 : 100}%`, position: 'relative' }}>
                        <Box sx={{ width: `${status === "exceeded" ? 100 : usedPercent > thresholdPercent ? usedPercent : thresholdPercent}%`, height: 10, bgcolor: status === "alerting" || status === "exceeded" ? '#ffcc00' : '#c2c7e3', borderRadius: 5, position: 'absolute' }} />
                        <Box sx={{ width: `${status === "exceeded" ? thresholdPercent : usedPercent < thresholdPercent ? usedPercent : thresholdPercent}%`, height: 10, bgcolor: '#1B51A7', borderRadius: 5, position: 'absolute' }} />
                    </Box>
                </Box>
            </Tooltip>
            <Box>
                {readableBytesAsGB(used)} / {readableBytesAsGB(total)} ({usedPercent}%)
            </Box>
        </Fragment>
    )
}
