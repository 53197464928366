// Dialog Box for creating / updating Note -  Pop-up
import { useEffect, useState } from "react";
import { Button, DialogActions, FormControl, Grid, InputLabel, MenuItem, TextField } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Dialog, Select } from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from '@mui/material/DialogContent';
import close from "../../asset/image/close.svg";
import { updateTicket, createNewTicket } from "../../actions/Users/authenticateTicket";
import { makeStyles } from "@material-ui/core/styles";
import "./viewVesselNotes.css"
import moment from "moment-timezone";
import _ from "lodash";
import { event } from "react-ga";


const helperTextStyles = makeStyles(theme => ({
    root: {
        margin: 4,
        color: "red"
    },
}));

const CreateNewNote = (props) => {
    const { closeCreateTicketDialog, open, selectedVessel, ticketDetails, authReducer, updateTicket, createNewTicket } = props;
    const prioritySet = ["High", "Low"]
    const [subject, setSubject] = useState(ticketDetails && ticketDetails.hasOwnProperty("ticketStatus") && ticketDetails.ticketStatus === "Active" && ticketDetails.hasOwnProperty("ticketSubject") ? ticketDetails.ticketSubject : "")
    const [helperText, setHelperText] = useState("")
    const [selectedPriority, setSelectedPriority]: any = useState(ticketDetails && ticketDetails.hasOwnProperty("priority") ? ticketDetails.priority : "Low");
    const [ticketId, setTicketId]: any = useState(null);
    const [isUpdated, setIsUpdated]: any = useState(false)
    const [openConfirmDialog, setOpenConfirmDialog]: any = useState(false)
    const K4ID_KEY = "k4Id";
    const TICKET_ID_KEY = "ticketId";
    const helperTestClasses = helperTextStyles();


    const handleSendTicketClick = () => {

        // If subject is empty then return 
        if (subject.trim() === "") {
            setHelperText("Note Subject cannot be empty")
            return
        }


        if (selectedVessel) {
            let payload = {
                "k4Id": selectedVessel ? selectedVessel : null,
                "ticketStatus": "Active",
                "ticketSubject": subject.trim(),
                "eventDate": moment(),
                "priority": selectedPriority,
                "channel": "Web",
                "ticketType": "Notes"
            }
            createNewTicket(payload);
        }
    }


    useEffect(() => {
        if (!_.isEmpty(authReducer.createNewTicket)) {
            if (authReducer.createNewTicket.data && authReducer.createNewTicket["status"] == 1 && authReducer.createNewTicket.data["ticketId"]) {
                setTicketId(authReducer.createNewTicket.data["ticketId"])
                authReducer.createNewTicket = {};
                closeCreateTicketDialog()
            }
        }

        if (!_.isEmpty(authReducer.updateTicket)) {
            authReducer.updateTicket = {};
            closeCreateTicketDialog()

        }


    }, [authReducer.createNewTicket, authReducer.updateTicket])


    const updateDetails = (key) => {
        if (subject.trim() === "") {
            setHelperText("Note Subject cannot be empty")
            return
        }

        if (ticketDetails.ticketId) {
            let payload = {}

            payload[K4ID_KEY] = selectedVessel;
            payload[TICKET_ID_KEY] = ticketDetails.ticketId;
            payload["ticketType"] = "Notes"

            if (key === "saveNote") {
                payload["ticketSubject"] = subject;
                payload["priority"] = selectedPriority;
            }

            if (key === "closeNote") {
                payload["ticketStatus"] = "Closed";
            }

            if (key === "openNote") {
                payload["ticketStatus"] = "Active";
                payload["ticketSubject"] = subject;
                payload["priority"] = selectedPriority;
            }

            updateTicket(payload);

        }
    }

    const handleChangeSubject = (event) => {

        setSubject(event.currentTarget.value)
        if (event.currentTarget.value.length > 0) {
            setHelperText("")
        }
        else {
            setHelperText("Note Subject cannot be empty")
        }
    }

    useEffect(() => {
        setIsUpdated(false)
        if (ticketDetails && ticketDetails.hasOwnProperty("priority") && ticketDetails.priority !== selectedPriority) {
            setIsUpdated(true)
        }

        if (ticketDetails && ticketDetails.hasOwnProperty("ticketSubject") && ticketDetails.ticketSubject !== subject) {
            setIsUpdated(true)
        }

    }, [selectedPriority, subject])


    const closeNoteDialog = () => {
        if (isUpdated) {
            setOpenConfirmDialog(true)
        } else {

            closeCreateTicketDialog()
        }

    }

    return (
        <div className="Form">
            <Dialog style={{width:'565px', marginLeft:'450px'}}
                open={open}
                onClose={() => {
                    if (closeCreateTicketDialog) closeCreateTicketDialog()
                }}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth={'md'}
                className="noteDialog"
            >
                <div style={{width:'500px'}} className="title-head-parent">
                <DialogTitle className="dialog_title title-padding">
                    <Grid>
                        {ticketDetails && !_.isEmpty(ticketDetails) ? "Update Note" : "Create New Note"}
                        <Grid style={{ marginLeft: "auto" }}>
                            <img src={close} title="Close" onClick={closeNoteDialog} className="dialog_closeIcon" />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent >
                    <div className="tkt_new_ticket_dialogContent">
                        <Grid container spacing={2} style={{ alignItems: "center" }}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <div className="tkt_new_ticket_dialog_subject_title font14" style={{ marginLeft: "5px" }}>Note</div>
                                <TextField error={false} helperText={helperText} FormHelperTextProps={{ classes: helperTestClasses }} id="outlined-error-helper-text" multiline value={subject} onChange={handleChangeSubject} className="tkt_new_ticket_dialog_subject_textfield"></TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <InputLabel className="tkt_new_ticket_dialog_subject_title font14" >Priority</InputLabel>
                                <FormControl size="small">
                                    <Select
                                        id="demo-simple-select"
                                        style={{ width: "210px" }}
                                        value={selectedPriority}
                                        onChange={(e) => {
                                            setSelectedPriority(e.target.value)
                                        }}
                                    >
                                        {prioritySet && prioritySet.length > 0 ?
                                            prioritySet.map((priority, idx) => {
                                                return (
                                                    <MenuItem className="font14" key={idx} value={priority} >{priority}</MenuItem>
                                                )
                                            }) :
                                            null
                                        }
                                    </Select>
                                </FormControl>

                            </Grid>
                        </Grid>
                    </div>
                    <DialogActions>
                        {ticketDetails && !_.isEmpty(ticketDetails) ? ticketDetails && ticketDetails.ticketStatus === "Active" ?
                            <>
                                <Button className="dialog_okButton" onClick={() => updateDetails("closeNote")}>Close Note</Button>
                                <Button className="dialog_okButton" onClick={() => updateDetails("saveNote")}>Save Note</Button>
                            </>
                            :
                            <Button className="dialog_okButton" onClick={() => updateDetails("openNote")}>Open Note</Button>
                            :
                            <Button className="dialog_okButton" onClick={() => handleSendTicketClick()}>Create Note</Button>}

                    </DialogActions>
                </ DialogContent>
                </div>

            </Dialog>

            {openConfirmDialog ? (
                <Dialog
                    open={openConfirmDialog}
                    aria-labelledby="form-dialog-title"
                    maxWidth={'md'}
                    className="noteDialog"
                >
                    <DialogContent style={{ border: "8px solid #264C86" }}>
                        <div >
                            <h4 className='mb-4'>You have unsaved changes. Do you want to close?</h4>
                            <div style={{ display: "flex", justifyContent:"space-around" }}>
                                <Button className="dialog_okButton"
                                    onClick={() => {
                                        setOpenConfirmDialog(false)
                                    }}
                                >
                                    No
                                </Button>
                                <Button className="dialog_okButton"
                                    onClick={() => {
                                        setOpenConfirmDialog(false)
                                        closeCreateTicketDialog()

                                    }}
                                >
                                    Yes
                                </Button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            ) : null}
        </div>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});
export default withRouter(
    connect(mapStateToProps, {
        updateTicket,
        createNewTicket,
    })(CreateNewNote)
);