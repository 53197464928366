import { api } from "../../server/request";
import { handleResponse, handleError } from "../responseUtil";
import { KAPTUREV2_URL, KAPTUREV2_URL_INV } from "../../config"
import { 
  SET_INVENTORY_LISTING_LOADING,
  GET_TRAFFIC_POLICY_LISTING_LOADING,
  APPLY_TRAFFIC_POLICY_LOADING,
  GET_TRAFFIC_POLICY_LOG_LIST_LOADING,
  GET_WAN_PROFILE_LISTING_LOADING,
  GET_POP_IPS_LISTING_LOADING,
  APPLY_FIREWALL_RULE_LOADING,
  APPLY_WAN_PROFILE_LOADING,
  APPLY_HUB_IP_CONFIG_LOADING,
  COPY_QUOTA_CONFIG_LOADING,
  GET_INVENTORY_WAREHOUSE_TABLE_DATA, 
  GET_INVENTORY_WAREHOUSE_VESSEL_LIST , 
  ASSIGN_INVENTORY_TO_VESSEL , 
  CREATE_AND_ASSIGN_INVENTORY,
  GET_DP_LIST,
  GET_INVENTORY_DEPLOYED_DATA_COUNT,
  INVENTORY_ASSIGN_DEVICE_TO_WAREHOUSE,
  INVENTORY_DELETE_SITE,
  INVENTORY_TOP_WIDGETS_REGISTERED_COUNT,
  SET_LOADING,
  INVENTORY_TOP_WIDGETS_WAREHOUSE_COUNT,
  INVENTORY_DOWNLOAD_CONFIG,
  INVENTORY_CHANGE_GROUP,
  GET_CONFIG_PRODUCTS,
  GET_WAN_PROFILE_LIST,
  GET_POP_IPS_LIST,
  APPLY_GOLDEN_CONFIG,
  APPLY_TRAFFIC_POLICY,
  APPLY_FIREWALL_RULE,
  APPLY_WAN_PROFILE,
  APPLY_HUB_IP_CONFIG,
  GET_TRAFFIC_POLICY_LOG_LIST,
  GET_BACKUP_CONFIG_DATA,
  GET_DEFAULT_CONFIG_LISTING,
  EDIT_DEFAULT_CONFIG,
  CREATE_DEFAULT_CONFIG,
  DELETE_DEFAULT_CONFIG,
  GET_DEFAULT_PRODUCTS,
  GET_CONFIG_SOFTWARE_VERSIONS,
  GET_CONFIG_STATUS,
  BACKUP_CONFIG_NOW,
  COPY_CONFIG_TO_MANUAL_BACKUP,
  DELETE_MANUAL_BACKUP_CONFIG,
  DEVICEWISE_LATEST_EOS_VERSION,
  UPGRADE_DEVICE_TO_LATEST_EOS_VERSION,
  INVENTORY_WAREHOUSE_CHANGE_GROUP,
  REBOOT_DEVICE,
  GET_DEVICE_LIST,
  CREATE_INVENTORY_GROUP,
  UPDATE_INVENTORY_GROUP,
  DELETE_INVENTORY_GROUP,
  GET_INVENTORY_GROUP_LIST,
  GET_TRAFFIC_POLICY_LIST,
  GET_INVENTORY_GROUP_LIST_COUNT,
  INVENTORY_GROUP_DEVICES_EOS_UPGRADE,
  INVENTORY_TOP_WIDGET_KAAS_COUNT,
  GET_GROUP_TRANSCATION_CONFIG_STATUS,
  GET_LICENSE_TYPES,
  GET_SITE_INFO_LIST,
  GET_SITE_INFO_LIST_COUNT,
  GET_DPS_DEFAULT_ACTIVE_CONFIG,
  SET_DEVICE_HISTORY_LOADING,
  GET_DEVICE_HISTORY,
  SET_DEVICE_EOS_HISTORY_LOADING,
  GET_DEVICE_EOS_HISTORY,
  SET_UPGRADE_INVENTORY_LICENSE_LOADING,
  GET_UPGRADE_INVENTORY_LICENSE,
  SET_DEVICE_EOS_HISTORY_EVENTS_LOADING,
  GET_DEVICE_EOS_HISTORY_EVENTS
 } 
  from "../types";
import { ERROR_ASSING_INVENTORY, ERROR_FETCH_OU_RESOURCES } from "../error_types";
import { toast } from "react-toastify";
import _ from "lodash";

const handleResponseDeployedData = (res, dispatch, dispatcher) => {
  if (res?.data?.error) {
    toast.error('Failed to fetch inventory data..', {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  }
  handleResponse(res, dispatch, dispatcher)
}

export const getInventoryDeployedData = (parameters, dispatcher) => (dispatch) => {
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const {pageStart, pagesize, search, sortOrder, dp, k4Ids, sortBy} = parameters;
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  const vesselMap: any = [];
    k4Ids?.map((item: any) => {
      let vesselName = item["id"];
      vesselMap.push(vesselName);
    })  
  let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;
  const data : any = {
    query: 'INVENTORY_DASHBOARD',
    named: true,
    parameters: {
      pageStart : pageStart,
      pagesize : pagesize,
      search : search,
      sortBy: sortBy,
      sortOrder : sortOrder,
      dp : "",
      k4Ids : (k4idhere && k4idhere !== '') ? `${k4idhere}` : ''
    },
    format: 'csv'
  }
  api
  .postRequestOut(`${apiUrl}`, data)
  .then((res) => 
  handleResponseDeployedData(res, dispatch, dispatcher))
  .catch((err) => handleError(err, dispatch));
};

export const getInventoryDeployedDataCount = (parameters) => (dispatch) => {
  // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
  let isClickHouse = true;
  const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
  const {pageStart, pagesize, search, sortOrder, dp, k4Ids} = parameters;
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  const vesselMap: any = [];
    k4Ids?.map((item: any) => {
      let vesselName = item["id"];
      vesselMap.push(vesselName);
    })  
  let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;
  const data : any = {
    query: 'INVENTORY_DASHBOARD_COUNT',
    named: true,
    parameters: {
      pageStart : pageStart,
      pagesize : pagesize,
      search : search,
      sortOrder : sortOrder,
      dp : "",
      k4Ids : (k4idhere && k4idhere !== '') ? `${k4idhere}` : ''
    },
    format: 'csv'
  }
  api
  .postRequestOut(`${apiUrl}`, data)
  .then((res) => 
  handleResponse(res, dispatch, GET_INVENTORY_DEPLOYED_DATA_COUNT))
  .catch((err) => handleError(err, dispatch));
};

export const getInventoryWarehouseTableData = ( ouId="", searchValue="", page=-1, limit=-1, sortBy,sortOrder) => (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  api
    .getRequestForExternalApi(`${KAPTUREV2_URL_INV}dp/inventoryList?k4DpId=${ouId}&searchValue=${searchValue}&pageNumber=${page}&pageSize=${limit}&sortKey=${sortBy}&sortOrder=${sortOrder}`)
    .then((res) => handleResponse(res, dispatch, GET_INVENTORY_WAREHOUSE_TABLE_DATA))
    .catch((err) => handleError( err, dispatch));
};

export const getInventoryWarehouseVesselList = (ouName, searchValue:"", viewWarehouseService:any, viewwarehouseFunction:any) => (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  api
    .getRequestOut(`/edge/distributionPartners/dpName/${ouName}/locations?search=${searchValue}&sort=name&sortOrder=asc&service=${viewWarehouseService}&service_function=${viewwarehouseFunction}`)
    .then((res) => handleResponse(res, dispatch, GET_INVENTORY_WAREHOUSE_VESSEL_LIST, ERROR_FETCH_OU_RESOURCES, SET_INVENTORY_LISTING_LOADING))
    .catch((err) => handleError( err, dispatch));
}

export const getDevicewiseLatestEOSversion = () => (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  api
    .getRequestOut("/edge/softwares/getDevicewiseLatestStableOsVersion")
    .then((res) => handleResponse(res, dispatch, DEVICEWISE_LATEST_EOS_VERSION))
    .catch((err) => handleError(err, dispatch));
}

export const upgradeDevicetoLatestEOSversion = (siteId, deviceId) => async(dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true })

  let URL = `/v1/sites/${siteId}/devices/${deviceId}/software/upgrade`
  let payload = {
    "site_id": siteId,
    "device_id": deviceId
  }

  try {
      let res = await api.siteController.post(URL, payload);
      if(res && res?.success == true) {
        toast.success(res.result, {
            position: toast.POSITION.BOTTOM_LEFT,
        });
      }
      else{
        let response = res?.data? res.data: res;
        let message = response?.result ? response.result :"Failed to initiate Upgrade on the device"
        toast.error(message, {
            position: toast.POSITION.BOTTOM_LEFT,
        });
      }
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
      dispatch({ type: UPGRADE_DEVICE_TO_LATEST_EOS_VERSION, payload: res?.result })
      return res;
  } catch (error) {
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
      return null
  }
}

export const getDeviceList = (ouName) => (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  let URL = "/edge/locations"
  if(!_.isEmpty(ouName)){
    URL+="?ouName="+ouName
  }
  api
    .getRequestOut(URL)
    .then((res) => handleResponse(res, dispatch, GET_DEVICE_LIST))
    .catch((err) => handleError(err, dispatch));
}

export const createGroup = (dpId, payload) => async (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  let URL = `/v1/dps/${dpId}/group`;
  try {
      let res = await api.siteController.post(URL, payload);
      groupToastMessage(res,"Group created successfully")
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
      dispatch({ type: CREATE_INVENTORY_GROUP, payload: res })
      return res;
  } catch (error) {
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
      return null;
  }
};

const groupToastMessage = (res,message) =>{
  if(res && res.success == true) {
    toast.success(message, {
        position: toast.POSITION.BOTTOM_LEFT,
    });
  }
  else if (res && res.success == false) {
    if (Array.isArray(res.errors) && res.errors.length > 0) {
      toast.error(res.errors[0].message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  }
}

export const updateGroup = (dpId, payload) => async (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  let URL = `/v1/dps/${dpId}/group`;
  try {
      let res = await api.siteController.put(URL, payload);
      groupToastMessage(res,"Group updated successfully")
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
      dispatch({ type: UPDATE_INVENTORY_GROUP, payload: res })
      return res;
  } catch (error) {
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
      return null;
  }
};

export const deleteGroup = (dpId, groupId) => async (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  let URL = `/v1/dps/${dpId}/group/${groupId}`;
  try {
      let res = await api.siteController.delete(URL);
      groupToastMessage(res,"Group deleted successfully")
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
      dispatch({ type: DELETE_INVENTORY_GROUP, payload: res })
      return res;
  } catch (error) {
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
      return null;
  }
};

export const groupDevicesEosUpgrade = (dpId, groupId) => async (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  let URL = `/v1/dps/${dpId}/group/${groupId}/action/software/upgrade`;
  try {
      let res = await api.siteController.post(URL);
      groupToastMessage(res,"Initiated the software upgrade on devices")
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
      dispatch({ type: INVENTORY_GROUP_DEVICES_EOS_UPGRADE, payload: res })
      return res;
  } catch (error) {
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
      return null;
  }
};

export const getGroupList = (payload) => (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  api
    .postRequestOut(`/store/pg/query`, payload)
    .then((res) =>
      handleResponse(res, dispatch, GET_INVENTORY_GROUP_LIST))
    .catch((err) => handleError(err, dispatch));
}

export const getGroupListCount = (payload) => (dispatch) => {  
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  api
    .postRequestOut(`/store/pg/query`, payload)
    .then((res) =>
      handleResponse(res, dispatch, GET_INVENTORY_GROUP_LIST_COUNT))
    .catch((err) => handleError(err, dispatch));
}

export const getSiteInfo = (payload) => (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  api
    .postRequestOut(`/store/ch/query`, payload)
    .then((res) =>
      handleResponse(res, dispatch, GET_SITE_INFO_LIST))
    .catch((err) => handleError(err, dispatch));
}

export const getSiteInfoCount = (payload) => (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  api
    .postRequestOut(`/store/ch/query`, payload)
    .then((res) =>
      handleResponse(res, dispatch, GET_SITE_INFO_LIST_COUNT))
    .catch((err) => handleError(err, dispatch));
}

export const assignInventoryToVessel = (deviceId, payload) => (dispatch) => {
    dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
    api
        .postRequestForExternalApi(`${KAPTUREV2_URL_INV}inventoryConfig/updateInventoryLocation/v3?deviceId=${deviceId}`, payload)
        .then((res) => showToastMessage(res, dispatch, ASSIGN_INVENTORY_TO_VESSEL))
        .catch((err) => handleError(err, dispatch));
};

export const getLicenseTypes = (dpId) => (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  api
    .getRequestForExternalApi(`${KAPTUREV2_URL_INV}inventoryConfig/getLicenseTypes?dpId=${dpId}`)
    .then((res) => handleResponse(res, dispatch, GET_LICENSE_TYPES))
    .catch((err) => handleError( err, dispatch));
};

export const getDpsDefaultActiveConfig = (dpId, deviceId) => (dispatch) => {
  if (_.isEmpty(deviceId) || _.isEmpty(dpId)){
    return
  }

  const data = {
    query: `GET_DPS_DEFAULT_ACTIVE_CONFIG`,
    named: true,
    format: 'json',
    parameters: {
      "device_identifier": deviceId.slice(0, 2),
      "dp_id": dpId
    }
  }
  api
    .postRequestOut(`/store/pg/query`, data)
    .then((res) => {
      handleResponse(res, dispatch, GET_DPS_DEFAULT_ACTIVE_CONFIG)
    })
    .catch((err) => {
      handleError(err, dispatch);
    });
};

const handlCreateVesselRes = (res, dispatch, dispatcher) => {
  if (res.status !== 201) {
    toast.error(res.message, {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  }
  else if (res.status == 201 && res?.data?.status == 1) {
    toast.success(res?.data?.message, {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  }
  handleResponse(res, dispatch, dispatcher)
}

export const createVessel = (userEmail, payload) => (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  api
      .postRequestForExternalApi(`${KAPTUREV2_URL}/location/create?userEmail=${userEmail}`, payload)
      .then((res) => {
        handlCreateVesselRes(res, dispatch, CREATE_AND_ASSIGN_INVENTORY)
      })
      .catch((err) => handleError(err, dispatch));
};

export const getDPList = (search="") => (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  api
    .getRequest(`/iam/v3/ou/derived?search=${search}&sort=name&sortOrder=asc`)
      .then((res) => handleResponse(res, dispatch, GET_DP_LIST))
      .catch((err) => handleError(err, dispatch));
};

export const moveDeviceToWarehouse = (deviceId, payload) => (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  api
      .postRequestForExternalApi(`${KAPTUREV2_URL_INV}inventoryConfig/updateInventoryLocation/v3?deviceId=${deviceId}`,payload)
      .then((res) => showToastMessage(res, dispatch, INVENTORY_ASSIGN_DEVICE_TO_WAREHOUSE))
      .catch((err) => handleError(err, dispatch));
};

export const inventoryChangeGroup = (k4Id, dpId) => (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  const payload = {
    k4DpId: dpId
  }
  api
      .postRequestForExternalApi(`${KAPTUREV2_URL_INV}vessels/moveToDP?k4Id=${k4Id}`, payload)
      .then((res) => showToastMessage(res, dispatch, INVENTORY_CHANGE_GROUP))
      .catch((err) => handleError(err, dispatch));
};

export const warehouseChangeGroup = (dpId, deviceId) => (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  const payload = {
    dpId: dpId
  }
  api
      .postRequestForExternalApi(`${KAPTUREV2_URL_INV}inventoryConfig/updateInventoryLocation/v1?deviceId=${deviceId}`, payload)
      .then((res) => showToastMessage(res, dispatch, INVENTORY_WAREHOUSE_CHANGE_GROUP))
      .catch((err) => handleError(err, dispatch));
};

export const deleteSite = (siteId) => (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  api
      .deleteRequestForExternalApi(`${KAPTUREV2_URL_INV}vessels/delete/${siteId}`)
      .then((res) => showToastMessage(res, dispatch, INVENTORY_DELETE_SITE))
      .catch((err) => handleError(err, dispatch,));
};

export const showToastMessage = (res, dispatch, handler) => {
  const ssDbSyncTime= " Changes may take time upto 30 secs."
  let message = ""
  let success = false;
  if(res.status == 200 && res.data.status == 1 && (res.data.hasOwnProperty('error_code') && res.data.error_code != 10011 && res.data.error_code != 10035 && res.data.error_code != 10015 && res.data.error_code != 10043 && res.data.error_code != 10055) ) {
    success = true;
    message = res.data.message;
  } else if (res.status == 200) {
    if(handler == INVENTORY_ASSIGN_DEVICE_TO_WAREHOUSE){
      message = res.data.message;
    } else if(handler == INVENTORY_DELETE_SITE){
      message = "Successfully deleted the site."+ssDbSyncTime
    } else if(handler == INVENTORY_WAREHOUSE_CHANGE_GROUP){
      message = "Successfully changed the organization"
    } else {
      message = res.data.message;
    }
    if (res.hasOwnProperty("data") && res.data.hasOwnProperty("status") && res.data.status == 1){
      if(res.data.hasOwnProperty('error_code') && (res.data.error_code == 10011 || res.data.error_code == 10035 || res.data.error_code == 10015 || res.data.error_code == 10043 || res.data.error_code == 10055)) {
        success = false;
      } else {
        success = true;
      }
    }
  } else {
    if (res.hasOwnProperty("message")){
      message = res.message
    }else {
      message = res
    }
  }

  if (success){
    toast.success(message, {
      position: toast.POSITION.BOTTOM_LEFT,
    })
  } else if(res.data.hasOwnProperty('error_code') && (res.data.error_code != 10011 && res.data.error_code != 10035 && res.data.error_code != 10015 && res.data.error_code != 10043 && res.data.error_code != 10055)) {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
    })
  }
  handleResponse(res, dispatch, handler)
}

export const getInventoryTopWidgetsRegisteredCount = (ouId,ouName) => (dispatch) => {
  if (ouId.toLowerCase() == "dp-0001") {
    ouName = "ALL"
  }

  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequestForExternalApi(`${KAPTUREV2_URL_INV}nodesReport/getNodesCount?revenue=true&dpName=${ouName}&src=SPORT`)
    .then((res) => handleResponse(res, dispatch, INVENTORY_TOP_WIDGETS_REGISTERED_COUNT))
    .catch((err) => handleError(err, dispatch));
}

export const getInventoryTopWidgetKassCount = (ouId,ouName) => (dispatch) => {
  let dpCheck = true

  let date = new Date()
  let year = date.getFullYear()
  let month = date.getMonth()+1
  let todaysDate = date.getDate()
  let startDateOfMonth = "01"

  let startDate = year + "-" + month + "-" + startDateOfMonth
  let endDate = year + "-" + month + "-" + todaysDate

  if (ouId.toLowerCase() == "dp-0001") {
    ouName = ""
    dpCheck = false

  }
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequestForExternalApi(`${KAPTUREV2_URL_INV}nodesReport/getNodesData?recType=kaasLicences&startDate=${startDate}&endDate=${endDate}&pageNumber=1&pageSize=5&vslName=${ouName}&dpCheck=${dpCheck}`)
    .then((res) => handleResponse(res, dispatch, INVENTORY_TOP_WIDGET_KAAS_COUNT))
    .catch((err) => handleError(err, dispatch));
}

export const getInventoryTopWidgetWarehouseCount = (ouId) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequestForExternalApi(`${KAPTUREV2_URL_INV}dp/inventoryList?k4DpId=${ouId}&pageNumber=1&pageSize=5`)
    .then((res) => handleResponse(res, dispatch, INVENTORY_TOP_WIDGETS_WAREHOUSE_COUNT))
    .catch((err) => handleError( err, dispatch));
}

export const inventoryDownloadConfig = (siteId, deviceId, configId) => (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  let URL = `/v1/sites/${siteId}/devices/${deviceId}/configuration/${configId}/download`;
  api.siteController.get(URL)
      .then((res) => {
          dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
          dispatch({ type: INVENTORY_DOWNLOAD_CONFIG, payload: res })
      })
      .catch((err) => {
          dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
      });
}

export const clearInventoryConfigDownload = () => (dispatch) => {
  dispatch({ type: INVENTORY_DOWNLOAD_CONFIG})
}

export const getConfigProducts = (dpId, deviceType) => (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  const data: any = {
      query: "PRODUCT_DEFAULT_CONFIG",
      named: true,
      parameters: {
          dp_id: dpId,
          device_type: deviceType
      },
      format: "csv"
  }
  api
  .postRequestOut(`/store/ss/query`, data)
  .then((res) =>
  handleResponse(res, dispatch, GET_CONFIG_PRODUCTS))
  .catch((err) => handleError(err, dispatch));
}

export const applyGoldenConfig = (payload) => async (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  let URL = `/v1/sites/devices/configuration/apply`;
  try {
      let res = await api.siteController.post(URL, payload);
      if(res && res.success == true) {
        toast.success(res.result, {
            position: toast.POSITION.BOTTOM_LEFT,
        });
      }
      else if(res && res.success == false) {
        toast.error(res.errors.message, {
            position: toast.POSITION.BOTTOM_LEFT,
        });
      }
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
      dispatch({ type: APPLY_GOLDEN_CONFIG, payload: res?.result })
      return res;
  } catch (error) {
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
      return null;
  }
};

export const postDeviceResgistrationActions = (siteId, deviceId, payload) => async (dispatch) => {
  let URL = `/v1/sites/${siteId}/devices/${deviceId}/v2/postResgistrationActions`;
  try {
      let res = await api.siteController.post(URL, payload);
  } catch (error) {
      return null;
  }
};

export const backupConfigNow = (siteId, deviceId, deviceName, configName) => async (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  let payload = {
    "config_name": configName
  }

  let URL = `/v1/sites/${siteId}/devices/${deviceId}/configuration/backup`;
  try {
    let res = await api.siteController.post(URL, payload);

    let success = false;
    let success_message = `Started the configuration backup for ${deviceName}`
    let failed_message = `Failed to take the configuration backup for ${deviceName}`
    let message = failed_message
    if (res && res.hasOwnProperty("success")) {
      if (res.success == true) {
        success = true;
        message = success_message
      }
    } else if (res && res.hasOwnProperty("data")) {
      if (!_.isEmpty(res.data) && res.data.hasOwnProperty("success") && res.data.success == true) {
        success = true;
        message = success_message
      }
    }

    if (success == true) {
      toast.success(message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      toast.error(message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }

    dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
    dispatch({ type: BACKUP_CONFIG_NOW, payload: res })
    return res;
  } catch (error) {
    dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
    return null;
  }
};

export const getTrafficPolicyData = (payload) => (dispatch) => {
  dispatch({ type: GET_TRAFFIC_POLICY_LISTING_LOADING, payload: true });
  

  api
    .postRequestOut(`/store/pg/query`, payload)
    .then((res) => {
      dispatch({ type: GET_TRAFFIC_POLICY_LISTING_LOADING, payload: false })
      return handleResponse(res, dispatch, GET_TRAFFIC_POLICY_LIST)
    })
    .catch((err) => handleError(err, dispatch));
}

export const applyTrafficPolicy = (payload) => async (dispatch) => {
  dispatch({ type: APPLY_TRAFFIC_POLICY_LOADING, payload: true });
  let URL = `/v1/sites/trafficpolicy/apply`;
  try {
    let res = await api.siteController.post(URL, payload);
    if(res && res.success === true) {
        toast.success(res.result, {
            position: toast.POSITION.BOTTOM_LEFT,
        });
      } else if(res && res.success === false) {
        let errMessage = res?.result ? res.result : "Something went wrong.";
        toast.error(errMessage, {
            position: toast.POSITION.BOTTOM_LEFT,
        });
    }
    dispatch({ type: APPLY_TRAFFIC_POLICY_LOADING, payload: false });
    dispatch({ type: APPLY_TRAFFIC_POLICY, payload: res?.result });
    return res;
  } catch (error) {
    dispatch({ type: APPLY_TRAFFIC_POLICY_LOADING, payload: false })
    return null;
  }
}

export const getTraficPolicyApplyLogs = (payload) => (dispatch) => {
  dispatch({ type: GET_TRAFFIC_POLICY_LOG_LIST_LOADING, payload: true });

  api
    .postRequestOut(`/store/pg/query`, payload)
    .then((res) => {
      dispatch({ type: GET_TRAFFIC_POLICY_LOG_LIST_LOADING, payload: false })
      return handleResponse(res, dispatch, GET_TRAFFIC_POLICY_LOG_LIST)
    })
    .catch((err) => handleError(err, dispatch));
}

export const applyFirewallRule = (payload) => async (dispatch) => {
  dispatch({ type: APPLY_FIREWALL_RULE_LOADING, payload: true });
  let URL = `/v1/sites/firewallrules/apply`;

  try {
    let res = await api.siteController.post(URL, payload);
    if(res && res.success === true) {
        toast.success(res.result, {
            position: toast.POSITION.BOTTOM_LEFT,
        });
      } else if(res && res.success === false) {
        let errMessage = res?.result ? res.result : "Something went wrong.";
        toast.error(errMessage, {
            position: toast.POSITION.BOTTOM_LEFT,
        });
    } else if(res && res?.data && res?.data?.result) {
      toast.error(res?.data?.result, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    dispatch({ type: APPLY_FIREWALL_RULE_LOADING, payload: false });
    dispatch({ type: APPLY_FIREWALL_RULE, payload: res?.result });
  } catch (error) {
    dispatch({ type: APPLY_FIREWALL_RULE_LOADING, payload: false })
  }
}

export const getWANProfileList = (payload) => async (dispatch) => {
  dispatch({ type: GET_WAN_PROFILE_LISTING_LOADING, payload: true });
  

  api
    .postRequestOut(`/store/pg/query`, payload)
    .then((res) => {
      dispatch({ type: GET_WAN_PROFILE_LISTING_LOADING, payload: false })
      return handleResponse(res, dispatch, GET_WAN_PROFILE_LIST)
    })
    .catch((err) => handleError(err, dispatch));
} 

export const applyWanProfile = (payload) => async (dispatch) => {
  dispatch({ type: APPLY_WAN_PROFILE_LOADING, payload: true });

  let URL = `/v1/sites/wanprofiles/apply`;
  try {
    let res = await api.siteController.post(URL, payload);
    if (res && res.success === true) {
      toast.success(res.result, {
            position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (res && res.success === false) {
      let errMessage = res?.result ? res.result : "Something went wrong.";
      toast.error(errMessage, {
            position: toast.POSITION.BOTTOM_LEFT,
        });
    }
    dispatch({ type: APPLY_WAN_PROFILE_LOADING, payload: false });
    dispatch({ type: APPLY_WAN_PROFILE, payload: res?.result });
  } catch (error) {
    dispatch({ type: APPLY_WAN_PROFILE_LOADING, payload: false })
    return null;
  }
}

export const getPopIpsList = (payload) => async (dispatch) => {
  dispatch({ type:  GET_POP_IPS_LISTING_LOADING, payload: true });

  api
    .postRequestOut('/store/pg/query', payload)
    .then((res) => {
      dispatch({ type: GET_POP_IPS_LISTING_LOADING, payload: true});
      return handleResponse(res, dispatch, GET_POP_IPS_LIST);
    })
    .catch((err) => handleError(err, dispatch));
}

export const applyHubIPConfig = (payload) => async (dispatch) => {
  dispatch({ type:APPLY_HUB_IP_CONFIG_LOADING, payload: true });

  let URL = `/v1/sites/hubipconfig/apply`;
  try {
    let res = await api.siteController.post(URL, payload);
    if (res && res.success === true) {
      toast.success(res.result, {
            position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (res && res.success === false) {
      let errMessage = res?.result ? res.result : "Something went wrong.";
      toast.error(errMessage, {
            position: toast.POSITION.BOTTOM_LEFT,
        });
    }

    dispatch({ type: APPLY_HUB_IP_CONFIG_LOADING, payload: false });
    dispatch({ type: APPLY_HUB_IP_CONFIG, payload: res?.result });
  } catch (error) {
    dispatch({ type: APPLY_WAN_PROFILE_LOADING, payload: false })
    return null;
  }
}

export const copyQuotaConfig = (payload) => async (dispatch) => {
  dispatch({ type:COPY_QUOTA_CONFIG_LOADING, payload: true });

  let URL = `/v1/sites/quota/copy`;
  try {
    let res = await api.siteController.post(URL, payload);
    if (res && res.success === true) {
      toast.success(res.result, {
            position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (res && res.success === false) {
      let errMessage = res?.result ? res.result : "Something went wrong.";
      toast.error(errMessage, {
            position: toast.POSITION.BOTTOM_LEFT,
        });
    }

    dispatch({ type: COPY_QUOTA_CONFIG_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: COPY_QUOTA_CONFIG_LOADING, payload: false })
    return null;
  }
}

export const backupConfigData = (k4Ids, page, limit, search, sort, sortOrder, deviceId, deviceType, softwareVersion, manual) => (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });

  let URL = "/v1/sites/devices/configuration/query"

  let payload = {
    "site_id": k4Ids,
    "device_id": deviceId,
    "device_type": deviceType,
    "search": search,
    "page": page,
    "size": limit,
    "sort_by": sort,
    "sort_order": sortOrder,
    "software_version": softwareVersion
  }

  if (manual != undefined) {
    payload["manual"] = manual
  }
  if(k4Ids && k4Ids.length > 0) {


  api.siteController.post(URL, payload)
    .then((res) => {
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
      dispatch({ type: GET_BACKUP_CONFIG_DATA, payload: res })
    })
    .catch((err) => {
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
    });
  } else {
    dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
    dispatch({ type: GET_BACKUP_CONFIG_DATA, payload: {} })
  }
};

export const copyConfigToManualBackup = (siteId, deviceId, configId, newConfigName) => (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  let URL = `/v1/sites/${siteId}/devices/${deviceId}/configuration/${configId}`;
  let payload = {
    "config_name": newConfigName,
    "copy": true
  }
  api.siteController.put(URL, payload)
    .then((res) => {
      if (res && res.success == true) {
        toast.success('Successfully copied to backup configuration', {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }else {
        toast.error('Failed to copy the configuration to backup configuration', {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
      dispatch({ type: COPY_CONFIG_TO_MANUAL_BACKUP, payload: res })
    })
    .catch((err) => {
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
    });
};

export const deleteManualBackupConfig = (siteId, deviceId, configId, configName) => (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  let URL = `/v1/sites/${siteId}/devices/${deviceId}/configuration/${configId}`;
  api.siteController.delete(URL)
    .then((res) => {
      if (res && res.success == true) {
        toast.success(`Successfully deleted the backup configuration: ${configName}`, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }else {
        toast.error(`Failed to delete the backup configuration: ${configName}`, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
      dispatch({ type: DELETE_MANUAL_BACKUP_CONFIG, payload: res })
    })
    .catch((err) => {
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
    });
};

export const editDefaultConfig = (dpId, configId, payload) => async (dispatch) => {
    dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
    let URL = `/v1/dps/${dpId}/default_configurations/${configId}`;
    try {
        let res = await api.siteController.put(URL, payload);
        if(res && res.success == true) {
          toast.success('Default Config Updated Successfully..', {
              position: toast.POSITION.BOTTOM_LEFT,
          });
        }
        dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
        dispatch({ type: EDIT_DEFAULT_CONFIG, payload: res })
        return res;
    } catch (error) {
        dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
        return null;
    }
};

export const createDefaultConfig = (dpId, payload) => async (dispatch) => {
    dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
    let URL = `/v1/dps/${dpId}/default_configurations`;
    try {
        let res = await api.siteController.post(URL, payload);
        if(res && res.success == true) {
            toast.success('Default Config Created Successfully..', {
                position: toast.POSITION.BOTTOM_LEFT,
            });
        }
        dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
        dispatch({ type: CREATE_DEFAULT_CONFIG, payload: res })
        return res;
    } catch (error) {
        dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
        return null;
    }
};

export const deleteDefaultConfig = (dpId, id) => async (dispatch) => {
    dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
    let URL = `/v1/dps/${dpId}/default_configurations/${id}`;
    try {
        let res = await api.siteController.delete(URL);
        dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
        dispatch({ type: DELETE_DEFAULT_CONFIG, payload: res })
        if(res?.result) {
          toast.success('Config Deleted Successfully..', {
              position: toast.POSITION.BOTTOM_LEFT,
          });
      }
        return res;
    } catch (error) {
        dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
        return null;
    }
};

export const getDefaultConfigListing = (dpIds, page, limit, sort, sortOrder, searchValue) => async (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  let URL = `/v1/dps/default_configurations`;
  const payload = {
    dp_id: dpIds,
    search: searchValue,
    sort_by: sort,
    sort_order: sortOrder,
    page: page,
    size: limit,
    show_product_config: false,
    show_dp_name: true
  }
  if(dpIds && dpIds.length > 0 ) {

  
  try {
      let res = await api.siteController.post(URL, payload);
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
      dispatch({ type: GET_DEFAULT_CONFIG_LISTING, payload: res })
      return res;
  } catch (error) {
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
      return null;
  }
  } else {
    dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
    dispatch({ type: GET_DEFAULT_CONFIG_LISTING, payload: {} })
  }
};

export const getDefaultProducts = (configId) => async (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  let URL = `/v1/dps/default_configurations/product_configuration`;
  const payload = {
    default_config_id: [`${configId}`],
    show_names: true,
    page: 1,
    size: 1000
  }
  try {
      let res = await api.siteController.post(URL, payload);
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
      dispatch({ type: GET_DEFAULT_PRODUCTS, payload: res?.result })
      return res;
  } catch (error) {
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
      return null;
  }
};

export const getConfigSoftwareVersions = () => (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true });
  const softwareVersions : any = {
      query: 'GET_CONFIG_BACKUP_SOFTWARE_VERSIONS',
      named: true,
      format: 'csv'
  }
  api
  .postRequestOut(`/store/ss/query`, softwareVersions)
  .then((res) => handleResponse(res, dispatch, GET_CONFIG_SOFTWARE_VERSIONS))
  .catch((err) => handleError(err, dispatch));
}

export interface getConfigStatusParameters {
  sites: string[];
  search: string;
  sort: string;
  order: string;
  limit: number;
  offset: number;
}

export const getConfigStatus = (parameters: getConfigStatusParameters) => (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true })
  let _parameters: any = { ...parameters };
  _parameters.sites = _parameters?.sites.map(site => `'${site}'`).join(',');
  const data = {
    query: `GET_CONFIG_RESTORE_STATUS`,
    named: true,
    format: 'csv',
    parameters: _parameters
  }
  api
    .postRequestOut(`/store/pg/query`, data)
    .then((res) => {
      handleResponse(res, dispatch, GET_CONFIG_STATUS)
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
    })
    .catch((err) => {
      handleError(err, dispatch);
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
    });
}

export const getGroupTranscationConfigStatus = (sites, devices, groupTransactionId, searchText, sortBy, sortOrder, page, size) => (dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true })

  let payload = {
    "query": "GET_GROUP_TRANSCATION_CONFIG_RESTORE_STATUS",
    "named": true,
    "parameters": {
      "sites": sites,
      "devices": devices,
      "group_transaction_id": groupTransactionId,
      "search": searchText,
      "sort": sortBy,
      "order": sortOrder,
      "limit": size,
      "offset": (page - 1) * size
    },
    "format": "csv"
  }

  api
    .postRequestOut(`/store/pg/query`, payload)
    .then((res) => {
      handleResponse(res, dispatch, GET_GROUP_TRANSCATION_CONFIG_STATUS)
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
    })
    .catch((err) => {
      handleError(err, dispatch);
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
    });
}

export const rebootDevice = (siteId, deviceId) => async(dispatch) => {
  dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: true })

  let URL = `/v1/sites/${siteId}/device/${deviceId}/reboot`
  let payload = {}

  try {
      let res = await api.siteController.post(URL, payload);
      if(res && res.success == true) {
        toast.success(res.result, {
            position: toast.POSITION.BOTTOM_LEFT,
        });
      }
      else if(res && res.success == false) {
        toast.error(res.errors.message, {
            position: toast.POSITION.BOTTOM_LEFT,
        });
      }
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
      dispatch({ type: REBOOT_DEVICE, payload: res?.result })
      return res;
  } catch (error) {
      dispatch({ type: SET_INVENTORY_LISTING_LOADING, payload: false })
      return null
  }
}

export const inventoryDeviceHistory = (invId) => (dispatch) => {
  dispatch({ type: SET_DEVICE_HISTORY_LOADING, payload: true });
  api
      .getRequestForExternalApi(`${KAPTUREV2_URL_INV}inventoryConfig/inventoryHistory?inventoryId=${invId}`)
      .then((res) => handleResponse(res, dispatch, GET_DEVICE_HISTORY))
      .catch((err) => handleError(err, dispatch));
};

export const getDeviceEosHistory = (deviceId) => (dispatch) => {
  dispatch({ type: SET_DEVICE_EOS_HISTORY_LOADING, payload: true });
  let payload ={
    "query": "GET_CONFIG_ACTION_DATA",
    "named": true,
    "parameters": {
        "deviceIds": `'${deviceId}'`,
         "action": "EOS upgrade"
    },
    "format": "csv"
}
  api
      .postRequestOut(`/store/pg/query`, payload)
      .then((res) => handleResponse(res, dispatch, GET_DEVICE_EOS_HISTORY))
      .catch((err) => handleError(err, dispatch));
};

export const getDeviceEosHistoryEvents = (deviceId) => (dispatch) => {
  dispatch({ type: SET_DEVICE_EOS_HISTORY_EVENTS_LOADING, payload: true });
  let payload ={
    "query": "GET_DEVICE_UPGRADE_EVENTS",
    "named": true,
    "parameters": {
        "deviceIds": `'${deviceId}'`,
    },
    "format": "csv"
}
  api
      .postRequestOut(`/store/ch/query`, payload)
      .then((res) => handleResponse(res, dispatch, GET_DEVICE_EOS_HISTORY_EVENTS))
      .catch((err) => handleError(err, dispatch));
};

export const handleToastMessage = (res, dispatch, handler) => {
  let message = ""
  let success = false;
  if (res.status == 200 && res.data.status == 1 && (res.data.hasOwnProperty('error_code') && (res.data.error_code != 14013 && res.data.error_code != 14014 && res.data.error_code != 14015 && res.data.error_code != 14016 && res.data.error_code != 14019 && res.data.error_code != 14029))) {
    success = true;
    message = res.data.message;
  } else if (res.status == 200) {
    if (handler == GET_UPGRADE_INVENTORY_LICENSE) {
      message = res.data.message;
    } else {
      message = res.data.message;
    }
    if (res.hasOwnProperty("data") && res.data.hasOwnProperty("status") && res.data.status == 1) {
      if (res.data.hasOwnProperty('error_code') && (res.data.error_code == 14013 || res.data.error_code == 14014 || res.data.error_code == 14015 || res.data.error_code == 14016 || res.data.error_code == 14019 || res.data.error_code == 14029)) {
        success = false;
      } else {
        success = true;
      }
    }
  } else {
    if (res.hasOwnProperty("message")) {
      message = res.message
    } else {
      message = res
    }
  }

  if (success) {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_LEFT,
    })
  } else if (res.data.hasOwnProperty('error_code') && (res.data.error_code != 14013 && res.data.error_code != 14014 && res.data.error_code != 14015 && res.data.error_code != 14016 && res.data.error_code != 14019 && res.data.error_code != 14029)) {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
    })
  }
  handleResponse(res, dispatch, handler)
}

export const upgradeInventoryLicense = (payload) => (dispatch) => {
  dispatch({ type: SET_UPGRADE_INVENTORY_LICENSE_LOADING, payload: true });
  api
    .postRequestForExternalApi(`${KAPTUREV2_URL_INV}inventoryConfig/updateLicense/v1`, payload)
    .then((res) => handleToastMessage(res, dispatch, GET_UPGRADE_INVENTORY_LICENSE))
    .catch((err) => handleError(err, dispatch));
};