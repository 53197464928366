import React, { useEffect, useState } from 'react';
import { Grid, Box, Paper, TextField, Chip, IconButton, Table, Typography, Button, Stack, TableContainer, TableHead, TableRow, TableCell, TableBody, Tooltip, Autocomplete, Dialog, DialogTitle, DialogContent, DialogActions, ListItemText, ListItem, Select, MenuItem } from '@mui/material';
import { Edit as EditIcon, Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import _ from 'lodash';
import { ACTION_TYPES, topUpServiceLine } from '../slice';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import { getDecodeURI } from '../../../utils/util';
import moment from 'moment-timezone';

const ServiceLineTopUp = (props) => {
    const { serviceLineToUpdate, starlinkAdmin, location, changeSLName, topUpServiceLine, addTopUpToServiceLine } = props;
    const _q = getDecodeURI(location?.search)
    const dispatch = useDispatch();
    const [selectedOu, setSelectedOu] = useState(_q?.ouId)
    const [topUpValue, setTopUpValue] = useState<any>(0);
    const [usageType, setUsageType] = useState<any>("gb")
    const [topUpData, setTopUpData] = useState<any>([])

    useEffect(() => {
        if (serviceLineToUpdate && serviceLineToUpdate?.topups) {
            setTopUpData(serviceLineToUpdate?.topups)
        }
    }, serviceLineToUpdate)

    const handleTopUp = async () => {

        //api call for topup
        let params = {
            organizationId: selectedOu ? selectedOu : "",
            accountNumber: serviceLineToUpdate?.accountNumber,
            unit: usageType,
            topup: topUpValue,
            credentialId: serviceLineToUpdate?.credentialId,
            serviceLineNumber: serviceLineToUpdate?.serviceLineNumber,
            customPlanId: serviceLineToUpdate?.customPlanId
        }
        await topUpServiceLine(params)
        dispatch({ type: ACTION_TYPES.ADD_TOPUP_TO_SERVICE_LINE, payload: {} })
        changeSLName(serviceLineToUpdate?.serviceLineName)
    }

    useEffect(() => {
        if (!_.isEmpty(addTopUpToServiceLine)) {
            toast.success(`Top Up added successfully.`, {
                position: toast.POSITION.BOTTOM_LEFT,
            })

            setTopUpData([{
                createdAt: moment(),
                topup: topUpValue,
                unit: usageType,
                billingDate: moment().endOf('month')
            }, ...topUpData]);
        }
        setTopUpValue(0)
    }, [addTopUpToServiceLine])

    return (
        <div className="serviceLineDetails-container">
            <div>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <Typography
                            variant="h5"
                            component="div"
                            style={{ fontWeight: 'bold', color: "#424141" }}
                        >
                            Top Up
                        </Typography>
                    </Grid>
                    <Grid container xs={12} m={1} >
                        <Typography fontSize={"0.8rem"}
                            ml={1}
                        // textAlign={'center'}
                        >Note: The Service Line can have additional data added for that usage-duration only, for the specific Starlink Pool Account.</Typography>
                    </Grid>
                    <Grid container xs={12} mt={1} spacing={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        {/* <Grid item xs={4}><Typography>Current Pool Size : */}
                        {/* {accountForTopup?.subPoolDetails?.poolSize} {accountForTopup?.subPoolDetails?.unit?.toUpperCase()} */}
                        {/* </Typography></Grid> */}
                    </Grid>
                    <Grid container xs={12} m={1} spacing={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Grid item xs={1.5}><Typography>Top Up</Typography></Grid>
                        <Grid item xs={4}>
                            <TextField
                                type="number"
                                fullWidth
                                value={topUpValue === 0 ? "" : topUpValue} 
                                onChange={(e) => { setTopUpValue(e.target.value) }}
                                size="small"
                            /></Grid>
                        <Grid item xs={2}>
                            <Select
                                fullWidth
                                size="small"
                                value={usageType}
                                onChange={(e) => { setUsageType(e.target.value) }}
                                displayEmpty
                            >
                                <MenuItem value="gb">GB</MenuItem>
                                {/* <MenuItem value="tb">TB</MenuItem> */}
                            </Select>
                        </Grid>
                        <Grid item xs={2} ml={1}>
                            <Button variant="contained" className='starlink--button_contained--primary' onClick={() => { handleTopUp() }} >Save</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            {topUpData && topUpData?.length > 0 ?
                <>
                    <Typography component="div" className="starlink--base--font_1x">TopUps History</Typography>
                    <TableContainer component={Paper} className="starlink--table_container" >
                        <Table className='starlink-sl-devices-table' size="small" aria-label="starlink--service_accounts">
                            <TableHead>
                                <TableRow>
                                    <TableCell> <span className="font-wt-900">SNo.</span> </TableCell>
                                    <TableCell> <span className="font-wt-900">DATE</span> </TableCell>
                                    <TableCell> <span className="font-wt-900">SIZE</span> </TableCell>
                                    <TableCell> <span className="font-wt-900">VALID TILL</span> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {topUpData?.map((topUp, index) => {
                                    return (<TableRow>
                                        <TableCell> {index + 1} </TableCell>
                                        <TableCell> {moment(topUp?.createdAt)?.format("MM-DD-YYYY")} </TableCell>
                                        <TableCell> {topUp?.topup} {topUp?.unit.toUpperCase()} </TableCell>
                                        <TableCell> {moment(topUp?.billingDate)?.endOf('month')?.format("MM-DD-YYYY")} </TableCell>
                                    </TableRow>)
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </> :
                <>
                    <Typography component="div" className="starlink--base--font_1x"></Typography>
                </>
            }




        </div>
    );
};

const mapStateToProps = (state) => ({
    starlinkAdmin: state.starlinkAdmin,
    authReducer: state.authReducer,
    addTopUpToServiceLine: state.starlinkAdmin.addTopUpToServiceLine
});

export default withRouter(
    connect(mapStateToProps, { topUpServiceLine })(ServiceLineTopUp)
);


