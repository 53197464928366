
import { Box, Button, Grid, Menu, Stack } from '@mui/material';
import FirewallReportChart from './FirewallReportChart';
import FirewallReportData from './FirewallReportData';
import { FirewallReportProvider } from './FirewallReportContext';
import FirewallTopWidgets from './FirewallTopWidgets';
import FirewallReportLineChart from './FirewallReportLineChart';
import { Tune } from '@material-ui/icons';

import './firewallReport.css';
import close from "../../asset/image/close.svg";
import FirewallFilters from './FirewallFilters';
import { useState } from 'react';

const FirewallReport = (props) => {
    const { isAuthorizedToViewBetaFeature } = props;
    const [firewallTableDataCount, setFirewallTableDataCount] = useState(0);

    return (
        <FirewallReportProvider>
            <div className="Form firewallReports-Filter">
                <Grid>
                    <FirewallFilters isAuthorizedToViewBetaFeature={localStorage.getItem("isBetaUser") === "true"} tableCount={firewallTableDataCount} />
                    <Box padding={1}>
                        <div className="Form firewall-container-height">
                            <FirewallTopWidgets />
                            <FirewallReportChart />
                            <FirewallReportLineChart/>
                            <FirewallReportData tableCount={firewallTableDataCount} setTableCount={setFirewallTableDataCount} />
                        </div>
                    </Box>
                </Grid>
            </div>
        </FirewallReportProvider>
    )
}

export default FirewallReport;