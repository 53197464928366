import React,{ useState, useEffect, useRef } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip, Switch } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import Box from '@mui/material/Box';
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from "@mui/material/Autocomplete";
import {getInventoryDeployedData, getInventoryDeployedDataCount, moveDeviceToWarehouse, deleteSite, inventoryDownloadConfig, clearInventoryConfigDownload, inventoryChangeGroup, backupConfigNow, getDevicewiseLatestEOSversion, rebootDevice} from "../../actions/Users/authenticateInventory"
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import { DEFAULT_PAGE_SIZE, NEW_PER_PAGE_ITEMS } from "../../constants/Constants";
import { checkLoggedInUserAuthorizedToViewPage, checkSelectedOuIsInvitedOu, convertDateTimeIntoTimezone, generatePDFfile, getDecodeURI, getEncodedURI, getInvitedOuList, redirectToTopolgyTabs } from "../../utils/util";
import { PER_PAGE_ITEMS } from "../../constants/Constants";
import Paper from "@material-ui/core/Paper";
import Stack from "@mui/material/Stack";
import ascSortingSelected from "../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../asset/image/descSortingSelected.svg";
import AscSort from "../../asset/image/AscSort.svg";
import DescSort from "../../asset/image/DescSort.svg";
import { Link } from "react-router-dom";
import "./inventoryDeployed.css"
import DeleteIcon from "../../asset/image/user_delete.svg";
import WarehouseIcon from "../../asset/image/warehouse.svg";
import ConfirmDialog from "../../components/ConfirmDialog";
import {
  CONFIRM_DIALOG_TITLE
} from "../../config";
import { SERVICE_FUNCTION_TO_FEATURE } from '../../config';
import { DEFAULT_TIMEZONE, DEFAULT_DATE_FORMAT, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import DP_Dropdown from "../InventoryWarehouseTable/DP_Dropdown";
import close from "../../asset/image/close.svg";
import changeGroupIcon from "../../asset/image/changeGroup.svg";
import { updateUserOuHasSubOus } from "../../actions/Users/authenticate";
import { displayToastError } from "../../server/request";
import GetApp from "@material-ui/icons/GetApp";
import { DOWNLOAD_INVENTORY_DEPLOYED_DATA, GET_CSV_DATA_TO_DOWNLOAD, GET_INVENTORY_DEPLOYED_DATA } from "../../actions/types";
import ApplyConfig from "./ApplyConfig";
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { BackupConfigNow } from "./BackupConfigNow";
import { compare } from 'compare-versions';
import { Pagination } from "../Pagination";
import { AddBoxSharp } from "@mui/icons-material";
import SoftwareUpgrade from "./SoftwareUpgrade";
import RotateRightOutlinedIcon from '@mui/icons-material/RotateRightOutlined';
import Download from '../../asset/image/DownloadUsage.svg';
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";
import DownloadCSV, { clearCsvDownload } from "../DownloadCSV";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { getVesselsListingForPrivate } from "../../actions/Users/authenticateService";

const convertToCsv = (data: any[][], userTimezone: string) => {
  const [_columns, ...rows] = data;
  const csvRows: any[] = [];
  rows.forEach(row => {
    csvRows.push([row[3], row[0], row[5], row[17], row[4], convertDateTimeIntoTimezone(row[16], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), convertDateTimeIntoTimezone(row[6], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), convertDateTimeIntoTimezone(row[13], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), convertDateTimeIntoTimezone(row[14], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), convertDateTimeIntoTimezone(row[9], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)])
  })
  let lastSeen = 'Last Seen (' + userTimezone + ')'
  let regDate = 'Reg. Date (' + userTimezone + ')'
  let licenseExpDate = 'License Exp. Date (' + userTimezone + ')'
  let uptimeSince = 'Uptime Since (' + userTimezone + ')'
  let savedConfig = 'Saved Config (' + userTimezone + ')'

  return [['Site', 'Organization', 'Device name', 'EOS Version', 'DID', lastSeen, regDate, licenseExpDate, uptimeSince, savedConfig], ...csvRows]
}

const InventoryDeployedTablePrivate = (props) => {
  const { authReducer, errorReducer, getInventoryDeployedData, location, history, permissionChanges, getInventoryDeployedDataCount, moveDeviceToWarehouse, deleteSite, inventoryDownloadConfig, clearInventoryConfigDownload, inventoryChangeGroup, backupConfigNow, updateUserOuHasSubOus, getDevicewiseLatestEOSversion,getVesselsListingForPrivate,
    downloadAsCSV, rebootDevice, isReadOnlyUser } = props;
  let _q = getDecodeURI(location?.search);
  let [inventoryDeployedData, setInventoryDeployedData] = useState<any[]>([]);

  const VESSEL_NAME = "name";
  const GROUP_NAME = "dpname";
  const DEVICE_NAME = "deviceName";
  const PRODUCT_ID = "productId";
  const LAST_SEEN = "last_seen";
  const REG_DATE = "dateRegistered"
  const LICENSE_EXP_DATE = "license_expiry_date";
  const UPTIME_SINCE = "uptime_date";
  const CURRENT_EOS_VERSION = "e_software_version";
  const EOS_VERSION = "software_version";
  const CONFIG_BACK_UP_CREATE_TIME = "create_time";
  const ASC_ORDER = "asc";
  const DESC_ORDER = "desc";

  let [searchValue, setSearchValue] = useState<any>(_q.hasOwnProperty("search") ? _q.search : "");
  const ENTER_KEY_CODE = "Enter";
  const [fromItem, setFromItem] = useState<number>(0);
  const [toItem, setToItem] = useState<number>(0);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = useState<any>(_q.hasOwnProperty("page") ? parseInt(_q.page) : 0)
  const [pages, setPages] = React.useState(0);
  const [limit, setLimit] = useState<any>(_q.hasOwnProperty("limit") ? _q.limit : DEFAULT_PAGE_SIZE);
  const [currentPageItemType, setCurrentPageItemType] = React.useState("Devices");
  const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : VESSEL_NAME);
  const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : ASC_ORDER);
  const [selectedOu, setSelectedOu ] = useState<any>(_q.hasOwnProperty("ouName") && _q.ouName!=="K4Mobility" ? `and fullname IN ('${_q.ouName}')` : "");
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: {},
  });
  const [privateVesselListing, setPrivateVesselListing] = useState<any>({})
  const [locationIds, setLocatioIds] = useState([]);

  const VIEW_INVENTORY = "view-inventory";
  const viewDeployedService = SERVICE_FUNCTION_TO_FEATURE[VIEW_INVENTORY]['service'];
  const viewDeployedFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_INVENTORY]['function'];
  const [downloadConfig, setDownloadConfig] = useState(false);
  const [changeGroupPopup, setChangeGroupPopup] = useState(false);
  const [ou, setOu] = useState<any>({});
  const [selectedSiteId, setselectedSiteIdId] = useState('');
  const [selectedSiteName, setSelectedSiteName] = useState('');
  const [dpName, setDpName] = useState('');
  const [invitedOus, setInvitedOus] = useState<any[]>([])
  const [isSelectedOuIsUserOu, setIsSelectedOuIsUserOu] = useState(false)
  const [modifyConfigData, setModifyConfigData] = useState<any>();
  const isAuthorizedToBeta= checkLoggedInUserAuthorizedToViewPage(SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["service"], SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["function"]);

  const MODIFY_CONFIG = "modify_config"
  const BACKUP_CONFIG_NOW = "backup-config-now"
  const EOS_UPGRADE = "eos-upgrade"

  const [clickedActionIcon, setClickedActionIcon] = useState("")
  const [devicewiseLatestStableOsVersion, setDevicewiseLatestStableOsVersion] = useState<object>({})
  const searchTimeoutRef = useRef<NodeJS.Timeout>();
  const dispatch = useDispatch();


  let payload = {
    pageStart: ((page)*limit),
    pagesize: limit,
    search: searchValue,
    sortBy : sort,
    sortOrder: sortOrder,
    dp: selectedOu,
    k4Ids: locationIds
  }

  function fetchInventoryDeployedData(payload){
    getInventoryDeployedData(payload, GET_INVENTORY_DEPLOYED_DATA);
    getInventoryDeployedDataCount(payload);
}

useEffect(() => {
  if (!_.isEmpty(authReducer.devicewiseLatestEOSversion)) {
    const data = authReducer.devicewiseLatestEOSversion.hasOwnProperty("data") ? authReducer.devicewiseLatestEOSversion.data : {}
    if(!_.isEmpty(data)){
      setDevicewiseLatestStableOsVersion(data)
    } else {
      setDevicewiseLatestStableOsVersion({})
    }
    authReducer.devicewiseLatestEOSversion = {}
  }
},[authReducer.devicewiseLatestEOSversion])

  useEffect(() => {
    if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
      if (authReducer.csvDownloadId === 'inventory-dashboard') {
        DownloadCSV(convertToCsv(authReducer.csvDataDownloded, authReducer.userTimezone), {
          formatters: {
            0: 'ESC-COMMA',
            1: 'ESC-COMMA',
            2: 'ESC-COMMA',
            5: 'ESC-COMMA',
            6: 'ESC-COMMA',
            7: 'ESC-COMMA',
            8: 'ESC-COMMA',
            9: 'ESC-COMMA'
          }
        });
        return () => {
          dispatch(clearCsvDownload())
        }
      }
    }
  }, [authReducer.csvDataDownloded])

useEffect(() => {
  updateUserOuHasSubOus(authReducer)
  let invitedOuList = getInvitedOuList(authReducer.getOrganisationUnitList)
  setInvitedOus(invitedOuList)
}, [authReducer.getOrganisationUnitList])

useEffect(() => {
  if(invitedOus.length>0){
    let isSelectedOuIsInvitedOu = checkSelectedOuIsInvitedOu(authReducer.selectedOu, invitedOus)
    setIsSelectedOuIsUserOu(!isSelectedOuIsInvitedOu)
  }
}, [invitedOus])

useEffect(() => {
  if(!_.isEmpty(privateVesselListing)) {
    const k4Ids = privateVesselListing
    setLocatioIds(k4Ids);
    if(k4Ids?.length > 0) {
      fetchInventoryDeployedData({...payload, k4Ids: k4Ids});
    } else {
      setInventoryDeployedData([]);
    }
  }
}, [privateVesselListing])

useEffect(() => {
  if (!_.isEmpty(authReducer.selectedOu) && authReducer.selectedOu.hasOwnProperty("id") && authReducer.selectedOu.id
      && authReducer.selectedOu.hasOwnProperty("name") && authReducer.selectedOu.name) {
      if(authReducer.selectedOu.name !== "K4Mobility"){
        setSelectedOu(`and fullname IN ('${authReducer.selectedOu.name}')`);
      }else{
        setSelectedOu("") 
      }
      setPage(0)
      setDpName(authReducer.selectedOu.name);
      setInventoryDeployedData([]);
      let isSelectedOuIsInvitedOu = checkSelectedOuIsInvitedOu(authReducer.selectedOu, invitedOus)
      setIsSelectedOuIsUserOu(!isSelectedOuIsInvitedOu)
  }
  getDevicewiseLatestEOSversion()
}, [authReducer.selectedOu]);

useEffect(() => {
  if (!_.isEmpty(errorReducer.errorFetchOuResources)) {
    displayToastError(errorReducer.errorFetchOuResources.description)
    errorReducer.errorFetchOuResources = {};
    setInventoryDeployedData([]);
  }
}, [errorReducer.errorFetchOuResources]);

const searchBoxKeyDown = (event: any) => {
  if (event && event.key === ENTER_KEY_CODE) {
      doSearch(searchValue);
  }
}

const doSearch = (value: string) => {
    let params: any = getDecodeURI(location?.search);
    value ? params.search = value: delete params.search;
    delete params.page;
    setPage(0);

    doNavigate(location.url, params);
    payload.search = value;
    fetchInventoryDeployedData({...payload, pageStart:0});
};

useEffect(() => {
  if (!_.isEmpty(authReducer.getInventoryDeployedData) && authReducer.getInventoryDeployedData.hasOwnProperty("data")) {
    const data = authReducer.getInventoryDeployedData.data
    if (data.hasOwnProperty("rows") && Array.isArray(data["rows"])) {
      const rows: any[] = data["rows"]
      let deployedData: any[] = [];
      for (let i = 0; i < rows?.length; i++) {
        let row = {};
        row["dpname"] = rows[i][0]
        row["dpId"] = rows[i][1]
        row["k4Id"] = rows[i][2]
        row["name"] = rows[i][3]
        row["sn"] = rows[i][4]
        row["deviceName"] = rows[i][5]
        row["dateRegistered"] = rows[i][6]
        row["deviceId"] = rows[i][7]
        row["productType"] = rows[i][8]
        row["create_time"] = rows[i][9]
        row["Path"] = rows[i][10]
        row["saved_config_file_name"] = ""
        if(!_.isEmpty(row["Path"])){
          if(row["Path"].includes("backups")){
            row["saved_config_file_name"] = row["Path"]?.split("backups")[1].replace("/","")
          } else {
            row["saved_config_file_name"] = row["Path"]
          }
        }
        row["EOS_Version"] = rows[i][11]
        row["config_id"] = rows[i][12]

        row["license_expiry_date"] = rows[i][13]
        row["uptime_date"] = rows[i][14]

        row["internet_status_flag"] = rows[i][15]

        row["last_seen"] = rows[i][16]

        // do not show change group icon - if previous row and current row belongs to same site
        row["showChangeGroupIcon"] = true;
        if(i>0 &&  row["k4Id"]==deployedData[i-1]["k4Id"]){
          row["showChangeGroupIcon"] = false;
        }

        row["current_EOS_version"] = ""
        row["is_current_EOS_version_above_v6.5.3"] = false
        row["isLatestEOSavailable"] = false
        row["latestEOSavailable"]  = ""

        if (!_.isEmpty(row["deviceId"]) && !_.isEmpty(rows[i][17])) {
          let deviceIdentifier = row["deviceId"].slice(0, 2);
          row["current_EOS_version"] = rows[i][17]?.split("~")[0]

          try {
            row["is_current_EOS_version_above_v6.5.3"] = compare(row["current_EOS_version"], '6.5.3', '>=')
            row["is_current_EOS_version_above_v6.5.2"] = compare(row["current_EOS_version"], '6.5.2', '>=')
          } catch (error) {
            console.log("error in version compare, current_EOS_version", row["current_EOS_version"])
          }

          row["tierAlias"]  = rows[i][18]
          row["termDisplay"]  = rows[i][19]

          // upgrade
          if (!_.isEmpty(devicewiseLatestStableOsVersion) && devicewiseLatestStableOsVersion.hasOwnProperty(deviceIdentifier)) {
            let latestEOSversion = devicewiseLatestStableOsVersion[deviceIdentifier]
            row["latestEOSavailable"] = latestEOSversion
            try {
              row["isLatestEOSavailable"] = compare(row["current_EOS_version"], latestEOSversion, "<")
            } catch (error) {
              console.log("error in version compare, current_EOS_version", row["current_EOS_version"], "latest_EOS_version", latestEOSversion)
            }
          }
        }

        deployedData.push(row)
      }

      setInventoryDeployedData(deployedData)
    }
    authReducer.getInventoryDeployedData = {};
  }
}, [[authReducer.getInventoryDeployedData]])

useEffect(()=>{
    if (inventoryDeployedData?.length > 0) {
      const length = inventoryDeployedData?.length;
      const totalCount: number = parseInt(authReducer.getInventoryDeployedDataCount?.data?.rows[0][0]);
      const pages = Math.ceil(totalCount / limit);
      updatePageInfo(page, limit, length, pages, totalCount);
    } else {
      updatePageInfo(page, limit, 0, 1, 0);
    }
}, [authReducer.getInventoryDeployedDataCount, inventoryDeployedData])

useEffect(()=>{
  if (!_.isEmpty(authReducer.inventoryAssignDeviceToWarehouse)) {
    const res =authReducer.inventoryAssignDeviceToWarehouse;
    if (res.hasOwnProperty("status") && res.status ==1){
      const dp = authReducer.selectedOu.name=="K4Mobility"? "" : `and fullname IN ('${authReducer.selectedOu.name}')` ;
      const page = _q.hasOwnProperty("page") ? parseInt(_q.page) : 0 ;
      fetchInventoryDeployedData({...payload, dp:dp, pageStart:(page*limit)});
    }
    authReducer.inventoryAssignDeviceToWarehouse = {}
  }
}, [authReducer.inventoryAssignDeviceToWarehouse])

useEffect(()=>{
  if (!_.isEmpty(authReducer.inventoryDeleteSite)) {
    authReducer.inventoryDeleteSite = {}
  }
}, [authReducer.inventoryDeleteSite])

const doNavigate = (pathname, params={}) => {
  history.push({ pathname: pathname, search: `?${getEncodedURI(params)}` });
}

const handlePerPageChange = (e: any) => {
  let _perPage = parseInt(e.target.value);
  if (Number.isInteger(_perPage)) {
      let params: any = getDecodeURI(location?.search);
      params.page = 0;
      params.limit = _perPage;
      setPage(0);
      setLimit(_perPage);
      doNavigate(location.url, params);
      fetchInventoryDeployedData({...payload, pagesize:_perPage, pageStart:0});
  }
};

const handlePagination = (e, value) => {
  let params: any = getDecodeURI(location?.search);
  params.page = value-1;
  setPage(value-1)
  doNavigate(location.url, params);
  fetchInventoryDeployedData({...payload, pageStart:((value-1)*limit)})
};

const updatePageInfo = (page: number, limit: number, itemCount: number, pages: number, total: number) => {
  setPage(page);
  setFromItem(itemCount > 0 ? ((page) * limit) + 1 : 0);
  setToItem(((page) * limit) + itemCount);
  setTotal(total);
  setPages(pages);
};

const setSorting = (event: any, field: any, order: any,) => {
  if (event && event.currentTarget) {
      let params: any = getDecodeURI(location?.search);
      params.page = 0;
      params.sort = field;
      params.sortOrder = order;

      setPage(0);
      setSort(field);
      setSortOrder(order);

      doNavigate(location.url, params);
      fetchInventoryDeployedData({...payload, sortBy:field, sortOrder:order})
  }
};

const switchSorting = (event: any, field: any) => {
  let order = ASC_ORDER;
  // if (sort === field) {
  //     order = ASC_ORDER === sortOrder ? DESC_ORDER : ASC_ORDER;
  // }
  let params: any = getDecodeURI(location?.search);
  params.page = 0;
  params.sort = field;
  params.sortOrder = order;

  setPage(0);
  setSort(field);
  setSortOrder(order);
  
  doNavigate(location.url, params);
  fetchInventoryDeployedData({...payload,  sortBy:field, sortOrder:order})
};

useEffect(() => {
   if(!_.isEmpty(authReducer.inventoryDownloadConfig) && downloadConfig) {
     const url = authReducer?.inventoryDownloadConfig?.result;

      if(authReducer?.inventoryDownloadConfig?.success == true) {
       window.open(url);
       clearInventoryConfigDownload();
      } else if (!authReducer?.inventoryDownloadConfig?.success || authReducer?.inventoryDownloadConfig?.data?.success == false) {
      toast.error('Something went wrong, unable to download the file..', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
     }
     authReducer.inventoryDownloadConfig = {};
    }
},[authReducer?.inventoryDownloadConfig])

const handleDOwnloadConfig = (row) => {
  setDownloadConfig(true);
  const siteId = row?.k4Id;
  const deviceId = row?.sn;
  const configId = row?.config_id
  inventoryDownloadConfig(siteId, deviceId, configId)
}

const changeGroup = () => {
  inventoryChangeGroup(selectedSiteId, ou?.id);
  setChangeGroupPopup(false);
}

const DownloadDeployedTable = () => {
  payload = { ...payload, pageStart: 0, pagesize: total, sortBy: sort, sortOrder: sortOrder }
  getInventoryDeployedData(payload, DOWNLOAD_INVENTORY_DEPLOYED_DATA);
}

const handleModifyConfig = (row, clickedIcon) => () => {
  // if(!isSelectedOuIsUserOu){
  //   return
  // }
  setClickedActionIcon(clickedIcon)
  setModifyConfigData({
    selectedDevice: {
      id: clickedIcon == BACKUP_CONFIG_NOW ? row["sn"] : row["deviceId"],
      name: row["deviceName"],
      productType: row["productType"],
    },
    selectedSite: row["k4Id"],
    selectedSiteName: row["name"],
    currentEOSversion: row["current_EOS_version"],
    latestEOSversion:  row["latestEOSavailable"]
  })
}

  const handleOnSearchClick = () => {
    doSearch(searchValue);
  }

  const handleOnSearchClear = () => {
    setSearchValue('');
    doSearch('');
  }

  const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchValue(e.target.value);
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    searchTimeoutRef.current = setTimeout(() => {
      searchTimeoutRef.current = undefined;
      doSearch(e.target.value);
    }, 1000)
  }

  const handleDownloadReport = () => {
    // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/pg/query` : `/store/ss/query`
    const { search, sortOrder, k4Ids, sortBy } = payload;
    if (k4Ids.length === 0) {
      toast.error("No data available to download", {
        position: toast.POSITION.BOTTOM_LEFT,
      })
      return;
    }
    const vesselMap: any = k4Ids?.map((item: any) => item["id"])
    let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;
    const params: CsvParameters = {
      type: isClickHouse? 'QUERY_CH' : "QUERY",
      id: 'inventory-dashboard',
      queryName: 'INVENTORY_DASHBOARD',
      payload: {
        pageStart: 0,
        pagesize: total,
        search: search,
        sortBy: sortBy,
        sortOrder: sortOrder,
        dp: "",
        k4Ids: (k4idhere && k4idhere !== '') ? `${k4idhere}` : ''
      }
    }
    downloadAsCSV(params);
  }  

  useEffect(() => {
    if(authReducer?.selectedOu && authReducer?.selectedOu?.name) {
      getVesselsListingForPrivate(authReducer?.selectedOu?.name,'','','')
    }
  },[authReducer?.selectedOu])


  useEffect(() => {
    if( authReducer?.privateVesselList?.locations) {
      if(authReducer?.privateVesselList?.locations?.length) {
        let k4ids = authReducer?.privateVesselList?.locations?.map(locObject => {
          return locObject
        })
         setPrivateVesselListing(k4ids);
       }
      }
  },[authReducer?.privateVesselList])

  return (
    <div className="Form">
      <Grid className="am-table inventory-am-table">
        <Grid className="deployedContainer">
            <Grid className="topbarWidth alignTopbarItemsCenter">
              <Grid item xs={6} sm={6} md={6} lg={6} className="searchbarMaxWidth1">
                <Box className="searchbarBox">
                  {<TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Type something"
                      size="small"
                      value={searchValue}
                      onChange={handleOnSearchChange}
                      onKeyDown={(event) => searchBoxKeyDown(event)}
                      InputLabelProps={{ className: "serachLabel" }}
                      InputProps={{
                          className: "searchbarInputs",
                          startAdornment: (
                              <InputAdornment position="start">
                                {!searchValue && <SearchIcon
                                  className="searchbarIconsPosition"
                                  onClick={handleOnSearchClick}
                                />}
                                {searchValue && <CloseIcon
                                  className="searchbarIconsPosition"
                                  onClick={handleOnSearchClear}
                                />}
                              </InputAdornment>
                          ),
                      }}
                  />}
                </Box>
              </Grid>
            {total > 0 && <Grid item xs={6} sm={6} md={6} lg={6} className="dwn-margin-auto">
              <div className="download-summary-btn marg-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
            </Grid>}
          </Grid>
          {
            <div className="menuScroll">
              <Box>
                <Pagination
                  rowsPerPageOptions={NEW_PER_PAGE_ITEMS}
                  count={total ? total : 0}
                  rowsPerPage={limit}
                  page={page + 1}
                  onPageChange={handlePagination}
                  onRowsPerPageChange={handlePerPageChange}
                />
              </Box>
              <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer deployed-container white-bg" component={Paper}>
                <Table aria-label="simple sticky table" stickyHeader>
                  <TableHead className="usageReports-tableHead">
                    <TableRow className="UsageReports-tableRow login-tableRow">
                      <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                        <span className="align-items-center">
                          <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, VESSEL_NAME)}>SITE</span>
                          <Grid className="sort-icon-margin">
                            <img src={sort === VESSEL_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                              className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, VESSEL_NAME, ASC_ORDER)} />
                            <img src={sort === VESSEL_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                              className="sortingImages" onClick={(event) => setSorting(event, VESSEL_NAME, DESC_ORDER)} />
                          </Grid>
                        </span>
                      </TableCell>
                      <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                        <span className=" align-items-center">
                          <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, GROUP_NAME)}>Organization</span>
                          <Grid className="sort-icon-margin">
                            <img src={sort === GROUP_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                              className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, GROUP_NAME, ASC_ORDER)} />
                            <img src={sort === GROUP_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                              className="sortingImages" onClick={(event) => setSorting(event, GROUP_NAME, DESC_ORDER)} />
                          </Grid>
                        </span>
                      </TableCell>
                      <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                        <span className=" align-items-center">
                          <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, DEVICE_NAME)}>Device Name</span>
                          <Grid className="sort-icon-margin">
                            <img src={sort === DEVICE_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                              className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, DEVICE_NAME, ASC_ORDER)} />
                            <img src={sort === DEVICE_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                              className="sortingImages" onClick={(event) => setSorting(event, DEVICE_NAME, DESC_ORDER)} />
                          </Grid>
                        </span>
                      </TableCell>
                      <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                        <span className=" align-items-center">
                          <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, CURRENT_EOS_VERSION)}>EOS Version</span>
                          <Grid className="sort-icon-margin">
                            <img src={sort === CURRENT_EOS_VERSION && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                              className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, CURRENT_EOS_VERSION, ASC_ORDER)} />
                            <img src={sort === CURRENT_EOS_VERSION && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                              className="sortingImages" onClick={(event) => setSorting(event, CURRENT_EOS_VERSION, DESC_ORDER)} />
                          </Grid>
                        </span>
                      </TableCell>
                      <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                        <span className=" align-items-center">
                          <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, PRODUCT_ID)}>DID</span>
                          <Grid className="sort-icon-margin">
                            <img src={sort === PRODUCT_ID && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                              className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, PRODUCT_ID, ASC_ORDER)} />
                            <img src={sort === PRODUCT_ID && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                              className="sortingImages" onClick={(event) => setSorting(event, PRODUCT_ID, DESC_ORDER)} />
                          </Grid>
                        </span>
                      </TableCell>
                      <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                        <span className=" align-items-center">
                          <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, LAST_SEEN)}>Last Seen</span>
                          <Grid className="sort-icon-margin">
                            <img src={sort === LAST_SEEN && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                              className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, LAST_SEEN, ASC_ORDER)} />
                            <img src={sort === LAST_SEEN && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                              className="sortingImages" onClick={(event) => setSorting(event, LAST_SEEN, DESC_ORDER)} />
                          </Grid>
                        </span>
                      </TableCell>
                      <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                        <span className=" align-items-center">
                          <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, REG_DATE)}>Reg. Date</span>
                          <Grid className="sort-icon-margin">
                            <img src={sort === REG_DATE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                              className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, REG_DATE, ASC_ORDER)} />
                            <img src={sort === REG_DATE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                              className="sortingImages" onClick={(event) => setSorting(event, REG_DATE, DESC_ORDER)} />
                          </Grid>
                        </span>
                      </TableCell>
                      <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                        <span className=" align-items-center">
                          <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, LICENSE_EXP_DATE)}>License Exp. Date</span>
                          <Grid className="sort-icon-margin">
                            <img src={sort === LICENSE_EXP_DATE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                              className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, LICENSE_EXP_DATE, ASC_ORDER)} />
                            <img src={sort === LICENSE_EXP_DATE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                              className="sortingImages" onClick={(event) => setSorting(event, LICENSE_EXP_DATE, DESC_ORDER)} />
                          </Grid>
                        </span>
                      </TableCell>
                      <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                        <span className=" align-items-center">
                          <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, UPTIME_SINCE)}>Uptime Since</span>
                          <Grid className="sort-icon-margin">
                            <img src={sort === UPTIME_SINCE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                              className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, UPTIME_SINCE, ASC_ORDER)} />
                            <img src={sort === UPTIME_SINCE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                              className="sortingImages" onClick={(event) => setSorting(event, UPTIME_SINCE, DESC_ORDER)} />
                          </Grid>
                        </span>
                      </TableCell>
                      <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                        <span className=" align-items-center">
                          <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, CONFIG_BACK_UP_CREATE_TIME)}>Saved Config</span>
                          <Grid className="sort-icon-margin">
                            <img src={sort === CONFIG_BACK_UP_CREATE_TIME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                              className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, CONFIG_BACK_UP_CREATE_TIME, ASC_ORDER)} />
                            <img src={sort === CONFIG_BACK_UP_CREATE_TIME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                              className="sortingImages" onClick={(event) => setSorting(event, CONFIG_BACK_UP_CREATE_TIME, DESC_ORDER)} />
                          </Grid>
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {inventoryDeployedData && 0 != inventoryDeployedData.length ? (
                    <TableBody className="tableBody usage-tableBody">
                      {
                        inventoryDeployedData && inventoryDeployedData.map((row, i) =>
                          <TableRow key={i} className="loginHistory-tableRow new-deployed-usage-tr">
                            <TableCell className="login-left-align inter-font  text-center CF-cell">
                                {row['name']}
                            </TableCell>
                            <TableCell className="login-left-align inter-font text-center CF-cell">
                              {row?.dpname}
                            </TableCell>
                            <Tooltip title={<span style={{ fontSize: "12px" }}>{row['deviceName']}</span>}>
                              <TableCell className="login-left-align inter-font text-center CF-cell inventory-ellipsis">{row['deviceName']}</TableCell>
                            </Tooltip>  
                            <TableCell className="login-left-align inter-font text-center CF-cell">
                              {row['current_EOS_version']}
                            </TableCell>
                            <TableCell className='login-left-align inter-font CF-cellt'>
                                {row['sn']}
                            </TableCell>
                            <Tooltip title={<span className="font-size-12px">{convertDateTimeIntoTimezone(row['last_seen'], authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</span>}>
                              <TableCell className="login-left-align inter-font text-center CF-cell">
                                {convertDateTimeIntoTimezone(row['last_seen'], authReducer.userTimezone, DEFAULT_DATE_FORMAT)}
                              </TableCell>
                            </Tooltip>
                            <TableCell className="login-left-align inter-font text-center noWrap CF-cell">
                              {convertDateTimeIntoTimezone(row['dateRegistered'], authReducer.userTimezone, DEFAULT_DATE_FORMAT)}
                            </TableCell>
                            {
                              !_.isEmpty(row?.tierAlias) && !_.isEmpty(row?.termDisplay)
                              ? <Tooltip title={<span style={{ fontSize: "12px" }}>{`License: ${row['tierAlias']}`}<br/>{`Term: ${row['termDisplay']}`}</span>}>
                                  <TableCell className='login-left-align inter-font text-center CF-cell'>
                                  {convertDateTimeIntoTimezone(row['license_expiry_date'], authReducer.userTimezone, DEFAULT_DATE_FORMAT)}
                                </TableCell>
                                </Tooltip>
                              : <TableCell className='login-left-align inter-font text-center CF-cell'>
                                  {convertDateTimeIntoTimezone(row['license_expiry_date'], authReducer.userTimezone, DEFAULT_DATE_FORMAT)}
                                </TableCell>
                            }
                            <Tooltip title={<span className="font-size-12px">{convertDateTimeIntoTimezone(row['uptime_date'], authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</span>}>
                              <TableCell className="login-left-align inter-font text-center CF-cell">
                                {convertDateTimeIntoTimezone(row['uptime_date'], authReducer.userTimezone, DEFAULT_DATE_FORMAT)}
                              </TableCell>
                            </Tooltip>
                            <Tooltip title={row['saved_config_file_name']}>
                              <TableCell className="login-left-align inter-font text-center noWrap CF-cell selectedClick">
                                  <span onClick={() => handleDOwnloadConfig(row)}>{convertDateTimeIntoTimezone(row['create_time'], authReducer.userTimezone, DEFAULT_DATE_FORMAT)}</span>
                              </TableCell>
                            </Tooltip>
                            
                          </TableRow>
                        )
                      }
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={12} className="noDataAvailableCss" align="center">No data available</TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          }
            {
              clickedActionIcon == MODIFY_CONFIG && modifyConfigData && modifyConfigData?.selectedSite && modifyConfigData?.selectedDevice && <ApplyConfig selectedDevice={modifyConfigData.selectedDevice} selectedSite={modifyConfigData.selectedSite} selectedSiteName={modifyConfigData.selectedSiteName} onClose={() => setModifyConfigData(undefined)} />
            }
            {
              clickedActionIcon == BACKUP_CONFIG_NOW && modifyConfigData && modifyConfigData?.selectedSite && modifyConfigData?.selectedDevice && <BackupConfigNow authReducer={authReducer} backupConfigNow={backupConfigNow} selectedDevice={modifyConfigData.selectedDevice} selectedSite={modifyConfigData.selectedSite} selectedSiteName={modifyConfigData.selectedSiteName} onClose={() => setModifyConfigData(undefined)} />
            }
            {
                clickedActionIcon == EOS_UPGRADE && modifyConfigData && modifyConfigData?.selectedSite && modifyConfigData?.selectedDevice && <SoftwareUpgrade authReducer={authReducer} selectedDevice={modifyConfigData.selectedDevice} selectedSiteId={modifyConfigData.selectedSite} selectedSiteName={modifyConfigData.selectedSiteName} currentEOSversion={modifyConfigData.currentEOSversion} latestEOSversion={modifyConfigData.latestEOSversion} onClose={() => setModifyConfigData(undefined)} />
            }
        </Grid>
        <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      </Grid>
      { changeGroupPopup ? <ChangeGroup selectedSiteName={selectedSiteName} open={changeGroupPopup} setOpen={setChangeGroupPopup} setOu={setOu} changeGroup={changeGroup} dpName={dpName} /> : null}
    </div>
  )
}

const ChangeGroup = ({selectedSiteName, open, setOpen, setOu, changeGroup, dpName}) => {

  return (
    <div className="Form">
        <Dialog 
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth={'md'}
            className="assignDialog subdp-create-assignDialog"
        >
            <div className="change-group-container">
                <DialogTitle className="dialog_title">
                    <Grid className='mainAssignActiontitle'>
                        <Grid className="AssignActiontitle change-group-title">Change Organization</Grid>
                        <Grid className="change-group-cancel-icon">
                            <img src={close} title="Close" onClick={() => setOpen(false)} className="dialog_closeIcon" />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className="changeDp-content">
                    <div className="tkt_new_ticket_dialogContent">
                        <Grid container spacing={2} className="changeDp-content-div">
                            <Grid className="selectedDeviceDetails change-group">
                                <Grid>Site: <span className='current-group-name'>{`${selectedSiteName}`}</span></Grid>
                            </Grid>
                            <Grid className="selectedDeviceDetails change-group">
                                <Grid>Current Organization: <span className='current-group-name'>{`${dpName}`}</span></Grid>
                            </Grid>
                            <Grid className="selectedDeviceDetails change-group">
                                <Grid>Set Organization</Grid>
                            </Grid>
                            <Grid className="change-group-dropdown">
                               <DP_Dropdown setOu={setOu} isReports= {false}/>
                            </Grid>
                        </Grid>
                    </div>
                </ DialogContent>
                <DialogActions className="change-group-actions">
                  <Button onClick={changeGroup}>Apply</Button>
                </DialogActions>
            </div>
        </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    isReadOnlyUser: state?.authReducer?.isReadOnlyUser
  });
  
  export default withRouter(
    connect(mapStateToProps, { getInventoryDeployedData, getInventoryDeployedDataCount, moveDeviceToWarehouse, deleteSite, inventoryDownloadConfig, clearInventoryConfigDownload, inventoryChangeGroup, backupConfigNow, updateUserOuHasSubOus, getDevicewiseLatestEOSversion, downloadAsCSV, rebootDevice , getVesselsListingForPrivate})(InventoryDeployedTablePrivate)
  );