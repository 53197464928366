import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { FormControl, FormControlLabel, Grid, IconButton, MenuItem, Select, Button } from "@mui/material";
import { getDateForChart, getDecodeURI, getEncodedURI, getMinutes, getSingleRecordChartLable, getTimezoneCity, readableBytesAsGB, readableBytesAsMB } from "../../../utils/util";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import TopSitesPie from "./captiveUsagePie";
import TopSubscribersPie from "./captiveSubsPie";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { getCaptiveDashSubscribersLineChart, getCaptiveDashSessionsLineChart, getCaptiveDashUsageLineChart } from "../../../actions/Users/authenticateCaptiveDahshboard";
import _ from "lodash";
import moment from "moment";

const CaptiveCharts = (props) => {
    const { authReducer, newSummaryStartDate, newSummaryEndDate, location, history, vessels, getCaptiveDashSubscribersLineChart, getCaptiveDashSessionsLineChart, getCaptiveDashUsageLineChart, getCDSitesSubscribersLineChart, getCDSitesSessionsLineChart, getCDSitesUsageLineChart, topcardSelected } = props;
    const _q = getDecodeURI(location?.search);
    const [subscribertsData, setSubscribertsData] = useState<any>([]);
    const [sessionsData, setSessionsData] = useState<any>([]);
    const [usageData, setUsageData] = useState<any>([]);
    const [chartView, setChartView] = useState('spline');
    const [selectTab, setSelectTab] = useState('Subscribers');

    const [resetDate, setResetDate] = useState<any>([]);
    const timeZone = getTimezoneCity(authReducer?.userTimezone);

    const getInterval = (minutes) => {
        if (minutes <= 720) {
            return "15 minutes";
        } else if (minutes > 720 && minutes <= 1440) {
            return "30 minutes";
        } else if (minutes > 1440 && minutes <= 10080) {
            return "3 hour";
        } else {
            return "12 hour";
        }
    }
    const [selectedBin, setSelectedBin] = useState(getInterval(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)));
    const [dataUnit, setDataUnit] = useState("GB");

    useEffect(() => {
        if(vessels?.length > 0) {
            selectTab == 'Subscribers' && getCaptiveDashSubscribersLineChart(vessels, newSummaryStartDate, newSummaryEndDate, selectedBin);
            selectTab == 'Sessions' && getCaptiveDashSessionsLineChart(vessels, newSummaryStartDate, newSummaryEndDate, selectedBin);
            selectTab == 'Usage' && getCaptiveDashUsageLineChart(vessels, newSummaryStartDate, newSummaryEndDate, selectedBin);
        } else {
            setSubscribertsData([])
            setSessionsData([])
            setUsageData([])
        }
    }, [vessels, newSummaryStartDate, newSummaryEndDate, selectTab])

    useEffect(() => {
        if (!_.isEmpty(getCDSitesSubscribersLineChart)) {
            const data = getCDSitesSubscribersLineChart?.data;
            if (data) {
                const chartData: any = data?.subscriberCount?.series && Object.keys(data?.subscriberCount?.series)?.map((key, i) => {
                    return { name: 'Subscribers', data: data?.subscriberCount?.series[key], marker: { symbol: 'circle' }, visible: true, color: '#2f2282' }
                })
                setSubscribertsData(chartData);
            }
        } else { setSubscribertsData([]) }
        if (!_.isEmpty(getCDSitesSessionsLineChart)) {
            const data = getCDSitesSessionsLineChart?.data;
            if (data) {
                const chartData: any = data?.sessionCount?.series && Object.keys(data?.sessionCount?.series)?.map((key, i) => {
                    return { name: 'Sessions', data: data?.sessionCount?.series[key], marker: { symbol: 'circle' }, visible: true, color: '#2f2282' }
                })
                setSessionsData(chartData);
            }
        } else { setSessionsData([]) }
        if (!_.isEmpty(getCDSitesUsageLineChart)) {
            const data = getCDSitesUsageLineChart?.data;
            if (data) {
                const series = data?.quotaUsed?.series || {};
                const seriesKeys = Object.keys(series);
                const gbValue = Math.pow(1000, 3);
                const isGBCrossed = seriesKeys.some(key => {
                    const seriesValue = series[key];
                    return (_.isArray(seriesValue) && seriesValue.some(item => item.y > gbValue))
                })
                const convertBytes = isGBCrossed ? readableBytesAsGB : readableBytesAsMB;
                const usage: any = seriesKeys.map(key => {
                    series[key]?.forEach((el, i) => {
                        series[key][i].y = Number(convertBytes(el.y, false));
                    })
                    return { name: 'Usage', data: series[key], marker: { symbol: 'circle' }, visible: true, color: '#2f2282' }
                })
                setDataUnit(isGBCrossed ? 'GB' : 'MB');
                setUsageData(usage);
            }
        } else { setUsageData([]) }
    }, [getCDSitesSubscribersLineChart, getCDSitesSessionsLineChart, getCDSitesUsageLineChart])

    const pinchAndResetChart = (start, end, interval) => {
        let params = getDecodeURI(location?.search);
        params.startDate = start.valueOf();
        params.endDate = end.valueOf();
        params.interval = interval;
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const chartAreaSelection = () => {
        return (event: any) => {
            let start: any = moment(new Date(Math.ceil(event.xAxis[0].min)));
            let end = moment(new Date(Math.floor(event.xAxis[0].max)));
            pinchAndResetChart(start, end, 'customDates');
            let arr: any = resetDate;
            arr.push({ startDate: start, endDate: end });
            setResetDate(arr);
            return false;
        }
    }

    const handleResetZoom = () => {
        if (resetDate?.length == 0) {
            return;
        }
        let arr: any = resetDate;
        arr.pop();
        setResetDate(arr);
        if (arr?.length == 0) {
            const start = moment(Date.now()).subtract(1, 'hour');
            const end = moment(Date.now());
            pinchAndResetChart(start, end, '1h');
        }
        else {
            let obj = arr[arr?.length - 1];
            let start = obj.startDate;
            let end = obj.endDate;
            pinchAndResetChart(start, end, 'customDates');
        }
    }

    const handleDefaultResetZoom = () => {
        setResetDate([]);
        const start = moment(Date.now()).subtract(1, 'hour');
        const end = moment(Date.now());
        pinchAndResetChart(start, end, '1h');
    }

    const barOptions: any = {
        time: {
            timezone: timeZone
        },
        chart: {
            type: chartView,
            height: '260px',
            zoomType: 'x',
            plotBorderWidth: 1,
            resetZoomButton: {
                theme: {
                    style: {
                        display: 'none'
                    }
                }
            },
            events: {
                selection: chartAreaSelection()
            },
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
        },
        title: {
            text: '',
            align: 'left',
            floating: true,
            x: 12,
            y: 32,
            style: {
                fontWeight: '500'
            }
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                turboThreshold: 1000000,
                stickyTracking: false,
                connectNulls: false,
            },
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                }
            },
            colorByPoint: true
        },
        tooltip: {
            valueSuffix: selectTab == 'Usage' && ` ${dataUnit}`,
            headerFormat: '',
            pointFormat: '<span style="color: #7e8089">{series.name}</span> <br/> <b style="color: #fff">{point.y}</b><br/>',
            valueDecimals: selectTab == 'Usage' && 2,
            borderWidth: 1,
            useHTML: true,
            borderRadius: 15,
            backgroundColor: '#060606',
        },
        xAxis: {
            gridLineWidth: 0.5,
            reversed: false,
            type: "datetime",
            maxPadding: 0.05,
            showLastLabel: true,
            labels: {
                style: {
                    color: '#2F4B82',
                    fontFamily: 'Inter',
                    fontSize: '9px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                },
                format: selectTab == 'Subscribers' && getDateForChart(subscribertsData) ? getSingleRecordChartLable(subscribertsData, timeZone)
                    : selectTab == 'Sessions' && getDateForChart(sessionsData) ? getSingleRecordChartLable(sessionsData, timeZone)
                        : selectTab == 'Usage' && getDateForChart(usageData) ? getSingleRecordChartLable(usageData, timeZone) : ''
            }
        },
        yAxis: {
            tickInterval: 1,
            gridLineWidth: 1,
            labels: {
                format: selectTab == 'Usage' && `{value} ${dataUnit}`,
                style: {
                    color: '#2F4B82',
                    fontFamily: 'Inter',
                    fontSize: '9px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                }
            },
            title: {
                text: selectTab == 'Usage' ? `Usage (${dataUnit})` : selectTab,
            }
        },
        series: selectTab == 'Subscribers' && !_.isEmpty(subscribertsData) ? subscribertsData : selectTab == 'Sessions' && !_.isEmpty(sessionsData) ? sessionsData : !_.isEmpty(usageData) ? usageData : [],
        colorByPoint: true
    }

    return (
        <div className="new-summary-chart-container charts-container-captive-dashboard" style={{ marginTop: '15px' }}>
            <Grid container spacing={2} className='new-summary-chart-component'>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={0} className="usage-per-site-pie">
                        <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5} className="pie-chart-summary">
                            <TopSitesPie vessels={vessels} topcardSelected={topcardSelected} />
                        </Grid>
                        <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5} className="pie-chart-summary">
                            <TopSubscribersPie vessels={vessels} topcardSelected={topcardSelected} />
                        </Grid>
                        {<Grid item xs={7} sm={7} md={7} lg={7}>
                            <Grid className={resetDate.length > 0 ? "legends-pie-new-container justify-content-tabs removeMarginTop" : "legends-pie-new-container justify-content-tabs"}>
                                <Grid className="usage-selectedTabs">
                                    <span>
                                        <Button className={selectTab == 'Subscribers' ? "usageActiveAppClass" : "usageInactiveAppClass"}
                                            onClick={() => {
                                                setSelectTab('Subscribers')
                                            }}
                                        >Subscribers</Button>
                                        <Button className={selectTab == 'Sessions' ? "usageActiveAppClass" : "usageInactiveAppClass"}
                                            onClick={() => {
                                                setSelectTab('Sessions')
                                            }}
                                        >Sessions</Button>
                                        <Button className={selectTab == 'Usage' ? "usageActiveAppClass" : "usageInactiveAppClass"} id='width-45px'
                                            onClick={() => {
                                                setSelectTab('Usage')
                                            }}
                                        >Usage</Button>
                                    </span>
                                </Grid>
                                <Grid className="time-series-legends-new">
                                    <Grid className="summary-pie-bar-legends">
                                        <div className="summary-pie-usage-dot" style={{ background: '#2f2282' }}></div>
                                        <span className="barChart-legends">{selectTab}</span>
                                    </Grid>
                                    {resetDate.length > 0 &&
                                        <div className="siteCharts__filters-reset-zoom resetZoom-usage">
                                            <div>
                                                <FormControlLabel
                                                    value="Zoom Back"
                                                    control={<IconButton onClick={handleResetZoom}><ZoomOutIcon /></IconButton>}
                                                    label=""
                                                    labelPlacement="start"
                                                />
                                                <FormControlLabel
                                                    value="Zoom Reset"
                                                    control={<IconButton onClick={handleDefaultResetZoom}><RestartAltIcon /></IconButton>}
                                                    label=""
                                                    labelPlacement="start"
                                                />
                                            </div>
                                        </div>}
                                </Grid>
                            </Grid>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={barOptions}
                            />
                        </Grid>
                        }

                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state?.authReducer,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    getCaptiveDashSubscribersLineChart: state?.authReducer?.getCaptiveDashSubscribersLineChart,
    getCDSitesSubscribersLineChart: state?.authReducer?.getCDSitesSubscribersLineChart,
    getCDSitesSessionsLineChart: state?.authReducer?.getCDSitesSessionsLineChart,
    getCDSitesUsageLineChart: state?.authReducer?.getCDSitesUsageLineChart,
});

export default withRouter(
    connect(mapStateToProps, { getCaptiveDashSubscribersLineChart, getCaptiveDashSessionsLineChart, getCaptiveDashUsageLineChart })(CaptiveCharts)
);