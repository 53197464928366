import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Chip, Stack, Tooltip } from "@mui/material";
import { getDecodeURI, getEncodedURI } from "../../utils/util";
import { getIamLoginHistoryTable, getIamLoginHistortTableCount, getIamLoginHistoryTopWidget, getIamLoginHistoryTimeseriesChart } from "../../actions/Users/authenticate";

const LoginCardFilters = (props) => {
    const { getIamLoginHistoryTable, getIamLoginHistortTableCount, getIamLoginHistoryTopWidget, getIamLoginHistoryTimeseriesChart, setValues, values, applyFilterClick, location, history } = props;
    const { setSelectedUsers, setSelectedApp, setSelectedDesc, setSelectedOu, setWidgetFilt, setPage } = setValues;
    const { startDate, endDate, searchValue, limit, sort, interval, sortOrder, selectedUsers, selectedApp, selectedDesc, selectedOu, trackFilter, widgetFilt } = values;
    let _q = getDecodeURI(location.search);

    const handleUserDelete = () => {
        setSelectedUsers([]);
        const users = [];
        applyFilterClick(users, selectedApp, selectedDesc, widgetFilt);
    }

    const handleAppDelete = () => {
        setSelectedApp([]);
        const apps = [];
        applyFilterClick(selectedUsers, apps, selectedDesc, widgetFilt);
    }

    const handleDescDelete = () => {
        setSelectedDesc([]);
        const desc = [];
        setWidgetFilt({type:'', check:'', kpi:''});
        let params: any = getDecodeURI(location.search);
        delete params.type;
        delete params.check;
        delete params.kpi;
        delete params.page;
        doNavigate(params);
        applyFilterClick(selectedUsers, selectedApp, desc, widgetFilt);
    }

    const handleDeleteOu = () => {
        setSelectedOu([]);
        let params: any = getDecodeURI(location.search);
        delete params.ouName;
        delete params.ouId;
        delete params.page;
        const Page = 1;
        setPage(1);
        doNavigate(params);
        getIamLoginHistoryTopWidget(startDate, endDate, searchValue, [], selectedUsers, selectedApp, selectedDesc, widgetFilt);
        getIamLoginHistoryTimeseriesChart(startDate, endDate, searchValue, interval, [], selectedUsers, selectedApp, selectedDesc, widgetFilt);
        getIamLoginHistoryTable(startDate, endDate, searchValue, Page, limit, sort, sortOrder, [], selectedUsers, selectedApp, selectedDesc, widgetFilt);
        getIamLoginHistortTableCount(startDate, endDate, searchValue, [], selectedUsers, selectedApp, selectedDesc, widgetFilt);
    }
 
    const handleCardFiltDelete = () => {
        setWidgetFilt({type:'', check:'', kpi:''});
        const filt = {type:'', check:'', kpi:''};
        let params: any = getDecodeURI(location.search);
        delete params.type;
        delete params.check;
        delete params.kpi;
        delete params.page;
        const Page = 1;
        setPage(1);
        doNavigate(params);
        getIamLoginHistoryTimeseriesChart(startDate, endDate, searchValue, interval, selectedOu, selectedUsers, selectedApp, selectedDesc, filt);
        getIamLoginHistoryTable(startDate, endDate, searchValue, Page, limit, sort, sortOrder, selectedOu, selectedUsers, selectedApp, selectedDesc, filt);
        getIamLoginHistortTableCount(startDate, endDate, searchValue, selectedOu, selectedUsers, selectedApp, selectedDesc, filt);
    }
    
    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const Event: any = {
        'tot_login' : {text : 'Total Logins'},
        'fail_login' : {text: 'Failed Logins'},
        'success_login' : {text: 'Success Logins'},
        'tot_logouts' : {text: 'Total Logouts'}
    }

    return (
        <Stack direction="row" spacing={1} alignItems='center'>
            {/* { selectedOu && _q.hasOwnProperty('ouName') && <Tooltip title='Group'><Chip label={_q.ouName} onDelete={handleDeleteOu} /></Tooltip> } */}
            { selectedUsers[0]?.user_name && trackFilter && <Tooltip title='User'><Chip label={selectedUsers[0]?.user_name} onDelete={handleUserDelete} /></Tooltip> }
            { selectedApp[0]?.application_name && trackFilter && <Tooltip title='Service'><Chip label={selectedApp[0]?.application_name} onDelete={handleAppDelete} /></Tooltip> }
            { selectedDesc[0]?.description && trackFilter && _q.hasOwnProperty('desc') ? <Tooltip title='Event Type'><Chip label={selectedDesc[0]?.description} onDelete={handleDescDelete} /></Tooltip> :
              widgetFilt?.kpi && _q.hasOwnProperty('kpi') ? <Tooltip title='Event Type'><Chip label={Event[widgetFilt.kpi]?.text} onDelete={handleCardFiltDelete} /></Tooltip> 
              : null }
        </Stack>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
  });
  
  export default withRouter(
    connect(mapStateToProps, { 
        getIamLoginHistoryTopWidget,
        getIamLoginHistoryTimeseriesChart,
        getIamLoginHistoryTable,
        getIamLoginHistortTableCount
     })(LoginCardFilters)
  )