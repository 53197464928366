import ContentCopy from "@mui/icons-material/ContentCopy";
import { Button, List, ListItem, Paper, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import copyToClipboard from 'copy-to-clipboard';
import { toast } from "react-toastify";


interface Props {
    id: string;
    metadata: string;
    before: string;
    after: string;
}

const splitJSON = (value: any) => {
    return JSON.stringify(value, undefined, 4).split(/\r?\n/);
}

export const AuditLogContent = ({ after, before, metadata, id }: Props) => {

    const [beforeJSON, setBeforeJSON] = useState<string[]>([]);
    const [afterJSON, setAfterJSON] = useState<string[]>([]);
    const [metadataJSON, setMetadataJSON] = useState<string[]>([]);

    useEffect(() => {
        setAfterJSON(splitJSON(after));
    }, [after]);

    useEffect(() => {
        setBeforeJSON(splitJSON(before));
    }, [before]);

    useEffect(() => {
        setMetadataJSON(splitJSON(metadata));
    }, [metadata]);

    const handleMetadataCopy = () => {
        copyToClipboard(metadataJSON.join('\r\n'));
        toast.success("Metadata copied successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
        })
    }

    const handleNewValueCopy = () => {
        copyToClipboard(afterJSON.join('\r\n'));
        toast.success("New value copied successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
        })
    }

    const handleOldValueCopy = () => {
        copyToClipboard(beforeJSON.join('\r\n'));
        toast.success("Old value copied successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
        })
    }

    return <List>
        <ListItem>
            <div className="audit-log-item">
                <Stack direction='row'>
                    <Typography className="filter-meta">Metadata</Typography>
                    <Typography sx={{ marginLeft: 'auto' }} className="filter-meta">ID: {id}  </Typography>
                </Stack>
                <Paper>
                    <div className="syntax-items">
                        {metadataJSON.map((row, index) => <div key={index} className="syntax-row">
                            <span className="syntax-line-item" />
                            <pre>{row}</pre>
                        </div>)
                        }
                        <div className="audit-syntax-copy">
                            <Button variant="contained" className="copy-audit-metas" size="small" onClick={handleMetadataCopy} startIcon={<ContentCopy className="copy-icon" />}>Copy</Button>
                        </div>
                    </div>
                </Paper>
            </div>
        </ListItem>
        <ListItem>
            <div className="audit-log-item">
                <Stack direction='row'>
                    <Typography className="filter-meta">New Value</Typography>
                </Stack>
                <Paper>
                    <div className="syntax-items">
                        {afterJSON.map((row, index) => <div key={index} className="syntax-row">
                            <span className="syntax-line-item" />
                            <pre>{row}</pre>
                        </div>)
                        }
                        <div className="audit-syntax-copy">
                            <Button variant="contained" size="small" onClick={handleNewValueCopy} className="copy-audit-metas" startIcon={<ContentCopy className="copy-icon" />}>Copy</Button>
                        </div>
                    </div>
                </Paper>
            </div>
        </ListItem>
        <ListItem>
            <div className="audit-log-item">
                <Stack direction='row'>
                    <Typography className="filter-meta">Old Value</Typography>
                </Stack>
                <Paper>
                    <div className="syntax-items">
                        {beforeJSON.map((row, index) => <div key={index} className="syntax-row">
                            <span className="syntax-line-item" />
                            <pre>{row}</pre>
                        </div>)
                        }
                        <div className="audit-syntax-copy">
                            <Button variant="contained" size="small" onClick={handleOldValueCopy} className="copy-audit-metas" startIcon={<ContentCopy className="copy-icon" />}>Copy</Button>
                        </div>
                    </div>
                </Paper>
            </div>
        </ListItem>
    </List>
}