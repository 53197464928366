import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect } from "react";
import { getCaptiveDashSitesSubscribersSessionsTopWidget, getCaptiveDashPlansTopWidget, getCaptiveDashUsageTopWidget } from "../../../actions/Users/authenticateCaptiveDahshboard";
import _ from "lodash";
import Card from "@mui/material/Card";
import { Grid, LinearProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import { getDecodeURI, getEncodedURI, readableBytesAsGBDecimals } from '../../../utils/util';
import { useHistory, useLocation } from 'react-router-dom';

const CaptiveTopWidget = (props) => {
  const { vessels, getCaptiveDashSitesSubscribersSessionsTopWidget, getCaptiveDashPlansTopWidget, getCaptiveDashUsageTopWidget, newSummaryStartDate, newSummaryEndDate, getCDSitesSubscribersSesTopWidget, getCDPlansTopWidget, getCDUsageTopWidget, location, topcardSelected, setTopCardSelected } = props;
  const _q = getDecodeURI(location?.search);

  useEffect(() => {
    if(vessels?.length > 0) {
      getCaptiveDashSitesSubscribersSessionsTopWidget(vessels, newSummaryStartDate, newSummaryEndDate);
      getCaptiveDashPlansTopWidget(vessels, newSummaryStartDate, newSummaryEndDate);
      getCaptiveDashUsageTopWidget(vessels, newSummaryStartDate, newSummaryEndDate);
    }
  }, [vessels, newSummaryStartDate, newSummaryEndDate])

  return (
    <Grid container columns={10} spacing={2}>
      {CardContents(`SITES ONLINE`, getCDSitesSubscribersSesTopWidget?.[0]?.activeSites, '', '', getCDSitesSubscribersSesTopWidget?.[0]?.TotalSites, "#2F4B82", '', '', 'Total Sites', ((getCDSitesSubscribersSesTopWidget?.[0]?.activeSites / getCDSitesSubscribersSesTopWidget?.[0]?.TotalSites) * 100), setTopCardSelected)}
      {(topcardSelected == 'SUBSCRIBERS' || topcardSelected == 'SITES ONLINE' || !topcardSelected) && CardContents(`SUBSCRIBERS`, getCDSitesSubscribersSesTopWidget?.[0]?.currentSubscribers, getCDSitesSubscribersSesTopWidget?.[0]?.typicalSubscribers, '', 'NA', "#d62728", 'Typical', '', 'New', 0, setTopCardSelected)}
      {(topcardSelected == 'SESSIONS' || topcardSelected == 'SITES ONLINE'  || !topcardSelected) && CardContents(`SESSIONS`, getCDSitesSubscribersSesTopWidget?.[0]?.currentSessions, getCDSitesSubscribersSesTopWidget?.[0]?.typicalSessions, '', 'NA', "#ff7f0e", 'Typical', '', 'Failed', 0, setTopCardSelected)}
      {(topcardSelected == 'PLANS' || topcardSelected == 'SITES ONLINE'  || !topcardSelected) && CardContents(`PLANS`, getCDPlansTopWidget?.[0]?.currentPlans, getCDPlansTopWidget?.[0]?.typicalPlans, 'NA', getCDPlansTopWidget?.[0]?.depletedPlans, "#ffbf00", 'Typical', 'Topups', 'Depleted', ((getCDPlansTopWidget?.[0]?.depletedPlans / (getCDPlansTopWidget?.[0]?.currentPlans)) * 100), setTopCardSelected)}
      {(topcardSelected == 'TOTAL USAGE' || topcardSelected == 'SITES ONLINE'  || !topcardSelected) && CardContents(`TOTAL USAGE`, readableBytesAsGBDecimals(getCDUsageTopWidget?.[0]?.current_quota_usage, true, 0), readableBytesAsGBDecimals(getCDUsageTopWidget?.[0]?.comparison_quota_usage, true, 0), '', readableBytesAsGBDecimals(getCDUsageTopWidget?.[0]?.last_24h_quota_usage, true, 0), "#1f77b4", 'Typical', '', '24 hrs ago', ((getCDUsageTopWidget?.[0]?.last_24h_quota_usage / (getCDUsageTopWidget?.[0]?.last_24h_quota_usage + getCDUsageTopWidget?.[0]?.current_quota_usage)) * 100), setTopCardSelected)}
    </Grid>
  )
}

const CardContents = (cardHeading, totalValue, leftBottomValue, middleBottomValue, rightBottomValue, borderColor, bottomLeftText, bottommiddleText, bottomRightText, progress, setTopCardSelected) => {
  const location = useLocation();
  const history = useHistory();

  const handleCardClick = (card) => {
    setTopCardSelected(card);
    let params = getDecodeURI(location?.search);
    params.topCard = card;
    delete params.page;
    history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
  }
  return (
    <Grid item xs={12} sm={6} md={2} lg={2} style={{ marginTop: '15px' }}>
      <Card className="card-widget"
        id={'addBoxShadow'} onClick={() => cardHeading != 'PLANS' && handleCardClick(cardHeading)}>
        <Box className="cardHeader">
          <Typography className="cardTitle">{cardHeading}</Typography>
        </Box>
        <Box className="cardContents">
          <Box className="cardActive">
            <div title={"count"} className="content">{totalValue ? totalValue : '-'}</div>
          </Box>
        </Box>
        <Box sx={{ margin: '0.25rem', marginLeft: '0.5rem', marginRight: '0.5rem' }}>
          {
            !_.isNaN(1) && !_.isNaN(1) ? <>
              <LinearProgress
                sx={{
                  "--LinearProgress-radius": "10px",
                  width: '100%',
                  height: '5px',
                  backgroundColor: '#ddd', // Background color of the progress track
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: borderColor, // Custom color for the progress bar itself
                  },
                }}
                variant="determinate"
                value={progress} // Progress at 70%
              />
              <div className="advance-dashboard-card-css">
                <div>{`${bottomLeftText} `}<span style={{ fontWeight: '600' }}>{leftBottomValue}</span></div>
                {bottommiddleText && <div >{`${bottommiddleText} `} <span style={{ fontWeight: '600' }}>{middleBottomValue}</span></div>}
                <div >{`${bottomRightText} `}<span style={{ fontWeight: '600' }}>{rightBottomValue}</span></div>
              </div>
            </> : null
          }
        </Box>
      </Card>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
  newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
  getCDSitesSubscribersSesTopWidget: state?.authReducer?.getCDSitesSubscribersSesTopWidget?.data,
  getCDPlansTopWidget: state?.authReducer?.getCDPlansTopWidget?.data,
  getCDUsageTopWidget: state?.authReducer?.getCDUsageTopWidget?.data,
});

export default withRouter(
  connect(mapStateToProps, { getCaptiveDashSitesSubscribersSessionsTopWidget, getCaptiveDashPlansTopWidget, getCaptiveDashUsageTopWidget })(CaptiveTopWidget)
);