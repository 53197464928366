import { MapContainer, Polygon, TileLayer, Marker, Popup, Polyline } from "react-leaflet";
import './mapBox.css';

const MapIncluder = (props: any) => {
  const { isDark,isOpeStreet } = props;
  let VITE_USERNAME = "ravieticala";
  let VITE_ACCESS_TOKEN = "pk.eyJ1IjoicmF2aWV0aWNhbGEiLCJhIjoiY2t5MGZra2Q5MDBuZTJ2bnJlYmFlZXlvYiJ9.fshbna7GKnaQq2GbY7_q7g";
  let VITE_STYLE_ID = "cl09x552100e015oi9ib0k7ig";
  return (
     isOpeStreet ? <TileLayer
     attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
         url={`https://api.mapbox.com/styles/v1/${VITE_USERNAME}/${VITE_STYLE_ID}/tiles/256/{z}/{x}/{y}@2x?access_token=${VITE_ACCESS_TOKEN}`}
     maxZoom={19}
   />: <TileLayer
   attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
   url={isDark ? "https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png" : "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"}
   maxZoom={19}
 /> 
  );
}
export default MapIncluder;