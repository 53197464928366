import _ from "lodash";
import { api } from "../../server/request";
import { Category, AllowedDomain, BlockedDomain, Domain } from "./types";

const ACTION_TYPES = {
    SET_LOADING: "contentFilter/setLoading",
    SET_DP_IDS: "contentFilter/setDpIds",

    GET_CONTENT_FILTERS: "contentFilter/getContentFilters",
    SET_CONTENT_FILTER_COUNT: "contentFilter/setContentFilterCount"
}

const initialState = {
    dpIds: [],

    contentFilters: [],
    totalContentFilters: 0,
    gettingContentFilters: false,
    creatingContentFilter: false,
    updatingContentFilter: false,
    deletingContentFilter: false,   
}

export function contentFilterReducer(state: any = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.SET_DP_IDS: {
            return {
                ...state,
                dpIds: action.payload
            }
        }
        case ACTION_TYPES.GET_CONTENT_FILTERS: {
            // console.log("DBG: action.payload", action.payload);
            return {
                ...state,
                contentFilters: action.payload?.data ? action.payload.data : [],
            }
        }
        case ACTION_TYPES.SET_LOADING: {
            return {
                ...state,
                ...action.payload
            }
        }
        case ACTION_TYPES.SET_CONTENT_FILTER_COUNT: {
            return {
                ...state,
                totalContentFilters: action.payload
            }
        }
        default:
            return state
    }
}

export const setLoading = (loading: boolean) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: loading })
}

export const setDpIds = (dpIds: string[]) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_DP_IDS, payload: dpIds })
}

export interface GetContentFiltersParameters {
    dp_id: string;
    // content_filter_id: string[];
    // name: string[];
    search: string;
    sort_by: string;
    sort_order: string;
    page: number;
    size: number;
}

export const getContentFilters = (parameters: GetContentFiltersParameters) => async (dispatch) => {
    if (_.isEmpty(parameters?.dp_id)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingContentFilters: true } });
    try {
        let payload = {
            "query": "GET_CF_DASHBOARD",
            "named": true,
            "parameters": {
              "dp_id":parameters.dp_id,
              "sortBy": parameters.sort_by,
              "sortOrder": parameters.sort_order,
              "pageLimit": parameters.size,
              "pageStart": ((parameters.page - 1) * parameters.size)
            },
            "format": "csv"
        }
        let res = await api.postRequestOut(`/store/pg/query`, payload)
        dispatch({ type: ACTION_TYPES.GET_CONTENT_FILTERS, payload: res});
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingContentFilters: false } });
        return error;
    }
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingContentFilters: false } });
}

export interface CreateContentFilterParameters {
    dp_id: string;
    name: string;
    description: string;
    categories: Category[];
    allowed_domains: Domain[];
    blocked_domains: Domain[];
    enabled:boolean;
}

export const createContentFilter = (parameters: CreateContentFilterParameters, abortSignal: AbortSignal | undefined) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingContentFilter: true } });
    const data = {
        name: parameters.name,
        description: parameters.description,
        categories: parameters.categories,
        allowed_domains: parameters.allowed_domains,
        blocked_domains: parameters.blocked_domains,
        enabled: parameters.enabled,
    }
    
    try {
        let res = await api.siteController.post(`/v1/dps/${parameters.dp_id}/content_filters`, data, {}, abortSignal)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingContentFilter: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingContentFilter: false } });
        return error;
    }
}

export interface UpdateContentFilterParameters {
    dp_id: string;
    id: string;
    name: string;
    description: string;
    categories: Category[];
    allowed_domains: Domain[];
    blocked_domains: Domain[];
    enabled: boolean;
}

export const updateContentFilter = (parameters: UpdateContentFilterParameters) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingContentFilter: true } });
    const data = {
        name: parameters.name,
        description: parameters.description,
        categories: parameters.categories,
        allowed_domains: parameters.allowed_domains,
        blocked_domains: parameters.blocked_domains,
        enabled: parameters.enabled,
    }
    
    try {
        let res = await api.siteController.put(`/v1/dps/${parameters.dp_id}/content_filters/${parameters.id}`, data)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingContentFilter: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingContentFilter: false } });
        return null;
    }
}

export interface DeleteContentFilterParameters {
    dp_id: string;
    id: string;
}

export const deleteContentFilter = (parameters: DeleteContentFilterParameters) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { deletingContentFilter: true } });
    try {
        let res = await api.siteController.delete(`/v1/dps/${parameters.dp_id}/content_filters/${parameters.id}`)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { deletingContentFilter: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { deletingContentFilter: false } });
        return null;
    }
}

export const clearComponentData = () => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_DP_IDS, payload: [] })
}