import { api, postRequestNormal } from "../../server/request";

import { handleResponse, handleError } from "../responseUtil";

import { KAPTURE_DOWNLOAD_URL, KAPTUREV2_URL } from '../../config';

import {

    SET_LOADING,

    GET_SIM_BOX,

    GET_SIM_BOXES,
    GET_SIM_BOXES_COUNT,

    GET_LTE_PROVIDERS,

    GET_DATA_USAGE,
    GET_DATA_USAGE_COUNT,
    GET_DAILY_DATA_USAGE,

    GET_GRAPHICAL_DATA_USAGE,
    SET_CELLULAR_DATA_USAGE_LOADING,
    GET_CELLULAR_DATA_USAGE,

    EXPORT_DATA_USAGE_REPORT,

    GET_USAGE_VESSEL_LIST,
    GRAPHICAL_DATA_USAGE_LOADING,
    GET_SIM_BOXES_LOADING,

    GET_FIREALL_WIDGETS,
    GET_FIREWALL_ACTIONS_TODAY_GRAPH,
    GET_FIREWALL_RULE_NAME_GRAPH,
    GET_FIREWALL_TABLE_DATA,
    GET_FIREWALL_RULE_NAME_FILTER,
    GET_FIREWALL_TABLE_DATA_COUNT,
    GET_FIREWALL_LINE_CHART,
    SET_LOADING_FIREWALL_COUNTS,
    SET_LOADING_FIREWALL_LINECHART,
    SET_LOADING_FIREWALL_PIE_RULENAME,
    SET_LOADING_FIREWALL_RISTRICT_PIECHART,
    SET_LOADING_FIREWALL_RULE_FILTER,
    SET_LOADING_FIREWALL_TABLE_DATA,
    SET_LOADING_FIREWALL_WIDGETS,
    CREATE_DOWNLOAD_SERVICE_FIREWALL,
    CREATE_DOWNLOAD_SERVICE_CONTENT_FILTER

} from "../types";

import { Moment } from "moment-timezone";
import { GET_ERRORS } from "../error_types";

export const getBoxes = () => (dispatch) => {

 

    dispatch({ type: SET_LOADING, payload: true });

    api

        .getRequestForExternalApi(`${KAPTURE_DOWNLOAD_URL}simBox/getBoxByK4Id?k4Id=K4-0004&boxId=K100`)

        .then((res) => handleResponse(res, dispatch, GET_SIM_BOX))

        .catch((err) => handleError(err, dispatch));

};

 

export const getLteProviders = () => (dispatch) => {

 

    dispatch({ type: SET_LOADING, payload: true });

    api

        .getRequestForExternalApi(`${KAPTURE_DOWNLOAD_URL}lteProvider/getProviders`)

        .then((res) => handleResponse(res, dispatch, GET_LTE_PROVIDERS))

        .catch((err) => handleError(err, dispatch));

};

export const getDataUsage = (limit, page, year, month, dp, vessels) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    const vesselMap: any = [];
     vessels?.map((item: any) => {
    let vesselName = item["id"];
    vesselMap.push(vesselName);
  })  
  let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;

    const dataUsage : any = {
        query: 'REPORTS_DATA_USAGE_ALL_VESSEL',
        named: true,
        parameters: {
            f6_limit_addf5: (page*limit),
            f5_pageStart: (page-1)*limit,
            f4_year: year,
            f3_month: month,
            f2_fullName: dp,
            f1_k4id: (k4idhere && k4idhere !== '') ? `${k4idhere}` : ''
        },
        format: 'csv',
        groupBy: "vessel_name"
    }
    api
    .postRequestOut(`/store/ch/query`, dataUsage)
    .then((res) => handleResponse(res, dispatch, GET_DATA_USAGE))
    .catch((err) => handleError(err, dispatch));
    };

export const getDataUsageCount = (dp, vessels) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    const vesselMap: any = [];
        vessels?.map((item: any) => {
    let vesselName = item["id"];
    vesselMap.push(vesselName);
    })  
    let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;

    const dataUsageCount : any = {
        query: 'REPORTS_DATA_USAGE_ALL_VESSEL_COUNT',
        named: true,
        parameters: {
            f2_fullName: dp,
            f1_k4id: (k4idhere && k4idhere !== '') ? `${k4idhere}` : ''
        },
        format: 'csv'
    }
    api
    .postRequestOut(`/store/ch/query`, dataUsageCount)
    .then((res) => handleResponse(res, dispatch, GET_DATA_USAGE_COUNT))
    .catch((err) => handleError(err, dispatch));
    };
 
export const getDailyDataUsage = (payload) => (dispatch) => {
    const {year, month, dp, k4Id} = payload;
    dispatch({ type: SET_LOADING, payload: true });

    const dailyDataUsage : any = {
        query: 'REPORTS_DATA_USAGE_PER_VESSEL',
        named: true,
        parameters: {
            f4_year: year,
            f3_month: month,
            f2_fullName: dp,
            f1_k4id: `'${k4Id}'`
        },
        format: 'csv',
        groupBy: 'snum'
    }
    api
    .postRequestOut(`/store/ss/query`, dailyDataUsage)
    .then((res) => handleResponse(res, dispatch, GET_DAILY_DATA_USAGE))
    .catch((err) => handleError(err, dispatch));
    };
 

export const getUsageVesselList = () => (dispatch) => {

 

    dispatch({ type: SET_LOADING, payload: true });

    api

        .getRequestForExternalApi(`${KAPTURE_DOWNLOAD_URL}simUsage/getActiveVessels`)

        .then((res) => handleResponse(res, dispatch,  GET_USAGE_VESSEL_LIST))

        .catch((err) => handleError(err, dispatch));

};

 

export const exportDataUsageReport = (payload) => (dispatch) => {

 

    dispatch({ type: SET_LOADING, payload: true });

    api

        .postRequestForExternalApi(`${KAPTURE_DOWNLOAD_URL}invoices/exportSimUsageOverall`,payload)

        .then((res) => handleResponse(res, dispatch, EXPORT_DATA_USAGE_REPORT))

        .catch((err) => handleError(err, dispatch));

};

export interface SimboxPayload {
    limit: number,
    page: number,
    search: string,
    dp: string,
    vessels?: any[];
  }

export const getSimBoxes = (payload: SimboxPayload) => (dispatch) => {
    let isClickHouse = true
    const apiUrl =  isClickHouse ? `/store/ch/query` : `/store/ss/query`;
    const { limit, page, search, dp, vessels } = payload;
    dispatch({ type: GET_SIM_BOXES_LOADING, payload: true });
    const vesselMap: any = [];
    vessels?.map((item: any) => {
        let vesselName = item["id"];
        vesselMap.push(vesselName);
    })
    let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;

    const simAnalysisData: any = {
        query: 'REPORTS_SIM_ANALYSIS_DASHBAORD',
        named: true,
        parameters: {
            f5_limit_addf4: limit + (page * limit),
            f4_pageStart: page * limit,
            f3_search: search,
            f2_fullName: dp,
            f1_k4id: (k4idhere && k4idhere !== '') ? `${k4idhere}` : ''
        },
        format: 'csv',
        groupBy: "boxId"
    }

    api
        .postRequestOut(`${apiUrl}`, simAnalysisData)
        .then((res) => {
            const simAnalysisDataCount: any = {
                query: 'REPORTS_SIM_ANALYSIS_DASHBAORD_COUNT',
                named: true,
                parameters: {
                    f5_limit_addf4: limit + (page * limit),
                    f4_pageStart: page * limit,
                    f3_search: search,
                    f2_fullName: dp,
                    f1_k4id: (k4idhere && k4idhere !== '') ? `${k4idhere}` : ''
                },
                format: 'csv'
            }
            api
                .postRequestOut(`${apiUrl}`, simAnalysisDataCount)
                .then((resCount) => {
                    handleResponse(resCount, dispatch, GET_SIM_BOXES_COUNT)
                    handleResponse(res, dispatch, GET_SIM_BOXES);
                })
                .catch((err) => handleError(err, dispatch));
        })
        .catch((err) => handleError(err, dispatch));
};

export interface FirewallReportData {
    query: string;
    named?: boolean;
    format?: string;
    parameters: FirewallReportPayload
}

export interface FirewallTableDataPayload extends FirewallReportPayload {
    pageStart: number;
    pageLimit: number;
}

export interface FirewallReportPayload {
    k4Id: string,
    dp: string,
    startDate: Moment;
    endDate: Moment;
    ruleName: string;
    activity: string;
}

export interface FirewallReportLineChartPayload {
    k4Id: string,
    dp: string,
    startDate: Moment;
    endDate: Moment;
    ruleName: string;
    activity: string;
    interval: string;
}

export const getFirewallWidgets = (payload: FirewallReportPayload) => (dispatch) => {
    // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_LOADING_FIREWALL_WIDGETS, payload: true });
    const data: FirewallReportData = {
        query: 'FIREWALL_REPORTS_TOP_WIDGETS',
        named: true,
        format: 'csv',
        parameters: payload
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => handleResponse(res, dispatch, GET_FIREALL_WIDGETS))
        .catch((err) => handleError(err, dispatch));
};

export const getFirewallActionTodayGraph = (payload: FirewallReportPayload) => (dispatch) => {
    // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_LOADING_FIREWALL_RISTRICT_PIECHART, payload: true });
    const data: FirewallReportData = {
        query: 'FIREWALL_REPORTS_ACTIONS_PIE',
        named: true,
        parameters: payload,
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => handleResponse(res, dispatch, GET_FIREWALL_ACTIONS_TODAY_GRAPH))
        .catch((err) => handleError(err, dispatch));
};

export const getFirewallRuleNameGraph = (payload: FirewallReportPayload) => (dispatch) => {
    // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_LOADING_FIREWALL_PIE_RULENAME, payload: true });
    const data: FirewallReportData = {
        query: 'FIREWALL_REPORT_RULEWISE_PKT_PIE',
        named: true,
        parameters: payload,
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => handleResponse(res, dispatch, GET_FIREWALL_RULE_NAME_GRAPH))
        .catch((err) => handleError(err, dispatch));
};

export const getFirewallReportRuleFilter = (payload: FirewallReportPayload) => (dispatch) => {
    // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_LOADING_FIREWALL_RULE_FILTER, payload: true });
    const {ruleName: _, ...filterPayload} = payload;
    const data: FirewallReportData = {
        query: 'FIREWALL_REPORT_RULE_LIST_FILTER',
        named: true,
        parameters: {
            ruleName: '',
            ...filterPayload
        },
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => handleResponse(res, dispatch, GET_FIREWALL_RULE_NAME_FILTER))
        .catch((err) => handleError(err, dispatch));
};

export const getFirewallReportTableData = (payload: FirewallTableDataPayload) => (dispatch) => {
    // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_LOADING_FIREWALL_TABLE_DATA, payload: true });
    const data: FirewallReportData = {
        query: 'FIREWALL_REPORTS_BOTTOM_INTERFACE_TABLE',
        named: true,
        parameters: payload,
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => handleResponse(res, dispatch, GET_FIREWALL_TABLE_DATA))
        .catch((err) => handleError(err, dispatch));
};

export const getFirewallTableDataCount = (payload: FirewallTableDataPayload) => (dispatch) => {
    // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_LOADING_FIREWALL_COUNTS, payload: true });
    const data: FirewallReportData = {
        query: 'FIREWALL_REPORTS_BOTTOM_INTERFACE_TABLE_TOTAL',
        named: true,
        parameters: payload,
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => handleResponse(res, dispatch, GET_FIREWALL_TABLE_DATA_COUNT))
        .catch((err) => handleError(err, dispatch));
};

export const getFirewallLineChart = (payload: FirewallReportLineChartPayload) => (dispatch) => {
    // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    console.log("apiUrl",payload?.interval);
    let newInterval = payload?.interval;
    if(isClickHouse) {
        if(newInterval.includes('m')) {
            newInterval = newInterval.replace("m", " minute")
        } else if(newInterval.includes('h')) {
            newInterval = newInterval.replace("h"," hour")
        } else {
            newInterval = newInterval.replace("d", " day")
        }
    }

    let newPayload = {
        ...payload, 
        interval: newInterval  
      };
    
    dispatch({ type: SET_LOADING_FIREWALL_LINECHART, payload: true });
    const data: FirewallReportData = {
        query: 'FIREWALL_REPORTS_ACTION_TIMESERIES_LINE',
        named: true,
        parameters: newPayload,
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => handleResponse(res, dispatch, GET_FIREWALL_LINE_CHART))
        .catch((err) => handleError(err, dispatch));
};

export const createDownloadServiceFirewall = (dpId, payload) => (dispatch) => {
    api.siteController
        .post(`/v1/organizations/${dpId}/reports/firewall`, payload)
        .then((res) => handleResponse(res, dispatch, CREATE_DOWNLOAD_SERVICE_FIREWALL))
        .catch((err) => handleError(err, dispatch));
};

export const createDownloadServiceContentFilter = (dpId, payload) => (dispatch) => {
    api.siteController
        .post(`/v1/organizations/${dpId}/reports/content_filter`, payload)
        .then((res) => handleResponse(res, dispatch, CREATE_DOWNLOAD_SERVICE_CONTENT_FILTER))
        .catch((err) => handleError(err, dispatch));
};

interface QueryParametersBase {
    f1_k4id: string;
    f2_fullName: string;
    f3_day: string;
    f4_month: number;
    f5_year: number;
    f6_sp: string;
    f6_wan_type: string;
    interval: string;
    startDate: string;
    endDate: string;
    tablesize: string;
}

interface LTEQueryParameters extends QueryParametersBase {
}

export type QueryParameters = LTEQueryParameters ;

interface LTEQueryPayload {
    type: 'CELLULAR';
    parameters: Partial<LTEQueryParameters>;
}

interface VASTQueryPayload {
    type: 'VSAT';
    parameters: Partial<QueryParametersBase>;
}

interface StarLinkQueryPayload {
    type: 'STARLINK';
    parameters: Partial<QueryParametersBase>;
}

interface FBBQueryPayload {
    type: 'LBAND';
    parameters: Partial<QueryParametersBase>;
}

interface ETHERNETQueryPayload {
    type: 'ETHERNET';
    parameters: Partial<QueryParametersBase>;
}

interface WIFIQueryPayload {
    type: 'WIFI';
    parameters: Partial<QueryParametersBase>;
}

interface TOTALQueryPayload {
    type: 'TOTAL';
    parameters: Partial<QueryParametersBase>;
}

interface QueryPayload {
    "CELLULAR": LTEQueryPayload;
    "VSAT": VASTQueryPayload;
    "STARLINK": StarLinkQueryPayload
    "LBAND": FBBQueryPayload;
    "ETHERNET": ETHERNETQueryPayload;
    "WIFI": WIFIQueryPayload;
    "TOTAL": TOTALQueryPayload;
}

export type SIM_TYPE = keyof QueryPayload;

export type GetDataUsageType = (payload: QueryPayload[keyof QueryPayload]) => any;

interface ResData {
    status: number;
    data: any;
}

export const handleDataUsageResponse = (type: SIM_TYPE, res: ResData[], dispatch: any) => {
    if (res.every(r => r.status >= 200 && r.status <= 202)) {
        dispatch({
            type: GET_GRAPHICAL_DATA_USAGE, payload: {
                data: {
                    operatorChart: res[0]?.data?.data,
                    sitewiseChart: res[1]?.data?.data,
                    totalTopUsage: res[2]?.data?.data,
                    operatorChartLegends: res[3]?.data?.data
                },
                type
            }
        });
    } else {
        dispatch({ type: GET_ERRORS, payload: res.find(r => r.status < 200 || r.status > 202) });
    }
    dispatch({ type: GRAPHICAL_DATA_USAGE_LOADING, payload: false });
}

//GDU
export const getGraphicalDataUsage: GetDataUsageType = ({ type, parameters }) => {
    return async (dispatch) => {
        dispatch({ type: GRAPHICAL_DATA_USAGE_LOADING, payload: true });
        let queryParams = parameters;
        const { f6_wan_type = '', interval = '', tablesize = 0, ...requiredParams } = queryParams;
        let queryType = '';
        switch (type) {
            case 'CELLULAR':
                queryType = 'LTE';
                queryParams = { ...requiredParams, f6_wan_type }
                break;
            case 'VSAT':
                queryParams = { ...requiredParams, f6_wan_type };
                queryType = 'VSAT';
                break;
            case 'STARLINK':
                queryParams = { ...requiredParams, f6_wan_type };
                queryType = 'STARLINK';
                break;
            case 'LBAND':
                queryParams = { ...requiredParams, f6_wan_type };
                queryType = 'STARLINK';
                break;
            case 'ETHERNET':
                queryParams = { ...requiredParams, f6_wan_type };
                queryType = 'ETHERNET';
                break;
            case 'WIFI':
                queryParams = { ...requiredParams, f6_wan_type };
                queryType = 'WIFI';
                break;
            case 'TOTAL':
                queryParams = { ...requiredParams };
                queryType = 'TOTAL';
                break;
        }
        // let isClickHouse = (window.location.host.includes("devch") || window.location.host.includes("stagech") || window.location.host.includes("prodch"))
        let isClickHouse = true;
        const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
        let newInterval = interval;
        if(isClickHouse) {
            if(newInterval.includes('m')) {
                newInterval = newInterval.replace("m", " minute")
            } else {
                newInterval = newInterval.replace("h"," hour")
            }
        }
        try {
            const operatorChart = api
                .postRequest(`${apiUrl}`, {
                    query: queryType == 'TOTAL' ? 'REPORTS_GRAPHICAL_DATA_TOTAL_DATEWISE_CHART' : `REPORTS_GRAPHICAL_DATA_OPEARTOR_CHART`,
                    named: true,
                    format: "chart",
                    groupBy: "wan_type",
                    series: "wan_usage",
                    tsColumn: "ts",
                    parameters: { ...queryParams,interval: newInterval}
                });

            const operatorChartLegends = api
                .postRequest(`${apiUrl}`, {
                    query: queryType == 'TOTAL' ? 'REPORTS_GRAPHICAL_DATA_LEGEND_ALL_WAN_WISE' : `REPORTS_GRAPHICAL_DATA_LEGEND_PER_WAN_WISE`,
                    named: true,
                    format: 'csv',
                    parameters: { ...queryParams}
                });

            const siteChart = api
                .postRequest(`${apiUrl}`, {
                    query: queryType == 'TOTAL' ? `REPORTS_GRAPHICAL_DATA_USAGE_TOTAL_VESSEL_CHART` : 'REPORTS_GRAPHICAL_DATA_USAGE_VESSEL_CHART',
                    named: true,
                    format: "csv",
                    parameters: {...queryParams, f6_wan_type: queryType !== 'TOTAL' ? f6_wan_type : '', tablesize: tablesize != 'All' ? `and rn<=${tablesize}` : ''}
                });

            const TotalUsage = api
                .postRequest(`${apiUrl}`, {
                    query: `REPORTS_WAN_USAGE_TOP_WIDGET`,
                    named: true,
                    format: "csv",
                    parameters: {
                        ...requiredParams
                    }
                });
            const res = await Promise.all([
                operatorChart,
                siteChart,
                TotalUsage,
                operatorChartLegends
            ]);
            handleDataUsageResponse(type, res, dispatch)
        } catch (err) {
            handleError(err, dispatch);
        }
    };
}

export const handleCellularUsageResponse = (type: any, res: ResData[], dispatch: any) => {
    if (res.every(r => r.status >= 200 && r.status <= 202)) {
        dispatch({
            type: GET_CELLULAR_DATA_USAGE, payload: {
                data: {
                    operatorChart: res[0]?.data,
                    sitewiseChart: res[1]?.data,
                    totalLTE: res[2]?.data,
                    serviceProviders: res[3]?.data
                },
                type
            }
        });
    } else {
        dispatch({ type: GET_ERRORS, payload: res.find(r => r.status < 200 || r.status > 202) });
    }
    dispatch({ type: SET_CELLULAR_DATA_USAGE_LOADING, payload: false });
}

export const getCellularDataUsage: GetDataUsageType = ({ type, parameters }) => {
    let isClickHouse = true
        const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    return async (dispatch) => {
        dispatch({ type: SET_CELLULAR_DATA_USAGE_LOADING, payload: true });
        let queryParams = parameters;
        const { f6_sp = '', ...requiredParams } = queryParams;
        try {
            const operatorChart = api
                .postRequest(`${apiUrl}`, {
                    query: `REPORTS_GRAPHICAL_DATA_USAGE_LTE_OPEARTOR_CHART`,
                    named: true,
                    format: "csv",
                    parameters: queryParams,
                    groupBy: 'label'
                });
            const siteChart = api
                .postRequest(`${apiUrl}`, {
                    query: `REPORTS_GRAPHICAL_DATA_USAGE_LTE_VESSEL_CHART`,
                    named: true,
                    format: "csv",
                    parameters: queryParams,
                    groupBy: 'name'
                });
            const totalLTECount = api
                .postRequest(`${apiUrl}`, {
                    query: `REPORTS_GRAPHICAL_DATA_USAGE_LTE_TOTAL_VES_CRT`,
                    named: true,
                    format: "csv",
                    parameters: {
                        ...requiredParams,
                        f6_sp
                    }
                });
            let serviceProviders: Promise<ResData>;
                serviceProviders = api
                    .postRequest(`${apiUrl}`, {
                        query: `REPORTS_GRAPHICAL_DATA_USAGE_LTE_OPEARTOR_LIST`,
                        named: true,
                        format: "csv",
                        parameters: queryParams
                    });
            const res = await Promise.all([
                operatorChart,
                siteChart,
                totalLTECount,
                serviceProviders,
            ]);
            handleCellularUsageResponse(type, res, dispatch)
        } catch (err) {
            handleError(err, dispatch);
        }
    };
}