import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { getTopAppTopBlockedPieChart } from "../../../actions/Users/authenticateUsageReports";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import UsagePieChart from "../../UsagePieChart";
import { UsageContext } from "../../../UserScreen/UserData/UsageDataContext";

const AppAndBlockedPieChart = (props) => {
    const { TopAppTopBlockedPieChartData, getVesselsListing, appIcons, getPieChartAllSiteRisk, getPieChartSingleSiteRisk } = props;
    const {vessels} = useContext(UsageContext);
    const [pieChartUsage, setPieChartUsage] = useState<any>([]);
    const [blockedUsage, setBlockedUsage] = useState([]);
    const [riskScore, setRiskScore] = useState([]);
    const [riskScorePerSite, setRiskScorePerSite] = useState([]);

    useEffect(() => {
        if (getVesselsListing?.locations && getVesselsListing?.locations?.length == 0) {
            setPieChartUsage([])
            setBlockedUsage([])
            return
        }
        if (!_.isEmpty(TopAppTopBlockedPieChartData)) {
            const data = TopAppTopBlockedPieChartData.hasOwnProperty('data') ? TopAppTopBlockedPieChartData?.data : {};
            if (data) {
                let app: any = [];
                let blocked: any = [];
                data?.dataType?.data.forEach((el, i) => {
                    let obj: any = { x: "", y: 0, z: 0 }
                    if (el == "APP") {
                        obj.x = data?.usageFrom?.data[i];
                        obj.y = data?.PERCENT?.data[i];
                        obj.z = Number(((data?.usageTotal?.data[i]) / (1024 * 1024 * 1024)).toFixed(1));
                        app.push(obj)
                    } else {
                        obj.x = data?.usageFrom?.data[i];
                        obj.y = data?.PERCENT?.data[i];
                        obj.z = data?.usageTotal?.data[i];
                        blocked.push(obj)
                    }
                    obj.type = 'application'
                })                
                setPieChartUsage(app);
                setBlockedUsage(blocked);
            }
        }
    }, [TopAppTopBlockedPieChartData,getVesselsListing])

    useEffect(() => {
        if (getVesselsListing?.locations && getVesselsListing?.locations?.length == 0) {
            setRiskScore([])
            setRiskScorePerSite([])
            return
        }
       if(!_.isEmpty(getPieChartAllSiteRisk)) {
          const data = getPieChartAllSiteRisk.hasOwnProperty('data') ? getPieChartAllSiteRisk.data : [];
            const arr = data?.rows?.map((el, i) => {
              return { x: el[1], y: el[4], z: Math.round(el[2]), list: el[5], type: 'netify' }
            })
            setRiskScore(arr);
        }
       if(!_.isEmpty(getPieChartSingleSiteRisk)) {
          const data = getPieChartSingleSiteRisk.hasOwnProperty('data') ? getPieChartSingleSiteRisk.data : [];
            const arr = data?.rows?.map((el, i) => {
                return { x: el[1], y: el[4], z: Math.round(el[2]), list: el[5], type: 'netify', userCheck: el[6] }
            })
            setRiskScorePerSite(arr);
        }
    }, [getPieChartAllSiteRisk, getPieChartSingleSiteRisk])

    return <>
        <Grid item xs={12} sm={6} md={6} lg={3}>
            <UsagePieChart pieChartUsage={pieChartUsage} title="Top Applications" name="Usage" unit="GB" appIcons={appIcons}/>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
            <UsagePieChart pieChartUsage={blockedUsage} title="Top Applications Blocked" name="Hits" unit="" appIcons={appIcons}/>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
            <UsagePieChart pieChartUsage={vessels?.length == 1 ? riskScorePerSite : riskScore} title="Top Risk Score" header={vessels?.length == 1 ? 'User' : 'Site'} name="netify" unit=""/>
        </Grid>
    </>
}

const mapStateToProps = (state) => ({
    TopAppTopBlockedPieChartData: state.authReducer?.TopAppTopBlockedPieChartData,
    getPieChartAllSiteRisk: state.authReducer?.getPieChartAllSiteRisk,
    getPieChartSingleSiteRisk: state.authReducer?.getPieChartSingleSiteRisk,
    getVesselsListing: state?.authReducer?.getVesselsListing
});

export default withRouter(
    connect(mapStateToProps, {
    })(AppAndBlockedPieChart)
);