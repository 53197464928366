import React, { useState, useEffect, isValidElement } from "react";
import Chart from '../../components/Charts/chart';
import { connect, useDispatch } from 'react-redux';
import { fetchVesselKpiData, fetchVesselMetrics } from "../../actions/fetchData";
import ChartAdv from '../../components/Charts/HighChartsComp';
import Button from '@mui/material/Button';
import { Grid, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import './TowerVesselDetails.css';
import Table from '../../components/Table';
import CustomLoader from '../../../../../../src/components/Loader/CustomLoader';
import moment from "moment";
import CircularProgress from '@mui/material/CircularProgress';
import { start } from "repl";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getVesselRATE, getVesselRSSIandSINR, getVesselRTT } from "../../actions/singleStoreActions";
import { time } from "highcharts";
import MultiAxesChart from '../../components/Charts/MultiAxesChart';
import SiteCharts from '../../../../../../src/components/SiteCharts';
import { endsWith } from "lodash";

const ChartDetails = (props: any) => {
    const { selctedVessel, vesselName, hoveredStart, hoveredEnd, handleSelctedTrack, handleChartOnClose } = props;

    const handleChartAreaSelection = (start: number, end: number) => {
        handleSelctedTrack(start, end);
        return false;
    }

    const handleChartReset = () => {
        handleSelctedTrack("", "");
    }

    const handleChartClose = () => {
        handleChartOnClose();
        handleSelctedTrack("", "");
    }

    return (
        <div className="popUpchartContainer">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                <div className="hichartTitle">Performance Metrics</div>
                <div>
                    <IconButton onClick={handleChartClose}>
                        <CloseIcon className="closeIc" />
                    </IconButton>
                </div>
            </div>
            <div className="maps-vessel-charts-container">
                <SiteCharts selectedVessel={selctedVessel} selectedVesselName={vesselName} handleReset={handleChartReset} handleChartAreaSelection={handleChartAreaSelection} />
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({});

const ChartDetailsWithData = connect(
    mapStateToProps,
)(ChartDetails);

export default ChartDetailsWithData;


{/* <Accordion className="chartAccordions"
defaultExpanded={true}
>
    <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
    >
        <Typography className="accordionTittle">Mobile Networks</Typography>
    </AccordionSummary>
    <AccordionDetails>
        {/* <div className="hichartTitle">{rateChartOptions?.tittleText}</div> */}
        // {/* <div className="metricsTableInfo"> */}
        //     {<Table className="mobileNetTable" vesselMetricsInfo={vesselMetricsInfo} isModemEnabled={true}></Table>}
        // {/* </div> */}
//     </AccordionDetails>
// </Accordion> */}