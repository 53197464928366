import {  Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Box, Button, Grid, Menu } from "@mui/material";
import _ from "lodash";
import "./userData.css";
import UsageReportsDetails from "../../components/UsageReportsDetails";
import UsageReportFilters from "../../components/UsageReportFilters";
import UsageContextProvider from "./UsageDataContext";
import ActivityCardFilters from "./ActivityCardFilters";
import TuneIcon from '@mui/icons-material/Tune';
import close from "../../asset/image/close.svg";
import OuterDateRange from "./OuterDareRange";
import Download from '../../asset/image/DownloadUsage.svg';
import FiltersPopup from "./FiltersPopup";
import { getDecodeURI, getEncodedURI } from "../../utils/util";
import Reports from "./Reports";

const UserData = (props) => {
    const { location, history, isAuthorizedToViewBetaFeature, selectedVessels } = props;
    const [filterPopup, setFilterPopup] = useState(false);
    const [generateFilter, setGenerateFilter] = useState(false);
    const [reportsPopup, setReportsPopup] = useState(false);
    const [topUsersCount, setTopUsersCount] = useState(0);
    const [topSitesCount, setTopSitesCount] = useState(0);
    const [anchorElRef, setAnchorElRef] = useState<HTMLElement>();

    const handleOpenFilters = () => {
        setGenerateFilter(true);
        let params: any = getDecodeURI(location.search);
        params.filterClick = true;
        doNavigate(params);
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const filterPopClick = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorElRef(e.currentTarget)
        setFilterPopup(true);
    } 

  return (
    <UsageContextProvider>
        <div className="Form">
            <Grid className="am-table">
                <Box className='activityReports-Filter alignTopbarItemsCenter'>
                <Fragment>
                    <Grid item xs={10.5} sm={10.5} md={10.5} lg={10.5} className="filter-dateRange-container">
                        <Button variant="outlined" startIcon={<TuneIcon id="filterIcon"/>} onClick={filterPopClick} id="filterButton">
                            Filter
                        </Button>
                        <OuterDateRange setReportsPopup={setReportsPopup} setGenerateFilter={setGenerateFilter} isAuthorizedToViewBetaFeature={localStorage.getItem("isBetaUser") === "true"} vesselLength={selectedVessels?.length}/>
                        <ActivityCardFilters setReportsPopup={setReportsPopup} />
                    </Grid>
                    {<Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} className="download-reports-container">
                        <div className="download-reports-btn" onClick={handleOpenFilters}><Button>Generate Report</Button><img src={Download} alt="" /></div>
                    </Grid>}
                    <Menu disableEnforceFocus
                        onClose={() => setFilterPopup(false)}
                        open={filterPopup}
                        anchorEl={anchorElRef}
                        className="rc--filter_menu_container_reports"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Grid className="usageFilterComponent">
                            <UsageReportFilters  filterPopup={filterPopup} setFilterPopup={setFilterPopup} />
                        </Grid>     
                    </Menu>
                </Fragment>
                </Box>
                <Grid className="userDataContainer">

                    <Grid className="usageDataComponent">
                        <UsageReportsDetails topUsersCount={topUsersCount} setTopUsersCount={setTopUsersCount} topSitesCount={topSitesCount} setTopSitesCount={setTopSitesCount} />
                    </Grid>
                </Grid>
            </Grid>
            { generateFilter ? <FiltersPopup generateFilter={generateFilter} setGenerateFilter={setGenerateFilter} setOpen={setReportsPopup} topUsersCount={topUsersCount} topSitesCount={topSitesCount} /> : null}
            { reportsPopup ? <Reports open={reportsPopup} setOpen={setReportsPopup} setGenerateFilter={setGenerateFilter} /> : null}
       </div>
    </UsageContextProvider>
  )
}


const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    selectedVessels: state.authReducer?.filteredVessels
});

export default withRouter(
    connect(mapStateToProps, {

    })(UserData)
);