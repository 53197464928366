import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Chip from "@mui/material/Chip";
import {
    Grid, Dialog
  } from "@mui/material";
  import close from "../../asset/image/cross.svg";

const RolesList = (props) => {
    const {handleListRoleClose, listRolePopUp, listRoleData, viewServiceFunctionPermission, handleRolePopUp} = props;

return (
    <div className="Form">
        <Dialog onClose={handleListRoleClose} open={listRolePopUp} style={{ background: "rgba(38, 76, 134, 0.75)" }}>
            <DialogTitle style={{ fontWeight: 500, fontSize: "18px", maxWidth: "550px", background: "#264C86" }}>
            <Grid item container style={{ color: "#fff" }}>
                Role
                <Grid style={{ marginLeft: "auto" }}>
                <img src={close} onClick={handleListRoleClose} style={{ width: "16px", height: "16px", cursor: "pointer" }} />
                </Grid>
            </Grid>
            </DialogTitle>
            <DialogContent style={{ height: "max-content", color: "#273050", background: "rgba(245, 249, 255, 1)", paddingTop: "15px", maxWidth: "550px" }}>
                <div className="RolesList">
                    { listRoleData && listRoleData.map((row: any, idx) => (
                        <Chip label={row.name}
                        style={{ padding: "8px", display: "inline-block", fontSize: "10px", background: "#fff", border: "1px solid #264C86", cursor: "pointer" }}
                        onClick={() => {
                        if(viewServiceFunctionPermission){
                            handleRolePopUp(row.name);
                        }
                        }}
                        />
                    ))}
                </div>
            </DialogContent>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});
export default withRouter(
    connect(mapStateToProps, {
     
    })(RolesList)
);