import { Button, Divider, Grid } from "@mui/material";
import DP_Dropdown from "./DP_Dropdown";

const ChangeGroup = ({ setOpen, setOu, changeGroup, actionSelectedRow }) => {

    let ou = {
        "id": actionSelectedRow.k4DpId,
        "name": actionSelectedRow.dpName
    }

    return (
        <div className="edit-actions-overflow-hidden">
            <Grid container spacing={2} className="deploy-inventory-content EOS-version-div">
                <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy select-config-from org-change-10px-margintop" >
                    <span id="content-left-text">DID</span>
                    <Grid id='content-right-text'>{`${actionSelectedRow?.deviceId}`}</Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy select-config-from org-change-remove-margintop" >
                    <span id="content-left-text">Current Organization</span>
                    <Grid id='content-right-text'>{`${actionSelectedRow?.dpName}`}</Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy select-config-from org-change-remove-margintop" >
                    <span id="content-left-text">Set Organization</span>
                    <Grid id='content-right-text' className="change-org-dp-dropdown"><DP_Dropdown isReports={false} setOu={setOu} deviceOu={ou} /></Grid>
                </Grid>
            </Grid>
            <div className="edit-actions-confirm-btns">
                <Divider className="confirm-dialogue-new-divider" />
                <div className="confirm-dialogue-new-btns">
                    <Button className="confirm-dialogue-cancel" onClick={() => setOpen(false)}> Cancel </Button>
                    <Button className="confirm-dialogue-proceed" onClick={changeGroup}>Apply</Button>
                </div>
            </div>
        </div>
    )
}

export default ChangeGroup;