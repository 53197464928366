// Dialog Box for the Create new ticket of Ticketing Page Pop-up
import { useEffect, useState } from "react";
import {
    Button,
    Grid, TextField, Typography,
} from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getVesselPlans } from "../../actions/Users/authenticateService";
import {
    getTicketHistoryByTicketId, getTicketDetailsByTicketId,
    sentTicketEmail,
    searchTicketAssigneeByName, updateTicket, addAttachments, createNewTicket
} from "../../actions/Users/authenticateTicket";
import moment from "moment-timezone";
import _ from "lodash";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { updatePermission } from "../../actions/Users/authenticate";
import "./viewVesselNotes.css"
import CreateNewNote from "./CreateNewNote";
import { convertDateTimeIntoTimezone } from "../../utils/util";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants";


const ViewVesselNotes = (props) => {
    const { match, getTicketHistoryByTicketId, authReducer, updateTicket, updatePermission, createNewTicket, getVesselPlans, selectedVessel } = props;


    const [ticketId, setTicketId]: any = useState(null);
    const [k4id, setk4id]: any = useState(null);
    const [ticketHistory, setTicketHistory]: any = useState();
    const [ticketDetails, setTicketDetails]: any = useState([])
    const [subject, setSubject] = useState("")
    const [notesStatus, setNotesStatus] = useState("")
    const [openCreateNote, setOpenCreateNote] = useState(false)



    const servicePortal = SERVICE_FUNCTION_TO_FEATURE["view-tickets"] && SERVICE_FUNCTION_TO_FEATURE["view-tickets"]["service"]
        ? SERVICE_FUNCTION_TO_FEATURE["view-tickets"]["service"]
        : ""
    const viewTickets = SERVICE_FUNCTION_TO_FEATURE && SERVICE_FUNCTION_TO_FEATURE["view-tickets"] && SERVICE_FUNCTION_TO_FEATURE["view-tickets"]["function"]
        ? SERVICE_FUNCTION_TO_FEATURE["view-tickets"]["function"]
        : "";

    useEffect(() => {
        const info = {
            permission: {
                service: servicePortal,
                serviceFunction: viewTickets,
            },
            isMultiVessel: true,
            isSingleVessel: false,
            showOu: true,
            ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
        };
        updatePermission(info);
        if (ticketId) {

            getTicketHistoryByTicketId(ticketId, k4id);
        }
    }, [ticketId])




    useEffect(() => {
        setk4id(selectedVessel)
        let payload = {}
        payload["k4Id"] = [selectedVessel]
        getVesselPlans(payload, 0, 15);

    }, []);


    useEffect(() => {


        if (!_.isEmpty(authReducer.getTicketHistory) && authReducer.getTicketHistory.data) {
            setTicketHistory(authReducer.getTicketHistory.data);
            authReducer.getTicketHistory = {};
        }

        if (!_.isEmpty(authReducer.vesselPlans) && authReducer.vesselPlans.data) {
            let notes = authReducer.vesselPlans.data && authReducer.vesselPlans.data[0] && authReducer.vesselPlans.data[0].Notes
            if (notes && notes.length > 0) {
                setTicketId(notes[0].ticketId)
                setNotesStatus(notes[0].ticketStatus)
                setSubject(notes[0].ticketSubject)
                setTicketDetails(notes[0])
            }
            authReducer.vesselPlans = {};
        }

    }, [authReducer.getTicketHistory, authReducer.vesselPlans])

    const closeCreateTicketDialog = () => {

        setOpenCreateNote(false);

        let payload = {}
        payload["k4Id"] = [selectedVessel]
        getVesselPlans(payload, 0, 15);

        if (ticketId) {
            getTicketHistoryByTicketId(ticketId, selectedVessel);
        }

    }


    return (
        <div className="form">

            <Grid item xs={12} sm={12} md={12} lg={12} >
                <Grid className="noteGrid">
                    {notesStatus != "" ? notesStatus === "Active" ?
                        <Grid item className="subjectstyle" >
                            <Typography style={{ wordWrap: 'break-word' }} variant="h3" className="font15 bold width-80">
                                {subject}
                            </Typography>
                            <Button className="dialog_okButton" onClick={() => setOpenCreateNote(true)} >Edit Note</Button>
                        </Grid>
                        :
                        <Button className="dialog_okButton" onClick={() => setOpenCreateNote(true)} >Open Note</Button>
                        :
                        <Grid>
                            <Button className="dialog_okButton" onClick={() => setOpenCreateNote(true)}>Create Note</Button>
                            <div className="no-notes-div-per-site">No Notes</div>
                        </Grid>
                    }
                    {notesStatus != "" ?
                        <Grid item className="notePadding">
                            <Grid item className="flex justify-center">
                                <div className="notesHistory">
                                    <div
                                        className="font10 sectionStyle">
                                        <Typography className="textUpperCase fontBold notesHistoryForm">
                                            History
                                        </Typography>
                                    </div>

                                </div>
                            </Grid>

                            <Grid item>
                                {ticketHistory && ticketHistory.length > 0 ?
                                    ticketHistory.map((history) => {
                                        return (
                                            <div>
                                                <Grid container justifyContent={"space-between"} style={{ padding: "0px 20px" }}>
                                                    <Grid item className="flex">
                                                        <div className="t_border bullet" ></div>
                                                        <Typography variant="h3" className="font12 bold">
                                                            {convertDateTimeIntoTimezone(history.updateDate, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item style={{ color: "#676984" }}>
                                                        <Typography variant="h5" className="font12 transformUpper">
                                                            {history.fullName}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid style={{ padding: "5px 50px" }}>
                                                    <Typography variant="h4" style={{ fontSize: "12px", wordWrap: 'break-word', }}>
                                                        {history.updateMsg}
                                                    </Typography>
                                                </Grid>
                                            </div>
                                        )

                                    })
                                    : null
                                }

                            </Grid>



                        </Grid>
                        : null}

                </Grid>
            </Grid>

            {
                openCreateNote ? <CreateNewNote open={openCreateNote} selectedVessel={selectedVessel} ticketDetails={ticketDetails} closeCreateTicketDialog={() => { closeCreateTicketDialog() }} /> : null
            }

        </div>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});
export default withRouter(
    connect(mapStateToProps, {
        sentTicketEmail,
        addAttachments,
        updateTicket,
        getTicketHistoryByTicketId,
        getTicketDetailsByTicketId,
        searchTicketAssigneeByName,
        updatePermission,
        createNewTicket,
        getVesselPlans
    })(ViewVesselNotes)
);