import { Fragment, SyntheticEvent, useEffect, useState } from "react";
import { AutocompleteChangeDetails, AutocompleteChangeReason, Button, Divider, FormHelperText, Grid, TextField, Typography } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import OrgSelection from "../../OrgSelection";
import { createSubOrg } from "../slice";
import AddIcon from '@mui/icons-material/Add';
import { getAllServiceLines } from "../../StarlinkReports/slice";
import { ServiceLine } from "../../StarlinkReports/types";

interface ChangeSubOrgDialogProps {
    open: boolean;
    authReducer: any;
    errorReducer: any;
    starlinkCloud: any;
    starlinkAdmin: any;
    serviceLine: ServiceLine | null;
    serviceLines: ServiceLine[];

    onClose: () => void;
    onSave: (dpId: string, serviceLineNumbers: string[]) => void;
    createSubOrg: (parameters: any) => Promise<any>;
    getAllServiceLines: (parameters: any) => void;
    currentDpName: string;
}


function ChangeSubOrg(props: ChangeSubOrgDialogProps) {

    const { starlinkAdmin, open, serviceLine, serviceLines, onClose, starlinkCloud, onSave, createSubOrg, getAllServiceLines, currentDpName } = props;
    
    const [makeNewSubOrg, setMakeNewSubOrg] = useState<boolean>(false);
    const [newSubOrgName, setNewSubOrgName] = useState<string>('');
    const [selectedDp, setSelectedDp] = useState<any>(null);
    const [selectedServiceLines, setSelectedServiceLine] = useState<ServiceLine[]>([])
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [newSubOrgNameValidationError, setNewSubOrgNameValidationError] = useState<string>('');
    const [valid, setValid] = useState<boolean>(false);
    const [disableActions, setDisableActions] = useState<boolean>(false);
    
    const handleClose = () => {
        onClose();
    }
    
    const handleSave = () => {
        setErrorMessage('')
        setDisableActions(true)
        onSave(selectedDp?.id, selectedServiceLines?.map(serviceLine => serviceLine.serviceLineNumber))
    }
    
    const handleChangeDp = (ou: any) => {
        setSelectedDp(ou)
    }
    
    const handleChangeNewSubOrgName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewSubOrgName(event.target.value);
        if (event.target.value.match(/[^A-Za-z0-9\s]/)) {
            setNewSubOrgNameValidationError('Only alphanumeric characters are allowed');
        } else {
            setNewSubOrgNameValidationError('');
        }
    }
    
    const handleNewSubOrgInit = () => {
        setMakeNewSubOrg(true);
        setNewSubOrgName('');
    }
    
    const handleNewSubOrgCreationCancel = () => {
        setMakeNewSubOrg(false);
    }
    
    const handleChangeSelectedServiceLines = (event: SyntheticEvent<Element, Event>, value: unknown, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<unknown> | undefined) => {
        
        if (reason === "selectOption" || reason === "removeOption") {
            if ((value as ServiceLine[]).find(option => option.serviceLineNumber === "all")) {
                return setSelectedServiceLine(serviceLines);
            } else {
                return setSelectedServiceLine(value as ServiceLine[]);
            }
        } else if (reason === "clear") {
            setSelectedServiceLine([]);
        }
    }
    
    const handleNewSubOrgSave = async () => {
        setDisableActions(true);
        setErrorMessage('');
        
        if (!_.isEmpty(selectedDp)) {
            let res = await createSubOrg({
                DPName: newSubOrgName,
                DPFullName: newSubOrgName,
                parentName: selectedDp.name
            })
            let _level = selectedDp.level + 1;
            if (res?.status === 1) {
                setSelectedDp({
                    id: res?.data?.data?.dpId,
                    name: newSubOrgName,
                    level: _level,
                    hasChildren: false,
                })
            } else if (res?.response?.data?.message) {
                setErrorMessage(res?.response?.data?.message);
            } else {
                setErrorMessage('Failed to create Sub-Organization');
            }
        }
        setDisableActions(false);
        setMakeNewSubOrg(false);
    }
    
    useEffect(() => {
        if (open) {
            setSelectedDp(null)
            setErrorMessage('')
            setSelectedServiceLine(serviceLine !== null ? [serviceLine] : [])
            setDisableActions(false)
            setMakeNewSubOrg(false)
            setNewSubOrgName('')
        }
    }, [open, serviceLine]);
    
    useEffect(() => {
        setValid(
            selectedDp && selectedDp?.id !== ""
        )
    }, [selectedDp]);

    useEffect(() => {
        setDisableActions(
            starlinkAdmin.updatingServiceLine
        );
    }, [starlinkAdmin.updatingServiceLine]);

    useEffect(() => {
        if (!_.isEmpty(starlinkCloud.dpIds)) {
            getAllServiceLines({ dpIds: starlinkCloud.dpIds });
        }
    }, [starlinkCloud.dpIds]);

    return (
        <div className="Form starlink-admin-change-org">
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} className="config-apply-config-target" >
                    <span id="content-left-text">Current Organization:</span>
                    <Grid id='content-right-text'>
                        <div>{` ${currentDpName}`}</div>
                    </Grid>
                </Grid>
                <Grid item xs={5}>
                    <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                        <OrgSelection label={makeNewSubOrg ? "Parent Organization" : "New Sub-Organization"} value={selectedDp} onChange={handleChangeDp} disabled={disableActions} />
                    </Grid>
                    <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                        {
                            makeNewSubOrg ? <TextField
                                disabled={disableActions}
                                fullWidth
                                size="small"
                                id="starlink--service_account--sub_org_name"
                                label="Sub-Organization Name"
                                value={newSubOrgName}
                                onChange={handleChangeNewSubOrgName}
                                error={newSubOrgNameValidationError.length > 0}
                            /> : null
                        }
                        <FormHelperText error={newSubOrgNameValidationError?.length > 0}>
                            <Typography component="div" className="starlink--base--text_center starlink--base--font_08x">
                                {newSubOrgNameValidationError}
                            </Typography>
                        </FormHelperText>
                    </Grid>
                    <Grid classes={{ root: 'starlink--base--flex starlink--base--mtb_07x starlink--base--flex--justify--start' }}>
                        {
                            makeNewSubOrg ? <Fragment>
                                <Button size="small" variant="outlined" className='starlink--button--cancel' onClick={handleNewSubOrgCreationCancel} disabled={disableActions}>Cancel</Button>
                                <Button size="small" variant="contained" className='starlink--button_contained--primary' onClick={handleNewSubOrgSave} disabled={disableActions || newSubOrgNameValidationError.length > 0 || newSubOrgName.length === 0 || selectedDp === null}>Create</Button>
                            </Fragment> : <Button size="small" variant="contained" className='starlink--button_contained--primary create-org-btn-width' onClick={handleNewSubOrgInit} disabled={disableActions} startIcon={<AddIcon />}>Create Sub-Organization</Button>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <FormHelperText error={errorMessage?.length > 0}>
                    <Typography component="div" className="starlink--base--text_center starlink--base--font_08x">
                        {errorMessage}
                    </Typography>
                </FormHelperText>
            </Grid>
            <div className="edit-actions-confirm-btns starlink-admin-edit-actions-confirm-btns">
                <Divider className="confirm-dialogue-new-divider" />
                <div className="confirm-dialogue-new-btns">
                    <Button className="confirm-dialogue-cancel" onClick={handleClose}> Cancel </Button>
                    <Button className={!valid || disableActions || makeNewSubOrg ? 'confirm-dialogue-proceed-notAllow' : "confirm-dialogue-proceed"} onClick={() => {handleSave()}} disabled={!valid || disableActions || makeNewSubOrg}>Save</Button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    starlinkAdmin: state.starlinkAdmin,
    starlinkCloud: state.starlinkCloud,
    serviceLines: state.starlinkReports.allServiceLines,
});

export default withRouter(
    connect(mapStateToProps, {
        createSubOrg,
        getAllServiceLines
    })(ChangeSubOrg)
);
