import { useEffect, useState,CSSProperties } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Link from '@mui/material/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import onlineIcon from "../../asset/image/online.svg";
import offlineIcon from "../../asset/image/offline.svg";
import { Grid } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import './BreadCrumbs.css';
import { useLocation } from "react-router-dom";
import { removeQueryParamK4Ids } from '../../utils/util';
var Loader = require("react-loader");

const BreadCrumbsConatiner = (props) => {
  const { setBreadcrumbInfo, breadcrumbinfo, isVesselOnline, loading, authReducer, history } = props;
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([{ title: '' }]);
  const location = useLocation();

  const override: CSSProperties = {
    width: "10px !important",
     height: "10px !important",
  };

  useEffect(() => {
    if (breadcrumbinfo) {
      _setBreadcrumbs(breadcrumbinfo);
    }
    if (setBreadcrumbInfo) {
      setBreadcrumbInfo.current = _setBreadcrumbs;
    }
  }, []);

  useEffect(() => {
    if (authReducer?.selectedOu?.name && breadcrumbs && breadcrumbs.length > 0) {
      breadcrumbs[0] = { title: authReducer?.selectedOu.name };
    }
  }, [authReducer.selectedOu]);

  const _setBreadcrumbs = (_breadcrumbs: any[]) => {
    if (authReducer?.selectedOu?.name && _breadcrumbs && _breadcrumbs.length > 0) {
      _breadcrumbs[0] = { title: authReducer?.selectedOu.name };
    }
    setBreadcrumbs(_breadcrumbs);
  }

  return (
    <div className="breadcrumbs" style={authReducer?.getDrawerOpen ? {width: '93%'} : {width: '100%'}}>
      <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="medium" />}>
        {breadcrumbs.map((value, index) => {
          return (
            ((breadcrumbs.length - 1) === index ?
              <Link key={index} className="decortion currentPageName">
                {value.title}
              </Link>
              : (
                value.link && '' != value.link.trim() ?
                  <Link key={index} className="decortion pageName" href={value.link} onClick={()=>removeQueryParamK4Ids()}>
                    {value.title}
                  </Link>
                  :
                  value.hasOwnProperty("goBack") && value.goBack ?
                    <Link key={index} className="decortion pageName" style = {{cursor: "pointer"}} onClick={()=>history.goBack()}>
                      {value.title}
                    </Link>
                  :
                  <Link key={index} className="decortion pageName"  >
                    {value.title}
                  </Link>
              )
            )
          )
        })}
      </Breadcrumbs>
      {
        true === isVesselOnline ?
          (<Grid className="edgeStatus edgeOnline">
            <img src={onlineIcon} alt="Online" title="Edge Server Online" />
            <span style={{ width: "40px" }}>Online</span>
          </Grid>
          )
          : (false === isVesselOnline ?
            (<Grid className="edgeStatus edgeOffline">
              <img src={offlineIcon} alt="Offline" title="Edge Server Offline" />
              <span style={{ width: "40px" }}>Offline</span>
            </Grid>) : null)
      }
      {
        loading ? (
          <div className="commonLoader">
            <Loader radius={4} length={5}  lines={10} width={2} color={"#264C86"} />
          </div>
          
          ) : null
      }
    </div>

  )
}
const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {})(BreadCrumbsConatiner)
);
