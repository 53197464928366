import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Box, Button, Chip, Dialog, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete";
import close from "../../asset/image/close.svg";
import "./EditGroup.css";
import { getDeviceList, updateGroup } from "../../actions/Users/authenticateInventory";
import _ from "lodash";
import { GET_DEVICE_LIST } from "../../actions/types";

const EditGroup = (props) => {
    const { authReducer, editGroupPopup, setEditGroupPopup, selectedRow, getDeviceList, updateGroup } = props;

    const dispatch = useDispatch();
    const [groupName, setGroupName] = useState<string>(selectedRow["groupName"])
    const [sites, setSites] = useState<any[]>([])
    const [devices, setDevices] = useState<any[]>([])
    const [selectedDevices, setSelectedDevices] = useState<any[]>([])
    const [selectedOu, setSelectedOu] = useState<any>({
        "id": selectedRow["dpId"],
        "name": selectedRow["dpName"]
    })
    const [isAllSelected, setIsAllSelected] = useState(false)

    interface DeviceInterface {
        dpId: string;
        siteId: string;
        deviceId: string;
        isAllSelected: boolean
    }

    useEffect(() => {
        if (!_.isEmpty(selectedOu) && selectedOu.hasOwnProperty("name")) {
            let ouName = selectedOu.name
            clearData()
            getDeviceList(ouName)
        }
    }, [selectedOu])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getDeviceList) && authReducer.getDeviceList.hasOwnProperty("data")) {
            setSites(authReducer.getDeviceList.data)
            dispatch({ type: GET_DEVICE_LIST, payload: {}})
        }
    }, [authReducer.getDeviceList])

    useEffect(() => {
        if (sites.length > 0) {
            const deviceIdentifiers = new Set();
            const _devices: any = [];
            const _selectedDevices: any = []
            for (let s = 0; s < sites.length; s++) {
                let site = sites[s]
                if (!_.isEmpty(site) && site.hasOwnProperty("devices") && site.devices.length > 0) {
                    let siteDevices = site["devices"]
                    for (let d = 0; d < siteDevices.length; d++) {
                        let device = {}
                        deviceIdentifiers.add(siteDevices[d]["id"].slice(0, 2))
                        device["dpId"] = site["dp_id"]
                        device["dpName"] = site["dp_name"]
                        device["siteId"] = site["location_id"]
                        device["siteName"] = site["location_name"]
                        let deviceId = siteDevices[d]["id"]
                        device["deviceId"] = deviceId
                        device["deviceName"] = siteDevices[d]["name"]

                        if (selectedRow["devices"].find(d => d.id === deviceId)) {
                            _selectedDevices.push(device)
                        }
                        _devices.push(device)
                    }
                }
            }

            let diDevices: any = []
            // deviceIdentifiers.forEach(deviceIdentifier =>
            //     diDevices.push({
            //         "siteName": "ALL " + deviceIdentifier,
            //         "siteId": deviceIdentifier,
            //         "deviceId": deviceIdentifier,
            //         "deviceName": "ALL " + deviceIdentifier,
            //         "isAllSelected": true,
            //         "deviceIdentifier": deviceIdentifier
            //     })
            // )
            diDevices = diDevices.concat(_devices)
            setDevices(diDevices)
            setSelectedDevices(_selectedDevices)
        } else {
            setDevices([])
            setSelectedDevices([])
        }
    }, [sites])

    const clearData = () => {
        setSites([])
        setDevices([])
        setSelectedDevices([])
    }
    const closeDialog = () => {
        clearData()
        setEditGroupPopup(false)
    }

    const update = () => {
        let payload = {
            "id": selectedRow["groupId"],
            "name": groupName,
            "devices": selectedDevices
        }
        setEditGroupPopup(false)
        updateGroup(selectedRow["dpId"], payload)
    }

    const handleSelectedDevicesChange = (event: React.SyntheticEvent<Element, Event>, value: unknown, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<unknown> | undefined) => {
        if (reason === 'selectOption' || reason === 'removeOption') {
            let _value = value as DeviceInterface[];
            setSelectedDevices(_value);
            if (_value.length === 1) {
                if (_value[0].isAllSelected) {
                    // setIsAllSelected(true)
                } else {
                    // setIsAllSelected(false)
                }
            }
        } else if (reason === 'clear') {
            setSelectedDevices([]);
        }
    };
    return (
        <Dialog
            open={editGroupPopup}
            onClose={() => { closeDialog() }}
            aria-labelledby='form-dialog-title'
            maxWidth={'md'}
            className='create-default-config-container group-actions-tab-create-group-popup menuScroll'
        >
            <div>
                <DialogTitle className='create-default-config-title'>
                    <span>Edit Group</span>
                    <img src={close} title="Close" onClick={() => { closeDialog() }} />
                </DialogTitle>
                <DialogContent className='create-default-config-content'>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} className='name-input-default-config name-margin-top'>
                            <span>Name</span>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <Box>
                                    {<TextField
                                        variant="outlined"
                                        type="search"
                                        placeholder="Group Name"
                                        onChange={(event) => {
                                            setGroupName(event.target.value);
                                        }}
                                        value={groupName}
                                        InputProps={{
                                            autoComplete: 'none',
                                            className: "serachBar name-config-default group_name",
                                        }}

                                    />}
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} className='name-input-default-config create-config-table select_devices'>
                            <span>Devices</span>
                            <Autocomplete
                                freeSolo
                                multiple
                                limitTags={1}
                                disableCloseOnSelect={true}
                                value={[...selectedDevices.reverse()]}
                                options={devices}
                                getOptionLabel={(option: any) => option.deviceName}
                                getOptionDisabled={option => {
                                        if (selectedDevices.length > 0) {
                                            if (selectedDevices[0]['deviceId'].slice(0, 2) == option['deviceId'].slice(0, 2)) {
                                                return false
                                            }
                                            return true
                                        }
                                        return false
                                    }
                                }
                                renderOption={(props, option) => {
                                    const key = `listItem-${props["data-option-index"]}-${option.siteId + "_" + option.deviceId}`
                                    return (<li {...props} title={option.siteName} key={key}>{option.deviceName}</li>)
                                }}
                                onChange={handleSelectedDevicesChange}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" className={selectedDevices.length > 0 ? "search-or-select" : ""} placeholder="Search or Select" type="search" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="dialog-actions">
                            <Button variant="outlined" className={!_.isEmpty(groupName) && selectedDevices.length > 0 ? "create" : "create disable"} onClick={() => { update() }}>Update</Button>
                            <Button variant="outlined" onClick={() => { closeDialog() }} >Cancel</Button>
                        </Grid>
                    </Grid>
                </ DialogContent>
            </div>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {
        getDeviceList, updateGroup
    })(EditGroup)
);