import { fetchDataRequest, fetchDataSuccess, fetchTowerDetailsSuccess, fetchDataError, fetchVesselNameSuccess, fetchVesselVoyageSuccess, fetchVesselKpiDataSuccess, fetchCarrierDataSuccess, fetchCarrierDataInnerLayerSuccess, fetchVesselCurrentLocationSuccess, fetchVesselMetricsInfoSuccess, fetchUniqueCarrierSuccess, fetchVesselVoyageRequest, fetchVesselKpiDataRequest, fetchSelctedVesselVoyageSuccess, fetchHexSinrHistoSuccess, fetchHexRssiHistoSuccess, fetchHexSpeedHistoSuccess, fetchVesselCurrentLocationRequest, setApiErrors, deleteApiError, fetchVsatCoverageSuccess, fetchVsatCoverageInnerLayerDataSuccess, fetchVSATHistoSuccess, fetchVSATTxPowerHistoSuccess, fethcVSATAllHexMetricsSuccess, clearMapErros, fetchGetEdgeEventsSucesss } from "./action";
import { gql } from "@apollo/client";
import { print } from 'graphql/language/printer'


const LINK_H3_DATA = gql`
query ($getLinkAvailAggsVsat: Int!, $getLinkAvailAggsLte: Int!) {
  getLinkAvailAggs(vsat: $getLinkAvailAggsVsat, lte: $getLinkAvailAggsLte) {
    key
    doc_count
  }
}
`;

const TOWER_DETAILS = gql`
query GetTowers {
  getTowers {
    type
    properties {
      towerid
    }
    geometry {
      type
      coordinates
    }
  }
} 
`;

const GET_VESSEL_NAMES = gql`
query GetVoyageVessels($starttime: String!, $endtime: String!) {
  getVoyageVessels(starttime: $starttime, endtime: $endtime) {
    name
    points
  }
}
`;

const GET_VESSEL_VOYAGE = gql`
query GetVesselPath($locationid: String!, $starttime: String!, $endtime: String!, $interval: String!) {
  getVesselPath(locationid: $locationid, starttime: $starttime, endtime: $endtime, interval: $interval) {
    data
  }
}
`;

const VESSEL_KPI_DATA = gql`
query GetVoyageNewKpis($locationid: String!, $starttime: String!, $endtime: String!, $interval: String!) {
  getVoyageNewKpis(locationid: $locationid, starttime: $starttime, endtime: $endtime, interval: $interval) {
    data
  }
}
`;

const CARIER_DATA = gql`
query GetNetworksV3($operator: String!, $resolution: Int!, $layer: String!) {
  getNetworksV3(operator: $operator, resolution: $resolution, layer: $layer) {
    data
  }
}
`;

const VESSEL_CURRENT_LOCATION = gql`
query GetVesselLastLocAuth($vesselist: [String], $onlyExternal: Boolean, $locationIDlist: [String], $ouName: String) {
  getVesselLastLocAuth(vesselist: $vesselist, onlyExternal: $onlyExternal, locationIDlist: $locationIDlist, ouName: $ouName) {
    data
  }
}
`

const VESSEL_METRICS_INFO = gql`
query GetVoyageMetaData($locationid: String!, $starttime: String!, $endtime: String!) {
  getVoyageMetaData(locationid: $locationid, starttime: $starttime, endtime: $endtime) {
    name
    starttime
    endtime
    modems {
      modem_id
      carrier
      mcc
      mnc
      iccid
    }
  }
}
`

const VESSEL_UNIQUE_CARRIER_INFO = gql`
  query GetOperators {
    getOperators
}
`

const VESSEL_UNIQUE_VSAT_CARRIER_INFO = gql`
query GetSatelliteOperators {
  getSatelliteOperators
}
`

const GET_CARRIERMETRICS_INFOR_HISTO = gql`
query GetCarrierMetrics($h3Index: String!, $operator: String!, $carrier: String!, $mcc: String!, $mnc: String!, $histroType: String!, $interval: Int!) {
  getCarrierMetrics(h3index: $h3Index, operator: $operator, carrier: $carrier, mcc: $mcc, mnc: $mnc, histroType: $histroType, interval: $interval) {
    histogramType
    histogramData {
      key
      doc_count
    }
  }
}
`

const GET_VSAT_NETWORK_COVERAGE = gql`
query GetVsatLocations($operator: String!, $layer: String!, $resolution: Int!) {
  getVsatLocations(operator: $operator, layer: $layer, resolution: $resolution) {
    data
  }
}
`

const GET_VSAT_HISTOGRAMS = gql`
query GetVsatMetrics($h3Index: String!, $operator: String!, $histroType: String!, $interval: Int!) {
  getVsatMetrics(h3index: $h3Index, operator: $operator, histroType: $histroType, interval: $interval) {
    histogramType
    histogramData {
      key
      doc_count
    }
  }
}
`

const GET_VSAT_ALL_METRICS = gql`
query GetVsatH3Details($h3Index: String!) {
  getVsatH3Details(h3index: $h3Index) {
    h3index
    serviceproviders {
      name
      satellite
      beam
    }
  }
}
`

const STORE_MAP_ERORS = gql`
query StoreEvents($jsonString: String) {
  storeEvents(jsonString: $jsonString)
}
`

const GET_EDGE_EVENTS =gql`
query GetEdgeEvents($starttime: String!, $locationid: String, $customerName: String, $deviceid: String, $endtime: String, $level: String, $module: String) {
  getEdgeEvents(starttime: $starttime, locationid: $locationid, customerName: $customerName, deviceid: $deviceid, endtime: $endtime, level: $level, module: $module) {
    geo_data {
      latitude
      longitude
    }
    metadata
    timestamp
    description
    deviceID
    level
    locationID
    module
    name
    service
  }
}`

export function fetchProducts(vsat: number, lte: number) {
  let input = `variables: {
    getLinkAvailAggsVsat: ${vsat},
    getLinkAvailAggsLte: ${lte}
  }`;
  return (dispatch: any, getState: any, client: any) => {
    client
      .query({
        query: LINK_H3_DATA,
        fetchPolicy: 'no-cache',
        variables: {
          getLinkAvailAggsVsat: vsat,
          getLinkAvailAggsLte: lte
        }
      })
      .then((res: any) => {
        if (res.data) {
          let apiData = res.data;
          dispatch(deleteApiError({ errorKey: "fetchProducts" }))
          dispatch(fetchDataSuccess(apiData));
        }
      })
      .catch((error: any) => {
        let queryTime = new Date().toISOString();
        console.log('error in fetchProducts');
        console.log({ errorKey: "fetchProducts", error: error, "query": print(LINK_H3_DATA), "params": `${input}`, "queryTime": queryTime });
        dispatch(setApiErrors({ errorKey: "fetchProducts", error: error, "query": print(LINK_H3_DATA), "params": `${input}`, "queryTime": queryTime }))
      })
      ;
  }
}

export function fetchTowerDetails() {
  return (dispatch: any, getState: any, client: any) => {
    client
      .query({
        query: TOWER_DETAILS,
        fetchPolicy: 'no-cache',

      })
      .then((res: any) => {
        if (res.data) {
          let apiData = res.data;
          dispatch(deleteApiError({ errorKey: "towerDetails" }))
          dispatch(fetchTowerDetailsSuccess(apiData));
        }
      })
      .catch((error: any) => {
        let queryTime = new Date().toISOString();
        console.log('error in fetch');
        console.log({ errorKey: "towerDetails", error: error, "query": print(TOWER_DETAILS), "params": "No Params","queryTime": queryTime });
        dispatch(setApiErrors({ errorKey: "towerDetails", error: error, "query": print(TOWER_DETAILS), "params": "No Params", "queryTime": queryTime }))
      })
      ;
  }
}

export function fetchVesselNames(start: string, end: string) {
  let input = ` variables: {
    starttime: ${start},
    endtime: ${end}
  }
  `;
  return (dispatch: any, getState: any, client: any) => {
    client
      .query({
        query: GET_VESSEL_NAMES,
        fetchPolicy: 'no-cache',
        variables: {
          starttime: start,
          endtime: end
        }
      })
      .then((res: any) => {
        if (res.data) {
          let apiData = res.data;
          dispatch(deleteApiError({ errorKey: "vesselNames" }))
          dispatch(fetchVesselNameSuccess(apiData));
        }
      })
      .catch((error: any) => {
        let queryTime = new Date().toISOString();
        console.log('error in fetiching vessel Names');
        console.log({ errorKey: "vesselNames", error: error, "query": print(GET_VESSEL_NAMES), "params": `${input}`,"queryTime": queryTime });
        dispatch(setApiErrors({ errorKey: "vesselNames", error: error, "query": print(GET_VESSEL_NAMES), "params": `${input}`, "queryTime": queryTime }))
      })
      ;
  }
}

export function fetchVesseVoyagePath(customerName: string, start: string, end: string, interval: string) {
  let input = ` variables: {
    locationid: ${customerName},
    starttime: ${start},
    endtime: ${end},
    interval: ${interval}
  }
  `;
  return (dispatch: any, getState: any, client: any) => {
    dispatch(fetchVesselVoyageRequest())
    client
      .query({
        query: GET_VESSEL_VOYAGE,
        fetchPolicy: 'no-cache',
        variables: {
          locationid: customerName,
          starttime: start,
          endtime: end,
          interval: interval
        }
      })
      .then((res: any) => {
        if (res.data) {
          let apiData = res.data;
          dispatch(deleteApiError({ errorKey: "voyage" }))
          dispatch(fetchVesselVoyageSuccess(apiData));
        }
      })
      .catch((error: any) => {
        let queryTime = new Date().toISOString();
        console.log('error in fetch');
        console.log({ errorKey: "voyage", error: error, "query": print(GET_VESSEL_VOYAGE), "params": `${input}`,"queryTime": queryTime });
        dispatch(setApiErrors({ errorKey: "voyage", error: error, "query": print(GET_VESSEL_VOYAGE), "params": `${input}`, "queryTime": queryTime }))
      })
      ;
  }
}

export function fetchSelectedVesseVoyagePath(customerName: string, start: string, end: string, interval: string) {
  let input = ` variables: {
    locationid: ${customerName},
    starttime: ${start},
    endtime: ${end},
    interval: ${interval}
  }
  `;
  return (dispatch: any, getState: any, client: any) => {
    dispatch(fetchVesselVoyageRequest())
    client
      .query({
        query: GET_VESSEL_VOYAGE,
        fetchPolicy: 'no-cache',
        variables: {
          locationid: customerName,
          starttime: start,
          endtime: end,
          interval: interval
        }
      })
      .then((res: any) => {
        if (res.data) {
          let apiData = res.data;
          dispatch(deleteApiError({ errorKey: "selectedVoyage" }))
          dispatch(fetchSelctedVesselVoyageSuccess(apiData));
        }
      })
      .catch((error: any) => {
        let queryTime = new Date().toISOString();
        console.log('error in fetch');
        console.log({ "errorKey": "selectedVoyage", "error": error, "query": print(GET_VESSEL_VOYAGE), "params": `${input}`,"queryTime": queryTime  });
        dispatch(setApiErrors({ "errorKey": "selectedVoyage", "error": error, "query": print(GET_VESSEL_VOYAGE), "params": `${input}`, "queryTime": queryTime }))
      })
      ;
  }
}


export function fetchVesselKpiData(locationId: string, start: string, end: string, interval: string) {
  let input = ` variables: {
    locationid: ${locationId},
    starttime: ${start},
    endtime: ${end},
    interval: ${interval}
  }`;
  return (dispatch: any, getState: any, client: any) => {
    dispatch(fetchVesselKpiDataRequest())
    client
      .query({
        query: VESSEL_KPI_DATA,
        fetchPolicy: 'no-cache',
        variables: {
          locationid: locationId,
          starttime: start,
          endtime: end,
          interval: interval
        }
      })
      .then((res: any) => {
        if (res.data) {
          let apiData = res.data;
          dispatch(deleteApiError({ errorKey: "kpiData" }))
          dispatch(fetchVesselKpiDataSuccess(apiData));
        }
      })
      .catch((error: any) => {
        let queryTime = new Date().toISOString();
        console.log('error in kpi data api');
        console.log({ errorKey: "kpiData", error: error, "query": print(VESSEL_KPI_DATA), "params": `${input}`, "queryTime": queryTime });
        dispatch(setApiErrors({ errorKey: "kpiData", error: error, "query": print(VESSEL_KPI_DATA), "params": `${input}`, "queryTime": queryTime }))
      })
      ;
  }
}

export function fetchCarrierData(career: string, resolution: number, layer: string) {
  let input = `
  variables: {
    operator:${career},
    resolution: ${resolution},
    layer: ${layer}
  }
  `
  return (dispatch: any, getState: any, client: any) => {
    client
      .query({
        query: CARIER_DATA,
        fetchPolicy: 'no-cache',
        variables: {
          operator: career,
          resolution: resolution,
          layer: layer
        }
      })
      .then((res: any) => {
        if (res.data) {
          let apiData = res.data;
          dispatch(deleteApiError({ errorKey: "outerHex" }))
          dispatch(fetchCarrierDataSuccess(apiData));
        }
      })
      .catch((error: any) => {
        let queryTime = new Date().toISOString();
        console.log('error in outerHex api');
        console.log({ errorKey: "outerHex", error: error, "query": print(CARIER_DATA), "params": `${input}`,"queryTime": queryTime });
        dispatch(setApiErrors({ errorKey: "outerHex", error: error, "query": print(CARIER_DATA), "params": `${input}`, "queryTime": queryTime }))
      })
      ;
  }
}

export function fetchCarrierDataInnerLayer(career: string, resolution: number, layer: string) {
  let input = `
  variables: {
    operator:${career},
    resolution: ${resolution},
    layer: ${layer}
  }
  `;
  return (dispatch: any, getState: any, client: any) => {
    client
      .query({
        query: CARIER_DATA,
        fetchPolicy: 'no-cache',
        variables: {
          operator: career,
          resolution: resolution,
          layer: layer
        }
      })
      .then((res: any) => {
        if (res.data) {
          let apiData = res.data;
          dispatch(deleteApiError({ errorKey: "InnerHex" }))
          dispatch(fetchCarrierDataInnerLayerSuccess(apiData));
        }
      })
      .catch((error: any) => {
        let queryTime = new Date().toISOString();
        console.log('error in InnerHex api ');
        console.log({ errorKey: "InnerHex", error: error, "query": print(CARIER_DATA), "params": `${input}`,"queryTime": queryTime });
        dispatch(setApiErrors({ errorKey: "InnerHex", error: error, "query": print(CARIER_DATA), "params": `${input}`, "queryTime": queryTime }))
      })
      ;
  }
}

export function fetchVesselCurrentLocation(locationIds: string[], ouName: string) {

  let input = `variables {
    locationIDlist:${locationIds},
    ouName: ${ouName}
  }`;

  return (dispatch: any, getState: any, client: any) => {
    dispatch(fetchVesselCurrentLocationRequest())
    client
      .query({
        query: VESSEL_CURRENT_LOCATION,
        fetchPolicy: 'no-cache',
        variables: {
          locationIDlist: locationIds,
          ouName: ouName
        }
      })
      .then((res: any) => {
        if (res.data) {
          let apiData = res.data;
          dispatch(deleteApiError({ errorKey: "vesselLastLoc" }))
          dispatch(fetchVesselCurrentLocationSuccess(apiData));
        }
      })
      .catch((error: any) => {
        let queryTime = new Date().toISOString();
        console.log('error in vessel curent Location api ');
        console.log({ errorKey: "vesselLastLoc", error: error, "query": print(VESSEL_CURRENT_LOCATION), "params": `${input}`,"queryTime": queryTime });
        dispatch(setApiErrors({ errorKey: "vesselLastLoc", error: error, "query": print(VESSEL_CURRENT_LOCATION), "params": `${input}`, "queryTime": queryTime }))
      })
      ;
  }
}

export function fetchVesselMetrics(customerName: string, start: string, end: string) {
  let input = ` variables: { 
    locationid:${customerName},
    endtime: ${end},
    starttime: ${start}
  }
  `
  return (dispatch: any, getState: any, client: any) => {
    client
      .query({
        query: VESSEL_METRICS_INFO,
        fetchPolicy: 'no-cache',
        variables: {
          locationid: customerName,
          endtime: end,
          starttime: start
        }
      })
      .then((res: any) => {
        if (res.data) {
          let apiData = res.data;
          dispatch(deleteApiError({ errorKey: "vesselMetrics" }))
          dispatch(fetchVesselMetricsInfoSuccess(apiData));
        }
      })
      .catch((error: any) => {
        let queryTime = new Date().toISOString();
        console.log('error in vessel metrics ');
        console.log({ errorKey: "vesselMetrics", error: error, "query": print(VESSEL_METRICS_INFO), "params": `${input}`,"queryTime": queryTime });
        dispatch(setApiErrors({ errorKey: "vesselMetrics", error: error, "query": print(VESSEL_METRICS_INFO), "params": `${input}`, "queryTime": queryTime }))
      })
      ;
  }
}


export function fetchUniquecarrier() {
  return (dispatch: any, getState: any, client: any) => {
    client
      .query({
        query: VESSEL_UNIQUE_CARRIER_INFO,
        fetchPolicy: 'no-cache',
      })
      .then((res: any) => {
        if (res.data) {
          let apiData = res.data;
          dispatch(deleteApiError({ errorKey: "uniqueCarrier", }))
          dispatch(fetchUniqueCarrierSuccess(apiData));
        }
      })
      .catch((error: any) => {
        let queryTime = new Date().toISOString();
        console.log('error in unique carrier ');
        console.log({ errorKey: "uniqueCarrier", error: error, "query": print(VESSEL_UNIQUE_CARRIER_INFO), "params": "No Params","queryTime": queryTime });
        dispatch(setApiErrors({ errorKey: "uniqueCarrier", error: error, "query": print(VESSEL_UNIQUE_CARRIER_INFO), "params": "No Params", "queryTime": queryTime }))
      })
      ;
  }
}

export function fetchSinrHistoData(h3index: string, operator: string, carrierName: string, mcc: string, mnc: string, histroType: string, interval: number) {
  let input = `
  variables: { 
    h3Index: ${h3index},
    operator: ${operator},
    carrier: ${carrierName},
    mcc: ${mcc},
    mnc: ${mnc},
    histroType: ${histroType},
    interval: ${interval}
  }
  `;
  return (dispatch: any, getState: any, client: any) => {
    client.query({
      query: GET_CARRIERMETRICS_INFOR_HISTO,
      fetchPolicy: 'no-cache',
      variables: {
        h3Index: h3index,
        operator: operator,
        carrier: carrierName,
        mcc: mcc,
        mnc: mnc,
        histroType: histroType,
        interval: interval
      }
    })
      .then((res: any) => {
        if (res.data) {
          let apiData = res.data;
          dispatch(deleteApiError({ errorKey: "sinrHisto" }))
          dispatch(fetchHexSinrHistoSuccess(apiData));
        }
      })
      .catch((error: any) => {
        let queryTime = new Date().toISOString();
        console.log('error in  SINR histo ');
        console.log({ errorKey: "sinrHisto", error: error, "query": print(GET_CARRIERMETRICS_INFOR_HISTO), "params": `${input}`,"queryTime": queryTime });
        dispatch(setApiErrors({ errorKey: "sinrHisto", error: error, "query": print(GET_CARRIERMETRICS_INFOR_HISTO), "params": `${input}`, "queryTime": queryTime }))
      })
      ;
  }
}


export function fetchRssiHistoData(h3index: string, operator: string, carrierName: string, mcc: number, mnc: number, histroType: string, interval: number) {
  let input = `
  variables: { 
    h3Index: ${h3index},
    operator: ${operator},
    carrier: ${carrierName},
    mcc: ${mcc},
    mnc: ${mnc},
    histroType: ${histroType},
    interval: ${interval}
  }
  `;
  return (dispatch: any, getState: any, client: any) => {
    client
      .query({
        query: GET_CARRIERMETRICS_INFOR_HISTO,
        fetchPolicy: 'no-cache',
        variables: {
          h3Index: h3index,
          operator: operator,
          carrier: carrierName,
          mcc: mcc,
          mnc: mnc,
          histroType: histroType,
          interval: interval
        }
      })
      .then((res: any) => {
        if (res.data) {
          let apiData = res.data;
          dispatch(deleteApiError({ errorKey: "rssiHisto" }))
          dispatch(fetchHexRssiHistoSuccess(apiData));
        }
      })
      .catch((error: any) => {
        let queryTime = new Date().toISOString();
        console.log('error in RSSI histo ');
        console.log({ errorKey: "rssiHisto", error: error, "query": print(GET_CARRIERMETRICS_INFOR_HISTO), "params": `${input}`,"queryTime": queryTime });
        dispatch(setApiErrors({ errorKey: "rssiHisto", error: error, "query": print(GET_CARRIERMETRICS_INFOR_HISTO), "params": `${input}`, "queryTime": queryTime }))
      })
      ;
  }
}


export function fetchSpeedHistoData(h3index: string, operator: string, carrierName: string, mcc: number, mnc: number, histroType: string, interval: number) {
  let input = `
  variables: { 
    h3Index: ${h3index},
    operator: ${operator},
    carrier: ${carrierName},
    mcc: ${mcc},
    mnc: ${mnc},
    histroType: ${histroType},
    interval: ${interval}
  }
  `;
  return (dispatch: any, getState: any, client: any) => {
    client
      .query({
        query: GET_CARRIERMETRICS_INFOR_HISTO,
        fetchPolicy: 'no-cache',
        variables: {
          h3Index: h3index,
          operator: operator,
          carrier: carrierName,
          mcc: mcc,
          mnc: mnc,
          histroType: histroType,
          interval: interval
        }
      })
      .then((res: any) => {
        if (res.data) {
          let apiData = res.data;
          dispatch(deleteApiError({ errorKey: "speedHisto" }))
          dispatch(fetchHexSpeedHistoSuccess(apiData));
        }
      })
      .catch((error: any) => {
        let queryTime = new Date().toISOString();
        console.log('error in Speed Histo ');
        console.log({ errorKey: "speedHisto", error: error, "query": print(GET_CARRIERMETRICS_INFOR_HISTO), "params": `${input}`,"queryTime": queryTime });
        dispatch(setApiErrors({ errorKey: "speedHisto", error: error, "query": print(GET_CARRIERMETRICS_INFOR_HISTO), "params": `${input}`, "queryTime": queryTime }))
      })
      ;
  }
}

export function fetchVsatCoverage(career: string, resolution: number, layer: string) {
  let input = `
  variables: {
    operator:${career},
    resolution: ${resolution},
    layer: ${layer}
  }
  `;
  return (dispatch: any, getState: any, client: any) => {
    client
      .query({
        query: GET_VSAT_NETWORK_COVERAGE,
        fetchPolicy: 'no-cache',
        variables: {
          operator: career,
          resolution: resolution,
          layer: layer
        }
      })
      .then((res: any) => {
        if (res.data) {
          let apiData = res.data;
          dispatch(deleteApiError({ errorKey: "vsatHex5" }))
          dispatch(fetchVsatCoverageSuccess(apiData));
        }
      })
      .catch((error: any) => {
        let queryTime = new Date().toISOString();
        console.log('error in outerHex api');
        console.log({ errorKey: "vsatHex5", error: error, "query": print(GET_VSAT_NETWORK_COVERAGE), "params": `${input}`,"queryTime": queryTime });
        dispatch(setApiErrors({ errorKey: "vsatHex5", error: error, "query": print(GET_VSAT_NETWORK_COVERAGE), "params": `${input}`, "queryTime": queryTime }))
      })
      ;
  }
}

export function fetchVsatCoverageInnerLayer(career: string, resolution: number, layer: string) {
  let input = `
  variables: {
    operator:${career},
    resolution: ${resolution},
    layer: ${layer}
  }
  `;
  return (dispatch: any, getState: any, client: any) => {
    client
      .query({
        query: GET_VSAT_NETWORK_COVERAGE,
        fetchPolicy: 'no-cache',
        variables: {
          operator: career,
          resolution: resolution,
          layer: layer
        }
      })
      .then((res: any) => {
        if (res.data) {
          let apiData = res.data;
          dispatch(deleteApiError({ errorKey: "vsatHex5" }))
          dispatch(fetchVsatCoverageInnerLayerDataSuccess(apiData));
        }
      })
      .catch((error: any) => {
        let queryTime = new Date().toISOString();
        console.log('error in outerHex api');
        console.log({ errorKey: "vsatHex5", error: error, "query": print(GET_VSAT_NETWORK_COVERAGE), "params": `${input}`,"queryTime": queryTime });
        dispatch(setApiErrors({ errorKey: "vsatHex5", error: error, "query": print(GET_VSAT_NETWORK_COVERAGE), "params": `${input}`, "queryTime": queryTime }))
      })
      ;
  }
}

export function fetchUniqueVsatcarrier() {
  return (dispatch: any, getState: any, client: any) => {
    client
      .query({
        query: VESSEL_UNIQUE_VSAT_CARRIER_INFO,
        fetchPolicy: 'no-cache',
      })
      .then((res: any) => {
        if (res.data) {
          let apiData = res.data;
          dispatch(deleteApiError({ errorKey: "uniqueCarrier", }))
          dispatch(fetchUniqueCarrierSuccess(apiData));
        }
      })
      .catch((error: any) => {
        let queryTime = new Date().toISOString();
        console.log('error in unique carrier ');
        console.log({ errorKey: "uniqueCarrier", error: error, "query": print(VESSEL_UNIQUE_VSAT_CARRIER_INFO), "params": "No params","queryTime": queryTime });
        dispatch(setApiErrors({ errorKey: "uniqueCarrier", error: error, "query": print(VESSEL_UNIQUE_VSAT_CARRIER_INFO), "params": "No params", "queryTime": queryTime }))
      })
      ;
  }
}

export function fetchVsatHistoDetails(h3index: string, operator: string, histroType: string, interval: number) {
  let input = `
  variables: { 
    h3Index: ${h3index},
    operator: ${operator},
    histroType: ${histroType},
    interval: ${interval}
  }
  `;
  return (dispatch: any, getState: any, client: any) => {
    client.query({
      query: GET_VSAT_HISTOGRAMS,
      fetchPolicy: 'no-cache',
      variables: {
        h3Index: h3index,
        operator: operator,
        histroType: histroType,
        interval: interval
      }
    })
      .then((res: any) => {
        if (res.data) {
          let apiData = res.data;
          dispatch(deleteApiError({ errorKey: "vsatHisto" }))
          dispatch(fetchVSATHistoSuccess(apiData));
        }
      })
      .catch((error: any) => {
        let queryTime = new Date().toISOString();
        console.log('error in  vsatHisto ');
        console.log({ errorKey: "vsatHisto", error: error, "query": print(GET_VSAT_HISTOGRAMS), "params": `${input}`,"queryTime": queryTime });
        dispatch(setApiErrors({ errorKey: "vsatHisto", error: error, "query": print(GET_VSAT_HISTOGRAMS), "params": `${input}`, "queryTime": queryTime }))
      })
      ;
  }
}

export function fetchVsatTxPowerHistoDetails(h3index: string, operator: string, histroType: string, interval: number) {
  let input = `
  variables: { 
    h3Index: ${h3index},
    operator: ${operator},
    histroType: ${histroType},
    interval: ${interval}
  }
  `;
  return (dispatch: any, getState: any, client: any) => {
    client.query({
      query: GET_VSAT_HISTOGRAMS,
      fetchPolicy: 'no-cache',
      variables: {
        h3Index: h3index,
        operator: operator,
        histroType: histroType,
        interval: interval
      }
    })
      .then((res: any) => {
        if (res.data) {
          let apiData = res.data;
          dispatch(deleteApiError({ errorKey: "vsatTxPower" }))
          dispatch(fetchVSATTxPowerHistoSuccess(apiData));
        }
      })
      .catch((error: any) => {
        let queryTime = new Date().toISOString();
        console.log('error in  vsatTxPower ');
        console.log({ errorKey: "vsatTxPower", error: error, "query": print(GET_VSAT_HISTOGRAMS), "params": `${input}`,"queryTime": queryTime });
        dispatch(setApiErrors({ errorKey: "vsatTxPower", error: error, "query": print(GET_VSAT_HISTOGRAMS), "params": `${input}`, "queryTime": queryTime }))
      })
      ;
  }
}

export function fetchVsatHexALLMetrics(h3index: string) {
  let input = `
  variables: { 
    h3Index: ${h3index}
  }
  `;
  return (dispatch: any, getState: any, client: any) => {
    client.query({
      query: GET_VSAT_ALL_METRICS,
      fetchPolicy: 'no-cache',
      variables: {
        h3Index: h3index
      }
    })
      .then((res: any) => {
        if (res.data) {
          let apiData = res.data;
          dispatch(fethcVSATAllHexMetricsSuccess(apiData));
          dispatch(deleteApiError({ errorKey: "vsatAllHex" }))
        }
      })
      .catch((error: any) => {
        let queryTime = new Date().toISOString();
        console.log('error in  vsat all hex Metrics ');
        console.log({ errorKey: "vsatAllHex", error: error, "query": print(GET_VSAT_ALL_METRICS), "params": `${input}`,"queryTime": queryTime});
        dispatch(setApiErrors({ "errorKey": "vsatAllHex", error: error, "query": print(GET_VSAT_ALL_METRICS), "params": `${input}`, "queryTime": queryTime }))
      })
      ;
  }
}

export function setAllMapErrors(jssonError: string) {
  let input = `
  variables: { 
    jsonString: ${jssonError}
  }
  `;
  return (dispatch: any, getState: any, client: any) => {
    client.query({
      query: STORE_MAP_ERORS,
      fetchPolicy: 'no-cache',
      variables: {
        jsonString: jssonError
      }
    })
      .then((res: any) => {
        if (res.data) {
          let apiData = res.data;
          console.log(apiData);
          if(apiData?.storeEvents === "Log saved successfully.") {
            console.log("Errors recoded successfully!!");
               dispatch(clearMapErros(null));
          }
         

          // dispatch(fethcVSATAllHexMetricsSuccess(apiData));
          // dispatch(deleteApiError({errorKey:"mapErrorList"}))
        }
      })
      .catch((error: any) => {
        let queryTime = new Date().toISOString();
        console.log('error in  MAPS error send ');
        console.log({ errorKey: "mapErrorList", error: error, "query": print(STORE_MAP_ERORS), "params": `${input}`, "queryTime": queryTime });

      })
      ;
  }
}


export function fetchEdgeEvents(starttime: string, endtime: string, locationid: string,customerName: string, deviceid: string,level: string, module: string) {
  let input = `
  variables: {
    starttime:${starttime},
    endtime: ${endtime},
    locationid: ${locationid},
    customerName: ${customerName},
    deviceid: ${deviceid},
    level: ${level},
    module: ${module}
  }
  `;
  return (dispatch: any, getState: any, client: any) => {
    client
      .query({
        query: GET_EDGE_EVENTS,
        fetchPolicy: 'no-cache',
        variables: {
          starttime: starttime,
          endtime: endtime,
          locationid: locationid,
          customerName: customerName,
          deviceid: deviceid,
          level: level,
          module: module
        }
      })
      .then((res: any) => {
        if (res.data) {
          let apiData = res.data;
          dispatch(deleteApiError({ errorKey: "GetEdgeEvents" }))
          dispatch(fetchGetEdgeEventsSucesss(apiData));
        }
      })
      .catch((error: any) => {
        let queryTime = new Date().toISOString();
        console.log('error in GetEdgeEvents api');
        console.log({ errorKey: "GetEdgeEvents", error: error, "query": print(GET_EDGE_EVENTS), "params": `${input}`,"queryTime": queryTime });
        dispatch(setApiErrors({ errorKey: "GetEdgeEvents", error: error, "query": print(GET_EDGE_EVENTS), "params": `${input}`, "queryTime": queryTime }))
      })
      ;
  }
}


