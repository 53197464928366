import { Grid } from "@mui/material"
import { connect } from "react-redux";
import FirewallReportWidget, { WidgetData } from "./FirewallReportWidget"
import { useEffect, useState } from "react";
import _ from "lodash";

const DEFAULT_VALUE: WidgetData = {
    packetsCount: '-',
    ruleName: '-',
    site_name: '-'
}

interface ActivityWidgetData extends WidgetData {
    action: 0 | 1 | number;
}

const FirewallTopWidgets = (props: any) => {
    const { getFirewallWidgets, getVesselsListing } = props;

    const [allowedReport, setAllowedReport] = useState(DEFAULT_VALUE);
    const [deniedReport, setDeniedReport] = useState(DEFAULT_VALUE);

    useEffect(() => {
        if (_.isEmpty(getFirewallWidgets))
            return;

        const data = getFirewallWidgets?.hasOwnProperty('data') ? getFirewallWidgets?.data : {};
        if (!data)
            return;
        const columns: string[] = data.columns ?? [];
        const rows: any[] = data.rows ?? [];
        if(rows.length !== 2){
            setAllowedReport(DEFAULT_VALUE);
            setDeniedReport(DEFAULT_VALUE);
        }
        rows.forEach((row) => {
            const item: Partial<ActivityWidgetData> = {};
            columns.forEach((col, index) => {
                item[col] = row[index];
            })
            const { action, packetsCount = 0, ruleName = '', site_name = '' } = item;
            switch (action) {
                case 0:
                    setAllowedReport({ packetsCount, ruleName, site_name });
                    break;
                default:
                    setDeniedReport({ packetsCount, ruleName, site_name });
                    break;

            }
        });
    }, [getFirewallWidgets])

    useEffect(() => {
        if(_.isEmpty(getVesselsListing)){
            setAllowedReport(DEFAULT_VALUE);
            setDeniedReport(DEFAULT_VALUE);
        }
    }, [getVesselsListing])

    return <Grid container spacing={4}>
        <Grid item xs={12} sm={4} md={6} lg={6} >
            <FirewallReportWidget header='Top Denied' data={allowedReport} />
        </Grid>
        <Grid item xs={12} sm={4} md={6} lg={6} >
            <FirewallReportWidget header='Top Allowed' data={deniedReport} />
        </Grid>
    </Grid>
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    getFirewallWidgets: state.authReducer?.getFirewallWidgets,
    getVesselsListing: state.authReducer?.getVesselsListing
});

export default connect(mapStateToProps)(FirewallTopWidgets);
