import { Autocomplete, Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import close from "../../../asset/image/close.svg";
import { SelectTimezone } from "reactjs-timezone-select";
import _ from "lodash";
import moment from "moment";
import "./Preferences.css";
import { getDataFromLocalStorage, getDecodeURI, getEncodedURI, getTimezoneCity } from "../../../utils/util";
import { LOGGED_IN_EMAIL, USER_PREFERENCES } from "../../../constants/Constants";
import { updateUserPreferences, getUserPreferences } from "../../../actions/Users/authenticateService";
import { GET_USER_PREFERENCES, UPDATE_USER_PREFERENCES, USER_TIMEZONE } from "../../../actions/types";

const Preferences = (props) => {
    const { authReducer, isPopUpOpen, setIsPopUpOpen, updateUserPreferences, getUserPreferences, history, location } = props;

    const dispatch = useDispatch();

    // ex: Asia/Colombo - IST
    let systemTimezone = moment.tz.guess(true) + " - " + moment.tz(moment.tz.guess(true)).format('z')
    let defaultDashboard = "Sites"
    const [selectedTimeZone, setSelectedTimeZone] = useState(systemTimezone)
    const [selectedDefaultDashboard, setSelectedDefaultDashboard] = useState(defaultDashboard)

    useEffect(() => {
        if (isPopUpOpen) {
            try {
                let userPreferences = getDataFromLocalStorage(USER_PREFERENCES)
                if (userPreferences) {
                    let preferences = JSON.parse(userPreferences)
                    if (preferences) {
                        if (preferences?.timezone) {
                            setSelectedTimeZone(preferences.timezone)
                        }

                        if (preferences?.defaultDashboard) {
                            setSelectedDefaultDashboard(preferences?.defaultDashboard)
                        }
                    }
                }
            } catch (error) {
 
            }
        }
    }, [isPopUpOpen])

    useEffect(() => {
        if (!_.isEmpty(authReducer.updateUserPreferences)) {
            let userEmailId = getDataFromLocalStorage(LOGGED_IN_EMAIL)
            if (!_.isEmpty(userEmailId)) {
                getUserPreferences(userEmailId)
            }
            dispatch({ type: UPDATE_USER_PREFERENCES, payload: {}})
        }
    }, [authReducer.updateUserPreferences])

    // once user updates
    useEffect(() => {
        if (!_.isEmpty(authReducer.getUserPreferences) && authReducer.getUserPreferences?.data) {
            let data = authReducer.getUserPreferences.data
            localStorage.setItem(USER_PREFERENCES, JSON.stringify(data))
            if (data?.timezone) {
                setSelectedTimeZone(data.timezone)
                dispatch({ type: USER_TIMEZONE, payload: data.timezone})
            }

            if (data?.defaultDashboard) {
                setSelectedDefaultDashboard(data?.defaultDashboard)
            }
            closeDialog()
            dispatch({ type: GET_USER_PREFERENCES, payload: {}})
        }
    }, [authReducer.getUserPreferences])

    const closeDialog = () => {
        setIsPopUpOpen(false)
    }

    const save = () => {
        let params = getDecodeURI(location.search);
        params.timezone = selectedTimeZone;
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });

        let userEmailId = getDataFromLocalStorage(LOGGED_IN_EMAIL)
        if(!_.isEmpty(userEmailId)){
            let payload = {
                user: userEmailId,
                timezone : selectedTimeZone,
                defaultDashboard: selectedDefaultDashboard
            }
            updateUserPreferences(payload)
            // closeDialog()
        }
    }

    return (
        <div className="Form">
            <Dialog maxWidth="md" className="create-default-config-container group-actions-tab-create-group-popup menuScroll preferences-pop-up" open={isPopUpOpen}>
                <DialogTitle className="create-default-config-title">
                    Preferences
                    <img src={close} title="Close" className="close" onClick={() => { closeDialog() }} />
                </DialogTitle>
                <DialogContent className="create-default-config-content">
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="name-input-default-config create-config-table select_devices time-zone-selection">
                            <span>Select Time zone</span>
                            <SelectTimezone
                                value={getTimezoneCity(selectedTimeZone)}
                                onChange={({ label, value }) => {
                                    setSelectedTimeZone(label)
                                }}
                                optionLabelFormat={(timezone) =>
                                    `${timezone.name} - ${timezone.abbreviation}`
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="name-input-default-config create-config-table select_devices">
                            <span>Select Default Dashboard Mode</span>
                            <Autocomplete
                                freeSolo
                                value={selectedDefaultDashboard}
                                disableClearable
                                onChange={(e, newValue: any) => { setSelectedDefaultDashboard(newValue) }}
                                options={["Sites","Trends"]}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" placeholder="Select" type="search" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="dialog-actions">
                            <Button variant="outlined"  className={!_.isEmpty(selectedTimeZone) && !_.isEmpty(selectedDefaultDashboard) ? "create" : "create disable"} onClick={() => save()}>Save</Button>
                            <Button variant="outlined" onClick={() => { closeDialog() }} >Cancel</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {
        updateUserPreferences, getUserPreferences
    })(Preferences)
);