import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import "./StateHistoryTableComponent.css"
import NormalAlert from "../../asset/image/bar_chart.svg"
import Warning from "../../asset/image/warning.svg"
import Pending from "../../asset/image/Pending.svg"
import { convertDateTimeIntoTimezone } from '../../utils/util';
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from '../../utils/constants';

const StateHistoryTableComponent = (props:any)=>{
    const {data, userTimezone} = props;

    const getAlertTextColor = (alertText: any) => {
        switch (alertText.trim()) {
          case "Alerting": return "RedColor"
          case "Normal": return "GreenColor"
          case "Pending": return "OrangeColor"
          default: return "GreenColor";
        }
      }
      const getAlertIcon = (status: string | undefined) => {
        switch (status?.trim()) {
          case "Alerting": return Warning;
          case "Normal": return NormalAlert;
          case "Pending": return Pending;
          default: return NormalAlert;
        }
      }
    
    return (
        <div>
            <TableContainer>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead className="stateHistoryTableHead">
                                <TableRow>
                                    <TableCell className='stateHistoryTableHeadCell'>
                                        <span className='stateHistoryTableHeadTitle'>STATE</span>
                                    </TableCell>
                                    <TableCell>
                                        <span className='stateHistoryTableHeadTitle'>TIME</span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data && data.length > 0 &&  data?.map((row) => (
                                    <TableRow key={row?.ts}>
                                        <TableCell component="th" scope="row" className="stateHistoryTableRowCell">
                                            <div className={getAlertTextColor(row?.status)}>
                                                <div className="alertStatusWithIcon">
                                                    <img src={getAlertIcon(row?.status)} className="alertIcon" alt={row?.status} />
                                                    <span >{row?.status}</span>
                                                </div>
                                            </div>
                                        </TableCell >
                                        <TableCell>{convertDateTimeIntoTimezone(row.ts, userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell >
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
        </div>
    )
}

export default StateHistoryTableComponent