import { Button, Divider, FormControl, Grid, MenuItem, Select, TextField } from "@mui/material";

const PauseResumeServiceLine = (props) => {
    const { pauseData, rejectReason, setRejectReason, endNow, handleChangeEndNow, product, handleChangeProduct, availableProducts, handleClose, handlePauseResume } = props;

    return (
        <div className="pause-resume-container">
            {pauseData && pauseData?.action != '' && pauseData?.action === "Pause" &&
                <div className="pause-container-starlink">
                    <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy select-config-from" >
                        <span id="content-left-text">End On</span>
                        <Grid className="configuration-form-build">
                            <FormControl>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={endNow === true ? "Yes" : "No"}
                                    label=""
                                    onChange={handleChangeEndNow}
                                    size="small"
                                >
                                    <MenuItem value="Yes" disabled>Now</MenuItem>
                                    <MenuItem value="No">Billing Date</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy select-config-from" >
                        <span id="content-left-text">Reason</span>
                        <Grid className="configuration-form-build">
                            <FormControl>
                                <TextField sx={{ padding: 0 }}
                                    className="audit-filter-inputs"
                                    size="small"
                                    variant="outlined"
                                    placeholder="Description(Optional)"
                                    value={rejectReason}
                                    onChange={(e) => { setRejectReason(e.target.value) }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            }
            {pauseData && pauseData?.action != '' && pauseData?.action === "Resume" &&
                <div className="pause-container-starlink">
                    <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy select-config-from" >
                        <span id="content-left-text">Select Products</span>
                        <Grid className="configuration-form-build">
                            <FormControl>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={product}
                                    label="select"
                                    onChange={(e) => { handleChangeProduct(e) }}
                                    size="small"
                                >
                                    {availableProducts.map((product) => {
                                        return <MenuItem value={product.product_reference_id}>{product.plan}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            }
            <div className="edit-actions-confirm-btns starlink-admin-edit-actions-confirm-btns">
                <Divider className="confirm-dialogue-new-divider" />
                <div className="confirm-dialogue-new-btns">
                    <Button className="confirm-dialogue-cancel" onClick={handleClose}> Cancel </Button>
                    <Button className={pauseData && pauseData?.action != '' && pauseData?.action === "Resume" && product == '' ? 'confirm-dialogue-proceed-notAllow' : "confirm-dialogue-proceed"} onClick={() => {handlePauseResume()}}
                    disabled={pauseData && pauseData?.action != '' && pauseData?.action === "Resume" && product == ''}>{pauseData?.action}</Button>
                </div>
            </div>
        </div>
    )
}

export default PauseResumeServiceLine;