import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, TextField, Box, Paper, FormControl, Select, MenuItem, Button } from "@mui/material";
import { withRouter, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Pagination } from "../Pagination";
import { useEffect, useRef, useState } from "react";
import { getDecodeURI, getEncodedURI, readableBytesAsGB, redirectToTopolgyTabs } from "../../utils/util";
import ascSortingSelected from "../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../asset/image/descSortingSelected.svg";
import AscSort from "../../asset/image/AscSort.svg";
import DescSort from "../../asset/image/DescSort.svg";
import _ from "lodash";
import './usageTable.css';
import ImageWithFallback from "../ImageWithFallback";
import { getNewSummaryUsageTable } from "../../actions/Users/authenticateTrends";
import Download from '../../asset/image/DownloadUsage.svg';
import DownloadCSV, { clearCsvDownload } from "../DownloadCSV";
import { GET_CSV_DATA_TO_DOWNLOAD } from "../../actions/types";
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";

const convertToCsv = (data: any[][]) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        csvRows.push([row[0], row[4], row[3], row[2],row[5], row[6]])
    })
    return [['Site', 'Starlink (GB)', 'Cellular (GB)', 'VSAT (GB)', 'LBAND (GB)', 'Top Usage Apps (All WAN)'], ...csvRows]
}

const UsageDashboardTable = (props) => {
    const { authReducer, getNewSummaryUsageTable, location, history, newSummaryUsageTable, newSummaryStartDate, newSummaryEndDate, newSummaryWanType, handleSiteClick, downloadAsCSV, csvDataDownloded, vesselDashboardLoading } = props;
    const _q = getDecodeURI(location?.search);
    const DEFAULT_PAGE_SIZE = 10;
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(_q.hasOwnProperty('page') ? parseInt(_q.page) : 0);
    const [limit, setLimit] = useState(_q.hasOwnProperty('limit') ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const ENTER_KEY_CODE = "Enter";
    const [tableData, setTableData] = useState<any>([]);
    const [count, setCount] = useState(0);
    const SITE_NAME = 'site';
    const VSAT = 'vsat_usage';
    const LTE = 'lte_usage';
    const STARLINK = 'starlink_usage';
    const LBAND = 'lband_usage';
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";
    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : SITE_NAME);
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER);
    const [vessels, setVessels] = useState<any[]>([]);
    const dispatch = useDispatch();
    const searchTimeoutRef = useRef<NodeJS.Timeout>();

    function GetTopAppsImage (props)  {
        const { app } = props;
        if(app) {
          let appName = app.includes(' ') ? app.split(' ')[0]: app.includes('-') ? app.split('-')[0] : ""; 
          return <ImageWithFallback src={`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${app.trim()}`} fallbackSrcs={[`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${appName.trim()}`,"https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png"]} app={app} />
        } else {
          return <img className="logo-activity paddingLogo" src={`https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png`} />
        }
    
      }

    useEffect(() => {
        if (_.isEmpty(authReducer?.getVesselsListing))
            return;
        let vessels = [];
        const filteredVessels = authReducer?.filteredVessels;
        if (filteredVessels && filteredVessels.length > 0) {
            vessels = filteredVessels;
        } else {
            vessels = authReducer?.getVesselsListing?.locations;
        }
        setVessels(vessels);
    },[authReducer?.getVesselsListing, authReducer?.filteredVessels])

    useEffect(() => {
        if (vessels?.length > 1) {
            getNewSummaryUsageTable(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, 0, limit, sort, sortOrder, searchValue)
        } else {
            setTableData([]);
            setCount(0);
        }
        delete _q.pageNumber;
        delete _q.pageLimit;
        delete _q.sortColumn;
        delete _q.orderSort;
        doNavigate(_q);
        setPage(0)
    }, [vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType])

    useEffect(() => {
        if(!_.isEmpty(newSummaryUsageTable)) {
            const data = newSummaryUsageTable.hasOwnProperty('data') ? newSummaryUsageTable.data : {};
            const dataArr:any = [];
            setCount(data?.rows?.[0][7]);

           data?.rows && data?.rows?.forEach((item, i) => {
                if (i != 0) {
                    const itemArr = data?.columns?.reduce((acc, columItem, i) => {
                        acc[columItem] = item[i];
                        return acc;
                    }, {})
                    dataArr.push(itemArr)
                }
            })
            setTableData(dataArr);
        }
    }, [newSummaryUsageTable])

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'usage-table-newsum') {
                const [columns, _countRow, ...rows] = authReducer.csvDataDownloded;
                DownloadCSV(convertToCsv([columns, ...rows]), {
                    formatters : {
                        0 : 'ESC-COMMA',
                        1: 'GB',
                        2: 'GB',
                        3: 'GB',
                        4: 'GB',
                        5: 'ESC-COMMA'
                    }
                });
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue);
        }
    }

    const doSearch = (value: string) => {
        let params: any = getDecodeURI(location?.search);
        value ? params.search = value : delete params.search;
        delete params.page;
        const Page = 0;
        setPage(0);
        doNavigate(params);
        vessels && getNewSummaryUsageTable(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, Page, limit, sort, sortOrder, value)
    };

    const Sorting = (field, order) => {
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        params.sort = field;
        params.sortOrder = order;
        const Page = 0;
        setPage(0);
        setSort(field);
        setSortOrder(order);
        doNavigate(params);
        vessels && getNewSummaryUsageTable(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, Page, limit, field, order, searchValue)
    }

    const setSorting = (event: any, field: any, order: any,) => {
        if (event && event.currentTarget) {
            Sorting(field, order)
        }
    };

    const switchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sort === field) {
            order = ASC_ORDER === sortOrder ? DESC_ORDER : ASC_ORDER;
        }
        Sorting(field, order)
    };

    const handleChangePage = (e, value) => {
        setPage(value-1);
        vessels && getNewSummaryUsageTable(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, value-1, limit, sort, sortOrder, searchValue)
    }

    const handleChangeRowsPerPage = (e) => { 
        const Limit = parseInt(e.target.value);
        const Page = 0;
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        Limit == 10 ? delete params.limit : params.limit = Limit;
        setPage(0);
        setLimit(Limit);
        doNavigate(params);
        vessels && getNewSummaryUsageTable(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, Page, Limit, sort, sortOrder, searchValue)
    }

    const handleChangeTableLimit = (e) => {
        const value = e.target.value;
        setLimit(value);
        const Page = 0;
        setPage(0);
        let params: any = getDecodeURI(location?.search);
        value == 10 ? delete params.limit : params.limit = value;
        delete params.page;
        doNavigate(params);
        vessels && getNewSummaryUsageTable(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, Page, value, sort, sortOrder, searchValue);
    }

    const handleDownloadReport = () => {
        let isClickHouse = true;
        const startDate = newSummaryStartDate;
        const endDate = newSummaryEndDate;
        const wanType = newSummaryWanType?.value;
        const params: CsvParameters = {
            type: isClickHouse? 'QUERY_CH' : "QUERY",
            id : "usage-table-newsum",
            queryName: 'DASHBOARD_SITESTATISTICS_TABLE',
            payload: {
                "dp": "",
                "k4Id": vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
                "search": searchValue,
                "endTime": endDate,
                "startTime": startDate,
                "wanType": wanType ? `and wan_type like '%${wanType}%'` : '',
                "pageStart": 0,
                "pageLimit": count,
                "sortColumn": sort,
                "sortOrder": sortOrder
              }
        }
        downloadAsCSV(params);
    }

    const rowsOption = [10, 15, 25, 50, 100]

    const handleOnSearchClick = () => {
        doSearch(searchValue);
    }

    const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            doSearch(e.target.value);
        }, 1000)
    }
    
    return (
        <div className="config-container">
            <Grid container spacing={0.5} className="reports-login-history trends-usage-table-container">
                <Grid item xs={12} sm={12} md={12} lg={12} className="search-dropdown-limit-div trends-usage-search-container topbarMarginPaddingReset alignTopbarItemsCenter">
                    <Grid item xs={4} sm={4} md={4} lg={4} className="site-details-header usage-site-details-header">{`Site Statistics (All Sites)`}</Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8} className="usage-table-search-download-div">
                        <Grid className="usage-search-border-color">
                            <Box className="searchbarBox">
                                {<TextField
                                    id="outlined-basic"                                
                                    variant="outlined"
                                    placeholder="Type something"
                                    size="small"
                                    value={searchValue}
                                    onChange={handleOnSearchChange}
                                    onKeyDown={(event) => searchBoxKeyDown(event)}
                                    InputLabelProps={{ className: "serachLabel1" }}
                                    InputProps={{
                                        className: "searchbarInputs",
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {!searchValue && <SearchIcon
                                                    className="searchbarIconsPosition"
                                                    onClick={handleOnSearchClick}
                                                />}
                                                {searchValue && <CloseIcon
                                                    className="searchbarIconsPosition"
                                                    onClick={handleOnSearchClear}
                                                />}
                                            </InputAdornment>
                                        ),
                                    }}
                                />}
                            </Box>
                        </Grid>
                        {count > 0 && <Grid>
                            <div className="download-summary-btn" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner tablePaddingReset">
                    <Paper className="pagination-position config-pagination new-usage remove-shadow">
                        <Pagination
                            rowsPerPageOptions={rowsOption}
                            count={count ? Number(count) : 0}
                            rowsPerPage={limit ? limit : 0}
                            page={page + 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                        <Table aria-label="simple sticky table" stickyHeader>
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="UsageReports-tableRow login-tableRow">
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, SITE_NAME)}>SITE</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === SITE_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SITE_NAME, ASC_ORDER)} />
                                                <img src={sort === SITE_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, SITE_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, STARLINK)}>STARLINK</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === STARLINK && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, STARLINK, ASC_ORDER)} />
                                                <img src={sort === STARLINK && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, STARLINK, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, LTE)}>CELLULAR</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === LTE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, LTE, ASC_ORDER)} />
                                            <img src={sort === LTE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, LTE, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, VSAT)}>VSAT</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === VSAT && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, VSAT, ASC_ORDER)} />
                                            <img src={sort === VSAT && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, VSAT, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, LBAND)}>LBAND</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === LBAND && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, LBAND, ASC_ORDER)} />
                                            <img src={sort === LBAND && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, LBAND, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }}>
                                            <span className="login-history-dp-name font-wt-900">TOP USAGE APPS (ALL WAN)</span>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {tableData && 0 != tableData.length && !vesselDashboardLoading ? (
                                <TableBody className="tableBody usage-tableBody Table-Body-Opacity">

                                    {
                                        tableData && tableData.map((item, i) =>
                                            <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr Table-Row-Opacity">
                                                <TableCell className="login-left-align inter-font selectedClick Table-Cell-Opacity" onClick={() => handleSiteClick(item)}>{item?.site}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{readableBytesAsGB(item?.starlink_usage)}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{readableBytesAsGB(item?.lte_usage)}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{readableBytesAsGB(item?.vsat_usage)}</TableCell>
                                                <TableCell className='login-left-align inter-font Table-Cell-Opacity'>{readableBytesAsGB(item?.lband_usage)}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                                    <Grid className="tableSites-topIcons">
                                                        <Link to={redirectToTopolgyTabs(location, "/reports/activity", item.location_id, item.site, {}, false, false)} style={{ textDecoration: "none" }}>
                                                            {item?.topApps?.split(',')?.map((app) => {
                                                                return (<GetTopAppsImage app={app} />)
                                                            }
                                                            )}
                                                            {/* {!row?.apps && <div>NO DATA</div>} */}
                                                        </Link>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state?.authReducer,
    newSummaryUsageTable: state.authReducer?.newSummaryUsageTable,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    newSummaryWanType:state?.authReducer?.newSummaryWanType,
    vesselDashboardLoading: state?.authReducer?.vesselDashboardLoading,
    csvDataDownloded: state?.authReducer?.csvDataDownloded
  });
  
  export default withRouter(
    connect(mapStateToProps, {getNewSummaryUsageTable , downloadAsCSV})(UsageDashboardTable)
  )