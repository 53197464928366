import { withStyles } from "@material-ui/core/styles";
import { Modal } from "@material-ui/core";
import MDSpinner from "react-md-spinner";
import { Button } from "@mui/material";

const styles: any = (theme) => ({
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    bordeRadius: 10,
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "50px",
    paddingRight: "50px",
  },
});

const getModalStyle = () => {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    borderRadius: "8px",
    transform: `translate(-${top}%, -${left}%)`,
    outline: "none",
  };
};

const CustomLoader = (props) => {
  const { classes, showLoader, cancelCallBack } = props;
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={showLoader ? showLoader : true}
      disableAutoFocus={true}
      onClose={() => {
        //  this.props.handleModalTouchOutisde();
      }}
    >
      <div style={getModalStyle()} className={classes.modal}>
        <MDSpinner singleColor={"rgb(66, 165, 245)"} />
        <span style={{ color: "#fff", fontSize: "20px" }}>
          {props.loadingText ? props.loadingText : "Please Wait..."}
        </span>
        {cancelCallBack ?
          <Button
            style={{
              backgroundColor: "rgba(40, 96, 180, 1)",
              color: "#fff",
              border: "rgba(199, 218, 235, 1)",
              minHeight: "25px",
              minWidth: "90px",
              fontSize: "10px",
              textTransform: "capitalize",
              borderRadius: "4px",
              fontWeight: 100,
            }}
            title="Cancel"
            onClick={() => {
              cancelCallBack();
            }}
          >
            Cancel
          </Button>
          : null
        }
      </div>
    </Modal>
  );
};

// CustomLoader.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

export default withStyles(styles)(CustomLoader);

