import * as React from 'react';
import _ from 'lodash';
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Collapse, Grid, IconButton, TextField } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import AlarmIcon from '@mui/icons-material/Alarm';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { getMetricData, MetricQueries, getSiteInterfaces } from '../../../actions/Users/authenticateCharts';

import "./index.css";

import { AlertConfig, AlertConfigTypes, MetricAggregation, MetricAlert, MetricCondition, MetricConditionEndInterval, MetricConditionStartInterval, MetricConditionType, MetricTypes, NoDataErrorOptions, _MetricTypes } from '../types';
import { DeviceInterface } from '..';
import Chart from '../../../components/Charts';
import { CHARTS_METRICS } from '../../../constants/Constants';
import moment from 'moment-timezone';
import { handleAttachmentResponse } from '../../../actions/responseUtil';
import { useEffect } from 'react';
import { SERVICE_FUNCTION_TO_FEATURE } from '../../../config';
import { checkLoggedInUserAuthorizedToViewPage } from '../../../utils/util';

const { PRE, RTT, SINR, RSSI, LINK_STATUS, THROUGHPUT, VSAT_SNR, VSAT_TXPOWER, VSAT_POWER, VSAT_SYMBOL_RATE } = CHARTS_METRICS;

type Props = {
    config: Extract<AlertConfig, { type: AlertConfigTypes.Metric }>
    deviceInterfaces: { [key: string]: DeviceInterface },
    siteId: string,
    handleDelete: (id: string) => void,
    handleChange: (id: string, data: MetricAlert) => void,
    showHeader: boolean,
    isDefaultCardView: boolean,
    handleCancel?: (id: string) => void,
    [x: string]: any,
    getSiteInterfaces:any,
    authReducer:any
}

function MetricAlertConfiguration(props: Props) {
    const { config,
        deviceInterfaces,
        siteId,
        handleDelete,
        handleChange,
        showHeader,
        isDefaultCardView,
        handleCancel,
        getMetricData,
        getSiteInterfaces,
        authReducer
    } = props

    
    const dispatch = useDispatch();

    const [device, setDevice] = React.useState(config.data.device)
    const [displayInterface, setDisplayInterface] = React.useState(config.data.interface)
    const [metric, setMetric] = React.useState(config.data.metric)
    const [every, setEvery] = React.useState(moment.duration(`PT${config.data.every}`.toUpperCase()).minutes())
    const [forValue, setForValue] = React.useState(moment.duration(`PT${config.data.for}`.toUpperCase()).minutes())
    const [metricCondition, setMetricCondition] = React.useState(config.data.metric_condition)
    const [noDataCase, setNoDataCase] = React.useState(config.data.nodata)
    const [errorCase, setErrorCase] = React.useState(config.data.error)
    const [interfaces, setInterfaces] = React.useState<{ [key: string]: DeviceInterface }>({})

    const [authorizedBetaUser, isAuthorizedBetaUser] = React.useState(false)
    useEffect(() => {
        const betaFeatureService = SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["service"];
        const betaFeatureFunction = SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["function"];
        isAuthorizedBetaUser(checkLoggedInUserAuthorizedToViewPage(betaFeatureService, betaFeatureFunction))
    }, [])

    useEffect(() => {
        const { data } = authReducer.getSiteInterfaces
        console.log("interface Data",data);
        let _interfaces: { [key: string]: DeviceInterface } = {};
        if (!_.isEmpty(data?.rows)) {
            for (let row of data.rows) {
                const [deviceName, deviceId, displayInterface] = row;
                if (_.isEmpty(_interfaces[deviceId])) {
                    _interfaces[deviceId] = {
                        deviceName: deviceName,
                        deviceId: deviceId,
                        displayInterfaces: [displayInterface]
                    }
                } else {
                    _interfaces[deviceId].displayInterfaces.push(displayInterface);
                }
            }
        }
        setInterfaces(_interfaces)
    }, [authReducer.getSiteInterfaces])

    useEffect(() => {
        if (!_.isEmpty(siteId)) {
            getSiteInterfaces({ locationId: siteId });
        }
    }, [siteId])


    const [chartOptions, setChartOptions] = React.useState({})
    const [editing, isEditing] = React.useState(true);

    React.useEffect(() => {
        if (isDefaultCardView) {
            isEditing(true);
            resetInputs();
        }
    }, [editing])

    React.useEffect(() => {
        if (!_.isEmpty(device)
            && !_.isEmpty(displayInterface)
            && !_.isEmpty(metric)
            && !_.isEmpty(metricCondition.start_interval)
            && !_.isEmpty(metricCondition.end_interval)) {

            let now = Date.now();
            let endInterval = moment(now);
            let startInterval = moment(now).subtract(moment.duration(`PT${metricCondition.start_interval}`.toUpperCase()));

            if (metricCondition.end_interval !== MetricConditionEndInterval.now) {
                let endDuration = moment.duration(`PT${metricCondition.end_interval}`.toUpperCase())
                endInterval.subtract(endDuration);
                startInterval.subtract(endDuration);
            }

            fetchChartData(device, displayInterface, metric, startInterval, endInterval)
        }
    }, [device, displayInterface, metric, metricCondition.start_interval, metricCondition.end_interval])

    const getInterval = (minutes: any): string => {
        if (minutes <= 60) {
            return "1 minute"
        } else if (minutes <= 1440 && minutes > 60) {
            return "3 minute"
        } else if (minutes > 1440 && minutes <= 10080) {
            return "20 minute"
        } else {
            return "12 hour";
        }
    }

    const defaultChartOptions = {
        chart: {
            type: 'spline',
            zoomType: 'x',
            height: "400px",
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            }
        },
        legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            y: 12,
            margin: 28,
            itemStyle: {
                color: '#3F3F3F'
            }
        },
        title: {
            align: 'left',
            floating: true,
            x: 12,
            y: 32,
            style: {
                fontWeight: '500'
            }
        },
        plotOptions: {
            series: {
                turboThreshold: 1000000,
                stickyTracking: false,
                connectNulls: false,
            }
        },
        credits: {
            enabled: false
        },
        xAxis: {
            type: 'datetime',
            gridLineWidth: 0.5,
        },
        yAxis: {
            title: {
                text: undefined
            }
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        series: {
            type: 'spline',
            marker: {
                symbol: 'circle',
            },
        },
        showLegendTable: true
    }

    const getChartOptions = (chartTitle, data, valueUnit, tooltip = {}) => {
        let series = !_.isEmpty(data) ? Object.keys(data).map((key: string, i: any) => {
            return {
                ...defaultChartOptions.series,
                marker: {
                    symbol: 'circle',
                },
                name: key,
                data: data[key],
                tooltip: _.isEmpty(tooltip) ? {
                    valueSuffix: valueUnit
                } : tooltip,
            };
        }) : []
        return {
            ...defaultChartOptions,
            title: {
                ...defaultChartOptions.title,
                text: chartTitle
            },
            series: series
        }
    }

    const fetchChartData = async (deviceId: string, displayInterface: string, metric: string, startTime: moment.Moment, endTime: moment.Moment) => {

        let parameters = {
            startTime: startTime.utc().toISOString(),
            endTime: endTime.utc().toISOString(),
            locationId: siteId,
            chartInterval: getInterval(moment.duration(endTime.diff(startTime)).asMinutes()),
            device_interface_filter: `WHERE device_id = '${deviceId}' AND displayInterface = '${displayInterface}'`,
            intervalNum: getInterval(moment.duration(endTime.diff(startTime)).asMinutes())?.split(' ')[0],
            interval: getInterval(moment.duration(endTime.diff(startTime)).asMinutes())?.split(' ')[1],
        }

        let res = await getMetricData(parameters, metric)
        const { series } = res?.data?.data[MetricQueries[metric].series] || {};

        let _chartOptions = getChartOptions(_MetricTypes[metric], series, MetricQueries[metric].valueUnit)
        setChartOptions(_chartOptions)

    }

    const handleSave = () => {
        isEditing(false)
        handleChange(config.id, {
            device: device,
            interface: displayInterface,
            metric: metric,
            every: `${every}m`,
            for: `${forValue}m`,
            metric_condition: metricCondition,
            nodata: noDataCase,
            error: errorCase,
        })
    }

    const handleClickCancel = () => {
        isEditing(false);
        resetInputs();
    }

    const resetInputs = () => {
        setDevice(config.data.device)
        setDisplayInterface(config.data.interface)
        setMetric(config.data.metric)
        setEvery(moment.duration(`PT${config.data.every}`.toUpperCase()).minutes())
        setForValue(moment.duration(`PT${config.data.for}`.toUpperCase()).minutes())
        setMetricCondition(config.data.metric_condition)
        setNoDataCase(config.data.nodata)
        setErrorCase(config.data.error)
        setChartOptions({})
    }

    const handleChangeMetricCondition = (condition: MetricCondition) => {
        setMetricCondition(condition);
    }

    return (
        <div className="metric_alert_configuration--container">
            {
                showHeader ? <div className="metric_alert_configuration--header" onClick={() => isEditing(!editing)}>
                    <div className="metric_alert_configuration--header--meta">
                        <div className="metric_alert_configuration--header--meta--icon">
                            <AlarmIcon />
                        </div>
                        <div className='metric_alert_configuration--header--meta--title_container'>
                            <div className='metric_alert_configuration--header--meta--title'>Metric</div>
                            <div className='metric_alert_configuration--header--meta--subtitle'>Set customized details for trigger</div>
                        </div>
                    </div>
                    <div className="metric_alert_configuration--header--action_dots">
                        <MoreActionMenu isEditing={isEditing} config={config} handleDeleteMetricConfig={handleDelete} />
                    </div>
                </div> : null
            }
            <Collapse in={editing}>
                {
                    editing ? <div>
                        <div className={`metric_alert_configuration--body`}>
                            <div className="metric_alert_configuration--body--chart">
                                {
                                    !_.isEmpty(chartOptions) ? <Chart chartOptions={chartOptions} /> : null
                                }
                            </div>
                            <div className="metric_alert_configuration--body--section">
                                <Grid container>
                                    <Grid item lg={3} className="section_title">
                                        Interface & Metric
                                    </Grid>
                                    <Grid item lg={9}>
                                        <Grid container spacing={2}>
                                            <Grid item lg={6}>
                                                <label className='section_input_label section_input_label--space_bottom'>Device</label>
                                                <FormControl fullWidth>
                                                    <Select
                                                        id="device-select"
                                                        value={device}
                                                        onChange={(e) => setDevice(e.target.value)}
                                                        size="small"
                                                        fullWidth
                                                    >
                                                        {
                                                            Object.keys(interfaces).map(deviceId => <MenuItem value={deviceId}>{interfaces[deviceId].deviceName}</MenuItem>)
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={6}>
                                                <label className='section_input_label section_input_label--space_bottom'>Interface</label>
                                                <FormControl fullWidth>
                                                    <Select
                                                        id="device-select"
                                                        value={displayInterface}
                                                        onChange={(e) => setDisplayInterface(e.target.value)}
                                                        size="small"
                                                        fullWidth
                                                    >
                                                        {
                                                            !_.isEmpty(interfaces[device]) ? interfaces[device].displayInterfaces.map(displayInterface => <MenuItem value={displayInterface}>{displayInterface}</MenuItem>) : null
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={6}>
                                                <label className='section_input_label section_input_label--space_bottom'>Metric</label>
                                                <FormControl fullWidth>
                                                    <Select
                                                        id="device-select"
                                                        value={metric}
                                                        onChange={(e) => setMetric(e.target.value)}
                                                        size="small"
                                                        fullWidth
                                                    >
                                                        {
                                                            Object.keys(MetricTypes).map(key => <MenuItem value={MetricTypes[key]}>{key}</MenuItem>)
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </div>
                            <div className="metric_alert_configuration--body--section">
                                <Grid container>
                                    <Grid item lg={3} className="section_title">
                                        Evaluate
                                    </Grid>
                                    <Grid item lg={9} className="metric_alert_configuration--body--section--grey_box">
                                        <label className='section_input_label'>Every</label>
                                        <SelectDuration value={moment.duration(`PT${config.data.every}`.toUpperCase()).minutes()} unit={"m"} min={5} onChange={(value: number) => setEvery(value)} />
                                        <label className='section_input_label'>For</label>
                                        <SelectDuration value={moment.duration(`PT${config.data.for}`.toUpperCase()).minutes()} unit={"m"} min={every} onChange={(value: number) => setForValue(value)} />
                                    </Grid>

                                </Grid>
                            </div>
                            <div className="metric_alert_configuration--body--section">
                                <Grid container>
                                    <Grid item lg={3} className="section_title">
                                        Conditions
                                    </Grid>
                                    <Grid item lg={9} className="metric_alert_configuration--body--section--grey_box">
                                        <Condition metricCondition={config.data.metric_condition} metric={metric} handleChange={handleChangeMetricCondition} />
                                    </Grid>

                                </Grid>
                            </div>
                            <div className="metric_alert_configuration--body--section last_section">
                                <Grid container>
                                    <Grid item lg={3} className="section_title">
                                        Error Handling
                                    </Grid>
                                    <Grid item lg={9}>
                                        <Grid container>
                                            <Grid item lg={6} className='error_handling_subsection'>
                                                <label className='section_input_label'>If no data or all values are null, set state to</label>
                                                <FormControl>
                                                    <Select
                                                        id="device-select"
                                                        value={noDataCase}
                                                        onChange={(e) => setNoDataCase(e.target.value)}
                                                        size="small"
                                                        fullWidth
                                                    >
                                                        {
                                                            Object.keys(NoDataErrorOptions).map(key => <MenuItem value={NoDataErrorOptions[key]}>{key}</MenuItem>)
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={6} className='error_handling_subsection'>
                                                <label className='section_input_label'>If error while fetching data, set state to</label>
                                                <FormControl>
                                                    <Select
                                                        id="device-select"
                                                        value={errorCase}
                                                        onChange={(e) => setErrorCase(e.target.value)}
                                                        size="small"
                                                        fullWidth
                                                    >
                                                        {
                                                            Object.keys(NoDataErrorOptions).map(key => <MenuItem value={NoDataErrorOptions[key]}>{key}</MenuItem>)
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </div>
                        </div>
                        <div className={`metric_alert_configuration--footer`}>
                            <Button variant="outlined" className="" onClick={handleClickCancel}>Cancel</Button>
                            <Button variant="contained" disabled={false} className="save_config_button" onClick={handleSave}>Save</Button>
                        </div>
                    </div> : null
                }
            </Collapse>
        </div>
    )
}

function Condition({
    metricCondition,
    metric,
    handleChange
}: {
    metricCondition: MetricCondition,
    metric: string,
    handleChange: (condition: MetricCondition) => void
}) {
    const [aggregation, setAggregation] = React.useState(metricCondition.aggregation);
    const [startInterval, setStartInterval] = React.useState(convertsToMinutes(metricCondition.start_interval));
    const [endInterval, setEndInterval] = React.useState(convertsToMinutes(metricCondition.end_interval));
    const [condition, setCondition] = React.useState(metricCondition.condition);
    const [conditionValue, setConditionValue] = React.useState(metricCondition.condition_value);

    function convertsToMinutes(duration) {
        let time = parseInt(duration)
        if (duration.endsWith("h")) {
            time *= 60
        }
        return time.toString()
    }

    React.useEffect(() => {
        // validations if any will come here

        // set in parent if validated
        handleChange({
            aggregation: aggregation,
            start_interval: startInterval,
            end_interval: endInterval,
            condition: condition,
            condition_value: conditionValue
        })
    }, [aggregation, startInterval, endInterval, condition, conditionValue])

    return (
        <React.Fragment>
            <label className='section_input_label'>Calculate</label>
            <CustomSelect value={aggregation} onChange={(value) => setAggregation(value)} options={MetricAggregation} />
            <label className='section_input_label'>of Query {"("}</label>
            <div>{_MetricTypes[metric]}</div>
            <div> , </div>
            <div className='custom_text_field'>
                <TextField className='custom_text_field' size='small' variant="standard" value={startInterval} onChange={(e) => setStartInterval(e.target.value)} />
            </div> 
            <div>m</div>
            {/* <CustomSelect value={startInterval} onChange={(value) => setStartInterval(value)} options={MetricConditionStartInterval} /> */}
            <div> , </div>
            {/* <CustomSelect value={endInterval} onChange={(value) => setEndInterval(value)} options={MetricConditionEndInterval} /> */}
            <div className='custom_text_field'>
                <TextField className='custom_text_field' size='small' variant="standard" value={endInterval} onChange={(e) => setEndInterval(e.target.value)} />
            </div> 
            <div>m</div>
            <label className='section_input_label'> {")"} is </label>
            <CustomSelect value={condition} onChange={(value) => setCondition(value)} options={MetricConditionType} />
            <label className='section_input_label'> value </label>
            <div className='custom_text_field'>
                <TextField className='custom_text_field' size='small' variant="standard" type="number" value={conditionValue} onChange={(e) => setConditionValue(Number(e.target.value))} inputProps={{ inputMode: 'decimal' }} />
            </div>
        </React.Fragment>
    )
}

function SelectDuration({
    value: defaultValue,
    unit: defaultUnit,
    min: minimumValue,
    onChange: handleChange
}: {
    value: number,
    unit: string,
    min: number,
    onChange: (value: number) => void
}) {

    const [value, setValue] = React.useState(defaultValue);
    const [unit, setUnit] = React.useState(defaultUnit);

    React.useEffect(() => {
        setValue(value > minimumValue ? value : minimumValue)
    }, [minimumValue])

    React.useEffect(() => {
        handleChange(moment.duration(`PT${value}${unit}`.toUpperCase()).minutes())
    }, [value, unit])

    return (
        <div className="select_duration">
            <TextField fullWidth size='small' variant="standard" type="number" value={value} onChange={(e) => setValue(Number(e.target.value))} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: minimumValue }} />
            <div className='mid_separator'></div>
            <Select
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                size="small"
                variant='standard'
            >
                <MenuItem value={"m"}>min</MenuItem>
                <MenuItem value={"h"}>hour</MenuItem>
            </Select>
        </div>
    )
}

function CustomSelect({ value: defaultValue, options, onChange: handleChange }: { value: string, onChange: (value: string) => void, options: { [key: string]: string; } }) {
    const [value, setValue] = React.useState(defaultValue);

    React.useEffect(() => {
        if (!_.isEmpty(value)) {
            handleChange(value)
        }
    })

    return (
        <div className='custom_select'>
            <Select
                value={value}
                onChange={(e) => setValue(e.target.value)}
                size="small"
                variant="standard"
            >
                {
                    Object.keys(options).map(key => <MenuItem value={options[key]}>{key}</MenuItem>)
                }
            </Select>
        </div>
    )
}

function MoreActionMenu({ isEditing, config, handleDeleteMetricConfig }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleEdit = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
        isEditing(true)
    };

    const handleDelete = (e) => {
        e.stopPropagation();
        handleDeleteMetricConfig(config.id);
    };

    return (
        <React.Fragment>
            <IconButton aria-label="more-options" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="metric-configuration-more-options"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'more-action-button',
                }}
                PaperProps={{
                    className: 'metric-configuration-more-options-paper'
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleEdit}>Edit</MenuItem>
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
        </React.Fragment>
    )
}


const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer
});

export default withRouter(
    connect(mapStateToProps, {
        getMetricData,
        getSiteInterfaces
    })(MetricAlertConfiguration)
);