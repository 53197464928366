import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import ConfirmDialog from "../ConfirmDialog";
import { useState } from "react";
import close from "../../asset/image/close.svg";
import { CONFIRM_DIALOG_TITLE } from "../../config";
import _ from "lodash";
import "./SoftwareUpgrade.css";
import { upgradeDevicetoLatestEOSversion } from "../../actions/Users/authenticateInventory"

const SoftwareUpgrade = (props: any) => {
    const { authReducer, onClose, selectedSiteId, selectedSiteName, selectedDevice, currentEOSversion, latestEOSversion, upgradeDevicetoLatestEOSversion } = props;

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: {},
    });

    const handleUpgrade = () => {
        upgradeDevicetoLatestEOSversion(selectedSiteId,selectedDevice.id)
        onClose()
    }

    return (
        <div className="Form">
            <Dialog
                disableEnforceFocus
                open={true}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                className='config-deploy-inventory-popup inventory-upgradepopup'
            >
                <div>
                    <DialogTitle className='create-default-config-title'>
                        <span>EOS Upgrade</span>
                        <img src={close} title="Close" onClick={onClose} />
                    </DialogTitle>
                    <DialogContent>
                        <Grid container className="deploy-inventory-content">
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-apply-config-target" >
                                <span>Target</span>
                                <Grid>
                                    <div><div className="key">Site: </div><div className="value" title={selectedSiteName}>{selectedSiteName}</div></div>
                                    <div><div className="key">Device: </div><div className="value" title={selectedDevice.name}>{selectedDevice.name}</div></div>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy select-config-from" >
                                <span>Current EOS Version</span>
                                <Grid className="configuration-form-build EOS-version">
                                {!_.isEmpty(currentEOSversion) && currentEOSversion.toLowerCase().startsWith("v")?currentEOSversion:"v"+currentEOSversion}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy select-config-from" >
                                <span>Latest EOS Version</span>
                                <Grid className="configuration-form-build EOS-version">
                                {!_.isEmpty(latestEOSversion) && latestEOSversion.toLowerCase().startsWith("v")?latestEOSversion:"v"+latestEOSversion}
                                </Grid>
                            </Grid>

                        </Grid>
                    </ DialogContent>
                    <DialogActions className="config-create-actions actions">
                        <Button className="config-cancel-btn" onClick={onClose}>Cancel</Button>
                        <Button className="config-create-btn"
                            onClick={() => {
                                setConfirmDialog({
                                    isOpen: true,
                                    title: CONFIRM_DIALOG_TITLE,
                                    subTitle: "Are you sure to upgrade the device " + selectedDevice.name + " to latest "+latestEOSversion+" ?",
                                    onConfirm: () => {
                                        handleUpgrade()
                                    },
                                })
                            }}
                        >Upgrade</Button>
                    </DialogActions>
                </div>
            </Dialog>
            <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {
        upgradeDevicetoLatestEOSversion
    })(SoftwareUpgrade)
);