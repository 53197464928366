import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './../../components/Table/table.css';


export default function VsatMetricsTable(props:any) {
  const { vsatMetricsInfo, isModemEnabled, isOperatorEnabled } = props;
  const headingList = ["Name","satellite","Beam Id"];


  return (
    <>
    <TableContainer className="mccMncTable" component={Paper}>
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow className="vsatTableRow">
            {headingList.map((item:any) => {
              return <TableCell className="tableHeader">{item}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {vsatMetricsInfo !== undefined && vsatMetricsInfo.length> 0 && vsatMetricsInfo.map((row:any) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >

              {isModemEnabled && <TableCell component="th" scope="row">
                {row.modemId} </TableCell>}
              
              <TableCell >{row.name}</TableCell>
              <TableCell >{row.satellite}</TableCell>
              <TableCell >{row.beam}</TableCell>
              
            </TableRow> 
          ))}
        </TableBody>
      </Table>
      {!(vsatMetricsInfo !== undefined && vsatMetricsInfo.length> 0) && <div className="noData">No data</div> }
    </TableContainer>
    </>
  );
}