import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import { Button } from "@mui/material";
import "./ConfirmDialog.css";

export default function ConfirmDialog(props) {

    const { confirmDialog, setConfirmDialog, setMobilePriority, checked, isMobilePriority } = props;
    const handleConfirmClick = () => {
        // close confirm dialog
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        // call back function for onlcick of YES
        confirmDialog.onConfirm();
    }

    const handleClose = () => {
        isMobilePriority && setMobilePriority(checked);
        setConfirmDialog({ ...confirmDialog, isOpen: false });
    }

    return (
        <Dialog open={confirmDialog.isOpen} style={{background: "rgba(38, 76, 134, 0.75)"}} className="custom_confirm_dialog">
            <DialogTitle className='inter-font-parent confirm-pad' style={{background:"#f5f5f5"}}>
                <div className="inter-font">
                    {confirmDialog.title}
                </div>
            </DialogTitle>
            <DialogContent style={{padding:"8px, 16px", paddingTop:"20px"}}>
                <div className="custom_confirm_dialog_sub_title">
                    {confirmDialog.subTitle}
                </div>
            </DialogContent>
            <DialogActions style={{ marginLeft: "18px", marginRight: "20px", marginBottom: "5px"}}>
                <Button className="CancelRoleEditButton" onClick={handleClose}> Back </Button>
                <Button className="role-button" onClick={() => handleConfirmClick()}> Proceed </Button>
            </DialogActions>
        </Dialog>
    )
}