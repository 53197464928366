export interface Site {
    id: string;
    name: string;
}

export interface Device {
    id: string;
    site_id: string;
    name: string;
    interfaces: DeviceInterface[];
    quotas?: Quota[];
}

export interface DeviceInterface {
    alias: string;
    wan_type: string;
}

export interface Quota {
    id?: string;
    site_id: string;
    site_name?: string;
    device_id: string;
    device_name?: string;
    wan_links?: string[];
    access_networks?: string[];
    access_networks_name?: string[];
    starlink_quota?: StarlinkQuota;
    limit: number;
    period: number;
    period_unit: string;
    period_display?: string;
    start_time: string;
    actions?: Action[];
    current_quota_start_time?: string;
    current_usage?: string;
    current_usage_updated?: string;
    used_percent?: string;
    span?: string;
    estimated_max_over_date?: string | null;
    status?: string;
    notification_methods?: string[],
    users_to_notify?: User[];
    disabled?: boolean;
}

export interface StarlinkQuota {
    service_line_numbers: string[];
    enabled: boolean;
}

export interface User {
    name: string;
    email: string;
}
export interface Action {
    id: string;
    usage_percentage: number;
    type: ActionType;
    access_networks?: string[];
    wan_profile_id?: string;
    traffic_policy_id?: string;
    interfaces?: string[];
    executed: boolean;
    valid?: boolean;
    revert?: boolean;
}

export type ActionType = 'assignWanProfile' | 'assignTrafficPolicy' | 'disableInterfaces' | 'enableInterfaces' | 'sendNotification' | '';

export interface AccessNetwork {
    id: string;
    name: string;
    wan_profile: string;
    vlan_aggregate: string;
}

export const Actions = {
    ActionAssignWanProfile: 'assignWanProfile',
    ActionAssignTrafficPolicy: 'assignTrafficPolicy',
    ActionDisableInterface: 'disableInterfaces',
    ActionEnableInterfaces: 'enableInterfaces',
    ActionNotification: 'sendNotification',
    ActionUndefined: ''
} as const;

export interface WanProfile {
    id: string;
    name: string;
}

export interface TrafficPolicy {
    id: string;
    name: string;
}

export interface StarlinkServiceLine {
    service_line_number?: string;
    nickname?: string;
}

export type QuotaSortBy = 'site_name' | 'device_name' | 'wan_links' | 'current_quota_start_time' | 'period_display' | 'used_percent' | 'status'
export type SortOrder = 'asc' | 'desc'

export const NotificationMethods = {
    'email': 'Email',
    'microsoft_teams': 'Microsoft Teams',
}