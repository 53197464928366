import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Dialog, DialogProps, DialogTitle, DialogContent, Grid, TextField, Box, Button } from "@mui/material";
import "./ContentFilterBasicDetails.css";

const ContentFilterBasicDetails = ({onSetContentFilterName, contentFilterName, onSetContentFilterComment, contentFilterComment, isEditable})=>{
    
    const [contenFilterPolicyName, setContentFilterPolicyName] = useState(contentFilterName)
    const [contenFilterPolicyComment, setContentFilterPolicyComment] = useState(contentFilterComment)
    const [isContentFilterEdit, setContentFilterEdit] = useState(false)
    
    useEffect(()=>{
        onSetContentFilterName(contenFilterPolicyName)
    }, [contenFilterPolicyName])

    useEffect(()=>{
        onSetContentFilterComment(contenFilterPolicyComment)
    }, [contenFilterPolicyComment])

    const onChangeContentFilterPolicyName = (event:any)=>{
        setContentFilterPolicyName(event.target.value)
    }

    const onChangeContentFilterPolicyComment = (event:any)=>{
        setContentFilterPolicyComment(event.target.value)
    }

    return (
        <>
            <Grid container xs={12} sm={12} md={12} lg={12} className="create-content-filter-single-form-row">
                <Grid item xs={2} sm={2} md={2} lg={2} className="create-content-filter-single-form-span-center">
                    <span>Enter Name</span>
                </Grid>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                    <Box>
                        {<TextField
                            style={{ width: "100%" }}
                            variant="outlined"
                            placeholder="Name"
                            value={contenFilterPolicyName}
                            disabled={isEditable}
                            onChange={onChangeContentFilterPolicyName}
                        />}
                    </Box>
                </Grid>
            </Grid>
            <Grid container xs={12} sm={12} md={12} lg={12} className="create-content-filter-single-form-row">
                <Grid item xs={2} sm={2} md={2} lg={2} className="create-content-filter-single-form-span-center">
                    <span>Enter Comment</span>
                </Grid>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                    <Box>
                        {<TextField
                            style={{ width: "100%" }}
                            variant="outlined"
                            multiline={true}
                            rows={15}
                            placeholder="Comment"
                            value={contenFilterPolicyComment}
                            onChange={onChangeContentFilterPolicyComment}
                        />}
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    customContentFilter: state.customContentFilter,
})

export default withRouter(
    connect(mapStateToProps, null)(ContentFilterBasicDetails)
);  