import { FormControl, Grid, MenuItem, Select, Switch } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect } from "react";
import { getPooledUsagePie, getPooledUsagePieSplit } from './slice';
import _ from "lodash";
import { YYYYMMDD_HHmmss } from "../../utils/constants";
import moment from "moment";

const PooledPieCharts = (props) => {
    const { selectedOu, newSummaryStartDate, newSummaryEndDate, pieLimit, handleChangePieLimit, getPooledUsagePie, pieChartData, selectedServiceAccount, getPooledUsagePieSplit, toggleBtn, setToggleBtn } = props;

    useEffect(() => {
        if (!selectedServiceAccount || selectedServiceAccount.length === 0) return;
        if(toggleBtn) {
            getPooledUsagePieSplit({
                dpId: selectedOu?.id,
                limit: pieLimit,
                offset: 0,
                accounts: selectedServiceAccount
            })
        } else {
            getPooledUsagePie({
                dpId: selectedOu?.id,
                chartStartDate: (newSummaryStartDate).format(YYYYMMDD_HHmmss),
                chartEndDate: newSummaryEndDate.format(YYYYMMDD_HHmmss),
                limit: pieLimit,
                offset: 0,
                source: getUsageSource(),
                accounts: selectedServiceAccount
            })
        }
    }, [selectedOu, newSummaryStartDate, newSummaryEndDate, pieLimit, selectedServiceAccount, toggleBtn])

    const getUsageSource = () => {
        const endDate = moment(newSummaryEndDate, 'YYYY-MM-DD HH:mm:ss');
        const startDate = moment(newSummaryStartDate, 'YYYY-MM-DD HH:mm:ss');

        const hoursDifference = moment.duration(endDate.diff(startDate)).asHours();
        if (hoursDifference <= 24) {
            return 'agg_source';
        } else {
            return 'direct_source';
        }
    }

    const pieOptions = {
        chart: {
            type: 'pie',
            height: '185px',
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
            backgroundColor: 'none'
        },
        title: {
            text: ''
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        tooltip: {
            enabled: true,
            headerFormat: ``,
            pointFormat: `<span style="color: #7e8089">{point.x}</span> <br/> <b style="color: #fff">{point.z}</b><br/>`,
            valueDecimals: 0,
            useHTML: true,
            borderRadius: 15,
            backgroundColor: '#060606',
            followPointer: false
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                shadow: false,
            }
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        series: [{
            type: 'pie',
            data: pieChartData,
            size: '100%',
            innerSize: '50%',
            dataLabels: {
                enabled: false
            }
        }]
    }

    const handleChangeToggle = (e) => {
        const value = e.target.checked;
        setToggleBtn(value);
    }

    return (
        <Grid>
            <Grid className="new-summary-control-limit title-toggle-btn-div">
                <Grid className="title-dropdown-pie-chart" style={{ gap: "0.5em" }}>
                    <div className="title-text">Usage</div>
                    <Grid className="pieChat-selectTag">
                        <FormControl variant="standard" className='selectEntry-pie'>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={pieLimit}
                                onChange={handleChangePieLimit}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid>
                    <span className="title-text-left">Org</span>
                    <Switch
                        checked={toggleBtn}
                        onChange={handleChangeToggle}
                        inputProps={{ 'aria-label': 'controlled' }}
                        size="small"
                    />
                     <span className="title-text-right">Pool</span>
                </Grid>
            </Grid>
            <HighchartsReact
                highcharts={Highcharts}
                options={pieOptions}
            />
            <Grid container className="usage-details-pie-perSite" id={pieChartData?.length == 1 ? 'justify-content-center' : 'justify-content-start'}>
                {pieChartData && pieChartData.map((usage, i) => (
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className="summary-pie-usage-dot" style={{ background: usage.color }}></div>
                        <span className="summary-usage-value">{usage.x}</span>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    selectedOu: state.authReducer?.selectedOu,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    selectedServiceAccount: state?.authReducer?.selectedServiceAccount
});

export default withRouter(connect(mapStateToProps, { getPooledUsagePie, getPooledUsagePieSplit })(PooledPieCharts));