import { Button, Dialog, Grid, Paper, Typography } from "@mui/material";
import { Cancel, Delete } from "@material-ui/icons";

interface DeleteReportConfirmationDialogProps {
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}

export function DeleteReportConfirmationDialog(props: DeleteReportConfirmationDialogProps) {
    const { open, onConfirm, onCancel } = props;

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Paper elevation={0} square={true} classes={{ root: 'quota_mangement--base--padding_1x' }}>
                <Typography variant="body2" classes={{ root: 'quota_management--base--font_2x' }}>
                    Are you sure you want to delete this Report?
                </Typography>
                <Grid container spacing={3} classes={{ root: 'quota_management--base--mt_1x' }}>
                    <Grid item xs={6}>
                        <Paper elevation={0} classes={{ root: 'quota_management--base--flex--justify--end quota_mangement--base--padding_1x' }}>
                            <Button variant="outlined" color="error" onClick={onCancel} startIcon={<Cancel />}>
                                No
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper elevation={0} classes={{ root: 'quota_management--base--flex--justify--start quota_mangement--base--padding_1x' }}>
                            <Button variant="outlined" color="primary" onClick={onConfirm} startIcon={<Delete />}>
                                Yes
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
        </Dialog>
    )
}