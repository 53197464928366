// SubHeader for the Alert page
import React, { useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Line from "../../asset/image/Line.png";
import { Grid, MenuItem, Menu, IconButton } from "@mui/material";
import vector from "../../asset/image/Vector.png";
import onlineStatus from "../../asset/image/online.svg";

const SubHeaderReport = () => {
  // const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // const alertTop = [{ label: "All vessels" }, { label: "Axe vessel" }, { label: "Bella vessel" }, { label: "Moka vessel" }, { label: "Namaste vessel" }];
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Grid
      style={{
        backgroundColor: "#FAF9FC",
        flexDirection: "column",
        width: "-webkit-fill-available",
        zIndex:1
      }}
    >
      <div>
        <Toolbar>
          <Typography
            style={{
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "25.1px",
              color: "#676984",
            }}
            component="div"
          >
            Reports
          </Typography>
          <img src={Line} style={{ marginLeft: "24px" }} />
          <Typography style={{fontWeight:500, fontSize:"18px", lineHeight:"22.59px", color:"#676984", marginLeft:"24px"}} component="div">
          Namaste Site
          </Typography>
            <img  src={vector} style={{marginLeft:"8px"}} onClick={handleMenu}/>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
            style={{ top: "20px"}}
          >
          </Menu>
          <img  src={onlineStatus} style={{marginLeft:"auto"}}/>
        </Toolbar>
      </div>
    </Grid>
  );
}

export default SubHeaderReport;