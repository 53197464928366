import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FormControl, Grid, MenuItem, Select, Tooltip } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { getContentFilterCategoryPie, getContentFilterApplicationPie, getContentFilterSubCategoryPie } from '../../actions/Users/authenticateContentFilter';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { getDecodeURI, getEncodedURI } from "../../utils/util";

const PieChart = (props) => {
    const { authReducer, values, setValues, applyFilterClick, newSummaryStartDate, newSummaryEndDate, location, history } = props;
    const { setCategory, setApp, setPieChartLimit, setSubCategory, setTrackDataLimit } = setValues;
    const { vessels, category, app, user, vlan, device, subCategory, pieChartLimit } = values;
    const [categoryData, setCategoryData] = useState<any>([]);
    const [applicationData, setApplicationData] = useState<any>([]);
    const [subCategoryData, setSubCategoryData] = useState<any>([]);

    useEffect(() => {
        if(vessels.length == 0) {
            setCategoryData([]);
            setSubCategoryData([]);
            setApplicationData([]);
        }
     }, [vessels])

    useEffect(() => {
       if(!_.isEmpty(authReducer.getContentFilterCategoryPie)) {
            const category = authReducer.getContentFilterCategoryPie.hasOwnProperty('data') ? authReducer.getContentFilterCategoryPie?.data : {};
            const categoryData:any = [];
            category?.rows?.forEach((item, i) => {
                if(i < pieChartLimit) {
                    categoryData.push({x: item[0], y: item[1], z: item[2]});
                }
            })
            setCategoryData(categoryData)
        }
        if(!_.isEmpty(authReducer.getContentFilterSubCategoryPie)) {
            const subCategory = authReducer.getContentFilterSubCategoryPie.hasOwnProperty('data') ? authReducer.getContentFilterSubCategoryPie?.data : {};
            const subCategoryData:any = [];
            subCategory?.rows?.forEach((item, i) => {
                if(i < pieChartLimit) {
                    subCategoryData.push({x: item[0], y: item[1], z: item[2]})
                }
            })
            setSubCategoryData(subCategoryData)
        }
        if(!_.isEmpty(authReducer.getContentFilterApplicationPie)) {
            const application = authReducer.getContentFilterApplicationPie.hasOwnProperty('data') ? authReducer.getContentFilterApplicationPie?.data : {};
            const applicationData:any = []
             application?.rows?.forEach((item, i) => {
                if(i < pieChartLimit) {
                    applicationData.push({x: item[0], y: item[1], z: item[2] })
                }
            })
            setApplicationData(applicationData)
        }
    }, [authReducer.getContentFilterCategoryPie, authReducer.getContentFilterSubCategoryPie, authReducer.getContentFilterApplicationPie])

    const categoryOptions: Highcharts.Options = {
        chart: {
          type: 'pie',
          height: '185px',
          style: {
              fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
          },
          backgroundColor: 'none'
        },
        colors: ["#1b51a7", "#8cb5ec", "#0e0548", "#1d136a", "#3e88d3", "#86c6df", "#4b91f1", "#64a1f4", "#8dbdff", "#c1d5f6"],
        title: {
            text: ''
        },
        yAxis: {
            title: {
                text: 'App Category Used'
            }
        },
        tooltip: {
          enabled:true,
          useHTML: true,
          headerFormat: ``,
          pointFormat: '<tr>' + 
              `<td style="text-align: left;display:block"><b>{point.x} <br/> Hits : {point.y}</b></td></tr>`,
          footerFormat: '</table>',
          valueDecimals: 0
        },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
                shadow: false,
          }
      },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
          series: [{
              type: 'pie',
              data: categoryData,
              size: '100%',
              innerSize: '50%',
              dataLabels: {
                  enabled: false
              }
          }]
    }

    const applicationOptions: Highcharts.Options = {
        chart: {
          type: 'pie',
          height: '185px',
          style: {
              fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
          },
          backgroundColor: 'none'
        },
        colors: ["#1b51a7", "#8cb5ec", "#0e0548", "#1d136a", "#3e88d3", "#86c6df", "#4b91f1", "#64a1f4", "#8dbdff", "#c1d5f6"],
        title: {
            text: ''
        },
        yAxis: {
            title: {
                text: 'App Category Used'
            }
        },
        tooltip: {
          enabled:true,
          useHTML: true,
          headerFormat: ``,
          pointFormat: '<tr>' + 
              `<td style="text-align: left;display:block"><b>{point.x} <br/> Hits : {point.y}</b></td></tr>`,
          footerFormat: '</table>',
          valueDecimals: 0
        },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
                shadow: false,
          }
      },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
          series: [{
              type: 'pie',
              data: applicationData,
              size: '100%',
              innerSize: '50%',
              dataLabels: {
                  enabled: false
              }
          }]
    }

    const subCategoryOptions: Highcharts.Options = {
        chart: {
          type: 'pie',
          height: '185px',
          style: {
              fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
          },
          backgroundColor: 'none'
        },
        colors: ["#1b51a7", "#8cb5ec", "#0e0548", "#1d136a", "#3e88d3", "#86c6df", "#4b91f1", "#64a1f4", "#8dbdff", "#c1d5f6"],
        title: {
            text: ''
        },
        yAxis: {
            title: {
                text: 'App Category Used'
            }
        },
        tooltip: {
          enabled:true,
          useHTML: true,
          headerFormat: ``,
          pointFormat: '<tr>' + 
              `<td style="text-align: left;display:block"><b>{point.x} <br/> Hits : {point.y}</b></td></tr>`,
          footerFormat: '</table>',
          valueDecimals: 0
        },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
                shadow: false,
          }
      },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
          series: [{
              type: 'pie',
              data: subCategoryData,
              size: '100%',
              innerSize: '50%',
              dataLabels: {
                  enabled: false
              }
          }]
    }

    const colors= ["#1b51a7", "#8cb5ec", "#0e0548", "#1d136a", "#3e88d3", "#86c6df", "#4b91f1", "#64a1f4", "#8dbdff", "#c1d5f6", 
   "#1b51a7", "#8cb5ec", "#0e0548", "#1d136a", "#3e88d3", "#86c6df", "#4b91f1", "#64a1f4", "#8dbdff", "#c1d5f6"
   ];

    const handleChange = (row, title) => {
        const value = row.x;
        switch (title) {
            case 'Category': {
                setCategory(value);
                applyFilterClick(newSummaryStartDate, newSummaryEndDate, vessels, value, app, user, vlan, device, subCategory);
                break;
            }
            case 'Domain': {
                setApp(value);
                applyFilterClick(newSummaryStartDate, newSummaryEndDate, vessels, category, value, user, vlan, device, subCategory);
                break;
            }
            case 'Sub Category': {
                setSubCategory(value);
                applyFilterClick(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, value);
            }
        }
    }

    const handleChangeLimit = (e) => {
        const value = e.target.value;
        setPieChartLimit(value);
        setTrackDataLimit(true);
        let params: any = getDecodeURI(location.search);
        value == 10 ? delete params.pieLimit : params.pieLimit = value;
        doNavigate(params);
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    return (
        <Grid container className="Form">
            <Grid className="pie-summary">Summary</Grid>
            <Grid className="pieChat-selectTag pie-chart-limit-div-cf">
                <FormControl variant="standard" className='selectEntry-pie'>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={pieChartLimit}
                        onChange={handleChangeLimit}
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6} lg={4} className="content-filter-pie-chart">
                    <PieChartData options={categoryOptions} title={'Category'} data={categoryData} handleChange={handleChange} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} className="content-filter-pie-chart">
                    <PieChartData options={subCategoryOptions} title={'Sub Category'} data={subCategoryData} handleChange={handleChange} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} className="content-filter-pie-chart">
                    <PieChartData options={applicationOptions} title={'Domain'} data={applicationData} handleChange={handleChange} />
                </Grid>
            </Grid>
        </Grid>
    )

    function PieChartData ({options, title, data, handleChange}) {

        return (
            <div className="Form pie-container-cf">
                <Grid className="am-table">
                    <Grid className="totalUsageChartContainer">
                        <Grid>
                            <Grid>
                                <div className="rc--filter_pie-usage piechart-selectContainer">
                                    <Grid className="pie-content-title">{title}</Grid>
                                </div>
                            </Grid>
                            <Grid className="usage-doughnutChart usage-activity-pie">
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={options}
                                />
                            </Grid>
                        </Grid>
                        <Grid className="content-filter-pie-chart-div">
                            <Grid className="usageDetailsWithCategory">
                                {
                                    data && data.map((ele,i) => (
                                        <Grid key={i} className="appcategoryUsed-usage">
                                            <div style={{background:`${colors[i]}`}} className="usageDot-1 con-filt-dot"></div>
                                            <Grid className="pieChart-activity cont-filt-pie-text">
                                                <Grid className="usagepercentage">{data && `${ele.z < 1 ? ele.z < 0.01 && ele.z !== 0 ? 0.01: Math.round(ele.z * 100) / 100: Math.round(ele.z)}%`}</Grid>
                                                <Tooltip title={ele.x}><p className="content-filter-filt" onClick={() => handleChange(ele, title)}>{ele.x}</p></Tooltip>
                                            </Grid>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    newSummaryStartDate: state.authReducer.newSummaryStartDate,
    newSummaryEndDate: state.authReducer.newSummaryEndDate,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, { getContentFilterCategoryPie, getContentFilterApplicationPie, getContentFilterSubCategoryPie })(PieChart)
);