import { api } from '../../../../../src/server/request';
import { fetchDataRequest, fetchDataSuccess, fetchTowerDetailsSuccess, fetchDataError, fetchVesselNameSuccess, fetchVesselVoyageSuccess, fetchVesselKpiDataSuccess, fetchCarrierDataSuccess, fetchCarrierDataInnerLayerSuccess, fetchVesselCurrentLocationSuccess, fetchVesselMetricsInfoSuccess, fetchUniqueCarrierSuccess, fetchVesselVoyageRequest, fetchVesselKpiDataRequest, fetchSelctedVesselVoyageSuccess, fetchHexSinrHistoSuccess, fetchHexRssiHistoSuccess, fetchHexSpeedHistoSuccess, fetchVesselCurrentLocationRequest, setApiErrors, deleteApiError, fetchVsatCoverageSuccess, fetchVsatCoverageInnerLayerDataSuccess, fetchVSATHistoSuccess, fetchVSATTxPowerHistoSuccess, fethcVSATAllHexMetricsSuccess, clearMapErros, fetchGetEdgeEventsSucesss, fetchVesselDataRateSuccess, fetchVesselDataRTTSuccess, fetchStarLinkSuccess, fetchStarLinkSpeedSuccess, fetchCarrierDataRequest, fetchCarrierDataInnerLayerRequest, fetchVsatCoverageRequest, fetchStarLinkRequest, fetchStarLinkSpeedRequest } from "./action";

export function carrierGeoJson250sqkm(datasource:string,career: string, resolution: number, layer: string) {
    const hexURL = `https://cdn.k4mobility.com/cdn/${datasource}_${layer}_${resolution}_${career}.json`;
    return (dispatch: any) => {
      dispatch(fetchCarrierDataRequest());
      api.getRequestForExternalApiWithoutHeader(`${hexURL}`)
      .then((res) =>  {
        dispatch(fetchCarrierDataSuccess(res));
      })
      .catch((err) => {

      })
    }
  }

  export function carrierGeoJson5sqkm(datasource:string,career: string, resolution: number, layer: string) {
      const hexURL = `https://cdn.k4mobility.com/cdn/${datasource}_${layer}_${resolution}_${career}.json`;
      return (dispatch: any) => {
        dispatch(fetchCarrierDataInnerLayerRequest());
        api.getRequestForExternalApiWithoutHeader(`${hexURL}`)
        .then((res) =>  {
          dispatch(fetchCarrierDataInnerLayerSuccess(res))
        })
        .catch((err) => {
  
        })
      }
    }

    export function vsatCoverage(datasource:string,career: string, resolution: number, layer: string) {
        const hexURL = `https://cdn.k4mobility.com/cdn/${datasource}_${layer}_${resolution}_${career}.json`;
        return (dispatch: any) => {
          dispatch(fetchVsatCoverageRequest());
          api.getRequestForExternalApiWithoutHeader(`${hexURL}`)
          .then((res) =>  {
            dispatch(fetchVsatCoverageSuccess(res))
          })
          .catch((err) => {
    
          })
        }
      }

      export function StartlinkCoverageWithThree(datasource:string,resolution: number, layer: string) {
        const hexURL = `https://cdn.k4mobility.com/cdn/${datasource}_${layer}_${resolution}_Starlink.json?=`;
        return (dispatch: any) => {
          dispatch(fetchStarLinkRequest());
          api.getRequestForExternalApiWithoutHeader(`${hexURL}`)
          .then((res) =>  {
            dispatch(fetchStarLinkSuccess(res))
          })
          .catch((err) => {
    
          })
        }
      }

      export function StarLinkCoverageWithFour(datasource:string,resolution: number, layer: string) {
        const hexURL = `https://cdn.k4mobility.com/cdn/${datasource}_${layer}_${resolution}_Starlink.json`;
        return (dispatch: any) => {
          dispatch(fetchStarLinkSpeedRequest());
          api.getRequestForExternalApiWithoutHeader(`${hexURL}`)
          .then((res) =>  {
            dispatch(fetchStarLinkSpeedSuccess(res))
          })
          .catch((err) => {
    
          })
        }
      }
