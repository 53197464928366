import { useEffect, useState, useMemo, useRef } from "react";
import { connect } from "react-redux";
import { useLeafletContext } from "@react-leaflet/core";
import L from "leaflet";
import './Map.css';
import "leaflet.vectorgrid";
import moment from "moment";
import { SET_SELECTED_HEX } from "../../actions/actionType";
import { getSingleRecordChartLable } from "../../util/util";

const LocationGrid = (props) => {
  const { layerContainer, map } = useLeafletContext();
  const { assets, hexOpacity, isEventsEnabled, handleHexogonClick, h3Index, setHexId, selectedHexId, zindex, resolution, userTimezone,isChartClosed } = props;
  const data = assets;
  const [selectedHexLocal, setSelectedHexLocal] = useState(0); // Track selected hex ID
  useEffect(() => {
    if(isChartClosed== false) {
      vectorGrid.resetFeatureStyle(selectedHexLocal);
    }
  },[isChartClosed])

  const hoveredHexId = useRef(null); // Track the current hex on hover
  const currentPopup = useRef(null); // Track the current popup
  const removePopupTimeout = useRef(null); // Timeout for removing the popup

  const vectorGrid = useMemo(() => {
    return L.vectorGrid.slicer(data, {
      zIndex: zindex,
      vectorTileLayerStyles: {
        data: {
          fill: true,
          fillColor: "#FFB347",
          color: '#FFB347',
          weight: 0.3,
        },
      },
      getFeatureId: (e) => e?.properties?.id,
      interactive: true,
    });
  }, [data, hexOpacity]);

  useEffect(() => {
    const handleClick = (e) => {
      const { id, h3index } = e?.layer?.properties;
      let firsTime = e?.layer?.properties?.fisrt_time
      let LastTime = e?.layer?.properties?.last_time
      if (isEventsEnabled) {
        // Reset previous selected hex style only if a new hex is clicked
        if (selectedHexLocal && selectedHexLocal !== id) {
          vectorGrid.resetFeatureStyle(selectedHexLocal);
        }

        if(selectedHexLocal && selectedHexLocal == id) {
          vectorGrid.resetFeatureStyle(selectedHexLocal);
          h3Index(h3index);
          handleHexogonClick(e, firsTime,LastTime);
          setHexId(null);
          setSelectedHexLocal(null);
          return
        }

        // Apply style for clicked hex
        vectorGrid.setFeatureStyle(id, {
          fill: true,
          color: '#FFFFFF', // Highlight color for selected hex
          weight: 2,
          fillOpacity: 0.5,
        });

        // Update selected hex state
        h3Index(h3index);
        handleHexogonClick(e, firsTime,LastTime);
        setHexId(id);
        setSelectedHexLocal(id);
      }
    };

    const handleMouseOver = (e) => {
      const { id, fisrt_time, last_time } = e.layer.properties;

      // Skip re-styling if hovering over the selected hex
      if (id === selectedHexLocal || (hoveredHexId.current === id && currentPopup.current)) return;

      if (hoveredHexId.current && hoveredHexId.current !== id) {
        if (selectedHexLocal !== hoveredHexId.current) {
          vectorGrid.resetFeatureStyle(hoveredHexId.current);
        }
        if (currentPopup.current) {
          currentPopup.current.remove();
          currentPopup.current = null;
        }
      }

      hoveredHexId.current = id;

      // Highlight hex on hover
      vectorGrid.setFeatureStyle(id, {
        fill: true,
        color: '#FFFFFF',
        weight: 1.5,
      });

      const popupContent = `
        <div>
          <div> Start - ${getSingleRecordChartLable(moment(fisrt_time).valueOf(), userTimezone)}</div>
          <div> End - ${getSingleRecordChartLable(moment(last_time).valueOf(), userTimezone)}</div>
        </div>
      `;
      currentPopup.current = L.popup({
        closeButton: false,
        autoClose: false,
        className: 'hex-popup',
      }).setLatLng(e.latlng).setContent(popupContent);

      map.openPopup(currentPopup.current);
    };

    const handleMouseOut = (e) => {
      const { id } = e.layer.properties;

      // Clear any delayed removal of the popup
      if (removePopupTimeout.current) {
        clearTimeout(removePopupTimeout.current);
      }

      removePopupTimeout.current = setTimeout(() => {
        if (hoveredHexId.current === id && id !== selectedHexLocal) {
          if(selectedHexLocal !== hoveredHexId.current && selectedHexLocal !== id) {
            vectorGrid.resetFeatureStyle(id);
          }
  
          if (currentPopup.current) {
            currentPopup.current.remove();
            currentPopup.current = null;
          }
          hoveredHexId.current = null;
        }
      }, 2000); // Adjust delay as needed
    };

    vectorGrid.on("click", handleClick);
    vectorGrid.on("mouseover", handleMouseOver);
    vectorGrid.on("mouseout", handleMouseOut);

    return () => {
      vectorGrid.off("click", handleClick);
      vectorGrid.off("mouseover", handleMouseOver);
      vectorGrid.off("mouseout", handleMouseOut);

      if (currentPopup.current) currentPopup.current.remove();
      currentPopup.current = null;
      if (removePopupTimeout.current) clearTimeout(removePopupTimeout.current);
    };
  }, [isEventsEnabled, h3Index, selectedHexLocal, handleHexogonClick, resolution, setHexId, map]);

  const container = layerContainer || map;

  useEffect(() => {
    container.addLayer(vectorGrid);
    return () => {
      container.removeLayer(vectorGrid);
    };
  }, [data, hexOpacity, container, vectorGrid]);

  return null;
};

const mapStateToProps = (state) => ({
  selectedHexId: state?.products?.selectedHexId
});

const mapDispatchToProps = (dispatch) => ({
  setHexId: (hexIndexId) => dispatch({ type: SET_SELECTED_HEX, item: hexIndexId })
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationGrid);
