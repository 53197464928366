import React, { useState } from 'react';
import {Tooltip } from "@mui/material";

function ImageWithFallback({ app, src, fallbackSrcs }) {
  const [imageSrc, setImageSrc] = useState(src);

  const handleImageError = () => {
    const nextFallbackSrc = fallbackSrcs.shift();
    if (nextFallbackSrc) {
      setImageSrc(nextFallbackSrc);
    }
  };

  return (
    <Tooltip title={app}>
        <img  className="logo-activity paddingLogo"
        src={imageSrc}
        onError={handleImageError}
        alt="Image"
        />
    </Tooltip>
  );
}

export default ImageWithFallback;