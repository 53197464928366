import React, { Fragment, HTMLAttributes, ReactNode, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@mui/material/TextField";
import Autocomplete, { AutocompleteRenderOptionState } from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import { getDPList } from "../../actions/Users/authenticateInventory";
import _ from "lodash";
import { Grid, Typography, Chip } from "@mui/material";
import "./index.css";

interface Organization {
  id: string;
  name: string;
  parent_of: Organization[];
}

export interface OrgOption {
  id: string;
  name: string;
  level?: number;
  hasChildren?: boolean;
}

export interface OrgSelectionProps {
  authReducer: any;
  label: string;
  value?: OrgOption | OrgOption[];
  disabled?: boolean;
  multiple?: boolean;
  dpIdsToKeep: string[];

  onChange: (org: OrgOption | OrgOption[] | null | undefined) => void;
  getDPList: (search?: string) => void;
}

function OrgSelectionMultiple(props: OrgSelectionProps) {
  const { authReducer, label, value, disabled, onChange, getDPList, multiple, dpIdsToKeep } = props;

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly OrgOption[]>([]);

  const filterOptionsRecursively = (orgs: Organization[], idsToKeep: Set<string>, level = 0): OrgOption[] => {
    const options: OrgOption[] = [];

    orgs.forEach((org) => {
      if (idsToKeep.has(org.id)) {
        options.push({
          id: org.id,
          name: org.name,
          level: level,
          hasChildren: org.parent_of && org.parent_of.length > 0,
        });

        if (org.parent_of && org.parent_of.length > 0) {
          const childOptions = filterOptionsRecursively(org.parent_of, idsToKeep, level + 1);
          options.push(...childOptions);
        }
      } else if (org.parent_of && org.parent_of.length > 0) {
        const childOptions = filterOptionsRecursively(org.parent_of, idsToKeep, level + 1);
        options.push(...childOptions);
      }
    });

    return options;
  };

  const sortOptionsByIds = (options: OrgOption[], idsToKeep: string[]) => {
    const idIndexMap = new Map(idsToKeep.map((id, index) => [id, index]));
    return options.sort((a, b) => (idIndexMap.get(a.id) || 0) - (idIndexMap.get(b.id) || 0));
  };

  useEffect(() => {
    getDPList();
  }, [open, value]);

  useEffect(() => {
    if (!_.isEmpty(authReducer?.getDPList?.dps)) {
      const idsToKeepSet = new Set(dpIdsToKeep);
      let filteredOptions = filterOptionsRecursively(authReducer.getDPList.dps, idsToKeepSet);
      filteredOptions = sortOptionsByIds(filteredOptions, dpIdsToKeep);
      setOptions(filteredOptions);
    }
  }, [authReducer.getDPList, dpIdsToKeep]);

  return (
    <Autocomplete
      id="select-organization"
      size="small"
      fullWidth
      multiple={multiple}
      value={value || (multiple ? [] : null)}
      disabled={disabled}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={authReducer.inventoryListingLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {params.InputProps.endAdornment}
              </Fragment>
            ),
            style: { maxHeight: 40 },
          }}
        />
      )}
      renderTags={(tagValue, getTagProps) => {
        if (multiple && tagValue.length > 1) {
          return (
            <div style={{display:"flex"}}>
              <Chip
                label={tagValue[0]?.name?.length > 10 ? `${tagValue[0]?.name?.slice(0, 10)}...` : tagValue[0].name}
                {...getTagProps({ index: 0 })}
                style={{ marginRight: 4, height: 24 }}
              />
              {`+${tagValue.length - 1}`}
            </div>
          );
        }
        return tagValue.map((option, index) => (
          <Chip
            label={option.name}
            {...getTagProps({ index })}
            style={{ marginRight: 4, height: 24 }}
          />
        ));
      }}
      renderOption={(
        props: HTMLAttributes<HTMLLIElement>,
        option: OrgOption,
        state: AutocompleteRenderOptionState
      ): ReactNode => {
        return (
          <li {...props} key={option.id}>
            <Grid
              style={{ marginLeft: `${option?.level ? option?.level * 5 : 0 || 0}px`, paddingLeft: "5px" }}
              classes={{ root: "starlink--base--flex--gap--05x starlink--base--flex--align--center" }}
            >
              <Grid display={"flex"} alignItems={"center"} style={{ padding: "0.1rem 0" }}>
                {multiple && (
                  <Checkbox
                    checked={Array.isArray(value) ? value.some((val) => val.id === option.id) : false}
                    style={{ marginRight: 4, padding: "2px" }}
                    size="small"
                  />
                )}
                {option?.level && option.level > 0 ? (
                  <Fragment>
                    <Grid
                      classes={{ root: "org--selection--option--pre0" }}
                      style={{ width: "0.25rem", height: "0.25rem" }}
                    />
                    <Grid
                      classes={{ root: "org--selection--option--pre1" }}
                      style={{ width: "0.25rem", height: "0.25rem" }}
                    />
                  </Fragment>
                ) : null}
                <Typography
                  component="span"
                  sx={{ marginLeft: "0.25rem", fontSize: "0.75rem" }}
                >
                  {option.name}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    getDPList,
  })(OrgSelectionMultiple)
);
