import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Tooltip } from '@mui/material';

const AutoCompleteComponent = (props) => {
  const { options, onChange, value, data } = props;
    
  return (
    <Autocomplete
      disableClearable={value ? false : true}
      ListboxProps={{ className:'config-list-box-props' }}
      className="audit-filter-inputs-usage auto-config-textField"
      size='small'
      value={value}
      onChange={onChange}
      id="combo-box-demo"
      options={[...options]}
      getOptionDisabled = {(option: any) => {
        if(data?.find((ele) => (ele.systemType == option || ele.systemType == `K4 ${option}`))){
          return true;
        }
          return false
      }}
      renderInput={(params) => <Tooltip title={value}><TextField {...params} placeholder="select" /></Tooltip>}
    />
  );
}

export default AutoCompleteComponent;