import React, { useState, useEffect } from "react";
import { Grid, Pagination, Stack, Autocomplete, TextField } from "@mui/material";
import Paper from "@material-ui/core/Paper";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI, getEncodedURI, getServiceFunction } from "../../utils/util";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { SIDE_MENU_ITEM_SERVICE_MANAGEMENT } from "../../utils/constants";
import { BC_SUBSCRIPTION_MANAGEMENT, PER_PAGE_ITEMS, DEFAULT_PAGE_SIZE } from "../../constants/Constants";
import { getVesselPlans } from "../../actions/Users/authenticateService";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import NotAuthorized from "../NotAuthorized"
import ServiceManagementTable from "../../components/ServiceManagementTable";
import { updatePermission } from "../../actions/Users/authenticate";
import _ from "lodash";

const ServiceManagment = (props) => {

  const { authReducer, getVesselPlans, location, history, updatePermission } = props;
  const setBreadcrumbInfo: any = React.useRef();
  const updateData: any = React.useRef();

  let [isAuthorizedToViewPage, setIsAuthorizedToViewPage] = useState<Boolean>(false);
  let [modifyVessels, setModifyVessels] = useState<any[]>([]);
  let [selectedVessels, setSelectedVessels] = useState<any[]>([]);
  let [total, setTotal] = useState<number>(0);
  let [page, setPage] = useState<number>(0);
  let [pages, setPages] = useState<number>(0);
  let [limit, setLimit] = useState<number>(DEFAULT_PAGE_SIZE);
  let [fromItem, setFromItem] = useState<number>(0);
  let [toItem, setToItem] = useState<number>(0);
  let [loading, setLoading] = useState(false);

  let [breadcrumbs, __] = useState(BC_SUBSCRIPTION_MANAGEMENT);
  const [allowSetLocation, setAllowSetLocation] = React.useState(false);

  const not_authorized_page_title = "Service Management";
  const not_authorized_page_message = "You are not authorised to view service plans";

  // "ViewServicePlan";
  const viewServicePlan = SERVICE_FUNCTION_TO_FEATURE["view-service-management"]["function"];

  // "ModifyServicePlan";
  const modifyServicePlan = SERVICE_FUNCTION_TO_FEATURE["edit-service-plan"]["function"];

  const servicePortal = SERVICE_FUNCTION_TO_FEATURE["view-service-management"]["service"] ? SERVICE_FUNCTION_TO_FEATURE["view-service-management"]["service"] : SERVICE_FUNCTION_TO_FEATURE["edit-service-plan"]["service"];

  if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_SERVICE_MANAGEMENT !== authReducer.sideMenu.menuItem)) {
    authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_SERVICE_MANAGEMENT };
  }

  useEffect(() => {
    setLoading(authReducer.setVesselListingLoading ||
      authReducer.vesselPlansLoading)
  }, [authReducer.setVesselListingLoading, authReducer.vesselPlansLoading])

  useEffect(() => {
    processQueryUrl();
  }, [location]);

  useEffect(() => {
    fetchVesselPlans();
  }, [selectedVessels, page, limit, authReducer.getVesselsListing]);

  useEffect(() => {
    let vessels = authReducer.selectedVessels?.vessels;
    if (vessels && Array.isArray(vessels)) {
      setSelectedVessels(vessels);
    }
  }, [authReducer.selectedVessels]);

  useEffect(() => {
    processQueryUrl();
    const isAuthorized = checkLoggedInUserAuthorizedToViewPage(servicePortal, viewServicePlan);
    setIsAuthorizedToViewPage(isAuthorized);
    prepareModifyVessels();
    prepareViewVessels(isAuthorized);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(authReducer.vesselPlans) && authReducer.vesselPlans.data) {
      if (updateData.current) {
        updateData.current(authReducer.vesselPlans);
      }
      let pages = parseInt("" + (authReducer.vesselPlans.totalCount / limit));
      if ((authReducer.vesselPlans.totalCount % limit) > 0) {
        pages++;
      }
      setPages(pages);
      setTotal(authReducer.vesselPlans.totalCount);
      setFromItem((page * limit) + 1);
      setToItem((page * limit) + authReducer.vesselPlans.data.length);
      setAllowSetLocation(true);
      authReducer.vesselPlans = {};
    }
  }, [authReducer.vesselPlans]);

  const processQueryUrl = () => {
    let _q = getDecodeURI(location?.search);
    setPage(_q.page && '' != _q.page.trim() ? parseInt(_q.page) : 0);
    setLimit(_q.limit && '' != _q.limit.trim() ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
  }

  const doNavigate = (pathname, selectedVessels, page, limit) => {
    let params: any = getDecodeURI(location?.search);
    params.page = page;
    params.limit = limit;

    history.push({ pathname: pathname, search: `?${getEncodedURI(params)}` });
  }

  const fetchVesselPlans = () => {
    if (allowSetLocation) {
      doNavigate("/service-management", selectedVessels, page, limit);
      setAllowSetLocation(false);
    }
    if ((selectedVessels && selectedVessels.length > 0) || !_.isEmpty(authReducer.getVesselsListing?.locations)) {
      let _selectedVessels: any[] = !_.isEmpty(authReducer.getVesselsListing?.locations) ? authReducer.getVesselsListing?.locations.map(vessel => vessel.id) : [];
      if (selectedVessels.length > 0) {
        _selectedVessels = []
        selectedVessels.forEach((item)=>{
          if(item["id"] !== ""){
            _selectedVessels.push(item["id"])
          }
        });
      }
      let payload = {};
      payload["k4Id"] = _selectedVessels;
      if(_selectedVessels.length > 0)
        getVesselPlans(payload, page, limit);
    } else {
      setPages(0);
      setTotal(0);
      setFromItem(0);
      setToItem(0);
      setAllowSetLocation(true);
    }
  }

  // construct vessels i.e multi-select drop-down list
  const prepareViewVessels = (isAuthorized) => {
    const info = {
      permission: {
        service: servicePortal,
        serviceFunction: viewServicePlan,
      },
      isMultiVessel: false,
      isSingleVessel: false,
      showOu: false,
      ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
    };
    setSelectedVessels([]);
    if (isAuthorized) {
      info.isMultiVessel = true;
      info.showOu = true;
    }
    updatePermission(info);
  }

  // construct modifyServicePlanVesselNames i.e Edit plan button available for which all vessels
  const prepareModifyVessels = () => {
    let modifyServiceFunction = getServiceFunction(servicePortal, modifyServicePlan);
    let vessels: any[] = [];
    if (modifyServiceFunction && modifyServiceFunction.resources && Array.isArray(modifyServiceFunction.resources) && modifyServiceFunction.resources.length > 0) {
      vessels = modifyServiceFunction.resources;
    }
    setModifyVessels(vessels)
  }

  const handlePerPageChange = (e: any) => {
    let _perPage = parseInt(e.target.textContent);
    if (Number.isInteger(_perPage)) {
      setLimit(_perPage);
      setPage(0);
    }
  }

  const handlePagination = (e, value) => {
    setPage(value - 1);
  };


  return (
    <div className="Form">
      <BreadCrumbs breadcrumbinfo={breadcrumbs} setBreadcrumbInfo={setBreadcrumbInfo} loading={loading}></BreadCrumbs>
      <Grid key="content" item container style={{ backgroundColor: "#fff" }}>
        <Grid key="content1" item style={{ width: "100%", paddingTop: "80px" }}>
          {
            isAuthorizedToViewPage
              ? [
                <Grid key="pagination-parent" container style={{ height: "46px" }}>
                  <Grid key="pagination" item xs={12} sm={12} md={12} lg={12} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: "10px", paddingRight: "25px" }}>
                    <Grid key="perpage">
                      <Grid className="vesselPageDetails">Per Page</Grid>
                      <Grid className="vesselPagination">
                        {fromItem} to  {toItem} out of {total} Sites
                      </Grid>
                    </Grid>
                    <Grid style={{ marginLeft: "10px" }}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={PER_PAGE_ITEMS}
                        className="props.classes.paper"
                        sx={{ width: 65 }}
                        disableClearable
                        onChange={(e: any) => {
                          handlePerPageChange(e);
                        }}
                        value={{ label: `${limit}` }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            InputLabelProps={{ className: "autoCompleteBox" }}
                          />
                        )}
                        PaperComponent={({ children }) => (
                          <Paper style={{ fontSize: "12px" }}>{children}</Paper>
                        )}
                      />
                    </Grid>
                    <Grid key="pagination-list" style={{ marginLeft: "10px" }}>
                      <Stack>
                        <Pagination
                          className="paginationContainer"
                          count={pages}
                          page={page + 1}
                          size="small"
                          onChange={handlePagination}
                          variant="outlined"
                          shape="rounded"
                          siblingCount={0}
                          defaultPage={1}
                          boundaryCount={1}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>,
                <ServiceManagementTable selectedVessels={selectedVessels} updateData={updateData} modifyServiePlan={modifyVessels} />
              ]
              :
              <Grid key="content-no-auth" item container style={{ position: "fixed", paddingBottom: "16px", backgroundColor: "#ffffff", zIndex: 10 }}>
                <NotAuthorized pageTitle={not_authorized_page_title} PageMessage={not_authorized_page_message} />
              </Grid>
          }
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    getVesselPlans,
    updatePermission
  })(ServiceManagment)
);