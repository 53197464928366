import { api } from "../../server/request";
import { handleResponse, handleError,handleAttachmentResponse } from "../responseUtil";
import { KAPTUREV2_URL, KAPTUREV2_URL_INV } from '../../config';
import {
    SET_LOADING,
    SET_TICKETS_LOADING,
    GET_TICKETS,
    GET_TICKET_DETAILS,
    GET_HOT_VESSELS,
    GET_TICKET_COMMENTS,
    GET_TICKET_DETAILS_FIELD,
    GET_TICKET_ATTACHMENTS,
    GET_SINGLE_TICKET_ATTACHMENTS,
    CREATE_NEW_TICKET,
    ADD_ATTACHMENTS_TO_TICKET,
    UPDATE_TICKET,
    SEND_TICKET_EMAIL,
    SEND_TICKET_COMMENT,
    GET_TICKET_HISTORY,
    GET_TICKET_AGENTS_BY_NAME,
    GET_TICKET_ALL_AGENTS,
    SET_TICKET_DETAILS_FIELD_LOADING,
    GET_DP_DATA,
    SET_TICKET_ATTACHMENTS_LOADING,
    SET_TICKET_COMMENTS_LOADING,
    SET_TICKET_HISTORY_LOADING,
    SET_TICKET_DETAILS_LOADING
} from "../types";
import { toast } from "react-toastify";
export const getTickets = (k4Ids, page, limit) => (dispatch) => {
    let payload = {};
    payload['k4Ids'] = k4Ids;
    payload['limit'] = limit;
    payload['page'] = page;

    dispatch({ type: SET_TICKETS_LOADING, payload: true });
    api
        .postRequestForExternalApi(`${KAPTUREV2_URL}/tickets/list`, payload)
        .then((res) => handleResponse(res, dispatch, GET_TICKETS))
        .catch((err) => handleError(err, dispatch));
};

export const getTicketsByFilter = (payload,isPageLoader=true) => (dispatch) => {
   isPageLoader && dispatch({ type: SET_TICKETS_LOADING, payload: true });
    api
        .postRequestForExternalApi(`${KAPTUREV2_URL}/tickets/getFilteredTickets`, payload)
        .then((res) => handleResponse(res, dispatch, GET_TICKETS))
        .catch((err) => handleError(err, dispatch));
};
  
const handlecreateNewTicket = (res, dispatch) => {
    if (res.status == 200) {
      toast.success("Ticket sent successfully", {
        position: toast.POSITION.BOTTOM_LEFT,
      })
    } else {
      toast.error(res.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      })
    }
    handleResponse(res, dispatch, CREATE_NEW_TICKET)
  }

export const createNewTicket = (payload) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    api
        .postRequestForExternalApi(`${KAPTUREV2_URL}/tickets/createTicket`, payload)
        .then((res) => handlecreateNewTicket(res, dispatch))
        .catch((err) => handleError(err, dispatch));
};

export const getTicketDetailsByTicketId = (ticketId,k4Id) => (dispatch) => {
    dispatch({ type: SET_TICKET_DETAILS_LOADING, payload: true });
    api
        .getRequestForExternalApi(`${KAPTUREV2_URL}/tickets/details?k4Id=${k4Id}&ticketId=${ticketId}`)
        .then((res) => handleResponse(res, dispatch, GET_TICKET_DETAILS))
        .catch((err) => handleError(err, dispatch));
};

export const getHotVessels = (pageNumber,pageSize,from,to) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    api
        .getRequestForExternalApi(`${KAPTUREV2_URL}/tickets/getHotVessels?pageNumber=${pageNumber}&pageSize=${pageSize}&from=${from}&to=${to}`)
        .then((res) => handleResponse(res, dispatch, GET_HOT_VESSELS))
        .catch((err) => handleError(err, dispatch));
};

export const getTicketCommentsByTicketId = (ticketId,k4Id) => (dispatch) => {
    dispatch({ type: SET_TICKET_COMMENTS_LOADING, payload: true });
    api
        .getRequestForExternalApi(`${KAPTUREV2_URL}/tickets/comments?k4Id=${k4Id}&ticketId=${ticketId}`)
        .then((res) => handleResponse(res, dispatch, GET_TICKET_COMMENTS))
        .catch((err) => handleError(err, dispatch));
};

export const getTicketFields = (ticketId) => (dispatch) => {
    dispatch({ type: SET_TICKET_DETAILS_FIELD_LOADING, payload: true });
    api
        .getRequestForExternalApi(`${KAPTUREV2_URL}/tickets/getTicketFields`)
        .then((res) => handleResponse(res, dispatch, GET_TICKET_DETAILS_FIELD))
        .catch((err) => handleError(err, dispatch));
};

export const getTicketAttachmentsByTicketId = (ticketId,k4Id) => (dispatch) => {
    dispatch({ type: SET_TICKET_ATTACHMENTS_LOADING, payload: true });
    api
        .getRequestForExternalApi(`${KAPTUREV2_URL}/tickets/attachments?k4Id=${k4Id}&ticketId=${ticketId}`)
        .then((res) => handleResponse(res, dispatch, GET_TICKET_ATTACHMENTS))
        .catch((err) => handleError(err, dispatch));
};

export const getSingleAttachmentsByTicketId = (k4Id,AttachmentId) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    api
        .getRequestForExternalApiBlob(`${KAPTUREV2_URL}/tickets/getAttachment?k4Id=${k4Id}&attachmentId=${AttachmentId}`)
        .then((res) => handleAttachmentResponse(res, dispatch, GET_SINGLE_TICKET_ATTACHMENTS))
        .catch((err) => handleError(err, dispatch));
};

export const updateTicket = (payload) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    api
        .postRequestForTicketExternalApi(`${KAPTUREV2_URL}/tickets/updateTicket`, payload)
        .then((res) => handleResponse(res, dispatch, UPDATE_TICKET))
        .catch((err) => handleError(err, dispatch));
};

export const sentTicketEmail = (payload) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    api
        .postRequestForExternalApi(`${KAPTUREV2_URL}/tickets/sendemail`, payload)
        .then((res) => handleResponse(res, dispatch, SEND_TICKET_EMAIL))
        .catch((err) => handleError(err, dispatch));
};

export const sendComment = (payload) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    api
        .postRequestForExternalApi(`${KAPTUREV2_URL}/tickets/postComment`, payload)
        .then((res) => handleResponse(res, dispatch, SEND_TICKET_COMMENT))
        .catch((err) => handleError(err, dispatch));
};

export const getTicketHistoryByTicketId = (ticketId,k4Id) => (dispatch) => {
    dispatch({ type: SET_TICKET_HISTORY_LOADING, payload: true });
    api
        .getRequestForExternalApi(`${KAPTUREV2_URL}/tickets/history?k4Id=${k4Id}&ticketId=${ticketId}`)
        .then((res) => handleResponse(res, dispatch, GET_TICKET_HISTORY))
        .catch((err) => handleError(err, dispatch));
};

export const searchTicketAssigneeByName = (name: any) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    api
        .getRequestForExternalApi(`${KAPTUREV2_URL}/tickets/getAllAgents?name=${name}`)
        .then((res) => handleResponse(res, dispatch, GET_TICKET_AGENTS_BY_NAME))
        .catch((err) => handleError(err, dispatch));
};

export const getTicketAllAgents = () => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    api
        .getRequestForExternalApi(`${KAPTUREV2_URL}/tickets/getAllAgents`)
        .then((res) => handleResponse(res, dispatch, GET_TICKET_ALL_AGENTS))
        .catch((err) => handleError(err, dispatch));
};

export const addAttachments = (formData) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    api
        .postRequestForExternalApi(`${KAPTUREV2_URL}/tickets/attachments`, formData)
        .then((res) => handleResponse(res, dispatch, ADD_ATTACHMENTS_TO_TICKET))
        .catch((err) => handleError(err, dispatch));
};

export const getDpData = (dpName) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    api
        .getRequestForExternalApi(`${KAPTUREV2_URL_INV}dp/data?dpName=${dpName}`)
        .then((res) => handleResponse(res, dispatch, GET_DP_DATA))
        .catch((err) => handleError(err, dispatch));
};