// Table of Summary vessels
import React, { useEffect, useState } from "react";
import { Grid, Accordion, AccordionSummary, Typography, AccordionDetails, IconButton, InputLabel, Button } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "../AccordionTree/AccordionTree.css";
import ChildAccrodionComp from "./ChildAccordionComp";
import Chip from '@mui/material/Chip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AndGate from "../../asset/image/merge.svg";
import OrGate from "../../asset/image/call_split.svg";
import { Alert, AlertConfigTypes, EventAlert, MetricAlert } from "../../UserScreen/CreateAlert/types";
import NormalAlert from "../../asset/image/bar_chart.svg"
import Warning from "../../asset/image/warning.svg"
import Pending from "../../asset/image/Pending.svg"
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getAlertChildInfo, updateAlert } from "../../components/AlertsDashboard/slice";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Dialog } from "@material-ui/core";
import close from "../../asset/image/close.svg";

const AccrodionComp = (props: any) => {
  const { siteId, alert, updateAlert,getAlertChildInfo, alertChildDetails,handleCloseMenuOnMouseOut }: {
    alert: Alert,
    siteId: string,
    updateAlert: (siteId: any, alert: any) => (dispatch: any) => void,
    getAlertChildInfo: any,
    alertChildDetails: any,
    handleCloseMenuOnMouseOut:any
  } = props;

  const [name, setName] = useState(alert.name)
  const [description, setDescription] = useState('')
  const [gate, setGate] = useState(alert.root_condition)
  const [updates, setUpdates] = useState<Alert>(_.cloneDeep(alert))
  const [updated, isUpdated] = useState<boolean>(false);
  const [isAccordionExapanded, setIsAccordionExapanded] = useState(false);
  const [alertLablesMoreData, setAlertLablesMoreData] = useState({});
  const alertLablesMinCount = 2;
  const [showMoreLabelsPopUP, setShowMoreLabelsPopUP] = useState(false);

  const handleAccordClick = (event, expanded, alertId) => {
    if (expanded) {
      setIsAccordionExapanded(expanded);
      // getAlertChildInfo(siteId,alertId)
      // call api for alert.id and get child objects
      // console.log("accordion expanded", expanded, alertId);
    } else {
      setIsAccordionExapanded(expanded);
    }
  }

  const handleAccordionMore = (e, alertId , ruleId) => {
    e.stopPropagation();
    props.openAccordianMenu(true,alertId, ruleId)
    props.setMenuAnchorElement(e.currentTarget)
    // implement accordion menu here
  }

  const handleCloseMenu = () => {
    props.handleCloseMenuOnMouseOut()
  }

  const handleAlertNameChange = (e) => {
    // Alert name Update
    setName(e.target.value)
    isUpdated(true)
    setUpdates({ ...updates, name: e.target.value })
  }

  const handleDescriptionChange = (e) => {
    // Alert name Update
    setDescription(e.target.value)
    isUpdated(true)
    setUpdates({ ...updates, description: e.target.value })
  }

  const handleGateChange = (e) => {
    setGate(e.target.value)
    isUpdated(true)
    setUpdates({ ...updates, root_condition: e.target.value })
  }

  const handleClickSave = () => {
    handleSave(updates);
  }

  useEffect(() => {
    if(alert) {
      setUpdates(alert);
      setName(alert?.name);
      setGate(alert?.root_condition)
      setDescription(alert?.description)
      let labelsMoreData = {};
      alert?.labels && Object.keys(alert?.labels).map((item, index,) => {
        if (index > alertLablesMinCount) {
          labelsMoreData[item] = alert?.labels ? alert?.labels[item] : "";
        }
      })
      setAlertLablesMoreData(labelsMoreData);
      setIsAccordionExapanded(false);
    }
  }, [alert])

  const handleSave = async (updates) => {
    let _updates = _.cloneDeep(updates);
    // delete status key before sending the config to backend
    delete _updates.status
    Object.keys(_updates.event_alerts).forEach(key => {
      if (_updates.event_alerts) {
        delete _updates.event_alerts[key].status
      }
    })

    // do api call to update the alert config
    updateAlert(siteId, updates);
    isUpdated(false);
  }

  const handleChangeAlertRule = (type: AlertConfigTypes, id: string, configData: EventAlert | MetricAlert) => {
    isUpdated(true)
    let _updates = _.cloneDeep(updates);
    _updates[type][id] = configData
    setUpdates(_updates)
    handleSave(_updates)
  }

  const getAlertTextColor = (alertText: any) => {
    switch (alertText.trim()) {
      case "Alerting": return "RedColor"
      case "Normal": return "GreenColor"
      case "Pending": return "OrangeColor"
      default: return "GreenColor";
    }
  }

  const getAlertIcon = (status: string | undefined) => {
    switch (status?.trim()) {
      case "Alerting": return Warning
      case "Normal": return NormalAlert
      case "Pending": return Pending
      default: return NormalAlert;
    }
  }
  const handleAlertLablesMoreClick = (event) => {
    setShowMoreLabelsPopUP(true);
    event.stopPropagation();
  }

  const handlecloseButtonClick = (event) => {
    setShowMoreLabelsPopUP(false);
    event.stopPropagation();
  }

  return (
    <div className="accordian">
      <Accordion className="alertsAccordions" expanded={isAccordionExapanded} onChange={() => {setIsAccordionExapanded(!isAccordionExapanded)}}>
        <AccordionSummary className="accordionSummaryAlert"
          expandIcon={<ExpandMoreIcon />}
        >
          <Grid item xs={5} >
            <Typography className="alertStatusAccordhead">
               <Grid item xs={4} className={getAlertTextColor(alert?.status)}>
                <div className="alertStatusWithIcon">
                  <img src={getAlertIcon(alert.status)} className="alertIcon" alt={alert.status} />
                  <span className="cursor-auto" title={alert.status}>{alert.status}</span>
                </div>
              </Grid>
              <Grid item xs={3} className="alertName cursor-auto" title={alert.name}>{alert.name} </Grid>
              <Grid item xs={1} className="gateChipContainer cursor-auto" title= {alert?.root_condition} ><Chip className="gateName" label={alert?.root_condition} /></Grid>
            </Typography>
          </Grid>
          <Grid item xs={7} className="chipsContainer display-flex">
            {alert?.labels && Object.keys(alert?.labels).map((item, index, array) => {
              if (index <= alertLablesMinCount) {
                return <Chip className="chip cursor-auto" label={<div className="display-flex"><span className="chipKey">{item} :&nbsp;</span><span className="chipValue" title={alert?.labels ? alert?.labels[item] : ""}>{alert?.labels ? alert?.labels[item] : ""}</span></div>} />
              }
            }
            )}
            {
              [!_.isEmpty(alertLablesMoreData) && alert?.labels && Object.keys(alert?.labels).length > alertLablesMinCount 
                ?
                  <div onClick={(e)=>handleAlertLablesMoreClick(e)}><Chip  className="cursor-pointer" label={<div><span className="chipKey">+{Object.keys(alert?.labels).length - 3} </span></div>} /></div>
                : "",
                <div className="Form">
                  <Dialog className="moreLabelsDialog" onClick={(e)=>handlecloseButtonClick(e)} open={showMoreLabelsPopUP}>
                    <Grid className="moreLabelsDialogCloseIconGrid" onClick={(e)=>e.stopPropagation()}>
                            <img src={close} onClick={(e) => handlecloseButtonClick(e)} className="" alt="Close" />
                    </Grid>
                    <Grid container  item xs={12} className="moreLabelsDialogGrid chipsContainer display-flex" onClick={(e)=>e.stopPropagation()}>
                      {Object.keys(alertLablesMoreData).map((item, index, array) => {
                        if (index <= alertLablesMinCount) {
                          return <Grid item >
                                  <Chip className="cursor-auto lables" label={<div className="display-flex"><span className="chipKey">{item} :&nbsp;</span><span className="chipValue" title={alert?.labels ? alert?.labels[item] : ""}>{alert?.labels ? alert?.labels[item] : ""}</span></div>} />
                                 </Grid>
                        }
                      }
                      )}
                    </Grid>
                  </Dialog>
                </div>
              ]
            }
          </Grid>
          <ClickAwayListener onClickAway={handleCloseMenu}>
            <IconButton className="accordionThreeDotMenu" onClick={(e) => handleAccordionMore(e,  alert.id ,"")}><MoreVertIcon></MoreVertIcon></IconButton>
          </ClickAwayListener>
        </AccordionSummary>
        <AccordionDetails>
          <Box className="gateSection">

            <Grid item xs={3}>
              <InputLabel className="alertNameHead">Name of the Alert</InputLabel>
              <TextField id="outlined-name" className="alertNameText" value={name} onChange={handleAlertNameChange} />
            </Grid>
            <Grid item xs={6}>
              <InputLabel className="alertNameHead">Description</InputLabel>
              <TextField id="outlined-description" className="alertDescriptionText" value={description} onChange={handleDescriptionChange} 
              inputProps={{
                maxLength:100
              }}
              InputProps={{
                readOnly: alert?.labels?.type === "User" ? false : true,
              }}/>
            </Grid>
            <Grid item xs={3}>
              <InputLabel className="alertNameHead">Select Event to start with</InputLabel>
              <div className="gateContainer" onChange={handleGateChange}>
                <Box className="gateBox">
                  <img src={AndGate} alt="AND GATE"></img>
                  <InputLabel className="andGateText">AND</InputLabel>
                  <div className="radioButton"><input type="radio" name={`${alert.id}-gate`} value="and" checked={gate === "and"} /></div>
                </Box>
                <Box className="gateBox">
                  <img src={OrGate} alt="AND GATE"></img>
                  <InputLabel className="andGateText">OR</InputLabel>
                  <div className="radioButton"><input type="radio" name={`${alert.id}-gate`} value="or" checked={gate === "or"} /></div>
                </Box>
              </div>
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" disabled={!updated} onClick={handleClickSave}>Save</Button>
            </Grid>
          </Box>
          <Box className="accordionHead">
            <Grid className="eventsHeading">
              <Typography className="rulesHead">List of alert rules</Typography>
            </Grid>
            <Grid className="accordionTableHeader">
              <Grid item xs={2} className="eachAccordionHeadItem accordionFirstItem">
                <Typography className="eachAccordColHead">STATUS</Typography>
              </Grid>
              <Grid item xs={2} className="eachAccordionHeadItem">
                <Typography className="eachAccordColHead">NAME</Typography>
              </Grid>
              <Grid item xs={6} className="eachAccordionHeadItem">
              </Grid>
              <Grid item xs={2} className="eachAccordionHeadItem">
                <Typography className="eachAccordColHead">ACTIONS</Typography>
              </Grid>
            </Grid>
          </Box>
          <ul>
            {isAccordionExapanded && alert?.event_alerts && Object.keys(alert.event_alerts).length > 0 && Object.keys(alert.event_alerts).map((id) => {
              return <li><ChildAccrodionComp key={id} alertId={alert.id} ruleId={id} ruleType={AlertConfigTypes.Event} configData={alert.event_alerts[id]} siteId={siteId} setMenuAnchorElement={props.setMenuAnchorElement} openAccordianMenu={props.openAccordianMenu} handleChange={handleChangeAlertRule}></ChildAccrodionComp></li>
            })}
            {isAccordionExapanded && alert?.metric_alerts && Object.keys(alert.metric_alerts).length > 0 &&  Object.keys(alert.metric_alerts).map((id) => {
              return <li><ChildAccrodionComp key={id} alertId={alert.id} ruleId={id} ruleType={AlertConfigTypes.Metric} configData={alert.metric_alerts[id]} siteId={siteId} setMenuAnchorElement={props.setMenuAnchorElement} openAccordianMenu={props.openAccordianMenu} handleChange={handleChangeAlertRule}></ChildAccrodionComp></li>
            })
            }
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

const mapStateToProps = (state) => ({
  alertChildDetails: state?.alertsDashboard?.alertChildDetails
});


export default withRouter(
  connect(mapStateToProps, {
    updateAlert,
    getAlertChildInfo
  })(AccrodionComp)
);