import moment from "moment";

export const getEncodedURI = (data: any) => {
  if (!data) {
    data = {}
  }
  return Object.keys(data).map(function (k) {
    return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
  }).join('&');
}

export const getDecodeURI = (line: any) => {
  if (!line) {
    line = ""
  }
  if (line.startsWith("?")) {
    line = line.substring(1);
  }
  if ('' === line.trim()) {
    return {};
  }
  let _line = decodeURIComponent(line);
  let ouName = new URLSearchParams(window.location.search).get('ouName');
  let k4Ids = new URLSearchParams(window.location.search).get('k4Ids');
  let key = "";
  let value = "";
  let keyValue = ""
  let newLineObj: any = {};
  if(ouName && ouName?.includes('&') || k4Ids && k4Ids?.includes('&')) {
      let newLine = _line.split('&').forEach((item:any) => {
        if(item.includes('ouName') || !item.includes('=') || item.includes('k4Ids')) {
            // ignore
        } else {
           keyValue = item.split('=');
           if(keyValue) {
            key = keyValue[0];
            value = keyValue[1];
            newLineObj[key] = value;
           }
        }
      })
      if(ouName) {
        newLineObj['ouName'] = ouName;
      }
      if(k4Ids) {
        newLineObj['k4Ids'] = k4Ids;
      }
    return newLineObj;
  } else {
    return Object.fromEntries(_line.split('&').map(item => item.split('=')));
  }
}

export const getSingleRecordChartLable = (time:any, timezone:any) => {
  return moment(new Date(time)).tz(timezone).format("MM-DD-YYYY HH:mm");
}

export const getDateRangeLablePerVessel = (time:any, timezone:any) => {
  return moment(new Date(time)).tz(timezone).format('ddd MMM DD yyyy HH:mm:ss');
}

export const  getDateInISo = (time:any , timezone:any) => {
  return moment(new Date(time)).tz(timezone).format('YYYY-MM-DDTHH:mm:ss.sssZ');
}