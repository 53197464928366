import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import {getOperationalUsageFilterDeviceList, getOperationalUsageFilterSWList} from '../../actions/Users/authenticateUsageReports';
import { getDecodeURI, getEncodedURI, getIntervalCheck } from "../../utils/util";
import _ from "lodash";
import { Autocomplete, Button, Dialog, Grid, TextField } from "@mui/material";
import close from "../../asset/image/close.svg";

const OperationalFilterDeviceList = (props) => {
    const {
        authReducer, openFilter, setOpenFilter, device, setDevice, softwareVersion, setSoftwareVersion, tableParams, setTableParams,
        getOperationalUsageFilterDeviceList,
        getOperationalUsageFilterSWList,
        newSummaryStartDate,
        newSummaryEndDate,
        operationalUsageDeviceList, operationalUsageSWList, location, history
    } = props;

    let _q = new Map<string, string>(Object.entries(getDecodeURI(location.search)));
    const [vessels, setVessels] = useState([]);
    const [deviceNames, setDeviceNames] = useState<any[]>([]);
    const [sWNames, setSWNames] = useState<any[]>([]);
    const [selectedDevice, setSelectedDevice] = useState<any>("");
    const [selectedSW, setSelectedSW] = useState<any>("");
    const [device_param, setDevice_param] = useState<string>("");
    useEffect(() => {
        if (_.isEmpty(authReducer?.getVesselsListing)){
            return;
        }
        let Vessels = [];
        const filteredVessels = authReducer?.filteredVessels;
        if (filteredVessels && filteredVessels.length > 0) {
            Vessels = filteredVessels;
        } else {
            Vessels = authReducer?.getVesselsListing?.locations;
        }
        setVessels(Vessels);
    },[authReducer?.getVesselsListing, authReducer?.filteredVessels]);

    useEffect(() => {
        if(vessels.length > 0 && vessels?.length == authReducer?.getVesselsListing?.locations?.length){
            getOperationalUsageFilterDeviceList({
                vessels: vessels,
                device_id: "",
                start_time: newSummaryStartDate,
                end_time: newSummaryEndDate
            })
        }
    },[vessels, newSummaryStartDate, newSummaryEndDate]);

    useEffect(() => {
        if (!_.isEmpty(operationalUsageDeviceList)) {
            const data = operationalUsageDeviceList.data;
            const deviceNamesList = data?.rows.map(row => row[0]);
            setDeviceNames(deviceNamesList);
        }
    }, [operationalUsageDeviceList]);

    useEffect(() => {
        if(vessels.length > 0 && vessels?.length == authReducer?.getVesselsListing?.locations?.length){
            getOperationalUsageFilterSWList({
                vessels: vessels,
                device: device_param,
                start_time: newSummaryStartDate,
                end_time: newSummaryEndDate
            })
        }
    },[vessels, newSummaryStartDate, newSummaryEndDate, device_param]);

    useEffect(() => {
        if (!_.isEmpty(operationalUsageSWList)) {
            const data = operationalUsageSWList.data;
            const deviceNamesList = data?.rows.map(row => row[0]);
            setSWNames(deviceNamesList);
        }
    }, [operationalUsageSWList]);

    const handleInputDeviceChange = (e, newValue) => {
        setSelectedDevice(newValue);
        setDevice_param(`AND deviceID IN('${newValue}')`);
    };

    const handleInputSWChange = (e, newValue) => {
        setSelectedSW(newValue);
    }

    const handleClearFilter = () => {
        setSelectedDevice("");
        setSelectedSW("");
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }
    const handleApplyFilter = () => {
        if (selectedDevice !== "") {setDevice(`and deviceID IN('${selectedDevice}')`);}
        if (selectedSW !== "") {setSoftwareVersion(`WHERE software_version IN('${selectedSW}')`);}
        setOpenFilter(false);
        setTableParams((prevParams) => ({
            ...prevParams,
            page: 1,
        }));
    }

    return (
                <Dialog className="menuScroll operationalFilterPopup" onClose={() => {setOpenFilter(false)}} open={openFilter} maxWidth="lg" fullWidth>
                    <Grid className="login-history-filter-inputs">
                        <Grid className="operationFilterpadding">
                            <div className="Filters_Title">Filters</div>
                            <img src={close} onClick={() => setOpenFilter(false)} className="dialog_closeIcon-filters" alt="Close" />
                        </Grid>
                        <Grid >
                            <Grid className="rc--filter_section login-rc--filter_section">
                                <div className="optionHeadingPadding12">Device Names</div>
                                <Autocomplete
                                    disableClearable={selectedDevice ? false : true}
                                    ListboxProps={{ style: { maxHeight: 200, fontSize: '13px' } }}
                                    options={deviceNames}
                                    size="small"
                                    value={selectedDevice}
                                    onChange={handleInputDeviceChange}
                                    getOptionLabel={(option) => option || ""}
                                    renderInput={(params) => <TextField {...params} placeholder="Device Name" />}
                                />
                            </Grid>
                            <Grid className="rc--filter_section login-rc--filter_section">
                                <div className="optionHeadingPadding12">EOS Version</div>
                                <Autocomplete
                                    disableClearable={selectedSW ? false : true}
                                    ListboxProps={{ style: { maxHeight: 200, fontSize: '13px' } }}
                                    options={sWNames}
                                    size="small"
                                    value={selectedSW.split("~")[0]}
                                    onChange={handleInputSWChange}
                                    getOptionLabel={(option) => option.split("~")[0] || ""}
                                    renderInput={(params) => <TextField {...params} placeholder="EOS Version" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className="clearApplyFilters operationalClearApplyFilters">
                        <Button onClick={() => handleClearFilter()} className="clearFiltersButton">Clear Filter</Button>
                        <Button className="usage-applyFilterBtn" onClick={()=> handleApplyFilter()}>Apply Filter</Button>
                    </div>
                </Dialog>

    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    newSummaryStartDate: state.authReducer.newSummaryStartDate,
    newSummaryEndDate: state.authReducer.newSummaryEndDate,
    operationalUsageDeviceList: state.authReducer.getOperationalUsageFilterDeviceList,
    operationalUsageSWList: state.authReducer.getOperationalUsageFilterSWList
});

export default withRouter(
    connect(mapStateToProps, {
        getOperationalUsageFilterDeviceList,
        getOperationalUsageFilterSWList
    })(OperationalFilterDeviceList)
);

