import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { getDashboardPieUsageperWantype, getDashboardHistogramUsageperWantype,getDashboardHistogramUsageBySiteCount, getDashboardUsagePieTop10Apps, getDashboardPieWanUsageBySites, getDashboardUsageHistogramUpDownLink, getDashboardUsageHistogramBySite, getNewSummaryUsageTable, getTopWidgetDataNewSumm, getTopWidgetWanlinkStatusNewSumm } from '../../../actions/Users/authenticateTrends';
import { useEffect, useState } from "react";
import { FormControl, FormControlLabel, Grid, IconButton, MenuItem, Select, Tooltip } from "@mui/material";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import barIcon from '../../../asset/image/bar_chart_icon.svg';
import lineIcon from '../../../asset/image/line_chart_icon.svg';
import bar_chart from '../../../asset/image/bar_chart_light.svg';
import line_chart from '../../../asset/image/line_chart.svg';
import { getDateForChart, getDecodeURI, getEncodedURI, getMinutes, getSingleRecordChartLable, getTimezoneCity, readableBytesAsGB, readableBytesAsMB } from "../../../utils/util";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import './chartComponent.css';
import _ from "lodash";
import { UPDATE_NEW_SUMMARY_END_DATE, UPDATE_NEW_SUMMARY_START_DATE, UPDATE_NEW_SUMMARY_WAN_TYPE } from "../../../actions/types";
import moment from "moment";
import WanUsagePie from "./WanUsagePie";
import SiteUsagePie from "./SiteUsagePie";
import WanLineChart from "./WanLineChart";
import SiteLineChart from "./SiteLineChart";
import ImageWithFallback from "../../ImageWithFallback";

const ChartsComponent = (props) => {
    const { authReducer, timePeriod, handleSiteClick, getDashboardPieUsageperWantype, getDashboardHistogramUsageperWantype,getDashboardHistogramUsageBySiteCount, getDashboardUsagePieTop10Apps, getDashboardPieWanUsageBySites, getDashboardUsageHistogramUpDownLink, getDashboardUsageHistogramBySite, getNewSummaryUsageTable, getTopWidgetDataNewSumm, getTopWidgetWanlinkStatusNewSumm, newUsagePieTop10Apps, newUsageperWantype, newHistogramUsageperWantype, newHistogramBySiteCount, newSummaryStartDate, newSummaryEndDate, newSummaryWanType, wanUsageBySites, newSummaryUploadDownload, newSummaryHistogramBySite, location, history } = props;
    const maxBucketValue = 15;
    const maxPointWidth = 50;
    const minPointWidth = 10;
    const minBucketValue = 5
    const _q = getDecodeURI(location?.search);
    const [topAppsData, setTopAppsData] = useState<any>([]);
    const [usagePerWanType, setUsagePerWanType] = useState<any>([]);
    const [usagePerSite, setUsagePerSite] = useState<any>([]);
    const [barChartData, setBarChartData] = useState<any>([]);
    const [barChartDataUnit, setBarChartDataUnit] = useState('GB');
    const [histogramData, setHistogramData] = useState<any>([]);
    const [histogramBySiteData, setHistogramBySiteData] = useState<any>([]);
    const [isHistogram, setIsHistogram] = useState(false)
    const [bucketValue, setBucketValue] = useState(minBucketValue)
    const [chartView, setChartView] = useState('column');
    const [pieLimit, setPieLimit] = useState(_q.hasOwnProperty('pieLimit') ? _q.pieLimit : 10);
    const [appLimit, setAppLimit] = useState(_q.hasOwnProperty('appLimit') ? _q.appLimit : 10);
    const [upDownData, setUpDOwnData] = useState<any>([]);
    const [upDownDataUnit, setUpDownDataUnit] = useState('GB');
    const [resetDate, setResetDate] = useState<any>([]);
    const dispatch = useDispatch();
    const [vessels, setVessels] = useState([]);
    const [toggleBtn, setToggleBtn] = useState(_q.hasOwnProperty('toggle') ? true : false);
    const [siteChartUsage, setSiteChartUsage] = useState<any>([]);
    const [siteDataUnit, setSiteDataUnit] = useState('GB');
    const [minBucket, setMinBucket] = useState(0)
    const [maxBucket, setMaxBucket] = useState(100)
    const [defaultMinMax, setDefaultMinMax] = useState({min:minBucket,max:maxBucket})
    const getInterval = (minutes) => {
        if (minutes <= 720) {
          return "15m";
        } else if (minutes > 720 && minutes <= 1440) {
          return "30m";
        } else if (minutes > 1440 && minutes <= 10080) {
          return "3h";
        } else {
          return "12h";
        }
    }
    const [selectedBin, setSelectedBin] = useState(getInterval(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)));
    const timeZone = getTimezoneCity(authReducer?.userTimezone);

    const handleResetValue = () =>{
        setMinBucket(defaultMinMax?.min)
        setMaxBucket(defaultMinMax?.max)
        setHistogramBySiteData(getBucketData(histogramData, bucketValue,minBucket, maxBucket))
    }

    useEffect(()=>{
        if(isHistogram){
            if (_.isEmpty(authReducer?.getVesselsListing))
            return;
        let vessels = [];
        const filteredVessels = authReducer?.filteredVessels;
        if (filteredVessels && filteredVessels.length > 0) {
            vessels = filteredVessels;
        } else {
            vessels = authReducer?.getVesselsListing?.locations;
        }
        setVessels(vessels);
        if(vessels?.length > 0) {
            getDashboardHistogramUsageBySiteCount(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value) 
            var duration = moment.duration(newSummaryEndDate.diff(newSummaryStartDate));
            var minutes = duration.asMinutes();
            minutes <= 60 ? setBucketValue(2) : setBucketValue(5) 
        }
        else if(vessels?.length === 1){
            setIsHistogram(false)
        }
        else{
            setHistogramBySiteData([])
        }
    }
    },[isHistogram, authReducer?.getVesselsListing, authReducer?.filteredVessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType])

    useEffect(()=>{

        if(!_.isEmpty(newHistogramBySiteCount)) {
            const data = newHistogramBySiteCount?.hasOwnProperty('data') ? newHistogramBySiteCount?.data : {};
         
            if (data) {
              let rows = data?.rows;
                setHistogramData(rows)
                setMinBucket(Number(((rows[0][2])/(1024*1024*1024)).toFixed(0)))
                setMaxBucket(Number(((rows[rows.length - 1][2])/(1024*1024*1024)).toFixed(0)))
                setDefaultMinMax({min:Number(((rows[0][2])/(1024*1024*1024)).toFixed(0)), max:Number(((rows[rows.length - 1][2])/(1024*1024*1024)).toFixed(0)) })
                setHistogramBySiteData(getBucketData(rows, bucketValue,Number(((rows[0][2])/(1024*1024*1024)).toFixed(2)),Number(((rows[rows.length - 1][2])/(1024*1024*1024)).toFixed(2)) ));
            }
        }

    },[newHistogramBySiteCount])

    useEffect(()=>{
        if (histogramData && histogramData.length > 0){
            setMinBucket(minBucket)
                setMaxBucket(maxBucket)
                setHistogramBySiteData(getBucketData(histogramData, bucketValue, minBucket, maxBucket ));
        }
    },[minBucket, maxBucket])


    useEffect(() => {
        if (_.isEmpty(authReducer?.getVesselsListing))
            return;
        let vessels = [];
        const filteredVessels = authReducer?.filteredVessels;
        if (filteredVessels && filteredVessels.length > 0) {
            vessels = filteredVessels;
        } else {
            vessels = authReducer?.getVesselsListing?.locations;
        }
        setVessels(vessels);
    }, [authReducer?.getVesselsListing, authReducer?.filteredVessels]);

    useEffect(() => {
        if(vessels?.length > 0) {
            getDashboardUsagePieTop10Apps(vessels, newSummaryStartDate, newSummaryEndDate, appLimit);
            // newSummaryWanType?.value == '' &&    getDashboardHistogramUsageBySiteCount(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value) 
            newSummaryWanType?.value == '' && !toggleBtn ? getDashboardHistogramUsageperWantype(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, selectedBin) :
            newSummaryWanType?.value && !toggleBtn && getDashboardUsageHistogramUpDownLink(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, selectedBin);
            ((newSummaryWanType?.value && toggleBtn) || (!newSummaryWanType?.value && toggleBtn)) && getDashboardUsageHistogramBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, pieLimit, selectedBin);
            newSummaryWanType?.value == '' && toggleBtn && getDashboardPieWanUsageBySites(vessels, newSummaryStartDate, newSummaryEndDate, '', pieLimit);
            newSummaryWanType?.value == '' && !toggleBtn && getDashboardPieUsageperWantype(vessels, newSummaryStartDate, newSummaryEndDate, '');
            newSummaryWanType?.value && !toggleBtn && getDashboardPieUsageperWantype(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value);
            newSummaryWanType?.value && toggleBtn && getDashboardPieWanUsageBySites(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, pieLimit);
        } else {
            setTopAppsData([]);
            setUsagePerWanType([]);
            setUsagePerSite([]);
            setBarChartData([]);
            setHistogramBySiteData([]);
            setUpDOwnData([]);
            setSiteChartUsage([]);
        }
    }, [vessels, newSummaryWanType])

    useEffect(() => {
        const interval = getInterval(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3));
        setSelectedBin(interval);
        if(vessels?.length > 0) {
            getDashboardUsagePieTop10Apps(vessels, newSummaryStartDate, newSummaryEndDate, appLimit);
            newSummaryWanType?.value == '' && !toggleBtn ? getDashboardHistogramUsageperWantype(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, interval) :
            newSummaryWanType?.value && !toggleBtn && getDashboardUsageHistogramUpDownLink(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, interval);
            ((newSummaryWanType?.value && toggleBtn) || (!newSummaryWanType?.value && toggleBtn)) && getDashboardUsageHistogramBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, pieLimit, interval);
            newSummaryWanType?.value == '' && toggleBtn && getDashboardPieWanUsageBySites(vessels, newSummaryStartDate, newSummaryEndDate, '', pieLimit);
            newSummaryWanType?.value == '' && !toggleBtn && getDashboardPieUsageperWantype(vessels, newSummaryStartDate, newSummaryEndDate, '');
            newSummaryWanType?.value && !toggleBtn && getDashboardPieUsageperWantype(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value);
            newSummaryWanType?.value && toggleBtn && getDashboardPieWanUsageBySites(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, pieLimit);
        }
    }, [newSummaryStartDate, newSummaryEndDate])

    const divideRange = (start, end, x) => {
        const step = (end - start) / x;
        const result: Array<{ start, end }> = [];
        
        for (let i = 0; i < x; i++) {
          const rangeStart = start + i * step;
          const rangeEnd = rangeStart - 1 + step;
          
          result.push({ start: rangeStart.toFixed(0), end: rangeEnd.toFixed(0) });
        }
        
        return result;
      }

      const handleBucket = (e) => {
        const value = e.target.value;
        setBucketValue(value);
        // setMinBucket(Number(((histogramData[0][2])/(1024*1024*1024)).toFixed(0)))
        // setMaxBucket(Number(((histogramData[histogramData.length - 1][2])/(1024*1024*1024)).toFixed(0)))
        setHistogramBySiteData(getBucketData(histogramData, value,minBucket, maxBucket));
    }

    const handleLegend = (name) =>{
        let legendData = histogramBySiteData.filter(item => item.name === name);
        setHistogramBySiteData(legendData)
    }


    const getBucketData = (rows, bucketValue, start, end) => {
              const dividedRanges = divideRange(start, end, bucketValue);
              const resultArray: number[] = [];
              const rangeArray : {}[] = [];

              for (const obj of dividedRanges) {
                
                let xVal = 0;
                const start = parseInt(obj.start, 10) ; 
                const end = parseInt(obj.end, 10) ; 
              
                const filteredValues = rows.filter((innerArray) => {
                    const value = Number(((innerArray[2])/(1024*1024*1024)).toFixed(2)) ;
                    return value >= start && value < end;
                  });
                  
                resultArray.push(filteredValues.length);

                 xVal = filteredValues.map(item => item[2]).reduce((acc, currentValue) => acc + currentValue, 0);

                let valueInBytes = Math.round(xVal/filteredValues.length)
                let valInGB = valueInBytes / (1024*1024*1024)

                rangeArray.push({
                    name:`${obj.start} - ${obj.end} GB`,
                    data:[{x:valInGB ? valInGB : 0 ,y:filteredValues.length}]
                  })
              }
              return rangeArray;
    }

    useEffect(() => {
        if(!_.isEmpty(newHistogramUsageperWantype)) {
            const data = newHistogramUsageperWantype?.hasOwnProperty('data') ? newHistogramUsageperWantype?.data : {};
            if (data) {
                const series = data?.wan_usage?.series || {};
                const seriesKeys = Object.keys(series);
                const gbValue = Math.pow(1000, 3);
                const isGBCrossed = seriesKeys.some(key => {
                    const seriesValue = series[key];
                    return (_.isArray(seriesValue) && seriesValue.some(item => item.y > gbValue))
                })
                const convertBytes = isGBCrossed ? readableBytesAsGB : readableBytesAsMB;
                const barChartData: any =seriesKeys.map((key, i) => {
                    series[key]?.forEach((el, i)=>{
                        series[key][i].y = Number(convertBytes(el.y, false))
                    })
                    const color = key == 'CELLULAR' ? '#9ec4f8' : key == 'ETHERNET' ? '#8AC9E0' : key == 'LBAND' ? '#3a88d1' : key == 'STARLINK' ? '#1d136a' : key=='VSAT' ? '#1f3da8' : '#4dbaf7';
                    return { name: key, data: series[key], marker: { symbol: 'circle' }, visible: true, color }
                })
                setBarChartDataUnit(isGBCrossed ? 'GB' : 'MB');
                setBarChartData(barChartData);
            }
        }
        if(!_.isEmpty(newSummaryUploadDownload)) {
            const data = newSummaryUploadDownload?.hasOwnProperty('data') ? newSummaryUploadDownload?.data : {};
            if (data) {
                const series = data?.upDown?.series || {};
                const seriesKeys = Object.keys(series);
                const gbValue = Math.pow(1000, 3);
                const isGBCrossed = seriesKeys.some(key => {
                    const seriesValue = series[key];
                    return (_.isArray(seriesValue) && seriesValue.some(item => item.y > gbValue))
                })
                const convertBytes = isGBCrossed ? readableBytesAsGB : readableBytesAsMB;
                const chartData: any = seriesKeys.map((key, i) => {
                    series[key]?.forEach((el, i) => {
                        data.upDown.series[key][i].y = Number(convertBytes(el.y, false))
                    })
                    const color = key == 'Downlink' ? '#1f3da8' : '#8ac9e0';
                    return { name: key, data: series[key], marker: { symbol: 'circle' }, visible: true, color }
                })
                setUpDownDataUnit(isGBCrossed ? 'GB' : 'MB')
                setUpDOwnData(chartData);
            }
        }
        if(!_.isEmpty(newSummaryHistogramBySite)) {
            const data = newSummaryHistogramBySite?.hasOwnProperty('data') ? newSummaryHistogramBySite?.data : {};
            if (data) {
                const series = data?.siteusage?.series || {};
                const seriesKeys = Object.keys(series);
                const gbValue = Math.pow(1000, 3);
                const isGBCrossed = seriesKeys.some(key => {
                    const seriesValue = series[key];
                    return (_.isArray(seriesValue) && seriesValue.some(item => item.y > gbValue))
                })
                const convertBytes = isGBCrossed ? readableBytesAsGB : readableBytesAsMB;
                const chartData: any = data?.siteusage?.series && Object.keys(data?.siteusage?.series)?.map((key, i) => {
                    data?.siteusage?.series[key]?.forEach((el, i) => {
                        data.siteusage.series[key][i].y =  Number(convertBytes(el.y, false))
                    })
                    return { name: key.split('|')[0], k4Id: key.split('|')[1], data: data?.siteusage?.series[key], marker: { symbol: 'circle' }, visible: true }
                })
                setSiteDataUnit(isGBCrossed ? 'GB' : 'MB')
                setSiteChartUsage(chartData);
            }
        }
    }, [newHistogramUsageperWantype, newSummaryUploadDownload, newSummaryHistogramBySite])

    useEffect(() => {
        if(!_.isEmpty(newUsageperWantype)) {
            const data = newUsageperWantype?.hasOwnProperty('data') ? newUsageperWantype?.data : {};
            if(data) {
                const Usage = data?.rows && data?.rows?.map((item) => {
                    const color = item[0] == 'CELLULAR' ? '#9ec4f8' : item[0] == 'ETHERNET' ? '#8AC9E0' : item[0] == 'LBAND' ? '#3a88d1' : item[0] == 'STARLINK' ? '#1d136a' : item[0]=='VSAT' ? '#1f3da8' : '#4dbaf7';
                    const value = item[0] == 'CELLULAR' ? 'lte' : item[0] == 'ETHERNET' ? 'ethernet' : item[0] == 'LBAND' ? 'lband' : item[0] == 'STARLINK' ? 'starlink' : item[0] == 'WIFI' ? 'wifi' : 'vsat';
                    return {x: item[0], y: item[2], z: readableBytesAsGB(item[1]), color, value}
                })
                setUsagePerWanType(Usage);
            }
        }

        if(!_.isEmpty(wanUsageBySites)) {
            const data = wanUsageBySites?.hasOwnProperty('data') ? wanUsageBySites?.data : {};
            if(data) {
                const Usage = data?.rows && data?.rows?.map((item) => {
                    return {x: item[0], y: item[4], z: readableBytesAsGB(item[3]), k4Id: item[1]}
                })
                setUsagePerSite(Usage);
            }
        }

        if(!_.isEmpty(newUsagePieTop10Apps)) {
            const apps = newUsagePieTop10Apps?.hasOwnProperty('data') ? newUsagePieTop10Apps?.data : {};
            if(apps) {
                const topUsage = apps?.rows && apps?.rows?.map((item) => {
                    return {x: item[0], y: item[2], z: readableBytesAsGB(item[1])}
                })
                setTopAppsData(topUsage);
            }
        }
    }, [newUsageperWantype, newUsagePieTop10Apps, wanUsageBySites])

    const chartAreaSelection = () => {
        return (event: any) => {
            let start:any = moment(new Date(Math.ceil(event.xAxis[0].min)));
            let end:any = moment(new Date(Math.floor(event.xAxis[0].max)));
            const timeBand = Math.ceil(((new Date(end)).getTime() - (new Date(start)).getTime()) / 60e3);
            timeBand < 5 ? start = moment(Date.now()).subtract(5, 'minutes') : start = moment(new Date(Math.ceil(event.xAxis[0].min)));
            timeBand < 5 ? end = moment(Date.now()) : end = moment(new Date(Math.floor(event.xAxis[0].max)));
            pinchAndResetChart(start, end, 'customDates');

            let arr:any = resetDate;
            arr.push({startDate: start, endDate: end});
            setResetDate(arr);
            return false;
        }
    }

    const appOptions: Highcharts.Options = {
        chart: {
            type: 'pie',
            height: '185px',
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
            backgroundColor: 'none'
        },
        colors: ['#2f2282', '#1b51a7', '#3dab97', '#5e3f9e', '#3e88d3', '#1d136a', '#69b8e5', '#3f79ca', '#9e8cc5', '#ed9340',
            '#8cafdf', '#d9914f', '#86c6df', '#3c7df1', '#61cdc0', '#64a1f4', '#dc6161', '#146680', '#2039b5', '#00ad64',
            '#1b4dac', '#b50000', '#8cb5ec', '#c1d5f6', '#b3bbe6', '#e5a8a8', '#f9dabe', '#4b91f1', '#8dbdff', '#efeab9',
            '#8a39a7', '#d9cb4f', '#145f89', '#e7d696', '#8592e7', '#e78585', '#92d7c9', '#aeaeb1', '#755c5d', '#7c6ce6',
            '#cc7460', '#bd9331', '#15e1e8', '#514ced', '#8633de', '#eb52b3', '#c4355b', '#6dc779', '#5373a3', '#8dc293'
        ],
        title: {
            verticalAlign: 'middle',
            floating: true,
            text: '',
            style: {
                color: '#9E9E9E',
                fontFamily: 'Roboto',
                fontSize: '10px',
                fontWeight: '400'
            }
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        tooltip: {
            enabled: true,
            headerFormat: '',
            pointFormat: '<span style="color: #7e8089">{point.x}</span> <br/> <b style="color: #fff">{point.z}</b><br/>',
            valueDecimals: 0,
            useHTML: true,
            borderRadius: 15,
            backgroundColor: '#060606',
            followPointer: false
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                shadow: false,
            },
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        series: [{
            type: 'pie',
            data: topAppsData,
            size: '100%',
            innerSize: '50%',
            dataLabels: {
                enabled: false
            }
        }]
    }

    const wanOptions: Highcharts.Options = {
        chart: {
            type: 'pie',
            height: '185px',
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
            backgroundColor: 'none'
        },
        colors: ['#2f2282', '#1b51a7', '#3dab97', '#5e3f9e', '#3e88d3', '#1d136a', '#69b8e5', '#3f79ca', '#9e8cc5', '#ed9340',
            '#8cafdf', '#d9914f', '#86c6df', '#3c7df1', '#61cdc0', '#64a1f4', '#dc6161', '#146680', '#2039b5', '#00ad64',
            '#1b4dac', '#b50000', '#8cb5ec', '#c1d5f6', '#b3bbe6', '#e5a8a8', '#f9dabe', '#4b91f1', '#8dbdff', '#efeab9',
            '#8a39a7', '#d9cb4f', '#145f89', '#e7d696', '#8592e7', '#e78585', '#92d7c9', '#aeaeb1', '#755c5d', '#7c6ce6',
            '#cc7460', '#bd9331', '#15e1e8', '#514ced', '#8633de', '#eb52b3', '#c4355b', '#6dc779', '#5373a3', '#8dc293'
        ],
        title: {
            text: ''
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        tooltip: {
            enabled: true,
            headerFormat: ``,
            pointFormat: '<span style="color: #7e8089">{point.x}</span> <br/> <b style="color: #fff">{point.z}</b><br/>',
            valueDecimals: 0,
            useHTML: true,
            borderRadius: 15,
            backgroundColor: '#060606',
            followPointer: false
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                shadow: false,
            }
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        series: [{
            type: 'pie',
            data: newSummaryWanType?.value == '' && !toggleBtn ? usagePerWanType : newSummaryWanType?.value == '' && toggleBtn ? usagePerSite : newSummaryWanType?.value && !toggleBtn ? usagePerWanType :  usagePerSite,
            size: '100%',
            innerSize: '50%',
            dataLabels: {
                enabled: false
            }
        }]
    }

    const histogramOptions: any = {
        chart: {
            type: "column",
            height: '300px',
            // zoomType: 'x',
            plotBorderWidth: 1,
            resetZoomButton: {
                theme: {
                    style: {
                        display: 'none'
                    }
                }
            },
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
        },
        colors: ['#2f2282', '#1b51a7', '#3dab97', '#5e3f9e', '#3e88d3', '#1d136a', '#69b8e5', '#3f79ca',  ],
        title: {
            text: '',
            align: 'left',
            floating: true,
            x: 12,
            y: 32,
            style: {
                fontWeight: '500'
            }
        },
        tooltip: {
            valueSuffix: ' ',
            headerFormat: '',
            pointFormat: ' <b style="color: #fff">{point.y}</b><br/>',
            borderWidth: 1,
            useHTML: true,
            borderRadius: 15,
            backgroundColor: '#060606',
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        xAxis: {
            min:minBucket,
            max:maxBucket,
            gridLineWidth: 0.5,
            format: '{value} GB',
            reversed: false,
            maxPadding: 0.05,
            showLastLabel: true,
            labels: {
                format: '{value} GB',
                style: {
                    color: '#2F4B82',
                    fontFamily: 'Inter',
                    fontSize: '9px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                }
            }
        },
        yAxis: {
            allowDecimals:false,
            tickInterval: 1,
            gridLineWidth: 1,
            labels: {
               
                style: {
                    color: '#2F4B82',
                    fontFamily: 'Inter',
                    fontSize: '9px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                }
            },
            title: {
                text: 'Sites (Count)',
            }
        },
          plotOptions: {
            // series:{
            //     events:{
            //         legendItemClick: function (event) {
            //             const series: any = this;
            //             const visibility:any = series.visible ? 'visible' : 'hidden';
            //             series.setVisible(!series.visible);
            //             console.log("eeeeeeeee",series.visible)

            //                return true; 
            //         }
            //     }
            // },
            column: {
                pointWidth: minPointWidth+ ( (maxBucketValue - bucketValue) / maxBucketValue)*(maxPointWidth-minPointWidth ),
                // pointWidth: maxPointWidth - (bucketValue + maxBucket) * (maxPointWidth - minPointWidth) / (maxBucketValue + 500),
                grouping: false
            }
          },
            series:  histogramBySiteData ,
        colorByPoint: true
    }

    const dataUnit = newSummaryWanType?.value == '' && !toggleBtn ? barChartDataUnit : newSummaryWanType?.value && !toggleBtn ? upDownDataUnit : siteDataUnit;

    const barOptions: any = {
        time: {
            timezone: timeZone
        },
        chart: {
            type: chartView,
            height: '260px',
            zoomType: 'x',
            plotBorderWidth: 1,
            resetZoomButton: {
                theme: {
                    style: {
                        display: 'none'
                    }
                }
            },
            events: {
                selection: chartAreaSelection()
            },
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
        },
        colors: ['#2f2282', '#1b51a7', '#3dab97', '#5e3f9e', '#3e88d3', '#1d136a', '#69b8e5', '#3f79ca', '#9e8cc5', '#ed9340',
            '#8cafdf', '#d9914f', '#86c6df', '#3c7df1', '#61cdc0', '#64a1f4', '#dc6161', '#146680', '#2039b5', '#00ad64',
            '#1b4dac', '#b50000', '#8cb5ec', '#c1d5f6', '#b3bbe6', '#e5a8a8', '#f9dabe', '#4b91f1', '#8dbdff', '#efeab9',
            '#8a39a7', '#d9cb4f', '#145f89', '#e7d696', '#8592e7', '#e78585', '#92d7c9', '#aeaeb1', '#755c5d', '#7c6ce6',
            '#cc7460', '#bd9331', '#15e1e8', '#514ced', '#8633de', '#eb52b3', '#c4355b', '#6dc779', '#5373a3', '#8dc293'
        ],
        title: {
            text: '',
            align: 'left',
            floating: true,
            x: 12,
            y: 32,
            style: {
                fontWeight: '500'
            }
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                turboThreshold: 1000000,
                stickyTracking: false,
                connectNulls: false,
            },
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                }
            },
            colorByPoint: true
        },
        tooltip: {
            valueSuffix: ` ${dataUnit}`,
            headerFormat: '',
            pointFormat: '<span style="color: #7e8089">{series.name}</span> <br/> <b style="color: #fff">{point.y}</b><br/>',
            valueDecimals: 2,
            borderWidth: 1,
            useHTML: true,
            borderRadius: 15,
            backgroundColor: '#060606',
        },
        xAxis: {
            gridLineWidth: 0.5,
            reversed: false,
            type: "datetime",
            maxPadding: 0.05,
            showLastLabel: true,
            labels: {
                style: {
                    color: '#2F4B82',
                    fontFamily: 'Inter',
                    fontSize: '9px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                },
                format: newSummaryWanType?.value == '' && !toggleBtn && getDateForChart(barChartData) ? getSingleRecordChartLable(barChartData, timeZone) 
                      : newSummaryWanType?.value && !toggleBtn && getDateForChart(upDownData) ? getSingleRecordChartLable(upDownData, timeZone) 
                      : toggleBtn && getDateForChart(siteChartUsage) ? getSingleRecordChartLable(siteChartUsage, timeZone) : ''
            }
        },
        yAxis: {
            tickInterval: 0.5,
            gridLineWidth: 1,
            labels: {
                format: `{value} ${dataUnit}`,
                style: {
                    color: '#2F4B82',
                    fontFamily: 'Inter',
                    fontSize: '9px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                }
            },
            title: {
                text: `Usage (${dataUnit})`,
            }
        },
        series: newSummaryWanType?.value == '' && !toggleBtn ? barChartData : newSummaryWanType?.value && !toggleBtn ? upDownData : siteChartUsage, 
        colorByPoint: true
    }

    const handleLegendClick = (index) => {
        let all_true = true;
        let updatedData: any = newSummaryWanType?.value == '' && !toggleBtn ? [...barChartData] : newSummaryWanType?.value && !toggleBtn ? [...upDownData] : [...siteChartUsage];
        let all_off_turn_on = 0;
        let click = 0;

        updatedData?.forEach((ele, i) => {
            if (!ele.visible) {
                all_true = false;
                all_off_turn_on += 1;
            }
            if (ele.visible) {
                click = i;
            }
        })

        if (click == index && all_off_turn_on == updatedData.length - 1) {
            updatedData?.forEach((ele, i) => {
                updatedData[i] = { ...ele, visible: true }
            })
            newSummaryWanType?.value == '' && !toggleBtn ? setBarChartData([...updatedData]) : newSummaryWanType?.value && !toggleBtn ? setUpDOwnData([...updatedData]) : setSiteChartUsage([...updatedData]);
        }
        else if (all_true) {
            updatedData?.forEach((ele, i) => {
                updatedData[i] = { ...ele, visible: false }
            })
            updatedData[index].visible = true;
            newSummaryWanType?.value == '' && !toggleBtn ? setBarChartData([...updatedData]) : newSummaryWanType?.value && !toggleBtn ? setUpDOwnData([...updatedData]) : setSiteChartUsage([...updatedData]);
        }
        else {
            updatedData.forEach((ele, i) => {
                updatedData[i] = ele;
            })
            updatedData[index].visible = !updatedData[index].visible;
            newSummaryWanType?.value == '' && !toggleBtn ? setBarChartData([...updatedData]) : newSummaryWanType?.value && !toggleBtn ? setUpDOwnData([...updatedData]) : setSiteChartUsage([...updatedData]);
        }
    }

    const handleChangePieLimit = (e) => {
        const value = e.target.value;
        setPieLimit(value);
        let params: any = getDecodeURI(location?.search);
        value == 10 ? delete params.pieLimit : params.pieLimit = value;
        doNavigate(params);
        vessels && getDashboardPieWanUsageBySites(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, value);
        vessels && getDashboardUsageHistogramBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, value, selectedBin);
    }

    const handleChangePieAppLimit = (e) => {
        const value = e.target.value;
        setAppLimit(value);
        let params: any = getDecodeURI(location?.search);
        value == 10 ? delete params.appLimit : params.appLimit = value;
        doNavigate(params);
        vessels && getDashboardUsagePieTop10Apps(vessels, newSummaryStartDate, newSummaryEndDate, value);
    }

    const pinchAndResetChart = (start, end, interval) => {
        let params = getDecodeURI(location?.search);
        params.startDate = start.valueOf();
        params.endDate = end.valueOf();
        params.interval = interval;
        doNavigate(params);
    }

    const handleResetZoom = () => {
        if(resetDate?.length == 0) {
          return;
        }
        let arr:any = resetDate;
        arr.pop();
        setResetDate(arr);
        if(arr?.length == 0) {
            const start = moment(Date.now()).subtract(1, 'hour');
            const end = moment(Date.now());
            pinchAndResetChart(start, end, '1h');
        }
        else {
            let obj = arr[arr?.length-1];
            let start = obj.startDate;
            let end = obj.endDate;
            pinchAndResetChart(start, end, 'customDates');
        }
    }
    
    const handleDefaultResetZoom = () => {
        setResetDate([]);
        const start = moment(Date.now()).subtract(1, 'hour');
        const end = moment(Date.now());
        pinchAndResetChart(start, end, '1h');
    }

    const handleWanTypeClick = (row) => {
        const selectedWan = {label: row.x == 'CELLULAR' ? 'LTE' : row.x, value: row.value};
        let params: any = getDecodeURI(location?.search);
        params.wanType = selectedWan.label;
        doNavigate(params);
        dispatch({ type: UPDATE_NEW_SUMMARY_WAN_TYPE, payload: selectedWan });
    }

    const handleChangeToggle = (e) => {
        const value = e.target.checked;
        setToggleBtn(value);
        let params: any = getDecodeURI(location?.search);
        value ? params.toggle = true : delete params.toggle;
        doNavigate(params);
        newSummaryWanType?.value == '' && value && getDashboardPieWanUsageBySites(vessels, newSummaryStartDate, newSummaryEndDate, '', pieLimit);
        newSummaryWanType?.value == '' && !value && getDashboardPieUsageperWantype(vessels, newSummaryStartDate, newSummaryEndDate, '');
        newSummaryWanType?.value && !value && getDashboardPieUsageperWantype(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value);
        newSummaryWanType?.value && value && getDashboardPieWanUsageBySites(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, pieLimit);
        // newSummaryWanType?.value == '' && !value &&   getDashboardHistogramUsageBySiteCount(vessels, newSummaryStartDate, newSummaryEndDate, '');
        newSummaryWanType?.value == '' && !value && getDashboardHistogramUsageperWantype(vessels, newSummaryStartDate, newSummaryEndDate, '', selectedBin);
        newSummaryWanType?.value && !value && getDashboardUsageHistogramUpDownLink(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, selectedBin);
        ((!newSummaryWanType?.value && value) || (newSummaryWanType?.value && value)) && getDashboardUsageHistogramBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, pieLimit, selectedBin);
    };

    const handleBinChange = (e) => {
        const value = e.target.value;
        setSelectedBin(e.target.value);
        if(vessels?.length > 0) { 
            newSummaryWanType?.value == '' && !toggleBtn && getDashboardHistogramUsageperWantype(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, value);
            newSummaryWanType?.value && !toggleBtn && getDashboardUsageHistogramUpDownLink(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, value);
            ((newSummaryWanType?.value && toggleBtn) || (!newSummaryWanType?.value && toggleBtn)) && getDashboardUsageHistogramBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, pieLimit, value);
        }
    };

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const colors = ['#2f2282', '#1b51a7', '#3dab97', '#5e3f9e', '#3e88d3', '#1d136a', '#69b8e5', '#3f79ca', '#9e8cc5', '#ed9340',
        '#8cafdf', '#d9914f', '#86c6df', '#3c7df1', '#61cdc0', '#64a1f4', '#dc6161', '#146680', '#2039b5', '#00ad64',
        '#1b4dac', '#b50000', '#8cb5ec', '#c1d5f6', '#b3bbe6', '#e5a8a8', '#f9dabe', '#4b91f1', '#8dbdff', '#efeab9',
        '#8a39a7', '#d9cb4f', '#145f89', '#e7d696', '#8592e7', '#e78585', '#92d7c9', '#aeaeb1', '#755c5d', '#7c6ce6',
        '#cc7460', '#bd9331', '#15e1e8', '#514ced', '#8633de', '#eb52b3', '#c4355b', '#6dc779', '#5373a3', '#8dc293'
    ]

    function GetTopAppsImage (props)  {
        const { app } = props;
        if(app) {
          let appName = app.includes(' ') ? app.split(' ')[0]: app.includes('-') ? app.split('-')[0] : ""; 
          return <ImageWithFallback src={`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${app.trim()}`} fallbackSrcs={[`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${appName.trim()}`,"https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png"]} app={app} />
        } else {
          return <img className="logo-activity paddingLogo" src={`https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png`} />
        }
    
      }

    return (
        <div className="new-summary-chart-container">
            <Grid container spacing={2} className='new-summary-chart-component'>
                <Grid item xs={12} sm={12} md={3} lg={3} className="padding-left-22">
                    <Grid className="usage-per-site-pie">
                        <Grid className="new-summary-control-limit">
                            <Tooltip title='Source : Activity Report'><div className="title-text">Application Usage (ALL WAN)</div></Tooltip>
                            <Grid className="pieChat-selectTag">
                                <FormControl variant="standard" className='selectEntry-pie'>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={appLimit}
                                        onChange={handleChangePieAppLimit}
                                    >
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={30}>30</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={appOptions}
                        />
                        <Grid container className="usage-details-pie-perSite">
                            {topAppsData && topAppsData.map((usage, i) => (
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <div className="summary-pie-usage-dot" style={{ background: `${colors[i]}` }}></div>
                                    <GetTopAppsImage app={usage.x} />
                                    <span className="summary-usage-value">{usage.x}</span>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={9}>
                    <Grid container spacing={0} className="usage-per-site-pie">
                        {!newSummaryWanType?.value && !toggleBtn ?
                            <Grid item xs={4} sm={4} md={4} lg={4} className="pie-chart-summary">
                                <WanUsagePie
                                    title='Usage'
                                    toggleBtn={toggleBtn}
                                    handleChangeToggle={handleChangeToggle}
                                    wanOptions={wanOptions}
                                    usagePerWanType={usagePerWanType}
                                    handleWanTypeClick={handleWanTypeClick}
                                />
                            </Grid> 
                            :
                            !newSummaryWanType?.value && toggleBtn ? 
                            <Grid item xs={4} sm={4} md={4} lg={4} className="pie-chart-summary">
                                <SiteUsagePie 
                                    title='Usage'
                                    pieLimit={pieLimit}
                                    handleChangePieLimit={handleChangePieLimit}
                                    toggleBtn={toggleBtn}
                                    handleChangeToggle={handleChangeToggle}
                                    wanOptions={wanOptions}
                                    usagePerSite={usagePerSite}
                                    handleSiteClick={handleSiteClick}
                                    colors={colors} 
                                />
                            </Grid>
                             :
                            newSummaryWanType?.value && !toggleBtn ?
                            <Grid item xs={4} sm={4} md={4} lg={4} className="pie-chart-summary">
                                <WanUsagePie
                                    title='Usage'
                                    toggleBtn={toggleBtn}
                                    handleChangeToggle={handleChangeToggle}
                                    wanOptions={wanOptions}
                                    usagePerWanType={usagePerWanType}
                                    handleWanTypeClick={handleWanTypeClick}
                                />
                            </Grid>
                             :
                            <Grid item xs={4} sm={4} md={4} lg={4} className="pie-chart-summary">
                                <SiteUsagePie
                                    title='Usage'
                                    pieLimit={pieLimit}
                                    handleChangePieLimit={handleChangePieLimit}
                                    toggleBtn={toggleBtn}
                                    handleChangeToggle={handleChangeToggle}
                                    wanOptions={wanOptions}
                                    usagePerSite={usagePerSite}
                                    handleSiteClick={handleSiteClick}
                                    colors={colors} 
                                />
                            </Grid>
                        }
                        { newSummaryWanType?.value == '' && !toggleBtn ? <Grid item xs={8} sm={8} md={8} lg={8}>
                            <Grid className={resetDate.length > 0 ? "legends-pie-new-container removeMarginTop" : "legends-pie-new-container"}>
                                <Grid className="time-series-legends-new">
                                    {barChartData && barChartData.map((usage, i) => (
                                        <Grid className="summary-pie-bar-legends">
                                            <div className="summary-pie-usage-dot" style={{ background: usage?.color }}></div>
                                            <span className= {barChartData[i]?.visible ? "barChart-legends" : 'disable-barChart-legends'} onClick={() => handleLegendClick(i)}>{usage.name}</span>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Grid className="line-bar-view">
                                    <Grid className="pieChat-selectTag">
                                        <FormControl variant="standard" className='selectEntry-pie'>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={selectedBin}
                                                onChange={handleBinChange}
                                            >
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 5 && <MenuItem value={'5m'}>5m</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10 && <MenuItem value={'10m'}>10m</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 15 && <MenuItem value={'15m'}>15m</MenuItem>} 
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 30 && <MenuItem value={'30m'}>30m</MenuItem>} 
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 60 && <MenuItem value={'1h'}>1h</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 120 && <MenuItem value={'2h'}>2h</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 180 && <MenuItem value={'3h'}>3h</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 360 && <MenuItem value={'6h'}>6h</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 720 && <MenuItem value={'12h'}>12h</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 1440 && <MenuItem value={'1d'}>1d</MenuItem>}
                                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10080 && <MenuItem value={'7d'}>7d</MenuItem>}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <img onClick={() => setChartView('column')} src={chartView == 'column' && !isHistogram ? barIcon : bar_chart} />
                                    <img onClick={() => setChartView('spline')} src={chartView == 'spline' ? line_chart : lineIcon} />

                                </Grid>
                                {resetDate.length > 0 &&
                                    <div>
                                        <FormControlLabel
                                            value="Zoom Back"
                                            control={<IconButton onClick={handleResetZoom}><ZoomOutIcon /></IconButton>}
                                            label=""
                                            labelPlacement="start"
                                        />
                                        <FormControlLabel
                                            value="Zoom Reset"
                                            control={<IconButton onClick={handleDefaultResetZoom}><RestartAltIcon /></IconButton>}
                                            label=""
                                            labelPlacement="start"
                                        />
                                    </div>
                                }
                            </Grid>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={barOptions}
                            />
                        </Grid> :
                        newSummaryWanType?.value && !toggleBtn ? <Grid item xs={8} sm={8} md={8} lg={8}>
                            <WanLineChart
                                title='Usage (Downlink vs Uplink)'
                                data={upDownData}
                                setChartView={setChartView}
                                setIsHistogram={setIsHistogram}
                                isHistogram={isHistogram}
                                resetDate={resetDate}
                                handleResetZoom={handleResetZoom}
    m                            handleDefaultResetZoom={handleDefaultResetZoom}
                                options={barOptions}
                                handleLegendClick={handleLegendClick}
                                chartView={chartView}
                                selectedBin={selectedBin}
                                handleBinChange={handleBinChange}
                            />
                        </Grid> : <Grid item xs={8} sm={8} md={8} lg={8}>
                            {isHistogram ? 
                              <SiteLineChart
                              title=''
                              data={histogramBySiteData}
                              setChartView={setChartView}
                              setIsHistogram={setIsHistogram}
                              isHistogram={isHistogram}
                              resetDate={resetDate}
                              handleResetZoom={handleResetZoom}
                              handleDefaultResetZoom={handleDefaultResetZoom}
                              options={histogramOptions }
                              handleSiteClick={handleSiteClick}
                              colors={colors}
                              chartView={chartView}
                              bucketValue={bucketValue}
                              handleBucket={handleBucket}
                              minBucket={minBucket}
                              maxBucket = {maxBucket}
                              setMinBucket={setMinBucket}
                              setMaxBucket = {setMaxBucket}
                              handleResetValue ={handleResetValue}
                              handleLegend = {handleLegend}
                              vesselLength = {vessels}
                          />
                          :
                          <SiteLineChart
                          title=''
                          data={siteChartUsage}
                          setChartView={setChartView}
                          setIsHistogram={setIsHistogram}
                          isHistogram={isHistogram}
                          resetDate={resetDate}
                          handleResetZoom={handleResetZoom}
                          handleDefaultResetZoom={handleDefaultResetZoom}
                          options={ barOptions}
                          handleSiteClick={handleSiteClick}
                          colors={colors}
                          chartView={chartView}
                          vesselLength = {vessels}
                          selectedBin={selectedBin}
                          handleBinChange={handleBinChange}
                      />
                        }
                        </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state?.authReducer,
    newUsagePieTop10Apps: state.authReducer.newUsagePieTop10Apps,
    newUsageperWantype: state.authReducer.newUsageperWantype,
    newHistogramUsageperWantype: state.authReducer.newHistogramUsageperWantype,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    newSummaryWanType:state?.authReducer?.newSummaryWanType,
    wanUsageBySites: state?.authReducer?.wanUsageBySites,
    newSummaryUploadDownload: state?.authReducer?.newSummaryUploadDownload,
    newSummaryHistogramBySite: state?.authReducer?.newSummaryHistogramBySite,
    newHistogramBySiteCount: state.authReducer.newHistogramBySiteCount
});

export default withRouter(
  connect(mapStateToProps, { getDashboardUsagePieTop10Apps, getDashboardPieUsageperWantype, getDashboardHistogramUsageperWantype, getDashboardHistogramUsageBySiteCount, getDashboardPieWanUsageBySites, getDashboardUsageHistogramUpDownLink, getDashboardUsageHistogramBySite, getNewSummaryUsageTable, getTopWidgetDataNewSumm, getTopWidgetWanlinkStatusNewSumm })(ChartsComponent)
);