import { useState, useRef, Fragment, useEffect } from "react";
import { withRouter, useHistory,useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { nanoid } from 'nanoid';
import _, { cloneDeep } from "lodash";

import AlertPingIcon from "../../asset/image/alert-ping.svg";
import AlarmIcon from '@mui/icons-material/Alarm';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { BC_CREATE_ALERT } from "../../constants/Constants";
import { EventAlertConfiguration } from "./EventAlertConfiguration";
import { getVesselEdgeTopologyProducts } from "../../actions/Users/authenticateDashboard";
import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI, getEncodedURI, queryParamK4Ids } from "../../utils/util";
import { getServiceFunction } from "../../utils/util";

import { updatePermission } from "../../actions/Users/authenticate";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { RESOURCE_FIELD_VESSELNAME, RESOURCE_FIELD_ID, SITE_NOTES, EDGE_EVENTS, CHARTS } from "../../utils/constants";

import { createAlertConfiguration } from "../../actions/Users/authenticateAlert";

import "./index.css";
import { Alert, AlertConfig, AlertConfigTypes, EventAlert, MetricAggregation, MetricConditionEndInterval, MetricConditionStartInterval, MetricConditionType, MetricTypes, NoDataErrorOptions } from "./types";
import { IconButton } from "@mui/material";
import { LabelConfiguration } from "./LabelConfiguration";
import MetricAlertConfiguration from "./MetricAlertConfiguration";
import { getSiteInterfaces } from "../../actions/Users/authenticateCharts";

const VIEW_VESSEL_SUMMARY = "view-vessel-summary";

function IndicatorLineIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} viewBox="0 0 7 74" sx={{ width: 7, height: 74, fill: "none" }}>
            <line x1="3.5" y1="-2.18557e-08" x2="3.5" y2="74" stroke="#EBEBEB" />
            <circle cx="3.5" cy="3.5" r="3.5" fill="#D9D9D9" />
            <circle cx="3.5" cy="70.5" r="3.5" fill="#D9D9D9" />
        </SvgIcon>
    );
}

const buttonStyle = { marginLeft: '1rem', background: '#264C86', "&:hover": { background: '#264C86' } };
const outlineButtonStyle = { border: "1px solid #EBEBEB", borderRadius: "4px", color: '#2F4B82', "&:hover": { border: "1px solid #EBEBEB" } };

const ALERT_TYPES = {
    "Event": "event_alerts",
    "Metric": "metric_alerts",
    "Geo Location": "geo_alerts",
}

export interface DeviceInterface {
    deviceName: string
    deviceId: string
    displayInterfaces: string[]
}

const CreateAlert = (props) => {

    const { authReducer, errorReducer, match, getVesselEdgeTopologyProducts, createAlertConfiguration, getSiteInterfaces } = props;

    const history = useHistory()
    const location = useLocation();

    const _q = getDecodeURI(location?.search);

    const setBreadcrumbInfo: any = useRef();
    BC_CREATE_ALERT[0]["title"] = _q?.ouName ? _q.ouName : BC_CREATE_ALERT[0]["title"];
    const [breadcrumb, setBreadcrumb] = useState<any[]>(BC_CREATE_ALERT);
    const [loading, setLoading] = useState(false);
    const [configuring, isConfiguring] = useState(false);
    const [devices, setDevices] = useState<string[]>([])
    const [siteId, setSiteId] = useState<any>(_q?.k4Ids ? _q.k4Ids.split(":")[0] : "");
    const [valid, isValid] = useState(false);

    const [alert, setAlert] = useState<Alert>({
        name: '',
        root_condition: '',
        event_alerts: {},
        metric_alerts: {},
        labels: {},
        description: ''
    });
    const [alertConfigs, setAlertConfigs] = useState<AlertConfig[]>([]);

    useEffect(() => {
        const viewVesselSummaryService = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["service"];
        const viewVesselSummaryFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["function"];

        let pathName = "/fleet-dashboard/sites";
        let paramsK4Ids= localStorage.getItem(queryParamK4Ids);
        let queryParams =  getDecodeURI(location?.search);

        if (queryParams?.k4Ids && paramsK4Ids){
          queryParams["k4Ids"]=paramsK4Ids;
        } else {
          delete queryParams["k4Ids"]
        }

        if(!(queryParams?.filterApplied && queryParams?.internetStatus)){
            queryParams.filterApplied = "true";
            queryParams.internetStatus = "Online,Offline";
        }

        let pURL = pathName
        if(!_.isEmpty(queryParams)){
          pURL= pURL + "?"+getEncodedURI(queryParams);
        }

        if (pURL && breadcrumb) {
            if (breadcrumb[1]) {
                if (breadcrumb[1].hasOwnProperty("link")) {
                    breadcrumb[1]["link"] = pURL;
                }
            }
            if (breadcrumb[2]) {
                if (breadcrumb[2].hasOwnProperty("link")) {
                    breadcrumb[2]["link"] = pURL;
                }
            }
            setBreadcrumbInfo.current(breadcrumb);
        }
        let authorized = checkLoggedInUserAuthorizedToViewPage(viewVesselSummaryService, viewVesselSummaryFunction);
        // setIsAuthorizedToViewPage(authorized);
        let viewResources: any = [];
        let viewFunctionPermission = getServiceFunction(viewVesselSummaryService, viewVesselSummaryFunction);
        if (viewFunctionPermission && viewFunctionPermission.resources) {
            viewResources = viewFunctionPermission.resources;
        }
        let vesselName = "-";
        if (viewResources.length > 0) {
            let _selectedVessel = viewResources.find(viewResource => viewResource[RESOURCE_FIELD_ID] === siteId)
            if (_selectedVessel) {
                vesselName = _selectedVessel["name"];
            }
        }
        breadcrumb[3] = { title: vesselName }

        const info = {
            permission: {
                service: viewVesselSummaryService,
                serviceFunction: viewVesselSummaryFunction,
            },
            isMultiVessel: false,
            isSingleVessel: false,
            showOu: false,
            ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
        };
        updatePermission(info);
        if (!_.isEmpty(siteId)) {
            getVesselEdgeTopologyProducts(1, siteId);
        }
    }, [siteId])

    useEffect(() => {
        // check name and condition are there
        let _valid = !_.isEmpty(alert.name) && !_.isEmpty(alert.root_condition);

        // check event alerts have atleast one entry and exit condition
        let validEventConfigs = _.isEmpty(alert.event_alerts) || (Object.keys(alert.event_alerts).filter((id: string) => {
            return !_.isEmpty(alert.event_alerts[id].entry) && !_.isEmpty(alert.event_alerts[id].exit)
        }).length > 0);

        // validate metric alerts
        let validMetricAlerts = _.isEmpty(alert.metric_alerts) || (Object.keys(alert.metric_alerts).filter((id: string) => {
            return !_.isEmpty(alert.metric_alerts[id].device) && !_.isEmpty(alert.metric_alerts[id].interface)
        }).length > 0)

        // has atleast one rule
        let atleastOneRule = !_.isEmpty(alert.event_alerts) || !_.isEmpty(alert.metric_alerts);

        _valid = _valid && atleastOneRule && validEventConfigs && validMetricAlerts;

        isValid(_valid)

    }, [alert])

    useEffect(() => {
        let _alert: Alert = {
            ...alert
        };
        _alert.event_alerts = {}
        _alert.metric_alerts = {}

        for (let config of alertConfigs) {
            switch (config.type) {
                case AlertConfigTypes.Event:
                    _alert.event_alerts[config.id] = config.data
                    break;
                case AlertConfigTypes.Metric:
                    _alert.metric_alerts[config.id] = config.data
                    break;
                default:
                    break;
            }
        }
        setAlert(_alert)
    }, [alertConfigs])

    useEffect(() => {
        const site = authReducer.vesselEdgeTopologyProducts?.data?.manage_location[0];
        if (!_.isEmpty(site)) {
            const _devices = site?.products?.map(p => p?.devices?.map(d => d.id))?.flat();
            setDevices(_devices);
        }
    }, [authReducer.vesselEdgeTopologyProducts])

    const handleInitAlert = ({ name, alertTypes, condition, description }: { name: string, alertTypes: { [key: string]: Boolean; }, condition: string, description: string}) => {
        let _alert = {
            ...alert,
            name: name,
            root_condition: condition,
            description: description
        };
        let _alertConfigs: AlertConfig[] = []
        for (let alertType of Object.keys(alertTypes)) {
            if (alertTypes[alertType]) {
                let config = createConfig(alertType);
                if (config != null) {
                    _alertConfigs.push(config)
                }
            }
        }
        setAlert(_alert);
        setAlertConfigs(_alertConfigs)
        isConfiguring(true);
    }

    const handleAddConfig = (alertType) => {
        let config = createConfig(alertType);
        if (config != null) {
            setAlertConfigs([...alertConfigs, config])
        }
    }

    const createConfig = (alertType): AlertConfig | null => {
        switch (alertType) {
            case AlertConfigTypes.Event:
                return {
                    id: nanoid(),
                    type: AlertConfigTypes.Event,
                    data: {
                        entry: [],
                        exit: []
                    }
                }
            case AlertConfigTypes.Metric:
                return {
                    id: nanoid(),
                    type: AlertConfigTypes.Metric,
                    data: {
                        device: '',
                        interface: '',
                        metric: MetricTypes["Link Status"],
                        every: '15m',
                        for: '15m',
                        metric_condition: {
                            aggregation: MetricAggregation.average,
                            start_interval: MetricConditionStartInterval["1h"],
                            end_interval: MetricConditionEndInterval["now"],
                            condition: MetricConditionType.above,
                            condition_value: 0.0
                        },
                        nodata: NoDataErrorOptions.Normal,
                        error: NoDataErrorOptions.Normal,
                    }
                }
            default:
                return null;
        }
    }

    const handleDeleteConfig = (id) => {
        let _alertConfigs = alertConfigs.filter(rule => rule.id !== id);
        setAlertConfigs(_alertConfigs)
    }

    const handleChangeConfig = (id, config) => {
        let configIndex = alertConfigs.findIndex(c => c.id === id);
        alertConfigs[configIndex].data = config
        setAlertConfigs([...alertConfigs])
    }

    const handleChangeLabel = (labels) => {
        let _alert = { ...alert };
        _alert.labels = labels
        setAlert(_alert)
    }

    const handleCreate = async () => {
        try {
            let _alert = cloneDeep(alert);
            await createAlertConfiguration(siteId, {
                name: _alert.name,
                root_condition: _alert.root_condition,
                event_alerts: _alert.event_alerts,
                metric_alerts: _alert.metric_alerts,
                labels: _alert.labels,
                description: _alert?.description
            });
            history.goBack();
        } catch (error) {
            console.log(error)
        }
    }

    const handleCancelAlertCreation = () => {
        history.goBack()
    }

    return (
        <Fragment>
            <BreadCrumbs breadcrumbinfo={breadcrumb} setBreadcrumbInfo={setBreadcrumbInfo} loading={loading}></BreadCrumbs>
            <div className="create_alert--container create_alert--background">
                {
                    configuring ? (
                        <Fragment>
                            <div className="create_alert--alert_header">
                                <div className="create_alert--alert_header--alert_name">
                                    {alert.name}
                                </div>
                                <div className="">
                                    <IconButton aria-label="cancel" onClick={handleCancelAlertCreation}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>
                            {alertConfigs.map((config, i) => <Fragment key={config.id}>
                                {
                                    config.type === AlertConfigTypes.Event ? <EventAlertConfiguration key={config.id} config={config} devices={devices} handleDelete={handleDeleteConfig} handleChange={handleChangeConfig} showHeader={true} isDefaultCardView={false} /> :
                                        config.type === AlertConfigTypes.Metric ? <MetricAlertConfiguration key={config.id} config={config} siteId={siteId} handleDelete={handleDeleteConfig} handleChange={handleChangeConfig} showHeader={true} isDefaultCardView={false} /> :
                                            null
                                }
                                <div className="create_alert--workflow_line_container">
                                    <IndicatorLineIcon className="create_alert--workflow_line_icon" />
                                    {i !== alertConfigs.length - 1 ? <div className="create_alert--workflow_line_root_condition">
                                        {alert.root_condition}
                                    </div> : null}
                                </div>
                            </Fragment>)}
                            <div className="create_alert--add_alert_rule_menu">
                                <AddAlertConfigMenuButton handleAdd={handleAddConfig} />
                            </div>
                            <div className="create_alert--workflow_line_container">
                                <IndicatorLineIcon className="create_alert--workflow_line_icon" />
                            </div>
                            <div>
                                <LabelConfiguration defaultLabels={alert.labels} handleChange={handleChangeLabel} showHeader={true} isDefaultCardView={false} />
                            </div>
                            <div className="create_alert--workflow_line_container">
                                <IndicatorLineIcon className="create_alert--workflow_line_icon" />
                            </div>
                            <div className="create_alert--create_button--container">
                                <Button className={`create_alert--create_button--button ${valid ? 'valid' : ''}`} onClick={handleCreate} variant="contained" disabled={!valid} >Create</Button>
                            </div>
                        </Fragment>
                    ) : <div className="create-alert--alert_meta">
                        <AlertMeta
                            handleCancel={handleCancelAlertCreation}
                            handleInit={handleInitAlert}
                        />
                    </div>
                }
            </div>
        </Fragment>
    )
}

function AlertMeta({ handleCancel, handleInit }) {

    const [name, setName] = useState('');
    const [alertDescription, setAlertDescription] =useState('');
    const [alertTypes, setAlertTypes] = useState(() => {
        let _alertTypes = {};
        Object.keys(AlertConfigTypes).forEach(key => {
            _alertTypes[AlertConfigTypes[key]] = false;
        })
        return _alertTypes;
    })
    const [condition, setCondition] = useState();
    const [valid, isValid] = useState(false);

    useEffect(() => {
        if (!_.isEmpty(name) && !_.isEmpty(alertDescription) && !_.isEmpty(condition) && _.keys(alertTypes).filter(selected => alertTypes[selected]).length > 0) {
            isValid(true)
        } else {
            isValid(false)
        } 
    }, [name, alertTypes, condition,alertDescription])

    const handleSelectAlertTypes = ({ name, selected }) => {
        let _alertTypes = { ...alertTypes }
        _alertTypes[AlertConfigTypes[name]] = selected;
        setAlertTypes(_alertTypes);
    }

    const handleChangeCondition = (e) => {
        setCondition(e.target.value);
    }

    const handleClickNext = () => {
        handleInit({
            name: name,
            condition: condition,
            alertTypes: alertTypes,
            description: alertDescription
        })
    }

    return (
        <div className="create_alert--alert_meta--container">
            <div className="create_alert--alert_meta--title">
                <div>
                    <img src={AlertPingIcon} alt="Alert Ping Icon" />
                </div>
                <div className="create_alert--header">
                    Create an Alert
                </div>
                <div className="create_alert--sub_header">
                    <div>Set a name, select alert types</div>
                    <div>and select a condition to work with</div>
                </div>
            </div>
            <div className="create_alert--alert_meta--info">
                <div>
                    <div className="create_alert--form_group create_alert--form_group--column">
                        <label className="create_alert--form_group--label" htmlFor="create_alert--alert_meta--name">Set Name</label>
                        <input className="create_alert--form_group--input create_alert--form_group--input_text" type="text" name="alert_name" value={name} onChange={(e) => setName(e.target.value)} id="create_alert--alert_meta--name" placeholder="Alert Name" aria-label="Set Name" />
                    </div>
                    <div className="create_alert--form_group create_alert--form_group--column">
                        <label className="create_alert--form_group--label" htmlFor="create_alert--alert_meta--name">Set Description</label>
                        <input className="create_alert--form_group--input create_alert--form_group--input_text" type="text" name="alert_name" value={alertDescription} onChange={(e) => setAlertDescription(e.target.value)} id="create_alert--alert_meta--name" placeholder="Alert Description" aria-label="Set Description" />
                    </div>

                    <div className="create_alert--form_group create_alert--form_group--column">
                        <label className="create_alert--form_group--label" htmlFor="create_alert--alert_meta--name">Select Alert Type</label>
                        <div className="card_select--group--row">
                            <CardSelect onChange={handleSelectAlertTypes} name="Event" Icon={EventOutlinedIcon} defaultChecked={false} color="#8B8D97" checkedColor="#2F4B82" />
                            <CardSelect onChange={handleSelectAlertTypes} name="Metric" Icon={AlarmIcon} defaultChecked={false} color="#8B8D97" checkedColor="#2F4B82" />
                            <CardSelect onChange={handleSelectAlertTypes} disabled={true} name="Geo Location" Icon={LocationOnOutlinedIcon} defaultChecked={false} color="#8B8D97" checkedColor="#2F4B82" />
                        </div>
                    </div>

                    <div className="create_alert--form_group create_alert--form_group--column">
                        <label className="create_alert--form_group--label">Select Condition</label>
                        <div className="create_alert--form_group--radio_group create_alert--form_group--row" onChange={handleChangeCondition}>
                            <div className="create_alert--form_group--radio">
                                <input className="create_alert--form_group--input create_alert--form_group--input_radio" type="radio" name="condition" value="and" id="and-condition" />
                                <label className="create_alert--form_group--input_text" htmlFor="and-condition">AND</label>
                            </div>
                            <div className="create_alert--form_group--radio">
                                <input className="create_alert--form_group--input create_alert--form_group--input_radio" type="radio" name="condition" value="or" id="or-condition" />
                                <label className="create_alert--form_group--input_text" htmlFor="or-condition">OR</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="create_alert--form_group justify_content--end">
                    <Button variant="outlined" sx={outlineButtonStyle} onClick={handleCancel}>Cancel</Button>
                    <Button variant="contained" sx={buttonStyle} onClick={handleClickNext} disabled={!valid}>Next</Button>
                </div>
            </div>
        </div>
    )
}

function CardSelect({ name, Icon, defaultChecked, color, checkedColor, onChange, disabled = false }) {
    const [selected, setSelected] = useState(disabled ? false : defaultChecked);

    useEffect(() => {
        onChange({
            name: name,
            selected: selected
        });
    }, [selected, name])

    return (
        <div className="card_select--container" onClick={(e) => setSelected(disabled ? false : !selected)}>
            <div className="card_select--checkcircle">
                <div>
                    {
                        selected ?
                            <CheckCircleIcon sx={{ color: checkedColor }} /> :
                            <CircleOutlinedIcon sx={{ color: disabled ? "#E7E7E7" : color }} />
                    }
                </div>
            </div>
            <div className="card_select--icon_x_name">
                <div><Icon sx={{ color: disabled ? "#E7E7E7" : selected ? checkedColor : color }} /></div>
                <div className="card_select--name" style={{ color: disabled ? "#E7E7E7" : selected ? checkedColor : color }}>{name}</div>
            </div>
        </div>
    )
}

function AddAlertConfigMenuButton({ handleAdd }) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClickAdd = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <Fragment>
            <Button onClick={handleClickAdd} size="small" variant="contained" startIcon={<AddCircleOutlineIcon />} sx={{ margin: '1em', color: '#2F4B82', background: '#FFFFFF', "&:hover": { background: '#FFFFFF' }, borderRadius: '40px' }}>
                Add Alert Rule
            </Button>
            <Menu
                id="event-configuration-more-options"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'more-action-button',
                }}
                PaperProps={{
                    className: 'event-configuration-more-options-paper'
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {
                    Object.keys(AlertConfigTypes).map(alertType => <MenuItem disabled={alertType === 'Geo Location'} onClick={() => { setAnchorEl(null); handleAdd(AlertConfigTypes[alertType]) }}>{alertType}</MenuItem>)
                }
            </Menu>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer
});

export default withRouter(
    connect(mapStateToProps, { getVesselEdgeTopologyProducts, createAlertConfiguration, getSiteInterfaces })(CreateAlert)
);