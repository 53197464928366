import React,{ useState, useEffect, Fragment } from "react";
import { Grid, Button } from "@mui/material";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { checkLoggedInUserAuthorizedToViewPage } from "../../utils/util";
import NotAuthorized from "../NotAuthorized"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { SIDE_MENU_ITEM_SOFTWARE_LISTING } from "../../utils/constants";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { BC_SOFTWARE_BUILDS, BC_SOFTWARE_VERSIONS, BC_SOFTWARE_KONNECT_APP } from "../../constants/Constants";
import SoftwareListingTable from "../../components/SoftwareListingTable";
import { updatePermission } from "../../actions/Users/authenticate";
import SoftwareVersionsTable from "../../components/SoftwareVersionsTable";
import { getDecodeURI, getEncodedURI } from '../../utils/util';
import _ from "lodash";
import "./softwareList.css";
import SoftwareVersionKonnectAppDetails from "../../components/SoftwareVersion-KonnectApp";
import CommonFilters from "../../components/BreadCrumbs/CommonFilters";
import TabsComponent from "../../components/NewTabs";

const SoftwareList = (props) => {
  const { authReducer, history, match, location, updatePermission } = props;
  const [isAuthorizedToViewPage, setIsAuthorizedToViewPage] = useState<Boolean>(false);
  const [loading, setLoading] = useState(false);
  const [timePeriod, setTimePeriod] = useState({});
  const EDGE_ADMINISTRATION = "edge-administration";
  const not_authorized_page_title = "Software Listing";
  const not_authorized_page_message = "You are not authorised to view software listing";
  const viewSoftwareService = SERVICE_FUNCTION_TO_FEATURE[EDGE_ADMINISTRATION]["service"];
  const viewSoftwareFunction = SERVICE_FUNCTION_TO_FEATURE[EDGE_ADMINISTRATION]["function"];
  BC_SOFTWARE_BUILDS[0]["title"] = authReducer?.selectedOu?.name ? authReducer.selectedOu.name: BC_SOFTWARE_BUILDS[0]["title"];
  BC_SOFTWARE_VERSIONS[0]["title"] = authReducer?.selectedOu?.name ? authReducer.selectedOu.name : BC_SOFTWARE_VERSIONS[0]["title"];
  BC_SOFTWARE_KONNECT_APP[0]["title"] = authReducer?.selectedOu?.name ? authReducer.selectedOu.name: BC_SOFTWARE_KONNECT_APP[0]["title"];
  let breadcrumbInfo  = BC_SOFTWARE_VERSIONS;
  const BUILDS = "Current EOS";
  const VERSIONS = "Deployed EOS";
  const KONNECT_APP = "Konnect App"
  let _q = getDecodeURI(location?.search);
  const [selectedButton, setSelectedButton] = useState<any>(_q.hasOwnProperty("tab") && !_.isEmpty(_q.tab) ? _q.tab : VERSIONS);
  const setBreadcrumbInfo: any = React.useRef();

  const [isAuthorizedToViewBetaFeature, setIsAuthorizedToViewBetaFeature] = useState<Boolean>(false);
  const betaFeatureService = SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["service"];
  const betaFeatureFunction = SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["function"];

  if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_SOFTWARE_LISTING !== authReducer.sideMenu.menuItem)) {
    authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_SOFTWARE_LISTING };
  }

  useEffect(() => {
    if (isAuthorizedToViewPage) {
      applyBreadCumbAndPermissionChanges()
    }
    permissionChanges(true);
  }, [selectedButton]);

  const applyBreadCumbAndPermissionChanges = () => {
      if (VERSIONS == selectedButton) {
        permissionChanges(true)
      } else {
        permissionChanges(false)
      }
      breadcrumbInfo[2]["title"] = selectedButton
      setBreadcrumbInfo.current(breadcrumbInfo)
  }
  useEffect(() => {
    let authorized = checkLoggedInUserAuthorizedToViewPage(viewSoftwareService, viewSoftwareFunction);
    setIsAuthorizedToViewPage(authorized);

    const authorizedBetaUser = checkLoggedInUserAuthorizedToViewPage(betaFeatureService, betaFeatureFunction);
    setIsAuthorizedToViewBetaFeature(authorizedBetaUser)

    // applyBreadCumbAndPermissionChanges()
  }, []);

  useEffect(() => {
    if(!_.isEmpty(authReducer.selectedOu) && authReducer.selectedOu.hasOwnProperty("name") && authReducer.selectedOu.name){
      if(breadcrumbInfo && breadcrumbInfo[0]){
        breadcrumbInfo[0]["title"] =  authReducer.selectedOu.name;
      }
    }
  }, [authReducer.selectedOu]);

  const permissionChanges = (showOu) => {
    const info = {
      permission: {
        service: viewSoftwareService,
        serviceFunction: viewSoftwareFunction,
      },
      isMultiVessel: false,
      isSingleVessel: false,
      showOu: showOu,
      ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
    };
    updatePermission(info);
  }
  const doNavigateToBuilds = () => {
    setSelectedButton(BUILDS);
    breadcrumbInfo[1]["link"] =  "/softwareList";

    let params: any = getDecodeURI(location?.search);
    delete params.search;
    delete params.page;
    delete params.limit;
    delete params.sort;
    delete params.sortOrder;
    params.tab = BUILDS;
    doNavigate(location.url, params);
  }

  const doNavigateToVersions = () => {
    setSelectedButton(VERSIONS);
    if(breadcrumbInfo && breadcrumbInfo[1] && breadcrumbInfo[1].hasOwnProperty("link")){
      delete breadcrumbInfo[1]["link"];
    }

    let params: any = getDecodeURI(location?.search);
    delete params.tab;
    doNavigate(location.url, params);
  }

  const doNavigateToKonnectAppTab = () => {
    setSelectedButton(KONNECT_APP);
    breadcrumbInfo[1]["link"] =  "/softwareList";

    let params: any = getDecodeURI(location?.search);
    delete params.search;
    delete params.page;
    delete params.limit;
    delete params.sort;
    delete params.sortOrder;
    params.tab = KONNECT_APP;
    doNavigate(location.url, params);
  }

  const doNavigate = (pathname, params = {}) => {
    history.push({ pathname: pathname, search: `?${getEncodedURI(params)}` });
  }

  useEffect(() => {
    setLoading(authReducer.softwareListingLoading);
  }, [authReducer.softwareListingLoading]);

const handleSiteClick = (row) => {
  let params: any = getDecodeURI(location?.search);
  row && row.k4Id ? params.k4Ids = `${row.k4Id}:${row.x}` : row && row.location_id ? params.k4Ids = `${row.location_id}:${row.site}`:  delete params.k4Ids;
  delete params.page;
  delete params.limit;
  delete params.sort;
  delete params.sortOrder;
  delete params.search;
  doNavigate(params);
}

  const doNavigateTab = (pathname, tabName) => {
    const currentParams = getDecodeURI(location?.search);
    const params:any = {};
    if (currentParams.ouName) {
      params['ouName'] = currentParams.ouName;
    }
    if (currentParams.ouId) {
      params['ouId'] = currentParams.ouId;
    }
    if (currentParams.k4Ids) {
      params['k4Ids'] = currentParams.k4Ids;
    }
    if (currentParams.sideNav) {
      params['sideNav'] = currentParams.sideNav
    }
    if (currentParams.interval) {
      params['interval'] = currentParams.interval
    }
    if (currentParams.startDate && currentParams.startDate > 1) {
      params['startDate'] = currentParams.startDate
    }
    if (currentParams.endDate && currentParams.endDate > 1) {
      params['endDate'] = currentParams.endDate
    }
    params.tab = tabName;
    history.push({ pathname, search: getEncodedURI(params) });
    setSelectedButton(tabName);
  }

  const tabs = [
    { tabName: 'Deployed EOS', tabValue: VERSIONS, pathName: '/softwareList' },
    { tabName: 'Current EOS', tabValue: BUILDS, pathName: '/softwareList' },
    { tabName: 'Konnect App', tabValue: KONNECT_APP, pathName: '/softwareList' }
  ]

  return (
    <div className="Form">
      <CommonFilters breadcrumbinfo={breadcrumbInfo} setBreadcrumbInfo={setBreadcrumbInfo} loading={loading || authReducer?.userPreferencesLoading} setTimePeriod={setTimePeriod} handleSiteClick={handleSiteClick} showWantType={false} showSummaryPeriod={false}></CommonFilters>
      <TabsComponent tabs={tabs} handleChangeTab={doNavigateTab} activeTab={selectedButton} />
      <Grid item container style={{ backgroundColor: "#F4F7F8" }}>
        <Grid item className= "software-main-container">
          {
            isAuthorizedToViewPage ? (
              <div>
                {selectedButton === BUILDS ? (
                  <SoftwareListingTable />
                ) : selectedButton === VERSIONS ? (
                  <Fragment>
                    <SoftwareVersionsTable permissionChanges={permissionChanges}/>
                  </Fragment>
                ) : selectedButton === KONNECT_APP ? (
                  <Fragment>
                    <SoftwareVersionKonnectAppDetails permissionChanges={permissionChanges}/>
                  </Fragment>
                ) : null}
              </div>
            )
            :
              <Grid item container>
                <NotAuthorized pageTitle={not_authorized_page_title} PageMessage={not_authorized_page_message} />
              </Grid>
          }
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, { updatePermission })(SoftwareList)
);
