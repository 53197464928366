import { connect, useDispatch } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { ConfigStatus } from './types';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import close from "../../../asset/image/close.svg";
import "./DeviceStatuses.css";
import _ from "lodash";
import { getGroupTranscationConfigStatus } from "../../../actions/Users/authenticateInventory";
import JsonTooltip from "../../JSONTooltip/JsonTooltip";
import { GET_GROUP_TRANSCATION_CONFIG_STATUS } from "../../../actions/types";
import { DEFAULT_PAGE_SIZE, NEW_PER_PAGE_ITEMS } from "../../../constants/Constants";
import { convertDateTimeIntoTimezone, getDecodeURI } from "../../../utils/util";
import { Pagination } from "../../../components/Pagination/index";
import Download from '../../../asset/image/DownloadUsage.svg';
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import DownloadCSV, { clearCsvDownload } from "../../DownloadCSV";
import { Replay } from "@material-ui/icons";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";

const DeviceStatuses = (props) => {
    const { authReducer, deviceStatusesListPopup, setDeviceStatusesListPopup, handleRetry, clickedRow, clickedStatus, getGroupTranscationConfigStatus, downloadAsCSV } = props;

    const location = useLocation()
    const dispatch = useDispatch()
    const searchTimeoutRef = useRef<NodeJS.Timeout>();

    let _q = getDecodeURI(location?.search);

    const [tableData, setTableData] = useState<ConfigStatus[]>([]);
    const [totalCount, setTotalCount] = useState(0)

    const [sites, setSites] = useState("")
    const [devices, setDevices] = useState("")
    const [groupTransactionId, setGroupTransactionId] = useState("")
    const [searchText, setSearchText] = useState("")
    const [sortBy, setSortBy] = useState("created_at")
    const [sortOrder, setSortOrder] = useState("desc")
    const [page, setPage] = useState(1)
    const [size, setSize] = useState<any>(_q.hasOwnProperty("size") ? _q.size : DEFAULT_PAGE_SIZE);

    useEffect(() => {
        if (deviceStatusesListPopup && !_.isEmpty(clickedRow)) {
            if(!_.isEmpty(clickedRow.site_id) && !_.isEmpty(clickedRow.device_id) && !_.isEmpty(clickedRow.status_obj) && !_.isEmpty(clickedRow.group_transaction_id)){
                let status = clickedRow.status_obj;
                let deviceIds = []
                if (!_.isEmpty(status) && status.hasOwnProperty(clickedStatus)) {
                    if (!_.isEmpty(status[clickedStatus].devices)) {
                        deviceIds = status[clickedStatus].devices.map(function (device) { return device.id; })
                    }
                } else if(clickedStatus.toLowerCase() == "total"){
                    deviceIds = clickedRow.device_id.split(",");
                }

                if(deviceIds.length > 0){
                    let _sites = "'" + clickedRow.site_id.split(",").join("','") + "'"
                    let _devices = "'" + deviceIds.join("','") + "'"
                    let _groupTransactionId = clickedRow.group_transaction_id
                    setSites(_sites)
                    setDevices(_devices)
                    setGroupTransactionId(_groupTransactionId)
                    getGroupTranscationConfigStatus(_sites, _devices, _groupTransactionId, searchText, sortBy, sortOrder, page, size)
                }
            }
        }
    }, [clickedRow])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getGroupTranscationConfigStatus)) {
            let statuses = authReducer.getGroupTranscationConfigStatus
            statuses?.map((row: ConfigStatus) => {
                let status = ""
                if(!_.isEmpty(row.status)){
                    status = row.status
                    status = status.length > 1 ? status.charAt(0).toUpperCase() + status.slice(1) : status.charAt(0)
                }
                if (status.toLowerCase() == "upgraded") {
                    status = "Success"
                }

                row.status = status

                if (!_.isEmpty(row.status_history)) {
                    row.status_history = JSON.stringify(row.status_history)
                }
            })
            setTableData(statuses)
            setTotalCount(authReducer.getGroupTranscationConfigStatusTotalCount)
            dispatch({
                type: GET_GROUP_TRANSCATION_CONFIG_STATUS, payload: {
                    data: {
                        rows: [
                            [[], 0]
                        ]
                    }
                }
            })
        }
    }, [authReducer.getGroupTranscationConfigStatus])

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'group-transcation-config-restore-status') {
                const [_columns, status] = authReducer.csvDataDownloded;
                if (status && status.length > 0) {
                    const rows = status[0];
                    if (!_.isEmpty(rows) && rows.length > 0) {
                        DownloadCSV(convertToCsv([Object.keys(rows[0]), ...rows.map(row => Object.values(row))], authReducer.userTimezone)
                            , {
                                formatters: {
                                    4: 'ESC-COMMA',
                                    5: 'ESC-COMMA'
                                }
                            }
                        );
                    }
                }
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const closeDialog = () => {
        setDeviceStatusesListPopup(false)
        setTableData([])
        setTotalCount(0)
    }

    const handleSearch = (value: string) => {
        setTableData([])
        getGroupTranscationConfigStatus(sites, devices, groupTransactionId, value, sortBy, sortOrder, page, size)
    }

    const handleOnSearchClick = () => {
        handleSearch(searchText);
    }

    const handleOnSearchClear = () => {
        setSearchText('');
        handleSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }

    const handlePerPageChange = (e: any) => {
        let _perPage = parseInt(e.target.value)
        if (Number.isInteger(_perPage)) {
            setPage(1)
            setSize(_perPage)
            setTableData([])
            getGroupTranscationConfigStatus(sites, devices, groupTransactionId, searchText, sortBy, sortOrder, 1, _perPage)
        }
    }

    const handlePagination = (e, value) => {
        setPage(value)
        setTableData([])
        getGroupTranscationConfigStatus(sites, devices, groupTransactionId, searchText, sortBy, sortOrder, value, size)
    }

    const convertToCsv = (data: any[][], userTimezone: string) => {
        const [_columns, ...rows] = data;
        const csvRows: any[] = [];
        rows.forEach(row => {
            csvRows.push([row[17], row[7], row[0] === null ? 'Config Restore' : row[0], row[9], convertDateTimeIntoTimezone(row[5], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), convertDateTimeIntoTimezone(row[21], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), row[18]])
        })
        let startTime = 'Start Time (' + userTimezone + ')'
        let completeTime = 'Complete Time (' + userTimezone + ')'
        return [['Site', 'Device', 'Action', 'Version', startTime, completeTime, 'Status'], ...csvRows]
    }

    const handleDownloadReport = () => {
        const params: CsvParameters = {
            type: 'QUERY_PG',
            id: 'group-transcation-config-restore-status',
            payload: {
                "sites": sites,
                "devices": devices,
                "group_transaction_id": groupTransactionId,
                "search": searchText,
                "sort": sortBy,
                "order": sortOrder,
                "limit": totalCount,
                "offset": 0
            },
            queryName: 'GET_GROUP_TRANSCATION_CONFIG_RESTORE_STATUS',
        }
        downloadAsCSV(params);
    }

    const getReasonTitle = (row) => {
        if(row.status) {
            if(row?.status.includes("failed") || row?.status.includes("Failed") ) {
                if(row?.reason) {
                    return row?.reason;
                } else {
                    // return row?.status;
                }
            } else {
                return ""
            }
        }  else {
            return ""
        }
    }

    return (
        <div className="Form">
            <Dialog fullWidth maxWidth="lg" className="inventory-group-device-list-popup inventory-logs-status-pop-up inventory-content menuScroll" open={deviceStatusesListPopup}>
                <DialogTitle className="dialog-title">
                    Group: {clickedRow["group_name"]}
                    <img src={close} title="Close" className="close" onClick={() => { closeDialog() }} />
                </DialogTitle>
                <DialogContent className="dialog-content quota_management--dashboard">
                    <Grid item xs={12} sm={12} md={12} lg={12} className='disp-flex'>
                        <Grid item xs={6} sm={6} md={6} lg={6} className="search-text">
                            <TextField
                                variant="outlined"
                                placeholder="Type something"
                                classes={{ root: "input-box-def-conf" }}
                                value={searchText}
                                onChange={handleOnSearchChange}
                                onKeyDown={(e) => { if (e.key === 'Enter') { handleOnSearchClick() } }}
                                size="small"
                                InputLabelProps={{ className: "serachLabel" }}
                                InputProps={{
                                    className: "serachBar",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {!searchText && <SearchIcon
                                                className="cursorPointer"
                                                onClick={handleOnSearchClick}
                                            />}
                                            {searchText && <CloseIcon
                                                className="cursorPointer"
                                                onClick={handleOnSearchClear}
                                            />}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <div className="config-status">Status: {!_.isEmpty(clickedStatus) && clickedStatus.toLowerCase() == "total" ? "All" : clickedStatus}</div>
                        </Grid>
                        {
                            tableData.length > 0 &&
                            <Grid item xs={6} sm={6} md={6} lg={6} className="sim-anal-csv">
                                <div className="download-summary-btn marg-left-auto" onClick={() => handleDownloadReport()}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                            </Grid>
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner">
                        <Paper className="pagination-position config-pagination new-usage">
                            <Pagination
                                rowsPerPageOptions={NEW_PER_PAGE_ITEMS}
                                count={totalCount ? totalCount : 0}
                                rowsPerPage={size}
                                page={page}
                                onPageChange={handlePagination}
                                onRowsPerPageChange={handlePerPageChange}
                            />
                        </Paper>
                        <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer inventory-config-status">
                            <Table aria-label="simple sticky table" stickyHeader>
                                <TableHead className="usageReports-tableHead alerts-tableHead">
                                    <TableRow className="UsageReports-tableRow login-tableRow">
                                        <TableCell className="usage-fleetDataTable alert-left-align login-tableCell">SITE</TableCell>
                                        <TableCell className="usage-fleetDataTable alert-left-align login-tableCell">DEVICE</TableCell>
                                        <TableCell className="usage-fleetDataTable alert-left-align login-tableCell">VERSION</TableCell>
                                        <TableCell className="usage-fleetDataTable alert-left-align login-tableCell">START TIME</TableCell>
                                        <TableCell className="usage-fleetDataTable alert-left-align login-tableCell">COMPLETE TIME</TableCell>
                                        <TableCell className="usage-fleetDataTable alert-left-align login-tableCell">ACTION</TableCell>
                                        <TableCell className="usage-fleetDataTable alert-left-align login-tableCell">STATUS</TableCell>
                                    </TableRow>
                                </TableHead>
                                {tableData && 0 !== tableData?.length && !authReducer.loading ? (
                                    <TableBody className="tableBody usage-tableBody Table-Body-Opacity white-bg">
                                        {
                                            tableData?.map((row: ConfigStatus, index) =>
                                                <TableRow key={`${row.site_id}-${row.device_id}-${index}`}>
                                                    <TableCell className="alert-left-align inter-font Table-Cell-Opacity ellipsis" title={_.isEmpty(row.group_name) ? row.site_name : row.group_name}>{_.isEmpty(row.group_name) ? row.site_name : row.group_name}</TableCell>
                                                    <TableCell className="alert-left-align inter-font Table-Cell-Opacity inventory-group-table-devices ellipsis" title= {row?.device_name}>
                                                        {row?.device_name}
                                                    </TableCell>
                                                    <TableCell className="alert-left-align inter-font Table-Cell-Opacity">{_.isEmpty(row.config_name) ? (!_.isEmpty(row.eos_version) ? "v" + row.eos_version.split("~")[0] : "") : row.config_name}</TableCell>
                                                    <TableCell className="alert-left-align inter-font Table-Cell-Opacity">{convertDateTimeIntoTimezone(row.created_at, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                                    <TableCell className='alert-left-align inter-font Table-Cell-Opacity'>{convertDateTimeIntoTimezone(row.updated_at, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                                    <TableCell className="alert-left-align inter-font Table-Cell-Opacity">{_.isEmpty(row.action) ? "Config restore" : row.action}</TableCell>
                                                    <TableCell className='alert-left-align inter-font Table-Cell-Opacity'>
                                                        {
                                                             (getReasonTitle(row) ? <Tooltip title={getReasonTitle(row)}><span className="Pointer">{row.status.length > 1 ? row.status.charAt(0).toUpperCase() + row.status.slice(1) : row.status.charAt(0)}</span></Tooltip> : <span>{row.status.length > 1 ? row.status.charAt(0).toUpperCase() + row.status.slice(1) : row.status.charAt(0)}</span>)
                                                        }
                                                        {
                                                            !_.isEmpty(row.status) && row.status.toLowerCase().includes("failed") && !row.status.toLowerCase().includes("inprogress") ? <Tooltip title="Retry" key={`pmore-${Math.random()}`}>
                                                                <IconButton onClick={() => handleRetry(row)}>
                                                                    <Replay />
                                                                </IconButton>
                                                            </Tooltip> : null
                                                        }
                                                        {
                                                            _.isEmpty(row.status_history) || "null" == row.status_history ? null : <JsonTooltip className="prettyToolTip" json={row.status_history} />
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {
        getGroupTranscationConfigStatus,
        downloadAsCSV
    })(DeviceStatuses)
);