import React from "react";
import {cloneDeep} from "lodash";
import {
  FETCH_DATA_REQUEST, FETCH_DATA_SUCCESS, FETCH_VESSEL_VOAYGE_REQUEST, FETCH_VESSEL_KPI_DATA_SUCCESS, FETCH_VESSEL_VOYAGE_SUCCESS, FETCH_VESSEL_NAME_SUCCESS, FETCH_TOWER_DETAILS_SUCCESS, FETCH_CARRIER_DATA_SUCCESS, FETCH_CARRIER_DATA_INNER_LAYER_SUCCESS,
  FETCH_VESSEL_CURRENT_LOCATION_SUCCESS, FETCH_VESSEL_METRICS_INFO_SUCCESS, FETCH_UNIQUE_CARRIER_SUCCESS, FETCH_VESSEL_KPI_DATA, FETCH_SELCTED_VESSEL_VOYAGE_SUCCESS, FETCH_HEX_RSSI_HISTOGRAM_SUCCESS, FETCH_HEX_SINR_HISTOGRAM_SUCCESS, FETCH_HEX_SPEED_HISTOGRAM_SUCCESS, 
  SET_SELECTED_HEX,
  FETCH_VESSEL_CURRENT_LOCATION,
  SET_API_ERRORS,
  DELETE_API_ERRORS,
  FETCH_VSAT_COVERAGE_DATA_SUCCESS,
  FETCH_VSAT_COVERAGE_INNER_DATA_SUCCESS,
  FETCH_VSAT_HISTO_SUCCESS,
  FETCH_VSAT_TX_POWER_HISTO_SUCCESS,
  GET_VSAT_ALL_HEX_METRICS,
  SET_ENV,
  CLEAR_MAP_ERRORS,
  GET_EDGE_EVENTS_SUCCESS,
  FETCH_VESSEL_KPI_DATA_RATE_SUCCESS,
  FETCH_VESSEL_KPI_DATA_RTT_SUCCESS,
  FETCH_UNIQUE_VSAT_CARRIER_SUCCESS,
  SET_STARTLINK_DATA,
  GET_STARLINK_SPEED_DATA,
  GET_STARLINK_HISTO_SUCCESS,
  GET_STARLINK_LATENCY_HISTO_SUCCESS,
  GET_STARLINK_DROPPERCENTAGE_HISTO_SUCCESS,
  GET_STARLINK_SPEED_HISTO_SUCCESS,
  FETCH_UNIQUE_CARRIER_REQUEST,
  FETCH_CARRIER_DATA_REQUEST,
  FETCH_CARRIER_DATA_INNER_LAYER_REQUEST,
  FETCH_VSAT_COVERAGE_DATA_REQUEST,
  SET_STARTLINK_DATA_LOADING,
  GET_STARLINK_SPEED_DATA_LOADING,
  FETCH_HEX_SPEED_HISTOGRAM_REQUEST,
  FETCH_HEX_RSSI_HISTOGRAM_REQUEST,
  FETCH_HEX_SINR_HISTOGRAM_REQUEST,
  FETCH_UNIQUE_VSAT_CARRIER_REQUEST,
  GET_STARLINK_LATENCY_HISTO_REQUEST,
  GET_STARLINK_SPEED_HISTO_REQUEST,
  FETCH_SELCTED_VESSEL_VOYAGE_REQUEST,
  GET_STARLINK_DROPPERCENTAGE_HISTO_REQUEST,
  FETCH_TERMINAL_CURRENT_LOCATION_SUCCESS,
  FETCH_TERMINAL_CURRENT_LOCATION,
  FETCH_SELECTED_SITE_SERVICE_LINE_DATA_SUCCESS,
  FETCH_SELECTED_SITE_SERVICE_LINE_DATA,
  GET_ALL_STARLINK_SERVICE_LINES_REQUEST,
  GET_ALL_STARLINK_SERVICE_LINES,
  GET_MAP_SITES_STARLINK_COUNT,
  GET_MAP_SITES_STARLINK_LOADER,
  SET_EMPTY_VESSEL_VOYAGE,
  FETCH_VESSEL_VOYAGE_STARLINK_SUCCESS,
  FETCH_VESSEL_VOAYGE_STARLINK_REQUEST
} from "../actions/actionType";

let initialState = {
  loading: false,
  data: {},
  vesselData: [],
  voyageData: [],
  kpiData: {},
  carrierData: {},
  carrierDataLoading: false,
  vsatCoverageData: {},
  vsatCoverageDataLoading: false,
  carrierInnerLayerData: {},
  carrierInnerLayerDataLoading: false,
  vesselCurrentLocationDetails: {},
  terminalCurrentLocationDetails: {},
  vesselMetricsInfo: {},
  carriersList: {},
  carriersListLoading: {},
  error: null,
  voaygeLoading: false,
  networkLoading: false,
  vesselLocationLoading: false,
  coverageLoading: false,
  chartsLoading: false,
  selctedVoyageData: [],
  selctedVoyageDataLoading: false,
  sinrHistoData: {},
  sinrHistoDataLoading: false,
  rssiHistoData: {},
  rssiHistoDataLoading: false,
  speedHistoData: {},
  speedHistoDataLoading: false,
  selectedHexId: 0,
  vesselLastLocLoading:false,
  terminalLastLocLoading: false,
  apiErrorHandling: [],
  vsatCoverageInnerLayerData:{},
  vsatHistoDetails: {},
  vsatTxPowerDetails: {},
  vsatHexAllMetrics: {},
  edgeEventsData: {},
  env:"",
  vesselDataRate: {},
  vesselDataRTT: {},
  vsatCarrierList: {},
  vsatCarrierListLoading: false,
  starLinkData: {},
  starLinkDataLoading: false,
  starLinkSpeedData: {},
  starLinkSpeedDataLoading: false,
  starLinkLossData: {},
  starLinkHistoDetails: {},
  starLinkLatencyHistoDetails: {},
  starLinkLatencyHistoDetailsLoading: false,
  starLinkDropPercentageHistoDetails: {},
  starLinkDropPercentageHistoDetailsLoading: false,
  starLinkSpeedHistoDetails: {},
  starLinkSpeedHistoDetailsLoading: false,
  selectedSiteOrServiceLineData: {},
  siteSelectionOrServiceLineLoading: false,
  getServiceLineandSitesCount : {},
  getServiceLineandSitesCountLoading : false,
  starlinkLocLoading: false,
  starlinkLocationData: {}
};

function products(state = initialState, action: any) {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.item.getLinkAvailAggs
      };
    case FETCH_TOWER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.item
      };
    case FETCH_VESSEL_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        vesselData: action.item
      };
    case FETCH_VESSEL_VOAYGE_STARLINK_REQUEST: 
    return {
      ...state,
      starlinkLocLoading: true,

    }
    case FETCH_VESSEL_VOYAGE_STARLINK_SUCCESS:
      return {
        ...state,
        starlinkLocLoading: false,
        starlinkLocationData: action.item
      }
    case FETCH_VESSEL_VOYAGE_SUCCESS:
      return {
        ...state,
        voaygeLoading: false,
        voyageData: action.item
      };
    case SET_EMPTY_VESSEL_VOYAGE:
      return {
        ...state,
        voyageData: {},
        starlinkLocationData: {}
      }
    case FETCH_SELCTED_VESSEL_VOYAGE_SUCCESS:
    return {
      ...state,
      selctedVoyageDataLoading: false,
      selctedVoyageData: action.item
    };
    case FETCH_SELCTED_VESSEL_VOYAGE_REQUEST:
      return {
        ...state,
        selctedVoyageDataLoading: true
      } 
    case FETCH_VESSEL_VOAYGE_REQUEST:
      return {
        ...state,
        voaygeLoading: true,
        error: null
      };
    case FETCH_VESSEL_KPI_DATA:
      return {
        ...state,
        chartsLoading: true,
        error: null
      };
    case FETCH_VESSEL_KPI_DATA_SUCCESS:
      return {
        ...state,
        chartsLoading: false,
        kpiData: action.item
      };
    case FETCH_VESSEL_KPI_DATA_RATE_SUCCESS:
      return {
        ...state,
        chartsLoading: false,
        vesselDataRate: action.item
      };
    case FETCH_VESSEL_KPI_DATA_RTT_SUCCESS:
      return {
        ...state,
        chartsLoading: false,
        vesselDataRTT: action.item
      };
    case FETCH_CARRIER_DATA_SUCCESS:
      return {
        ...state,
        carrierDataLoading: false,
        carrierData: action.item
      };
    case FETCH_CARRIER_DATA_REQUEST:
      return {
        ...state,
        carrierDataLoading: true
      }
    case FETCH_CARRIER_DATA_INNER_LAYER_SUCCESS:
      return {
        ...state,
        carrierInnerLayerDataLoading: false,
        carrierInnerLayerData: action.item
      };
    case FETCH_CARRIER_DATA_INNER_LAYER_REQUEST:
      return {
        ...state,
        carrierInnerLayerDataLoading: true
      }
    case FETCH_VESSEL_CURRENT_LOCATION_SUCCESS:
      return {
        ...state,
        vesselLastLocLoading: false,
        vesselCurrentLocationDetails: action?.item
      };
    case FETCH_VESSEL_CURRENT_LOCATION:
      return {
        ...state,
        vesselLastLocLoading: true,
        error: null
      };
      case FETCH_TERMINAL_CURRENT_LOCATION_SUCCESS:
        return {
          ...state,
          terminalLastLocLoading: false,
          terminalCurrentLocationDetails: action?.item
        };
      case FETCH_TERMINAL_CURRENT_LOCATION:
        return {
          ...state,
          terminalLastLocLoading: true,
          error: null
        };
    case FETCH_VESSEL_METRICS_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        vesselMetricsInfo: action.item
      };
    case FETCH_UNIQUE_CARRIER_SUCCESS:
      return {
        ...state,
        carriersListLoading: false,
        carriersList: action.item
      };
    case FETCH_UNIQUE_CARRIER_REQUEST: 
      return {
        ...state,
        carriersListLoading: true,
        error: null
      }
    case FETCH_UNIQUE_VSAT_CARRIER_SUCCESS:
      return {
        ...state,
        vsatCarrierListLoading:false,
        vsatCarrierList: action.item
      }
    case FETCH_UNIQUE_VSAT_CARRIER_REQUEST:
      return {
        ...state,
        vsatCarrierListLoading: true
      }
    case FETCH_HEX_RSSI_HISTOGRAM_SUCCESS:
    return {
      ...state,
      rssiHistoDataLoading: false,
      rssiHistoData: action.item
    };
    case FETCH_HEX_RSSI_HISTOGRAM_REQUEST:
      return {
        ...state,
        rssiHistoDataLoading: true
      }
    case FETCH_HEX_SINR_HISTOGRAM_SUCCESS:
      return {
        ...state,
        sinrHistoDataLoading: false,
        sinrHistoData: action.item
      };
    case FETCH_HEX_SINR_HISTOGRAM_REQUEST:
      return {
        ...state,
        sinrHistoDataLoading: true
      }
    case GET_STARLINK_HISTO_SUCCESS:
      return {
        ...state,
        loading: false,
        starLinkHistoDetails: action.item
      }
    case GET_STARLINK_LATENCY_HISTO_SUCCESS:
      return {
        ...state,
        starLinkLatencyHistoDetailsLoading: false,
        starLinkLatencyHistoDetails: action.item
      }
    case GET_STARLINK_LATENCY_HISTO_REQUEST:
      return {
        ...state,
        starLinkLatencyHistoDetailsLoading: true
      }
    case GET_STARLINK_DROPPERCENTAGE_HISTO_SUCCESS:
      return {
        ...state,
        starLinkDropPercentageHistoDetailsLoading: false,
        starLinkDropPercentageHistoDetails: action.item
      }
    case GET_STARLINK_DROPPERCENTAGE_HISTO_REQUEST:
      return {
        ...state,
        starLinkDropPercentageHistoDetailsLoading: true
      }
    case GET_STARLINK_SPEED_HISTO_SUCCESS:
      return {
        ...state,
        starLinkSpeedHistoDetailsLoading: false,
        starLinkSpeedHistoDetails: action.item
      }
    case GET_STARLINK_SPEED_HISTO_REQUEST:
      return {
        ...state,
        starLinkSpeedHistoDetailsLoading: true
      }
    case FETCH_HEX_SPEED_HISTOGRAM_SUCCESS:
      return {
        ...state,
        speedHistoDataLoading: false,
        speedHistoData: action.item
      };
    case FETCH_HEX_SPEED_HISTOGRAM_REQUEST:
      return {
        ...state,
        speedHistoDataLoading: true
      }
    case SET_SELECTED_HEX:
      return {
        ...state,
        selectedHexId: action.item
      }
      case SET_API_ERRORS:
       let errorHandling:any[] =  cloneDeep(state.apiErrorHandling);
       errorHandling.push({apiName:action?.item?.errorKey,errorObject: action.item,env:state.env,userEmail:localStorage.getItem("loggedInEmail")})
      return {
        ...state,
        apiErrorHandling: errorHandling
    
      }
    case FETCH_VSAT_COVERAGE_DATA_SUCCESS:
      return {
        ...state,
        vsatCoverageDataLoading: false,
        vsatCoverageData: action?.item
      };
    case FETCH_VSAT_COVERAGE_DATA_REQUEST:
      return {
        ...state,
        vsatCoverageDataLoading: true
      }
    case FETCH_VSAT_COVERAGE_INNER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        vsatCoverageInnerLayerData: action?.item?.getVsatLocations?.data
      };
    case FETCH_VSAT_HISTO_SUCCESS:
      return {
        ...state,
        loading: false,
        vsatHistoDetails: action?.item
      };
    case FETCH_VSAT_TX_POWER_HISTO_SUCCESS:
      return {
        ...state,
        loading: false,
        vsatTxPowerDetails: action?.item
      };
    case GET_VSAT_ALL_HEX_METRICS:
      return {
        ...state,
        loading: false,
        vsatHexAllMetrics: action?.item?.getVsatH3Details
      };
    case GET_EDGE_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        edgeEventsData: action?.item
      }

    case SET_STARTLINK_DATA:
      return {
        ...state,
        starLinkDataLoading: false,
        starLinkData: action?.item
      }
    case SET_STARTLINK_DATA_LOADING:
      return {
        ...state,
        starLinkDataLoading: true
      }
    case GET_STARLINK_SPEED_DATA:
      return {
        ...state,
        starLinkSpeedDataLoading: false,
        starLinkSpeedData: action?.item
      }
      case GET_STARLINK_SPEED_DATA_LOADING:
        return {
          ...state,
          starLinkSpeedDataLoading: true
        }
      case DELETE_API_ERRORS:
        let errorRemoval:any[]=  cloneDeep(state.apiErrorHandling);
        const errorIndex = errorRemoval.findIndex((item) => {
          return item.key === action?.item?.errorKey
        })
      if(errorIndex > -1) {
        errorRemoval = errorRemoval.slice(errorIndex,errorIndex+1);
      } 
       return {
         ...state,
         apiErrorHandling: errorRemoval
     
       }

      
    case SET_ENV:
      return {
        ...state,
        env:action?.item
      }
    case CLEAR_MAP_ERRORS:
      return {
        ...state,
        apiErrorHandling: []
      }
    case FETCH_SELECTED_SITE_SERVICE_LINE_DATA: {
      return {
        ...state,
        siteSelectionOrServiceLineLoading: true
      }
    }
    case FETCH_SELECTED_SITE_SERVICE_LINE_DATA_SUCCESS: {
      return {
        ...state,
        siteSelectionOrServiceLineLoading: false,
        selectedSiteOrServiceLineData: action.item
      }
    }
    case GET_ALL_STARLINK_SERVICE_LINES_REQUEST: {
      return {
        ...state,
        getStarlinkServiceLinesLoading: true
      }
    }
    case GET_ALL_STARLINK_SERVICE_LINES: {
      return {
        ...state,
        starlinkServiceLines: action.item,
        getStarlinkServiceLinesLoading: false
      }
    }
    case GET_MAP_SITES_STARLINK_LOADER: {
      return {
        ...state,
        getServiceLineandSitesCountLoading: true
      }
    }
    case GET_MAP_SITES_STARLINK_COUNT: {
      return {
        ...state,
        getServiceLineandSitesCount: action.item,
        getServiceLineandSitesCountLoading: false
      }
    }
    default:
      return state;
  }
}

export default products;
