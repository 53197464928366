import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { GetAlertHistory } from "../../actions/Users/authenticateAlert";
import { Grid } from "@mui/material";
import CustomLoader from "../Loader/CustomLoader";
import CircularProgress from '@mui/material/CircularProgress';
import { Dialog } from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import close from "../../asset/image/cross.svg";
import "./AlertHistory.css";
import moment from "moment-timezone";
import { MMDDYYYYHMMSS_DATE_FORMAT } from "../../utils/constants";
import { displayToastError } from "../../server/request";

const AlertHistory = (props) => {
  const { onClose, open, GetAlertHistory, AlertIdHistory, locationId, authReducer, errorReducer } = props;
  const loadingTextOnUI = "Fetching data please wait...";
  const [loaders, setLoaders] = useState({ showLoader: false, loadingText: loadingTextOnUI });
  const [alertName, setAlertName] = useState("");
  const [vesselName, SetVesselName] = useState("");
  const [severity, SetSeverity] = useState("");
  const [description, SetDescription] = useState("");
  // const [source, SetSource] = useState("");
  const [dataTable, setDataTable] = React.useState<any[]>([]);

  const handleClose = () => {
    onClose();
  };

  const _getAlertHistory = (historyList: any[]) => {
    let rows: any[] = [];

    if (Array.isArray(historyList)) {
      for (let i = 0; i < historyList.length; i++) {
        let u_opendate =
          historyList[i].hasOwnProperty("opendate") &&
            historyList[i].opendate != null &&
            historyList[i].opendate !== ""
            ? historyList[i].opendate
            : "-";
        let u_closedate =
          historyList[i].hasOwnProperty("closedate") &&
            historyList[i].closedate != null &&
            historyList[i].closedate !== ""
            ? historyList[i].closedate
            : "-";
        let u_lastupdatedon =
          historyList[i].hasOwnProperty("lastupdatedon") &&
            historyList[i].lastupdatedon != null &&
            historyList[i].lastupdatedon !== ""
            ? historyList[i].lastupdatedon
            : "-";
        let u_status =
          historyList[i].hasOwnProperty("status") &&
            historyList[i].status != null &&
            historyList[i].status !== ""
            ? historyList[i].status
            : "-";
        rows.push(createData(u_opendate, u_closedate, u_lastupdatedon, u_status));
      }
    }
    return rows;
  };

  function createData(opendate: any, closedate: any, lastupdated: any, status: any) {
    return { opendate, closedate, lastupdated, status };
  }

  useEffect(() => {
    if (!_.isEmpty(AlertIdHistory)) {
      GetAlertHistory(AlertIdHistory, locationId);
    }
  }, [AlertIdHistory, locationId]);

  useEffect(() => {
    if (!_.isEmpty(errorReducer.errorAlertsHistory)) {
      displayToastError(errorReducer.errorAlertsHistory.description)
      errorReducer.errorAlertsHistory = {};
    }
  }, [errorReducer.errorAlertsHistory]);

  useEffect(() => {
    setLoaders({ showLoader: true, loadingText: "Loading alerts history, please wait ..." });
    if (!_.isEmpty(authReducer.alertHistory) && authReducer?.alertHistory?.data?.getAlertHistory) {
      const data = authReducer?.alertHistory?.data?.getAlertHistory?.alerts;
      if (data && Array.isArray(data) && data.length > 0) {
        let firstalert = data[0];
        setAlertName(firstalert.alert ? firstalert.alert : "-");
        SetVesselName(firstalert.vessel ? firstalert.vessel : "-");
        SetSeverity(firstalert.severity ? firstalert.severity : "-");
        // SetSource(firstalert.source ? firstalert.source : "-");
        SetDescription(firstalert.description ? firstalert.description : "-")
        setDataTable(_getAlertHistory(data));
      }
      authReducer.alertHistory = {};
    }
  }, [authReducer.alertHistory]);





  return (
    <div className="Form">
      <Dialog fullWidth onClose={handleClose} open={open} style={{ width: "90%", minWidth: "100%" }}>
        <DialogTitle
          style={{ fontWeight: 500, fontSize: "20px", background: "#264C86" }}
        >
          <Grid item container style={{ color: "#fff", fontSize: "16px" }}>
            Alert History
            <Grid style={{ marginLeft: "auto" }}>
              <img src={close} title="close" onClick={handleClose} style={{ width: "16px", height: "16px", cursor: "pointer" }} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ maxHeight: "400px", paddingLeft: "20px", paddingRight: "20px" }}>
          <Grid item container>
            <TableContainer className="FleetDashboard" style={{ marginTop: "0px !important" }}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow className="fleetRowRank" style={{ background: '#ffffff' }}>
                    <TableCell className="ComponentHeader alert_history_header">Vessel</TableCell>
                    <TableCell className="fleetRowDataDetails alert_history_value">: {vesselName}</TableCell>
                  </TableRow>
                  <TableRow className="fleetRowRank" style={{ background: '#ffffff' }}>
                    <TableCell className="ComponentHeader alert_history_header">Alert Name</TableCell>
                    <TableCell className="fleetRowDataDetails alert_history_value">: {alertName}</TableCell>
                  </TableRow>
                  <TableRow className="fleetRowRank" style={{ background: '#ffffff' }}>
                    <TableCell className="ComponentHeader alert_history_header">Severity</TableCell>
                    <TableCell className="fleetRowDataDetails alert_history_value">: {severity}</TableCell>
                  </TableRow>
                  <TableRow className="fleetRowRank" style={{ background: '#ffffff' }}>
                    <TableCell className="ComponentHeader alert_history_header">Details</TableCell>
                    <TableCell className="fleetRowDataDetails alert_history_value">: {description}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {
            authReducer.loading ? (
              <CircularProgress sx={{ width: "28px !important", height: "28px !important", color: "#264C86",margin:"10px 350px" }} />

            ) :
              <TableContainer className="FleetDashboard" style={{ marginTop: "0px !important" }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="fleetDataTable" style={{ color: '#ffffff' }}>Created On</TableCell>
                      <TableCell className="fleetDataTable" style={{ color: '#ffffff' }}>Closed On</TableCell>
                      <TableCell className="fleetDataTable" style={{ color: '#ffffff' }}>Last Updated</TableCell>
                      <TableCell className="fleetDataTable" style={{ color: '#ffffff' }}>Status</TableCell>
                    </TableRow>
                  </TableHead>


                  <TableBody>
                    {dataTable.map((row, idx) => (
                      <TableRow
                        style={idx % 2 ? { background: "linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%)" } : { background: "white" }}
                        className="fleetRowRank"
                        key={idx}
                      >
                        <TableCell className="fleetRowDataDetails">
                          {row.opendate === "-" ? "-" : moment(row.opendate).format(MMDDYYYYHMMSS_DATE_FORMAT)}
                        </TableCell>
                        <TableCell className="fleetRowDataDetails">
                          {row.closedate === "-" ? "-" : moment(row.closedate).format(MMDDYYYYHMMSS_DATE_FORMAT)}
                        </TableCell>
                        <TableCell className="fleetRowDataDetails">
                          {row.lastupdated === "-" ? "-" : moment(row.lastupdated).format(MMDDYYYYHMMSS_DATE_FORMAT)}
                        </TableCell>
                        <TableCell className="fleetRowDataDetails"> {row.status} </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>

                </Table>
              </TableContainer>
          }
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    GetAlertHistory,
  })(AlertHistory)
);
