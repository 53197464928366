import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core'
import { Grid, Button, Table, Collapse } from "@mui/material";
import "../../../components/ConfirmDialog/ConfirmDialog.css";
// import GenericAccordionComp from '../TableSummary/genericAccordionComp';
import close from "./../../../asset/image/cross.svg";
import ThreatSiteList from './ThreatSiteList';
import ThreatDeviceList from './ThreatDeviceList';

export default function ThreatPopUpList(props) {
  const { confirmDialog, setConfirmDialog, row, interval, handleClosePopUp } = props;

  const handleClose = () => {

    handleClosePopUp()
  }

  return (
    <div className='status-pop'>
      <Dialog open={confirmDialog.isOpen} className="dashboard_popop_dialog_sitelist interface-profile-popup" onClose={() => handleClose()}>
        <DialogTitle className='pop-head'>
          <Grid item container style={{ color: "black", fontSize: "14px" }}>
            {confirmDialog?.title}
            <Grid style={{ marginLeft: "auto" }}>
              <img src={close} onClick={handleClose} style={{ width: "24px", height: "24px", cursor: "pointer", border: "none", color: "black" }} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ padding: "8px, 16px" }}>
          {confirmDialog?.subTitle === 'DEVICE LIST' ? <ThreatDeviceList /> : <ThreatSiteList />}
          {/* <GenericAccordionComp k4Id={row?.k4Id} interval={interval} data={row} /> */}
        </DialogContent>
        {/* <DialogActions style={{ marginLeft: "18px", marginRight: "20px", marginBottom: "5px"}}>
                <Button className="CancelRoleEditButton" onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}> No </Button>
                <Button className="role-button" onClick={() => handleConfirmClick()}> Yes </Button>
            </DialogActions> */}
      </Dialog>
    </div>
  )
}