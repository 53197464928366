import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {  getDecodeURI, getEncodedURI, readableBytes } from "../../../utils/util";
import { getAllThreatDeviceList, getAllThreatSiteList, getTopThreatTableData } from "../../../actions/Users/authenticateThreatDashboard";
import _ from "lodash";
import "./../ThreatDashboard.css"

const PopUpSiteClickList = (props) => {
    const { location, authReducer, allThreatSiteList, allThreatSiteListLoading } = props;

    const _q = getDecodeURI(location?.search);
    const [tableData, setTableData] = useState<any>([]);

    useEffect(() => {
        let tableData =allThreatSiteList?.data?.rows.map((item) => {
          return authReducer?.allThreatSiteList?.data?.columns.reduce((vsd, columnitem, i) => {
            vsd[columnitem] = item[i]
            return vsd;
          }, {})
        })
        setTableData(tableData)
      },[allThreatSiteList])

    return (
        <div className="config-container">
            <Grid container spacing={0.5} className="reports-login-history trends-usage-table-container">

                <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner">

                    <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                        <Table aria-label="simple sticky table" stickyHeader>
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="UsageReports-tableRow login-tableRow">
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" >Site Name</span>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" >Count</span>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {tableData && 0 != tableData.length && !allThreatSiteListLoading ? (
                                <TableBody className="tableBody usage-tableBody Table-Body-Opacity">
                                    {
                                        tableData && tableData.map((item, i) =>
                                            <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr Table-Row-Opacity">
                                                {/* <TableCell className="login-left-align inter-font selectedClick Table-Cell-Opacity" onClick={() => handleSiteClick({k4Id: item?.k4Id, x: item?.name})}>{item?.name}</TableCell> */}
                                                <Tooltip title={<span style={{ fontSize: "12px" }}>{item?.sitename ? item?.sitename : 'no data'}</span>}>
                                                    <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat">{item?.sitename ? item?.sitename : '-'}</TableCell>
                                                </Tooltip>
                                                <Tooltip title={<span style={{ fontSize: "12px" }}>{item?.total_hits ? item?.total_hits : 'no data'}</span>}>
                                                    <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat">{item?.total_hits ? item?.total_hits : '-'}</TableCell>
                                                </Tooltip>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    newSummaryUptimeTable: state.authReducer?.newSummaryUptimeTable,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    allThreatSiteList: state?.authReducer?.allThreatSiteList,
    allThreatSiteListLoading: state?.authReducer?.allThreatSiteListLoading,
    allThreatDeviceList: state?.authReducer?.allThreatDeviceList,
    allTheratDeviceListLoading: state?.authReducer?.allTheratDeviceListLoading,
});

export default withRouter(
    connect(mapStateToProps, { getTopThreatTableData, getAllThreatSiteList, getAllThreatDeviceList })(PopUpSiteClickList)
)