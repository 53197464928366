import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getSystemHealthDevices, getSystemHealthCpuChart, getSystemHealthMemoryChart, getSystemHealthContrackChart, getSystemHealthRxDropsChart, getSystemHealthSwapChart, getSystemHealthTemperatureChart, getSystemHealthDiskStorageChart } from '../../actions/Users/authenticateDashboard';
import { Autocomplete, TextField, Grid, FormControl, Select, MenuItem, FormControlLabel, IconButton } from "@mui/material";
import { getDecodeURI, getEncodedURI, getMinutes, getTimezoneCity } from "../../utils/util";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import _ from "lodash";
import './systemHealth.css';
import moment from "moment";
import CustomLoader from "../Loader/CustomLoader";

const SystemHealth = (props) => {
    const { timeZone, siteId, startDate, endDate, getSystemHealthDevices, systemHealthDevices, getSystemHealthCpuChart, systemHealthCpuChart, getSystemHealthMemoryChart, systemHealthMemoryChart, systemHealthContrackChart, getSystemHealthContrackChart, getSystemHealthRxDropsChart,
        systemHealthRxDropsChart, getSystemHealthSwapChart, getSystemHealthTemperatureChart, systemHealthSwapChart, systemHealthTemperatureChart, getSystemHealthDiskStorageChart, systemHealthDiskStorageChart, location, history,
        setSystemHealthDevicesLoading, setSystemHealthCpuChartLoading, setSystemHealthMemoryChartLoading, setSystemHealthContrackChartLoading, setSystemHealthRxDropsChartLoading, setSystemHealthSwapChartLoading, setSystemHealthTemperatureChartLoading, setSystemHealthDiskStorageChartLoading
    } = props;

    const [devices, setDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState<any>({});
    const [cpuData, setCpuData] = useState<any>([]);
    const [memoryData, setMemorData] = useState<any>([]);
    const [contrackData, setContrackData] = useState<any>([]);
    const [rxDropsData, setRxDropsData] = useState<any>([]);
    const [swapData, setSwapData] = useState<any>([]);
    const [temperatureData, setTemperatureData] = useState<any>([]);
    const [diskStorageData, setDiskStorageData] = useState<any>([]);
    const getInterval = (minutes) => {
        if (minutes <= 1440) {
            return "5 MINUTE"
        } else if (minutes > 1440 && minutes <= 10080) {
            return "20 MINUTE"
        } else {
            return "12 HOUR";
        }
    }
    const [selectedBin, setSelectedBin] = useState(getInterval(Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3)));

    const handleEmptyRes = () => {
        setSelectedDevice({});
        setCpuData([]);
        setMemorData([]);
        setContrackData([]);
        setRxDropsData([]);
        setSwapData([]);
        setTemperatureData([]);
        setDiskStorageData([]);
    }

    useEffect(() => {
        getSystemHealthDevices(siteId);
    }, [siteId])

    useEffect(() => {
        const _interval = getInterval(Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3));
        setSelectedBin(_interval)
        if (!_.isEmpty(selectedDevice) && siteId) {
            getSystemHealthData(siteId, startDate, endDate, _interval, selectedDevice['id'])
        }
    }, [startDate, endDate])

    const getSystemHealthData = (k4Id, start, end, binSelected, deviceId) => {
        const intervalNum = binSelected.split(' ')[0];
        const interval = binSelected.split(' ')[1];
        getSystemHealthCpuChart(k4Id, start, end, intervalNum, interval, deviceId);
        getSystemHealthMemoryChart(k4Id, start, end, intervalNum, interval, deviceId);
        getSystemHealthContrackChart(k4Id, start, end, intervalNum, interval, deviceId);
        getSystemHealthRxDropsChart(k4Id, start, end, intervalNum, interval, deviceId);
        getSystemHealthSwapChart(k4Id, start, end, intervalNum, interval, deviceId);
        getSystemHealthTemperatureChart(k4Id, start, end, intervalNum, interval, deviceId);
        getSystemHealthDiskStorageChart(k4Id, start, end, intervalNum, interval, deviceId);
    }

    useEffect(() => {
        if (!_.isEmpty(systemHealthDevices)) {
            const devices = systemHealthDevices.hasOwnProperty('data') ? systemHealthDevices.data : {};
            const deviceList = devices?.rows?.map((item) => {
                return devices?.columns?.reduce((acc, columItem, i) => {
                    acc[columItem] = item[i];
                    return acc;
                }, {})
            })
            const deviceArr = deviceList?.map((dv) => {
                return { label: dv['deviceName'], id: dv['device_id'] }
            })
            if (!_.isEmpty(deviceArr[0]) && siteId) {
                getSystemHealthData(siteId, startDate, endDate, selectedBin, deviceArr[0]['id'])
            }
            setSelectedDevice(deviceArr[0])
            setDevices(deviceArr);
        }
    }, [systemHealthDevices])

    useEffect(() => {
        if (!_.isEmpty(systemHealthCpuChart)) {
            const data = systemHealthCpuChart.hasOwnProperty('data') ? systemHealthCpuChart.data : {}
            if (data) {
                const chartData: any = data?.value?.series && Object.keys(data?.value?.series)?.map((key, i) => {
                    return { name: 'CPU', data: data?.value?.series['x'], marker: { symbol: 'circle' } }
                })
                setCpuData(chartData)
            }
        }
        if (!_.isEmpty(systemHealthMemoryChart)) {
            const data = systemHealthMemoryChart.hasOwnProperty('data') ? systemHealthMemoryChart.data : {};
            if (data) {
                const chartData: any = data?.value?.series && Object.keys(data?.value?.series)?.map((key, i) => {
                    return { name: 'Memory', data: data?.value?.series['x'], marker: { symbol: 'circle' } }
                })
                setMemorData(chartData)
            }
        }
        if (!_.isEmpty(systemHealthContrackChart)) {
            const data = systemHealthContrackChart.hasOwnProperty('data') ? systemHealthContrackChart.data : {};
            if (data) {
                const chartData: any = data?.value?.series && Object.keys(data?.value?.series)?.map((key, i) => {
                    return { name: 'Contrack', data: data?.value?.series['x'], marker: { symbol: 'circle' } }
                })
                setContrackData(chartData)
            }
        }
        if (!_.isEmpty(systemHealthRxDropsChart)) {
            const data = systemHealthRxDropsChart.hasOwnProperty('data') ? systemHealthRxDropsChart.data : {};
            if (data) {
                const chartData: any = data?.value?.series && Object.keys(data?.value?.series)?.map((key, i) => {
                    return { name: 'Io Rx Drops', data: data?.value?.series['x'], marker: { symbol: 'circle' } }
                })
                setRxDropsData(chartData)
            }
        }
        if (!_.isEmpty(systemHealthSwapChart)) {
            const data = systemHealthSwapChart.hasOwnProperty('data') ? systemHealthSwapChart.data : {};
            if (data) {
                const chartData: any = data?.value?.series && Object.keys(data?.value?.series)?.map((key, i) => {
                    return { name: 'Swap', data: data?.value?.series['x'], marker: { symbol: 'circle' } }
                })
                setSwapData(chartData)
            }
        }
        if (!_.isEmpty(systemHealthTemperatureChart)) {
            const data = systemHealthTemperatureChart.hasOwnProperty('data') ? systemHealthTemperatureChart.data : {};
            if (data) {
                const chartData: any = data?.value?.series && Object.keys(data?.value?.series)?.map((key, i) => {
                    return { name: 'Temperature', data: data?.value?.series['x'], marker: { symbol: 'circle' } }
                })
                setTemperatureData(chartData)
            }
        }
        if (!_.isEmpty(systemHealthDiskStorageChart)) {
            const data = systemHealthDiskStorageChart.hasOwnProperty('data') ? systemHealthDiskStorageChart.data : {};
            if (data) {
                let chartData: any = [];
                data && Object.keys(data)?.forEach((key, i) => {
                    Object.keys(data[key].series).forEach((device) => {
                        chartData.push({ name: key.replace(/_/g, "/"), data: data[key].series[device], marker: { symbol: 'circle' } })
                    })
                })
                setDiskStorageData(chartData)
            }
        }
    }, [systemHealthCpuChart, systemHealthMemoryChart, systemHealthContrackChart, systemHealthRxDropsChart, systemHealthSwapChart, systemHealthTemperatureChart, systemHealthDiskStorageChart])

    const handleChangeDevice = (e, newValue) => {
        if (newValue) {
            setSelectedDevice(newValue);
            getSystemHealthData(siteId, startDate, endDate, selectedBin, newValue['id'])
        } else {
            handleEmptyRes();
        }
    }

    const chartAreaSelection = () => {
        return (event: any) => {
            let start = new Date(Math.ceil(event.xAxis[0].min));
            let end = new Date(Math.floor(event.xAxis[0].max));
            let params = getDecodeURI(location?.search);
            params.startDate = start.valueOf();
            params.endDate = end.valueOf();
            params.interval = 'customDates';
            doNavigate(params);
            return false;
        }
    }

    const defaultChartOptions: any = {
        time: {
            timezone: getTimezoneCity(timeZone)
        },
        chart: {
            type: 'spline',
            height: '300px',
            zoomType: 'x',
            events: {
                selection: chartAreaSelection()
            },
            plotBorderColor: '#e8eaeb',
            plotBorderWidth: 1,
            resetZoomButton: {
                theme: {
                    style: {
                        display: 'none'
                    }
                }
            },
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
        },
        colors: ["#3C7DD9", "#C1D5F6", "#2039B5"],
        legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            y: 12,
            margin: 28,
            itemStyle: {
                color: '#3F3F3F'
            }
        },
        title: {
            text: '',
            align: 'left',
            floating: true,
            x: 30,
            y: 32,
            style: {
                fontWeight: '500'
            }
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                turboThreshold: 1000000,
                stickyTracking: false,
                connectNulls: false,
            }
        },
        tooltip: {
            valueSuffix: ` %`,
            pointFormat: `<span style="color:{series.color}">{series.name}</span> - <span>${selectedDevice?.label}</span> : <b>{point.y}</b><br/>`,
            valueDecimals: 0,
            borderWidth: 1
        },
        xAxis: {
            gridLineWidth: 0.5,
            reversed: false,
            type: "datetime",
            maxPadding: 0.05,
            showLastLabel: true,
            labels: {
                format: ''
            }
        },
        yAxis: {
            tickInterval: 5,
            gridLineWidth: 1,
            title: {
                text: 'Percentage (%)'
            },
            min: 0,
            max: 100
        },
        series: {
            type: 'spline',
            marker: {
                symbol: 'circle',
            },
        },
    }

    const cpuOptions = {
        ...defaultChartOptions,
        title: {
            ...defaultChartOptions.title,
            text: 'CPU Utilization'
        },
        yAxis: {
            ...defaultChartOptions.yAxis,
            max: cpuData[0]?.data ? 100 : null
        },
        series: cpuData
    }

    const memoryOptions = {
        ...defaultChartOptions,
        title: {
            ...defaultChartOptions.title,
            text: 'Memory Utilization'
        },
        yAxis: {
            ...defaultChartOptions.yAxis,
            max: memoryData[0]?.data ? 100 : null
        },
        series: memoryData
    }

    const contrackOptions = {
        ...defaultChartOptions,
        title: {
            ...defaultChartOptions.title,
            text: 'Number of Sockets'
        },
        yAxis: {
            ...defaultChartOptions.yAxis,
            title: {
                text: 'Counts'
            },
            max: null
        },
        tooltip: {
            ...defaultChartOptions.tooltip,
            valueSuffix: ''
        },
        series: contrackData
    }

    const rxDropsOptions = {
        ...defaultChartOptions,
        title: {
            ...defaultChartOptions.title,
            text: 'Io Rx Drops'
        },
        yAxis: {
            ...defaultChartOptions.yAxis,
            title: {
                text: 'Counts'
            },
            max: null
        },
        tooltip: {
            ...defaultChartOptions.tooltip,
            valueSuffix: ''
        },
        series: rxDropsData
    }

    const swapOptions = {
        ...defaultChartOptions,
        title: {
            ...defaultChartOptions.title,
            text: 'Swap Utilization'
        },
        yAxis: {
            ...defaultChartOptions.yAxis,
            max: swapData[0]?.data ? 100 : null
        },
        series: swapData
    }

    const temperatureOptions = {
        ...defaultChartOptions,
        title: {
            ...defaultChartOptions.title,
            text: 'Temperature'
        },
        yAxis: {
            ...defaultChartOptions.yAxis,
            title: {
                text: 'Temperature (°C)'
            },
            max: null
        },
        tooltip: {
            ...defaultChartOptions.tooltip,
            valueSuffix: ' °C'
        },
        series: temperatureData
    }

    const diskStorageOptions = {
        ...defaultChartOptions,
        title: {
            ...defaultChartOptions.title,
            text: 'Disk Utilization'
        },
        yAxis: {
            ...defaultChartOptions.yAxis,
            max: diskStorageData[0]?.data ? 100 : null
        },
        series: diskStorageData
    }

    const handleBinChange = (e) => {
        const value = e.target.value;
        setSelectedBin(value);
        getSystemHealthData(siteId, startDate, endDate, value, selectedDevice['id'])
    }

    const handleResetZoom = () => {
        const start = moment(Date.now()).subtract(1, 'hour');
        const end = moment(Date.now());
        let params = getDecodeURI(location?.search);
        params.startDate = start.valueOf();
        params.endDate = end.valueOf();
        params.interval = '1h';
        doNavigate(params);
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const getDropdown = (value, options) => {
        return <Autocomplete
            disableClearable={value ? false : true}
            ListboxProps={{ className: 'warehouse-apply-config-props' }}
            className="audit-filter-inputs-usage system-health-devices"
            size='small'
            value={value}
            onChange={(e, newValue) => handleChangeDevice(e, newValue)}
            options={options}
            renderInput={(params) => <TextField {...params} placeholder="select device" />}
        />
    }

    return (
        <div className="system-health-conatner">
            <div className='system-health-filters-container'>
                {selectedDevice?.label ? getDropdown(selectedDevice?.label, devices) : getDropdown('', devices)}
                <div className="sh-bin-resetZoom">
                    <Grid>
                        <FormControl variant="standard">
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={selectedBin}
                                onChange={handleBinChange}
                            >
                                {getMinutes(startDate, endDate) >= 5 && <MenuItem value={'5 MINUTE'}>5m</MenuItem>}
                                {getMinutes(startDate, endDate) >= 10 && <MenuItem value={'10 MINUTE'}>10m</MenuItem>}
                                {getMinutes(startDate, endDate) >= 20 && <MenuItem value={'20 MINUTE'}>20m</MenuItem>}
                                {getMinutes(startDate, endDate) >= 60 && <MenuItem value={'1 HOUR'}>1h</MenuItem>}
                                {getMinutes(startDate, endDate) >= 360 && <MenuItem value={'6 HOUR'}>6h</MenuItem>}
                                {getMinutes(startDate, endDate) >= 720 && <MenuItem value={'12 HOUR'}>12h</MenuItem>}
                                {getMinutes(startDate, endDate) >= 1440 && <MenuItem value={'1 DAY'}>1d</MenuItem>}
                                {getMinutes(startDate, endDate) >= 10080 && <MenuItem value={'7 DAY'}>7d</MenuItem>}
                            </Select>
                        </FormControl>
                    </Grid>
                    <div>
                        <FormControlLabel
                            value="Reset Zoom"
                            control={<IconButton onClick={handleResetZoom}><RestartAltIcon /></IconButton>}
                            label="Reset Zoom"
                            labelPlacement="start"
                        />
                    </div>
                </div>
            </div>
            <Grid container className='system-health-charts-container' >
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={cpuOptions}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={memoryOptions}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={diskStorageOptions}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={contrackOptions}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={rxDropsOptions}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={swapOptions}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={temperatureOptions}
                    />
                </Grid>
            </Grid>
            {
                setSystemHealthDevicesLoading || setSystemHealthCpuChartLoading || setSystemHealthMemoryChartLoading || setSystemHealthContrackChartLoading ||
                    setSystemHealthRxDropsChartLoading || setSystemHealthSwapChartLoading || setSystemHealthTemperatureChartLoading || setSystemHealthDiskStorageChartLoading ?
                    <CustomLoader
                        showLoader={true}
                        loadingText={"Fetching data please wait..."}
                    />
                    : null
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    timeZone: state.authReducer?.userTimezone,
    startDate: state.authReducer.newSummaryStartDate,
    endDate: state.authReducer.newSummaryEndDate,
    systemHealthDevices: state.authReducer.systemHealthDevices,
    systemHealthCpuChart: state.authReducer.systemHealthCpuChart,
    systemHealthMemoryChart: state.authReducer.systemHealthMemoryChart,
    systemHealthContrackChart: state.authReducer.systemHealthContrackChart,
    systemHealthRxDropsChart: state.authReducer.systemHealthRxDropsChart,
    systemHealthSwapChart: state.authReducer.systemHealthSwapChart,
    systemHealthTemperatureChart: state.authReducer.systemHealthTemperatureChart,
    systemHealthDiskStorageChart: state.authReducer.systemHealthDiskStorageChart,
    setSystemHealthDevicesLoading: state.authReducer.setSystemHealthDevicesLoading,
    setSystemHealthCpuChartLoading: state.authReducer.setSystemHealthCpuChartLoading,
    setSystemHealthMemoryChartLoading: state.authReducer.setSystemHealthMemoryChartLoading,
    setSystemHealthContrackChartLoading: state.authReducer.setSystemHealthContrackChartLoading,
    setSystemHealthRxDropsChartLoading: state.authReducer.setSystemHealthRxDropsChartLoading,
    setSystemHealthSwapChartLoading: state.authReducer.setSystemHealthSwapChartLoading,
    setSystemHealthTemperatureChartLoading: state.authReducer.setSystemHealthTemperatureChartLoading,
    setSystemHealthDiskStorageChartLoading: state.authReducer.setSystemHealthDiskStorageChartLoading,
});

export default withRouter(
    connect(mapStateToProps, {
        getSystemHealthDevices,
        getSystemHealthCpuChart,
        getSystemHealthMemoryChart,
        getSystemHealthContrackChart,
        getSystemHealthRxDropsChart,
        getSystemHealthSwapChart,
        getSystemHealthTemperatureChart,
        getSystemHealthDiskStorageChart
    })(SystemHealth)
);