// Card Details for the summary page
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Grid, LinearProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import Line from '../../asset/image/DividerLine.svg';
import Box from '@mui/material/Box';
import "./CardDetails.css";
import { connect, useDispatch } from 'react-redux';
import { vesselLinkStatusSummaryDashboard, vesselSummaryDashboard,getTopWidgetAvailability} from '../../actions/Users/authenticateDashboard'
import { withRouter } from "react-router-dom";
import { readableBytes, getDecodeURI, readableBytesAsGB } from '../../utils/util';
import { displayToastError } from "../../server/request";
import _ from "lodash";
import Tooltip from '@mui/material/Tooltip';
import { GET_WIDGEY_SUMMARY_DASHBOARD, GET_WIDGET_LINK_STATUS_SUMMARY_DASHBOARD, GET_TOP_WIDGET_AVAILABILITY } from "../../actions/types";
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  colorPrimary: {
    backgroundColor:'#D6DAFA',
  },
  barColorPrimary: {
    backgroundColor:  '#2F4B82',
  }
});

const CardHeader = (title, tiltleColor) => {
  return (
    <Typography className="cardTitle" style={{ color: `${tiltleColor}` }}>{title}</Typography>

  );
}


const CardContents = (cardHeading, headingColor, leftContentSub, leftData, rightContentSub, rightData, online, offline, inactive, availability,selectedOu) => {

  const history = useHistory();
  const classes = useStyles();
  const getAvilWindow = (carHead) => {
    switch (carHead) {
      case "CELLULAR USAGE": return `/reports/wanUsage?type=CELLULAR&provider=All&ouId=${selectedOu?.id}&ouName=${selectedOu?.name}`;
      case "STARLINK USAGE": return `/reports/wanUsage?type=STARLINK&ouId=${selectedOu?.id}&ouName=${selectedOu?.name}`;
      case "VSAT USAGE": return `/reports/wanUsage?type=VSAT&ouId=${selectedOu?.id}&ouName=${selectedOu?.name}`;
      case "LBAND USAGE": return `/reports/wanUsage?type=LBAND&ouId=${selectedOu?.id}&ouName=${selectedOu?.name}`;
      default: return "";
    }
  }


  const handleCardClick = (carHead) => {
    let redirectUrl = getAvilWindow(carHead)
    if(redirectUrl) {
       history.push(redirectUrl);
    }
  }
  
  let inactiveTitle =  offline + online > 0 ? `Total ${offline + online}` : `Total 0`;
  let inactiveCount = inactive > 0 ? `Inactive ${inactive}` : ``;
  let availabilityTitle = "Availability";
  return (
    <Grid item xs={12} sm={6} md={2} lg={2} className="card-container" marginTop={2}>
      <Card className="card-widget" id={'removeBoxShadow'} >
        <Box className="cardHeader">
          <Typography className="cardTitle">{cardHeading}</Typography>
          {/* <Tooltip className="topWidgetInactiveCursor" title={availabilityTitle}>
            <Typography className="Availability">{availability > 0 ? `${availability.toFixed(0)}%` : '0%'}</Typography>
           </Tooltip> */}
        </Box>
        <Box className="cardContents">
            <Box className="cardActive">

              {/* <Typography className="contentSubTitle">{leftContentSub}</Typography> */}
              <div title={inactiveTitle} className="content">{(online + offline) > 0 ? (online + offline) : '-' }</div>
              <div className="topWidgetInactiveCursor cursorAuto" >
              {(online + offline) > 0 ? <div>Active</div> : <div></div>}
              </div>
            </Box>
            {/* <img className="divider" src={Line} alt="Divider" /> */}
            {/* <Box className="cardRightContents">
            <Typography className="contentSubTitle">{rightContentSub}</Typography>
            <Typography className="content">{}</Typography>
          </Box> */}
        </Box>
        <Box sx={{ margin: '0.25rem', marginLeft: '0.5rem', marginRight: '0.5rem' }}>
          {
            !_.isNaN(online) && !_.isNaN(offline) ? <>
              <LinearProgress  sx={{ "--LinearProgress-radius": "10px"}} classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}} variant="determinate" value={online / (online + offline) * 100} />

              <div className="advance-dashboard-card-css">
                {<div>{cardHeading !== "INTERNET LINKS"  && "Usage " }<span style={{ fontWeight: '600' }}>{cardHeading !== "INTERNET LINKS"  ? readableBytesAsGB(leftData + rightData) : ""}</span></div>}
                {/* <Tooltip className="topWidgetInactiveCursor" title={inactiveCount}> */}
                <div >Online <span style={{ fontWeight: '600' }}>{online}</span></div>
                {/* </Tooltip> */}

              </div>
            </> : null
          }
        </Box>
      </Card>
    </Grid>
  );
}

const CardDetails = (props) => {
  const { vesselSummaryDashboard, vesselLinkStatusSummaryDashboard, search, authReducer, interval, errorReducer, vesselList, selectedOu, getTopWidgetAvailability, newSummaryStartDate, newSummaryEndDate } = props;
  const [cardetails, setCardDetails] = useState({})
  const [cardetailsLinkStatus, setCardDetailsLinkStatus] = useState({})
  const [topWidgetAvailability, setTopWidgetAvailability] = useState({})
  const dispatch = useDispatch()
  const location = useLocation();
  const [selectedVesselsList, setSelectedVesselsList] = useState([]);

  useEffect(() => {
    let tableData = authReducer?.widgetDashboardSummary?.data?.rows.map((item) => {
      return authReducer?.widgetDashboardSummary?.data?.columns.reduce((vsd, columnitem, i) => {
        vsd[columnitem] = item[i]
        return vsd;
      }, {})
    })
    if (tableData && tableData.length > 0) {
      setCardDetails(tableData[0])
    } else {
      setCardDetails({})
    }
  }, [authReducer.widgetDashboardSummary])

  useEffect(() => {
    let tableData = authReducer?.widgetLinkStatusDashboardSummary?.data?.rows.map((item) => {
      return authReducer?.widgetLinkStatusDashboardSummary?.data?.columns.reduce((vsd, columnitem, i) => {
        vsd[columnitem] = item[i]
        return vsd;
      }, {})
    })
    if (tableData && tableData.length > 0) {
      setCardDetailsLinkStatus(tableData[0])
    } else {
      setCardDetailsLinkStatus({})
    }
  }, [authReducer.widgetLinkStatusDashboardSummary])

  useEffect(() => {
    let tableData = authReducer?.setTopWidgetAvailability?.data?.rows.map((item) => {
      return authReducer?.setTopWidgetAvailability?.data?.columns.reduce((vsd, columnitem, i) => {
        vsd[columnitem] = item[i]
        return vsd;
      }, {})
    })
    if (tableData && tableData.length > 0) {
      setTopWidgetAvailability(tableData[0])
    } else {
      setTopWidgetAvailability({})
    }
  },[authReducer?.setTopWidgetAvailability])

  useEffect(() => {
    // create new requestAborter
    let _requestAbortController = new AbortController();
    let _q = getDecodeURI(location.search)
    let _interval = _q.interval == '15mins' ? _q.interval.replace('15mins', '15m') : _q.interval;
    if (interval && Object.keys(interval).length > 0 && !_.isEmpty(vesselList)) {
      let vessels = [];
      let params = getDecodeURI(location?.search);
      let filter= "";
      if(!_.isEmpty(params) && params.hasOwnProperty("filterApplied")){
        let statues = params?.internetStatus;
        if(statues){
          statues = statues.split(",")
          statues="'" + statues.join("','") + "'";
          filter = "AND status IN(" + statues+")"
        }
      }
      if(vesselList?.length > 0) {
        // getTopWidgetAvailability(selectedVesselsList,search,interval.value,filter, newSummaryStartDate, newSummaryEndDate);
        vesselSummaryDashboard(vesselList, search, _interval, selectedOu?.name, _requestAbortController.signal, filter, newSummaryStartDate, newSummaryEndDate);
        vesselLinkStatusSummaryDashboard(vesselList, search, _interval, selectedOu?.name, _requestAbortController.signal, filter, newSummaryStartDate, newSummaryEndDate);
      } else {
        dispatch({type: GET_WIDGEY_SUMMARY_DASHBOARD, payload: {}})
        dispatch({type: GET_WIDGET_LINK_STATUS_SUMMARY_DASHBOARD, payload: {}})
        dispatch({type: GET_TOP_WIDGET_AVAILABILITY, payload: {}})
        return;
      }
    }

    return () => {
      _requestAbortController.abort()
    }
  }, [vesselList, search, newSummaryStartDate, newSummaryEndDate]);

  useEffect(() => {
    if (!_.isEmpty(errorReducer.errorGetVesselSummaryDashboard)) {
      displayToastError(errorReducer.errorGetVesselSummaryDashboard.description)
      errorReducer.errorGetVesselSummaryDashboard = {};
    }
  }, [errorReducer.errorGetVesselSummaryDashboard]);

  const getUsage = (info) => {
    let usage = "-";
    if (null !== info && undefined !== info) {
      usage = readableBytesAsGB(info);
    }
    return usage;
  }

  const getIsValidval = (info) => {
    let value = "-";
    if (value !== info && undefined !== info) {
      value = info;
    }
    return value;
  }

  return (
    <Grid className="cards" container item>
      {CardContents("SITES", "#2E1A70", "Download", cardetails?.DOWN, "Upload", cardetails?.UP, cardetailsLinkStatus.online, (cardetailsLinkStatus.total - cardetailsLinkStatus.online) - cardetailsLinkStatus?.INC_SITE, cardetailsLinkStatus?.INC_SITE, topWidgetAvailability?.TOTAL_AVAIL,selectedOu)}
      {CardContents("INTERNET LINKS", "#114E61", "Total", getIsValidval(cardetailsLinkStatus?.T_Link), "Active", getIsValidval(cardetailsLinkStatus?.A_Link), cardetailsLinkStatus.A_Link, (cardetailsLinkStatus.T_Link - cardetailsLinkStatus.A_Link) - cardetailsLinkStatus?.T_IN,  cardetailsLinkStatus?.T_IN, topWidgetAvailability?.Link_AVAIL,selectedOu)}
      {/* {CardConte71AC", "SPEED (Mbps)", "#781D43", "LTE", toMbps(Cardetails?.speed?.lte).toFixed(1), "VSAT", toMbps(Cardetails?.speed?.vsat).toFixed(1))} */}
      {CardContents("CELLULAR USAGE", "#781D43", "Download", cardetails?.LTE_DOWN, "Upload", cardetails?.LTE_UP, cardetailsLinkStatus.A_LTE_links, (cardetailsLinkStatus.T_LTE_links - cardetailsLinkStatus.A_LTE_links) - cardetailsLinkStatus?.LTE_IN, cardetailsLinkStatus?.LTE_IN, topWidgetAvailability?.LTE,selectedOu)}
      {CardContents("STARLINK USAGE", "#114E61", "Download", cardetails?.STAR_DOWN, "Upload", cardetails?.STAR_UP, cardetailsLinkStatus.A_STAR_links, (cardetailsLinkStatus.T_STAR_links - cardetailsLinkStatus.A_STAR_links) - cardetailsLinkStatus?.STAR_IN, cardetailsLinkStatus?.STAR_IN, topWidgetAvailability?.starlink,selectedOu)}
      {CardContents("VSAT USAGE", "#781D43", "Download", cardetails?.VSAT_DOWN, "Upload", cardetails?.VSAT_UP, cardetailsLinkStatus.A_VSAT_links, (cardetailsLinkStatus.T_VSAT_links - cardetailsLinkStatus.A_VSAT_links) - cardetailsLinkStatus?.VSAT_IN, cardetailsLinkStatus?.VSAT_IN, topWidgetAvailability?.VSAT,selectedOu)}
      {CardContents("LBAND USAGE", "#114E61", "Download", cardetails?.FBB_DOWN, "Upload", cardetails?.FBB_UP, cardetailsLinkStatus.A_FBB_links, (cardetailsLinkStatus.T_FBB_links - cardetailsLinkStatus.A_FBB_links) - cardetailsLinkStatus?.FBB_IN, cardetailsLinkStatus?.FBB_IN, topWidgetAvailability?.FBB,selectedOu)}

      {/* <Grid style={{position: 'absolute', left: 5, top: 35, textTransform: 'uppercase', fontWeight: 500, fontSize: '9px'}}>{interval.label}</Grid> */}
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state?.authReducer,
  vesselDashboardSummary: state?.authReducer?.vesselDashboardSummary,
  setTopWidgetAvailability: state?.authReducer?.setTopWidgetAvailability,
  newSummaryStartDate: state.authReducer.newSummaryStartDate,
  newSummaryEndDate: state.authReducer.newSummaryEndDate,
  errorReducer: state?.errorReducer
});


export default withRouter(
  connect(mapStateToProps, {
    vesselSummaryDashboard,
    vesselLinkStatusSummaryDashboard,
    getTopWidgetAvailability
  })(CardDetails)
);


