import { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { useLeafletContext } from "@react-leaflet/core";
import L from "leaflet";
import './Map.css';

import "leaflet.vectorgrid";
import { SET_SELECTED_HEX } from "../../actions/actionType";


const VectorGrid = (props) => {
  const { layerContainer, map } = useLeafletContext();
  const { assets, selectedLayer, hexOpacity, isEventsEnabled, handleHexogonClick,selectedCarrier, h3Index, setHexId, selectedHexId, zindex, isVsatDataSource, resolution } = props;
  const data = assets;
  const [selectdHexLocal, setSelectedHeIdLocal] = useState(0);
 

  const getRssiQuality = (item) => {
    let rssiAvg = Math.round(item);
    if (rssiAvg <= -90) {
      return "#EA3A24";
    } else if (rssiAvg >= -89 && rssiAvg <= -80) {
      return "#FA9100";
    } else if (rssiAvg >= -79 && rssiAvg <= -70) {
      return "#FFD11D";
    } else if (rssiAvg >= -69 && rssiAvg <= -60) {
      return "#FFFFCC";
    } else if (rssiAvg >= -59 && rssiAvg <= -55) {
      return "#B9E0A8";
    } else if (rssiAvg >= -54 && rssiAvg <= -50) {
      return "#88ce7d";
    } else if (rssiAvg >= -49 && rssiAvg <= -45) {
      return "#37b345";
    } else if (rssiAvg >= -44) {
      return "#0183D7";
    }
  }

  const getSpeedColor = (rateAvg) => {
    let rateInMbps = Math.round((rateAvg / 1024) / 1024);
    if (rateInMbps <= 1) {
      return "#EA3A24";
    } else if (rateInMbps >= 2 && rateInMbps <= 5) {
      return "#FA9100";
    } else if (rateInMbps >= 6 && rateInMbps <= 10) {
      return "#FFD11D";
    } else if (rateInMbps >= 11 && rateInMbps <= 15) {
      return "#FFFFCC";
    } else if (rateInMbps >= 16 && rateInMbps <= 20) {
      return "#B9E0A8";
    } else if (rateInMbps >= 21 && rateInMbps <= 30) {
      return "#88ce7d";
    } else if (rateInMbps >= 31 && rateInMbps <= 50) {
      return "#37b345";
    } else if (rateInMbps >= 51) {
      return "#0183D7";
    }
  }

  const getVsatSpeedColor = (percentage) => {
    if (percentage <= 5) {
      return "#EA3A24";
    } else if (percentage >= 5 && percentage <= 15) {
      return "#FA9100";
    } else if (percentage >= 16 && percentage <= 30) {
      return "#FFD11D";
    } else if (percentage >= 31 && percentage <= 45) {
      return "#FFFFCC";
    } else if (percentage >= 46 && percentage <= 60) {
      return "#B9E0A8";
    } else if (percentage >= 61 && percentage <= 75) {
      return "#88ce7d";
    } else if (percentage >= 76 && percentage <= 90) {
      return "#37b345";
    } else if (percentage >= 91) {
      return "#0183D7";
    }
  }

  const getSinrAvg = (rateAvg) => {
    let rateInMbps = Math.round(rateAvg);
    if (rateInMbps <= 1) {
      return "#EA3A24";
    } else if (rateInMbps >= -10 && rateInMbps <= -5) {
      return "#FA9100";
    } else if (rateInMbps >= -6 && rateInMbps <= 0) {
      return "#FFD11D";
    } else if (rateInMbps >= 1 && rateInMbps <= 5) {
      return "#FFFFCC";
    } else if (rateInMbps >= 6 && rateInMbps <= 10) {
      return "#B9E0A8";
    } else if (rateInMbps >= 11 && rateInMbps <= 15) {
      return "#88ce7d";
    } else if (rateInMbps >= 16 && rateInMbps <= 20) {
      return "#37b345";
    } else if (rateInMbps >= 21) {
      return "#0183D7";
    }
  }

  const vectorGrid = useMemo(() => {

    return L.vectorGrid.slicer(data, {

      // rendererFactory: L.canvas.tile,
      // idField: 'vectorLayer',
      zIndex: zindex,
      vectorTileLayerStyles: {
        data: function (e) {
          if (selectedLayer === "Call Performance") {
            let rssiColor = "#000000"
            let RSSI = !isVsatDataSource? e?.rssi_avg: e?.snr_avg;
            rssiColor = !isVsatDataSource ? getRssiQuality(RSSI): getSinrAvg(RSSI);
            let style = { fill: true, fillColor: rssiColor, fillOpacity: (hexOpacity * 0.1), color: 'white', weight: 0.3 };
            return style;
  
          } else {
            let rssiColor = "#000000"
            let RSSI = !isVsatDataSource? e?.rate_avg: e?.performance_avg;
            rssiColor = isVsatDataSource? getVsatSpeedColor(RSSI):getSpeedColor(RSSI) ;
            let style = { fill: true, fillColor: rssiColor, fillOpacity: (hexOpacity * 0.1), color: 'white', weight: 0.3 };
            return style;
  
          }
  
        },
  
      },
      getFeatureId: function (e) {
        return e?.properties?.id
      },
  
  
      interactive: true,
    });

  }, [data, selectedCarrier,hexOpacity,selectedLayer]);


  vectorGrid.on("mouseover", function (e) {
    if (selectedLayer === 'Call Performance') {
      let rssiColor = "#000000"
      let RSSI = !isVsatDataSource? e?.layer?.properties?.rssi_avg: e?.layer?.properties?.snr_avg;
      rssiColor = !isVsatDataSource ? getRssiQuality(RSSI): getSinrAvg(RSSI);
      isEventsEnabled && vectorGrid.setFeatureStyle(e?.layer?.properties?.id, { fill: true, fillColor: rssiColor, fillOpacity: 0.3, color: "white", weight: 3 });
    } else {
      let rssiColor = "#000000"
      let rateAvg = isVsatDataSource? e?.layer?.properties?.rate_avg: e?.layer?.properties?.performance_avg;
      rssiColor = !isVsatDataSource ? getSpeedColor(rateAvg): getVsatSpeedColor(rateAvg);
      isEventsEnabled && vectorGrid.setFeatureStyle(e?.layer?.properties?.id, { fill: true, fillColor: rssiColor, fillOpacity: 0.3, color: "white", weight: 3 });
    }
  });

  const resetSelectedHex = (hexId) => {
    vectorGrid.resetFeatureStyle(hexId);
  }

  vectorGrid.on("click", function (e) {
    isEventsEnabled && h3Index(e?.layer?.properties?.h3index);
    resetSelectedHex(selectdHexLocal)
    handleHexogonClick(e, resolution);
    setHexId(e?.layer?.properties?.id);
    setSelectedHeIdLocal(e?.layer?.properties?.id)
    if (selectedLayer === 'Call Performance') {
      let rssiColor = "#000000"
      let RSSI = !isVsatDataSource? e?.layer?.properties?.rssi_avg: e?.layer?.properties?.snr_avg;
      rssiColor = !isVsatDataSource ? getRssiQuality(RSSI): getSinrAvg(RSSI);
      isEventsEnabled && vectorGrid.setFeatureStyle(e?.layer?.properties?.id, { fill: true, fillColor: rssiColor, fillOpacity: 0.3, color: "white", weight: 3 });
    } else {
      let rssiColor = "#000000"
      let rateAvg = isVsatDataSource? e?.layer?.properties?.rate_avg: e?.layer?.properties?.performance_avg;
      rssiColor = !isVsatDataSource ? getSpeedColor(rateAvg): getVsatSpeedColor(rateAvg);
      isEventsEnabled && vectorGrid.setFeatureStyle(e?.layer?.properties?.id, { fill: true, fillColor: rssiColor, fillOpacity: 0.3, color: "white", weight: 3 });
    }
    
  });

  vectorGrid.on("mouseout", function (e) {
   if(selectedHexId !== e?.layer?.properties?.id) {
    vectorGrid.resetFeatureStyle(e?.layer?.properties?.id);
   } else {
    if (selectedLayer === 'Call Performance') {
      let rssiColor = "#000000"
      let RSSI = !isVsatDataSource? e?.layer?.properties?.rssi_avg: e?.layer?.properties?.snr_avg;
      rssiColor = !isVsatDataSource ? getRssiQuality(RSSI): getSinrAvg(RSSI);
      isEventsEnabled && vectorGrid.setFeatureStyle(e?.layer?.properties?.id, { fill: true, fillColor: rssiColor, fillOpacity: 0.3, color: "white", weight: 3 });
    } else {
      let rssiColor = "#000000"
      let rateAvg = isVsatDataSource? e?.layer?.properties?.rate_avg: e?.layer?.properties?.performance_avg;
      rssiColor = !isVsatDataSource ? getSpeedColor(rateAvg): getVsatSpeedColor(rateAvg);
      isEventsEnabled && vectorGrid.setFeatureStyle(e?.layer?.properties?.id, { fill: true, fillColor: rssiColor, fillOpacity: 0.3, color: "white", weight: 3 });
    }
   }
  });

  if(selectedHexId === 0) {
    vectorGrid.resetFeatureStyle(selectdHexLocal);
  }

  const container = layerContainer || map;



  useEffect(() => {
    container.addLayer(vectorGrid);
    return () => {
      container.removeLayer(vectorGrid);
      
    };
  }, [data,selectedCarrier,hexOpacity,selectedLayer]);
  
  return null;
}

const mapStateToProps = (state) => ({
  selectedHexId: state?.products?.selectedHexId
});

const mapDispatchToProps = (dispatch) => {
  return {
    setHexId: (hexIndexId) => dispatch({ type: SET_SELECTED_HEX, item: hexIndexId })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VectorGrid);
