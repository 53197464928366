
export const AVAILABLE_STARLINK_METRICS: StarlinkMetric[] = [
    "downlink_throughput",
    "uplink_throughput",
    "ping_drop_rate",
    "ping_latency_ms",
    "obstruction_percent_time",
    "signal_quality"
]

export const STARLINK_METRIC_DISPLAY_NAMES = {
    "downlink_throughput": "Downlink Throughput",
    "uplink_throughput": "Uplink Throughput",
    "ping_drop_rate": "Ping Drop Rate",
    "ping_latency_ms": "Ping Latency",
    "obstruction_percent_time": "Obstruction Time",
    "signal_quality": "Signal Quality"
}

export const STARLINK_METRIC_UNITS: { [key in StarlinkMetric]: string } = {
    "downlink_throughput": "Mbps",
    "uplink_throughput": "Mbps",
    "ping_drop_rate": "%",
    "ping_latency_ms": "ms",
    "obstruction_percent_time": "%",
    "signal_quality": ""
}

export const STARLINK_METRIC_COLUMN_NAMES: { [key in StarlinkMetric]: StarlinkMetricColumn } = {
    "downlink_throughput": "downlink_throughput",
    "uplink_throughput": "uplink_throughput",
    "ping_drop_rate": "ping_drop_rate_avg",
    "ping_latency_ms": "ping_latency_ms_avg",
    "obstruction_percent_time": "obstruction_percent_time",
    "signal_quality": "signal_quality"
}

// YYYY-MM-DD HH:mm:ss
export const YYYY_MM_DD_HH_MM_SS = "YYYY-MM-DD HH:mm:ss";

export type StarlinkMetric = "downlink_throughput" | "uplink_throughput" | "ping_drop_rate" | "ping_latency_ms" | "obstruction_percent_time" | "signal_quality";

export type StarlinkMetricColumn = "downlink_throughput" | "uplink_throughput" | "ping_drop_rate_avg" | "ping_latency_ms_avg" | "obstruction_percent_time" | "signal_quality";

export interface Interval {
    startDate: string;
    endDate: string;
}