import { Fragment, useState, useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid } from "@mui/material";
import { Pagination } from "../Pagination/index";
import { JsonDataTooltip } from "../JSONTooltip/JsonTooltip";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants";
import {  connect } from "react-redux";
import { withRouter} from "react-router-dom";
import _ from "lodash";
import { getEdgeEventsTable, getEdgeEventsTableCount } from "../../actions/Users/authenticateEdgeEvents";
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI } from "../../utils/util";
import "./style.css";

const ChartTable = (props: any) => {
    const { authReducer, parameters, getEdgeEventsTable, getEdgeEventsTableCount, history, location, Page, setPage } = props;
    let _q = getDecodeURI(location?.search);
    const [edgeTable, setEdgeTable] = useState<any>([]);
    const [count, setCount] = useState(0);
    const DEFAULT_PAGE_SIZE = 10;
    const [Limit, setLimit] = useState(_q.hasOwnProperty("limit") ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    
    useEffect(() => {
        if (Object.keys(parameters).length) {
            getEdgeEventsTable({...parameters, offset: ((Page-1)*Limit), pageSize: Limit})
            getEdgeEventsTableCount({...parameters, offset: ((Page-1)*Limit), pageSize: Limit})
        }
    },[parameters])

    useEffect(() => {
      if(!_.isEmpty(authReducer.getEdgeEventsTable)) {
        const data = authReducer?.getEdgeEventsTable?.hasOwnProperty('data') ? authReducer?.getEdgeEventsTable?.data : {};
        if(data) {
            const rows: any[] = data?.rows;
            const columns: string[] = data?.columns;
            const edgeData: any[] = [];
            for (let i = 0; i < rows?.length; i++) {
                const row = {};
                const currentRow = rows[i];
                columns.forEach((column, index) => {
                    row[column] = currentRow[index];
                })
                edgeData.push(row);
            }
            setEdgeTable(edgeData);
          }
      } else {
        setEdgeTable([]);
      }
      if(!_.isEmpty(authReducer.getEdgeEventsTableCount)) {
        const data = authReducer?.getEdgeEventsTableCount.hasOwnProperty('data') ? authReducer?.getEdgeEventsTableCount?.data?.filters?.data[4] : 0;
        setCount(data);
      } else {
        setCount(0)
      }
    },[authReducer.getEdgeEventsTable, authReducer.getEdgeEventsTableCount])

    const handleChangePage = (e, value) => {
        const page = value;
        let params: any = getDecodeURI(location?.search);
        params.page = value;
        setPage(page);
        doNavigate(params);
        getEdgeEventsTable({...parameters, offset: ((page-1)*Limit), pageSize: Limit})
        getEdgeEventsTableCount({...parameters, offset: ((page-1)*Limit), pageSize: Limit})
    }
    
      const handleChangeRowsPerPage = (e) => {
        const limit = parseInt(e.target.value);
        const page = 1;
        let params: any = getDecodeURI(location?.search);
        params.page = 1;
        params.limit = limit;
        setPage(1);
        setLimit(limit);
        doNavigate(params);
        getEdgeEventsTable({...parameters, offset: ((page-1)*limit), pageSize: limit})
        getEdgeEventsTableCount({...parameters, offset: ((page-1)*limit), pageSize: limit})
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const rowsOption = [10, 15, 25, 50, 100];

    return (
        <Grid className="chartsEventTableContainer">
            <Grid>
            <Paper className="pagination-position usage-pagination">
                    <Pagination
                        rowsPerPageOptions={rowsOption}
                        count={count}
                        rowsPerPage={Limit}
                        page={Page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                <TableContainer className="usageReports-FleetDashboard chartEvent-FleetDashboard">
                    <Table aria-label="simple table">
                        <TableHead className="usageReports-tableHead">
                            <TableRow className="UsageReports-tableRow">
                                <TableCell className="usage-fleetDataTable">TIMESTAMP</TableCell>
                                <TableCell className="usage-fleetDataTable">DID</TableCell>
                                <TableCell className="usage-fleetDataTable">SOURCE</TableCell>
                                <TableCell className="usage-fleetDataTable">LEVEL</TableCell>
                                <TableCell className="usage-fleetDataTable">MODULE</TableCell>
                                <TableCell className="usage-fleetDataTable user-fleetDataTable">DESCRIPTION</TableCell>
                                <TableCell className="usage-fleetDataTable">METADATA</TableCell>
                            </TableRow>
                        </TableHead>
                        {0 != edgeTable.length ? (
                            <TableBody className="tableBody usage-tableBody">
                                {
                                    edgeTable && edgeTable.map((row, i) =>
                                        <TableRow className="usageData-tabelRow chartEvent-tableRow">
                                            <TableCell className="usagedata-tableCell chartEvent-tablecell">{convertDateTimeIntoTimezone(row['ts'], authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                            <TableCell className="usagedata-tableCell chartEvent-tablecell">{row['device_id']}</TableCell>
                                            <TableCell className="usagedata-tableCell chartEvent-tablecell chartEvent-tablecell-source">{row['source']}</TableCell>
                                            <TableCell className="usagedata-tableCell chartEvent-tablecell">{row['level']}</TableCell>
                                            <TableCell className="usagedata-tableCell chartEvent-tablecell">{row['module']}</TableCell>
                                            <TableCell className="usagedata-tableCell chartEvent-tablecell">{row['description']}</TableCell>
                                            <TableCell className="usagedata-tableCell chartEvent-tablecell">
                                                <JsonDataTooltip data={row['metadata']} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                            ) : <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                        }                                     
                    </Table>
                </TableContainer>
                
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {
        getEdgeEventsTable,
        getEdgeEventsTableCount
    })(ChartTable)
);