import React, { useState } from "react";
import {
  Grid,
  Button,
  Paper,
  Input,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import "./Form.css";
import { Dialog, DialogContentText } from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const Form = (props) => {
  const { onClose, selectedValue, open } = props;
  const [passwordValue, setPasswordValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const top100Films = [
    { label: "Elasticsearch" },
    { label: "Postgresql" },
    { label: "Kafka KSQL" },
  ];

  return (
    <div className="Form">
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle style={{ fontWeight: 500, fontSize: "20px" }}>
          New Data Source
        </DialogTitle>
        <Grid
          item
          container
          xs={12}
          style={{
            backgroundColor: "#FAF9FC",
            minHeight: "380px",
            width: "1000px",
          }}
        >
          <Grid item xs={4}></Grid>
          <Grid xs={8} style={{ marginLeft: "145px" }}>
            <DialogContentText
              size={20}
              weight={600}
              color="#000"
              style={{
                marginTop: "-20px",
                marginBottom: "10px",
                fontSize: "20px",
                fontWeight: "500",
                justifyContent: "center",
                display: "flex",
              }}
            >
              Enter the new data source
            </DialogContentText>
            <Paper className="innerDiv">
              <Grid item container xs={12}>
                <Grid>
                  <Typography
                    size={16}
                    weight={600}
                    color="#000"
                    style={{ marginLeft: "50px", marginTop: "15px" }}
                  >
                    Name
                  </Typography>
                </Grid>
                <Grid>
                  <input
                    style={{
                      width: "300px",
                      height: "30px",
                      border: "1px solid #c2c2c2",
                      marginLeft: "50px",
                      marginTop: "8px",
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid item container xs={12}>
                <Grid>
                  <Typography
                    size={16}
                    weight={600}
                    color="#000"
                    style={{ marginLeft: "50px", marginTop: "5px" }}
                  >
                    Type
                  </Typography>
                </Grid>
                <Grid>
                  {/* <input
                    style={{
                      width: "300px",
                      height: "30px",
                      border: "1px solid #c2c2c2",
                      marginLeft: "58px",
                    }}
                  /> */}
                  <Autocomplete
                    style={{
                      width: "308px",
                      // border: "1px solid #c2c2c2",
                      marginLeft: "58px",
                    }}
                    disablePortal
                    id="combo-box-demo"
                    options={top100Films}
                    // sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        style={{ paddingTop: "initial" }}
                        {...params}
                        size="small"
                        label="Type"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid item container xs={12}>
                <Grid>
                  <Typography
                    size={16}
                    weight={600}
                    color="#000"
                    style={{ marginLeft: "50px", marginTop: "5px" }}
                  >
                    HostName
                  </Typography>
                </Grid>
                <Grid>
                  <input
                    style={{
                      width: "300px",
                      height: "30px",
                      border: "1px solid #c2c2c2",
                      marginLeft: "17px",
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid item container xs={12}>
                <Grid>
                  <Typography
                    size={16}
                    weight={600}
                    color="#000"
                    style={{ marginLeft: "50px", marginTop: "2px" }}
                  >
                    Username
                  </Typography>
                </Grid>
                <Grid>
                  <input
                    style={{
                      width: "300px",
                      height: "30px",
                      border: "1px solid #c2c2c2",
                      marginLeft: "19px",
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid item container xs={12}>
                <Grid>
                  <Typography
                    size={16}
                    weight={600}
                    color="#000"
                    style={{ marginLeft: "50px", marginTop: "5px" }}
                  >
                    Password
                  </Typography>
                </Grid>
                <Grid>
                  <Input
                    inputProps={{ "aria-label": "username" }}
                    style={{
                      width: "309px",
                      height: "33px",
                      border: "1px solid #c2c2c2",
                      marginLeft: "22px",
                      marginBottom: "10px",
                    }}
                    type={showPassword ? "text" : "password"}
                    value={passwordValue}
                    onChange={(e) => setPasswordValue(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button>submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Form;
