import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import _, { cloneDeep, update } from "lodash"
import moment from "moment-timezone";
import { Grid, Paper, Switch, FormControlLabel, TextField, IconButton, Menu, MenuItem, Button, ThemeProvider, FormControl, Select } from "@mui/material";

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import "./SiteCharts.css"

import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI, getEncodedURI, getMinutes, getTimezoneCity } from "../../utils/util";

import { getSiteRSSI, getSiteSINR, getSiteDataRate, getSiteLinkStatus, getSiteLatencyJitter, getSiteThroughput, clearSiteCharts, deleteSiteChartSeries, clearSiteChartEvents, getSiteVSATSnr, getSiteVSATTxpower, getSiteVSATPower, getSiteVSATSymbolRate, getSiteInterfaces, ChartsQueryParameters, getSiteClients, getSiteUsage, getSiteSystemUptime, getSiteLanStatus, getSiteFlows, getSiteSpeed, getSiteLatency, getSiteStatus, getSiteSpeedtest } from "../../actions/Users/authenticateCharts";
import { getEdgeEventsForCharts, getEdgeEventsFilter, clearEdgeEvents } from "../../actions/Users/authenticateEdgeEvents";
import { ERROR_GET_SITE_RSSI, ERROR_GET_SITE_SINR, ERROR_GET_SITE_DATA_RATE, ERROR_GET_SITE_LINK_STATUS, ERROR_GET_SITE_LATENCY_JITTER, ERROR_GET_SITE_INTERFACES, ERROR_GET_EDGE_EVENTS} from "../../actions/error_types";

import { displayToastError } from "../../server/request";
import MultipleSelectChip from "../MultipleSelectChip";
import { EventsFilterMenu } from "./EventFilterMenu";
import Chart from "../Charts";
import { CHART_COLORS } from "../../utils/Colors";
import { CHARTS_METRICS, EVENT_FILTERS } from "../../constants/Constants";
import CustomLoader from "../Loader/CustomLoader";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";

const {PRE, RTT, SINR, RSSI, LINK_STATUS, THROUGHPUT, VSAT_SNR, VSAT_TXPOWER, VSAT_POWER, VSAT_SYMBOL_RATE, CLIENTS, USAGE, SYSTEM_UPTIME, LAN_STATUS, FLOWS, SPEED, LATENCY, STATUS, SPEEDTEST} = CHARTS_METRICS;

interface Interval {
    startDate: string;
    endDate: string;
}

interface deviceInterface {
    deviceName: string,
    device_id: string,
    displayInterface: string,
    wanType: string
}

interface locationInterfaces {
    interfaces: deviceInterface[],
    nameToDeviceInterfaceMap: Map<string, deviceInterface>
    deviceIdToNameMap: Map<string, string>
    deviceIdToNameMapForClient: Map<string, string>
    toString(): string[]
    getDeviceInterface(deviceName: string): (deviceInterface | undefined)
    getDeviceName(deviceId: string): (string | undefined)
    getDeviceNameForClient(deviceId: string): (string | undefined)
}

interface EventFilters {
    devices: string[];
    modules: string[];
    levels: string[];
    sources: string[];
    eventsCount?: number
}

const VesselCharts = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    let _q = getDecodeURI(location?.search);
    const { authReducer, errorReducer, getSiteRSSI, getSiteSINR, getSiteDataRate, getSiteLinkStatus, getSiteLatencyJitter, clearSiteCharts, selectedVessel,
         selectedVesselName, deleteSiteChartSeries, getEdgeEventsForCharts, getEdgeEventsFilter, clearSiteChartEvents, getSiteThroughput, getSiteVSATSnr, 
         getSiteVSATTxpower, getSiteVSATPower, getSiteVSATSymbolRate, handleReset, handleChartAreaSelection, getSiteInterfaces, clearEdgeEvents,
         newSummaryStartDate, newSummaryEndDate, getSiteClients, getSiteUsage, getSiteSystemUptime, getSiteLanStatus, getSiteFlows, getSiteSpeed, getSiteLatency, getSiteStatus, getSiteSpeedtest } = props;

    const [siteInterfaces, setSiteInterfaces] = useState<locationInterfaces>();
    const [eventFilters, setEventFilters] = useState<EventFilters>();
    const [selectedInterfaces, setSelectedInterfaces] = useState<string[]>([]);
    const previousSelectedInterfaces = useRef(selectedInterfaces);
    const [selectedEventFilters, setSelectedEventFilters] = useState<EventFilters>();
    const [metrics, setMetrics] = useState<any>([]);
    const [selectedMetrics, setSelectedMetrics] = useState<string[]>(_q.hasOwnProperty('device_id') ? [FLOWS, SPEED, LATENCY, STATUS] : [LINK_STATUS, THROUGHPUT]);
    const previousSelectedMetrics = useRef(selectedMetrics);
    const [interval, setChartInterval] = useState<Interval>(() => {
        let now = Date.now();
        return {
            startDate: moment(now).subtract(1, 'hour').toISOString(),
            endDate: moment(now).toISOString()
        }
    })
    const previousInterval = useRef<Interval>()
    // const [selectedStartDate, setSelectedStartDate] = useState(moment(interval.startDate).utc());
    // const [selectedEndDate, setSelectedEndDate] = useState(moment(interval.endDate).utc());
    const [analystMode, setAnalystMode] = useState(true);
    const [combinedChartOptions, setCombinedChartOption] = useState<any>({});
    const [prevEdgeEventFilterReq, setPrevEdgeEventFilterReq] = useState<any>();
    let globalColorCount = 0;

    const [Page, setPage] = useState(_q.hasOwnProperty("page") ? parseInt(_q.page) : 1);
    const [parameters, setParameters] = useState({});
    const getInterval = (minutes) => {
        if (minutes <= 60) {
            return "1 minute"
        } else if (minutes <= 1440 && minutes > 60) {
            return "3 minute"
        } else if (minutes > 1440 && minutes <= 10080) {
            return "20 minute"
        } else {
            return "12 hour";
        }
    }
    const [selectedBin, setSelectedBin] = useState(getInterval(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)));
    const [trackCount, setTrackCount] = useState<any>(0);

    const betaUser = (localStorage.getItem("isBetaUser") === "true");

    const METRIC_BY_WAN_TYPE = betaUser ? {
        "lband": [CHARTS_METRICS.LINK_STATUS, CHARTS_METRICS.PRE, CHARTS_METRICS.RTT, CHARTS_METRICS.THROUGHPUT, CHARTS_METRICS.CLIENTS, CHARTS_METRICS.USAGE, CHARTS_METRICS.SYSTEM_UPTIME, CHARTS_METRICS.LAN_STATUS, CHARTS_METRICS.FLOWS, CHARTS_METRICS.SPEED, CHARTS_METRICS.LATENCY, CHARTS_METRICS.STATUS, CHARTS_METRICS.SPEEDTEST],
        "lte": [CHARTS_METRICS.LINK_STATUS, CHARTS_METRICS.PRE, CHARTS_METRICS.RTT, CHARTS_METRICS.THROUGHPUT, CHARTS_METRICS.SINR, CHARTS_METRICS.RSSI, CHARTS_METRICS.CLIENTS, CHARTS_METRICS.USAGE, CHARTS_METRICS.SYSTEM_UPTIME, CHARTS_METRICS.LAN_STATUS, CHARTS_METRICS.FLOWS, CHARTS_METRICS.SPEED, CHARTS_METRICS.LATENCY, CHARTS_METRICS.STATUS, CHARTS_METRICS.SPEEDTEST],
        "starlink": [CHARTS_METRICS.LINK_STATUS, CHARTS_METRICS.PRE, CHARTS_METRICS.RTT, CHARTS_METRICS.THROUGHPUT, CHARTS_METRICS.CLIENTS, CHARTS_METRICS.USAGE, CHARTS_METRICS.SYSTEM_UPTIME, CHARTS_METRICS.LAN_STATUS, CHARTS_METRICS.FLOWS, CHARTS_METRICS.SPEED, CHARTS_METRICS.LATENCY, CHARTS_METRICS.STATUS, CHARTS_METRICS.SPEEDTEST],
        "vsat": [CHARTS_METRICS.LINK_STATUS, CHARTS_METRICS.PRE, CHARTS_METRICS.RTT, CHARTS_METRICS.THROUGHPUT, CHARTS_METRICS.VSAT_SNR, CHARTS_METRICS.VSAT_TXPOWER, CHARTS_METRICS.VSAT_POWER, CHARTS_METRICS.VSAT_SYMBOL_RATE, CHARTS_METRICS.CLIENTS, CHARTS_METRICS.USAGE, CHARTS_METRICS.SYSTEM_UPTIME, CHARTS_METRICS.LAN_STATUS, CHARTS_METRICS.FLOWS, CHARTS_METRICS.SPEED, CHARTS_METRICS.LATENCY, CHARTS_METRICS.STATUS, CHARTS_METRICS.SPEEDTEST],
        "wifi": [CHARTS_METRICS.LINK_STATUS, CHARTS_METRICS.PRE, CHARTS_METRICS.RTT, CHARTS_METRICS.THROUGHPUT, CHARTS_METRICS.CLIENTS, CHARTS_METRICS.USAGE, CHARTS_METRICS.SYSTEM_UPTIME, CHARTS_METRICS.LAN_STATUS, CHARTS_METRICS.FLOWS, CHARTS_METRICS.SPEED, CHARTS_METRICS.LATENCY, CHARTS_METRICS.STATUS, CHARTS_METRICS.SPEEDTEST],
        "wired": [CHARTS_METRICS.LINK_STATUS, CHARTS_METRICS.PRE, CHARTS_METRICS.RTT, CHARTS_METRICS.THROUGHPUT, CHARTS_METRICS.CLIENTS, CHARTS_METRICS.USAGE, CHARTS_METRICS.SYSTEM_UPTIME, CHARTS_METRICS.LAN_STATUS, CHARTS_METRICS.FLOWS, CHARTS_METRICS.SPEED, CHARTS_METRICS.LATENCY, CHARTS_METRICS.STATUS, CHARTS_METRICS.SPEEDTEST]
    } : {
        "lband": [CHARTS_METRICS.LINK_STATUS, CHARTS_METRICS.PRE, CHARTS_METRICS.RTT, CHARTS_METRICS.THROUGHPUT, CHARTS_METRICS.CLIENTS, CHARTS_METRICS.USAGE, CHARTS_METRICS.SYSTEM_UPTIME, CHARTS_METRICS.LAN_STATUS, CHARTS_METRICS.SPEEDTEST],
        "lte": [CHARTS_METRICS.LINK_STATUS, CHARTS_METRICS.PRE, CHARTS_METRICS.RTT, CHARTS_METRICS.THROUGHPUT, CHARTS_METRICS.SINR, CHARTS_METRICS.RSSI, CHARTS_METRICS.CLIENTS, CHARTS_METRICS.USAGE, CHARTS_METRICS.SYSTEM_UPTIME, CHARTS_METRICS.LAN_STATUS, CHARTS_METRICS.SPEEDTEST],
        "starlink": [CHARTS_METRICS.LINK_STATUS, CHARTS_METRICS.PRE, CHARTS_METRICS.RTT, CHARTS_METRICS.THROUGHPUT, CHARTS_METRICS.CLIENTS, CHARTS_METRICS.USAGE, CHARTS_METRICS.SYSTEM_UPTIME, CHARTS_METRICS.LAN_STATUS, CHARTS_METRICS.SPEEDTEST],
        "vsat": [CHARTS_METRICS.LINK_STATUS, CHARTS_METRICS.PRE, CHARTS_METRICS.RTT, CHARTS_METRICS.THROUGHPUT, CHARTS_METRICS.VSAT_SNR, CHARTS_METRICS.VSAT_TXPOWER, CHARTS_METRICS.VSAT_POWER, CHARTS_METRICS.VSAT_SYMBOL_RATE, CHARTS_METRICS.CLIENTS, CHARTS_METRICS.USAGE, CHARTS_METRICS.SYSTEM_UPTIME, CHARTS_METRICS.LAN_STATUS, CHARTS_METRICS.SPEEDTEST],
        "wifi": [CHARTS_METRICS.LINK_STATUS, CHARTS_METRICS.PRE, CHARTS_METRICS.RTT, CHARTS_METRICS.THROUGHPUT, CHARTS_METRICS.CLIENTS, CHARTS_METRICS.USAGE, CHARTS_METRICS.SYSTEM_UPTIME, CHARTS_METRICS.LAN_STATUS, CHARTS_METRICS.SPEEDTEST],
        "wired": [CHARTS_METRICS.LINK_STATUS, CHARTS_METRICS.PRE, CHARTS_METRICS.RTT, CHARTS_METRICS.THROUGHPUT, CHARTS_METRICS.CLIENTS, CHARTS_METRICS.USAGE, CHARTS_METRICS.SYSTEM_UPTIME, CHARTS_METRICS.LAN_STATUS, CHARTS_METRICS.SPEEDTEST]
    }

    useEffect(() => {
        let parameters = {
            locationId: selectedVessel
        }
        if(selectedVessel == _q?.k4Ids?.split(':')?.[0]) getSiteInterfaces(parameters);
        clearEdgeEvents();

        return () => {
            clearSiteCharts(Object.keys(CHARTS_METRICS).map(key => CHARTS_METRICS[key]));
            clearEdgeEvents();
        }
    }, [selectedVessel])

    useEffect(() => {
        try {
            let _q = getDecodeURI(location?.search);

            // if (_q.startDate && '' !== _q.startDate && _q.startDate > 1) {
            //     let incominStarDate = moment(new Date(parseInt(_q.startDate))).utc();
            //     setSelectedStartDate(incominStarDate);
            // }
    
            // if (_q.endDate && '' !== _q.endDate && _q.endDate > 1) {
            //     let incominEndDate = moment(new Date(parseInt(_q.endDate))).utc();
            //     setSelectedEndDate(incominEndDate);
            // }
        } catch (error) {
            displayToastError('Error parsing query url');
        }
    }, [location])

    useEffect(() => {
        if (previousInterval.current?.startDate !== interval.startDate || previousInterval.current?.endDate !== interval.endDate) {
            clearSiteCharts(Object.keys(CHARTS_METRICS).map(key => CHARTS_METRICS[key]));
            clearEdgeEvents();
            const _interval = getInterval(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3))
            if (!_.isEmpty(selectedInterfaces) && !_.isEmpty(siteInterfaces)) {
                fetchChartData(selectedInterfaces, selectedMetrics, undefined, _interval);
            }
            previousInterval.current = interval;
        }
    }, [interval]);

    useEffect(() => {
        if (newSummaryStartDate.isSameOrBefore(newSummaryEndDate)) {
            setChartInterval({
                startDate: newSummaryStartDate.toISOString(),
                endDate: newSummaryEndDate.toISOString()
            })
        }
        setTrackCount(1);
        setSelectedBin(getInterval(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)));
    }, [newSummaryEndDate, newSummaryStartDate])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getSiteInterfaces)) {
            const { data } = authReducer.getSiteInterfaces;
            if (data?.rows?.length && data.rows.length > 0) {
                let deviceInterfaces: locationInterfaces = {
                    interfaces: data.rows.map(r => {
                        return { deviceName: r[0], device_id: r[1], displayInterface: r[2], wanType: r[3] }
                    }),
                    toString: function () {
                        return this.interfaces.map(inf => `${inf.deviceName} - ${inf.displayInterface}`)
                    },
                    nameToDeviceInterfaceMap: (() => {
                        let m = new Map;
                        data.rows.forEach(r => m.set(`${r[0]} - ${r[2]}`, { deviceName: r[0], device_id: r[1], displayInterface: r[2], wanType: r[3]}));
                        return m;
                    })(),
                    deviceIdToNameMap: (() => {
                        let m = new Map;
                        data.rows.forEach(r => m.set(`${r[1]} - ${r[2]}`, `${r[0]} - ${r[2]}`));
                        return m;
                    })(),
                    deviceIdToNameMapForClient: (() => {
                        let m = new Map;
                        data.rows.forEach(r => m.set(`${r[1]}`, `${r[0]}`));
                        return m;
                    })(),
                    getDeviceInterface: function (name: string) {
                        return this.nameToDeviceInterfaceMap.get(name);
                    },
                    getDeviceName: function (deviceId: string) {
                        return this.deviceIdToNameMap.get(deviceId);
                    },
                    getDeviceNameForClient: function (deviceId: string) {
                        return this.deviceIdToNameMapForClient.get(deviceId);
                    }
                }
                setSiteInterfaces(deviceInterfaces);
            } else {
                setSiteInterfaces(undefined)
            }
        }
    }, [authReducer.getSiteInterfaces]);

    useEffect(() => {
        if (!_.isEmpty(siteInterfaces)) {
            let hubInterface:any = [];
            if(_q.hasOwnProperty('device_id')) {
                siteInterfaces?.interfaces.forEach(intfc => {
                    if((intfc.device_id == _q.device_id) && (intfc.displayInterface == _q.wan_name)) {
                        setSelectedInterfaces([`${intfc.deviceName} - ${_q.wan_name}`]);
                        hubInterface = [`${intfc.deviceName} - ${_q.wan_name}`];
                    }
                })
            }
            if(_.isEmpty(hubInterface)) {
                let di = siteInterfaces?.toString();
                if (di && Array.isArray(di) && di.length > 0) {
                    setSelectedInterfaces([di[0]]);
                }
            }
        }
    }, [siteInterfaces])

    useEffect(() => {
        if (siteInterfaces !== undefined) {
            let newInterfaces = _.difference(selectedInterfaces, previousSelectedInterfaces.current);
            let _metrics: any = []
            selectedInterfaces.forEach(intrfc => {
                let di = siteInterfaces.getDeviceInterface(intrfc)
                if (di) {
                    let _m = di?.wanType.includes('_') ? METRIC_BY_WAN_TYPE['vsat'] : METRIC_BY_WAN_TYPE[di?.wanType]
                    _metrics = [..._metrics, ..._m]
                }
            })
            _metrics = Array.from(new Set(_metrics));
            let _selectedMetrics: any = _.intersection(_metrics, selectedMetrics);
            setSelectedMetrics(_selectedMetrics);
            setMetrics(_metrics)
            if (newInterfaces.length > 0) {
                fetchChartData(newInterfaces, _selectedMetrics , undefined, selectedBin);
            } else if(selectedInterfaces.length > 0) {
                fetchChartData(selectedInterfaces, _selectedMetrics , undefined, selectedBin);
            }
            let deletedInterfaces = _.difference(previousSelectedInterfaces.current, selectedInterfaces);
            if (deletedInterfaces.length > 0) {
                deletedInterfaces.forEach(i => {
                    let di = siteInterfaces?.getDeviceInterface(i);
                    if (di) {
                        deleteSiteChartSeries(`${di.device_id} - ${di.displayInterface}`);
                    }
                });
            }
        }
        previousSelectedInterfaces.current = selectedInterfaces;
    }, [selectedInterfaces])

    useEffect(() => {
        if (!_.isEmpty(siteInterfaces)) {
            let newMetrics = _.difference(selectedMetrics, previousSelectedMetrics.current);
            if (newMetrics.length > 0) {
                fetchChartData(selectedInterfaces, newMetrics, undefined, selectedBin);
            }
            let deletedMetrics = _.difference(previousSelectedMetrics.current, selectedMetrics);
            if (deletedMetrics.length > 0) {
                clearSiteCharts(deletedMetrics);
            }
        }
        previousSelectedMetrics.current = selectedMetrics;
    }, [selectedMetrics])

    useEffect(() => {
        if (!_.isEmpty(siteInterfaces) && !analystMode) {
            if (selectedInterfaces.length > 0) {
                fetchChartData(selectedInterfaces, [PRE, RTT, SINR, RSSI, LINK_STATUS, THROUGHPUT, VSAT_SNR, VSAT_TXPOWER, VSAT_POWER, VSAT_SYMBOL_RATE, CLIENTS, USAGE, SYSTEM_UPTIME, LAN_STATUS, FLOWS, SPEED, LATENCY, STATUS, SPEEDTEST], undefined, selectedBin);
            }
        }
        previousSelectedMetrics.current = selectedMetrics;
    }, [analystMode])

    useEffect(() => {
        if (_.isEmpty(selectedEventFilters?.devices) || _.isEmpty(selectedEventFilters?.modules) || _.isEmpty(selectedEventFilters?.levels)) {
            clearSiteChartEvents()
            clearEdgeEvents()
        } else if(trackCount == 1) {
            fetchChartData([], [], selectedEventFilters, selectedBin);
        }
        setTrackCount(trackCount + 1);
    }, [selectedEventFilters])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getEdgeEventsFilter?.data)) {
            const { data: response } = authReducer.getEdgeEventsFilter;
            if (!_.isEmpty(response) && response.hasOwnProperty('filters')) {
                let filters: string[] | undefined = response?.filters?.data;
                if (filters?.length && filters.length >= 3) {
                    const [devices, modules, levels, sources, eventCount] = filters;
                    let _eventFilter: EventFilters = {
                        devices: devices?.split(','),
                        modules: modules?.split(','),
                        levels: levels?.split(','),
                        sources: sources?.split(','),
                        eventsCount: parseInt(eventCount)
                    };
                    setEventFilters(_eventFilter)

                    let _selectedEventFilters = {
                        devices: _eventFilter.devices.map(d => _eventFilter.devices[d] || d),
                        modules: _eventFilter.modules.filter(m => m !== EVENT_FILTERS._MODULES["WAN Management"]).map(m => EVENT_FILTERS.MODULES[m] || m),
                        levels: _eventFilter.levels.filter(l => l === EVENT_FILTERS._LEVELS["Critical"] || l === EVENT_FILTERS._LEVELS["Warning"]).map(l => EVENT_FILTERS.LEVELS[l] || l),
                        sources: _eventFilter.sources.map(s => EVENT_FILTERS.SOURCES[s] || s)
                    };
                    setSelectedEventFilters(_selectedEventFilters);        
                } else {
                    setSelectedEventFilters(undefined)
                    setEventFilters(undefined)
                }
            }
        }
    }, [authReducer.getEdgeEventsFilter])

    useEffect(() => {
        if (!_.isEmpty(selectedVessel) && !_.isEmpty(interval)) {
            let _request = {
                locationId: selectedVessel,
                startTime: interval.startDate,
                endTime: interval.endDate,
                deviceIdFilter: "",
                levelFilter: "",
                moduleFilter: "",
                sourceFilter: "",
                search: "",
            }
            if (!_.isEqual(_request, prevEdgeEventFilterReq)){
                if(prevEdgeEventFilterReq != undefined) {
                    getEdgeEventsFilter(_request);
                }
                setPrevEdgeEventFilterReq(_request);
            }
        }
    }, [selectedVessel, interval])

    const toolTipRSSI = {
        pointFormat: `<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br/>Provider: <b>{point.provider}</b><br/>Signal Type: <b style="text-transform: uppercase;s">{point.signalType}</b><br/>`,
        valueSuffix: 'dBm'
    }
    const toolTipSINR = {
        ...toolTipRSSI,
        valueSuffix: 'dB'
    }
    const toolTipVSAT_SNR = {
        pointFormat: `<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br/>Provider: <b>{point.provider}</b><br/>`,
        valueSuffix: 'dB'
    }
    const toolTipVSAT_TXPOWER = {
        ...toolTipVSAT_SNR,
        valueSuffix: 'dBm'
    }
    const toolTipVSAT_POWER = {
        ...toolTipVSAT_SNR,
        valueSuffix: 'dBm'
    }
    const toolTipVSAT_SYMBOL_RATE = {
        ...toolTipVSAT_SNR,
        valueSuffix: 'Bd'
    }

    useEffect(() => {
        let chartOptions = {
            ...defaultChartOptions,
            time: {
                timezone: getTimezoneCity(authReducer?.userTimezone)
            },
            chart: {
                ...defaultChartOptions.chart,
                events: {
                    selection: chartAreaSelectionHandler()
                },
                height: "450px"
            },
            title: {
                ...defaultChartOptions.title,
                text: selectedVesselName,
                floating: false
            },
            series: (!_.isEmpty(selectedMetrics) && !_.isEmpty(selectedInterfaces)) || (!_.isEmpty(selectedEventFilters?.devices) && !_.isEmpty(selectedEventFilters?.modules) && !_.isEmpty(selectedEventFilters?.levels)) ? (() => {
                let yAxisCount = 0;
                globalColorCount = 0;
                let performanceMetricSeries: any[] = [];
                let eventSeries: any[] = [];
                let speedTestEventSeries: any[] = [];
                if (!_.isEmpty(selectedMetrics) && !_.isEmpty(selectedInterfaces)) {
                    performanceMetricSeries = selectedMetrics.map((metric, i) => {
                        let tmpYAxisCount;
                        switch (metric) {
                            case LINK_STATUS:
                                return authReducer?.getSiteLinkStatus?.data?.probeSuccess?.series ? getSeriesData(authReducer?.getSiteLinkStatus?.data?.probeSuccess?.series, '%', yAxisCount++, 'Probe Success', true) : []
                            case RSSI:
                                return authReducer?.getSiteRSSI?.data?.rssiAvg?.series ? getSeriesData(authReducer?.getSiteRSSI?.data?.rssiAvg?.series, 'dBm', yAxisCount++, RSSI, true, toolTipRSSI) : []
                            case PRE:
                                return authReducer.getSiteDataRate?.data?.rateAvg?.series ? getSeriesData(authReducer.getSiteDataRate?.data?.rateAvg?.series, 'Mbps', yAxisCount++, PRE, true) : []
                            case RTT:
                                tmpYAxisCount = yAxisCount++;
                                return authReducer?.getSiteLatencyJitter?.data?.latency?.series && authReducer?.getSiteLatencyJitter?.data?.jitter?.series ? [
                                    getSeriesData(authReducer?.getSiteLatencyJitter?.data.latency.series, 'ms', tmpYAxisCount, 'Latency', true),
                                    getSeriesData(authReducer?.getSiteLatencyJitter?.data.jitter.series, 'ms', tmpYAxisCount, 'Jitter', true)
                                ].flat() : []
                            case SINR:
                                return authReducer?.getSiteSINR?.data?.sinrAvg?.series ? getSeriesData(authReducer?.getSiteSINR?.data?.sinrAvg?.series, 'db', yAxisCount++, SINR, true, toolTipSINR) : []
                            case THROUGHPUT:
                                tmpYAxisCount = yAxisCount++;
                                return authReducer?.getSiteThroughput?.data?.avgDownSpeed?.series && authReducer?.getSiteThroughput?.data?.avgUpSpeed?.series ? [
                                    getSeriesData(authReducer?.getSiteThroughput?.data.avgDownSpeed.series, 'Mbps', tmpYAxisCount, 'Download Rate', true),
                                    getSeriesData(authReducer?.getSiteThroughput?.data.avgUpSpeed.series, 'Mbps', tmpYAxisCount, 'Upload Rate', true)
                                ].flat() : []
                            case VSAT_SNR:
                                return authReducer?.getSiteVSATSnr?.data?.avg.series ? getSeriesData(authReducer?.getSiteVSATSnr?.data?.avg?.series, 'dB', yAxisCount++, VSAT_SNR, true, toolTipVSAT_SNR) : []
                            case VSAT_TXPOWER:
                                return authReducer?.getSiteVSATTxpower?.data?.avg.series ? getSeriesData(authReducer?.getSiteVSATTxpower?.data?.avg?.series, 'dBm', yAxisCount++, VSAT_TXPOWER, true, toolTipVSAT_TXPOWER) : []
                            case VSAT_POWER:
                                return authReducer?.getSiteVSATPower?.data?.avg.series ? getSeriesData(authReducer?.getSiteVSATPower?.data?.avg?.series, 'dBm', yAxisCount++, VSAT_POWER, true, toolTipVSAT_POWER) : []
                            case VSAT_SYMBOL_RATE:
                                return authReducer?.getSiteVSATSymbolRate?.data?.avg.series ? getSeriesData(authReducer?.getSiteVSATSymbolRate?.data?.avg?.series, 'Bd', yAxisCount++, VSAT_SYMBOL_RATE, true, toolTipVSAT_SYMBOL_RATE) : []
                            case CLIENTS:
                                return authReducer?.getSiteClients?.data?.clients?.series ? getSeriesData(authReducer?.getSiteClients?.data?.clients?.series, 'client', yAxisCount++, CLIENTS, true) : []
                            case USAGE:
                                tmpYAxisCount = yAxisCount++;
                                return authReducer?.getSiteUsage?.data?.totalUsage?.series && authReducer?.getSiteUsage?.data?.downUsage?.series && authReducer?.getSiteUsage?.data?.upUsage?.series ? [
                                    getSeriesData(getRedableBytesValue(authReducer?.getSiteUsage?.data?.totalUsage?.series)?.['series'], getRedableBytesValue(authReducer?.getSiteUsage?.data?.totalUsage?.series)?.['unit'], tmpYAxisCount, 'TotalUsage', true),
                                    getSeriesData(getRedableBytesValue(authReducer?.getSiteUsage?.data?.downUsage?.series)?.['series'], getRedableBytesValue(authReducer?.getSiteUsage?.data?.downUsage?.series)?.['unit'], tmpYAxisCount, 'DownUsage', true),
                                    getSeriesData(getRedableBytesValue(authReducer?.getSiteUsage?.data?.upUsage?.series)?.['series'], getRedableBytesValue(authReducer?.getSiteUsage?.data?.upUsage?.series)?.['unit'], tmpYAxisCount, 'UpUsage', true)
                                ].flat() : []
                            case SYSTEM_UPTIME:
                                return authReducer?.getSiteSystemUptime?.data?.status?.series ? getSeriesDataForUptime(authReducer?.getSiteSystemUptime?.data?.status?.series, '%', yAxisCount++, SYSTEM_UPTIME, true) : []
                            case LAN_STATUS:
                                return authReducer?.getSiteLanStatus?.data?.LAN_status?.series ? getSeriesData(authReducer?.getSiteLanStatus?.data?.LAN_status?.series, '', yAxisCount++, LAN_STATUS, true) : []
                            case FLOWS:
                                return authReducer?.getSiteFlows?.data?.flows?.series ? getSeriesData(authReducer?.getSiteFlows?.data?.flows?.series, 'flows', yAxisCount++, FLOWS, true) : []
                            case SPEED:
                                tmpYAxisCount = yAxisCount++;
                                return authReducer?.getSiteSpeed?.data?.avgDownSpeed?.series && authReducer?.getSiteSpeed?.data?.avgUpSpeed?.series ? [
                                    getSeriesData(authReducer?.getSiteSpeed?.data?.avgDownSpeed?.series, 'Mbps', tmpYAxisCount, 'AvgDownSpeed', true),
                                    getSeriesData(authReducer?.getSiteSpeed?.data?.avgUpSpeed?.series, 'Mbps', tmpYAxisCount, 'AvgUpSpeed', true)
                                ].flat() : []
                            case LATENCY:
                                return authReducer?.getSiteLatency?.data?.latency?.series ? getSeriesData(authReducer?.getSiteLatency?.data?.latency?.series, 'ms', yAxisCount++, LATENCY, true) : []
                            case STATUS:
                                return authReducer?.getSiteStatus?.data?.status?.series ? getSeriesData(authReducer?.getSiteStatus?.data?.status?.series, 'status', yAxisCount++, STATUS, true) : []
                            case SPEEDTEST:
                                if (!_.isEmpty(authReducer?.getSiteSpeedtest) && !_.isEmpty(authReducer?.getSiteSpeedtest?.data) && !_.isEmpty(authReducer?.getSiteSpeedtest?.data?.rows)) {
                                    let seriesData = authReducer?.getSiteSpeedtest?.data?.rows.map(row => {
                                        const [tb, device_id, interface_name, displayInterface, upSpeed, downSpeeed, interfaceDeviceAlias] = row;
                                        return {
                                            x: moment(tb).valueOf(),
                                            y: Number(downSpeeed),
                                            color: 'Blue',
                                            description: `Up Speed: <b>${upSpeed} Mbps</b></br> Down Speed: <b>${downSpeeed} Mbps</b></br> Device ID: <b>${device_id}</b></br>Device Interface: <b>${interfaceDeviceAlias}</b>`,
                                            upSpeed: Number(upSpeed),
                                            downSpeed: Number(downSpeeed)
                                        }
                                    });
                                    speedTestEventSeries = [{
                                        ...defaultChartOptions.series,
                                        type: 'scatter',
                                        name: `SpeedTest`,
                                        data: seriesData,
                                        color: 'blue',
                                        marker: {
                                            ...defaultChartOptions.series.marker,
                                            enabled: true,
                                            symbol: 'square'
                                        },
                                        tooltip: {
                                            pointFormat: '{point.description}'
                                        },
                                        yAxis: yAxisCount++,
                                        lineWidth: 0,
                                    }]
                                }
                        }
                    }).flat();
                }
                if (!_.isEmpty(selectedEventFilters?.devices) && !_.isEmpty(selectedEventFilters?.modules) && !_.isEmpty(selectedEventFilters?.levels) && !_.isEmpty(authReducer?.getEdgeEvents?.data?.rows)) {
                    let seriesData = authReducer?.getEdgeEvents?.data?.rows.map(row => {
                        const [ts, device_id, location_id, name, service, description, module, level, site, metadata] = row;
                        return {
                            x: moment(ts).valueOf(),
                            y: 0,
                            color: EVENT_FILTERS.LEVEL_COLOR[level] ? EVENT_FILTERS.LEVEL_COLOR[level] : 'black',
                            description: `Device ID: <b>${device_id}</b></br> Module: <b>${EVENT_FILTERS.MODULES[module]}</b></br> Level: <b>${EVENT_FILTERS.LEVELS[level]}</b></br><b><i>${description}</i></b>`
                        }
                    });
                    eventSeries = [{
                        ...defaultChartOptions.series,
                        name: 'Event',
                        data: seriesData,
                        color: 'red',
                        marker: {
                            ...defaultChartOptions.series.marker,
                            enabled: true,
                            symbol: 'square'
                        },
                        tooltip: {
                            pointFormat: '{point.description}'
                        },
                        yAxis: yAxisCount++
                    }]
                }
                return cloneDeep([...performanceMetricSeries, ...eventSeries, ...speedTestEventSeries]?.filter(series => series!=undefined));
            })() : [],
            xAxis:{
                ...defaultChartOptions.xAxis,
                plotLines: (!_.isEmpty(selectedEventFilters?.devices) && !_.isEmpty(selectedEventFilters?.modules) && !_.isEmpty(selectedEventFilters?.levels)) || !_.isEmpty(authReducer?.getSiteSpeedtest) ? (() => {
                    let eventsPloteLines: any = (!_.isEmpty(selectedEventFilters?.devices) && !_.isEmpty(selectedEventFilters?.modules) && !_.isEmpty(selectedEventFilters?.levels) && !_.isEmpty(authReducer?.getEdgeEvents?.data?.rows)) && authReducer?.getEdgeEvents?.data?.rows.map(row => {
                        const [ts, device_id, location_id, name, service, description, module, level, site, metadata] = row;
                        return {
                            value: moment(ts).valueOf(),
                            width: 0.5,
                            color: EVENT_FILTERS.LEVEL_COLOR[level] ? EVENT_FILTERS.LEVEL_COLOR[level] : 'black'
                        }
                    });
                    let speedTestEventsPloteLines: any = !_.isEmpty(authReducer?.getSiteSpeedtest) && !_.isEmpty(authReducer?.getSiteSpeedtest?.data) ? authReducer?.getSiteSpeedtest?.data?.rows.map(row => {
                        const [tb, device_id, interface_name, displayInterface, upSpeed, downSpeeed, interfaceDeviceAlias] = row;
                         return {
                                value: moment(tb).valueOf(),
                                width: 0.5,
                                color: 'Blue'
                            }
                    }) : [];
                    if(Array.isArray(eventsPloteLines) && Array.isArray(speedTestEventsPloteLines)) {
                        return cloneDeep([...eventsPloteLines]?.filter(series => series!=undefined));
                    }
                })() : []
            },
            yAxis: (!_.isEmpty(selectedMetrics) && !_.isEmpty(selectedInterfaces)) || (!_.isEmpty(selectedEventFilters?.devices) && !_.isEmpty(selectedEventFilters?.modules) && !_.isEmpty(selectedEventFilters?.levels)) ? (() => {
                let yAxisCount = 0;
                let performanceMetricAxes: any[] = [];
                let eventAxis: any[] = [];
                if (!_.isEmpty(selectedMetrics) && !_.isEmpty(selectedInterfaces)) {
                    performanceMetricAxes = selectedMetrics.map((metric, i) => {
                        switch (metric) {
                            case LINK_STATUS:
                                return authReducer?.getSiteLinkStatus?.data?.probeSuccess?.series ? getYAxis(`${LINK_STATUS} (Probe Success %)`, yAxisCount++) : []
                            case RSSI:
                                return authReducer?.getSiteRSSI?.data?.rssiAvg?.series ? getYAxis(`${RSSI} (dBm)`, yAxisCount++) : []
                            case PRE:
                                return authReducer.getSiteDataRate?.data?.rateAvg?.series ? getYAxis('PRE (Mbps)', yAxisCount++) : []
                            case RTT:
                                return authReducer?.getSiteLatencyJitter?.data?.latency?.series && authReducer?.getSiteLatencyJitter?.data?.jitter?.series ?
                                    getYAxis('RTT (ms)', yAxisCount++) : []
                            case SINR:
                                return authReducer?.getSiteSINR?.data?.sinrAvg?.series ? getYAxis(`${SINR} (db)`, yAxisCount++) : []
                            case THROUGHPUT:
                                return authReducer?.getSiteThroughput?.data?.avgDownSpeed?.series && authReducer?.getSiteThroughput?.data?.avgUpSpeed?.series ?
                                    getYAxis('Throughput (Mbps)', yAxisCount++) : []
                            case VSAT_SNR:
                                return authReducer?.getSiteVSATSnr?.data?.avg?.series ? getYAxis(`${VSAT_SNR} (dB)`, yAxisCount++) : []
                            case VSAT_TXPOWER:
                                return authReducer?.getSiteVSATTxpower?.data?.avg?.series ? getYAxis(`${VSAT_TXPOWER} (dBm)`, yAxisCount++) : []
                            case VSAT_POWER:
                                return authReducer?.getSiteVSATPower?.data?.avg?.series ? getYAxis(`${VSAT_POWER} (dBm)`, yAxisCount++) : []
                            case VSAT_SYMBOL_RATE:
                                return authReducer?.getSiteVSATSymbolRate?.data?.avg?.series ? getYAxis(`${VSAT_SYMBOL_RATE} (Bd)`, yAxisCount++) : []
                            case CLIENTS:
                                return authReducer?.getSiteClients?.data?.clients?.series ? getYAxis(`${CLIENTS}`, yAxisCount++) : []
                            case USAGE:
                                return authReducer?.getSiteUsage?.data?.totalUsage?.series && authReducer?.getSiteUsage?.data?.downUsage?.series && authReducer?.getSiteUsage?.data?.upUsage?.series ? getYAxis(`${USAGE} (GB)`, yAxisCount++) : []
                            case SYSTEM_UPTIME:
                                return authReducer?.getSiteSystemUptime?.data?.status?.series ? getYAxis(`${SYSTEM_UPTIME} (%)`, yAxisCount++) : []
                            case LAN_STATUS:
                                return authReducer?.getSiteLanStatus?.data?.LAN_status?.series ? getYAxis(`${LAN_STATUS}`, yAxisCount++) : []
                            case FLOWS:
                                return authReducer?.getSiteFlows?.data?.flows?.series ? getYAxis(`${FLOWS}`, yAxisCount++) : []
                            case SPEED:
                                return authReducer?.getSiteSpeed?.data?.avgDownSpeed?.series && authReducer?.getSiteSpeed?.data?.avgUpSpeed?.series ? getYAxis(`${SPEED} (Mbps)`, yAxisCount++) : []
                            case LATENCY:
                                return authReducer?.getSiteLatency?.data?.latency?.series ? getYAxis(`${LATENCY} (ms)`, yAxisCount++) : []
                            case STATUS:
                                return authReducer?.getSiteStatus?.data?.status?.series ? getYAxis(`${STATUS}`, yAxisCount++) : []
                            case SPEEDTEST:
                                return authReducer?.getSiteSpeedtest?.data?.rows ? getYAxis(`${SPEEDTEST} (Mbps)`, yAxisCount++) : []
                        }
                    }).flat();
                }
                if (!_.isEmpty(selectedEventFilters?.devices) && !_.isEmpty(selectedEventFilters?.modules) && !_.isEmpty(selectedEventFilters?.levels) && !_.isEmpty(authReducer?.getEdgeEvents?.data?.rows)) {
                    eventAxis = [{ title: null, labels: { enabled: false }, opposite: selectedMetrics.length > 0 }]
                }
                return cloneDeep([...performanceMetricAxes, ...eventAxis]?.filter(series => series!=undefined));
            })() : []
        };
        setCombinedChartOption(chartOptions);
    }, [authReducer?.userTimezone, authReducer.getSiteRSSI, authReducer.getSiteDataRate, authReducer.getSiteLatencyJitter, authReducer.getSiteLinkStatus, authReducer.getSiteSINR,
        authReducer.getSiteThroughput, authReducer.getEdgeEvents, authReducer.getSiteVSATSnr, authReducer.getSiteVSATTxpower, authReducer.getSiteVSATPower, authReducer.getSiteVSATSymbolRate,
       authReducer.getSiteClients, authReducer.getSiteUsage, authReducer.getSiteSystemUptime, authReducer.getSiteLanStatus, authReducer.getSiteFlows, authReducer.getSiteSpeed, authReducer.getSiteLatency,
       authReducer.getSiteStatus, authReducer.getSiteSpeedtest
    ])

    useEffect(() => {
        if (!_.isEmpty(errorReducer.errorGetSiteRSSI)) {
            displayToastError(errorReducer.errorGetSiteRSSI.description);
            dispatch({ type: ERROR_GET_SITE_RSSI, payload: {} })
        }
    }, [errorReducer.errorGetSiteRSSI])
    useEffect(() => {
        if (!_.isEmpty(errorReducer.errorGetSiteSINR)) {
            displayToastError(errorReducer.errorGetSiteSINR.description);
            dispatch({ type: ERROR_GET_SITE_SINR, payload: {} })
        }
    }, [errorReducer.errorGetSiteSINR])
    useEffect(() => {
        if (!_.isEmpty(errorReducer.errorGetSiteDataRate)) {
            displayToastError(errorReducer.errorGetSiteDataRate.description);
            dispatch({ type: ERROR_GET_SITE_DATA_RATE, payload: {} })
        }
    }, [errorReducer.errorGetSiteDataRate])
    useEffect(() => {
        if (!_.isEmpty(errorReducer.errorGetSiteLinkStatus)) {
            displayToastError(errorReducer.errorGetSiteLinkStatus.description);
            dispatch({ type: ERROR_GET_SITE_LINK_STATUS, payload: {} })
        }
    }, [errorReducer.errorGetSiteLinkStatus])
    useEffect(() => {
        if (!_.isEmpty(errorReducer.errorGetSiteLatencyJitter)) {
            displayToastError(errorReducer.errorGetSiteLatencyJitter.description);
            dispatch({ type: ERROR_GET_SITE_LATENCY_JITTER, payload: {} })
        }
    }, [errorReducer.errorGetSiteLatencyJitter])
    useEffect(() => {
        if (!_.isEmpty(errorReducer.errorGetSiteInterfaces)) {
            displayToastError(errorReducer.errorGetSiteInterfaces.description);
            dispatch({ type: ERROR_GET_SITE_INTERFACES, payload: {} })
        }
    }, [errorReducer.errorGetSiteInterfaces])
    useEffect(() => {
        if (!_.isEmpty(errorReducer.errorGetEdgeEvents)) {
            displayToastError(errorReducer.errorGetEdgeEvents.description);
            dispatch({ type: ERROR_GET_EDGE_EVENTS, payload: {} })
        }
    }, [errorReducer.errorGetEdgeEvents]);

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const handleStartDatePicker = (_startDate: moment.Moment | null) => {
        if (_startDate == null || !_startDate.isValid() || _startDate.toISOString() === interval.startDate)
            return;
        let params: any = getDecodeURI(location.search);
        params.startDate = _startDate.utc().valueOf();
        doNavigate(params);
    }

    const handleEndDatePicker = (_endDate: moment.Moment | null) => {
        if (_endDate == null || !_endDate.isValid() || _endDate.toISOString() === interval.endDate)
            return;
        let params: any = getDecodeURI(location.search);
        params.endDate = _endDate.utc().valueOf();
        doNavigate(params);
    }

    const handleInterfaceSelection = (selected) => {
        setSelectedInterfaces(selected);
    }

    const handleMetricSelection = (selected) => {
        setSelectedMetrics(selected)
    }

    const handleAnalystModeSwitch = (event) => {
        if (event.target.checked) {
            setSelectedMetrics([LINK_STATUS, THROUGHPUT]);
        }
        setAnalystMode(event.target.checked);
    }

    const handleEventFiltersChange = (_devices, _modules, _levels, _sources) => {
        setTrackCount(1);
        if (selectedEventFilters) {
            let _eventFilters = { ...selectedEventFilters }
            let updated = false;
            if (_.isArray(_devices) && !_.isEqual(_devices, selectedEventFilters.devices)) {
                _eventFilters = {
                    ..._eventFilters,
                    devices: _devices
                }
                updated = true;
            }
            if (_.isArray(_modules) && !_.isEqual(_modules, selectedEventFilters.modules)) {
                _eventFilters = {
                    ..._eventFilters,
                    modules: _modules
                }
                updated = true;
            }
            if (_.isArray(_levels) && !_.isEqual(_levels, selectedEventFilters.levels)) {
                _eventFilters = {
                    ..._eventFilters,
                    levels: _levels
                }
                updated = true;
            }
            if (_.isArray(_sources) && !_.isEqual(_sources, selectedEventFilters.sources)) {
                _eventFilters = {
                    ..._eventFilters,
                    sources: _sources
                }
                updated = true;
            }
            if (updated) {
                setSelectedEventFilters(_eventFilters);
                setPage(1);
            }
        }
    }

    const handleResetZoom = () => {
        const start = moment(Date.now()).subtract(1, 'hour');
        const end = moment(Date.now());
        setChartInterval({
            startDate: start.toISOString(),
            endDate: end.toISOString()
        })
        if (handleReset) {
            handleReset()
        }
        setPage(1);
        let params = getDecodeURI(location?.search);
        params.startDate = start.valueOf();
        params.endDate = end.valueOf();
        params.interval = '1h';
        doNavigate(params);
    }

    const getSelectedInterfaces = (selection): string => {
        if (!_.isEmpty(siteInterfaces) && selection?.length && selection.length > 0) {
            // From: ["EN1K23ABAAA000155 - LTE1", "EN1K23ABAAA000155 - LTE2"]
            let toFilterString = selection
                .map(si => {
                    const di = siteInterfaces?.getDeviceInterface(si);
                    return di ? `(device_id = '${di.device_id}' AND displayInterface = '${di.displayInterface}')` : null
                })
                .filter(si => si != null)
                .join(" OR ")
            // To:   (device_id = 'EN1K23ABAAA000155' AND displayInterface = 'LTE1') OR (device_id = 'EN1K23ABAAA000155' AND displayInterface = 'LTE2')
            let deviceInterfaceFilter = `WHERE ${toFilterString}`;
            return deviceInterfaceFilter;
        }
        return '';
    }

    const getSelectedInterfacesForClients = (selection): string => {
        if (!_.isEmpty(siteInterfaces) && selection?.length && selection.length > 0) {
            let toFilterString = selection
                .map(si => {
                    const di = siteInterfaces?.getDeviceInterface(si);
                    return di ? `deviceID = '${di.device_id}'` : null
                })
                .filter(si => si != null)
                .join(" OR ")
                let deviceInterfaceFilter = `AND (${toFilterString})`;
            return deviceInterfaceFilter;
        }
        return '';
    }

    const getSelectedInterfacesForLanStatus = (selection): string => {
        if (!_.isEmpty(siteInterfaces) && selection?.length && selection.length > 0) {
            let toFilterString = selection
                .map(si => {
                    const di = siteInterfaces?.getDeviceInterface(si);
                    return di ? `'${di.device_id}'` : null
                })
                .filter(si => si != null)
                .join(" , ")
                let deviceInterfaceFilter = `AND deviceID IN (${toFilterString})`;
            return deviceInterfaceFilter;
        }
        return '';
    }

    const getSelectedDeviceForFlows = (selection): string => {
        if (!_.isEmpty(siteInterfaces) && selection?.length && selection.length > 0) {
            // From: ["EN1K23ABAAA000155 - LTE1", "EN1K23ABAAA000155 - LTE2"]
            let toFilterString = selection
                .map(si => {
                    const di = siteInterfaces?.getDeviceInterface(si);
                    return di ? `'${di.device_id}'` : null
                })
                .filter(si => si != null)
                .join(",")
            // To:   'EN1K23ABAAA000155','EN1K23ABAAA000155'
            let deviceInterfaceFilter = `${toFilterString}`;
            return deviceInterfaceFilter;
        }
        return '';
    }

    const getSelectedWanForFlows = (selection): string => {
        if (!_.isEmpty(siteInterfaces) && selection?.length && selection.length > 0) {
            // From: ["EN1K23ABAAA000155 - LTE1", "EN1K23ABAAA000155 - LTE2"]
            let toFilterString = selection
                .map(si => {
                    const di = siteInterfaces?.getDeviceInterface(si);
                    return di ? `'${di.displayInterface}'` : null
                })
                .filter(si => si != null)
                .join(",")
            // To:   'LTE1','LTE2'
            let deviceInterfaceFilter = `${toFilterString}`;
            return deviceInterfaceFilter;
        }
        return '';
    }

    const chartAreaSelectionHandler = () => {
        return (event: any) => {
            let start = new Date(Math.ceil(event.xAxis[0].min)).toISOString();
            let end = new Date(Math.floor(event.xAxis[0].max)).toISOString();
            setChartInterval({
                startDate: start,
                endDate: end
            })
            setPage(1);
            let params = getDecodeURI(location?.search);
            params.startDate = new Date(Math.ceil(event.xAxis[0].min)).valueOf();
            params.endDate = new Date(Math.floor(event.xAxis[0].max)).valueOf();
            params.interval = 'customDates';
            doNavigate(params);
            if (handleChartAreaSelection) {
                handleChartAreaSelection(start, end)
            }
            return false;
        }
    }

    const readableBytesAsGB = (bytes: any, appendUnit = true) => {
        let _bytes = bytes;
        if (_bytes <= 0) {
            return 0;
        }
        const value = (_bytes / Math.pow(1000, 3)).toFixed(3);
        return appendUnit ? value + ' GB' : value;
    }

    const readableBytesAsMB = (bytes: any, appendUnit = true) => {
        let _bytes = bytes;
        if (_bytes <= 0) {
            return 0;
        }
        const value = (_bytes / Math.pow(1000, 2)).toFixed(2);
        return appendUnit ? value + ' MB' : value;
    }

    const getRedableBytesValue = (series) => {
        const seriesKeys = Object.keys(series);
        const gbValue = Math.pow(1000, 3);
        const isGBCrossed = seriesKeys.some(key => {
          const seriesValue = series[key];
          return (_.isArray(seriesValue) && seriesValue.some(item => item.y > gbValue))
        })
        const convertBytes = isGBCrossed ? readableBytesAsGB : readableBytesAsMB;
        let usage: any = seriesKeys.map(key => {
            let arr: any = [];
            series[key]?.forEach((el, i) => {
                let del=Number(convertBytes(el.y, false));
                arr.push({x:el.x,y:del, ['key']: key})
            })
            return arr;
        })
        const desiredOutput:any = {};
        usage.forEach(series => {
            series.forEach(({ x, y, key }) => {
                if (!desiredOutput[key]) {
                    desiredOutput[key] = [];
                }
               desiredOutput[key].push({ x, y: y});
            });
        });
        return {series: desiredOutput, unit: isGBCrossed ? 'GB' : 'MB'};
    }

    const getSeriesData = (series: any[], valueUnit: string, yAxisCount: any = 0, metric: any = null, useGlobalColorCount: boolean = false, tooltip: any = {}) => {
        return (() => {
            let selectedInterfaceSet = new Set(selectedInterfaces.map(i => {
                let di = siteInterfaces?.getDeviceInterface(i);
                return di && (valueUnit=='client' || valueUnit=='') ? `${di.device_id}` : di ? `${di.device_id} - ${di.displayInterface}` : ''
            }));
            return Object.keys(series).filter(key => selectedInterfaceSet.has(key)).map((key: string, i: any) => {
                return {
                    ...defaultChartOptions.series,
                    marker: {
                        symbol: 'circle',
                    },
                    name: metric && (valueUnit=='client' || valueUnit=='') ? `${metric} - ${siteInterfaces?.getDeviceNameForClient(key)}` : !metric && (valueUnit=='client' || valueUnit=='') ? `${siteInterfaces?.getDeviceNameForClient(key)}` : metric ? `${metric} - ${siteInterfaces?.getDeviceName(key)}` : siteInterfaces?.getDeviceName(key),
                    data: series[key],
                    color: useGlobalColorCount ? CHART_COLORS[globalColorCount++] : CHART_COLORS[i],
                    tooltip: _.isEmpty(tooltip) ? {
                        valueSuffix: (valueUnit!='client' && valueUnit!='' && valueUnit!='flows' && valueUnit!='status') ? valueUnit : ''
                    } : tooltip,
                    yAxis: yAxisCount,
                    valueUnit: (valueUnit!='client' && valueUnit!='' && valueUnit!='flows' && valueUnit!='status') ? valueUnit : ''
                };
            });
        })()
    }

    const getSeriesDataForUptime = (series: any[], valueUnit: string, yAxisCount: any = 0, metric: any = null, useGlobalColorCount: boolean = false, tooltip: any = {}) => {
        return (() => {
            return Object.keys(series).map((key: string, i: any) => {
                return {
                    ...defaultChartOptions.series,
                    marker: {
                        symbol: 'circle',
                    },
                    name: metric ? `${metric} - ${key}` : key,
                    data: series[key],
                    color: useGlobalColorCount ? CHART_COLORS[globalColorCount++] : CHART_COLORS[i],
                    tooltip: _.isEmpty(tooltip) ? {
                        valueSuffix: valueUnit
                    } : tooltip,
                    yAxis: yAxisCount,
                    valueUnit: valueUnit
                };
            });
        })()
    }

    const getYAxis = (title, i) => {
        return title == `${SYSTEM_UPTIME} (%)` || title == `${LINK_STATUS} (Probe Success %)` ? {
            title: {
                text: title,
            },
            max: 100,
            type: 'logarithmic',
            lineWidth: 1,
            opposite: i != 0 ? true : false
        } : title == LAN_STATUS ? {
            title: {
                text: title,
            },
            tickPositions: [0, 1, 2]
        } : title == STATUS ? {
            title: {
                text: title,
            },
            tickPositions: [0, 1, 2]
        } :
        {
            title: {
                text: title,
            },
            lineWidth: 1,
            opposite: i != 0 ? true : false
        }
    }

    const defaultChartOptions = {
        time: {
            timezone: getTimezoneCity(authReducer?.userTimezone)
        },
        chart: {
            type: 'spline',
            zoomType: 'x',
            height: "400px",
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
            plotBorderColor: '#e8eaeb',
            plotBorderWidth: 1
        },
        legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            y: 12,
            margin: 28,
            itemStyle: {
                color: '#3F3F3F'
            }
        },
        title: {
            align: 'left',
            floating: true,
            x: 12,
            y: 32,
            style: {
                fontWeight: '500'
            }
        },
        plotOptions: {
            series: {
                turboThreshold: 1000000,
                stickyTracking: false,
                connectNulls: false,
            }
        },
        credits: {
            enabled: false
        },
        xAxis: {
            type: 'datetime',
            gridLineWidth: 0.5,
        },
        yAxis: {
            title: {
                text: undefined
            }
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        series: {
            type: 'spline',
            marker: {
                symbol: 'circle',
            },
        },
        showLegendTable: true
    }

    const getChartOptions = (chartOptions, chartTitle, dataFetcher, data, valueUnit, tooltip = {}) => {
        let series = !_.isEmpty(data) ? chartTitle == SYSTEM_UPTIME ? getSeriesDataForUptime(data, valueUnit, 0, null, false, tooltip) : getSeriesData(data, valueUnit, 0, null, false, tooltip) : []
        return {
            ...chartOptions,
            chart: {
                ...chartOptions.chart,
                events: {
                    selection: chartAreaSelectionHandler()
                }
            },
            title: {
                ...chartOptions.title,
                text: chartTitle
            },
            legend: {
                ...chartOptions.legend,
                y: series.length > 2 ? 32 : 12
            },
            series: series
        }
    }

    const latencyJitterChartOptions = {
        ...defaultChartOptions,
        chart: {
            ...defaultChartOptions.chart,
            events: {
                selection: chartAreaSelectionHandler()
            }
        },
        title: {
            ...defaultChartOptions.title,
            text: 'Latency & Jitter'
        },
        legend: {
            ...defaultChartOptions.legend,
            y: 32
        },
        series: authReducer?.getSiteLatencyJitter?.data?.latency?.series && authReducer?.getSiteLatencyJitter?.data?.jitter?.series ? (() => {
            globalColorCount = 0;
            return [
                getSeriesData(authReducer?.getSiteLatencyJitter?.data.latency.series, 'ms', 0, 'Latency', true),
                getSeriesData(authReducer?.getSiteLatencyJitter?.data.jitter.series, 'ms', 0, 'Jitter', true)
            ].flat()
        })() : []
    }

    const throughputChartOptions = {
        ...defaultChartOptions,
        chart: {
            ...defaultChartOptions.chart,
            events: {
                selection: chartAreaSelectionHandler()
            }
        },
        title: {
            ...defaultChartOptions.title,
            text: 'Throughput'
        },
        legend: {
            ...defaultChartOptions.legend,
            y: 32
        },
        series: authReducer?.getSiteThroughput?.data?.avgDownSpeed?.series && authReducer?.getSiteThroughput?.data?.avgUpSpeed?.series ? (() => {
            globalColorCount = 0;
            return [
                getSeriesData(authReducer?.getSiteThroughput?.data.avgDownSpeed.series, 'Mbps', 0, 'Download Rate', true),
                getSeriesData(authReducer?.getSiteThroughput?.data.avgUpSpeed.series, 'Mbps', 0, 'Upload Rate', true)
            ].flat()
        })() : []
    }

    const usageChartOptions = {
        ...defaultChartOptions,
        chart: {
            ...defaultChartOptions.chart,
            events: {
                selection: chartAreaSelectionHandler()
            }
        },
        title: {
            ...defaultChartOptions.title,
            text: 'Usage'
        },
        legend: {
            ...defaultChartOptions.legend,
            y: 32
        },
        series: authReducer?.getSiteUsage?.data?.totalUsage?.series && authReducer?.getSiteUsage?.data?.downUsage?.series && authReducer?.getSiteUsage?.data?.upUsage?.series ? (() => {
            globalColorCount = 0;
            return [
                getSeriesData(getRedableBytesValue(authReducer?.getSiteUsage?.data?.totalUsage?.series)?.['series'], getRedableBytesValue(authReducer?.getSiteUsage?.data?.totalUsage?.series)?.['unit'], 0, 'TotalUsage', true),
                getSeriesData(getRedableBytesValue(authReducer?.getSiteUsage?.data?.downUsage?.series)?.['series'], getRedableBytesValue(authReducer?.getSiteUsage?.data?.downUsage?.series)?.['unit'], 0, 'DownUsage', true),
                getSeriesData(getRedableBytesValue(authReducer?.getSiteUsage?.data?.upUsage?.series)?.['series'], getRedableBytesValue(authReducer?.getSiteUsage?.data?.upUsage?.series)?.['unit'], 0, 'UpUsage', true)
            ].flat()
        })() : []
    }

    const speedChartOptions = {
        ...defaultChartOptions,
        chart: {
            ...defaultChartOptions.chart,
            events: {
                selection: chartAreaSelectionHandler()
            }
        },
        title: {
            ...defaultChartOptions.title,
            text: 'MPK Speed'
        },
        legend: {
            ...defaultChartOptions.legend,
            y: 32
        },
        series: authReducer?.getSiteSpeed?.data?.avgDownSpeed?.series && authReducer?.getSiteSpeed?.data?.avgUpSpeed?.series ? (() => {
            globalColorCount = 0;
            return [
                getSeriesData(authReducer?.getSiteSpeed?.data?.avgDownSpeed?.series, 'Mbps', 0, 'AvgDownSpeed', true),
                getSeriesData(authReducer?.getSiteSpeed?.data?.avgUpSpeed?.series, 'Mbps', 0, 'AvgUpSpeed', true)
            ].flat()
        })() : []
    }

    const fetchChartData = (interfaces: string[], metrics: string[], eventFilters: EventFilters | undefined = undefined, _selectedBin) => {
        if (!_.isEmpty(eventFilters) && !_.isEmpty(eventFilters?.devices) && !_.isEmpty(eventFilters?.modules) && !_.isEmpty(eventFilters?.levels)) {
            let parameters = {
                startTime: interval.startDate,
                endTime: interval.endDate,
                locationId: selectedVessel,
                deviceIdFilter: !_.isEmpty(eventFilters?.devices) ? `and device_id IN (${eventFilters?.devices.map(d => `'${d}'`).join()})` : '',
                moduleFilter: !_.isEmpty(eventFilters?.modules) ? `and module IN (${eventFilters?.modules.map(m => `'${EVENT_FILTERS._MODULES[m]}'`).join()})` : '',
                levelFilter: !_.isEmpty(eventFilters?.levels) ? `and level IN (${eventFilters?.levels.map(l => `'${EVENT_FILTERS._LEVELS[l]}'`).join()})` : '',
                sourceFilter: !_.isEmpty(eventFilters?.sources) ? `and source IN (${eventFilters?.sources.map(s => `'${EVENT_FILTERS._SOURCES[s]}'`).join()})` : '',
                search: ""
            }
            getEdgeEventsForCharts(parameters)
            setParameters(parameters);
        }
        if (!_.isEmpty(siteInterfaces) && !_.isEmpty(interfaces) && !_.isEmpty(metrics)) {
            let parameters: ChartsQueryParameters  = {
                startTime: interval.startDate,
                endTime: interval.endDate,
                locationId: selectedVessel,
                chartInterval: _selectedBin ? _selectedBin : getInterval(Math.floor(((new Date(interval.endDate)).getTime() - (new Date(interval.startDate)).getTime()) / 60e3)),
                device_interface_filter: getSelectedInterfaces(interfaces),
                device_filter: ''
            }
            let isClickHouse = true;
            let chartInt = parameters?.chartInterval;
            if(isClickHouse) {
                parameters  = { ...parameters , 
                    intervalNum: chartInt.split(' ')[0],
                    interval:  chartInt.split(' ')[1]
                }
            }
            if (!analystMode) {
                metrics = [PRE, RTT, SINR, RSSI, LINK_STATUS, THROUGHPUT, VSAT_SNR, VSAT_TXPOWER, VSAT_POWER, VSAT_SYMBOL_RATE, CLIENTS, USAGE, SYSTEM_UPTIME, LAN_STATUS, FLOWS, SPEED, LATENCY, STATUS, SPEEDTEST];
            }
            metrics.forEach(metric => {
                switch (metric) {
                    case LINK_STATUS:
                        getSiteLinkStatus(parameters);
                        break;
                    case RSSI:
                        getSiteRSSI(parameters);
                        break;
                    case PRE:
                        getSiteDataRate(parameters);
                        break;
                    case RTT:
                        getSiteLatencyJitter(parameters);
                        break;
                    case SINR:
                        getSiteSINR(parameters);
                        break;
                    case THROUGHPUT:
                        getSiteThroughput(parameters);
                        break;
                    case VSAT_SNR:
                        getSiteVSATSnr(parameters);
                        break;
                    case VSAT_TXPOWER:
                        getSiteVSATTxpower(parameters);
                        break;
                    case VSAT_POWER:
                        getSiteVSATPower(parameters);
                        break;
                    case VSAT_SYMBOL_RATE:
                        getSiteVSATSymbolRate(parameters);
                        break;
                    case CLIENTS:
                        getSiteClients({...parameters, device_filter: getSelectedInterfacesForClients(interfaces)});
                        break;
                    case USAGE:
                        getSiteUsage(parameters);
                        break;
                    case SYSTEM_UPTIME:
                        getSiteSystemUptime(parameters);
                        break;
                    case LAN_STATUS:
                        getSiteLanStatus({...parameters, device_filter: getSelectedInterfacesForLanStatus(interfaces)});
                        break;
                    case FLOWS:
                        getSiteFlows({...parameters, device_id: getSelectedDeviceForFlows(interfaces), wan_name: getSelectedWanForFlows(interfaces)});
                        break;
                    case SPEED:
                        getSiteSpeed({...parameters, device_id: getSelectedDeviceForFlows(interfaces), wan_name: getSelectedWanForFlows(interfaces)});
                        break;
                    case LATENCY:
                        getSiteLatency({...parameters, device_id: getSelectedDeviceForFlows(interfaces), wan_name: getSelectedWanForFlows(interfaces)});
                        break;
                    case STATUS:
                        getSiteStatus({...parameters, device_id: getSelectedDeviceForFlows(interfaces), wan_name: getSelectedWanForFlows(interfaces)});
                        break;
                    case SPEEDTEST:
                        getSiteSpeedtest(parameters);
                        break;
                }
            })
        }
    }

    const handleBinChange = (e) => {
        const value = e.target.value;
        setSelectedBin(value);
        fetchChartData(selectedInterfaces, selectedMetrics, undefined, value);
    };

    return (
        <Fragment>
            <div className="site-filter-container siteCharts__filters alignTopbarItemsCenter">
                <div className="site-filter-item">
                    {analystMode ? (
                        <div className="sdwanTopbar">
                            {eventFilters && <EventsFilterMenu
                                devices={eventFilters?.devices}
                                modules={eventFilters?.modules.map(value => EVENT_FILTERS.MODULES[value] || value)}
                                levels={eventFilters?.levels.map(value => EVENT_FILTERS.LEVELS[value] || value)}
                                sources={eventFilters?.sources.map(value => EVENT_FILTERS.SOURCES[value] || value)}
                                handleChange={handleEventFiltersChange}
                                defaultSelectionDevices={selectedEventFilters?.devices}
                                defaultSelectionModules={selectedEventFilters?.modules}
                                defaultSelectionLevels={selectedEventFilters?.levels}
                                defaultSelectionSources={selectedEventFilters?.sources}
                            />}
                            {siteInterfaces && selectedInterfaces && <MultipleSelectChip  defaultSelection={selectedInterfaces} handleSelection={handleInterfaceSelection} values={siteInterfaces.toString()} name={'Interfaces'} sx={{ maxWidth:"250px", width:"250px" }} />}
                            <MultipleSelectChip defaultSelection={selectedMetrics} handleSelection={handleMetricSelection} values={metrics} name={'Metrics'} sx={{ maxWidth:"250px", width:"250px" }} />

                        </div>
                    ) : null}

                        {siteInterfaces && selectedInterfaces && !analystMode ?
                            <MultipleSelectChip defaultSelection={selectedInterfaces} handleSelection={handleInterfaceSelection} values={siteInterfaces.toString()} name={'Interfaces'} sx={{marginTop:"10px",  maxWidth:"250px", width:"250px"  }} />
                            : null
                        }
                </div>

                <div className="site-filter-item jc-end">
                    <Grid className="pieChat-selectTag">
                        <FormControl variant="standard" className='selectEntry-pie sdwan-select-bin'>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={selectedBin}
                                onChange={handleBinChange}
                            >
                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 1 && <MenuItem value={'1 minute'}>1m</MenuItem>}
                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 3 && <MenuItem value={'3 minute'}>3m</MenuItem>}
                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10 && <MenuItem value={'10 minute'}>10m</MenuItem>}
                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 20 && <MenuItem value={'20 minute'}>20m</MenuItem>}
                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 60 && <MenuItem value={'1 hour'}>1h</MenuItem>}
                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 360 && <MenuItem value={'6 hour'}>6h</MenuItem>}
                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 720 && <MenuItem value={'12 hour'}>12h</MenuItem>}
                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 1440 && <MenuItem value={'1 day'}>1d</MenuItem>}
                                {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10080 && <MenuItem value={'7 day'}>7d</MenuItem>}
                            </Select>
                        </FormControl>
                    </Grid>
                    <div className="siteCharts__filters-reset-zoom">
                        <FormControlLabel
                            value="Reset Zoom"
                            control={<IconButton onClick={handleResetZoom}><RestartAltIcon /></IconButton>}
                            label="Reset Zoom"
                            labelPlacement="start"
                        />
                    </div>
                    <div className="siteCharts__filters-analyst-mode-switch">
                        <FormControlLabel
                            value="Analyst Mode"
                            control={<Switch color="primary" checked={analystMode} onChange={handleAnalystModeSwitch} inputProps={{ 'aria-label': 'controlled' }} />}
                            label="Analyst Mode"
                            labelPlacement="start"
                        />
                    </div>
                </div>
             
            </div>

            <div className="siteCharts__charts-container">
                {
                    !analystMode ?
                        <Grid container spacing={{ xs: 2, md: 4 }}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Paper elevation={3} className="siteCharts__charts-paper">
                                    <Chart chartOptions={getChartOptions(
                                        {
                                            ...defaultChartOptions, tooltip: {
                                                pointFormat: '<span style="color:{series.color}">{series.name} Probe Success</span>: <b>{point.y}</b><br/>',
                                                shared: true
                                            },
                                            yAxis: {
                                                ...defaultChartOptions.yAxis,
                                                max: 100
                                            }
                                        },
                                        'Link Uptime', getSiteLinkStatus, authReducer?.getSiteLinkStatus?.data?.probeSuccess?.series, '%')} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Paper elevation={3} className="siteCharts__charts-paper">
                                    <Chart chartOptions={latencyJitterChartOptions} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Paper elevation={3} className="siteCharts__charts-paper">
                                    <Chart chartOptions={getChartOptions(defaultChartOptions, 'SINR', getSiteSINR, authReducer?.getSiteSINR?.data?.sinrAvg?.series, 'db', toolTipSINR)} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Paper elevation={3} className="siteCharts__charts-paper">
                                    <Chart chartOptions={getChartOptions(defaultChartOptions, 'RSSI', getSiteRSSI, authReducer?.getSiteRSSI?.data?.rssiAvg?.series, 'dBm', toolTipRSSI)} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Paper elevation={3} className="siteCharts__charts-paper">
                                    <Chart chartOptions={throughputChartOptions} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Paper elevation={3} className="siteCharts__charts-paper">
                                    <Chart chartOptions={getChartOptions(defaultChartOptions, 'Peak Rate Estimate', getSiteDataRate, authReducer.getSiteDataRate?.data?.rateAvg?.series, 'Mbps')} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Paper elevation={3} className="siteCharts__charts-paper">
                                    <Chart chartOptions={getChartOptions(defaultChartOptions, VSAT_SNR, getSiteVSATSnr, authReducer.getSiteVSATSnr?.data?.avg?.series, 'dB', toolTipVSAT_SNR)} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Paper elevation={3} className="siteCharts__charts-paper">
                                    <Chart chartOptions={getChartOptions(defaultChartOptions, VSAT_TXPOWER, getSiteVSATTxpower, authReducer.getSiteVSATTxpower?.data?.avg?.series, 'dBm', toolTipVSAT_TXPOWER)} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Paper elevation={3} className="siteCharts__charts-paper">
                                    <Chart chartOptions={getChartOptions(defaultChartOptions, VSAT_POWER, getSiteVSATPower, authReducer.getSiteVSATPower?.data?.avg?.series, 'dBm', toolTipVSAT_POWER)} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Paper elevation={3} className="siteCharts__charts-paper">
                                    <Chart chartOptions={getChartOptions(defaultChartOptions, VSAT_SYMBOL_RATE, getSiteVSATSymbolRate, authReducer.getSiteVSATSymbolRate?.data?.avg?.series, 'Bd', toolTipVSAT_SYMBOL_RATE)} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Paper elevation={3} className="siteCharts__charts-paper">
                                    <Chart chartOptions={getChartOptions(defaultChartOptions, CLIENTS, getSiteClients, authReducer?.getSiteClients?.data?.clients?.series, 'client', {})} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Paper elevation={3} className="siteCharts__charts-paper">
                                    <Chart chartOptions={usageChartOptions} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Paper elevation={3} className="siteCharts__charts-paper">
                                    <Chart chartOptions={getChartOptions({...defaultChartOptions, yAxis: {
                                                ...defaultChartOptions.yAxis,
                                                max: 100
                                            }}, SYSTEM_UPTIME, getSiteSystemUptime, authReducer?.getSiteSystemUptime?.data?.status?.series, '%', {})} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Paper elevation={3} className="siteCharts__charts-paper">
                                    <Chart chartOptions={getChartOptions({...defaultChartOptions, yAxis: {
                                                ...defaultChartOptions.yAxis,
                                                max: 2
                                            }}, LAN_STATUS, getSiteLanStatus, authReducer?.getSiteLanStatus?.data?.LAN_status?.series, '', {})} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Paper elevation={3} className="siteCharts__charts-paper">
                                    <Chart chartOptions={getChartOptions(defaultChartOptions, FLOWS, getSiteFlows, authReducer?.getSiteFlows?.data?.flows?.series, 'flows', {})} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Paper elevation={3} className="siteCharts__charts-paper">
                                    <Chart chartOptions={speedChartOptions} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Paper elevation={3} className="siteCharts__charts-paper">
                                    <Chart chartOptions={getChartOptions(defaultChartOptions, LATENCY, getSiteLatency, authReducer?.getSiteLatency?.data?.latency?.series, 'ms', {})} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Paper elevation={3} className="siteCharts__charts-paper">
                                    <Chart chartOptions={getChartOptions(defaultChartOptions, STATUS, getSiteStatus, authReducer?.getSiteStatus?.data?.status?.series, 'status', {})} />
                                </Paper>
                            </Grid>
                        </Grid> :
                        <div className="siteCharts__charts-container-freeFormTool">
                            {!_.isEmpty(combinedChartOptions) ? <Chart chartOptions={combinedChartOptions} immutable={true} updateArgs={[true, false, true]} parameters={parameters} page={Page} setPage={setPage} enableEventsTable={true} uptimeChart={SYSTEM_UPTIME ? true : ''} /> : null}
                        </div>
                }
            </div>
            {
                authReducer.loading || authReducer.siteChartsSiteInterfacesLoading || authReducer.siteChartsRSSILoading || authReducer.siteChartsSINRLoading || authReducer.siteChartsLinkStatusLoading || authReducer.siteChartsLatencyJitterLoading || authReducer.siteChartsThroughputLoading ||
                authReducer.siteChartsVSATSnrLoading || authReducer.siteChartsVSATyTXPowerLoading || authReducer.siteChartsVSATPowerLoading || authReducer.siteChartsVSATSymbolRateLoading || authReducer.siteChartsSiteDataRateLoading || authReducer.getEdgeEventsTableLoading || authReducer.siteChartsSiteClientsLoading ||
                authReducer.siteChartsSiteUsageLoading || authReducer.siteChartsSiteSystemUptimeLoading || authReducer.getEdgeEventsTableCountLoading || authReducer.siteChartsSiteLanStatusLoading || authReducer.siteChartsSiteFlowsLoading || authReducer.siteChartsSiteSpeedLoading || authReducer.siteChartsSiteLatencyLoading || authReducer.siteChartsSiteStatusLoading || authReducer.siteChartsSiteSpeedtestLoading ?
                    <CustomLoader
                    showLoader={true}
                    loadingText={ "Fetching data please wait..." }
                    />
               : null
            }
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
});

export default withRouter(
    connect(mapStateToProps, {
        getSiteRSSI,
        getSiteSINR,
        getSiteDataRate,
        getSiteLinkStatus,
        getSiteLatencyJitter,
        getSiteThroughput,
        getSiteVSATSnr,
        getSiteVSATTxpower,
        getSiteVSATPower,
        getSiteVSATSymbolRate,
        clearSiteCharts,
        deleteSiteChartSeries,
        getEdgeEventsForCharts,
        getEdgeEventsFilter,
        clearSiteChartEvents,
        clearEdgeEvents,
        getSiteInterfaces,
        getSiteClients,
        getSiteUsage,
        getSiteSystemUptime,
        getSiteLanStatus,
        getSiteFlows,
        getSiteSpeed,
        getSiteLatency,
        getSiteStatus,
        getSiteSpeedtest
    })(VesselCharts)
);