export const beamNames = {
    "Astra4A_AF17_BTZ": true,
    "SES-14_Beam_Atlantic03_BTZ": true,
    "SES-6_AE01_BTZ": true,
    "NSS-7_CA01_WMP": true,
    "SES-10_CA02-SMN": true,
    "SES-14_NA09_WDB": true,
    "SES-15_Beam_BC_SMN": true,
    "SES-1_NA": true,
    "Astra4A_AF17_BTZ": true,
    "Astra4A_EU03_BTZ": true,
    "SES-4_EU01_WMP": true,
    "SES-4_EU02_WMP": true,
    "SES-4_SC01_WMP": true,
    "SES-12_ME12_ADL": true,
    "NSS-7_SA01_WMP": true
};

export const kmlNames = {
    "Viasat1": true,
    "Viasat2": true
}