import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Chip from "@mui/material/Chip";
import { Grid, Dialog } from "@mui/material";
import close from "../../asset/image/cross.svg";
import "./GroupDeviceList.css";

const GroupDeviceList = (props) => {
    const { selectedRow, groupDeviceListPopup, setGroupDeviceListPopup } = props;

    const colsePopup = () => {
        setGroupDeviceListPopup(false)
    }

    return (
        <div className="Form">
            <Dialog className="inventory-group-device-list-popup menuScroll" open={groupDeviceListPopup}>
                <DialogTitle className="dialog-title">
                    Group: {selectedRow["groupName"]}
                    <img src={close} className="close" title="Close" onClick={() => { colsePopup() }} />
                </DialogTitle>
                <DialogContent className="dialog-content">
                    <div className="devices">
                        {selectedRow.devices && selectedRow.devices.map((device: any, idx) => (
                            <Chip key={device.id} label={device.name} />
                        ))}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {
    })(GroupDeviceList)
);