import { api } from "../../server/request";
import { handleResponse, handleError, handleResponseObj } from "../responseUtil";
import axios from "axios";
import { 
    SET_ACTIVITY_REPORTS_LOADING,
    SET_ACTIVITY_REPORTS_APPS_LOADING,
    SET_ACTIVITY_REPORTS_VLANS_LOADING,
    SET_ACTIVITY_REPORTS_WIDGET_LOADING,
    SET_ACTIVITY_REPORTS_CATEGORY_PIE_LOADING,
    SET_ACTIVITY_REPORTS_APPLICATION_PIE_LOADING,
    SET_ACTIVITY_REPORTS_USAGE_LINE_CHART_LOADING,
    SET_ACTIVITY_REPORTS_TOP_APP_LINE_CHART_LOADING,
    SET_ACTIVITY_REPORTS_TOP_USERS_LOADING,
    SET_ACTIVITY_REPORTS_TOP_SITES_LOADING,
    GET_ALL_USERNAMES,
    GET_USAGE_WIDGETS, 
    GET_USAGE_TABLE_DATA,
    GET_LINE_CHART_USAGE,
    GET_CATEGORY_PIE_CHART_USAGE,
    GET_TOP_APPLICATION_LINE_CHART,
    GET_TOP_SITES_TABLE_DATA,
    GET_TOP_APP_TOP_BLOCKED_PIE_CHART,
    GET_TOP_DOWNLOAD_UPLOAD_BLOCKED_APPLICATION,
    GET_TOP_DOWNLOAD_UPLOAD_BLOCKED_DOMAIN,
    GET_TOP_DOWNLOAD_UPLOAD_BLOCKED_IP,
    SET_ACTIVITY_REPORTS_BOTTOM_TABLE_LOADING,
    SET_ACTIVITY_REPORTS_BOTTOM_TABLE_DOMAIN_LOADING,
    SET_ACTIVITY_REPORTS_BOTTOM_TABLE_IP_LOADING,
    GET_ACTIVITY_REPORTS_CONTENT_FILTERS_TABLE,
    GET_TOP_BLOCKED_APP_BOTTOM_TABLE,
    SET_REPORTS__TOP_BLOCKED_APP_BOTTOM_TABLE_LOADING,
    INVENTORY_ASSIGN_DEVICE_TO_WAREHOUSE,
    SET_OPERATIONAL_USAGE_DATA_DUMP_TABLE_COUNT_LOADING,
    GET_OPERATIONAL_USAGE_DATA_DUMP_TABLE_COUNT,
    SET_OPERATIONAL_USAGE_DATA_DUMP_TABLE_LOADING,
    GET_OPERATIONAL_USAGE_DATA_DUMP_TABLE,
    SET_OPERATIONAL_USAGE_TIMESERIES_TABLE_LOADING,
    GET_OPERATIONAL_USAGE_TIMESERIES_TABLE,
    SET_OPERATIONAL_USAGE_TIMESERIES_TABLE_COUNT_LOADING,
    GET_OPERATIONAL_USAGE_TIMESERIES_TABLE_COUNT,
    SET_OPERATIONAL_USAGE_DATA_SINGLE_SITE_PER_WAN_WISE_LOADING,
    GET_OPERATIONAL_USAGE_DATA_SINGLE_SITE_PER_WAN_WISE,
    SET_OPERATIONAL_USAGE_FILTER_DEVICE_LIST_LOADING,
    GET_OPERATIONAL_USAGE_FILTER_DEVICE_LIST,
    SET_OPERATIONAL_USAGE_FILTER_SW_LIST_LOADING,
    GET_OPERATIONAL_USAGE_FILTER_SW_LIST,
    SET_OPERATIONAL_USAGE_DATA_DUMP_DOWNLOAD_TABLE_LOADING,
    GET_OPERATIONAL_USAGE_DATA_DUMP_DOWNLOAD_TABLE,
    CREATE_DOWNLOAD_SERVICE,
    SET_GENERATED_REPORTS_TABLE_LOADING,
    SET_GENERATED_REPORTS_TABLE_COUNT_LOADING,
    GET_GENERATED_REPORTS_TABLE,
    GET_GENERATED_REPORTS_TABLE_COUNT,
    GET_ALL_APPLICATIONS,
    GET_ALL_VLANS,
    GET_DPI_REPORTS_USER_MAX_RISK_TYPES,
    SET_LOADING,
    SET_DPI_REPORTS_TOP_WIDGET_ALLSITE_RISK,
    GET_DPI_REPORTS_TOP_WIDGET_ALLSITE_RISK,
    GET_DPI_REPORTS_TOP_WIDGET_SINGLESITE_RISK,
    SET_DPI_REPORTS_PIE_CHART_ALLSITE_RISK,
    GET_DPI_REPORTS_PIE_CHART_ALLSITE_RISK,
    GET_DPI_REPORTS_PIE_CHART_SINGLESITE_RISK
 } from "../types";
import moment from "moment";
import { getIntervalInFormat } from "./authenticateDashboard";

export const getIntervalInNewFormat = (interval) => {
    switch (interval) {
      case "15m": return "MINUTE,15";
      case "1h": return "HOUR,1";
      case "2h": return "HOUR,2";
      case "6h": return "HOUR,6";
      case "12h": return "HOUR,12";
      case "1d": return "DAY,1";
      case "7d": return "DAY,7";
      case "30d": return "DAY,30";
      default: return "MINUTE,15";
    }
  }
  

export const getAllUsernames = (dp, vessels, startDate, endDate) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_ACTIVITY_REPORTS_LOADING, payload: true });
    const vesselMap: any = [];
     vessels?.map((item: any) => {
    let vesselName = item["id"];
    vesselMap.push(vesselName);
  })  
  let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;
    const userNames : any = {
        query: 'DPI_REPORTS_FILTERS',
        named: true,
        parameters: {
            dp: dp,
            k4Id: (k4idhere && k4idhere !== '') ? `${k4idhere}` : '' ,
            startTime: startDate,
            endTime: endDate
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, userNames)
    .then((res) => handleResponse(res, dispatch, GET_ALL_USERNAMES))
    .catch((err) => handleError(err, dispatch));
    };

export const getAllApplications = (vessels, startDate, endDate) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_ACTIVITY_REPORTS_APPS_LOADING, payload: true });
    const vesselMap: any = [];
     vessels?.map((item: any) => {
    let vesselName = item["id"];
    vesselMap.push(vesselName);
  })  
  let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;
    const apps: any = {
        query: 'DPI_REPORTS_FILTERS_APPVLAN',
        named: true,
        parameters: {
            k4Id: (k4idhere && k4idhere !== '') ? `${k4idhere}` : '',
            startDate: startDate,
            endDate: endDate
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, apps)
    .then((res) => handleResponse(res, dispatch, GET_ALL_APPLICATIONS))
    .catch((err) => handleError(err, dispatch));
    };

export const getAllVlans = (vessels, startDate, endDate) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_ACTIVITY_REPORTS_VLANS_LOADING, payload: true });
    const vesselMap: any = [];
     vessels?.map((item: any) => {
    let vesselName = item["id"];
    vesselMap.push(vesselName);
  })  
  let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;
    const vlans : any = {
        query: 'DPI_REPORTS_FILTERS_VLAN',
        named: true,
        parameters: {
            k4Id: (k4idhere && k4idhere !== '') ? `${k4idhere}` : '' ,
            startDate: startDate,
            endDate: endDate
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, vlans)
    .then((res) => handleResponse(res, dispatch, GET_ALL_VLANS))
    .catch((err) => handleError(err, dispatch));
    };

export const getUsageWidgets = (dp, vessels, startDate, endDate, user, category, appFilt, vlanFilt, deviceFilt, userCheck) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_ACTIVITY_REPORTS_WIDGET_LOADING, payload: true });
    const vesselMap: any = [];
    vessels?.map((item: any) => {
    let vesselName = item["id"];
    vesselMap.push(vesselName);
    })  
    let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;
    const usageWidgets : any = {
        query: 'DPI_REPORTS_TOP_WIDGETS',
        named: true,
        parameters: {
            dp: dp,
            k4Id: (k4idhere && k4idhere !== '') ? `${k4idhere}` : '' ,
            startDate: startDate,
            endDate: endDate,
            user: user && userCheck ? `and ${userCheck}='${user}'` : '',
            category: category ? `and categoryx='${category}'` : '',
            appFilt: appFilt ? `and usageFrom in ('${appFilt}')` : '',
            vlanName: vlanFilt ? `and vlanName = '${vlanFilt}'` : '',
            deviceName: deviceFilt ? `where deviceAliasName like '%${deviceFilt}%'` : ''
        },
    }
    api
    .postRequestOut(`${apiUrl}`, usageWidgets)
    .then((res) => handleResponse(res, dispatch, GET_USAGE_WIDGETS))
    .catch((err) => handleError(err, dispatch));
    };

export const getUsageTableData = (vessels, startDate, endDate, user, category, appFilt, page, limit, vlanFilt, deviceFilt, userCheck, sort, sortOrder) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_ACTIVITY_REPORTS_TOP_USERS_LOADING, payload: true });
    const vesselMap: any = [];
    vessels?.map((item: any) => {
    let vesselName = item["id"];
    vesselMap.push(vesselName);
    })  
    let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;

    const usageTableData : any = {
        query: 'DPI_REPORTS_TOP_USERS_IP_TABLE_SORTING',
        named: true,
        parameters: {
            k4Id: (k4idhere && k4idhere !== '') ? `${k4idhere}` : '' ,
            startDate: startDate,
            endDate: endDate,
            user: user && userCheck ? `and ${userCheck}='${user}'` : '',
            category: category ? `and categoryx='${category}'` : '',
            pageStart: ((page-1)*limit)+1,
            pageLimit: (page*limit),
            appFilt: appFilt ? `and usageFrom in ('${appFilt}')` : '',
            vlanName: vlanFilt ? `and vlanName = '${vlanFilt}'` : '',
            deviceName: deviceFilt ? `where deviceAliasName like '%${deviceFilt}%'` : '',
            sortColumn: sort,
            sortOrder: sortOrder
        },
    }
    api
    .postRequestOut(`${apiUrl}`, usageTableData)
    .then((res) => handleResponse(res, dispatch, GET_USAGE_TABLE_DATA))
    .catch((err) => handleError(err, dispatch));
    };
    
export const getLineChartUsage = (dp, vessels, startDate, endDate, user, category, appFilt, interval, vlanFilt, deviceFilt, userCheck) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_ACTIVITY_REPORTS_USAGE_LINE_CHART_LOADING, payload: true });
    const vesselMap: any = [];
    vessels?.map((item: any) => {
    let vesselName = item["id"];
    vesselMap.push(vesselName);
    })  
    let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;
    const lineChartUsage : any = {
        query: 'DPI_REPORTS_TOP_USAGE_OVER_TIME_LINE_CHART',
        named: true,
        parameters: {
            dp: dp,
            k4Id: (k4idhere && k4idhere !== '') ? `${k4idhere}` : '' ,
            startDate: startDate,
            endDate: endDate,
            user: user && userCheck ? `and ${userCheck}='${user}'` : '',
            category: category ? `and categoryx='${category}'` : '',
            interval: isClickHouse ? getIntervalInFormat(interval): interval,
            appFilt: appFilt ? `and usageFrom in ('${appFilt}')` : '',
            vlanName: vlanFilt ? `and vlanName = '${vlanFilt}'` : '',
            deviceName: deviceFilt ? `where deviceAliasName like '${deviceFilt}'` : ''
        },
        format: 'chart',
        tsColumn: "tb",
        series: "download,total,upload",
        groupBy:'groupHelper'
    }
    api
    .postRequestOut(`${apiUrl}`, lineChartUsage)
    .then((res) => handleResponse(res, dispatch, GET_LINE_CHART_USAGE))
    .catch((err) => handleError(err, dispatch));
    };

export const getCategoryPieChartUsage = (dp, vessels, startDate, endDate, user, category, appFilt, categoryPieLimit, vlanFilt, deviceFilt, userCheck) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_ACTIVITY_REPORTS_CATEGORY_PIE_LOADING, payload: true });
    const vesselMap: any = [];
    vessels?.map((item: any) => {
    let vesselName = item["id"];
    vesselMap.push(vesselName);
    })  
    let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;

    const pieChartCategoryUsage : any = {
        query: 'DPI_REPORTS_CATEGORY_USAGE_PIE_CHART',
        named: true,
        parameters: {
            dp: dp,
            k4Id: (k4idhere && k4idhere !== '') ? `${k4idhere}` : '' ,
            startDate: startDate,
            endDate: endDate,
            user: user && userCheck ? `and ${userCheck}='${user}'` : '',
            category: category ? `and categoryx='${category}'` : '',
            recLimit: categoryPieLimit!=='all' ? `limit ${categoryPieLimit}` : "",
            appFilt: appFilt ? `and usageFrom in ('${appFilt}')` : '',
            vlanName: vlanFilt ? `and vlanName = '${vlanFilt}'` : '',
            deviceName: deviceFilt ? `where deviceAliasName like '%${deviceFilt}%'` : ''
        },
    }
    api
    .postRequestOut(`${apiUrl}`, pieChartCategoryUsage)
    .then((res) => handleResponse(res, dispatch, GET_CATEGORY_PIE_CHART_USAGE))
    .catch((err) => handleError(err, dispatch));
    };

export const getTopApplicationLineChart = (dp, vessels, startDate, endDate, user, category, topAppLimit, interval, application, vlanFilt, deviceFilt, userCheck) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_ACTIVITY_REPORTS_TOP_APP_LINE_CHART_LOADING, payload: true });
    const vesselMap: any = [];
    vessels?.map((item: any) => {
    let vesselName = item["id"];
    vesselMap.push(vesselName);
    })  
    let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;

    const topApplicationUsage : any = {
        query: 'DPI_REPORTS_TOP10_APPS_LINECHART',
        named: true,
        parameters: {
            dp: dp,
            k4Id: (k4idhere && k4idhere !== '') ? `${k4idhere}` : '' ,
            startDate: startDate,
            endDate: endDate,
            user: user && userCheck ? `and ${userCheck}='${user}'` : '',
            category: category ? `and categoryx='${category}'` : '',
            interval: isClickHouse? getIntervalInFormat(interval):interval,
            recLimit: topAppLimit!=='all' ? `limit ${topAppLimit}` : '',
            appFilt: application ? `and usageFrom in ('${application}')` : '',
            vlanName: vlanFilt ? `and vlanName = '${vlanFilt}'` : '',
            deviceName: deviceFilt ? `where deviceAliasName like '%${deviceFilt}%'` : ''
        },
        groupBy: "application",
        format: "chart",
        tsColumn: "timeBand",
        series: "total"
    }
    api
    .postRequestOut(`${apiUrl}`, topApplicationUsage)
    .then((res) => handleResponse(res, dispatch, GET_TOP_APPLICATION_LINE_CHART))
    .catch((err) => handleError(err, dispatch));
    };

export const getTopSitesTableData = (vessels, startDate, endDate, user, category, appFilt, pageStart, pageLimit, vlanFilt, deviceFilt, userCheck, sort, sortOrder) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_ACTIVITY_REPORTS_TOP_SITES_LOADING, payload: true });
    const vesselMap: any = [];
    vessels?.map((item: any) => {
    let vesselName = item["id"];
    vesselMap.push(vesselName);
    })  
    let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;

    const topSitesData : any = {
        query: 'DPI_REPORTS_TOP_USAGE_BY_SITES_TABLE_SORTING',
        named: true,
        parameters: {
            k4Id: (k4idhere && k4idhere !== '') ? `${k4idhere}` : '' ,
            startDate: startDate,
            endDate: endDate,
            user: user && userCheck ? `and ${userCheck}='${user}'` : '',
            category: category ? `and categoryx='${category}'` : '',
            pageStart: ((pageStart-1)*pageLimit)+1,
            pageLimit: (pageStart*pageLimit),
            appFilt: appFilt ? `and usageFrom in ('${appFilt}')` : '',
            vlanName: vlanFilt ? `and vlanName = '${vlanFilt}'` : '',
            deviceName: deviceFilt ? `where deviceAliasName like '%${deviceFilt}%'` : '',
            sortColumn: sort,
            sortOrder: sortOrder
        },
    }
    api
    .postRequestOut(`${apiUrl}`, topSitesData)
    .then((res) => handleResponse(res, dispatch, GET_TOP_SITES_TABLE_DATA))
    .catch((err) => handleError(err, dispatch));
    };

export const getTopAppTopBlockedPieChart = (dp, vessels, startDate, endDate, user, category, appFilt, pieChartLimit, vlanFilt, deviceFilt, userCheck) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_ACTIVITY_REPORTS_APPLICATION_PIE_LOADING, payload: true });
    const vesselMap: any = [];
    vessels?.map((item: any) => {
    let vesselName = item["id"];
    vesselMap.push(vesselName);
    })  
    let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;

    const combinedPieChartdata : any = {
        query: 'DPI_REPORTS_TOP_APP_BLOCKED_PIE_CHART',
        named: true,
        parameters: {
            dp: dp,
            k4Id: (k4idhere && k4idhere !== '') ? `${k4idhere}` : '' ,
            startDate: startDate,
            endDate: endDate,
            user: user && userCheck ? `and ${userCheck}='${user}'` : '',
            category: category ? `and categoryx='${category}'` : '',
            recLimit : pieChartLimit!=='all' ? `where rn <= ${pieChartLimit}` : '',
            appFilt: appFilt ? `and usageFrom in ('${appFilt}')` : '',
            vlanName: vlanFilt ? `and vlanName = '${vlanFilt}'` : '',
            deviceName: deviceFilt ? `where deviceAliasName like '%${deviceFilt}%'` : ''
        },
    }
    api
    .postRequestOut(`${apiUrl}`, combinedPieChartdata)
    .then((res) => handleResponse(res, dispatch, GET_TOP_APP_TOP_BLOCKED_PIE_CHART))
    .catch((err) => handleError(err, dispatch));
    };

export const getTopDownloadUploadBlockedApplication = (dp, vessels, startDate, endDate, user, category, appFilt, reportType, bottomTableLimit, vlanFilt, deviceFilt, userCheck) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_ACTIVITY_REPORTS_BOTTOM_TABLE_LOADING, payload: true });
    const vesselMap: any = [];
    vessels?.map((item: any) => {
    let vesselName = item["id"];
    vesselMap.push(vesselName);
    })  
    let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;

    const appDetails : any = {
        query: 'DPI_REP_TOP_DOWNLOAD_UPLOAD_BLOCKED_APPLICATION',
        named: true,
        groupBy:"dataType", 
        format:"csv",
        parameters: {
            dp: dp,
            k4Id: (k4idhere && k4idhere !== '') ? `${k4idhere}` : '' ,
            startDate: startDate,
            endDate: endDate,
            user: user && userCheck ? `and ${userCheck}='${user}'` : '',
            category: category ? `and categoryx='${category}'` : '',
            reportType: reportType,
            recLimit: bottomTableLimit!=='all' ? `where rn <= ${bottomTableLimit}` : '',
            appFilt: appFilt ? `and appname in ('${appFilt}')` : '',
            vlanName: vlanFilt ? `and vlanName = '${vlanFilt}'` : '',
            deviceName: deviceFilt ? `where deviceAliasName like '%${deviceFilt}%'` : ''
        },
    }
    api
    .postRequestOut(`${apiUrl}`, appDetails)
    .then((res) => handleResponse(res, dispatch, GET_TOP_DOWNLOAD_UPLOAD_BLOCKED_APPLICATION))
    .catch((err) => handleError(err, dispatch));
    };

export const getTopDownloadUploadBlockedDomain = (dp, vessels, startDate, endDate, user, category, appFilt, reportType, bottomTableLimit, vlanFilt, deviceFilt, userCheck) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_ACTIVITY_REPORTS_BOTTOM_TABLE_DOMAIN_LOADING, payload: true });
    const vesselMap: any = [];
    vessels?.map((item: any) => {
    let vesselName = item["id"];
    vesselMap.push(vesselName);
    })  
    let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;

    const appDetails : any = {
        query: 'DPI_REP_TOP_DOWNLOAD_UPLOAD_BLOCKED_APPLICATION',
        named: true,
        groupBy:"dataType",
        format:"csv",
        parameters: {
            dp: dp,
            k4Id: (k4idhere && k4idhere !== '') ? `${k4idhere}` : '' ,
            startDate: startDate,
            endDate: endDate,
            user: user && userCheck ? `and ${userCheck}='${user}'` : '',
            category: category ? `and categoryx='${category}'` : '',
            reportType: reportType,
            recLimit: bottomTableLimit!=='all' ? `where rn <= ${bottomTableLimit}` : '',
            appFilt: appFilt ? `and appname in ('${appFilt}')` : '',
            vlanName: vlanFilt ? `and vlanName = '${vlanFilt}'` : '',
            deviceName: deviceFilt ? `where deviceAliasName like '%${deviceFilt}%'` : ''
        },
    }
    api
    .postRequestOut(`${apiUrl}`, appDetails)
    .then((res) => handleResponse(res, dispatch, GET_TOP_DOWNLOAD_UPLOAD_BLOCKED_DOMAIN))
    .catch((err) => handleError(err, dispatch));
    };

export const getTopDownloadUploadBlockedIp = (dp, vessels, startDate, endDate, user, category, appFilt, reportType, bottomTableLimit, vlanFilt, deviceFilt, userCheck) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_ACTIVITY_REPORTS_BOTTOM_TABLE_IP_LOADING, payload: true });
    const vesselMap: any = [];
    vessels?.map((item: any) => {
    let vesselName = item["id"];
    vesselMap.push(vesselName);
    })  
    let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;

    const appDetails : any = {
        query: 'DPI_REP_TOP_DOWNLOAD_UPLOAD_BLOCKED_APPLICATION',
        named: true,
        groupBy:"dataType",
        format:"csv",
        parameters: {
            dp: dp,
            k4Id: (k4idhere && k4idhere !== '') ? `${k4idhere}` : '' ,
            startDate: startDate,
            endDate: endDate,
            user: user && userCheck ? `and ${userCheck}='${user}'` : '',
            category: category ? `and categoryx='${category}'` : '',
            reportType: reportType,
            recLimit: bottomTableLimit!=='all' ? `where rn <= ${bottomTableLimit}` : '',
            appFilt: appFilt ? `and appname in ('${appFilt}')` : '',
            vlanName: vlanFilt ? `and vlanName = '${vlanFilt}'` : '',
            deviceName: deviceFilt ? `where deviceAliasName like '%${deviceFilt}%'` : ''
        },
    }
    api
    .postRequestOut(`${apiUrl}`, appDetails)
    .then((res) => handleResponse(res, dispatch, GET_TOP_DOWNLOAD_UPLOAD_BLOCKED_IP))
    .catch((err) => handleError(err, dispatch));
    };

export const getContentFiltersTable = (vessels, startDate, endDate, recLimit) => (dispatch) => {
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_ACTIVITY_REPORTS_BOTTOM_TABLE_LOADING, payload: true });
    const contentFilters : any = {
        query: 'ACTIVITY_REPORTS_CONTENT_FILTERS_TABLE',
        named: true,
        groupBy:"application",
        format:"csv",
        parameters: {
            location_id: vessels ? vessels.map((vs) => `'${vs.id}'`).join(',') : '' ,
            startTime: startDate,
            endTime: endDate,
            recLimit: recLimit !== 'all' ? `where rn<=${recLimit}` : ''
        },
    }
    api
    .postRequestOut(`${apiUrl}`, contentFilters)
    .then((res) => handleResponse(res, dispatch, GET_ACTIVITY_REPORTS_CONTENT_FILTERS_TABLE))
    .catch((err) => handleError(err, dispatch));
};
export const createDownloadService = (dpId, payload) => (dispatch) => {
    // dispatch({ type: SET_ACTIVITY_REPORTS_LOADING, payload: true });
    api.siteController
        .post(`/v1/organizations/${dpId}/reports/activity`, payload)
        .then((res) => handleResponse(res, dispatch, CREATE_DOWNLOAD_SERVICE))
        .catch((err) => handleError(err, dispatch));
};

export const deleteGeneratedReport = (dpId: string, id: string) => async (dispatch) => {
    dispatch({ type: SET_GENERATED_REPORTS_TABLE_LOADING, payload: true })
    try {
        let res = await api.siteController.delete(`/v1/organizations/${dpId}/reports/${id}`);
        dispatch({ type: SET_GENERATED_REPORTS_TABLE_LOADING, payload: true });
        return res;
    } catch (error) {
        dispatch({ type: SET_GENERATED_REPORTS_TABLE_LOADING, payload: true });
        handleError(error, dispatch)
        return null;
    }
};

export interface getGeneratedReportsParams {
    dpId: string;
    page: number;
    size: number;
    search: string;
    sort_by: string;
    sort_order: string;
}

export const getGeneratedReports = (parameters: getGeneratedReportsParams) => async (dispatch) => {
    const { dpId, page, size, search, sort_by, sort_order } = parameters;

    dispatch({ type: SET_GENERATED_REPORTS_TABLE_LOADING, payload: true })
    try {
        let res = await api.siteController.get(`/v1/organizations/${dpId}/reports?page=${page}&size=${size}&search=${search}&sort_by=${sort_by}&sort_order=${sort_order}`);
        dispatch({ type: SET_GENERATED_REPORTS_TABLE_LOADING, payload: false });
        handleResponseObj(res, dispatch, GET_GENERATED_REPORTS_TABLE)
    } catch (error) {
        dispatch({ type: SET_GENERATED_REPORTS_TABLE_LOADING, payload: false });
        handleError(error, dispatch)
        return null;
    }
};

export interface getReportDownloadURLParams {
    dpId: string;
    id: string;
    detailed_report: boolean;
}

export const getGeneretedReportDownloadURL = (parameters: getReportDownloadURLParams) => async (dispatch) => {
    const { dpId, id, detailed_report } = parameters;

    dispatch({ type: SET_GENERATED_REPORTS_TABLE_LOADING, payload: true })
    try {
        let res = await api.siteController.get(`/v1/organizations/${dpId}/reports/${id}/download${detailed_report ? '?detailed_report=true' : ''}`);
        dispatch({ type: SET_GENERATED_REPORTS_TABLE_LOADING, payload: false });
        return res;
    } catch (error) {
        dispatch({ type: SET_GENERATED_REPORTS_TABLE_LOADING, payload: false });
        handleError(error, dispatch)
        return null;
    }
};

export interface getOperationalReportsCountParams {
    vessels: any;
    device_id: string;
    software_version: string;
    start_time: string;
    end_time: string;
}
export const getOperationalReportsCount = (parameters: getOperationalReportsCountParams) => async (dispatch) => {
    const { vessels, device_id,software_version, start_time, end_time } = parameters;
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({type: SET_OPERATIONAL_USAGE_DATA_DUMP_TABLE_COUNT_LOADING, payload: true})
    const OperationalTableCount : any = {
        query: "OPERATIONAL_USAGE_DATA_DUMP_TABLE_COUNT_V2",
        named: true,
        format: "csv",
        parameters: {
            location_id : vessels && vessels.length > 0 ? `${vessels.map((item: any) => `'${item.id}'`).join(",")}` : '',
            device_id : device_id,
            software_version : software_version, 
            start_time : start_time, 
            end_time : end_time
        }
    }
    api
    .postRequestOut(`${apiUrl}`, OperationalTableCount)
    .then((res) => handleResponse(res, dispatch, GET_OPERATIONAL_USAGE_DATA_DUMP_TABLE_COUNT))
    .catch((err) => handleError(err, dispatch));
}

export interface getOperationalReportsParams {
    vessels: any;
    device_id : string;
    software_version : string;
    start_time: string;
    end_time: string;
    sort_column: string;
    sort_order: string;
    page_offset: string;
    page_size: string;
}

export const getOperationalReports = (parameters: getOperationalReportsParams) => async (dispatch) => {
    const { vessels, device_id, software_version, start_time, end_time, sort_column, sort_order, page_offset, page_size } = parameters;
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({type: SET_OPERATIONAL_USAGE_DATA_DUMP_TABLE_LOADING, payload: true})
    const OperationalTable : any = {
        query: 'OPERATIONAL_USAGE_DATA_DUMP_TABLE_V2',
        named: true,
        format:"csv",
        parameters: {
            location_id : vessels && vessels.length > 0 ? `${vessels.map((item: any) => `'${item.id}'`).join(",")}` : '',
            device_id : device_id,
            software_version : software_version,
            start_time : start_time,
            end_time : end_time,
            sort_column : sort_column,
            sort_order : sort_order,
            page_offset : page_offset,
            page_size : page_size,
        }
    }
    api
    .postRequestOut(`${apiUrl}`, OperationalTable)
    .then((res) => handleResponse(res, dispatch, GET_OPERATIONAL_USAGE_DATA_DUMP_TABLE))
    .catch((err) => handleError(err, dispatch));  
};

export interface getOperationalUsageTimeseriesTableParams {
    vessels: string,
    start_time: string,
    end_time: string,
    sort_column:string,
    sort_order:string,
    page_offset:string,
    page_size:string,
    interface_name:string
}
export const getOperationalUsageTimeseriesTable = (parameters: getOperationalUsageTimeseriesTableParams) => async (dispatch) => {
    const { vessels, start_time, end_time, sort_column, sort_order, page_offset, page_size, interface_name } = parameters;
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({type: SET_OPERATIONAL_USAGE_TIMESERIES_TABLE_LOADING, payload: true})
    const OperationalTable : any = {
        query: 'OPERATIONAL_USAGE_TIMESERIES_TABLE',
        named: true,
        format:"csv",
        parameters: {
            location_id : vessels,
            start_time : start_time,
            end_time : end_time,
            sort_column : sort_column,
            sort_order : sort_order,
            page_offset : page_offset,
            page_size : page_size,
            interface_name: interface_name
        }
    }
    api
    .postRequestOut(`${apiUrl}`, OperationalTable)
    .then((res) => handleResponse(res, dispatch, GET_OPERATIONAL_USAGE_TIMESERIES_TABLE))
    .catch((err) => handleError(err, dispatch));
};

export interface getOperationalUsageTimeseriesTableCountParams {
    vessels: string,
    start_time: string,
    end_time: string,
    interface_name:string
}

export const getOperationalUsageTimeseriesTableCount = (parameters: getOperationalUsageTimeseriesTableCountParams) => async (dispatch) => {
    const { vessels, start_time, end_time, interface_name } = parameters;
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({type: SET_OPERATIONAL_USAGE_TIMESERIES_TABLE_COUNT_LOADING, payload: true})
    const OperationalTable : any = {
        query: "OPERATIONAL_USAGE_TIMESERIES_TABLE_COUNT",
        named: true,
        format:"csv",
        parameters: {
            location_id : vessels,
            start_time : start_time,
            end_time : end_time,
            interface_name: interface_name
        }
    }
    api
    .postRequestOut(`${apiUrl}`, OperationalTable)
    .then((res) => handleResponse(res, dispatch, GET_OPERATIONAL_USAGE_TIMESERIES_TABLE_COUNT))
    .catch((err) => handleError(err, dispatch));
};

export interface getOperationalUsageDataSingleSitePerWanUsageParams {
    vessels: string,
    start_time: string,
    end_time: string
}

export const getOperationalUsageDataSingleSitePerWanUsage = (parameters: getOperationalUsageDataSingleSitePerWanUsageParams) => async (dispatch) => {
    const { vessels, start_time, end_time } = parameters;
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({type: SET_OPERATIONAL_USAGE_DATA_SINGLE_SITE_PER_WAN_WISE_LOADING, payload: true})
    const OperationalTable : any = {
        query: "OPERATIONAL_USAGE_DATA_SINGLE_SITE_PER_WAN_WISE",
        named: true,
        format:"csv",
        parameters: {
            location_id : vessels,
            start_time : start_time,
            end_time : end_time
        }
    }
    api
    .postRequestOut(`${apiUrl}`, OperationalTable)
    .then((res) => handleResponse(res, dispatch, GET_OPERATIONAL_USAGE_DATA_SINGLE_SITE_PER_WAN_WISE))
    .catch((err) => handleError(err, dispatch));
};

export interface getOperationalUsageFilterDeviceListParams {
    vessels: any,
    device_id: string,
    start_time: string,
    end_time: string
}
export const getOperationalUsageFilterDeviceList = (parameters: getOperationalUsageFilterDeviceListParams) => async (dispatch) => {
    const {vessels, device_id, start_time, end_time} = parameters;
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({type: SET_OPERATIONAL_USAGE_FILTER_DEVICE_LIST_LOADING, payload: true})
    const OperationalTable : any = {
        query: "OPERATIONAL_USAGE_FILTER_DEVICE_LIST_V2",
        named: true,
        format: "csv",
        parameters: {
            location_id: vessels && vessels.length > 0 ? `${vessels.map((item: any) => `'${item.id}'`).join(",")}` : '',
            device_id: device_id, 
            start_time: start_time,
            end_time: end_time
        }
    }
    api
    .postRequestOut(`${apiUrl}`, OperationalTable)
    .then((res) => handleResponse(res, dispatch, GET_OPERATIONAL_USAGE_FILTER_DEVICE_LIST))
    .catch((err) => handleError(err, dispatch));
};

export interface getOperationalUsageFilterSWListParams {
    vessels: any,
    device: string,
    start_time: string,
    end_time: string
}
export const getOperationalUsageFilterSWList = (parameters: getOperationalUsageFilterSWListParams) => async (dispatch) => {
    const {vessels, device, start_time, end_time} = parameters;
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({type: SET_OPERATIONAL_USAGE_FILTER_SW_LIST_LOADING, payload: true})
    const OperationalTable : any = {
        query: "OPERATIONAL_USAGE_FILTER_SW_LIST",
        named: true,
        format: "csv",
        parameters: {
            location_id: vessels && vessels.length > 0 ? `${vessels.map((item: any) => `'${item.id}'`).join(",")}` : '',
            device_id : device,
            start_time: start_time,
            end_time: end_time
        }
    }
    api
    .postRequestOut(`${apiUrl}`, OperationalTable)
    .then((res) => handleResponse(res, dispatch, GET_OPERATIONAL_USAGE_FILTER_SW_LIST))
    .catch((err) => handleError(err, dispatch));
};

export interface getOperationalUsageReportsDownloadParams {
    vessels: any;
    device_id: string,
    software_version: string,
    start_time: string,
    end_time: string,
    sort_column:string,
    sort_order:string,
    page_offset:string,
    page_size:string
}
export const getOperationalUsageReportsDownload = (parameters: getOperationalUsageReportsDownloadParams) => async (dispatch) => {
    const { vessels, device_id, software_version, start_time, end_time, sort_column, sort_order, page_offset, page_size } = parameters;
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({type: SET_OPERATIONAL_USAGE_DATA_DUMP_DOWNLOAD_TABLE_LOADING, payload: true})
    const OperationalTable : any = {
        query: 'OPERATIONAL_USAGE_DATA_DUMP_DOWNLOAD_TABLE',
        named: true,
        format:"csv",
        parameters: {
            location_id : vessels && vessels.length > 0 ? `${vessels.map((item: any) => `'${item.id}'`).join(",")}` : '',
            device_id : device_id,
            software_version : software_version,
            start_time : start_time,
            end_time : end_time,
            sort_column : sort_column,
            sort_order : sort_order,
            page_offset : page_offset,
            page_size : page_size,
        }
    }
    api
    .postRequestOut(`${apiUrl}`, OperationalTable)
    .then((res) => handleResponse(res, dispatch, GET_OPERATIONAL_USAGE_DATA_DUMP_DOWNLOAD_TABLE))
    .catch((err) => handleError(err, dispatch));
}

export const getUserMaxRiskList = (vessel, startDate, endDate, macAddress) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_LOADING, payload: true });
    const tableRiskScore : any = {
        query: 'DPI_REPORTS_USER_MAX_RISK_TYPES',
        named: true,
        parameters: {
            k4Id: vessel,
            macaddress: macAddress,
            startDate: startDate,
            endDate: endDate
        }
    }
    api
    .postRequestOut(`${apiUrl}`, tableRiskScore)
    .then((res) => handleResponse(res, dispatch, GET_DPI_REPORTS_USER_MAX_RISK_TYPES))
    .catch((err) => handleError(err, dispatch));
};

export const getTopWidgetRiskScore = (vessels, startDate, endDate, user, userCheck) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_DPI_REPORTS_TOP_WIDGET_ALLSITE_RISK, payload: true });
    const widgetRiskScore : any = {
        query: 'DPI_REPORTS_TOP_WIDGET_ALLSITE_RISK',
        named: true,
        parameters: {
            k4Id: vessels?.map((site) => `'${site.id}'`).join(','),
            identifier_type: userCheck == 'ipAddress' ? 'local_ip' : 'local_mac',
            identifier: user ? `and identifier='${user}'` : '',
            startDate: startDate,
            endDate: endDate
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, widgetRiskScore)
    .then((res) => handleResponse(res, dispatch, GET_DPI_REPORTS_TOP_WIDGET_ALLSITE_RISK))
    .catch((err) => handleError(err, dispatch));
};

export const getTopWidgetSingleSiteRiskScore = (vessel, startDate, endDate, user, userCheck) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_DPI_REPORTS_TOP_WIDGET_ALLSITE_RISK, payload: true });
    const widgetRiskScorePerSite : any = {
        query: 'DPI_REPORTS_TOP_WIDGET_SINGLESITE_RISK',
        named: true,
        parameters: {
            k4Id: vessel[0]?.id,
            identifier_type: userCheck == 'ipAddress' ? 'local_ip' : 'local_mac',
            identifier: user ? `and identifier='${user}'` : '',
            startDate: startDate,
            endDate: endDate
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, widgetRiskScorePerSite)
    .then((res) => handleResponse(res, dispatch, GET_DPI_REPORTS_TOP_WIDGET_SINGLESITE_RISK))
    .catch((err) => handleError(err, dispatch));
};

export const getPieChartAllSiteRiskScore = (vessels, startDate, endDate, user, rec_limit, userCheck) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_DPI_REPORTS_PIE_CHART_ALLSITE_RISK, payload: true });
    const pieRiskScore : any = {
        query: 'DPI_REPORTS_PIE_CHART_ALLSITE_RISK',
        named: true,
        parameters: {
            k4Id: vessels?.map((site) => `'${site.id}'`).join(','),
            identifier_type: userCheck == 'ipAddress' ? 'local_ip' : 'local_mac',
            identifier: user ? `and identifier='${user}'` : '',
            startDate: startDate,
            endDate: endDate,
            recLimit: rec_limit ? `limit ${rec_limit}` : 10
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, pieRiskScore)
    .then((res) => handleResponse(res, dispatch, GET_DPI_REPORTS_PIE_CHART_ALLSITE_RISK))
    .catch((err) => handleError(err, dispatch));
};

export const getPieChartSingleSiteRiskScore = (vessel, startDate, endDate, user, rec_limit, userCheck) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_DPI_REPORTS_PIE_CHART_ALLSITE_RISK, payload: true });
    const pieRiskScorePerSite : any = {
        query: 'DPI_REPORTS_PIE_CHART_SINGLESITE_RISK',
        named: true,
        parameters: {
            k4Id: vessel[0]?.id,
            identifier_type: userCheck == 'ipAddress' ? 'local_ip' : 'local_mac',
            identifier: user ? `and identifier='${user}'` : '',
            startDate: startDate,
            endDate: endDate,
            recLimit: rec_limit ? `limit ${rec_limit}` : 10
        },
        format: 'csv'
    }
    api
    .postRequestOut(`${apiUrl}`, pieRiskScorePerSite)
    .then((res) => handleResponse(res, dispatch, GET_DPI_REPORTS_PIE_CHART_SINGLESITE_RISK))
    .catch((err) => handleError(err, dispatch));
};

