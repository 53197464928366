import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { getCategoryPieChartUsage, getTopAppTopBlockedPieChart } from "../../../actions/Users/authenticateUsageReports";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import UsagePieChart from "../../UsagePieChart";

const CategoryPieChart = (props) => {
    const { CategoryPieChartUsageData, getVesselsListing, categoryPieLimit, handleChangeCategoryPieLimit, categoryIcons } = props;
    const [pieChartCategory, setPieChartCategory] = useState([]);

    useEffect(() => {
        if (getVesselsListing?.locations && getVesselsListing?.locations?.length == 0) {
            setPieChartCategory([])
            return
        }
        if (!_.isEmpty(CategoryPieChartUsageData)) {
            const data = CategoryPieChartUsageData?.hasOwnProperty('data') ? CategoryPieChartUsageData.data : [];
            let arr: any = []
            data?.category?.data?.forEach((el, i) => {
                let total = Number(((data?.total?.data[i]) / (1024 * 1024 * 1024)).toFixed(1));
                arr.push({ x: el, y: data?.percentage?.data[i], z: total, type: 'category' })
            })
            setPieChartCategory(arr)
        }
    }, [CategoryPieChartUsageData,getVesselsListing])

    return <>
        <Grid item xs={12} sm={6} md={6} lg={3}>
            <UsagePieChart pieChartUsage={pieChartCategory} title="Top Categories" name="Usage" unit="GB" categoryIcons={categoryIcons}/>
        </Grid>
    </>

}


const mapStateToProps = (state) => ({
    CategoryPieChartUsageData: state.authReducer?.CategoryPieChartUsageData,
    getVesselsListing: state?.authReducer?.getVesselsListing
});

export default withRouter(
    connect(mapStateToProps, {
    })(CategoryPieChart)
);