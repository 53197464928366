import { Dispatch } from "react";
import { api } from "../../server/request";
import { handleError } from "../responseUtil";
import { GET_CSV_DATA_TO_DOWNLOAD, SET_LOADING } from "../types";
import { GET_ERRORS } from "../error_types";

export interface CsvPayload {
    dp: string,
    k4Id: string,
    search: string,
    endTime: string,
    startTime: string,
    wanType: string,
    pageStart: number,
    pageLimit: number,
    sortColumn: boolean,
    sortOrder: boolean
}

export const escapeCommasCsvField = (value: string) => {
    // Escape commas with a backslash
    return `"${value.replace(/,/g, '\\,')}"`;
}

interface DownloadCsvParameters {
    successHandler?: string;
    signal?: AbortSignal;
    id?: string;
}

interface DownloadQueryCsvParameters extends DownloadCsvParameters {
    type: 'QUERY' | 'QUERY_CH' | 'QUERY_PG';
    queryName: string;
    payload: any;
}

interface DownloadPostAPICsvParameters extends DownloadCsvParameters {
    type: 'POST_API' | 'POST_API_SITE_CONTROLLER' | 'POST_API_EXTERNAL';
    endpoint: string;
    payload: any;
}

interface DownloadGetAPICsvParameters extends DownloadCsvParameters {
    type: 'GET_API' | 'GET_API_EXTERNAL';
    endpoint: string;
}

export type CsvParameters = DownloadPostAPICsvParameters | DownloadQueryCsvParameters | DownloadGetAPICsvParameters;

export const downloadAsCSV = (parameters: CsvParameters) => async (dispatch) => {
    const { successHandler = SET_LOADING, id } = parameters;
    dispatch({ type: successHandler, payload: true })
    switch (parameters.type) {
        case "QUERY":
            api.postRequestOut("/store/ss/query", {
                query: parameters.queryName,
                named: true,
                parameters: parameters.payload,
                format: 'csv'
            }, parameters.signal)
                .then(res => handleCsvResponse(res, dispatch, parameters))
                .catch(error => handleError(error, dispatch))
                .finally(() => dispatch({ type: successHandler, payload: false }))
            break;
        case "QUERY_CH":
            api.postRequestOut("/store/ch/query", {
                query: parameters.queryName,
                named: true,
                parameters: parameters.payload,
                format: 'csv'
            }, parameters.signal)
                .then(res => handleCsvResponse(res, dispatch, parameters))
                .catch(error => handleError(error, dispatch))
                .finally(() => dispatch({ type: successHandler, payload: false }))
            break;
        case "QUERY_PG":
            api.postRequestOut("/store/pg/query", {
                query: parameters.queryName,
                named: true,
                parameters: parameters.payload,
                format: 'csv'
            }, parameters.signal)
                .then(res => handleCsvResponse(res, dispatch, parameters))
                .catch(error => handleError(error, dispatch))
                .finally(() => dispatch({ type: successHandler, payload: false }))
            break;
        case "POST_API":
            api.postRequestOut(parameters.endpoint, parameters.payload, parameters.signal)
                .then(res => handlePostResponse(res, dispatch, id))
                .catch(error => handleError(error, dispatch))
                .finally(() => dispatch({ type: successHandler, payload: false }))
            break;
        case "POST_API_EXTERNAL":
            api.postRequestForExternalApi(parameters.endpoint, parameters.payload)
                .then(res => handlePostResponseExternal(res, dispatch, id))
                .catch(error => handleError(error, dispatch))
                .finally(() => dispatch({ type: successHandler, payload: false }))
            break; 
        case "POST_API_SITE_CONTROLLER":
            api.siteController.post(parameters.endpoint, parameters.payload)
                .then(res => handleSiteResponse(res, dispatch, id))
                .catch(error => handleError(error, dispatch))
                .finally(() => dispatch({ type: successHandler, payload: false }))
            break;
        case 'GET_API':
            api
                .getRequest(parameters.endpoint)
                .then((res) => handleGetResponse(res, dispatch, id))
                .catch((err) => handleError(err, dispatch))
                .finally(() => dispatch({ type: successHandler, payload: false }));
            break;
        case 'GET_API_EXTERNAL':
            api
                .getRequestForExternalApi(parameters.endpoint)
                .then((res) => handleExternalResponse(res, dispatch, id))
                .catch((err) => handleError(err, dispatch))
                .finally(() => dispatch({ type: successHandler, payload: false }));
            break;
    }
}

const convertQueryDataToCsv = (data: any) => {
    if (!data) return [];
    const rows: any[][] = data.rows || [];
    if(rows.length === 0) return [];
    const columns: string[] = data.columns || [];
    return [columns, ...rows];
}

const convertExternalDataToCsv = (data: any) => {
    if (!data || !data.filteredRecords || !data.filteredRecords.length) return [];
    const filteredRecords = data.filteredRecords;
    const keys = Object.keys(filteredRecords[0]);
    const rows = filteredRecords.map(record => Object.values(record));
    return [keys, ...rows];
}

const convertSiteDataToCsv = (data: any[]) => {
    if (!data || !data.length) return [];
    const keys = Object.keys(data[0]);
    const rows = data.map(record => Object.values(record));
    return [keys, ...rows];
}

const handleCsvResponse = (res: any, dispatch: Dispatch<any>, parameters: CsvParameters) => {
    if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
        dispatch({ type: GET_CSV_DATA_TO_DOWNLOAD, payload: convertQueryDataToCsv(res.data?.data), id: parameters.id });
    } else {
        dispatch({ type: GET_ERRORS, payload: res })
    }
}

const handleSiteResponse = (res: any, dispatch: Dispatch<any>, id?: string) => {
    if (res && res.success) {
        dispatch({ type: GET_CSV_DATA_TO_DOWNLOAD, payload: convertSiteDataToCsv(res.result), id });
    } else {
        dispatch({ type: GET_ERRORS, payload: res })
    }
}

const handleGetResponse = (res: any, dispatch: Dispatch<any>, id?: string) => {
    if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
        dispatch({ type: GET_CSV_DATA_TO_DOWNLOAD, payload: res.data?.data?.records, id });
    } else {
        dispatch({ type: GET_ERRORS, payload: res })
    }
}

const handlePostResponse = (res: any, dispatch: Dispatch<any>, id?: string) => {
    if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
        dispatch({ type: GET_CSV_DATA_TO_DOWNLOAD, payload: res.data, id });
    } else {
        dispatch({ type: GET_ERRORS, payload: res })
    }
}

const handlePostResponseExternal = (res: any, dispatch: Dispatch<any>, id?: string) => {
    if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
        dispatch({ type: GET_CSV_DATA_TO_DOWNLOAD, payload: res.data?.data?.tickets, id });
    } else {
        dispatch({ type: GET_ERRORS, payload: res })
    }
}

const handleExternalResponse = (res: any, dispatch: Dispatch<any>, id?: string) => {
    if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
        dispatch({ type: GET_CSV_DATA_TO_DOWNLOAD, payload: convertExternalDataToCsv(res.data?.data), id });
    } else {
        dispatch({ type: GET_ERRORS, payload: res })
    }
}