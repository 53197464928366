import { useLayoutEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Box, IconButton, Modal, Paper, SxProps, Theme } from "@mui/material";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'
import './ReleaseNote.css';
import { MAINTENANCE } from "../../constants/Constants";
import { getDataFromLocalStorage } from "../../utils/util";

const style: SxProps<Theme> = {
    position: 'absolute',
    top: '5%',
    // bottom: '10%',
    right: '3%',
    width: '30%',
    // bgcolor: 'background.paper',
    // border: '1px solid rgba(126, 126, 126, 0.8)',
};

const headerStyle: SxProps<Theme> = {
    position: 'sticky',
    top: 0,
    background: '#2860B4',
    color: '#fff',
    fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #D9D9D9',
    padding: '5px 10px 5px 24px'
}


const Maintainence = (props: any) => {
    const [releaseNotes, setReleaseNotes] = useState<string>('');
    const [open, setOpen] = useState((getDataFromLocalStorage("ISBANNER") === "true") ? true : false);

    const onClose = () => {
        setOpen(false);
    }

    return (
        <div>
            {
                getDataFromLocalStorage(MAINTENANCE)
                ?
                [
                    <IconButton
                        onMouseEnter={() => setOpen(true)}
                        sx={{padding: '0.16px 4px'}}
                    >
                        <WarningAmberIcon className="releaseNoteIcon" style={{color:"orange"}} />
                    </IconButton>,
                    <Modal open={open} disableScrollLock disableAutoFocus>
                        <Paper sx={style}>
                            <Box sx={headerStyle}>
                            <h5>{getDataFromLocalStorage("ISBANNER") === "true"  ? "Information" : "Maintenance: "} <span className="MaintainenceNote">{getDataFromLocalStorage(MAINTENANCE)}</span></h5>
                                <IconButton onClick={onClose}>
                                    <CloseIcon className="color-white"/>
                                </IconButton>
                            </Box>
                        </Paper>
                    </Modal>
                ]
                :<></>
            }
        </div>
    );
};

export default withRouter(
    (Maintainence)
);