import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import close from "../../asset/image/close.svg";
import AutoCompleteComponent from "../InventoryConfig/DefaultConfig/AutoComplete";
import _ from "lodash"
import "./UploadConfiguration.css"
import { useState } from "react";

export const UploadConfiguration = (props) => {
    const { authReducer, uploadConfigurationPopup, setUploadConfigurationPopup, selectedRow } = props;
    const [isGoldenConfigApplied, setIsGoldenConfigApplied] = useState(true);

    const handleGoldenConfigApply = (e) => {
        if (e.target.value == 'Yes') {
            setIsGoldenConfigApplied(true);
        } else {
            setIsGoldenConfigApplied(false);
        }
    }

    const closeDialog = () => {
        // setSelectedAction("")
        setIsGoldenConfigApplied(true)
        setUploadConfigurationPopup(false)
    }

    return (
        uploadConfigurationPopup
            ?
            <Dialog
                open={true}
                onClose={() => { closeDialog() }}
                aria-labelledby='form-dialog-title'
                // miWidth={'md'}
                className='create-default-config-container group-actions-tab-actions-popup'
            >
                <div>
                    <DialogTitle className='create-default-config-title'>
                        <span>Upload Configuration</span>
                        <img src={close} title="Close" onClick={() => { closeDialog() }} />
                    </DialogTitle>
                    <DialogContent className='create-default-config-content'>
                        <Grid container>
                            <Grid className='name-input-default-config name-margin-top'>
                                <span>Group Name</span>
                                <Grid className="group-name">{selectedRow["groupName"]}</Grid>
                            </Grid>
                            <Grid className='name-input-default-config name-margin-top'>
                                <span>Apply Default Config</span>
                                <div className="radioButton config-radio-button"> <input value='Yes' type="radio" onChange={handleGoldenConfigApply} checked={isGoldenConfigApplied} /> <span>Yes</span>
                                    <input value='Other' type="radio" onChange={handleGoldenConfigApply} checked={!isGoldenConfigApplied} /> <span>Other</span>
                                </div>
                            </Grid>
                            {
                                !isGoldenConfigApplied
                                    ?
                                    <Grid className='name-input-default-config name-margin-top'>
                                        <span>Select Configuration</span>
                                        <Grid >
                                            <AutoCompleteComponent options={[]} />
                                        </Grid>
                                    </Grid>
                                    : null
                            }
                            <Grid item xs={12} sm={12} md={12} lg={12} className="dialog-actions">
                                <Button variant="outlined" className="apply">Apply</Button>
                                <Button variant="outlined" onClick={() => { closeDialog() }} >Cancel</Button>
                            </Grid>
                        </Grid>
                    </ DialogContent>
                </div>
            </Dialog>
            : null
    )
}