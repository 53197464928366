import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FormControl, Grid, MenuItem, Select, Tooltip } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState, useEffect } from "react";
import { getCaptiveDashTopSitesUsagePie, getCaptiveDashTopSitesSubscribersPie, getCaptiveDashTopSitesSessionsPie } from "../../../actions/Users/authenticateCaptiveDahshboard";
import _ from "lodash";
import './index.css';
import { getDecodeURI, getEncodedURI } from "../../../utils/util";

const TopSitesUsagePie = (props) => {
    const { newSummaryStartDate, newSummaryEndDate, getCaptiveDashTopSitesUsagePie, getCDSitesUsagePie, vessels, history, location, getCaptiveDashTopSitesSubscribersPie, getCDTopSitesSubscribersPie, getCaptiveDashTopSitesSessionsPie, getCDTopSitesSessionsPie, topcardSelected } = props;

    const [pieChartData, setPieChartData] = useState<any>();
    const [topSitesSubscribersPiedata, setTopSitesSubscribersPiedata] = useState<any>();
    const [topSitesSessionsPiedata, setTopSitesSessionsPiedata] = useState<any>();
    const [pieLimit, setPieLimit] = useState(10);

  useEffect(() => {
    if(vessels?.length > 0) {
        if (topcardSelected === 'TOTAL USAGE' || topcardSelected === 'SITES ONLINE' || !topcardSelected) {
          getCaptiveDashTopSitesUsagePie(vessels, newSummaryStartDate, newSummaryEndDate, pieLimit);
        }
        if (topcardSelected === 'SUBSCRIBERS') {
          getCaptiveDashTopSitesSubscribersPie(vessels, newSummaryStartDate, newSummaryEndDate, pieLimit);
        }
        if (topcardSelected === 'SESSIONS') {
          getCaptiveDashTopSitesSessionsPie(vessels, newSummaryStartDate, newSummaryEndDate, pieLimit);
        }
    } else {
        setPieChartData([]);
        setTopSitesSubscribersPiedata([]);
        setTopSitesSessionsPiedata([]);
    }
  }, [vessels, newSummaryStartDate, newSummaryEndDate, pieLimit, topcardSelected])

  const colors = ['#2f2282', '#1b51a7', '#3dab97', '#5e3f9e', '#3e88d3', '#1d136a', '#69b8e5', '#3f79ca', '#9e8cc5', '#ed9340',
    '#8cafdf', '#d9914f', '#86c6df', '#3c7df1', '#61cdc0', '#64a1f4', '#dc6161', '#146680', '#2039b5', '#00ad64',
    '#1b4dac', '#8cb5ec', '#c1d5f6', '#b3bbe6', '#e5a8a8', '#f9dabe', '#4b91f1', '#8dbdff', '#efeab9',
    '#8a39a7', '#d9cb4f', '#145f89', '#e7d696', '#8592e7', '#e78585', '#92d7c9', '#aeaeb1', '#755c5d', '#7c6ce6',
    '#cc7460', '#bd9331', '#15e1e8', '#514ced', '#8633de', '#eb52b3', '#c4355b', '#6dc779', '#5373a3', '#8dc293'
   ]

    useEffect(() => {
        if (!_.isEmpty(getCDSitesUsagePie)) {
            const data = getCDSitesUsagePie;
            const pieChartData = data?.map((item, i) => {
                return {
                    x: item.siteName,
                    y: item.quotaPercentage,
                    z: item.quotaUsed,
                    color: colors[i],
                    k4Id: item.k4Id,
                    unit: 'GB'
                }
            })
            setPieChartData(pieChartData);
        }
        if (!_.isEmpty(getCDTopSitesSubscribersPie)) {
            const data = getCDTopSitesSubscribersPie;
            const subscriberspie = data?.map((item, i) => {
                return {
                    x: item.siteName,
                    y: item.percentage,
                    z: item.subscriber_count,
                    color: colors[i],
                    k4Id: item.k4Id,
                    unit: 'subscribers'
                }
            })
            setTopSitesSubscribersPiedata(subscriberspie);
        }
        if (!_.isEmpty(getCDTopSitesSessionsPie)) {
            const data = getCDTopSitesSessionsPie;
            const sessionsPie = data?.map((item, i) => {
                return {
                    x: item.siteName,
                    y: item.sessionPercentage,
                    z: item.numberOfSessions,
                    color: colors[i],
                    k4Id: item.k4Id,
                    unit: 'sessions'
                }
            })
            setTopSitesSessionsPiedata(sessionsPie);
        }
    }, [getCDSitesUsagePie, getCDTopSitesSubscribersPie, getCDTopSitesSessionsPie])

  const pieOptions = {
    chart: {
        type: 'pie',
        height: '185px',
        style: {
            fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
        },
        backgroundColor: 'none'
    },
    title: {
        text: ''
    },
    yAxis: {
        title: {
            text: ''
        }
    },
    tooltip: {
        enabled: true,
        headerFormat: ``,
        pointFormat: `<span style="color: #7e8089">{point.x}</span> <br/> <b style="color: #fff">{point.z} {point.unit}</b><br/>`,
        valueDecimals: 0,
        useHTML: true,
        borderRadius: 15,
        backgroundColor: '#060606',
        followPointer: false
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            shadow: false,
        }
    },
    legend: {
        enabled: false
    },
    credits: {
        enabled: false
    },
    series: [{
        type: 'pie',
        data: ((topcardSelected == 'TOTAL USAGE' || topcardSelected == 'SITES ONLINE'  || !topcardSelected) && !_.isEmpty(pieChartData)) ? pieChartData : (topcardSelected == 'SUBSCRIBERS' && !_.isEmpty(topSitesSubscribersPiedata)) ? topSitesSubscribersPiedata : !_.isEmpty(topSitesSessionsPiedata) ? topSitesSessionsPiedata : [],
        size: '100%',
        innerSize: '50%',
        dataLabels: {
            enabled: false
        }
    }]
  }

  const handleChangePieLimit = (e) => {
    setPieLimit(e.target.value);
  }

  const handleSiteClick = (row) => {
    let params: any = getDecodeURI(location?.search);
    params.k4Ids = `${row.k4Id}:${row.siteName}`;
    delete params.page;
    history.push({ pathname: '/captive', search: `?${getEncodedURI(params)}` });
  }

    return (
        <Grid>
            <Grid className="new-summary-control-limit title-toggle-btn-div">
                <Grid className="title-dropdown-pie-chart">
                    <div className="title-text">Top Sites</div>
                    <Grid className="pieChat-selectTag">
                        <FormControl variant="standard" className='selectEntry-pie'>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={pieLimit}
                                onChange={handleChangePieLimit}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <HighchartsReact
                highcharts={Highcharts}
                options={pieOptions}
            />
            {(topcardSelected == 'TOTAL USAGE' || topcardSelected == 'SITES ONLINE'  || !topcardSelected) && !_.isEmpty(pieChartData) ? <Grid container className="usage-details-pie-perSite remove-margin-left" style={pieChartData?.length > 1 ? {justifyContent:'flex-start'} : {justifyContent:'center'}}>
                {pieChartData && pieChartData.map((usage, i) => (
                    <Grid item xs={12} sm={12} md={5.9} lg={5.9} className={pieChartData?.length == 1 ? 'single-value' : ''}>
                        <div className="summary-pie-usage-dot" style={{ background: usage?.color }}></div>
                        <Tooltip title={usage.x}><span className="summary-usage-value selected-filter captive-pie-name" onClick={() => handleSiteClick(usage)}>{usage.x}</span></Tooltip>
                    </Grid>
                ))}
            </Grid> : topcardSelected == 'SUBSCRIBERS' && !_.isEmpty(topSitesSubscribersPiedata) ? <Grid container className="usage-details-pie-perSite remove-margin-left" style={topSitesSubscribersPiedata?.length > 1 ? {justifyContent:'flex-start'} : {justifyContent:'center'}}>
                {topSitesSubscribersPiedata && topSitesSubscribersPiedata.map((usage, i) => (
                    <Grid item xs={12} sm={12} md={5.9} lg={5.9} className={topSitesSubscribersPiedata?.length == 1 ? 'single-value' : ''}>
                        <div className="summary-pie-usage-dot" style={{ background: usage?.color }}></div>
                        <Tooltip title={usage.x}><span className="summary-usage-value selected-filter captive-pie-name" onClick={() => handleSiteClick(usage)}>{usage.x}</span></Tooltip>
                    </Grid>
                ))}
            </Grid> : !_.isEmpty(topSitesSessionsPiedata) ? <Grid container className="usage-details-pie-perSite remove-margin-left" style={topSitesSessionsPiedata?.length > 1 ? {justifyContent:'flex-start'} : {justifyContent:'center'}}>
                {topSitesSessionsPiedata && topSitesSessionsPiedata.map((usage, i) => (
                    <Grid item xs={12} sm={12} md={5.9} lg={5.9} className={topSitesSessionsPiedata?.length == 1 ? 'single-value' : ''}>
                        <div className="summary-pie-usage-dot" style={{ background: usage?.color }}></div>
                        <Tooltip title={usage.x}><span className="summary-usage-value selected-filter captive-pie-name" onClick={() => handleSiteClick(usage)}>{usage.x}</span></Tooltip>
                    </Grid>
                ))}
            </Grid> : null }
        </Grid>
    )
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
  newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
  getCDSitesUsagePie: state?.authReducer?.getCDSitesUsagePie?.data,
  getCDTopSitesSubscribersPie: state?.authReducer?.getCDTopSitesSubscribersPie?.data,
  getCDTopSitesSessionsPie: state?.authReducer?.getCDTopSitesSessionsPie?.data,
});

export default withRouter(
  connect(mapStateToProps, { getCaptiveDashTopSitesUsagePie, getCaptiveDashTopSitesSubscribersPie, getCaptiveDashTopSitesSessionsPie })(TopSitesUsagePie)
);