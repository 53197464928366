import { Grid } from "@mui/material";
import SubHeaderReport from "../../components/SubHeaderReport";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { SIDE_MENU_ITEM_REPORTS } from "../../utils/constants";

const ReportPage = (props) => {
  const { authReducer } = props;
  useEffect(() => {
    if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_REPORTS !== authReducer.sideMenu.menuItem)) {
      authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_REPORTS };
    }
  });

  return (
    <div className="Form" style={{ overflowX: "hidden", width: "-webkit-fill-available" }}>
      <Grid item container style={{ backgroundColor: "#fff", marginTop: "60px" }}>
        <Grid item style={{ marginLeft: "82px", width: "100%" }}>
          <Grid item container style={{ position: "fixed", paddingBottom: "16px", backgroundColor: "#ffffff", zIndex: 10, paddingRight: "68px" }}>
            <SubHeaderReport />
          </Grid>
          <div style={{ marginTop: "80px", marginRight: "16px", marginLeft: "-10px" }}>
            Report page goes here
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter( connect(mapStateToProps, {})(ReportPage) );