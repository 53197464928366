import { api } from "../../server/request";
import { handleError, handleResponse } from "../responseUtil";
import { SET_LOADING, GET_GROUP_SETTINGS_DATA, UPDATE_GROUP_SETTINGS_DATA } from "../types";

export const getGroupSettingsData = (dpId) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    api
        .getRequest(`/iam/ou/getDpConfiguration/${dpId}`)
        .then((res) => handleResponse(res, dispatch, GET_GROUP_SETTINGS_DATA))
        .catch((err) => handleError(err, dispatch));
};

export const updateOrganizationSettingsData = (dpId, payload) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    api
        .putRequest(`/iam/ou/updateDpConfiguration/${dpId}`, payload)
        .then((res) => handleResponse(res, dispatch, UPDATE_GROUP_SETTINGS_DATA))
        .catch((err) => handleError(err, dispatch));
};