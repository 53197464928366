import { useState, useEffect } from "react";
import { Button, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, Step, StepLabel, Stepper, TextField, Typography, Table, TableBody, TableRow, TableCell, TableHead, Tooltip } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./StarlinkToS.css"
import moment from "moment";
import HelpIcon from '@mui/icons-material/Help';
import StarlinkCloud from "../../asset/image/starlink_cloud.svg";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { acceptTerms } from "../../components/StarlinkToS/slice";
import { getDpData } from "../../actions/Users/authenticateTicket";
import { getDataFromLocalStorage, getTimezoneCity } from "../../utils/util";
import { starlinkAgreementServiceDesc, starlinkAgreementAppointment, starlinkAgreementEarlyTermination, starlinkAgreementPaymenAndInvoices, starlinkAgreementPricing, starlinkAgreementEntireAgreement, starlinkAgreementTagline, starlinkAgreementEntireTerm, starlinkWelcomeText } from "./StarlinkAgreementData"
import CircularProgress from '@mui/material/CircularProgress';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { Switch } from "@mui/material";
import { getDataFromLocalStorageParsed } from "../../utils/util";
import { subscribeToServiceLines } from "../StarlinkAdmin/slice";
import { getAllServiceLines } from "../StarlinkReports/slice";
import { getServiceLineSubscription } from "../StarlinkAdmin/slice";
import _ from "lodash";
import {  ServiceAccountType } from "../StarlinkAdmin/types"
import {  createServiceAccount } from "../StarlinkAdmin/slice"


const useStyles = makeStyles(theme => ({
    root: {
        "& .PrivateStepIcon-root.MuiStepIcon-active": { color: "red" },
        "& .PrivateStepIcon-root.MuiStepIcon-completed": { color: "green" },
        "& .PrivateStepIcon-text": { color: "blue" },
    },
}));

export interface StarlinkTermsProps {
    acceptTerms: (params: { dpId: any, acceptorSignature: any, loggedInUser: any, tosAccepted: any, acceptorEmail: any, createdAt: any }) => void;
    fnActiveStep: () => void;
    getDpData: (ouName: any) => void;
    starlinkToS: any;
    authReducer: any;
    loggedInUser: any;
}

const AgreementSection = ({ title, description }) => {
    return (
        <>
            <Typography className="starlink-title-typo">{title}</Typography>
            <Typography className="starlink-title-desc">{description}</Typography>
        </>
    );
};


const TermsPage = (props: StarlinkTermsProps) => {
    const { fnActiveStep, starlinkToS, acceptTerms, authReducer, getDpData, loggedInUser } = props
    const handleSignature = (e) => {
        setAcceptorName(e.target.value)
    }

    interface DpDataTypes {

        address: string;
        addrLine1: string;
        addrLine2: string;
        addrCity: string;
        addrState: string;
        addrZipCode: string;
        addrCountry: string;
    }

    const [acceptorName, setAcceptorName] = useState("")
    const [agreementDate, setAgreementDate] = useState(moment())
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [selectedOu, setSelectedOu] = useState(null)
    const [dpData, setDpData] = useState<DpDataTypes | null>(null);
    const [userLoggedIn, setUserLoggedIn] = useState(" ")

    const sections =[
        {
            title : 'SERVICE DESCRIPTION' ,
            description : starlinkAgreementServiceDesc,
        },
        {  
            title: 'APPOINTMENT', 
            description : starlinkAgreementAppointment, 
        },
        {
            title: 'PRICING AND FEES', 
            description : starlinkAgreementPricing,
        },  
        {
              title: 'PAYMENT AND INVOICES',
            description : starlinkAgreementPaymenAndInvoices ,
        },
        {
             title: 'EARLY TERMINATION', 
            description : starlinkAgreementEarlyTermination ,
        },
        {
             title: 'ENTIRE AGREEMENT',
            description : starlinkAgreementEntireAgreement,
        },
        { 
            title : 'ENTIRE TERM', 
             description : starlinkAgreementEntireTerm ,
        },
    ];

    const handleEmailChange = (event) => {
        const emailValue = event.target.value;
        setEmail(emailValue);
        setError(emailValue !== '' && !validateEmail(emailValue));
    };

    useEffect(() => {
        if (authReducer?.getDpAddressData) {
            setDpData(authReducer?.getDpAddressData?.data)
        }

    }, [authReducer?.getDpAddressData])

    const validateEmail = (email) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email);
    };


    useEffect(() => {
        if (authReducer && authReducer?.selectedOu) {
            setSelectedOu(authReducer.selectedOu)
            getDpData(authReducer.selectedOu?.name)
        }
    }, [authReducer?.selectedOu])

    useEffect(() => {
        let loggedUser = JSON.parse(loggedInUser)
        setUserLoggedIn(loggedUser ? loggedUser?.data?.user?.name?.givenName + " " + loggedUser?.data?.user?.name?.familyName : " ")
    }, [])

    const fnAcceptTos = async () => {
        if (acceptorName != "" && authReducer?.selectedOu?.id)
            await acceptTerms({
                dpId: authReducer?.selectedOu?.id,
                acceptorSignature: acceptorName,
                loggedInUser: userLoggedIn,
                tosAccepted: true,
                acceptorEmail: email,
                createdAt: agreementDate
            })
        fnActiveStep()
    }

    return (
        <div className="starlinkPadding">
            <Typography className="termsTypo">
                Terms & Conditions
            </Typography>

            <Paper className="agreementPageGrid menuScroll">

                {sections.map((section, index) => (
                    <AgreementSection
                        key={"agreement"+index}
                        title={section?.title}
                        description={section?.description}
                    />
                ))}

                <Typography className="starlink-title-desc">
                    <Table className="starlinkInfoTable">
                        <TableBody>
                            <TableRow key={"infoTable"}>
                                <TableCell>PRIMARY ORGANIZATION : {authReducer?.selectedOu?.name}</TableCell>
                            </TableRow>
                            <TableRow key={"infoTable"}>
                                <TableCell>ADDRESS: {(dpData?.address) || ''}
                                    {(dpData?.addrLine1) || ''}
                                    {(dpData?.addrLine2) || ''}
                                    {(dpData?.addrCity) || ''}
                                    {(dpData?.addrState) || ''}
                                    {(dpData?.addrCountry) || ''}
                                    {(dpData?.addrZipCode) || ''}
                                </TableCell>
                            </TableRow>
                            <TableRow key={"infoTable"}>
                                <TableCell>SIGNATURE : {acceptorName} </TableCell>
                            </TableRow>
                            <TableRow key={"infoTable"}>
                                <TableCell>EMAIL : {email}</TableCell>
                            </TableRow>
                            <TableRow key={"infoTable"}>
                                <TableCell>DATE : {moment(agreementDate).format("MM-DD-YYYY")}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Typography>

                <Typography className="starlink-title-typo">
                    SCHEDULE 1
                </Typography>

                <Typography className="starlink-title-typo">
                    Subscription Services
                </Typography>

                <Typography className="starlink-title-desc">
                    <Table className="starlinkTableBorder">
                        <TableHead>
                            <TableRow key={"infoTable"}>
                                <TableCell>Plan</TableCell>
                                <TableCell>Plan Description</TableCell>
                                <TableCell>Subscription Fee (Per Service Line & Kit)</TableCell>
                                <TableCell>MIT (months)</TableCell>
                                <TableCell>Qty</TableCell>
                                <TableCell>Activation Date</TableCell>
                                <TableCell> Deactivation Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow key={"infoTableData"}>
                                <TableCell>Starlink Cloud Service</TableCell>
                                <TableCell>Starlink Cloud Service</TableCell>
                                <TableCell>$10.00 / Month </TableCell>
                                <TableCell>1</TableCell>
                                <TableCell>NA</TableCell>
                                <TableCell>Agreement</TableCell>
                                <TableCell>NA</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                </Typography>
                <Typography className="starlink-title-desc">
                    {starlinkAgreementTagline}
                </Typography>

            </Paper>
            <div className="agreementBtn">
                <div className="agreementInnerDiv">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePicker
                            ampm={false}
                            timezone={getTimezoneCity(authReducer.userTimezone)}
                            value={agreementDate}
                            onChange={(newValue) => {
                                if (newValue !== null) {
                                    setAgreementDate(moment(newValue))
                                }
                            }}
                            label='Date'
                            viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                            }}
                            maxDateTime={moment()}
                            className="audit-filter-inputs"
                        />
                    </LocalizationProvider>
                    {/* </div> */}
                </div>
                <div className="starlinkTextAlign">
                    <TextField
                        className="starlinkTextField"
                        fullWidth
                        size="small"
                        id=""
                        label="Email"
                        variant="outlined"
                        value={email}
                        onChange={handleEmailChange}
                        error={error}
                        helperText={error ? 'Please enter a valid email' : ''}
                    />
                </div>
                <div className="starlinkTextAlign">
                    <TextField
                        className="starlinkTextField"
                        fullWidth
                        size="small"
                        id=""
                        // helperText="Required"
                        label="Full Name"
                        value={acceptorName}
                        onChange={handleSignature}
                    />
                </div>
                <div className="starlinkTextAlign">
                    <Button className="acceptBtn" disabled={acceptorName === "" || error || email === ""} onClick={() => { fnAcceptTos() }} variant="contained">
                        Accept & Continue
                    </Button>
                </div>
            </div>

        </div >
    )
}
const StarlinkTos = (props) => {
    const { authReducer, goToStarlinkDashborad, acceptTerms, createServiceAccount, starlinkToS, getDpData, loading, allServiceLines, dpIds, subscribeToServiceLines, getAllServiceLines, getServiceLineSubscription } = props
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        if(!_.isEmpty(dpIds)) {
            getAllServiceLines({dpIds: dpIds});
        }
    }, [dpIds]);

    // const [selectOu, setSelectOu] = useState(null)


    // useEffect(() => {
    //     if (authReducer && authReducer?.selectedOu) {
    //         selectOu && selectOu !== authReducer?.selectedOu && setActiveStep(0)
    //         setSelectOu(authReducer.selectedOu)
    //     }
    // }, [authReducer?.selectedOu])

    const steps = [
        {
            stepperHeader: "Welcome",
            topicHeader: "Welcome",
            btnContent: "Next"
        },
        {
            stepperHeader: "Accept Terms & Conditions",
            topicHeader: "Terms & Conditions",
            btnContent: "Agree & Continue"
        },
        {
            stepperHeader: "Setup Starlink Service Account",
            topicHeader: "Starlink Service Account",
            btnContent: "Next"
        }
    ]

    const goToStarlink = (value) => {
        goToStarlinkDashborad(value)
    }

    const fnActiveStep = () => {
        setActiveStep(activeStep + 1)
    }

    const getStarlinkPage = () => {
        if (activeStep === 0) {
            goToStarlink(1)
            return <WelcomePage />
        }
        else if (activeStep === 1) {
            goToStarlink(2)
            return <TermsPage fnActiveStep={fnActiveStep} acceptTerms={acceptTerms} starlinkToS={starlinkToS} authReducer={authReducer} getDpData={getDpData} loggedInUser={getDataFromLocalStorage("loggedInUser")} />
        }
        else if (activeStep === 2) {
            return <ServiceAccount allServiceLines={allServiceLines} dpIds={dpIds} subscribeToServiceLines={subscribeToServiceLines} getServiceLineSubscription={getServiceLineSubscription}    createServiceAccount={createServiceAccount} />
        }
        else if (activeStep === 3) {
            goToStarlink(3)
        }
        else {
            goToStarlink(false)
        }
    }

    const ServiceAccount = (props, ) => {
      
        const {allServiceLines,dpIds, getServiceLineSubscription, subscribeToServiceLines, createServiceAccount,} = props;
        const [value, setValue] = useState('Service Account Client');
        const [clientId, setClientId] = useState<string>('');
        const [clientSecret, setClientSecret] = useState<string>('');
        const [userName, setUserName] = useState<string>('');
        const [password, setPassword] = useState<string>('');
        const loggedInUser = getDataFromLocalStorageParsed("loggedInUser");
        const [serviceAccountType, setServiceAccountType] = useState<ServiceAccountType>('service_account_client');
        const [requestAbortController, setRequestAbortController] = useState<AbortController | null>(null);


        const handleChangeSubscribeToAll = async (e, sl) => {
            if (allServiceLines.length > 0) {
                const action = e?.target?.checked ? "subscribeToAll" : "unsubscribeToAll";
                const date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
                const requestData: { serviceLineNumber: any; dpId: string[]; user: any; action: string; startDate?: string | undefined; endDate?: string | undefined; } =
                {
                    serviceLineNumber: allServiceLines
                        .map((item) => item.serviceLineNumber),
                    dpId: dpIds,
                    user: loggedInUser?.data?.user?.userName,
                    action: action,
                };

                if (e?.target?.checked) {
                    requestData.startDate = date;
                } else {
                    requestData.endDate = date;
                }
                await subscribeToServiceLines(requestData);
                await getServiceLineSubscription({dpId:dpIds})
            }
           
        }

        const handleChangeClientSecret = (event) => {
            setClientSecret(event.target.value);
        };

        const handleChangeClientId = (event) => {
            setClientId(event.target.value);
        };

        const handleChangeUserName = (event) => {
            setUserName(event.target.value);
        };

        const handleChangePassword = (event) => {
            setPassword(event.target.value);
        };

        const handleChange = (event) => {
            setServiceAccountType(event.target.value);
        };   

        const addServiceAccountClient = async() =>{
              
                let _serviceAccount = {
                    id: '',
                    dpId: authReducer.selectedOu.id,
                    type: serviceAccountType,
                    clientId,
                    clientSecret,
                    userName,
                    password,
                    validate: true,
                }
                // setValidating(true);
                let _abortController = new AbortController();
                setRequestAbortController(_abortController);
                let res = await createServiceAccount(_serviceAccount, _abortController?.signal);
                if (res?.success) {
                    // handleSubOrgStepInitialization();
                    setActiveStep(activeStep+1)
                } else if (res?.response?.data?.message) {
                    // setCredentialError(res?.response?.data?.message);
                }
                // setValidating(false);
            
        }
        return (
            <div className="lastPage starlinkPadding" >
                <Typography className="termsTypo">
                    Starlink Service Account

                </Typography>
                <Paper className="menuScroll welcomePageGrid">
                    <Grid container className="StarlinkMainLayoutGrid">
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                            <Typography>Account Type</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                            <FormControl>
                                <RadioGroup
                                    value={serviceAccountType}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value="service_account_client" control={<Radio />} label={
                                        <Grid classes={{ root: 'starlink--base--flex--gap--05x starlink--base--flex--align--center' }}>
                                            Service Account Client
                                            <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={<Grid>
                                                <Typography component="div" className="starlink--base--font_08x starlink--base--padding_05x">
                                                    <p>To set up API access, log into your Starlink account home page.</p>
                                                    <p>In the section named "Your Service Accounts" select the "+" in the top right corner to create a new service account.</p>
                                                    <p>If you do not see the section "Your Service Accounts" reach out to your account manager to inquire about access.</p>
                                                </Typography>
                                            </Grid>}>
                                                <HelpIcon fontSize="small" />
                                            </Tooltip>
                                        </Grid>
                                    } />
                                    <FormControlLabel value="credential" control={<Radio />} label="Web Portal" />
                                </RadioGroup>
                            </FormControl>
                            {
                                serviceAccountType === 'service_account_client' ?
                                    <Grid container spacing={1} classes={{ root: 'starlink--base--mtb_07x' }}>
                                        <Grid item md={6}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                id="starlink--service_account--client_id"
                                                label="Client ID"
                                                value={`****${clientId?.slice(-4)}`}
                                                onChange={handleChangeClientId}
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <TextField
                                                fullWidth
                                                type="password"
                                                size="small"
                                                id="starlink--service_account--client_secret"
                                                label="Client Secret"
                                                value={clientSecret}
                                                onChange={handleChangeClientSecret}
                                            />
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid container spacing={1} classes={{ root: 'starlink--base--mtb_07x' }}>
                                        <Grid item md={6}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                id="starlink--service_account--user_name"
                                                label="Email"
                                                value={userName}
                                                onChange={handleChangeUserName}
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <TextField
                                                fullWidth
                                                type="password"
                                                size="small"
                                                id="starlink--service_account--password"
                                                label="Password"
                                                value={password}
                                                onChange={handleChangePassword}
                                            />
                                        </Grid>
                                    </Grid>}
                        </Grid>
                    </Grid>
                    <Grid container style={{display:"flex"}}>
                    <Grid style={{marginTop:"27px"}}>
                            <Typography>Subscribe All Service Lines</Typography>
                        </Grid>
                        <Grid style={{marginTop:"20px"}} item xs={12} sm={6} md={6} lg={6} xl={6} >
                            <FormControl>
                            <Tooltip title="Subscribe all service lines">
                                        <Switch
                                            checked={ allServiceLines?.length > 0 && allServiceLines[0].isSubscribedToAll}
                                            onChange={ (e) => { handleChangeSubscribeToAll(e,allServiceLines) }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </Tooltip>
                            </FormControl>
                         
                        </Grid>
                    </Grid>
                </Paper>
                <div className="btnNextGrid">
                    <Button className="acceptBtnfinal" style={{ marginLeft: "auto" }} onClick={() => { setActiveStep(activeStep + 1) }} variant="contained">Skip</Button>
                    <Button className="acceptBtnfinal" onClick={() => {  addServiceAccountClient() }} variant="contained">Next</Button>
                </div>
            </div>
        )
    }

    const WelcomePage = () => {
        return (
            <div className="starlinkPadding">
                {
                    loading ? 
                    <div className="starlinkCircularDiv">
                             <CircularProgress className="starlinkCircularProgress" /> 
                    </div>
                    :
                    <div >
                       <Typography className="termsTypo">
                            Welcome
                        </Typography>
                        <Paper className="welcomePageGrid menuScroll" >
                            <Typography className="starlink-title-desc">
                                {starlinkWelcomeText}
                            </Typography>
                        </Paper>
                        <div className="agreeBtnDiv" onClick={() => { setActiveStep(activeStep + 1) }}>
                            <Button className="agreeBtn">Next</Button>
                        </div>
                    </div>
                }
            </div>
        )
    }


    const classes = useStyles();
    return (
        <Grid className="starlinkGridMain">
            <Grid item className="starlinkBackgroundImage">
                <div className="starImageGrid">
                    <img className="starImageBackground icon-cursor" src={StarlinkCloud} title="Starlink" />
                </div>
                <div className="stepperStarlink starlinkStepperGrid">
                    {
                        loading ? 
                        <Typography style={{color:"#ffffff"}}> Validating Starlink Terms of Service (ToS). Please wait...</Typography>
                        :
                    <Stepper className={classes.root} style={{ padding: "20px", width: "80%" }} activeStep={activeStep}>
                        {steps.map((step) => {
                            return (
                                <Step key={step.stepperHeader}>
                                    <StepLabel >{step.stepperHeader} </StepLabel>
                                </Step>
                            )
                        })}
                    </Stepper>
                    }
                </div>
            </Grid>
            <Grid container className="starlinkStepperGrid">
                <Grid item xs={9} sm={9} md={9} lg={9} className="starlinkPageLayout">
                    <Paper className="starlinkGrid">
                        {getStarlinkPage()}
                    </Paper>
                </Grid>
            </Grid>
        </Grid >
    )

}

const mapStateToProps = (state) => ({
    starlinkToS: state.starlinkToS,
    authReducer: state.authReducer,
    dpIds: state.starlinkCloud.dpIds,
    allServiceLines: state.starlinkReports.allServiceLines,
});

export default withRouter(
    connect(mapStateToProps, {
        acceptTerms,
        getDpData,
        subscribeToServiceLines,
        getAllServiceLines,
        getServiceLineSubscription,
        createServiceAccount,
    })(StarlinkTos)
);
