import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, InputAdornment, List, ListItem, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AuditTableContext, getFilterActions, NavigationFilters, TableFilters } from "./AuditTableContext";
import moment, { Moment } from "moment-timezone";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import close from "../../asset/image/close.svg";

import './AuditLogs.css';

interface Props {
    header: string;
    expanded?: boolean;
    children: JSX.Element;
}

const Expandable = (props: Props) => {
    const { children, header, expanded = false } = props;

    const [isExpanded, setExpanded] = useState(expanded);

    const handleOnExpand = () => {
        setExpanded(prev => !prev);
    }

    return <Grid>
        <Grid className="filter-accordian" sx={{ py: 1 }}>
            <span>{header}</span>
        </Grid>
        <Grid sx={{ px: 2, py: 1 }}>
            {children}
        </Grid>
    </Grid>
}


const AuditLogFilters = (props) => {

    const {setFilterPopup} = props

    const { filters, setFilter, clearFilters, applyFilters } = useContext(AuditTableContext);

    const handleUsernameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        setFilter('username', value);
    }

    const handleResourceChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        setFilter('resource', value);
    }

    const handleLabelChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        setFilter('label', value);
    }

    const handleStartDateChange = (date: Moment | null) => {
        if (date) {
            setFilter('startDate', date);
        }
    }

    const handleEndDateChange = (date: Moment | null) => {
        if (date) {
            setFilter('endDate', date);
        }
    }

    const handleApplyFilters = () => {
        applyFilters();
    }

    const handleOnCreateCheck = (_: any, checked: boolean) => {
        setFilter('createAction', checked);
    }

    const handleOnDeletedCheck = (_: any, checked: boolean) => {
        setFilter('deleteAction', checked);
    }

    const handleOnUpdateCheck = (_: any, checked: boolean) => {
        setFilter('updateAction', checked);
    }

    const handleOnClearAll = () => {
        clearFilters();
    }

    return (
        <Paper elevation={0}>
            <div className="audit-filters-container">
                <div className="audit-filter-items menuScroll">
                    <div className="audit-filter-list audit-logs-font">               
                        <Typography className="audit-filter audit-logs-font-header">Filters</Typography>
                        <img src={close} onClick={() => { setFilterPopup(false) }} alt="Close" className="audiFilterCloseIcon"/>
                    </div>
                    <Divider className="auditFilterBorderColor"/>
                    <List className="audit-pop-up-height ">
                        <ListItem className="audit-logs-font auditFilterBorderColor" sx={{ padding: 0 }} divider>
                            <Expandable header='User name'>
                                <FormControl size="small" fullWidth>
                                    <TextField sx={{ padding: 0 }}
                                        className="audit-filter-inputs"
                                        size="small"
                                        variant="outlined"
                                        placeholder="Search for username"
                                        onClick={() => { }}
                                        value={filters.username}
                                        onChange={handleUsernameChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormControl>
                            </Expandable>
                        </ListItem>
                        <ListItem className="audit-logs-font auditFilterBorderColor" sx={{ padding: 0 }} divider>
                            <Expandable header='Resource'>
                                <FormControl size="small" fullWidth>
                                    <TextField
                                        size="small"
                                        className="audit-filter-inputs"
                                        variant="outlined"
                                        placeholder="Search for resource"
                                        onClick={() => { }}
                                        value={filters.resource}
                                        onChange={handleResourceChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormControl>
                            </Expandable>
                        </ListItem>
                        <ListItem className="audit-logs-font auditFilterBorderColor" sx={{ padding: 0 }} divider>
                            <Expandable header="Actions">
                                <Stack spacing={2}>
                                    <FormGroup sx={{ padding: 0 }}>
                                        <FormControlLabel sx={{ padding: 0 }} control={<Checkbox name="create" checked={filters.createAction} onChange={handleOnCreateCheck} />} label="Create" className="auditFilterActions"/>
                                        <FormControlLabel control={<Checkbox name="update" checked={filters.updateAction} onChange={handleOnUpdateCheck} />} className="auditFilterActions" label="Update" />
                                        <FormControlLabel control={<Checkbox name="delete" checked={filters.deleteAction} onChange={handleOnDeletedCheck} />} className="auditFilterActions" label="Delete" />
                                    </FormGroup>
                                </Stack>
                            </Expandable>
                        </ListItem>
                        {/* <ListItem className="audit-logs-font" sx={{ padding: 0 }} divider>
                            <Expandable header='Date/Time Range' expanded>
                                <Stack spacing={2} alignItems='stretch'>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DateTimePicker
                                            className="audit-filter-inputs"
                                            label="From Date"
                                            renderInput={(params) => <TextField size="small" {...params} />}
                                            value={filters.startDate}
                                            maxDateTime={filters.endDate ?? moment()}
                                            onChange={handleStartDateChange}
                                        />

                                        <DateTimePicker
                                            className="audit-filter-inputs"
                                            label="To Date"
                                            renderInput={(params) => <TextField size="small" {...params} />}
                                            minDateTime={filters.startDate ?? undefined}
                                            value={filters.endDate}
                                            maxDateTime={moment()}
                                            onChange={handleEndDateChange}
                                        />
                                    </LocalizationProvider>
                                </Stack>
                            </Expandable>
                        </ListItem> */}
                        <ListItem className="audit-logs-font auditFilterBorderColor" sx={{ padding: 0 }} divider>
                            <Expandable header='Label'>
                                <Stack spacing={2} alignItems='stretch'>
                                    <span className="filter-accordian-label">Search label</span>
                                    <FormControl fullWidth size="small">
                                        <TextField
                                            size="small"
                                            className="audit-filter-inputs"
                                            variant="outlined"
                                            placeholder="Search label"
                                            onClick={() => { }}
                                            value={filters.label}
                                            onChange={handleLabelChange}
                                        />
                                    </FormControl>
{/* 
                                    <span className="filter-accordian-label">Search value</span>
                                    <FormControl fullWidth size="small">
                                        <TextField
                                            size="small"
                                            className="audit-filter-inputs"
                                            variant="outlined"
                                            placeholder="Search value"
                                            onClick={() => { }}
                                        />
                                    </FormControl> */}
                                </Stack>
                            </Expandable>
                        </ListItem>
                    </List>
                </div>
                <div className="audit-btn-parent">
                    <Button variant="text" className="auditClearButton" onClick={handleOnClearAll}>Clear Filter</Button>
                    <Button
                        className="audit-filter-apply"
                        onClick={handleApplyFilters}
                        variant="contained">
                        Apply Filter
                    </Button>
                </div>
            </div>
        </Paper>
    )
}


const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer
});

export default withRouter(
    connect(mapStateToProps, {})(AuditLogFilters)
);