import React, { useState, useEffect, Fragment, useCallback, useRef } from "react";
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomLoader from "../Loader/CustomLoader";
import _ from "lodash";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import NotAuthorized from "../../UserScreen/NotAuthorized";
import { getServiceFunction } from "../../utils/util";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { BC_EDGE_ADMIN_FIRMWARE } from "../../constants/Constants";
import { RESOURCE_FIELD_ID, RESOURCE_FIELD_VESSELNAME } from "../../utils/constants";
import { getEdgeServerStatus } from "../../actions/Users/graphQlServices";

import "./VesselSummary.css";

import { vesselSummaryTopology } from "../../actions/Users/graphQlServices";
import { useHistory,useLocation } from "react-router-dom";
import { getDecodeURI } from "../../utils/util";
import { clearData, getGeneratedLinks } from "../RemoteConnections/remoteConnectionSlice";

const EDGE_PORTAL_ADDRESS = "127.0.0.1"
const EDGE_PORTAL_PORT = 80
const MAX_WAIT_TIME = 20

const EdgePortal: any = (props) => {
    const { authReducer, match, getEdgeServerStatus, generatedLinks, getGeneratedLinks, clearRemoteConnectionData } = props;
    const loadingTextOnUI = "Fetching data please wait...";
    const [loaders, setLoaders] = useState({ showLoader: false, loadingText: loadingTextOnUI });
    const [loadingEdgeStatus, setLoadingEdgeStatus] = useState(true);
    const [loadingEdgePortalLink, isLoadingEdgePortalLink] = useState(false);
    const [isVesselOnline, setIsVesselOnline] = useState(false);
    const [breadcrumb, setBreadcrumb] = useState<any[]>(BC_EDGE_ADMIN_FIRMWARE);
    const setBreadcrumbInfo: any = React.useRef();
    const edgePortalIframe: any = useRef(null);

    const location = useLocation();
    const _q = getDecodeURI(location?.search);
    let _selectedVesselK4Id = location?.state?.clickedVessel?.id ;
    const [selectedVessel, setSelectedVessel] = useState<any>(_q?.k4Ids ? _q.k4Ids.split(":")[0] : _selectedVesselK4Id? _selectedVesselK4Id:"");
    const [deviceId, setDeviceId] = useState(_q?.device_id ? _q.device_id : '')
    const EDGE_ADMINISTRATION = "view-vessel-summary";
    const edgeAdminService = SERVICE_FUNCTION_TO_FEATURE[EDGE_ADMINISTRATION]["service"];
    const edgeAdminFunction = SERVICE_FUNCTION_TO_FEATURE[EDGE_ADMINISTRATION]["function"];
    const history = useHistory();

    useEffect(() => {
     
        setLoaders({ showLoader: true, loadingText: "Loading portal, please wait ..." });
      
        let viewResources : any= [];
        let viewFunctionPermission = getServiceFunction(edgeAdminService, edgeAdminFunction);
        if (viewFunctionPermission && viewFunctionPermission.resources) {
          viewResources = viewFunctionPermission.resources;
        }
        let vesselName = "-";
        if (viewResources.length > 0) {
          let _selectedVessel = viewResources.find(viewResource => viewResource[RESOURCE_FIELD_ID] === selectedVessel)
          if (_selectedVessel) {
            vesselName = _selectedVessel.name;
          }
        }
    
        const bc = applyBreadcrumb(breadcrumb, vesselName);
        setBreadcrumb(bc);
        setBreadcrumbInfo.current(bc);

        return () => {
            clearRemoteConnectionData();
        }
    }, []);

    useEffect(() => {
        if(!_.isEmpty(deviceId)){
            getEdgeServerStatus([deviceId])
        }
    }, [deviceId])

    useEffect(() => {
        (async () => {
            if (isVesselOnline && !_.isEmpty(selectedVessel) && !_.isEmpty(deviceId)) {
                isLoadingEdgePortalLink(true);
                await getGeneratedLinks(selectedVessel, deviceId, { address: [EDGE_PORTAL_ADDRESS] })
                isLoadingEdgePortalLink(false)

            }
        })()
    }, [isVesselOnline, selectedVessel, deviceId])

    const edgePortalLink = useCallback(() => {
        let [edgeLink] = generatedLinks.filter(rc => {
            return rc.address === EDGE_PORTAL_ADDRESS && rc.port === EDGE_PORTAL_PORT && rc.alias.includes("Portal")
        });
        return edgeLink;
    }, [generatedLinks])

    const applyBreadcrumb = (bc: any, vesselName) => {
        let pURL = history?.location?.state?.from;
        if (pURL && bc) {
          if (bc[1]) {
            if (bc[1].hasOwnProperty("link")) {
                bc[1]["link"] = pURL;
            }
          }
          if (bc[2]) {
            if (bc[2].hasOwnProperty("link")) {
                bc[2]["link"] = pURL;
            }
          }
        }

        let bcObj = bc?.slice(0);
        if (vesselName) {
          let vName = { title: vesselName }
          bcObj[3] = vName;
        }
        bcObj[4].link = `/fleet-dashboard/sites/topology?k4Ids=${encodeURIComponent(selectedVessel+":"+vesselName)}&ouName=${_q?.ouName ? _q.ouName : ''}&ouId=${_q?.ouId ? _q.ouId : ''}`;
        bcObj.push({title: deviceId});
        return bcObj;
      };

    useEffect(() => {

        if (!_.isEmpty(authReducer.edgeServerStatus) && authReducer.edgeServerStatus.data) {
            let _isVesselOnline = false;
            let endpoint_status: any[] = authReducer?.edgeServerStatus?.data?.getVesselsEndPointStatusResponse?.endpoint_status;
            if (deviceId && endpoint_status && 
                Array.isArray(endpoint_status) && endpoint_status.length > 0 &&
                endpoint_status[0].name) {
                if (endpoint_status[0].status && "STATUS_CONNECTED" === endpoint_status[0].status) {
                    _isVesselOnline = true;
                }
            }
            setIsVesselOnline(_isVesselOnline);
            setLoadingEdgeStatus(false);
            authReducer.vesselEdgeInfo = {};
        }
    }, [authReducer.edgeServerStatus]);

    const handleLoadIframe = (lapsed) => {
        const frame = edgePortalIframe.current?.contentWindow
        if (Object.keys(frame).includes("0") || (lapsed && lapsed >= MAX_WAIT_TIME)) {
            setLoaders({ showLoader: false, loadingText: "" });
            return
        }
        setTimeout(() => {
            if (_.isNumber(lapsed)) {
                handleLoadIframe(++lapsed)
            } else {
                handleLoadIframe(1)
            }
        }, 1000)
    }

    const clickCancel = () => {
        history.goBack();
    }


    return (
        <div className="Form">
            <BreadCrumbs breadcrumbinfo={breadcrumb} setBreadcrumbInfo={setBreadcrumbInfo} isVesselOnline={isVesselOnline}></BreadCrumbs>
            <Grid className="vessel_summary_page">
                <Grid container className="topology">

                    < Grid item xs={12} sm={12} md={12} lg={12} className="vessel_summary_product_details" style={{ paddingLeft: '0px', margin: "100px 0px 0px 100px" }}>

                        <Fragment>
                            <Grid container item sx={{ width: "100%", height: "calc(100% - 100px)" }}>
                                {
                                    loadingEdgeStatus ? (<NotAuthorized pageTitle={""} PageMessage={"Fetching vessel online status"} />)
                                        : (
                                            isVesselOnline ? loadingEdgePortalLink ? <NotAuthorized pageTitle={"Ooops.."} PageMessage={"Getting Portal link"} /> :
                                            (!_.isEmpty(edgePortalLink()?.url) ?
                                                [
                                                    <iframe onLoad={handleLoadIframe} ref={edgePortalIframe}  src={edgePortalLink().url} style={{ position: "absolute", width: "calc(100% - 137px)", height: "calc(100vh - 120px)", border: "none"}} title="Portal"></iframe>,
                                                    loaders.showLoader ? (
                                                        <CustomLoader showLoader={true} cancelCallBack={clickCancel}
                                                            loadingText={loaders["loadingText"] ? loaders["loadingText"] : "Loading  portal, please wait..."}
                                                        />
                                                    ) : null
                                                ]
                                                : <NotAuthorized pageTitle={"Ooops.."} PageMessage={"Portal link not available"} />
                                            ) :
                                            <NotAuthorized pageTitle={"Ooops.."} PageMessage={"Portal is offline, unable to connect!!"} />
                                        )
                                }
                            </Grid>
                        </Fragment>
                    </Grid>
                </Grid>

                {
                    authReducer.loading ? (
                        <CustomLoader
                            showLoader={true}
                            loadingText={
                                loaders["loadingText"]
                                    ? loaders["loadingText"]
                                    : loadingTextOnUI
                            }
                        />
                    ) : null
                }
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    generatedLinks: state.remoteConnection.generatedLinks,
});

export default withRouter(
    connect(mapStateToProps, {
        getEdgeServerStatus,
        getGeneratedLinks,
        clearRemoteConnectionData: clearData
    })(EdgePortal)
);