
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import close from "../../asset/image/close.svg";
import { getDecodeURI, getEncodedURI } from "../../utils/util";
import { UsageContext } from "./UsageDataContext";
import { useContext, useState } from "react";
import moment from "moment";
import { UPDATE_NEW_SUMMARY_END_DATE, UPDATE_NEW_SUMMARY_START_DATE } from "../../actions/types";

const Reports = (props) => {
    const { open, setOpen, setGenerateFilter, location, history } = props;
    const dispatch = useDispatch();

    const handleClick = () => {
        setOpen(false);
        setGenerateFilter(true);
    }

    const handleClose = () => {
            setOpen(false);
            const start = moment(Date.now()).utc().subtract(1, 'hour');
            const end = moment(Date.now()).utc();
            dispatch({ type: UPDATE_NEW_SUMMARY_END_DATE, payload: end});
            dispatch({ type: UPDATE_NEW_SUMMARY_START_DATE, payload: start});
            let params: any = getDecodeURI(location?.search);
            delete params.startDate;
            delete params.endDate;
            params.interval = '1h';
            doNavigate(params);
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    return (
        <div className="Form">
            <Dialog 
                disableEnforceFocus
                open={open}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                sx={{borderRadius: '8px', width:'550px'}}
                className='config-deploy-inventory-popup'
            >
                <div className="menuScroll">
                    <DialogTitle className='create-default-config-title'>
                        <span>Report</span>
                        <img src={close} title="Close" onClick={handleClose} />
                    </DialogTitle>
                    <DialogContent>
                        <Grid container className="deploy-inventory-content">
                            <Grid className="check-interval-text">
                                <span>You'r trying to fetch the data for more than 7 days, would you like to continue to generate the Report.</span><br /><br />
                                <span>Click on <span className="continue-generate">"Continue"</span> to proceed.</span>
                            </Grid>
                            <DialogActions className="config-create-actions">
                                <Button className="config-cancel-btn" onClick={handleClose}>Cancel</Button>
                                <Button className="config-create-btn" onClick={handleClick}>Continue</Button>
                            </DialogActions>
                        </Grid>
                    </ DialogContent>
                </div>
            </Dialog>
        </div>
    )
}
  
const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, { })(Reports)
);