import { Grid, Paper, Stack } from "@mui/material";
import { useContext } from "react";

import './firewallReport.css';
import { FirewallReportContext } from "./FirewallReportContext";

export interface WidgetData {
    packetsCount: number | '-';
    ruleName: string;
    site_name: string;
}

interface Props {
    header: string
    data: WidgetData;
}

const FirewallReportWidget = (props: Props) => {
    const { header, data } = props;

    const { handleRuleNameChange, onSelectFilters,   } = useContext(FirewallReportContext)

    
    const firewallSiteFilter = () => {
        if(data.packetsCount == 0)
            return;
        onSelectFilters({filterSiteName: data.site_name})
    }

    const firewallRulenameFilter = () => {
        if (data.packetsCount == 0)
            return;
        handleRuleNameChange(data.ruleName);
        onSelectFilters({filterRuleName: data.ruleName});
    }
    
    return (
        <div className="widgetContainer">
            <Grid container>
                <Grid className="header-name padding-15" item xs={12}>
                    {header}
                </Grid>
                <Grid item xs={12} className="padding-15 firewall-filters">
                    <Stack direction='row' spacing={5}>
                        <Stack className='firewall-stack-value'>
                            <span className="rule-name">Site name</span>
                            <span onClick={firewallSiteFilter} className="rule-value cardFilter">{data.packetsCount > 0 ? data.site_name : '-'}</span>
                        </Stack>
                        <Stack>
                            <span className="rule-name">Rule name</span>
                            <span onClick={firewallRulenameFilter} className="rule-value cardFilter">{data.packetsCount > 0 ? data.ruleName : '-'}</span>
                        </Stack>
                        <Stack>
                            <span className="rule-name">No of Packets</span>
                            <span>{data.packetsCount > 0 ? data.packetsCount : '-'}</span>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </div>
    )
}

export default FirewallReportWidget;