import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid, Button } from "@mui/material";
import _ from "lodash";
import { highAvailability, getHighAvailabilityDevice, createHighAvailability, syncDevice, updateDevice, deleteHighAvailability } from "../../actions/Users/authenticateHighAvailability";
import "./HighAvailability.css";
import HaLogData from "./HighAvailibilityLogData"
import HaLogFilters from "./HighAvailibilityLogFilters"

import { getEdgeEvents, getEdgeEventsFilter} from "../../actions/Users/authenticateEdgeEvents";
import { getDecodeURI, getEncodedURI } from "../../utils/util";
import { DEFAULT_PAGE_SIZE } from "../../constants/Constants";
import { useHistory, useLocation } from "react-router-dom";

const HighAvailability = (props) => { 
  const { authReducer, highAvailability, selectedVessel, getHighAvailabilityDevice, createHighAvailability, syncDevice, updateDevice, deleteHighAvailability, getEdgeEvents, getEdgeEventsFilter, newSummaryStartDate, newSummaryEndDate } = props;

  const history = useHistory();

  const [errorObj, setErrorObj] = useState(false);
  const [resultValue, setResultValue] = useState<any>({});
  const [selectedOu, setSelectedOu] = useState<any>({});
  const [devicesId,setDeviceId]=useState<any>([]);
  const [primaryDevice, setprimaryDevice] = useState('');
  const [secondaryDevice, setSecondaryDevice] = useState('');
  const [servingDevice, setServingDevice] = useState('');
  const [defaultPrimaryCheck, setDefaultPrimaryCheck]=useState(resultValue?.serving_device == resultValue?.primary_device ? true : false)
  const [defaultSecondaryCheck, setDefaultSecondaryCheck] = useState(resultValue?.serving_device == resultValue?.secondary_device ? true : false)
  const [trackServingDevicePri, setTrackServingDevicePri]= useState(false)
  const [trackServingDeviceSec, setTrackServingDeviceSec]= useState(false)
  const [autoUpdate, setAutoUpdate] = useState(false);

  const deviceNameAndIdSeparator = " | "
  const location = useLocation();

  const _q = getDecodeURI(location?.search);
  const [searchValue, setSearchValue] = useState<String>(_q.hasOwnProperty("search") && _q.search.trim() !== '' ? _q.search : "");


  const DEFAULT_PAGE = 1;
  const [page, setPage] = useState<number>(_q.hasOwnProperty("page") && '' != _q.page.trim() ? parseInt(_q.page) : DEFAULT_PAGE);
  const [limit, setLimit] = useState<number>(_q.hasOwnProperty("limit") && '' != _q.limit.trim() ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);

  const [filters, setFilters] = useState<any>({
    startTime: newSummaryStartDate,
    endTime: newSummaryEndDate,
    locationId: selectedVessel,
    pageSize: limit,
    offset: (page-1) * limit,
    page : page,
    deviceIdFilter: "",
    moduleFilter: "",
    levelFilter: "",
    search: "",
    searchValue : searchValue
  })

  const defaultFilters = {
    startTime: newSummaryStartDate,
    endTime: newSummaryEndDate,
    locationId: selectedVessel,
    pageSize: DEFAULT_PAGE_SIZE,
    offset: (DEFAULT_PAGE-1) * DEFAULT_PAGE_SIZE,
    page : DEFAULT_PAGE,
    deviceIdFilter: "",
    moduleFilter: "",
    levelFilter: "",
    search: "",
    searchValue : searchValue
  }

  useEffect (() => {
    defaultActions();
  },[selectedVessel])

  useEffect (() => {
    let _q: any = getDecodeURI(location.search);
    let params = _q;
    // if(_q?.ouName) {
    //   params['ouName'] = _q.ouName;
    // }
    // if(_q?.k4Ids){
    //   params["k4Ids"] = _q.k4Ids
    // }
    // if(_q.interval) {
    //   params['interval'] = _q.interval;
    // }
    if(filters?.searchValue){
      params["search"] = filters.searchValue
    }

    if(filters?.startTime){
      params["startDate"] = filters.startTime.valueOf()
    }

    if(filters?.endTime){
      params["endDate"] = filters.endTime.valueOf()
    }

    if(filters?.page){
      params["page"] = filters.page
    }

    if(filters?.pageSize){
      params["limit"] = filters.pageSize
    }
    // if(_q?.sideNav) {
    //   params['sideNav'] = _q.sideNav;
    // }
    history.replace({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });

  },[filters])

  useEffect (() => {
    if(autoUpdate){
      defaultActions();
      setAutoUpdate(false);
    }
  },[autoUpdate])

  const defaultActions = () =>{
    setDefaultPrimaryCheck(false);
    setDefaultSecondaryCheck(false);
    highAvailability(selectedVessel);
    getHighAvailabilityDevice(selectedOu.id, selectedVessel)
  }

  useEffect (() => {
    if(!_.isEmpty(authReducer.highAvailability)) {
      setErrorObj(authReducer.highAvailability.hasOwnProperty('data') && authReducer.highAvailability.data.hasOwnProperty('errors'))
      if(!(authReducer.hasOwnProperty("highAvailability") && authReducer.highAvailability.hasOwnProperty("primary_device"))){
        return;
      }
      let data = authReducer.highAvailability ;
      if(data?.serving_device == data?.primary_device) {
        setDefaultPrimaryCheck(true);
        setDefaultSecondaryCheck(false)
      }
      else if(data?.serving_device == data?.secondary_device) {
        setDefaultSecondaryCheck(true)
        setDefaultPrimaryCheck(false);
      }
      if(data.primary_device_name){
        data.primary_device =  data.primary_device_name + deviceNameAndIdSeparator + data.primary_device;
      }
      if( data.secondary_device_name){
        data.secondary_device =  data.secondary_device_name + deviceNameAndIdSeparator + data.secondary_device;
      }
      setResultValue(data)
    }
  },[authReducer.highAvailability])

  useEffect (()=> {
    if(!_.isEmpty(authReducer.getHighAvailabilityDevice)) {
      const arr:any =[];
      const data = authReducer.getHighAvailabilityDevice;
      data?.data?.manage_location.forEach((e)=>{
        e.products?.forEach((product)=>{
          product.devices?.forEach((id)=>{
            if(id.id?.includes("ES")) {
              arr.push(id.name + deviceNameAndIdSeparator + id.id)
            }
          })
        })
      })
      setDeviceId(arr)
    }
  },[authReducer.getHighAvailabilityDevice])

  useEffect(() => {
    if (!_.isEmpty(authReducer.selectedOu) && authReducer.selectedOu.hasOwnProperty("id") && authReducer.selectedOu.id
        && authReducer.selectedOu.hasOwnProperty("name") && authReducer.selectedOu.name) {
          setSelectedOu(authReducer.selectedOu)
    }
  }, [authReducer.selectedOu]);

  const handleChangePrimary = (e, newValue = "") => {
    setprimaryDevice(newValue)
  }

  const handleChangeSecondary = (e, newValue = "") => {
    setSecondaryDevice(newValue)
  }

  const handleChangeServing = (e) => {
    if(e.target.value == 'primary') {
      if(_.isEmpty(primaryDevice)){
        return
      }
      setTrackServingDevicePri(true)
      setTrackServingDeviceSec(false)
      setServingDevice(primaryDevice)
    }
    else if(e.target.value == 'secondary') {
      if(_.isEmpty(secondaryDevice)){
        return
      }
      setTrackServingDevicePri(false)
      setTrackServingDeviceSec(true)
      setServingDevice(secondaryDevice)
    }
  }

  useEffect (() => {
    if(!_.isEmpty(authReducer.createHighAvailability) || !_.isEmpty(authReducer.updateDevice) || !_.isEmpty(authReducer.syncDevice)) {
      const data = authReducer.createHighAvailability;
      setAutoUpdate(true);
      resetFilterParamsToDefault()
    }
  },[authReducer.createHighAvailability, authReducer.updateDevice, authReducer.syncDevice])

  useEffect (() => {
    if(!_.isEmpty(authReducer.deleteHighAvailability)) {
      setAutoUpdate(true);
      setErrorObj(true);
      setSecondaryDevice('');
      setprimaryDevice('');
      setTrackServingDevicePri(false);
      setTrackServingDeviceSec(false);
      resetFilterParamsToDefault()
    }
  },[authReducer.deleteHighAvailability])

  const resetFilterParamsToDefault = () => {
    let params: any = getDecodeURI(location?.search)
    let p = {}
    if(params?.k4Ids){
      p["k4Ids"] = params.k4Ids
    }
    if(params?.limit){
      p["limit"] = params.limit
    }

    setFilters(defaultFilters)
    getHaLogTableData(defaultFilters)
  }

  const createButtonClick = () => {
    setResultValue({
      primary_device: primaryDevice,
      secondary_device: secondaryDevice,
      serving_device: servingDevice
    })
    let payload = getPayload(primaryDevice, secondaryDevice, servingDevice);
    createHighAvailability(selectedVessel, payload)
  }

 const syncButtonClick = () => {
   syncDevice(selectedVessel)
 }

  const getPayload = (pDevice, sDevice, serving_device) => {
    pDevice = pDevice.includes(deviceNameAndIdSeparator) ? pDevice.split(deviceNameAndIdSeparator)[1] : pDevice;
    sDevice = sDevice.includes(deviceNameAndIdSeparator) ? sDevice.split(deviceNameAndIdSeparator)[1] : sDevice;
    serving_device = serving_device.includes(deviceNameAndIdSeparator) ? serving_device.split(deviceNameAndIdSeparator)[1] : serving_device;

    let payload = {
      primary_device: pDevice,
      secondary_device: sDevice,
      serving_device: serving_device
    }
    return payload;
  }

 const updateButtonClick = () => {
   let pDevice = resultValue?.primary_device;
   let sDevice = resultValue?.secondary_device;
   let serving_device = resultValue?.serving_device;
   setResultValue({
    primary_device: pDevice,
    secondary_device: sDevice,
    serving_device: serving_device
  })
   let payload = getPayload(pDevice, sDevice, serving_device);
   updateDevice(selectedVessel, payload)
 }

  const deleteButtonClick = () => {
    deleteHighAvailability(selectedVessel);
  }

 const handlePrimaryDeviceChange = (e, newValue = "") => {
  setResultValue({
    primary_device: newValue,
    secondary_device: resultValue?.secondary_device,
    serving_device: resultValue?.serving_device
  })
 }

 const handleSecondaryDeviceChange = (e, newValue="") => {
  setResultValue({
    primary_device: resultValue?.primary_device,
    secondary_device: newValue,
    serving_device: resultValue?.serving_device
  })
 }

 const handleServingDeviceChange = (e) => {
  if(e.target.value == "primary"){
    setDefaultPrimaryCheck(true);
    setDefaultSecondaryCheck(false)
    setResultValue({
      primary_device: resultValue?.primary_device,
      secondary_device: resultValue?.secondary_device,
      serving_device: resultValue?.primary_device
    })
  }
  else if(e.target.value == "secondary"){
    setDefaultSecondaryCheck(true)
    setDefaultPrimaryCheck(false);
    setResultValue({
      primary_device: resultValue?.primary_device,
      secondary_device: resultValue?.secondary_device,
      serving_device: resultValue?.secondary_device
    })
  }
 }

  const getHaLogTableData = (params) => {

    let _startDate = newSummaryStartDate;
    let _endDate = newSummaryEndDate;
    let _limit = params.hasOwnProperty("limit") ? params.limit : filters.pageSize;
    let _page = params.hasOwnProperty("page") ? params.page : filters.page;
    let _searchValue = params.hasOwnProperty("searchValue") ? params.searchValue : filters.searchValue;

    let parameters = {
      startTime: _startDate,
      endTime: _endDate,
      locationId: selectedVessel,
      pageSize: _limit,
      offset: (_page-1) * _limit,
      page : _page,
      deviceIdFilter: "",
      moduleFilter: "",
      levelFilter: "",
      search: "",
      searchValue : _searchValue,
      sourceFilter: ""
      // parameters.sourceFilter = filters.sources?.length > 0 ? `and source IN (${filters.sources.map(s => `'${s}'`).join()})`: '';
    }

    parameters.moduleFilter = 'and module IN (\'HA\')';
    parameters.deviceIdFilter = '';
    parameters.levelFilter = '';
    if (_searchValue) {
      parameters.search = `and (concat_ws(',',if(device_id IS NULL,'',device_id),if(deviceAlias IS NULL,'',deviceAlias),if(level IS NULL,'',level),if(deviceAlias IS NULL,'',deviceAlias),
      if(description IS NULL,'',description),if(source IS NULL,'',source)) ILIKE '%${_searchValue}%' OR  b_metadata ILIKE '%${_searchValue}%')`
    }

    setFilters(parameters);

    getEdgeEventsFilter(parameters);
    getEdgeEvents(parameters);
  }

 return (
  <Grid className="ha-main-container">
    <div className="Form">
      {devicesId.length > 1  ?  <Grid className="am-table">
        { !errorObj ? (
          <Grid item container xs={12} className="ParentContainer" direction="column">
              <Grid item xs={2}> 
                <Grid className="autoCompleteDiv">
                  <Autocomplete
                    disableClearable
                    id="status_list" className="fliterAlertDropdown filterBy"
                    options={devicesId}
                    value={ resultValue && resultValue?.primary_device ? resultValue.primary_device.split(deviceNameAndIdSeparator)[0] : ""}
                    onChange={(e, newValue: any) => { handlePrimaryDeviceChange(e, newValue) }}
                    renderInput={(params) => (
                      <TextField {...params} title = { resultValue && resultValue?.primary_device ? resultValue.primary_device : ""} size="small" label="Primary" InputLabelProps={{ className: "filterStateName_filterBy" }} />
                    )}
                  />
                </Grid>
                <Grid className="radioButtonClick">
                  <input type="checkbox" value={"primary"} checked={defaultPrimaryCheck} name="id" onChange={handleServingDeviceChange}/>
                  {defaultPrimaryCheck ? <div className="servingDevice"><div>Serving</div></div> : ''}
                </Grid>
              </Grid>
              <Grid className="update-syncBtn">
                <Button
                  className="UpdateBtn"
                  onClick={()=> {
                    updateButtonClick()
                  }}
                  >
                  Update
                </Button>
                <Button
                  className="UpdateBtn"
                  onClick={()=> {
                    deleteButtonClick()
                  }}
                  >
                  Delete
                </Button>
                <Button
                  className="syncBtn"
                  onClick={()=>{
                      syncButtonClick()
                  }}
                  >
                  Sync
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Grid className="autoCompleteDiv">
                  <Autocomplete
                    disableClearable
                    id="status_list" className="fliterAlertDropdown filterBy"
                    options={devicesId}
                    value={ resultValue && resultValue?.secondary_device ? resultValue?.secondary_device.split(deviceNameAndIdSeparator)[0] : ""}
                    onChange={(e, newValue: any) => { handleSecondaryDeviceChange(e, newValue) }}
                    renderInput={(params) => (
                        <TextField {...params} label="Secondary" title = { resultValue && resultValue?.secondary_device ? resultValue?.secondary_device: ""} size="small" InputLabelProps={{ className: "filterStateName_filterBy" }} />
                    )}
                  />
                </Grid>
                <Grid className="radioButtonClick">
                  <input type="checkbox" value={"secondary"} checked={defaultSecondaryCheck} name="id" onChange={handleServingDeviceChange} />
                  {defaultSecondaryCheck ? <div className="servingDevice"><div>Serving</div></div> : ''}
                </Grid>
              </Grid>
          </Grid>
          ) : (
          <Grid item container xs={12} className="createParentContainer" direction="column">
            <Grid item xs={2}> 
              <Grid className="autoCompleteDiv">
                <Autocomplete
                  disableClearable
                  id="status_list" className="fliterAlertDropdown filterBy"
                  options={devicesId}
                  value={primaryDevice.split(deviceNameAndIdSeparator)[0]}
                  onChange={(e, newValue: any) => { handleChangePrimary(e, newValue) }}
                  renderInput={(params) => (
                    <TextField {...params} title = {primaryDevice} label="Primary" size="small" InputLabelProps={{ className: "filterStateName_filterBy" }} />
                  )}
                />
              </Grid>
              <Grid className="radioButtonClick">
                <input type="checkbox" className={_.isEmpty(primaryDevice)?"cursor-not-allowed":""} value={'primary'} checked={trackServingDevicePri} onChange={handleChangeServing} />
                {trackServingDevicePri ? <div className="servingDevice">Serving</div> : ''}
              </Grid>
            </Grid>
            <Grid className="update-syncBtn">
              <Button
                className={!_.isEmpty(primaryDevice) && !_.isEmpty(secondaryDevice) && !_.isEmpty(servingDevice) ? "CreateBtn":"CreateBtn-cursor-not-allowed"}
                onClick={()=>{
                  !_.isEmpty(primaryDevice) && !_.isEmpty(secondaryDevice) && !_.isEmpty(servingDevice) && createButtonClick()
                }}
                >
                CREATE
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Grid className="autoCompleteDiv">
                <Autocomplete
                  disableClearable
                  id="status_list" className="fliterAlertDropdown filterBy"
                  options={devicesId}
                  value={secondaryDevice.split(deviceNameAndIdSeparator)[0]}
                  onChange={(e, newValue: any) => { handleChangeSecondary(e, newValue) }}
                  renderInput={(params) => (
                      <TextField {...params} title = {secondaryDevice} label="Secondary" InputLabelProps={{ className: "filterStateName_filterBy" }} />
                  )}
                />
              </Grid>
              <Grid className="radioButtonClick">
                <input type="checkbox" className={_.isEmpty(secondaryDevice)?"cursor-not-allowed":""} value={'secondary'} checked={trackServingDeviceSec} onChange={handleChangeServing}/>
                {trackServingDeviceSec ? <div className="servingDevice">Serving</div> : ''}
              </Grid>
            </Grid>
          </Grid>
           )
        }
      </Grid> : null} 
    </div>
    <div className=''>
        <div className='ha-log-data'>
            <HaLogFilters selectedVessel = {selectedVessel} filters= {filters} getHaLogTableData = {getHaLogTableData}/>
            <HaLogData selectedVessel = {selectedVessel} filters= {filters} getHaLogTableData = {getHaLogTableData}/>
        </div>
    </div>
  </Grid>
 )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate
});
export default withRouter(
    connect(mapStateToProps, {
      highAvailability,
      getHighAvailabilityDevice,
      createHighAvailability,
      syncDevice,
      updateDevice,
      deleteHighAvailability,
      getEdgeEvents,
      getEdgeEventsFilter
    })(HighAvailability)
);