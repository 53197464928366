import { createTheme } from '@material-ui/core/styles'
import "./default.css";
import defaultTheme from "./default";
const theme = createTheme();
const overrides = {
  typography: {
    fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
    fontStyle: 'normal',

    h1: {
      fontSize: "1.4rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.7rem",
        color: "rgba(0, 0, 0, 0.8)",
      },
    },
    h2: {
      fontSize: "1.1rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.4rem",
      },
    },
    h3: {
      fontSize: "1rem",
      // "@media (min-width:600px)": {
      //   fontSize: "1rem",
      // },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.3rem",
      },
    },
    h4: {
      fontSize: "0.8rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
    },
    h5: {
      fontSize: "0.7rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
    },
    h6: {
      fontSize: "0.6rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
      },
    },
    tableCellColorEven : { '&:nth-of-type(odd)': {
      backgroundColor: "pink",
    },},
  },
};

export default {
  default: createTheme({
    ...defaultTheme,
    ...overrides,
  }),
};
