import { makeStyles } from "@material-ui/styles";
import { alpha } from "@material-ui/core";
import RECT from "./login-rec.png";
import { primaryColor } from "../../constants/Color";
import "./styles.css";

export default makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoContainer: {
    position: "relative",
    width: "607.14px",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    backgroundImage: "url(" + RECT + ")",
    backgroundSize: "cover",
  },
  logoCaption: {
    fontWeight: "700 !important",
    fontSize: "26px !important",
    lineHeight: "30px",
    marginLeft: "30px !important",
    maxWidth: "600px",
    color: "#254B83",
    "@media (min-width: 800px)": {
      marginTop: "170px",
    },
    "@media (max-width: 780px)": {
      marginTop: "50px",
    },
  },
  logoCaptionMobile: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "23px",
    marginLeft: "25px !important",
    maxWidth: "313px",
    color: "#254B83",
    marginTop: "25px",
  },
  copyright: {
    position: "absolute",
    bottom: 20,
    height: "16px",
    fontSize: "12px !important",
    fontWeight: 500,
    lineHeight: "14px",
    color: "#fff",
    display:"flex",
    alignItems: "center"
  },
  loginText: {
    fontWeight: "500 !important",
    lineHeight: "33px",
    color: "#384089",
    marginLeft: "30px",
    marginTop: "40px",
    fontSize: "14px",
    textDecoration: "underline",
    textUnderlineOffset: "7px",
  },
  submitBtnWrapper: {
    marginTop: "60px",
    marginLeft: "33px",
  },
  submitBtnPasswordWrapper: {
    marginTop: "60px",
    "@media (min-width: 760px)": {
      marginLeft: "35px",
    },
    "@media (max-width: 750px)": {
      marginLeft: "22px",
    },
  },
  forgotPass: {
    fontSize: "12px !important",
    cursor: "pointer",
    marginTop: "20px",
    color: "#264C86",
    lineHeight: "14px",
    opacity: "0.75",
    justifyContent: "flex-end",
    display: "flex",
    whiteSpace: "nowrap",
  },
  RemeberMe: {
    fontSize: "12px !important",
    marginTop: "20px",
    color: "#273050",
    lineHeight: "14px",
    opacity: "0.75",
    whiteSpace: "nowrap",
  },
}));
