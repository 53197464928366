import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, InputAdornment, Typography, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import CustomAlert from "../../components/CustomAlert";
import { verifyUser } from "../../actions/Users/authenticate";
import DialogBox from "../../components/DialogBox";
import Scroll from "react-scroll";
import _ from "lodash";
import "./Registration.css";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "libphonenumber-js";
import CustomSnackbar from "../../components/Loader/CustomSnackbar";
import CustomLoader from "../../components/Loader/CustomLoader";
import { Base64 } from "js-base64";
import Logo from "../../asset/image/k4Logo.png";
import Box from "@mui/material/Box";
import useStyles from "./style";
import LogoMobileView from "../../asset/image/k4LogoMobileView.png";
import TextField from "@mui/material/TextField";
import PhoneInput from "react-phone-input-2";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CustomPasswordValidator from "../../components/CustomPasswordValidator";

const scroll = Scroll.animateScroll;

function Registration({ history, authReducer, errorReducer, verifyUser }) {
  const { userNamePar, passwordPar } = useParams();
  const classes = useStyles();
  const [userName, setUserName] = useState(userNamePar);
  const [anchorEl, setAnchorEl] = React.useState(null);
  let [contactNo, setContactNo] = useState(null);
  let [contactNoCountryCode, setContactNoCountryCode] = useState("");
  let [contactNoDialCode, setContactNoDialCode] = useState("");
  const [oldPassword, setOldPassword] = useState(passwordPar);
  const [newPassword, setNewPassword] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [helperTextList, setHelperTextList] = useState({
    firstName: "",
    lastName: "",
    contactNo: "Contact number is invalid",
  });
  const [notErrorInFields, setNotErrorInFields] = useState({
    firstName: false,
    lastName: false,
    contactNo: false,
    newPassword: false,
  });
  let [loaders, setLoaders] = useState({
    showLoader: false,
    loadingText: "",
  });
  let [snackBar, setSnackBar] = useState({
    showSnackbar: false,
    snackbarVariant: "",
    snakBarMessage: "",
    position: "left",
  });

  const [alertMsg, setAlertMsg] = useState({ msg: null, type: "error" });
  let [allFieldsIsValidORnot, setAllFieldsIsValidORnot] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [isImageError, setImageError] = useState(false);
  const [validator, setValidator] = useState(notErrorInFields)
  const open = Boolean(anchorEl);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 15000);
  }, [showAlert]);

  useEffect(() => {
    if (errorReducer && errorReducer.message && "" !== errorReducer.message) {
      let message = "Unable to complete registration, unknown error.";
      if (errorReducer.description) {
        if ("string" === typeof errorReducer.description) {
          message = errorReducer.description;
        } else if (
          errorReducer?.description?.message &&
          "string" === typeof errorReducer.description.message &&
          "" !== errorReducer.description.message.trim()
        ) {
          message = errorReducer.description.message;
        }
      }
      setLoaders({ showLoader: false, loadingText: "" });
      setAlertMsg({ msg: message, type: "error" });
      setShowAlert(true);
      errorReducer.error = {};
    } else if (errorReducer && errorReducer?.error?.status == 0 && errorReducer?.error?.message == "User already registered.") {
      setLoaders({ showLoader: false, loadingText: "" });
      setAlertMsg({ msg: errorReducer?.error?.message, type: "error" });
      setShowAlert(true);
      errorReducer.error = {};
    } else if (errorReducer && errorReducer?.error?.status == 0 && errorReducer?.error?.message == "Invalid registration link.") {
      setLoaders({ showLoader: false, loadingText: "" });
      setAlertMsg({ msg: errorReducer?.error?.message, type: "error" });
      setShowAlert(true);
      errorReducer.error = {};
    } else if (errorReducer && errorReducer?.error?.status == 0 && errorReducer?.error?.message == "Invitation link got expired.") {
      setLoaders({ showLoader: false, loadingText: "" });
      setAlertMsg({ msg: errorReducer?.error?.message, type: "error" });
      setShowAlert(true);
      errorReducer.error = {};
    } else {
      setLoaders({ showLoader: false, loadingText: "" });
    }
  }, [errorReducer]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.verifyUser)) {
      setLoaders({ showLoader: false, loadingText: "" });
      setShowPopUp(true);
      authReducer.verifyUser = {};
    } else {
      setLoaders({ showLoader: false, loadingText: "" });
    }
  }, [authReducer.verifyUser]);

  // useEffect(()=>{
  //   if(!_.isEmpty(contactNo) && !_.isEmpty(contactNoCountryCode) && !_.isEmpty(contactNoDialCode)){
  //     checkContactNo(contactNo);
  //   }
  // }, [contactNoCountryCode, contactNoDialCode, contactNo])
  
  const checkAllFieldsValidation = (notErrorInFields) => {
    let isExist = 0;
    Object.keys(notErrorInFields).map((keysName) => {
      if (notErrorInFields[keysName] === false) {
        isExist = 1;
        setAllFieldsIsValidORnot(notErrorInFields[keysName]);
      }
    });
    if (0 === isExist) setAllFieldsIsValidORnot(true);
    setNotErrorInFields(notErrorInFields);
  };

  const handlePhoneNumber = (value, country, e, formattedValue) => {
    let checkVlidation = formattedValue.split("+" + country.dialCode)[1];
    let countryCode = country.countryCode;
    let _notErrorInFields = {...notErrorInFields}
    if (checkVlidation && checkVlidation !== "") {
      checkVlidation = checkVlidation.trim()
      _notErrorInFields["contactNo"] = isValidPhoneNumber(
        checkVlidation,
        countryCode.toUpperCase()
      ) ;
      // || _notErrorInFields["contactNo"] !== null
    } else { _notErrorInFields["contactNo"] = false }
    
    if(contactNoCountryCode != country.countryCode && !_.isEmpty(formattedValue)){
      let enteredDailCode = formattedValue.split(' ')[0];
      formattedValue = "+" + country.dialCode + "" + formattedValue.split(enteredDailCode)[1];
    }

    setContactNo(formattedValue);
    setContactNoCountryCode(country.countryCode);
    setContactNoDialCode("+" + country.dialCode + " ");
    checkAllFieldsValidation(_notErrorInFields);
    setValidator(_notErrorInFields)
  };

  const checkContactNo = (value) => {
    let _notErrorInFields = {...notErrorInFields}
    if (value && "" !== value && contactNoCountryCode && contactNoDialCode) {
      let checkVlidation = value.split(contactNoDialCode)[1];
      
      if (checkVlidation && checkVlidation !== "") {
        _notErrorInFields["contactNo"] = isValidPhoneNumber( checkVlidation, contactNoCountryCode.toUpperCase());
      } else {
        _notErrorInFields["contactNo"] = false
      }
    } else { 
      _notErrorInFields["contactNo"] = false
    }
    checkAllFieldsValidation(_notErrorInFields);
  };

  const checkFirstName = (value) => {
    const freg = /^[a-zA-Z0-9\s- ]+$/;
    let _notErrorInFields = {...notErrorInFields}
    const isValid = freg.test(value)
    _notErrorInFields["firstName"] = isValid;
    checkAllFieldsValidation(_notErrorInFields);

    if (!value) {
      helperTextList["firstName"] = "First name is mandatory"
      setHelperTextList(helperTextList)
    } else if (!isValid) {
      helperTextList["firstName"] = "Special characters are not allowed"
      setHelperTextList(helperTextList)
    }
  };

  const checkLastName = (value) => {
    const lreg = /^[a-zA-Z0-9\s- ]+$/;
    let _notErrorInFields = {...notErrorInFields}
    const isValid = lreg.test(value)
    _notErrorInFields["lastName"] = isValid;
    checkAllFieldsValidation(_notErrorInFields);

    if (!value) {
      helperTextList["lastName"] = "Last name is mandatory"
      setHelperTextList(helperTextList)
    } else if (!isValid) {
      helperTextList["lastName"] = "Special characters are not allowed"
      setHelperTextList(helperTextList)
    }
  };

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;

    if (name === "userName") {
      setUserName(event.target.value);
    } else if (name === "contactNo") {
      checkContactNo(event.target.value);
      setContactNo(event.target.value);
    } else if (name === "oldPassword") {
      setOldPassword(event.target.value);
    } else if (name === "newPassword") {
      setNewPassword(event.target.value);
    } else if (name === "reEnterPassword") {
      setReEnterPassword(event.target.value);
    } else if (name === "firstName") {
      checkFirstName(event.target.value);
      setFirstName(event.target.value);
    } else if (name === "lastName") {
      checkLastName(event.target.value);
      setLastName(event.target.value);
    }
  };

  const handleVerifyUser = () => {
    let payload = {};
    checkContactNo(contactNo);
    checkFirstName(firstName);
    checkLastName(lastName);
    if (!allFieldsIsValidORnot) {
      setAlertMsg({ msg: "Please fill all required fields.", type: "error" });
      setShowAlert(true);
      return;
    }
    if (!_.isEmpty(contactNo) && !_.isEmpty(userName) && !_.isEmpty(firstName) && !_.isEmpty(lastName) && !_.isEmpty(newPassword) && !_.isEmpty(reEnterPassword) && !_.isEmpty(oldPassword) && typeof firstName === "string" && typeof lastName === "string") {
      if(reEnterPassword !== newPassword){
        setAlertMsg({ msg: "Password and confirm password didn’t match", type: "error" });
        setShowAlert(true);
        return
      }
      setLoaders({
        showLoader: true,
        loadingText: "Registering user please wait...",
      });
      scroll.scrollToTop();
      payload = {
        userName: userName,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        currentPassword: oldPassword,
        newPassword: Base64.encode(newPassword),
        phone: contactNo,
      };
      verifyUser(payload);
    } else {
      if(_.isEmpty(reEnterPassword)){
        setAlertMsg({ msg: "Please enter a confirm password.", type: "error" });
        setShowAlert(true);
      }
      else {
        setAlertMsg({ msg: "Please enter all mandatory fields.", type: "error" });
        setShowAlert(true);
      }
    
    }
  };

  const handleSnackBarClose = () => {
    setSnackBar({
      finalMessage: "",
      showSnackbar: false,
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleImgError = (host) => {
    if(host) {
      setImageError(true);
    }
  }

  const handlePasswordValidation = (result) => {
    notErrorInFields["newPassword"] = result
    setNotErrorInFields(notErrorInFields)
    checkAllFieldsValidation(notErrorInFields);
  }

  const [isSvgError, setIsSvgError] = useState(false);
  const [isPngError, setIsPngError] = useState(false);

  const handleSvgError = () => {
    setIsSvgError(true);
  };

  const handlePngError = () => {
    setIsPngError(true);
  };

  return (
    <Grid container component="main" className={classes.root} >
      {showAlert && showAlert === true ? (<CustomAlert type={alertMsg.type} msg={alertMsg.msg} />) : null}
      <Grid item container xs={12} style={{ height: "100%" }}>
        <Box display={{ xs: "none", sm: "contents", md: "contents", lg: "contents" }}>
          <Grid xs={0} sm={6} md={6} lg={6} className={classes.logoContainer}>
          {authReducer.hostName && (
              <img
                onError={isSvgError ? handlePngError : handleSvgError}
                src={!isSvgError ? `https://k4storage.blob.core.windows.net/cdn/sportAssets/${authReducer.hostName}/k4Logo.svg` : (!isPngError ? `https://k4storage.blob.core.windows.net/cdn/sportAssets/${authReducer.hostName}/k4Logo.png` : Logo)}
                style={{ width: "50%" }}
                alt="K4 Logo"
              />
            )}
          </Grid>
        </Box>
        <Grid xs={12} sm={6} md={6} lg={6} className={classes.textContainer}>
          <Box display={{ xs: "none", sm: "contents", md: "contents", lg: "contents" }}>
            <Typography className={classes.logoCaption}> Registration </Typography>
            <Typography className={classes.loginText}> Please fill following fields for the Username : {userName} to complete the registration.
            </Typography>
          </Box>
          <Box display={{ xs: "contents", sm: "none", md: "none", lg: "none" }}>
            <Grid>
              <img src={LogoMobileView} style={{ width: "100%", marginTop: "-20px", height: "150px" }} />
              <Grid className="registration_loginMobileText"> Please fill following fields for the Username : {userName} to complete the registration.</Grid>
            </Grid>
            <Typography className={classes.logoCaptionMobile}> Registration </Typography>
          </Box>
          <Grid container item xs={12} className="registration_userNameContainer">
            <Grid xs={11} sm={10} md={5} lg={5}>
              <Box sx={{"& .MuiTextField-root": { m: 1, width: "100%"}}} className="registration_TextBoxMobileView">
                <TextField
                  id="outlined-basic"
                  label="First Name*"
                  variant="outlined"
                  placeholder="Type something"
                  size="small"
                  value={firstName}
                  name="firstName"
                  autoFocus={true}
                  onFocus={()=>{setNotErrorInFields({...notErrorInFields,firstName:true})}}
                  onBlur={()=>{checkAllFieldsValidation(notErrorInFields)}}
                  onChange={handleChange}
                  InputLabelProps={{ className: "registration_UserNameInput" }}
                  InputProps={{ className: "registration_labelTextbox" }}
                />
              </Box>
              {notErrorInFields["firstName"] === false ? (
                <div style={{ fontSize: "15px", paddingTop: "1px", color: "rgb(255, 0, 0)", paddingLeft: "40px"}}> {helperTextList["firstName"]}</div>
              ) : null}
            </Grid>
            <Grid xs={11} sm={10} md={5} lg={5}>
              <Box sx={{"& .MuiTextField-root": { m: 1, width: "100%"}}} className="registration_TextBoxMobileView">
                <TextField
                  id="outlined-basic"
                  label="Last Name*"
                  variant="outlined"
                  placeholder="Type something"
                  size="small"
                  value={lastName}
                  name="lastName"
                  onChange={handleChange}
                  onFocus={()=>{setNotErrorInFields({...notErrorInFields,lastName:true})}}
                  onFoucusOut={()=>{checkAllFieldsValidation(notErrorInFields) }}
                  // onBlur={()=>{checkAllFieldsValidation(notErrorInFields) }}
                  InputLabelProps={{ className: "registration_UserNameInput" }}
                  InputProps={{ className: "registration_labelTextbox" }}
                />
              </Box>
              {notErrorInFields["lastName"] === false ? (
                <div style={{ fontSize: "15px", paddingTop: "1px", color: "rgb(255, 0, 0)", paddingLeft: "40px"}}> {helperTextList["lastName"]}</div>
              ) : null}
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid xs={11} sm={10} md={5} lg={5} >
              <Box sx={{ "& .MuiTextField-root": { m: 1, height: "10px" }}} className="contactNumber">
                <PhoneInput
                  country={"us"}
                  value={contactNo}
                  placeholder="Phone number"
                  inputProps={{
                    name: "contactNo",
                    required: true,
                    autoFocus: true,
                    autocomplete: "off",
                  }}
                  inputStyle={{ width: "100% !important" }}
                  searchStyle={{ paddingLeft: "0px" }}
                  enableSearch={true}
                  enableTerritories={true}
                  onChange={handlePhoneNumber}
                  onFocus={()=>{setNotErrorInFields({...notErrorInFields,contactNo:true})}}
                  // onBlur={()=>{checkAllFieldsValidation(validator)}}
                />
              </Box>
              {contactNo && notErrorInFields["contactNo"] === false ? (
                <div style={{ fontSize: "15px", paddingTop: "1px", color: "rgb(255, 0, 0)", paddingLeft: "40px"}}>Contact number is not valid</div>
              ) : null}
            </Grid>
          </Grid>
          <Grid container item xs={12} >
            <Grid xs={11} sm={10} md={5} lg={5}>
              <Box sx={{ "& .MuiTextField-root": { m: 1, width: "100%", marginTop: "20px"}}} className="registration_TextBoxMobileView">
                <TextField
                  id="outlined-basic"
                  label="Password*"
                  variant="outlined"
                  placeholder="Password"
                  size="small"
                  value={newPassword}
                  name="newPassword"
                  onChange={handleChange}
                  focused={true}
                  className="visibilityButton"
                  type={showPassword ? "text" : "password"}
                  InputLabelProps={{ className: "registration_UserNameInput" }}
                  InputProps={{
                    className: "registration_labelTextbox",
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
            <Grid xs={11} sm={9} md={5} lg={5}>
              <Box sx={{ "& .MuiTextField-root": { m: 1, width: "100%", marginTop: "20px"}}} className=" registration_TextBoxMobileView">
                <TextField
                  id="outlined-basic"
                  label="Confirm Password*"
                  variant="outlined"
                  placeholder="Confirm Password"
                  size="small"
                  className="visibilityButton"
                  value={reEnterPassword}
                  type={showConfirmPassword ? "text" : "password"}
                  name="reEnterPassword"
                  onChange={handleChange}
                  onFocus={()=>{setNotErrorInFields({...notErrorInFields,reEnterPassword:true})}}
                  InputLabelProps={{ className: "registration_UserNameInput" }}
                  InputProps={{
                    className: "registration_labelTextbox",
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                        >{showConfirmPassword ? (<Visibility />) : (<VisibilityOff />)}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid lg={10} md={10} sm={10} sx={12} className="registration_passwordRequirement">
            <CustomPasswordValidator newPassword={newPassword} reEnterPassword={reEnterPassword} onValidatorChangeHandler={handlePasswordValidation} styles={{width: "95%"}} />
          </Grid>
          <Grid item container spacing={5}>
            <Grid item xs={12} className="registerButtonContainer">
              <Button className="registerButton" variant="contained" disableRipple disableElevation
                disabled={!allFieldsIsValidORnot} onClick={handleVerifyUser}
              >
                REGISTER
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="User Successfully Added"
        ButtonLabel="Done"
        onClick={() => {
          window.location.href="/";
        }}
      />
      {loaders["showLoader"] ? (
        <CustomLoader
          showLoader={loaders["showLoader"]}
          loadingText={loaders["loadingText"] ? loaders["loadingText"] : "Please wait..."}
        />
      ) : null}

      <CustomSnackbar
        showSnackbar={snackBar["showSnackbar"]}
        variant={snackBar["snackbarVariant"]}
        message={snackBar["snakBarMessage"]}
        onClose={handleSnackBarClose}
        position={snackBar["position"]}
      />
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
    verifyUser,
  })(Registration)
);
