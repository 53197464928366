import React, { useState, useEffect, Fragment, useCallback } from "react";
import { Link, useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import moment from "moment-timezone";
import _ from "lodash";
import copyToClipboard from 'copy-to-clipboard';
import { toast } from "react-toastify";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AntSwitch from "../../../AntSwitch";
import { Icon, Input, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { TextField } from "@mui/material";
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import IconButton from '@mui/material/IconButton';
import EdgeLinkIcon from "../../../../asset/image/EdgeLinkIcon.svg";
import EdgeLinkIconDisabled from "../../../../asset/image/linkDisabled.svg";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@mui/icons-material/Close';

import { getGeneratedLinks, deleteRemoteConnectionLink, updateRemoteConnectionLink } from "../../remoteConnectionSlice";

import "./index.css"
import { convertDateTimeIntoTimezone, getDataFromLocalStorageParsed, getTimezoneCity } from "../../../../utils/util";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../../utils/constants";

function GeneratedLinks(props) {

    const { authReducer, generatedLinks, siteId, siteName, deviceId, filter, getGeneratedLinks, deleteRemoteConnectionLink, updateRemoteConnectionLink, selectedScannedDevices } = props

    const initialiseSdSelectionFilter = useCallback(() => {
        return !_.isEmpty(selectedScannedDevices) ? new Set(Object.keys(selectedScannedDevices).map(sd_id => `${selectedScannedDevices[sd_id].address}-${selectedScannedDevices[sd_id].port}`)) : null;
    }, [selectedScannedDevices])

    const [sdSelectionFilter, setSdSelectionFilter] = useState(initialiseSdSelectionFilter())
    const [deviceOnline, isDeviceOnline] = useState(true)

    useEffect(() => {
        setSdSelectionFilter(initialiseSdSelectionFilter())
    }, [selectedScannedDevices])

    const filterGeneratedLinks = useCallback(() => {
        return sdSelectionFilter ? generatedLinks.filter(rc => sdSelectionFilter.has(`${rc.address}-${rc.port}`)) : generatedLinks
    }, [sdSelectionFilter, generatedLinks])

    useEffect(() => {
        let requestAbortController = new AbortController();

        if (!_.isEmpty(siteId) && !_.isEmpty(deviceId)) {
            getGeneratedLinks(siteId, deviceId, filter, requestAbortController.signal);
        }

        return () => {
            requestAbortController.abort();
        }
    }, [siteId, deviceId, getGeneratedLinks, filter])

    useEffect(() => {
        if (!_.isEmpty(authReducer.edgeServerStatus) && authReducer.edgeServerStatus.data) {
            let _isVesselOnline = false;
            let endpoint_status: any[] = authReducer?.edgeServerStatus?.data?.getVesselsEndPointStatusResponse?.endpoint_status;
            if (!_.isEmpty(deviceId) && endpoint_status &&
                Array.isArray(endpoint_status) && endpoint_status.length > 0 &&
                endpoint_status[0].name) {
                if (endpoint_status[0].status && "STATUS_CONNECTED" === endpoint_status[0].status) {
                    _isVesselOnline = true;
                }
            }
            // isDeviceOnline(_isVesselOnline);
            authReducer.vesselEdgeInfo = {};
        }
    }, [authReducer.edgeServerStatus]);

    return (
        <TableContainer className="alertsTable-FleetDashboard">
            <Table aria-label="simple table" size="small">
                <TableHead className="tableHead alertsTable-tableHead">
                    <TableRow className="remcon-tableRow">
                        <TableCell className="rem-con-table-fleetDataTable font-wt-900">ALIAS</TableCell>
                        <TableCell className="rem-con-table-fleetDataTable font-wt-900">ADDRESS</TableCell>
                        <TableCell className="rem-con-table-fleetDataTable font-wt-900">PORT</TableCell>
                        <TableCell className="rem-con-table-fleetDataTable font-wt-900">PROTOCOL</TableCell>
                        <TableCell className="rem-con-table-fleetDataTable font-wt-900 rc-expiryDate-width">EXPIRY DATE</TableCell>
                        <TableCell className="rem-con-table-fleetDataTable font-wt-900">KONNECT URL</TableCell>
                        <TableCell align="left" className="rem-con-table-fleetDataTable font-wt-900">URL SUFFIX</TableCell>
                        <TableCell className="rem-con-table-fleetDataTable font-wt-900">AUTH EMAIL</TableCell>
                        <TableCell className="rem-con-table-fleetDataTable font-wt-900">REVOKE</TableCell>
                        <TableCell className="rem-con-table-fleetDataTable font-wt-900 rc-actions-width">ACTIONS</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className="tableBody alertsTable-tableBody">
                    {filterGeneratedLinks().map((rc) =>
                        <TableRow className="rem-con-editable-row" key={rc.connectionId}>
                            <EditableRow userTimezone={authReducer.userTimezone} remoteConnection={rc} siteName={siteName} deleteRemoteConnection={deleteRemoteConnectionLink} updateRemoteConnectionLink={updateRemoteConnectionLink} deviceOnline={deviceOnline} vesselEdgeTopologyProducts={authReducer.vesselEdgeTopologyProducts} />
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

function EditableRow({ userTimezone, remoteConnection, siteName, deleteRemoteConnection, updateRemoteConnectionLink, deviceOnline, vesselEdgeTopologyProducts }) {

    const [revoked, isRevoked] = useState(remoteConnection.isRevoked);
    const [editing, isEditing] = useState(false);
    const [alias, setAlias] = useState(remoteConnection.alias)
    const [expireTime, setExpireTime] = useState(moment(remoteConnection.expireTime))
    const [inputsDisabled, isInputsDisabled] = React.useState(false);
    const [validInputs, setValidInputs] = React.useState<boolean>(false);
    const [urlSuffix, setUrlSuffix] = React.useState(remoteConnection.urlSuffix);
    const [authEmail, setAuthEmail] = React.useState(remoteConnection.authEmail);
    const [protocol, setProtocol] = React.useState(remoteConnection.protocol);

    const resetRow = () => {
        setAlias(remoteConnection.alias);
        setExpireTime(remoteConnection.expireTime);
        setUrlSuffix(remoteConnection.urlSuffix);
        setAuthEmail(remoteConnection.authEmail);
        setProtocol(remoteConnection.protocol);
    }

    const handleDelete = async () => {
        await deleteRemoteConnection(remoteConnection.siteId, remoteConnection.deviceId, remoteConnection.connectionId);
    }

    const handleSave = async () => {
        isInputsDisabled(true);
        let res = await updateRemoteConnectionLink(remoteConnection.siteId, remoteConnection.deviceId, remoteConnection.connectionId, {
            alias: alias,
            expireTime: expireTime,
            isRevoked: revoked,
            urlSuffix: urlSuffix,
            authEmail: authEmail,
            protocol: protocol
        });
        if (_.isEmpty(res)) {
            resetRow();
        }
        isEditing(false);
        isInputsDisabled(false);
    }

    const handleChangeRevoke = async (e) => {
        isInputsDisabled(true);
        let prev = revoked;
        isRevoked(e.target.checked);
        let res = await updateRemoteConnectionLink(remoteConnection.siteId, remoteConnection.deviceId, remoteConnection.connectionId, {
            isRevoked: e.target.checked
        });
        if (!_.isEmpty(res)) {
        } else {
            isRevoked(prev);
        }
        isInputsDisabled(false);
    }

    const handleChangeEditing = (e) => {
        isEditing(!editing);
        resetRow();
    }

    const handleClose = () => {
        isEditing(false);
        resetRow();
    }

    const handleChangeExpireTime = (date: moment.Moment | null) => {
        if (date == null || !date.isValid())
            return;
        setExpireTime(date)
    }

    const handleCopyUrl = () => {
        copyToClipboard(remoteConnection.url);
        toast.success("Konnect URL copied !", {
            position: toast.POSITION.BOTTOM_LEFT,
        })
    }

    const appendEdgeToken = (url) => {
        const loggedInUser = getDataFromLocalStorageParsed("loggedInUser");
        let edgeToken = loggedInUser?.data?.edge_token;
        if (!_.isEmpty(edgeToken)) {
            let objUrl = new URL(`/login?token=${edgeToken}`, `${url}`)
            url = objUrl?.href
        }
        return url
    }

    const validEmail = (email: string) => {
        if (email === '')
            return true;
        let re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    React.useEffect(() => {
        setValidInputs(validEmail(authEmail));
    }, [authEmail])

    const getKonnectStatus = (selectedDevice) => {
        const data = vesselEdgeTopologyProducts?.data;
        let status = 'Offline';
        data?.manage_location?.[0]?.products?.forEach((product) => {
          return product?.devices?.forEach((device) => {
            if(device?.id == selectedDevice) {
              status = device.konnectStatus == 'STATUS_CONNECTED' ? 'Online' : 'Offline';
            }
          })
        })
        return status;
    }

    return (
        <Fragment>
            <Tooltip title={<span style={{ fontSize: "12px" }}>{remoteConnection.alias}</span>}>
                <TableCell className="rem-con-table-body rem-conn-data-width" align="left">
                    {
                        editing ?
                            <Input
                                type='text'
                                value={alias}
                                placeholder='Set Alias'
                                fullWidth
                                onChange={(e) => setAlias(e.target.value)}
                                disabled={inputsDisabled}
                            />
                            : (remoteConnection.alias || `${remoteConnection.address}:${remoteConnection.port}`)
                    }
                </TableCell>
            </Tooltip>
            <TableCell className="rem-con-table-body" align="left">{remoteConnection.address}</TableCell>
            <TableCell className="rem-con-table-body width30" align="left">{remoteConnection.port}</TableCell>
            <TableCell className="rem-con-table-body width30" align="left">
                {editing ?
                    <Select
                        labelId="select-protocol"
                        id="select-protocol"
                        value={protocol}
                        style={{ height: '40px' }}
                        variant='outlined'
                        size="small"
                        onChange={(event: SelectChangeEvent) => { setProtocol(event.target.value) }}
                        disabled={inputsDisabled}
                    >
                        <MenuItem value={'http'}>HTTP</MenuItem>
                        <MenuItem value={'https'}>HTTPS</MenuItem>
                    </Select> : remoteConnection.protocol
                }
            </TableCell>
            <Tooltip title={<span style={{ fontSize: "12px" }}>{convertDateTimeIntoTimezone(remoteConnection.expireTime, userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</span>}>
                <TableCell className="rem-con-table-body rem-conn-data-width rc-editable-date" align="left">
                    {
                        editing ?
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DateTimePicker
                                    ampm={false}
                                    timezone={getTimezoneCity(userTimezone)}
                                    value={moment(expireTime)}
                                    onChange={handleChangeExpireTime}
                                    minDateTime={moment(Date.now())}
                                    disabled={inputsDisabled}
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                    }}
                                />
                            </LocalizationProvider>
                            : convertDateTimeIntoTimezone(remoteConnection.expireTime, userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)
                    }
                </TableCell>
            </Tooltip>
            <Tooltip title={remoteConnection.url}>
                <TableCell align="left" className="rem-con-table-body rem-conn-data-width">
                    {remoteConnection.url}
                </TableCell>
            </Tooltip>
            <TableCell className="rem-con-table-body" align="left">
                {editing ?
                    <Input
                        type='text'
                        value={urlSuffix}
                        placeholder='Set URL Suffix'
                        fullWidth
                        onChange={(e) => setUrlSuffix(e.target.value)}
                        disabled={inputsDisabled}
                    /> : remoteConnection.urlSuffix
                }
            </TableCell>
            <Tooltip title={<span style={{ fontSize: "12px" }}>{remoteConnection.authEmail}</span>}>
                <TableCell className="rem-con-table-body rem-conn-data-width" align="left">
                    {editing ?
                        <Input
                            type='text'
                            value={authEmail}
                            placeholder='Set Auth Email'
                            fullWidth
                            onChange={(e) => setAuthEmail(e.target.value)}
                            disabled={inputsDisabled}
                            error={!validEmail(authEmail)}
                        /> : remoteConnection.authEmail

                    }
                </TableCell>
            </Tooltip>
            <TableCell className="rem-con-table-body width30" align="left">
                <AntSwitch inputProps={{ 'aria-label': 'ant design' }} disabled={inputsDisabled} checked={revoked} onChange={handleChangeRevoke} />
            </TableCell>
            <TableCell className="rem-con-table-body width120" align="justify">
                <div>
                    <Tooltip title="Connect to remote">
                        {
                            getKonnectStatus(remoteConnection.deviceId) == 'Online' ?
                                <IconButton
                                    aria-label="connect" disabled={revoked}
                                    size="small"
                                >
                                    <a href={remoteConnection.address === "127.0.0.1" ? appendEdgeToken(remoteConnection.url) : remoteConnection.url} target="_blank" rel="noreferrer" className="rc--connect_icon">
                                        <img src={revoked ? EdgeLinkIconDisabled : EdgeLinkIcon} alt="connect to remote" />
                                    </a>
                                </IconButton> : <Tooltip title="device status not connected"><span><IconButton
                                    aria-label="connect" disabled={true}
                                    size="small"
                                >
                                    <img src={EdgeLinkIconDisabled} alt="device status not connected" />
                                </IconButton></span></Tooltip>

                        }
                    </Tooltip>
                    <Tooltip title="Copy Konnect URL">
                        <IconButton
                            aria-label="copy url"
                            size="small"
                            onClick={handleCopyUrl}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                    {
                        remoteConnection.address !== "127.0.0.1" && <Fragment>
                            <Tooltip title={editing ? 'Save' : 'Edit'}>
                                <IconButton
                                    aria-label={editing ? 'Edit' : 'Save'}
                                    size="small"
                                    onClick={editing ? handleSave : handleChangeEditing}
                                    disabled={inputsDisabled || (editing && !validInputs)}
                                >
                                    {
                                        editing ?
                                            <SaveIcon />
                                            : <EditIcon />
                                    }
                                </IconButton>
                            </Tooltip>
                            {
                                editing ? <Tooltip title="Cancel">
                                    <IconButton
                                        aria-label="cancel"
                                        size="small"
                                        onClick={handleClose}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip> : null
                            }
                            <Tooltip title="Delete">
                                <IconButton
                                    aria-label="delete"
                                    size="small"
                                    onClick={handleDelete}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Fragment>
                    }
                </div>
            </TableCell>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    generatedLinks: state.remoteConnection.generatedLinks
});

export default connect(mapStateToProps, {
    getGeneratedLinks,
    deleteRemoteConnectionLink,
    updateRemoteConnectionLink
})(GeneratedLinks)