import RootRouter from './routes';
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from 'react-redux';
import thunk from "redux-thunk";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { ApolloProvider, createHttpLink, ApolloClient, InMemoryCache } from '@apollo/client';
import rootReducer from "./reducers";
import { API_URL, GRAPHQL_URI, GOOGLE_TRACKER_ID } from './config';
import { setContext } from '@apollo/client/link/context';

import { LOGGED_IN_USER } from './constants/Constants';
import ReactGA from 'react-ga';
import { getDataFromLocalStorage } from "./utils/util";

const _authorizationHeaders = () => ({
  "Content-Type": "application/json",
  "Authorization": getDataFromLocalStorage(LOGGED_IN_USER) ? "Bearer " + JSON.parse("" + getDataFromLocalStorage(LOGGED_IN_USER)).data.id_token : '',
});

const authLink = setContext((_, { headers }) => {
  let _headers = _authorizationHeaders();
  return {
    headers: {
      ...headers,
      ..._headers
    }
  }
});

const httpLink = createHttpLink({
  uri: `${API_URL}${GRAPHQL_URI}`,
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false
  })
});

const composedEnhancer = compose(applyMiddleware(thunk.withExtraArgument(client)))

const store = createStore(rootReducer, composedEnhancer);
ReactGA.initialize(GOOGLE_TRACKER_ID);

function App() {
  return (
    <ApolloProvider client={client} >
      <Provider store={store}>
        <Router>
          <div className="App">
            <RootRouter></RootRouter>
          </div>
        </Router>
      </Provider>
    </ApolloProvider>
  );
}

export default App;
