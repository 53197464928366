import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { SIDE_MENU_ITEM_MAP } from "../../utils/constants";
import TowerVesselDetails from "../../vis/react-app/src/pages/TowerVesselDetails";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { BC_MAPS, PERMISSIONS } from "../../constants/Constants";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { getDataFromLocalStorage, getDecodeURI, getEncodedURI, getServiceFunction, getTimezoneCity, strToK4List } from "../../utils/util";
import { checkLoggedInUserAuthorizedToViewPage } from "../../utils/util";
import NotAuthorized from "../NotAuthorized"
import { updatePermission } from "../../actions/Users/authenticate";
import './MapPage.css';
import { APP_ENV } from '../../config';
import _ from "lodash";
import CommonFilters from "../../components/BreadCrumbs/CommonFilters";

const MapPage = (props) => {
  const { authReducer, location, history, updatePermission, userTimezone} = props;
  const [isSpecialPrrmEnabled, setIsSpecialPermEnabled] = useState(false);
  const [ isMultiVessel ,setMutiVessel] = useState(true)
  const [isStarlink, setIsStarlink] = useState(false)

  useEffect(() => {
    let PermissionCheck: any = getDataFromLocalStorage(PERMISSIONS);
    PermissionCheck = JSON.parse(PermissionCheck)
    let serviceFunctions = PermissionCheck.find(item => item.service === "serviceportal");
    if (serviceFunctions) {
      let viewLoginHistoryPermission = serviceFunctions.servicefunctions.find(item => item.function == "ViewKMLLayers")
      if (viewLoginHistoryPermission) {
        setIsSpecialPermEnabled(true);
      }
      else {
        setIsSpecialPermEnabled(false);
      }
    }
  }, []);

  const setBreadcrumbInfo: any = React.useRef();
  let breadcrumbInfo = BC_MAPS;
  let appEnv = APP_ENV;
  const [vesselDetails, setVesselDetails] = useState<any>()
  const [vesselList, setvesselList] = useState([]);
  const [k4Ids, setK4Ids] = useState([]);
  const [selectedVessel, setSelectedVessel] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);
  const [isAuthorizedToViewPage, setIsAuthorizedToViewPage] = useState<Boolean>(false);

  if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_MAP !== authReducer.sideMenu.menuItem)) {
    authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_MAP };
  }


  const not_authorized_page_title = "Map";
  const not_authorized_page_message = "You are not authorised to view map";

  const VIEW_MAPS = "view-maps";
  const viewMapsService = SERVICE_FUNCTION_TO_FEATURE[VIEW_MAPS]["service"];
  const viewMapsFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_MAPS]["function"];

  useEffect(() => {
    let _q = getDecodeURI(location?.search);
    if (_q.hasOwnProperty("k4Ids") && '' != _q.k4Ids.trim()) {
      let vessels = strToK4List(_q.k4Ids);
      if (vessels && vessels.length > 0) {
        setSelectedVessel(vessels);
      }
    } else if (selectedVessel?.id != '') {
      setSelectedVessel({ "id": "", "vessel-name": "" });
    }
  }, [location]);

  useEffect(() => {
    const { locations } = authReducer.getVesselsListing;
    if (!_.isEmpty(locations)) {
      let authVesselList = locations.map(vessel => {
        return {
          id: vessel.id,
          type: "Vessel",
          'vessel-name': vessel.name
        }
      })
      let authVesK4Ids = authVesselList && authVesselList.length > 0 && authVesselList.map((item) => {
        return item?.id;
      });
      authVesK4Ids = authVesK4Ids && authVesK4Ids.length > 0 && authVesK4Ids.filter(item => item);
      authVesK4Ids = authVesK4Ids && authVesK4Ids.length > 0 && authVesK4Ids.map((item) => {
        return `${item}`;
      })
      setK4Ids(authVesK4Ids);
      setVesselDetails(authVesselList);
    } else {
      setK4Ids([])
      setVesselDetails([])
    }
  }, [authReducer.getVesselsListing])

  useEffect(() => {
    let authorized = checkLoggedInUserAuthorizedToViewPage(viewMapsService, viewMapsFunction);
    setIsAuthorizedToViewPage(authorized);
    let viewResources: any = [];

    let viewFunctionPermission = getServiceFunction(viewMapsService, viewMapsFunction);
    if (viewFunctionPermission && viewFunctionPermission.resources) {
      viewResources = viewFunctionPermission.resources;
    }
    if (viewResources) {
      setVesselDetails(viewResources)
    }
    const info = {
      permission: {
        service: viewMapsService,
        serviceFunction: viewMapsFunction,
      },
      isMultiVessel: true,
      isSingleVessel: false,
      showOu: false,
      ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
    };
    if (authorized) {
      info.isSingleVessel = false;
      info.showOu = true;
    }
    updatePermission(info);
  }, [isMultiVessel]);

  const handleApiLoading = (isLoad) => {
    setLoading(isLoad);
  }

  const handleLastLocSelectedVessel = (incomingSelctedVessel: any) => {
    if (incomingSelctedVessel && incomingSelctedVessel[0]) {
      let params: any = getDecodeURI(location?.search);
      params.k4Ids = `${incomingSelctedVessel[0]?.id}:${incomingSelctedVessel[0]['vessel-name']}`;
      history.push({ pathname: location.url, search: `?${getEncodedURI(params)}` });
    } else {
      let params: any = getDecodeURI(location?.search);
      delete params.k4Ids;
      history.push({ pathname: location.url, search: `?${getEncodedURI(params)}` });
    }
  }

  const handleIsMultiVessel = (isEnabled) => {
    setMutiVessel(isEnabled);
  }
  const handleStarlinkLoc = (isStarlink:any) => {
    setIsStarlink(isStarlink)
  }
  return (
    <div className="Form" style={{ overflowX: "hidden", width: "-webkit-fill-available" }}>
      <CommonFilters showSummaryPeriod={isMultiVessel ? false : true} showWantType={false} loading={isLoading || authReducer?.userPreferencesLoading} showSLDropDown={isStarlink && isMultiVessel} disableOu={!isMultiVessel} disableSite={!isMultiVessel} />
      <Grid item container style={{ backgroundColor: "#fff" }}>
        <Grid className="mapPage" item style={{  width: "100%" }}>
          {
            isAuthorizedToViewPage ?
              <TowerVesselDetails vesselList={vesselList} selectedVessel={selectedVessel} selectedOu={authReducer.selectedOu} style={{ marginLeft: "60px" }} handleApiLoading={handleApiLoading} k4Ids={k4Ids} handleLastLocSelectedVessel={handleLastLocSelectedVessel} appEnv={appEnv} authorizedVesselList={vesselDetails} isSpecialPrrmEnabled={isSpecialPrrmEnabled} userTimezone={getTimezoneCity(userTimezone)} handleIsMultiVessel={handleIsMultiVessel} handleStarlinkLoc={handleStarlinkLoc}></TowerVesselDetails>
              :
              <Grid item container style={{ position: "fixed", paddingBottom: "16px", backgroundColor: "#ffffff", zIndex: 10, marginTop: "80px", }}>
                <NotAuthorized pageTitle={not_authorized_page_title} PageMessage={not_authorized_page_message} />
              </Grid>
          }
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
  userTimezone: state?.authReducer?.userTimezone,
});

export default withRouter(connect(mapStateToProps, { updatePermission })(MapPage));