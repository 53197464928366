import React from 'react';
import { Chip, Stack, Tooltip } from '@mui/material';

const OperationalChips = (props) => {
    const { device, setDevice, softwareVersion, setSoftwareVersion } = props;
    const handleClose = () => {
        setDevice("");
        setSoftwareVersion("");
    }
    const deviceId = device.match(/IN\('([^']+)'\)/)?.[1];
    const sw_Id = softwareVersion.match(/IN\('([^']+)'\)/)?.[1].split("~")[0];
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            {device !== '' && <Tooltip title={"device ID"} arrow>
                <Chip
                    label={`${deviceId}`} 
                    onDelete={handleClose} 
                />
            </Tooltip>}
            {softwareVersion !== '' && <Tooltip title={"EOS version"} arrow>
                <Chip
                    label={`${sw_Id}`} 
                    onDelete={handleClose} 
                />
            </Tooltip>}
        </Stack>
    );
};

export default OperationalChips;