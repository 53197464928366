import React, { useState, useEffect, Fragment, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";

import { Grid, Paper, Typography , Button, Tooltip} from "@mui/material";
import Loader from "react-loader";

import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { SIDE_MENU_ITEM_QUOTA_MANAGEMENT } from "../../utils/constants";
import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI, getEncodedURI, strToK4List } from "../../utils/util";
import { fetchUserListAPI, updatePermission, updateSideMenu } from "../../actions/Users/authenticate";
import { Site, User } from "./types";
import { getDevices, createQuota, clearData } from "./slice";

import NotAuthorized from "../../UserScreen/NotAuthorized";
import "./index.css";
import Dashboard from "./Dashboard";
import AccountManagement from '../../asset/user_guide/Quota Kontrol_User_Guide.pdf';
import HelpIcon from '@mui/icons-material/Help';
import CommonFilters from "../../components/BreadCrumbs/CommonFilters";
import InfoIcon from '@mui/icons-material/Info';

const USAGE_QUOTA = "view-vessel-summary";
const usageQuotaService = SERVICE_FUNCTION_TO_FEATURE[USAGE_QUOTA]["service"];
const usageQuotaFunction = SERVICE_FUNCTION_TO_FEATURE[USAGE_QUOTA]["function"];

const QuotaManagement = (props: any): JSX.Element => {
    const { authReducer, history, location, errorReducer, getVesselsListing, filteredVessels, usersList, devices, quotas, fetchUserListAPI, getDevices, updatePermission, updateSideMenu, clearData, creating, updating, deleting, gettingDevices, gettingQuotas, gettingCurrentUsage } = props;

    const [isAuthorizedToViewPage, setIsAuthorizedToViewPage] = useState<Boolean>(false);
    const not_authorized_page_title = "Quota Manager";
    const not_authorized_page_message = "You are not authorised to view quota manager.";

    useEffect(() => {
        if ((authReducer && !authReducer.sideMenu) || (SIDE_MENU_ITEM_QUOTA_MANAGEMENT !== authReducer.sideMenu.menuItem)) {
            updateSideMenu({ menuItem: SIDE_MENU_ITEM_QUOTA_MANAGEMENT });
        }
        return () => {
            clearData();
        }
    }, [])

    const [sites, setSites] = useState<Site[]>([]);

    useEffect(() => {
        if (_.isEmpty(getVesselsListing)) return;
        let locations: any[] = [];
        if (_.isArray(filteredVessels) && filteredVessels.length > 0) {
            locations = filteredVessels;
        } else {
            const { k4Ids = '' } = getDecodeURI(location?.search);
            const k4IdsFromUrl = strToK4List(k4Ids);
            if (_.isArray(k4IdsFromUrl) && k4IdsFromUrl.length) {
                locations = k4IdsFromUrl;
            } else if (_.isArray(getVesselsListing.locations)) {
                locations = getVesselsListing.locations;
            }
        }
        const data = locations.map(site => ({
            id: site.id,
            name: site['vessel-name'],
        }))

        if (_.isEqual(data, sites)) {
            return;
        } else {
            setSites(data);
        }
    }, [getVesselsListing, filteredVessels]);

    const users = useMemo(() => {
        return authReducer?.usersList?.data?.records?.length > 0 ? _.orderBy(authReducer?.usersList?.data?.records?.map((u: any) => {
            let _u: User = {
                name: u?.name?.givenName + " " + u?.name?.familyName,
                email: u.userName
            };
            return _u;
        }).filter(
            (u: User) => u.name.trim() !== "" && u.email.trim() !== ""
        ), 'name', 'asc') : [];
    }, [usersList]);

    useEffect(() => {
        if (sites && sites.length > 0) {
            let _sites = sites.map((site: Site) => site.id);
            getDevices({ sites: _sites })
        }
    }, [sites]);

    useEffect(() => {
        let authorized = checkLoggedInUserAuthorizedToViewPage(usageQuotaService, usageQuotaFunction);
        if (authorized && !authReducer.permission) {
            const info = {
                permission: {
                    service: usageQuotaService,
                    serviceFunction: usageQuotaFunction,
                },
                isMultiVessel: true,
                isSingleVessel: false,
                showOu: true,
                ou: authReducer.selectedOu ? authReducer.selectedOu : ''
            };
            updatePermission(info);
            setIsAuthorizedToViewPage(true);

            if (authReducer?.selectedOu?.name) {
                fetchUserListAPI(authReducer.selectedOu.name, 1, 1000000, '');
            }
        }
    }, [authReducer.selectedOu])

    const handleSiteClick = (row) => {
        let params: any = getDecodeURI(location?.search);
        row && row.k4Id ? params.k4Ids = `${row.k4Id}:${row.x}` : row && row.location_id ? params.k4Ids = `${row.location_id}:${row.site}`:  delete params.k4Ids;
        delete params.page;
        delete params.limit;
        delete params.sort;
        delete params.sortOrder;
        delete params.search;
        doNavigate(params);
      }

      const doNavigate = (pathname, params = {}) => {
        history.push({ pathname: pathname, search: `?${getEncodedURI(params)}` });
      }

      const helpAction = () =>{
        window.open(AccountManagement)
      }

    return (
        <Fragment>
           <CommonFilters loading={authReducer.loading || creating || updating || deleting || gettingDevices || gettingQuotas || gettingCurrentUsage || authReducer?.userPreferencesLoading || authReducer?.copyQuotaConfigLoading} helpIcon={false} helpAction={ helpAction} handleSiteClick={handleSiteClick} showWantType={false} showSummaryPeriod={false} userGuide={[true, 'quota-kontrol-user-guide']}></CommonFilters>
            <Paper elevation={0} square={true} className="quota_management--container">
                {
                    isAuthorizedToViewPage ?
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Paper elevation={0} classes={{ root: 'quota_management--base--flex--align--center quota_management--base--flex--justify--space_between quota_mangement--base--padding_1x' }}>
                                    <div className="quota_management--base--font_2x">
                                        {window?.location?.host.includes("pulsar") ? "Quota Control" : "Quota Kontrol"}
                                        <p className="quota-kontrol-note"><b>Note:</b> Quotas are monitored every 15 minutes.</p>
                                    </div>
                                    <div>
                                    <Tooltip classes={{ tooltip: 'quota_management--tooltip' }} title={
                                        <Grid classes={{ root: 'quota_mangement--base--padding_1x' }}>
                                            <div className='quota_management--base--font_07x'>
                                                <div>
                                                    WAN usage is measured on the Edge system for each WAN interface. The Edge usage may differ from Carrier usage (e.g. Starlink or T Mobile) due additional radio network and operational overheads from the carrier.
                                                </div>
                                                <div className='quota_management--base--mt_1x'>
                                                    Starlink provided usage is on average 8% larger than Edge Starlink WAN usage.
                                                </div>
                                            </div>
                                        </Grid>
                                    }>
                                        <InfoIcon className="helpIcon-quota" />
                                    </Tooltip>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Dashboard sites={sites} devices={devices} users={users} />
                            </Grid>
                        </Grid>
                        : <NotAuthorized pageTitle={not_authorized_page_title} PageMessage={not_authorized_page_message} />
                }
            </Paper>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    getVesselsListing: state.authReducer?.getVesselsListing,
    filteredVessels: state.authReducer?.filteredVessels,
    usersList: state.authReducer.usersList,
    devices: state.quotaManager.devices,
    quotas: state.quotaManager.quotas,
    creating: state.quotaManager.creating,
    updating: state.quotaManager.updating,
    deleting: state.quotaManager.deleting,
    gettingDevices: state.quotaManager.gettingDevices,
    gettingQuotas: state.quotaManager.gettingQuotas,
    gettingCurrentUsage: state.quotaManager.gettingCurrentUsage,
});

export default withRouter(connect(mapStateToProps, {
    clearData,
    fetchUserListAPI,
    getDevices,
    createQuota,
    updatePermission,
    updateSideMenu
})(QuotaManagement));