import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect, useState } from "react"; 
import { Grid, Tooltip } from "@mui/material";
import _ from "lodash";
import { getContentFilterTopWidgetTopSite, getContentFilterTopWidgetTopUser, getContentFilterTopWidgetTopCategory, getContentFilterTopWidgetTopDomain } from '../../actions/Users/authenticateContentFilter';
import topSite from "../../asset/image/topSiteIcon.svg";
import topUser from "../../asset/image/topUserIcon.svg";
import topCategory from "../../asset/image/categoryIcon.svg";
import topApp from "../../asset/image/topAppIcon.svg";

const TopWidget = (props) => {
    const { authReducer, values, setValues, applyFilterClick, newSummaryStartDate, newSummaryEndDate } = props;
    const { vessels, category, app, user, vlan, device, subCategory } = values;
    const { setVessels, setCategory, setApp, setUser } = setValues;
    const [topWidget, setTopWidget] = useState<any>([]);

    const getJsonData = (data) => {
        const Data = data?.rows?.map((item) => {
            return data?.columns?.reduce((acc, columItem, i) => {
                acc[columItem] = item[i];
                return acc;
            }, {})
        })
        return Data;
    }

    useEffect(() => {
       if(vessels.length == 0) {
            setTopWidget([{}, {}, {}, {}]);
       }
    }, [vessels])

    useEffect(() => {
        const List: any = [{}, {}, {}, {}];
        if(_.isEmpty(authReducer.getContentFilterTopWidgetTopSite) && _.isEmpty(authReducer.getContentFilterTopWidgetTopUser) && _.isEmpty(authReducer.getContentFilterTopWidgetTopCategory) && _.isEmpty(authReducer.getContentFilterTopWidgetTopDomain)) {
            setTopWidget([{}, {}, {}, {}]);
            return;
        }
        if(!_.isEmpty(authReducer.getContentFilterTopWidgetTopSite)) {
            const topSite = authReducer.getContentFilterTopWidgetTopSite.hasOwnProperty('data') ? authReducer.getContentFilterTopWidgetTopSite?.data : {};
            List[0] = {...getJsonData(topSite)?.[0], key:'Site_Name'};
        }
        if(!_.isEmpty(authReducer.getContentFilterTopWidgetTopUser)) {
            const topUser = authReducer.getContentFilterTopWidgetTopUser.hasOwnProperty('data') ? authReducer.getContentFilterTopWidgetTopUser?.data : {};
            List[1] = {...getJsonData(topUser)?.[0], key:'Username'}
        }
        if(!_.isEmpty(authReducer.getContentFilterTopWidgetTopCategory)) {
            const topCategory = authReducer.getContentFilterTopWidgetTopCategory.hasOwnProperty('data') ? authReducer.getContentFilterTopWidgetTopCategory?.data : {};
               List[2] = {...getJsonData(topCategory)?.[0], key:'category'}
        }
        if(!_.isEmpty(authReducer.getContentFilterTopWidgetTopDomain)) {
            const topDomain = authReducer.getContentFilterTopWidgetTopDomain.hasOwnProperty('data') ? authReducer.getContentFilterTopWidgetTopDomain?.data : {};
            List[3] = {...getJsonData(topDomain)?.[0], key:'fqdn'}
        }
        setTopWidget(List);
    }, [authReducer.getContentFilterTopWidgetTopSite, authReducer.getContentFilterTopWidgetTopUser, authReducer.getContentFilterTopWidgetTopCategory, authReducer.getContentFilterTopWidgetTopDomain])

    const handleFilterClick = (row, key) => {
        switch(key) {
            case 'Username': {
                if(row.value == 'NA') {
                    const value = row.mac;
                    setUser(value);
                    applyFilterClick(newSummaryStartDate, newSummaryEndDate, vessels, category, app, value, vlan, device, subCategory);
                    break;
                } else {
                    const value = row.Username;
                    setUser(value);
                    applyFilterClick(newSummaryStartDate, newSummaryEndDate, vessels, category, app, value, vlan, device, subCategory);
                    break;
                }
            }
            case 'category': {
                const value = row.value;
                setCategory(value);
                applyFilterClick(newSummaryStartDate, newSummaryEndDate, vessels, value, app, user, vlan, device, subCategory);
                break;
            }
            case 'fqdn': {
                const value = row.value;
                setApp(value);
                applyFilterClick(newSummaryStartDate, newSummaryEndDate, vessels, category, value, user, vlan, device, subCategory);
                break;
            }
            case 'Site_Name': {
                const value = [{id: row.id, 'vessel-name': row.value }];
                setVessels(value);
                applyFilterClick(newSummaryStartDate, newSummaryEndDate, value, category, app, user, vlan, device, subCategory, 'filter');
                break;
            }
        }
    }

    return (
        <div className="Form">
            <p className="top-overview">Overview</p>
            <Grid container spacing={3}>
                {topWidget?.map((card, i) => (
                    <Grid key={i} item xs={12} sm={3} md={3} lg={3}>
                        <WidgetCard card={card} handleFilterClick={handleFilterClick} />
                    </Grid>
                ))}
            </Grid>
        </div>
    )
    
    function WidgetCard ({card, handleFilterClick}) {

        const widget = {
            Site_Name: { icon: topSite, text: "Top Site", value: card.Site_Name, hits: card.site_hits, id: card.location_id },
            Username: { icon: topUser, text: "Top User", value: card.Username, mac: card.macAddress, hits: card.user_hits },
            category: { icon: topCategory, text: 'Top Category', value: card.category, hits: card.category_hits },
            fqdn: { icon: topApp, text: 'Top Domain', value: card.fqdn, hits: card.domain_hits }
        }

        return (
            <div className="Form">
                <Grid className="content-filter-widget-card">
                    <Grid className="top-details">
                        <Grid className="details cf-widget-name">
                            {widget[card.key]?.text ? <p>{widget[card.key]?.text}</p> : <p>Top Site</p>}
                            <Tooltip title={card['key'] == 'Username' && widget[card.key]?.value == 'NA' ? widget[card.key]?.mac : widget[card.key]?.value}>
                                <p onClick={() => handleFilterClick(widget[card.key], card.key)}>{card.key == 'Username' && widget[card.key]?.value == 'NA' ? widget[card.key]?.mac : widget[card.key]?.value}</p>
                            </Tooltip>
                        </Grid>
                        <Grid className='logo-div' style={card.key == 'Site_Name' ? { background: '#f1f4fe' } : card.key == 'Username' ? { background: '#f1f9fe' } : card.key == 'category' ? { background: '#fff3e8' } : card.key == 'fqdn' ? { background: '#fef3f3' } : { background: '#f1f4fe' }}>
                            {widget[card.key]?.icon ? <img src={widget[card.key]?.icon} alt="" /> : <img src={topSite} alt="" />}
                        </Grid>
                    </Grid>
                    <Grid className="hit-details">
                        <p>Hits</p>
                        {widget[card.key]?.hits ? <p>{widget[card.key]?.hits}</p> : <p>0</p>}
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    newSummaryStartDate: state.authReducer.newSummaryStartDate,
    newSummaryEndDate: state.authReducer.newSummaryEndDate,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, { getContentFilterTopWidgetTopSite, getContentFilterTopWidgetTopUser, getContentFilterTopWidgetTopCategory, getContentFilterTopWidgetTopDomain })(TopWidget)
);