import { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Stack, TextField } from "@mui/material";
import close from "../../asset/image/close.svg";
import DP_Dropdown from "../../components/InventoryWarehouseTable/DP_Dropdown";
import SelectTag from "../../components/InventoryWarehouseTable/SelectTag";
import { getDecodeURI, getEncodedURI, getTimezoneCity, strToK4List } from "../../utils/util";
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Moment } from "moment-timezone";
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import _ from "lodash";
import { createDownloadServiceFirewall } from '../../actions/Users/authenticateReports';
import { fetchResources } from '../../actions/Users/authenticate';
import { toast } from "react-toastify";
import { FirewallReportContext } from "./FirewallReportContext";
import { UPDATE_NEW_SUMMARY_END_DATE, UPDATE_NEW_SUMMARY_START_DATE } from "../../actions/types";

const ReportPopup = (props) => {
    const { authReducer, open, setOpen, fetchResources, ruleNames, createDownloadServiceFirewall, tableCount, location, history } = props;
    let _q = getDecodeURI(location?.search);
    const [startDate, setStartdate] = useState<any>(_q.hasOwnProperty("startDate") ? moment(new Date(parseInt(_q.startDate))) : moment(Date.now()).subtract(1, 'hour'));
    const [endDate, setEnddate] = useState<any>(_q.hasOwnProperty("endDate") ? moment(new Date(parseInt(_q.endDate))) : moment(Date.now()));
    const [sites, setSites] = useState<any>(_q.hasOwnProperty('k4Ids') && _q.k4Ids != '' ? [...strToK4List(_q.k4Ids)?.map((ele) => { return { id: ele["id"], name: ele['vessel-name'] } })] : []);
    const [sitesList, setSitesList] = useState<any>([]);
    const [selectedOu, setSelectedOu] = useState<any>({});
    const [ruleName, setRuleName] = useState<any>(_q.hasOwnProperty('rule') ? _q.rule : '');
    const [actions, setActions] = useState<any>(_q.hasOwnProperty('activities') ? _q.activities.split(',').map((ele) => {
        return ele == 1 ? { allowed:true, denied: false } : { allowed:false, denied: true }
    }) : [{allowed: true, denied: true}]);
    const [enableDPI, setEnableDPI] = useState(false);

    const [serviceName, setServiceName] = useState("")
    const [serviceFunction, setServiceFunction] = useState("");
    const dispatch = useDispatch();
    const [format, setFormat] = useState('xlsx');

    useEffect(()=> {
        const service = JSON.parse(localStorage.getItem('permissions') || '');
        service?.map((list)=>{
          if(list.service == 'iam'){
            setServiceName(list.service)
            setServiceFunction(list?.servicefunctions[0]?.function)
          }
        })
    },[])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.fetchResources)) {
            let resourceRecords = authReducer.fetchResources?.locations;
            if (resourceRecords && Array.isArray(resourceRecords)) {
                let resourceSort = resourceRecords.map((index, i) => index.name);
                resourceSort.sort();
                let sortedResourceNames: any[] = resourceRecords.sort((a: any, b: any) => {
                  return ('' + a.name).localeCompare(b.name);
                })
                let externalAllVesselObj = {name: "All Sites", description: "", type: "Site", is_all: true}
                sortedResourceNames?.unshift(externalAllVesselObj);
                let defaultAllVessel =  "All Sites"
                let all_sites = sortedResourceNames.find(item => item.name === defaultAllVessel)
                sortedResourceNames = sortedResourceNames.filter(item => item.name !== defaultAllVessel)
                if(all_sites){
                    sortedResourceNames.unshift(all_sites);
                    !_q.hasOwnProperty('k4Ids') && setSites([all_sites]);
                }
                setSitesList(sortedResourceNames);
            } else {
                setSitesList([]);
            }
            authReducer.fetchResources = {};
        }
    }, [authReducer.fetchResources]);

    const handleStartDatePicker = (date: Moment | null) => {
        if(date){
            setStartdate(date);
        }
    }

    const handleEndDatePicker = (date: Moment | null) => {
        if(date) {
            setEnddate(date);
        }
    }

    const handleOu = (ou: any) => {
        setSelectedOu(ou);
        if(authReducer?.selectedOu?.name != ou.name) {
            setSites([{ name:"All Sites", type:"Site" }])
        }
        fetchResources(ou.name, 0, 500, serviceName, serviceFunction);
    }

    const handleChangeSite = (e, newValue) => {
        setSites([...newValue]);
    }

    const hanldeChangeRule = (e, newValue, key) => {
        setRuleName(newValue);
    }

    const handleClose = () => {
        setOpen(false); 
        if(_q.hasOwnProperty('filterClick')) {
            let params: any = getDecodeURI(location.search);
            delete params.filterClick;
            doNavigate(params);
        }
        else if(_q.hasOwnProperty('startDate') || _q.hasOwnProperty('endDate')) {
            const start = moment(Date.now()).utc().subtract(1, 'hour');
            const end = moment(Date.now()).utc();
            dispatch({ type: UPDATE_NEW_SUMMARY_END_DATE, payload: end})
            dispatch({ type: UPDATE_NEW_SUMMARY_START_DATE, payload: start})
            let params: any = getDecodeURI(location.search);
            delete params.startDate;
            delete params.endDate;
            params.interval = '1h';
            doNavigate(params);
        }
    }

    const handleChangeCheckbox = (e) => {
        e.target.name == 'allowed' ? setActions([{ ...actions[0],allowed: !actions[0].allowed}]) : setActions([{...actions[0], denied:  !actions[0].denied}])
    }

    const getInterval = (minutes) => {
        if (minutes > 10080) {
          return true;
        } 
        return false;
    }

    const getIntervalBin = (minutes) => {
        if (minutes <= 1440) {
          return "15m";
        } else if (minutes > 1440 && minutes <= 10080) {
          return "1h";
        } else {
          return "4h";
        }
    }

    const handleClickGenerate = () => {
        let dpId = selectedOu?.id
        if (dpId === undefined) {
            toast.error('Please select an Organization', {
                position: toast.POSITION.BOTTOM_LEFT,
            });
            return;
        }
        const payload = {
            "filters": {
              "activity": actions[0].allowed && !actions[0].denied ? ['allow'] : actions[0].denied && !actions[0].allowed ? ['deny'] : ['allow', 'deny'],
              "detailed_report": enableDPI,
              "end_time": endDate.toISOString(),
              "rule_name": ruleName,
              "site_ids": sites.length > 0 && sites[0].name !== 'All Sites' ? sites?.map((site:any) => site.id) : sitesList?.filter((site) => site.name !== 'All Sites')?.map((site:any) => site.id),
              "start_time": startDate.toISOString()
            },
            "format": format
        }
        createDownloadServiceFirewall(dpId, payload);
        handleClose();
        toast.success('Please check the progress of your report request in the "Generated Reports" tab.', {
            position: toast.POSITION.BOTTOM_LEFT,
        });
    }

    const handleChangeCheckboxDPI = (e) => {
        setEnableDPI(e.target.checked);
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    return (
        <div className="Form menuScroll">
            <Dialog 
                disableEnforceFocus
                open={open}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                sx={{borderRadius: '8px'}}
                className='config-deploy-inventory-popup activity-filters-popup-dialogue menuScroll'
            >
                <div>
                    <DialogTitle className='create-default-config-title'>
                        <span>Report</span>
                        <img src={close} title="Close" onClick={handleClose} />
                    </DialogTitle>
                    <DialogContent>
                        <Grid container className="deploy-inventory-content">
                            <Grid className="selectedDeviceDetails">
                                <Grid className="generate-report-title">
                                    <span>Generate Report</span> <br />
                                    <span>Apply Filters below to generate your Report</span>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy">
                                <span>Select Date Range</span>
                                <Stack direction='row' spacing={1}>
                                    <div className="siteCharts__filters-date-time-picker">
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DateTimePicker
                                                label='Start Date'
                                                ampm={false}
                                                timezone={getTimezoneCity(authReducer.userTimezone)}
                                                value={startDate}
                                                onChange={handleStartDatePicker}
                                                maxDateTime={endDate}
                                                className="audit-filter-inputs date-range-activity"
                                                viewRenderers={{
                                                    hours: renderTimeViewClock,
                                                    minutes: renderTimeViewClock,
                                                    seconds: renderTimeViewClock,
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="siteCharts__filters-date-time-picker">
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DateTimePicker
                                                label='End Date'
                                                ampm={false}
                                                timezone={getTimezoneCity(authReducer.userTimezone)}
                                                value={endDate}
                                                onChange={handleEndDatePicker}
                                                minDateTime={startDate}
                                                className="audit-filter-inputs date-range-activity"
                                                maxDateTime={moment()}
                                                viewRenderers={{
                                                    hours: renderTimeViewClock,
                                                    minutes: renderTimeViewClock,
                                                    seconds: renderTimeViewClock,
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </Stack>
                            </Grid>
                             <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy">
                                <span>Select Organization</span>
                                <DP_Dropdown  isReports= {true} setOu={handleOu} className={'dp-dropdown-activity-filters'} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="select-site-check" >
                                <div>
                                    <span>Select Site</span>
                                    <div className="list-filtered-filter">
                                        <Grid>
                                            <Autocomplete className="select-site-input"
                                                fullWidth
                                                size="small"
                                                multiple 
                                                limitTags={1}
                                                ListboxProps={{className: 'warehouse-apply-config-props'}}
                                                id="multiple-limit-tags"
                                                options={sitesList}
                                                getOptionLabel={(option: any) => {
                                                    return option.name;
                                                }}
                                                value={[...sites]}
                                                getOptionDisabled={(option: any) => {
                                                    let vesselOption = "All Sites";
                                                    if (sites.findIndex(item => item?.name === vesselOption) !== -1) {
                                                        return true;
                                                    }
                                                    if (option.name === vesselOption && sites[0]?.name && sites[0]?.name !== vesselOption) {
                                                        return true;
                                                    }
                                                    if(sites.find((ele)=> ele.name==option.name)) {
                                                        return true
                                                    }
                                                    return false;
                                                }}
                                                onChange={(e, newValue) => { handleChangeSite(e, newValue) }}
                                                renderInput={(params) => (<TextField {...params} required placeholder="select" style={{fontSize:'11px'}} />)}
                                            />
                                        </Grid>
                                        <span>List is Filtered based on Organization</span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy extand-width">
                                <span>Select RuleName</span>
                                <SelectTag options={ruleNames} handleChange={(e, newValue) => hanldeChangeRule(e, newValue, 'rule')} value={ruleName} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy fw-actions">
                                <span>Actions</span>
                                <FormControlLabel control={<Checkbox checked={actions[0].allowed} name='allowed' onChange={handleChangeCheckbox} />} label="Allowed" />
                                <FormControlLabel control={<Checkbox checked={actions[0].denied} name='denied' onChange={handleChangeCheckbox} />} label="Denied" />
                                { !actions[0].allowed && !actions[0].denied && <span className="select-action-warning">Please select the Action</span>}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy extand-width">
                                <span>Format</span>
                                <SelectTag options={['xlsx', 'csv']} handleChange={(e, newValue) => setFormat(newValue)} value={format} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="config-select-site-deploy DPI-checkbox suggested-filters">
                                <span>Detailed Flow Report</span>
                                <FormControlLabel control={<Checkbox checked={enableDPI} onChange={handleChangeCheckboxDPI} />} label="" />
                            </Grid>
                            <DialogActions className="config-create-actions">
                                <Button className="config-cancel-btn" onClick={handleClose}>Cancel</Button>
                                <Button className="config-create-btn" onClick = {handleClickGenerate}
                                    disabled={ !actions[0].allowed && !actions[0].denied ? true : false }
                                    style={{ backgroundColor: !actions[0].allowed && !actions[0].denied ? "#E8F0FD" : "#264C86",
                                    color: !actions[0].allowed && !actions[0].denied ? "#264C86" : "#fff" }}
                                >Generate</Button>
                            </DialogActions>
                        </Grid>
                    </ DialogContent>
                </div>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
  });
  
  export default withRouter(
    connect(mapStateToProps, { fetchResources, createDownloadServiceFirewall })( ReportPopup )
  );
