import { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, TextField, Button, InputAdornment, ListItem, Stack, Accordion, AccordionDetails, AccordionSummary, List, Box } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { getDecodeURI, getEncodedURI } from "../../utils/util";
import { useHistory, useLocation } from "react-router-dom";
import TuneIcon from '@mui/icons-material/Tune';
import _ from "lodash"
import { DEFAULT_PAGE_SIZE } from "../../constants/Constants";
import filter from "../../asset/image/filter.svg";
import { Dialog } from "@material-ui/core";
import DialogActions from "@mui/material/DialogActions";
import close from "../../asset/image/close.svg";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const DEFAULT_PAGE = 1;

interface Props {
    header: string;
    expanded?: boolean;
    children: JSX.Element;
}

const Expandable = (props: Props) => {
    const { children, header, expanded = false } = props;

    const [isExpanded, setExpanded] = useState(expanded);

    const handleOnExpand = () => {
        setExpanded(prev => !prev);
    }

    return <Accordion className="expand-accordin-root" elevation={0} expanded={isExpanded} onChange={handleOnExpand}>
        <AccordionSummary className="filter-accordian" sx={{ py: 1 }}
            classes={{ root: 'expand-summary', content: 'expand-summary-content' }}
            expandIcon={<ArrowDropDownIcon fontSize="small" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <span>{header}</span>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 2, py: 1 }}>
            {children}
        </AccordionDetails>
    </Accordion>
}

const HaLogFilters = (props) => {
    const { selectedVessel, filters, getHaLogTableData, newSummaryStartDate, newSummaryEndDate, location } = props;

    const MORE_FILTERS_TEXT = "Filters";
    const ENTER_KEY_CODE = "Enter";
    const searchTimeoutRef = useRef<NodeJS.Timeout>();

    const [searchValue, setSearchValue] = useState<string>('');

    useEffect(() => {
        setSearchValue(filters?.hasOwnProperty("searchValue") && filters.searchValue.trim() !== '' ? filters.searchValue : "")
    }, [filters]);

    useEffect(() => {
        let _filters = {}
        _filters['startDate'] = newSummaryStartDate;
        _filters['endDate'] = newSummaryEndDate;
        _filters['page'] = DEFAULT_PAGE;
        getHaLogTableData(_filters);
    }, [selectedVessel, newSummaryStartDate, newSummaryEndDate]);

    const doSearch = (value: string) => {

        let _filters = {};
        _filters["searchValue"] = value;
        _filters["page"] = DEFAULT_PAGE;
        getHaLogTableData(_filters);
    }

    const handleOnSearchClick = () => {
        doSearch(searchValue);
    }

    const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            doSearch(e.target.value);
        }, 1000)
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue);
        }
    }

    return (
        <div className="ha-logs-filter-div">
            {<TextField
                id="outlined-basic"
                variant="outlined"
                classes={{ root: "input-box-events" }}
                placeholder="Type something"
                size="small"
                value={searchValue}
                onChange={handleOnSearchChange}
                onKeyDown={(event) => searchBoxKeyDown(event)}
                InputLabelProps={{ className: "serachLabel" }}
                InputProps={{
                    className: "serachBar",
                    startAdornment: (
                        <InputAdornment position="start">
                            {!searchValue && <SearchIcon
                                className="cursorPointer"
                                onClick={handleOnSearchClick}
                            />}
                            {searchValue && <CloseIcon
                                className="cursorPointer input-search-icons"
                                onClick={handleOnSearchClear}
                            />}
                        </InputAdornment>
                    ),
                }}
            />}
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate
});

export default withRouter(
    connect(mapStateToProps, {
    })(HaLogFilters)
);