import { UsageContext } from "./UsageDataContext";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useContext, useEffect } from "react";
import { TextField } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import "./userData.css";
import { Stack } from "@mui/system";
import { getDecodeURI, getEncodedURI } from "../../utils/util";

const OuterDateRange = (props) => {
    const { setReportsPopup, location , isAuthorizedToViewBetaFeature, vesselLength, history } = props;
    let _q: any = getDecodeURI(location?.search);
    const { newSummaryStartDate, newSummaryEndDate } = useContext(UsageContext);

    const getInterval = (minutes) => {
        if (minutes > 10080) {
          return true;
        } 
        return false;
    }

    useEffect(() => {
        if((vesselLength == 0 || vesselLength == 1) && _q.hasOwnProperty('topAppClick')) {
            setReportsPopup(false);
            delete _q.topAppClick;
            history.push({ pathname: location.pathname, search: `?${getEncodedURI(_q)}` });
        }
        else if(vesselLength !== 1 ) {
            const checkInterval = getInterval(Math.floor(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3));
            !_q.isFixedIntervalDisabled && setReportsPopup(checkInterval);
        }
        // if(_q.isFixedIntervalDisabled) {
        //     localStorage.setItem("intraUrl","http://sqlapi.dev.svc.cluster.local:8080/store/ch/query")
        // } else {
        //     localStorage.setItem("intraUrl","")
        // }
    }, [newSummaryStartDate, newSummaryEndDate, vesselLength])
    
    return(
        <Stack direction='row' spacing={1}>
        </Stack>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter( connect(mapStateToProps, { })(OuterDateRange));