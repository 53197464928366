import {isArray} from "lodash";
import { GET_CSV_DATA_TO_DOWNLOAD } from "../../actions/types";
import { readableBytesAsGB } from "../../utils/util";
import moment from 'moment';
import _ from "lodash";

export const clearCsvDownload = () => {
    return {
        type: GET_CSV_DATA_TO_DOWNLOAD,
        payload: [],
        id: ''
    }
}

type CsvColumnFormatFunction = (data: any) => string;
interface CsvColumnFormatOptions {
    [index: number]: 'GB' | 'JSON' | 'ESC-COMMA' | 'ESC-COMMA-All' | CsvColumnFormatFunction;
}

interface CsvOptions {
    formatters?: CsvColumnFormatOptions;
}

const DATE_TIME_FORMAT_FILE = "YYYY_MM_DDTHH_mm";

const DownloadCSV = (csvDataDownloded: any[], options: CsvOptions = {}) => {
    if(!csvDataDownloded || !isArray(csvDataDownloded)) {
        csvDataDownloded = [];
    }
    const { formatters } = options;
    const [columns, ...rows] = csvDataDownloded;

    if(formatters && csvDataDownloded.length > 1) {
        const formatIndices = Object.keys(formatters).map(key => Number(key));
        const columnFormatters: Record<number, CsvColumnFormatFunction> = {};
        formatIndices.forEach(key => {
            const formatter = formatters[key];
            if (formatter === 'GB') {
                columnFormatters[key] = (value) => readableBytesAsGB(value, false);
            } else if (formatter === 'JSON') {
                columnFormatters[key] = (value) => value ? escapeJsonString(value): '-';
            } else if (formatter === 'ESC-COMMA') {
                columnFormatters[key] = (value) => `"${value}"`;
            } else if (formatter === 'ESC-COMMA-All') {
                columnFormatters[key] = (value) => value ? `"${value.replace(/,/g, '; ')}"` : '';
            } else {
                columnFormatters[key] = formatter;
            }
        })
        if (!_.isEmpty(rows) && Array.isArray(rows)) {
            rows.forEach((column) => {
                if (!_.isEmpty(column) && Array.isArray(column)) {
                    formatIndices.forEach(formatIndex => {
                        column[formatIndex] = columnFormatters[formatIndex](column[formatIndex]);
                    })
                }
            })
        }
    }
    let csvData = csvDataDownloded.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `data_${moment().utc().format(DATE_TIME_FORMAT_FILE)}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}

export const DownloadNamedCSV = (csvDataDownloded: any[], csvFileName:any, options: CsvOptions = {}) => {
    if(!csvDataDownloded || !isArray(csvDataDownloded)) {
        csvDataDownloded = [];
    }
    const { formatters } = options;
    const [columns, ...rows] = csvDataDownloded;

    if(formatters && csvDataDownloded.length > 1) {
        const formatIndices = Object.keys(formatters).map(key => Number(key));
        const columnFormatters: Record<number, CsvColumnFormatFunction> = {};
        formatIndices.forEach(key => {
            const formatter = formatters[key];
            if (formatter === 'GB') {
                columnFormatters[key] = (value) => readableBytesAsGB(value, false);
            } else if (formatter === 'JSON') {
                columnFormatters[key] = (value) => value ? escapeJsonString(value): '-';
            } else if (formatter === 'ESC-COMMA') {
                columnFormatters[key] = (value) => `"${value}"`;
            } else if (formatter === 'ESC-COMMA-All') {
                columnFormatters[key] = (value) => value ? `"${value.replace(/,/g, '; ')}"` : '';
            } else {
                columnFormatters[key] = formatter;
            }
        })
        if (!_.isEmpty(rows) && Array.isArray(rows)) {
            rows.forEach((column) => {
                if (!_.isEmpty(column) && Array.isArray(column)) {
                    formatIndices.forEach(formatIndex => {
                        column[formatIndex] = columnFormatters[formatIndex](column[formatIndex]);
                    })
                }
            })
        }
    }
    let csvData = csvDataDownloded.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${csvFileName}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}

export default DownloadCSV;

export function escapeJsonString(value: string) {
    return `"${value.replace(/"/g, '""')}"`;
}