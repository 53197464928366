// These categories remain same and are not expected to change.
// hence, they are hardcoded here as well as in the backend.
export const WEB_TITAN_CATEGORIES = {
    '0': {
        name: 'Compromised',
        description: 'Web pages that have been compromised by someone other than the site owner, which appear to be legitimate, but house malicious code '
    },
    '1': {
        name: 'Criminal Skills/Hacking',
        description: 'Activities that violate human rights including murder, sabatoge, bomb building, etc. Information about illegal manipulation of electronic devices, encryption, misuse, and fraud. Warez and other illegal software distribution'
    },
    '2': {
        name: 'Hate Speech',
        description: 'Web pages that promote extreme right/left wing groups, sexism, racism, religious hate and other discrimination'
    },
    '3': {
        name: 'Illegal Drugs',
        description: 'Web pages that promote the use or information of common illegal drugs and the misuse of prescription drugs and compounds '
    },
    '4': {
        name: 'Phishing/Fraud',
        description: 'Manipulated web pages and emails used for fraudulent purposes, also known as phishing'
    },
    '5': {
        name: 'Spyware and Malicious Sites',
        description: "Sites or software that installs on a user's computer with the intent to collect information or make system changes without the user's consent."
    },
    '6': {
        name: 'Nudity',
        description: 'Web pages that display full or partial nudity with no sexual references or intent '
    },
    '7': {
        name: 'Mature',
        description: 'Sites not appropriate for children. Includes sites with content about alternative lifetyles, profanity, etc.'
    },
    '8': {
        name: 'Pornography/Sex',
        description: 'Explicit sexual content unsuitable for persons under the age of 18'
    },
    '9': {
        name: 'Violence',
        description: 'Web pages that promote questionable activities such as violence and militancy'
    },
    '10': {
        name: 'Weapons',
        description: 'Web pages that include guns and weapons when not used in a violent manner '
    },
    '11': {
        name: 'Anonymizer',
        description: 'Web pages that promote proxies and anonymizers for surfing websites with the intent of circumventing filters'
    },
    '12': {
        name: 'Computers and Technology',
        description: 'Sites with information about computers, software, hardware, peripheral and computers services.'
    },
    '13': {
        name: 'Download Sites',
        description: 'Shareware, Freeware and other software. P2P sites and software.'
    },
    '14': {
        name: 'Translator',
        description: 'Web pages which translate languages from one to another'
    },
    '15': {
        name: 'Alcohol',
        description: 'Web pages that promote, advocate or sell alcohol including beer, wine and hard liquor'
    },
    '16': {
        name: 'Health',
        description: 'Personal health and medical services including sites with information on equipment, procedures, etc. Does not include drugs.'
    },
    '17': {
        name: 'Pharmacy',
        description: 'Web pages which include prescribed medications and information about approved drugs and their medical use'
    },
    '18': {
        name: 'Tobacco',
        description: 'Web pages promoting the use of tobacco related products (cigarettes, cigars, pipes)'
    },
    '19': {
        name: 'Gambling',
        description: 'Web pages which promote gambling, lotteries, casinos and betting agencies involving chance'
    },
    '20': {
        name: 'Games',
        description: 'Web pages consisting of computer games, game producers and online gaming'
    },
    '21': {
        name: 'Cars/Transportation',
        description: 'Vehicles including selling, promoting, or discussion.'
    },
    '22': {
        name: 'Dating',
        description: 'Web pages that promote relationships such as dating and marriage'
    },
    '23': {
        name: 'Home/Leisure',
        description: 'Sites with information about home improvement and decorating, family, gardening, hobbies, etc.'
    },
    '24': {
        name: 'Personal Webpages',
        description: 'Websites about or hosted by personal individuals. Communication through blogs and guestbook servers. Information on personal hobbies and activities.'
    },
    '25': {
        name: 'Restaurants',
        description: 'Food, dining and catering services including sites that provide reviews, advertisement or other promotion.'
    },
    '26': {
        name: 'Sports and Recreation',
        description: 'Sports teams, fan clubs and news. Recreation activities including zoos, public recreation centers, pools, amusement parks'
    },
    '27': {
        name: 'Travel',
        description: 'Web pages which provide travel and tourism information, online booking or travel services such as airlines, car rentals, and hotels'
    },
    '28': {
        name: 'Government',
        description: 'Government organizations, departments, or agencies. Includes police, fire, hospitals.'
    },
    '29': {
        name: 'Military',
        description: 'Web pages sponsored by the armed forces and government controlled agencies'
    },
    '30': {
        name: 'Non-profits',
        description: 'Clubs, communities, unions, and non-profit organizations. '
    },
    '31': {
        name: 'Politics and Law',
        description: 'Sites that promote a political parties and interest groups. Information on elections and legislation. Sites that offer legal information and advice.'
    },
    '32': {
        name: 'Religion',
        description: 'Religious sites and information. Includes Sects, cults, occultism and regious fundamentalism.'
    },
    '33': {
        name: 'Education',
        description: 'Educational institutions and schools. Educational and reference materials including dictionaries, encyclopedias,'
    },
    '34': {
        name: 'Art',
        description: 'Theater, museums, exhibits, photography, and digital graphic resources. '
    },
    '35': {
        name: 'Entertainment and Videos',
        description: 'Web sites for videos, TV and motion picture including celebrity sites, entertainment news.'
    },
    '36': {
        name: 'Humor',
        description: 'Web pages which include comics, jokes and other humorous content'
    },
    '37': {
        name: 'Music',
        description: 'Web pages that include internet radio and streaming media, musicians, bands, MP3 and media downloads'
    },
    '38': {
        name: 'News',
        description: 'Web pages with general news information such as newspapers and magazines'
    },
    '39': {
        name: 'Finance',
        description: 'Bank and insurance companies and other financial institutions. Active trading of certificates and stocks.'
    },
    '41': {
        name: 'Shopping',
        description: 'Online shops, catalogs and online ordering. Auction sites and advertising. Classified ads. Excludes shopping for products and services exclusively covered by another category such as health.'
    },
    '42': {
        name: 'Chat/IM',
        description: 'Communication through chat or IM services as well as sites with information about IM communication or chatrooms. '
    },
    '43': {
        name: 'Community Sites',
        description: 'Newsgroup sites and posting including forums and bulletin boards.'
    },
    '44': {
        name: 'Social Networking',
        description: 'Social networking web pages and online communities built around communities of people where users "connect" to other users'
    },
    '45': {
        name: 'Web-based Email',
        description: 'Web pages which enable users to send and/or receive email through a web accessible email account'
    },
    '46': {
        name: 'Portal Sites',
        description: 'General web pages with customized personal portals, including white/yellow pages'
    },
    '47': {
        name: 'Search Engines',
        description: 'Web pages supporting the searching of web, newsgroups, pictures, directories, and other online content'
    },
    '48': {
        name: 'Online Ads',
        description: 'Web pages strictly devoted to advertising graphics, banners, or pop-up ad content'
    },
    '49': {
        name: 'Business/Services',
        description: 'General business.'
    },
    '50': {
        name: 'Job Search',
        description: 'Web pages devoted to job searches or agencies, career planning and human resources'
    },
    '51': {
        name: 'Real Estate',
        description: 'Web pages possessing information about renting, purchasing, selling or financing real estate including homes, apartments, office space, etc.'
    },
    '52': {
        name: 'Spam',
        description: 'Products and web pages promoted through spam techniques'
    },
    '53': {
        name: 'Miscellaneous',
        description: 'Content Servers used to supply supplementary website content, or web pages that do not clearly fall into any other category'
    }
}

export const WEB_TITAN_EXCLUDED_IDS = [40];