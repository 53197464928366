import { Autocomplete, TextField } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const SelectTag = (props) => {
    const { value, handleChange , options, readOnly} = props;

    return (
        <Autocomplete
            forcePopupIcon={true}
            popupIcon={<ArrowDropDownIcon/>}
            disableClearable={value ? false : true}
            ListboxProps={{className: 'warehouse-apply-config-props'}}
            className="audit-filter-inputs-usage config-textField"
            size='small'
            value={value}
            onChange={handleChange}
            options={options}
            readOnly={readOnly}
            renderInput={(params) => <TextField {...params} placeholder="Select"/>}
        />
    )
}

export default SelectTag;