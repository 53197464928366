import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { softwareProduct, softwareListing, getVxBuildsDetails } from "../../actions/Users/authenticateSoftware";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import moment from "moment-timezone";
import _ from "lodash";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants";
import "./SoftwareListingTable.css";
import ascSorting from "../../asset/image/ascSorting.svg";
import descSorting from "../../asset/image/descSorting.svg";
import ascSortingSelected from "../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../asset/image/descSortingSelected.svg";
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { DEFAULT_PAGE_SIZE } from "../../constants/Constants";
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI } from "../../utils/util";
// import { PER_PAGE_ITEMS } from "../../constants/Constants";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { GET_VX_BUILD_DETAILS, SOFTWARE_LISTING } from "../../actions/types";

const SoftwareListingTable = (props: any) => {
  const { authReducer, softwareProduct, softwareListing, location, history, getVxBuildsDetails } = props;
  // const [structuredData, setStructuredData] = useState<any[]>([]);
  const [softwareData, setSoftwareData] = useState<any[]>([]);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit]: any = useState(DEFAULT_PAGE_SIZE);
  const [pages, setPages] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [sort, setSort] = useState<string>("opendate");
  const [sortOrder, setSortOrder] = useState<string>("asc");
  const [productList, setProductList] = useState<string[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [typesName, setTypesName] = useState(["stable", 'release']);
  // const BUILD_TYPES = [
  //   { title: "Archive", value: "archive" },
  //   { title: "ATP", value: "ATP" },
  //   { title: "Beta", value: "beta" },
  //   { title: "QA", value: "QA" },
  //   { title: "Release", value: "release" },
  //   { title: "Stable", value: "stable" },
  // ];
  const [lastPage, setLastPage] = useState(0);
  const [fromItem, setFromItem] = useState<number>(0);
  const [toItem, setToItem] = useState<number>(0);
  const [allowSetLocation, setAllowSetLocation] = React.useState(false);
  const [locationKeys, setLocationKeys] = useState<any[]>([])

  const dispatch = useDispatch();
  const [vxBuildDetails, setVxBuildDetails] = useState<any>({})
  const [vxBuildShowolderVersions, setVxBuildShowolderVersions] = useState<boolean>(false)

  useEffect(() => {
    if (!_.isEmpty(authReducer.vxBuildDetails)) {
      if (authReducer.vxBuildDetails?.data) {
        setVxBuildDetails(authReducer.vxBuildDetails.data)
      } else {
        setVxBuildDetails({})
      }

      dispatch({ type: GET_VX_BUILD_DETAILS, payload: {} })
    }
  }, [authReducer.vxBuildDetails])

  useEffect(() => {
    return history.listen(_location => {
      if (history.action === 'PUSH') {
        setLocationKeys([_location.key])
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === _location.key) {
          setLocationKeys(([_, ...keys]) => keys)
        } else {
          setLocationKeys((keys) => [_location.key, ...keys])
        }
      }
    })
  }, [locationKeys,])

  useEffect(() => {
    processQueryUrl();
  }, [location]);

  useEffect(() => {
    doSearch();
  }, [selectedProducts, sort, sortOrder]);

  useEffect(() => {
    softwareProduct();
    getVxBuildsDetails()
  }, []);

  const processQueryUrl = () => {
    let _q = getDecodeURI(location?.search);
    if (_q.products && '' != _q.products) {
      if (selectedProducts.toString() !== _q.products) {
        setSelectedProducts(_q.products.split(","));
      }
    } else if (selectedProducts.length > 0) {
      setSelectedProducts([]);
    }

    if (!_q.sort || '' == _q.sort) {
      _q.sort = "opendate";
    }
    if (sort != _q.sort) {
      setSort(_q.sort);
    }

    if (!_q.sortOrder || '' == _q.sortOrder) {
      _q.sortOrder = "asc";
    }
    if (sortOrder != _q.sortOrder) {
      setSortOrder(_q.sortOrder);
    }
  }

  const doNavigate = (params: any) => {
    history.push({ pathname: "/softwareList", search: `?${getEncodedURI(params)}` });
  }

  function createData(productName: any, type: any, osVersion: any, appVersion: any, createdOn: any, lastest: any, appShortVersion) {
    return { productName, type, osVersion, appVersion, createdOn, lastest, appShortVersion };
  }

  const setSortingField = (event: any, field: any, sorting: any) => {
    let params: any = getDecodeURI(location.search);
    params.sort = field;
    params.sortOrder = sorting;
    doNavigate(params);
  };

  const doSearch = () => {
    softwareListing(typesName, selectedProducts, sort, sortOrder, page, limit);
  };

  const switchSorting = (event: any, field: any) => {
    let sorting = "desc";
    if (sort === field) { sorting = "asc" === sortOrder ? "desc" : "asc" }
    let params: any = getDecodeURI(location.search);
    params.sort = field;
    params.sortOrder = sorting;
    doNavigate(params);
  };

  const handleTypeChange = (e: any, value) => {
    setTypesName(value);
  };

  const handleProductChange = (e: any, value) => {
    let params: any = getDecodeURI(location.search);
    params.products = value;
    doNavigate(params);
  };

  const handlePagination = (e, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (!_.isEmpty(authReducer.softwareProduct) && authReducer?.softwareProduct?.data) {
      setProductList(authReducer?.softwareProduct?.data);
      authReducer.softwareProduct = {};
    }
  }, [authReducer.softwareProduct]);


  useEffect(() => {
    if (!_.isEmpty(authReducer.softwareListing) && authReducer?.softwareListing?.data) {
      let data = authReducer?.softwareListing?.data;
      if (!data) data = [];
      for (let i = 0; i < data.length; i++) {
        data[i].showAll = false;
        if (data[i].builds) {
          for (let j = 0; j < data[i].builds.length; j++) {
            let build = data[i].builds[j];
            build.appShortVersion = "";
            if (build.app?.version) {
              let v = build.app?.version.split("~");
              if (v && v.length > 0) {
                build.appShortVersion = v[0];
              }
            }
          }
        }
      }
      setSoftwareData(data);
      const page = authReducer?.softwareListing?.page;
      const pages = authReducer?.softwareListing?.pages;
      const total = authReducer?.softwareListing?.total;
      setTotal(_getSoftware(data).length);
      setPages(pages);
      let firstRowIndex = (page - 1) * limit + 1;
      setFromItem(firstRowIndex);
      let lastRowIndex = firstRowIndex + limit - 1;
      setToItem(lastRowIndex > total ? total : lastRowIndex);
      setLastPage(Math.ceil(authReducer.softwareListing.total / limit) - 1);
      // setStructuredData(_getSoftware(data));
      setAllowSetLocation(true);
      dispatch({ type: SOFTWARE_LISTING, payload: {}})
    }
  }, [authReducer.softwareListing]);

  const handlePerPageChange = (e: any) => {
    let _perPage = parseInt(e.target.textContent);
    if (Number.isInteger(_perPage)) {
      setLimit(_perPage);
      setPage(1);
    }
  };

  const _getSoftware = (softwareList: any[]) => {
    let rows: any[] = [];
    if (Array.isArray(softwareList)) {
      for (let i = 0; i < softwareList.length; i++) {
        for (let j = 0; j < softwareList[i]?.builds?.length; j++) {
          let u_productName = softwareList[i]?.productName ? softwareList[i]?.productName : "-";
          let u_type = softwareList[i]?.type ? softwareList[i]?.type : "-";
          let u_osVersion = softwareList[i]?.builds[j]?.os?.version;
          let u_appVersion = softwareList[i]?.builds[j]?.app?.version;
          let u_createdOn = softwareList[i]?.builds[j]?.created_on;
          let u_lastest = softwareList[i]?.builds[j]?.latest;
          let u_appShortVersion = "";
          if (u_appVersion) {
            let v = u_appVersion.split("~");
            if (v && v.length > 0) {
              u_appShortVersion = v[0];
            }
          }
          rows.push(createData(u_productName, u_type, u_osVersion, u_appVersion, u_createdOn, u_lastest, u_appShortVersion));
        }
      }
    }
    return rows;
  };

  const reverseShowAll = (software, index) => {
    let s = softwareData;
    s[index].showAll = !s[index].showAll;
    setSoftwareData(JSON.parse(JSON.stringify(s)));
  }

  const renderBuild = (software: any, index: number) => {
    let inputDateFormat = "YYYY-MM-DD HH:mm:ss Z [GMT]";
    let rows: any[] = [];
    if (software && Array.isArray(software.builds) && software.builds.length > 0) {
      let row = software.builds[0];
      let idx = `${index}_0`;
      rows.push(
        <TableRow key={idx} className="fleetRowRank lastestVersion" onClick={(event) => reverseShowAll(software, index)}>
          {/* <TableCell component="th" scope="row" align="left" className="fleetRowDataDetails">{software?.productName}</TableCell>
          <TableCell align="left" className="fleetRowDataDetails">{software?.type}</TableCell> */}
          <TableCell align="center" title={"APP Version : " + row?.app.version + " / OS Version : " + row?.os.version} style={{ cursor: "default" }} className="fleetRowDataDetails">{row?.appShortVersion}</TableCell>
          <TableCell align="center" className="fleetRowDataDetails ">{convertDateTimeIntoTimezone(row.created_on, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
          <TableCell align="center" className="fleetRowDataDetails">
            {
              software.showAll ?
                <KeyboardArrowDownIcon style={{ cursor: "pointer", opacity:"0.75" }} />
                :
                <KeyboardArrowRightIcon style={{ cursor: "pointer", opacity:"0.75" }} />
            }
          </TableCell>
        </TableRow>
      );

      if (software.showAll) {
        for (let i = 1; i < software.builds.length; i++) {
          let row = software.builds[i];
          let idx = `${index}_${i}`;
          rows.push(
            <TableRow key={idx} className="fleetRowRank oldVersion">
              {/* <TableCell component="th" scope="row" align="left" className="fleetRowDataDetails">{software?.productName}</TableCell>
              <TableCell align="left" className="fleetRowDataDetails">{software?.type}</TableCell> */}
              <TableCell align="center" style={{ cursor: "pointer" }} title={"APP Version : " + row?.app?.version + " / OS Version : " + row?.os.version} className="fleetRowDataDetails">{row?.appShortVersion}</TableCell>
              <TableCell align="center" className="fleetRowDataDetails">{convertDateTimeIntoTimezone(row.created_on, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
              <TableCell align="center" className="fleetRowDataDetails"></TableCell>
            </TableRow>
          );
        }
      }
    }
    return rows;
  }

  return (
    <div className="Form">
      <Grid className="am-table softwareListing-table" style={{ marginLeft: "11px", marginTop: "40px" }}>
        <Grid style={{ paddingRight: "25px" }}>
          <Grid container item xs={12} spacing={5}>
            {/* <Grid item xs={12} sm={6} md={4} lg={4} style={{ marginTop: "15px" }}>
              <Autocomplete
                multiple id="tags-outlined" filterSelectedOptions
                limitTags={1} options={BUILD_TYPES} style={{ textTransform: "capitalize" }}
                getOptionLabel={(option) => option.title} sx={{ maxWidth: 230 }}
                onChange={(e: any, newValue: any) => { handleTypeChange(e, newValue.map((el: any) => el.value)) }}
                renderInput={(params) => (<TextField {...params} size="small" label="Select Build Type" />)}
              />
            </Grid> */}
            <Grid item xs={12} sm={6} md={4} lg={4} className='select-device margin45'>
              {/* <Autocomplete
                className="ProductAutoComplete"
                multiple id="tags-outlined" limitTags={1}
                value={selectedProducts}
                options={productList}
                filterSelectedOptions
                sx={{ maxWidth: 230 }}
                onChange={(e: any, newValue: any) => { handleProductChange(e, newValue) }}
                renderInput={(params) => (<TextField {...params} size="small" label="Select Device" />)}
              /> */}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} style={{ marginTop: "15px" }}></Grid>
            <Grid item container xs={12} sm={6} md={4} lg={4} style={{ marginTop: "15px", justifyContent: "flex-end", display: "flex", alignItems:"center" }}>
              <Grid item className="LatestCheck lastestVersion"></Grid>
              <Grid item className="textLatestBuildOn">Latest Build</Grid>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6} lg={6} style={{display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: "10px"}}>
              <Grid>
                <Grid className="vesselPageDetails">Per Page</Grid>
                <Grid className="vesselPagination">{`${fromItem} to ${toItem} out of ${total}`} Software</Grid>
              </Grid>
              <Grid style={{ marginLeft: "10px" }}>
                <Autocomplete
                  disablePortal id="pagination" options={PER_PAGE_ITEMS} 
                  className="props.classes.paper" sx={{ width: 65 }} disableClearable
                  onChange={(e: any) => {handlePerPageChange(e)}}
                  value={{ label: `${limit}` }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" InputLabelProps={{ className: "autoCompleteBox" }}/>
                  )}
                />
              </Grid>
              <Grid style={{ marginLeft: "10px" }}>
                <Stack>
                  <Pagination
                    className="paginationContainer" count={pages} page={page} size="small"
                    onChange={handlePagination} variant="outlined" shape="rounded"
                    siblingCount={0} defaultPage={1} boundaryCount={0}
                  />
                </Stack>
              </Grid>
            </Grid> */}
          </Grid>
          <Grid container spacing={2} className="parent-element -menuScroll">
            <Grid item xs>
              <Grid className="table-title">EOS</Grid>
              <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                <Table aria-label="simple sticky table" stickyHeader>
                  <TableHead className="usageReports-tableHead">
                    <TableRow className="UsageReports-tableRow login-tableRow">
                      <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                        <span style={{ whiteSpace: "nowrap" }}>
                          <span className="login-history-dp-name paddingRight">VERSION</span>
                        </span>
                      </TableCell>
                      <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                        <span style={{ whiteSpace: "nowrap" }}>
                          <span className="login-history-dp-name paddingRight">RELEASED ON</span>
                        </span>
                      </TableCell>
                      <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell"></TableCell>
                    </TableRow>
                  </TableHead>
                  {softwareData && 0 != softwareData.length ? (
                    <TableBody className="tableBody usage-tableBody">
                      {softwareData.map((software, index) => {
                      if(software?.productName == 'EdgeServer v2') {
                        return renderBuild(software, index)
                      }
                    }
                      )}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={3} className="noDataAvailableCss" align="center">No data available</TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs>
            <Grid className="table-title"><span>Virtual EOS</span><a className="virtual-eos-quick-start-guide" href="https://kognitive.scrollhelp.site/kb/virtual-device-quick-start-guide" target="_blank">Quick Start Guide</a></Grid>
              <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                <Table aria-label="simple sticky table" stickyHeader>
                  <TableHead className="usageReports-tableHead">
                    <TableRow className="UsageReports-tableRow login-tableRow">
                      <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                        <span style={{ whiteSpace: "nowrap" }}>
                          <span className="login-history-dp-name paddingRight">VERSION</span>
                        </span>
                      </TableCell>
                      <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                        <span style={{ whiteSpace: "nowrap" }}>
                          <span className="login-history-dp-name paddingRight">RELEASED ON</span>
                        </span>
                      </TableCell>
                      <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                        <span style={{ whiteSpace: "nowrap" }}>
                          <span className="login-history-dp-name paddingRight">VMDK</span>
                        </span>
                      </TableCell>
                      <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell">
                        <span style={{ whiteSpace: "nowrap" }}>
                          <span className="login-history-dp-name paddingRight">QCOW</span>
                        </span>
                      </TableCell>
                      <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell"></TableCell>
                    </TableRow>
                  </TableHead>
                  {
                    !_.isEmpty(vxBuildDetails)
                      ?
                      <TableBody className="tableBody usage-tableBody">
                        {
                          Object.keys(vxBuildDetails).map((version,index) =>
                            !_.isEmpty(vxBuildDetails[version]) && vxBuildDetails[version]["latest"] || vxBuildShowolderVersions
                              ?
                              <TableRow key={version} className={vxBuildDetails[version] && vxBuildDetails[version]["latest"] ? "fleetRowRank lastestVersion" : "fleetRowRank"}>
                                <TableCell align="center" className="fleetRowDataDetails">{!_.isEmpty(version) && version.split("~")[0]?version.split("~")[0].replace("v",""):""}</TableCell>
                                <TableCell align="center" className="fleetRowDataDetails ">{convertDateTimeIntoTimezone(vxBuildDetails[version]["created_on"], authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                <TableCell align="center" className="fleetRowDataDetails">
                                  {
                                    vxBuildDetails[version] && vxBuildDetails[version]["vmdk_url"]
                                      ?
                                      <a href={vxBuildDetails[version]["vmdk_url"]} target="_blank">Download</a>
                                      : null
                                  }
                                </TableCell>
                                <TableCell align="center" className="fleetRowDataDetails">
                                  {
                                    vxBuildDetails[version] && vxBuildDetails[version]["qcow_url"]
                                      ?
                                      <a href={vxBuildDetails[version]["qcow_url"]} target="_blank">Download</a>
                                      : null
                                  }
                                </TableCell>
                                <TableCell align="center" className="fleetRowDataDetails" >
                                  {
                                    Object.keys(vxBuildDetails).length > 1 && vxBuildDetails[version] && vxBuildDetails[version]["latest"]
                                      ?
                                      vxBuildShowolderVersions ?
                                        <KeyboardArrowDownIcon className="arrow-down-icon" onClick={() => setVxBuildShowolderVersions(false)} />
                                        :
                                        <KeyboardArrowRightIcon className="arrow-right-icon" onClick={() => setVxBuildShowolderVersions(true)} />
                                      : null
                                  }
                                </TableCell>
                              </TableRow>
                              : null
                          )
                        }
                      </TableBody>
                      :
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={5} className="noDataAvailableCss" align="center">No data available</TableCell>
                        </TableRow>
                      </TableBody>
                  }
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    softwareProduct, softwareListing, getVxBuildsDetails
  })(SoftwareListingTable)
);
