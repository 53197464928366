import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import { Grid } from "@mui/material";
import Highcharts from "highcharts";
import { getUptimeLinechart, getUptimePiechart, getUptimeLinechartBySite, getUptimePiechartByWan, getUptimeTableData, getNewSummaryUptimeAllLinks, getTopWidgetWanlinkStatusNewSumm } from "../../../actions/Users/authenticateTrends";
import { getDateForChart, getDecodeURI, getEncodedURI, getSingleRecordChartLable, getTimezoneCity } from "../../../utils/util";
import moment from "moment";
import { UPDATE_NEW_SUMMARY_END_DATE, UPDATE_NEW_SUMMARY_START_DATE, UPDATE_NEW_SUMMARY_WAN_TYPE } from "../../../actions/types";
import SiteUsagePie from "../ChartsComponent/SiteUsagePie";
import WanLineChart from "../ChartsComponent/WanLineChart";
import SiteLineChart from "../ChartsComponent/SiteLineChart";
import WanUsagePie from "../ChartsComponent/WanUsagePie";

const UpTimeCharts = (props) => {
    const { handleSiteClick, getUptimeLinechart, getUptimePiechart, getUptimeLinechartBySite, newSummaryUptimeLinechart, getUptimePiechartByWan, getUptimeTableData, getNewSummaryUptimeAllLinks, getTopWidgetWanlinkStatusNewSumm, newSummaryStartDate, newSummaryEndDate, newSummaryWanType, newSummaryUptimePiechart, newSummaryUptimeLineChartBySite, newSummaryUptimePiechartByWan, filteredVessels, getVesselsListing, userTimezone, location, history } = props;
    
    const _q = getDecodeURI(location?.search);
    const [chartLimit, setChartLimit] = useState(10);
    const [lineChartUsage, setLineChartUsage] = useState<any>([]);
    const [chartView, setChartView] = useState('spline');
    const [resetDate, setResetDate] = useState([]);
    const [usagePerSite, setUsagePerSite] = useState<any>([]);
    const [usagePerWan, setUsagePerWan] = useState<any>([]);
    const [pieLimit, setPieLimit] = useState(_q.hasOwnProperty('pieLimit') ? _q.pieLimit : 10);
    const dispatch = useDispatch();
    const [toggleBtn, setToggleBtn] = useState(_q.hasOwnProperty('toggle') ? true : false);
    const [lineChartBySite, setLineChartBySite] = useState<any>([]);
    const [isHistogram, setIsHistogram] = useState(false);
    const [vessels, setVessels] = useState([]);
    const getIntervalBySite = (minutes) => {
        if (minutes <= 360) {
          return "30m";
        } else if (minutes > 360 && minutes <= 1440) {
          return "2h";
        } else if (minutes > 1440 && minutes <= 10080) {
          return "12h";
        } else {
          return "1d";
        }
    }
      
    const getIntervalbyWan = (minutes) => {
        if (minutes <= 360) {
            return "10m";
        } else if (minutes > 360 && minutes <= 1440) {
            return "1h";
        } else if (minutes > 1440 && minutes <= 10080) {
            return "6h";
        } else {
            return "1d";
        }
    }
    const [selectedBinWan, setSelectedBinWan] = useState(getIntervalbyWan(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)));
    const [selectedBinSite, setSelectedBinSite] = useState(getIntervalBySite(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)));
    const timeZone = getTimezoneCity(userTimezone);
    const getSelectedIntervalMin = (interval) => {
        switch (interval) {
            case "5m": return 5;
            case "10m": return 10;
            case "15m": return 15;
            case "30m": return 30;
            case "1h": return 60;
            case "2h": return 120;
            case "3h": return 180;
            case "6h": return 360;
            case "12h": return 720;
            case "1d": return 1440;
            case "7d": return 10080;
            default: return 30;
        }
    }

    useEffect(() => {
        if (_.isEmpty(getVesselsListing))
            return;
        let vessels = [];
        const _filteredVessels = filteredVessels;
        if (_filteredVessels && _filteredVessels.length > 0) {
            vessels = _filteredVessels;
        } else {
            vessels = getVesselsListing?.locations;
        }
        setVessels(vessels);
    }, [getVesselsListing, filteredVessels]);

    useEffect(() => {
        if (vessels.length > 0) {
            const interval_min = getSelectedIntervalMin(selectedBinSite);
            ((newSummaryWanType?.value == '' && !toggleBtn) || (newSummaryWanType?.value && !toggleBtn)) && getUptimeLinechart(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, chartLimit, selectedBinWan);
            ((newSummaryWanType?.value == '' && toggleBtn) || (newSummaryWanType?.value && toggleBtn)) && getUptimeLinechartBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, pieLimit, selectedBinSite, interval_min);
            ((newSummaryWanType?.value == '' && toggleBtn) || (newSummaryWanType?.value && toggleBtn)) && getUptimePiechart(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, pieLimit);
            ((newSummaryWanType?.value == '' && !toggleBtn) || (newSummaryWanType?.value && !toggleBtn)) && getUptimePiechartByWan(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, pieLimit);
        } else {
            setLineChartUsage([]);
            setUsagePerSite([]);
            setLineChartBySite([]);
            setUsagePerWan([]);
        }
    },[vessels, newSummaryWanType])

    useEffect(() => {
        const wanInterval = getIntervalbyWan(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3));
        const siteInterval = getIntervalBySite(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3));
        setSelectedBinWan(wanInterval);
        setSelectedBinSite(siteInterval);
        const interval_min = getSelectedIntervalMin(siteInterval);
        if (vessels.length > 0) {
            ((newSummaryWanType?.value == '' && !toggleBtn) || (newSummaryWanType?.value && !toggleBtn)) && getUptimeLinechart(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, chartLimit, wanInterval);
            ((newSummaryWanType?.value == '' && toggleBtn) || (newSummaryWanType?.value && toggleBtn)) && getUptimeLinechartBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, pieLimit, siteInterval, interval_min);
            ((newSummaryWanType?.value == '' && toggleBtn) || (newSummaryWanType?.value && toggleBtn)) && getUptimePiechart(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, pieLimit);
            ((newSummaryWanType?.value == '' && !toggleBtn) || (newSummaryWanType?.value && !toggleBtn)) && getUptimePiechartByWan(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, pieLimit);
        }
    }, [newSummaryStartDate, newSummaryEndDate])

    useEffect(() => {
        if(!_.isEmpty(newSummaryUptimeLinechart)) {
            const data = newSummaryUptimeLinechart?.hasOwnProperty('data') ? newSummaryUptimeLinechart?.data : {};
            if (data) {
                const chartData: any = data?.percUsage?.series && Object.keys(data?.percUsage?.series)?.map((key, i) => {
                    const color = key == 'LTE' ? '#9ec4f8' : key == 'ETHERNET' ? '#8AC9E0' : key == 'LBAND' ? '#3a88d1' : key == 'STARLINK' ? '#1d136a' : key=='VSAT' ? '#1f3da8' : '#4dbaf7';
                    return { name: key, data: data?.percUsage?.series[key], marker: { symbol: 'circle' }, visible: true, color }
                })
                setLineChartUsage(chartData);
            }
        }
        if(!_.isEmpty(newSummaryUptimeLineChartBySite)) {
            const data = newSummaryUptimeLineChartBySite?.hasOwnProperty('data') ? newSummaryUptimeLineChartBySite?.data : {};
            if (data) {
                const chartData: any = data?.percUsage?.series && Object.keys(data?.percUsage?.series)?.map((key, i) => {
                    return { name: key.split('|')[0], k4Id: key.split('|')[1], data: data?.percUsage?.series[key], marker: { symbol: 'circle' }, visible: true }
                })
                setLineChartBySite(chartData);
            }
        }
    }, [newSummaryUptimeLinechart, newSummaryUptimeLineChartBySite])

    useEffect(() => {
        if(!_.isEmpty(newSummaryUptimePiechart)) {
            const data = newSummaryUptimePiechart?.hasOwnProperty('data') ? newSummaryUptimePiechart?.data : {};
            if(data) {
                const Usage = data?.rows && data?.rows?.map((item) => {
                    return {x: item[1], y: item[2], k4Id: item[0]}
                })
                setUsagePerSite(Usage);
            }
        }
        if(!_.isEmpty(newSummaryUptimePiechartByWan)) {
            const data = newSummaryUptimePiechartByWan?.hasOwnProperty('data') ? newSummaryUptimePiechartByWan?.data : {};
            if(data) {
                const Usage = data?.rows && data?.rows?.map((item) => {
                    const color = item[0] == 'lte' ? '#9ec4f8' : item[0] == 'wired' ? '#8AC9E0' : item[0] == 'lband' ? '#3a88d1' : item[0] == 'starlink' ? '#1d136a' : item[0]=='vsat' ? '#1f3da8' : '#4dbaf7';
                    const value = item[0] == 'lte' ? 'lte' : item[0] == 'wired' ? 'ethernet' : item[0] == 'lband' ? 'lband' : item[0] == 'starlink' ? 'starlink' : 'vsat';
                    const name = item[0] == 'lte' ? 'CELLULAR' : item[0] == 'vsat' ? 'VSAT' : item[0] == 'starlink' ? 'STARLINK' : item[0] == 'wifi' ? 'WIFI' : item[0] == 'wired' ? 'ETHERNET' : 'LBAND';
                    return {x: name, y: item[1], value, color}
                })
                setUsagePerWan(Usage);
            }
        }
    }, [newSummaryUptimePiechart, newSummaryUptimePiechartByWan])

    const pinchAndResetChart = (start, end, interval) => {
        let params = getDecodeURI(location?.search);
        params.startDate = start.valueOf();
        params.endDate = end.valueOf();
        params.interval = interval;
        doNavigate(params);
    }

    const chartAreaSelection = () => {
        return (event: any) => {
            let start:any = moment(new Date(Math.ceil(event.xAxis[0].min)));
            let end:any = moment(new Date(Math.floor(event.xAxis[0].max)));
            const timeBand = Math.ceil(((new Date(end)).getTime() - (new Date(start)).getTime()) / 60e3);
            timeBand < 5 ? start = moment(Date.now()).subtract(5, 'minutes') : start = moment(new Date(Math.ceil(event.xAxis[0].min)));
            timeBand < 5 ? end = moment(Date.now()) : end = moment(new Date(Math.floor(event.xAxis[0].max)));
            pinchAndResetChart(start, end, 'customDates');

            let arr:any = resetDate;
            arr.push({startDate: start, endDate: end});
            setResetDate(arr);
            return false;
        }
    }

    const lineOptions: any = {
        time: {
            timezone: timeZone
        },
        chart: {
            type: chartView,
            height: '260px',
            zoomType: 'x',
            plotBorderWidth: 1,
            resetZoomButton: {
                theme: {
                    style: {
                        display: 'none'
                    }
                }
            },
            events: {
                selection: chartAreaSelection()
            },
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
        },
        colors: ['#2f2282', '#1b51a7', '#3dab97', '#5e3f9e', '#3e88d3', '#1d136a', '#69b8e5', '#3f79ca', '#9e8cc5', '#ed9340',
            '#8cafdf', '#d9914f', '#86c6df', '#3c7df1', '#61cdc0', '#64a1f4', '#dc6161', '#146680', '#2039b5', '#00ad64',
            '#1b4dac', '#b50000', '#8cb5ec', '#c1d5f6', '#b3bbe6', '#e5a8a8', '#f9dabe', '#4b91f1', '#8dbdff', '#efeab9',
            '#8a39a7', '#d9cb4f', '#145f89', '#e7d696', '#8592e7', '#e78585', '#92d7c9', '#aeaeb1', '#755c5d', '#7c6ce6',
            '#cc7460', '#bd9331', '#15e1e8', '#514ced', '#8633de', '#eb52b3', '#c4355b', '#6dc779', '#5373a3', '#8dc293'
        ],
        title: {
            text: '',
            align: 'left',
            floating: true,
            x: 12,
            y: 32,
            style: {
                fontWeight: '500'
            }
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                turboThreshold: 1000000,
                stickyTracking: false,
                connectNulls: false,
            },
            colorByPoint: true
        },
        tooltip: {
            valueSuffix: ' %',
            headerFormat: '',
            pointFormat: '<span style="color: #7e8089">{series.name}</span> <br/> <b style="color: #fff">{point.y}</b><br/>',
            valueDecimals: 2,
            borderWidth: 1,
            useHTML: true,
            borderRadius: 15,
            backgroundColor: '#060606',
        },
        xAxis: {
            gridLineWidth: 0.5,
            reversed: false,
            type: "datetime",
            maxPadding: 0.05,
            showLastLabel: true,
            labels: {
                style: {
                    color: '#2F4B82',
                    fontFamily: 'Inter',
                    fontSize: '9px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                },
                format: !toggleBtn && getDateForChart(lineChartUsage) ? getSingleRecordChartLable(lineChartUsage, timeZone) : toggleBtn && getDateForChart(lineChartBySite) ? getSingleRecordChartLable(lineChartBySite, timeZone) : ''
            }
        },
        yAxis: {
            max: 100,
            tickInterval: 1,
            gridLineWidth: 1,
            labels: {
                format: '{value}',
                style: {
                    color: '#2F4B82',
                    fontFamily: 'Inter',
                    fontSize: '9px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                }
            },
            title: {
                text: 'Percentage',
            }
        },
        series: ((!newSummaryWanType?.value && !toggleBtn) || (newSummaryWanType?.value && !toggleBtn)) ? lineChartUsage : lineChartBySite, 
        colorByPoint: true
    }

    const pieOptions: Highcharts.Options = {
        chart: {
            type: 'pie',
            height: '185px',
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
            backgroundColor: 'none'
        },
        colors: ['#2f2282', '#1b51a7', '#3dab97', '#5e3f9e', '#3e88d3', '#1d136a', '#69b8e5', '#3f79ca', '#9e8cc5', '#ed9340',
            '#8cafdf', '#d9914f', '#86c6df', '#3c7df1', '#61cdc0', '#64a1f4', '#dc6161', '#146680', '#2039b5', '#00ad64',
            '#1b4dac', '#b50000', '#8cb5ec', '#c1d5f6', '#b3bbe6', '#e5a8a8', '#f9dabe', '#4b91f1', '#8dbdff', '#efeab9',
            '#8a39a7', '#d9cb4f', '#145f89', '#e7d696', '#8592e7', '#e78585', '#92d7c9', '#aeaeb1', '#755c5d', '#7c6ce6',
            '#cc7460', '#bd9331', '#15e1e8', '#514ced', '#8633de', '#eb52b3', '#c4355b', '#6dc779', '#5373a3', '#8dc293'
        ],
        title: {
            verticalAlign: 'middle',
            floating: true,
            text: '',
            style: {
                color: '#9E9E9E',
                fontFamily: 'Roboto',
                fontSize: '10px',
                fontWeight: '400'
            }
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        tooltip: {
            enabled: true,
            headerFormat: '',
            pointFormat: '<span style="color: #7e8089">{point.x}</span> <br/> <b style="color: #fff">{point.y} %</b><br/>',
            valueDecimals: 2,
            useHTML: true,
            borderRadius: 15,
            backgroundColor: '#060606',
            followPointer: false
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                shadow: false,
            },
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        series: [{
            type: 'pie',
            data: ((!newSummaryWanType?.value && !toggleBtn) || (newSummaryWanType?.value && !toggleBtn)) ? usagePerWan : usagePerSite,
            size: '100%',
            innerSize: '50%',
            dataLabels: {
                enabled: false
            }
        }]
    }

    const handleResetZoom = () => {
        if(resetDate?.length == 0) {
          return;
        }
        let arr:any = resetDate;
        arr.pop();
        setResetDate(arr);
        if(arr?.length == 0) {
            const start = moment(Date.now()).subtract(1, 'hour');
            const end = moment(Date.now());
            pinchAndResetChart(start, end, '1h');
        }
        else {
            let obj = arr[arr?.length-1];
            let start = obj.startDate;
            let end = obj.endDate;
            pinchAndResetChart(start, end, 'customDates');
        }
    }
    
    const handleDefaultResetZoom = () => {
        setResetDate([]);
        const start = moment(Date.now()).subtract(1, 'hour');
        const end = moment(Date.now());
        pinchAndResetChart(start, end, '1h');
    }

    const handleLegendClick = (index) => {
        let all_true = true;
        let updatedData: any = !newSummaryWanType?.value && !toggleBtn ? [...lineChartUsage] : [...lineChartBySite];
        let all_off_turn_on = 0;
        let click = 0;

        updatedData?.forEach((ele, i) => {
            if (!ele.visible) {
                all_true = false;
                all_off_turn_on += 1;
            }
            if (ele.visible) {
                click = i;
            }
        })

        if (click == index && all_off_turn_on == updatedData.length - 1) {
            updatedData?.forEach((ele, i) => {
                updatedData[i] = { ...ele, visible: true }
            })
            !newSummaryWanType?.value && !toggleBtn ? setLineChartUsage([...updatedData]) : setLineChartBySite([...updatedData]);
        }
        else if (all_true) {
            updatedData?.forEach((ele, i) => {
                updatedData[i] = { ...ele, visible: false }
            })
            updatedData[index].visible = true;
            !newSummaryWanType?.value && !toggleBtn ? setLineChartUsage([...updatedData]) : setLineChartBySite([...updatedData]);
        }
        else {
            updatedData.forEach((ele, i) => {
                updatedData[i] = ele;
            })
            updatedData[index].visible = !updatedData[index].visible;
            !newSummaryWanType?.value && !toggleBtn ? setLineChartUsage([...updatedData]) : setLineChartBySite([...updatedData]);
        }
    }

    const handleChangePieLimit = (e) => {
        const value = e.target.value;
        setPieLimit(value);
        let params: any = getDecodeURI(location?.search);
        value == 10 ? delete params.pieLimit : params.pieLimit = value;
        doNavigate(params);
        const interval_min = getSelectedIntervalMin(selectedBinSite);

        vessels && getUptimePiechart(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, value);
        vessels && getUptimeLinechartBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, value, selectedBinSite, interval_min);
    }

    const handleChangeToggle = (e) => {
        const value = e.target.checked;
        setToggleBtn(value);
        let params: any = getDecodeURI(location?.search);
        value ? params.toggle = true : delete params.toggle;
        doNavigate(params);
        const interval_min = getSelectedIntervalMin(selectedBinSite);
        ((newSummaryWanType?.value == '' && !value) || (newSummaryWanType?.value && !value)) && getUptimeLinechart(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, chartLimit, selectedBinWan);
        ((newSummaryWanType?.value == '' && value) || (newSummaryWanType?.value && value)) && getUptimeLinechartBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, pieLimit, selectedBinSite, interval_min);
        ((newSummaryWanType?.value == '' && value) || (newSummaryWanType?.value && value)) && getUptimePiechart(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, pieLimit);
        ((newSummaryWanType?.value == '' && !value) || (newSummaryWanType?.value && !value)) && getUptimePiechartByWan(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, pieLimit);
    };

    const handleWanTypeClick = (row) => {
        const selectedWan = {label: row.x == 'CELLULAR' ? 'LTE' : row.x, value: row.value};
        let params: any = getDecodeURI(location?.search);
        params.wanType = selectedWan.label;
        doNavigate(params);
        dispatch({ type: UPDATE_NEW_SUMMARY_WAN_TYPE, payload: selectedWan });
    }

    const handleBinChange = (e) => {
        const value = e.target.value;
        setSelectedBinWan(e.target.value);
        setSelectedBinSite(e.target.value);
        const interval_min = getSelectedIntervalMin(value);

        if (vessels.length > 0) {
            ((newSummaryWanType?.value == '' && !toggleBtn) || (newSummaryWanType?.value && !toggleBtn)) && getUptimeLinechart(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, chartLimit, value);
            ((newSummaryWanType?.value == '' && toggleBtn) || (newSummaryWanType?.value && toggleBtn)) && getUptimeLinechartBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, pieLimit, value, interval_min);
        }
    };

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const colors = ['#2f2282', '#1b51a7', '#3dab97', '#5e3f9e', '#3e88d3', '#1d136a', '#69b8e5', '#3f79ca', '#9e8cc5', '#ed9340',
        '#8cafdf', '#d9914f', '#86c6df', '#3c7df1', '#61cdc0', '#64a1f4', '#dc6161', '#146680', '#2039b5', '#00ad64',
        '#1b4dac', '#b50000', '#8cb5ec', '#c1d5f6', '#b3bbe6', '#e5a8a8', '#f9dabe', '#4b91f1', '#8dbdff', '#efeab9',
        '#8a39a7', '#d9cb4f', '#145f89', '#e7d696', '#8592e7', '#e78585', '#92d7c9', '#aeaeb1', '#755c5d', '#7c6ce6',
        '#cc7460', '#bd9331', '#15e1e8', '#514ced', '#8633de', '#eb52b3', '#c4355b', '#6dc779', '#5373a3', '#8dc293'
    ]

    return (
        <div className="new-summary-chart-container padding-left-5">
            <Grid container spacing={2} className='new-summary-chart-component'>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                        {((newSummaryWanType?.value == '' && !toggleBtn) || (newSummaryWanType?.value && !toggleBtn)) ?
                            <Grid className="usage-per-site-pie">
                                <WanUsagePie
                                    title='Availability'
                                    toggleBtn={toggleBtn}
                                    handleChangeToggle={handleChangeToggle}
                                    wanOptions={pieOptions}
                                    usagePerWanType={usagePerWan}
                                    handleWanTypeClick={handleWanTypeClick}
                                />
                            </Grid>
                            : <Grid className="usage-per-site-pie">
                                <SiteUsagePie
                                    title='Availability'
                                    pieLimit={pieLimit}
                                    handleChangePieLimit={handleChangePieLimit}
                                    toggleBtn={toggleBtn}
                                    handleChangeToggle={handleChangeToggle}
                                    wanOptions={pieOptions}
                                    usagePerSite={usagePerSite}
                                    handleSiteClick={handleSiteClick}
                                    colors={colors}
                                />
                            </Grid>
                        }
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9} className="uptime-charts-component">
                    <Grid className="usage-per-site-pie">
                        {((!newSummaryWanType?.value && !toggleBtn) || (newSummaryWanType?.value && !toggleBtn)) ? <WanLineChart
                            title='Availability'
                            data={lineChartUsage}
                            setChartView={setChartView}
                            resetDate={resetDate}
                            handleResetZoom={handleResetZoom}
                            handleDefaultResetZoom={handleDefaultResetZoom}
                            options={lineOptions}
                            handleLegendClick={handleLegendClick}
                            chartView={chartView}
                            selectedBin={selectedBinWan}
                            handleBinChange={handleBinChange}
                        /> :
                        <SiteLineChart
                            title='Availability'
                            data={lineChartBySite}
                            setChartView={setChartView}
                            resetDate={resetDate}
                            handleResetZoom={handleResetZoom}
                            handleDefaultResetZoom={handleDefaultResetZoom}
                            options={lineOptions}
                            handleSiteClick={handleSiteClick}
                            colors={colors}
                            chartView={chartView}
                            setIsHistogram={setIsHistogram}
                            selectedBin={selectedBinSite}
                            handleBinChange={handleBinChange}
                        /> }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    newSummaryUptimeLinechart: state?.authReducer?.newSummaryUptimeLinechart,
    newSummaryUptimeLineChartBySite: state?.authReducer?.newSummaryUptimeLineChartBySite,
    newSummaryUptimePiechart: state?.authReducer?.newSummaryUptimePiechart,
    newSummaryUptimePiechartByWan: state?.authReducer?.newSummaryUptimePiechartByWan,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    newSummaryWanType: state?.authReducer?.newSummaryWanType,
    filteredVessels: state?.authReducer?.filteredVessels,
    getVesselsListing: state?.authReducer?.getVesselsListing,
    userTimezone: state?.authReducer?.userTimezone,
    errorReducer: state.errorReducer
  });
  
  export default withRouter(
    connect(mapStateToProps, { getUptimeLinechart, getUptimePiechart, getUptimeLinechartBySite, getUptimePiechartByWan, getUptimeTableData, getNewSummaryUptimeAllLinks, getTopWidgetWanlinkStatusNewSumm })(UpTimeCharts)
  );