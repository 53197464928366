import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import moment, { Moment } from "moment-timezone";

import {
    getFirewallWidgets,
    getFirewallActionTodayGraph,
    FirewallReportPayload,
    getFirewallRuleNameGraph,
    getFirewallReportTableData,
    getFirewallReportRuleFilter,
    getFirewallTableDataCount,
    getFirewallLineChart
} from "../../actions/Users/authenticateReports";
import _ from "lodash";
import { getDecodeURI, getEncodedURI, strToK4IdList, strToK4List, strToList } from "../../utils/util";
import { DEFAULT_PAGE_SIZE } from "../../constants/Constants";
import { RowPerPageHandler, PageChangeHandler } from "../../components/Pagination";
import { updatFilteredVessels } from "../../actions/Users/authenticate";
import CustomLoader from "../../components/Loader/CustomLoader";
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";
import DownloadCSV, { clearCsvDownload } from "../../components/DownloadCSV";
import { GET_CSV_DATA_TO_DOWNLOAD } from "../../actions/types";
import { toast } from "react-toastify";

const convertToCsvFirewall = (data: any[][]) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
      csvRows.push([row[1], row[0], row[2], row[3], row[7], row[9], row[4], row[8], row[10], row[5],  row[6] === 0 ? 'Denied' : 'Allowed'])
    })
    return [['Rule Name', 'Site Name', 'Protocol', 'Source Interface', 'Source IP Domain', 'Source Port', 'Destination Interface', 'Destination IP Domain', 'Destination Port', 'Number of Packets', 'Action'], ...csvRows]
  }
interface FirewallReportContextType {
    page: number;
    limit: number;
    vessels: any[];
    activities: Activity[];
    selectedStartDate: Moment;
    selectedEndDate: Moment;
    startDate: any;
    endDate: any;
    ruleName: string;
    currentZoom: Array<[Moment, Moment]>;
    zoomOut(): void;
    resetZoom(): void;
    onPinch(startDate: Moment, endDate: Moment): void;
    onApplyFilters(): void;
    onSelectFilters(filters: Partial<FirewallSelectFilters>): void;
    onClearFilters(): void;
    handleActivityFilter(activity: Activity): void;
    handleRuleNameChange(name: string | null): void;
    handleChangePageUser: PageChangeHandler;
    handleChangeRowsPerPageUser: RowPerPageHandler,
    handleTopUsageOnCsvDownload: (count: number) => void;
    setStartDate: any,
    setEndDate: any,
    handleBinChange: any,
    selectedBin: any
}

export type DateChangeHandler = (date: Moment | null, refresh?: boolean) => void;

interface FirewallSelectFilters {
    filterRuleName: string;
    filterSiteName: string;
    filterActivities: Activity[];
    trackSite: boolean
}
interface FirewallReportFilters extends Omit<FirewallReportPayload, 'k4Id'> {
    filterVessels: any[];
    ou: string;
    filterdActivities: Array<Activity>;
    pageStart?: number;
    interval?: string;
    pageLimit?: number
}

export const FirewallReportContext = React.createContext<FirewallReportContextType>(Object.assign({}));


const AllowedActivity: Activity = {
    label: 'Allowed',
    key: 'allowed',
    value: 1,
    selected: false
};

const DeniedActivity: Activity = {
    label: 'Denied',
    key: 'denied',
    value: 0,
    selected: false
};


export interface Activity {
    label: string;
    key: string;
    value: number;
    selected: boolean;
}

export interface Activities {
    allowed: boolean;
    denied: boolean;
}

export const updateActivitySelection = (activities: Activity[], label: string) => {
    let selectedActivity: Activity | null = null;
    activities.map(act => {
        if (act.label === label) {
            selectedActivity = act;
            act.selected = true;
            return;
        }
        act.selected = false;
        return act;
    });
    return selectedActivity;
}

const applyActivityFilter = (filters: string[], activities: Activity[]) => {
    filters.forEach(filter => {
        switch (filter) {
            case '1':
                activities[0].selected = true;
                break;
            default:
                activities[1].selected = true;
                break;
        }
    })
    return activities;
}

const FirewallContextProvider = (props: any) => {

    const { authReducer, children, location, history, getFirewallWidgets, getFirewallActionTodayGraph, getFirewallRuleNameGraph, getFirewallReportTableData, getFirewallReportRuleFilter, getFirewallTableDataCount, getFirewallLineChart, filteredVessels, selectedOu, selectedVessels, getVesselsListing, updatFilteredVessels, newSummaryStartDate, newSummaryEndDate, downloadAsCSV } = props;

    const _q = getDecodeURI(location?.search);

    const [selectedStartDate, setSelectedStartDate] = useState(_q.hasOwnProperty("startDate") ? moment(new Date(parseInt(_q.startDate))) : moment(Date.now()).subtract(1, 'days'));
    const [selectedEndDate, setSelectedEndDate] = useState(_q.hasOwnProperty("endDate") ? moment(new Date(parseInt(_q.endDate))) : moment(Date.now()));
    const [page, setPage] = useState(_q.hasOwnProperty('page') ? parseInt(_q.page) : 1);
    const [limit, setLimit] = useState(_q.hasOwnProperty('limit') ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const [vessels, setVessels] = useState<any[]>([]);
    const [activities, setActivities] = useState<Array<Activity>>([AllowedActivity, DeniedActivity]);
    // pinch start date
    const [startDate, setStartDate] = useState<any>(moment());
    // pinch end date
    const [endDate, setEndDate] = useState<any>(moment());

    const [ruleName, setRuleName] = useState<string>(_q.hasOwnProperty("rule") ? _q.rule : "");

    const zoomHistory = useRef<Array<[Moment, Moment]>>([]);
    const dispatch = useDispatch();

    const getInterval = (minutes) => {
        if (minutes <= 1440) {
          return "10m";
        } else if (minutes > 1440 && minutes <= 10080) {
          return "1h";
        } else {
          return "4h";
        }
    }
    const [selectedBin, setSelectedBin] = useState(getInterval(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)));

    useLayoutEffect(() => {
        setStartDate(newSummaryStartDate);
        setEndDate(newSummaryEndDate);
        setActivities(applyActivityFilter((_q.hasOwnProperty("activities") ? strToList(_q.activities) : ['1', '0']), activities))
    }, [newSummaryStartDate, newSummaryEndDate])

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'firewall-report-csv') {
                DownloadCSV(convertToCsvFirewall(authReducer.csvDataDownloded), {
                    formatters: {
                        1: 'ESC-COMMA'
                    }
                });
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const getFirewallPayload = (filter: Partial<FirewallReportFilters>) => {
        let { filterVessels,
            filterdActivities,
            pageLimit = limit,
            pageStart = page,
            startDate = newSummaryStartDate,
            endDate = newSummaryEndDate,
            ruleName: currentRule = ruleName
        } = filter;
        if (!filterVessels) {
            filterVessels = vessels;
        }
        if (!filterdActivities) {
            filterdActivities = activities;
        }
        return {
            k4Id: filterVessels && filterVessels.length > 0 ? `'${filterVessels.map(item => item.id).join("','")}'` : '',
            pageStart: ((pageStart - 1) * pageLimit),
            pageLimit,
            dp: '',
            startDate,
            endDate,
            activity: filterdActivities.filter(a => a.selected).map(a => a.value).join(','),
            ruleName: currentRule && currentRule.length ? `and ruleName IN ('${currentRule}')` : ''
        };
    }

    const fireWallReportApi = (filter: Partial<FirewallReportFilters>) => {
        const payload = getFirewallPayload(filter);
        getFirewallWidgets(payload);
        getFirewallActionTodayGraph(payload);
        getFirewallRuleNameGraph(payload);
        getFirewallReportTableData(payload);
        getFirewallReportRuleFilter(payload);
        getFirewallTableDataCount(payload);
        getFirewallLineChart({ ...payload, interval: filter.interval ? filter.interval : getInterval(Math.floor((payload.endDate.valueOf() - (payload.startDate.valueOf())) / 60e3)) });
    }

    const handleTopUsageOnCsvDownload = (count: number) => {
        if (vessels.length === 0) {
            toast.error("No data available to download", {
              position: toast.POSITION.BOTTOM_LEFT,
            })
            return;
          }
        const payload = getFirewallPayload({});
        const params: CsvParameters = {
          type: 'QUERY_CH',
          id: 'firewall-report-csv',
          queryName: 'FIREWALL_REPORTS_BOTTOM_INTERFACE_TABLE',
          payload: {
            ...payload,
            pageStart: 0,
            pageLimit: count,
          }
        }
        downloadAsCSV(params);
        // getFirewallReportTableDataCsvDownload(getFirewallPayload({ pageLimit: count }));    
      }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const getIntervalCheck = (minutes) => {
        if (minutes > 10080) {
          return true;
        } 
        return false;
    }
    
    useEffect(() => {
        if (_.isEmpty(getVesselsListing))
            return;
        let data: any[] = [];
        if(_.isArray(filteredVessels) && filteredVessels.length > 0) {
            data = filteredVessels;
        }
        else if (!_.isEmpty(getVesselsListing)) {
            data = getVesselsListing?.locations;
        }
        setVessels(data);
    }, [getVesselsListing, filteredVessels]);

    useEffect(() => {
        const checkInterval = getIntervalCheck(Math.floor(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3));
        if (vessels?.length > 0) {
            setPage(_q.hasOwnProperty('page') ? parseInt(_q.page) : 1);
            const Page = _q.hasOwnProperty('page') ? parseInt(_q.page) : 1;
            if(vessels.length == getVesselsListing?.locations?.length && !checkInterval) {
                fireWallReportApi({ filterVessels: vessels, pageStart: Page, interval: selectedBin });
            } else if(vessels?.length == 1) {
                fireWallReportApi({ filterVessels: vessels, pageStart: Page, interval: selectedBin });
            } else if(vessels?.length > 0 && !checkInterval) {
                fireWallReportApi({ filterVessels: vessels, pageStart: Page, interval: selectedBin });
            }
        }
    }, [vessels])

    useEffect(() => {
        const checkInterval = getIntervalCheck(Math.floor(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3));
        const _interval = getInterval(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3));
        setSelectedBin(_interval);
        if (vessels?.length > 0) {
            setPage(1);
            const Page = 1;
            if(vessels.length == getVesselsListing?.locations?.length && !checkInterval) {
                fireWallReportApi({ filterVessels: vessels, pageStart: Page, interval: _interval });
            } else if(vessels?.length == 1) {
                fireWallReportApi({ filterVessels: vessels, pageStart: Page, interval: _interval });
            } else if(vessels?.length > 0 && !checkInterval) {
                fireWallReportApi({ filterVessels: vessels, pageStart: Page, interval: _interval });
            }
        }
    }, [newSummaryStartDate, newSummaryEndDate])

    const handleChangePageUser: PageChangeHandler = (_, value) => {
        let page = value
        let params: any = getDecodeURI(location?.search);
        params.page = value;
        setPage(value);
        doNavigate(params);
        if (vessels?.length > 0) {
            const payload = getFirewallPayload({ pageStart: page })
            getFirewallReportTableData(payload);
            getFirewallTableDataCount(payload);
        }
    }

    const handleChangeRowsPerPageUser: RowPerPageHandler = (e) => {
        const limit = parseInt(e.target.value as string);
        let params: any = getDecodeURI(location?.search);
        params.page = 1;
        params.limit = limit
        setPage(1);
        setLimit(limit);
        doNavigate(params);
        if (vessels?.length > 0) {
            const payload = getFirewallPayload({ pageLimit: limit, pageStart: 1 })
            getFirewallReportTableData(payload);
            getFirewallTableDataCount(payload);
        }
    }

    const handleRuleNameChange = (name: string) => {
        setRuleName(name);
    }

    const pinchAndResetChart = (start, end, interval) => {
        let params = getDecodeURI(location?.search);
        params.startDate = start.valueOf();
        params.endDate = end.valueOf();
        params.interval = interval;
        doNavigate(params);
    }

    const onPinch = (pinchStart: Moment, pinchEnd: Moment) => {
        zoomHistory.current.push([startDate, endDate]);
        setPage(1);
        pinchAndResetChart(pinchStart, pinchEnd, 'customDates');
    }

    const zoomOut = () => {
        if (zoomHistory.current.length == 0)
        return;
        const current = zoomHistory.current.pop();
        if (!current)
        return;
        if(zoomHistory.current.length > 0) {
            const startDate = current[0];
            const endDate = current[1];
            pinchAndResetChart(startDate, endDate, 'customDates');
        } else {
            const startDate = moment(Date.now()).subtract(1, 'hour');
            const endDate = moment(Date.now());
            pinchAndResetChart(startDate, endDate, '1h');
        }
    }

    const resetZoom = () => {
        if (zoomHistory.current.length == 0)
            return;
        zoomHistory.current = [];
        const startDate = moment(Date.now()).subtract(1, 'hour');
        const endDate = moment(Date.now());
        pinchAndResetChart(startDate, endDate, '1h');
    }

    const onApplyFilters = () => {
        let params: any = getDecodeURI(location.search);
        params.rule = ruleName;
        params.startDate = newSummaryStartDate.valueOf();
        params.endDate = newSummaryEndDate.valueOf();
        params.page = 1;
        params.activities = activities.filter(item => item.selected).map(item => item.value);
        doNavigate(params);
        setPage(1);
        if (vessels?.length > 0) {
            fireWallReportApi({ pageStart: 1, interval: selectedBin })
        }
    }

    const onSelectFilters = (filters: Partial<FirewallSelectFilters>) => {
        // Destructors -- 
        const { filterRuleName = ruleName, filterSiteName, filterActivities = activities, trackSite } = filters;
        let params = getDecodeURI(location.search);
        if (filterRuleName && filterRuleName.length > 0) {
            params.rule = filterRuleName;
        } else {
            delete params.rule;
        }
        const currentActivities: string[] = _q.hasOwnProperty("activities") ? strToList(_q.activities) : ['1', '0'];
        if (filterActivities && filterActivities.length > 0 && !filterActivities.every(activity => activity.selected && currentActivities.includes(activity.value.toFixed()))) {
            params.activities = filterActivities.filter(item => item.selected).map(item => item.value);
        } else {
            delete params.activities;
        }
        let _selectedVessels = vessels;
        if (_.isString(filterSiteName)) {
            const currentSelectedVessels = selectedVessels.vessels ?? [];
            // do not call api if same site
            if ((currentSelectedVessels.length == 1 && currentSelectedVessels[0]['vessel-name'] === filterSiteName))
                return;
            delete params.k4Ids;
            if (filterSiteName.length > 0 && _.isArray(vessels)) {
                const vessel = vessels[0]['name'] ? vessels.find(v => v['name'] === filterSiteName) : vessels.find(v => v['vessel-name'] === filterSiteName);
                if (vessel) {
                    params.k4Ids = vessel['name'] ? `${vessel.id}:${vessel['name']}` : `${vessel.id}:${vessel['vessel-name']}`;
                    doNavigate(params);
                    return
                }
            } else {
                _selectedVessels = [];
                updatFilteredVessels({ isAll: true, vessels: [] });
            }
        }
        else if (vessels?.length > 0 && !trackSite) {
            fireWallReportApi({ pageStart: 1, filterdActivities: filterActivities, ruleName: filterRuleName, interval: selectedBin })
        }
        delete params.page;
        setPage(1);
        doNavigate(params);
    }

    const onClearFilters = () => {
        const start = moment(Date.now()).subtract(3, 'days');
        const end = moment(Date.now());
        setRuleName('');
        setStartDate(start);
        setEndDate(end);
        setPage(1);
        const filterdActivities = applyActivityFilter(['1', '0'], activities);
        setRuleName('')
        setActivities(filterdActivities);

        let params: any = getDecodeURI(location.search);
        delete params.startDate;
        delete params.endDate;
        delete params.activities;
        delete params.rule;
        delete params.page;
        doNavigate(params);
        if (vessels?.length > 0) {
            fireWallReportApi({ startDate: start, endDate: end, ruleName: '', pageStart: 1, filterdActivities, interval: selectedBin });
        }
    }

    const handleActivityFilter = (activity: Activity) => {
        const index = activities.indexOf(activity);
        if (index > -1) {
            activities[index] = activity;
            setActivities(activities.slice())
        }
    }

    const handleBinChange = (e) => {
        const value = e.target.value;
        setSelectedBin(value);
        const payload = getFirewallPayload({});
        getFirewallLineChart({...payload, interval: value});
    }

    const value: FirewallReportContextType = {
        selectedStartDate,
        selectedEndDate,
        startDate,
        endDate,
        activities,
        vessels,
        ruleName,
        page,
        limit,
        currentZoom: zoomHistory.current,
        resetZoom,
        zoomOut,
        onClearFilters,
        handleActivityFilter,
        handleRuleNameChange,
        onPinch,
        onApplyFilters,
        onSelectFilters,
        handleChangePageUser,
        handleChangeRowsPerPageUser,
        setStartDate,
        setEndDate,
        handleTopUsageOnCsvDownload,
        handleBinChange,
        selectedBin
    }

    return <FirewallReportContext.Provider value={value}>
        {children}
        {(authReducer.setFirewallWidgetsLoading ||
            authReducer.setFirewallActiveTodayLoading ||
            authReducer.setFirewallRuleNameLoading ||
            authReducer.setFirewallRuleNameFilterLoading ||
            authReducer.setFirewallTableDataCountLoading ||
            authReducer.setFirewallTableDataLoading ||
            authReducer.setFirewallLineChartLoading) && (
            <CustomLoader
                showLoader={true}
                loadingText="Fetching data please wait..."
            />
        )}
    </FirewallReportContext.Provider>
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    filteredVessels: state.authReducer?.filteredVessels,
    selectedVessels: state.authReducer?.selectedVessels,
    selectedOu: state.authReducer?.selectedOu,
    getVesselsListing: state.authReducer?.getVesselsListing,
    newSummaryStartDate: state.authReducer.newSummaryStartDate,
    newSummaryEndDate: state.authReducer.newSummaryEndDate,
    errorReducer: state.errorReducer,
});

export const FirewallReportProvider = withRouter(
    connect(mapStateToProps, {
        getFirewallWidgets,
        getFirewallRuleNameGraph,
        getFirewallActionTodayGraph,
        getFirewallReportTableData,
        getFirewallReportRuleFilter,
        getFirewallTableDataCount,
        getFirewallLineChart,
        updatFilteredVessels,
        downloadAsCSV
    })(FirewallContextProvider)
);