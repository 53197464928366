import { Fragment, useEffect, useState } from "react";

import { FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, TextField } from "@mui/material";

interface PeriodWithUnitInputProps {
    value: number;
    unit: string;
    disabled?: boolean;
    onChange: (event: any) => void;
}

export interface QuotaPeriodChangeEvent<T = Element> extends React.ChangeEvent<T> {
    target: {
        value: {
            period: number;
            unit: string;
        }
    } & EventTarget & T;
}

export function PeriodWithUnitInput(props: PeriodWithUnitInputProps) {
    const { disabled, onChange } = props;

    const fixedOptions = ["Monthly", "Weekly", "Daily"];
    const [period, setPeriodValue] = useState<number>(props.value);
    const [unit, setUnitValue] = useState<string>(props.unit);
    const [isFixed, setIsFixed] = useState<boolean>(`${period}${unit}` === "1M" || `${period}${unit}` === "1W" || `${period}${unit}` === "1D");

    const handlePeriodValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPeriodValue(Number(event.target.value));
    }

    const handleUnitValueChange = (event: SelectChangeEvent) => {
        setUnitValue(event.target.value);
    }

    const handleFixedOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsFixed(event.target.value !== "custom");
        if (event.target.value === "Monthly") {
            setPeriodValue(1);
            setUnitValue("M");
        } else if (event.target.value === "Weekly") {
            setPeriodValue(1);
            setUnitValue("W");
        } else if (event.target.value === "Daily") {
            setPeriodValue(1);
            setUnitValue("D");
        }
    }

    const setDefaultValue = () => {
        if (`${period}${unit}` === "1M") {
            return "Monthly";
        } else if (`${period}${unit}` === "1W") {
            return "Weekly";
        } else if (`${period}${unit}` === "1D") {
            return "Daily";
        } else {
            return "custom";
        }
    }

    useEffect(() => {
        onChange({
            target: {
                value: {
                    period: Number(period),
                    unit: unit
                }
            }
        } as any);
    }, [period, unit]);

    return (
        <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={12}>
                <RadioGroup row aria-label="position" name="position" onChange={handleFixedOptionChange} defaultValue={setDefaultValue}>
                    {
                        fixedOptions.map(option => <FormControlLabel value={option} control={<Radio />} label={option} disabled={disabled} />)
                    }
                    <FormControlLabel value="custom" control={<Radio />} label="Custom" disabled={disabled} />
                </RadioGroup>
            </Grid>
            {
                !isFixed ?
                    <Fragment>
                        <Grid item xs={8}>
                            <TextField fullWidth label="Quota Period" size='small' value={period} onChange={handlePeriodValueChange} disabled={disabled} />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth size='small' disabled={disabled}>
                                <InputLabel id="period-unit-label">Unit</InputLabel>
                                <Select
                                    fullWidth
                                    size='small'
                                    value={unit}
                                    label="Unit"
                                    labelId="period-unit-label"
                                    onChange={handleUnitValueChange}
                                    inputProps={{
                                        name: 'unit',
                                        type: 'number',
                                    }}
                                >
                                    <MenuItem value={"D"}>Days</MenuItem>
                                    <MenuItem value={"W"}>Weeks</MenuItem>
                                    <MenuItem value={"M"}>Months</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Fragment> : null
            }
        </Grid>
    )
}