import { useEffect, useState } from "react";

import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";

import { calibrateData, readableBytesToBytes } from "../../../utils/util";

interface ByteWithUnitInputProps {
    value: number;
    disabled?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function DataWithUnitInput(props: ByteWithUnitInputProps) {
    const { disabled, onChange } = props;

    const [value, setValue] = useState<number>(props.value / Math.pow(1000, 3));
    const [unit, setUnit] = useState<string>('GB');

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let _value = parseFloat(event.target.value);
        if (isNaN(_value)) {
            _value = 0;
        } else {
            _value = Math.max(0, _value);
        }
        setValue(_value);
    }

    const handleUnitChange = (event: SelectChangeEvent) => {
        setUnit(event.target.value);
    }

    useEffect(() => {
        onChange({
            target: {
                value: readableBytesToBytes(`${Number(value)}` + unit)
            }
        } as any);
    }, [value, unit]);

    return (
        <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={8}>
                <TextField
                    fullWidth
                    size='small'
                    value={value}
                    label="Quota Limit"
                    onChange={handleValueChange}
                    type="number"
                    disabled={disabled}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9.]*', min: 0, step: 'any' }}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth size='small' disabled={disabled}>
                    <InputLabel id="data-unit-label">Unit</InputLabel>
                    <Select
                        value={unit}
                        label="Unit"
                        labelId="data-unit-label"
                        id="unit"
                        onChange={handleUnitChange}
                    >
                        <MenuItem value={"MB"}>MB</MenuItem>
                        <MenuItem value={"GB"}>GB</MenuItem>
                        <MenuItem value={"TB"}>TB</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}