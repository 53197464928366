import _ from "lodash";
import { api } from "../../server/request";
import { handleError, handleResponse } from "../../actions/responseUtil";
import { Action, StarlinkQuota, User } from "./types";
import { g } from "jointjs";

export const ACTION_TYPES = {
    SET_LOADING: "starlinkQuota/setLoading",
    GET_SERVICE_LINE_QUOTAS: "starlinkQuota/getServiceLineQuotas",
    CREATE_QUOTA: "starlinkQuota/createQuota",
    UPDATE_QUOTA: "starlinkQuota/updateQuota",
    DELETE_QUOTA: "starlinkQuota/deleteQuota",
}

const initialState = {
    quotas: [],
    totalQuotas: 0,
    gettingServiceLineQuotas: false,
    creating: false,
    deleting: false,
    updating: false,
}

export function starlinkQuotaReducer(state: any = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.GET_SERVICE_LINE_QUOTAS: {
            let [quotas, totalCount] = action.payload?.data?.rows?.[0] || [[], 0]
            return {
                ...state,
                quotas,
                totalQuotas: totalCount
            }
        }
        case ACTION_TYPES.CREATE_QUOTA || ACTION_TYPES.UPDATE_QUOTA: {
            return {
                ...state,
            }
        }
        case ACTION_TYPES.DELETE_QUOTA: {
            return {
                ...state,
                quotas: state.quotas.filter(quota => quota.id !== action.payload.id)
            }
        }
        case ACTION_TYPES.SET_LOADING: {
            return {
                ...state,
                ...action.payload
            }
        }
        default:
            return state
    }
}

export interface paramGetServiceLineQuotas {
    dpIds: string[];
    searchText: string;
    sortBy: string;
    sortOrder: string;
    page: number;
    limit: number;
    serviceLines: string[];
}

export const getServiceLineQuotas = (parameters: paramGetServiceLineQuotas) => (dispatch) => {
    // if (_.isEmpty(parameters?.dpIds)) return;
    if (_.isEmpty(parameters?.serviceLines)) return;
    if (parameters?.serviceLines.includes('All') ) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLineQuotas: true } });
    let _parameters: any = {
        ...parameters,
        // dpIds: parameters.dpIds.map(dpId => `'${dpId}'`).join(','),
        serviceLines: parameters.serviceLines.map(sl => `'${sl}'`).join(','),
        limit: parameters.limit,
        offset: (parameters.page - 1) * parameters.limit
    };
    const data = {
        query: `GET_STARLINK_QUTOAS`,
        named: true,
        format: 'csv',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_SERVICE_LINE_QUOTAS);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLineQuotas: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLineQuotas: false } });
        });
}

interface createQuotaParameters {
    quota: StarlinkQuota;
}

export const createQuota = (parameters: createQuotaParameters) => async (dispatch) => {
    const { quota } = parameters;

    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creating: true } });

    let URL = `/alerts/quotas`;
    try {
        let res = await api.starlink.post(URL, quota);
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creating: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creating: false } });
        return null;
    }
};

export interface updateQuotaParameters {
    dp_id: string;
    id: string;
    quota: {
        limit?: number;
        period?: number;
        period_unit?: string;
        start_time: string;
        actions?: Action[];
        notification_methods?: string[],
        users_to_notify?: User[];
        // disabled?: boolean;
    };
}

export const updateQuota = (parameters: updateQuotaParameters) => async (dispatch) => {
    const { quota } = parameters;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updating: true } });

    let URL = `/alerts/quotas`;
    try {
        let res = await api.starlink.put(URL, quota);
        dispatch({ type: ACTION_TYPES.UPDATE_QUOTA, payload: quota });
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updating: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updating: false } });
        return null;
    }
}

interface deleteQuotaParameters {
    quota: StarlinkQuota;
}

export const deleteQuota = (parameters: deleteQuotaParameters) => async (dispatch) => {
    const { quota } = parameters;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { deleting: true } });

    let URL = `/alerts/quotas`;
    try {
        let res: any = await api.starlink.delete(URL, quota);
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { deleting: false } });
        if (res?.success) {
            dispatch({ type: ACTION_TYPES.DELETE_QUOTA, payload: quota })
        }
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { deleting: false } });
        return null;
    }
};

export const clearComponentData = () => (dispatch) => {
    dispatch({ type: ACTION_TYPES.GET_SERVICE_LINE_QUOTAS, payload: { data: { rows: [[[], 0]] } } })
   
}

