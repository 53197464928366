import React, {  useEffect, useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import Moment from 'moment';
import {
    Grid,
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Typography,
    Tooltip,
    Select,
    MenuItem,
    Autocomplete,
    TextField,
    Paper,
    Stack,
    Pagination
} from "@mui/material";
import { fnRound, fnComma, getDecodeURI, getEncodedURI } from "../../utils/util"
import "./dailyDataUsage.css"
import { getDailyDataUsage } from "../../actions/Users/authenticateReports";
import authReducer from "../../reducers/authReducer";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { BC_DAILY_USAGE } from "../../constants/Constants";
import { updatePermission } from "../../actions/Users/authenticate";



const Month = [
    { name: 'Jan', val: '01' },
    { name: 'Feb', val: '02' },
    { name: 'Mar', val: '03' },
    { name: 'Apr', val: '04' },
    { name: 'May', val: '05' },
    { name: 'Jun', val: '06' },
    { name: 'Jul', val: '07' },
    { name: 'Aug', val: '08' },
    { name: 'Sep', val: '09' },
    { name: 'Oct', val: '10' },
    { name: 'Nov', val: '11' },
    { name: 'Dec', val: '12' },
]
const Year = [Moment().year() - 1, Moment().year(), Moment().year() + 1]


const DataUsage = (props) => {

    const { match, getDailyDataUsage, authReducer, history, location, updatePermission } = props;
    let _q = getDecodeURI(location?.search);
    const [vessel, setVessel] = useState<any>(match?.params?.vesselName ? match.params.vesselName : "-");
    const [selectedMonth, setSelectedMonth] = useState(_q.hasOwnProperty('month') ? (_q.month) : Moment().format('MMM'));
    const [selectedYear, setSelectedYear]: any = useState(_q.hasOwnProperty('year') ? (_q.year) : Moment().year());
    const [breadcrumbs, __] = useState(BC_DAILY_USAGE);
    const value = _q.month ;
    const [ValMonth, setValMonth]: any = useState(_q.hasOwnProperty('month') ? (parseInt(Month.filter((month) => month.name === value)[0].val)) : Moment().month() + 1)
    const setBreadcrumbInfo: any = React.useRef();


    const [DailyLabel, setDailyLabel]: any = useState([])
    const [loaders, setLoaders] = useState({ showLoader: false, loadingText: "Fetching data please wait..." });
    const [selectedOu, setSelectedOu ] = useState<any>(_q.hasOwnProperty("ouName") && _q.ouName!=="K4Mobility" ? `and fullname IN ('${_q.ouName}')` : "");
    const params = useParams();
    const [dailyDataUsage, setDailyDataUsage] = useState<any>([]);

    const payload = {
        year: selectedYear,
        month: ValMonth,
        dp: selectedOu,
        k4Id: params.k4Id
    }

    useEffect(() => {
        processQueryUrl();
    }, [location]);

    const processQueryUrl = () => {
        let _q = getDecodeURI(location?.search);
        
        DailyTable();

        let payload = {
            month: selectedMonth,
            srcType: "K4 Server",
            year: selectedYear,
            vs_nm: vessel,
        };


        if (_q.month && "" != _q.month) {
            setSelectedMonth(_q.month)
            payload.month = _q.month;
        }

        if (_q.year && "" != _q.year) {
            setSelectedYear(_q.year)
            payload.year = _q.year;
        }
    }

    const DailyTable = async () => {
        let DailyLabel: any[] = [],
            endloop = Moment().daysInMonth();

        for (let s = 1; s <= endloop; s++) {
            let date = ` ` + s + `-${selectedMonth}`;
            DailyLabel.push(date);
        }
        setDailyLabel(DailyLabel)
    };

    useEffect (()=> {
        getDailyDataUsage(payload);
        DailyTable();
    },[])

    useEffect(() => {
        if(!_.isEmpty(authReducer.getDailyDataUsage)) {
            const data = authReducer.getDailyDataUsage.hasOwnProperty('data') ?  authReducer.getDailyDataUsage?.data : {};
            let dailyDataUsage: any = [];
            let col: any = [];
            let rows: any = [];

            for(var key in data) {
                if(!col.length)
                    col = data[key]?.columns;
                if(data[key])
                    rows = [...rows,...data[key]?.rows]    
            }

            let rowObj : any = {};
            rows?.forEach((ele, index) => {
                let row = { snum : "" };
                col?.forEach((item, i) => {
                    row[item] = ele[i];
                })
                if(rowObj[row?.snum]) {
                    let tempArr :any = rowObj[row?.snum];
                    tempArr.push(row);
                    rowObj[row?.snum] = tempArr;
                }else{
                    let key = row?.snum;
                    rowObj[key] = [row];
                }
            })

            for(var key in rowObj){
                let dataRow = rowObj[key];
                dailyDataUsage.push({
                            data: dataRow,
                            vessel_name: dataRow[0].vessel_name,
                            snum: dataRow[0].snum,
                            provider: dataRow[0].provider,
                            k4Owned: dataRow[0].k4Owned,
                            i_status: dataRow[0].i_status,
                            fullName: dataRow[0].fullName,
                            system_id: dataRow[0].system_id
                        })
            }
            setDailyDataUsage(dailyDataUsage)
        }
    }, [authReducer.getDailyDataUsage])

    useEffect(() => {
        const info = {
            isMultiVessel: false,
            isSingleVessel: false,
            showOu: false,
        };
        updatePermission(info);
    }, [])

    const handleMonthChange = (e) => {
        const value = e.target.value;
        setValMonth(parseInt(Month.filter((month) => month.name === value)[0].val) + 1)
        setSelectedMonth(value)
        let params = getDecodeURI(location?.search);
        params.month = value;
        doNavigate(params);
        getDailyDataUsage({...payload, month:(parseInt(Month.filter((month) => month.name === value)[0].val))})
    }

    const handleYearChange = (e) => {
        const value = e.target.value;
        setSelectedYear(e.target.value)
        let params = getDecodeURI(location?.search);
        params.year = value;
        doNavigate(params);
        getDailyDataUsage({...payload, year: parseInt(value)})
    }

    const doNavigate = (params) => {
        history.replace({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const handleCalculateUsage = (row) => {
       let usage=0;
        for(let i=0; i<row.data.length; i++){
            usage = usage + row.data[i].sim_data_usage;
        }
        if(usage){
            return (usage/1073741824).toFixed(1);
        }
        return "0";
    }

    return (
        <div className="Form">
            <BreadCrumbs breadcrumbinfo={breadcrumbs} setBreadcrumbInfo={setBreadcrumbInfo} loading={authReducer.loading}></BreadCrumbs>
            <div className="innerBox">
                <div className="heading">
                    Daily Data Usage : <span className="vessName">{vessel}</span>

                </div>

                <Grid container className="justyfy-space-between sel_padding">
                    <Grid item className="dailyReportsDataHeader">
                        <div className="d-flex align-center">
                            <div className="usage_select">Month</div>
                            <div className="padLeft">
                                <Select
                                    className="selBackground"
                                    autoWidth
                                    margin='dense'
                                    variant='outlined'
                                    id='simple-select-month'
                                    value={selectedMonth}
                                    onChange={handleMonthChange}
                                    SelectDisplayProps={{
                                        style: { padding: '8px 32px 8px 8px' },
                                    }}
                                >
                                    {Month.map((entry, id) => {
                                        return (
                                            <MenuItem key={id} value={entry.name}>
                                                {entry.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div className="d-flex align-center">
                            <div className="usage_select">Year</div>
                            <div className="padLeft">
                                <Select
                                    className="selBackground"
                                    autoWidth
                                    margin='dense'
                                    variant='outlined'
                                    id='simple-select-month'
                                    value={selectedYear}
                                    onChange={handleYearChange}
                                    SelectDisplayProps={{ style: { padding: '8px 32px 8px 8px' } }}
                                >
                                    {Year.map((entry, id) => {
                                        return (
                                            <MenuItem key={id} value={entry}>
                                                {entry}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </div>
                        </div>
                    </Grid>

                </Grid>
                <div className='iccidTable' >
                    ICCID Table
                </div>
                <div className="tableDiv">
                    <TableContainer className="dailyReportsTableContainer dailyDataUsageScroll">
                        <Table >
                            <TableHead className="dataUsageHeader tableHead">
                                <TableRow>
                                    {[
                                        // 'SIM Box',
                                        // 'Vessel Name',
                                        'SP',
                                        'System ID',
                                        'ICCID',
                                        'SIM Owner',
                                        'Status',
                                        'Usage (GB)',
                                    ].map((columnHeader) => (
                                        <TableCell key={columnHeader} align="center" className="dailyReportsTableCell" >
                                            {columnHeader}
                                        </TableCell>
                                    ))}

                                    {DailyLabel &&
                                        DailyLabel.map((obj, i) => {
                                            return (
                                                <TableCell key={i} className="dailyReportsTableCell" style={{ whiteSpace: 'nowrap' }} >
                                                    <div style={{ transform: 'rotate(270deg)', }} >
                                                        {obj}{' '}
                                                    </div>
                                                </TableCell>
                                            );
                                        })}
                                </TableRow>
                            </TableHead>
                            <TableBody className="tableBody">
                                {dailyDataUsage &&
                                    dailyDataUsage.map((obj, i) => {
                                        return (
                                            <TableRow key={i}>
                                                {/* <TableCell className=" dailyFleetRowRank">
                                                    {obj.simbox}
                                                </TableCell> */}
                                                {/* <TableCell align="center" className=" dailyFleetRowRank" style={{minWidth:"100px"}}>
                                                    {obj.vs_nm}
                                                </TableCell> */}
                                                { obj.provider ? 
                                                <TableCell align="center" className=" dailyFleetRowRank">
                                                    {obj.provider}
                                                </TableCell> : 
                                                <TableCell align="center" className=" dailyFleetRowRank">-</TableCell>
                                                }
                                                {
                                                  obj.system_id ? 
                                                    <TableCell align="center" className=" dailyFleetRowRank">
                                                        {obj.system_id}
                                                    </TableCell> : 
                                                    <TableCell align="center" className=" dailyFleetRowRank">-</TableCell>
                                                }
                                                {obj.snum ? 
                                                    <TableCell
                                                        className=" dailyFleetRowRank">
                                                        {/* style={{  color: obj.status === 'InActive' ? 'red' : obj.status === 'WIP'  ? 'blue'  : obj.status === 'Active' ? 'green'  : 'black'}}> */}
                                                        {obj.snum}
                                                    </TableCell> :
                                                    <TableCell align="center" className=" dailyFleetRowRank">-</TableCell> 
                                                    }
                                                {obj.k4Owned ? 
                                                    <TableCell
                                                        className=" dailyFleetRowRank">
                                                        {obj.k4Owned === 'Yes' ? 'K4' : '3P'}
                                                    </TableCell> :
                                                    <TableCell align="center" className=" dailyFleetRowRank">-</TableCell>
                                                }
                                                {obj.i_status ? 
                                                    <TableCell align="center" className=" dailyFleetRowRank">
                                                        {obj.i_status}
                                                    </TableCell> :
                                                    <TableCell align="center" className=" dailyFleetRowRank">-</TableCell>
                                                }
                                                <TableCell align="center" className=" dailyFleetRowRank">
                                                    {handleCalculateUsage(obj)}
                                                </TableCell>
                                                {
                                                    DailyLabel && DailyLabel?.map((el, i) => {
                                                      for(let i=0 ; i<obj?.data?.length ; i++) {
                                                        let elem = el.trim();
                                                            if(elem == obj?.data[i]?.date_column) {
                                                               return <TableCell align="center" className=" dailyFleetRowRank">{((obj.data[i].sim_data_usage)/(1024*1024*1024)).toFixed(1)}</TableCell>
                                                            }                                           
                                                        }
                                                      return <TableCell align="center" className=" dailyFleetRowRank">-</TableCell>
                                                    })
                                                }                                                   
                                            </TableRow>
                                        );
                                    })}
                                {0 === dailyDataUsage?.length ? (
                                    <TableRow>
                                        <TableCell colSpan={26} align="center" className="noDataAvailableCss">{authReducer.loading ? loaders["loadingText"] : "No data available"}</TableCell>
                                    </TableRow>
                                ) : null}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>


            </div>

        </div >
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer
});

export default withRouter(
    connect(mapStateToProps, {
        getDailyDataUsage,
        updatePermission
    })(DataUsage)
);