import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConfirmDialog from "../../../components/ConfirmDialog";
import { CONFIRM_DIALOG_TITLE } from "../../../config";

const MenuItemComp = (props:any)=>{
    const { deleteDefaultConfig, row, setEditPopup, setConfigId } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [confirmDialog, setConfirmDialog] = useState({
      isOpen: false,
      title: "",
      subTitle: "",
      onConfirm: {},
    });
  
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const ITEM_HEIGHT = 30;
    
    return (
        <div id="menu">
            <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            sx={{padding:'0px'}}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                sx: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '15ch',
                },
                }}
            >
                <MenuItem onClick={() => {
                    setEditPopup(true)
                    setAnchorEl(null);
                    setConfigId({name: row?.name, id: row?.id, active: row?.active, dp_id: row?.dp_id});
                }}>Edit</MenuItem>
                <MenuItem onClick={() => {
                    setConfirmDialog({
                        isOpen: true,
                        title: CONFIRM_DIALOG_TITLE,
                        subTitle: "Are you sure to delete the config ? ",
                        onConfirm: () => {
                            deleteDefaultConfig(row?.dp_id, row?.id)
                        },
                    })
                    setAnchorEl(null)
                }}>Delete</MenuItem>
            </Menu>
            <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </div>
    )
  }

export default MenuItemComp;