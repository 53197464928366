// import { api } from "../../server/request";
// import { handleResponse, handleError } from "../responseUtil";
import { SET_LOADING, SEARCH_ALERTS, MARK_AS_FIXED_ALERT, ALERTS_NAME, ALERTS_HISTORY, SET_SEARCH_ALERTS_LOADING, SET_VESSEL_ALERTS_LOADING, GET_DP_ALERTS, UPDATE_DP_ALERT, UPDATE_DP_ALERT_NOTIFICATION, DELETE_DP_ALERT_LINK_AVAILABILITY, GET_DP_ALERT_AUDIT_LOGS, GET_DP_ALERTS_LOADING, UPDATE_DP_ALERT_LOADING, UPDATE_DP_ALERT_NOTIFICATION_LOADING, DELETE_DP_ALERT_LINK_AVAILABILITY_LOADING, GET_DP_ALERT_AUDIT_LOGS_LOADING,
  GET_SITE_ALERTS,
  GET_SITE_ALERTS_LOADING,
  UPDATE_SITE_ALERT,
  UPDATE_SITE_ALERT_LOADING,
  UPDATE_SITE_ALERT_NOTIFICATION,
  UPDATE_SITE_ALERT_NOTIFICATION_LOADING,
  DELETE_SITE_ALERT_LINK_AVAILABILITY,
  DELETE_SITE_ALERT_LINK_AVAILABILITY_LOADING,
  GET_SITE_ALERT_AUDIT_LOGS,
  GET_SITE_ALERT_AUDIT_LOGS_LOADING,
  UPDATE_DP_ALERT_STATUS_LOADING,
  UPDATE_DP_ALERT_STATUS,
  UPDATE_SITE_ALERT_STATUS_LOADING,
  UPDATE_SITE_ALERT_STATUS,
 } from "../types";
import { gql } from "@apollo/client";
import { handleError, handleGRaphQlError, handleGraphQlResponse, handleResponse, handleResponseObj } from "../responseUtil";
import {ERROR_SEARCH_ALERTS, ERROR_CLOSE_VESSEL_ALERT, ERROR_GET_ALERT_NAME, ERROR_ALERTS_HISTORY, GET_ERRORS} from "../error_types";
import { api } from "../../server/request";

const GET_VESSEL_ALERTS = gql`
query GetVesselAlerts($limit: Int, $page: Int, $sort: String, $order: Int, $search: String, $vessels: [String], $alerts: [String], $fromdate: String, $todate: String, $categories: [String], $components: [String], $severity: [String], $statuses: [String], $ouName: String) {
  getVesselAlerts(limit: $limit, page: $page, sort: $sort, order: $order, search: $search, vessels: $vessels, alerts: $alerts, fromdate: $fromdate, todate: $todate, categories: $categories, components: $components, severity: $severity, statuses: $statuses, ouName: $ouName) {
    alerts {
      id
      vessel
      alert
      description
      category
      component
      severity
      opendate
      closedate
      status
      source
      state
      lastupdatedon
      comments {
        id
        comment
        updatedon
        updatedby
      }
      ticket {
        ticketid
        status
      }
      location
      product
      device
    },
    page,
    limit,
    total,
    pages
  }
}
`;

const CLOSE_VESSEL_ALERT = gql`
query CloseVesselAlert($closeVesselAlertId: String, $comment: String, $locationId: String) {
  closeVesselAlert(id: $closeVesselAlertId, comment: $comment, locationId: $locationId) {
    alert {
      id
      vessel
      alert
      description
      category
      component
      severity
      opendate
      closedate
      status
      source
      state
      lastupdatedon
      comments {
        id
        comment
        updatedon
        updatedby
      }
      ticket {
        ticketid
        status
      }
    }
  }
}
`;

const GET_ALERT_HISTORY = gql`
query GetAlertHistory($getAlertHistoryId: String, $limit: Int, $page: Int, $locationId: String) {
  getAlertHistory(id: $getAlertHistoryId, limit: $limit, page: $page, locationId: $locationId) {
    alerts {
      id
      vessel
      alert
      description
      category
      component
      severity
      opendate
      closedate
      status
      source
      state
      lastupdatedon
      comments {
        id
        comment
        updatedon
        updatedby
      }
      ticket {
        ticketid
        status
      }
    }
    page
    limit
    pages
    total
    id
  }
}`;

const GET_ALERT_NAMES = gql`
  query GetAlertNames {
    getAlertNames {
      names
      alertstatus
      severity
    }
  }
`;

export function GetVesselAlerts(selectedVessels: any,
  page: number,
  limit: number,
  fromdate,
  todate,
  alerts,
  search: any,
  severity,
  status: any,
  sort,
  order,
  ouName: string) {
  return (dispatch: any, getState: any, client: any) => {
    dispatch({ type:  SET_SEARCH_ALERTS_LOADING, payload: true });
    let _vessels: any[] = [];
    if (selectedVessels && Array.isArray(selectedVessels)) {
      for (let i = 0; i < selectedVessels.length; i++) {
        if (selectedVessels[i]['id']) {
          _vessels.push(selectedVessels[i]['id']);
        }
      }
    }
    let body = {
      vessels: _vessels, // must be array
      page: page,
      limit: limit,
      todate: todate,
      fromdate: fromdate,
      alerts: alerts, // must be array
      search: search,
      severity: severity, // must be array
      statuses: status, // must be array
      sort: sort,
      order: order, // -1 does not work, 0 - asc, 1 - desc
    };
    if(ouName) {
      body['ouName'] = ouName;
    }

    client
      .query({
        query: GET_VESSEL_ALERTS,
        fetchPolicy: "no-cache",
        variables: body
      })
      .then((res) => {
        handleGraphQlResponse(res, dispatch, SEARCH_ALERTS, ERROR_SEARCH_ALERTS);
      })
      .catch((err) => {
        handleGRaphQlError(err, dispatch, ERROR_SEARCH_ALERTS);
      });
  };
}

export function GetAlertHistory(id, locationId) {

  return (dispatch: any, getState: any, client: any) => {
    dispatch({ type: SET_LOADING, payload: true });
    client
      .query({
        query: GET_ALERT_HISTORY,
        fetchPolicy: "no-cache",
        variables: {
          "getAlertHistoryId": id,
          "limit": 500,
          "page": 1,
          "locationId": locationId
        }
      })
      .then((res) => {
        handleGraphQlResponse(res, dispatch, ALERTS_HISTORY, ERROR_ALERTS_HISTORY);
      })
      .catch((err) => {
        handleGRaphQlError(err, dispatch, ERROR_ALERTS_HISTORY);
      });
  };
};

export function GetAlertNames() {

  return (dispatch: any, getState: any, client: any) => {
    client
      .query({
        query: GET_ALERT_NAMES,
        fetchPolicy: "no-cache"
      })
      .then((res) => {
        handleGraphQlResponse(res, dispatch, ALERTS_NAME, ERROR_GET_ALERT_NAME);
      })
      .catch((err) => {
        handleGRaphQlError(err, dispatch, ERROR_GET_ALERT_NAME);
      });
  };
}

interface QueryData {
  query: string;
  named: boolean;
  format?: 'csv' | null;
  parameters: QueryParameters
}
export interface QueryParameters {
  locationId: string;
  state: string;
  severity: string;
  alert: string;
  sortBy: string;
  order: 'asc' | 'desc';
  pageStart: string | number;
  limit: string | number;
  startTime: string;
  endTime: string;
  search: string;
  active: string;
}

const handleVesselAlertsResponse = (res: any, dispatch: any, handler: string, errorHandler: string = "", successHandler: string = SET_LOADING) => {
  if (res) {
    dispatch({ type: handler, payload: res.data });
  } else {
    if (errorHandler && "" != errorHandler) {
      dispatch({ type: errorHandler, payload: res })
    }
    else { dispatch({ type: GET_ERRORS, payload: res }) }
  }
  dispatch({ type: successHandler, payload: false });
}


export const getVesselAlerts = (parameters: QueryParameters) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  dispatch({ type: SET_SEARCH_ALERTS_LOADING, payload: true });
  const data: QueryData = {
    query: 'ALERTS_DASHBOARD',
    named: true,
    format: 'csv',
    parameters: parameters
  }
  return api
    .postRequestOut(`/store/ss/query`, data)
    .then((res) => {
      const data: QueryData = {
        query: 'ALERTS_DASHBOARD_COUNT',
        named: true,
        format: 'csv',
        parameters: parameters,
      }
      return api
        .postRequestOut(`/store/ss/query`, data)
        .then(countRes => {
          if (countRes.data && countRes.data.data && countRes.data.data.rows) {
            const rows = countRes.data.data.rows;
            const count = rows.length > 0 && Array.isArray(rows[0]) && rows[0].length > 0 ? rows[0][0] : 0;
            handleVesselAlertsResponse({ data: { data: { count, ...res.data.data } } }, dispatch, SEARCH_ALERTS);
            return;
          }
          handleVesselAlertsResponse(res, dispatch, SEARCH_ALERTS);
        })
    })
    .catch((err) => handleError(err, dispatch));
};

export function CloseVesselAlert(locationId, alertType, payload) {
  return (dispatch: any) => {
    dispatch({ type: SET_LOADING, payload: true });
    api.postRequest(`/sqlapi/sites/${locationId}/alerts/${alertType}/alerts/suppressed`, payload)
      .then((res) => {
        handleResponse(res, dispatch, MARK_AS_FIXED_ALERT, ERROR_CLOSE_VESSEL_ALERT);
      })
      .catch((err) => {
        handleError(err, dispatch, ERROR_CLOSE_VESSEL_ALERT);
      });
  };
};

export const createAlertConfiguration = (siteId, alert) => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let URL = `/v1/sites/${siteId}/alerts`;
  try {
      let res = await api.siteController.post(URL, {Alert: alert});
      dispatch({ type: SET_LOADING, payload: false })
      return res?.result;
  } catch (error) {
      dispatch({ type: SET_LOADING, payload: false })
      return null;
  }
};

export const getDpAlerts = ( dpId ) => async (dispatch) => {
  dispatch({ type: GET_DP_ALERTS_LOADING, payload: true });
  let URL = `/v1/dps/${dpId}/alerts_config`
  try {
    let res = await api.siteController.get(URL);
    dispatch({ type: GET_DP_ALERTS_LOADING, payload: false })
    handleResponseObj(res, dispatch, GET_DP_ALERTS)
  } catch (error) {
    dispatch({ type: GET_DP_ALERTS_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const updateDpAlert = ( dpId, payload ) => async (dispatch) => {
  dispatch({ type: UPDATE_DP_ALERT_LOADING, payload: true });
  let URL = `/v1/dps/${dpId}/alerts_config`
  try {
    let res = await api.siteController.put(URL, payload);
    dispatch({ type: UPDATE_DP_ALERT_LOADING, payload: false })
    handleResponseObj(res, dispatch, UPDATE_DP_ALERT)
  } catch (error) {
    dispatch({ type: UPDATE_DP_ALERT_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const updateDpAlertNotification = ( dpId, payload ) => async (dispatch) => {
  dispatch({ type: UPDATE_DP_ALERT_NOTIFICATION_LOADING, payload: true });
  let URL = `/v1/dps/${dpId}/alerts_config/notifications`
  try {
    let res = await api.siteController.put(URL, payload);
    dispatch({ type: UPDATE_DP_ALERT_NOTIFICATION_LOADING, payload: false })
    handleResponseObj(res, dispatch, UPDATE_DP_ALERT_NOTIFICATION)
  } catch (error) {
    dispatch({ type: UPDATE_DP_ALERT_NOTIFICATION_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const updateDpAlertStatus = ( dpId, payload ) => async (dispatch) => {
  dispatch({ type: UPDATE_DP_ALERT_STATUS_LOADING, payload: true });
  let URL = `/v1/dps/${dpId}/alerts_config/alert_status`
  try {
    let res = await api.siteController.put(URL, payload);
    dispatch({ type: UPDATE_DP_ALERT_STATUS_LOADING, payload: false })
    handleResponseObj(res, dispatch, UPDATE_DP_ALERT_STATUS)
  } catch (error) {
    dispatch({ type: UPDATE_DP_ALERT_STATUS_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const deleteDpAlertLinkAvailability = ( dpId, payload ) => async (dispatch) => {
  dispatch({ type: DELETE_DP_ALERT_LINK_AVAILABILITY_LOADING, payload: true });
  let URL = `/v1/dps/${dpId}/alerts_config/link_availability`
  try {
    let res = await api.siteController.put(URL, payload);
    dispatch({ type: DELETE_DP_ALERT_LINK_AVAILABILITY_LOADING, payload: false })
    handleResponseObj(res, dispatch, DELETE_DP_ALERT_LINK_AVAILABILITY)
  } catch (error) {
    dispatch({ type: DELETE_DP_ALERT_LINK_AVAILABILITY_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const getDpAlertAuditLogs = ( dpId, payload ) => async (dispatch) => {
  dispatch({ type: GET_DP_ALERT_AUDIT_LOGS_LOADING, payload: true });
  let URL = `/v1/dps/${dpId}/alerts_config/audit_logs`
  try {
    let res = await api.siteController.post(URL, payload);
    dispatch({ type: GET_DP_ALERT_AUDIT_LOGS_LOADING, payload: false })
    handleResponseObj(res, dispatch, GET_DP_ALERT_AUDIT_LOGS)
  } catch (error) {
    dispatch({ type: GET_DP_ALERT_AUDIT_LOGS_LOADING, payload: false })
    handleError(error, dispatch)
  }
};


export const getSiteAlerts = ( siteId ) => async (dispatch) => {
  dispatch({ type: GET_SITE_ALERTS_LOADING, payload: true });
  let URL = `/v1/sites/${siteId}/alerts_config`
  try {
    let res = await api.siteController.get(URL);
    dispatch({ type: GET_SITE_ALERTS_LOADING, payload: false })
    handleResponseObj(res, dispatch, GET_SITE_ALERTS)
  } catch (error) {
    dispatch({ type: GET_SITE_ALERTS_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const updateSiteAlert = ( siteId, payload ) => async (dispatch) => {
  dispatch({ type: UPDATE_SITE_ALERT_LOADING, payload: true });
  let URL = `/v1/sites/${siteId}/alerts_config`
  try {
    let res = await api.siteController.put(URL, payload);
    dispatch({ type: UPDATE_SITE_ALERT_LOADING, payload: false })
    handleResponseObj(res, dispatch, UPDATE_SITE_ALERT)
  } catch (error) {
    dispatch({ type: UPDATE_SITE_ALERT_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const updateSiteAlertNotification = ( siteId, payload ) => async (dispatch) => {
  dispatch({ type: UPDATE_SITE_ALERT_NOTIFICATION_LOADING, payload: true });
  let URL = `/v1/sites/${siteId}/alerts_config/notifications`
  try {
    let res = await api.siteController.put(URL, payload);
    dispatch({ type: UPDATE_SITE_ALERT_NOTIFICATION_LOADING, payload: false })
    handleResponseObj(res, dispatch, UPDATE_SITE_ALERT_NOTIFICATION)
  } catch (error) {
    dispatch({ type: UPDATE_SITE_ALERT_NOTIFICATION_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const updateSiteAlertStatus = ( siteId, payload ) => async (dispatch) => {
  dispatch({ type: UPDATE_SITE_ALERT_STATUS_LOADING, payload: true });
  let URL = `/v1/sites/${siteId}/alerts_config/alert_status`
  try {
    let res = await api.siteController.put(URL, payload);
    dispatch({ type: UPDATE_SITE_ALERT_STATUS_LOADING, payload: false })
    handleResponseObj(res, dispatch, UPDATE_SITE_ALERT_STATUS)
  } catch (error) {
    dispatch({ type: UPDATE_SITE_ALERT_STATUS_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const deleteSiteAlertLinkAvailability = ( siteId, payload ) => async (dispatch) => {
  dispatch({ type: DELETE_SITE_ALERT_LINK_AVAILABILITY_LOADING, payload: true });
  let URL = `/v1/sites/${siteId}/alerts_config/link_availability`
  try {
    let res = await api.siteController.put(URL, payload);
    dispatch({ type: DELETE_SITE_ALERT_LINK_AVAILABILITY_LOADING, payload: false })
    handleResponseObj(res, dispatch, DELETE_SITE_ALERT_LINK_AVAILABILITY)
  } catch (error) {
    dispatch({ type: DELETE_SITE_ALERT_LINK_AVAILABILITY_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const getSiteAlertAuditLogs = ( siteId, payload ) => async (dispatch) => {
  dispatch({ type: GET_SITE_ALERT_AUDIT_LOGS_LOADING, payload: true });
  let URL = `/v1/sites/${siteId}/alerts_config/audit_logs`
  try {
    let res = await api.siteController.post(URL, payload);
    dispatch({ type: GET_SITE_ALERT_AUDIT_LOGS_LOADING, payload: false })
    handleResponseObj(res, dispatch, GET_SITE_ALERT_AUDIT_LOGS)
  } catch (error) {
    dispatch({ type: GET_SITE_ALERT_AUDIT_LOGS_LOADING, payload: false })
    handleError(error, dispatch)
  }
};