import { TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const filter = createFilterOptions();

export const FreeSoloCreateOption = ({vesselNames, check, setSiteValue, setIsCreate, value, setValue}) => {

  useEffect(() => {
    setSiteValue(value)
  }, [value])

  return (
    <div>
        <Autocomplete
            forcePopupIcon={true}
            popupIcon={<KeyboardArrowDownIcon/>}
            value={value}
            ListboxProps={{className: 'warehouse-apply-config-props'}}
            className="audit-filter-inputs-usage config-textField"
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    setValue({
                        label: newValue,
                    });
                    setIsCreate(false);
                } else if (newValue && newValue.inputValue) {
                    setValue({
                        label: newValue.inputValue
                    });
                    setIsCreate(true);
                    check(false);
                } else {
                    setIsCreate(false);
                    setValue(newValue);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some((option) => inputValue === option.label);
                if(inputValue.includes("'")) {
                    check(true);
                }
                if(inputValue.length == 0) {
                    check(false);
                }
                if (inputValue !== '' && !isExisting && !inputValue.includes("'")) {
                    filtered.push({
                        inputValue,
                        label: `Add "${inputValue}"`,
                    });
                }
                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            size='small'
            options={vesselNames}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                   return option;
                }
                if (option.inputValue) {
                   return option.inputValue;
                }
                return option.label;
            }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            freeSolo
            renderInput={(params) => (
                <TextField {...params} placeholder="select" />
            )}
        />
    </div>
  );
}