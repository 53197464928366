import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import './smalldropdown.css';

export default function BasicSelect(props:any) {
    const {handleHistoInterval, chartType} = props;
  const [inetval, setInterval] = React.useState('2');


  const handleChange = (event: SelectChangeEvent) => {
    handleHistoInterval(event);
    setInterval(event.target.value as string);
  };

  const list = [2,4,6,8,10,12,14,16,18,20,22,24,26,28,30];

  return (
    <Box  sx={{ minWidth: 20 }} className="histoInterval">
      <FormControl fullWidth className="histoIntervalInternal">
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={inetval}
          label="Age"
          name={chartType}
          onChange={handleChange}
        >{list && list.length > 0 && list.map((item) => {
            return <MenuItem value={item}>{item}</MenuItem>
        })
        }
        </Select>
      </FormControl>
    </Box>
  );
}