import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import JSONPretty from "react-json-pretty";
import './JsonTooltip.css';
import _ from 'lodash';

interface JsonTooltipProps {
    json: string;
    className?: string;
}

interface JsonDataTooltipProps {
    data: any;
    className?: string;
}

const JsonTooltip = ({ className, json, ...props }: JsonTooltipProps) => (
    <Tooltip classes={{tooltip: 'jsonTooltip'}}  title={<JSONPretty className='scrollTooltip' json={_.isEmpty(json)?"":json?.replace(/'/g, '"')} onJSONPrettyError={console.error} />} {...props} placement="left-start" arrow>
        <IconButton>
            <InfoIcon />
        </IconButton>
    </Tooltip>
);

export const JsonDataTooltip = ({ className, data, ...props }: JsonDataTooltipProps) => (
    <Tooltip classes={{tooltip: 'jsonTooltip'}}  title={<JSONPretty className='scrollTooltip' data={data} onJSONPrettyError={console.error} />} {...props} placement="left-start" arrow>
        <IconButton>
            <InfoIcon />
        </IconButton>
    </Tooltip>
);


export default JsonTooltip;