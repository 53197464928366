
import axios from "axios";
import { API_URL, GRAPHQL_URI, APP_ENV, SITE_CONTROLLER_URL, STARLINK_URL } from '../config';
import { toast } from 'react-toastify';
import { LOGGED_IN_USER } from '../constants/Constants';
import { getDataFromLocalStorage } from "../utils/util";
import _ from "lodash";

const REDIRECT_URL = "/";
const _authorizationHeaders = () => ({
  "Content-Type": "application/json",
  "Authorization": getDataFromLocalStorage(LOGGED_IN_USER) ? "Bearer " + JSON.parse(getDataFromLocalStorage(LOGGED_IN_USER)).data.id_token : '',
});
const abortSingnalOrUndefined = (() => {if (true) return undefined; else return (new AbortController()).signal})()


// const _authorizationHeadersRoot = () => ({
//   "Content-Type": "application/json",
//   "Authorization": getDataFromLocalStorage(LOGGED_IN_USER) ? "Bearer " + JSON.parse(getDataFromLocalStorage(LOGGED_IN_USER)).data.id_token : '',
//   "root_ou": "K4Mobility",
// });

const _authorizationHeaders1 = () => ({
  "Content-Type": "application/json",
  "Authorization": getDataFromLocalStorage(LOGGED_IN_USER) ? "Bearer " + JSON.parse(getDataFromLocalStorage(LOGGED_IN_USER)).data.id_token : '',
});

const _authorizationHeadersOut = () => ({
  "Content-Type": "application/json",
  "Authorization":  getDataFromLocalStorage(LOGGED_IN_USER) ? "Bearer " + JSON.parse(getDataFromLocalStorage(LOGGED_IN_USER)).data.id_token : '',
});

const logout = async (redirectPageUrl =  "") => {
  let logoutPayload = {
    idToken: JSON.parse(getDataFromLocalStorage('loggedInUser')).data.id_token,
    redirectUrl: REDIRECT_URL,
    loginId: JSON.parse(getDataFromLocalStorage('loggedInUser')).data.login_id
  }

  try {
    const res = await axios({
      url: API_URL + "/iam/user/logout",
      method: "POST",
      headers: Object.assign({}, _authorizationHeaders()),
      data: logoutPayload,
    });
  } catch (error) {
    console.log("logout ", error);
  }

  localStorage.setItem('loggedInEmail', '');
  localStorage.setItem('loggedInUpdateEmail', '');
  localStorage.setItem('loggedInUser', '');
  localStorage.setItem('Refresh Token', '');
  localStorage.clear();
  if (redirectPageUrl) {
    window.location = REDIRECT_URL + "?redirectURL=" + redirectPageUrl;
  } else {
    window.location = REDIRECT_URL;
  }
}

const parseJwt = (token) => {
  if (token) {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  }
  return null;
};

const renewTokenIfRequired = async () => {
  console.log("renewTokenIfRequired");

  var loggedInUser = getDataFromLocalStorage("loggedInUser");
  if (loggedInUser) {
    var id_token = JSON.parse(loggedInUser).data.id_token

    if (id_token) {
      const decodedJwt = parseJwt(id_token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        var refresh_token = JSON.parse(loggedInUser).data.refresh_token;

        var body = { refreshToken: refresh_token };
        const headers = { "Content-Type": "application/json" };
        try {
          const res = await axios({
            url: API_URL + "/iam/token",
            method: "POST",
            headers: Object.assign({}, headers),
            data: body,
          });
          if (res && res.data && res.data.message) {
            var _loggedInUser = JSON.parse(loggedInUser);            
            _loggedInUser.data = JSON.parse(res.data.message.message);
            localStorage.setItem("loggedInUser", JSON.stringify(_loggedInUser));
            return true;
          }
          console.log("Session expired, logging out.");
          await logout(window.location.href);
        } catch (err) {
          console.log("Session expired, logging out.");
          console.error(err);
          await logout(window.location.href);
        }
      }
    }
  }
  return false;
}

export const getRequestWithHeader = async (url, data = {}, headers = {}) => {
  try {
    const res = await axios.get(API_URL + url, {
      headers: Object.assign(_authorizationHeaders(), headers), data
    });
    getToastMessageGet(res);
    if (res && res.data && 0 === res.data.status) {
      return;
    }
    return res && res.data && res.data.result ? res.data.result : res;
  } catch (err) {
    displayToastError(err)
    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const getRequest = async (url, data = {}, headers = _authorizationHeaders()) => {
  try {
    var isValidToken = await renewTokenIfRequired();
    if (isValidToken) {
      headers = _authorizationHeaders();
    }
    const res = await axios.get(API_URL + url, {
      headers: Object.assign({}, headers), data
    });
    getToastMessageGet(res);
    if (res && res.data && 0 === res.data.status) {
      return;
    }
    return res && res.data && res.data.result ? res.data.result : res;
  } catch (err) {
    displayToastError(err)
    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const getRequestAPI = async (url, data = {}, headers = _authorizationHeaders()) => {
  console.log(headers, "{}")
  try {
    var isValidToken = await renewTokenIfRequired();
    if (isValidToken) {
      headers = _authorizationHeaders();
    }
    const res = await axios.get(API_URL + url, {
      headers: Object.assign({}, headers), data
    });
    getToastMessageGet(res);
    if (res && res.data && 0 === res.data.status) {
      return;
    }
    return res && res.data && res.data.result ? res.data.result : res;
  } catch (err) {

    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const getRequestOut = async (url, data = {}, headers = _authorizationHeaders1()) => {

  try {
    var isValidToken = await renewTokenIfRequired();
    if (isValidToken) {
      headers = _authorizationHeaders();
    }
    const res = await axios.get(API_URL + url, {
      headers: Object.assign({}, headers), data
    });
    getToastMessageGet(res);
    if (res && res.data && 0 === res.data.status) {
      return;
    }
    return res && res.data && res.data.result ? res.data.result : res;
  } catch (err) {

    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const postRequest = async (url, data = {}, headers = _authorizationHeaders()) => {
  try {
    var isValidToken = await renewTokenIfRequired();
    if (isValidToken) {
      headers = _authorizationHeaders();
    }
    const res = await axios({
      url: API_URL + url,
      method: "POST",
      headers: Object.assign({}, headers),
      data,
    });
    getToastMessage(res);
    if (res && res.data && 0 === res.data.status) {
      return;
    }
    return res && res.data && res.data.result ? res.data.result : res;
  } catch (err) {

    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const postRequestNormal = async (url, data = {}, headers = _authorizationHeaders()) => {
  try {
    var isValidToken = await renewTokenIfRequired();
    if (isValidToken) {
      headers = _authorizationHeaders();
    }
    const res = await axios({
      url: API_URL + url,
      method: "POST",
      headers: Object.assign({}, headers),
      data,
    });
    if(!url.includes('updateInventoryLocation/v2')) getToastMessage(res);
    return res.data ?? res;
  } catch (err) {
    return err && err.response && err.response.data ? err.response.data : err;
  }
};


export const postRequestOut = async (url, data = {}, signal = (() => {if (true) return undefined; else return (new AbortController).signal})(), headers = _authorizationHeadersOut()) => {
  let finalURl = false ? localStorage.getItem("intraUrl") : API_URL + url;
  try {
    const res = await axios({
      url: finalURl,
      method: "POST",
      headers: Object.assign({}, headers),
      data,
      signal: signal
    });
    return res && res.data && res.data.result ? res.data.result : res;
  } catch (err) {

    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const putRequest = async (url, data = {}, headers = _authorizationHeaders()) => {
  try {
    var isValidToken = await renewTokenIfRequired();
    if (isValidToken) {
      headers = _authorizationHeaders();
    }
    const res = await axios({
      url: API_URL + url,
      method: "PUT",
      headers: Object.assign({}, headers),
      data,
    });
    return res && res.data && res.data.result ? res.data.result : res;
  } catch (err) {

    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const putRequestOut = async (url, data = {}, headers = _authorizationHeadersOut()) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "PUT",
      headers: Object.assign({}, headers),
      data,
    });
    return res && res.data && res.data.result ? res.data.result : res;
  } catch (err) {

    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const patchRequest = async (url, data = {}, headers = _authorizationHeaders()) => {
  try {
    var isValidToken = await renewTokenIfRequired();
    if (isValidToken) {
      headers = _authorizationHeaders();
    }
    const res = await axios({
      url: API_URL + url,
      method: "PATCH",
      headers: Object.assign({}, headers),
      data,
    });
    getToastMessage(res);
    if (res && res.data && 0 === res.data.status) {
      return;
    }
    return res && res.data && res.data.result ? res.data.result : res;
  } catch (err) {

    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const deleteRequest = async (url, data = {}, headers = _authorizationHeaders()) => {
  try {
    var isValidToken = await renewTokenIfRequired();
    if (isValidToken) {
      headers = _authorizationHeaders();
    }
    const res = await axios({
      url: API_URL + url,
      method: "DELETE",
      headers: Object.assign({}, headers),
      data,
    });
    getToastMessage(res);
    if (res && res.data && 0 === res.data.status) {
      return;
    }
    return res && res.data && res.data.result ? res.data.result : res;
  } catch (err) {

    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const getRequestForExternalApi = async (url, data = {}, headers = _authorizationHeaders()) => {
  try {
    var isValidToken = await renewTokenIfRequired();
    if (isValidToken) {
      headers = Object.assign(headers, _authorizationHeaders);
    }
    const res = await axios.get(url, {
      headers: Object.assign({}, headers), data
    });
    getToastMessage(res);
    if (res && res.data && (res.data.status === 1 || res.data.status === "ok"  || res.data.success)) {
      return res && res.data && res.data.result ? res.data.result : res;
    }
    // else{
    //   displayToastErrorForExternalApi(res ? res.message ? res.message : "" : "")
    // }
    return res ? res.message ? res.message : "" : "";
  } catch (err) {

    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const getRequestForExternalApiWithoutHeader = async (url, data = {}, headers = null) => {
  try {
    const res = await axios.get(url, {
      headers: data
    });
    getToastMessage(res);
    if (res && res.data) {
      return res && res.data && res.data ? res.data : res;
    }
    return res ? res.message ? res.message : "" : "";
  } catch (err) {
    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const getRequestForExternalApiBlob = async (url, data = {}, headers = _authorizationHeaders()) => {
  try {
    var isValidToken = await renewTokenIfRequired();
    if (isValidToken) {
      headers = _authorizationHeaders();
    }
    const res = await axios.get(url, {
      headers: Object.assign({}, headers),
      responseType: "blob",
      data
    });
    return res

  } catch (err) {

    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const postRequestForExternalApi = async (url, data, headers = _authorizationHeaders()) => {
  try {
    var isValidToken = await renewTokenIfRequired();
    if (isValidToken) {
      headers = Object.assign(headers, _authorizationHeaders);
    }
    const res = await axios.post(url, data, {
      headers: Object.assign({}, headers)
    });

    if (res && res.data && (res.data.status === 1 || res.data.status === "ok" || res.data.success || res.status == 200)) {
      return res && res.data && res.data.result ? res.data.result : res;

    } else {
      displayToastErrorForExternalApi(res ? res.data ? res.data.message : "" : "")
    }
    // getToastMessage(res);
    // if (res && res.data && 0 === res.data.status) {
    //   return;
    // }
    // return res && res.data && res.data.result ? res.data.result : res;
  } catch (err) {

    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const putRequestForExternalApi = async (url, data, headers = _authorizationHeaders()) => {
  try {
    var isValidToken = await renewTokenIfRequired();
    if (isValidToken) {
      headers = Object.assign(headers, _authorizationHeaders);
    }
    const res = await axios.put(url, data, {
      headers: Object.assign({}, headers)
    });
    if (res && res.data && (res.data.status === 1 || res.data.status === "ok" || res.data.success)) {
      return res;

    } else {
      displayToastErrorForExternalApi(res ? res.data ? res.data.message : "" : "")
    }
  } catch (err) {

    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const deleteRequestForExternalApi = async (url, data = {}, headers = _authorizationHeaders()) => {
  try {
    var isValidToken = await renewTokenIfRequired();
    if (isValidToken) {
      headers = Object.assign(headers, _authorizationHeaders);
    }
    const res = await axios.delete(url, {
      headers: Object.assign({}, headers)
    });

    if (res && res.data && (res.data.status === 1 || res.data.status === "ok")) {
      return res && res.data && res.data.result ? res.data.result : res;

    } else {
      displayToastErrorForExternalApi(res ? res.data ? res.data.message : "" : "")
    }
  } catch (err) {

    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const postRequestForTicketExternalApi = async (url, data, headers = _authorizationHeaders()) => {
  try {
    var isValidToken = await renewTokenIfRequired();
    if (isValidToken) {
      headers = _authorizationHeaders();
    }
    const res = await axios.post(url, data, {
      headers: Object.assign({}, headers)
    });

    if (res && res.data && res.data.status === 1) {
        toast.success(getSuccessMessage(res.data), {
        autoClose: toast_setting_autoClose,
        hideProgressBar: toast_setting_hideProgressBar,
        className: custom_toast_success_className,
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return res && res.data && res.data.result ? res.data.result : res;

    } else {
      displayToastErrorForExternalApi(res ? res.data ? res.data.message : "" : "")
    }
  } catch (err) {

    return err && err.response && err.response.data ? err.response.data : err;
  }

  function getSuccessMessage(res) {
    if (!res.data) {
      return res.message ?? '';
    }
    if(!res.data.hasOwnProperty('ticketType')) {
      return "Name change successful, will reflect across the system in next 30 seconds"
    }
    return res.data.ticketType === "Notes" ? "Note Updated" : "Ticket Updated";
    
  }

};

// Site Controller API Handler
const siteController = {
  host: SITE_CONTROLLER_URL,
  handleAuthHeaders: async function (headers) {
    // Uncomment when iam-middleware is implemented in site-controller
    let authHeaders = Object.assign({}, _authorizationHeaders());
    var isValidToken = await renewTokenIfRequired();
    if (isValidToken) {
      authHeaders = Object.assign({}, _authorizationHeaders());
    }
    headers = Object.assign(headers, authHeaders)
    return headers;
  },
  handleResponse: function(res) {
    return res?.data?.success ? res?.data : res;
  },
  showErrorToast: function (err) {
    if(err?.message && err.message === "canceled"){
      return
    }
    if (err?.response) {
      // response with status - 5xx, 4xx
      if(!_.isEmpty(err?.response?.data?.errors)) {
        displayToastError(err?.response?.data?.errors[0].message);
      }
    } else if (err?.request) {
      displayToastError(err.message)
    } else {
      displayToastError(err.message)
    }
  },
  get: async function (url, headers = {}, signal = abortSingnalOrUndefined) {
    try {
      headers = await this.handleAuthHeaders(headers);
      const res = await axios.get(`${this.host}${url}`, {
        headers: Object.assign({}, headers),
        signal: signal
      });
      return this.handleResponse(res)
    } catch (err) {
      this.showErrorToast(err)
      throw err;
    }
  },
  post: async function (url, data, headers = {}, signal = abortSingnalOrUndefined) {
    try {
      headers = await this.handleAuthHeaders(headers);
      const res = await axios.post(`${this.host}${url}`, data, {
        headers: Object.assign({}, headers),
        signal: signal
      });
      return this.handleResponse(res)
    } catch (err) {
      this.showErrorToast(err)
      throw err;
    }
  },
  put: async function (url, data, headers = {}, signal = abortSingnalOrUndefined) {
    try {
      headers = await this.handleAuthHeaders(headers);
      const res = await axios.put(`${this.host}${url}`, data, {
        headers: Object.assign({}, headers),
        signal: signal
      });
      return this.handleResponse(res)
    } catch (err) {
      this.showErrorToast(err)
      throw err;
    }
  },
  delete: async function (url, data = {}, headers = {}, signal = abortSingnalOrUndefined) {
    try {
      headers = await this.handleAuthHeaders(headers);
      const res = await axios.delete(`${this.host}${url}`, {
        headers: Object.assign({}, headers),
        data: data,
        signal: signal
      });
      return this.handleResponse(res)
    } catch (err) {
      this.showErrorToast(err)
      throw err;
    } 
  }
}

// starlink API Handler
export const starlink = {
  host: STARLINK_URL,
  handleAuthHeaders: async function (headers) {
    // Uncomment when iam-middleware is implemented in site-controller
    let authHeaders = Object.assign({}, _authorizationHeaders());
    var isValidToken = await renewTokenIfRequired();
    if (isValidToken) {
      authHeaders = Object.assign({}, _authorizationHeaders());
    }
    headers = Object.assign(headers, authHeaders)
    return headers;
  },
  showErrorToast: function (err) {
    if(err?.message && err.message === "canceled"){
      return
    }
    if (err?.response) {
      // response with status - 5xx, 4xx
      if(!_.isEmpty(err?.response?.data?.error)) {
        displayToastError(err?.response?.data?.error?.map((e) => e.msg).join(', '));
      } else if (err?.response?.data?.message) {
        displayToastError(err?.response?.data?.message);
      }
    } else {
      displayToastError(err.message)
    }
  },
  get: async function (url, headers = {}, signal = abortSingnalOrUndefined) {
    try {
      headers = await this.handleAuthHeaders(headers);
      const res = await axios.get(`${this.host}${url}`, {
        headers: Object.assign({}, headers),
        signal: signal
      });
      return res?.data
    } catch (err) {
      this.showErrorToast(err)
      throw err;
    }
  },
  post: async function (url, data, headers = {}, signal = abortSingnalOrUndefined) {
    try {
      headers = await this.handleAuthHeaders(headers);
      const res = await axios.post(`${this.host}${url}`, data, {
        headers: Object.assign({}, headers),
        signal: signal
      });
      return res?.data
    } catch (err) {
      this.showErrorToast(err)
      throw err;
    }
  },
  put: async function (url, data, headers = {}, signal = abortSingnalOrUndefined) {
    try {
      headers = await this.handleAuthHeaders(headers);
      const res = await axios.put(`${this.host}${url}`, data, {
        headers: Object.assign({}, headers),
        signal: signal
      });
      return res?.data
    } catch (err) {
      this.showErrorToast(err)
      throw err;
    }
  },
  delete: async function (url, data = {}, headers = {}, signal = abortSingnalOrUndefined) {
    try {
      headers = await this.handleAuthHeaders(headers);
      const res = await axios.delete(`${this.host}${url}`, {
        headers: Object.assign({}, headers),
        data: data,
        signal: signal
      });
      return res?.data
    } catch (err) {
      this.showErrorToast(err)
      throw err;
    } 
  }
}


let toast_setting_autoClose = true;
let toast_setting_hideProgressBar = false;
// let toast_setting_position = toast.POSITION.TOP_RIGHT;
let custom_toast_className = "custom_toast ";
let custom_toast_success_className = custom_toast_className + "custom_toast_success";
let custom_toast_error_className = custom_toast_className + "custom_toast_error";

const getToastMessage = (res) => {
  if (res && res.data && res.data.message) {
    let message = res.data.message;
    if (res.data.status && (1 === res.data.status || 'ok' === res.data.status)) {
      // displayToastSuccess(message);
    } else {
      displayToastError(message);
    }
  }
}

const getToastMessageGet = (res) => {
  if (res && res.data && res.data.message) {
    let message = res.data.message;
    if (res.data.status && (1 === res.data.status || 'ok' === res.data.status)) {
      // success
    } else {
      displayToastError(message);
    }
  }
}

const displayToastSuccess = (message) => {
  console.debug(message)
  // toast.success(message, {
  //   autoClose: toast_setting_autoClose,
  //   hideProgressBar: toast_setting_hideProgressBar,
  //   className: custom_toast_success_className,
  //   position: toast.POSITION.BOTTOM_LEFT,
  // });
}

export const displayToastErrorForExternalApi = (message) => {
  console.error("Error: ", message);
  if(message && message.hasOwnProperty("message")){
    message = message.message
    if(message.includes("Request failed with status code")){
      return
    }
  }
  toast.error(message, {
    autoClose: toast_setting_autoClose,
    hideProgressBar: toast_setting_hideProgressBar,
    className: custom_toast_error_className,
    position: toast.POSITION.BOTTOM_LEFT,
  });
}

export const displayToastError = (message) => {
  if (!message) return;
  console.error("Error: ", message);
  if(message && message.hasOwnProperty("message")){
    message = message.message
    if(message.includes("Request failed with status code")){
      return
    }
  }
  toast.error(message, {
    autoClose: toast_setting_autoClose,
    hideProgressBar: toast_setting_hideProgressBar,
    className: custom_toast_error_className,
    position: toast.POSITION.BOTTOM_LEFT,
  });
}

axios.interceptors.response.use(
  function(successRes) {;
    handleErrorResponse(successRes);
    return successRes;
  },
  function(error) {
    handleErrorResponse(error);
    return Promise.reject(error);
  }
);

export const handleErrorResponse = (res) => {
  let appEnv = APP_ENV;
  res = JSON.parse(JSON.stringify(res));
  if (!(res && (res.status === 201 || res.status === 200 || res.status === 202))) {
    let queryTime = new Date().toISOString();
    let statusCode = res.hasOwnProperty("status") ? res.status : null;
    let message = res.hasOwnProperty("message") ? res.message : null;
    let apiUrl = res.hasOwnProperty("config") && res.config.hasOwnProperty("url") ? res.config.url : null;
    let queryParams = apiUrl ? new URL(apiUrl).search : null;
    let payload = res.hasOwnProperty("config") && res.config.hasOwnProperty("data") ? res.config.data : null;
    let response =  res.hasOwnProperty("data") && res.data ? res.data : null;
    if(response && _.isEmpty(message)){
      message = response.hasOwnProperty("message") ? response.message : null;
    }
    let errorInfo = {
      "errorKey": message,
      "url" : apiUrl,
      "error": message,
      "statusCode": statusCode,
      "params": queryParams,
      "payload" : payload,
      "response" : response,
      "queryTime": queryTime,
      "userEmail": localStorage.getItem("loggedInEmail"),
      "env":appEnv
    };
    // saveError(JSON.stringify(errorInfo));
  }
}

export async function saveError(jsonString) {
  let url = API_URL + GRAPHQL_URI;
  const payload = {
    "operationName": "StoreEvents",
    "query": `query StoreEvents($jsonString: String) {
      storeEvents(jsonString: $jsonString)
    }`,
    "variables": {
        "jsonString": jsonString
    }
  };
  let headers = _authorizationHeaders()
  var isValidToken = await renewTokenIfRequired();
  if (isValidToken) {
    headers = _authorizationHeaders();
  }
  const response = await axios.post(url, payload, {
    headers: Object.assign({}, headers)
  });
  // let message = response.hasOwnProperty("data") ? response.data.hasOwnProperty("data") ? response.data.data : response.data : null;
  // console.log("saveError response", JSON.stringify(message));
}

export const api = {
  deleteRequest,
  getRequest,
  getRequestAPI,
  getRequestOut,
  postRequest,
  postRequestNormal,
  putRequest,
  patchRequest,
  postRequestOut,
  putRequestOut,
  getRequestForExternalApi,
  putRequestForExternalApi,
  deleteRequestForExternalApi,
  getRequestForExternalApiBlob,
  postRequestForExternalApi,
  postRequestForTicketExternalApi,
  getRequestWithHeader,
  getRequestForExternalApiWithoutHeader,
  saveError,
  siteController,
  starlink
};
