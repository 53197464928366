import { Grid } from "@mui/material";
import { Dialog } from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from '@mui/material/DialogContent';
import succesIcon from "../../asset/image/SuccessIcon.svg";
import rightMark from "../../asset/image/rightMark.svg";
import "./inventoryWarehouse.css";

export const SuccessPopup = (props) => {
    const { selectedDevice, siteValue, handleCloseSuccesPopup } = props;
    
return (
    <div className="Form">
        <Dialog
        open={true}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'md'}
        className="successDialog"
    >
        <div className="inventory-success-popup">
            <DialogTitle className="successdialog_title">
                <Grid>
                    <Grid className="SuccesGreenLogo">
                        <Grid>
                            <img src={succesIcon} alt="success" />
                        </Grid>
                        <Grid>
                            <img src={rightMark} alt="right" />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent >
                <div className="DialogueContent">
                    <Grid container spacing={2}>
                        Device ({selectedDevice.deviceId}) has been assigned to 
                        <br />
                        <span className="createdVessel">"{siteValue?.label}"</span>
                    </Grid>
                    <button className="Close_button" onClick={()=>{
                        handleCloseSuccesPopup()
                    }}>Close</button>
                </div>
            </ DialogContent>
        </div>
    </Dialog>
    </div>
 )
}

export default SuccessPopup;