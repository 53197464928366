import { api } from "../../server/request";
import { handleResponse, handleError } from "../responseUtil";
import { SET_LOADING, SOFTWARE_PRODUCT, SET_SOFTWARE_LISTING_LOADING, SOFTWARE_LISTING, GET_SOFTWARE_VERSIONS_DEVICE_COUNT, GET_SOFTWARE_VERSION_DEVICE_DETAILS, GET_SOFT_VERSION_DEVICE_DETAILS, GET_KONNECT_APP_DOWNLOAD_LINKS, GET_DP_DOCUMENTS_LIST, GET_VX_BUILD_DETAILS } from "../types";

export const softwareProduct = () => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest("/edge/softwares/getProducts")
    .then((res) => handleResponse(res, dispatch, SOFTWARE_PRODUCT))
    .catch((err) => handleError(err, dispatch));
};

export const softwareListing = (type, productName, sort, sortOrder, page, limit) => (dispatch) => {
  dispatch({ type: SET_SOFTWARE_LISTING_LOADING, payload: true });
  api
    .getRequest(`/edge/softwares/search?type=${type}&productName=${productName}&sort=${sort}&sortOrder=${sortOrder}&page=${page}&limit=${limit}`)
    .then((res) => handleResponse(res, dispatch, SOFTWARE_LISTING))
    .catch((err) => handleError(err, dispatch));
};

export const getSoftwareVersionsDeviceCount = (ouName, ouId, sort = "", sortOrder = "") => (dispatch) => {
  dispatch({ type: SET_SOFTWARE_LISTING_LOADING, payload: true });
  api
    .getRequest(`/edge/softwares/versions?ouName=${encodeURIComponent(ouName)}&ouId=${ouId}&sort=${sort}&sortOrder=${sortOrder}`)
    .then((res) => handleResponse(res, dispatch, GET_SOFTWARE_VERSIONS_DEVICE_COUNT))
    .catch((err) => handleError(err, dispatch));
};

export const getSoftwareVersionDeviceDetails = (ouName, ouId, softwareVersion, search = "", page = -1, limit = -1, sort = "", sortOrder = "") => (dispatch) => {
  dispatch({ type: SET_SOFTWARE_LISTING_LOADING, payload: true });
  api
    .getRequest(`/edge/softwares/versions/${softwareVersion}/devices?ouName=${encodeURIComponent(ouName)}&ouId=${ouId}&page=${page}&limit=${limit}&sort=${sort}&sortOrder=${sortOrder}&search=${search}`)
    .then((res) => handleResponse(res, dispatch, GET_SOFTWARE_VERSION_DEVICE_DETAILS))
    .catch((err) => handleError(err, dispatch));
};

export const getDeviceDetails = (ouName, ouId, search = "", page = -1, limit = -1, sort = "", sortOrder = "") => (dispatch) => {
  dispatch({ type: SET_SOFTWARE_LISTING_LOADING, payload: true });
  api
    .getRequest(`/edge/softwares/versions/devices?ouName=${encodeURIComponent(ouName)}&ouId=${ouId}&page=${page}&limit=${limit}&sort=${sort}&sortOrder=${sortOrder}&search=${search}`)
    .then((res) => handleResponse(res, dispatch, GET_SOFT_VERSION_DEVICE_DETAILS))
    .catch((err) => handleError(err, dispatch));
};

export const getKonnectDownloadLinks = () => (dispatch) => {
  dispatch({ type: SET_SOFTWARE_LISTING_LOADING, payload: true });
  api
    .getRequest(`/edge/softwares/konnect/versions?latest=true&os=windows,mac,mac-intel`)
    .then((res) => handleResponse(res, dispatch, GET_KONNECT_APP_DOWNLOAD_LINKS))
    .catch((err) => handleError(err, dispatch));
};

export const getVxBuildsDetails = () => (dispatch) => {
  dispatch({ type: SET_SOFTWARE_LISTING_LOADING, payload: true });
  api
    .getRequest(`/edge/softwares/vx/versions/v2/download`)
    .then((res) => handleResponse(res, dispatch, GET_VX_BUILD_DETAILS))
    .catch((err) => handleError(err, dispatch));
};

export const getDpDocumentList = (dpId) => (dispatch) => {
  dispatch({ type: SET_SOFTWARE_LISTING_LOADING, payload: true });
  api
    .getRequest(`/edge/softwares/documents/${dpId}`)
    .then((res) => handleResponse(res, dispatch, GET_DP_DOCUMENTS_LIST))
    .catch((err) => handleError(err, dispatch));
};