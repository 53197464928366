import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import "./konnect-app.css";
import { getKonnectDownloadLinks } from "../../actions/Users/authenticateSoftware";
import _ from "lodash";

const SoftwareVersionKonnectAppDetails = (props) => {
    const { authReducer, location, getKonnectDownloadLinks } = props;
    let [konnectAppDownloadLinks, setKonnectAppDownloadLinks] = useState<{}>({});
    useEffect(() => {
        getKonnectDownloadLinks();
    }, []);


    useEffect(() => {
        if (!_.isEmpty(authReducer.konnectAppDownloadLinks)) {
            let data = authReducer.konnectAppDownloadLinks.hasOwnProperty("data") ? authReducer.konnectAppDownloadLinks.data : {};
            setKonnectAppDownloadLinks(data);
            authReducer.konnectAppDownloadLinks = {};
        }
    }, [authReducer.konnectAppDownloadLinks]);

    return (
        <Grid id="konnectDownloadLinks">
            <ul key={`download-platform`} className="download-platform">
                {
                    Object.keys(konnectAppDownloadLinks).map((k, index) => {
                        return [
                            konnectAppDownloadLinks[k].hasOwnProperty("windows") ?
                                <li key={`${k}_windows`}>
                                    <a target="_blank" href={konnectAppDownloadLinks[k].windows}>
                                        <svg className="download-logo" width="50" height="50" viewBox="0 0 50 50" focusable="false"><path d="M1.589 23.55L1.572 8.24l18.839-2.558V23.55zM23.55 5.225l25.112-3.654V23.55H23.55zM48.669 26.69l-.006 21.979-25.112-3.533V26.69zM20.41 44.736l-18.824-2.58-.001-15.466H20.41z"></path></svg>
                                        Windows Installer
                                        <p className="versionNumber">{k}.exe</p>
                                    </a>
                                </li>
                            : null,
                            konnectAppDownloadLinks[k].hasOwnProperty("mac")?
                                <li key={`${k}_mac`}>
                                    <a target="_blank" href={konnectAppDownloadLinks[k].mac}>
                                        <svg className="download-logo" width="50" height="50" viewBox="0 0 50 50" focusable="false"><path d="M39.054 34.065q-1.093 3.504-3.448 7.009-3.617 5.495-7.205 5.495-1.374 0-3.925-.897-2.411-.897-4.233-.897-1.71 0-3.981.925-2.271.953-3.701.953-4.261 0-8.439-7.261Q.001 32.075.001 25.29q0-6.392 3.168-10.485 3.14-4.037 7.962-4.037 2.019 0 4.962.841 2.916.841 3.869.841 1.262 0 4.009-.953 2.86-.953 4.85-.953 3.336 0 5.972 1.822 1.458 1.009 2.916 2.804-2.215 1.878-3.196 3.308-1.822 2.635-1.822 5.803 0 3.476 1.934 6.252t4.43 3.533zM28.512 1.179q0 1.71-.813 3.813-.841 2.103-2.607 3.869-1.514 1.514-3.028 2.019-1.037.308-2.916.477.084-4.177 2.187-7.205 2.075-3 7.009-4.149.028.084.07.308t.07.308q0 .112.014.28t.014.28z"></path></svg>
                                            MacOS Installer
                                        <p className="versionNumber">{k}.dmg</p>
                                    </a>
                                </li>
                            : null,
                            konnectAppDownloadLinks[k].hasOwnProperty("mac-intel") ?
                                <li key={`${k}_mac-intel`}>
                                    <a target="_blank" href={konnectAppDownloadLinks[k]["mac-intel"]}>
                                        <svg className="download-logo" width="50" height="50" viewBox="0 0 50 50" focusable="false"><path d="M39.054 34.065q-1.093 3.504-3.448 7.009-3.617 5.495-7.205 5.495-1.374 0-3.925-.897-2.411-.897-4.233-.897-1.71 0-3.981.925-2.271.953-3.701.953-4.261 0-8.439-7.261Q.001 32.075.001 25.29q0-6.392 3.168-10.485 3.14-4.037 7.962-4.037 2.019 0 4.962.841 2.916.841 3.869.841 1.262 0 4.009-.953 2.86-.953 4.85-.953 3.336 0 5.972 1.822 1.458 1.009 2.916 2.804-2.215 1.878-3.196 3.308-1.822 2.635-1.822 5.803 0 3.476 1.934 6.252t4.43 3.533zM28.512 1.179q0 1.71-.813 3.813-.841 2.103-2.607 3.869-1.514 1.514-3.028 2.019-1.037.308-2.916.477.084-4.177 2.187-7.205 2.075-3 7.009-4.149.028.084.07.308t.07.308q0 .112.014.28t.014.28z"></path></svg>
                                            Mac IntelOS Installer
                                        <p className="versionNumber">{k}.dmg</p>
                                    </a>
                                </li>
                            : null
                        ]
                    })
                }
            </ul>
        </Grid>
    );
}
const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, { getKonnectDownloadLinks })(SoftwareVersionKonnectAppDetails)
);