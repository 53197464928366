import { Avatar, Chip, Stack, Tooltip } from "@mui/material"
import _ from "lodash";
import { useContext } from "react";
import { connect } from "react-redux";
import { UsageContext } from "./UsageDataContext";
import topApp from "../../asset/image/topAppIcon.svg";
import topUser from "../../asset/image/topUserIcon.svg";
import topSite from "../../asset/image/topSiteIcon.svg";
import categoryIcon from "../../asset/image/categoryIcon.svg";
import vlanIcon from "../../asset/image/vlanIcon.svg";

const ActivityCardFilters = (props: any) => {
    const { selectedVessels = [], isSitesLoading, setReportsPopup } = props;

    const {
        handleUsernameChange,
        handleAppFilterChange,
        handleCategoryChange,
        userName,
        appFilt,
        selectFilter,
        setPage,
        setPageStart,
        userNameTrack,
        category,
        categorTrack,
        vlanFilt,
        handleVlanFilterChange,
        deviceFilt,
        handleDeviceFilterChange,
        startDate, endDate, setTrackSite,
        checkUser
    } = useContext(UsageContext);

    const getSiteFilterChip = () => {
        const _vessels = selectedVessels;
        if (!_.isArray(_vessels) || _vessels.length !== 1 || !_vessels[0].hasOwnProperty('vessel-name'))
            return null;
        return <Tooltip title='Site'><Chip label={_vessels[0]['vessel-name']} disabled={isSitesLoading} onDelete={handleSiteDelete} avatar={<Avatar style={{width:'17px', height:'17px'}} src={`${topSite}`} />} /></Tooltip>
    }

    const handleCategoryFilterDelete = () => {
        handleCategoryChange('');
        selectFilter({ filterCategory: '', vlanFilter: vlanFilt, userCheck: checkUser });
        setPage(1)
        setPageStart(1);
    } 

    const handleUserDelete = () => {
        handleUsernameChange('');
        selectFilter({ filterUserName: '', vlanFilter: vlanFilt, deviceFilter: deviceFilt, userCheck: '' });
        setPage(1)
        setPageStart(1);
    }

    const handleAppFilterDelete = () => {
        handleAppFilterChange('');
        selectFilter({ filterAppFilt: '', vlanFilter: vlanFilt, deviceFilter: deviceFilt, userCheck: checkUser });
        setPage(1)
        setPageStart(1);
    }

    const getInterval = (minutes) => {
        if (minutes > 10080) {
          return true;
        } 
        return false;
    }

    const handleSiteDelete = () => {
        const checkInterval = getInterval(Math.floor(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3));
        if(checkInterval) {
            setReportsPopup(checkInterval);
            setTrackSite(checkInterval);
        }
        selectFilter({ filterSite: '', vlanFilter: vlanFilt, deviceFilter: deviceFilt, userCheck: checkUser });
        setPage(1);
        setPageStart(1);
    }

    const handleVlanFilterDelete = () => {
        handleVlanFilterChange('');
        selectFilter({ vlanFilter: '', deviceFilter: deviceFilt, userCheck: checkUser });
        setPage(1)
        setPageStart(1);
    }

    const handleDeviceFilterDelete = () => {
        handleDeviceFilterChange('');
        selectFilter({ deviceFilter: '', userCheck: checkUser });
        setPage(1)
        setPageStart(1);
    }

    return <Stack direction="row" spacing={1} alignItems='center'>
        {getSiteFilterChip()}
        {userName && userNameTrack && <Tooltip title='User'><Chip label={userName} disabled={isSitesLoading} onDelete={handleUserDelete} avatar={<Avatar style={{width:'17px', height:'17px'}} src={`${topUser}`} />} /></Tooltip> }
        {category && categorTrack && <Tooltip title='Category'><Chip label={category} disabled={isSitesLoading} onDelete={handleCategoryFilterDelete} avatar={<Avatar style={{width:'14px', height:'14px', padding:'3px'}} src={`${categoryIcon}`} />} /></Tooltip> }
        {appFilt && <Tooltip title='Application'><Chip label={appFilt} disabled={isSitesLoading} onDelete={handleAppFilterDelete} avatar={<Avatar style={{width:'15px', height:'15px', paddingLeft:'5px'}} src={`${topApp}`} />} /></Tooltip>}
        {vlanFilt && <Tooltip title='VLAN'><Chip label={vlanFilt} disabled={isSitesLoading} onDelete={handleVlanFilterDelete} avatar={<Avatar style={{width:'15px', height:'15px', padding:'3px'}} src={`${vlanIcon}`} />} /></Tooltip> }
        {deviceFilt && <Tooltip title='Device'><Chip label={deviceFilt} disabled={isSitesLoading} onDelete={handleDeviceFilterDelete}/></Tooltip> }
    </Stack>
}


const mapStateToProps = (state) => ({
    selectedVessels: state.authReducer?.filteredVessels,
    isSitesLoading: state.authReducer.setVesselListingLoading
});

export default connect(mapStateToProps, {})(ActivityCardFilters)