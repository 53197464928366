import { Dialog } from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from '@mui/material/DialogContent';
import { Button, DialogActions, Divider, Grid, TextField } from "@mui/material";
import close from "../../asset/image/close.svg";
import "./BackupConfigNow.css";
import { useState } from "react";
import _ from "lodash";
import { CONFIRM_DIALOG_TITLE } from "../../config";
import ConfirmDialog from "../ConfirmDialog";

export const BackupConfigNow = (props) => {
    const { authReducer, setConfirmDialog, backupConfigNow, selectedSite, selectedSiteName, selectedDevice, onClose } = props;

    const [configName, setConfigName] = useState("")

    const takeABackupNow = () => {
        backupConfigNow(selectedSite, selectedDevice.id, selectedDevice.name, configName)
        onClose()
    }

    return (
        <div className="Form">
            <Grid container className="deploy-inventory-content apply-inventory-content">
                <Grid item xs={12} sm={12} md={12} lg={12} className="config-apply-config-target" >
                    <span id="content-left-text">Device</span>
                    <Grid id="content-right-text">
                        <div title={selectedDevice.name}>{selectedDevice.name}</div>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="config-apply-config-target config-apply-config-target-width">
                    <span id="content-left-text">Config Name</span>
                    <TextField
                        className="user-entered-config-name"
                        placeholder="Enter the name"
                        value={configName}
                        onChange={(event) => { setConfigName(event.target.value) }}
                    />
                </Grid>
            </Grid>
            <div className="edit-actions-confirm-btns">
                <Divider className="confirm-dialogue-new-divider" />
                <div className="confirm-dialogue-new-btns">
                    <Button className="confirm-dialogue-cancel" onClick={onClose}> Cancel </Button>
                    <Button className={!_.isEmpty(configName) ? "confirm-dialogue-proceed" : 'confirm-dialogue-proceed-notAllow'} onClick={() => {
                        if (!_.isEmpty(configName)) {
                            setConfirmDialog({
                                isOpen: true,
                                title: CONFIRM_DIALOG_TITLE,
                                subTitle: "Are you sure to download the configuration for " + selectedDevice.name + " ?",
                                onConfirm: () => {
                                    takeABackupNow()
                                },
                            })
                        }
                    }}>Download</Button>
                </div>
            </div>
        </div>
    )
}