import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContentText, Button } from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';
import './dialogBox.css';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        height: "auto",
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 7px 64px rgba(0, 0, 0, 0.1)',
        borderRadius: '12px',
        padding: '20px',
        border: '2px solid #cacaca'
    },
    dialogPaperClose: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 7px 64px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        paddingTop: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    dialogContent: {
        textAlign: 'center',
        marginTop: '-10px',
        marginBottom: "0px"
    },
    dialogFooter: {
        justifyContent: 'center',
        marginBottom: '-11px'
    },
}));

export default function DialogBox(props) {
    const classes = useStyles();
    return (
        <div className='dialog-box'>
            <Dialog classes={props.onClose ? { paper: classes.dialogPaper } : { paper: classes.dialogPaperClose }} open={props.Open}>
                <DialogContentText classes={{ root: classes.dialogContent }}>
                    <CheckCircleRoundedIcon style={{height: "60px", width: "60px", color: "#4fb391"}}/>
                    <div className='dialog-box-title'>
                        {props.DialogTitle}
                    </div>
                    <div className='dialog-body-content'>
                        {props.DialogBody}
                    </div>
                </DialogContentText>
                <DialogActions classes={{ root: classes.dialogFooter }}>
                    {
                        props.ButtonLabel ?
                            <div className='button'>
                                <Button onClick={props.onClick} variant="contained" color="primary" size="small">{props.ButtonLabel}</Button>
                            </div> : ''
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}
