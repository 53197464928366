// New Summary Dashboard

import { api } from "../../server/request";
import { handleError, handleResponse } from "../responseUtil";
import {  SET_CAPTIVE_DASHBOARD_SITES_SUBSCRIBERS_SESSIONS_TOP_WIDGET, GET_CAPTIVE_DASHBOARD_SITES_SUBSCRIBERS_SESSIONS_TOP_WIDGET, SET_CAPTIVE_DASHBOARD_PLANS_TOP_WIDGET, GET_CAPTIVE_DASHBOARD_PLANS_TOP_WIDGET, SET_CAPTIVE_DASHBOARD_USAGE_TOP_WIDGET, GET_CAPTIVE_DASHBOARD_USAGE_TOP_WIDGET,
    SET_CAPTIVE_DASHBOARD_TOP_SITES_USAGE_PIE, GET_CAPTIVE_DASHBOARD_TOP_SITES_USAGE_PIE, SET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_PIE, GET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_PIE, SET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LINE_CHART, GET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LINE_CHART,
    SET_CAPTIVE_DASHBOARD_SESSIONS_LINE_CHART, GET_CAPTIVE_DASHBOARD_SESSIONS_LINE_CHART, SET_CAPTIVE_DASHBOARD_USAGE_LINE_CHART, GET_CAPTIVE_DASHBOARD_USAGE_LINE_CHART, SET_CAPTIVE_DASHBOARD_SITES_TABLE, GET_CAPTIVE_DASHBOARD_SITES_TABLE, SET_CAPTIVE_DASHBOARD_TOP_SITES_SUBSCRIBERS_PIE,
    GET_CAPTIVE_DASHBOARD_TOP_SITES_SUBSCRIBERS_PIE, SET_CAPTIVE_DASHBOARD_TOP_SITES_SESSIONS_PIE, GET_CAPTIVE_DASHBOARD_TOP_SITES_SESSIONS_PIE, SET_CAPTIVE_DASHBOARD_TOP_SESSIONS_DURATION_PIE, GET_CAPTIVE_DASHBOARD_TOP_SESSIONS_DURATION_PIE
 } from "../types";

export const getClickhouseBucketReformat = (interval) => {
    switch (interval) {
        case "5m": return '5 minute';
        case "10m": return '10 minute';
        case "15m": return '15 minute';
        case "30m": return '30 minute';
        case "1h": return '1 hour';
        case "2h": return '2 hour';
        case "3h": return '3 hour';
        case "4h": return '4 hour';
        case "6h": return '6 hour';
        case "12h": return '12 hour';
        case "1d": return '1 day';
        case "7d": return '7 day';
        default: return '1 hour';
    }
}

export const getCaptiveDashSitesSubscribersSessionsTopWidget = (vessels, startDate, endDate) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_SITES_SUBSCRIBERS_SESSIONS_TOP_WIDGET, payload: true });
    const sitesSubscribersSessionsTopWidget: any = {
        query: 'CAPTIVE_DASHBOARD_SITES_SUBSCRIBERS_SESSIONS_TOP_WIDGET',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
        }
    }
    api
        .postRequestOut(`/store/ch/query`, sitesSubscribersSessionsTopWidget)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_SITES_SUBSCRIBERS_SESSIONS_TOP_WIDGET))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashPlansTopWidget = (vessels, startDate, endDate) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_PLANS_TOP_WIDGET, payload: true });
    const plansTopWidget: any = {
        query: 'CAPTIVE_DASHBOARD_PLANS_TOP_WIDGET',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
        }
    }
    api
        .postRequestOut(`/store/ch/query`, plansTopWidget)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_PLANS_TOP_WIDGET))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashUsageTopWidget = (vessels, startDate, endDate) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_USAGE_TOP_WIDGET, payload: true });
    const usageTopWidget: any = {
        query: 'CAPTIVE_DASHBOARD_USAGE_TOP_WIDGET',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,

        }
    }
    api
        .postRequestOut(`/store/ch/query`, usageTopWidget)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_USAGE_TOP_WIDGET))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashTopSitesUsagePie = (vessels, startDate, endDate, pieLimit) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_TOP_SITES_USAGE_PIE, payload: true });
    const topSitesPie: any = {
        query: 'CAPTIVE_DASHBOARD_TOP_SITES_USAGE_PIE',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            recLimit: pieLimit
        }
    }
    api
        .postRequestOut(`/store/ch/query`, topSitesPie)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_TOP_SITES_USAGE_PIE))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashTopSubscribersPie = (vessels, startDate, endDate, pieLimit) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_PIE, payload: true });
    const topSubscribersPie: any = {
        query: 'CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_PIE',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            recLimit: pieLimit
        }
    }
    api
        .postRequestOut(`/store/ch/query`, topSubscribersPie)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_PIE))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashSubscribersLineChart = (vessels, startDate, endDate, interval) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LINE_CHART, payload: true });
    const subscribersLine: any = {
        query: 'CAPTIVE_DASHBOARD_SUBSCRIBERS_LINE_CHART',
        named: true,
        format: "chart",
        groupBy: "type",
        series: "subscriberCount",
        tsColumn: "timeStamp",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            intervalNum: interval.split(' ')[0],
            interval: interval.split(' ')[1]
        }
    }
    api
        .postRequestOut(`/store/ch/query`, subscribersLine)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LINE_CHART))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashSessionsLineChart = (vessels, startDate, endDate, interval) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_SESSIONS_LINE_CHART, payload: true });
    const sessionsLine: any = {
        query: 'CAPTIVE_DASHBOARD_SESSIONS_LINE_CHART',
        named: true,
        format: "chart",
        groupBy: "type",
        series: "sessionCount",
        tsColumn: "timeStamp",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            intervalNum: interval.split(' ')[0],
            interval: interval.split(' ')[1]
        }
    }
    api
        .postRequestOut(`/store/ch/query`, sessionsLine)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_SESSIONS_LINE_CHART))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashUsageLineChart = (vessels, startDate, endDate, interval) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_USAGE_LINE_CHART, payload: true });
    const usageLine: any = {
        query: 'CAPTIVE_DASHBOARD_USAGE_LINE_CHART',
        named: true,
        format: "chart",
        groupBy: "type",
        series: "quotaUsed",
        tsColumn: "timeStamp",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            intervalNum: interval.split(' ')[0],
            interval: interval.split(' ')[1]
        }
    }
    api
        .postRequestOut(`/store/ch/query`, usageLine)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_USAGE_LINE_CHART))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashSitesTable = (vessels, startDate, endDate, page, limit, sortColumn, sortOrder, search) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_SITES_TABLE, payload: true });
    const sitesTable: any = {
        query: 'CAPTIVE_DASHBOARD_SITES_TABLE',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            search: search,
            sortColumn: sortColumn,
            sortOrder: sortOrder,
            pageOffset: ((page - 1) * limit),
            pageSize: limit
        }
    }
    api
        .postRequestOut(`/store/ch/query`, sitesTable)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_SITES_TABLE))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashTopSitesSubscribersPie = (vessels, startDate, endDate, pieLimit) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_TOP_SITES_SUBSCRIBERS_PIE, payload: true });
    const topSitesSubscribersPie: any = {
        query: 'CAPTIVE_DASHBOARD_TOP_SITES_SUBSCRIBERS_PIE',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            recLimit: pieLimit
        }
    }
    api
        .postRequestOut(`/store/ch/query`, topSitesSubscribersPie)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_TOP_SITES_SUBSCRIBERS_PIE))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashTopSitesSessionsPie = (vessels, startDate, endDate, pieLimit) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_TOP_SITES_SESSIONS_PIE, payload: true });
    const topSitesSessionsPie: any = {
        query: 'CAPTIVE_DASHBOARD_TOP_SITES_SESSIONS_PIE',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            recLimit: pieLimit
        }
    }
    api
        .postRequestOut(`/store/ch/query`, topSitesSessionsPie)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_TOP_SITES_SESSIONS_PIE))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashTopSessionsDurationPie = (vessels, startDate, endDate, pieLimit) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_TOP_SESSIONS_DURATION_PIE, payload: true });
    const topSessionsDurationPie: any = {
        query: 'CAPTIVE_DASHBOARD_TOP_SESSIONS_DURATION_PIE',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            recLimit: pieLimit
        }
    }
    api
        .postRequestOut(`/store/ch/query`, topSessionsDurationPie)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_TOP_SESSIONS_DURATION_PIE))
        .catch((err) => handleError(err, dispatch));
};