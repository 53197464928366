import { Grid, Switch, Tooltip } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "../ThreatDashboard.css"

const WanUsagePie = (props) => {
    const { title, toggleBtn, handleChangeToggle, wanOptions, usagePerWanType, handleWanTypeClick } = props;
    return (
        <Grid>
            <Grid className="title-toggle-btn-div">
                <Tooltip title={title=='Usage' ? 'Source : WAN Report' : null}><div className="title-text">{title}</div></Tooltip>
                {/* <Grid>
                    <span className="toggle-wan">{title=='Alerts' ? 'ALERT TYPE' : 'WAN'}</span>
                    <Switch
                        checked={toggleBtn}
                        onChange={handleChangeToggle}
                        inputProps={{ 'aria-label': 'controlled' }}
                        size="small"
                    />
                    <span className="toggle-site">SITE</span>
                </Grid> */}
            </Grid>
            <HighchartsReact
                highcharts={Highcharts}
                options={wanOptions}
            />
            <Grid container className={usagePerWanType?.length > 1 ? "usage-details-pie-perSite wan-type-pie" : 'usage-details-pie-perSite remove-margin-left'}>
                {usagePerWanType && usagePerWanType.map((usage, i) => (
                    <Grid item xs={12} sm={12} md={6} lg={6} className={usagePerWanType?.length == 1 ? 'single-value' : ''}>
                        <div className="summary-pie-usage-dot" style={{ background: usage?.color }}></div>
                        <span className={title!=='Alerts' ? "summary-usage-value selected-filter" : 'summary-usage-value'} onClick={() => title!=='Alerts' && handleWanTypeClick(usage)}>{usage.x}</span>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

export default WanUsagePie;