// Dialog Box for the filter action of Edge Events
import React, { useEffect, useState } from "react";
import {
    Grid,
    Button,
    Typography,
    Box,
    TextField,
    InputAdornment,
    Divider
} from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Dialog, Tooltip } from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import close from "../../asset/image/close.svg";
import { styled } from '@mui/material/styles';
import _ from "lodash";
import "./FilterEdgeEventsDialog.css"
import { getDecodeURI, getEncodedURI } from "../../utils/util";

const FilterEdgeEventsDialog = (props) => {
    const { onClose, open, edgeEventsFitler, onApplyFilter, selectedEventFilters, globalEvents, history, location, authReducer } = props;
    let _q = getDecodeURI(location?.search);
    const [allCheckboxStatus, setAllCheckboxStatus] = useState({});
    const [eventFilters, setEventFIlters] = useState<any>({});
    const [searchValue, setSearchValue] = useState(_q.hasOwnProperty('deviceId') ? _q.deviceId : '');
    const [selectedColumns, setSelectedColumns] = useState<Array<string>>([]);

    useEffect(() => {

        if (!_.isEmpty(edgeEventsFitler)) {
            const checkboxStatus = {};
            Object.keys(edgeEventsFitler).map((column) => {
                checkboxStatus[`${column}`] = {};
                return (
                    edgeEventsFitler[column].map((value, idx) => {
                        checkboxStatus[`${column}`][`${value}`] = (selectedEventFilters?.hasOwnProperty(column)) ? selectedEventFilters[column].includes(value) : false;
                    })
                )
            })

            setAllCheckboxStatus(checkboxStatus);

            if(_q.deviceId && _q.deviceId !== '') {
                const value = _q.deviceId;
                const deviceObj = {...edgeEventsFitler};
                const DeviceList = edgeEventsFitler?.devices?.filter((device) => ( device.toLowerCase().includes(value.toLowerCase()) ))
                DeviceList.length > 0 ? setEventFIlters({...deviceObj, ['devices'] : DeviceList}) : setEventFIlters({...deviceObj, ['devices'] : []});
            } else {
                setEventFIlters(edgeEventsFitler);
            }
        } else {
            setAllCheckboxStatus({});
            setEventFIlters({});
        }
    }, [edgeEventsFitler, selectedEventFilters, open]);

    useEffect(() => {
        if (_.isEmpty(edgeEventsFitler)) return
        const columnCounts = new Map(Object.entries<any>(edgeEventsFitler).map(([key, current]) => ([key, current.length])));
        const selectedColumnCounts = Object.entries<any>(allCheckboxStatus).map(([key, current]) => ({ key, length: Object.values(current).filter(val => val).length }))
        setSelectedColumns(selectedColumnCounts.filter(item => columnCounts.get(item.key) == item.length).map(item => item.key))
    }, [edgeEventsFitler, allCheckboxStatus])

    const changeCheckboxStatus = (column, value, status) => {
        const checkboxStatus = {};
        checkboxStatus[column] = {
            ...allCheckboxStatus[column]
        }
        
        checkboxStatus[column][value] = status;

        setAllCheckboxStatus((prevState) => {
            return {
                ...prevState,
                ...checkboxStatus
            }
        })
    }

    const handleAllSelect = (column: string) => {
        if (!_.isEmpty(edgeEventsFitler)) {
            const checkboxStatus = allCheckboxStatus;
            const currentIsSelected = !selectedColumns.includes(column);
            edgeEventsFitler[column].map((value, idx) => {
                checkboxStatus[`${column}`][`${value}`] = currentIsSelected;
            })
            setAllCheckboxStatus({ ...checkboxStatus });
        }
    }

    const handleClose = () => {
        handleClearAllClick();
        onClose();
    };

    const handleClearAllClick = () => {
        if (!_.isEmpty(edgeEventsFitler)) {
            const checkboxStatus = {};
            Object.keys(edgeEventsFitler).map((column) => {
                checkboxStatus[`${column}`] = {};
                return (
                    edgeEventsFitler[column].map((value, idx) => {
                        checkboxStatus[`${column}`][`${value}`] = false;
                    })
                )
            })
            let selectedEventFilters = {};
            for (let column in checkboxStatus) {
                selectedEventFilters[column] = [];
            }
            setAllCheckboxStatus(checkboxStatus);
            let params: any = getDecodeURI(location?.search);
            delete params.deviceId;
            params.filter = JSON.stringify(selectedEventFilters);
            doNavigate(params);
            setSearchValue('');
            setEventFIlters(edgeEventsFitler);
        }
    }


    const handleOkClick = () => {
        let selectedEventFilters = {};
        for (let column in allCheckboxStatus) {
            selectedEventFilters[column] = [];
            for (let value in allCheckboxStatus[column]) {
                if (allCheckboxStatus[column][value]) {
                    selectedEventFilters[column].push(value);
                }
            }
        }
        onApplyFilter(selectedEventFilters);
        onClose();
    }

    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow:
            theme.palette.mode === 'dark'
                ? '0 0 0 1px rgb(16 22 26 / 40%)'
                : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
            theme.palette.mode === 'dark'
                ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
                : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    });

    function BpCheckbox(props) {
        return (
            <Checkbox
                inputProps={{ 'aria-label': 'Checkbox demo' }}
                {...props}
            />
        );
    }

    const handleSearchDevices = (event) => {
        const value = event.target.value;
        const deviceObj = {...eventFilters};
        setSearchValue(value);
        let params: any = getDecodeURI(location?.search);
        value && value !== '' ? params.deviceId = value : delete params.deviceId;
        doNavigate(params);
        const DeviceList = edgeEventsFitler?.devices?.filter((device) => ( device.toLowerCase().includes(value.toLowerCase()) ))
        DeviceList.length > 0 ? setEventFIlters({...deviceObj, ['devices'] : DeviceList}) : setEventFIlters({...deviceObj, ['devices'] : []});
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    return (
        <div className="Form">
            <Dialog id={authReducer?.getDrawerOpen ? "pop-up1" : "pop-up2"} className="edge_events_more_filters_dialog menuScroll" open={open} style={{ display: "flex", justifyContent: "flex-start", left: "80px", top: "175px" }}>
                <DialogTitle className="dialog_title2">
                    <Grid className="filter-parent" container>
                        <Grid className="padding-10 filter-top">
                            
                            <Grid className="filter-head-parent">
                                <span className="filter-header">
                                    Event Filters
                                </span>
                                <img src={close} onClick={() => handleClose()} className="dialog_closeIcon-filters" alt="Close" />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Divider className="eventFilterBorderColor"/>
                        </Grid>
                        <Grid className="filter-body-container" item xs={12} sm={12} md={12} lg={12}>
                            {eventFilters ? Object.keys(eventFilters).map((column) => {
                                return (
                                    <div key={`column_${column}`}>
                                        <div className="edge_events_more_filters_dialog_status">
                                            <div className="edge_events_more_filters_dialog_status_title">{column}</div>
                                            { column == 'devices' && globalEvents ?
                                                <Grid item xs={2} sm={2} md={2} lg={2} className="events-search"> 
                                                    <Box
                                                    >
                                                    {<TextField
                                                        className='config-search-bar-text events-search-bar-text'
                                                        id="outlined-basic"
                                                        variant="outlined"
                                                        placeholder="Search"
                                                        size="small"
                                                        onChange={(event) => handleSearchDevices(event)}
                                                        value={searchValue}
                                                        InputLabelProps={{ className: "serachLabel" }}
                                                        InputProps={{ 
                                                            className: "serachBar events-search-bar"
                                                        }}
                                                    />}
                                                    </Box>
                                                    { eventFilters?.devices?.length == 0 ? <p className="no-device-text-events">no device found</p> : null}
                                                </Grid> : null
                                            }
                                            <Grid container className="events-gridflex" key={"All"}>
                                                <Grid item>
                                                    <BpCheckbox className="item-checkbox"
                                                        style={{ padding: '0px', display: 'grid' }}
                                                        checked={selectedColumns.includes(column)}
                                                        onClick={() => handleAllSelect(column)} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography className="typo filterTextWrap">All</Typography>
                                                </Grid>
                                            </Grid>
                                            {
                                                eventFilters[column].map((value, idx) => {
                                                    return (
                                                        <Grid container className="events-gridflex" key={`value_${value}`}>
                                                            <Grid item>
                                                                <BpCheckbox className="item-checkbox"
                                                                    style={{ padding: '0px', display:'grid' }}
                                                                    checked={(allCheckboxStatus && Object.keys(allCheckboxStatus).length > 0) ? allCheckboxStatus[`${column}`][`${value}`] : false}
                                                                    onClick={(event) => {
                                                                        changeCheckboxStatus(`${column}`, `${value}`, !allCheckboxStatus[`${column}`][`${value}`]);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <Tooltip title={value}>
                                                                    <Typography className="typo filterTextWrap">{value}</Typography>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </div>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Divider className="eventFilterBorderColor"/>
                                        </Grid>
                                    </div>
                                )
                            }) : <></>
                            }
                        </Grid>
                    </Grid>
                </DialogTitle>
                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                </Grid> */}
                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button onClick={() => handleOkClick()} >Ok</Button>
                </Grid> */}
                <Grid className="dash-filter-pop edge-events-filter-actions">
                  <span className="clearFiletrBtnDiv clear-edge-filter">
                    <Button className="clearFiletrBtnUsage margin-left-80" onClick={handleClearAllClick}>Clear Filter</Button>
                  </span>
                  <Button className="usage-applyFilterBtn" onClick={() => handleOkClick()} >Apply Filter</Button>
                </Grid>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {

    })(FilterEdgeEventsDialog)
);