import { Avatar, Chip, Stack, Tooltip } from "@mui/material";
import topUser from "../../../asset/image/topUserIcon.svg";
import vlanIcon from "../../../asset/image/vlanIcon.svg";

const FilterChips = (props) => {
    const { userName, deviceFilt, vlanFilt, handleFilterClick } = props;

    return (
        <Stack direction="row" spacing={1} alignItems='center'>
        {userName && <Tooltip title='User'><Chip label={userName} onDelete={() => handleFilterClick('', 'user')} avatar={<Avatar style={{width:'17px', height:'17px'}} src={`${topUser}`} />} /></Tooltip> }
        {deviceFilt && <Tooltip title='Device'><Chip label={deviceFilt} onDelete={() => handleFilterClick('', 'device')}/></Tooltip> }
        {vlanFilt && <Tooltip title='VLAN'><Chip label={vlanFilt} onDelete={() => handleFilterClick('', 'vlan')} avatar={<Avatar style={{width:'15px', height:'15px', padding:'3px'}} src={`${vlanIcon}`} />} /></Tooltip> }
    </Stack>
    )
}

export default FilterChips;