import React, { useState, useEffect, useRef } from "react";
import { Grid, Button, Paper, TextField, InputAdornment, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Chip, SelectChangeEvent, IconButton } from "@mui/material";
import { withRouter, useLocation, useHistory } from "react-router-dom";
import { Replay } from "@mui/icons-material";
import { connect, useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import Download from '../../asset/image/DownloadUsage.svg';
import { Pagination } from '../Pagination';
import _ from 'lodash';
import { getTraficPolicyApplyLogs, applyTrafficPolicy, applyFirewallRule, applyWanProfile } from "../../actions/Users/authenticateInventory";
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI } from "../../utils/util";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from '../../utils/constants';
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";
import DownloadCSV, { clearCsvDownload } from "../DownloadCSV";
import { DEFAULT_PAGE_SIZE } from "../../constants/Constants";
import CloseIcon from '@mui/icons-material/Close';
import JsonTooltip from "../JSONTooltip/JsonTooltip";
import "./MangeConfigLogs.css";
var Loader = require("react-loader");

const convertToCsv = (data: any[][], userTimezone: string) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        let status = row[17]
        if(!_.isEmpty(status)){
            if(!status.includes("|") && status.includes(":")){
                status = status.split(":")[1]
            }
        }
    
        csvRows.push(
            [
                row[16],
                row[6],
                row[0] === null ? 'Config Restore' : row[0],
                convertDateTimeIntoTimezone(row[4], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS),
                convertDateTimeIntoTimezone(row[20], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS),
                status
            ]
        );

    })
    let startTime = 'Start Time (' + userTimezone + ')';
    let completeTime = 'Complete Time (' + userTimezone + ')';

    return [['Site', 'Device', 'Action', startTime, completeTime, 'Status'], ...csvRows];
}

export const ManageConfigLogs = (props) => {
    const { authReducer, selectedSite, getTraficPolicyApplyLogs, applyTrafficPolicy, applyFirewallRule, applyWanProfile, downloadAsCSV } = props;
    const history = useHistory();
    const location = useLocation();
    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));
    
    const [searchText, setSearchText] = React.useState<string>("");
    const [paginationParams, setPaginationParams] = React.useState<{
        page: number;
        size: number;
        sortBy: 'site_name' | 'device_name' | 'status';
        sortOrder: 'asc' | 'desc';
        search: string;
    }>({
        page: 1,
        size: queryParams.get('size') ? Number(queryParams.get('size')) : DEFAULT_PAGE_SIZE,
        sortBy: queryParams.get('sortBy') as 'site_name' | 'device_name' | 'status' || 'created_at',
        sortOrder: queryParams.get('sortOrder') as 'asc' | 'desc' || 'desc',
        search: ""
    });
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();

    const [tableData, setTableData] = useState<any[]>([]);
    const [totalLogs, setTotalLogs] = useState<number>(0);
    const rowsOption = [10, 15, 25, 50, 100];


    const refresh = (_searchText: string | undefined = undefined) => {
        setTableData([]);

        const payload = {
            "query": "GET_TOPOLOGY_PAGE_CONFIG_MANAGER_LOGS",
            "named": true,
            "format": "json",
            "parameters": {
                "source_siteId": selectedSite,
                "search": paginationParams.search,
                "sort": paginationParams.sortBy,
                "order": paginationParams.sortOrder,
                "limit": paginationParams.size,
                "offset": (paginationParams.page - 1) * paginationParams.size
            }
        };

        getTraficPolicyApplyLogs(payload);
        
    }

    useEffect(() => {

        updateQueryParams();
        refresh();

    },[paginationParams])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getTraficPolicyApplyLogs)) {
            if (authReducer.getTraficPolicyApplyLogs?.data && authReducer.getTraficPolicyApplyLogs?.data.length > 0 && authReducer.getTraficPolicyApplyLogs?.data[0]?.quotas) {
                const data = authReducer.getTraficPolicyApplyLogs?.data[0]?.quotas;
                const totalCount = authReducer.getTraficPolicyApplyLogs?.data[0]?.total_count;
                
                setTableData(data?.map((ele) => {
                    return {
                        sourceSite: "_",
                        sourceDevice: "_",
                        targetSiteName: ele?.site_name,
                        targetDeviceName: ele?.device_name,
                        site_id: ele?.site_id,
                        device_id: ele?.device_id,
                        eos_version: ele?.eos_version,
                        startTime: ele?.created_at,
                        completeTime: ele?.updated_at,
                        action: `${ele?.action}`,
                        action_initiated_by: ele?.action_initiated_by || "",
                        status: ele?.status,
                        status_history: { "initiated_by": ele?.action_initiated_by || "",...ele?.status_history },
                        deviceCount: _.isEmpty(ele.device_id) ? 0 : ele.device_id.split(",").length,
                        edge_transaction_id: ele?.edge_transaction_id,
                        reason: ele?.reason
                    }
                }));
                setTotalLogs(totalCount);
            }
            authReducer.getTraficPolicyApplyLogs = {};
        }
    }, [authReducer.getTraficPolicyApplyLogs]);

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === "manage-config-log-status") {
                const [_columns, status] = authReducer.csvDataDownloded;

                if (status && status.length > 0) {
                    const rows = status[0];
                    if (!_.isEmpty(rows) && rows.length > 0) {
                        DownloadCSV(convertToCsv([Object.keys(rows[0]), ...rows.map(row => Object.values(row))], authReducer.userTimezone),
                            {
                                formatters: {
                                    0: 'ESC-COMMA',
                                    1: 'ESC-COMMA',
                                    3: 'ESC-COMMA',
                                    4: 'ESC-COMMA'
                                }
                            }
                        );
                    }
                }
                return () => {
                    dispatch(clearCsvDownload());
                }

            }
            }
        }, [authReducer.csvDataDownloded]);


    const updateQueryParams = () => {
        queryParams.set('page', paginationParams.page.toString())
        queryParams.set('size', paginationParams.size.toString())
        queryParams.set('sortBy', paginationParams.sortBy)
        queryParams.set('sortOrder', paginationParams.sortOrder)
        queryParams.set('search', paginationParams.search)
    }

    const handleSearch = (value: string) => {
        setPaginationParams({
            ...paginationParams,
            search: value,
            page: 1
        });
    };

    const handleChangePage = (event: React.SyntheticEvent<HTMLButtonElement> | null, _page: number) => {
        setPaginationParams({
            ...paginationParams,
            page: _page
        });
    };

    const handleChangeSize = (event: SelectChangeEvent<number>, child: React.ReactNode) => {
        setPaginationParams({
            ...paginationParams,
            size: event.target.value as number,
            page: 1
        });
    };

    const handleOnSearchClick = () => {
        handleSearch(searchText);
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }


    const handleOnSearchClear = () => {
        setSearchText('');
        handleSearch('');
    }

    const handleDownloadReport = () => {
        const params: CsvParameters = {
            type: "QUERY_PG",
            id: "manage-config-log-status",
            payload: { source_siteId: selectedSite, search: paginationParams.search, sort: paginationParams.sortBy, order: paginationParams.sortOrder, limit: totalLogs, offset: 0 },
            queryName: "GET_TOPOLOGY_PAGE_CONFIG_MANAGER_LOGS",
        };
        downloadAsCSV(params);
    }

    const handleRetry = async (row) => {
        
        if (row?.action === "Traffic policy") {
            const payload = {
                "deviceIds": [row["device_id"]],
                "sourceSiteId": row?.status_history?.source_siteId,
                "sourceDeviceId": row?.status_history?.source_deviceId,
                "sourceDeviceTrafficPolicyName": row?.status_history?.source_device_traffic_policy_name,
                "sourceDeviceTrafficPolicyType": row?.status_history?.source_device_traffic_policy_type
            };

            await applyTrafficPolicy(payload);
            refresh();   
        } else if (row?.action === "Firewall rules") {
            const payload = {
                "deviceIds": [row["device_id"]],
                "sourceSiteId": row?.status_history?.source_siteId,
                "sourceDeviceId": row?.status_history?.source_deviceId,
            };

            await applyFirewallRule(payload);
            refresh();
        } else if (row?.action === "WAN profile") {
            const payload = {
                "deviceIds": [row["device_id"]],
                "sourceSiteId": row?.status_history?.source_siteId,
                "sourceDeviceId": row?.status_history?.source_deviceId,
                "sourceDeviceWANprofileId": row?.status_history?.source_device_wan_profile_id,
            }
            await applyWanProfile(payload);
            refresh();
        }  
    }

    const getReasonTitle = (row) => {
        if(row.status) {
            if(row?.status.includes("failed") || row?.status.includes("Failed")) {
                if(row?.reason) {
                    return row?.reason;
                }
            }
        }
        return ""
    }


    return (
        <Paper elevation={0} className="quota_management--dashboard manage-config-log-head"> 
            <div className="manageConfiglogHeadName" >Logs</div>
            <Grid classes={{ root: "quota_management--base--flew quota_management--base--flex--justify--end quota_management--base--padding_05x" }}>
                <Grid item xs={12} sm={12} md={12} lg={12} className='logs-table-search-container topbarWidth alignTopbarItemsCenter'>
                    <Grid item xs={6} sm={6} md={6} lg={6} className="searchbarBox searchbarMaxWidth1">
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Type something"
                            value={searchText}
                            onChange={handleOnSearchChange}
                            onKeyDown={(e) => { if (e.key === 'Enter') { handleOnSearchClick() } }}
                            size="small"
                            InputLabelProps={{ className: "serachLabel" }}
                            InputProps={{
                                className: "searchbarInputs",
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {!searchText && <SearchIcon
                                            className="searchbarIconsPosition"
                                            onClick={handleOnSearchClick}
                                        />}
                                        {searchText && <CloseIcon
                                            className="searchbarIconsPosition"
                                            onClick={handleOnSearchClear}
                                        />
                                        }
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} className="dwn-margin-auto">
                        {totalLogs > 0 && tableData && tableData?.length !== 0 && <Grid item xs={6} sm={6} md={6} lg={6}>
                                <div className="download-summary-btn marg-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt=""/><Button>Download</Button> </div>
                        </Grid>}
                        {authReducer.inventoryListingLoading ? (
                            <div className="config-logs-loader">
                                <Loader radius={4} length={5} lines={10} width={2} color={"#264C86"} />
                            </div>
                        ) : null}
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersContainer">
                <Paper className="pagination-position config-pagination new-usage">
                    <Pagination count={totalLogs > 0 && tableData && 0 !== tableData?.length ? totalLogs : 0} rowsPerPageOptions={rowsOption} onPageChange={handleChangePage} page={paginationParams.page} rowsPerPage={paginationParams.size} onRowsPerPageChange={handleChangeSize} />
                </Paper>
                <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer manage-config-log-status">
                    <Table aria-label="simple sticky table" stickyHeader>
                        <TableHead className="tableHead alertsTable-tableHead">
                        <TableRow className="alertReports-tableRow">
                                <TableCell className="alertsTable-fleetDataTable font-wt-900 width-20-percent border-right-topology"> TARGET SITE</TableCell>
                                <TableCell className="alertsTable-fleetDataTable font-wt-900 width-20-percent border-right-topology"> TARGET DEVICE</TableCell>
                                <TableCell className="alertsTable-fleetDataTable font-wt-900 width-20-percent border-right-topology"> START TIME</TableCell>
                                <TableCell className="alertsTable-fleetDataTable font-wt-900 width-20-percent border-right-topology"> COMPLETE TIME</TableCell>
                                <TableCell className="alertsTable-fleetDataTable font-wt-900 width-20-percent border-right-topology"> ACTION</TableCell>
                                <TableCell className="alertsTable-fleetDataTable font-wt-900 width-20-percent border-right-topology"> INITIATED BY</TableCell>
                                <TableCell className="alertsTable-fleetDataTable font-wt-900 width-20-percent border-right-topology"> STATUS</TableCell>
                            </TableRow>
                        </TableHead>
                        {tableData && tableData?.length ? (
                            <TableBody className="tableBody alertsTable-tableBody">
                                {
                                    tableData?.map((row, index) =>
                                        <TableRow className="alertsData-tabelRow">
                                            <TableCell className="alertsTable-tableCell width-20-percent"> {row.targetSiteName} </TableCell>
                                            {
                                                <Tooltip title={<span className="font-size-12px">{row['targetDeviceName']}</span>}>
                                                    <TableCell className="alertsTable-tableCell width-20-percent">
                                                        {row?.targetDeviceName}
                                                    </TableCell>
                                                </Tooltip>
                                            }
                                            <TableCell className="alertsTable-tableCell width-20-percent"> {convertDateTimeIntoTimezone(row.startTime, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)} </TableCell>
                                            <TableCell className="alertsTable-tableCell width-20-percent"> {convertDateTimeIntoTimezone(row.completeTime, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)} </TableCell>
                                            <TableCell className="alertsTable-tableCell width-20-percent"> {row.action} </TableCell>
                                            <TableCell className="alertsTable-tableCell width-10-percent"> {row.action_initiated_by} </TableCell>
                                            <TableCell className="topology-tab-actions alertsTable-tableCell labelDetails width-12-percent">
                                                {
                                                    _.isEmpty(row.status)
                                                        ? "" : (getReasonTitle(row) ? <Tooltip title={getReasonTitle(row)}><span className="pointer">{row.status.length > 1 ? row.status.charAt(0).toUpperCase() + row.status.slice(1) : row.status.charAt(0)}</span></Tooltip> : <span>{row.status.length > 1 ? row.status.charAt(0).toUpperCase() + row.status.slice(1) : row.status.charAt(0)}</span>)
                                                        
                                                }
                                                {
                                                    row.deviceCount === 1 && !_.isEmpty(row.status) && row.status.toLowerCase().includes("failed") && !row.status.toLowerCase().includes("inprogess") ? <Tooltip title="Retry" key={`pmore-${Math.random()}`}>
                                                        <IconButton onClick={() => handleRetry(row)}>
                                                            <Replay />
                                                        </IconButton>
                                                    </Tooltip> : null
                                                }
                                                {
                                                   _.isEmpty(row.status_history) ? null: <JsonTooltip className="prettyToolTip" json={JSON.stringify(row.status_history)} /> 
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        ): (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={12} className="noDataAvailableCss" align="center">No data available</TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Grid>
        </Paper>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer
})

export default withRouter(
    connect(mapStateToProps, {getTraficPolicyApplyLogs, applyTrafficPolicy, applyFirewallRule, applyWanProfile, downloadAsCSV })(ManageConfigLogs)
);