import _ from "lodash";
import { api } from "../../server/request";
import { handleError, handleResponse } from "../../actions/responseUtil";
import { AccessNetwork, Action, Device, Quota, User, WanProfile } from "./types";

export const ACTION_TYPES = {
    GET_DEVICES: "quotaManagement/getDevices",
    GET_QUOTAS: "quotaManagement/getQuotas",
    GET_ACCESS_NETWORKS: "quotaManagement/getAccessNetworks",
    GET_WAN_PROFILES: "quotaManagement/getWanProfiles",
    GET_TRAFFIC_POLICIES: "quotaManagement/getTrafficPolicies",
    GET_SERVICE_LINE_FOR_EDGE: "quotaManagement/getServiceLineForEdge",
    CREATE_QUOTA: "quotaManagement/createQuota",
    DELETE_QUOTA: "quotaManagement/deleteQuota",
    UPDATE_QUOTA: "quotaManagement/updateQuota",
    TOGGLE_QUOTA: "quotaManagement/toggleQuota",
    SET_LOADING: "quotaManagement/setLoading",
}

const initialState = {
    devices: [],
    quotas: [],
    accessNetworks: [],
    wanProfiles: [],
    trafficPolicies: [],
    starlinkServiceLines: [],
    totalQuotas: 0,
    creating: false,
    deleting: false,
    updating: false,
    gettingCurrentUsage: false,
    gettingQuotas: false,
    gettingDevices: false,
    gettingAccessNetworks: false,
    gettingWanProfile: false,
    gettingTrafficPolicies: false,
    gettingServiceLineForEdge: false,
}

export function quotaManagementReducer(state: any = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.GET_DEVICES: {
            let interfaces = action?.payload?.data?.rows;
            let _devicesMap: Map<string, Device> = new Map();
            interfaces?.forEach(i => {
                if (_devicesMap.has(i[2]) === false) {
                    _devicesMap.set(i[2], {
                        id: i[2],
                        name: i[1],
                        site_id: i[0],
                        interfaces: [
                            {
                                alias: i[3],
                                wan_type: i[4],
                            }
                        ]
                    })
                } else {
                    let device = _devicesMap.get(i[2]);
                    if (device) {
                        device.interfaces.push({
                            alias: i[3],
                            wan_type: i[4],
                        })
                    }
                }
            });
            let _devices = Array.from(_devicesMap.values());
            return {
                ...state,
                devices: _devices
            }
        }
        case ACTION_TYPES.GET_QUOTAS: {
            let [quotas, totalCount] = action.payload?.data?.rows?.[0] || [[], 0]
            return {
                ...state,
                quotas: quotas?.map(q => {
                    q.actions = q.actions?.filter((a: Action) => a.id !== null);
                    return q;
                }) || [],
                totalQuotas: totalCount ? totalCount : 0
            }
        }
        case ACTION_TYPES.GET_ACCESS_NETWORKS: {
            let accessNetworks = action?.payload?.data;
            return {
                ...state,
                accessNetworks: accessNetworks?.map?.((an: any) => {
                    let _an: AccessNetwork = {
                        id: an.id,
                        name: an.name,
                        wan_profile: an.wan_profile,
                        vlan_aggregate: an.vlan_aggregate
                    }
                    return _an;
                }) || []
            }
        }
        case ACTION_TYPES.GET_WAN_PROFILES: {
            let wanProfiles = action?.payload?.data
            return {
                ...state,
                wanProfiles: wanProfiles?.map?.((wp: any) => {
                    let _wp: WanProfile = {
                        id: wp.id,
                        name: wp.name
                    }
                    return _wp;
                }) || []
            }
        }
        case ACTION_TYPES.GET_TRAFFIC_POLICIES: {
            let trafficPolicies = action?.payload?.data;
            return {
                ...state,
                trafficPolicies: trafficPolicies?.map?.((tp: any) => {
                    let _tp: WanProfile = {
                        id: tp.id,
                        name: tp.name
                    }
                    return _tp;
                }) || []
            }
        }
        case ACTION_TYPES.GET_SERVICE_LINE_FOR_EDGE: {
            let _service_lines = action?.payload?.data?.rows?.map?.((row: any) => {
                return {
                    service_line_number: row[0],
                    nickname: row[1]
                }
            });
            return {
                ...state,
                starlinkServiceLines: _service_lines || []
            }
        } 
        case ACTION_TYPES.UPDATE_QUOTA: {
            return {
                ...state,
                quotas: state.quotas.map((quota: Quota) => {
                    if (quota.id === action.payload.id) {
                        return action.payload;
                    }
                    return quota;
                })
            }
        }
        case ACTION_TYPES.DELETE_QUOTA: {
            return {
                ...state,
                quotas: state.quotas.filter((quota: Quota) => quota.id !== action.payload.id)
            }
        }
        case ACTION_TYPES.TOGGLE_QUOTA: {
            return {
                ...state,
                quotas: state.quotas.map((quota: Quota) => {
                    if (quota.id === action.payload.id) {
                        quota.disabled = action.payload.disabled
                    }
                    return quota;
                })
            }
        }
        case ACTION_TYPES.SET_LOADING: {
            return {
                ...state,
                ...action.payload
            }
        }
        default:
            return state
    }
}

interface getDevicesParameters {
    sites: string[];
}

export const getDevices = (parameters: getDevicesParameters) => (dispatch) => {
    if (_.isEmpty(parameters?.sites)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingDevices: true } });
    let _parameters: any = { ...parameters };
    _parameters.sites = _parameters?.sites.map(site => `'${site}'`).join(',');
    const data = {
        query: `SITES_DEVICES_INTERFACES`,
        named: true,
        format: 'csv',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_DEVICES)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingDevices: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingDevices: false } });
        });
}

export interface getQuotasParameters {
    sites: string[];
    search: string;
    sort: string;
    order: string;
    limit: number;
    offset: number;
}

export const getQuotas = (parameters: getQuotasParameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingQuotas: true } });
    let _parameters: any = { ...parameters };
    _parameters.sites = _parameters?.sites.map(site => `'${site}'`).join(',');
    const data = {
        query: `GET_QUOTAS`,
        named: true,
        format: 'csv',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_QUOTAS)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingQuotas: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingQuotas: false } });
        });
};

interface getCurrentUsageParameters {
    sites: string[];
    quota_ids: string[];
}

interface getAccessNetworksParameters {
    deviceId: string;
}

export const getAccessNetworks = (parameters: getAccessNetworksParameters) => (dispatch) => {
    const { deviceId } = parameters;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingAccessNetworks: true } });
    const data = {
        query: `GET_ACCESS_NETWORKS`,
        named: true,
        format: 'json',
        parameters: {
            device_id: deviceId
        }
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_ACCESS_NETWORKS)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingAccessNetworks: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingAccessNetworks: false } });
        });
}

interface getWanProfileParameters {
    deviceId: string;
}

export const getWanProfiles = (parameters: getWanProfileParameters) => (dispatch) => {
    const { deviceId } = parameters;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingWanProfile: true } });
    const data = {
        query: `GET_WAN_PROFILES`,
        named: true,
        format: 'json',
        parameters: {
            device_id: deviceId
        }
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_WAN_PROFILES)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingWanProfile: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingWanProfile: false } });
        });
}

interface getTrafficPoliciesParameters {
    deviceId: string;
}

export const getTrafficPolicies = (parameters: getTrafficPoliciesParameters) => (dispatch) => {
    const { deviceId } = parameters;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingTrafficPolicies: true } });
    const data = {
        query: "GET_TRAFFIC_POLICIES",
        named: true,
        format: 'json',
        parameters: {
            device_id: deviceId
        }
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_TRAFFIC_POLICIES)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingTrafficPolicies: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingTrafficPolicies: false } });
        });
}

interface createQuotaParameters {
    site: string;
    quota: Quota;
}

export const createQuota = (parameters: createQuotaParameters) => async (dispatch) => {
    const { quota } = parameters;
    const { site_id, device_id} = quota;

    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creating: true } });

    let URL = `/v1/sites/${site_id}/devices/${device_id}/quotas`;
    try {
        let res = await api.siteController.post(URL, quota);
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creating: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creating: false } });
        return null;
    }
};

export interface updateQuotaParameters {
    site_id: string;
    device_id: string;
    id: string;
    quota: {
        wan_links?: string[];
        access_networks?: string[];
        limit?: number;
        period?: number;
        period_unit?: string;
        start_time: string;
        actions?: Action[];
        notification_methods?: string[],
        users_to_notify?: User[];
        disabled?: boolean;
    };
}

export const updateQuota = (parameters: updateQuotaParameters) => async (dispatch) => {
    const { quota, id, site_id, device_id } = parameters;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updating: true } });

    let URL = `/v1/sites/${site_id}/devices/${device_id}/quotas/${id}`;
    try {
        let res = await api.siteController.put(URL, quota);
        dispatch({ type: ACTION_TYPES.UPDATE_QUOTA, payload: quota });
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updating: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updating: false } });
        return null;
    }
}

interface deleteQuotaParameters {
    quota: Quota;
}

export const deleteQuota = (parameters: deleteQuotaParameters) => async (dispatch) => {
    const { quota } = parameters;
    const { site_id, device_id, id } = quota;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { deleting: true } });

    let URL = `/v1/sites/${site_id}/devices/${device_id}/quotas/${id}`;
    try {
        let res = await api.siteController.delete(URL, { id: id });
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { deleting: false } });
        if (res?.success) {
            dispatch({ type: ACTION_TYPES.DELETE_QUOTA, payload: quota })
        }
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { deleting: false } });
        return null;
    }
};

interface toggleQuotaParameters {
    quota: Quota;
}

export const toggleQuota = (parameters: toggleQuotaParameters) => async (dispatch) => {
    const { quota } = parameters;
    const { site_id, device_id, id, disabled } = quota;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updating: true } });

    let URL = `/v1/sites/${site_id}/devices/${device_id}/quotas/${id}`;
    try {
        dispatch({ type: ACTION_TYPES.TOGGLE_QUOTA, payload: { id: id, disabled: !disabled } });
        let res = await api.siteController.put(URL, { disabled: !disabled });
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updating: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.TOGGLE_QUOTA, payload: { id: id, disabled: disabled } });
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updating: false } });
        return null;
    }
}

interface getServiceLineForEdgeParameters {
    site_id: string;
    device_id: string;
}

export const getServiceLineForEdge = (parameters: getServiceLineForEdgeParameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { getServiceLineForEdge: true } });
    const data = {
        query: "GET_SERVICE_LINE_FOR_EDGE",
        named: true,
        format: 'csv',
        parameters: parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_SERVICE_LINE_FOR_EDGE)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { getServiceLineForEdge: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { getServiceLineForEdge: false } });
        });
};

export const clearData = () => (dispatch) => {
    dispatch({
        type: ACTION_TYPES.GET_DEVICES, payload: {
            data: {
                rows: []
            }
        }
    });
    dispatch({
        type: ACTION_TYPES.GET_QUOTAS, payload: {
            data: {
                rows: [[null, null]]
            }
        }
    });
    dispatch({
        type: ACTION_TYPES.SET_LOADING, payload: {
            gettingDevices: false, gettingQuotas: false, gettingCurrentUsage: false,
            creating: false, deleting: false, updating: false
        }
    })
}

export const clearDevicePolicyData = () => (dispatch) => {
    dispatch({
        type: ACTION_TYPES.GET_ACCESS_NETWORKS, payload: {
            data: {
                rows: [[null]]
            }
        }
    });
    dispatch({
        type: ACTION_TYPES.GET_WAN_PROFILES, payload: {
            data: {
                rows: [[null]]
            }
        }
    });
    dispatch({
        type: ACTION_TYPES.GET_TRAFFIC_POLICIES, payload: {
            data: {
                rows: [[null]]
            }
        }
    });
    dispatch({
        type: ACTION_TYPES.GET_SERVICE_LINE_FOR_EDGE, payload: {
            data: {
                rows: []
            }
        }
    });
    dispatch({
        type: ACTION_TYPES.SET_LOADING, payload: {
            gettingAccessNetworks: false, gettingWanProfile: false, gettingTrafficPolicies: false
        }
    })
}