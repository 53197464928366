import { Grid } from '@mui/material';
import * as React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {useState} from "react"
import _ from 'lodash';
import AccrodionComp from './AccordionComponent';
import "./AccordionTree.css";
import { Alert } from '../../UserScreen/CreateAlert/types';

const AccordionTree = (props) => {

    const {alerts, siteId} : {
        siteId: string
        alerts: Alert[]
    } = props

    const openAccordianMenu = (e,alertId, ruleId)=>{
        props.openAccordianMenu(true,alertId,ruleId)
    }

    const handleCloseMenuOnMouseOut = () => {
        props.openAccordianMenu(false,"","")
    }
    React.useEffect(() => {
        // console.log('alerts changed', alerts)
    }, [alerts])

    return (
        <>
        <Grid item xs={12} lg={12} className="accordTree">
            {alerts?.map((alert, index) => {
                return <li>
                    <span>
                        <AccrodionComp className="item" key={index} siteId={siteId} alert={alert} openAccordianMenu={openAccordianMenu} setMenuAnchorElement={props.setMenuAnchorElement} handleCloseMenuOnMouseOut={handleCloseMenuOnMouseOut}></AccrodionComp>
                    </span>
                </li>
            })}
        </Grid>
        </>
    );
}


const mapStateToProps = (state) => ({
    alerts: state.alertsDashboard.alerts?.alerts
});

export default withRouter(
    connect(mapStateToProps, {

    })(AccordionTree)
);