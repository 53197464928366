import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import "./AccordianMenuComponent.css"

const AccordianMenuComponent = (props:any)=>{
    const [open, setOpen] = useState(props.open)

    useEffect(()=>{
        setOpen(props.open)
    },[props.open])

    const handleClose = ()=>{
      props.deleteAccordion()
      props.setOpenAccordianMenu(false)
    
    }

    const showStatusHistory = ()=>{
      props.setShowStatusHistory(true)
      props.setOpenAccordianMenu(false)
    }

    return (<div id="menu">
        <Menu
        anchorEl={props.menuAnchorElement}
        open={open}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={showStatusHistory}>Show Status History</MenuItem>
        <MenuItem onClick={handleClose}>Delete</MenuItem>
      </Menu>
    </div>)
}

export default AccordianMenuComponent