
export const starlinkAgreementServiceDesc = ` The Starlink Cloud Monitoring Service (the “Starlink Cloud Service” or the “Service”) offered by Kognitive Networks Inc. (“Kognitive Networks”, “we”, “us”, and “our”) delivers high-level metrics, aggregated performance details, and actionable analytics for multiple Starlink Service Lines (subscription plans, or “Lines”) and Starlink Kits (individual terminals, or “Kits”) purchased by users from Space Exploration Technology Corp. (“SpaceX”). Typical features of the Service include a Comprehensive Dashboard, Usage Summary, High Usage/Low-Availability Notifications/Alerts, and Administration Services. As an existing Distribution Partner or Reseller agreeing to these Terms, you are designated a “Primary Organization” that can establish and oversee other distributors and dealers within your channel as “Sub-Organizations”, that must also be existing Distribution Partners or Resellers of ours and that are each associated with individual Lines and Kits. Through the Service, a Primary Organization can share and re-license specific data access to Sub-Organizations and designated individuals and entities subscribing to the Service for their own use and not resale (“End Users”), streamlining both management of and access to Starlink data across their various sites. Individual sites may be those covered by a subscription to a Kognitive Networks’ KaaS plan with K4Edge SD-WAN hardware, that use competitive SD-WAN hardware, or that simply have direct Starlink installations without SD-WAN hardware.
Features of the Service are subject to change without advance notice. The availability and validity of metrics and historical data provided through the Service are contingent on the availability and validity of the data and other information provided by the Starlink installations, any associated APIs, and any permissions and actions of SpaceX, as well as the functionality of hardware, software and other services (including without limitation connectivity) not provided by Kognitive Networks. Kognitive Networks provides no guarantee to the availability, validity, quantity, or quality of data or other information collected directly by Starlink or otherwise obtained and provided by SpaceX or its partners or affiliates.
Further description is provided in the Starlink Cloud User Guide made available by Kognitive Networks from time to time.`

export const starlinkAgreementAppointment = ` Kognitive Networks hereby appoints you (“Reseller”, “you” “your”), as a Primary Organization and an authorized reseller of the Starlink Cloud Service, which consists of a software-as-a-service subscription license for the Service (“Subscription”) hosted by the Kognitive Service Portal that includes access to and use of the Kognitive Service Portal and Mobile App (the “App”).
This appointment is nonexclusive and worldwide. Under this appointment Reseller may purchase and resell the Starlink Cloud Service to End Users, directly or through subresellers or subdistributors as Sub-Organizations that themselves are distribution partners or resellers of Kognitive Networks under separate agreements, and under these same terms and conditions. You and your Sub-Organizations must also subscribe to the Service as End Users, in order to access and use the Service. You, as Reseller and a Primary Organization, are responsible for the acts and omissions of your Sub-Organizations.
The terms of your existing Distribution Partner or Reseller Agreement with us, including without limitation any flow-down terms applicable to end users, apply mutatis mutandis to your resale of the
Starlink Cloud Service, which is made available as a subscription service under the applicable license terms.` 

export const starlinkAgreementPricing = `1. PRICING. The Starlink Cloud Service is a subscription service subject to the pricing terms set forth in Schedule 1 hereto. As Reseller, you shall be free to establish the prices at which you and your Sub-Organizations resell the Starlink Cloud Service to End Users (your and their ultimate customers). All prices are stated in United States dollars and exclude taxes and other charges.`

export const starlinkAgreementPaymenAndInvoices = `2. PAYMENT AND INVOICES. All amounts due for the Starlink Cloud Service are invoiced 30 days after sign-up for service; payment terms are Net 30 date of invoice. In case of late payment, default interest at the rate of 2% for every month or part of a month will be charged from the due date. Setoff is not permitted. No refunds are available. Accepted payments methods are ACH and wire transfer.`

export const starlinkAgreementEarlyTermination = `3. EARLY TERMINATION. If you, a Sub-Organization or an End User cancels the Starlink Cloud Service Subscription prior to the end of the monthly subscription term, you and they will be charged for that month’s service.`

export const starlinkAgreementEntireAgreement = `4. ENTIRE AGREEMENT. This page and Schedule 1 that follows, all of which are incorporated herein by reference (together, this “Agreement”), together with the terms of our existing Distribution Partner or Reseller Agreement, set forth the entire understanding, and hereby supersede any and all prior agreements, oral or written, heretofore made between the Parties with respect to the Starlink Cloud Service, and there are no representations, warranties, covenants, agreements, or collateral understandings, oral or otherwise, express or implied, affecting this Agreement or our existing Distribution Partner or Reseller Agreement that are not expressly set forth herein. The terms of this Agreement and our existing Distribution Partner or Reseller Agreement expressly supersede the conflicting or additional terms of any purchase order or other ordering document of Reseller or any Sub-Organization, such terms being hereby rejected.`

export const starlinkAgreementEntireTerm = `5. TERM. This Agreement begins on your acceptance of it as indicated by your clicking on the ‘acceptance’ button shown on the webpage that references or displays these Terms. Either of us may terminate this Agreement upon thirty (30) days’ prior written or email notice. Termination of this Agreement will not terminate our existing Distribution Partner or Reseller Agreement.`

export const  starlinkAgreementTagline = `Upon the Minimum Initial Term (“MIT”) end date, all the plans automatically renew for the same Term Period as MIT (another term of same duration) unless terminated by End User in writing at least 30 days prior to the current term end date
The Subscription Fee is $10 per Month for each Starlink Service Line and Kit combination. Excluded from this monthly fee are any Starlink Kits that are managed by a K4Edge product, or if the Kit does not have a Service Line assigned.
For example, if a Subscriber has 100 Starlink Service Lines and 130 Starlink Kits, with 120 Kits in use and assigned to a Starlink Service Line, then the Subscriber will be charged 120*$10 = $1200/month for the Starlink Cloud Service. However, if 10 of those Starlink Kits are managed by a K4Edge server, then that monthly fee of $1200 is reduced by 10*$10, or $100.
Additional Reseller discounts, if available, can be applied and completed at the time of invoice. Please contact Kognitive Sales team for more details.`

export const  starlinkWelcomeText = `The Starlink Cloud service provides Starlink Service Line and Kit information by accessing the Starlink cloud directly, and thus does not require local/direct access to the Starlink terminal. Further, if one has a K4Edge Server on site with the Starlink Terminal, then the Service Portal will provide both Terminal and Cloud data as a part of the KAAS Subscription.`