import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AnyIfEmpty, connect } from "react-redux";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import {
    BC_CHANGE_PASSWORD, LOGGED_IN_EMAIL, LOGGED_IN_UPDATE_EMAIL,
    LOGGED_IN_USER, PERMISSIONS, USER_OU, REFRESH_TOKEN,
    IAM_USER_POLICY_ALL_RESOURCES_KEY, CAPTIVE_ACCESS_ENABLED, MAINTENANCE
} from "../../constants/Constants";
import {
    Grid, CircularProgress, Button, Typography as MyTypography, InputAdornment, IconButton
} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputLabel from '@mui/material/InputLabel';
import Box from "@mui/material/Box";
import { Base64 } from "js-base64";
import { changePassword, logoutUser, restState } from "../../actions/Users/authenticateLogin";
import { useHistory, useLocation} from "react-router-dom";
import DialogBox from "../../components/DialogBox";
import _ from "lodash";
import DialogBoxError from "../../components/DialogBoxError"
import './changePassword.css';
import { removeDataFromLocalStorage, getDataFromLocalStorage } from "../../utils/util";
import { getDecodeURI, getEncodedURI } from "../../utils/util";
import CustomPasswordValidator from "../../components/CustomPasswordValidator";
import CustomAlert from "../../components/CustomAlert";

const ChangePassword = (props) => {
    const { changePassword, authReducer, errorReducer, restState } = props;
    const [isSuccess, setSuccess] = useState(false);
    const [isFailed, setFailed] = useState(false);
    const [errorDialogBody, setErrorDialogBody] = useState("")
    const [alertMsg, setAlertMsg] = useState({ msg: "Please enter all the mandatory fields", type: "error" });
    const [showAlert, setShowAlert] = useState(false);
    const location = useLocation();

    let history = useHistory();
    
    useEffect(() => {
        setTimeout(() => {
        setShowAlert(false);
        }, 15000);
    }, [showAlert]);

    useEffect(() => {
        if (!_.isEmpty(authReducer.changePassword)) {
            if (authReducer?.changePassword?.status == 1) {
                setSuccess(true);
                setTimeout(() => {
                    let loggedInUser = getDataFromLocalStorage(LOGGED_IN_USER);
                    let logoutPayload = {
                        idToken: JSON.parse("" + loggedInUser).id_token,
                        redirectUrl: "/login",
                        applicationName: "Sport"
                    }

                    logoutUser(logoutPayload);
                    removeDataFromLocalStorage(LOGGED_IN_EMAIL);
                    removeDataFromLocalStorage(LOGGED_IN_UPDATE_EMAIL);
                    removeDataFromLocalStorage(LOGGED_IN_USER);
                    removeDataFromLocalStorage(REFRESH_TOKEN);
                    removeDataFromLocalStorage(PERMISSIONS);
                    removeDataFromLocalStorage(USER_OU);
                    removeDataFromLocalStorage(IAM_USER_POLICY_ALL_RESOURCES_KEY);
                    removeDataFromLocalStorage("isAccountsEnabled");
                    removeDataFromLocalStorage(CAPTIVE_ACCESS_ENABLED)
                    removeDataFromLocalStorage(MAINTENANCE)

                    // This will clear everything of this app
                    // localStorage.clear();
                    setSuccess(false);
                    history.push('/login');
                    restState();


                }, 5000);
            } else {
                setErrorDialogBody(authReducer?.changePassword?.message)
                setFailed(true);
                setTimeout(() => {
                    setFailed(false);
                }, 3000);
            }
        }
    }, [authReducer.changePassword])

    useEffect(() => {
        if (errorReducer && errorReducer.message && errorReducer.message !== "") {
            if (errorReducer.status === 0) {
                if (errorReducer.message.includes("INTERNA")) {
                    setErrorDialogBody(errorReducer.message)
                    setFailed(true);
                    setTimeout(() => {
                        setFailed(false);
                    }, 3000)
                }
            }
        } else if (errorReducer && errorReducer?.error?.status == 0 && errorReducer?.error?.message == "Either user name or Password is Incorrect"){
            setErrorDialogBody("Current password is incorrect")
            setFailed(true);
            setTimeout(() => {
                setFailed(false);
            }, 3000)
        } else if (errorReducer && errorReducer?.error?.status == 0){
            setErrorDialogBody(errorReducer?.error?.message)
            setFailed(true);
            setTimeout(() => {
                setFailed(false);
            }, 3000)
        }
    }, [errorReducer]);

    const [passWordList, setPasswordList] = useState<any>({ "CURRENT_PASSWORD": "", "NEW_PASSWORD": "", "CONFIRM_PASSWORD": "" });
    const [passwordVisibility, setPasswordVisibility] = useState<any>({ "CURRENT_PASSWORD": false, "NEW_PASSWORD": false, "CONFIRM_PASSWORD": false });
    const [isValidDetails, setIsValidDetails] = useState(true);
    const [validatedDetails, setValidatedDetails] = useState({});

    const breadcrumbInfo = BC_CHANGE_PASSWORD;
    const handleClickShowPassword = (itemKey) => {
        let PassWordVisibilityList = { ...passwordVisibility };
        PassWordVisibilityList[itemKey] = !PassWordVisibilityList[itemKey];
        setPasswordVisibility(PassWordVisibilityList);
    };

    const handleChange = (event: any, itemKey: any) => {
        let PasswordList = { ...passWordList };
        PasswordList[itemKey] = event.target.value;
        setPasswordList(PasswordList);

        if (!event.target.value) {
            setIsValidDetails(false)
        }
    }

    const handleChangePassword = () => {
        let _userName = getDataFromLocalStorage(LOGGED_IN_EMAIL);
        if (isValidDetails && (passWordList["CURRENT_PASSWORD"] !== "") && (passWordList["NEW_PASSWORD"] === passWordList["CONFIRM_PASSWORD"]) && (_userName !== "" && _userName !== undefined)) {
            let payload = {
                userName: _userName,
                currentPassword: Base64.encode(passWordList["CURRENT_PASSWORD"]),
                newPassword: Base64.encode(passWordList["NEW_PASSWORD"])
            };
            setValidatedDetails(passWordList);
            // api call
            changePassword(payload);

        } else {
            setShowAlert(true)
        }
    }

    const doNavigateToDashboard = (path) => {
        let params: any = getDecodeURI(location?.search);
        history.push({ pathname: path, search: `?${getEncodedURI(params)}` });
    }
    
    const handleCancel = () => {
        setIsValidDetails(true);
        setPasswordList({ "CURRENT_PASSWORD": "", "NEW_PASSWORD": "", "CONFIRM_PASSWORD": "" });
        setValidatedDetails({});
        doNavigateToDashboard(`/fleet-dashboard/sites/`);
    }


    const handlePasswordValidation = (result) => {
        setIsValidDetails(result);
    }

    return (
        <div className="Form">
            {/* <BreadCrumbs breadcrumbinfo={breadcrumbInfo}></BreadCrumbs> */}
            <Grid item container style={{ backgroundColor: "#fff" }}>
                <Grid item style={{ paddingTop: "88px", width: "100%" }} className="changePasswordPage">
                    {showAlert && showAlert === true ? (<CustomAlert type={alertMsg.type} msg={alertMsg.msg} />) : null}
                    <Grid item container lg={12} className="changePasswordTitle" > Change Password</Grid>
                    <Grid item container lg={5} className="changePasswordContainer" >
                        {Object.keys(passWordList).map((item: any, index: any) => {
                            return <Box className="passWordFiled">
                                <InputLabel className="fieldLabel">{item.replace('_', ' ')}</InputLabel>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    type={passwordVisibility[item] ? "text" : "password"}
                                    value={passWordList[item]}
                                    name="password"
                                    onChange={(e) => handleChange(e, item)}
                                    InputProps={{
                                        className: "labelTextbox",
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => handleClickShowPassword(item)}
                                                >
                                                    {passwordVisibility[item] ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                        })
                        }

                        <Grid lg={12} style={{marginBottom: "1em"}}>
                            <CustomPasswordValidator newPassword={passWordList["NEW_PASSWORD"]} reEnterPassword={passWordList["CONFIRM_PASSWORD"]} onValidatorChangeHandler={handlePasswordValidation} />
                        </Grid>
                        <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button className="onChangeCancel" onClick={handleCancel}>CANCEL</Button>
                            <Button className="changePassButtons" disabled={!isValidDetails} onClick={handleChangePassword}>CHANGE PASSWORD</Button>
                        </Grid>
                        {isSuccess && <DialogBox
                            Open={true}
                            // onClose={() => setShowPopUp(false)}
                            DialogTitle="Password Updated "
                            DialogBody="Redirecting to login..."
                            ButtonLabel="Done"
                        />}
                        {isFailed && <DialogBoxError
                            Open={true}
                            // onClose={() => setShowPopUp(false)}
                            DialogTitle="Change Password Error"
                            DialogBody={errorDialogBody}
                            ButtonLabel="Close"
                        />}

                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});


export default withRouter(
    connect(mapStateToProps, { changePassword, restState })(ChangePassword)
);