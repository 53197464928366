import { api } from "../../server/request";
import { handleError, handleResponseObj } from "../responseUtil";
import { AUDIT_LOGS, SET_LOADING } from "../types";

export interface AuditPayload {
    locationId: string;
    startTime: string;
    endTime: string;
    filters: {
        user: string,
        resource: string,
        action: string,
        label: string
    }
    page: number;
    limit: number;
}

export const auditLogs = (payload: AuditPayload) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    api
        .postRequestNormal(`/client/v1/sites/audit_logs`, payload)
        .then((res) => handleResponseObj(res, dispatch, AUDIT_LOGS))
        .catch((err) => handleError(err, dispatch));
};