import { withRouter} from "react-router-dom";
import { connect } from "react-redux";
import { fetchUserListAPI, parentDpAndSubDpList, updatePermission, updateSideMenu } from "../../actions/Users/authenticate";

import { ContentFilterPageProps } from './types';
import { Grid } from '@mui/material';

import "./index.css";
import { Fragment, useEffect, useState } from "react";
import { SIDE_MENU_ITEM_CONTENT_FILTER } from "../../utils/constants";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { checkLoggedInUserAuthorizedToViewPage, populateForParent } from "../../utils/util";
import NotAuthorized from "../NotAuthorized";
import UserGuideDoc from '../../asset/user_guide/Starlink_Cloud_User_Guide.pdf';


import _ from "lodash";
import { setDpIds } from "./slice";
import CommonFilters from "../../components/BreadCrumbs/CommonFilters";
import ContentFilterTable from "../../components/ContentFilter/ContentFilterTable";


function CustomContentFilter(props: ContentFilterPageProps) {
    const { authReducer, errorReducer, match, location, history, customContentFilter, parentDpAndSubDpList, updatePermission, updateSideMenu, setDpIds, fetchUserListAPI } = props;
    const [loading, setLoading] = useState(false);
    const [isAuthorizedToViewPage, setIsAuthorizedToViewPage] = useState<boolean>(false);
    const not_authorized_page_title = "Custom Content Filter";
    const not_authorized_page_message = "You are not authorised to view Custom Content Filter";

    useEffect(() => {

        if ((authReducer && !authReducer.sideMenu) || (SIDE_MENU_ITEM_CONTENT_FILTER !== authReducer.sideMenu.menuItem)) {
            updateSideMenu({ menuItem: SIDE_MENU_ITEM_CONTENT_FILTER });
        }
    }, [])

    useEffect(() => {
        const service = SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["service"];
        const func = SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["function"];
        let authorized = checkLoggedInUserAuthorizedToViewPage(service, func);
        setIsAuthorizedToViewPage(authorized);
        if (authorized) {
            if (authReducer && authReducer.selectedOu) {
                const info = {
                    permission: {
                        service: service,
                        serviceFunction: func,
                    },
                    isMultiVessel: false,
                    isSingleVessel: false,
                    showOu: true,
                    ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
                };
                updatePermission(info);
            }
        }
    }, [authReducer.selectedOu])

    const helpAction = () => {
        window.open(UserGuideDoc)
    }

    useEffect(() => {
        setLoading(
            authReducer.loading || customContentFilter.gettingContentFilters || customContentFilter.creatingContentFilter || customContentFilter.updatingContentFilter || customContentFilter.deletingContentFilter
        )
    }, [authReducer.loading, customContentFilter.gettingContentFilters, customContentFilter.creatingContentFilter, customContentFilter.updatingContentFilter, customContentFilter.deletingContentFilter])

    return (
        <Grid>
            <CommonFilters loading={loading} showSummaryPeriod={false} showWantType={false} helpIcon={false} helpAction={helpAction} ></CommonFilters>
            {
                isAuthorizedToViewPage ? <Fragment>
                    <Grid>
                        <Grid>
                            <ContentFilterTable />
                        </Grid>
                    </Grid>
                </Fragment>
                    : <NotAuthorized pageTitle={not_authorized_page_title} PageMessage={not_authorized_page_message} />
            }
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    customContentFilter: state.customContentFilter,
});

export default withRouter(
    connect(mapStateToProps, {
        parentDpAndSubDpList,
        updatePermission,
        updateSideMenu,
        setDpIds,
        fetchUserListAPI,
    })(CustomContentFilter)
);