import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Avatar, Chip, Stack, Tooltip } from "@mui/material";
import topSite from "../../asset/image/topSiteIcon.svg";

const FilterChips = (props) => {
    const { filteredVessels, handleSiteClick } = props;
    const vessels = filteredVessels;

    const handleSiteDelete = () => {
        handleSiteClick('')
    }

    return (
        <div className="new-summary-filter-chips">
            <Stack direction="row" spacing={1} alignItems='center'>
                {/* { vessels?.length == 1 &&  <Tooltip title={`Site : ${vessels?.[0]?.['vessel-name']}`}><Chip label={vessels?.[0]?.['vessel-name']} onDelete={handleSiteDelete} avatar={<Avatar style={{width:'17px', height:'17px'}} src={`${topSite}`} />} /></Tooltip> } */}
            </Stack>
        </div>
    )
}

const mapStateToProps = (state) => ({
    filteredVessels: state?.authReducer?.filteredVessels,
    errorReducer: state.errorReducer
});

export default withRouter(
  connect(mapStateToProps, { })(FilterChips)
);