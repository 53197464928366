import { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Divider, Button, DialogActions, DialogContent, Grid, TextField, Typography, Switch, Box, Tabs, Tab } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { compare } from "compare-versions";
import "./ManageConfig.css"
import _ from "lodash";
import { getTrafficPolicyData, getGroupList, getDeviceList, getWANProfileList, getPopIpsList, applyTrafficPolicy, applyFirewallRule, applyWanProfile, applyHubIPConfig, getTraficPolicyApplyLogs, copyQuotaConfig } from "../../actions/Users/authenticateInventory";
import { populateForParent } from "../../utils/util"
import { parentDpAndSubDpList } from "../../actions/Users/authenticate";
import { getDecodeURI } from '../../utils/util';
import { DEFAULT_PAGE_SIZE } from "../../constants/Constants";
import { CONFIRM_DIALOG_TITLE } from "../../config";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const Traffic_Policy = "Traffic Policy"
export const Firewall = "Firewall"
export const WAN_Profile = "WAN Profile"
export const Hub_IP = "Hub IP *"
export const Quota = "Quota *"
export const Hub_IP_Configure = "Configure"
export const Hub_IP_Copy = "Copy"
export const System_Configuration = "System"
export const Organization_Configuration = "Organization"
export const Delete_Site = 'Delete Site'
const Hub_IP_Action_Add = "Add"
const Hub_IP_Action_Replace = "Replace"

export const ManageConfig = (props) => {
    const { authReducer, getTrafficPolicyData, getWANProfileList, getPopIpsList, parentDpAndSubDpList,  getDeviceList, getGroupList, applyTrafficPolicy, applyFirewallRule, applyWanProfile, applyHubIPConfig, getTraficPolicyApplyLogs, selectedDevice, onClose, setConfirmDialog, selectedManageConfigType, selectedManageConfigHubIPType, seletedConfigType, copyQuotaConfig } = props;
    const [trafficPolicy, setTrafficPolicy] = useState<any[]>([]);
    const [groupList, setGroupList] = useState<any[]>([]);
    const [siteList, setSiteList] = useState<any[]>([]);
    const [deviceList, setDeviceList] = useState<any[]>([]);
    const [configTypeOptions, setConfigTypeOptions] = useState<any[]>([]);
    const [configType, setConfigType] = useState(seletedConfigType);
    const [targetType, setTargetType] = useState({label: ""});
    const [siteFilterValue, setSiteFilterValue] = useState("");
    const [deviceFilterValue, setDeviceFilterValue] = useState("");
    const [managedGroupFilterValue, setManagedGroupFilterValue] = useState("");
    const [selectedTrafficPolicy, setSelectedTrafficPolicy] = useState({ group: "", name: "", type: "", id: "" });
    const [selectedSites, setSelectedSites] = useState<any[]>([]);
    const [selectedDevices, setSelectedDevices] = useState<any[]>([]);
    const [selectedManagedGroups, setSelectedManagedGroups] = useState<any[]>([]);
    const [wanProfileOptions, setWanProfileOptions] = useState<any[]>([]);
    const [selectedWanProfile, setSelectedWanProfile] = useState({ id: "", name: "" });
    const [hubIPConfigType, setHubIPConfigType] = useState<string>(Hub_IP_Configure);
    const [hubIPAction, setHubIPAction] = useState<string>(Hub_IP_Action_Add);
    const [isServingHub, setIsServingHub] = useState<boolean>(true);
    const [hubIPOptions, setHubIPOptions] = useState<any[]>([]);
    const [servingHubOptions, setServingHubOptions] = useState<any[]>([]);
    const [sourceDeviceServingHubName, setSourceDeviceServingHubName] = useState<string>("");

    const [selectedHubIPs, setSelectedHubIPs] = useState<any[]>([]);
    const [selectedServingHub, setSelectedServingHub] = useState({id: "", name: ""});
    const [configuredHubName, setConfiguredHubName] = useState<string>("");
    const [configuredHubPorts, setConfiguredHubPorts] = useState<string>("");
    const [configuredHubIsNotValidMessage, setConfiguredHubIsNotValidMessage] = useState("");
    const [hubNameFilterValue, setHubNameFilterValue] = useState("");
    
    const [deviceKonnectStatus, setDeviceKonnectStatus] = useState(selectedDevice.konnectStatus)
    const KONNECT_STATUS_CONNECTED = "STATUS_CONNECTED"

    // URL query params
    const location = useLocation()
    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));

    const [paginationParams, setPaginationParams] = useState<{
        page: number;
        size: number;
        sortBy: string;
        sortOrder: string;
        search: string;
    }>({
        page: queryParams.get('page') ? Number(queryParams.get('page')) : 1,
        size: queryParams.get('size') ? Number(queryParams.get('size')) : DEFAULT_PAGE_SIZE,
        sortBy: queryParams.get('sortBy') || 'created_at',
        sortOrder: queryParams.get('sortOrder') || 'desc',
        search: queryParams.get('search') || ''
    });

    const getDPTree = (ous: any[], arr) => {
        if (ous && Array.isArray(ous)) {
            for (let i = 0; i < ous?.length; i++) {
                const item = ous[i];
                if (arr.hasOwnProperty(item.id)) {
                    const temp: any = arr[item.id];
                    arr[item.id] = { ...temp, ...item._childern };
                } else {
                    arr[item.id] = item._childern;
                }
                getDPTree(item._childern, arr);
            }
        }
    }
    const getDpList = (dpList) => {
        const tempArr: any = {};
        for (let i = 0; i < dpList.length; i++) {
            const _organisations = [];
            populateForParent(dpList[i], null, _organisations);
            getDPTree(_organisations, tempArr);
        }
        parentDpAndSubDpList(tempArr);
    }

    useEffect(() => {
        if (!_.isEmpty(authReducer.getOrganisationUnitList)) {
            if (authReducer.getOrganisationUnitList.dps && authReducer.getOrganisationUnitList.dps.length > 0) {
                getDpList(authReducer.getOrganisationUnitList.dps)
            }
        }
    }, [authReducer.getOrganisationUnitList])

    const getParentAndSubDpIds = () => {
        const _dpIds: any = [];
        if (!_.isEmpty(authReducer.selectedOu) && authReducer.selectedOu.hasOwnProperty("id") && authReducer.selectedOu.id && authReducer.selectedOu.hasOwnProperty("name") && authReducer.selectedOu.name) {
            const parentDpId = authReducer?.selectedOu?.id
            const dpIds = authReducer?.parentDpAndSubDpList
            if (!_.isEmpty(authReducer.selectedOu) && authReducer.selectedOu.hasOwnProperty("id") && authReducer.selectedOu.id && authReducer.selectedOu.hasOwnProperty("name") && authReducer.selectedOu.name && !_.isEmpty(authReducer.parentDpAndSubDpList)) {
            const parentDpId = authReducer?.selectedOu?.id
            const dpIds = authReducer?.parentDpAndSubDpList
            if (dpIds.hasOwnProperty(parentDpId)) {
                _dpIds.push(parentDpId)
                getChildren(_dpIds, dpIds[parentDpId])
            }
        }
            _dpIds.push(parentDpId)
        }
        return _dpIds
    }

    const getChildren = (dpIds, dps) => {
        for (let i = 0; i < dps?.length; i++) {
            let id = dps[i]?.id;
            dpIds.push(id);
            if (dps[i].hasOwnProperty('_childern') && dps[i]['_childern']?.length > 0) {
                getChildren(dpIds, dps[i]['_childern'])
            }
        }
    }


    useEffect(() => {
        //set config type options
        let options = [{ value: Traffic_Policy, label: Traffic_Policy }];
        if (!_.isEmpty(selectedDevice["current_EOS_version"])) {
            if (compare(selectedDevice["current_EOS_version"], "6.5.4", ">=")) {
                options.push({ value: Firewall, label: Firewall })
                options.push({ value:WAN_Profile, label:WAN_Profile });
            }
            if(compare(selectedDevice["current_EOS_version"], "6.5.5", ">=")) {
                options.push({ value: Hub_IP, label: Hub_IP });
            }
        }
        setConfigTypeOptions(options);

        if (!_.isEmpty(authReducer.getVesselsListing)) {
            getTrafficPolicyData({ query: "GET_TRAFFIC_POLICIES_V2", "named": true, "format": "json", "parameters": { "device_id": selectedDevice?.id } });

            let dpIds = getParentAndSubDpIds();
            let _dpIds = dpIds.map(x => "'" + x + "'").toString();
            const payloads = {
                "query": "GET_INVENTORY_GROUPS",
                "named": true,
                "format": "json",
                "parameters": {
                    "dp": _dpIds,
                    "search": "",
                    "page_start": 0,
                    "page_size": 100,
                    "sort_by": "update_time",
                    "sort_order": "desc"
                }
            };

            getGroupList(payloads);

            let ouName = authReducer.selectedOu?.name;
            getDeviceList(ouName);
        }
    }, [authReducer.getVesselsListing])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getTrafficPolicy)) {
            const data = authReducer.getTrafficPolicy?.data;
            setTrafficPolicy(data?.map((ele) => {
                
                return {
                    group: ele.type === "vlan" ? "Network Traffic Policy" : "Device Traffic Policy",
                    name: ele.name,
                    type: ele.type,
                    id: ele.id
                }
            }));
            authReducer.getTrafficPolicy = {};
        }
    }, [authReducer.getTrafficPolicy])
    

    useEffect(() => {
        if (!_.isEmpty(authReducer.getInventoryGroupList)) {
            const data = authReducer.getInventoryGroupList?.data;
            setGroupList(data?.map((ele) => {
                return {
                    name: ele.name,
                    id: ele.id
                };
            }))
        }

    }, [authReducer.getInventoryGroupList]);
    
    useEffect(() => {
        if (!_.isEmpty(authReducer.getDeviceList)) {
            const data = authReducer.getDeviceList?.data;

            const siteListOptions: { name: string, id: number }[] = [];
            const deviceListOptions: { name: string, id: number }[] = [];
            data.forEach((ele) => {
                siteListOptions.push({
                    name: ele.location_name,
                    id: ele.location_id
                });
                ele?.devices.forEach((eleDevice) => {
                    if (selectedDevice?.id !== eleDevice?.id) {
                        deviceListOptions.push({
                            name: eleDevice.name,
                            id: eleDevice.id
                        });
                    }
                });
            })
            setSiteList(siteListOptions);
            setDeviceList(deviceListOptions);
        }

    }, [authReducer.getDeviceList]);

    useEffect(() => {
        if (!_.isEmpty(authReducer.getWANProfileList) && authReducer.getWANProfileList?.data && Array.isArray(authReducer.getWANProfileList?.data) ) {
            const data = authReducer.getWANProfileList?.data;

            setWanProfileOptions(data?.map((ele) => {
                return {
                    name: ele.name,
                    id: ele.id
                }
            }))
        }
    }, [authReducer.getWANProfileList])

    useEffect(() => {
        if(!_.isEmpty(authReducer.getPopIpsList) && authReducer.getPopIpsList?.data && Array.isArray(authReducer.getPopIpsList?.data)) {
            const data = authReducer.getPopIpsList?.data;
            let hubSelectionList : any[] = []
            data?.map((ele) => {
                // if (!ele?.k4) {
                    hubSelectionList.push({
                        name: ele?.name,
                        id: ele?.id
                    })
                // }
                if (ele.serving_hub) {
                    setSourceDeviceServingHubName(ele?.name)
                }
            })
            setHubIPOptions(hubSelectionList)
            setServingHubOptions(data?.map((ele) => {
                return {
                    name: ele?.name,
                    id: ele?.id
                }
            }));
        }
    }, [authReducer.getPopIpsList])

    useEffect(() => {
        if(!_.isEmpty(selectedManageConfigType)) {
            handleConfigTypeChange(selectedManageConfigType)
            if (selectedManageConfigType == Hub_IP)  {
                handleHubIPConfigType(Hub_IP_Configure)
            }
        }
    }, [selectedManageConfigType])

    useEffect(() => {
        if(!_.isEmpty(selectedManageConfigHubIPType)) {
            if (selectedManageConfigType == Hub_IP)  {
                handleHubIPConfigType(selectedManageConfigHubIPType)
            }
        }
    }, [selectedManageConfigHubIPType])

    const handleConfigTypeChange = (configType) => {
        setConfigType(configType);
        setSelectedTrafficPolicy({ group: "", name: "", type: "", id: "" });
        setTargetType({ label:"" });
        setSelectedSites([]);
        setSelectedDevices([]);
        setSelectedManagedGroups([]);
        setSelectedWanProfile({ id: "", name: "" });
        if (configType ===WAN_Profile) {
            getWANProfileList({
                query: "GET_WAN_PROFILES", "named": true, "format": "json", "parameters": { "device_id": selectedDevice?.id }
            })
        } else if (configType === Hub_IP) {
            setHubIPConfigType(Hub_IP_Configure);
            setTargetType({label: 'Device'})
            setHubIPAction(Hub_IP_Action_Add);
            setSourceDeviceServingHubName("")
            getPopIpsList({
                query: "GET_POP_IPS", "named": true, "format": "json", "parameters": { "device_id": selectedDevice?.id }
            })
        }
    }

    const handleHubIPConfigType = (value) => {
        setTargetType({ label:"" });
        setSelectedSites([]);
        setSelectedDevices([]);
        setSelectedManagedGroups([]);
        setHubIPAction(Hub_IP_Action_Add);
        if(value==Hub_IP_Configure) {
            setHubIPConfigType(Hub_IP_Configure);
            setTargetType({label: 'Device'})
            let device = {
                "name":selectedDevice.name,
                "id":selectedDevice.id
            }
            let devices : Object[] = []
            devices.push(device)
            setSelectedDevices(devices)
        } else {
            setHubIPConfigType(Hub_IP_Copy);
            setSelectedDevices([])
        }
    }

    const handleHubIpAction = (event) => {
        if(hubIPAction==Hub_IP_Action_Add) {
            setHubIPAction(Hub_IP_Action_Replace);
        } else {
            setHubIPAction(Hub_IP_Action_Add);
        }
    }
    const handleTargetTypeChange = (event, value) => {
        setTargetType(value);
        setSelectedSites([]);
        setSelectedDevices([]);
        setSelectedManagedGroups([]);
    } 

    const createSubTitle = () => {
        let message = "";
        if (configType === Traffic_Policy) {
            if (targetType?.label === "Site") {
                const siteNames = selectedSites.map((site) => site.name).join(', ');
                message = `Traffic Policy ${selectedTrafficPolicy.name} will be applied  from ${selectedDevice.name} to site${selectedSites.length===1 ? ": ": "s: "}${siteNames}.\n\nAre you sure to apply the configuration change ?`;
            } else if (targetType?.label === "Device") {
                const deviceNames = selectedDevices.map((device) => device.name).join(', ');
                message = `Traffic Policy ${selectedTrafficPolicy.name} will be applied  from ${selectedDevice.name} to device${selectedDevices.length===1 ? ": ": "s: "}${deviceNames}.\n\nAre you sure to apply the configuration change ?`;
            } else if (targetType?.label === "Managed Group") {
                const managedGroupNames = selectedManagedGroups.map((group) => group.name).join(', ');
                message = `Traffic Policy ${selectedTrafficPolicy.name} will be applied  from ${selectedDevice.name} to managed group${selectedManagedGroups.length===1 ? ": ": "s: "}${managedGroupNames}.\n\nAre you sure to apply the configuration change ?`;
            }
        } else if (configType === Firewall) {
             if (targetType?.label === "Site") {
                const siteNames = selectedSites.map((site) => site.name).join(', ');
                message = `Firewall Rules will be applied  from ${selectedDevice.name} to site${selectedSites.length===1 ? ": ": "s: "}${siteNames}.\n\nAre you sure to apply the configuration change ?`;
            } else if (targetType?.label === "Device") {
                const deviceNames = selectedDevices.map((device) => device.name).join(', ');
                message = `Firewall Rules will be applied  from ${selectedDevice.name} to device${selectedDevices.length===1 ? ": ": "s: "}${deviceNames}.\n\nAre you sure to apply the configuration change ?`;
            } else if (targetType?.label === "Managed Group") {
                const managedGroupNames = selectedManagedGroups.map((group) => group.name).join(', ');
                message = `Firewall Rules will be applied  from ${selectedDevice.name} to managed group${selectedManagedGroups.length===1 ? ": ": "s: "}${managedGroupNames}.\n\nAre you sure to apply the configuration change ?`;
            }
        } else if (configType ===WAN_Profile) {
            if (targetType?.label === "Site") {
                const siteNames = selectedSites.map((site) => site.name).join(', ');
                message = `WAN Profile ${selectedWanProfile.name} will be applied  from ${selectedDevice.name} to site${selectedSites.length===1 ? ": ": "s: "}${siteNames}.\n\nAre you sure to apply the configuration change ?`;
            } else if (targetType?.label === "Device") {
                const deviceNames = selectedDevices.map((device) => device.name).join(', ');
                message = `WAN Profile ${selectedWanProfile.name} will be applied  from ${selectedDevice.name} to device${selectedDevices.length===1 ? ": ": "s: "}${deviceNames}.\n\nAre you sure to apply the configuration change ?`;
            } else if (targetType?.label === "Managed Group") {
                const managedGroupNames = selectedManagedGroups.map((group) => group.name).join(', ');
                message = `WAN Profile ${selectedWanProfile.name} will be applied  from ${selectedDevice.name} to managed group${selectedManagedGroups.length===1 ? ": ": "s: "}${managedGroupNames}.\n\nAre you sure to apply the configuration change ?`;
            }
        } else if (configType === Hub_IP) {
            if (targetType?.label === "Site") {
                const siteNames = selectedSites.map((site) => site.name).join(', ');
                message = `Hub IP configuration will be applied  to site${selectedSites.length===1 ? ": ": "s: "}${siteNames}.\n`;
            } else if (targetType?.label === "Device") {
                const deviceNames = selectedDevices.map((device) => device.name).join(', ');
                message = `Hub IP configuration will be applied to device${selectedDevices.length===1 ? ": ": "s: "}${deviceNames}.\n`;
            } else if (targetType?.label === "Managed Group") {
                const managedGroupNames = selectedManagedGroups.map((group) => group.name).join(', ');
                message = `Hub IP configuration will be applied to managed group${selectedManagedGroups.length===1 ? ": ": "s: "}${managedGroupNames}.\n`;
            }
            if (hubIPConfigType == Hub_IP_Configure) {
                message += `Hub Name : ${configuredHubName} \nHub IP:Port(s) : ${configuredHubPorts}\n\nAre you sure to apply the configuration change ?`;
            } else {
                let copyHubs = selectedHubIPs.map(a => a.name)
                message += `Hub Name(s) : ${copyHubs}\n\nAre you sure to apply the configuration change ?`;
            }
        } else if (configType === Quota) {
            if (targetType?.label === "Site") {
               const siteNames = selectedSites.map((site) => site.name).join(', ');
               message = `Quota Rules will be applied  from ${selectedDevice.name} to site${selectedSites.length===1 ? ": ": "s: "}${siteNames}.\n\nAre you sure to apply the configuration change ?`;
           } else if (targetType?.label === "Device") {
               const deviceNames = selectedDevices.map((device) => device.name).join(', ');
               message = `Quota Rules will be applied  from ${selectedDevice.name} to device${selectedDevices.length===1 ? ": ": "s: "}${deviceNames}.\n\nAre you sure to apply the configuration change ?`;
           } else if (targetType?.label === "Managed Group") {
               const managedGroupNames = selectedManagedGroups.map((group) => group.name).join(', ');
               message = `Quota Rules will be applied  from ${selectedDevice.name} to managed group${selectedManagedGroups.length===1 ? ": ": "s: "}${managedGroupNames}.\n\nAre you sure to apply the configuration change ?`;
           }
       }
        return message;
    }


    const handleApply = async () => {
        const siteIds = selectedSites.map((site) => site.id);
        const deviceIds = selectedDevices.map((device) => device.id);
        const groupIds = selectedManagedGroups.map((group) => group.id);
        const filteredVessels = authReducer?.filteredVessels;
        const sourceSiteId = filteredVessels[0]?.id;


        if (configType === Traffic_Policy) {
            const payload = {
                "groupIds": groupIds,
                "siteIds": siteIds,
                "deviceIds": deviceIds,
                "sourceSiteId": sourceSiteId,
                "sourceDeviceId": selectedDevice?.id,
                "sourceDeviceTrafficPolicyName": selectedTrafficPolicy["name"],
                "sourceDeviceTrafficPolicyType": selectedTrafficPolicy["type"]
            };
            await applyTrafficPolicy(payload);
        } else if (configType === Firewall) {
            const payload = {
                "groupIds": groupIds,
                "siteIds": siteIds,
                "deviceIds": deviceIds,
                "sourceSiteId": sourceSiteId,
                "sourceDeviceId": selectedDevice?.id
            }
            await applyFirewallRule(payload);
        } else if (configType ===WAN_Profile) {
            const payload = {
                "groupIds": groupIds,
                "siteIds": siteIds,
                "deviceIds": deviceIds,
                "sourceSiteId": sourceSiteId,
                "sourceDeviceId": selectedDevice?.id,
                "sourceDeviceWANprofileId": selectedWanProfile?.id
            };
            await applyWanProfile(payload); 
        } else if (configType === Hub_IP) {
            let payload = {}
            if (hubIPConfigType == Hub_IP_Configure) {
                // by default apply on selected device
                if (deviceIds.indexOf(selectedDevice?.id) == -1) {
                    deviceIds.push(selectedDevice?.id)
                }

                payload = {
                    "groupIds": groupIds,
                    "siteIds": siteIds,
                    "deviceIds": deviceIds,
                    "hubName": configuredHubName,
                    "hubIpPortList": configuredHubPorts.replace(/\s/g, ""),
                    "configType": hubIPConfigType,
                    "isServingHub": isServingHub,
                    "action": hubIPAction
                }
            } else {

                payload = {
                    "groupIds": groupIds,
                    "siteIds": siteIds,
                    "deviceIds": deviceIds,
                    "sourceSiteId": sourceSiteId,
                    "sourceDeviceId": selectedDevice?.id,
                    "sourceDeviceHubIpIds": selectedHubIPs.map(a => a.id),
                    "servingHubId": selectedServingHub.id,
                    "configType": hubIPConfigType,
                    "action": hubIPAction
                }
            }
            await applyHubIPConfig(payload);
        } else if (configType === Quota) {
            const payload = {
                "groupIds": groupIds,
                "siteIds": siteIds,
                "deviceIds": deviceIds,
                "sourceSiteId": sourceSiteId,
                "sourceDeviceId": selectedDevice?.id
            }
            await copyQuotaConfig(payload);
        }

        onClose();
        refreshLogTable(sourceSiteId);
    }

    const refreshLogTable = (sourceSiteId:string) => {
        const payload = {
            "query": "GET_TOPOLOGY_PAGE_CONFIG_MANAGER_LOGS",
            "named": true,
            "format": "json",
            "parameters": {
                "source_siteId": sourceSiteId,
                "search": "",
                "sort": paginationParams.sortBy,
                "order": paginationParams.sortOrder,
                "limit": paginationParams.size, 
                "offset":0
            }
        };
        getTraficPolicyApplyLogs(payload);
    }

    const handleHubIPtabChange = () => {
        setHubIPAction(Hub_IP_Action_Add)
        if (hubIPConfigType == Hub_IP_Configure) {
            setHubIPConfigType(Hub_IP_Copy)
            setSelectedDevices([])
        } else {
            setHubIPConfigType(Hub_IP_Configure)
            let device = {
                "name":selectedDevice.name,
                "id":selectedDevice.id
            }
            let devices : Object[] = []
            devices.push(device)
            setSelectedDevices(devices)
        }
    }

    return (
        <div className="Form assignInventory-container">
            <DialogContent>
                        <Grid container className="deploy-inventory-content register-inventory-content manage-config-pop-up">
                            {
                                configType === Hub_IP
                                ? 
                                // <Grid>
                                    <Grid className="hub-ip-config-type-tabs">
                                        <div className={hubIPConfigType == Hub_IP_Configure ? "active-tab configure" : "not-active-tab configure"} onClick={()=>handleHubIPtabChange()}>{Hub_IP_Configure}</div>
                                        <div className={hubIPConfigType == Hub_IP_Copy ? "active-tab copy" : "not-active-tab copy"} onClick={()=>handleHubIPtabChange()}>{Hub_IP_Copy}</div>
                                    </Grid>
                                // </Grid>
                                : null
                            }
                            {
                            configType==Hub_IP
                                ?
                                <Grid item xs={12} sm={12} lg={12} className="config-configuration-form-deploy">
                                        <span className="hub-ip-config-source-target-header">{hubIPConfigType == Hub_IP_Configure ? "Target" : "Source"}</span>
                                </Grid>
                                : null
                            }
                            <Grid container className="config-apply-config-target">
                                {
                                    configType==Hub_IP && hubIPConfigType == Hub_IP_Configure ? <Grid xs={4} sm={4} md={4} lg={4}>Target Device</Grid > : <Grid xs={4} sm={4} md={4} lg={4}>Source Device</Grid >
                                }
                                <Grid xs={6} sm={6} md={6} lg={6}>
                                    <div className="value"> {selectedDevice.name} </div> 
                                </Grid>
                            </Grid>
                            {configType === Traffic_Policy ? (
                                <Grid container className="config-apply-config-target" >
                                    <Grid xs={4} sm={4} md={4} lg={4}>Traffic Policy Name</Grid>
                                    <Grid xs={6} sm={6} md={6} lg={6} className="configuration-form-build">
                                        <Autocomplete
                                            id="traffic-policy-name-select"
                                            options={trafficPolicy.sort((a, b) => -a.group.localeCompare(b.group))}
                                            loading={authReducer.trafficPolicyListingLoading}
                                            groupBy={(option) => option.group}
                                            getOptionLabel={(option) => option.name}
                                            sx={{ width: 300 }}
                                            onChange={(e, value) => { setSelectedTrafficPolicy(value); }}
                                            value={selectedTrafficPolicy}
                                            renderInput={(params) => <TextField {...params} placeholder="Select Traffic Policy" variant="outlined" size="small" />}
                                        />
                                    </Grid>
                                </Grid>            
                            ) : null}

                            {configType ===WAN_Profile ? (
                                <Grid container className="config-apply-config-target" >
                                    <Grid xs={4} sm={4} md={4} lg={4}>WAN Profile Name</Grid>
                                    <Grid xs={2} sm={2} md={2} lg={2} className="configuration-form-build">
                                        <Autocomplete
                                            id="wan-profile-name-select"
                                            options={wanProfileOptions}
                                            loading={authReducer.wanProfileListingLoading}
                                            getOptionLabel={(option) => option.name?option.name:""}
                                            sx={{ width: 300 }}
                                            onChange={(e, value) => { 
                                                if (value != null){
                                                    setSelectedWanProfile(value); 
                                                }
                                            }}
                                            value={selectedWanProfile}
                                            renderInput={(params) => <TextField {...params} placeholder="Select WAN" variant="outlined" size="small" />}
                                        />
                                    </Grid>
                                </Grid>            
                            ) : null}

                            {
                                configType === Hub_IP ? (
                                <Grid container>
                                    <Grid container className="config-apply-config-target">
                                        <Grid xs={4} sm={4} md={4} lg={4}>Action</Grid>
                                        <Grid xs={6} sm={6} md={6} lg={6} className="configuration-form-build">
                                            <Autocomplete
                                                options={[Hub_IP_Action_Add,Hub_IP_Action_Replace]}
                                                sx={{ width: 300 }}
                                                onChange={handleHubIpAction}
                                                value={hubIPAction}
                                                renderInput={(params) => <TextField {...params} placeholder="Select Action" variant="outlined" size="small" />}
                                            />
                                        </Grid>
                                    </Grid>
                                   {
                                        hubIPConfigType === Hub_IP_Copy ?
                                        (
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Grid container className="config-apply-config-target">
                                                    <Grid xs={4} sm={4} md={4} lg={4}>Hub</Grid>
                                                    <Grid xs={6} sm={6} md={6} lg={6} className="menuScroll"> 
                                                        <Autocomplete
                                                            multiple
                                                            options={[{name: "Select All", id: null}, ...hubIPOptions]}
                                                            value={selectedHubIPs}
                                                            limitTags={3}
                                                            style={{ width: 300 }}
                                                            disableCloseOnSelect
                                                            inputValue={hubNameFilterValue}
                                                            getOptionLabel={(option) =>!_.isEmpty(option)?(option?.name?option.name:""):""}
                                                            renderInput={(params)=> (
                                                                <TextField {...params} onChange={(e) => setHubNameFilterValue(e.target.value)} placeholder="Select Hub" variant="outlined" size="small" />
                                                            )}
                                                            onChange={(e, value) => {
                                                                if (value != null){
                                                                    if (value.some((option) => option.name === "Select All")) {
                                                                        if(selectedHubIPs.length === hubIPOptions.length) {
                                                                            setSelectedHubIPs([]);
                                                                        } else {
                                                                            setSelectedHubIPs(hubIPOptions);
                                                                        }
                                                                    } else {
                                                                        setSelectedHubIPs(value);
                                                                    }
                                                                }
                                                            }}
                                                            onClose={() => {setHubNameFilterValue("")}}
                                                            isOptionEqualToValue = {(option, value) => !_.isEmpty(option) ? option.name === value.name:false}
                                                            />
                                                    </Grid>
                                                </Grid>
                                                <span className="source-device-serving-hub-info">Serving hub on source is <span className="serving_hub-name">{sourceDeviceServingHubName}</span></span>
                                                <Grid container className="config-apply-config-target">
                                                    <Grid xs={4} sm={4} md={4} lg={4}>Serving Hub</Grid>
                                                    <Grid xs={6} sm={6} md={6} lg={6}>
                                                        <Autocomplete
                                                            id="hub-name-select"
                                                            options={servingHubOptions}
                                                            loading={authReducer.applyHubIPConfigLoading}
                                                            getOptionLabel={(option) => !_.isEmpty(option)?(option?.name?option.name:""):""}
                                                            sx={{ width: 300 }}
                                                            onChange= {(e, value) => { 
                                                                if (value != null){
                                                                    setSelectedServingHub(value);
                                                                }
                                                            }}
                                                            value = {selectedServingHub}
                                                            renderInput={(params) => <TextField {...params} placeholder="Select Serving Hub" variant="outlined" size="small" /> }
                                                            isOptionEqualToValue = {(option, value) => !_.isEmpty(option) ? option.name === value.name:false}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) 
                                        : 
                                        (
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Grid container className="config-apply-config-target">
                                                    <Grid xs={4} sm={4} md={4} lg={4}>Hub Name</Grid>
                                                    <Grid xs={6} sm={6} md={6} lg={6}>
                                                        <TextField 
                                                            className="hub-config-hub-name"
                                                            placeholder="Enter Hub name"
                                                            value={configuredHubName}
                                                            onChange={(event) => { setConfiguredHubName(event.target.value)}}
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                    </Grid>
                                                </Grid> 

                                                <Grid container className="config-apply-config-target">
                                                    <Grid xs={4} sm={4} md={4} lg={4}>Hub IP:Port(s)</Grid>
                                                    <Grid xs={6} sm={6} md={6} lg={6}>
                                                        <TextField
                                                            className="hub-config-hub-ip"
                                                            placeholder="eg. 192.168.10.1, 127.0.0.1:8080"
                                                            value={configuredHubPorts}
                                                            onChange={(event) => {
                                                                setConfiguredHubIsNotValidMessage("")
                                                                setConfiguredHubPorts(event.target.value)
                                                            }}
                                                            helperText={configuredHubIsNotValidMessage}
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container className="config-configuration-form-deploy select-config-from">
                                                    <Grid xs={4} sm={4} md={4} lg={4}> 
                                                        <span className="hub-ip-config-source-target-header serving-hub">Serving Hub</span>
                                                    </Grid>
                                                    <Grid xs={6} sm={6} md={6} lg={6}>
                                                        <Checkbox className="serving-hub-checkbox" onClick={() => {setIsServingHub(!isServingHub)}} checked={isServingHub} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                            </Grid>
                            ): null}

                            {configType == Hub_IP ? hubIPConfigType == Hub_IP_Copy ? <Grid item xs={12} sm={12} lg={12} container className="config-configuration-form-deploy select-config-from">
                                <span className="hub-ip-config-source-target-header">Target</span>
                            </Grid> : null : null}

                            <Grid container className="config-apply-config-target">
                                <Grid xs={4} sm={4} md={4} lg={4}>Target Type</Grid>
                                <Grid xs={6} sm={6} md={6} lg={6} className="configuration-form-build">
                                    <Autocomplete
                                        options={[{ label: "Site" }, { label: "Device" }, { label: "Managed Group" }]}
                                        getOptionLabel={(option) => option.label}
                                        sx={{ width: 300 }}
                                        onChange={handleTargetTypeChange}
                                        value={targetType}
                                        renderInput={(params) => <TextField {...params} placeholder="Select Target Type" variant="outlined" size="small" />}
                                        isOptionEqualToValue = {(option, value) => option.label === value.label}
                                    />
                                </Grid>
                            </Grid>


                            {targetType?.label === "Site" ? (
                            <Grid container className="config-apply-config-target traffic-policy-site">
                                <Grid xs={4} sm={4} md={4} lg={4}> Select Site</Grid>
                                <Grid xs={6} sm={6} md={6} lg={6} className="menuScroll">
                                    <Autocomplete
                                        multiple
                                        id="site-select-box"
                                        options={[{ name: "Select All", id: null }, ...siteList]}
                                        value={selectedSites}
                                        limitTags={3}
                                        style={{ width: 300, margin: '0px' }}
                                        disableCloseOnSelect
                                        inputValue={siteFilterValue}    
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(prop, option, { selected }) => (
                                                <li {...prop}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.name}
                                                </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} onChange={(e) => setSiteFilterValue(e.target.value)} placeholder="Select Site" variant="outlined" size="small" />
                                        )}
                                        onChange={(e, value) => {
                                            if (value.some((option) => option.name === "Select All")) {
                                                if (selectedSites.length === siteList.length) {
                                                    setSelectedSites([]);
                                                } else {
                                                    setSelectedSites(siteList);
                                                }
                                            } else {
                                                setSelectedSites(value);
                                            }
                                        }}
                                        onClose={() => {setSiteFilterValue("")}}
                                        isOptionEqualToValue = {(option, value) => option.name === value.name}
                                    />
                                </Grid>
                            </Grid>   
                            
                            ) : null}
                            
                            {targetType?.label === "Device" ? (
                                <Grid container className="config-apply-config-target traffic-policy-site">
                                    <Grid xs={4} sm={4} md={4} lg={4}> Select Device</Grid>
                                        <Grid xs={6} sm={6} md={6} lg={6} className="menuScroll">            
                                        <Autocomplete
                                            multiple
                                            id="device-select-box"
                                            options={[{name: "Select All", id: null},...deviceList]}
                                            value={selectedDevices}
                                            limitTags={3}
                                            style={{ width: 300, margin: '0px' }}
                                            disableCloseOnSelect
                                            inputValue={deviceFilterValue}
                                            getOptionLabel={(option) => option.name}                              
                                            renderOption={(prop, option, { selected }) => (
                                                    <li {...prop} key={option.id}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.name}
                                                        </li>
                                                )}
                                            renderInput={(params) => (
                                                <TextField {...params}  onChange={(e) => setDeviceFilterValue(e.target.value)} placeholder="Select Device" variant="outlined" size="small" />
                                            )}
                                            onChange={(e, value) => {
                                                if (value.some((option) => option.name === "Select All")) {
                                                    if (selectedDevices.length === deviceList.length) {
                                                        setSelectedDevices([]); // Deselect all
                                                    } else {
                                                        setSelectedDevices(deviceList); // Select all
                                                    }
                                                } else {
                                                    if(selectedManageConfigType == Hub_IP && hubIPConfigType == Hub_IP_Configure && !value.some(device => device.id === selectedDevice.id)) {
                                                        let device = {
                                                            "name":selectedDevice.name,
                                                            "id":selectedDevice.id
                                                        }
                                                        value.unshift(device)
                                                    }
                                                    setSelectedDevices(value);
                                                }
                                            }}
                                            onClose={()=>{setDeviceFilterValue("")}}
                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                        />
                                        </Grid>
                                </Grid>
                            ) : null}
                            
                            {targetType?.label === "Managed Group" ? (
                                <Grid container className="config-apply-config-target traffic-policy-site">
                                    <Grid xs={4} sm={4} md={4} lg={4}> Managed Group</Grid>
                                    <Grid xs={6} sm={6} md={6} lg={6} className="menuScroll">
                                        <Grid className="menuScroll">
                                            <Autocomplete
                                                multiple
                                                id="managedgroup-select-box"
                                                options={[{ name: "Select All", id: null }, ...groupList]}
                                                value={selectedManagedGroups}
                                                limitTags={3}
                                                style={{ width: 300, margin: '0px' }}
                                                disableCloseOnSelect
                                                inputValue={managedGroupFilterValue}
                                                getOptionLabel={(option) => option.name}
                                                renderOption={(prop, option, { selected }) => (
                                                    <li {...prop}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.name}
                                                        </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField {...params} onChange={(e) => setManagedGroupFilterValue(e.target.value)} placeholder="Select Group" size="small" />
                                                )}
                                                onChange={(e, value) => {
                                                    if (value.some((option) => option.name === "Select All")) {
                                                        if (selectedManagedGroups.length === groupList.length) {
                                                            setSelectedManagedGroups([]);
                                                        } else {
                                                            setSelectedManagedGroups(groupList);
                                                        }
                                                    } else {
                                                        setSelectedManagedGroups(value);
                                                    }
                                                }}
                                                onClose={() => setManagedGroupFilterValue("")}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>  
                            ) : null}
                            

                        </Grid>
                </DialogContent>
            <DialogActions>
            <div className="register-edit-actions-confirm-btns">
                <Divider className="confirm-dialogue-new-divider" />
                <div className="confirm-dialogue-new-btns">
                    <Button className="confirm-dialogue-cancel" onClick={onClose}> Cancel </Button>
                        {
                        deviceKonnectStatus == KONNECT_STATUS_CONNECTED || configType===Quota
                        ?
                        <Button 
                            className={(
                                (
                                (!_.isEmpty(selectedTrafficPolicy?.id) || !_.isEmpty(selectedWanProfile?.id) || configType===Firewall || configType===Quota) && !_.isEmpty(configType))
                                ||
                                ( (!_.isEmpty(configuredHubName ) && !_.isEmpty(configuredHubPorts) || selectedHubIPs.length > 0) )
                                )
                                && (selectedSites.length!==0 || selectedDevices.length!==0 || selectedManagedGroups.length!==0) ? "confirm-dialogue-proceed" : "confirm-dialogue-proceed-notAllow"}
                            disabled= {!(
                                (
                                (!_.isEmpty(selectedTrafficPolicy?.id) || !_.isEmpty(selectedWanProfile?.id) || configType===Firewall || configType===Quota) && !_.isEmpty(configType))
                                ||
                                ( (!_.isEmpty(configuredHubName ) && !_.isEmpty(configuredHubPorts)) || selectedHubIPs.length > 0 )
                                )
                                && (selectedSites.length!==0 || selectedDevices.length!==0 || selectedManagedGroups.length!==0
                                    )}
                            onClick={() => {
                                    if ( (!_.isEmpty(selectedTrafficPolicy) || !_.isEmpty(configType)) && (selectedSites.length!==0 || selectedDevices.length!==0 || selectedManagedGroups.length!==0) ) {
                                        if (configType === Hub_IP && hubIPConfigType == Hub_IP_Configure) {
                                            let isValidIPport= true
                                            let ipPortList = configuredHubPorts.split(",")

                                            for(let ipPort of ipPortList) {
                                                // remove all spaces
                                                ipPort=ipPort.replace(/\s/g, "")

                                                // Regular expression for validating IPv4 with optional port
                                                const regex = /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(:[0-9]{1,5})?$/;                                                isValidIPport = regex.test(ipPort)

                                                if (!isValidIPport){
                                                    break
                                                }
                                            }
                                            // not valid
                                            if (!isValidIPport){
                                                setConfiguredHubIsNotValidMessage("Invalid Hub IP:Port(s)")
                                                return
                                            }
                                        }
                                        setConfirmDialog({
                                            isOpen: true,
                                            title: CONFIRM_DIALOG_TITLE,
                                            subTitle: createSubTitle(),
                                            onConfirm: () => { 
                                                handleApply()
                                            }
                                        })
                                    }
                                }}
                        >Apply</Button>
                        :  <Button className="confirm-dialogue-proceed-notAllow" title="Device is offline">Apply</Button>
                        }
                </div>
            </div>
        </DialogActions>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer
})

export default withRouter(
    connect(mapStateToProps, { getTrafficPolicyData, getWANProfileList, getPopIpsList, applyHubIPConfig, applyTrafficPolicy, applyFirewallRule, applyWanProfile, getTraficPolicyApplyLogs, parentDpAndSubDpList, getGroupList, getDeviceList, copyQuotaConfig })(ManageConfig)
);