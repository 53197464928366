import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Box, Button, Grid, Menu, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import TuneIcon from '@mui/icons-material/Tune';
import close from "../../asset/image/close.svg";
import { getDecodeURI, getEncodedURI, getIntervalCheck } from "../../utils/util";
import { getOperationalReports, getOperationalReportsCount } from '../../actions/Users/authenticateUsageReports';
import _ from "lodash";
import { Pagination } from '../Pagination';
import SortArrows, { SortOrder } from "../SortArrows";
import OperationalTimeSeriesTable from "../OperationalUsageTimeseriesTable";
import OperationalUsagePerWanWise from "../OperationalUsageSingleSiteWanUsage";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import OperationalFilterDeviceList from "./OperationalUsageFilterList";
import OperationalChips from "./OperationalFilterChips";
import ascSortingSelected from "../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../asset/image/descSortingSelected.svg";
import AscSort from "../../asset/image/AscSort.svg";
import DescSort from "../../asset/image/DescSort.svg";
import Download from '../../asset/image/DownloadUsage.svg';
import OperationalUsageReportsDownload from "./OperationalUsageReportsDownload";
import { readableBytesAsGB } from "../../utils/util";

const OperationalReports = (props) => {
    const { authReducer,
        getOperationalReports,
        getOperationalReportsCount,
        newSummaryStartDate,
        newSummaryEndDate, 
        reports,
        total_count,
        location,history 
    } = props;
    let _q = new Map<string, string>(Object.entries(getDecodeURI(location.search)));
    const [vessels, setVessels] = useState([]);
    const [device, setDevice] = useState<string>("");
    const [softwareVersion, setSoftwareVersion] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("desc");
    const [sortColumn, setSortColumn] = useState<string>("wan_usage");
    const [tableParams, setTableParams] = useState<{
        page: number;
        size: number;
    }>({
        page: _q.has('page') ? parseInt(_q.get('page') as string) : 1,
        size: _q.has('size') ? parseInt(_q.get('size') as string) : 10
    });

    const rowsOption = [10, 15, 25, 50, 100];

    const handleChangePage = (event: any, newPage: number) => {
        setTableParams({ ...tableParams, page: newPage });
    };

    const handleChangeSize = (event: any) => {
        setTableParams({ ...tableParams, size: event.target.value, page: 1 });
    };

    useEffect(() => {
        if (_.isEmpty(authReducer?.getVesselsListing)){
            return;
        }
        let Vessels = [];
        const filteredVessels = authReducer?.filteredVessels;
        if (filteredVessels && filteredVessels.length > 0) {
            Vessels = filteredVessels;
        } else {
            Vessels = authReducer?.getVesselsListing?.locations;
        }
        setVessels(Vessels);
        setTableParams(() => ({
            page: 1,
            size: 10
        }));
    },[authReducer?.getVesselsListing, authReducer?.filteredVessels])

    let count_rows=total_count? total_count[0] : 0;
    const DEFAULT_PAGE_SIZE = 10; 

    useEffect ( () => {
        if(vessels.length > 0 ){
            getOperationalReports({
                vessels: vessels,
                device_id: device,
                software_version : softwareVersion,
                start_time: newSummaryStartDate,
                end_time: newSummaryEndDate,
                sort_column: sortColumn,      
                sort_order: sortOrder,     
                page_offset: (tableParams.page-1)*tableParams.size,       
                page_size: tableParams.size
            })
            getOperationalReportsCount({
                vessels: vessels,
                device_id: device,
                software_version : softwareVersion,
                start_time: newSummaryStartDate,
                end_time: newSummaryEndDate
            })
        }
    },[vessels, newSummaryStartDate, newSummaryEndDate, tableParams, device, softwareVersion, sortOrder, sortColumn])

    const[open, setOpen] = useState<boolean>(false);
    const[ids, setIds] = useState<string>("");
    const handleClick = (value: string) => {
        setOpen(true);
        setIds(value);
    }
    const[usageWanWiseOpen, setUsageWanWiseOpen] = useState<boolean>(false);
    const[site, setSite] = useState<string>("");
    const handleDetailsClick = (value: string, value1: string) => {
        setUsageWanWiseOpen(true);
        setIds(value);
        setSite(value1);
    }
    const[dwn, setDwn] = useState<boolean>(false);
    const handleDwn = (value: string) => {
        setDwn(true);
        setOpen(true);
        setIds(value);
    }
    const [searchValue, setSearchValue] = useState("");
    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
    }
    const handleOnSearchClear = () => {
        setSearchValue('');
    }

    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const handleFilterClick = () => {
        setOpenFilter(true);
    }
    const [reportsDownload, setReportsDownload] = useState<boolean>(false);
    const handleReportsDownload = () => {
        setReportsDownload(true);
    }

    const handleSiteClick = (row) => {
        let params: any = getDecodeURI(location?.search);
        row && row.k4Id ? params.k4Ids = `${row.k4Id}:${row.x}` : row && row.location_id ? params.k4Ids = `${row.location_id}:${row.site}`:  delete params.k4Ids;
        delete params.page;
        delete params.limit;
        delete params.sort;
        delete params.sortOrder;
        delete params.search;
        doNavigate(params);
      }
    
      const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
      }

      const handleColumnSort = (value: string, value1: string) => {
        setSortColumn(value);
        setSortOrder(value1);
      }

      const toggleSortOrder = (column) => {
        const newOrder = sortOrder === "desc" ? "asc" : "desc";
        handleColumnSort(column, newOrder); 
    };

      const handleDeviceClick = (value: string) => {
        setDevice(`and deviceID IN('${value}')`);
        setTableParams((prevParams) => ({
            ...prevParams,
            page: 1,
        }));
      }

    return (
        <Grid>
          <Grid className="alignTopbarItemsCenter topbarWidth">
            <Grid item xs={4} sm={4} md={4} lg={4} className="filterButtonMaxWidth">
              <Button variant="outlined" startIcon={<TuneIcon id="filterIcon"/>} onClick={handleFilterClick} id="filterButton">
                Filter
              </Button>
            </Grid>
            <OperationalChips device={device} setDevice={setDevice} softwareVersion={softwareVersion} setSoftwareVersion={setSoftwareVersion}/>
            <Grid item xs={4} sm={4} md={4} lg={4} className="timeseriesDwn">
                <div className="download-summary-btn marg-left-auto" onClick={handleReportsDownload}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
            </Grid>
            {openFilter && <OperationalFilterDeviceList openFilter={openFilter} setOpenFilter={setOpenFilter} device={device} setDevice={setDevice} softwareVersion={softwareVersion} setSoftwareVersion={setSoftwareVersion} tableParams={tableParams} setTableParams={setTableParams}/>}
            {reportsDownload && <OperationalUsageReportsDownload reportsDownload={reportsDownload} setReportsDownload={setReportsDownload} device={device} softwareVersion={softwareVersion} total_count={total_count}/>}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner generated-reports-table">
          <Pagination count={count_rows} onPageChange={handleChangePage} page={tableParams.page} rowsPerPage={tableParams.size} onRowsPerPageChange={handleChangeSize} />

            <TableContainer className="usageReports-FleetDashboard reports-gene-container">
                    <Table aria-label="simple sticky table" stickyHeader>
                        <TableHead className="usageReports-tableHead config-tableRow">
                            <TableRow className="UsageReports-tableRow login-tableRow">
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("sitename")}>SITE NAME</span>
                                        <Grid className="sort-icon-margin">
                                            <img src={sortColumn === "sitename" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("sitename", "asc")}/>
                                            <img src={sortColumn === "sitename" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={() => handleColumnSort("sitename", "desc")}/>
                                        </Grid>
                                    </span>
                                </TableCell>
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("deviceID")}>DEVICE ID</span>
                                        <Grid className="sort-icon-margin">
                                            <img src={sortColumn === "deviceID" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("deviceID", "asc")}/>
                                            <img src={sortColumn === "deviceID" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={() => handleColumnSort("deviceID", "desc")}/>
                                        </Grid>
                                    </span>
                                </TableCell>
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("wan_usage")}>WAN USAGE</span>
                                        <Grid className="sort-icon-margin">
                                            <img src={sortColumn === "wan_usage" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("wan_usage", "asc")}/>
                                            <img src={sortColumn === "wan_usage" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={() => handleColumnSort("wan_usage", "desc")}/>
                                        </Grid>
                                    </span>
                                </TableCell>
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("operational_usage")}>OPERATIONAL USAGE</span>
                                        <Grid className="sort-icon-margin">
                                            <img src={sortColumn === "operational_usage" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("operational_usage", "asc")}/>
                                            <img src={sortColumn === "operational_usage" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={() => handleColumnSort("operational_usage", "desc")}/>
                                        </Grid>
                                    </span>
                                </TableCell>
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("activity_usage")}>ACTIVITY USAGE</span>
                                        <Grid className="sort-icon-margin">
                                            <img src={sortColumn === "activity_usage" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("activity_usage", "asc")}/>
                                            <img src={sortColumn === "activity_usage" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={() => handleColumnSort("activity_usage", "desc")}/>
                                        </Grid>
                                    </span>
                                </TableCell>
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("software_version")}>EOS VERSION</span>
                                        <Grid className="sort-icon-margin">
                                            <img src={sortColumn === "software_version" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("software_version", "asc")}/>
                                            <img src={sortColumn === "software_version" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={() => handleColumnSort("software_version", "desc")}/>
                                        </Grid>
                                    </span>
                                </TableCell>
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900">ACTION</span>
                                    </span>
                                </TableCell>
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900">DOWNLOAD</span>
                                    </span>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {reports?.data?.rows?.length > 0 ? (  
                            <TableBody className="tableBody usage-tableBody operationalTanbleOverflow">
                                 {reports?.data?.rows.map((row:any, rowIndex:number) => {
                                    const rowData = reports.data.columns.reduce((acc: any, column: string, index: number) => {
                                        acc[column] = row[index];
                                        return acc;
                                    }, {});
                        
                                    return (
                                    <TableRow key={rowIndex}>
                                        <TableCell className="operationalTableData"><a className="operationalTableClickableColumns" onClick={() => handleSiteClick({k4Id: rowData.locationID, x: rowData.sitename})}>{rowData.sitename}</a></TableCell>
                                        <TableCell className="operationalTableData"><a className="operationalTableClickableColumns" onClick={() => handleDeviceClick(rowData.deviceID)}> {rowData.deviceID}</a></TableCell>
                                        <TableCell className="operationalTableData"> {typeof rowData.wan_usage === 'number' ? readableBytesAsGB(rowData.wan_usage) : rowData.wan_usage}</TableCell>
                                        <TableCell className="operationalTableData"> {typeof rowData.operational_usage === 'number' ? readableBytesAsGB(rowData.operational_usage) : rowData.operational_usage}</TableCell>
                                        <TableCell className="operationalTableData"> {typeof rowData.activity_usage === 'number' ? readableBytesAsGB(rowData.activity_usage) : rowData.activity_usage}</TableCell>
                                        <TableCell className="operationalTableData"> {typeof rowData.software_version === 'number' ? readableBytesAsGB(rowData.software_version) : rowData.software_version.split("~")[0]}</TableCell>
                                        <TableCell className="operationalTableData"><a className="operationalTableClickableColumns" onClick={() => handleDetailsClick(rowData.locationID, rowData.sitename)}>View Details</a></TableCell>
                                        <TableCell className="operationalTableData"><a className="operationalTableClickableColumns" onClick={() => handleDwn(rowData.locationID)}>Download</a></TableCell>
                                    </TableRow>
                                    );
                                 })}
                            </TableBody>
                        ) : (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
            </TableContainer>
          </Grid>
          {open && <OperationalTimeSeriesTable location_ids={ids} dwn={dwn} setDwn={setDwn} open={open} setOpen={setOpen}/>}
          {usageWanWiseOpen && <OperationalUsagePerWanWise ids={ids} setIds={setIds} usageWanWiseOpen={usageWanWiseOpen} setUsageWanWiseOpen={setUsageWanWiseOpen} site={site} setSite={setSite} dwn={dwn} setDwn={setDwn} open={open} setOpen={setOpen}/>}
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    newSummaryStartDate: state.authReducer.newSummaryStartDate,
    newSummaryEndDate: state.authReducer.newSummaryEndDate,
    reports: state.authReducer.getOperationalUsageDataTable,
    total_count: state.authReducer.getOperationalUsageDataTableCount
});

export default withRouter(
    connect(mapStateToProps, {
        getOperationalReports,
        getOperationalReportsCount
    })(OperationalReports)
);

