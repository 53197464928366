import { TextField } from '@material-ui/core';
import { typeFromAST } from 'graphql';
import './customTextField.css';

    const CustomTextField = (props: any) => { 
        const { label, isvalidorinvalid, isvalidorinvalidmessage, value, variant, id, onChange, isMultiline, rows, placeholder, disabled, notRequired, name, error, type, autoComplete, minLength, maxLength, style } = props;
    return (
        <div >
            <TextField
                InputProps={error === true ? { className: 'invalid', disableUnderline: true } : { className: 'text-field', disableUnderline: true }}
                InputLabelProps={{ className: 'text-field' }}
                fullWidth
                name={name}
                classes={{ root: 'text-field' }}
                value={value}
                onChange={onChange}
                label={label}
                multiline={isMultiline}
                inputProps={{ maxLength: maxLength, minLength:minLength }}
                rows={rows}
                type={type}
                id={id}
                placeholder={placeholder ? placeholder : ''}
                variant={variant}
                required={notRequired ? false : true}
                disabled={disabled}
                autoComplete = {autoComplete}
                style={{...style}}
            />
            <div style={{ display: isvalidorinvalid ? 'inline-block' : 'none', color: "#ff0000" }}
                className={isvalidorinvalid === 'isValid' ? "" : "invalid-feedback"}
                >
                {isvalidorinvalidmessage}
            </div>
        </div>
    )
}

export default CustomTextField
