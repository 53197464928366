import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import './DropDownCard.css';

const DropDownCard = (props: any) => {
    const { handleCarrierSelection, dropDownList, dropDownLabel, minWidth, defaultValue } = props;
    const [selectedNetwork, setSelectedValue] = useState("");
    const handleSelectecion = (event: SelectChangeEvent) => {
        handleCarrierSelection(event.target.value);  
        setSelectedValue(event.target.value);         
    }

   useEffect(() => {
    setSelectedValue(defaultValue)
   }, [defaultValue])
    

    return (
        <FormControl className="dropDown" sx={{ minWidth: minWidth }}>
            <InputLabel className="drpdownLoabel" id="demo-simple-select-filled-label">{dropDownLabel}</InputLabel>
            <Select className="headerDropDownSelect"
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={selectedNetwork} 
                onChange={handleSelectecion}
                label={dropDownLabel}
            >
                {dropDownList?.map((item: any, i: any) => {
                            let dataItem = item.replaceAll('-', ' ')
                             return <MenuItem value={item} key={i}>{dataItem}</MenuItem>
                         })}
            </Select>
        </FormControl>
    );
}

export default DropDownCard;

