import { Grid, Tooltip } from "@mui/material";
import _ from "lodash";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import "./../../components/UsagePieChart/usagePieChart.css";


const colors = ["#1b51a7", "#8cb5ec", "#0e0548", "#1d136a", "#3e88d3", "#86c6df", "#4b91f1", "#64a1f4", "#8dbdff", "#c1d5f6",
    "#1b51a7", "#8cb5ec", "#0e0548", "#1d136a", "#3e88d3", "#86c6df", "#4b91f1", "#64a1f4", "#8dbdff", "#c1d5f6"
];

export interface ChartData {
    x: string;
    y: number;
    z: number;
    tooltip?: React.ReactNode;
}

interface Props {
    data: Array<ChartData>;
    onLinkClick: (data: ChartData) => void;
    title: string;
    name: string;
    unit: string;
}

interface TooltipProps {
    title: React.ReactNode;
    children: React.ReactElement<any, any>;
}

const TooltipWrap: React.FC<TooltipProps> = ({ title, children }) => {
    return title ? <Tooltip title={title} placement='left-end'>
        {children}
    </Tooltip> : children;
}

const FirewallPieChart = (props: Props) => {
    const { data, title, name, unit, onLinkClick } = props;

    const options: Highcharts.Options = {
        chart: {
            type: 'pie',
            height: '185px',
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
        },
        colors: ["#1b51a7", "#8cb5ec", "#0e0548", "#1d136a", "#3e88d3", "#86c6df", "#4b91f1", "#64a1f4", "#8dbdff", "#c1d5f6"],
        title: {
            text: ''
        },
        yAxis: {
            title: {
                text: 'App Category Used'
            }
        },
        tooltip: {
            enabled: true,
            useHTML: true,
            headerFormat: ``,
            pointFormat: '<tr>' +
                `<td style="text-align: left;display:block"><b>{point.name} <br/> ${name}: {point.z} ${unit}</b></td></tr>`,
            footerFormat: '</table>',
            valueDecimals: 2
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                shadow: false,
            }
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        series: [{
            type: 'pie',
            name: 'Usage',
            data: data.map(value => ({name: value.x, y: value.y, z: value.z})),
            size: '100%',
            innerSize: '50%',
            dataLabels: {
                enabled: false
            }
        }]
    }

    return (
        <div className="Form">
            <Grid className="am-table">
                <Grid className="totalUsageChartContainer">
                    <Grid>
                        <Grid>
                            <div className="rc--filter_pie-usage piechart-selectContainer">
                                <Grid>{title}</Grid>
                            </div>
                        </Grid>
                        <Grid className="usage-doughnutChart">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                            />
                        </Grid>
                    </Grid>
                    <Grid>
                        <Grid className="usageDetailsWithCategory">
                            {data && data.map((item, i) => (
                                <Grid key={i} className="appcategoryUsed-usage">
                                    <div style={{ background: `${colors[i]}` }} className="usageDot-1"></div>
                                    <Grid className="pieChart-activity">
                                        <Grid className="usagepercentage">{`${item.y < 1 ? item.y < 0.01 && item.y !== 0 && item.y !== null ? 0.01 : Math.round(item.y * 100) / 100 : Math.round(item.y)}%`}</Grid>
                                        <TooltipWrap title={item.tooltip}>
                                            <p className="activity-pieChart-app" onClick={() => onLinkClick(item)}>{item.x}</p>
                                        </TooltipWrap>
                                    </Grid>
                                </Grid>
                            ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default FirewallPieChart;