import { useEffect, useState } from "react";
import { Button, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import _ from "lodash";
import "./usageLineChart.css";
import Chart from "../Charts";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import DisardIcon from "../../asset/image/DiscrdFilter.svg";
import { getDateForChart, getDecodeURI, getMinutes, getSingleRecordChartLable, getTimezoneCity } from "../../utils/util";
import {  connect } from "react-redux";
import { withRouter} from "react-router-dom";

const UsageLineChart = (props) => {
  const { lineChartData, topApplicationUsage, chartAreaSelectionUsage, chartAreaSelectionTopUsage, setActiveTab, setTrackTab, lineChartTab, setLineChartTab, handleResetZoom, handleDefaultResetZoom, resetDate, topAppLimit, handleChangeTopAppLimit, handleApplicationFilterClick, application, selectedAppColor, location, selectedBin, handleBinChange, startDate, endDate, yLabelUnit = "GB", userTimezone } = props;
  const USAGE_OVER_TIME = "usage_over_time";
  const TOP_USAGE = "top_usage";
  const [appNames, setAppName] = useState([]);
  const [usageName, setUsageName] = useState([]);
  let _q: any = getDecodeURI(location?.search);
  const timeZone = getTimezoneCity(userTimezone);

  useEffect(() => {
    if(topApplicationUsage) {
      let names:any = [];
      for(let key in topApplicationUsage) {
        names.push(topApplicationUsage[key].name)
      }
      setAppName(names);
    }
    if(lineChartData) {
      let names:any = [];
      for(let key in lineChartData) {
        names.push(lineChartData[key].name)
      }
      setUsageName(names)
    }
  },[topApplicationUsage, lineChartData])
  
    const options = {
        time: {
          timezone: timeZone
        },
        chart: {
            type: 'spline',
            height: '210px',
            zoomType: 'x',
            events: {
              selection: chartAreaSelectionUsage()
            },
            plotBorderWidth: 1,
            resetZoomButton: {
              theme: {
                  style: {
                    display: 'none'
                  }
              }
            },
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
          },
          colors: application ? [selectedAppColor] : ["#3C7DD9", "#C1D5F6", "#2039B5"],
          title: {
            text: '',
            align: 'left',
            floating: true,
            x: 12,
            y: 32,
            style: {
                fontWeight: '500'
            }
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        plotOptions: {
            series: {
                turboThreshold: 1000000,
                stickyTracking: false,
                connectNulls: false,
            }
        },
        tooltip: {
          valueSuffix: `${yLabelUnit}`,
          pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
          valueDecimals: 0,
          borderWidth: 1
        },
        xAxis: {
          gridLineWidth: 0.5,
          reversed: false,
          type: "datetime",
          maxPadding: 0.05,
          showLastLabel: true,
          labels: {
            format: getDateForChart(lineChartData) && getSingleRecordChartLable(lineChartData, timeZone)
          }
        },
        yAxis: {
          tickInterval: 1,
          gridLineWidth: 1,
          labels: {
            format: `{value} ${yLabelUnit}`,
          },
          title: {
              text: null
          }
        },
        series: lineChartData[0]?.data ? lineChartData : []
      }

      const topAppsOptions: any = {
        time: {
          timezone: timeZone
        },
        chart: {
            type: 'spline',
            height: '210px',
            zoomType: 'x',
            plotBorderWidth: 1,
            resetZoomButton: {
                theme: {
										style: {
                      display: 'none'
										}
                }
              },
            events: {
              selection: chartAreaSelectionTopUsage()
            },
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
          },
          colors: application ? [selectedAppColor] : ["#0e0548", "#1d136a", "#1b51a7", "#3e88d3", "#8cb5ec", "#86c6df", "#4b91f1", "#64a1f4", "#8dbdff", "#c1d5f6"],
          title: {
            text: '',
            align: 'left',
            floating: true,
            x: 12,
            y: 32,
            style: {
                fontWeight: '500'
            }
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
          credits: {
            enabled: false
          },
          legend: {
            enabled: false
          },
          plotOptions: {
            series: {
                turboThreshold: 1000000,
                stickyTracking: false,
                connectNulls: false,
            }
        },
        tooltip: {
          valueSuffix: ` ${yLabelUnit}`,
          pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
          valueDecimals: 0,
          borderWidth: 1
        },
          xAxis: {
            gridLineWidth: 0.5,
            reversed: false,
            type: "datetime",
            maxPadding: 0.05,
            showLastLabel: true,
            labels: {
              format: getDateForChart(topApplicationUsage) && getSingleRecordChartLable(topApplicationUsage, timeZone)
            }
          },
          yAxis: {
            tickInterval: 1,
            gridLineWidth: 1,
            labels: {
              format: `{value} ${yLabelUnit}`,
            },
            title: {
                text: null
            }
          },
        series: topApplicationUsage ? topApplicationUsage : [],
        colorByPoint: true,
      }

    const topAppColors = ["#0e0548", "#1d136a", "#1b51a7", "#3e88d3", "#8cb5ec", "#86c6df", "#4b91f1", "#64a1f4", "#8dbdff", "#c1d5f6",
      "#0e0548", "#1d136a", "#1b51a7", "#3e88d3", "#8cb5ec", "#86c6df", "#4b91f1", "#64a1f4", "#8dbdff", "#c1d5f6"
    ];

    const usageOverTimeColors = ["#3C7DD9", "#C1D5F6", "#2039B5"];

  return (
    <div className="Form">
        <Grid className="am-table">
           <Grid>               
                <Grid className="usageOverTimeMainContainer">
                    <Grid className="usageOverTimeContainer">
                        <Grid className="usage-lineChartTabs">
                            <Button onClick={()=> {
                              setActiveTab(USAGE_OVER_TIME)
                              setLineChartTab(USAGE_OVER_TIME)
                              setTrackTab(true);
                              }} 
                              className={ lineChartTab == USAGE_OVER_TIME ? "usageActiveClass" : "usageInactiveClass" }
                            >All Usage</Button>
                            {!_q.hasOwnProperty('topUsage') && <Button onClick={()=>{
                              setActiveTab(TOP_USAGE)
                              setLineChartTab(TOP_USAGE)
                              setTrackTab(true);
                            }}
                              className={ lineChartTab == TOP_USAGE ? "topUsageActiveClass" : "topUsageInactiveClass" }
                            >{ topAppLimit!=='all' ? `Top ${topAppLimit} App Usage` : "Top Usage" }</Button>}
                        </Grid>
                        <div className="siteCharts__filters-reset-zoom resetZoom-usage">
                          <Grid className="pieChat-selectTag">
                            <FormControl variant="standard" className='selectEntry-pie'>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={selectedBin}
                                onChange={handleBinChange}
                              >
                                {getMinutes(startDate, endDate) >= 15 && <MenuItem value={'15m'}>15m</MenuItem>}
                                {getMinutes(startDate, endDate) >= 60 && <MenuItem value={'1h'}>1h</MenuItem>}
                                {getMinutes(startDate, endDate) >= 240 && <MenuItem value={'4h'}>4h</MenuItem>}
                                {getMinutes(startDate, endDate) >= 360 && <MenuItem value={'6h'}>6h</MenuItem>}
                                {getMinutes(startDate, endDate) >= 720 && <MenuItem value={'12h'}>12h</MenuItem>}
                                {getMinutes(startDate, endDate) >= 1440 && <MenuItem value={'1d'}>1d</MenuItem>}
                                {getMinutes(startDate, endDate) >= 10080 && <MenuItem value={'7d'}>7d</MenuItem>}
                              </Select>
                            </FormControl>
                          </Grid>
                          { resetDate.length > 0 && 
                            <div>
                              <FormControlLabel
                                value="Zoom Back"
                                control={<IconButton onClick={handleResetZoom}><ZoomOutIcon /></IconButton>}
                                label="Zoom Back"
                                labelPlacement="start" 
                              />
                              <FormControlLabel
                                  value="Zoom Reset"
                                  control={<IconButton onClick={handleDefaultResetZoom}><RestartAltIcon /></IconButton>}
                                  label="Zoom Reset"
                                  labelPlacement="start"
                              />
                            </div>
                          }
                          {
                            lineChartTab == TOP_USAGE && <Grid className="entryLimit-lineChart">
                              <FormControl variant="standard" className='selectEntry-pie'>
                                  <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={topAppLimit}
                                      onChange={handleChangeTopAppLimit}
                                      >
                                      <MenuItem value="all">
                                      <em>All</em>
                                      </MenuItem>
                                      <MenuItem value={5}>5</MenuItem>
                                      <MenuItem value={10}>10</MenuItem>
                                      <MenuItem value={20}>20</MenuItem>
                                      <MenuItem value={30}>30</MenuItem>
                                      <MenuItem value={50}>50</MenuItem>
                                  </Select>
                              </FormControl>
                            </Grid>
                          }
                        </div>
                    </Grid>
                    <Grid>
                        {lineChartTab == USAGE_OVER_TIME ? <div>
                            <Chart chartOptions={options} hideLegendTable={true} />
                        </div> :
                        <div>
                            <Chart chartOptions={topAppsOptions} hideLegendTable={true} />
                        </div> 
                        }
                    </Grid>
                    {
                      lineChartTab == TOP_USAGE ? 
                      <Grid className="topApplicationNames">
                              { appNames?.map((name, i)=>(
                                <Grid key={i} className="name-color">
                                  <div className="totalUsageColor" style={{background:`${topAppColors[i]}`}}></div>
                                  <Grid className= {topApplicationUsage[i]?.visible ? "lineChart-legends" : 'disable-lineChart-legends'} onClick={() => handleApplicationFilterClick(name, topAppColors[i], i, 'top_usage')}>{name}</Grid>
                                </Grid>
                              )) }
                      </Grid> :
                      <Grid className="usageDetails-color">
                          { usageName?.map((name, i)=>(
                                <Grid key={i} className="name-color">
                                  <div className="totalUsageColor" style={{background:`${usageOverTimeColors[i]}`}}></div>
                                  <Grid className= {lineChartData[i]?.visible ? "lineChart-legends" : 'disable-lineChart-legends'} onClick={() => handleApplicationFilterClick(name, usageOverTimeColors[i], i, 'all_usage')}>{name}</Grid>
                                </Grid>
                              )) }
                      </Grid>
                    }
                </Grid>
                {_q.hasOwnProperty('topUsage') && <Grid className="usageOverTimeMainContainer">
                    <Grid className="usageOverTimeContainer">
                        <Grid className="usage-lineChartTabs">
                            <Button onClick={()=> {
                              setActiveTab(USAGE_OVER_TIME)
                              setLineChartTab(USAGE_OVER_TIME)
                              setTrackTab(true);
                              }} 
                              className="topUsageActiveClass"
                            >Top 10 App Usage</Button>
                        </Grid>
                        <div className="siteCharts__filters-reset-zoom resetZoom-usage">
                          <Grid className="entryLimit-lineChart">
                            <FormControl variant="standard" className='selectEntry-pie'>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={topAppLimit}
                                    onChange={handleChangeTopAppLimit}
                                    >
                                    <MenuItem value="all">
                                    <em>All</em>
                                    </MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                </Select>
                            </FormControl>
                          </Grid>
                        </div>
                    </Grid>
                    <Grid>
                      <div>
                          <Chart chartOptions={topAppsOptions} hideLegendTable={true} />
                      </div>
                    </Grid>
                    <Grid className="topApplicationNames">
                      {appNames?.map((name, i) => (
                        <Grid key={i} className="name-color">
                          <div className="totalUsageColor" style={{ background: `${topAppColors[i]}` }}></div>
                          <Grid className={topApplicationUsage[i]?.visible ? "lineChart-legends" : 'disable-lineChart-legends'} onClick={() => handleApplicationFilterClick(name, topAppColors[i], i, 'top_usage')}>{name}</Grid>
                        </Grid>
                      ))}
                    </Grid>
                </Grid>}
            </Grid>
        </Grid>
    </div>
  )
}

const mapStateToProps = (state) => ({
  startDate: state?.authReducer?.newSummaryStartDate,
  endDate: state?.authReducer?.newSummaryEndDate,
  userTimezone: state?.authReducer?.userTimezone
});

export default withRouter(
  connect(mapStateToProps, {
      
  })(UsageLineChart)
);