import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import SearchIcon from '@material-ui/icons/Search';
import Moment from 'moment';
import "./dataUsage.css"
import {
    Grid,
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Typography,
    Tooltip,
    Select,
    MenuItem,
    Autocomplete,
    TextField,
    Paper,
    Stack,
    Pagination
} from "@mui/material";
import { updatePermission } from "../../actions/Users/authenticate";
import Export_Icon from "../../asset/image/Export_Icon.svg";
import { KAPTURE_DOWNLOAD_URL } from "../../config"
import { PER_PAGE_ITEMS, DEFAULT_PAGE_SIZE } from "../../constants/Constants";
import { getBoxes, getLteProviders, getDataUsage, exportDataUsageReport, getDataUsageCount } from "../../actions/Users/authenticateReports";
import { getEncodedURI, getDecodeURI, strToK4List } from '../../utils/util';
import { setYear } from "date-fns/esm";
import GetApp from "@material-ui/icons/GetApp";


const Month = [
    { name: 'Jan', val: '01' },
    { name: 'Feb', val: '02' },
    { name: 'Mar', val: '03' },
    { name: 'Apr', val: '04' },
    { name: 'May', val: '05' },
    { name: 'Jun', val: '06' },
    { name: 'Jul', val: '07' },
    { name: 'Aug', val: '08' },
    { name: 'Sep', val: '09' },
    { name: 'Oct', val: '10' },
    { name: 'Nov', val: '11' },
    { name: 'Dec', val: '12' },
]
const Year = [Moment().year() - 1, Moment().year(), Moment().year() + 1]



const DataUsage = (props) => {

    const { authReducer, getLteProviders, getDataUsage, exportDataUsageReport, history, location, getDataUsageCount } = props;
    let _q = getDecodeURI(location?.search);
    const [selectedMonth, setSelectedMonth] = useState(_q.hasOwnProperty('month') ? _q.month : Moment().format('MMM'));
    const [selectedYear, setSelectedYear]: any = useState(_q.hasOwnProperty('year') ? parseInt(_q.year) : Moment().year());
    const [selectedSource, setSelectedSource]: any = useState('K4 Server');
    const [HEADERS, setHEADERS]: any = useState([])
    const [order, setOrder]: any = useState("totalUsage")
    const [orderBy, setOrderBy]: any = useState("DESC")
    const [page, setPage] = useState<any>(_q.hasOwnProperty("page") ? parseInt(_q.page) : 1)
    const [vesselArray, setVesselArray]: any = useState(['All'])
    const [vessel, setVessel]: any = useState('All');
    const [ouName, setOuName]: any = useState('All');

    const [selectedVsl, setSelectedVsl]: any = useState('All');
    const [pages, setPages] = useState<number>(0);
    const [includeBlended, setIncludeBlended]: any = useState(true)
    const [limit, setLimit] = useState<any>(_q.hasOwnProperty("limit") ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const [total, setTotal] = useState<number>(0);
    const [fromItem, setFromItem] = useState<number>(0);
    const [toItem, setToItem] = useState<number>(0);
    const [rows, setRows]: any = useState([])
    const value = _q.month ;
    const [ValMonth, setValMonth]: any = useState(_q.hasOwnProperty('month') ? (parseInt(Month.filter((month) => month.name === value)[0].val)) : Moment().month() + 1)
    const [loaders, setLoaders] = useState({ showLoader: false, loadingText: "Fetching data please wait..." });


    useEffect(() => {
        getLteProviders()
    }, [])

    const exportFile = () => {
        let payload = {
            date: selectedMonth + '-' + selectedYear,
            source: selectedSource,
        };

        exportDataUsageReport(payload)
    }

    let headers = [
        {
            id: 'vs_nm',
            label: 'Site Name',
            minWidth: '120px',
            link: true,
        },
        // {
        //     id: 'k4Owned',
        //     label: 'SIM Owner',
        //     minWidth: '40px',
        //     link: true,
        // },
        {
            id: 'totalsims',
            label: 'SIMs Total(Active)',
            minWidth: '40px',
        },
        {
            id: 'quota',
            label: 'Quota (GB)',
            minWidth: '50px',
        },
        {
            id: 'totalUsage',
            label: 'LTE (GB)',
            minWidth: '40px',
        },
        {
            id: 'hispaUsage',
            label: 'VSAT (GB)',
            minWidth: '40px',
        },
        {
            id: 'total',
            label: 'Total (GB)',
            minWidth: '40px',
        },
        {
            id: 'quotaprnt',
            label: 'Used %',
            minWidth: '30px',
        },
        {
            id: 'overage',
            label: 'Overage (GB)',
            minWidth: '30px',
        },
        {
            id: 'Operator',
            label: 'Operator',
            minWidth: '30px',
        },
        {
            id: 'Usage',
            label: 'Usage (GB)',
            minWidth: '30px',
        }

    ];

    useEffect(() => {
        if (!_.isEmpty(authReducer.exportDataUsageReport) && authReducer.exportDataUsageReport.data) {
            let path = authReducer.exportDataUsageReport.data.downloadFileName;
            if (authReducer.exportDataUsageReport.data.downloadFileName) {
                window.location.href = KAPTURE_DOWNLOAD_URL + 'uploads/' + path;
            }
            authReducer.exportDataUsageReport = {};
        }
    }, [authReducer.getSimBox, authReducer.getLteProviders, authReducer.exportDataUsageReport]
    )

    useEffect(() => {
        if(!_.isEmpty(authReducer.getDataUsage)) {
            const data = authReducer.getDataUsage.hasOwnProperty('data') ? authReducer?.getDataUsage?.data : [];
            let dataUsage: any = [];
            for(var key in data){
                if(data[key]) {
                const rows: any[] = data[key]?.rows;
                const columns: string[] = data[key]?.columns;
                const dataUsageData: any[] = [];
                for (let i = 0; i < rows?.length; i++) {
                    const row = {};
                    const currentRow = rows[i];
                    columns.forEach((column, index) => {
                        row[column] = currentRow[index];
                    })
                    dataUsageData.push(row);
                }

                dataUsage.push({
                    data: dataUsageData,
                    active_sim: dataUsageData[0].active_sim,
                    allSimCount: dataUsageData[0].allSimCount,
                    fullName: dataUsageData[0].fullName,
                    k4Owned: dataUsageData[0].k4Owned,
                    quota: dataUsageData[0].quota,
                    vessel_name: dataUsageData[0].vessel_name,
                    vessel_rank: dataUsageData[0].vessel_rank,
                    vsat_usage: dataUsageData[0].vsat_usage,
                    plans: dataUsageData[0].plans,
                    k4Id: dataUsageData[0].k4Id,
                    isThrottled: dataUsageData[0].isThrottled
                })
               }
            }
            setRows(dataUsage)
            authReducer.getDataUsage = {};
        }
    }, [authReducer.getDataUsage])

    useEffect(() => {
        if(!_.isEmpty(authReducer.getDataUsageCount)) {
            let totalRecords = authReducer.getDataUsageCount.hasOwnProperty('data') ? authReducer.getDataUsageCount?.data?.rows?.[0][0] : 0;
            let totalPages = Math.ceil(totalRecords / limit);
            let fromIndex = (totalRecords > 0) ? ((page-1) * limit  +1) : 0;
            let toIndex = (fromIndex + limit - 1) > totalRecords ? totalRecords : (fromIndex + limit - 1);
            setTotal(totalRecords)
            setPages(totalPages);
            setFromItem(fromIndex);
            setToItem(toIndex);
        }
        authReducer.getDataUsageCount = {};
    }, [authReducer.getDataUsageCount])

    const fnRound = (n, d) => {
        return Math.round(n * Math.pow(10, d)) / Math.pow(10, d);
    };

    const handlePagination = (e: any, value: number) => {
        let params: any = getDecodeURI(location?.search);
        params.page = value;
        setPage(value);
        doNavigate(params);
    }

    const handlePerPageChange = (e: any) => {
        const value = parseInt(e.target.innerText);
        let params: any = getDecodeURI(location?.search);
        params.page = 1;
        params.limit = value
        setPage(1);
        setLimit(value);
        doNavigate(params);
    }

    const handleYearChange = (e) => {
        const value = e.target.value;
        setSelectedVsl("All")
        setPage(1)
        setSelectedYear(value)
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        params.year = value;
        doNavigate(params);
    }

    const handleMonthChange = (e) => {
        const value = e.target.value;
        setValMonth(parseInt(Month.filter((month) => month.name === value)[0].val))
        setSelectedVsl("All")
        setPage(1)
        setSelectedMonth(value)
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        params.month = value;
        doNavigate(params);
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const handleVesselNameClick = (vsName: string, k4Id: string) => {
        const params = getDecodeURI(location?.search);
        const usageParams: any = {};
        if (params.month) {
            usageParams.month = params.month;
        }
        if (params.year) {
            usageParams.year = params.year;
        }
        history.push({ pathname: `/reports/dataUsage/dailyUsage/${vsName}/${k4Id}`, search: `?${getEncodedURI(usageParams)}` });
    }

    useEffect(() => {
         processQueryUrl();
    }, [location, authReducer?.getVesselsListing]);

    const processQueryUrl = () => {
        let _q = getDecodeURI(location?.search);

        let _page = _q.page && '' != _q.page.trim() ? parseInt(_q.page) : 1;
        if (_page != page) {
            setPage(_page);
        }

        let _limit = _q.limit && '' != _q.limit.trim() ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE;
        if (_limit != limit) {
            setLimit(_limit);
        }

        const value = _q.month ;
        let _month = _q.hasOwnProperty('month') ? (parseInt(Month.filter((month) => month.name === value)[0].val)) : Moment().month() + 1;
        if (_q.month && "" != _q.month) {
            setSelectedMonth(_q.month)
            _month = _q.month;
        }

        let _year = _q.hasOwnProperty('year') ? parseInt(_q.year) : Moment().year();
        if (_q.year && "" != _q.year) {
            setSelectedYear(_q.year)
            _year = _q.year;
        }

        let ouName = `and fullname IN ('${authReducer?.userOu?.name}')`;
        if (_q.hasOwnProperty("ouName") && '' != _q.ouName) {
            ouName = `and fullname IN ('${_q.ouName}')`;
         }

        let _vessels: any[] = [];
        if (_q.hasOwnProperty("k4Ids") && '' != _q.k4Ids.trim()) {
           _vessels = strToK4List(_q.k4Ids);
        }

        if(_.isEmpty(_vessels) && !_.isEmpty(authReducer?.getVesselsListing)){
            if(ouName !== `and fullname IN ('${authReducer?.selectedOu.name}')`){
                _vessels = [];
            } else {
                _vessels = authReducer?.getVesselsListing?.locations;
            }
        }

        if(authReducer?.getVesselsListing?.locations && _vessels.length>0 ) {
            if(ouName==`and fullname IN ('K4Mobility')`){
                getDataUsage(limit, page, _year, ValMonth, "", _vessels);
                getDataUsageCount("", _vessels);
            }else{
                getDataUsage(limit, page, _year, ValMonth, ouName, _vessels);
                getDataUsageCount(ouName, _vessels);
            }
        }
    }

    const handleCalculateLTE = (row) => {
        let usage=0;
        for(let i=0; i<row.data.length; i++){
            usage = usage + row.data[i].sim_data_usage;
        }
        if(usage){
            return Math.ceil(usage/1073741824);
        }
        return "-"
    }

    const handleCalculatePer = (row) => {
        let data=0;
        for(let i=0;i<row.data.length;i++){
            data = data+row.data[i].sim_data_usage
        }
        if(row.quota=='99999'){
            return '-';
        }
        else if(row.quota>0 && row.quota!=='99999'){
            return (`${((Math.ceil(data/1073741824)/row.quota)*100).toFixed(0)} %`)
        }
        else{
          return '-';
        }
    }

    const handleCalculateOverage = (row) => {
        let usage=0;
        for(let i=0; i<row.data.length; i++){
            usage = usage+row.data[i].sim_data_usage;
        }
        if(row.quota =='99999' || Math.ceil(usage/1073741824) < row.quota){
            return 0;
        }
        else if(row.quota && row.quota !== '99999') {
            return (Math.abs(Math.ceil(usage/1073741824) - row.quota))
        }
        else{
            return 0;
        }
    }

    const handleDisplayOperator = (row) => {
        let operator:any =[];
        for(let i=0; i<= row?.data?.length; i++) {
            if(row?.data[i]?.k4Owned!==null && row?.data[i]?.operator && row?.data[i]?.k4Owned=='Yes') {
                operator.push(row?.data[i]?.operator);
            }
            else if((row?.data[i]?.k4Owned!==null && row?.data[i]?.operator && row?.data[i]?.k4Owned=='No')) {
                operator.push(`3P-${row?.data[i]?.operator}`)
            }else{
                if(row?.data[i]!=undefined){
                    if(row?.data[i].operator==null || row?.data[i].operator==""){

                        operator.push("-")
                    }else{
                        operator.push(row?.data[i].operator)
                    }
                }
            }
        }  
        return operator;
    }

    const handleCalculateOperatorUsage = (row) => {
        let simDataUsage:any = [];
        for(let i=0; i< row?.data?.length; i++) {
            
            if(row?.data[i]?.sim_data_usage!=undefined || row?.data[i]?.sim_data_usage!=null){
                simDataUsage.push(row?.data[i]?.sim_data_usage)
            }else{
                simDataUsage.push(0)
            }
        }
        return simDataUsage;
    }

    const handleCheckTMobile = (el) => {
        let arr = el.trim().split("-");
        if(arr[2]=="Mobile"){
            return `${arr[1]}-${arr[2]}`
        }else{
            return arr[1];
        }
    }

    return (
        <div className="Form">
            <div>
                <Grid container className="justyfy-space-between sel_padding dataUsage-justyfy-space-between">
                    <Grid item className="reportsDataHeader">
                        <div className="d-flex align-center">
                            <div className="usage_select">Month</div>
                            <div className="padLeft">
                                <Select
                                    className="selBackground"
                                    autoWidth
                                    margin='dense'
                                    variant='outlined'
                                    id='simple-select-month'
                                    value={selectedMonth}
                                    onChange={handleMonthChange}
                                    SelectDisplayProps={{
                                        style: { padding: '8px 32px 8px 8px' },
                                    }}
                                >
                                    {Month.map((entry, id) => {
                                        return (
                                            <MenuItem key={id} value={entry.name}>
                                                {entry.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div className="d-flex align-center usage_select_margin">
                            <div className="usage_select">Year</div>
                            <div className="padLeft">
                                <Select
                                    className="selBackground"
                                    autoWidth
                                    margin='dense'
                                    variant='outlined'
                                    id='simple-select-month'
                                    value={selectedYear}
                                    onChange={handleYearChange}
                                    SelectDisplayProps={{ style: { padding: '8px 32px 8px 8px' } }}
                                >
                                    {Year.map((entry, id) => {
                                        return (
                                            <MenuItem key={id} value={entry}>
                                                {entry}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </div>
                        </div>
                        {/* <div className="d-flex align-center">
                            <div className="sel_padding usage_select">Site</div>
                            <div className="padLeft">
                                <Select
                                    className="selBackground"
                                    autoWidth
                                    margin='dense'
                                    variant='outlined'
                                    id='simple-select-month'
                                    value={selectedVsl}
                                    onChange={(e) => {
                                        setSelectedVsl(e.target.value)
                                    }}
                                    SelectDisplayProps={{ style: { padding: '8px 32px 8px 8px' } }}
                                >
                                    {vesselArray.map((entry, id) => {
                                        return (
                                            <MenuItem key={id} value={entry}>
                                                {entry}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </div>
                        </div> */}


                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} className="usage_pagination">
                        <Grid>
                            <Grid className="vesselPageDetails">Per Page</Grid>
                            <Grid className="vesselPagination">
                                {fromItem} to  {toItem} out of {total} Sites
                            </Grid>
                        </Grid>
                        <Grid className="ml-10">
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={PER_PAGE_ITEMS}
                                className="props.classes.paper"
                                sx={{ width: 65 }}
                                disableClearable
                                onChange={(e: any) => {
                                    handlePerPageChange(e);
                                }}
                                value={{ label: `${limit}` }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        InputLabelProps={{ className: "autoCompleteBox" }}
                                    />
                                )}
                                PaperComponent={({ children }) => (
                                    <Paper className="paperFont">{children}</Paper>
                                )}
                            />
                        </Grid>
                        <Grid className="ml-10">
                            <Stack>
                                <Pagination
                                    className="paginationContainer"
                                    count={pages}
                                    page={page}
                                    size="small"
                                    onChange={handlePagination}
                                    variant="outlined"
                                    shape="rounded"
                                    siblingCount={0}
                                    defaultPage={1}
                                    boundaryCount={1}
                                />
                            </Stack>
                        </Grid>
                    </Grid>

                    <Grid item className="d-flex align-center">
                        <Tooltip title='Export'>
                            <div
                                className={`d-flex align-center `}
                                onClick={() => {
                                    exportFile()
                                }}
                            >
                                <GetApp className="exportUsage"></GetApp>
                            </div>
                        </Tooltip>
                    </Grid>

                </Grid>

                <TableContainer className="reportsTableContainer dataUsageScroll">
                    <Table stickyHeader size='small'>
                        <TableHead className="dataUsageHeader fleetDataTable">
                            <TableRow>
                                {headers.map((header, idx) => {
                                    let label = header.label;
                                    return (
                                        <TableCell align="center" className="reportsTableCell" key={idx}>
                                            {label}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        
                        { 0 !== rows.length ? (
                           <TableBody className="tableBody">
                            {rows && rows?.map((row, i) => (
                                <TableRow style={i % 2 ? { background: "linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%)"} : { background: "white" }}
                                className="fleetRowRank fleetRowRank-usage" key={i}>
                                    {
                                        <Tooltip title={row.plans?.split('|') ? (
                                            <div>
                                                {row.plans && row.plans.split('|').map((el) => {
                                                    return <Typography style={{ fontSize: '0.7rem' }}>
                                                        {el}
                                                    </Typography>
                                                })}
                                            </div>
                                            ) : <Typography style={{ fontSize: '0.7rem'}}>No Active LTE Plan</Typography>}>
                                                {row.plans ? 
                                                    <TableCell onClick={() => {
                                                        handleVesselNameClick(row.vessel_name, row.k4Id);
                                                    }} className="fleetRowRank-usage activePlanSites" align="center">{row.vessel_name}</TableCell>
                                                    :
                                                    <TableCell onClick={() => {
                                                        handleVesselNameClick(row.vessel_name, row.k4Id);
                                                    }} className="fleetRowRank-usage inActivePlanSites" align="center">{row.vessel_name}</TableCell>
                                                
                                                }
                                        </Tooltip>
                                    }
                                    {
                                       row.allSimCount && row.active_sim ? <TableCell align="center" className="fleetRowRank-dataUsage">{`${row.allSimCount}(${row.active_sim})`}</TableCell> : row.allSimCount && !row.active_sim ? <TableCell className="fleetRowRank-dataUsage" align="center">{`${row.allSimCount}(0)`}</TableCell> : <TableCell className="fleetRowRank-dataUsage" align="center">0(0)</TableCell>
                                    }
                                    {
                                       row.quota && row.quota=='99999' && row.isThrottled==0 ? 
                                          <TableCell className="fleetRowRank-dataUsage" align="center">Unlimited</TableCell>
                                        : 
                                        row.quota && row.quota=='99999' && row.isThrottled==1 ? 
                                            <TableCell className="fleetRowRank-dataUsage" align="center"><Tooltip title='Throttled'><span className="throttledQuota">Unlimited(T)</span></Tooltip></TableCell> :
                                            row.quota ? <TableCell className="fleetRowRank-dataUsage" align="center">{row.quota}</TableCell> : <TableCell className="fleetRowRank-dataUsage" align="center">0</TableCell>
                                    }
                                    {
                                       <TableCell className="fleetRowRank-dataUsage" align="center">{handleCalculateLTE(row)}</TableCell>
                                    }
                                    {
                                       row.vsat_usage ? <TableCell className="fleetRowRank-dataUsage" align="center">{Math.ceil(row.vsat_usage/(1024*1024*1024))}</TableCell> : <TableCell className="fleetRowRank-dataUsage" align="center">-</TableCell>
                                    }
                                    {
                                       <TableCell className="fleetRowRank-dataUsage" align="center">{handleCalculateLTE(row)}</TableCell>
                                    }
                                    {
                                        <TableCell className="fleetRowRank-dataUsage" align="center">{handleCalculatePer(row)}</TableCell>
                                    }
                                    {
                                        <TableCell className="fleetRowRank-dataUsage" align="center" style={ Number(handleCalculateOverage(row))>0 ? {color:"red"} : {color:"#6c6b6b"}} >{handleCalculateOverage(row)}</TableCell>
                                    }
                                    {
                                        <TableCell className="fleetRowRank-dataUsage" align="center">
                                        {
                                            handleDisplayOperator(row).map((el) => (
                                                el.split("-")[0]=="3P" ? <div><span style={{color:"red"}}>{el.trim().split("-")[0]}</span> - {handleCheckTMobile(el)}</div> : <div>{el}</div>
                                            ))
                                        }
                                        </TableCell>
                                    }
                                    {
                                       <TableCell className="fleetRowRank-dataUsage" align="center">
                                        {
                                            handleCalculateOperatorUsage(row).map((usage) => (
                                                <div>{usage==0?0:((usage)/(1024*1024*1024)).toFixed(1)}</div>
                                            ))
                                        }
                                        </TableCell>
                                    }
                                </TableRow>
                            ))}
                           </TableBody>
                        ) : (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={3} className="noDataAvailableCss" align="center">No data available</TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </div>

        </div >
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer
});

export default withRouter(
    connect(mapStateToProps, {
        getBoxes,
        updatePermission,
        getDataUsage,
        getLteProviders,
        exportDataUsageReport,
        getDataUsageCount,
    })(DataUsage)
);